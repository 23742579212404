import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import CourseCompletedIcon from './completedicon'
import { Link, useHistory } from 'react-router-dom';
import {getLearnerCourseById} from "../../../modules/courses/services";
import { useDispatch, useSelector } from "react-redux";
// function CourseCompleted({ match }) {

    function CourseCompleted(props) {
    const dispatch = useDispatch();
    const history = useHistory()
    // const id = match.params.id;
    const name=props.name;
    const [state, setState] = useState({
        course: {},
    });
    // useEffect(() => {
    //     dispatch(getLearnerCourseById(id,function (res){
    //         setState({ ...state, course: res });
    //     }))
    // }, []);
    const handlePush = () => {
        if(props.callingComp){

        if(props.callingComp == 'lp'){
             props?.onHide()
            return;
        }else {
            history.push('/my-assignments')

        }


        }
        history.push('/my-assignments')

    }

    return (
        <div className="course-completed-wrap">
            {/*<h3 className="sec-title m-b-30">Introduction To Course Library</h3>*/}
            <div className="whitebox shadow-box">
                <div className="course-completed-outer-box">
                    <div className="shadow-icon-box">
                        <CourseCompletedIcon />
                    </div>
                    <div className="course-completed-description">
                        <h4 className="completed-text">Course Completed!</h4>
                        <p>You Have Completed The Course - <span>
                            {name}
                            {/* {state?.course?.name}. */}
                            </span> Keep Up The Great Work!</p>

                        {props?.data && props?.data?.completion_message &&
                        <h4 className="completed-text">Completion Message : {props?.data?.completion_message }</h4>

                        }

                    </div>
                    <Button onClick={handlePush}>Continue Learning</Button>
                </div>
            </div>
        </div>
    );
};

export default CourseCompleted;
