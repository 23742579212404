import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CourseAssignBox from './components/courseassignbox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GroupAssignBox from './components/groupselectionbox';
import Modal from 'react-bootstrap/Modal';
import { assignCourse, edLinkAssignmet, getCourseAssignments, getCourseAssignmentsById, updateAssignCourse, getMemberList } from "../../../../services";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import ScrollArea from "react-scrollbar";
import Spinner from "react-bootstrap/Spinner";
import AssignmentPopup from "./components/assignmentname";
import ClassesAssignBox from './components/classesAssignBox';
import { toast} from "react-toastify";

function MyVerticallyCenteredModal(props) {
    const dispatch = useDispatch()
    const response = useSelector(state => state.course?.get_course_assignments?.response);
    const loading = useSelector(state => state.course?.get_course_assignments?.loading);
   
    const user = useSelector(state => state.auth?.user);

    const [state, setState] = useState({
        courseAssignments: response?.results || [],
        modalId: ''
    })


    useEffect(() => {
        if (props.url) {
            dispatch(getCourseAssignments(props.url))
        }

    }, [])
    useEffect(() => {
        if (props.url) {
            setState({ ...state, courseAssignments: response?.results })
        }
    }, [response]);
    const handleSelection = (id, list) => {
        setState({ ...state, modalId: id })
        props.handle(id, list)
    }
    const handleCreate = () => {
        props.onHide()
    }
    return (
        <Modal className="common-modal team-member-pop inner-popup"
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header>

                <div className="addmember-header">

                    <h4>List Of Course User Enrollments</h4>
                    <p>Choose User Enrollment whom you want to Edit</p>
                </div>
                <div className="form-close-btn p-relative" onClick={() => props.onHide(8)}><span className="transparent-close-icon">×</span></div>
            </Modal.Header>
            <Modal.Body>
                <div className="add-quiz-box-content">

                    <ScrollArea
                        speed={0.8}
                        className="listscroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                        <ul>

                            {state?.courseAssignments && state?.courseAssignments && state?.courseAssignments?.length ? state?.courseAssignments?.map((el, index) => {

                                return (
                                    <>
                                        <li onClick={() => handleSelection(el.id, el)} key={index} className={`${el.isChecked && el.isChecked ? 'list-selected' : ''}`}>
                                            {props.loading && props.loading && state.modalId == el?.id ?
                                                <Spinner animation="border" variant="warning" />
                                                :
                                                <span >{` ${el?.name} ${el?.due_date ? el?.due_date : ''}`} </span>
                                            }

                                        </li>

                                    </>
                                );
                            })


                                :

                                <div className='row justify-content-center mt-2'>
                                    {loading ?
                                        <Spinner animation="border" variant="danger" />
                                        :
                                        <>

                                            <div className="col-12 text-center">
                                                <p>No Existing User Enrollment</p>

                                            </div>

                                        </>
                                    }
                                </div>


                            }
                            {loading ?

                                ''
                                :

                                <div className=" text-center mt-3">
                                    <button className="btn btn-m border-btn" onClick={handleCreate}>Create New</button>


                                </div>


                            }


                        </ul>
                    </ScrollArea>
                </div>

            </Modal.Body>
        </Modal>
    );
}

const CourseAssign = ({ match }) => {
    const history = useHistory()
    const dispatch = useDispatch();
    let loading = useSelector(state => state?.course?.get_course_assigment_by_id?.loading);
    let updateAssignmentLoading = useSelector(state => state?.course?.updated_course_assigment?.loading);
    let addAssignmentLoading = useSelector(state => state?.course?.assign_course?.loading);
    const user = useSelector(state => state.auth?.user);
    const courseid = match?.params?.id;
    const [state, setState] = useState({
        isDisplay: false,
        isGroupDisplay: false,
        isClassesDisplay: false,
        member: [],
        groupDetails: [],
        classes: [],
        modalShow: true,
        assignmentId: '',
        assignmentList: [],
        singleAssignment: {},
        basicInformation: {},
        focus: false,
        failedVaues: {}

    })

    const [assignmentPopup, setassignmentPopup] = useState(false)
    const groupSelection = useRef();
    const memberSelection = useRef();
  

    const memberList = (data) => {
        setState({ ...state, member: data });
    };

    const groupList = (data) => {
        setState({ ...state, groupDetails: data });
    };

    const getBasicInformation = (data) => {
        onSave(data);
    };

    const onSave = (basicInformation) => {
        let data = {
            "groups": state.groupDetails, "classes": state.classes, "learners": state.member, 'name': basicInformation?.name ? basicInformation?.name : state?.singleAssignment?.name, "due_date": basicInformation?.due_date ? basicInformation?.due_date : state?.singleAssignment?.due_date
        };
        // let payload = { 'ed_link_class_ids': state.classes, 'name': state?.basicInformation?.name ? state?.basicInformation?.name : state?.singleAssignment?.name, "due_date": state?.basicInformation?.due_date ? state?.basicInformation?.due_date : state?.singleAssignment?.due_date }
        if(state?.groupDetails?.length || state?.member?.length ) {
             if (basicInformation?.name?.length || state?.singleAssignment?.name?.length) {

            if (state.assignmentId && courseid) {

                dispatch(updateAssignCourse(data, courseid, state.assignmentId, history, function (err) {
                    if (err) {
                        setState({ ...state, failedVaues: data })
                        setassignmentPopup(true)
                    }

                }));
                }
                // else if(user?.is_edlink_teacher){
                //     dispatch(edLinkAssignmet(payload, courseid, function (error) {

                //         if (error) {
                //               setState({ ...state, failedVaues: data })
                //               setassignmentPopup(true)
                //           }
                //       }));
                // }
                else {

                    dispatch(assignCourse(data, courseid, history, function (error) {
                        if (error) {
                            setState({ ...state, failedVaues: data })
                            setassignmentPopup(true)
                        }
                    }));


                }
            } 
        } else {
            toast.warn('Please select any Learner or Group')
        }
    };

    useEffect(() => {
        let element = document.getElementById('headerTitle');
        if (element) {
            switch (true) {
                case history.location.pathname.includes('enroll_users'):
                    element.innerHTML = 'Course Library'
                    break;
                    'Profile'
            }

        }
    }, [])

    const handleBack = () => {
        history.push('/coursemanagement')
    }

    const handleAssignmentsById = (id, list) => {

        dispatch(getCourseAssignmentsById(id, courseid, function (response) {
            if (response) {
                memberSelection.current.checked = true;
                groupSelection.current.checked = true;
                // classesSelection.current.checked = true;


                setState({ ...state, isGroupDisplay: true, isClassesDisplay: true, isDisplay: true, modalShow: false, assignmentId: id, singleAssignment: list })
                setassignmentPopup(true)
            }
        }, function (error) {
            setState({ ...state, modalShow: false })
        }))


    }

    const handleLoader = () => {
        if (updateAssignmentLoading || addAssignmentLoading) {
            return (
                <div className='loader-full-screen-course'>
                    <div className='loader'>
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
            )
        }
    }


    return (

        <>
            <div className="course-assign-wrap">
                {handleLoader()}
                <div className="course-assign-member-type">
                    <Form className="d-block">
                        <Row>
                            <Col md="3">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    id="check1"

                                    ref={memberSelection}
                                    label={`Add member`}
                                    onChange={(e) => setState({ ...state, isDisplay: e.target.checked })}
                                />


                            </Col>
                            <Col md="3">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    id="check2"
                                    label={`Add group`}
                                    ref={groupSelection}
                                    onChange={(e) => setState({ ...state, isGroupDisplay: e.target.checked })}
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="course-assign-box-container">
                    {state.isDisplay && (
                        <>
                            <h4 className="course-assign-type-name">People to whom you want to assign this course</h4>
                            <CourseAssignBox singleAssignment={state.singleAssignment} urlId={courseid} assignId={state?.assignmentId} memberList={memberList} />
                        </>
                   ) }
                </div>


                {/* <div className="course-assign-box-container">
                    {state.isClassesDisplay &&
                        <>
                            <h4 className="course-assign-type-name">Classes to whom you want to assign this course</h4>
                            <ClassesAssignBox singleAssignment={state.singleAssignment} urlId={courseid} assignId={state?.assignmentId} classesList={classesList} />
                        </>
                    }
                </div> */}


                <div className="course-assign-box-container">

                    <div className="course-assign-box-container">
                        {state.isGroupDisplay &&
                            <>
                                <h4 className="course-assign-type-name">Groups to Whom you want to assign this course</h4>
                                <GroupAssignBox singleAssignment={state.singleAssignment} urlId={courseid} assignId={state?.assignmentId} groupList={groupList} />
                            </>
                        }
                    </div>
                </div>

            </div>
            <div className="add-module-footer d-flex justify-content-end">
                <div className="button-wrap double-btn">
                    <button className="btn btn-m border-btn" onClick={handleBack}>Cancel</button>
                    {state.assignmentId ?
                        <Button variant="primary" className="btn-m" disabled={updateAssignmentLoading} onClick={() => setassignmentPopup(true)}>Enroll Users</Button>
                        :
                        <Button variant="primary" className="btn-m" disabled={addAssignmentLoading} onClick={() => setassignmentPopup(true)}>Enroll Users</Button>
                    }

                </div>
            </div>

            <MyVerticallyCenteredModal
                url={courseid}
                show={state.modalShow}
                loading={loading}
                handle={handleAssignmentsById}
                // handleAssignment={handleAssignmentPopUp}
                onHide={() => setState({ ...state, modalShow: false, assignmentId: '' })}
            />
            <AssignmentPopup
                show={assignmentPopup}
                failed={state.failedVaues}
                basicInformation={getBasicInformation}
                singleAssignment={state?.singleAssignment}
                focus={state?.focus}
                onHide={() => setassignmentPopup(false)}

            />


        </>
    );
};

export default CourseAssign;
