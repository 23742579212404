import React, { useEffect, useState,useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import Logo from '~/assets/images/logo.png';
import { Formik } from "formik";
import * as yup from "yup";
import { register } from '~/modules/auth/services';
import { useDispatch, useSelector } from 'react-redux';
import country from "../../../../shared/country.json"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logOut } from '~/modules/auth/services';
import Modal from 'react-bootstrap/Modal';
import LetsGoImg from '~/assets/images/letsgo.svg';
import LetsSubs from '~/assets/images/subscription.svg';
import {Link} from 'react-router-dom';
import {resetStore} from "../../services";
import {simpleRedirect} from "../../../../shared/utils/checkHost";
import {Helmet} from "react-helmet";

const Registration = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [userinfo, setUserInfo] = useState({})
    const [count,setCount]  = useState(0)
    const { response, loading,completeRegistered, user } = useSelector(state => state.auth);
    const formikRef = React.useRef(null);
    const [show, setShow] = useState(false);
    const [SubShow, setSubShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleSubShow = () => setSubShow(true);


    useEffect(() => {
        setUserInfo(user)
    }, [user]);

    const logout = () => {
        let action = 'USER_LOGOUT'
        dispatch(resetStore(action))

    }

    useEffect(() => {
        simpleRedirect(user,history,logout)
    }, [count])

    const handleSubmit = (values) => {

        delete values.first_name;
        delete values.last_name;
        delete values.email;
        if (userinfo?.institution) {
            values.institute = user?.institution
        }
        dispatch(register(values, user?.user_id, history,function (res){
            if(res){
              
                setShow(true)

            }
        }));

    };


    const handleCancelRegister = () => {
        // dispatch(logOut(history));
        let action = 'USER_LOGOUT'
        dispatch(resetStore(action))
        history.push('/login');
    }
    const handleHistory = () => {
        setCount((prevState => prevState +1))

    }

    return (
        <>
            <ToastContainer autoClose={2000} />
            <Helmet>
                <title>Registration - Cultural Engagement  </title>
            </Helmet>
            <Modal className="custom-modal inner-popup letsgo-pop"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={SubShow}

            >
                <Modal.Body>
                    <div className="letsgo-pop-wrap plssubsribe-pop text-center">
                        <div className="letsgo-img"><img src={LetsSubs} /></div>
                        <h3>You are not part of any subscribed plan. Please <Link to="#">subscribe</Link></h3>
                    </div>

                </Modal.Body>

            </Modal>
            <Formik
                validationSchema={userinfo?.institution == null ? SignUpSchema : SignUpSchemaForNormal }
               // validationSchema={SignUpSchemaForNormal }
                onSubmit={handleSubmit}
                enableReinitialize={true}
                innerRef={formikRef}
                initialValues={userinfo?.institution == null ? {
                    institute: '',
                    mobile_number: '',
                    designation: '',
                    country: ''
                } : {
                    mobile_number: '',
                    designation: '',
                    country: ''

                }}
                // initialValues={{
                //    // institute: '',
                //     mobile_number: '',
                //     designation: '',
                //     country:'',
                // }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>

                        <div
                            className="auth-page-wrap fixed-footer-auth d-flex justify-content-center align-items-center">
                            <div className="checkout-page-wrap">
                                <div className="text-center">
                                    <div className="auth-logo"><img src={Logo} alt="" /></div>
                                    <h2 className="auth-title">Registration</h2>
                                </div>
                                <div className="checkout-order-boxes-wrap">
                                    <div className="registration-box white-box radius-box mt-lg-5">
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                <div className="form-input-wrap">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text"
                                                        name='first_name'
                                                        placeholder="Your First Name"
                                                        className="input-item"
                                                        value={user?.first_name}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <div className="form-input-wrap">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text"
                                                        name='last_name'
                                                        placeholder="Your Last Name"
                                                        value={user?.last_name}
                                                        disabled
                                                        className="input-item" />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <div className="form-input-wrap">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="text"
                                                        name='email'
                                                        placeholder="Your Email"
                                                        value={user?.email ? user?.email : user?.username}
                                                        disabled
                                                        className="input-item" />

                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <div className="form-input-wrap">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Your Phone Number"
                                                        name='mobile_number'
                                                        value={values.mobile_number}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mobile_number}
                                                        className="input-item" />
                                                    <Form.Control.Feedback type="invalid">
                                                       {errors.mobile_number}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                {userinfo?.institution == null ?
                                                    <div className="form-input-wrap">
                                                        <Form.Label>Company/Institution</Form.Label>
                                                        <Form.Control type="text"
                                                            placeholder="Your Company"
                                                            value={values?.institute}
                                                            name='institute'
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.institute}
                                                            
                                                            className="input-item" />
                                                            <Form.Control.Feedback type="invalid">
                                                       {errors.institute}
                                                        </Form.Control.Feedback>


                                                    </div>
                                                    :

                                                    <div className="form-input-wrap">
                                                        <Form.Label>Company/Institution</Form.Label>
                                                        <Form.Control type="text"
                                                            placeholder="Your Company"
                                                            value={user?.institution ? user?.institution : values.institute}
                                                            name='institute'
                                                            disabled
                                                            className="input-item" />

                                                    </div>

                                                }

                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <div className="form-input-wrap">
                                                    <Form.Label>Designation</Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Your Designation"
                                                        name='designation'
                                                        value={values.designation}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.designation}
                                                        className="input-item" />
                                                        <Form.Control.Feedback type="invalid">
                                                       {errors.designation}
                                                        </Form.Control.Feedback>


                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <div className="form-input-wrap">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control as="select"

                                                        value={values.country}
                                                        name='country'
                                                        onChange={handleChange}
                                                        placeholder="Please Select Country"
                                                        isInvalid={!!errors.country} className="select-item-country custom-select ">
                                                        <option> Please Select Country</option>
                                                        {country && country.length ? country.map((item) => (
                                                            <option value={item?.value}>{item?.label}</option>
                                                        ))
                                                            :
                                                            ""
                                                        }
                                                       

                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.country}
                                                        </Form.Control.Feedback>
                                                </div>
                                            </Col>

                                        </Row>
{/* <Button  onClick={handleSubShow}>Subscribe popup</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="checkout-footer">
                                <div className="checkout-btns-wrap">
                                    <Button className="white-btn border-btn" onClick={handleCancelRegister}>Cancel</Button>

                                    {/*<Link to='/subdomainsignin'><Button>Configure</Button></Link>*/}
                                    <Button type='submit' disabled={completeRegistered}  >{completeRegistered ? '...Please Wait' : 'Configure'}</Button>
                                </div>

                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <Modal className="custom-modal inner-popup letsgo-pop"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}

            >
                <Modal.Body>
                    <div className="letsgo-pop-wrap text-center">
                        <div className="letsgo-img"><img src={LetsGoImg} /></div>
                        <h3>Your 14-day free trial has started, please choose a sub-domain.</h3>
                        <div className="btn-wrap"><Button onClick={handleHistory}>Let's Go</Button></div>
                    </div>

                </Modal.Body>

            </Modal>
        </>
    );
};
var regex = /^[0-9 !@#\$%\^\&*\)\(+=._-]+$/g
const SignUpSchema = yup.object().shape({
    mobile_number: yup.string().required('Phone number is required')
        .matches(regex, 'Phone add valid number'),
    designation: yup.string().required('Designation is required'),
    institute: yup.string().required('Institute is required'),
    country: yup.string().required('Country is required'),


});

var regex = /^[0-9 !@#\$%\^\&*\)\(+=._-]+$/g
const SignUpSchemaForNormal = yup.object().shape({
    mobile_number: yup.string().required('Phone number is required')
        .matches(regex, 'Phone add valid number'),
    designation: yup.string().required('Designation is required'),
    country: yup.string().required('Country is required'),


});
export default Registration;
