import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ScrollArea from "react-scrollbar";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import ProfileDummy from "~/assets/images/logo-name.svg";
import { Steps, Hints } from "intro.js-react";
import {steps, stepsleaner, stepsadmin, steps_staffmanager} from './toursteps'
import { resetTour} from "../../../modules/Notifications/services"

import {
  DashboardIcon,
  LearnerDashboardIcon,
  LearningPathIcon,
  CoursesIcon,
  ProfileIcon,
  SurveyIcon,
  CourseManagementIcon,
  InstitutionContactIcon,
  LearningPathManagementIcon,
  SurveyManagementIcon,
  QuizManagementIcon,
  TeamIcon,
  ReportsIcon,
  SettingsIcon,
  SupportIcon,
} from "./icons";
import { simpleRedirect } from "../../utils/checkHost";
const Sidebar =  ({ toggled, handleToggleSidebar,name }) => {
  const [userinfo, setUserInfo] = useState({});
  const { response, loading, user } = useSelector((state) => state.auth);
  const { hideLpQuizes } = useSelector((state) => state.settings);
  const [stepEnabled, setStepEnabled] = useState(false);
  // const {  user1 } = useSelector(state => state.auth.logo);
  const [collapsed, setCollapsed] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [logo, setLogo] = useState(null);
  const selectedLogo = useSelector(
    (state) => state.settings.selectedLogo || ""
  );
  const tour = useSelector(state => state?.notifications?.tour);

  const [state, setState] = useState({
    mainRoles: ["manager", "admin", "staff_manager"],
    jun_role: ["learner"],
  });

  const history = useHistory();
  const dispatch = useDispatch()
  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };
  useEffect(() => {
    if (selectedLogo.logo) {
      if (selectedLogo.logo.includes("null")) {
        setImgData(ProfileDummy);
      } else {
        setImgData(selectedLogo.logo ? selectedLogo.logo : ProfileDummy);
      }
    } else {
      setImgData(user?.logo ? user?.logo : ProfileDummy);
    }
  }, [user, selectedLogo]);

  // useEffect(() => {
  //   simpleRedirect(user,history)
  // }, [user]);

  const onExit = () => {
    dispatch(resetTour(false));
    setStepEnabled(false)
   };

   useEffect(() => {
      let tourcompleted = localStorage.getItem("tour") === "completed" ? true : false;
      if(!tourcompleted){
        setStepEnabled(true)
        localStorage.setItem("tour","completed");
      } else{
        setStepEnabled(false)
      }
   },[]);

   useEffect(() => {
      if(tour){
        setStepEnabled(tour);
      }
  },[tour]);
   

  return (
    <div className="sidebar">
      <ProSidebar
   collapsed={collapsed}
   toggled={toggled}
   breakPoint="md"
   onToggle={handleToggleSidebar}
      >
        <SidebarHeader className="d-flex justify-content-end">
          <div className="sidebar-logo">
            <img src={imgData} alt="" />
          </div>
          <button className="toggle-bar svg-icon-color-change p-relative">
            <div className="bar-icon">
              <span className="bar-icon-item bar-icon-item-1"></span>
              <span className="bar-icon-item bar-icon-item-2"></span>
              <span className="bar-icon-item bar-icon-item-3"></span>
            </div>
            <div className="sidebar-switch-wrap">
              <Switch
                height={16}
                width={30}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={handleCollapsedChange}
                checked={collapsed}
                onColor="#219de9"
                offColor="#bbbbbb"
              />
            </div>
          </button>
        </SidebarHeader>
        <ScrollArea
          speed={0.8}
          className="sidebarscroll"
          contentClassName="content"
          horizontal={false}
        >

          <SidebarContent>
            <Menu>
              {/*<NavLink to="/dashboard"  className={`${user.user_type == "learner" ? "d-none" : ""} `}  exact >*/}
              {/*<MenuItem icon={<DashboardIcon />}>Dashboard*/}
              {/* </MenuItem>*/}
              {/*</NavLink>*/}
              {/*<NavLink to="/learner-dashboard" exact >*/}
              {/*  <MenuItem className="learners-icon" icon={<DashboardIcon />}>Learner Dashboard*/}
              {/*  </MenuItem>*/}
              {/*</NavLink>*/}
               <NavLink className={`${name == 'Assignments' ? 'active' : '' } `} to="/my-assignments" exact >
              <MenuItem className="learners-icon assignment-link" icon={<LearnerDashboardIcon />}>Assignments
              </MenuItem>
              </NavLink>

              {/*<NavLink to="/learnerCourses"  className={`${name == 'Courses' ? 'active' : '' } `} exact>*/}
              {/*  <MenuItem icon={<CoursesIcon />}>Courses</MenuItem>*/}
              {/*</NavLink>*/}
              {/*<NavLink to="/myLearnings" className={`${name == 'My Learnings' ? 'active' : '' } `} exact>*/}
              {/*  <MenuItem icon={<LearningPathIcon />}>My Learnings*/}
              {/*  </MenuItem>*/}
              {/*</NavLink>*/}
              {/* <NavLink to="/survey_list" exact>
                <MenuItem icon={<SurveyIcon />}>Surveys</MenuItem>
              </NavLink> */}
              <NavLink to="/profile" className={name === 'Profile' ? 'active ':''} exact>
                <MenuItem className="profile-link" icon={<ProfileIcon />}>Profile</MenuItem>
              </NavLink>
              <div
                className="side-link-seperator"
              >
                &nbsp;
              </div>
             

              {!hideLpQuizes && (
                <NavLink to="/learningpaths" className={`${user.user_type == 'learner' ? 'd-none': name == 'Learning Paths' || name == 'Learning Path Builder' || name == 'Learners List' ? 'active' : ''}`} exact >
                <MenuItem className="learning-path-menu" icon={<LearningPathIcon />}>Learning Paths</MenuItem>
              </NavLink>
              )}
              
              <NavLink
                to="/coursemanagement"
                className={`${user.user_type == "learner" ? "d-none" :  name == 'Course Library' ? 'active' : ''}`}
                exact
              >
                <MenuItem className="course-library-menu"  icon={<CourseManagementIcon />}>
                  Course Library
                </MenuItem>
              </NavLink>
              {!hideLpQuizes && (
              <NavLink to="/quizmanagement" className={`${user.user_type == 'learner' ? 'd-none':  name == 'Quiz Library' ? 'active' : ''}`} exact >
                      <MenuItem className="quiz-library-menu" icon={<QuizManagementIcon />}>Quiz Library</MenuItem>
              </NavLink>
               )}
              <NavLink
                to="/team"
                className={`${user.user_type == "learner" ? "d-none" : name == 'Member Details' || name == 'Group Details' || name == 'Class Details' ? 'active' : '' } `}
                exact
              >
                <MenuItem className="teams-menu"  icon={<TeamIcon />}>Teams</MenuItem>
              </NavLink>
             {/*<NavLink to="/comingsoon/learningpath" exact >*/}
              {/*  <MenuItem icon={<LearningPathManagementIcon />}>Learning Path Management</MenuItem></NavLink>*/}
              <NavLink
                to="/surveymanagement"
                className={`${user.user_type == "learner" ? "d-none" :  name == 'Create Survey' || name == 'Survey Reports' || name == 'Survey Library' ? 'active' : ''}`}
                exact
              >

                <MenuItem className="survey-library-menu" icon={<SurveyManagementIcon />}>
                  Survey Library
                </MenuItem>
              </NavLink>
            
              {/*<NavLink to="/comingsoon/quizmanagement" exact >*/}
              {/*  <MenuItem icon={<QuizManagementIcon />}>Quiz Management</MenuItem>*/}
              {/*</NavLink>*/}
              

              

              {/* <NavLink
               to="/surveyreports"
               className={`${user.user_type == "learner" ? "d-none" : ""}`}
               exact
               >
                <MenuItem icon={<ReportsIcon />}>Survey Reports</MenuItem>
              </NavLink> */}
              <div
                className={`${
                  user.user_type == "learner" ? "d-none" : "side-link-seperator"
                }`}
              >
                &nbsp;
              </div>
              <NavLink to="/institution_contact" className={`${user.user_type !== 'learner' && user.user_type !== 'staff_manager' ? 'd-none': name == 'InstitutionContact' ? 'active':''}`} exact >
                <MenuItem className="institution-contact-menu" icon={<InstitutionContactIcon />}>Institution Contact</MenuItem>
              </NavLink>
              <NavLink
                to="/settings"
                exact
                className={`${user.user_type == "learner"  || user.user_type == "staff_manager" ? "d-none" : ""}`}
              >
                <MenuItem className="settings-menu"  icon={<SettingsIcon />}>Settings</MenuItem>
              </NavLink>
              <NavLink
                to="/help"
                exact
                className={`${user.user_type == "learner" || user.user_type == "staff_manager"  || user.user_type == "admin" ? "d-none" : ""}`}
              >
                <MenuItem className="help-menu" icon={<SupportIcon />}>Help</MenuItem>
              </NavLink>
            </Menu>
          </SidebarContent>
        </ScrollArea>
      </ProSidebar>
      <Steps enabled={stepEnabled} steps={user.user_type == "learner" ? stepsleaner : user.user_type == "manager" ?  steps : user.user_type == "staff_manager" ? steps_staffmanager : stepsadmin} initialStep={0} onExit={onExit} showBullets={false}  />
    </div>
  );
};
export default Sidebar;
