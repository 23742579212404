
import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GenderGraph from './components/gendergraph';
import BarGraph from './components/bargraph';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
function Demographics(props) {

    const { demographicQuestions,surveyId } = props


    return (
        <div className="whitebox padding-box radiusbox">
            <div className="tab-wrapper">
                <Tabs defaultActiveKey={0} id="uncontrolled-tab-example" className="m-b-30">
                    <p>Individual responses can be viewed in these interactive graphics. Select the desired variable with which to view. </p>
                    {demographicQuestions?.length && demographicQuestions?.map((item, index) => {

                        if((item?.question_category?.includes("Ethnicity"))||item?.question_category?.includes("ethnicity")){
                            return (<Tab eventKey={index} title="Ethnicity">
                                <BarGraph type="ethnicity" demoQuestion={item} questionId={item?.question_id} surveyId={surveyId} />
                            </Tab>
                            )

                        } else if((item?.question_category?.includes("Gender")||item?.question_category?.includes("gender")) ){
                            return (<Tab eventKey={index} title="Gender">
                                <GenderGraph   demoQuestion={item} questionId={item?.question_id} surveyId={surveyId}/>
                            </Tab>
                            )
                        } else if( (item?.question_category?.includes("Age") ||item?.question_category?.includes("age")) ){
                            return (
                                <Tab eventKey={index} title="Age">
                                     <BarGraph type="age" demoQuestion={item} questionId={item?.question_id} surveyId={surveyId} />                            
                                </Tab>
                            )
                        } else if (item?.question?.length) {
                            return (
                                <Tab eventKey={index} title= { item?.title? item?.title :`Question${index+1}`}>
                                     <BarGraph type={ item?.title? item?.title:item.question.split(' ').slice(-1)[0] } demoQuestion={item} questionId={item?.question_id} surveyId={surveyId} />
                                </Tab>
                            )
                        }

                    })}
                </Tabs>

            </div>
        </div>
    );
};
export default Demographics;
