import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import FormikControl from "./formikComponents/formikQuizControl";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { submitQuizLearner } from "../../../../services";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import ScrollArea from "react-scrollbar";

const LearnerQuiz = (props) => {
  const dispatch = useDispatch();
  const { value, index, length } = props;
  const loading = useSelector(
    (state) => state?.learningpath_learner?.learner_submit_quiz?.loading
  );
  const [state, setState] = useState({
    initialValues: {},
    show: false,
    result: {},
  });

  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    let shape = {};
    if (value?.questions?.length) {
      value.questions = value.questions.map((el) => {
        return {
          ...el,
          question: el.question.charAt(el.question.length - 4)
            ? el.question.toString().split(".").join("")
            : el.question,
        };
      });
    }
    for (let i = 0; i < value?.questions?.length; i++) {
      shape[value?.questions[i]?.question] = "";
    }
    shape.questions = [];

    setState({ ...state, show: false, initialValues: shape });
    setActiveIndex(0);
    formikRef?.current?.setErrors({});
  }, [value]);

  const formikRef = useRef(null);

  let shape = {};
  for (let i = 0; i < value?.questions?.length; i++) {
    shape[value?.questions[i]?.question] = Yup.string().required("required");
  }
  const schema = Yup.object().shape(shape);
  const handleSubmit = async (values) => {

    let tempObj = { questions: values?.questions };

    dispatch(
      submitQuizLearner(tempObj, value.id, function (res) {
        if (res) {
          setState({ ...state, result: res?.data, show: true });
          props.parentQuizCallback(true);
        }
      })
    );
  };
  const handleNext = async () => {
    formikRef?.current?.setFieldTouched("questions");
    setTimeout(() => {
   
      let keys = Object?.keys(
        formikRef?.current?.errors ? formikRef?.current?.errors : ""
      );
      if (
        keys &&
        keys?.length &&
        keys?.includes(value?.questions[activeIndex]?.question)
      ) {
        return false;
      } else {
        formikRef?.current?.setErrors({});
        setActiveIndex(activeIndex + 1);
      }
    }, 100);
  };
  const handlePrevious = async () => {
    setActiveIndex(activeIndex - 1);
  };

  const handleRetry = () => {
    {
      setState({ ...state, show: false });
      setActiveIndex(0);
    }
  };

  if (!state.show) {
    return (
      <>
        {loading && loading ? (
          <div>
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <Formik
            innerRef={formikRef}
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={state.initialValues}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setValues,
              touched,
            }) => {
              return (
                <Form>
                  <div className="videomodule">
                    <div className="module-count-learnerpop mr-4">
                      <p>
                        {" "}
                        Question : {activeIndex + 1} /{" "}
                        {value?.questions?.length}
                      </p>
                    </div>
                    <ScrollArea
                      speed={0.8}
                      className="modulemodalscroll"
                      contentClassName="content"
                      horizontal={false}
                    >
                      <div className="video-content-wrap">
                        <div className="module-content">
                          <div className="whitebox shadow-box single-qstn-containerbox">
                            <div className="radio-block-main-wrap">

                              {/*{JSON.stringify(errors)}*/}
                              <div className="multiple-choise-section-box">
                                <FormikControl
                                  quesIndex={activeIndex}
                                  control={
                                    value?.questions[activeIndex]?.question_type
                                      ? value?.questions[activeIndex]
                                          ?.question_type
                                      : "No luck"
                                  }
                                  name={value?.questions[activeIndex]?.question}
                                  errorName={
                                    value?.questions[activeIndex]?.question
                                  }
                                  options={
                                    value?.questions[activeIndex]?.question_type
                                      ?.question_type == "fill_in_the_blanks"
                                      ? value?.questions[activeIndex]
                                          ?.fill_in_the_blanks?.answer
                                      : value?.questions[
                                          activeIndex
                                        ]?.multiple_choices?.filter(
                                          (el) => el?.choice?.length > 0
                                        )
                                  }
                                  errors={errors}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  setValues={setValues}
                                  questionId={value?.questions[activeIndex]?.id}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            {activeIndex + 1 == value?.questions?.length ? (
                              <div className="button-wrap double-btn d-flex justify-content-end m-t-20 ml-auto ">
                                <Button
                                  variant="primary"
                                  className="btn-m"
                                  disabled={activeIndex == 0}
                                  onClick={handlePrevious}
                                >
                                  Previous Question
                                </Button>
                                <Button
                                  variant="primary"
                                  className="btn-m"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            ) : (
                              <>
                                <div className="button-wrap double-btn d-flex justify-content-end m-t-20  ml-auto pr-4">
                                  <Button
                                    variant="primary"
                                    className="btn-m"
                                    disabled={activeIndex == 0}
                                    onClick={handlePrevious}
                                  >
                                    Previous Question
                                  </Button>
                                </div>
                                <div className="button-wrap double-btn d-flex justify-content-end m-t-20 ">
                                  <Button
                                    variant="primary"
                                    className="btn-m"
                                    disabled={
                                      activeIndex + 1 ==
                                      value?.questions?.length
                                    }
                                    onClick={handleNext}
                                  >
                                    Next Question
                                  </Button>{" "}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </ScrollArea>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </>
    );
  } else {
    return (
      <>

        <div className="score-card">
          <div className="quiz-completed-img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="257.102"
              height="264.308"
              viewBox="0 0 257.102 264.308"
            >
              <g
                id="_4529819"
                data-name="4529819"
                transform="translate(-48 -49.496)"
              >
                <g
                  id="Background_Complete"
                  transform="translate(49.035 88.825)"
                >
                  <g
                    id="Group_7948"
                    data-name="Group 7948"
                    transform="translate(167.721)"
                  >
                    <g id="Group_7929" data-name="Group 7929">
                      <g id="Group_7920" data-name="Group 7920">
                        <g id="Group_7919" data-name="Group 7919">
                          <g id="Group_7918" data-name="Group 7918">
                            <g id="Group_7917" data-name="Group 7917">
                              <path
                                id="Path_8676"
                                data-name="Path 8676"
                                d="M363.466,214.941,317.2,197.44a6.2,6.2,0,0,1-3.627-7.955L337.82,125.4a6.2,6.2,0,0,1,7.955-3.627l29.338,11.073,13.873,32.01-17.564,46.457A6.1,6.1,0,0,1,363.466,214.941Z"
                                transform="translate(-313.177 -121.377)"
                                fill="#f5f5f5"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_7924"
                        data-name="Group 7924"
                        transform="translate(54.809 11.533)"
                      >
                        <g id="Group_7923" data-name="Group 7923">
                          <g id="Group_7922" data-name="Group 7922">
                            <g id="Group_7921" data-name="Group 7921">
                              <path
                                id="Path_8677"
                                data-name="Path 8677"
                                d="M406.492,139.5l-6.809,18.01a5.766,5.766,0,0,0,3.373,7.446l17.31,6.555Z"
                                transform="translate(-399.301 -139.5)"
                                fill="#ebebeb"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_7928"
                        data-name="Group 7928"
                        transform="translate(54.401 31.261)"
                      >
                        <g id="Group_7927" data-name="Group 7927">
                          <g id="Group_7926" data-name="Group 7926">
                            <g id="Group_7925" data-name="Group 7925">
                              <path
                                id="Path_8678"
                                data-name="Path 8678"
                                d="M399.068,170.5s-1.909,4.264,2.546,7.637a2.232,2.232,0,0,0,.509.318c2.1,1.273,16.037,9.546,16.164,9.228.127-.382,1.846-4.837,1.846-4.837l-17.31-6.555C402.759,176.228,398.495,174.446,399.068,170.5Z"
                                transform="translate(-398.661 -170.5)"
                                fill="#e0e0e0"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_7932"
                      data-name="Group 7932"
                      transform="translate(30.115 19.271)"
                    >
                      <g id="Group_7931" data-name="Group 7931">
                        <g id="Group_7930" data-name="Group 7930">
                          <path
                            id="Path_8679"
                            data-name="Path 8679"
                            d="M381.946,159.767c-.064.255-4.964-1.4-10.882-3.627s-10.628-4.264-10.564-4.455c.064-.255,4.964,1.4,10.882,3.627S382.073,159.513,381.946,159.767Z"
                            transform="translate(-360.499 -151.659)"
                            fill="#e0e0e0"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_7935"
                      data-name="Group 7935"
                      transform="translate(26.36 29.463)"
                    >
                      <g id="Group_7934" data-name="Group 7934">
                        <g id="Group_7933" data-name="Group 7933">
                          <path
                            id="Path_8680"
                            data-name="Path 8680"
                            d="M389.792,180.991c-.064.255-8.019-2.546-17.755-6.237s-17.564-6.873-17.437-7.064c.064-.255,8.019,2.546,17.755,6.237C382.028,177.619,389.855,180.8,389.792,180.991Z"
                            transform="translate(-354.598 -167.675)"
                            fill="#e0e0e0"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_7938"
                      data-name="Group 7938"
                      transform="translate(23.114 37.929)"
                    >
                      <g id="Group_7937" data-name="Group 7937">
                        <g id="Group_7936" data-name="Group 7936">
                          <path
                            id="Path_8681"
                            data-name="Path 8681"
                            d="M387.874,195.565c-.064.255-8.782-2.864-19.346-6.873-10.628-4.009-19.155-7.446-19.028-7.7.064-.255,8.782,2.864,19.346,6.873C379.473,191.874,388,195.31,387.874,195.565Z"
                            transform="translate(-349.498 -180.977)"
                            fill="#e0e0e0"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_7941"
                      data-name="Group 7941"
                      transform="translate(20.06 45.948)"
                    >
                      <g id="Group_7940" data-name="Group 7940">
                        <g id="Group_7939" data-name="Group 7939">
                          <path
                            id="Path_8682"
                            data-name="Path 8682"
                            d="M383.074,208.165c-.064.255-8.782-2.864-19.346-6.873-10.628-4.009-19.155-7.446-19.028-7.7.064-.255,8.782,2.864,19.346,6.873C374.673,204.474,383.2,207.911,383.074,208.165Z"
                            transform="translate(-344.698 -193.577)"
                            fill="#e0e0e0"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_7944"
                      data-name="Group 7944"
                      transform="translate(17.069 53.966)"
                    >
                      <g id="Group_7943" data-name="Group 7943">
                        <g id="Group_7942" data-name="Group 7942">
                          <path
                            id="Path_8683"
                            data-name="Path 8683"
                            d="M378.374,220.765c-.064.255-8.782-2.864-19.346-6.873-10.628-4.009-19.155-7.446-19.028-7.7.064-.255,8.782,2.864,19.346,6.873C369.91,217.138,378.437,220.574,378.374,220.765Z"
                            transform="translate(-339.998 -206.177)"
                            fill="#e0e0e0"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_7947"
                      data-name="Group 7947"
                      transform="translate(14.014 61.985)"
                    >
                      <g id="Group_7946" data-name="Group 7946">
                        <g id="Group_7945" data-name="Group 7945">
                          <path
                            id="Path_8684"
                            data-name="Path 8684"
                            d="M373.574,233.365c-.064.255-8.782-2.864-19.346-6.873-10.628-4.009-19.155-7.446-19.028-7.7.064-.255,8.782,2.864,19.346,6.873C365.11,229.738,373.637,233.174,373.574,233.365Z"
                            transform="translate(-335.198 -218.777)"
                            fill="#e0e0e0"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_8030"
                    data-name="Group 8030"
                    transform="translate(0 26.824)"
                  >
                    <g id="Group_8025" data-name="Group 8025">
                      <g id="Group_7951" data-name="Group 7951">
                        <g id="Group_7950" data-name="Group 7950">
                          <g id="Group_7949" data-name="Group 7949">
                            <path
                              id="Path_8685"
                              data-name="Path 8685"
                              d="M146.659,258.841,85.63,280.224a4.045,4.045,0,0,1-5.155-2.482L49.864,190.3a4.045,4.045,0,0,1,2.482-5.155l61.03-21.383a4.045,4.045,0,0,1,5.155,2.482l30.61,87.44A4.072,4.072,0,0,1,146.659,258.841Z"
                              transform="translate(-49.627 -163.527)"
                              fill="#ebebeb"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8024"
                        data-name="Group 8024"
                        transform="translate(4.374 2.594)"
                      >
                        <g
                          id="Group_8019"
                          data-name="Group 8019"
                          transform="translate(0 2.671)"
                        >
                          <g
                            id="Group_7953"
                            data-name="Group 7953"
                            transform="translate(1.104 0.45)"
                          >
                            <g
                              id="Group_7952"
                              data-name="Group 7952"
                              transform="translate(0 0)"
                            >
                              <rect
                                id="Rectangle_3317"
                                data-name="Rectangle 3317"
                                width="62.557"
                                height="91.831"
                                transform="translate(0 20.675) rotate(-19.299)"
                                fill="#e0e0e0"
                              />
                            </g>
                          </g>
                          <g id="Group_7959" data-name="Group 7959">
                            <g
                              id="Group_7955"
                              data-name="Group 7955"
                              transform="translate(0.049 0.017)"
                            >
                              <g
                                id="Group_7954"
                                data-name="Group 7954"
                                transform="translate(0 0)"
                              >
                                <rect
                                  id="Rectangle_3318"
                                  data-name="Rectangle 3318"
                                  width="62.557"
                                  height="90.049"
                                  transform="translate(0 20.675) rotate(-19.299)"
                                  fill="#fafafa"
                                />
                              </g>
                            </g>
                            <g id="Group_7958" data-name="Group 7958">
                              <g id="Group_7957" data-name="Group 7957">
                                <g id="Group_7956" data-name="Group 7956">
                                  <path
                                    id="Path_8686"
                                    data-name="Path 8686"
                                    d="M145.34,256.822c-.255-.7-12.219-34.811-29.847-84.958l.127.064c-17.883,6.3-37.929,13.3-58.993,20.683h0l.064-.127C67.828,224.43,78.2,253.894,86.41,277.5l-.064-.064c35.829-12.537,58.421-20.428,58.993-20.619-.573.191-23.1,8.146-58.993,20.746h-.064V277.5C78.01,253.894,67.7,224.43,56.5,192.546v-.064h.064C77.628,185.1,97.674,178.1,115.557,171.8h.064v.064C133.185,221.948,145.086,256.122,145.34,256.822Z"
                                    transform="translate(-56.5 -171.8)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                          <g
                            id="Group_7962"
                            data-name="Group 7962"
                            transform="translate(67.903 69.685)"
                          >
                            <g id="Group_7961" data-name="Group 7961">
                              <g id="Group_7960" data-name="Group 7960">
                                <path
                                  id="Path_8687"
                                  data-name="Path 8687"
                                  d="M163.2,303.956s13.173-11.073,15.4-22.656a16.549,16.549,0,0,1-11.264,6.746C167.4,288.109,167.146,300.2,163.2,303.956Z"
                                  transform="translate(-163.2 -281.3)"
                                  fill="#e0e0e0"
                                />
                              </g>
                            </g>
                          </g>
                          <g
                            id="Group_7976"
                            data-name="Group 7976"
                            transform="translate(15.665 26.473)"
                          >
                            <g
                              id="Group_7964"
                              data-name="Group 7964"
                              transform="translate(0 12.993)"
                            >
                              <g id="Group_7963" data-name="Group 7963">
                                <path
                                  id="Path_8688"
                                  data-name="Path 8688"
                                  d="M88.233,241.188l-3.118,1.082a2.225,2.225,0,0,1-2.8-1.336l-1.082-3.118a2.225,2.225,0,0,1,1.336-2.8l3.118-1.082a2.225,2.225,0,0,1,2.8,1.336l1.082,3.118A2.254,2.254,0,0,1,88.233,241.188Z"
                                  transform="translate(-81.115 -233.815)"
                                  fill="#ebebeb"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_7966"
                              data-name="Group 7966"
                              transform="translate(2.573 14.739)"
                            >
                              <g id="Group_7965" data-name="Group 7965">
                                <path
                                  id="Path_8689"
                                  data-name="Path 8689"
                                  d="M86.776,240.34h-.127l-1.4-.827c-.064-.064-.127-.127-.064-.255.064-.064.127-.127.255-.064l1.273.7,1.527-3.246c.064-.064.127-.127.255-.064.064.064.127.127.064.255l-1.591,3.373C86.84,240.276,86.84,240.34,86.776,240.34Z"
                                  transform="translate(-85.159 -236.559)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_7969"
                              data-name="Group 7969"
                              transform="translate(10.618)"
                            >
                              <g id="Group_7968" data-name="Group 7968">
                                <g id="Group_7967" data-name="Group 7967">
                                  <path
                                    id="Path_8690"
                                    data-name="Path 8690"
                                    d="M132.1,213.4c0,.064-7.637,2.8-17.119,6.109S97.8,225.491,97.8,225.427s7.637-2.8,17.119-6.109S132.1,213.336,132.1,213.4Z"
                                    transform="translate(-97.8 -213.398)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_7972"
                              data-name="Group 7972"
                              transform="translate(12.527 12.22)"
                            >
                              <g id="Group_7971" data-name="Group 7971">
                                <g id="Group_7970" data-name="Group 7970">
                                  <path
                                    id="Path_8691"
                                    data-name="Path 8691"
                                    d="M115.182,232.6a5,5,0,0,0-.573.255q-.573.191-1.527.573c-1.273.445-3.118,1.145-5.091,1.846s-3.818,1.336-5.091,1.782c-.636.191-1.146.382-1.527.509l-.573.191a5.007,5.007,0,0,0,.573-.255q.573-.191,1.527-.573c1.273-.445,3.118-1.146,5.091-1.846s3.818-1.336,5.091-1.782c.636-.191,1.146-.382,1.527-.509Z"
                                    transform="translate(-100.8 -232.6)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_7975"
                              data-name="Group 7975"
                              transform="translate(11.446 2.355)"
                            >
                              <g id="Group_7974" data-name="Group 7974">
                                <g id="Group_7973" data-name="Group 7973">
                                  <path
                                    id="Path_8692"
                                    data-name="Path 8692"
                                    d="M133.4,217.1c0,.064-7.637,2.8-17.119,6.109S99.1,229.191,99.1,229.127s7.637-2.8,17.119-6.109S133.4,217.036,133.4,217.1Z"
                                    transform="translate(-99.1 -217.098)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                          <g
                            id="Group_7990"
                            data-name="Group 7990"
                            transform="translate(20.056 39.137)"
                          >
                            <g
                              id="Group_7978"
                              data-name="Group 7978"
                              transform="translate(0 12.993)"
                            >
                              <g id="Group_7977" data-name="Group 7977">
                                <path
                                  id="Path_8693"
                                  data-name="Path 8693"
                                  d="M95.133,261.088l-3.118,1.082a2.225,2.225,0,0,1-2.8-1.336l-1.082-3.118a2.225,2.225,0,0,1,1.336-2.8l3.118-1.082a2.225,2.225,0,0,1,2.8,1.336l1.082,3.118A2.2,2.2,0,0,1,95.133,261.088Z"
                                  transform="translate(-88.015 -253.715)"
                                  fill="#ebebeb"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_7980"
                              data-name="Group 7980"
                              transform="translate(2.637 14.739)"
                            >
                              <g id="Group_7979" data-name="Group 7979">
                                <path
                                  id="Path_8694"
                                  data-name="Path 8694"
                                  d="M93.776,260.24h-.127l-1.4-.827c-.064-.064-.127-.127-.064-.255.064-.064.127-.127.255-.064l1.273.7,1.527-3.246c.064-.064.127-.127.255-.064.064.064.127.127.064.255l-1.591,3.373A.788.788,0,0,0,93.776,260.24Z"
                                  transform="translate(-92.159 -256.459)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_7983"
                              data-name="Group 7983"
                              transform="translate(10.682)"
                            >
                              <g id="Group_7982" data-name="Group 7982">
                                <g id="Group_7981" data-name="Group 7981">
                                  <path
                                    id="Path_8695"
                                    data-name="Path 8695"
                                    d="M139.1,233.3c0,.064-7.637,2.8-17.119,6.109s-17.183,5.982-17.183,5.918,7.637-2.8,17.119-6.109S139.1,233.236,139.1,233.3Z"
                                    transform="translate(-104.8 -233.298)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_7986"
                              data-name="Group 7986"
                              transform="translate(12.591 12.22)"
                            >
                              <g id="Group_7985" data-name="Group 7985">
                                <g id="Group_7984" data-name="Group 7984">
                                  <path
                                    id="Path_8696"
                                    data-name="Path 8696"
                                    d="M122.182,252.5a5,5,0,0,0-.573.255q-.573.191-1.527.573c-1.273.445-3.118,1.146-5.091,1.846s-3.818,1.336-5.091,1.782c-.636.191-1.146.382-1.527.509l-.573.191a5.006,5.006,0,0,0,.573-.255q.573-.191,1.527-.573c1.273-.445,3.118-1.145,5.091-1.846s3.818-1.336,5.091-1.782c.636-.191,1.145-.382,1.527-.509Z"
                                    transform="translate(-107.8 -252.5)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_7989"
                              data-name="Group 7989"
                              transform="translate(11.509 2.355)"
                            >
                              <g id="Group_7988" data-name="Group 7988">
                                <g id="Group_7987" data-name="Group 7987">
                                  <path
                                    id="Path_8697"
                                    data-name="Path 8697"
                                    d="M140.4,237c0,.064-7.637,2.8-17.119,6.109s-17.183,5.982-17.183,5.918,7.637-2.8,17.119-6.109S140.4,236.936,140.4,237Z"
                                    transform="translate(-106.1 -236.998)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                          <g
                            id="Group_8004"
                            data-name="Group 8004"
                            transform="translate(24.511 51.801)"
                          >
                            <g
                              id="Group_7992"
                              data-name="Group 7992"
                              transform="translate(0 12.993)"
                            >
                              <g id="Group_7991" data-name="Group 7991">
                                <path
                                  id="Path_8698"
                                  data-name="Path 8698"
                                  d="M102.133,280.988l-3.118,1.082a2.225,2.225,0,0,1-2.8-1.336l-1.082-3.118a2.225,2.225,0,0,1,1.336-2.8l3.118-1.082a2.225,2.225,0,0,1,2.8,1.336l1.082,3.118A2.2,2.2,0,0,1,102.133,280.988Z"
                                  transform="translate(-95.015 -273.615)"
                                  fill="#ebebeb"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_7994"
                              data-name="Group 7994"
                              transform="translate(2.573 14.739)"
                            >
                              <g id="Group_7993" data-name="Group 7993">
                                <path
                                  id="Path_8699"
                                  data-name="Path 8699"
                                  d="M100.676,280.14h-.127l-1.4-.827c-.064-.064-.127-.127-.064-.255.064-.064.127-.127.255-.064l1.273.7,1.527-3.246c.064-.064.127-.127.255-.064.064.064.127.127.064.255l-1.591,3.373C100.8,280.076,100.74,280.14,100.676,280.14Z"
                                  transform="translate(-99.059 -276.359)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_7997"
                              data-name="Group 7997"
                              transform="translate(10.682)"
                            >
                              <g id="Group_7996" data-name="Group 7996">
                                <g id="Group_7995" data-name="Group 7995">
                                  <path
                                    id="Path_8700"
                                    data-name="Path 8700"
                                    d="M146.1,253.2c0,.064-7.637,2.8-17.119,6.109s-17.183,5.982-17.183,5.918,7.637-2.8,17.119-6.109S146.038,253.136,146.1,253.2Z"
                                    transform="translate(-111.8 -253.198)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8000"
                              data-name="Group 8000"
                              transform="translate(12.591 12.22)"
                            >
                              <g id="Group_7999" data-name="Group 7999">
                                <g id="Group_7998" data-name="Group 7998">
                                  <path
                                    id="Path_8701"
                                    data-name="Path 8701"
                                    d="M129.182,272.4a5,5,0,0,0-.573.255q-.573.191-1.527.573c-1.273.445-3.118,1.146-5.091,1.846s-3.818,1.336-5.091,1.782c-.636.191-1.145.382-1.527.509l-.573.191a5.007,5.007,0,0,0,.573-.255q.573-.191,1.527-.573c1.273-.445,3.118-1.146,5.091-1.846s3.818-1.336,5.091-1.782c.636-.191,1.146-.382,1.527-.509Z"
                                    transform="translate(-114.8 -272.4)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8003"
                              data-name="Group 8003"
                              transform="translate(11.509 2.355)"
                            >
                              <g id="Group_8002" data-name="Group 8002">
                                <g id="Group_8001" data-name="Group 8001">
                                  <path
                                    id="Path_8702"
                                    data-name="Path 8702"
                                    d="M147.4,256.9c0,.064-7.637,2.8-17.119,6.109s-17.183,5.982-17.183,5.918,7.637-2.8,17.119-6.109C139.637,259.509,147.338,256.836,147.4,256.9Z"
                                    transform="translate(-113.1 -256.898)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                          <g
                            id="Group_8018"
                            data-name="Group 8018"
                            transform="translate(28.965 64.465)"
                          >
                            <g
                              id="Group_8006"
                              data-name="Group 8006"
                              transform="translate(0 12.993)"
                            >
                              <g id="Group_8005" data-name="Group 8005">
                                <path
                                  id="Path_8703"
                                  data-name="Path 8703"
                                  d="M109.133,300.888l-3.118,1.082a2.225,2.225,0,0,1-2.8-1.336l-1.082-3.118a2.225,2.225,0,0,1,1.336-2.8l3.118-1.082a2.225,2.225,0,0,1,2.8,1.336l1.082,3.118A2.254,2.254,0,0,1,109.133,300.888Z"
                                  transform="translate(-102.015 -293.515)"
                                  fill="#ebebeb"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_8008"
                              data-name="Group 8008"
                              transform="translate(2.573 14.739)"
                            >
                              <g id="Group_8007" data-name="Group 8007">
                                <path
                                  id="Path_8704"
                                  data-name="Path 8704"
                                  d="M107.676,300.04h-.127l-1.4-.827c-.064-.064-.127-.127-.064-.255.064-.064.127-.127.255-.064l1.273.7,1.527-3.246c.064-.064.127-.127.255-.064.064.064.127.127.064.255l-1.591,3.373C107.74,299.976,107.74,300.04,107.676,300.04Z"
                                  transform="translate(-106.059 -296.259)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_8011"
                              data-name="Group 8011"
                              transform="translate(10.618)"
                            >
                              <g id="Group_8010" data-name="Group 8010">
                                <g id="Group_8009" data-name="Group 8009">
                                  <path
                                    id="Path_8705"
                                    data-name="Path 8705"
                                    d="M153,273.1c0,.064-7.637,2.8-17.119,6.109s-17.183,5.982-17.183,5.918,7.637-2.8,17.119-6.109S153,273.036,153,273.1Z"
                                    transform="translate(-118.7 -273.098)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8014"
                              data-name="Group 8014"
                              transform="translate(12.591 12.22)"
                            >
                              <g id="Group_8013" data-name="Group 8013">
                                <g id="Group_8012" data-name="Group 8012">
                                  <path
                                    id="Path_8706"
                                    data-name="Path 8706"
                                    d="M136.182,292.3a5,5,0,0,0-.573.255q-.573.191-1.527.573c-1.273.445-3.118,1.145-5.091,1.846s-3.818,1.336-5.091,1.782c-.636.191-1.146.382-1.527.509l-.573.191a5,5,0,0,0,.573-.255q.573-.191,1.527-.573c1.273-.445,3.118-1.146,5.091-1.846s3.818-1.336,5.091-1.782c.636-.191,1.145-.382,1.527-.509A2.659,2.659,0,0,1,136.182,292.3Z"
                                    transform="translate(-121.8 -292.3)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8017"
                              data-name="Group 8017"
                              transform="translate(11.446 2.355)"
                            >
                              <g id="Group_8016" data-name="Group 8016">
                                <g id="Group_8015" data-name="Group 8015">
                                  <path
                                    id="Path_8707"
                                    data-name="Path 8707"
                                    d="M154.3,276.8c0,.064-7.637,2.8-17.119,6.109S120,288.891,120,288.827s7.637-2.8,17.119-6.109S154.3,276.736,154.3,276.8Z"
                                    transform="translate(-120 -276.798)"
                                    fill="#e0e0e0"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8021"
                          data-name="Group 8021"
                          transform="translate(6.746 0.38)"
                        >
                          <g id="Group_8020" data-name="Group 8020">
                            <path
                              id="Path_8708"
                              data-name="Path 8708"
                              d="M67.1,182.9l1.527,4.455a4.947,4.947,0,0,0,6.3,3.055l32.647-11.455a4.947,4.947,0,0,0,3.055-6.3L109.1,168.2Z"
                              transform="translate(-67.1 -168.2)"
                              fill="#e0e0e0"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8023"
                          data-name="Group 8023"
                          transform="translate(22.021)"
                        >
                          <g id="Group_8022" data-name="Group 8022">
                            <path
                              id="Path_8709"
                              data-name="Path 8709"
                              d="M97.274,176.638a4.649,4.649,0,1,1,2.864-5.918A4.664,4.664,0,0,1,97.274,176.638Zm-2.482-7.064a2.858,2.858,0,1,0,3.627,1.782A2.843,2.843,0,0,0,94.792,169.574Z"
                              transform="translate(-91.104 -167.604)"
                              fill="#e0e0e0"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_8027"
                      data-name="Group 8027"
                      transform="translate(20.093 23.207)"
                    >
                      <g id="Group_8026" data-name="Group 8026">
                        <path
                          id="Path_8710"
                          data-name="Path 8710"
                          d="M119.765,200c0,.064-8.591,3.182-19.219,6.873-10.628,3.755-19.283,6.682-19.346,6.618,0-.064,8.591-3.182,19.219-6.873C111.047,202.862,119.7,199.87,119.765,200Z"
                          transform="translate(-81.2 -199.994)"
                          fill="#e0e0e0"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_8029"
                      data-name="Group 8029"
                      transform="translate(30.53 24.736)"
                    >
                      <g id="Group_8028" data-name="Group 8028">
                        <path
                          id="Path_8711"
                          data-name="Path 8711"
                          d="M114.655,202.4c0,.064-3.755,1.464-8.464,3.118a88.79,88.79,0,0,1-8.591,2.8c0-.064,3.755-1.464,8.464-3.118C110.837,203.608,114.655,202.335,114.655,202.4Z"
                          transform="translate(-97.6 -202.396)"
                          fill="#e0e0e0"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Plants" transform="translate(69.701 238.519)">
                  <g
                    id="Group_8199"
                    data-name="Group 8199"
                    transform="translate(0)"
                  >
                    <g
                      id="Group_8077"
                      data-name="Group 8077"
                      transform="translate(171.446 0.096)"
                    >
                      <g
                        id="Group_8052"
                        data-name="Group 8052"
                        transform="translate(0)"
                      >
                        <g
                          id="Group_8033"
                          data-name="Group 8033"
                          transform="translate(22.363 29.643)"
                        >
                          <g id="Group_8032" data-name="Group 8032">
                            <g id="Group_8031" data-name="Group 8031">
                              <path
                                id="Path_8712"
                                data-name="Path 8712"
                                d="M389.416,404.456a11.707,11.707,0,0,1,7.509-.891,6.367,6.367,0,0,1,3.691,1.655,2.673,2.673,0,0,1,.064,3.691,3.726,3.726,0,0,1-2.355.573c-1.973,0-4.2-.382-5.791.827-.891.7-1.527,1.846-2.609,2.164a2.559,2.559,0,0,1-2.8-1.273,4.4,4.4,0,0,1-.318-3.246A4.744,4.744,0,0,1,389.416,404.456Z"
                                transform="translate(-386.644 -403.33)"
                                fill="#ce002a"
                              />
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8036"
                          data-name="Group 8036"
                          transform="translate(18.454)"
                        >
                          <g id="Group_8035" data-name="Group 8035">
                            <g id="Group_8034" data-name="Group 8034">
                              <path
                                id="Path_8713"
                                data-name="Path 8713"
                                d="M381.9,377.4c1.4,1.973,4.137,1.336,5.346.127,1.273-1.209,1.782-2.991,2.291-4.646,1.082-3.818,2.227-7.891,1.4-11.773a6.471,6.471,0,0,0-1.527-3.182,3.36,3.36,0,0,0-3.246-1.082c-1.4.382-2.227,1.718-2.864,2.991a29.269,29.269,0,0,0-2.8,12.091,10.332,10.332,0,0,0,1.4,5.473"
                                transform="translate(-380.5 -356.75)"
                                fill="#ce002a"
                              />
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8039"
                          data-name="Group 8039"
                          transform="translate(0 6.322)"
                        >
                          <g id="Group_8038" data-name="Group 8038">
                            <g id="Group_8037" data-name="Group 8037">
                              <path
                                id="Path_8714"
                                data-name="Path 8714"
                                d="M367.538,383.877a7.353,7.353,0,0,0,1.146-6.682,13.281,13.281,0,0,0-3.946-5.728,21.468,21.468,0,0,0-7.064-4.2,7.832,7.832,0,0,0-3.182-.573,3.4,3.4,0,0,0-2.673,1.591c-.764,1.4,0,3.118.764,4.582a52.009,52.009,0,0,0,4.646,7.064,12.988,12.988,0,0,0,5.091,4.391c2.1.827,4.327.7,5.473-.764"
                                transform="translate(-351.503 -366.685)"
                                fill="#ce002a"
                              />
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8043"
                          data-name="Group 8043"
                          transform="translate(19.726 32.487)"
                        >
                          <g id="Group_8042" data-name="Group 8042">
                            <g id="Group_8041" data-name="Group 8041">
                              <g id="Group_8040" data-name="Group 8040">
                                <path
                                  id="Path_8715"
                                  data-name="Path 8715"
                                  d="M382.564,426.064s.064-.318.127-.891c.064-.636.127-1.464.255-2.482a27.691,27.691,0,0,1,1.909-7.891,16.183,16.183,0,0,1,2.164-3.882,8.083,8.083,0,0,1,2.736-2.291,7.707,7.707,0,0,1,2.355-.7c.573-.064.891-.127.891-.127h-.891a7.565,7.565,0,0,0-2.418.636,8.586,8.586,0,0,0-2.927,2.291,16.905,16.905,0,0,0-2.291,3.946A36.557,36.557,0,0,0,383.136,419c-.255,1.4-.382,2.609-.509,3.691-.064,1.082-.127,1.909-.127,2.482S382.5,426.064,382.564,426.064Z"
                                  transform="translate(-382.5 -407.8)"
                                  fill="#263238"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8047"
                          data-name="Group 8047"
                          transform="translate(19.663 7.923)"
                        >
                          <g id="Group_8046" data-name="Group 8046">
                            <g id="Group_8045" data-name="Group 8045">
                              <g id="Group_8044" data-name="Group 8044">
                                <path
                                  id="Path_8716"
                                  data-name="Path 8716"
                                  d="M382.655,410.12v-1.655c0-1.018,0-2.546.064-4.391.064-3.755.445-7.764,1.018-13.428.573-5.346,1.4-11.519,2.227-15.528.191-.891.382-1.718.509-2.482.191-.7.318-1.336.445-1.846.127-.445.191-.891.255-1.145a1.68,1.68,0,0,0,.064-.446,2.053,2.053,0,0,0-.127.382c-.064.318-.191.7-.318,1.146-.127.509-.318,1.145-.509,1.845s-.382,1.527-.573,2.482a137.057,137.057,0,0,0-2.355,14.446c-.636,5.664-.891,10.819-.955,14.573,0,1.846,0,3.373.064,4.455,0,.509.064.891.064,1.209A.985.985,0,0,1,382.655,410.12Z"
                                  transform="translate(-382.4 -369.2)"
                                  fill="#263238"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8051"
                          data-name="Group 8051"
                          transform="translate(6.551 10.977)"
                        >
                          <g id="Group_8050" data-name="Group 8050">
                            <g id="Group_8049" data-name="Group 8049">
                              <g id="Group_8048" data-name="Group 8048">
                                <path
                                  id="Path_8717"
                                  data-name="Path 8717"
                                  d="M375.227,401.938v-1.273a32.727,32.727,0,0,0-.382-3.437c-.255-1.4-.573-3.118-1.082-4.964a41.115,41.115,0,0,0-2.1-5.855,30.885,30.885,0,0,0-6.109-9.482,9.292,9.292,0,0,0-1.464-1.336,4.46,4.46,0,0,0-.636-.509l-.573-.382c-.7-.445-1.018-.7-1.082-.7a34.87,34.87,0,0,1,3.5,3.118,31.611,31.611,0,0,1,5.918,9.482,56.236,56.236,0,0,1,2.1,5.791c.573,1.846.891,3.5,1.209,4.9.255,1.4.382,2.546.509,3.373A7.1,7.1,0,0,0,375.227,401.938Z"
                                  transform="translate(-361.797 -374)"
                                  fill="#263238"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8055"
                        data-name="Group 8055"
                        transform="translate(10.308 56.479)"
                      >
                        <g id="Group_8054" data-name="Group 8054">
                          <g id="Group_8053" data-name="Group 8053">
                            <path
                              id="Path_8718"
                              data-name="Path 8718"
                              d="M367.7,445.5l1.018,13.682h17.564L387.3,445.5Z"
                              transform="translate(-367.7 -445.5)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8058"
                        data-name="Group 8058"
                        transform="translate(8.653 51.07)"
                      >
                        <g id="Group_8057" data-name="Group 8057">
                          <g id="Group_8056" data-name="Group 8056">
                            <rect
                              id="Rectangle_3319"
                              data-name="Rectangle 3319"
                              width="23.356"
                              height="5.409"
                              fill="#263238"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8061"
                        data-name="Group 8061"
                        transform="translate(8.653 69.653)"
                      >
                        <g id="Group_8060" data-name="Group 8060">
                          <g id="Group_8059" data-name="Group 8059">
                            <path
                              id="Path_8719"
                              data-name="Path 8719"
                              d="M365.1,466.2v1.464h22.974V466.2Z"
                              transform="translate(-365.1 -466.2)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8064"
                        data-name="Group 8064"
                        transform="translate(9.735 71.116)"
                      >
                        <g id="Group_8063" data-name="Group 8063">
                          <g id="Group_8062" data-name="Group 8062">
                            <path
                              id="Path_8720"
                              data-name="Path 8720"
                              d="M367.882,471.109,366.8,468.5h21.065l-1.273,2.609Z"
                              transform="translate(-366.8 -468.5)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8068"
                        data-name="Group 8068"
                        transform="translate(10.626 59.089)"
                      >
                        <g id="Group_8067" data-name="Group 8067">
                          <g id="Group_8066" data-name="Group 8066">
                            <g id="Group_8065" data-name="Group 8065">
                              <path
                                id="Path_8721"
                                data-name="Path 8721"
                                d="M387.164,451.446a.2.2,0,0,1-.064.127c-.064.064-.127.191-.255.318-.255.318-.573.764-1.018,1.336l-.064.064-.064-.064c-.636-.891-1.527-2.1-2.546-3.5h.127c-.764,1.082-1.591,2.227-2.482,3.5l-.064.127-.064-.127c-.891-1.082-1.909-2.291-2.927-3.5h.191l-.191.191c-.891,1.146-1.782,2.227-2.609,3.309l-.064.127-.064-.127c-.891-1.273-1.718-2.418-2.482-3.5h.127c-1.209,1.4-2.227,2.546-3.055,3.5l-.064.064-.064-.064c-.382-.7-.7-1.209-.955-1.591a3.642,3.642,0,0,1-.255-.445l-.064-.127.127.127c.064.127.191.255.255.382.255.382.573.891,1.018,1.591h-.064c.764-.891,1.782-2.1,2.991-3.5l.064-.064.064.127c.764,1.082,1.591,2.227,2.482,3.5h-.191c.827-1.018,1.718-2.164,2.609-3.309l.191-.191.064-.127.064.127c1.018,1.209,2.036,2.418,2.927,3.5h-.191c.891-1.273,1.718-2.418,2.482-3.5l.064-.127.064.127c1.018,1.4,1.846,2.609,2.482,3.5h-.064c.445-.509.827-.955,1.082-1.273.127-.127.191-.255.255-.318Z"
                                transform="translate(-368.2 -449.6)"
                                fill="#e0e0e0"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8072"
                        data-name="Group 8072"
                        transform="translate(10.435 56.225)"
                      >
                        <g id="Group_8071" data-name="Group 8071">
                          <g id="Group_8070" data-name="Group 8070">
                            <g id="Group_8069" data-name="Group 8069">
                              <path
                                id="Path_8722"
                                data-name="Path 8722"
                                d="M387.628,445.227c0,.064-4.391.127-9.864.127-5.409,0-9.864-.064-9.864-.127s4.391-.127,9.864-.127C383.237,445.164,387.628,445.164,387.628,445.227Z"
                                transform="translate(-367.9 -445.1)"
                                fill="#455a64"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8076"
                        data-name="Group 8076"
                        transform="translate(9.671 71.116)"
                      >
                        <g id="Group_8075" data-name="Group 8075">
                          <g id="Group_8074" data-name="Group 8074">
                            <g id="Group_8073" data-name="Group 8073">
                              <path
                                id="Path_8723"
                                data-name="Path 8723"
                                d="M388.083,468.627c0,.064-4.773.127-10.691.127s-10.691-.064-10.691-.127,4.773-.127,10.691-.127C383.31,468.564,388.083,468.627,388.083,468.627Z"
                                transform="translate(-366.7 -468.5)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_8198" data-name="Group 8198">
                      <g
                        id="Group_8128"
                        data-name="Group 8128"
                        transform="translate(26.659)"
                      >
                        <g
                          id="Group_8079"
                          data-name="Group 8079"
                          transform="translate(0.825 26.4)"
                        >
                          <g id="Group_8078" data-name="Group 8078">
                            <path
                              id="Path_8724"
                              data-name="Path 8724"
                              d="M126.886,399.367c-.064.064-.445-.191-.891-.509-.445-.382-.764-.7-.7-.764s.445.191.891.509C126.632,398.985,126.95,399.367,126.886,399.367Z"
                              transform="translate(-125.287 -398.085)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g id="Group_8127" data-name="Group 8127">
                          <g
                            id="Group_8080"
                            data-name="Group 8080"
                            transform="translate(1.548 0.884)"
                          >
                            <path
                              id="Path_8725"
                              data-name="Path 8725"
                              d="M139.518,366.587a13.733,13.733,0,0,0-.445-5.091,4.924,4.924,0,0,0-3.437-3.437,5.006,5.006,0,0,0-4.9,2.418,13.753,13.753,0,0,0-1.718,5.537,196.559,196.559,0,0,0-2.418,40.093l7.764,1.082C137.8,393.188,138.882,380.969,139.518,366.587Z"
                              transform="translate(-126.423 -357.988)"
                              fill="#455a64"
                            />
                          </g>
                          <g
                            id="Group_8082"
                            data-name="Group 8082"
                            transform="translate(3.888 0.891)"
                          >
                            <g id="Group_8081" data-name="Group 8081">
                              <path
                                id="Path_8726"
                                data-name="Path 8726"
                                d="M135.891,358a.2.2,0,0,1-.064.127c-.064.064-.064.191-.127.382-.127.318-.318.764-.509,1.336l-.382.955c-.127.382-.255.764-.382,1.209a10.964,10.964,0,0,1-.445,1.336l-.382,1.527a74.818,74.818,0,0,0-1.527,7.7c-.445,2.927-.7,6.173-.891,9.609-.382,6.809-.445,13.046-.636,17.5-.064,2.227-.191,4.073-.255,5.282-.064.636-.064,1.082-.127,1.464a.807.807,0,0,1-.064.382v-.382a13.4,13.4,0,0,1,.064-1.464c.064-1.273.127-3.055.127-5.282.127-4.455.127-10.691.573-17.5.191-3.436.509-6.682.955-9.609a60.145,60.145,0,0,1,1.591-7.7c.127-.509.318-1.082.445-1.527.191-.509.318-.955.445-1.336a12,12,0,0,1,.382-1.146,7.4,7.4,0,0,1,.382-.955q.382-.859.573-1.336a1.075,1.075,0,0,0,.127-.318A.7.7,0,0,1,135.891,358Z"
                                transform="translate(-130.1 -358)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8084"
                            data-name="Group 8084"
                            transform="translate(8.597 0.891)"
                          >
                            <g id="Group_8083" data-name="Group 8083">
                              <path
                                id="Path_8727"
                                data-name="Path 8727"
                                d="M139.282,358a5.185,5.185,0,0,1,.955,1.591,10.937,10.937,0,0,1,.7,5.028c-.191,4.327-.573,10.246-1.082,16.8s-1.082,12.473-1.527,16.737c-.255,2.164-.445,3.882-.573,5.091-.064.573-.127,1.018-.191,1.4,0,.127-.064.255-.064.382v-.382c0-.318.064-.827.127-1.4.127-1.209.255-2.927.509-5.091.382-4.264.955-10.182,1.464-16.737s.955-12.473,1.209-16.8a11.7,11.7,0,0,0-.636-4.964A10.491,10.491,0,0,0,139.282,358Z"
                                transform="translate(-137.5 -358)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8086"
                            data-name="Group 8086"
                            transform="translate(12.925 20.034)"
                          >
                            <g id="Group_8085" data-name="Group 8085">
                              <path
                                id="Path_8728"
                                data-name="Path 8728"
                                d="M147.164,389.111a5.563,5.563,0,0,1-1.464-.382,4.79,4.79,0,0,1-1.4-.636,5.561,5.561,0,0,1,1.464.382A5.909,5.909,0,0,1,147.164,389.111Z"
                                transform="translate(-144.3 -388.081)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8088"
                            data-name="Group 8088"
                            transform="translate(13.816 13.14)"
                          >
                            <g id="Group_8087" data-name="Group 8087">
                              <path
                                id="Path_8729"
                                data-name="Path 8729"
                                d="M148.627,377.281a4.172,4.172,0,0,1-1.4.764,4.041,4.041,0,0,1-1.527.509,4.172,4.172,0,0,1,1.4-.764C147.927,377.408,148.564,377.154,148.627,377.281Z"
                                transform="translate(-145.7 -377.248)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8090"
                            data-name="Group 8090"
                            transform="translate(13.943 6.465)"
                          >
                            <g id="Group_8089" data-name="Group 8089">
                              <path
                                id="Path_8730"
                                data-name="Path 8730"
                                d="M148.191,366.976a2.288,2.288,0,0,1-1.145.064,3.619,3.619,0,0,1-1.146-.191,5.242,5.242,0,0,1,2.291.127Z"
                                transform="translate(-145.9 -366.759)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8092"
                            data-name="Group 8092"
                            transform="translate(6.931 0.439)"
                          >
                            <g id="Group_8091" data-name="Group 8091">
                              <path
                                id="Path_8731"
                                data-name="Path 8731"
                                d="M136.166,359.333c-.064.064-.382-.382-.764-.955a2.448,2.448,0,0,1-.509-1.082c.064-.064.382.382.764.955A3.861,3.861,0,0,1,136.166,359.333Z"
                                transform="translate(-134.881 -357.29)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8094"
                            data-name="Group 8094"
                            transform="translate(3.379 6.237)"
                          >
                            <g id="Group_8093" data-name="Group 8093">
                              <path
                                id="Path_8732"
                                data-name="Path 8732"
                                d="M131.464,366.591a2.618,2.618,0,0,1-1.082.127c-.636,0-1.082-.064-1.082-.191a2.618,2.618,0,0,1,1.082-.127A3.466,3.466,0,0,1,131.464,366.591Z"
                                transform="translate(-129.3 -366.4)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8096"
                            data-name="Group 8096"
                            transform="translate(1.406 13.348)"
                          >
                            <g id="Group_8095" data-name="Group 8095">
                              <path
                                id="Path_8733"
                                data-name="Path 8733"
                                d="M128.427,378.482a2.617,2.617,0,0,1-1.146-.318,3.288,3.288,0,0,1-1.082-.573,2.617,2.617,0,0,1,1.146.318A3.288,3.288,0,0,1,128.427,378.482Z"
                                transform="translate(-126.2 -377.575)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8098"
                            data-name="Group 8098"
                            transform="translate(1.406 20.832)"
                          >
                            <g id="Group_8097" data-name="Group 8097">
                              <path
                                id="Path_8734"
                                data-name="Path 8734"
                                d="M128.236,389.376a2.407,2.407,0,0,1-1.018.382c-.573.127-1.018.127-1.018.064a2.408,2.408,0,0,1,1.018-.382A1.905,1.905,0,0,1,128.236,389.376Z"
                                transform="translate(-126.2 -389.334)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8100"
                            data-name="Group 8100"
                            transform="translate(0.388 31.602)"
                          >
                            <g id="Group_8099" data-name="Group 8099">
                              <path
                                id="Path_8735"
                                data-name="Path 8735"
                                d="M126.764,406.285a2.018,2.018,0,0,1-1.082.318,2.129,2.129,0,0,1-1.082.064,2.018,2.018,0,0,1,1.082-.318A3.541,3.541,0,0,1,126.764,406.285Z"
                                transform="translate(-124.6 -406.259)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8102"
                            data-name="Group 8102"
                            transform="translate(0 37.598)"
                          >
                            <g id="Group_8101" data-name="Group 8101">
                              <path
                                id="Path_8736"
                                data-name="Path 8736"
                                d="M125.906,415.693c.064.064-.382.318-.891.636-.509.255-.955.446-1.018.382s.382-.318.891-.636A3.284,3.284,0,0,1,125.906,415.693Z"
                                transform="translate(-123.99 -415.681)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8104"
                            data-name="Group 8104"
                            transform="translate(5.531 42.06)"
                          >
                            <g id="Group_8103" data-name="Group 8103">
                              <path
                                id="Path_8737"
                                data-name="Path 8737"
                                d="M133.966,424.988c-.064.064-.382-.445-.764-1.082s-.573-1.145-.509-1.209.382.445.764,1.082A4.53,4.53,0,0,1,133.966,424.988Z"
                                transform="translate(-132.681 -422.692)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8106"
                            data-name="Group 8106"
                            transform="translate(6.363 33.149)"
                          >
                            <g id="Group_8105" data-name="Group 8105">
                              <path
                                id="Path_8738"
                                data-name="Path 8738"
                                d="M135.842,408.7c.064.064-.318.509-.827,1.018a3.811,3.811,0,0,1-1.018.827c-.064-.064.318-.509.827-1.018S135.778,408.632,135.842,408.7Z"
                                transform="translate(-133.989 -408.689)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8108"
                            data-name="Group 8108"
                            transform="translate(6.551 25.766)"
                          >
                            <g id="Group_8107" data-name="Group 8107">
                              <path
                                id="Path_8739"
                                data-name="Path 8739"
                                d="M135.631,398.559c-.064.064-.382-.255-.764-.636s-.636-.764-.573-.827.382.255.764.636S135.694,398.5,135.631,398.559Z"
                                transform="translate(-134.285 -397.087)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8110"
                            data-name="Group 8110"
                            transform="translate(7.253 19.721)"
                          >
                            <g id="Group_8109" data-name="Group 8109">
                              <path
                                id="Path_8740"
                                data-name="Path 8740"
                                d="M136.986,387.6c.064.064-.255.509-.7,1.018a3.435,3.435,0,0,1-.891.827c-.064-.064.255-.509.7-1.018S136.923,387.532,136.986,387.6Z"
                                transform="translate(-135.387 -387.589)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8112"
                            data-name="Group 8112"
                            transform="translate(7.579 12.53)"
                          >
                            <g id="Group_8111" data-name="Group 8111">
                              <path
                                id="Path_8741"
                                data-name="Path 8741"
                                d="M137.746,378.014c-.064.064-.509-.318-1.018-.764a4.839,4.839,0,0,1-.827-.955c.064-.064.509.318,1.018.764A3.445,3.445,0,0,1,137.746,378.014Z"
                                transform="translate(-135.9 -376.289)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8114"
                            data-name="Group 8114"
                            transform="translate(9.158 6.867)"
                          >
                            <g id="Group_8113" data-name="Group 8113">
                              <path
                                id="Path_8742"
                                data-name="Path 8742"
                                d="M139.666,367.4c.064.064-.127.509-.509,1.082-.318.573-.7,1.018-.764.955s.127-.509.509-1.082S139.6,367.333,139.666,367.4Z"
                                transform="translate(-138.381 -367.391)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8116"
                            data-name="Group 8116"
                            transform="translate(11.322)"
                          >
                            <g id="Group_8115" data-name="Group 8115">
                              <path
                                id="Path_8743"
                                data-name="Path 8743"
                                d="M142.939,356.6c.064.064-.127.509-.445,1.082s-.636,1.018-.7.955.127-.509.445-1.082A4.283,4.283,0,0,1,142.939,356.6Z"
                                transform="translate(-141.781 -356.6)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8118"
                            data-name="Group 8118"
                            transform="translate(13.235 3.102)"
                          >
                            <g id="Group_8117" data-name="Group 8117">
                              <path
                                id="Path_8744"
                                data-name="Path 8744"
                                d="M146.323,361.491c.064.064-.255.318-.7.509s-.764.318-.827.255.255-.318.7-.509C145.877,361.554,146.259,361.427,146.323,361.491Z"
                                transform="translate(-144.787 -361.474)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8120"
                            data-name="Group 8120"
                            transform="translate(11.97 26.466)"
                          >
                            <g id="Group_8119" data-name="Group 8119">
                              <path
                                id="Path_8745"
                                data-name="Path 8745"
                                d="M145.664,399.4a2.96,2.96,0,0,1-1.527-.382,3.216,3.216,0,0,1-1.336-.827,7.322,7.322,0,0,1,1.4.573C145.027,399.15,145.664,399.341,145.664,399.4Z"
                                transform="translate(-142.8 -398.187)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8122"
                            data-name="Group 8122"
                            transform="translate(11.779 32.875)"
                          >
                            <g id="Group_8121" data-name="Group 8121">
                              <path
                                id="Path_8746"
                                data-name="Path 8746"
                                d="M144.6,408.285a1.935,1.935,0,0,1-1.018.318,2.13,2.13,0,0,1-1.082.064,1.935,1.935,0,0,1,1.018-.318A3.54,3.54,0,0,1,144.6,408.285Z"
                                transform="translate(-142.5 -408.259)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8124"
                            data-name="Group 8124"
                            transform="translate(10.752 39.452)"
                          >
                            <g id="Group_8123" data-name="Group 8123">
                              <path
                                id="Path_8747"
                                data-name="Path 8747"
                                d="M142.867,421.016c-.064.064-.636-.318-1.209-1.018a2.854,2.854,0,0,1-.764-1.4c.064-.064.445.573.955,1.209C142.422,420.443,142.931,420.952,142.867,421.016Z"
                                transform="translate(-140.885 -418.593)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8126"
                            data-name="Group 8126"
                            transform="translate(0.189 44.601)"
                          >
                            <g id="Group_8125" data-name="Group 8125">
                              <path
                                id="Path_8748"
                                data-name="Path 8748"
                                d="M124.3,428.031c-.064-.064.255-.382.7-.764s.827-.636.891-.573-.255.382-.7.764S124.359,428.094,124.3,428.031Z"
                                transform="translate(-124.287 -426.685)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8129"
                        data-name="Group 8129"
                        transform="translate(17.183 23.037)"
                      >
                        <path
                          id="Path_8749"
                          data-name="Path 8749"
                          d="M115.209,396.237c-.509-1.591-1.464-3.436-3.118-3.436a2.822,2.822,0,0,0-2.355,1.591,6.477,6.477,0,0,0-.636,2.864A75.542,75.542,0,0,0,112.664,418h6.109A73.866,73.866,0,0,0,115.209,396.237Z"
                          transform="translate(-109.1 -392.8)"
                          fill="#455a64"
                        />
                      </g>
                      <g
                        id="Group_8130"
                        data-name="Group 8130"
                        transform="translate(1.776 11.073)"
                      >
                        <path
                          id="Path_8750"
                          data-name="Path 8750"
                          d="M95.4,379.409A6.789,6.789,0,0,0,89.033,374c-2.673.445-3.755,3.118-4.073,5.855s.509,5.409,1.336,8.019c2.673,8.337,5.346,16.673,8.019,25.074,2.546-.255,5.027-.573,7.573-.827C99.534,400.856,98.133,390.483,95.4,379.409Z"
                          transform="translate(-84.89 -374)"
                          fill="#455a64"
                        />
                      </g>
                      <g
                        id="Group_8132"
                        data-name="Group 8132"
                        transform="translate(19.076 22.974)"
                      >
                        <g id="Group_8131" data-name="Group 8131">
                          <path
                            id="Path_8751"
                            data-name="Path 8751"
                            d="M113.109,392.7a2.823,2.823,0,0,0-.509.891,5.493,5.493,0,0,0-.255,2.673c.127,1.146.255,2.546.445,4.073s.445,3.182.827,4.964c.7,3.5,1.464,6.618,1.973,8.909.255,1.146.445,2.1.573,2.736.064.318.064.573.127.764,0,.191.064.255,0,.255s-.064-.064-.064-.255c-.064-.191-.127-.445-.191-.7-.191-.7-.382-1.591-.636-2.673-.509-2.291-1.336-5.409-2.036-8.909-.318-1.782-.636-3.436-.827-4.964-.127-.764-.191-1.527-.255-2.164q-.1-1.05-.191-1.909a5.545,5.545,0,0,1,.382-2.736A4.563,4.563,0,0,1,113.109,392.7Z"
                            transform="translate(-112.075 -392.7)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8134"
                        data-name="Group 8134"
                        transform="translate(3.858 10.819)"
                      >
                        <g id="Group_8133" data-name="Group 8133">
                          <path
                            id="Path_8752"
                            data-name="Path 8752"
                            d="M90.35,373.6a7.145,7.145,0,0,0-.891,1.209,8.3,8.3,0,0,0-1.146,3.946,17.086,17.086,0,0,0,.955,6.109c.636,2.227,1.4,4.709,2.164,7.318,1.527,5.218,2.864,9.864,3.882,13.3.445,1.655.891,3.055,1.146,4.073a7.761,7.761,0,0,0,.318,1.082,1.781,1.781,0,0,1,.064.382,2.051,2.051,0,0,1-.127-.382c-.064-.255-.191-.636-.318-1.082-.318-.955-.7-2.355-1.209-4.009-1.018-3.436-2.418-8.146-3.946-13.3-.764-2.609-1.464-5.091-2.164-7.319a18.781,18.781,0,0,1-.891-6.173,8.248,8.248,0,0,1,1.273-4.009A4.793,4.793,0,0,1,90.35,373.6Z"
                            transform="translate(-88.162 -373.6)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8136"
                        data-name="Group 8136"
                        transform="translate(5.6 10.946)"
                      >
                        <g id="Group_8135" data-name="Group 8135">
                          <path
                            id="Path_8753"
                            data-name="Path 8753"
                            d="M102.482,411.156l-.191-.382a11.657,11.657,0,0,1-.445-1.082,34.032,34.032,0,0,1-1.273-4.009c-.509-1.718-.955-3.818-1.4-6.109s-.891-4.837-1.4-7.509a71.139,71.139,0,0,0-1.782-7.446,49.571,49.571,0,0,0-2.227-5.855,28.858,28.858,0,0,0-2.036-3.691c-.255-.445-.509-.7-.636-.955a3.312,3.312,0,0,1-.191-.318,2.3,2.3,0,0,1,.255.318c.127.191.382.509.7.955a26.484,26.484,0,0,1,2.1,3.627,41.734,41.734,0,0,1,2.291,5.855c.7,2.227,1.273,4.773,1.846,7.446.509,2.673.955,5.218,1.4,7.509s.891,4.391,1.336,6.109.891,3.118,1.209,4.009c.127.445.318.827.382,1.082A1.1,1.1,0,0,0,102.482,411.156Z"
                            transform="translate(-90.9 -373.8)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8138"
                        data-name="Group 8138"
                        transform="translate(1.527 23.636)"
                      >
                        <g id="Group_8137" data-name="Group 8137">
                          <path
                            id="Path_8754"
                            data-name="Path 8754"
                            d="M86.346,393.778a1.92,1.92,0,0,1-.891.382c-.509.127-.955.191-.955.127a1.919,1.919,0,0,1,.891-.382C85.9,393.715,86.346,393.715,86.346,393.778Z"
                            transform="translate(-84.5 -393.74)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8140"
                        data-name="Group 8140"
                        transform="translate(0 18.328)"
                      >
                        <g id="Group_8139" data-name="Group 8139">
                          <path
                            id="Path_8755"
                            data-name="Path 8755"
                            d="M84.646,385.591a3.464,3.464,0,0,1-1.273.127c-.7,0-1.273.064-1.273-.064a3.483,3.483,0,0,1,1.273-.255C84.073,385.4,84.646,385.464,84.646,385.591Z"
                            transform="translate(-82.1 -385.4)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8142"
                        data-name="Group 8142"
                        transform="translate(1.782 12.336)"
                      >
                        <g id="Group_8141" data-name="Group 8141">
                          <path
                            id="Path_8756"
                            data-name="Path 8756"
                            d="M86.555,377.395c-.064.064-.445-.191-.891-.573a3.334,3.334,0,0,1-.764-.827c.064-.064.445.191.891.573C86.236,377.013,86.555,377.395,86.555,377.395Z"
                            transform="translate(-84.9 -375.985)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8144"
                        data-name="Group 8144"
                        transform="translate(5.524 9.419)"
                      >
                        <g id="Group_8143" data-name="Group 8143">
                          <path
                            id="Path_8757"
                            data-name="Path 8757"
                            d="M90.984,371.4c.064,0,.064.382.064.827s0,.827-.064.827a1.318,1.318,0,0,1-.191-.827C90.729,371.718,90.92,371.4,90.984,371.4Z"
                            transform="translate(-90.78 -371.4)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8146"
                        data-name="Group 8146"
                        transform="translate(9.406 11.447)"
                      >
                        <g id="Group_8145" data-name="Group 8145">
                          <path
                            id="Path_8758"
                            data-name="Path 8758"
                            d="M97.911,374.6c.064.064-.127.445-.382.827a2.286,2.286,0,0,1-.636.7c-.064-.064.127-.445.382-.827C97.593,374.85,97.848,374.532,97.911,374.6Z"
                            transform="translate(-96.881 -374.587)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8148"
                        data-name="Group 8148"
                        transform="translate(11.646 16.778)"
                      >
                        <g id="Group_8147" data-name="Group 8147">
                          <path
                            id="Path_8759"
                            data-name="Path 8759"
                            d="M102.246,382.987a2.168,2.168,0,0,1-.891.509c-.509.191-.955.318-.955.255a2.17,2.17,0,0,1,.891-.509C101.8,382.987,102.246,382.923,102.246,382.987Z"
                            transform="translate(-100.4 -382.964)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8150"
                        data-name="Group 8150"
                        transform="translate(12.791 22.257)"
                      >
                        <g id="Group_8149" data-name="Group 8149">
                          <path
                            id="Path_8760"
                            data-name="Path 8760"
                            d="M104.746,391.591a2.352,2.352,0,0,1-1.209.509,2.328,2.328,0,0,1-1.336.064,4.5,4.5,0,0,1,1.273-.318A4.127,4.127,0,0,1,104.746,391.591Z"
                            transform="translate(-102.2 -391.574)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8152"
                        data-name="Group 8152"
                        transform="translate(6.784 19.092)"
                      >
                        <g id="Group_8151" data-name="Group 8151">
                          <path
                            id="Path_8761"
                            data-name="Path 8761"
                            d="M92.786,388.191c-.064,0,0-.382.127-.827a1.5,1.5,0,0,1,.382-.764c.064,0,0,.382-.127.827S92.849,388.255,92.786,388.191Z"
                            transform="translate(-92.761 -386.6)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8154"
                        data-name="Group 8154"
                        transform="translate(8.002 25.387)"
                      >
                        <g id="Group_8153" data-name="Group 8153">
                          <path
                            id="Path_8762"
                            data-name="Path 8762"
                            d="M94.691,398.661a2.824,2.824,0,0,1,.318-1.145c.255-.573.509-1.082.573-1.018s-.064.509-.318,1.146S94.754,398.724,94.691,398.661Z"
                            transform="translate(-94.675 -396.492)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8156"
                        data-name="Group 8156"
                        transform="translate(2.991 28.74)"
                      >
                        <g id="Group_8155" data-name="Group 8155">
                          <path
                            id="Path_8763"
                            data-name="Path 8763"
                            d="M88.9,402.486a2.951,2.951,0,0,1-1.082-.255c-.573-.191-1.018-.318-1.018-.445a3.068,3.068,0,0,1,1.146.127A3.684,3.684,0,0,1,88.9,402.486Z"
                            transform="translate(-86.8 -401.761)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8158"
                        data-name="Group 8158"
                        transform="translate(5.147 35.053)"
                      >
                        <g id="Group_8157" data-name="Group 8157">
                          <path
                            id="Path_8764"
                            data-name="Path 8764"
                            d="M91.786,411.693c.064.064-.255.382-.7.7s-.827.509-.891.445.255-.382.7-.7C91.4,411.82,91.786,411.629,91.786,411.693Z"
                            transform="translate(-90.187 -411.681)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8160"
                        data-name="Group 8160"
                        transform="translate(6.3 40.624)"
                      >
                        <g id="Group_8159" data-name="Group 8159">
                          <path
                            id="Path_8765"
                            data-name="Path 8765"
                            d="M93.846,420.476a1.919,1.919,0,0,1-.891.382c-.509.127-.955.191-.955.064a1.919,1.919,0,0,1,.891-.382A1.664,1.664,0,0,1,93.846,420.476Z"
                            transform="translate(-92 -420.434)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8162"
                        data-name="Group 8162"
                        transform="translate(13.746 26.903)"
                      >
                        <g id="Group_8161" data-name="Group 8161">
                          <path
                            id="Path_8766"
                            data-name="Path 8766"
                            d="M105.482,398.891c.064.064-.318.318-.827.509s-.955.191-.955.127a3.36,3.36,0,0,1,.827-.382C105.036,398.954,105.418,398.827,105.482,398.891Z"
                            transform="translate(-103.7 -398.874)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8164"
                        data-name="Group 8164"
                        transform="translate(14.885 34.858)"
                      >
                        <g id="Group_8163" data-name="Group 8163">
                          <path
                            id="Path_8767"
                            data-name="Path 8767"
                            d="M107.151,412.154c-.064.064-.445-.064-.891-.255s-.827-.445-.764-.509.445.064.891.255S107.214,412.091,107.151,412.154Z"
                            transform="translate(-105.489 -411.374)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8166"
                        data-name="Group 8166"
                        transform="translate(16.095 40.904)"
                      >
                        <g id="Group_8165" data-name="Group 8165">
                          <path
                            id="Path_8768"
                            data-name="Path 8768"
                            d="M109.688,421.782a2.99,2.99,0,0,1-1.209-.318c-.636-.255-1.146-.509-1.082-.573a2.989,2.989,0,0,1,1.209.318C109.242,421.463,109.752,421.718,109.688,421.782Z"
                            transform="translate(-107.392 -420.874)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8168"
                        data-name="Group 8168"
                        transform="translate(8.395 45.056)"
                      >
                        <g id="Group_8167" data-name="Group 8167">
                          <path
                            id="Path_8769"
                            data-name="Path 8769"
                            d="M95.3,429.055c-.064-.064.445-.382,1.018-.827A11.955,11.955,0,0,1,97.4,427.4c.064.064-.318.573-.891,1.018A2.554,2.554,0,0,1,95.3,429.055Z"
                            transform="translate(-95.292 -427.4)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8170"
                        data-name="Group 8170"
                        transform="translate(12.652 41.74)"
                      >
                        <g id="Group_8169" data-name="Group 8169">
                          <path
                            id="Path_8770"
                            data-name="Path 8770"
                            d="M102.693,423.978a3.167,3.167,0,0,1-.382-.891c-.191-.509-.382-.891-.318-.891.064-.064.382.318.573.827S102.757,423.978,102.693,423.978Z"
                            transform="translate(-101.98 -422.189)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8172"
                        data-name="Group 8172"
                        transform="translate(10.755 35.176)"
                      >
                        <g id="Group_8171" data-name="Group 8171">
                          <path
                            id="Path_8771"
                            data-name="Path 8771"
                            d="M100.273,412.782c-.064.064-.382-.064-.7-.318a2.181,2.181,0,0,1-.573-.573c.064-.064.382.064.7.318C100.082,412.464,100.336,412.718,100.273,412.782Z"
                            transform="translate(-99 -411.875)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8174"
                        data-name="Group 8174"
                        transform="translate(21.947 24.362)"
                      >
                        <g id="Group_8173" data-name="Group 8173">
                          <path
                            id="Path_8772"
                            data-name="Path 8772"
                            d="M118.123,394.893c.064.064-.255.382-.7.7s-.827.509-.827.445c-.064-.064.255-.382.7-.7S118.123,394.829,118.123,394.893Z"
                            transform="translate(-116.587 -394.881)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8176"
                        data-name="Group 8176"
                        transform="translate(23.159 28.866)"
                      >
                        <g id="Group_8175" data-name="Group 8175">
                          <path
                            id="Path_8773"
                            data-name="Path 8773"
                            d="M120.6,402.176a1.8,1.8,0,0,1-1.082.127c-.573-.064-1.082-.255-1.018-.318a3.54,3.54,0,0,1,1.082.064C120.088,402.049,120.533,402.049,120.6,402.176Z"
                            transform="translate(-118.492 -401.959)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8178"
                        data-name="Group 8178"
                        transform="translate(15.782 30.073)"
                      >
                        <g id="Group_8177" data-name="Group 8177">
                          <path
                            id="Path_8774"
                            data-name="Path 8774"
                            d="M108.936,403.884a1.608,1.608,0,0,1-1.018.255,1.539,1.539,0,0,1-1.018-.127,2.212,2.212,0,0,1,1.018-.127A4.683,4.683,0,0,1,108.936,403.884Z"
                            transform="translate(-106.9 -403.856)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8180"
                        data-name="Group 8180"
                        transform="translate(16.791 24.873)"
                      >
                        <g id="Group_8179" data-name="Group 8179">
                          <path
                            id="Path_8775"
                            data-name="Path 8775"
                            d="M110.149,396.458c0,.064-.445.064-.955-.127-.509-.255-.764-.573-.7-.636s.382.191.827.382A3.355,3.355,0,0,1,110.149,396.458Z"
                            transform="translate(-108.485 -395.684)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8182"
                        data-name="Group 8182"
                        transform="translate(23.986 32.889)"
                      >
                        <g id="Group_8181" data-name="Group 8181">
                          <path
                            id="Path_8776"
                            data-name="Path 8776"
                            d="M121.706,409.5c-.064.064-.509-.127-1.018-.509-.509-.318-.955-.636-.891-.7s.509.127,1.018.509A3.259,3.259,0,0,1,121.706,409.5Z"
                            transform="translate(-119.79 -408.281)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8187"
                        data-name="Group 8187"
                        transform="translate(4.009 47.411)"
                      >
                        <g
                          id="Group_8183"
                          data-name="Group 8183"
                          transform="translate(0.382)"
                        >
                          <rect
                            id="Rectangle_3320"
                            data-name="Rectangle 3320"
                            width="39.647"
                            height="7"
                            fill="#ce002a"
                          />
                        </g>
                        <g
                          id="Group_8184"
                          data-name="Group 8184"
                          transform="translate(3.309 5.6)"
                        >
                          <path
                            id="Path_8777"
                            data-name="Path 8777"
                            d="M93.6,439.9l4.837,21h23.61l5.282-21Z"
                            transform="translate(-93.6 -439.9)"
                            fill="#ce002a"
                          />
                        </g>
                        <g
                          id="Group_8186"
                          data-name="Group 8186"
                          transform="translate(0 6.873)"
                        >
                          <g id="Group_8185" data-name="Group 8185">
                            <path
                              id="Path_8778"
                              data-name="Path 8778"
                              d="M128.365,442.027c0,.064-8.909.127-19.983.127-11.01,0-19.983-.064-19.983-.127s8.909-.127,19.983-.127C119.392,441.9,128.365,441.964,128.365,442.027Z"
                              transform="translate(-88.4 -441.9)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8189"
                        data-name="Group 8189"
                        transform="translate(20.925 28.757)"
                      >
                        <g id="Group_8188" data-name="Group 8188">
                          <path
                            id="Path_8779"
                            data-name="Path 8779"
                            d="M116.011,403.323c-.064.064-.318-.255-.636-.7-.255-.445-.445-.827-.382-.827.064-.064.318.255.636.7C115.884,402.877,116.075,403.259,116.011,403.323Z"
                            transform="translate(-114.981 -401.787)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8191"
                        data-name="Group 8191"
                        transform="translate(21.932 33.914)"
                      >
                        <g id="Group_8190" data-name="Group 8190">
                          <path
                            id="Path_8780"
                            data-name="Path 8780"
                            d="M116.586,411.933a2.242,2.242,0,0,1,.191-1.082c.191-.573.382-1.018.445-.955a2.243,2.243,0,0,1-.191,1.082A3.228,3.228,0,0,1,116.586,411.933Z"
                            transform="translate(-116.562 -409.89)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8193"
                        data-name="Group 8193"
                        transform="translate(22.807 39.578)"
                      >
                        <g id="Group_8192" data-name="Group 8192">
                          <path
                            id="Path_8781"
                            data-name="Path 8781"
                            d="M119,421.088c-.064.064-.382-.445-.636-1.082-.318-.636-.509-1.146-.382-1.209.064-.064.382.445.636,1.082A2.418,2.418,0,0,1,119,421.088Z"
                            transform="translate(-117.937 -418.792)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8195"
                        data-name="Group 8195"
                        transform="translate(19.718 45.176)"
                      >
                        <g id="Group_8194" data-name="Group 8194">
                          <path
                            id="Path_8782"
                            data-name="Path 8782"
                            d="M113.094,429.059c-.064-.064.191-.445.509-.827s.7-.7.764-.636-.191.446-.509.827C113.476,428.8,113.094,429.123,113.094,429.059Z"
                            transform="translate(-113.085 -427.587)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8197"
                        data-name="Group 8197"
                        transform="translate(17.183 37.69)"
                      >
                        <g id="Group_8196" data-name="Group 8196">
                          <path
                            id="Path_8783"
                            data-name="Path 8783"
                            d="M110.755,416.127c0,.064-.382.064-.827,0a1.678,1.678,0,0,1-.827-.255c0-.064.382-.064.827,0C110.373,415.873,110.755,416,110.755,416.127Z"
                            transform="translate(-109.1 -415.825)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Character" transform="translate(121.368 97.435)">
                  <g
                    id="Group_8510"
                    data-name="Group 8510"
                    transform="translate(0)"
                  >
                    <g
                      id="Group_8205"
                      data-name="Group 8205"
                      transform="translate(11.017 140.193)"
                    >
                      <g
                        id="Group_8200"
                        data-name="Group 8200"
                        transform="translate(0 0)"
                      >
                        <rect
                          id="Rectangle_3321"
                          data-name="Rectangle 3321"
                          width="93.931"
                          height="74.903"
                          fill="#e0e0e0"
                        />
                      </g>
                      <g
                        id="Group_8202"
                        data-name="Group 8202"
                        transform="translate(6.873)"
                        opacity="0.3"
                      >
                        <g id="Group_8201" data-name="Group 8201">
                          <path
                            id="Path_8784"
                            data-name="Path 8784"
                            d="M191.4,355.391c11.073,3.946,23.292,4.773,35.129,5.027a34.81,34.81,0,0,1,7.318.636,9.621,9.621,0,0,1,5.918,4.073c1.336,2.291,1.209,5.218.445,7.828-.764,2.546-2.036,4.964-2.991,7.446a27.834,27.834,0,0,0-1.464,13.873,13.429,13.429,0,0,0,1.973,5.6,6.517,6.517,0,0,0,4.964,2.927c3.564.064,6.046-3.436,7.764-6.555,7.255-12.728,15.146-27.174,19.792-41.047Z"
                            transform="translate(-191.4 -355.2)"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8204"
                        data-name="Group 8204"
                        transform="translate(31.82 0.318)"
                      >
                        <g id="Group_8203" data-name="Group 8203">
                          <path
                            id="Path_8785"
                            data-name="Path 8785"
                            d="M230.791,429.9c-.064,0-.191-16.61-.191-37.1s.064-37.1.191-37.1c.064,0,.191,16.61.191,37.1S230.918,429.9,230.791,429.9Z"
                            transform="translate(-230.6 -355.7)"
                            fill="#455a64"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Group_8494" data-name="Group 8494">
                      <g
                        id="Group_8238"
                        data-name="Group 8238"
                        transform="translate(0 33.661)"
                      >
                        <g
                          id="Group_8206"
                          data-name="Group 8206"
                          transform="translate(9.907)"
                        >
                          <path
                            id="Path_8786"
                            data-name="Path 8786"
                            d="M250.478,238.966c-.255-1.846-5.855-8.019-5.855-8.019L241,216.438a10.029,10.029,0,0,0-1.209-2.736,22.515,22.515,0,0,0-8.146-7.891l-7.7-4.327-1.846-4.137L203.512,187.8l-5.027,8.909s-12.219,3.882-13.873,6.682c-1.591,2.8.827,39.965.827,39.965s-5.091,21.446-6.173,25.9.318,9.737.318,9.737l9.1-11.455c2.8-3.564,9.037-5.155,13.491-5.091l38.12.191-1.527-10.691,30.8,5.982s5.282-10.373,5.727-10.564S250.478,238.966,250.478,238.966Z"
                            transform="translate(-178.855 -187.8)"
                            fill="#ce002a"
                          />
                        </g>
                        <g
                          id="Group_8207"
                          data-name="Group 8207"
                          transform="translate(33.718 12.404)"
                        >
                          <path
                            id="Path_8787"
                            data-name="Path 8787"
                            d="M216.289,207.3c-.255-.255,2.164,7.891,5.218,16.228a40.671,40.671,0,0,1,2.482,14.064v30.165l18.264-1.273-2.736-19.855s-2.227-12.791-2.864-17.692c-.573-4.455-2.546-16.546-2.546-16.546S223.926,215.124,216.289,207.3Z"
                            transform="translate(-216.271 -207.291)"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Group_8209"
                          data-name="Group 8209"
                          transform="translate(74.338 51.102)"
                        >
                          <g id="Group_8208" data-name="Group 8208">
                            <path
                              id="Path_8788"
                              data-name="Path 8788"
                              d="M280.1,275.673v-.445a5.891,5.891,0,0,1,.255-1.209,8.164,8.164,0,0,1,2.1-3.564,8.843,8.843,0,0,1,3.564-2.1c.509-.127.955-.191,1.209-.255h.445c0,.064-.636.127-1.591.445a8.77,8.77,0,0,0-5.473,5.473C280.227,275.037,280.164,275.673,280.1,275.673Z"
                              transform="translate(-280.1 -268.1)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8211"
                          data-name="Group 8211"
                          transform="translate(75.226 50.567)"
                        >
                          <g id="Group_8210" data-name="Group 8210">
                            <path
                              id="Path_8789"
                              data-name="Path 8789"
                              d="M281.5,268.113a5.566,5.566,0,0,1,2.8-.827,5.991,5.991,0,0,1,2.864.318c0,.127-1.273-.127-2.864,0C282.771,267.794,281.562,268.24,281.5,268.113Z"
                              transform="translate(-281.496 -267.259)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8219"
                          data-name="Group 8219"
                          transform="translate(13.053 63.066)"
                        >
                          <g
                            id="Group_8213"
                            data-name="Group 8213"
                            transform="translate(0 4.837)"
                            opacity="0.3"
                          >
                            <g id="Group_8212" data-name="Group 8212">
                              <path
                                id="Path_8790"
                                data-name="Path 8790"
                                d="M185.391,294.5l-1.591,1.782a12.918,12.918,0,0,0,6.428,4.264,31.793,31.793,0,0,0,7.764.7,10.2,10.2,0,0,0,3.118-.318,13.084,13.084,0,0,1,3.055-.955,6.611,6.611,0,0,1,2.164.318,4.971,4.971,0,0,0,2.164.064,1.7,1.7,0,0,0,1.273-1.591A113.775,113.775,0,0,0,185.391,294.5Z"
                                transform="translate(-183.8 -294.5)"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8214"
                            data-name="Group 8214"
                            transform="translate(21.574)"
                          >
                            <path
                              id="Path_8791"
                              data-name="Path 8791"
                              d="M249.9,294.028l-4.455-4.773-9.228-2.355s-8.464,2.546-8.655,2.546-7.382-1.973-7.382-1.973l-2.482,9.164,12.919.891,14.7-.573s.764-.7.318-1.209,2.482.827,2.8.827,1.018.255,1.464-.255A2.1,2.1,0,0,0,249.9,294.028Z"
                              transform="translate(-217.7 -286.9)"
                              fill="#ffbe9d"
                            />
                          </g>
                          <g
                            id="Group_8216"
                            data-name="Group 8216"
                            transform="translate(39.456 6.275)"
                          >
                            <g id="Group_8215" data-name="Group 8215">
                              <path
                                id="Path_8792"
                                data-name="Path 8792"
                                d="M255.791,299.395a5.756,5.756,0,0,1-1.464-.445c-.891-.255-2.1-.636-3.5-1.018s-2.673-.636-3.564-.827-1.464-.318-1.464-.318a5.509,5.509,0,0,1,1.527.127c.955.127,2.227.382,3.627.7a28.313,28.313,0,0,1,3.5,1.146A4.743,4.743,0,0,1,255.791,299.395Z"
                                transform="translate(-245.8 -296.761)"
                                fill="#eb996e"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_8218"
                            data-name="Group 8218"
                            transform="translate(39.647 3.564)"
                          >
                            <g id="Group_8217" data-name="Group 8217">
                              <path
                                id="Path_8793"
                                data-name="Path 8793"
                                d="M260.228,296.064c0,.064-.764-.255-2.036-.636-1.273-.445-2.991-.955-4.964-1.464a25.518,25.518,0,0,0-5.028-1.209,10.965,10.965,0,0,0-2.1.064,1.575,1.575,0,0,1,.573-.191,9.371,9.371,0,0,1,1.591-.127,21.489,21.489,0,0,1,5.091,1.145c1.973.573,3.691,1.082,4.964,1.591a12.134,12.134,0,0,1,1.464.636A1.584,1.584,0,0,1,260.228,296.064Z"
                                transform="translate(-246.1 -292.5)"
                                fill="#eb996e"
                              />
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8220"
                          data-name="Group 8220"
                          transform="translate(0 15.592)"
                        >
                          <path
                            id="Path_8794"
                            data-name="Path 8794"
                            d="M178.951,212.3s-6.682,4.646-8.273,11.264c-1.591,6.682-8.655,33.856-7.191,37.929s29.4,8.082,29.4,8.082l3.309-14-14.192-3.882s.191-12.982.382-18.328S178.951,212.3,178.951,212.3Z"
                            transform="translate(-163.288 -212.3)"
                            fill="#ce002a"
                          />
                        </g>
                        <g
                          id="Group_8221"
                          data-name="Group 8221"
                          transform="translate(30.045 60.966)"
                        >
                          <path
                            id="Path_8795"
                            data-name="Path 8795"
                            d="M213.173,283.6l4.582,1.145-2.736,10.564-4.518-.7Z"
                            transform="translate(-210.5 -283.6)"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Group_8223"
                          data-name="Group 8223"
                          transform="translate(0.071 55.017)"
                        >
                          <g id="Group_8222" data-name="Group 8222">
                            <path
                              id="Path_8796"
                              data-name="Path 8796"
                              d="M163.4,282.619a4.285,4.285,0,0,0,.064.636,3.1,3.1,0,0,0,.955,1.591c1.209,1.146,3.564,2.1,6.491,2.609h0c1.336.445,2.864.764,4.518,1.145s3.373.764,5.282,1.146c3.691.764,7.828,1.591,12.219,2.355l-.191.127c.318-1.273.636-2.673,1.018-4.073.764-3.182,1.527-6.3,2.291-9.291l.127.191c-4.709-1.336-8.973-2.546-12.6-3.627h0a12.577,12.577,0,0,0-5.537-.955,12.006,12.006,0,0,0-4.137.955,13.17,13.17,0,0,0-2.418,1.336,6.168,6.168,0,0,0-.573.445.788.788,0,0,0-.191.127l.191-.191a6.162,6.162,0,0,1,.573-.445,13.363,13.363,0,0,1,2.355-1.4,10.708,10.708,0,0,1,4.2-1.018,12.11,12.11,0,0,1,5.6.955h0c3.627,1.018,7.955,2.227,12.664,3.564l.127.064-.064.127c-.7,2.927-1.464,6.046-2.227,9.291-.318,1.4-.7,2.736-1.018,4.073l-.064.127h-.127c-4.391-.827-8.464-1.655-12.219-2.418-1.846-.382-3.627-.764-5.282-1.145s-3.182-.764-4.518-1.209h0c-2.991-.573-5.282-1.527-6.555-2.736a2.829,2.829,0,0,1-.955-1.655Z"
                              transform="translate(-163.4 -274.251)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8225"
                          data-name="Group 8225"
                          transform="translate(8.917 52.545)"
                        >
                          <g id="Group_8224" data-name="Group 8224">
                            <path
                              id="Path_8797"
                              data-name="Path 8797"
                              d="M187.864,273.761c-.064.064-.509-.382-1.336-.955a11.377,11.377,0,0,0-3.627-1.655,11.942,11.942,0,0,0-3.946-.509c-1.018,0-1.655.127-1.655.064a4,4,0,0,1,1.655-.318,12.528,12.528,0,0,1,4.073.445,12.81,12.81,0,0,1,3.627,1.782A6.783,6.783,0,0,1,187.864,273.761Z"
                              transform="translate(-177.3 -270.367)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8227"
                          data-name="Group 8227"
                          transform="translate(17.19 33.156)"
                        >
                          <g id="Group_8226" data-name="Group 8226">
                            <path
                              id="Path_8798"
                              data-name="Path 8798"
                              d="M194.437,239.9v.827c0,.573-.064,1.336-.127,2.291a74.182,74.182,0,0,1-.955,7.573,51.572,51.572,0,0,1-1.782,7.446c-.127.445-.318.891-.445,1.273a9.33,9.33,0,0,0-.382.891,5.47,5.47,0,0,1-.445.764,2.84,2.84,0,0,1,.318-.764c.127-.255.191-.573.382-.955.127-.382.255-.764.382-1.273a64.71,64.71,0,0,0,1.655-7.446c.509-2.927.827-5.6,1.082-7.573.127-.891.191-1.718.255-2.291A4.3,4.3,0,0,1,194.437,239.9Z"
                              transform="translate(-190.3 -239.9)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8229"
                          data-name="Group 8229"
                          transform="translate(28.894 8.973)"
                        >
                          <g id="Group_8228" data-name="Group 8228">
                            <path
                              id="Path_8799"
                              data-name="Path 8799"
                              d="M217.352,218.892a3.783,3.783,0,0,0-.191-.445,2.339,2.339,0,0,0-.955-1.018,2.55,2.55,0,0,0-2.1-.318,2.722,2.722,0,0,0-1.718,1.973l-.064.445-.191-.445a52.243,52.243,0,0,1-1.973-4.9,26.668,26.668,0,0,1-1.464-8.655,10.563,10.563,0,0,1,.127-1.527q.1-.668.191-1.145c.191-.636.255-.955.255-.955a19.624,19.624,0,0,0-.382,3.627,26.624,26.624,0,0,0,1.527,8.591c.573,1.782,1.273,3.437,1.909,4.9l-.255.064a2.862,2.862,0,0,1,1.973-2.164,2.573,2.573,0,0,1,2.227.382,2.92,2.92,0,0,1,.955,1.146,2.5,2.5,0,0,1,.127.382S217.352,218.892,217.352,218.892Z"
                              transform="translate(-208.691 -201.9)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8231"
                          data-name="Group 8231"
                          transform="translate(52.785 9.61)"
                        >
                          <g id="Group_8230" data-name="Group 8230">
                            <path
                              id="Path_8800"
                              data-name="Path 8800"
                              d="M246.53,220.655a7.2,7.2,0,0,1-.127-.764,7.1,7.1,0,0,1-.127-2.164,4.6,4.6,0,0,1,1.527-2.8,3.08,3.08,0,0,1,1.846-.636,2.493,2.493,0,0,1,1.973.891l-.255.191-.573-1.527c-1.209-2.991-2.227-5.728-2.991-7.7-.382-.955-.636-1.718-.891-2.355-.191-.573-.318-.891-.255-.891a5.549,5.549,0,0,1,.382.827c.255.573.573,1.4.955,2.291.827,1.973,1.909,4.646,3.055,7.637l.573,1.527.382.955-.7-.827a2.273,2.273,0,0,0-1.718-.764,2.492,2.492,0,0,0-1.655.573,4.668,4.668,0,0,0-1.527,2.609,7.179,7.179,0,0,0,.064,2.1C246.53,220.4,246.53,220.655,246.53,220.655Z"
                              transform="translate(-246.233 -202.9)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8233"
                          data-name="Group 8233"
                          transform="translate(64.853 28.956)"
                        >
                          <g id="Group_8232" data-name="Group 8232">
                            <path
                              id="Path_8801"
                              data-name="Path 8801"
                              d="M265.2,233.3c.064,0,1.527,9.673,3.182,21.637s2.991,21.637,2.864,21.637c-.064,0-1.527-9.673-3.182-21.637C266.408,243.037,265.135,233.3,265.2,233.3Z"
                              transform="translate(-265.196 -233.3)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8235"
                          data-name="Group 8235"
                          transform="translate(30.554 21.319)"
                          opacity="0.3"
                        >
                          <g id="Group_8234" data-name="Group 8234">
                            <path
                              id="Path_8802"
                              data-name="Path 8802"
                              d="M211.3,221.3a20.016,20.016,0,0,0,1.591,7.191,5.293,5.293,0,0,1,2.546-2.036A3.926,3.926,0,0,1,219,227.918a6.144,6.144,0,0,0-1.146-2.736,3.388,3.388,0,0,0-2.546-1.336,2.18,2.18,0,0,0-2.1,1.718l-1.846-4.264"
                              transform="translate(-211.3 -221.3)"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8237"
                          data-name="Group 8237"
                          transform="translate(13.563 19.155)"
                        >
                          <g id="Group_8236" data-name="Group 8236">
                            <path
                              id="Path_8803"
                              data-name="Path 8803"
                              d="M190.837,227.064c-.064,0-.064-.636-.255-1.718a10.352,10.352,0,0,0-1.527-3.755,9.879,9.879,0,0,0-1.464-1.718,10.336,10.336,0,0,0-1.464-1.146c-.891-.573-1.527-.764-1.527-.827a1.1,1.1,0,0,1,.445.127,4.386,4.386,0,0,0,.509.191,2.972,2.972,0,0,1,.636.318,7.509,7.509,0,0,1,1.527,1.082,10.661,10.661,0,0,1,1.527,1.782,9.111,9.111,0,0,1,1.527,3.946,9.9,9.9,0,0,1,.064,1.273A.762.762,0,0,1,190.837,227.064Z"
                              transform="translate(-184.6 -217.9)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8377"
                        data-name="Group 8377"
                        transform="translate(25.202)"
                      >
                        <g id="Group_8362" data-name="Group 8362">
                          <g id="Group_8361" data-name="Group 8361">
                            <g
                              id="Group_8241"
                              data-name="Group 8241"
                              transform="translate(6.306 4.382)"
                            >
                              <g id="Group_8240" data-name="Group 8240">
                                <g id="Group_8239" data-name="Group 8239">
                                  <path
                                    id="Path_8804"
                                    data-name="Path 8804"
                                    d="M238,153.952A13.622,13.622,0,0,0,224.063,141.8h-.7c-7.573.636-11.328,7.446-10.437,15.019l2.1,26.665.891,1.082a14.224,14.224,0,0,0,16.928,4.009h0l-.573-8.591s7.318-1.336,7.191-8.655C239.4,167.826,238.7,160.571,238,153.952Z"
                                    transform="translate(-212.797 -141.792)"
                                    fill="#ffbe9d"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8251"
                              data-name="Group 8251"
                              transform="translate(16.171 40.025)"
                            >
                              <g id="Group_8250" data-name="Group 8250">
                                <g id="Group_8249" data-name="Group 8249">
                                  <g id="Group_8248" data-name="Group 8248">
                                    <g id="Group_8247" data-name="Group 8247">
                                      <g id="Group_8246" data-name="Group 8246">
                                        <g
                                          id="Group_8245"
                                          data-name="Group 8245"
                                        >
                                          <g
                                            id="Group_8244"
                                            data-name="Group 8244"
                                          >
                                            <g
                                              id="Group_8243"
                                              data-name="Group 8243"
                                            >
                                              <g
                                                id="Group_8242"
                                                data-name="Group 8242"
                                              >
                                                <path
                                                  id="Path_8805"
                                                  data-name="Path 8805"
                                                  d="M237.973,200.346A18.109,18.109,0,0,1,228.3,197.8s2.418,5.218,9.673,4.391Z"
                                                  transform="translate(-228.3 -197.8)"
                                                  fill="#eb996e"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8264"
                              data-name="Group 8264"
                              transform="translate(27.175 21.739)"
                            >
                              <g id="Group_8263" data-name="Group 8263">
                                <g id="Group_8262" data-name="Group 8262">
                                  <g id="Group_8261" data-name="Group 8261">
                                    <g id="Group_8260" data-name="Group 8260">
                                      <g id="Group_8259" data-name="Group 8259">
                                        <g
                                          id="Group_8258"
                                          data-name="Group 8258"
                                        >
                                          <g
                                            id="Group_8257"
                                            data-name="Group 8257"
                                          >
                                            <g
                                              id="Group_8256"
                                              data-name="Group 8256"
                                            >
                                              <g
                                                id="Group_8255"
                                                data-name="Group 8255"
                                              >
                                                <g
                                                  id="Group_8254"
                                                  data-name="Group 8254"
                                                >
                                                  <g
                                                    id="Group_8253"
                                                    data-name="Group 8253"
                                                  >
                                                    <g
                                                      id="Group_8252"
                                                      data-name="Group 8252"
                                                    >
                                                      <path
                                                        id="Path_8806"
                                                        data-name="Path 8806"
                                                        d="M247.633,169.978a1,1,0,0,1-.955,1.082.962.962,0,0,1-1.082-.891,1,1,0,0,1,.955-1.082A.919.919,0,0,1,247.633,169.978Z"
                                                        transform="translate(-245.59 -169.066)"
                                                        fill="#263238"
                                                      />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8278"
                              data-name="Group 8278"
                              transform="translate(26.012 18.552)"
                            >
                              <g id="Group_8277" data-name="Group 8277">
                                <g id="Group_8276" data-name="Group 8276">
                                  <g id="Group_8275" data-name="Group 8275">
                                    <g id="Group_8274" data-name="Group 8274">
                                      <g id="Group_8273" data-name="Group 8273">
                                        <g
                                          id="Group_8272"
                                          data-name="Group 8272"
                                        >
                                          <g
                                            id="Group_8271"
                                            data-name="Group 8271"
                                          >
                                            <g
                                              id="Group_8270"
                                              data-name="Group 8270"
                                            >
                                              <g
                                                id="Group_8269"
                                                data-name="Group 8269"
                                              >
                                                <g
                                                  id="Group_8268"
                                                  data-name="Group 8268"
                                                >
                                                  <g
                                                    id="Group_8267"
                                                    data-name="Group 8267"
                                                  >
                                                    <g
                                                      id="Group_8266"
                                                      data-name="Group 8266"
                                                    >
                                                      <g
                                                        id="Group_8265"
                                                        data-name="Group 8265"
                                                      >
                                                        <path
                                                          id="Path_8807"
                                                          data-name="Path 8807"
                                                          d="M247.414,164.149a20.352,20.352,0,0,0-1.909.764,13.258,13.258,0,0,0-1.718,1.209c-.064,0,0-.191.255-.509a4.037,4.037,0,0,1,1.336-1.018,4.183,4.183,0,0,1,1.527-.509C247.287,164.021,247.478,164.085,247.414,164.149Z"
                                                          transform="translate(-243.764 -164.059)"
                                                          fill="#263238"
                                                        />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8291"
                              data-name="Group 8291"
                              transform="translate(16.611 22.264)"
                            >
                              <g id="Group_8290" data-name="Group 8290">
                                <g id="Group_8289" data-name="Group 8289">
                                  <g id="Group_8288" data-name="Group 8288">
                                    <g id="Group_8287" data-name="Group 8287">
                                      <g id="Group_8286" data-name="Group 8286">
                                        <g
                                          id="Group_8285"
                                          data-name="Group 8285"
                                        >
                                          <g
                                            id="Group_8284"
                                            data-name="Group 8284"
                                          >
                                            <g
                                              id="Group_8283"
                                              data-name="Group 8283"
                                            >
                                              <g
                                                id="Group_8282"
                                                data-name="Group 8282"
                                              >
                                                <g
                                                  id="Group_8281"
                                                  data-name="Group 8281"
                                                >
                                                  <g
                                                    id="Group_8280"
                                                    data-name="Group 8280"
                                                  >
                                                    <g
                                                      id="Group_8279"
                                                      data-name="Group 8279"
                                                    >
                                                      <path
                                                        id="Path_8808"
                                                        data-name="Path 8808"
                                                        d="M231.033,170.787a1,1,0,0,1-.955,1.082.962.962,0,0,1-1.082-.891,1,1,0,0,1,.955-1.082A.962.962,0,0,1,231.033,170.787Z"
                                                        transform="translate(-228.99 -169.89)"
                                                        fill="#263238"
                                                      />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8305"
                              data-name="Group 8305"
                              transform="translate(14.505 19)"
                            >
                              <g id="Group_8304" data-name="Group 8304">
                                <g id="Group_8303" data-name="Group 8303">
                                  <g id="Group_8302" data-name="Group 8302">
                                    <g id="Group_8301" data-name="Group 8301">
                                      <g id="Group_8300" data-name="Group 8300">
                                        <g
                                          id="Group_8299"
                                          data-name="Group 8299"
                                        >
                                          <g
                                            id="Group_8298"
                                            data-name="Group 8298"
                                          >
                                            <g
                                              id="Group_8297"
                                              data-name="Group 8297"
                                            >
                                              <g
                                                id="Group_8296"
                                                data-name="Group 8296"
                                              >
                                                <g
                                                  id="Group_8295"
                                                  data-name="Group 8295"
                                                >
                                                  <g
                                                    id="Group_8294"
                                                    data-name="Group 8294"
                                                  >
                                                    <g
                                                      id="Group_8293"
                                                      data-name="Group 8293"
                                                    >
                                                      <g
                                                        id="Group_8292"
                                                        data-name="Group 8292"
                                                      >
                                                        <path
                                                          id="Path_8809"
                                                          data-name="Path 8809"
                                                          d="M229.7,165.932c-.127.064-.827-.445-1.909-.7s-1.973-.191-2.1-.318c-.064-.064.127-.127.509-.127a4.11,4.11,0,0,1,1.655.191,6.084,6.084,0,0,1,1.527.636C229.639,165.8,229.766,165.932,229.7,165.932Z"
                                                          transform="translate(-225.681 -164.762)"
                                                          fill="#263238"
                                                        />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8318"
                              data-name="Group 8318"
                              transform="translate(22.205 19.596)"
                            >
                              <g id="Group_8317" data-name="Group 8317">
                                <g id="Group_8316" data-name="Group 8316">
                                  <g id="Group_8315" data-name="Group 8315">
                                    <g id="Group_8314" data-name="Group 8314">
                                      <g id="Group_8313" data-name="Group 8313">
                                        <g
                                          id="Group_8312"
                                          data-name="Group 8312"
                                        >
                                          <g
                                            id="Group_8311"
                                            data-name="Group 8311"
                                          >
                                            <g
                                              id="Group_8310"
                                              data-name="Group 8310"
                                            >
                                              <g
                                                id="Group_8309"
                                                data-name="Group 8309"
                                              >
                                                <g
                                                  id="Group_8308"
                                                  data-name="Group 8308"
                                                >
                                                  <g
                                                    id="Group_8307"
                                                    data-name="Group 8307"
                                                  >
                                                    <g
                                                      id="Group_8306"
                                                      data-name="Group 8306"
                                                    >
                                                      <path
                                                        id="Path_8810"
                                                        data-name="Path 8810"
                                                        d="M238.493,175.054a8.751,8.751,0,0,1,1.846-.382c.255-.064.573-.127.573-.318a1.524,1.524,0,0,0-.191-.827c-.318-.7-.573-1.4-.891-2.164a32.974,32.974,0,0,1-2.036-5.664,33.166,33.166,0,0,1,2.482,5.473c.318.764.573,1.464.891,2.164a1.7,1.7,0,0,1,.191,1.146.769.769,0,0,1-.445.445,1.4,1.4,0,0,1-.509.064A10.844,10.844,0,0,1,238.493,175.054Z"
                                                        transform="translate(-237.781 -165.698)"
                                                        fill="#263238"
                                                      />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8327"
                              data-name="Group 8327"
                              transform="translate(13.423 14.875)"
                            >
                              <g id="Group_8326" data-name="Group 8326">
                                <g id="Group_8325" data-name="Group 8325">
                                  <g id="Group_8324" data-name="Group 8324">
                                    <g id="Group_8323" data-name="Group 8323">
                                      <g id="Group_8322" data-name="Group 8322">
                                        <g
                                          id="Group_8321"
                                          data-name="Group 8321"
                                        >
                                          <g
                                            id="Group_8320"
                                            data-name="Group 8320"
                                          >
                                            <g
                                              id="Group_8319"
                                              data-name="Group 8319"
                                            >
                                              <path
                                                id="Path_8811"
                                                data-name="Path 8811"
                                                d="M229.084,159.247c-.127.318-1.209.127-2.546.191s-2.418.382-2.546.127c-.064-.127.127-.382.573-.7a4.837,4.837,0,0,1,1.909-.573,4.257,4.257,0,0,1,1.973.318C228.893,158.866,229.148,159.12,229.084,159.247Z"
                                                transform="translate(-223.981 -158.28)"
                                                fill="#263238"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8336"
                              data-name="Group 8336"
                              transform="translate(24.915 14.951)"
                            >
                              <g id="Group_8335" data-name="Group 8335">
                                <g id="Group_8334" data-name="Group 8334">
                                  <g id="Group_8333" data-name="Group 8333">
                                    <g id="Group_8332" data-name="Group 8332">
                                      <g id="Group_8331" data-name="Group 8331">
                                        <g
                                          id="Group_8330"
                                          data-name="Group 8330"
                                        >
                                          <g
                                            id="Group_8329"
                                            data-name="Group 8329"
                                          >
                                            <g
                                              id="Group_8328"
                                              data-name="Group 8328"
                                            >
                                              <path
                                                id="Path_8812"
                                                data-name="Path 8812"
                                                d="M246.787,159.546c-.255.255-1.209,0-2.355,0-1.145-.064-2.164.191-2.355-.064-.127-.127.064-.382.509-.636a4.025,4.025,0,0,1,1.909-.445,3.8,3.8,0,0,1,1.909.509C246.724,159.164,246.914,159.418,246.787,159.546Z"
                                                transform="translate(-242.039 -158.4)"
                                                fill="#263238"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8339"
                              data-name="Group 8339"
                              transform="translate(4.752)"
                            >
                              <g id="Group_8338" data-name="Group 8338">
                                <g id="Group_8337" data-name="Group 8337">
                                  <path
                                    id="Path_8813"
                                    data-name="Path 8813"
                                    d="M239.276,145.021a1.443,1.443,0,0,0-1.464-1.146,3.063,3.063,0,0,0,1.018-.509,1.018,1.018,0,0,0,.318-1.018c-.191-.573-.891-.7-1.464-.827a3.524,3.524,0,0,1-1.909-1.273c-.7-.891-.955-2.036-1.591-2.991a4.824,4.824,0,0,0-7.509-.827,5.137,5.137,0,0,0-4.391-1.464,5.072,5.072,0,0,0-3.755,2.736,6.122,6.122,0,0,0-5.982.827,5.943,5.943,0,0,0-2.1,5.6l4.582,2.8a2.485,2.485,0,0,1,2.991-2.164c1.4.382,2.227,1.973,3.691,2.1,1.082.064,2.036-.764,3.118-1.082a5.327,5.327,0,0,1,3.882.827,19.454,19.454,0,0,0,3.627,1.846,7.362,7.362,0,0,0,4.646-.573A4.392,4.392,0,0,0,238.7,146.8,2.252,2.252,0,0,0,239.276,145.021Z"
                                    transform="translate(-210.355 -134.906)"
                                    fill="#263238"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8342"
                              data-name="Group 8342"
                              transform="translate(3.862 3.176)"
                            >
                              <g id="Group_8341" data-name="Group 8341">
                                <g id="Group_8340" data-name="Group 8340">
                                  <path
                                    id="Path_8814"
                                    data-name="Path 8814"
                                    d="M215.285,146.581l20.874-.127a9.687,9.687,0,0,0-3.182-3.436,16.016,16.016,0,0,0-9.546-3.118c-3.373.064-10.182,4.391-12.6,6.809a5.291,5.291,0,0,0-1.846,3.564c-.255,2.737,1.336,5.728,2.036,8.4.318,1.336,1.082,5.473,1.082,5.473h0c1.145-.064,1.527-.955,1.336-2.1l-.318-4.455a6.717,6.717,0,0,0,1.846-7.891C214.585,149.254,213.948,147.408,215.285,146.581Z"
                                    transform="translate(-208.957 -139.896)"
                                    fill="#263238"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8345"
                              data-name="Group 8345"
                              transform="translate(0 9.224)"
                            >
                              <g id="Group_8344" data-name="Group 8344">
                                <g id="Group_8343" data-name="Group 8343">
                                  <path
                                    id="Path_8815"
                                    data-name="Path 8815"
                                    d="M210.533,160.728c-1.082-3.691-2.036-7.637-.891-11.328h-.636l-.255.191a3.136,3.136,0,0,0-3.309,1.018,3.074,3.074,0,0,0,0,3.437,2.541,2.541,0,0,0-2.546,2.227,4.823,4.823,0,0,0,.7,2.418,5.4,5.4,0,0,1,.764,2.418,1.93,1.93,0,0,1-1.4,1.909,2.059,2.059,0,0,0,2.355,1.145,4.426,4.426,0,0,1-1.464,1.782c1.336.955,3.246.636,4.709-.127a3.98,3.98,0,0,0,2.1-1.973A4.093,4.093,0,0,0,210.533,160.728Z"
                                    transform="translate(-202.889 -149.4)"
                                    fill="#263238"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8348"
                              data-name="Group 8348"
                              transform="translate(2.538 24.623)"
                            >
                              <g id="Group_8347" data-name="Group 8347">
                                <g id="Group_8346" data-name="Group 8346">
                                  <path
                                    id="Path_8816"
                                    data-name="Path 8816"
                                    d="M211.665,173.79c-.127-.064-5.091-1.336-4.773,3.627.318,5.028,5.218,3.691,5.218,3.564S211.665,173.79,211.665,173.79Z"
                                    transform="translate(-206.877 -173.598)"
                                    fill="#ffbe9d"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8351"
                              data-name="Group 8351"
                              transform="translate(4.247 26.215)"
                            >
                              <g id="Group_8350" data-name="Group 8350">
                                <g id="Group_8349" data-name="Group 8349">
                                  <path
                                    id="Path_8817"
                                    data-name="Path 8817"
                                    d="M211.877,180.046a.7.7,0,0,1-.255.127.988.988,0,0,1-.636.064,2.582,2.582,0,0,1-.891-3.118,1.014,1.014,0,0,1,.636-.7.439.439,0,0,1,.509.191.634.634,0,0,1,.064.255s.127-.064.064-.318c0-.127-.064-.191-.191-.318a1.2,1.2,0,0,0-.509-.127,1.422,1.422,0,0,0-.891.827,2.515,2.515,0,0,0-.191,1.4,2.221,2.221,0,0,0,1.4,2.1.855.855,0,0,0,.764-.191C211.877,180.173,211.941,180.046,211.877,180.046Z"
                                    transform="translate(-209.563 -176.1)"
                                    fill="#eb996e"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8354"
                              data-name="Group 8354"
                              transform="translate(2.026 10.115)"
                            >
                              <g id="Group_8353" data-name="Group 8353">
                                <g id="Group_8352" data-name="Group 8352">
                                  <path
                                    id="Path_8818"
                                    data-name="Path 8818"
                                    d="M206.153,161.364a.835.835,0,0,1-.064-.573,1.764,1.764,0,0,1,.955-1.273,6.384,6.384,0,0,1,2.291-.573,2.8,2.8,0,0,0,2.291-1.146,3.8,3.8,0,0,0,.382-2.609c-.127-.827-.318-1.591-.445-2.227a3.55,3.55,0,0,1,.064-2.164,11.859,11.859,0,0,0,.255,2.1c.127.636.382,1.4.509,2.291a4.226,4.226,0,0,1-.445,2.864,2.389,2.389,0,0,1-1.209,1.018,5.558,5.558,0,0,1-1.4.318,5.53,5.53,0,0,0-2.227.445,1.694,1.694,0,0,0-.891,1.082C206.153,161.173,206.217,161.364,206.153,161.364Z"
                                    transform="translate(-206.072 -150.8)"
                                    fill="#455a64"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8357"
                              data-name="Group 8357"
                              transform="translate(0.748 9.542)"
                            >
                              <g id="Group_8356" data-name="Group 8356">
                                <g id="Group_8355" data-name="Group 8355">
                                  <path
                                    id="Path_8819"
                                    data-name="Path 8819"
                                    d="M204.214,158.873a.644.644,0,0,1-.127-.445,2.056,2.056,0,0,1,.255-1.273,1.99,1.99,0,0,1,.573-.7,2.627,2.627,0,0,1,.955-.445,4.273,4.273,0,0,0,.955-.382,1.068,1.068,0,0,0,.382-.891c0-.382-.064-.764-.064-1.209a7.013,7.013,0,0,1,.127-1.146,5.783,5.783,0,0,1,.7-1.782,1.345,1.345,0,0,1,1.082-.7c.318,0,.445.127.445.191a.866.866,0,0,0-.445,0,1.45,1.45,0,0,0-.891.7,5.435,5.435,0,0,0-.573,1.718,5.605,5.605,0,0,0-.127,1.082c0,.382.064.764.064,1.209a1.5,1.5,0,0,1-.509,1.209,4.876,4.876,0,0,1-1.082.445,1.947,1.947,0,0,0-1.4.955A2.326,2.326,0,0,0,204.214,158.873Z"
                                    transform="translate(-204.064 -149.9)"
                                    fill="#455a64"
                                  />
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8360"
                              data-name="Group 8360"
                              transform="translate(7.962 2.669)"
                            >
                              <g id="Group_8359" data-name="Group 8359">
                                <g id="Group_8358" data-name="Group 8358">
                                  <path
                                    id="Path_8820"
                                    data-name="Path 8820"
                                    d="M237.61,141.773a1.041,1.041,0,0,1-1.018-.064,2.775,2.775,0,0,1-.955-.827,3.566,3.566,0,0,0-1.145-1.018,3.4,3.4,0,0,0-3.755.255c-.636.445-1.146,1.082-1.909,1.591a2.91,2.91,0,0,1-1.273.573,3.5,3.5,0,0,1-1.4-.127c-.891-.318-1.591-.891-2.418-1.146a6.292,6.292,0,0,0-7.891,2.927c-.318.573-.445.955-.445.891a1,1,0,0,1,.064-.255,4.836,4.836,0,0,1,.255-.7,5.781,5.781,0,0,1,1.782-2.227,6.406,6.406,0,0,1,6.428-1.018c.827.318,1.591.827,2.355,1.146a2.63,2.63,0,0,0,1.209.127,2.421,2.421,0,0,0,1.082-.509c.7-.509,1.209-1.082,1.909-1.591a3.512,3.512,0,0,1,2.164-.7,3.711,3.711,0,0,1,1.909.509,5.233,5.233,0,0,1,1.209,1.146,5.02,5.02,0,0,0,.827.827A2.094,2.094,0,0,0,237.61,141.773Z"
                                    transform="translate(-215.4 -139.1)"
                                    fill="#455a64"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8376"
                          data-name="Group 8376"
                          transform="translate(20.027 30.222)"
                        >
                          <g id="Group_8375" data-name="Group 8375">
                            <g id="Group_8374" data-name="Group 8374">
                              <g id="Group_8373" data-name="Group 8373">
                                <g id="Group_8372" data-name="Group 8372">
                                  <g id="Group_8371" data-name="Group 8371">
                                    <g id="Group_8370" data-name="Group 8370">
                                      <g id="Group_8369" data-name="Group 8369">
                                        <g
                                          id="Group_8368"
                                          data-name="Group 8368"
                                        >
                                          <g
                                            id="Group_8367"
                                            data-name="Group 8367"
                                          >
                                            <g
                                              id="Group_8366"
                                              data-name="Group 8366"
                                            >
                                              <g
                                                id="Group_8365"
                                                data-name="Group 8365"
                                              >
                                                <g
                                                  id="Group_8364"
                                                  data-name="Group 8364"
                                                >
                                                  <g
                                                    id="Group_8363"
                                                    data-name="Group 8363"
                                                  >
                                                    <path
                                                      id="Path_8821"
                                                      data-name="Path 8821"
                                                      d="M234.449,182.4c.127-.064.318,1.018,1.4,1.655a4.845,4.845,0,0,0,2.164.445c0,.064-.191.255-.7.318a2.551,2.551,0,0,1-1.846-.318,2.418,2.418,0,0,1-1.082-1.4C234.321,182.717,234.385,182.4,234.449,182.4Z"
                                                      transform="translate(-234.359 -182.396)"
                                                      fill="#263238"
                                                    />
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8493"
                        data-name="Group 8493"
                        transform="translate(9.33 106.782)"
                      >
                        <g
                          id="Group_8378"
                          data-name="Group 8378"
                          transform="translate(90.527 20.592)"
                        >
                          <path
                            id="Path_8822"
                            data-name="Path 8822"
                            d="M331.528,337.376a9.722,9.722,0,0,0-7-2.291L320.2,348.767c3.182.127,6.3,1.782,9.355.955a6.754,6.754,0,0,0,4.646-5.473A7.938,7.938,0,0,0,331.528,337.376Z"
                            transform="translate(-320.2 -335.058)"
                            fill="#ce002a"
                          />
                        </g>
                        <g
                          id="Group_8380"
                          data-name="Group 8380"
                          transform="translate(91.164 20.046)"
                        >
                          <g id="Group_8379" data-name="Group 8379">
                            <path
                              id="Path_8823"
                              data-name="Path 8823"
                              d="M324.955,334.391c0-.127.445-.191,1.273-.191a10.727,10.727,0,0,1,3.5.636,9.309,9.309,0,0,1,4.264,3.309,7.646,7.646,0,0,1,1.4,2.991,7.483,7.483,0,0,1,.127,3.564,6.778,6.778,0,0,1-1.591,3.246,6.891,6.891,0,0,1-2.8,1.909,9.481,9.481,0,0,1-5.409.318,20.075,20.075,0,0,1-3.373-1.018c-.764-.318-1.146-.573-1.146-.7.064-.127.509-.064,1.273.064.827.127,1.973.382,3.373.509a10.06,10.06,0,0,0,4.646-.573,5.414,5.414,0,0,0,2.1-1.527,5.913,5.913,0,0,0,1.209-2.546,7.308,7.308,0,0,0-1.145-5.346,9.348,9.348,0,0,0-3.5-3.118,13.047,13.047,0,0,0-3.118-1.082C325.4,334.645,324.955,334.518,324.955,334.391Z"
                              transform="translate(-321.2 -334.2)"
                              fill="#f5f5f5"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8470"
                          data-name="Group 8470"
                          transform="translate(45.726 71.53)"
                        >
                          <g id="Group_8469" data-name="Group 8469">
                            <g id="Group_8389" data-name="Group 8389">
                              <g id="Group_8388" data-name="Group 8388">
                                <g id="Group_8387" data-name="Group 8387">
                                  <g id="Group_8386" data-name="Group 8386">
                                    <g id="Group_8385" data-name="Group 8385">
                                      <g id="Group_8384" data-name="Group 8384">
                                        <g
                                          id="Group_8383"
                                          data-name="Group 8383"
                                        >
                                          <g
                                            id="Group_8382"
                                            data-name="Group 8382"
                                          >
                                            <g
                                              id="Group_8381"
                                              data-name="Group 8381"
                                            >
                                              <path
                                                id="Path_8824"
                                                data-name="Path 8824"
                                                d="M259.155,415.1,249.8,441.319l32.52,11.837c.955-3.182-13.619-15.528-13.619-15.528l6.682-16.546Z"
                                                transform="translate(-249.8 -415.1)"
                                                fill="#ce002a"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8399"
                              data-name="Group 8399"
                              transform="translate(7.886 17.369)"
                              opacity="0.6"
                            >
                              <g id="Group_8398" data-name="Group 8398">
                                <g id="Group_8397" data-name="Group 8397">
                                  <g id="Group_8396" data-name="Group 8396">
                                    <g id="Group_8395" data-name="Group 8395">
                                      <g id="Group_8394" data-name="Group 8394">
                                        <g
                                          id="Group_8393"
                                          data-name="Group 8393"
                                        >
                                          <g
                                            id="Group_8392"
                                            data-name="Group 8392"
                                          >
                                            <g
                                              id="Group_8391"
                                              data-name="Group 8391"
                                            >
                                              <g
                                                id="Group_8390"
                                                data-name="Group 8390"
                                              >
                                                <path
                                                  id="Path_8825"
                                                  data-name="Path 8825"
                                                  d="M263.661,442.4a1.4,1.4,0,0,0-1.464,1.209,1.345,1.345,0,0,0,1.146,1.464,1.343,1.343,0,1,0,.127-2.673"
                                                  transform="translate(-262.192 -442.392)"
                                                  fill="#fff"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8408"
                              data-name="Group 8408"
                              transform="translate(0 23.546)"
                            >
                              <g id="Group_8407" data-name="Group 8407">
                                <g id="Group_8406" data-name="Group 8406">
                                  <g id="Group_8405" data-name="Group 8405">
                                    <g id="Group_8404" data-name="Group 8404">
                                      <g id="Group_8403" data-name="Group 8403">
                                        <g
                                          id="Group_8402"
                                          data-name="Group 8402"
                                        >
                                          <g
                                            id="Group_8401"
                                            data-name="Group 8401"
                                          >
                                            <g
                                              id="Group_8400"
                                              data-name="Group 8400"
                                            >
                                              <path
                                                id="Path_8826"
                                                data-name="Path 8826"
                                                d="M249.8,454.773l.955-2.673,30.929,12.346s1.209,1.145.7,2.1Z"
                                                transform="translate(-249.8 -452.1)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8418"
                              data-name="Group 8418"
                              transform="translate(15.892 22.012)"
                            >
                              <g id="Group_8417" data-name="Group 8417">
                                <g id="Group_8416" data-name="Group 8416">
                                  <g id="Group_8415" data-name="Group 8415">
                                    <g id="Group_8414" data-name="Group 8414">
                                      <g id="Group_8413" data-name="Group 8413">
                                        <g
                                          id="Group_8412"
                                          data-name="Group 8412"
                                        >
                                          <g
                                            id="Group_8411"
                                            data-name="Group 8411"
                                          >
                                            <g
                                              id="Group_8410"
                                              data-name="Group 8410"
                                            >
                                              <g
                                                id="Group_8409"
                                                data-name="Group 8409"
                                              >
                                                <path
                                                  id="Path_8827"
                                                  data-name="Path 8827"
                                                  d="M278.354,450.142c-.064.127-.891-.064-1.846.191-.955.191-1.655.764-1.718.636-.127-.064.445-.955,1.591-1.209S278.417,450.014,278.354,450.142Z"
                                                  transform="translate(-274.773 -449.69)"
                                                  fill="#263238"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8428"
                              data-name="Group 8428"
                              transform="translate(19.439 24.934)"
                            >
                              <g id="Group_8427" data-name="Group 8427">
                                <g id="Group_8426" data-name="Group 8426">
                                  <g id="Group_8425" data-name="Group 8425">
                                    <g id="Group_8424" data-name="Group 8424">
                                      <g id="Group_8423" data-name="Group 8423">
                                        <g
                                          id="Group_8422"
                                          data-name="Group 8422"
                                        >
                                          <g
                                            id="Group_8421"
                                            data-name="Group 8421"
                                          >
                                            <g
                                              id="Group_8420"
                                              data-name="Group 8420"
                                            >
                                              <g
                                                id="Group_8419"
                                                data-name="Group 8419"
                                              >
                                                <path
                                                  id="Path_8828"
                                                  data-name="Path 8828"
                                                  d="M283.307,454.484c0,.191-.827.191-1.591.636-.827.445-1.209,1.146-1.336,1.082-.127,0,.064-1.018,1.082-1.591A1.689,1.689,0,0,1,283.307,454.484Z"
                                                  transform="translate(-280.345 -454.28)"
                                                  fill="#263238"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8438"
                              data-name="Group 8438"
                              transform="translate(22.794 27.603)"
                            >
                              <g id="Group_8437" data-name="Group 8437">
                                <g id="Group_8436" data-name="Group 8436">
                                  <g id="Group_8435" data-name="Group 8435">
                                    <g id="Group_8434" data-name="Group 8434">
                                      <g id="Group_8433" data-name="Group 8433">
                                        <g
                                          id="Group_8432"
                                          data-name="Group 8432"
                                        >
                                          <g
                                            id="Group_8431"
                                            data-name="Group 8431"
                                          >
                                            <g
                                              id="Group_8430"
                                              data-name="Group 8430"
                                            >
                                              <g
                                                id="Group_8429"
                                                data-name="Group 8429"
                                              >
                                                <path
                                                  id="Path_8829"
                                                  data-name="Path 8829"
                                                  d="M285.67,460.782c-.127,0-.064-.891.636-1.591s1.591-.827,1.591-.636c.064.191-.636.445-1.209,1.082C286.115,460.209,285.8,460.845,285.67,460.782Z"
                                                  transform="translate(-285.617 -458.474)"
                                                  fill="#263238"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8448"
                              data-name="Group 8448"
                              transform="translate(17.052 17.25)"
                            >
                              <g id="Group_8447" data-name="Group 8447">
                                <g id="Group_8446" data-name="Group 8446">
                                  <g id="Group_8445" data-name="Group 8445">
                                    <g id="Group_8444" data-name="Group 8444">
                                      <g id="Group_8443" data-name="Group 8443">
                                        <g
                                          id="Group_8442"
                                          data-name="Group 8442"
                                        >
                                          <g
                                            id="Group_8441"
                                            data-name="Group 8441"
                                          >
                                            <g
                                              id="Group_8440"
                                              data-name="Group 8440"
                                            >
                                              <g
                                                id="Group_8439"
                                                data-name="Group 8439"
                                              >
                                                <path
                                                  id="Path_8830"
                                                  data-name="Path 8830"
                                                  d="M280.1,443.539c-.127.127-.764-.382-1.718-.636-.891-.255-1.718-.255-1.782-.382s.827-.509,1.909-.191C279.653,442.648,280.226,443.411,280.1,443.539Z"
                                                  transform="translate(-276.595 -442.206)"
                                                  fill="#263238"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8458"
                              data-name="Group 8458"
                              transform="translate(16.803 10.208)"
                            >
                              <g id="Group_8457" data-name="Group 8457">
                                <g id="Group_8456" data-name="Group 8456">
                                  <g id="Group_8455" data-name="Group 8455">
                                    <g id="Group_8454" data-name="Group 8454">
                                      <g id="Group_8453" data-name="Group 8453">
                                        <g
                                          id="Group_8452"
                                          data-name="Group 8452"
                                        >
                                          <g
                                            id="Group_8451"
                                            data-name="Group 8451"
                                          >
                                            <g
                                              id="Group_8450"
                                              data-name="Group 8450"
                                            >
                                              <g
                                                id="Group_8449"
                                                data-name="Group 8449"
                                              >
                                                <path
                                                  id="Path_8831"
                                                  data-name="Path 8831"
                                                  d="M280.592,435.315a3.557,3.557,0,0,1-1.655-.573,7.325,7.325,0,0,1-1.527-1.145,9.789,9.789,0,0,1-.764-.827,2.221,2.221,0,0,1-.382-.573.724.724,0,0,1,.255-.891.975.975,0,0,1,.827-.127,1.23,1.23,0,0,1,.573.318,4.807,4.807,0,0,1,.891.7,5.983,5.983,0,0,1,1.145,1.527,3,3,0,0,1,.509,1.655c-.064,0-.255-.573-.827-1.464a5.353,5.353,0,0,0-1.146-1.336,9.353,9.353,0,0,0-.827-.636c-.318-.191-.573-.318-.764-.191-.064.064-.064.064,0,.191a1.332,1.332,0,0,0,.255.382,4.384,4.384,0,0,0,.7.827,6.777,6.777,0,0,0,1.336,1.145C280.02,435,280.656,435.251,280.592,435.315Z"
                                                  transform="translate(-276.203 -431.14)"
                                                  fill="#263238"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_8468"
                              data-name="Group 8468"
                              transform="translate(20.858 9.835)"
                            >
                              <g id="Group_8467" data-name="Group 8467">
                                <g id="Group_8466" data-name="Group 8466">
                                  <g id="Group_8465" data-name="Group 8465">
                                    <g id="Group_8464" data-name="Group 8464">
                                      <g id="Group_8463" data-name="Group 8463">
                                        <g
                                          id="Group_8462"
                                          data-name="Group 8462"
                                        >
                                          <g
                                            id="Group_8461"
                                            data-name="Group 8461"
                                          >
                                            <g
                                              id="Group_8460"
                                              data-name="Group 8460"
                                            >
                                              <g
                                                id="Group_8459"
                                                data-name="Group 8459"
                                              >
                                                <path
                                                  id="Path_8832"
                                                  data-name="Path 8832"
                                                  d="M282.655,435.293a2.656,2.656,0,0,1,.127-1.718,4.95,4.95,0,0,1,.891-1.718,3.115,3.115,0,0,1,.764-.827,1.5,1.5,0,0,1,1.336-.445.811.811,0,0,1,.509.7,1.961,1.961,0,0,1-.064.636,3.133,3.133,0,0,1-.382,1.082,3.56,3.56,0,0,1-1.336,1.4c-.955.636-1.655.573-1.655.509,0-.127.636-.191,1.4-.827a4.718,4.718,0,0,0,1.082-1.336,5.6,5.6,0,0,0,.318-.955c.064-.382.064-.7-.127-.7-.191-.064-.509.127-.764.318a7.621,7.621,0,0,0-.7.764,4.768,4.768,0,0,0-.891,1.527C282.782,434.656,282.718,435.293,282.655,435.293Z"
                                                  transform="translate(-282.575 -430.554)"
                                                  fill="#263238"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g
                          id="Group_8471"
                          data-name="Group 8471"
                          transform="translate(0 0.891)"
                        >
                          <path
                            id="Path_8833"
                            data-name="Path 8833"
                            d="M201.464,304.736c-4.964.127-9.61.827-13.046,4.391-4.518,4.773-10.119,11.519-10.437,16.228-.445,7,3.755,9.1,6.873,10.819a8.357,8.357,0,0,0,4.455,1.018l57.084-.445,1.782-9.673-36.911-9.1,1.527-13.873Z"
                            transform="translate(-177.949 -304.1)"
                            fill="#263238"
                          />
                        </g>
                        <g
                          id="Group_8472"
                          data-name="Group 8472"
                          transform="translate(33.316)"
                        >
                          <path
                            id="Path_8834"
                            data-name="Path 8834"
                            d="M231.891,303.527l17.946-.827s30.992,1.718,37.611,7.764,5.791,12.982,3.564,19.6S269.374,379,269.374,379a6.859,6.859,0,0,1-4.391,10.182l-17.246-6.364s-2.355-.827-.7-3.055,2.736-2.609,2.736-2.609v-4.391s6.046-11.01,6.618-12.537,6.364-23.928,6.364-23.928l3.882-6.618s-9.355-4.391-17.437-7.573l-18.9-4.646Z"
                            transform="translate(-230.3 -302.7)"
                            fill="#263238"
                          />
                        </g>
                        <g
                          id="Group_8474"
                          data-name="Group 8474"
                          transform="translate(52.599 74.983)"
                        >
                          <g id="Group_8473" data-name="Group 8473">
                            <path
                              id="Path_8835"
                              data-name="Path 8835"
                              d="M271.991,424.9a10.04,10.04,0,0,1-1.527-1.018,21.768,21.768,0,0,0-3.882-1.973,20.437,20.437,0,0,0-4.2-1.082c-1.082-.191-1.782-.191-1.782-.255a7.022,7.022,0,0,1,1.846,0,16.285,16.285,0,0,1,4.264,1.018,16.6,16.6,0,0,1,3.882,2.1A6.363,6.363,0,0,1,271.991,424.9Z"
                              transform="translate(-260.6 -420.525)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8476"
                          data-name="Group 8476"
                          transform="translate(66.78 68.412)"
                        >
                          <g id="Group_8475" data-name="Group 8475">
                            <path
                              id="Path_8836"
                              data-name="Path 8836"
                              d="M288.749,418.155a5.508,5.508,0,0,1-1.018-1.082c-.573-.7-1.336-1.655-2.164-2.736s-1.527-2.164-1.973-2.864a4.045,4.045,0,0,1-.7-1.273,7.368,7.368,0,0,1,.891,1.145c.573.827,1.273,1.782,2.036,2.8s1.464,1.973,2.1,2.8C288.495,417.646,288.813,418.091,288.749,418.155Z"
                              transform="translate(-282.885 -410.2)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8478"
                          data-name="Group 8478"
                          transform="translate(69.208 20.914)"
                        >
                          <g id="Group_8477" data-name="Group 8477">
                            <path
                              id="Path_8837"
                              data-name="Path 8837"
                              d="M296.437,335.587a14.136,14.136,0,0,1-1.655.509,16.8,16.8,0,0,0-3.691,1.718,18.411,18.411,0,0,0-3.182,2.546c-.764.764-1.146,1.273-1.209,1.209a9.829,9.829,0,0,1,1.018-1.4A12.751,12.751,0,0,1,290.9,337.5a13.284,13.284,0,0,1,3.818-1.655A4.988,4.988,0,0,1,296.437,335.587Z"
                              transform="translate(-286.7 -335.564)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8480"
                          data-name="Group 8480"
                          transform="translate(61.317 17.692)"
                        >
                          <g id="Group_8479" data-name="Group 8479">
                            <path
                              id="Path_8838"
                              data-name="Path 8838"
                              d="M274.3,363.72a1.78,1.78,0,0,1,.064-.382c.064-.255.127-.636.255-1.082.255-.955.636-2.227,1.018-3.882.891-3.309,2.164-7.764,3.564-12.664.7-2.482,1.336-4.837,2.036-6.937a15.613,15.613,0,0,1,3.246-4.964,20.453,20.453,0,0,1,3.055-2.546,9.35,9.35,0,0,1,.955-.573,3.3,3.3,0,0,1,.318-.191s-.445.318-1.209.827a22.609,22.609,0,0,0-2.991,2.609,14.759,14.759,0,0,0-3.118,4.9c-.636,2.1-1.273,4.518-1.973,6.937-1.4,4.9-2.673,9.355-3.627,12.664q-.668,2.386-1.145,3.818c-.127.446-.255.764-.318,1.018A1.989,1.989,0,0,1,274.3,363.72Z"
                              transform="translate(-274.3 -330.5)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8482"
                          data-name="Group 8482"
                          transform="translate(44.059 3.564)"
                        >
                          <g id="Group_8481" data-name="Group 8481">
                            <path
                              id="Path_8839"
                              data-name="Path 8839"
                              d="M248.53,323.319c-.064,0-.445-3.373-.827-7.509s-.573-7.509-.509-7.509.445,3.373.827,7.509A68.6,68.6,0,0,1,248.53,323.319Z"
                              transform="translate(-247.181 -308.3)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8484"
                          data-name="Group 8484"
                          transform="translate(7.283 21.042)"
                        >
                          <g id="Group_8483" data-name="Group 8483">
                            <path
                              id="Path_8840"
                              data-name="Path 8840"
                              d="M197.671,342.279c-.064,0-.318-.509-.891-1.336a12.83,12.83,0,0,0-2.609-2.736,11.738,11.738,0,0,0-3.309-1.846,12.318,12.318,0,0,1-1.464-.573,3.719,3.719,0,0,1,1.591.318,11.521,11.521,0,0,1,6.046,4.709A6.8,6.8,0,0,1,197.671,342.279Z"
                              transform="translate(-189.393 -335.765)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8486"
                          data-name="Group 8486"
                          transform="translate(11.17 12.221)"
                        >
                          <g id="Group_8485" data-name="Group 8485">
                            <path
                              id="Path_8841"
                              data-name="Path 8841"
                              d="M210.9,325.02c-.064.064-.764-.445-2.1-1.018a22.073,22.073,0,0,0-5.409-1.591,20.415,20.415,0,0,0-5.6,0c-1.464.191-2.291.445-2.291.382a2.612,2.612,0,0,1,.573-.255,13.21,13.21,0,0,1,1.655-.382,18.745,18.745,0,0,1,5.728-.064,17.338,17.338,0,0,1,5.473,1.718,14.071,14.071,0,0,1,1.527.891C210.71,324.892,210.9,325.02,210.9,325.02Z"
                              transform="translate(-195.5 -321.903)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8488"
                          data-name="Group 8488"
                          transform="translate(25.87 15.183)"
                        >
                          <g id="Group_8487" data-name="Group 8487">
                            <path
                              id="Path_8842"
                              data-name="Path 8842"
                              d="M262.256,335.24a2.664,2.664,0,0,1-.446-.064c-.318-.064-.764-.191-1.273-.255-1.146-.255-2.736-.636-4.646-1.018-3.946-.891-9.355-2.036-15.4-3.246s-11.519-2.164-15.464-2.864c-1.973-.318-3.564-.636-4.709-.827-.509-.064-.955-.191-1.273-.255s-.445-.064-.445-.127.127,0,.445.064.764.127,1.273.191c1.145.127,2.736.382,4.709.7,4.009.636,9.482,1.591,15.464,2.736,6.046,1.209,11.455,2.418,15.4,3.373,1.973.446,3.564.891,4.646,1.146.509.127.955.255,1.273.318S262.256,335.176,262.256,335.24Z"
                              transform="translate(-218.6 -326.559)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8490"
                          data-name="Group 8490"
                          transform="translate(23.512 7.565)"
                        >
                          <g id="Group_8489" data-name="Group 8489">
                            <path
                              id="Path_8843"
                              data-name="Path 8843"
                              d="M224.762,323.441A18.728,18.728,0,0,0,224,321.6a9.741,9.741,0,0,0-1.336-1.718c-.573-.573-1.273-1.145-1.973-1.718a26.931,26.931,0,0,0-4.073-2.546A7.156,7.156,0,0,1,214.9,314.6a11.253,11.253,0,0,1,1.846.764,22.279,22.279,0,0,1,4.2,2.482,14.08,14.08,0,0,1,2.036,1.782,6.2,6.2,0,0,1,1.273,1.782,7.835,7.835,0,0,1,.509,1.4Z"
                              transform="translate(-214.894 -314.587)"
                              fill="#455a64"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8492"
                          data-name="Group 8492"
                          transform="translate(94.2 25.007)"
                        >
                          <g id="Group_8491" data-name="Group 8491">
                            <path
                              id="Path_8844"
                              data-name="Path 8844"
                              d="M328.726,342a28.035,28.035,0,0,0-1.655,2.482c-.764,1.464-.955,2.8-1.082,2.8a6.177,6.177,0,0,1,.764-2.927A6.068,6.068,0,0,1,328.726,342Z"
                              transform="translate(-325.971 -341.995)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_8496"
                      data-name="Group 8496"
                      transform="translate(65.428 66.563)"
                      opacity="0.3"
                    >
                      <g id="Group_8495" data-name="Group 8495">
                        <path
                          id="Path_8845"
                          data-name="Path 8845"
                          d="M266.1,239.5l4.073,28.765.191.445A62.02,62.02,0,0,0,266.1,239.5Z"
                          transform="translate(-266.1 -239.5)"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_8509"
                      data-name="Group 8509"
                      transform="translate(41.373 82.281)"
                    >
                      <g id="Group_8497" data-name="Group 8497">
                        <path
                          id="Path_8846"
                          data-name="Path 8846"
                          d="M274.375,265.027l-13.3,23.228-32.71.7-.064,1.464,31.692-.255,1.018-.064,33.474.064,6.746-11.646,8.655-12.41a1.207,1.207,0,0,0-1.018-1.909l-33.41.127A1.5,1.5,0,0,0,274.375,265.027Z"
                          transform="translate(-228.3 -264.2)"
                          fill="#e0e0e0"
                        />
                      </g>
                      <g
                        id="Group_8505"
                        data-name="Group 8505"
                        transform="translate(62.02 14.382)"
                      >
                        <g id="Group_8498" data-name="Group 8498">
                          <path
                            id="Path_8847"
                            data-name="Path 8847"
                            d="M336.6,286.8s1.718,1.591,1.464,2.927-.191,2.355-1.464,2.418a1.933,1.933,0,0,1-1.718,1.973,2.144,2.144,0,0,1-2.036,2.482s-.764,2.227-2.291,1.718a20.134,20.134,0,0,1-2.546-1.146l-.445-.827s-2.291-.827-1.718-2.164c.318-.764,1.527-.955,1.527-.955a2.131,2.131,0,0,1,.127-1.973c.573-.7,2.291-.127,2.291-.127a2.3,2.3,0,0,1,.573-1.718c.573-.7,3.436,1.209,3.436,1.209l1.527-1.846Z"
                            transform="translate(-325.756 -286.8)"
                            fill="#ffbe9d"
                          />
                        </g>
                        <g
                          id="Group_8500"
                          data-name="Group 8500"
                          transform="translate(1.619 6.36)"
                        >
                          <g id="Group_8499" data-name="Group 8499">
                            <path
                              id="Path_8848"
                              data-name="Path 8848"
                              d="M333.773,300.234a15.035,15.035,0,0,1-2.8-1.591A21.83,21.83,0,0,1,328.3,296.8a15.032,15.032,0,0,1,2.8,1.591A17.013,17.013,0,0,1,333.773,300.234Z"
                              transform="translate(-328.3 -296.794)"
                              fill="#eb996e"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8502"
                          data-name="Group 8502"
                          transform="translate(4.099 4.323)"
                        >
                          <g id="Group_8501" data-name="Group 8501">
                            <path
                              id="Path_8849"
                              data-name="Path 8849"
                              d="M337.226,296.652a15.992,15.992,0,0,1-2.609-1.4A12.068,12.068,0,0,1,332.2,293.6a16,16,0,0,1,2.609,1.4A9.632,9.632,0,0,1,337.226,296.652Z"
                              transform="translate(-332.196 -293.593)"
                              fill="#eb996e"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_8504"
                          data-name="Group 8504"
                          transform="translate(6.326 2.731)"
                        >
                          <g id="Group_8503" data-name="Group 8503">
                            <path
                              id="Path_8850"
                              data-name="Path 8850"
                              d="M335.7,291.1a10.61,10.61,0,0,1,2.355,1.209,14.155,14.155,0,0,1,2.164,1.464,6.436,6.436,0,0,1-2.355-1.146A12.059,12.059,0,0,1,335.7,291.1Z"
                              transform="translate(-335.696 -291.092)"
                              fill="#eb996e"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8507"
                        data-name="Group 8507"
                        transform="translate(34.238 0.69)"
                      >
                        <g id="Group_8506" data-name="Group 8506">
                          <path
                            id="Path_8851"
                            data-name="Path 8851"
                            d="M329.384,265.549h-2.418c-1.591,0-3.882,0-6.618.064-5.664,0-13.364,0-21.955.064H296.8a1.262,1.262,0,0,0-1.209.573c-.509.891-1.018,1.782-1.464,2.673-.955,1.782-1.909,3.5-2.8,5.091-1.782,3.246-3.437,6.173-4.773,8.591s-2.482,4.391-3.246,5.791c-.382.636-.7,1.209-.891,1.591a3.754,3.754,0,0,1-.255.382.2.2,0,0,0-.064.127.2.2,0,0,1,.064-.127,3.092,3.092,0,0,0,.191-.445c.191-.382.509-.891.827-1.591.764-1.4,1.846-3.373,3.182-5.855s2.927-5.409,4.709-8.655c.891-1.655,1.846-3.309,2.8-5.091.509-.891,1.018-1.782,1.527-2.736a1.576,1.576,0,0,1,1.464-.7h1.591c8.528,0,16.292.064,21.956.064,2.8,0,5.027.064,6.618.064h2.227C329.32,265.549,329.384,265.549,329.384,265.549Z"
                            transform="translate(-282.1 -265.285)"
                            fill="#263238"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_8508"
                        data-name="Group 8508"
                        transform="translate(57.521 12.095)"
                      >
                        <path
                          id="Path_8852"
                          data-name="Path 8852"
                          d="M319.141,283.775a1.441,1.441,0,0,0,0,2.1,1.523,1.523,0,0,0,2.1-.255,1.441,1.441,0,0,0,0-2.1A1.523,1.523,0,0,0,319.141,283.775Z"
                          transform="translate(-318.687 -283.206)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_8512"
                    data-name="Group 8512"
                    transform="translate(5.799 85.164)"
                    opacity="0.3"
                  >
                    <g id="Group_8511" data-name="Group 8511">
                      <path
                        id="Path_8853"
                        data-name="Path 8853"
                        d="M173.036,269.538c-.255.127-.636.318-.636.636a.617.617,0,0,0,.445.509,4.629,4.629,0,0,0,1.336-.382c3.437-.764,6.3,0,9.546,1.273l.127-.127C182.582,268.647,176.727,268.011,173.036,269.538Z"
                        transform="translate(-172.4 -268.729)"
                      />
                    </g>
                  </g>
                </g>
                <g id="Floor" transform="translate(48 312.404)">
                  <g id="Group_8514" data-name="Group 8514">
                    <g id="Group_8513" data-name="Group 8513">
                      <path
                        id="Path_8854"
                        data-name="Path 8854"
                        d="M305.1,472.891c0,.064-57.593.191-128.551.191-71.021,0-128.551-.064-128.551-.191s57.53-.191,128.551-.191S305.1,472.764,305.1,472.891Z"
                        transform="translate(-48 -472.7)"
                        fill="#263238"
                      />
                    </g>
                  </g>
                </g>
                <g id="Stars" transform="translate(127.781 49.496)">
                  <g
                    id="Group_8536"
                    data-name="Group 8536"
                    transform="translate(0 11.964)"
                  >
                    <g
                      id="Group_8533"
                      data-name="Group 8533"
                      transform="translate(0 1.846)"
                    >
                      <path
                        id="Path_8878"
                        data-name="Path 8878"
                        d="M174.91,46.2l6.428,6.618,9.1-3.437.382,2.227-3.946,8.082,3.627,6.046-.318,1.782L181.4,66.055l-5.218,5.664-1.209-.891-1.082-7.255-8.209-4.327-.382-1.655,8.273-2.227Z"
                        transform="translate(-165.3 -46.2)"
                        fill="#ce002a"
                      />
                    </g>
                    <g
                      id="Group_8534"
                      data-name="Group 8534"
                      transform="translate(0 1.846)"
                      opacity="0.3"
                    >
                      <path
                        id="Path_8879"
                        data-name="Path 8879"
                        d="M174.91,46.2l6.428,6.618,9.1-3.437.382,2.227-3.946,8.082,3.627,6.046-.318,1.782L181.4,66.055l-5.218,5.664-1.209-.891-1.082-7.255-8.209-4.327-.382-1.655,8.273-2.227Z"
                        transform="translate(-165.3 -46.2)"
                      />
                    </g>
                    <g id="Group_8535" data-name="Group 8535">
                      <path
                        id="Path_8880"
                        data-name="Path 8880"
                        d="M174.91,43.3l6.428,6.618,9.1-1.591-4.264,8.146,4.327,8.209-9.1-1.527-6.428,6.618-1.336-9.164L165.3,56.537l8.273-4.073Z"
                        transform="translate(-165.3 -43.3)"
                        fill="#ce002a"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_8540"
                    data-name="Group 8540"
                    transform="translate(60.648 11.964)"
                  >
                    <g
                      id="Group_8537"
                      data-name="Group 8537"
                      transform="translate(0 1.846)"
                    >
                      <path
                        id="Path_8881"
                        data-name="Path 8881"
                        d="M276.51,46.2l-6.428,6.618-9.1-3.437-.382,2.227,3.946,8.082-3.627,6.046.318,1.782,8.782-1.464,5.218,5.664,1.209-.891,1.082-7.255,8.209-4.327.318-1.655-8.209-2.227Z"
                        transform="translate(-260.6 -46.2)"
                        fill="#ce002a"
                      />
                    </g>
                    <g
                      id="Group_8538"
                      data-name="Group 8538"
                      transform="translate(0 1.846)"
                      opacity="0.3"
                    >
                      <path
                        id="Path_8882"
                        data-name="Path 8882"
                        d="M276.51,46.2l-6.428,6.618-9.1-3.437-.382,2.227,3.946,8.082-3.627,6.046.318,1.782,8.782-1.464,5.218,5.664,1.209-.891,1.082-7.255,8.209-4.327.318-1.655-8.209-2.227Z"
                        transform="translate(-260.6 -46.2)"
                      />
                    </g>
                    <g
                      id="Group_8539"
                      data-name="Group 8539"
                      transform="translate(0.318)"
                    >
                      <path
                        id="Path_8883"
                        data-name="Path 8883"
                        d="M276.692,43.3l-6.428,6.618-9.1-1.591,4.264,8.146L261.1,64.683l9.1-1.527,6.428,6.618,1.336-9.164,8.273-4.073-8.209-4.073Z"
                        transform="translate(-261.1 -43.3)"
                        fill="#ce002a"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_8544"
                    data-name="Group 8544"
                    transform="translate(26.665)"
                  >
                    <g
                      id="Group_8541"
                      data-name="Group 8541"
                      transform="translate(0 2.291)"
                    >
                      <path
                        id="Path_8884"
                        data-name="Path 8884"
                        d="M223.428,28.1l5.346,10.5,12.41-.764-.445,2.927L232.91,49.1l2.1,8.719-1.018,2.036L223.81,54.7l-8.528,5.027-1.145-1.591,1.464-9.291-8.4-8.337.191-2.164,10.946.382Z"
                        transform="translate(-207.2 -28.1)"
                        fill="#ce002a"
                      />
                    </g>
                    <g
                      id="Group_8542"
                      data-name="Group 8542"
                      transform="translate(0 2.291)"
                      opacity="0.3"
                    >
                      <path
                        id="Path_8885"
                        data-name="Path 8885"
                        d="M223.428,28.1l5.346,10.5,12.41-.764-.445,2.927L232.91,49.1l2.1,8.719-1.018,2.036L223.81,54.7l-8.528,5.027-1.145-1.591,1.464-9.291-8.4-8.337.191-2.164,10.946.382Z"
                        transform="translate(-207.2 -28.1)"
                      />
                    </g>
                    <g
                      id="Group_8543"
                      data-name="Group 8543"
                      transform="translate(0.191)"
                    >
                      <path
                        id="Path_8886"
                        data-name="Path 8886"
                        d="M224.237,24.5,229.583,35l11.71,1.527-8.337,8.4,2.164,11.582-10.5-5.346-10.373,5.664,1.846-11.71L207.5,37.037l11.646-1.846Z"
                        transform="translate(-207.5 -24.5)"
                        fill="#ce002a"
                      />
                    </g>
                  </g>
                </g>
                <g id="Speech_Bubble" transform="translate(187.624 113.411)">
                  <g id="Group_8557" data-name="Group 8557">
                    <g id="Group_8550" data-name="Group 8550">
                      <g id="Group_8549" data-name="Group 8549">
                        <g id="Group_8548" data-name="Group 8548">
                          <g
                            id="Group_8545"
                            data-name="Group 8545"
                            transform="translate(0 0.122)"
                          >
                            <path
                              id="Path_8887"
                              data-name="Path 8887"
                              d="M267.4,168.092l8.973,1.018a21.382,21.382,0,1,1-3.118,6.873h0Z"
                              transform="translate(-267.4 -160.202)"
                              fill="#fafafa"
                            />
                          </g>
                          <g id="Group_8547" data-name="Group 8547">
                            <g id="Group_8546" data-name="Group 8546">
                              <path
                                id="Path_8888"
                                data-name="Path 8888"
                                d="M267.4,168.022c.382.445,2.164,2.864,5.918,7.828h-.127c0-.064.064-.064.127-.064a.062.062,0,0,1,.064.064,21.045,21.045,0,0,0,.127,10.882,20.559,20.559,0,0,0,1.145,3.118l.764,1.527.955,1.527a20.317,20.317,0,0,0,5.346,5.346,19.969,19.969,0,0,0,7.509,3.246,20.412,20.412,0,0,0,8.782.064,21.94,21.94,0,0,0,8.4-3.882,20.817,20.817,0,0,0,8.209-17.055,20.986,20.986,0,0,0-2.355-9.1,20.661,20.661,0,0,0-5.664-6.873,20.93,20.93,0,0,0-7.446-3.691,22.231,22.231,0,0,0-7.573-.636,21.6,21.6,0,0,0-6.618,1.846,20.683,20.683,0,0,0-8.337,6.937l-.064.064c-5.855-.764-8.591-1.082-9.164-1.145.573.064,3.309.318,8.973.891l-.064.064a20.779,20.779,0,0,1,8.337-7,21.423,21.423,0,0,1,14.382-1.273,22.017,22.017,0,0,1,7.573,3.691,21.246,21.246,0,0,1,5.791,6.937,21.841,21.841,0,0,1,2.418,9.228,20.857,20.857,0,0,1-2.1,9.737,20.541,20.541,0,0,1-6.173,7.573,21.281,21.281,0,0,1-8.528,3.946,21.012,21.012,0,0,1-8.909-.064,21.446,21.446,0,0,1-7.573-3.309,20.52,20.52,0,0,1-5.346-5.409l-.955-1.527c-.255-.509-.509-1.082-.764-1.591a15.435,15.435,0,0,1-1.146-3.118,21.2,21.2,0,0,1-.064-10.946l.127.064h0c0,.064-.064.064-.127.064h0C269.564,170.95,267.782,168.531,267.4,168.022Z"
                                transform="translate(-267.4 -160.01)"
                                fill="#263238"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_8556"
                      data-name="Group 8556"
                      transform="translate(18.264 13.485)"
                    >
                      <g id="Group_8553" data-name="Group 8553">
                        <g id="Group_8552" data-name="Group 8552">
                          <g id="Group_8551" data-name="Group 8551">
                            <path
                              id="Path_8889"
                              data-name="Path 8889"
                              d="M296.291,181.327v1.146c0,.764,0,1.782.064,3.055,0,2.609.064,6.364.064,10.819l-.127-.127h14.955l-.191.191h0V181.455l.127.127c-4.455,0-8.146-.064-10.819-.064-1.273,0-2.355,0-3.055-.064h-.827c-.064-.064-.191-.064-.191-.127h1.018c.7,0,1.718,0,3.055-.064,2.673,0,6.428-.064,10.882-.064h.127v15.082h0l-.191.191H296.1v-.127c0-4.518.064-8.273.064-10.882,0-1.273,0-2.291.064-3.055v-.764A1.114,1.114,0,0,0,296.291,181.327Z"
                              transform="translate(-296.1 -181.2)"
                              fill="#263238"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_8555"
                        data-name="Group 8555"
                        transform="translate(3.039 2.605)"
                      >
                        <g id="Group_8554" data-name="Group 8554">
                          <path
                            id="Path_8890"
                            data-name="Path 8890"
                            d="M311.392,185.3c.191.191-1.782,3.055-4.518,6.491-.509.7-1.082,1.336-1.527,1.909l-.318.382-.382-.318c-2.355-2.1-3.946-3.755-3.755-3.882.127-.191,1.973,1.146,4.391,3.182l-.7.064c.445-.636.955-1.273,1.527-1.973C308.782,187.779,311.2,185.17,311.392,185.3Z"
                            transform="translate(-300.876 -185.293)"
                            fill="#ce002a"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          {state?.result?.has_passed == false &&
          (state?.result?.criteria == "terminal_quiz" ||
            state?.result?.criteria == "pass_through_quiz") ? (
            <h3>You are not passed in quiz!</h3>
          ) : (
            <h3>You have successfully completed the quiz!</h3>
          )}

          <h2 className="score-view text-center">
            Your Score : {state?.result?.user_score}{" "}
          </h2>
          {/*<div className="text-center">*/}
          {/*    {state?.result && state?.result?.has_passed ?*/}
          {/*        <p className='mx-auto thatwasclose'>Congrats! You have passed </p>*/}

          {/*        :*/}
          {/*        <p className='mx-auto thatwasclose'>That was close! You will get there next time!</p>*/}
          {/*    }*/}

          {/*</div>*/}
          {props?.value?.completion_message && (
            <p className="mt-4 text-left">{props?.value?.completion_message}</p>
          )}
          {state?.result?.has_passed == false &&
          (state?.result?.criteria == "terminal_quiz" ||
            state?.result?.criteria == "pass_through_quiz") ? (
            <div className="button-wrap double-btn d-flex justify-content-center m-t-20 ml-auto ">
              <Button variant="primary" className="btn-m" onClick={handleRetry}>
                Retry
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
};
export default LearnerQuiz;
