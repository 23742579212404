import React from "react";
import LikertScale from '../../../components/likertscale'
import FreeResponse from '../../../components/freeresponse'
import MultipleChoice from '../../../components/multiplechoicesurvey'
import YesOrNoSurvey from '../../../components/yesornosurvey'


function FormikControl(props){
    const {control,...rest} =props
    if(control.length >1){   
   
    switch (control){
        case "multiple_choice":
        
           return <MultipleChoice {...rest}/>
        case "likert_scale":
            
            return <LikertScale {...rest}/>
        case 'true_or_false':
            
           return <YesOrNoSurvey {...rest} />
         case "free_response":
           
           return <FreeResponse {...rest}/>

        
        default :
            return false
   }

}
}
export default FormikControl;