import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from "./ckQues";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer } from "react-toastify";
import { FormControl } from "react-bootstrap";
import { deleteQuizChoice } from "../../../../../services";

function Likert_scale(props) {
    const dispatch = useDispatch();
    let loading = useSelector(state => state?.survey_builder?.get_survey_manager?.loading);
    const { values, errors, index, setFieldValue, general_questionsErrors, general_questionsTouched, handleBlur, setValues } = props
    const [showinput, setshowinput] = useState(false)
    const [hideicon, sethideicon] = useState(true)
    const [choiceid, setchoiceid] = useState('')



    return (
        <div className="module-quiz-add-wrap">
            <ToastContainer autoClose={2000} />
            {/*<div className="form-close-btn p-relative"><span className="memb-close-icon">×</span></div>*/}
            <Form>
                <Row className="justify-content-center likert-scale-row-wrap">
                     <Col md={12} >
                        <Row >
                            <Col md={6} >
                                <div className="form-input-wrap">
                                    <Form.Label>Add Question Title</Form.Label>
                                    <span className="mandatory-star">*</span>
                                    <Form.Control maxlength="20"
                                        value={values?.general_questions[index]?.title ? values?.general_questions[index]?.title : ''}
                                        name={`general_questions.${index}.title`}
                                        onChange={e => setFieldValue(`general_questions.${index}.title`, e.target.value)}
                                        isInvalid={!!general_questionsErrors?.title && general_questionsTouched?.title && !!general_questionsTouched?.title}
                                        placeholder={`Question - ${index + 1}`}
                                        minLength="8" />
                                    <Form.Text id="passwordHelpBlock" muted>
                                    Your title must not exceed 20 characters

                                    </Form.Text>


                                </div>
                            </Col>
                        </Row>

                    </Col> 

                    <Col md={12} >
                        <div className="form-input-wrap">
                            <Form.Label>Add Question</Form.Label>
                            <span className="mandatory-star">*</span>
                            {/* <CkEditorQuestion handleBlur={handleBlur} general_questionsErrors={general_questionsErrors} general_questionsTouched={general_questionsTouched} name={`general_questions.${index}.question`} setFieldValue={setFieldValue} values={values} index={index} /> */}
                            <Form.Control

                                value={values?.general_questions[index]?.question}
                                name={`general_questions.${index}.question`}
                                onChange={e => setFieldValue(`general_questions.${index}.question`, e.target.value)}
                                isInvalid={!!general_questionsErrors?.question && general_questionsTouched?.question && !!general_questionsTouched?.question}


                                as="textarea" rows={4} />

                        </div>
                    </Col>
                    <Col md={12}>
                        <Row className="">
                            <Col md={12}>
                                <div className="form-input-wrap">

                                    <div className="form-input-wrap">
                                        <div className="row m-l--30">
                                            <div className="col-md-1 d-flex align-items-center justify-content-end p-r-0">  <Form.Label>1*</Form.Label></div>
                                            <div className="col-md-11">
                                                <Form.Control
                                                    type="text" placeholder="Enter Response" maxlength="150"
                                                    value={values?.general_questions[index]?.multiple_choices[0]?.choice}
                                                    name={`general_questions.${index}.multiple_choices.${0}.choice`}
                                                    onChange={e => setFieldValue(`general_questions.${index}.multiple_choices.${0}.choice`, e.target.value)}
                                                    isInvalid={!!general_questionsErrors?.multiple_choices && !!general_questionsErrors?.multiple_choices?.length && !!general_questionsErrors?.multiple_choices[0] && general_questionsTouched?.multiple_choices?.length && general_questionsTouched?.multiple_choices?.length && !!general_questionsTouched?.multiple_choices[0] && !!general_questionsTouched?.multiple_choices[0]}
                                                // onChange={e=>setFieldValue(`general_questions.${index}.multiple_choices.${0}.choice`,e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-input-wrap">
                                    <div className="form-input-wrap">
                                        <div className="row m-l--30">
                                            <div className="col-md-1 d-flex align-items-center justify-content-end p-r-0"><Form.Label>2*</Form.Label></div>
                                            <div className="col-md-11">

                                                <Form.Control

                                                    type="text" placeholder="Enter Response" maxlength="150"
                                                    name={`general_questions.${index}.multiple_choices.${1}.choice`}
                                                    value={values?.general_questions[index]?.multiple_choices[1]?.choice}
                                                    onChange={e => setFieldValue(`general_questions.${index}.multiple_choices.${1}.choice`, e.target.value)}
                                                    isInvalid={!!general_questionsErrors?.multiple_choices && !!general_questionsErrors?.multiple_choices?.length && !!general_questionsErrors?.multiple_choices?.length && !!general_questionsErrors?.multiple_choices[1] && general_questionsTouched?.multiple_choices && general_questionsTouched?.multiple_choices?.length && general_questionsTouched?.multiple_choices?.length && !!general_questionsTouched?.multiple_choices[1] && !!general_questionsTouched?.multiple_choices[1]}
                                                // onChange={e=>setFieldValue(`general_questions.${index}.multiple_choices.${1}.choice`,e.target.value)}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>


                        </Row>

                        <Row>

                            <Col md={12}>
                                <div className="form-input-wrap" >
                                    <div className="row m-l--30">
                                        <div className="col-md-1 d-flex align-items-center justify-content-end p-r-0">  <Form.Label>3*</Form.Label></div>
                                        <div className="col-md-11">
                                            <Form.Control
                                                type="text" placeholder="Enter Response" maxlength="150"
                                                name={`general_questions.${index}.multiple_choices.${2}.choice`}
                                                value={values?.general_questions[index]?.multiple_choices[2]?.choice}
                                                onChange={e => setFieldValue(`general_questions.${index}.multiple_choices.${2}.choice`, e.target.value)}
                                                isInvalid={!!general_questionsErrors?.multiple_choices && !!general_questionsErrors?.multiple_choices?.length && !!general_questionsErrors?.multiple_choices?.length && !!general_questionsErrors?.multiple_choices[2] && general_questionsTouched?.multiple_choices && general_questionsTouched?.multiple_choices?.length && general_questionsTouched?.multiple_choices?.length && !!general_questionsTouched?.multiple_choices[2] && !!general_questionsTouched?.multiple_choices[2]}

                                            />
                                        </div>
                                    </div>
                                </div>

                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="form-input-wrap"   >
                                    <div className="row m-l--30">
                                        <div className="col-md-1 d-flex align-items-center justify-content-end p-r-0">  <Form.Label>4</Form.Label></div>
                                        <div className="col-md-11">
                                            <Form.Control type="text" placeholder="Enter Response" maxlength="150"
                                                name={`general_questions.${index}.multiple_choices.${3}.choice`}
                                                value={values?.general_questions[index]?.multiple_choices[3]?.choice}
                                                onChange={e => setFieldValue(`general_questions.${index}.multiple_choices.${3}.choice`, e.target.value)}
                                            // isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.choices?.length && !!moduleErrors?.module_tests[0]?.choices[3] && moduleTouched?.module_tests && moduleTouched?.module_tests.length && !!moduleTouched?.module_tests[0]?.choices[3]}
                                            //           onChange={e => setFieldValue(`general_questions.${index}.multiple_choices.${3}.choice`, e.target.value)}
                                            />


                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="form-input-wrap"   >
                                    <div className="row m-l--30">
                                        <div className="col-md-1 d-flex align-items-center justify-content-end p-r-0">  <Form.Label>5</Form.Label></div>
                                        <div className="col-md-11">
                                            <Form.Control type="text" placeholder="Enter Response" maxlength="150"
                                                name={`general_questions.${index}.multiple_choices.${4}.choice`}
                                                value={values?.general_questions[index]?.multiple_choices[4]?.choice}
                                                onChange={e => setFieldValue(`general_questions.${index}.multiple_choices.${4}.choice`, e.target.value)}
                                            // isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.choices?.length && !!moduleErrors?.module_tests[0]?.choices[3] && moduleTouched?.module_tests && moduleTouched?.module_tests.length && !!moduleTouched?.module_tests[0]?.choices[3]}
                                            //           onChange={e => setFieldValue(`general_questions.${index}.multiple_choices.${3}.choice`, e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Col>


                        </Row>

                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default Likert_scale;

