import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AddToCourse from "../components/addtocourse";
import AddDetails from "../components/adddetails";
import AddQuizDetails from "../components/addQuizDetails";

function QuizBuilder(props) {
  const urlId = props?.match?.params?.id;
  const [state, setState] = useState({
    defaultKey: "0",
    quizId: "",
    quiz: {},
    moduleAdded: false,
  });
  const parentState = (key, id, item, yes) => {
    setState({
      ...state,
      defaultKey: key,
      quizId: id,
      quiz: item,
      moduleAdded: yes,
    });
  };

  return (
    <div className="tab-wrapper">
      <Tabs
        onSelect={(k) => setState({ ...state, defaultKey: k })}
        activeKey={state?.defaultKey}
        id="controlled-tab-example"
        className="m-b-30"
      >
        <Tab eventKey="0" title="Details" disabled={state?.moduleAdded}>
          <AddQuizDetails
            parentState={parentState}
            urlId={urlId ? urlId : null}
          />
        </Tab>
        <Tab
          disabled={!state?.quizId || state?.moduleAdded}
          eventKey="1"
          title="Add Contents"
        >
          <AddDetails
            parentState={parentState}
            quizId={state?.quizId}
            quiz={state?.quiz}
            urlId={urlId ? urlId : null}
          />
        </Tab>
        {/* <Tab disabled={!state?.quizId} eventKey="2" title="Assign">
      <AddToCourse quiz={state.quiz} keys={state?.defaultKey} urlId={urlId ? urlId :null} parentState={parentState} quizId={state?.quizId} quiz={state?.quiz} urlId={urlId ? urlId :null}/>
       </Tab> */}
      </Tabs>
    </div>
  );
}
export default QuizBuilder;
