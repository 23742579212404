import React, { useEffect, useState, useCallback } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollArea from "react-scrollbar";
import LearnerQuiz from "./learnerQuiz";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { submitQuizLearner } from "../../../../services";
import $ from "jquery";
import img9 from "~/assets/images/moduleselection/img9.svg";

function TabModules(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [quizNotComplete, setQuizNotComplete] = useState(false);
  const [state, setState] = useState({
    key: 0,
    count: null,
    selectedModuleIds: [],
    vimeoImg: null,
    completeButton: false,
  });
  const handleQuiz = (childData) => {
    if (childData) {
      setQuizNotComplete(true);
    } else {
      setQuizNotComplete(false);
    }
  };

  const handleComplete = () => {
    props.onHide();
  };



  return (
    <div
      className="modulemodalscroll-barprogress"
      style={{ paddingBottom: "10px" }}
    >
      <Tab.Container id="controlled-tab-example" activeKey={state?.key}>
        <Row>
          <div className="module-item-box">
            {/*<h4>{props?.name}</h4> */}
            <ScrollArea
              speed={0.8}
              className="modulemodalthumbscroll"
              contentClassName="content"
              horizontal={false}
            >
              <Nav variant="pills" className="flex-column add-module-pop-tab">
                <Nav.Item>
                  <Nav.Link eventKey={`${1}`}>
                    <div className="d-flex align-items-center">
                      <span className="module-tab-icon">
                        <img src={img9} alt="" />
                      </span>
                      <span className="module-tab-title"> {props.name}</span>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </ScrollArea>
          </div>
          <Col className="module-content-box">
            <div className="w-100">
              {" "}
              <h4 className="module-pop-title">{props?.name}</h4>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey={`${state.key}`} id="tabId">
                {(() => {
                  switch (props?.node?.template) {
                    case "quiz":
                      return (
                        <LearnerQuiz
                          value={props?.node}
                          index={state?.key}
                          length={props?.node?.quiz_questions?.length}
                          parentQuizCallback={handleQuiz}
                        />
                      );

                    default:
                      return null;
                  }
                })()}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      {/* <div className="button-wrap double-btn module-footer-btn d-flex justify-content-end">
        {quizNotComplete && (
          <Button type="button" onClick={handleComplete}>
            Close
          </Button>
        )}
      </div> */}
    </div>
  );
}

export default TabModules;
