import React,{useEffect, useState} from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AvailableSurveys from "../components/availablesurveys";
import AdminSurvey from "../components/AdminSurvey";
import DraftSurveys from "../components/draftSurveys";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import DeletedSurveys from "../components/DeletedSurveys";
import {Helmet} from "react-helmet";

function ManagerSurveyListing(props) {
  const { user } = useSelector((state) => state.auth);

  const [key, setKey] = useState(0);

  const setKeyFromTab = (value) => {
    setKey(value);
 };

 useEffect(()=>{
  if(props.location?.state?.key){
       setKey(props?.location?.state?.key);
  }

},[props?.location]);

  return (
    <div className="tab-wrapper">
        <Helmet>
                <title>Survey Library - Cultural Engagement  </title>
            </Helmet>
      <ToastContainer autoClose={2000} />
      <Link to="/surveybuilder">
        <Button className="tab-right-button">Create New Survey</Button>
      </Link>
      <Tabs
        defaultActiveKey={
          props?.location?.data?.tabIndex ? props?.location?.data?.tabIndex : 0
        }
        onSelect={(k) => setKey(k)}
        activeKey={key}
        id="uncontrolled-tab-example"
        className="m-b-30"
      >
        <Tab eventKey={0} title="Published Surveys">
          <AvailableSurveys  setKeyFromTab={setKeyFromTab}/>
        </Tab>
        <Tab eventKey={1} title="Drafts">
          <DraftSurveys />
        </Tab>
        {user?.is_admin_institution == false && (
          <Tab eventKey={2} title="Template Library">
            <AdminSurvey />
          </Tab>
        )}
        <Tab eventKey={3} title="Deleted Surveys">
          <DeletedSurveys />
        </Tab>
      </Tabs>
    </div>
  );
}
export default ManagerSurveyListing;
