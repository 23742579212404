import { createAction, handleActions } from 'redux-actions';
import { _getProfile,_cancelSubscription, _updateProfile, _updateProfileImage, _deleteManagerContactManager,  _getManagerContactManager, _editManagerContactManager, _createManagerContactManager,
  _getContactManager, _changePassword, _getGroupList, _getTransactionHistory, _getCardDetails, _deleteCardDetails ,_saveCard, _upgradePlan, _getSubscription, _selectedPlan, _couponCode } from '~shared/httpService/api';
import ActionTypes from '~shared/constants/actionTypes';
import {signInSuccess} from '../../auth/services';
import { ToastContainer, toast } from 'react-toastify';
import { callback } from 'chart.js/helpers';

const initialState = {
  profile_details: { loading: false, response: {}, hasError: false, error: {} },
  profile_update: { loading: false, response: {}, hasError: false, error: {} },
  profile_image: { loading: false, response: {}, hasError: false, error: {} },
  contact_manager: { loading: false, response: {}, hasError: false, error: {} },
  change_password: { loading: false, response: {}, hasError: false, error: {} },
  group_list: { loading: false, response: {}, hasError: false, error: {} },
  transaction_history: { loading: false, response: {}, hasError: false, error: {} },
  card_details: { loading: false, response: {}, hasError: false, error: {} },
  delete_card_details: { loading: false, response: {}, hasError: false, error: {} },
  update_card: { loading: false, response: {}, hasError: false, error: {} },
  upgrade_plan: { loading: false, response: {}, hasError: false, error: {} },
  subscription_details: { loading: false, response: {}, hasError: false, error: {} },
  select_plan: { loading: false, response: {}, hasError: false, error: {} },
  coupon_code: { loading: false, response: {}, hasError: false, error: {} },
  disable_card: { loading: false, response: {}, hasError: false, error: {} },
  manager_contact_details:{ loading: false, response: {}, hasError: false, error: {} },
  create_manager_contact_details:{ loading: false, response: {}, hasError: false, error: {} },
  edit_manager_contact_details:{ loading: false, response: {}, hasError: false, error: {} },
  delete_manager_contact_details:{ loading: false, response: {}, hasError: false, error: {} },



};

export const getProfileStart = createAction(ActionTypes.GET_PROFILE);
export const getProfileSuccess = createAction(ActionTypes.GET_PROFILE_SUCCESS, response => response);
export const getProfileError = createAction(ActionTypes.GET_PROFILE_ERROR, error => error);

export const getProfile = (userId, token) => (dispatch) => {
  dispatch(getProfileStart());
  return _getProfile(userId, token).then((response) => {
    dispatch(getProfileSuccess(response));
  }).catch((error) => {
    dispatch(getProfileError(error));
  });
};

export const updateProfileStart = createAction(ActionTypes.PROFILE_UPDATE);
export const updateProfileSuccess = createAction(ActionTypes.PROFILE_UPDATE_SUCCESS, response => response);
export const updateProfileError = createAction(ActionTypes.PROFILE_UPDATE_ERROR, error => error);

export const updateProfile = (userId, token, formData,callback) => (dispatch) => {
  dispatch(updateProfileStart());
  return _updateProfile(userId,token, formData).then((response) => {
    if(response){
      toast.success('Profile Updated Successfully')
      callback(response)
    }
    dispatch(updateProfileSuccess(response));
  }).catch((error) => {
    if(error){
      if(error.mobile_number && error.mobile_number[0] == 'Enter a valid phone number.'){
        toast.warn('Enter a valid phone number')
      }
    }
    dispatch(updateProfileError(error));
  });
};

export const updateProfileImageStart = createAction(ActionTypes.PROFILE_IMAGE_UPDATE);
export const updateProfileImageSuccess = createAction(ActionTypes.PROFILE_IMAGE_UPDATE_SUCCESS, response => response);
export const updateProfileImageError = createAction(ActionTypes.PROFILE_IMAGE_UPDATE_ERROR, error => error);

export const updateProfileImage = (userId, token, formData,callback) => (dispatch) => {
  dispatch(updateProfileImageStart());
  return _updateProfileImage(userId,token, formData).then((response) => {
             if(response){
               callback(response)
               toast.success('Profile Updated Successfully')
             }
    dispatch(updateProfileImageSuccess(response));
  }).catch((error) => {
    dispatch(updateProfileImageError(error));
  });
};


export const getContactStart = createAction(ActionTypes.GET_CONTACT);
export const getContactSuccess = createAction(ActionTypes.GET_CONTACT_SUCCESS, response => response);
export const getContactError = createAction(ActionTypes.GET_CONTACT_ERROR, error => error);

export const getContact = (token) => (dispatch) => {
  dispatch(getContactStart());
  return _getContactManager(token).then((response) => {
    dispatch(getContactSuccess(response));
  }).catch((error) => {
    dispatch(getContactError(error));
  });
};


export const getManagerContactStart = createAction(ActionTypes.GET_MANAGER_CONTACT);
export const getManagerContactSuccess = createAction(ActionTypes.GET_MANAGER_CONTACT_SUCCESS, response => response);
export const getManagerContactError = createAction(ActionTypes.GET_MANAGER_CONTACT_ERROR, error => error);

export const getManagerContact = () => (dispatch) => {
  dispatch(getManagerContactStart());
  return _getManagerContactManager().then((response) => {
    dispatch(getManagerContactSuccess(response));
   
  }).catch((error) => {
    dispatch(getManagerContactError(error));
   

  });
};

export const editManagerContactStart = createAction(ActionTypes.EDIT_MANAGER_CONTACT);
export const editManagerContactSuccess = createAction(ActionTypes.EDIT_MANAGER_CONTACT_SUCCESS, response => response);
export const editManagerContactError = createAction(ActionTypes.EDIT_MANAGER_CONTACT_ERROR, error => error);

export const editManagerContact = (id,formData,callback) => (dispatch) => {
  dispatch(editManagerContactStart());
  return _editManagerContactManager(id,formData).then((response) => {
    if(response){
      toast.success('Manager Contact Updated') }
    dispatch(editManagerContactSuccess(response));
    callback()
  }).catch((error) => {
    dispatch(editManagerContactError(error));
    callback()
  });
};


export const deleteManagerContactStart = createAction(ActionTypes.DELETE_MANAGER_CONTACT);
export const deleteManagerContactSuccess = createAction(ActionTypes.DELETE_MANAGER_CONTACT_SUCCESS, response => response);
export const deleteManagerContactError = createAction(ActionTypes.DELETE_MANAGER_CONTACT_ERROR, error => error);

export const deleteManagerContact = (id, callback) => (dispatch) => {
  dispatch(deleteManagerContactStart());
  return _deleteManagerContactManager(id).then((response) => {

      toast.success('Manager Contact Deleted')
    dispatch(deleteManagerContactSuccess(response));
    callback()
  }).catch((error) => {

    dispatch(deleteManagerContactError(error));
    callback()
  });
};

export const createManagerContactStart = createAction(ActionTypes.CREATE_MANAGER_CONTACT);
export const createManagerContactSuccess = createAction(ActionTypes.CREATE_MANAGER_CONTACT_SUCCESS, response => response);
export const createManagerContactError = createAction(ActionTypes.CREATE_MANAGER_CONTACT_ERROR, error => error);

export const createManagerContact = (formData, callback) => (dispatch) => {
  dispatch(createManagerContactStart());
  return _createManagerContactManager(formData).then((response) => {
    if(response){
      toast.success('Manager Contact Created') }
    dispatch(createManagerContactSuccess(response));
    callback()
  }).catch((error) => {
    dispatch(createManagerContactError(error));
    callback()

  });
};





export const changePasswordStart = createAction(ActionTypes.CHANGE_PASSWORD);
export const changePasswordSuccess = createAction(ActionTypes.CHANGE_PASSWORD_SUCCESS, response => response);
export const changePasswordError = createAction(ActionTypes.CHANGE_PASSWORD_ERROR, error => error);

export const changePassword = (token, data,callback,callbackError) => (dispatch) => {
  dispatch(changePasswordStart());
  return _changePassword(token, data).then((response) => {
    if(response){
      toast.success('Password changed successfully')
        callback(response)
    }
    dispatch(changePasswordSuccess(response));
  }).catch((error) => {
    dispatch(changePasswordError(error));
    if(error && error?.old_password) {
      toast.error( "Your old password was entered incorrectly")
  } else{
    toast.error('Something went wrong')
  }
  setTimeout(()=>{
    callbackError(error)
  },900)


  });
};

export const getGroupListStart = createAction(ActionTypes.GROUP_LIST);
export const getGroupListSuccess = createAction(ActionTypes.GROUP_LIST_SUCCESS, response => response);
export const getGroupListError = createAction(ActionTypes.GROUP_LIST_ERROR, error => error);

export const getGroupList = (token) => (dispatch) => {
  dispatch(getGroupListStart());
  return _getGroupList(token).then((response) => {
    dispatch(getGroupListSuccess(response));
  }).catch((error) => {
    dispatch(getGroupListError(error));
  });
};

export const getTransactionHistoryStart = createAction(ActionTypes.SUBSCRIPTION_HISTORY);
export const getTransactionHistorySuccess = createAction(ActionTypes.SUBSCRIPTION_HISTORY_SUCCESS, response => response);
export const getTransactionHistoryError = createAction(ActionTypes.SUBSCRIPTION_HISTORY_ERROR, error => error);

export const getTransactionHistory = (token,page) => (dispatch) => {
  dispatch(getTransactionHistoryStart());
  return _getTransactionHistory(token,page).then((response) => {
    dispatch(getTransactionHistorySuccess(response));
  }).catch((error) => {
    dispatch(getTransactionHistoryError(error));
  });
};

export const getCardDetailsStart = createAction(ActionTypes.GET_CARD_DETAILS);
export const getCardDetailsSuccess = createAction(ActionTypes.GET_CARD_DETAILS_SUCCESS, response => response);
export const getCardDetailsError = createAction(ActionTypes.GET_CARD_DETAILS_ERROR, error => error);

export const getCardDetails = (token) => (dispatch) => {
  dispatch(getCardDetailsStart());
  return _getCardDetails(token).then((response) => {
    dispatch(getCardDetailsSuccess(response));
  }).catch((error) => {
    dispatch(getCardDetailsError(error));
  });
};

export const deleteCardStart = createAction(ActionTypes.DELETE_CARD);
export const deleteCardSuccess = createAction(ActionTypes.DELETE_CARD_SUCCESS, response => response);
export const deleteCardError = createAction(ActionTypes.DELETE_CARD_ERROR, error => error);

export const deleteCard = (token,callback) => (dispatch) => {
  dispatch(deleteCardStart());
  return _deleteCardDetails(token).then((response) => {
    dispatch(deleteCardSuccess(response));
    toast.success('Card deleted successfully')
    callback('deleted')
  }).catch((error) => {
    dispatch(deleteCardError(error));
  });
};

export const disableSubscriptionStart = createAction(ActionTypes.DISABLE_SUBSCRIPTION);
export const disableSubscriptionSuccess = createAction(ActionTypes.DISABLE_SUBSCRIPTION_SUCCESS, response => response);
export const disableSubscriptionError = createAction(ActionTypes.DISABLE_SUBSCRIPTION_ERROR, error => error);

export const cancelSubscription = (data,callback) => (dispatch) => {
  dispatch(disableSubscriptionStart());
  return _cancelSubscription(data).then((response) => {
    dispatch(disableSubscriptionSuccess(response));
        toast.success('subscription cancelled successfully');
    callback('deleted');
  }).catch((error) => {
    dispatch(disableSubscriptionError(error));
    toast.success('Something went wrong');
  });
};

export const updateCardStart = createAction(ActionTypes.UPDATE_CARD);
export const  updateCardSuccess = createAction(ActionTypes.UPDATE_CARD_SUCCESS, response => response);
export const  updateCardError = createAction(ActionTypes.UPDATE_CARD_ERROR, error => error);

export const updateCard = (token, data,callback) => (dispatch) => {
  dispatch(updateCardStart());
  return _saveCard(token, data).then((response) => {
    dispatch(updateCardSuccess(response));
    toast.success('Card updated successfully')
    callback('res')
  }).catch((error) => {
    dispatch(updateCardError(error));
    if(error && error?.errorMeassge) {
      toast.error(error?.errorMeassge)
    }else{
      toast.error('Something went wrong')
    }
  });
};



export const getSubscriptionStart = createAction(ActionTypes.SUBSCRIPTION_DETAILS);
export const getSubscriptionSuccess = createAction(ActionTypes.SUBSCRIPTION_DETAILS_SUCCESS, response => response);
export const getSubscriptionError = createAction(ActionTypes.SUBSCRIPTION_DETAILS_ERROR, error => error);

export const getSubscription = (userId, token) => (dispatch) => {
  dispatch(getSubscriptionStart());
  return _getSubscription(userId, token).then((response) => {
    dispatch(getSubscriptionSuccess(response));
  }).catch((error) => {
    dispatch(getSubscriptionError(error));
  });
};

export const selectPlanStart = createAction(ActionTypes.SELECT_PLAN);
export const  selectPlanSuccess = createAction(ActionTypes.SELECT_PLAN_SUCCESS, response => response);
export const  selectPlanError = createAction(ActionTypes.SELECT_PLAN_ERROR, error => error);

export const selectPlan = (token, data) => (dispatch) => {
  dispatch(selectPlanStart());
  return _selectedPlan(token, data).then((response) => {
    dispatch(selectPlanSuccess(response));
  }).catch((error) => {
    dispatch(selectPlanError(error));
  });
};

// apply coupon
export const applyCouponStart = createAction(ActionTypes.COUPON_CODE);
export const applyCouponSuccess = createAction(ActionTypes.COUPON_CODE_SUCCESS, response => response);
export const applyCouponError = createAction(ActionTypes.COUPON_CODE_ERROR, error => error);

export const applyCoupon = (formData,callback) => (dispatch) => {
    dispatch(applyCouponStart(formData));
    return _couponCode(formData).then((response) => {
        dispatch(applyCouponSuccess(response));
      if(response){
        callback(response)
        toast.success('Coupon Applied')

      }
    }).catch((error) => {
        dispatch(applyCouponError(error));
      dispatch(applyCouponError(error));
      if(error?.error?.errorMessage){
        toast.error(error?.error?.errorMessage)
      }else{
        toast.error('Something went wrong')
      }
    });
};

const reducer = handleActions({

  //PROFILE_DETAILS
  [ActionTypes.GET_PROFILE]: (state) => ({
    ...state, profile_details: {
      ...state.profile_details,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    profile_details: {
      ...state.profile_details,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_PROFILE_ERROR]: (state, action) => ({
    ...state,
    profile_details: {
      ...state.profile_details,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

  //UPDATE_PROFILE

  [ActionTypes.PROFILE_UPDATE]: (state) => ({
    ...state, profile_update: {
      ...state.profile_update,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.PROFILE_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    profile_update: {
      ...state.profile_update,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),

  [ActionTypes.PROFILE_UPDATE_ERROR]: (state, action) => ({
    ...state,
    profile_update: {
      ...state.profile_update,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

//UPDATE_PROFILE_IMAGE

[ActionTypes.PROFILE_IMAGE_UPDATE]: (state) => ({
  ...state, profile_image: {
    ...state.profile_image,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.PROFILE_IMAGE_UPDATE_SUCCESS]: (state, action) => ({
  ...state,
  profile_image: {
    ...state.profile_image,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.PROFILE_IMAGE_UPDATE_ERROR]: (state, action) => ({
  ...state,
  profile_image: {
    ...state.profile_image,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//GET_CONTACT

[ActionTypes.GET_CONTACT]: (state) => ({
  ...state, contact_manager: {
    ...state.contact_manager,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.GET_CONTACT_SUCCESS]: (state, action) => ({
  ...state,
  contact_manager: {
    ...state.contact_manager,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.GET_CONTACT_ERROR]: (state, action) => ({
  ...state,
  contact_manager: {
    ...state.contact_manager,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

////GET_MANAGER_CONTACT

[ActionTypes.GET_MANAGER_CONTACT]: (state) => ({
  ...state,  manager_contact_details: {
    ...state. manager_contact_details,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.GET_MANAGER_CONTACT_SUCCESS]: (state, action) => ({
  ...state,
  manager_contact_details: {
    ...state. manager_contact_details,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.GET_MANAGER_CONTACT_ERROR]: (state, action) => ({
  ...state,
  manager_contact_details: {
    ...state. manager_contact_details,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//edit manager contact

[ActionTypes.EDIT_MANAGER_CONTACT]: (state) => ({
  ...state,  edit_manager_contact_details: {
    ...state. edit_manager_contact_details,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.EDIT_MANAGER_CONTACT_SUCCESS]: (state, action) => ({
  ...state,
  edit_manager_contact_details: {
    ...state.  edit_manager_contact_details,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.EDIT_MANAGER_CONTACT_ERROR]: (state, action) => ({
  ...state,
  edit_manager_contact_details: {
    ...state. edit_manager_contact_details,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),
//create manager contact
[ActionTypes.CREATE_MANAGER_CONTACT]: (state) => ({
  ...state,  create_manager_contact_details: {
    ...state. create_manager_contact_details,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.CREATE_MANAGER_CONTACT_SUCCESS]: (state, action) => ({
  ...state,
  create_manager_contact_details: {
    ...state.  create_manager_contact_details,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.CREATE_MANAGER_CONTACT_ERROR]: (state, action) => ({
  ...state,
  create_manager_contact_details: {
    ...state.  create_manager_contact_details,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//delete manager contact
[ActionTypes.DELETE_MANAGER_CONTACT]: (state) => ({
  ...state,  delete_manager_contact_details: {
    ...state. delete_manager_contact_details,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.DELETE_MANAGER_CONTACT_SUCCESS]: (state, action) => ({
  ...state,
  delete_manager_contact_details: {
    ...state.  delete_manager_contact_details,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.DELETE_MANAGER_CONTACT_ERROR]: (state, action) => ({
  ...state,
  delete_manager_contact_details: {
    ...state. delete_manager_contact_details,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//CHANGE_PASSWORD

[ActionTypes.CHANGE_PASSWORD]: (state) => ({
  ...state, change_password: {
    ...state.change_password,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.CHANGE_PASSWORD_SUCCESS]: (state, action) => ({
  ...state,
  change_password: {
    ...state.change_password,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.CHANGE_PASSWORD_ERROR]: (state, action) => ({
  ...state,
  change_password: {
    ...state.change_password,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//GET_GROUP_LIST

[ActionTypes.GROUP_LIST]: (state) => ({
  ...state, group_list: {
    ...state.group_list,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.GROUP_LIST_SUCCESS]: (state, action) => ({
  ...state,
  group_list: {
    ...state.group_list,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.GROUP_LIST_ERROR]: (state, action) => ({
  ...state,
  group_list: {
    ...state.group_list,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),


//GET_TRANSACTION_HISTORY

[ActionTypes.SUBSCRIPTION_HISTORY]: (state) => ({
  ...state, transaction_history: {
    ...state.transaction_history,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.SUBSCRIPTION_HISTORY_SUCCESS]: (state, action) => ({
  ...state,
  transaction_history: {
    ...state.transaction_history,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.SUBSCRIPTION_HISTORY_ERROR]: (state, action) => ({
  ...state,
  transaction_history: {
    ...state.transaction_history,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//GET_CARD_DETAILS

[ActionTypes.GET_CARD_DETAILS]: (state) => ({
  ...state, card_details: {
    ...state.card_details,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.GET_CARD_DETAILS_SUCCESS]: (state, action) => ({
  ...state,
  card_details: {
    ...state.card_details,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.GET_CARD_DETAILS_ERROR]: (state, action) => ({
  ...state,
  card_details: {
    ...state.card_details,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//DELETE_CARD

[ActionTypes.DELETE_CARD]: (state) => ({
  ...state, delete_card_details: {
    ...state.delete_card_details,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.DELETE_CARD_SUCCESS]: (state, action) => ({
  ...state,
  delete_card_details: {
    ...state.delete_card_details,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.DELETE_CARD_ERROR]: (state, action) => ({
  ...state,
  delete_card_details: {
    ...state.delete_card_details,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

  //CANCEL SUBSCRIPTION

  [ActionTypes.DISABLE_SUBSCRIPTION]: (state) => ({
    ...state, disable_card: {
      ...state.disable_card,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.DISABLE_SUBSCRIPTION_SUCCESS]: (state, action) => ({
    ...state,
    disable_card: {
      ...state.disable_card,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.DISABLE_SUBSCRIPTION_ERROR]: (state, action) => ({
    ...state,
    disable_card: {
      ...state.disable_card,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),
//UPDATE_CARD


[ActionTypes.UPDATE_CARD]: (state) => ({
  ...state, update_card: {
    ...state.update_card,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.UPDATE_CARD_SUCCESS]: (state, action) => ({
  ...state,
  update_card: {
    ...state.update_card,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.UPDATE_CARD_ERROR]: (state, action) => ({
  ...state,
  update_card: {
    ...state.update_card,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//UPGRADE_PLAN

[ActionTypes.UPGRADE_PLAN]: (state) => ({
  ...state, upgrade_plan: {
    ...state.upgrade_plan,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.UPGRADE_PLAN_SUCCESS]: (state, action) => ({
  ...state,
  upgrade_plan: {
    ...state.upgrade_plan,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.UPGRADE_PLAN_ERROR]: (state, action) => ({
  ...state,
  upgrade_plan: {
    ...state.upgrade_plan,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//SUBSCRIPTION DETAILS

[ActionTypes.SUBSCRIPTION_DETAILS]: (state) => ({
  ...state, subscription_details: {
    ...state.subscription_details,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.SUBSCRIPTION_DETAILS_SUCCESS]: (state, action) => ({
  ...state,
  subscription_details: {
    ...state.subscription_details,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.SUBSCRIPTION_DETAILS_ERROR]: (state, action) => ({
  ...state,
  subscription_details: {
    ...state.subscription_details,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//SELECT PLAN

[ActionTypes.SELECT_PLAN]: (state) => ({
  ...state, select_plan: {
    ...state.select_plan,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.SELECT_PLAN_SUCCESS]: (state, action) => ({
  ...state,
  select_plan: {
    ...state.select_plan,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.SELECT_PLAN_ERROR]: (state, action) => ({
  ...state,
  select_plan: {
    ...state.select_plan,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

 // apply coupon
 [ActionTypes.COUPON_CODE]: (state) => ({
  ...state,coupon_code:{
    ...state.coupon_code,
    loading: true, hasError: false, error: {}

  }
}),
[ActionTypes.COUPON_CODE_SUCCESS]: (state, action) => ({
  ...state,coupon_code:{
    ...state.coupon_code,
    response: action.payload, loading: false, hasError: false, error: {}

  }

}),
[ActionTypes.COUPON_CODE_ERROR]: (state, action) => ({
  ...state,coupon_code:{
    ...state.coupon_code,
    error: action.payload, loading: false, hasError: true, response: {}

  }
}),

}, initialState);

export default reducer;
