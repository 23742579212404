import { createAction, handleActions } from 'redux-actions';
import { _getManagerCourses,
  _getMembersListALL ,
  _countMemberTeams,
   _getAllGroupsListALL ,
   _addMemberList,  
   _assignManager,
    _removeManager,
    _membersBulkDelete,
    _memberDelete  ,
    _addNewGroup,
    _groupDelete,
    _groupsBulkDelete,
    _getMemberDetail ,
    _getGroupDetail,
    _deleteMemberCourses,
    _deleteMemberLearningPath ,
    _getMemberLearningPath ,
    _getMemberCourses ,
    _getMemberSurvey,
    _deleteMemberSurvey,
    _deleteGroupLearningPath ,
    _getGroupSurvey,
    _deleteGroupSurvey,
    _getGroupLearningPath,
    _deleteGroupCourses,
    _getGroupCourses,
    _previousMembers,
    _nextMembers,
    _previousGroups,
    _nextGroups ,
    _assignSurveysToGroup,
    _assignSurveysToMembers,
    _assignLPToGroup,
    _assignLPToMembers,
    _assignCoursesToGroup,
    _assignCoursesToMembers,
    _updateGroup,
    _getLP,
    _assignLPToMultipleGroups,
    _learnerGroups ,
    _resendEmail,
    _checkTrialPlan,
    _assignCourse

  } from '~shared/httpService/api';
import ActionTypes from '~shared/constants/actionTypes';
import { id } from 'date-fns/locale';
import { ToastContainer, toast } from 'react-toastify';
import { _getClassDetail } from '../../../shared/httpService/api';



const initialState = {
  membersData:[],
  IsMembersData:false,
  paginatedMembers:{},
  editData:{},
  IsSurveyData:false,
  IsGroupData:false,
  paginatedGroup:{},
  group_list:{loading: false, response: {}, hasError: false, error: {}},
  learner_group_list:{loading: false, response: {}, hasError: false, error: {}},
  group_update:{loading: false, response: {}, hasError: false, error: {}},
  members_list:{loading: false, response: {}, hasError: false, error: {}},
  memberslist:{loading: false, response: {}, hasError: false, error: {}},
  courses_list: { loading: false, response: {}, hasError: false, error: {} },
  new_member: { loading: false, response: {}, hasError: false, error: {} },
  count_member: { loading: false, response: {}, hasError: false, error: {} },
  new_group: { loading: false, response: {}, hasError: false, error: {} },
  addManager_list: { loading: false, response: {}, hasError: false, error: {} },
  removeManager_list: { loading: false, response: {}, hasError: false, error: {} },
  removeMember: { loading: false, response: {}, hasError: false, error: {} },
  removeMember_list: { loading: false, response: {}, hasError: false, error: {} },
  removeGroup: { loading: false, response: {}, hasError: false, error: {} },
  removeGroup_list: { loading: false, response: {}, hasError: false, error: {} },
  memberDetails: { loading: false, response: {}, hasError: false, error: {} },
 groupDetails: { loading: false, response: {}, hasError: false, error: {} },
 classDetails: { loading: false, response: {}, hasError: false, error: {} },
 memberCourses: { loading: false, response: {}, hasError: false, error: {} },
 memberCoursesDelete: { loading: false, response: {}, hasError: false, error: {} },
 memberLP: { loading: false, response: {}, hasError: false, error: {} },
 memberLPDelete: { loading: false, response: {}, hasError: false, error: {} },
 memberSurvey: { loading: false, response: {}, hasError: false, error: {} },
 memberSurveyDelete: { loading: false, response: {}, hasError: false, error: {} },
 survey_assignment:{ loading: false, response: {}, hasError: false, error: {} },
 survey_assignment_members:{ loading: false, response: {}, hasError: false, error: {} },
groupCourses: { loading: false, response: {}, hasError: false, error: {} },
 groupCoursesDelete: { loading: false, response: {}, hasError: false, error: {} },
 groupLP: { loading: false, response: {}, hasError: false, error: {} },
 totalLP: { loading: false, response: {}, hasError: false, error: {} },
 groupLPDelete: { loading: false, response: {}, hasError: false, error: {} },
 groupSurvey: { loading: false, response: {}, hasError: false, error: {} },
 groupSurveyDelete: { loading: false, response: {}, hasError: false, error: {} },
 assign_member_course: { loading: false, response: {}, hasError: false, error: {} },
assign_course: { loading: false, response: {}, hasError: false, error: {} },
assign_course_groups: { loading: false, response: {}, hasError: false, error: {} },
assign_lp: { loading: false, response: {}, hasError: false, error: {} },
assign_lp_groups: { loading: false, response: {}, hasError: false, error: {} },
assign_lp_multiple_groups: { loading: false, response: {}, hasError: false, error: {} },
send_mail: { loading: false, response: {}, hasError: false, error: {} },
checkTrialPlan: { loading: false, response: {}, hasError: false, error: {} },

  selectdMembers_list:[]

};

export const getSelectedMembers = createAction(ActionTypes.SELECTED_MEMBERS);


//listing members

export const getMembersStart = createAction(ActionTypes. GET_MEMBERS);
export const getMembersSuccess = createAction(ActionTypes.  GET_MEMBERS_SUCCESS, response => response);
export const getMembersError = createAction(ActionTypes. GET_MEMBERS_ERROR, error => error);

export const getMembersList= (token,search,memberType,index)=>(dispatch)=>{
  dispatch(getMembersStart ());
  return  _getMembersListALL(token,search,memberType,index).then((response)=>{
    dispatch(getMembersSuccess(response));
  }).catch((error)=>{
    dispatch(getMembersError(error));
    toast.error('Something went wrong')
  })
}



//listing members

export const MembersStart = createAction(ActionTypes. GET_NEW_MEMBERS);
export const MembersSuccess = createAction(ActionTypes.  GET_NEW_MEMBERS_SUCCESS, response => response);
export const MembersError = createAction(ActionTypes. GET_NEW_MEMBERS_ERROR, error => error);

export const getNewMembers= (token,search,memberType)=>(dispatch)=>{
  dispatch(MembersStart ());
  return  _getMembersListALL(token,search,memberType).then((response)=>{
    dispatch(MembersSuccess(response));
  }).catch((error)=>{
    dispatch(MembersError(error));
    toast.error('Something went wrong')
  })
}

//listing groups
export const getGroupsStart = createAction(ActionTypes.  GET_ALL_GROUPS);
export const getGroupsSuccess = createAction(ActionTypes.  GET_ALL_GROUPS_SUCCESS, response => response);
export const getGroupsError = createAction(ActionTypes.  GET_ALL_GROUPS_ERROR, error => error);

export const getAllGroups= (token,search,created_by,index)=>(dispatch)=>{
  dispatch(getGroupsStart ());
  return _getAllGroupsListALL (token,search,created_by,index).then((response)=>{
    dispatch(getGroupsSuccess(response));
  }).catch((error)=>{
    dispatch(getGroupsError (error));
    toast.error('Something went wrong')
  })
}


//listing learner groups
export const getLearnerGroupsStart = createAction(ActionTypes.  GET_ALL_LEARNER_GROUPS);
export const getLearnerGroupsSuccess = createAction(ActionTypes.  GET_ALL_LEARNER_GROUPS_SUCCESS, response => response);
export const getLearnerGroupsError = createAction(ActionTypes.  GET_ALL_LEARNER_GROUPS_ERROR, error => error);

export const getAllLearnerGroups= (search,createdby, page)=>(dispatch)=>{
  dispatch(getLearnerGroupsStart ());
  return  _learnerGroups (search,createdby, page).then((response)=>{
    dispatch(getLearnerGroupsSuccess(response));
  }).catch((error)=>{
    dispatch(getLearnerGroupsError (error));
    toast.error('Something went wrong')
  })
}




//update groups
export const updateGroupsStart = createAction(ActionTypes. UPDATE_GROUPS);
export const updateGroupsSuccess = createAction(ActionTypes. UPDATE_GROUPS_SUCCESS, response => response);
export const updateGroupsError = createAction(ActionTypes. UPDATE_GROUPS_ERROR, error => error);

export const updateGroups= (id,data)=>(dispatch)=>{
 dispatch(updateGroupsStart  ());
  return _updateGroup (id,data).then((response)=>{
    dispatch(updateGroupsSuccess(response)); if (response) {
      toast.success('Group Details Updated')

        }
  }).catch((error)=>{
    dispatch(updateGroupsError (error));
    toast.error('Something went wrong')
  })
}


// //members pagination
// export const paginationStart = createAction(ActionTypes.PAGINATION_NEXT);
// export const paginationSuccess = createAction(ActionTypes.PAGINATION_NEXT_SUCCESS, response => response);
// export const paginationError = createAction(ActionTypes.PAGINATION_NEXT_ERROR, error => error);

// export const handlePagination = (url) => (dispatch) => {
//     dispatch(paginationStart());
//     return _nextMembers(url).then((response) => {
//         dispatch(paginationSuccess(response));
//     }).catch((error) => {
//         dispatch(paginationError(error));
//         toast.error('Something went wrong')
//     });
// };

//TEAMS MEMBERS PAGINATION
export const paginationTeamsMembersStart = createAction(ActionTypes.TEAMS_MEMBERS_PAGINATION_NEXT);
export const paginationTeamsMembersSuccess = createAction(ActionTypes.TEAMS_MEMBERS_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationTeamsMembersError = createAction(ActionTypes.TEAMS_MEMBERS_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationTeamsMembers = (url) => (dispatch) => {
 dispatch(paginationTeamsMembersStart());
 return _nextMembers(url).then((response) => {
  dispatch(paginationTeamsMembersSuccess(response));
 }).catch((error) => {
  dispatch(paginationTeamsMembersError(error));
  toast.error('Something went wrong')
 });
};

export const paginationPreviousTeamsMembersStart = createAction(ActionTypes.TEAMS_MEMBERS_PAGINATION_PREVIOUS);
export const paginationPreviousTeamsMembersSuccess = createAction(ActionTypes.TEAMS_MEMBERS_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousTeamsMembersError = createAction(ActionTypes.TEAMS_MEMBERS_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationTeamsMembers = (url) => (dispatch) => {
    dispatch(paginationPreviousTeamsMembersStart());
    return _previousMembers(url).then((response) => {
        dispatch(paginationPreviousTeamsMembersSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousTeamsMembersError(error));
        toast.error('Something went wrong')

    });
};

// export const paginationPreviousStart = createAction(ActionTypes.PAGINATION_PREVIOUS);
// export const paginationPreviousSuccess = createAction(ActionTypes.PAGINATION_PREVIOUS_SUCCESS, response => response);
// export const paginationPreviousError = createAction(ActionTypes.PAGINATION_PREVIOUS_ERROR, error => error);

// export const handlePreviousPagination = (url) => (dispatch) => {
//     dispatch(paginationPreviousStart());
//     return _previousMembers(url).then((response) => {
//         dispatch(paginationPreviousSuccess(response));
//     }).catch((error) => {
//         dispatch(paginationPreviousError(error));
//         toast.error('Something went wrong')

//     });
// };
//members courses pagination
export const paginationStart_Courses = createAction(ActionTypes. LEARNER_COURSE_PAGINATION_NEXT);
export const paginationSuccess_Courses = createAction(ActionTypes.LEARNER_COURSE_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationError_Courses = createAction(ActionTypes.LEARNER_COURSE_PAGINATION_NEXT_ERROR, error => error);

export const handlePagination_Courses = (url) => (dispatch) => {
    dispatch(paginationStart_Courses());
    return _nextMembers(url).then((response) => {
        dispatch(paginationSuccess_Courses(response));
    }).catch((error) => {
        dispatch(paginationError_Courses(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousStart_Courses = createAction(ActionTypes. LEARNER_COURSE_PAGINATION_PREVIOUS);
export const paginationPreviousSuccess_Courses = createAction(ActionTypes. LEARNER_COURSE_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousError_Courses = createAction(ActionTypes. LEARNER_COURSE_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPagination_Courses = (url) => (dispatch) => {
    dispatch(paginationPreviousStart_Courses());
    return _previousMembers(url).then((response) => {
        dispatch(paginationPreviousSuccess_Courses(response));
    }).catch((error) => {
        dispatch(paginationPreviousError_Courses(error));
        toast.error('Something went wrong')

    });
};

//members LP pagination
export const paginationStart_LP = createAction(ActionTypes.  AVAILABLE_COURSE_PAGINATION_NEXT);
export const paginationSuccess_LP = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationError_LP = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT_ERROR, error => error);

export const handlePagination_LP = (url) => (dispatch) => {
    dispatch(paginationStart_LP());
    return _nextMembers(url).then((response) => {
        dispatch(paginationSuccess_LP(response));
    }).catch((error) => {
        dispatch(paginationError_LP(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousStart_LP = createAction(ActionTypes. AVAILABLE_COURSE_PAGINATION_PREVIOUS);
export const paginationPreviousSuccess_LP = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousError_LP = createAction(ActionTypes. AVAILABLE_COURSE_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPagination_LP = (url) => (dispatch) => {
    dispatch(paginationPreviousStart_LP());
    return _previousMembers(url).then((response) => {
        dispatch(paginationPreviousSuccess_LP(response));
    }).catch((error) => {
        dispatch(paginationPreviousError_LP(error));
        toast.error('Something went wrong')

    });
};

// Group Survey pagination
export const paginationStart_Survey = createAction(ActionTypes.PAGINATION_NEXT_SURVEY);
export const paginationSuccess_Survey = createAction(ActionTypes.PAGINATION_NEXT_SURVEY_SUCCESS, response => response);
export const paginationError_Survey = createAction(ActionTypes.PAGINATION_NEXT_SURVEY_ERROR, error => error);

export const handlePagination_Survey = (url) => (dispatch) => {
    dispatch(paginationStart_Survey());
    return _nextMembers(url).then((response) => {
        dispatch(paginationSuccess_Survey(response));
    }).catch((error) => {
        dispatch(paginationError_Survey(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousStart_Survey = createAction(ActionTypes.PAGINATION_PREVIOUS_SURVEY);
export const paginationPreviousSuccess_Survey = createAction(ActionTypes.PAGINATION_PREVIOUS_SURVEY_SUCCESS, response => response);
export const paginationPreviousError_Survey = createAction(ActionTypes.PAGINATION_PREVIOUS_SURVEY_ERROR, error => error);

export const handlePreviousPagination_Survey = (url) => (dispatch) => {
    dispatch(paginationPreviousStart_Survey());
    return _previousMembers(url).then((response) => {
        dispatch(paginationPreviousSuccess_Survey(response));
    }).catch((error) => {
        dispatch(paginationPreviousError_Survey(error));
        toast.error('Something went wrong')

    });
};

export const checkTrialPlanStart = createAction(ActionTypes.GET_SURVEYS_LISTING);
export const checkTrialPlanSuccess = createAction(ActionTypes.GET_SURVEYS_LISTING_SUCCESS, response => response);
export const checkTrialPlanError = createAction(ActionTypes.GET_SURVEYS_LISTING_ERROR, error => error);

export const checkTrialPlan = (callback) => (dispatch) => {

    dispatch(checkTrialPlanStart());
    return _checkTrialPlan().then((response) => {

      if(response){
        callback(response)

    }

        dispatch(checkTrialPlanSuccess (response));

    }).catch((error) => {
        dispatch(checkTrialPlanError (error));

        if(error) {
            // toast.error('Something went wrong')

        }
    });
};











//Groups courses pagination
export const paginationStart_Courses_Groups = createAction(ActionTypes. GROUP_COURSE_PAGINATION_NEXT);
export const paginationSuccess_Courses_Groups = createAction(ActionTypes.GROUP_COURSE_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationError_Courses_Groups = createAction(ActionTypes.GROUP_COURSE_PAGINATION_NEXT_ERROR, error => error);

export const handlePagination_Courses_Groups  = (url) => (dispatch) => {
    dispatch(paginationStart_Courses_Groups ());
    return _nextMembers(url).then((response) => {
        dispatch(paginationSuccess_Courses_Groups (response));
    }).catch((error) => {
        dispatch(paginationError_Courses_Groups (error));
        toast.error('Something went wrong')
    });
};


export const paginationPreviousStart_Courses_Groups = createAction(ActionTypes.  GROUP_COURSE_PAGINATION_PREVIOUS);
export const paginationPreviousSuccess_Courses_Groups = createAction(ActionTypes. GROUP_COURSE_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousError_Courses_Groups = createAction(ActionTypes.  GROUP_COURSE_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPagination_Courses_Groups = (url) => (dispatch) => {
    dispatch(paginationPreviousStart_Courses_Groups());
    return _previousMembers(url).then((response) => {
        dispatch(paginationPreviousSuccess_Courses_Groups(response));
    }).catch((error) => {
        dispatch(paginationPreviousError_Courses_Groups(error));
        toast.error('Something went wrong')

    });
};

///group lp pagination


export const paginationStart_LP_Groups = createAction(ActionTypes.  LP_GROUP_PAGINATION_NEXT);
export const paginationSuccess_LP_Groups = createAction(ActionTypes.LP_GROUP_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationError_LP_Groups = createAction(ActionTypes.LP_GROUP_PAGINATION_NEXT_ERROR, error => error);

export const handlePagination_LP_Groups = (url) => (dispatch) => {
    dispatch(paginationStart_LP_Groups());
    return _nextMembers(url).then((response) => {
        dispatch(paginationSuccess_LP_Groups(response));
    }).catch((error) => {
        dispatch(paginationError_LP_Groups(error));
        toast.error('Something went wrong')
    });
};




export const paginationPreviousStart_LP_Groups = createAction(ActionTypes. LP_GROUP_PAGINATION_PREVIOUS);
export const paginationPreviousSuccess_LP_Groups = createAction(ActionTypes. LP_GROUP_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousError_LP_Groups = createAction(ActionTypes. LP_GROUP_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPagination_LP_Groups = (url) => (dispatch) => {
    dispatch(paginationPreviousStart_LP_Groups());
    return _previousMembers(url).then((response) => {
        dispatch(paginationPreviousSuccess_LP_Groups(response));
    }).catch((error) => {
        dispatch(paginationPreviousError_LP_Groups(error));
        toast.error('Something went wrong')

    });
};



//group pagination
export const group_paginationStart = createAction(ActionTypes.GROUP_PAGINATION_NEXT);
export const group_paginationSuccess = createAction(ActionTypes.GROUP_PAGINATION_NEXT_SUCCESS, response => response);
export const  group_paginationError = createAction(ActionTypes.GROUP_PAGINATION_NEXT_ERROR, error => error);

export const handleGroupPagination = (url) => (dispatch) => {
    dispatch( group_paginationStart());
    return _nextGroups(url).then((response) => {
        dispatch( group_paginationSuccess(response));
    }).catch((error) => {
        dispatch(group_paginationError(error));
        toast.error('Something went wrong')
    });
};

export const  group_paginationPreviousStart = createAction(ActionTypes.GROUP_PAGINATION_PREVIOUS);
export const  group_paginationPreviousSuccess = createAction(ActionTypes.GROUP_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const  group_paginationPreviousError = createAction(ActionTypes.GROUP_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousGroupPagination = (url) => (dispatch) => {
    dispatch(group_paginationPreviousStart());
    return _previousGroups(url).then((response) => {
        dispatch( group_paginationPreviousSuccess(response));
    }).catch((error) => {
        dispatch(group_paginationPreviousError(error));
        toast.error('Something went wrong')

    });
};




//add new members

export const addNewMemberStart = createAction(ActionTypes.ADD_MEMBER);
export const addNewMemberSuccess = createAction(ActionTypes.ADD_MEMBER_SUCCESS, response => response);
export const addNewMemberError = createAction(ActionTypes.ADD_MEMBER_ERROR, error => error);

export const addNewMember = (data,type,callback,callbackError) => (dispatch) => {

  dispatch( addNewMemberStart());
  return _addMemberList(data,type).then((response) => {
    dispatch( addNewMemberSuccess(response));
    if (response) {
      callback(response)
      toast.success('Member has been added successfully')

        }
  }).catch((error) => {
    dispatch( addNewMemberError (error));
    if(error){

      callbackError(error)
  }
  });
};
//add check member count

export const checkMemberCountStart = createAction(ActionTypes.COUNT_MEMBER);
export const checkMemberCountSuccess = createAction(ActionTypes.COUNT_MEMBER_SUCCESS, response => response);
export const checkMemberCountError = createAction(ActionTypes.COUNT_MEMBER_ERROR, error => error);

export const checkMemberCount = (callback) => (dispatch) => {

  dispatch( checkMemberCountStart());
  return _countMemberTeams().then((response) => {
    dispatch( checkMemberCountSuccess(response));
    if (response) {
      callback(response)

        }
  }).catch((error) => {
    dispatch(checkMemberCountError (error));
    if(error?.error?.errorMessage){

      toast.error(error?.error?.errorMessage)
      // errorCallBack(error)
  }
  });
};
//add new group
export const addNewGroupStart = createAction(ActionTypes.ADD_GROUP);
export const addNewGroupSuccess = createAction(ActionTypes.ADD_GROUP_SUCCESS, response => response);
export const addNewGroupError = createAction(ActionTypes.ADD_GROUP_ERROR, error => error);

export const addNewGroup = (token,name,data) => (dispatch) => {
  dispatch( addNewGroupStart());
  return _addNewGroup(token,name,data).then((response) => {
    dispatch( addNewGroupSuccess(response));
    if (response) {
      toast.success('New Group Created Successfully')
      history.push('/team');    }
  }).catch((error) => {
    dispatch( addNewGroupError (error));
    if(error?.error?.errorMessage){

      toast.error(error?.error?.errorMessage)

  }
  });
};



//remove manager access

export const removeManagerStart = createAction(ActionTypes. REMOVE_MANAGER);
export const removemanagerSuccess = createAction(ActionTypes.REMOVE_MANAGER_SUCCESS, response => response);
export const removeManagerError = createAction(ActionTypes. REMOVE_MANAGER_ERROR, error => error);

export const removeManager = (token,selected) => (dispatch) => {
  dispatch( removeManagerStart());
  return _removeManager(token, selected).then((response) => {
    dispatch(removemanagerSuccess(response));
    toast.success('Successfully Changed')
  }).catch((error) => {
    dispatch( removeManagerError(error));

    if(error?.error?.errorMessage){
        toast.error(error?.error?.errorMessage)

    }else{
        toast.error('Something went wrong')
    }

  });
};

//assign as manager

export const assignManagerStart = createAction(ActionTypes. ASSIGN_MANAGER);
export const assignManagerSuccess = createAction(ActionTypes.ASSIGN_MANAGER_SUCCESS, response => response);
export const  assignManagerError = createAction(ActionTypes. ASSIGN_MANAGER_ERROR, error => error);

export const  assignManager = (token,selected) => (dispatch) => {
  dispatch( assignManagerStart());
  return _assignManager(token, selected).then((response) => {
    dispatch(assignManagerSuccess(response));
    toast.success('Successfully Changed')
  }).catch((error) => {
    dispatch( assignManagerError(error));
    if(error?.error?.errorMessage){

        toast.error(`${error?.error?.errorMessage}`)

    }else {


        toast.error('Something went wrong')
    }



  });
};

//delete member
export const removeMemberStart = createAction(ActionTypes. REMOVE_MEMBER);
export const removeMemberSuccess = createAction(ActionTypes.REMOVE_MEMBER_SUCCESS, response => response);
export const removeMemberError = createAction(ActionTypes. REMOVE_MEMBER_ERROR, error => error);

export const removeMember = (token,id) => (dispatch) => {
  dispatch( removeMemberStart());
  return _memberDelete(token,id).then((response) => {
    dispatch(removeMemberSuccess(response));
    toast.success('Member Deleted')
  }).catch((error) => {
    dispatch( removeMemberError(error));
    toast.error('Something went wrong')
  });
};

//bulk delete member
export const removeMemberBulkStart = createAction(ActionTypes. REMOVE_MEMBER_BULK);
export const removeMemberBulkSuccess = createAction(ActionTypes.REMOVE_MEMBER_BULK_SUCCESS, response => response);
export const removeMemberBulkError = createAction(ActionTypes. REMOVE_MEMBER_BULK_ERROR, error => error);

export const removeMemberBulk = (token,list,callback) => (dispatch) => {
  dispatch( removeMemberBulkStart());
  return _membersBulkDelete(token,list).then((response) => {
    dispatch(removeMemberBulkSuccess(response));
    toast.success('Members Deleted')
    callback()
  }).catch((error) => {
    dispatch( removeMemberBulkError(error));
    toast.error('Something went wrong')
    callback()
  });
};



//delete group
export const removeGroupStart = createAction(ActionTypes. REMOVE_GROUP);
export const removeGroupSuccess = createAction(ActionTypes.REMOVE_GROUP_SUCCESS, response => response);
export const removeGroupError = createAction(ActionTypes. REMOVE_GROUP_ERROR, error => error);

export const removeGroup = (token,id) => (dispatch) => {
  dispatch( removeGroupStart());
  return _groupDelete(token,id).then((response) => {
    dispatch(removeGroupSuccess(response));
    toast.success('Group Deleted')
  }).catch((error) => {
    dispatch( removeGroupError(error));
    toast.error('Something went wrong')
  });
};


export const assignMemberCourseStart = createAction(ActionTypes.ASSIGN_MEMBER_COURSE);
export const assignMemberCourseSuccess = createAction(ActionTypes.ASSIGN_MEMBER_COURSE_SUCCESS, response => response);
export const assignMemberCourseError = createAction(ActionTypes.ASSIGN_MEMBER_COURSE_ERROR, error => error);

export const assignMemberCourse = (data, userId,history,callback) => (dispatch) => {
    dispatch(assignMemberCourseStart());
    return _assignCourse(data, userId).then((response) => {
        if(response){
            toast.success('Course has been  assigned  successfully')
           
        }
        dispatch(assignMemberCourseSuccess(response));
       
    }).catch((error) => {
        dispatch(assignMemberCourseError(error));
        if(error){
           
            if(error?.error?.errorMessage) {
                toast.error(error?.error?.errorMessage)
            }else{
              
                toast.error('Something went  wrong')
            }


            callback(error)
        }
    });
};


//bulk delete group
export const removeGroupBulkStart = createAction(ActionTypes. REMOVE_GROUP_BULK);
export const removeGroupBulkSuccess = createAction(ActionTypes.REMOVE_GROUP_BULK_SUCCESS, response => response);
export const removeGroupBulkError = createAction(ActionTypes. REMOVE_GROUP_BULK_ERROR, error => error);

export const removeGroupBulk = (token,list) => (dispatch) => {
  dispatch( removeGroupBulkStart());
  return _groupsBulkDelete(token,list).then((response) => {
    dispatch(removeGroupBulkSuccess(response));
    toast.success('Groups Deleted')
  }).catch((error) => {
    dispatch( removeGroupBulkError(error));
    toast.error('Something went wrong')
  });
};

//member detail

export const getMemberDetailStart = createAction(ActionTypes. GET_MEMBER_DETAIL);
export const getMemberDetailSuccess = createAction(ActionTypes.  GET_MEMBER_DETAIL_SUCCESS, response => response);
export const getMemberDetailError = createAction(ActionTypes. GET_MEMBER_DETAIL_ERROR, error => error);

export const getMemberDetail= (token,id)=>(dispatch)=>{
  dispatch(getMemberDetailStart ());
  return  _getMemberDetail(token,id).then((response)=>{
    dispatch( getMemberDetailSuccess(response));
  }).catch((error)=>{
    dispatch( getMemberDetailError(error));
    toast.error('Something went wrong')

  })
}

//group detail
export const getGroupDetailStart = createAction(ActionTypes.  GET_GROUP_DETAIL);
export const getGroupDetailSuccess = createAction(ActionTypes.   GET_GROUP_DETAIL_SUCCESS, response => response);
export const getGroupDetailError = createAction(ActionTypes.   GET_GROUP_DETAIL_ERROR, error => error);

export const getGroupDetail= (token,id)=>(dispatch)=>{
  dispatch(getGroupDetailStart ());
  return  _getGroupDetail(token,id).then((response)=>{
    dispatch(getGroupDetailSuccess(response));
  }).catch((error)=>{
    dispatch(getGroupDetailError (error));
    toast.error('Something went wrong')
  })
}

// CLASS DETAIL


export const getClassDetailStart = createAction(ActionTypes.GET_CLASS_DETAIL);
export const getClassDetailSuccess = createAction(ActionTypes.GET_CLASS_DETAIL_SUCCESS, response => response);
export const getClassDetailError = createAction(ActionTypes.GET_CLASS_DETAIL_ERROR, error => error);

export const getClassDetail= (token,id)=>(dispatch)=>{
  dispatch(getClassDetailStart ());

  return  _getClassDetail(token,id).then((response)=>{
    dispatch(getClassDetailSuccess(response));
  }).catch((error)=>{
    dispatch(getClassDetailError (error));
    toast.error('Something went wrong')
  })
}
//GET member courses

export const getMemberCoursesStart = createAction(ActionTypes.GET_MEMBER_COURSES);
export const getMemberCoursesSuccess = createAction(ActionTypes. GET_MEMBER_COURSES_SUCCESS, response => response);
export const getMemberCoursesError = createAction(ActionTypes. GET_MEMBER_COURSES_ERROR, error => error);

export const getMemberCourses= (token,id,search,index)=>(dispatch)=>{
   dispatch( getMemberCoursesStart ());
  return   _getMemberCourses (token,id,search,index).then((response)=>{
    dispatch( getMemberCoursesSuccess(response));
  }).catch((error)=>{
    dispatch( getMemberCoursesError(error));
    toast.error('Something went wrong')

  })
}



//GET member LP

export const getMemberLPStart = createAction(ActionTypes. GET_MEMBER_LP);
export const getMemberLPSuccess = createAction(ActionTypes. GET_MEMBER_LP_SUCCESS, response => response);
export const getMemberLPError = createAction(ActionTypes.GET_MEMBER_LP_ERROR, error => error);

export const getMemberLP= (token,id,search,index)=>(dispatch)=>{
  dispatch( getMemberLPStart ());
  return _getMemberLearningPath(token,id,search,index).then((response)=>{
    dispatch( getMemberLPSuccess(response));
  }).catch((error)=>{
    dispatch( getMemberLPError(error));
    toast.error('Something went wrong')
  })
}

//member courses delete

export const deleteMemberCoursesStart = createAction(ActionTypes. DELETE_MEMBER_COURSES );
export const deleteMemberCoursesSuccess = createAction(ActionTypes.  DELETE_MEMBER_COURSES_SUCCESS, response => response);
export const deleteMemberCoursesError = createAction(ActionTypes. DELETE_MEMBER_COURSES_ERROR, error => error);

export const deleteMemberCourses= (token,id,body)=>(dispatch)=>{
  dispatch( deleteMemberCoursesStart ());
  return _deleteMemberCourses(token,id,body).then((response)=>{
    dispatch( deleteMemberCoursesSuccess(response));
    if (response) {
      toast.success('Course Removed For The Member')

        }

  }).catch((error)=>{
    dispatch( deleteMemberCoursesError(error));
    toast.error('Something went wrong')
  })
}

//member lp delete

export const deleteMemberLPStart = createAction(ActionTypes. DELETE_MEMBER_LP);
export const deleteMemberLPSuccess = createAction(ActionTypes.  DELETE_MEMBER_LP_SUCCESS, response => response);
export const deleteMemberLPError = createAction(ActionTypes.  DELETE_MEMBER_LP_ERROR, error => error);

export const deleteMemberLP= (token,id,lpId,callback)=>(dispatch)=>{
  dispatch( deleteMemberLPStart ());
  return  _deleteMemberLearningPath (token,id,lpId).then((response)=>{
      dispatch(deleteMemberLPSuccess(response));
    if (true) {
      toast.success('Learning Path Removed For The Member')
        callback('success')
        }

  }).catch((error)=>{
    dispatch(deleteMemberLPError(error));
    toast.error('Something went wrong')
  })
}



//GET member Survey

export const getMemberSurveyStart = createAction(ActionTypes. GET_MEMBER_SURVEY);
export const getMemberSurveySuccess = createAction(ActionTypes. GET_MEMBER_SURVEY_SUCCESS, response => response);
export const getMemberSurveyError = createAction(ActionTypes.GET_MEMBER_SURVEY_ERROR, error => error);

export const getMemberSurvey= (token,id)=>(dispatch)=>{
  dispatch(getMemberSurveyStart ());
  return _getMemberSurvey(token,id).then((response)=>{
    dispatch(getMemberSurveySuccess(response));
  }).catch((error)=>{
    dispatch( getMemberSurveyError (error));
    toast.error('Something went wrong')
  })
}

//member Survey delete

export const deleteMemberSurveystart = createAction(ActionTypes. DELETE_MEMBER_SURVEY );
export const deleteMemberSurveySuccess = createAction(ActionTypes.  DELETE_MEMBER_SURVEY_SUCCESS, response => response);
export const deleteMemberSurveyError = createAction(ActionTypes. DELETE_MEMBER_SURVEY_ERROR, error => error);

export const deleteMemberSurvey= (token,id,data)=>(dispatch)=>{
  dispatch( deleteMemberSurveystart ());
  return _deleteMemberSurvey(token,id,data).then((response)=>{
    dispatch( deleteMemberSurveySuccess(response));
  }).catch((error)=>{
    dispatch( deleteMemberSurveyError(error));
    toast.error('Something went wrong')
  })
}



////////
//GET group courses

export const getGroupCoursesStart = createAction(ActionTypes.GET_GROUP_COURSES);
export const getGroupCoursesSuccess = createAction(ActionTypes. GET_GROUP_COURSES_SUCCESS, response => response);
export const getGroupCoursesError = createAction(ActionTypes. GET_GROUP_COURSES_ERROR, error => error);

export const getGroupCourses= (token,id,search,index)=>(dispatch)=>{
   dispatch( getGroupCoursesStart ());
  return   _getGroupCourses (token,id,search,index).then((response)=>{
    dispatch( getGroupCoursesSuccess(response));
  }).catch((error)=>{
    dispatch( getGroupCoursesError(error));
    toast.error('Something went wrong')
  })
}



//Group member LP

export const getGroupLPStart = createAction(ActionTypes. GET_GROUP_LP);
export const getGroupLPSuccess = createAction(ActionTypes. GET_GROUP_LP_SUCCESS, response => response);
export const getGroupLPError = createAction(ActionTypes.GET_GROUP_LP_ERROR, error => error);

export const getGroupLP= (token,id,search)=>(dispatch)=>{
  dispatch( getGroupLPStart ());
  return _getGroupLearningPath(token,id,search).then((response)=>{
    dispatch( getGroupLPSuccess(response));
  }).catch((error)=>{
    dispatch( getGroupLPError(error));
    toast.error('Something went wrong')
  })
}


//get Manager LP

export const getLPStart = createAction(ActionTypes. GET_LP);
export const getLPSuccess = createAction(ActionTypes. GET_LP_SUCCESS, response => response);
export const getLPError = createAction(ActionTypes.GET_LP_ERROR, error => error);

export const getLP= ()=>(dispatch)=>{
  dispatch( getLPStart ());
  return _getLP().then((response)=>{
    dispatch( getLPSuccess(response));
  }).catch((error)=>{
    dispatch( getLPError(error));
    toast.error('Something went wrong')
  })
}





//Group courses delete

export const deleteGroupCoursesStart = createAction(ActionTypes. DELETE_GROUP_COURSES );
export const deleteGroupCoursesSuccess = createAction(ActionTypes.  DELETE_GROUP_COURSES_SUCCESS, response => response);
export const deleteGroupCoursesError = createAction(ActionTypes. DELETE_GROUP_COURSES_ERROR, error => error);

export const deleteGroupCourses= (token,id,data)=>(dispatch)=>{
  dispatch( deleteGroupCoursesStart ());
  return _deleteGroupCourses(token,id,data).then((response)=>{
    dispatch( deleteGroupCoursesSuccess(response));
    if (response) {
      toast.success('Courses Removed For The Group')

        }
  }).catch((error)=>{
    dispatch( deleteGroupCoursesError(error));
    toast.error('Something went wrong')
  })
}

//Group lp delete

export const deleteGroupLPStart = createAction(ActionTypes. DELETE_GROUP_LP);
export const deleteGroupLPSuccess = createAction(ActionTypes.  DELETE_GROUP_LP_SUCCESS, response => response);
export const deleteGroupLPError = createAction(ActionTypes.  DELETE_GROUP_LP_ERROR, error => error);

export const deleteGroupLP= (token,id,data)=>(dispatch)=>{
  dispatch( deleteGroupLPStart ());
  return  _deleteGroupLearningPath (token,id,data).then((response)=>{
    dispatch(  deleteGroupLPSuccess(response));
    if (response) {
      toast.success('Learning Path Removed For The Group')

        }
  }).catch((error)=>{
    dispatch(deleteGroupLPError(error));
    toast.error('Something went wrong')
  })
}



//GET Group Survey

export const getGroupSurveyStart = createAction(ActionTypes. GET_GROUP_SURVEY);
export const getGroupSurveySuccess = createAction(ActionTypes. GET_GROUP_SURVEY_SUCCESS, response => response);
export const getGroupSurveyError = createAction(ActionTypes.GET_GROUP_SURVEY_ERROR, error => error);

export const getGroupSurvey= (token,id,search)=>(dispatch)=>{
  dispatch(getGroupSurveyStart ());
  return _getGroupSurvey(token,id,search).then((response)=>{
    dispatch(getGroupSurveySuccess(response));
  }).catch((error)=>{
    dispatch( getGroupSurveyError (error));
    toast.error('Something went wrong')
  })
}

//Group Survey delete

export const deleteGroupSurveystart = createAction(ActionTypes. DELETE_GROUP_SURVEY );
export const deleteGroupSurveySuccess = createAction(ActionTypes.  DELETE_GROUP_SURVEY_SUCCESS, response => response);
export const deleteGroupSurveyError = createAction(ActionTypes. DELETE_GROUP_SURVEY_ERROR, error => error);

export const deleteGroupSurvey= (token,id,data)=>(dispatch)=>{
  dispatch( deleteGroupSurveystart ());
  return _deleteGroupSurvey(token,id,data).then((response)=>{
    dispatch( deleteGroupSurveySuccess(response));
    if (response) {
      toast.success('Survey Removed For The Group')

        }
  }).catch((error)=>{
    dispatch( deleteGroupSurveyError(error));
    toast.error('Something went wrong')
  })
}

//assign survey to groups
export const assignSurveyStart = createAction(ActionTypes.ASSIGN_SURVEY);
export const assignSurveySuccess = createAction(ActionTypes.ASSIGN_SURVEY_SUCCESS, response => response);
export const assignSurveyError = createAction(ActionTypes.ASSIGN_SURVEY_ERROR, error => error);

export const assignSurvey = (id,data) => (dispatch) => {
    dispatch(assignSurveyStart());

    return  _assignSurveysToGroup(id,data).then((response) => {

        dispatch(assignSurveySuccess(response));
        if (response) {
          toast.success('Survey Assigned')

            }
    }).catch((error) => {
        dispatch(assignSurveyError(error));
        toast.error('Something went wrong')

    });
};


// //assign survey to members
// export const assignSurveyStart_members = createAction(ActionTypes.ASSIGN_SURVEY_MEMBERS);
// export const assignSurveySuccess_members = createAction(ActionTypes.ASSIGN_SURVEY_SUCCESS_MEMBERS, response => response);
// export const assignSurveyError_members = createAction(ActionTypes.ASSIGN_SURVEY_ERROR_MEMBERS, error => error);

// export const assignSurvey_members = (id,data) => (dispatch) => {
//     dispatch(assignSurveyStart_members());

//     return  _assignSurveysToMembers(id,data).then((response) => {

//         dispatch(assignSurveySuccess_members(response));
//     }).catch((error) => {
//         dispatch(assignSurveyError_members(error));


//     });
// };

//assign multiple courses to members
export const assignCourseStart = createAction(ActionTypes.ASSIGN_COURSE);
export const assignCourseSuccess = createAction(ActionTypes.ASSIGN_COURSE_SUCCESS, response => response);
export const assignCourseError = createAction(ActionTypes.ASSIGN_COURSE_ERROR, error => error);

export const assignCourse = ( id, data) => (dispatch) => {
    dispatch(assignCourseStart());
    return   _assignCoursesToMembers(id,data).then((response) => {
        dispatch(assignCourseSuccess(response));
        if (response) {
          toast.success('Courses Assigned')

            }
    }).catch((error) => {
        dispatch(assignCourseError(error));
        toast.error('Something went wrong')
    });
};



//assign multiple courses to groups
export const assignCourseStart_groups = createAction(ActionTypes.ASSIGN_COURSE_GROUPS);
export const assignCourseSuccess_groups = createAction(ActionTypes.ASSIGN_COURSE_SUCCESS_GROUPS, response => response);
export const assignCourseError_groups = createAction(ActionTypes.ASSIGN_COURSE_ERROR_GROUPS, error => error);

export const assignCourse_groups = (id,data) => (dispatch) => {
    dispatch(assignCourseStart_groups());
    return  _assignCoursesToGroup(id,data).then((response) => {
        dispatch(assignCourseSuccess_groups(response));
        if (response) {
          toast.success('Courses Assigned')

            }
    }).catch((error) => {
        dispatch(assignCourseError_groups(error));
    });
};

//assign multiple lp to members
export const assignLPStart = createAction(ActionTypes.ASSIGN_LP);
export const assignLPSuccess = createAction(ActionTypes.ASSIGN_LP_SUCCESS, response => response);
export const assignLPError = createAction(ActionTypes.ASSIGN_LP_ERROR, error => error);

export const assignLP = (id, data) => (dispatch) => {
    dispatch(assignLPStart());
    return  _assignLPToMembers(id, data).then((response) => {
        dispatch(assignLPSuccess(response));
        if (response) {
          toast.success('Learning Paths Assigned')

            }
    }).catch((error) => {
        dispatch(assignLPError(error));
    });
};



//assign multiple LP's to groups
export const assignLPStart_groups = createAction(ActionTypes.ASSIGN_LP_GROUPS);
export const assignLPSuccess_groups = createAction(ActionTypes.ASSIGN_LP_SUCCESS_GROUPS, response => response);
export const assignLPError_groups = createAction(ActionTypes.ASSIGN_LP_ERROR_GROUPS, error => error);

export const assignLP_groups = (id,data) => (dispatch) => {
    dispatch(assignLPStart_groups());
    return  _assignLPToGroup(id,data).then((response) => {
        dispatch(assignLPSuccess_groups(response));
        if (response) {
          toast.success('Learning Paths Assigned')

            }
    }).catch((error) => {
        dispatch(assignLPError_groups(error));
    });
};




//assign multiple groups an LP
export const assignLPStart_groups_multiple = createAction(ActionTypes.ASSIGN_LP_GROUPS_MULTIPLE);
export const assignLPSuccess_groups_multiple = createAction(ActionTypes.ASSIGN_LP_SUCCESS_GROUPS_MULTIPLE, response => response);
export const assignLPError_groups_multiple = createAction(ActionTypes.ASSIGN_LP_ERROR_GROUPS_MULTIPLE, error => error);

export const assignLP_groups_multiple = (id,data) => (dispatch) => {
    dispatch(assignLPStart_groups_multiple());
    return  _assignLPToMultipleGroups(id,data).then((response) => {
        dispatch(assignLPSuccess_groups_multiple(response));
        if (response) {
          toast.success('Learning Path Assigned')
            }

    }).catch((error) => {
        dispatch(assignLPError_groups_multiple(error));
    });
};


export const resendEmailStart = createAction(ActionTypes. RESEND_EMAIL);
export const resendEmailSuccess = createAction(ActionTypes.  RESEND_EMAIL_SUCCESS, response => response);
export const resendEmailError = createAction(ActionTypes.RESEND_EMAIL_ERROR, error => error);

export const resendEmail= (id)=>(dispatch)=>{

  dispatch(resendEmailStart ());
  return   _resendEmail(id).then((response)=>{
    dispatch(resendEmailSuccess(response));
    if (response) {
      toast.success('Invitation  sent successfully')
        }
  }).catch((error)=>{
    dispatch(resendEmailError(error));
    toast.error('Something went wrong')
  })
}


const reducer = handleActions({



//listing team members

  [ActionTypes.GET_MEMBERS]: (state) => ({
    ...state, members_list: {
      ...state.members_list,
      loading: true, hasError: false, error: {}
    }
  }),


  [ActionTypes.GET_MEMBERS_SUCCESS]: (state, action) => ({
    ...state,
    members_list: {
      ...state.members_list,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),

  [ActionTypes. GET_MEMBERS_ERROR]: (state, action) => ({
    ...state,
    members_list: {
      ...state.members_list,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

  //listing new members

  [ActionTypes.GET_NEW_MEMBERS]: (state) => ({
    ...state, memberslist: {
      ...state.memberslist,
      loading: true, hasError: false, error: {}
    }
  }),


  [ActionTypes.GET_NEW_MEMBERS_SUCCESS]: (state, action) => ({
    ...state,
    memberslist: {
      ...state.memberslist,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),

  [ActionTypes. GET_NEW_MEMBERS_ERROR]: (state, action) => ({
    ...state,
    memberslist: {
      ...state.memberslist,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),


//listing all groups

  [ActionTypes.GET_ALL_GROUPS]: (state) => ({
    ...state,
    group_list: {
      ...state.group_list,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes. GET_ALL_GROUPS_SUCCESS]: (state, action) => ({
    ...state,
    group_list: {
      ...state.group_list,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes. GET_ALL_GROUPS_ERROR]: (state, action) => ({
    ...state,
    group_list: {
      ...state.group_list,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),
  //listing all learner groups

  [ActionTypes.GET_ALL_LEARNER_GROUPS]: (state) => ({
    ...state,
    learner_group_list: {
      ...state.learner_group_list,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes. GET_ALL_LEARNER_GROUPS_SUCCESS]: (state, action) => ({
    ...state,
    learner_group_list: {
      ...state.learner_group_list,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_ALL_LEARNER_GROUPS_ERROR]: (state, action) => ({
    ...state,
    learner_group_list: {
      ...state.learner_group_list,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

//update groups

  [ActionTypes.UPDATE_GROUPS]: (state) => ({
    ...state,
    group_update: {
      ...state. group_update,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes. UPDATEL_GROUPS_SUCCESS]: (state, action) => ({
    ...state,
    group_update: {
      ...state. group_update,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes. UPDATE_GROUPS_ERROR]: (state, action) => ({
    ...state,
    group_update: {
      ...state. group_update,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

 //ADD_NEWMEMBER
 [ActionTypes.ADD_MEMBER]: (state) => ({
  ...state, new_member: {
    ...state.new_member,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.ADD_MEMBER_SUCCESS]: (state, action) => ({
  ...state,
  new_member: {
    ...state.new_member,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.ADD_MEMBER_ERROR]: (state, action) => ({
  ...state,
  new_member: {
    ...state.new_member,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

 //count_NEWMEMBER
 [ActionTypes.COUNT_MEMBER]: (state) => ({
  ...state, count_member: {
    ...state.count_member,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.COUNT_MEMBER_SUCCESS]: (state, action) => ({
  ...state,
  count_member: {
    ...state.count_member,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.COUNT_MEMBER_ERROR]: (state, action) => ({
  ...state,
  count_member: {
    ...state.count_member,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

 //ADD_NEW_GROUP
 [ActionTypes.ADD_GROUP]: (state) => ({
  ...state,  new_group: {
    ...state. new_group,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.ADD_GROUP_SUCCESS]: (state, action) => ({
  ...state,
  new_group: {
    ...state. new_group,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.ADD_GROUP_ERROR]: (state, action) => ({
  ...state,
  new_group: {
    ...state.new_group,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),




//Remove manager access
[ActionTypes.REMOVE_MANAGER]: (state) => ({
  ...state, removeManager_list: {
    ...state. removeManager_list,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.REMOVE_MANAGER_SUCCESS]: (state, action) => ({
  ...state,
  removeManager_list: {
    ...state.removeManager_list,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.REMOVE_MANAGER_ERROR]: (state, action) => ({
  ...state,
  removeManager_list: {
    ...state.removeManager_list,
    error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//Add manager access
[ActionTypes. ASSIGN_MANAGER]: (state) => ({
  ...state, addManager_list: {
    ...state. addManager_list,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. ASSIGN_MANAGER_SUCCESS]: (state, action) => ({
  ...state,
  addManager_list: {
    ...state.addManager_list,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes. ASSIGN_MANAGER_ERROR]: (state, action) => ({
  ...state,
  addManager_list: {
    ...state.addManager_list,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),


//Delete one member
[ActionTypes.  REMOVE_MEMBER]: (state) => ({
  ...state, removeMember: {
    ...state.  removeMember,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. REMOVE_MEMBER_SUCCESS]: (state, action) => ({
  ...state,
  removeMember: {
    ...state. removeMember,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  REMOVE_MEMBER_ERROR]: (state, action) => ({
  ...state,
  removeMember: {
    ...state. removeMember,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//Delete members Bulk
[ActionTypes.   REMOVE_MEMBER_BULK]: (state) => ({
  ...state,  removeMember_list: {
    ...state.  removeMember_list,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. REMOVE_MEMBER_BULK_SUCCESS]: (state, action) => ({
  ...state,
  removeMember_list: {
    ...state. removeMember_list,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.   REMOVE_MEMBER_BULK_ERROR]: (state, action) => ({
  ...state,
  removeMember_list: {
    ...state.  removeMember_list,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//selected members
[ActionTypes. SELECTED_MEMBERS]: (state, action) => ({
  ...state,
  selectdMembers_list: action.payload


}),


//get member course
[ActionTypes.  GET_MEMBER_COURSES]: (state) => ({
  ...state, memberCourses: {
    ...state. memberCourses,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. GET_MEMBER_COURSES_SUCCESS]: (state, action) => ({
  ...state,
  memberCourses: {
    ...state.memberCourses,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes. GET_MEMBER_COURSES_ERROR]: (state, action) => ({
  ...state,
  memberCourses: {
    ...state.memberCourses,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//Delete member courses
[ActionTypes.   DELETE_MEMBER_COURSES]: (state) => ({
  ...state, memberCoursesDelete: {
    ...state.  memberCoursesDelete,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.  DELETE_MEMBER_COURSES_SUCCESS]: (state, action) => ({
  ...state,
  memberCoursesDelete: {
    ...state.  memberCoursesDelete,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.   DELETE_MEMBER_COURSES_ERROR]: (state, action) => ({
  ...state,
  memberCoursesDelete: {
    ...state. memberCoursesDelete,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),


//get member LP
[ActionTypes. GET_MEMBER_LP]: (state) => ({
  ...state, memberLP: {
    ...state. memberLP,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_MEMBER_LP_SUCCESS]: (state, action) => ({
  ...state,
  memberLP: {
    ...state. memberLP,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_MEMBER_LP_ERROR]: (state, action) => ({
  ...state,
  memberLP: {
    ...state. memberLP,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//get manager LP
[ActionTypes. GET_LP]: (state) => ({
  ...state, totalLP: {
    ...state.totalLP,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_LP_SUCCESS]: (state, action) => ({
  ...state,
  totalLP: {
    ...state. totalLP,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_LP_ERROR]: (state, action) => ({
  ...state,
  totalLP: {
    ...state. totalLP,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),


//delete member lp
[ActionTypes.   DELETE_MEMBER_LP]: (state) => ({
  ...state, memberLPDelete: {
    ...state.  memberLPDelete,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. DELETE_MEMBER_LP_SUCCESS]: (state, action) => ({
  ...state,
  memberLPDelete: {
    ...state. memberLPDelete,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes. DELETE_MEMBER_LP_ERROR]: (state, action) => ({
  ...state,
  memberLPDelete: {
    ...state.memberLPDelete,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//get member Surcey
[ActionTypes. GET_MEMBER_SURVEY]: (state) => ({
  ...state,memberSurvey: {
    ...state.memberSurvey,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_MEMBER_SURVEY_SUCCESS]: (state, action) => ({
  ...state,
  memberSurvey: {
    ...state. memberSurvey,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_MEMBER_SURVEY_ERROR]: (state, action) => ({
  ...state,
  memberSurvey: {
    ...state.memberSurvey,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//delete member survey
[ActionTypes.   DELETE_MEMBER_SURVEY]: (state) => ({
  ...state, memberSurveyDelete: {
    ...state.  memberSurveyDelete,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. DELETE_MEMBER_SURVEY_SUCCESS]: (state, action) => ({
  ...state,
  memberSurveyDelete: {
    ...state.  memberSurveyDelete,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes. DELETE_MEMBER_SURVEY_ERROR]: (state, action) => ({
  ...state,
  memberSurveyDelete: {
    ...state. memberSurveyDelete,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//get GROUP course
[ActionTypes.  GET_GROUP_COURSES]: (state) => ({
  ...state, groupCourses: {
    ...state. groupCourses,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. GET_GROUP_COURSES_SUCCESS]: (state, action) => ({
  ...state,
  groupCourses: {
    ...state.groupCourses,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes. GET_GROUP_COURSES_ERROR]: (state, action) => ({
  ...state,
  groupCourses: {
    ...state.groupCourses,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//Delete GROUP courses
[ActionTypes.   DELETE_GROUP_COURSES]: (state) => ({
  ...state, groupCoursesDelete: {
    ...state. groupCoursesDelete,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.  DELETE_GROUP_COURSES_SUCCESS]: (state, action) => ({
  ...state,
  groupCoursesDelete: {
    ...state. groupCoursesDelete,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.   DELETE_GROUP_COURSES_ERROR]: (state, action) => ({
  ...state,
  groupCoursesDelete: {
    ...state.groupCoursesDelete,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),


//get GROUP LP
[ActionTypes. GET_GROUP_LP]: (state) => ({
  ...state,groupLP: {
    ...state. groupLP,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_GROUP_LP_SUCCESS]: (state, action) => ({
  ...state,
  groupLP: {
    ...state. groupLP,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_GROUP_LP_ERROR]: (state, action) => ({
  ...state,
  groupLP: {
    ...state.groupLP,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//delete member lp
[ActionTypes.   DELETE_GROUP_LP]: (state) => ({
  ...state, groupLPDelete: {
    ...state. groupLPDelete,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. DELETE_GROUP_LP_SUCCESS]: (state, action) => ({
  ...state,
  groupLPDelete: {
    ...state. groupLPDelete,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes. DELETE_GROUP_LP_ERROR]: (state, action) => ({
  ...state,
  groupLPDelete: {
    ...state.groupLPDelete,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//get GROUP Surcey
[ActionTypes. GET_GROUP_SURVEY]: (state) => ({
  ...state,groupSurvey: {
    ...state.groupSurvey,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_GROUP_SURVEY_SUCCESS]: (state, action) => ({
  ...state,
  groupSurvey: {
    ...state.groupSurvey,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_GROUP_SURVEY_ERROR]: (state, action) => ({
  ...state,
  groupSurvey: {
    ...state.groupSurvey,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//delete member survey
[ActionTypes.   DELETE_GROUP_SURVEY]: (state) => ({
  ...state,groupSurveyDelete: {
    ...state.  groupSurveyDelete,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. DELETE_GROUP_SURVEY_SUCCESS]: (state, action) => ({
  ...state,
  groupSurveyDelete: {
    ...state.  groupSurveyDelete,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes. DELETE_GROUP_SURVEY_ERROR]: (state, action) => ({
  ...state,
  groupSurveyDelete: {
    ...state. groupSurveyDelete,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),



//Delete one GROUP
[ActionTypes.  REMOVE_GROUP]: (state) => ({
  ...state, removeGroup: {
    ...state. removeGroup,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. REMOVE_GROUP_SUCCESS]: (state, action) => ({
  ...state,
  removeGroup: {
    ...state. removeGroup,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  REMOVE_GROUP_ERROR]: (state, action) => ({
  ...state,
  removeGroup: {
    ...state. removeGroup,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//Delete group Bulk
[ActionTypes.   REMOVE_GROUP_BULK]: (state) => ({
  ...state,  removeGroup_list: {
    ...state.  removeGroup_list,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. REMOVE_GROUP_BULK_SUCCESS]: (state, action) => ({
  ...state,
  removeGroup_list: {
    ...state. removeGroup_list,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.   REMOVE_GROUP_BULK_ERROR]: (state, action) => ({
  ...state,
  removeGroup_list: {
    ...state. removeGroup_list,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),


//get group detail
[ActionTypes. GET_GROUP_DETAIL]: (state) => ({
  ...state, groupDetails: {
    ...state. groupDetails,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. GET_GROUP_DETAIL_SUCCESS]: (state, action) => ({
  ...state,
  groupDetails: {
    ...state. groupDetails,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_GROUP_DETAIL_ERROR]: (state, action) => ({
  ...state,
  groupDetails: {
    ...state. groupDetails,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

//GET CLASS DETAIL



[ActionTypes. GET_CLASS_DETAIL]: (state) => ({
  ...state, classDetails: {
    ...state. classDetails,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. GET_CLASS_DETAIL_SUCCESS]: (state, action) => ({
  ...state,
  classDetails: {
    ...state. classDetails,
    response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.  GET_CLASS_DETAIL_ERROR]: (state, action) => ({
  ...state,
  classDetails: {
    ...state. classDetails,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),


//get member detail
[ActionTypes.    GET_MEMBER_DETAIL]: (state) => ({
  ...state, memberDetails: {
    ...state.  memberDetails,
    loading: true, hasError: false, error: {}
  }
}),
[ActionTypes. GET_MEMBER_DETAIL_SUCCESS]: (state, action) => ({


    ...state,
    memberDetails: {
      ...state. memberDetails,
      response: action.payload, loading: false, hasError: false, error: {}
  },
  editData:{ ...action.payload }



}),
[ActionTypes. GET_MEMBER_DETAIL_ERROR]: (state, action) => ({
  ...state,
  memberDetails: {
    ...state.memberDetails,
    error: action.payload, loading: false, hasError: true, response: {}  }
}),

// Teams members pagination next 

[ActionTypes.TEAMS_MEMBERS_PAGINATION_NEXT]: (state) => ({
  ...state,
  loading: true, hasError: false, error: {}, IsMembersData:false,
  members_list: {
      ...state.members_list,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.TEAMS_MEMBERS_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
  ...state,
  paginatedMembers:{...action.payload},
  loading: false, hasError: false, error: {}, IsMembersData:false,
  members_list: {
      ...state.members_list,
      response: action.payload, loading: false, hasError: false, error: {}
  },
}),
[ActionTypes.TEAMS_MEMBERS_PAGINATION_NEXT_ERROR]: (state, action) => ({
  ...state,
  paginatedMembers:{},
  error: action.payload, loading: false, hasError: true, IsMembersData:false,
  members_list: {
      ...state.members_list,
      loading: true, hasError: false, error: {}
  }
}),

// Teams Members pagination previous

[ActionTypes.TEAMS_MEMBERS_PAGINATION_PREVIOUS]: (state) => ({
  ...state,
  loading: true, hasError: false, error: {}, IsMembersData:false,
  members_list: {
      ...state.members_list,
      loading: true, hasError: false, error: {}
  }

}),
[ActionTypes.TEAMS_MEMBERS_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
  ...state,
  paginatedMembers:{...action.payload},
  members_list: {
      ...state.members_list,
      response: action.payload, loading: false, hasError: false, error: {}
  },
  loading: false, hasError: false, error: {}, IsMembersData:false,
}),
[ActionTypes.TEAMS_MEMBERS_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
  ...state,
  paginatedMembers:{},
  error: action.payload, loading: false, hasError: true, IsMembersData:false,
  members_list: {
      ...state.members_list,
      response: {}, loading: false, hasError: true, error: action.payload
  },
}),
//Get paginated Members
[ActionTypes.PAGINATION_NEXT]: (state) => ({
  ...state,
  loading: true, hasError: false, error: {}, IsMembersData:false,
  members_list: {
      ...state.members_list,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.PAGINATION_NEXT_SUCCESS]: (state, action) => ({
  ...state,
  paginatedMembers:{...action.payload},
  loading: false, hasError: false, error: {}, IsMembersData:false,
  members_list: {
      ...state.members_list,
      response: action.payload, loading: false, hasError: false, error: {}
  },
}),
[ActionTypes.PAGINATION_NEXT_ERROR]: (state, action) => ({
  ...state,
  paginatedMembers:{},
  error: action.payload, loading: false, hasError: true, IsMembersData:false,
  members_list: {
      ...state.members_list,
      loading: true, hasError: false, error: {}
  }
}),

//handle previous pagination
[ActionTypes.PAGINATION_PREVIOUS]: (state) => ({
  ...state,
  loading: true, hasError: false, error: {}, IsMembersData:false,
  members_list: {
      ...state.members_list,
      loading: true, hasError: false, error: {}
  }

}),
[ActionTypes.PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
  ...state,
  paginatedMembers:{...action.payload},
  members_list: {
      ...state.members_list,
      response: action.payload, loading: false, hasError: false, error: {}
  },
  loading: false, hasError: false, error: {}, IsMembersData:false,
}),
[ActionTypes.PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
  ...state,
  paginatedMembers:{},
  error: action.payload, loading: false, hasError: true, IsMembersData:false,
  members_list: {
      ...state.members_list,
      response: {}, loading: false, hasError: false, error: action.payload
  },
}),

//Get paginated groups
[ActionTypes.GROUP_PAGINATION_NEXT]: (state) => ({
  ...state,
  loading: true, hasError: false, error: {}, IsMembersData:false,
  group_list: {
      ...state.group_list,
      loading: true, hasError: false, error: {}
  },
  learner_group_list: {
    ...state.learner_group_list,
    loading: true, hasError: false, error: {}
}
}),
[ActionTypes.GROUP_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
  ...state,
  paginatedGroup:{...action.payload},
  loading: false, hasError: false, error: {}, IsMembersData:false,
  group_list: {
      ...state.group_list,
      response: action.payload, loading: false, hasError: false, error: {}
  },
  learner_group_list: {
    ...state.learner_group_list,
    response: action.payload, loading: false, hasError: false, error: {}
},
}),
[ActionTypes.GROUP_PAGINATION_NEXT_ERROR]: (state, action) => ({
  ...state,
  paginatedGroup:{},
  error: action.payload, loading: false, hasError: true, IsMembersData:false,
  group_list: {
      ...state.group_list,
      loading: true, hasError: false, error: {}
  },
  learner_group_list: {
    ...state.learner_group_list,
    loading: true, hasError: false, error: {}
}
}),


//handle previous group pagination
[ActionTypes.GROUP_PAGINATION_PREVIOUS]: (state) => ({
  ...state,
  loading: true, hasError: false, error: {}, IsMembersData:false,
  group_list: {
      ...state.group_list,
      loading: true, hasError: false, error: {}
  },
  learner_group_list: {
    ...state.learner_group_list,
    loading: true, hasError: false, error: {}
}

}),
[ActionTypes.GROUP_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
  ...state,
  paginatedGroup:{...action.payload},
  group_list: {
      ...state.group_list,
      response: action.payload, loading: false, hasError: false, error: {}
  },
  learner_group_list: {
    ...state.learner_group_list,
    response: action.payload, loading: false, hasError: false, error: {}
},
  loading: false, hasError: false, error: {}, IsMembersData:false,
}),
[ActionTypes.GROUP_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
  ...state,
  paginatedGroup:{},
  error: action.payload, loading: false, hasError: true, IsMembersData:false,
  group_list: {
      ...state.group_list,
      response: {}, loading: false, hasError: false, error: action.payload
  },
 learner_group_list: {
    ...state.learner_group_list,
    response: {}, loading: false, hasError: false, error: action.payload
},

}),


  //ASSIGN SURVEY

  [ActionTypes.ASSIGN_SURVEY]: (state) => ({
    ...state,
    loading: false, hasError: false, error: {},IsSurveyData:false,
    survey_assignment: { loading: false, response: {}, hasError: false, error: {} }
}),
[ActionTypes.ASSIGN_SURVEY_SUCCESS]: (state, action) => ({
    ...state,
    response: action.payload,IsSurveyData:false,
    survey_assignment: {
        ...state.survey_assignment,
        response: action.payload, loading: false, hasError: false, error: {}
    },

}),
[ActionTypes.ASSIGN_SURVEY_ERROR]: (state, action) => ({
    ...state,
    error: action.payload, loading: false, hasError: true, response: {}, IsSurveyData:false,
    survey_assignment: {
        ...state.survey_assignment,
        response: {}, loading: false, hasError: false, error: action.payload
    },
}),

//ASSIGN SURVEY members

[ActionTypes.ASSIGN_SURVEY_MEMBERS]: (state) => ({
  ...state,
  loading: false, hasError: false, error: {},IsSurveyData:false,
  survey_assignment_members: { loading: false, response: {}, hasError: false, error: {} }
}),
[ActionTypes.ASSIGN_SURVEY_SUCCESS_MEMBERS]: (state, action) => ({
  ...state,
  response: action.payload,IsSurveyData:false,
  survey_assignment_members: {
      ...state.survey_assignment_members,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.ASSIGN_SURVEY_ERROR_MEMBERS]: (state, action) => ({
  ...state,
  error: action.payload, loading: false, hasError: true, response: {}, IsSurveyData:false,
  survey_assignment_members: {
      ...state.survey_assignment_members,
      response: {}, loading: false, hasError: false, error: action.payload
  },
}),



 //ASSIGN_COURSE
 [ActionTypes.ASSIGN_MEMBER_COURSE]: (state) => ({
  ...state, assign_member_course: {
      ...state. assign_member_course,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.ASSIGN_MEMBER_COURSE_SUCCESS]: (state, action) => ({
  ...state,
  assign_member_course: {
      ...state. assign_member_course,
      response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.ASSIGN_MEMBER_COURSE_ERROR]: (state, action) => ({
  ...state,
  assign_member_course: {
      ...state. assign_member_course,
      error: action.payload, loading: false, hasError: true, response: {}
  }
}),

    //ASSIGN_COURSE
    [ActionTypes.ASSIGN_COURSE]: (state) => ({
      ...state, assign_course: {
          ...state.assign_course,
          loading: true, hasError: false, error: {}
      }
  }),
  [ActionTypes.ASSIGN_COURSE_SUCCESS]: (state, action) => ({
      ...state,
      assign_course: {
          ...state.assign_course,
          response: action.payload, loading: false, hasError: false, error: {}
      }
  }),
  [ActionTypes.ASSIGN_COURSE_ERROR]: (state, action) => ({
      ...state,
      assign_course: {
          ...state.assign_course,
          error: action.payload, loading: false, hasError: true, response: {}
      }
  }),

    //ASSIGN_COURSE GROUPS
    [ActionTypes.ASSIGN_COURSE_GROUPS]: (state) => ({
      ...state, assign_course_groups: {
          ...state.assign_course_groups,
          loading: true, hasError: false, error: {}
      }
  }),
  [ActionTypes.ASSIGN_COURSE_SUCCESS_GROUPS]: (state, action) => ({
      ...state,
      assign_course_groups: {
          ...state.assign_course_groups,
          response: action.payload, loading: false, hasError: false, error: {}
      }
  }),
  [ActionTypes.ASSIGN_COURSE_ERROR_GROUPS]: (state, action) => ({
      ...state,
      assign_course_groups: {
          ...state. assign_course_groups,
          error: action.payload, loading: false, hasError: true, response: {}
      }
  }),

 //ASSIGN_LP
 [ActionTypes.ASSIGN_LP]: (state) => ({
  ...state, assign_lp: {
      ...state.assign_lp,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.ASSIGN_LP_SUCCESS]: (state, action) => ({
  ...state,
  assign_lp: {
      ...state.assign_lp,
      response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.ASSIGN_LP_ERROR]: (state, action) => ({
  ...state,
  assign_lp: {
      ...state.assign_lp,
      error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//ASSIGN_LP GROUPS
[ActionTypes.ASSIGN_LP_GROUPS]: (state) => ({
  ...state, assign_lp_groups: {
      ...state.assign_lp_groups,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.ASSIGN_LP_SUCCESS_GROUPS]: (state, action) => ({
  ...state,
  assign_lp_groups: {
      ...state.assign_lp_groups,
      response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.ASSIGN_LP_ERROR_GROUPS]: (state, action) => ({
  ...state,
  assign_lp_groups: {
      ...state. assign_lp_groups,
      error: action.payload, loading: false, hasError: true, response: {}
  }
}),

//ASSIGN_LP to MULTIPLE groups
[ActionTypes.ASSIGN_LP_GROUPS_MULTIPLE]: (state) => ({
  ...state, assign_lp_multiple_groups: {
      ...state.assign_lp_multiple_groups,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.ASSIGN_LP_SUCCESS_GROUPS_MULTIPLE]: (state, action) => ({
  ...state,
  assign_lp_multiple_groups: {
      ...state.assign_lp_multiple_groups,
      response: action.payload, loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.ASSIGN_LP_ERROR_GROUPS_MULTIPLE]: (state, action) => ({
  ...state,
  assign_lp_multiple_groups: {
      ...state. assign_lp_multiple_groups,
      error: action.payload, loading: false, hasError: true, response: {}
  }
}),

 //    Learner Course Pagination Next
 [ActionTypes.LEARNER_COURSE_PAGINATION_NEXT]: (state) => ({
  ...state,
  memberCourses: {
      ...state.memberCourses,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.LEARNER_COURSE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
  ...state,


  memberCourses: {
      ...state.memberCourses,
      response: action.payload, loading: false, hasError: false, error: {}
  },
}),
[ActionTypes.LEARNER_COURSE_PAGINATION_NEXT_ERROR]: (state, action) => ({
  ...state,

  memberCourses: {
      ...state.memberCourses,
      loading: false, hasError: false, error: {}
  }
}),
//    Learner Course Pagination Previous
[ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS]: (state) => ({
  ...state,
  memberCourses: {
      ...state.memberCourses,
      loading: true, hasError: false, error: {}
  }

}),
[ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
  ...state,

  memberCourses: {
      ...state.memberCourses,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
  ...state,
  memberCourses: {
      ...state.memberCourses,
      response: {}, loading: false, hasError: false, error: action.payload
  },
}),

  //   Members Learning Path Pagination Next
  [ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT]: (state) => ({
    ...state,
    memberLP: {
        ...state. memberLP,
        loading: true, hasError: false, error: {}
    }
}),
[ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
    ...state,


    memberLP: {
        ...state. memberLP,
        response: action.payload, loading: false, hasError: false, error: {}
    },
}),
[ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT_ERROR]: (state, action) => ({
    ...state,

    memberLP: {
        ...state. memberLP,
        loading: false, hasError: false, error: {}
    }
}),
//    Members Learning Path Pagination Previous
[ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS]: (state) => ({
    ...state,
    memberLP: {
        ...state. memberLP,
        loading: true, hasError: false, error: {}
    }

}),
[ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
    ...state,

    memberLP: {
        ...state. memberLP,
        response: action.payload, loading: false, hasError: false, error: {}
    },

}),
[ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
    ...state,
    memberLP: {
        ...state. memberLP,
        response: {}, loading: false, hasError: false, error: action.payload
    },
}),

  //   Group Course Pagination Next
  [ActionTypes.GROUP_COURSE_PAGINATION_NEXT]: (state) => ({
    ...state,
    groupCourses: {
        ...state.groupCourses,
        loading: true, hasError: false, error: {}
    }
}),
[ActionTypes.GROUP_COURSE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
    ...state,


    groupCourses: {
        ...state.groupCourses,
        response: action.payload, loading: false, hasError: false, error: {}
    },
}),
[ActionTypes.GROUP_COURSE_PAGINATION_NEXT_ERROR]: (state, action) => ({
    ...state,

    groupCourses: {
        ...state.groupCourses,
        loading: false, hasError: false, error: {}
    }
}),
//   Group Course Pagination Previous
[ActionTypes.GROUP_COURSE_PAGINATION_PREVIOUS]: (state) => ({
    ...state,
    groupCourses: {
        ...state.groupCourses,
        loading: true, hasError: false, error: {}
    }
}),
[ActionTypes.GROUP_COURSE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
    ...state,


    groupCourses: {
        ...state.groupCourses,
        response: action.payload, loading: false, hasError: false, error: {}
    },
}),
[ActionTypes.GROUP_COURSE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
    ...state,

    groupCourses: {
        ...state.groupCourses,
        loading: false, hasError: false, error: {}
    }
}),

//   Group LP Pagination Next
[ActionTypes.LP_GROUP_PAGINATION_NEXT]: (state) => ({
  ...state,
  groupLP: {
      ...state.groupLP,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.LP_GROUP_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
  ...state,


  groupLP: {
      ...state.groupLP,
      response: action.payload, loading: false, hasError: false, error: {}
  },
}),
[ActionTypes.LP_GROUP_PAGINATION_NEXT_ERROR]: (state, action) => ({
  ...state,

  groupLP: {
      ...state.groupLP,
      loading: false, hasError: false, error: {}
  }
}),
//   Group LP Pagination Previous
[ActionTypes.LP_GROUP_PAGINATION_PREVIOUS]: (state) => ({
  ...state,
  groupLP: {
      ...state.groupLP,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.LP_GROUP_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
  ...state,


  groupLP: {
      ...state.groupLP,
      response: action.payload, loading: false, hasError: false, error: {}
  },
}),
[ActionTypes.LP_GROUP_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
  ...state,

  groupLP: {
      ...state.groupLP,
      loading: false, hasError: false, error: {}
  }
}),

//group survey pagination

[ActionTypes.PAGINATION_NEXT_SURVEY]: (state) => ({
  ...state,
  // loading: true, hasError: false, error: {}, IsMembersData:false,
  groupSurvey: {
      ...state.groupSurvey,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.PAGINATION_NEXT_SUCCESS_SURVEY]: (state, action) => ({
  ...state,
  // paginatedMembers:{...action.payload},
  // loading: false, hasError: false, error: {}, IsMembersData:false,
  groupSurvey: {
      ...state.groupSurvey,
      response: action.payload, loading: false, hasError: false, error: {}
  },
}),
[ActionTypes.PAGINATION_NEXT_ERROR_SURVEY]: (state, action) => ({
  ...state,
  // paginatedMembers:{},
  // error: action.payload, loading: false, hasError: true, IsMembersData:false,
  groupSurvey: {
      ...state.groupSurvey,
      loading: true, hasError: false, error: {}
  }
}),

//handle previous pagination
[ActionTypes.PAGINATION_PREVIOUS_SURVEY]: (state) => ({
  ...state,
  // loading: true, hasError: false, error: {}, IsMembersData:false,
  groupSurvey: {
      ...state.groupSurvey,
      loading: true, hasError: false, error: {}
  }

}),
[ActionTypes.PAGINATION_PREVIOUS_SUCCESS_SURVEY]: (state, action) => ({
  ...state,
  // paginatedMembers:{...action.payload},
  groupSurvey: {
      ...state.groupSurvey,
      response: action.payload, loading: false, hasError: false, error: {}
  },
  loading: false, hasError: false, error: {}, IsMembersData:false,
}),
[ActionTypes.PAGINATION_PREVIOUS_ERROR_SURVEY]: (state, action) => ({
  ...state,
  // paginatedMembers:{},
  // error: action.payload, loading: false, hasError: true, IsMembersData:false,
  groupSurvey: {
      ...state.groupSurvey,
      response: {}, loading: false, hasError: false, error: action.payload
  },
}),
///resend mail
[ActionTypes.RESEND_EMAIL]: (state) => ({
  ...state,
  send_mail: {
      ...state.send_mail,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.RESEND_EMAIL_SUCCESS]: (state, action) => ({
  ...state,


  send_mail: {
      ...state.send_mail,
      response: action.payload, loading: false, hasError: false, error: {}
  },
}),
[ActionTypes.RESEND_EMAIL_ERROR]: (state, action) => ({
  ...state,

  send_mail: {
      ...state.send_mail,
      loading: false, hasError: false, error: {}
  }
}),


[ActionTypes.GET_SURVEYS_LISTING]: (state) => ({
  ...state,
  checkTrialPlan: {
      ...state. checkTrialPlan,
      loading: false, hasError: false, error: {}
  }
}),
[ActionTypes.GET_SURVEYS_LISTING_SUCCESS]: (state, action) => ({
  ...state,
  checkTrialPlan: {
      ...state.  checkTrialPlan,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.GET_SURVEYS_LISTING_ERROR]: (state, action) => ({
  ...state,
  checkTrialPlan: {
      ...state.  checkTrialPlan,
      response: {}, loading: false, hasError: false, error: action.payload
  },

}),



}, initialState);

export default reducer;
