import React from 'react';

function LearnerDashboardIcon() {
return (
   <React.Fragment>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
<g>
	<path d="M2.1,5.1c0.1-0.3,0.2-0.7,0.4-1c0.4-0.5,1-0.8,1.6-0.8c1.4,0,2.7,0,4.1,0c1.1,0,2.1,0.9,2.1,2.1c0,3.7,0,7.5,0,11.2
		c0,1.1-0.9,2.1-2.1,2.1c-1.4,0-2.7,0-4.1,0c-1,0-1.9-0.7-2.1-1.7c0,0,0-0.1,0-0.1C2.1,13,2.1,9,2.1,5.1z M8.9,11c0-1.8,0-3.7,0-5.5
		c0-0.5-0.3-0.8-0.8-0.8c-1.3,0-2.6,0-3.9,0C3.7,4.7,3.5,5,3.5,5.5c0,3.7,0,7.3,0,11c0,0.5,0.3,0.8,0.8,0.8c1.3,0,2.6,0,3.9,0
		c0.5,0,0.8-0.3,0.8-0.8C8.9,14.6,8.9,12.8,8.9,11z"/>
	<path d="M15.8,3.3c0.7,0,1.3,0,2,0c1.2,0,2.1,0.9,2.1,2.1c0,0.9,0,1.8,0,2.7c0,1.2-0.9,2.1-2.1,2.1c-1.3,0-2.6,0-4,0
		c-1.2,0-2.1-0.9-2.1-2.1c0-0.9,0-1.8,0-2.7c0-1.2,0.9-2.1,2.1-2.1C14.5,3.3,15.2,3.3,15.8,3.3z M15.8,4.7c-0.7,0-1.3,0-2,0
		c-0.5,0-0.8,0.3-0.8,0.8c0,0.9,0,1.8,0,2.7c0,0.5,0.3,0.8,0.8,0.8c1.3,0,2.6,0,3.9,0c0.5,0,0.8-0.3,0.8-0.8c0-0.9,0-1.8,0-2.7
		c0-0.5-0.3-0.7-0.8-0.8C17.1,4.7,16.5,4.7,15.8,4.7z"/>
	<path d="M15.8,11.7c0.7,0,1.3,0,2,0c1.2,0,2.1,0.9,2.1,2.1c0,0.9,0,1.8,0,2.7c0,1.2-0.9,2.1-2.1,2.1c-1.3,0-2.7,0-4,0
		c-1.2,0-2.1-0.9-2.1-2.1c0-0.9,0-1.8,0-2.7c0-1.2,0.9-2.1,2.1-2.1C14.5,11.7,15.1,11.7,15.8,11.7z M15.8,13.1c-0.7,0-1.3,0-2,0
		c-0.5,0-0.8,0.3-0.8,0.8c0,0.9,0,1.8,0,2.7c0,0.5,0.3,0.8,0.8,0.8c1.3,0,2.6,0,3.9,0c0.5,0,0.8-0.3,0.8-0.8c0-0.9,0-1.8,0-2.7
		c0-0.5-0.3-0.8-0.8-0.8C17.1,13.1,16.5,13.1,15.8,13.1z"/>
</g>
</svg>

</React.Fragment>
);
}
export default LearnerDashboardIcon;