import React,{useEffect} from 'react';
import ScrollArea from 'react-scrollbar';
import Form from 'react-bootstrap/Form';
import {FormControl} from "react-bootstrap";

const ModuleInput = (props) => {
    const {name,setFieldValue,setValues,options,errors,values,touched,quesIndex,questionId,errorName} = props

    const handleInput = (e) => {
        const newValues =  {...values}
        newValues[name] = e.target.value
        newValues['questions'][quesIndex]  = {id:questionId,answer:e.target.value}
        setValues({...values, ...newValues})

    }

    return (
                        <div className="">

                                    {/*<h4>Cras gravida bibendum dolor eu varius. ____________________________, eget vehicula lorem sodales eget. Donec quis volutpat orci. Sed ipsum felis, tri stique id egestas et, convallis ac velit. In consequat dolor libero, nec luctus orci rutrum nec?</h4>*/}
                            {/* <div style={{display:'flex'}}><div className='mr-3'><b>{Number(quesIndex)+1} : </b></div><p dangerouslySetInnerHTML={{ __html: ` ${name ? name :''}` }}></p></div> */}
                            <div className="quiz-builder-pop-list d-flex">
                <span className="ind-numb">{Number(quesIndex)+1}.</span>
                <div dangerouslySetInnerHTML={{ __html: ` ${name ? name :''}` }} className="quiz-title-para"></div>
                </div>
                            <div className="answer-sec" style={{marginLeft:"15px"}}>
                                        <Form.Label>Answer</Form.Label>

                                <FormControl
                                    type="string"
                                    name={name}
                                    placeholder="Please write your answer here"
                                    value={values[name]}
                                    onChange={(e)=>handleInput(e)}
                                    isInvalid={!!errors && !!errors[errorName] && !!errors[errorName] && !!touched['questions']
                                        ? ' is-invalid'
                                        : ''}

                                />

                                {errors && errors[errorName] && touched['questions'] ?
                                    <span className={`form-error text-danger`} style={{display:'block'}}>{errors[errorName]}</span>
                                    :
                                    ''
                                }
                                    </div>

                </div>
    )

}

export default ModuleInput;
