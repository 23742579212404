import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
// import './app/assets/fonts/icon/style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './app/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'antd/dist/antd.css';
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
      <App />

        </Router>
    </PersistGate>
  </Provider >
  , document.getElementById('root')
);
