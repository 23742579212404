import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { tableData } from '../dummyTableData';
import FilterImg from '~/assets/images/icons/filter.svg';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
const AvailableLearningPaths = () => {

   const [data, setData] = useState(tableData);
   const [dateRange, setDateRange] = useState([null, null]);
   const [startDate, endDate] = dateRange;
   const options = [
      { value: 'One', label: 'One' },
      { value: 'Two', label: 'Two' },
      { value: 'Three', label: 'Three' }
   ]
   const tableColumns = [
      {
         name: 'Learning Path Name',
         selector: 'id',
         sortable: true,
         center: false,
         cell: row => <a href="#">Annual Development</a>,
         grow: 3
      },
      {
         name: 'Created On',
         selector: 'Progress',
         sortable: true,
         center: false,
         grow: 2,
         cell: row => <span>November 11, 2020</span>
      },
      {
         name: 'Courses',
         selector: 'Group_name',
         sortable: true,
         center: false,
         cell: row => <span>Lannisters</span>,
         grow: 2,
      },
      {
         name: 'Quizzes',
         selector: 'Courses',
         sortable: true,
         center: false,
         cell: row => <span>2</span>,
         grow: 1,
      },
      {
         name: 'Action',
         selector: 'Action',
         sortable: true,
         center: false,
         grow: 1,
         cell: row =>
            <div data-tag="allowRowEvents">
               <Link to="#" className="btn border-btn link-btn">
                  Preview</Link>
            </div>
         ,
      },
   ]

   return (
      <div className="available-course-wrap">
         <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
            <div className="datatable-filter-left-box d-flex align-items-center">
               <div className="datepicker-box p-relative">
                  <DatePicker
                     placeholderText={'Created date'}
                     selectsRange={true}
                     startDate={startDate}
                     endDate={endDate}
                     onChange={(update) => {
                        setDateRange(update);
                     }}
                     isClearable={true}
                  />
                  <span className="datepickericon">
                     <i className="fa fa-calendar"></i>
                  </span>
               </div>

               <div className="dt-filter-left d-flex justify-content-between align-items-center">
                  <div className="custom-react-select">
                     <Select options={options} />
                  </div>
                  <button type="button" className="iconbutton"><img src={FilterImg} /></button>
               </div>
            </div>
            <div className="dt-filter-right d-flex">
               <div className="dt-filter-search-wrap p-relative">
                  <input type="text" placeholder="Search using keyword"
                     onChange={(e => setSearch(e.target.value))}
                     className="form-control input-item" />
                  <span className="search-close-icon">×</span>
               </div>
               <button className="iconbutton search-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                  </svg>
               </button>
            </div>
         </div>
         <div className="datatble-wrap">
            <DataTable
               data={data}
               columns={tableColumns}
               center={true}
               pagination
               paginationIconLastPage={<i className="fa fa-toggle-right"></i>}
               paginationIconFirstPage={<i className="fa fa-toggle-left"></i>}
               paginationIconNext={<i className="fa fa-caret-right"></i>}
               paginationIconPrevious={<i className="fa fa-caret-left"></i>}
               noHeader={true}
            />
         </div>
      </div>
   );
};
export default AvailableLearningPaths;