
import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Responses from './freeresponses';
import {useEffect,useState}  from 'react';



function FreeResponses(props) {
    const {demographicQuestions, generalQuestions,surveyId } = props
   

   

    const finInd=()=>{
        if(generalQuestions?.length && generalQuestions ){
            const index=generalQuestions.findIndex((item)=> item?.question_type=="free_response")
            return index;

    }
}
    
    return (
        <div className="whitebox padding-box radiusbox">
            <div className="tab-wrapper">
                <Tabs defaultActiveKey={finInd()} id="uncontrolled-tab-example" className="m-b-30">
                    <p>Individual free responses can be viewed here </p>
                    {generalQuestions?.length && generalQuestions.map((item, index) => {

                if( item?.question_type=="free_response"){
                   return( <Tab eventKey={index} title={ item?.title? item?.title :`Question${index+1}`}>
                        <Responses {...props} generalQuestion={item}/>
                    </Tab>
                   )
                }
                 }
                   
                   )}

                   
                </Tabs>
            </div>
        </div>
    );
};

export default FreeResponses;
