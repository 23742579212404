import React ,{useState,useEffect} from 'react'
import {
  ToggleButton,
  ToggleButtonGroup,
  Button,
  ButtonGroup
} from "react-bootstrap";

function YesOrNoSurvey (props) {
  const [state, setState] = useState({
    stateOptions: [],

})
const [invalid,setInvalid]=useState(false)

const mystyle = {
  border:'1px solid #ce002a',
  borderRadius:'5px',
  padding: "10px",

};

useEffect(() => {

    setState({ ...state, stateOptions: props?.options })

}, [props?.options])

const handleSelection=(e)=>{

var myValues = state?.stateOptions;
let valueAtIndex = myValues[e]
setFieldValue(quesId, valueAtIndex?.choice)

}

const { name, errors, options, setFieldValue, values, mandatory, quesNumber,quesIndex,quesId, errorName,touched, ...rest } = props;

  return (
    <div className="survey-box whitebox shadow-box m-b-30">
         <h4 className="survey-qstn"><span>{quesNumber }.</span>{props?.name ? props?.name : ''}</h4>
         {
                mandatory ?  <span className="mandatory-star">*</span> :''
            }

          <div className="yesorno-wrap" style={!!errors && !!errors[errorName] && !!errors[errorName] && !!touched && !!touched[errorName] ? mystyle : {}}>

            <ToggleButtonGroup onChange={e => handleSelection(e)} type="radio" name={quesId} className="custom-toggle-radio"
             isInvalid={!!errors && !!errors[errorName] && !!errors[errorName] && !!touched && !!touched[errorName]
              ? ' is-invalid'
              : ''}

            >
            { state?.stateOptions.map((option, index) => {
                return( <ToggleButton value={index} key={index}>
                    <div className="d-flex justify-content-between custom-radio-survey align-items-center">
                        <span>{option?.choice}</span>
                        <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
                    </div>
                </ToggleButton>
                )
            })

            }
            </ToggleButtonGroup>
</div>
</div>
  )
}

export default YesOrNoSurvey
