import { createAction, handleActions } from 'redux-actions';
import {_getAdminLp,_nextAdminLp,_previousAdminLp,_getAdminLearningPathsList,_addLearningPaths,_cloneLearningPath,_updateLearningPaths,_nextDeletedLP,
    _previousDeletedLP,_nextDraftLearningPath,_previousDraftLearningPath,_previousAvailableLearningPath,_nextAvailableLearningPath, _restoreBulkLearningPath,
    _getManagerLearningPathList,_permanentlyBulkDeleteLearningPath,_permanentlyDeleteLearningPath,_getManagerDraftLearningPaths,_getManagerLearningPath,_getDeletedLearningPaths,
    _restoreLearningPath,_getWorkElement,_addWorkElement,_deleteLearningPath,_removeWorkElement,
    _updateWorkElement,_getCoursesWithOutPagination,_getQuizzesWithOutPagination,_getMembersWithOutPagination,
    _getGroupsWithOutPagination,_getClassesWithOutPagination,_updateAssignLearning,_assignLearningPath,_getLearningPathAssignments,_getLearningPathAssignmentsById,_updateLpModule,
    _getNodesLpList,_getElementByIdLp,_deleteElementByIdLp,_deleteAssignLearning,_getManagerLearningPathDetail,_getManagerLearningPathNodes,_getManagerLearningPathNodesDetail,
    _getManagerLearningPathLearners,_getManagerLearningPathLearnersWithPage,_nextgetManagerLearningPathLearnersWithPage,_prevgetManagerLearningPathLearnersWithPage,_getManagerLearningPathLearnersAll,
    _getManagerLearningPathLearnersAllWithPage,_nextgetManagerLearningPathLearnersAllWithPage,_prevgetManagerLearningPathLearnersAllWithPage,_extendLearningPath,_getManagerLearningPathLearnersAssigment,
    _getAdminLPsList,_nextAdminLpPagination,_previousAdminLpPagination,_getAdminLpDetail,s
} from '~shared/httpService/api';

import ActionTypes from '~shared/constants/actionTypes';
import {toast} from "react-toastify";

const initialState = {
    elements: { loading: false, response: [], hasError: false, error: {} },
    learning_path_listing:{ loading: false, response: {}, hasError: false, error: {} },
    learning_path_draft:{ loading: false, response: {}, hasError: false, error: {} },
    get_learning_path_manager:{ loading: false, response: {}, hasError: false, error: {} },
    get_learning_path_manager_details:{ loading: false, response: {}, hasError: false, error: {} },
    deleted_learning_paths : { loading: false, response: {}, hasError: false, error: {} },
    restore_learning_paths : { loading: false, response: {}, hasError: false, error: {} },
    restore_bulk_learning_paths : { loading: false, response: {}, hasError: false, error: {} },
    permanently_delete_learning_paths : { loading: false, response: {}, hasError: false, error: {} },
    bulk_delete_learning_paths : { loading: false, response: {}, hasError: false, error: {} },
    newly_added_lp:{ loading: false, response: {}, hasError: false, error: {} },
    updated_lp:{ loading: false, response: {}, hasError: false, error: {} },
    cloned_lp : { loading: false, response: {}, hasError: false, error: {} },
    lp_listing_admin:{ loading: false, response: {}, hasError: false, error: {} },
    admin_lp : { loading: false, response: {}, hasError: false, error: {} },
    courses_without_pagination : { loading: false, response: {}, hasError: false, error: {} },
    quizzes_without_pagination : { loading: false, response: {}, hasError: false, error: {} },
    groups_without_pagination : { loading: false, response: {}, hasError: false, error: {} },
    classes_without_pagination : { loading: false, response: {}, hasError: false, error: {} },
    members_without_pagination : { loading: false, response: {}, hasError: false, error: {} },
    get_lp_assignments:{ loading: false, response: {}, hasError: false, error: {} },
    get_lp_assigment_by_id:{ loading: false, response: {}, hasError: false, error: {} },
    updated_lp_assigment:{ loading: false, response: {}, hasError: false, error: {} },
    delete_lp_assigment:{ loading: false, response: {}, hasError: false, error: {} },
    assign_lp: { loading: false, response: {}, hasError: false, error: {} },
    updated_lp_module:{ loading: false, response: {}, hasError: false, error: {} },
    updated_lp_module_auto_save:{ loading: false, response: {}, hasError: false, error: {} },
    list_lp_module:{ loading: false, response: {}, hasError: false, error: {} },
    element_lp_module:{ loading: false, response: {}, hasError: false, error: {} },
    delete_lp_module:{ loading: false, response: {}, hasError: false, error: {} },
    list_nodes:{ loading: false, response: {}, hasError: false, error: {} },
    nodes_detail:{ loading: false, response: {}, hasError: false, error: {} },
    nodes_learner:{ loading: false, response: {}, hasError: false, error: {} },
    all_learner:{ loading: false, response: {}, hasError: false, error: {} },
    assigment_detail:{ loading: false, response: {}, hasError: false, error: {} },
    extend_lp:{ loading: false, response: {}, hasError: false, error: {} },
    admin_lps : { loading: false, response: {}, hasError: false, error: {} },
    admin_detail_lp : { loading: false, response: {}, hasError: false, error: {} },
};


export const getElementsStart = createAction(ActionTypes.GET_WORK_FLOW_ELEMENTS);
export const getElementsSuccess = createAction(ActionTypes.GET_WORK_FLOW_ELEMENTS_SUCCESS, response => response);
export const getElementsError = createAction(ActionTypes.GET_WORK_FLOW_ELEMENTS_ERROR, error => error);

export const getWorkElement = () => (dispatch) => {
    dispatch(getElementsStart());
    return _getWorkElement().then((response) => {

        dispatch(getElementsSuccess ());

    }).catch((error) => {
        dispatch(getElementsError (error));
    });
};


export const addElementStart = createAction(ActionTypes.ADD_WORK_FLOW_ELEMENTS);
export const addElementSuccess = createAction(ActionTypes.ADD_WORK_FLOW_ELEMENTS_SUCCESS, response => response);
export const addElementError = createAction(ActionTypes.ADD_WORK_FLOW_ELEMENTS_ERROR, error => error);

export const addWorkElement = (element) => (dispatch) => {
    dispatch(addElementStart());

    return _addWorkElement().then((response) => {

        dispatch(addElementSuccess (element));

    }).catch((error) => {
        dispatch(addElementError (error));
    });
};

export const updateElementStart = createAction(ActionTypes.UPDATE_WORK_FLOW_ELEMENTS);
export const updateElementSuccess = createAction(ActionTypes.UPDATE_WORK_FLOW_ELEMENTS_SUCCESS, response => response);
export const updateElementError = createAction(ActionTypes.UPDATE_WORK_FLOW_ELEMENTS_ERROR, error => error);

export const updateWorkElement = (element) => (dispatch) => {
    dispatch(updateElementStart());
    return _updateWorkElement().then((response) => {
        dispatch(updateElementSuccess (element));

    }).catch((error) => {
        dispatch(updateElementError (error));
    });
};

export const removeElementStart = createAction(ActionTypes.REMOVE_WORK_FLOW_ELEMENTS);
export const removeElementSuccess = createAction(ActionTypes.REMOVE_WORK_FLOW_ELEMENTS_SUCCESS, response => response);
export const removeElementError = createAction(ActionTypes.REMOVE_WORK_FLOW_ELEMENTS_ERROR, error => error);

export const removeWorkElement = (element) => (dispatch) => {
    dispatch(removeElementStart());
    return _updateWorkElement().then((response) => {
        dispatch(removeElementSuccess (element));

    }).catch((error) => {
        dispatch(removeElementError (error));
    });
};
//Learning Paths

export const getLearningPathsManagerStart = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_LIST);
export const getLearningPathsManagerSuccess = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_LIST_SUCCESS, response => response);
export const getLearningPathsManagerError = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_LIST_ERROR, error => error);

export const getManagerLearningPathList = (search, startDate, endDate, createdby, page,category) => (dispatch) => {
    dispatch(getLearningPathsManagerStart());
    return _getManagerLearningPathList(search, startDate, endDate, createdby, page,category).then((response) => {
        dispatch(getLearningPathsManagerSuccess(response));
    }).catch((error) => {
        dispatch(getLearningPathsManagerError(error));

    });
};

export const getDraftLearningPathManagerStart = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_LIST_DRAFT);
export const getDraftLearningPathManagerSuccess = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_DRAFT_SUCCESS, response => response);
export const getDraftLearningPathManagerError = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_DRAFT_ERROR, error => error);

export const getManagerDraftLearningPaths = ( search, startDate, endDate, publish, createdby,page,category) => (dispatch) => {
    dispatch(getDraftLearningPathManagerStart());
    return _getManagerDraftLearningPaths(search, startDate, endDate, publish, createdby,page,category).then((response) => {
        dispatch(getDraftLearningPathManagerSuccess(response));
    }).catch((error) => {
        dispatch(getDraftLearningPathManagerError(error));

    });
};

export const getLearningPathManagerStart = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER);
export const getLearningPathManagerSuccess = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_SUCCESS, response => response);
export const getLearningPathManagerError = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_ERROR, error => error);

export const getManagerLearningPath = (id,callback) => (dispatch) => {
    dispatch(getLearningPathManagerStart());
    return _getManagerLearningPath(id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathManagerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathManagerError(error));
    });
};


export const getLearningPathManagerDetailStart = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_DETAIL);
export const getLearningPathManagerDetailSuccess = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_DETAIL_SUCCESS, response => response);
export const getLearningPathManagerDetailError = createAction(ActionTypes.GET_LEARNING_PATH_MANAGER_DETAIL_ERROR, error => error);

export const getManagerLearningPathDetail = (id,callback) => (dispatch) => {
    dispatch(getLearningPathManagerDetailStart());
    return _getManagerLearningPathDetail(id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathManagerDetailSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathManagerDetailError(error));
    });
};


export const getDeleteLearningStart = createAction(ActionTypes.GET_DELETED_LEARNING_PATH);
export const getDeletedLearningSuccess = createAction(ActionTypes.GET_DELETED_LEARNING_PATH_SUCCESS, response => response);
export const getDeletedLearningError = createAction(ActionTypes.GET_DELETED_LEARNING_PATH_ERROR, error => error);

export const getDeletedLearningPaths = ( search, startDate, endDate,page) => (dispatch) => {
    dispatch(getDeleteLearningStart());
    return _getDeletedLearningPaths(search, startDate, endDate,page).then((response) => {
        dispatch(getDeletedLearningSuccess(response));
    }).catch((error) => {
        dispatch(getDeletedLearningError(error));

    });
};

export const deleteLearningPathStart = createAction(ActionTypes.DELETE_LEARNING_PATH);
export const deleteLearningPathSuccess = createAction(ActionTypes.DELETE_LEARNING_PATH_SUCCESS, response => response);
export const deleteLearningPathError = createAction(ActionTypes.DELETE_LEARNING_PATH_ERROR, error => error);

export const deleteLearningPath = (row,history) => (dispatch) => {
    dispatch(deleteLearningPathStart());


    return _deleteLearningPath(row).then((response) => {
        dispatch(deleteLearningPathSuccess(row));
        toast.success('Learning Path has been  deleted  successfully')
        if(row.is_published){
            history.push('/learningpaths')
        }else {
            history.push('/draft/learningpaths')
        }


    }).catch((error) => {
        dispatch(deleteLearningPathError(error));
        toast.error('Something went wrong')
    });
};

export const permanentlyDeleteLearningPathStart = createAction(ActionTypes.PERMANENTLY_DELETE_LEARNING_PATH);
export const permanentlyDeleteLearningPathSuccess = createAction(ActionTypes.PERMANENTLY_DELETE_LEARNING_PATH_SUCCESS, response => response);
export const permanentlyDeleteLearningPathError = createAction(ActionTypes.PERMANENTLY_DELETE_LEARNING_PATH_ERROR, error => error);


export const permanentlyDeleteLearningPath = (row,callback) => (dispatch) => {
    dispatch(permanentlyDeleteLearningPathStart());
    return _permanentlyDeleteLearningPath(row).then((response) => {
        dispatch(permanentlyDeleteLearningPathSuccess(row));
        callback(row)
        toast.success('Learning Path has been  deleted permanently successfully')

    }).catch((error) => {
        dispatch(permanentlyDeleteLearningPathError(error));
        toast.error('Something went wrong')
    });
};


export const permanentlyDeleteBulkLearningPathStart = createAction(ActionTypes.PERMANENTLY_DELETE_BULK_LEARNING_PATH);
export const permanentlyDeleteBulkLearningPathSuccess = createAction(ActionTypes.PERMANENTLY_DELETE_BULK_LEARNING_PATH_SUCCESS, response => response);
export const permanentlyDeleteBulkLearningPathError = createAction(ActionTypes.PERMANENTLY_DELETE_BULK_LEARNING_PATH_ERROR, error => error);


export const permanentlyBulkDeleteLearningPath = (row,callback) => (dispatch) => {
    dispatch(permanentlyDeleteBulkLearningPathStart());
    return _permanentlyBulkDeleteLearningPath(row).then((response) => {
        dispatch(permanentlyDeleteBulkLearningPathSuccess(row));
        callback('deleted')
        toast.success('Learning Paths has been  deleted permanently successfully')

    }).catch((error) => {
        dispatch(permanentlyDeleteBulkLearningPathError(error));

        toast.error('Something went wrong')
    });
};

export const restoreDeletedLearningPathStart = createAction(ActionTypes.RESTORE_DELETED_LEARNING_PATH);
export const restoreDeletedLearningPathSuccess = createAction(ActionTypes.RESTORE_DELETED_LEARNING_PATH_SUCCESS, response => response);
export const restoreDeletedLearningPathError = createAction(ActionTypes.RESTORE_DELETED_LEARNING_PATH_ERROR, error => error);


export const restoreLearningPath = (row,callback) => (dispatch) => {
    dispatch(restoreDeletedLearningPathStart());
    return _restoreLearningPath(row).then((response) => {
        dispatch(restoreDeletedLearningPathSuccess(row));
        callback(row)
        toast.success('Learning Path has been  restored successfully')

    }).catch((error) => {
        dispatch(restoreDeletedLearningPathError(error));
        toast.error('Something went wrong')
    });
};

export const restoreLearningPathDeletedLpStart = createAction(ActionTypes.RESTORE_BULK_DELETED_LEARNING_PATH);
export const restoreLearningPathDeletedLpSuccess = createAction(ActionTypes.RESTORE_BULK_DELETED_LEARNING_PATH_SUCCESS, response => response);
export const restoreLearningPathDeletedLpError = createAction(ActionTypes.RESTORE_BULK_DELETED_LEARNING_PATH_ERROR, error => error);


export const restoreBulkLearningPath = (row,callback) => (dispatch) => {
    dispatch(restoreLearningPathDeletedLpStart());
    return _restoreBulkLearningPath(row).then((response) => {
        dispatch(restoreLearningPathDeletedLpSuccess(row));
        callback(row)
        toast.success('Learning Paths has been  restored successfully')

    }).catch((error) => {
        dispatch(restoreLearningPathDeletedLpError(error));
        toast.error('Something went wrong')
    });
};

// export const getDraftCoursesManagerStart = createAction(ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT);
// export const getDraftCoursesManagerSuccess = createAction(ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT_SUCCESS, response => response);
// export const getDraftCoursesManagerError = createAction(ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT_ERROR, error => error);
//
// export const getDraftCoursesManagerList = ( search, startDate, endDate, publish, createdby,page) => (dispatch) => {
//     dispatch(getDraftCoursesManagerStart());
//     return _getDraftCoursesManagerList(search, startDate, endDate, publish, createdby,page).then((response) => {
//         dispatch(getDraftCoursesManagerSuccess(response));
//     }).catch((error) => {
//         dispatch(getDraftCoursesManagerError(error));
//
//     });
// };

export const paginationAvailableLearningPathStart = createAction(ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_NEXT);
export const paginationAvailableLearningPathSuccess = createAction(ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationAvailableLearningPathError = createAction(ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationAvailableLearningPath= (url) => (dispatch) => {
    dispatch(paginationAvailableLearningPathStart());
    return _nextAvailableLearningPath(url).then((response) => {
        dispatch(paginationAvailableLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(paginationAvailableLearningPathError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousAvailableLearningPathStart = createAction(ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS);
export const paginationPreviousAvailableLearningPathSuccess = createAction(ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousAvailableLearningPathError = createAction(ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationAvailableLearningPath = (url) => (dispatch) => {
    dispatch(paginationPreviousAvailableLearningPathStart());
    return _previousAvailableLearningPath(url).then((response) => {
        dispatch(paginationPreviousAvailableLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousAvailableLearningPathError(error));
        toast.error('Something went wrong')

    });
};


export const paginationDraftLearningPathStart = createAction(ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_NEXT);
export const paginationDraftLearningPathSuccess = createAction(ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationDraftLearningPathError = createAction(ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationDraftLearningPath = (url) => (dispatch) => {
    dispatch(paginationDraftLearningPathStart());
    return _nextDraftLearningPath(url).then((response) => {
        dispatch(paginationDraftLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(paginationDraftLearningPathError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousDraftLearningPathStart = createAction(ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS);
export const paginationPreviousDraftLearningPathSuccess = createAction(ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousDraftLearningPathError = createAction(ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationDraftLearningPath = (url) => (dispatch) => {
    dispatch(paginationPreviousDraftLearningPathStart());
    return _previousDraftLearningPath(url).then((response) => {
        dispatch(paginationPreviousDraftLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousDraftLearningPathError(error));
        toast.error('Something went wrong')

    });
};

//Deleted Courses
export const paginationDeletedLPSStart = createAction(ActionTypes.DELETED_LEARNING_PATH_PAGINATION_NEXT);
export const paginationDeletedLPSSuccess = createAction(ActionTypes.DELETED_LEARNING_PATH_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationDeletedLPSError = createAction(ActionTypes.DELETED_LEARNING_PATH_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationDeletedLPS= (url) => (dispatch) => {
    dispatch(paginationDeletedLPSStart());
    return _nextDeletedLP(url).then((response) => {
        dispatch(paginationDeletedLPSSuccess(response));
    }).catch((error) => {
        dispatch(paginationDeletedLPSError(error));
        toast.error('Something went wrong')
    });
};


export const paginationPreviousDeletedLPSStart = createAction(ActionTypes.DELETED_LEARNING_PATH_PAGINATION_PREVIOUS);
export const paginationPreviousDeletedLPSSuccess = createAction(ActionTypes.DELETED_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousDeletedLPSError = createAction(ActionTypes.DELETED_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousDeletedLPS = (url) => (dispatch) => {
    dispatch(paginationPreviousDeletedLPSStart());
    return _previousDeletedLP(url).then((response) => {
        dispatch(paginationPreviousDeletedLPSSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousDeletedLPSError(error));
        toast.error('Something went wrong')

    });
};
export const addLearningPathStart = createAction(ActionTypes.ADD_LEARNING_PATH);
export const addLearningPathSuccess = createAction(ActionTypes.ADD_LEARNING_PATH_SUCCESS, response => response);
export const addLearningPathError = createAction(ActionTypes.ADD_LEARNING_PATH_ERROR, error => error);

export const addLearningPaths = (formData,callback) => (dispatch) => {
    dispatch(addLearningPathStart());

    return _addLearningPaths(formData).then((response) => {
        if(response){
            toast.success('Learning Path detail has been created Successfully')
            callback(response)
        }
        dispatch(addLearningPathSuccess(response));
    }).catch((error) => {

        dispatch(addLearningPathError(error));
        if(error){
            if(error.image){
                toast.error(error.image[0])
            }else if(Object.keys(error).length){
                toast.error(`${Object.values(error)[0]}`)
            }else {
                toast.error('Something went wrong')
            }
        }


    });
};
export const updateLearningPathStart = createAction(ActionTypes.UPDATE_LEARNING_PATH);
export const updateLearningPathSuccess = createAction(ActionTypes.UPDATE_LEARNING_PATH_SUCCESS, response => response);
export const updateLearningPathError = createAction(ActionTypes.UPDATE_LEARNING_PATH_ERROR, error => error);

export const updateLearningPaths = (formData,id,callback) => (dispatch) => {
    dispatch(updateLearningPathStart());

    return _updateLearningPaths(formData,id).then((response) => {
        if(response){
            callback(response)
            toast.success('Learning Path detail has been updated Successfully')
        }
        dispatch(updateLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(updateLearningPathError(error));
        if(error){
            if(error.image){
                toast.error(error.image[0])
            }
        }else{
            toast.error('Something went wrong')
        }

    });
};

export const cloneLearningPathStart = createAction(ActionTypes.CLONE_LEARNING_PATH);
export const cloneLearningPathSuccess = createAction(ActionTypes.CLONE_LEARNING_PATH_SUCCESS, response => response);
export const cloneLearningPathError = createAction(ActionTypes.CLONE_LEARNING_PATH_ERROR, error => error);

export const cloneLearningPath = (id,history) => (dispatch) => {
    dispatch(cloneLearningPathStart());

    return _cloneLearningPath(id).then((response) => {
        toast.success('Learning Path has  Cloned Successfully')
       history.push({
            pathname: '/learningpaths',
            state: { tab: 'Drafts' }
        })

        dispatch(cloneLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(cloneLearningPathError(error));
        toast.error('Something went wrong')

    });
};
/////////////////////////////////////////////////////////////////
export const getLpsAdminStart = createAction(ActionTypes.GET_LEARNING_PATH_ADMIN_LIST);
export const getLpsAdminSuccess = createAction(ActionTypes.GET_LEARNING_PATH_ADMIN_LIST_SUCCESS, response => response);
export const getLpsAdminError = createAction(ActionTypes.GET_LEARNING_PATH_ADMIN_LIST_ERROR, error => error);

export const getAdminLearningPathsList = (search, startDate, endDate, page) => (dispatch) => {
    dispatch(getLpsAdminStart());
    return _getAdminLearningPathsList(search, startDate, endDate, page).then((response) => {
        dispatch(getLpsAdminSuccess(response));
    }).catch((error) => {
        dispatch(getLpsAdminError(error));

    });
};

export const paginationAdminLpStart = createAction(ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_NEXT);
export const paginationAdminLpSuccess = createAction(ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationAdminLpError = createAction(ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationAdminLp = (url) => (dispatch) => {
    dispatch(paginationAdminLpStart());
    return _nextAdminLp(url).then((response) => {
        dispatch(paginationAdminLpSuccess(response));
    }).catch((error) => {
        dispatch(paginationAdminLpError(error));
        toast.error('Something went wrong')
    });
};


export const paginationPreviousAdminLpStart = createAction(ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS);
export const paginationPreviousAdminLpSuccess = createAction(ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousAdminLpError = createAction(ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationAdminLp = (url) => (dispatch) => {
    dispatch(paginationPreviousAdminLpStart());
    return _previousAdminLp(url).then((response) => {
        dispatch(paginationPreviousAdminLpSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousAdminLpError(error));
        toast.error('Something went wrong')

    });
};



export const getLpAdminStart = createAction(ActionTypes.GET_LEARNING_PATH_ADMIN);
export const getLpAdminSuccess = createAction(ActionTypes.GET_LEARNING_PATH_ADMIN_SUCCESS, response => response);
export const getLpAdminError = createAction(ActionTypes.GET_LEARNING_PATH_ADMIN_ERROR, error => error);

export const getAdminLp = (id,callback) => (dispatch) => {
    dispatch(getLpAdminStart());
    return _getAdminLp(id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLpAdminSuccess(response));
    }).catch((error) => {
        dispatch(getLpAdminError(error));
    });
};


export const getCoursesWithOutPaginationStart = createAction(ActionTypes.GET_COURSES_LIST_WITHOUT_PAGINATION);
export const getCoursesWithOutPaginationSuccess = createAction(ActionTypes.GET_COURSES_LIST_WITHOUT_PAGINATION_SUCCESS, response => response);
export const getCoursesWithOutPaginationError = createAction(ActionTypes.GET_COURSES_LIST_WITHOUT_PAGINATION_ERROR, error => error);

export const getCoursesWithOutPagination = () => (dispatch) => {
    dispatch(getCoursesWithOutPaginationStart());
    return _getCoursesWithOutPagination().then((response) => {

        dispatch(getCoursesWithOutPaginationSuccess (response));

    }).catch((error) => {
        dispatch(getCoursesWithOutPaginationError (error));
    });
};
export const getQuizzesWithOutPaginationStart = createAction(ActionTypes.GET_QUIZZES_LIST_WITHOUT_PAGINATION);
export const getQuizzesWithOutPaginationSuccess = createAction(ActionTypes.GET_QUIZZES_LIST_WITHOUT_PAGINATION_SUCCESS, response => response);
export const getQuizzesWithOutPaginationError = createAction(ActionTypes.GET_QUIZZES_LIST_WITHOUT_PAGINATION_ERROR, error => error);

export const getQuizzesWithOutPagination = () => (dispatch) => {
    dispatch(getQuizzesWithOutPaginationStart());
    return _getQuizzesWithOutPagination().then((response) => {

        dispatch(getQuizzesWithOutPaginationSuccess (response));

    }).catch((error) => {
        dispatch(getQuizzesWithOutPaginationError (error));
    });
};

export const getGroupsWithOutPaginationStart = createAction(ActionTypes.GET_GROUPS_LIST_WITHOUT_PAGINATION);
export const getGroupsWithOutPaginationSuccess = createAction(ActionTypes.GET_GROUPS_LIST_WITHOUT_PAGINATION_SUCCESS, response => response);
export const getGroupsWithOutPaginationError = createAction(ActionTypes.GET_GROUPS_LIST_WITHOUT_PAGINATION_ERROR, error => error);

export const getGroupsWithOutPagination = () => (dispatch) => {
    dispatch(getGroupsWithOutPaginationStart());
    return _getGroupsWithOutPagination().then((response) => {

        dispatch(getGroupsWithOutPaginationSuccess (response));

    }).catch((error) => {
        dispatch(getGroupsWithOutPaginationError (error));
    });
};

/////////////////////////AddClassess///////////////

export const getClassesWithOutPaginationStart = createAction(ActionTypes.GET_CLASSES_LIST_WITHOUT_PAGINATION);
export const getClassesWithOutPaginationSuccess = createAction(ActionTypes.GET_CLASSES_LIST_WITHOUT_PAGINATION_SUCCESS, response => response);
export const getClassesWithOutPaginationError = createAction(ActionTypes.GET_CLASSES_LIST_WITHOUT_PAGINATION_ERROR, error => error);

export const getClassesWithOutPagination = () => (dispatch) => {
    dispatch(getClassesWithOutPaginationStart());
    return _getClassesWithOutPagination().then((response) => {


        dispatch(getClassesWithOutPaginationSuccess (response));

    }).catch((error) => {
        dispatch(getClassesWithOutPaginationError (error));
    });
};

///////////////////////////////////////////////////



export const getMembersWithOutPaginationStart = createAction(ActionTypes.GET_MEMBERS_LIST_WITHOUT_PAGINATION);
export const getMembersWithOutPaginationSuccess = createAction(ActionTypes.GET_MEMBERS_LIST_WITHOUT_PAGINATION_SUCCESS, response => response);
export const getMembersWithOutPaginationError = createAction(ActionTypes.GET_MEMBERS_LIST_WITHOUT_PAGINATION_ERROR, error => error);

export const getMembersWithOutPagination = () => (dispatch) => {
    dispatch(getMembersWithOutPaginationStart());
    return _getMembersWithOutPagination().then((response) => {

        dispatch(getMembersWithOutPaginationSuccess (response));

    }).catch((error) => {
        dispatch(getMembersWithOutPaginationError (error));
    });
};


//ASSIGNMENTS
export const assignLpStart = createAction(ActionTypes.ASSIGN_LEARNING_Path);
export const assignLpSuccess = createAction(ActionTypes.ASSIGN_LEARNING_Path_SUCCESS, response => response);
export const assignLpError = createAction(ActionTypes.ASSIGN_LEARNING_Path_ERROR, error => error);

export const assignLearningPath = (data, userId,history,callback) => (dispatch) => {
    dispatch(assignLpStart());
    return _assignLearningPath(data, userId).then((response) => {
        if(response){
            toast.success('Learning path has been  assigned  successfully')
              if(history != null) {
                  history.push('/learningpaths')
              }
        if(history == null){
            callback('success')
        }
        }
        dispatch(assignLpSuccess(response));
        if (response) {
            // toast.success('Lp Assigned')
        }
    }).catch((error) => {
        dispatch(assignLpError(error));

        if(error){
            // console.log(error)
            // console.log(error?.error.errorMessage)
            if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
                }else {
                toast.error('Something went wrong')
            }
            if(history != null) {
                callback(error)
            }
        }
    });
};

export const getLpAssignmentsStart = createAction(ActionTypes.ASSIGNMENT_LIST_LP);
export const getLpAssignmentsSuccess = createAction(ActionTypes.ASSIGNMENT_LP_LIST_SUCCESS, response => response);
export const getLpAssignmentsError = createAction(ActionTypes.ASSIGNMENT_LP_LIST_ERROR, error => error);

export const getLearningPathAssignments = (id,platform) => (dispatch) => {
    dispatch(getLpAssignmentsStart());
    return _getLearningPathAssignments(id,platform).then((response) => {
        dispatch(getLpAssignmentsSuccess(response));
    }).catch((error) => {
        dispatch(getLpAssignmentsError(error));
        toast.error('Something went wrong')
    });
};
export const getLpAssignmentsByIdStart = createAction(ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_LP);
export const getLpAssignmentsByIdSuccess = createAction(ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_LP_SUCCESS, response => response);
export const getLpAssignmentsByIdError = createAction(ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_LP_ERROR, error => error);
export const getLearningPathAssignmentsById = (id,assignid,callback,callbackError) => (dispatch) => {
    dispatch(getLpAssignmentsByIdStart());
    return _getLearningPathAssignmentsById(id,assignid).then((response) => {
        if(response){
            callback(response)
        }

        dispatch(getLpAssignmentsByIdSuccess(response));
    }).catch((error) => {
        dispatch(getLpAssignmentsByIdError(error));
        if(error){
     
            if(error.detail){
                toast.error('Detail not Found')
                callbackError(error)
            }else{
                toast.error('Something went wrong')
                callbackError(error)
            }

        }

    });
};
export const updateAssignLpStart = createAction(ActionTypes.UPDATE_LP_ASSIGNMENT);
export const updateAssignLpSuccess = createAction(ActionTypes.UPDATE_LP_ASSIGNMENT_SUCCESS, response => response);
export const updateAssignLpError = createAction(ActionTypes.UPDATE_LP_ASSIGNMENT_ERROR, error => error);

export const updateAssignLearning = (data, userId,assignid,history,callback) => (dispatch) => {
    dispatch(updateAssignLpStart());
    return _updateAssignLearning(data, userId,assignid).then((response) => {
        if(response){
            toast.success('Learning path has been  assigned  successfully')
            history.push('/learningpaths')
        }
        dispatch(updateAssignLpSuccess(response));
    }).catch((error) => {
        dispatch(updateAssignLpError(error));

        callback(error)
    });
};
export const deleteAssignLpStart = createAction(ActionTypes.DELETE_LP_ASSIGNMENT);
export const deleteAssignLpSuccess = createAction(ActionTypes.DELETE_LP_ASSIGNMENT_SUCCESS, response => response);
export const deleteAssignLpError = createAction(ActionTypes.DELETE_LP_ASSIGNMENT_ERROR, error => error);

export const deleteAssignLearning = (userId,assignid,history,callback) => (dispatch) => {
    dispatch(deleteAssignLpStart());
    return _deleteAssignLearning(userId,assignid).then((response) => {


        toast.success('Learning path assingement has been  deleted  successfully')
        callback('successs')
        dispatch(deleteAssignLpSuccess(response));
    }).catch((error) => {
        dispatch(deleteAssignLpError(error));


    });
};


export const updateLpNodeStart = createAction(ActionTypes.UPDATE_LP_NODE);
export const updateLpNodeSuccess = createAction(ActionTypes.UPDATE_LP_NODE_SUCCESS, response => response);
export const updateLpNodeError = createAction(ActionTypes.UPDATE_LP_NODE_ERROR, error => error);

export const updateLpModule = (formData,id,callback) => (dispatch) => {
    dispatch(updateLpNodeStart());

    return _updateLpModule(formData,id).then((response) => {
        dispatch(updateLpNodeSuccess(response));
        if(response){
            toast.success('Learning Path has been created  successfully')
            callback(response)
        }

    }).catch((error) => {
        dispatch(updateLpNodeError(error));
        if(error?.template){
            toast.error(error?.template[0])
        }else {
            toast.error('Something went wrong')
        }
    });
};


export const updateLpNodeAutoSaveStart = createAction(ActionTypes.UPDATE_LP_NODE_AUTO_SAVE);
export const updateLpNodeAutoSaveSuccess = createAction(ActionTypes.UPDATE_LP_NODE_AUTO_SAVE_SUCCESS, response => response);
export const updateLpNodeAutoSaveError = createAction(ActionTypes.UPDATE_LP_NODE_AUTO_SAVE_ERROR, error => error);

export const updateLpModuleAutoSave = (formData,id,callback) => (dispatch) => {
    dispatch(updateLpNodeAutoSaveStart());

    return _updateLpModule(formData,id).then((response) => {
        dispatch(updateLpNodeAutoSaveSuccess(response));
        if(response){
            // toast.success('Learning Path has been created  successfully')
            callback(response)
        }

    }).catch((error) => {
        dispatch(updateLpNodeAutoSaveError(error));
        if(error?.target_node){
            return false
        }else if(error?.template){
            toast.error(error?.template[0])
        }else {
            toast.error('Something went wrong')
        }
    });
};


export const listLpNodeStart = createAction(ActionTypes.LIST_LP_NODE);
export const listLpNodeSuccess = createAction(ActionTypes.LIST_LP_NODE_SUCCESS, response => response);
export const listLpNodeError = createAction(ActionTypes.LIST_LP_NODE_ERROR, error => error);

export const getNodesLpList = (id,callback,type) => (dispatch) => {
    dispatch(listLpNodeStart());

    return _getNodesLpList(id,type).then((response) => {
        dispatch(listLpNodeSuccess(response));
        if(response){
            // toast.success('Modules has been  updated  successfully')
            callback(response)
        }

    }).catch((error) => {
        dispatch(listLpNodeError(error));
        if(error?.template){
            toast.error(error?.template[0])
        }else {
            toast.error('Something went wrong')
        }
    });
};

export const elementLpNodeStart = createAction(ActionTypes.ELEMENT_LP_NODE);
export const elementLpNodeSuccess = createAction(ActionTypes.ELEMENT_LP_NODE_SUCCESS, response => response);
export const elementLpNodeError = createAction(ActionTypes.ELEMENT_LP_NODE_ERROR, error => error);

export const getElementByIdLp = (formData,id,callback) => (dispatch) => {
    dispatch(elementLpNodeStart());

    return _getElementByIdLp(formData,id).then((response) => {
        dispatch(elementLpNodeSuccess(response));
        if(response){
            toast.success('Modules has been  updated  successfully')
            callback(response)
        }

    }).catch((error) => {
        dispatch(elementLpNodeError(error));
        if(error?.template){
            toast.error(error?.template[0])
        }else {
            toast.error('Something went wrong')
        }
    });
};

export const deleteLpNodeStart = createAction(ActionTypes.DELETE_ELEMENT_LP_NODE);
export const deleteLpNodeSuccess = createAction(ActionTypes.DELETE_ELEMENT_LP_NODE_SUCCESS, response => response);
export const deleteLpNodeError = createAction(ActionTypes.DELETE_ELEMENT_LP_NODE_ERROR, error => error);

export const deleteElementByIdLp = (id,nodeId,callback) => (dispatch) => {
    dispatch(deleteLpNodeStart());

    return _deleteElementByIdLp(id,nodeId).then((response) => {
        dispatch(deleteLpNodeSuccess(response));
        response = 'deleted'
        if(response){
            toast.success('Node has been  successfully deleted')
            callback(response)
        }

    }).catch((error) => {
        dispatch(deleteLpNodeError(error));
        if(error?.template){
            toast.error(error?.template[0])
        }else {
            toast.error('Something went wrong')
        }
    });
};

export const getLearningPathNodesManagerStart = createAction(ActionTypes.LP_NODE_LIST_MANAGER);
export const getLearningPathNodesManagerSuccess = createAction(ActionTypes.LP_NODE_LIST_MANAGER_SUCCESS, response => response);
export const getLearningPathNodesManagerError = createAction(ActionTypes.LP_NODE_LIST_MANAGER_SUCCESS_ERROR, error => error);

export const getManagerLearningPathNodes = (id,callback,type) => (dispatch) => {
    dispatch(getLearningPathNodesManagerStart());
    return _getManagerLearningPathNodes(id,type).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathNodesManagerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathNodesManagerError(error));
    });
};
export const getLearningPathNodesDetailManagerStart = createAction(ActionTypes.LP_NODE_LIST_DETAIL_MANAGER);
export const getLearningPathNodesDetailManagerSuccess = createAction(ActionTypes.LP_NODE_LIST_DETAIL_MANAGER_SUCCESS, response => response);
export const getLearningPathNodesDetailManagerError = createAction(ActionTypes.LP_NODE_LIST_DETAIL_MANAGER_SUCCESS_ERROR, error => error);

export const getManagerLearningPathNodesDetail = (id,elementId,callback,type) => (dispatch) => {
    dispatch(getLearningPathNodesDetailManagerStart());
    return _getManagerLearningPathNodesDetail(id,elementId,type).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathNodesDetailManagerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathNodesDetailManagerError(error));
    });
};
export const getLearningPathNodesLearnerManagerStart = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER);
export const getLearningPathNodesLearnerManagerSuccess = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_SUCCESS, response => response);
export const getLearningPathNodesLearnerManagerError = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_SUCCESS_ERROR, error => error);

export const getManagerLearningPathLearners = (id,elementId,callback) => (dispatch) => {
    dispatch(getLearningPathNodesLearnerManagerStart());
    return _getManagerLearningPathLearners(id,elementId).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathNodesLearnerManagerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathManagerError(error));
    });
};

export const getLearningPathNodesLearnerManagerWithPageStart = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH);
export const getLearningPathNodesLearnerManagerWithPageSuccess = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_SUCCESS, response => response);
export const getLearningPathNodesLearnerManagerWithPageError = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_ERROR, error => error);

export const getManagerLearningPathLearnersWithPage = (id,elementId,page,callback,type) => (dispatch) => {
    dispatch(getLearningPathNodesLearnerManagerWithPageStart());
    return _getManagerLearningPathLearnersWithPage(id,elementId,page,type).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathNodesLearnerManagerWithPageSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathNodesLearnerManagerWithPageError(error));
    });
};


export const paginationLearningPathNodesLearnerManagerWithPageStart = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PAGINATION_NEXT);
export const paginationLearningPathNodesLearnerManagerWithPageSuccess = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_NEXT_SUCCESS, response => response);
export const paginationLearningPathNodesLearnerManagerWithPageError = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_NEXT_ERROR, error => error);

export const nextgetManagerLearningPathLearnersWithPage= (url,callback) => (dispatch) => {
    dispatch(paginationLearningPathNodesLearnerManagerWithPageStart());
    return _nextgetManagerLearningPathLearnersWithPage(url).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(paginationLearningPathNodesLearnerManagerWithPageSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearningPathNodesLearnerManagerWithPageError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousLearningPathNodesLearnerManagerWithPageStart = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PAGINATION_PREVIOUS);
export const paginationPreviousLearningPathNodesLearnerManagerWithPageSuccess = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearningPathNodesLearnerManagerWithPageError = createAction(ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PREVIOUS_ERROR, error => error);

export const prevgetManagerLearningPathLearnersWithPage = (url,callback) => (dispatch) => {
    dispatch(paginationPreviousLearningPathNodesLearnerManagerWithPageStart());
    return _prevgetManagerLearningPathLearnersWithPage(url).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(paginationPreviousLearningPathNodesLearnerManagerWithPageSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearningPathNodesLearnerManagerWithPageError(error));
        toast.error('Something went wrong')

    });
};

export const getLearningPathAllLearnerManagerStart = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER);
export const getLearningPathAllLearnerManagerSuccess = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_SUCCESS, response => response);
export const getLearningPathAllLearnerManagerError = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_SUCCESS_ERROR, error => error);

export const getManagerLearningPathLearnersAll = (id,callback) => (dispatch) => {
    dispatch(getLearningPathAllLearnerManagerStart());
    return _getManagerLearningPathLearnersAll(id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathAllLearnerManagerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathAllLearnerManagerError(error));
        toast.error('Something went wrong')
    });
};

export const getLearningPathAllLearnerManagerWithPageStart = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE);
export const getLearningPathAllLearnerManagerWithPageSuccess = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_SUCCESS, response => response);
export const getLearningPathAllLearnerManagerWithPageError = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_SUCCESS_ERROR, error => error);

export const getManagerLearningPathLearnersAllWithPage = (id,page) => (dispatch) => {
    dispatch(getLearningPathAllLearnerManagerWithPageStart());
    return _getManagerLearningPathLearnersAllWithPage(id,page).then((response) => {
        dispatch(getLearningPathAllLearnerManagerWithPageSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathAllLearnerManagerWithPageError(error));
        toast.error('Something went wrong')
    });
};


export const paginationLearningPathAllLearnerManagerWithPageStart = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_PAGINATION_NEXT);
export const paginationLearningPathAllLearnerManagerWithPageSuccess = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_NEXT_SUCCESS, response => response);
export const paginationLearningPathAllLearnerManagerWithPageError = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_NEXT_ERROR, error => error);

export const nextgetManagerLearningPathLearnersAllWithPage= (url) => (dispatch) => {
    dispatch(paginationLearningPathAllLearnerManagerWithPageStart());
    return _nextgetManagerLearningPathLearnersAllWithPage(url).then((response) => {
        dispatch(paginationLearningPathAllLearnerManagerWithPageSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearningPathAllLearnerManagerWithPageError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousLearningPathAllLearnerManagerWithPageStart = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_PAGINATION_PREVIOUS);
export const paginationPreviousLearningPathAllLearnerManagerWithPageSuccess = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearningPathAllLearnerManagerWithPageError = createAction(ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_PREVIOUS_ERROR, error => error);

export const prevgetManagerLearningPathLearnersAllWithPage = (url) => (dispatch) => {
    dispatch(paginationPreviousLearningPathAllLearnerManagerWithPageStart());
    return _prevgetManagerLearningPathLearnersAllWithPage(url).then((response) => {

        dispatch(paginationPreviousLearningPathAllLearnerManagerWithPageSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearningPathAllLearnerManagerWithPageError(error));
        toast.error('Something went wrong')

    });
};

//ASSIGNMENTS
export const extendLpStart = createAction(ActionTypes.LP_LEARNER_LP_EXTEND);
export const extendLpSuccess = createAction(ActionTypes.LP_LEARNER_LP_EXTEND_SUCCESS, response => response);
export const extendLpError = createAction(ActionTypes.LP_LEARNER_LP_EXTEND_ERROR, error => error);

export const extendLearningPath = (data,lpId,assignmentId,callback) => (dispatch) => {
    dispatch(extendLpStart());
    return _extendLearningPath(data,lpId,assignmentId).then((response) => {
        if(response){

            callback(response)

        }
        dispatch(extendLpSuccess(response));

    }).catch((error) => {
        dispatch(extendLpError(error));
        if(error){
            // console.log(error)
            // console.log(error?.error.errorMessage)
            toast.error(error?.error.errorMessage)
            callback(error)
        }
    });
};

export const getLearningPathAssigmentDetailManagerStart = createAction(ActionTypes.LP_LEARNER_ASSIGNMENT_MANAGER);
export const getLearningPathAssigmentDetailManagerSuccess = createAction(ActionTypes.LP_LEARNER_ASSIGNMENT_MANAGER_SUCCESS, response => response);
export const getLearningPathAssigmentDetailManagerError = createAction(ActionTypes.LP_LEARNER_ASSIGNMENT_MANAGER_SUCCESS_ERROR, error => error);

export const getManagerLearningPathLearnersAssigment = (id,elementId,callback) => (dispatch) => {
    dispatch(getLearningPathAssigmentDetailManagerStart());
    return _getManagerLearningPathLearnersAssigment(id,elementId).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathAssigmentDetailManagerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathAssigmentDetailManagerError(error));
    });
};

export const getLpsAdminDetailStart = createAction(ActionTypes.GET_LPS_ADMIN_LIST);
export const getLpsAdminDetailSuccess = createAction(ActionTypes.GET_LPS_ADMIN_LIST_SUCCESS, response => response);
export const getLpsAdminDetailError = createAction(ActionTypes.GET_LPS_ADMIN_LIST_ERROR, error => error);

export const getAdminLPsList = (search, startDate, endDate, page) => (dispatch) => {
    dispatch(getLpsAdminDetailStart());
    return _getAdminLPsList(search, startDate, endDate, page).then((response) => {
        dispatch(getLpsAdminDetailSuccess(response));

    }).catch((error) => {
        dispatch(getLpsAdminDetailError(error));
        toast.error('Something went wrong')

    });
};


export const paginationAdminLpDetailStart = createAction(ActionTypes.ADMIN_LP_PAGINATION_NEXT);
export const paginationAdminLpDetailSuccess = createAction(ActionTypes.ADMIN_LP_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationAdminLpDetailError = createAction(ActionTypes.ADMIN_LP_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationAdminLpNew = (url) => (dispatch) => {
    dispatch(paginationAdminLpDetailStart());
    return _nextAdminLpPagination(url).then((response) => {
        dispatch(paginationAdminLpDetailSuccess(response));
    }).catch((error) => {
        dispatch(paginationAdminLpDetailError(error));
        toast.error('Something went wrong')
    });
};


export const paginationPreviousAdminLpDetailStart = createAction(ActionTypes.ADMIN_LP_PAGINATION_PREVIOUS);
export const paginationPreviousAdminLpDetailSuccess = createAction(ActionTypes.ADMIN_LP_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousAdminLpDetailError = createAction(ActionTypes.ADMIN_LP_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationAdminLpNew = (url) => (dispatch) => {
    dispatch(paginationPreviousAdminLpDetailStart());
    return _previousAdminLpPagination(url).then((response) => {
        dispatch(paginationPreviousAdminLpDetailSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousAdminLpDetailError(error));
        toast.error('Something went wrong')

    });
};

export const getLpDetailAdminStart = createAction(ActionTypes.GET_LP_ADMIN);
export const getLpDetailAdminSuccess = createAction(ActionTypes.GET_LP_ADMIN_SUCCESS, response => response);
export const getLpDetailAdminError = createAction(ActionTypes.GET_LP_ADMIN_ERROR, error => error);

export const getAdminLpNew = (id,callback) => (dispatch) => {
    dispatch(getLpDetailAdminStart());
    return _getAdminLpDetail(id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLpDetailAdminSuccess(response));
    }).catch((error) => {
        dispatch(getLpDetailAdminError(error));
    });
};



//////////////////////////////////////////////////////////////
const reducer = handleActions({

    [ActionTypes.GET_WORK_FLOW_ELEMENTS]: (state) => ({
        ...state,
        elements: {
            ...state. elements,
            loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_WORK_FLOW_ELEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        elements: {
            ...state.  elements,
            response: state.elements.response, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.GET_WORK_FLOW_ELEMENTS_ERROR]: (state, action) => ({
        ...state,
        elements: {
            ...state.  elements,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    [ActionTypes.ADD_WORK_FLOW_ELEMENTS]: (state) => ({
        ...state,
        elements: {
            ...state. elements,
            loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.ADD_WORK_FLOW_ELEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        elements: {
            ...state.  elements,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.ADD_WORK_FLOW_ELEMENTS_ERROR]: (state, action) => ({
        ...state,
        elements: {
            ...state.  elements,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Get Manger Learning Paths
    [ActionTypes.GET_LEARNING_PATH_MANAGER_LIST]: (state) => ({
        ...state,
        learning_path_listing: {
            ...state.learning_path_listing,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_MANAGER_LIST_SUCCESS]: (state, action) => ({
        ...state,

        learning_path_listing: {
            ...state.learning_path_listing,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_MANAGER_LIST_ERROR]: (state, action) => ({
        ...state,

        learning_path_listing: {
            ...state.learning_path_listing,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Get Draft Manger Learning Path
    [ActionTypes.GET_LEARNING_PATH_MANAGER_LIST_DRAFT]: (state) => ({
        ...state,
        learning_path_draft: {
            ...state.learning_path_draft,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNING_PATH_MANAGER_DRAFT_SUCCESS]: (state, action) => ({
        ...state,

        learning_path_draft: {
            ...state.learning_path_draft,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.GET_LEARNING_PATH_MANAGER_DRAFT_ERROR]: (state, action) => ({
        ...state,

        learning_path_draft: {
            ...state.learning_path_draft,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Get Manger Learning path
    [ActionTypes.GET_LEARNING_PATH_MANAGER]: (state) => ({
        ...state,
        get_learning_path_manager: {
            ...state.get_learning_path_manager,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_MANAGER_SUCCESS]: (state, action) => ({
        ...state,

        get_learning_path_manager: {
            ...state.get_learning_path_manager,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_MANAGER_ERROR]: (state, action) => ({
        ...state,

        get_learning_path_manager: {
            ...state.get_learning_path_manager,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
    //DELETED LEARNING PATHS
    [ActionTypes.GET_DELETED_LEARNING_PATH]: (state) => ({
        ...state,
        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_DELETED_LEARNING_PATH_SUCCESS]: (state, action) => ({
        ...state,

        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.GET_DELETED_LEARNING_PATH_ERROR]: (state, action) => ({
        ...state,

        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //    Available Course Pagination Next
    [ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_NEXT]: (state) => ({
        ...state,
        learning_path_listing: {
            ...state.learning_path_listing,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        learning_path_listing: {
            ...state.learning_path_listing,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        learning_path_listing: {
            ...state.learning_path_listing,
            loading: false, hasError: false, error: {}
        }
    }),

    //    Available Learning Path Pagination Previous
    [ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        learning_path_listing: {
            ...state.learning_path_listing,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        learning_path_listing: {
            ...state.learning_path_listing,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        learning_path_listing: {
            ...state.learning_path_listing,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //    Draft Learning Pagination Next
    [ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_NEXT]: (state) => ({
        ...state,
        learning_path_draft: {
            ...state.learning_path_draft,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        learning_path_draft: {
            ...state.learning_path_draft,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        learning_path_draft: {
            ...state.learning_path_draft,
            loading: false, hasError: false, error: {}
        }
    }),
    //    Draft Learning Pagination Previous
    [ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        learning_path_draft: {
            ...state.learning_path_draft,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,


        learning_path_draft: {
            ...state.learning_path_draft,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        learning_path_draft: {
            ...state.learning_path_draft,
            loading: false, hasError: false, error: {}
        }
    }),

    //   DELETED LPS Pagination NEXT
    [ActionTypes.DELETED_LEARNING_PATH_PAGINATION_NEXT]: (state) => ({
        ...state,
        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DELETED_LEARNING_PATH_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DELETED_LEARNING_PATH_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            loading: false, hasError: false, error: {}
        }
    }),
    //   DELETED LPS Pagination Previous
    [ActionTypes.DELETED_LEARNING_PATH_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DELETED_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,


        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DELETED_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        deleted_learning_paths: {
            ...state.deleted_learning_paths,
            loading: false, hasError: false, error: {}
        }
    }),

    //   RESTORE DELETED COURSE
    [ActionTypes.RESTORE_DELETED_LEARNING_PATH]: (state) => ({
        ...state,
        restore_learning_paths: {
            ...state.restore_learning_paths,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.RESTORE_DELETED_LEARNING_PATH_SUCCESS]: (state, action) => ({
        ...state,


        restore_learning_paths: {
            ...state.restore_learning_paths,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.RESTORE_DELETED_LEARNING_PATH_ERROR]: (state, action) => ({
        ...state,

        restore_learning_paths: {
            ...state.restore_learning_paths,
            loading: false, hasError: false, error: {}
        }
    }),


    //   RESTORe BULK DELETED COURSE
    [ActionTypes.RESTORE_BULK_DELETED_LEARNING_PATH]: (state) => ({
        ...state,
        restore_bulk_learning_paths: {
            ...state.restore_bulk_learning_paths,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.RESTORE_BULK_DELETED_LEARNING_PATH_SUCCESS]: (state, action) => ({
        ...state,


        restore_bulk_learning_paths: {
            ...state.restore_bulk_learning_paths,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.RESTORE_BULK_DELETED_LEARNING_PATH_ERROR]: (state, action) => ({
        ...state,

        restore_bulk_learning_paths: {
            ...state.restore_bulk_learning_paths,
            loading: false, hasError: false, error: {}
        }
    }),

    //   PERMANENTLY DELETED COURSE
    [ActionTypes.PERMANENTLY_DELETE_LEARNING_PATH]: (state) => ({
        ...state,
        permanently_delete_learning_paths: {
            ...state.permanently_delete_learning_paths,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.PERMANENTLY_DELETE_LEARNING_PATH_SUCCESS]: (state, action) => ({
        ...state,


        permanently_delete_learning_paths: {
            ...state.permanently_delete_learning_paths,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.PERMANENTLY_DELETE_LEARNING_PATH_ERROR]: (state, action) => ({
        ...state,

        permanently_delete_learning_paths: {
            ...state.permanently_delete_learning_paths,
            loading: false, hasError: false, error: {}
        }
    }),

    //   PERMANENTLY DELETED BULK COURSE
    [ActionTypes.PERMANENTLY_DELETE_BULK_LEARNING_PATH]: (state) => ({
        ...state,
        bulk_delete_learning_paths: {
            ...state.bulk_delete_learning_paths,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.PERMANENTLY_DELETE_BULK_LEARNING_PATH_SUCCESS]: (state, action) => ({
        ...state,


        bulk_delete_learning_paths: {
            ...state.bulk_delete_learning_paths,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.PERMANENTLY_DELETE_BULK_LEARNING_PATH_ERROR]: (state, action) => ({
        ...state,

        bulk_delete_learning_paths: {
            ...state.bulk_delete_learning_paths,
            loading: false, hasError: false, error: {}
        }
    }),

    //ADD LP
    [ActionTypes.ADD_LEARNING_PATH]: (state) => ({
        ...state,
        newly_added_lp: {
            ...state.newly_added_lp,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.ADD_LEARNING_PATH_SUCCESS]: (state, action) => ({
        ...state,

        newly_added_lp: {
            ...state.newly_added_lp,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.ADD_LEARNING_PATH_ERROR]: (state, action) => ({
        ...state,

        newly_added_lp: {
            ...state.newly_added_lp,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),
    //Update LP
    [ActionTypes.UPDATE_LEARNING_PATH]: (state) => ({
        ...state,
        updated_lp: {
            ...state.updated_lp,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.UPDATE_LEARNING_PATH_SUCCESS]: (state, action) => ({
        ...state,

        updated_lp: {
            ...state.updated_lp,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.UPDATE_LEARNING_PATH_ERROR]: (state, action) => ({
        ...state,

        updated_lp: {
            ...state.updated_lp,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    // clone lp
    [ActionTypes.CLONE_LEARNING_PATH]: (state) => ({
        ...state,
        cloned_lp: { loading: true, response: {}, hasError: false, error: {} }
    }),
    [ActionTypes.CLONE_LEARNING_PATH_SUCCESS]: (state, action) => ({
        ...state,
        cloned_lp: {
            ...state.cloned_lp,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.CLONE_LEARNING_PATH_ERROR]: (state, action) => ({
        ...state,
        cloned_lp: {
            ...state.cloned_lp,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Get ADMIN LPS
    [ActionTypes.GET_LEARNING_PATH_ADMIN_LIST]: (state) => ({
        ...state,
        lp_listing_admin: {
            ...state.lp_listing_admin,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_ADMIN_LIST_SUCCESS]: (state, action) => ({
        ...state,

        lp_listing_admin: {
            ...state.lp_listing_admin,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_ADMIN_LIST_ERROR]: (state, action) => ({
        ...state,

        lp_listing_admin: {
            ...state.lp_listing_admin,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //    Admin Lp Pagination Next
    [ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_NEXT]: (state) => ({
        ...state,
        lp_listing_admin: {
            ...state.lp_listing_admin,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        lp_listing_admin: {
            ...state.lp_listing_admin,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        lp_listing_admin: {
            ...state.lp_listing_admin,
            loading: false, hasError: false, error: {}
        }
    }),

    [ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        lp_listing_admin: {
            ...state.lp_listing_admin,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        lp_listing_admin: {
            ...state.lp_listing_admin,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        lp_listing_admin: {
            ...state.lp_listing_admin,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Get Admin lp
    [ActionTypes.GET_LEARNING_PATH_ADMIN]: (state) => ({
        ...state,
        admin_lp: {
            ...state.admin_lp,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_ADMIN_SUCCESS]: (state, action) => ({
        ...state,

        admin_lp: {
            ...state.admin_lp,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_ADMIN_ERROR]: (state, action) => ({
        ...state,

        admin_lp: {
            ...state.admin_lp,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
//    GET COURSES WITHOUT PAGINATION

    [ActionTypes.GET_COURSES_LIST_WITHOUT_PAGINATION]: (state) => ({
        ...state,
        courses_without_pagination: {
            ...state.courses_without_pagination,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSES_LIST_WITHOUT_PAGINATION_SUCCESS]: (state, action) => ({
        ...state,

        courses_without_pagination: {
            ...state.courses_without_pagination,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSES_LIST_WITHOUT_PAGINATION_ERROR]: (state, action) => ({
        ...state,

        courses_without_pagination: {
            ...state.courses_without_pagination,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //    GET QUIZZES WITHOUT PAGINATION

    [ActionTypes.GET_QUIZZES_LIST_WITHOUT_PAGINATION]: (state) => ({
        ...state,
        quizzes_without_pagination: {
            ...state.quizzes_without_pagination,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_QUIZZES_LIST_WITHOUT_PAGINATION_SUCCESS]: (state, action) => ({
        ...state,

        quizzes_without_pagination: {
            ...state.quizzes_without_pagination,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_QUIZZES_LIST_WITHOUT_PAGINATION_ERROR]: (state, action) => ({
        ...state,

        quizzes_without_pagination: {
            ...state.quizzes_without_pagination,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //    GET GROUPS WITHOUT PAGINATION

    [ActionTypes.GET_GROUPS_LIST_WITHOUT_PAGINATION]: (state) => ({
        ...state,
        groups_without_pagination: {
            ...state.groups_without_pagination,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_GROUPS_LIST_WITHOUT_PAGINATION_SUCCESS]: (state, action) => ({
        ...state,

        groups_without_pagination: {
            ...state.groups_without_pagination,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_GROUPS_LIST_WITHOUT_PAGINATION_ERROR]: (state, action) => ({
        ...state,

        groups_without_pagination: {
            ...state.groups_without_pagination,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    // GET CLASSES WITHOUT PAGINATION
    [ActionTypes.GET_CLASSES_LIST_WITHOUT_PAGINATION]: (state) => ({
        ...state,
        classes_without_pagination: {
            ...state.classes_without_pagination,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_CLASSES_LIST_WITHOUT_PAGINATION_SUCCESS]: (state, action) => ({
        ...state,

        classes_without_pagination: {
            ...state.classes_without_pagination,
            response: action?.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_CLASSES_LIST_WITHOUT_PAGINATION_ERROR]: (state, action) => ({
        ...state,

        classes_without_pagination: {
            ...state.classes_without_pagination,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
    //    GET MEMBERS WITHOUT PAGINATION

    [ActionTypes.GET_MEMBERS_LIST_WITHOUT_PAGINATION]: (state) => ({
        ...state,
        members_without_pagination: {
            ...state.members_without_pagination,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_MEMBERS_LIST_WITHOUT_PAGINATION_SUCCESS]: (state, action) => ({
        ...state,

        members_without_pagination: {
            ...state.members_without_pagination,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_MEMBERS_LIST_WITHOUT_PAGINATION_ERROR]: (state, action) => ({
        ...state,

        members_without_pagination: {
            ...state.members_without_pagination,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
    //ASSIGN_LP
    [ActionTypes.ASSIGN_LEARNING_Path]: (state) => ({
        ...state, assign_lp: {
            ...state.assign_lp,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.ASSIGN_LEARNING_Path_SUCCESS]: (state, action) => ({
        ...state,
        assign_lp: {
            ...state.assign_lp,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.ASSIGN_LEARNING_Path_ERROR]: (state, action) => ({
        ...state,
        assign_lp: {
            ...state.assign_lp,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //LP ASSIGNMENTS
    [ActionTypes.ASSIGNMENT_LIST_LP]: (state) => ({
        ...state,
        get_lp_assignments: {
            ...state.get_lp_assignments,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.ASSIGNMENT_LP_LIST_SUCCESS]: (state, action) => ({
        ...state,

        get_lp_assignments: {
            ...state.get_lp_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.ASSIGNMENT_LP_LIST_ERROR]: (state, action) => ({
        ...state,
        get_lp_assignments: {
            ...state.get_lp_assignments,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //LP ASSIGNMENTS BY ASSIGNMENT ID
    [ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_LP]: (state) => ({
        ...state,
        get_lp_assigment_by_id: {
            ...state.get_lp_assigment_by_id,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_LP_SUCCESS]: (state, action) => ({
        ...state,

        get_lp_assigment_by_id: {
            ...state.get_lp_assigment_by_id,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_LP_ERROR]: (state, action) => ({
        ...state,
        get_lp_assigment_by_id: {
            ...state.get_lp_assigment_by_id,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //UPDATE ASSIGNMENTS
    [ActionTypes.UPDATE_LP_ASSIGNMENT]: (state) => ({
        ...state,
        updated_lp_assigment: {
            ...state.updated_lp_assigment,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.UPDATE_LP_ASSIGNMENT_SUCCESS]: (state, action) => ({
        ...state,

        updated_lp_assigment: {
            ...state.updated_lp_assigment,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.UPDATE_LP_ASSIGNMENT_ERROR]: (state, action) => ({
        ...state,
        updated_lp_assigment: {
            ...state.updated_lp_assigment,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),


    //Dekete ASSIGNMENTS
    [ActionTypes.DELETE_LP_ASSIGNMENT]: (state) => ({
        ...state,
        delete_lp_assigment: {
            ...state.delete_lp_assigment,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.DELETE_LP_ASSIGNMENT_SUCCESS]: (state, action) => ({
        ...state,

        delete_lp_assigment: {
            ...state.delete_lp_assigment,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DELETE_LP_ASSIGNMENT_ERROR]: (state, action) => ({
        ...state,
        delete_lp_assigment: {
            ...state.delete_lp_assigment,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //UpdATE LP MODULE

    [ActionTypes.UPDATE_LP_NODE]: (state) => ({
        ...state,
        updated_lp_module: {
            ...state.updated_lp_module,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.UPDATE_LP_NODE_SUCCESS]: (state, action) => ({
        ...state,

        updated_lp_module: {
            ...state.updated_lp_module,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.UPDATE_LP_NODE_ERROR]: (state, action) => ({
        ...state,

        updated_lp_module: {
            ...state.updated_lp_module,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //UpdATE LP MODULE

    [ActionTypes.UPDATE_LP_NODE_AUTO_SAVE]: (state) => ({
        ...state,
        updated_lp_module_auto_save: {
            ...state.updated_lp_module_auto_save,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.UPDATE_LP_NODE_AUTO_SAVE_SUCCESS]: (state, action) => ({
        ...state,

        updated_lp_module_auto_save: {
            ...state.updated_lp_module_auto_save,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.UPDATE_LP_NODE_AUTO_SAVE_ERROR]: (state, action) => ({
        ...state,

        updated_lp_module_auto_save: {
            ...state.updated_lp_module_auto_save,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //   Delete ELEMENT
    [ActionTypes.DELETE_ELEMENT_LP_NODE]: (state) => ({
        ...state,
        delete_lp_module: {
            ...state.delete_lp_module,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DELETE_ELEMENT_LP_NODE_SUCCESS]: (state, action) => ({
        ...state,


        delete_lp_module: {
            ...state.delete_lp_module,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DELETE_ELEMENT_LP_NODE_ERROR]: (state, action) => ({
        ...state,

        delete_lp_module: {
            ...state.delete_lp_module,
            loading: false, hasError: false, error: {}
        }
    }),

    //    ELEMENTs
    [ActionTypes.LIST_LP_NODE]: (state) => ({
        ...state,
        list_lp_module: {
            ...state.list_lp_module,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_LP_NODE_SUCCESS]: (state, action) => ({
        ...state,


        list_lp_module: {
            ...state.list_lp_module,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LIST_LP_NODE_ERROR]: (state, action) => ({
        ...state,

        list_lp_module: {
            ...state.list_lp_module,
            loading: false, hasError: false, error: {}
        }
    }),

    //    ELEMENT BY ID
    [ActionTypes.ELEMENT_LP_NODE]: (state) => ({
        ...state,
        element_lp_module: {
            ...state.element_lp_module,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.ELEMENT_LP_NODE_SUCCESS]: (state, action) => ({
        ...state,


        element_lp_module: {
            ...state.element_lp_module,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.ELEMENT_LP_NODE_ERROR]: (state, action) => ({
        ...state,

        element_lp_module: {
            ...state.element_lp_module,
            loading: false, hasError: false, error: {}
        }
    }),
    //Get Manger Learning path detail
    [ActionTypes.GET_LEARNING_PATH_MANAGER_DETAIL]: (state) => ({
        ...state,
        get_learning_path_manager_details: {
            ...state.get_learning_path_manager_details,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_MANAGER_DETAIL_SUCCESS]: (state, action) => ({
        ...state,

        get_learning_path_manager_details: {
            ...state.get_learning_path_manager_details,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNING_PATH_MANAGER_DETAIL_ERROR]: (state, action) => ({
        ...state,

        get_learning_path_manager_details: {
            ...state.get_learning_path_manager_details,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Get Manger Learning path Nodes
    [ActionTypes.LP_NODE_LIST_MANAGER]: (state) => ({
        ...state,
        list_nodes: {
            ...state.list_nodes,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_MANAGER_SUCCESS]: (state, action) => ({
        ...state,

        list_nodes: {
            ...state.list_nodes,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_MANAGER_SUCCESS_ERROR]: (state, action) => ({
        ...state,

        list_nodes: {
            ...state.list_nodes,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    //Get Manger Learning path Nodes detail
    [ActionTypes.LP_NODE_LIST_DETAIL_MANAGER]: (state) => ({
        ...state,
        nodes_detail: {
            ...state.nodes_detail,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_DETAIL_MANAGER_SUCCESS]: (state, action) => ({
        ...state,

        nodes_detail: {
            ...state.nodes_detail,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_DETAIL_MANAGER_SUCCESS_ERROR]: (state, action) => ({
        ...state,

        nodes_detail: {
            ...state.nodes_detail,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    //Get Manger Learning path Nodes learner
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PAGINATION_NEXT]: (state) => ({
        ...state,
        nodes_learner: {
            ...state.nodes_learner,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_NEXT_SUCCESS]: (state, action) => ({
        ...state,

        nodes_learner: {
            ...state.nodes_learner,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_NEXT_ERROR]: (state, action) => ({
        ...state,

        nodes_learner: {
            ...state.nodes_learner,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Get Manger Learning path Nodes learner
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH]: (state) => ({
        ...state,
        nodes_learner: {
            ...state.nodes_learner,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_SUCCESS]: (state, action) => ({
        ...state,

        nodes_learner: {
            ...state.nodes_learner,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_ERROR]: (state, action) => ({
        ...state,

        nodes_learner: {
            ...state.nodes_learner,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Get Manger Learning path Nodes learner
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        nodes_learner: {
            ...state.nodes_learner,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        nodes_learner: {
            ...state.nodes_learner,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        nodes_learner: {
            ...state.nodes_learner,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

     ////

    //Get Manger Learning path Nodes learner
    [ActionTypes.LP_LEARNER_ALL_MANAGER]: (state) => ({
        ...state,
        all_learner: {
            ...state.all_learner,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_MANAGER_SUCCESS]: (state, action) => ({
        ...state,
        all_learner: {
            ...state.all_learner,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_MANAGER_SUCCESS_ERROR]: (state, action) => ({
        ...state,
        all_learner: {
            ...state.all_learner,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Get Manger Learning path Nodes learner
    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE]: (state) => ({
        ...state,
        all_learner: {
            ...state.all_learner,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_SUCCESS]: (state, action) => ({
        ...state,
        all_learner: {
            ...state.all_learner,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_SUCCESS_ERROR]: (state, action) => ({
        ...state,
        all_learner: {
            ...state.all_learner,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    //Get Manger Learning path Nodes learner
    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_PAGINATION_NEXT]: (state) => ({
        ...state,
        all_learner: {
            ...state.all_learner,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_NEXT_SUCCESS]: (state, action) => ({
        ...state,

        all_learner: {
            ...state.all_learner,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_NEXT_ERROR]: (state, action) => ({
        ...state,

        all_learner: {
            ...state.all_learner,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        all_learner: {
            ...state.all_learner,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        all_learner: {
            ...state.all_learner,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_MANAGER_WITH_PAGE_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        all_learner: {
            ...state.all_learner,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    [ActionTypes.LP_LEARNER_LP_EXTEND]: (state) => ({
        ...state,
        extend_lp: {
            ...state.extend_lp,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_LP_EXTEND_SUCCESS]: (state, action) => ({
        ...state,

        extend_lp: {
            ...state.extend_lp,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_LP_EXTEND_ERROR]: (state, action) => ({
        ...state,

        extend_lp: {
            ...state.extend_lp,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    [ActionTypes.LP_LEARNER_ASSIGNMENT_MANAGER]: (state) => ({
        ...state,
        assigment_detail: {
            ...state.assigment_detail,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ASSIGNMENT_MANAGER_SUCCESS]: (state, action) => ({
        ...state,
        assigment_detail: {
            ...state.assigment_detail,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ASSIGNMENT_MANAGER_SUCCESS_ERROR]: (state, action) => ({
        ...state,
        assigment_detail: {
            ...state.assigment_detail,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Get ADMIN Courses
    [ActionTypes.GET_LPS_ADMIN_LIST]: (state) => ({
        ...state,
        admin_lps: {
            ...state.admin_lps,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LPS_ADMIN_LIST_SUCCESS]: (state, action) => ({
        ...state,

        admin_lps: {
            ...state.admin_lps,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LPS_ADMIN_LIST_ERROR]: (state, action) => ({
        ...state,

        admin_lps: {
            ...state.admin_lps,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    //    Admin Course Pagination Next
    [ActionTypes.ADMIN_LP_PAGINATION_NEXT]: (state) => ({
        ...state,
        admin_lps: {
            ...state.admin_lps,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.ADMIN_LP_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        admin_lps: {
            ...state.admin_lps,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.ADMIN_LP_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        admin_lps: {
            ...state.admin_lps,
            loading: false, hasError: false, error: {}
        }
    }),
    //   Admin Available Course Pagination Previous
    [ActionTypes.ADMIN_LP_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        admin_lps: {
            ...state.admin_lps,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.ADMIN_LP_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        admin_lps: {
            ...state.admin_lps,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.ADMIN_LP_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        admin_lps: {
            ...state.admin_lps,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Get Admin LP
    [ActionTypes.GET_LP_ADMIN]: (state) => ({
        ...state,
        admin_detail_lp: {
            ...state.admin_detail_lp,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LP_ADMIN_SUCCESS]: (state, action) => ({
        ...state,

        admin_detail_lp: {
            ...state.admin_detail_lp,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LP_ADMIN_ERROR]: (state, action) => ({
        ...state,

        admin_detail_lp: {
            ...state.admin_detail_lp,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

}, initialState);

export default reducer;
