import React, {useEffect, useRef, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CourseAssignBox from './components/courseassignbox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GroupAssignBox from './components/groupselectionbox';
import Modal from 'react-bootstrap/Modal';
import {assignCourse,getCourseAssignments,getCourseAssignmentsById,updateAssignCourse,getMemberList } from "../../../../services";
import { useDispatch, useSelector } from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import ScrollArea from "react-scrollbar";
import Spinner from "react-bootstrap/Spinner";
import AssignmentPopup from "../enrollusers/components/assignmentname";
import {ToastContainer, toast} from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function MyVerticallyCenteredModal(props) {
    const dispatch =useDispatch()
    const response = useSelector(state => state.course?.get_course_assignments?.response);
    const loading = useSelector(state => state.course?.get_course_assignments?.loading);
    const [state, setState]=useState({
        courseAssignments:response?.results || [],
        modalId:''
    })
    useEffect(()=>{
        if(props.url){
            dispatch(getCourseAssignments(props.url))
        }

    },[])
    useEffect(() => {
        if(props.url){
        setState({...state,courseAssignments:response?.results})
        }
    }, [response]);
  const handleSelection = (id,list) =>{
      setState({...state, modalId: id})
      props.handle(id,list)
  }

    const handleCreate = () => {
        props.onHide()
        props.handleAssignment()
    }
    return (
        <Modal className="custom-modal confirmation-popup inner-popup"
               {...props}
               size="md"
               aria-labelledby="contained-modal-title-vcenter"
               centered

        >
            <Modal.Body>
                <div className="add-quiz-box-content">
                    <div onClick={()=>props.onHide(8)}> <div className="form-close-btn p-relative"><span className="memb-close-icon-card">×</span></div></div>
                    {props.url ?
                     <>
                    <h4 className="course-assign-type-name">List Of Course User Enrollments</h4>
                        <h4 className="course-assign-type-name">Choose User Enrollment whom you want to Edit</h4>
                    </>
                       :
                    ''

                    }

                    <ScrollArea
                        speed={0.8}
                        className="listscroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                        <ul>
                            {props.url ?
                                <div>
                                    {state?.courseAssignments && state?.courseAssignments && state?.courseAssignments?.length ? state?.courseAssignments?.map((el, index) => {

                                            return (
                                                <li onClick={() => handleSelection(el.id,el)} key={index}
                                                    className={`${el.isChecked && el.isChecked ? 'list-selected' : ''}`}>
                                                    {props.loading && props.loading && state.modalId == el?.id ?
                                                        <Spinner animation="border" variant="warning"/>
                                                        :
                                                        <span >{` ${el?.name} ${el?.due_date ? el?.due_date : ''}`} </span>
                                                    }

                                                </li>
                                            );
                                        })
                                        :

                                        <div className='row justify-content-center mt-2'>
                                            {loading ?
                                                <Spinner animation="border" variant="danger"/>
                                                :
                                                <>

                                                    <div className="col-12 text-center">
                                                        <p>No Existing User Enrollment</p>

                                                    </div>

                                                </>
                                            }
                                        </div>


                                    }

                                </div>

                                :

                                ''
                            }

                            <div className=" text-center mt-3">
                                <button className="btn btn-m border-btn" onClick={handleCreate}>Create New User Enrollment</button>


                            </div>
                        </ul>
                    </ScrollArea>
                </div>

            </Modal.Body>
        </Modal>
    );
}

const CourseAssign = (props) => {
    const history = useHistory()
    const dispatch = useDispatch();
    let loading = useSelector(state => state?.course?.get_course_assigment_by_id?.loading)
    const assignloading = useSelector(state => state.course?.get_course_assignments?.loading);;
    let updateAssignmentLoading = useSelector(state => state?.course?.updated_course_assigment?.loading);
    let addAssignmentLoading = useSelector(state => state?.course?.assign_course?.loading);
    const response = useSelector(state => state.course?.get_course_assignments?.response);
    const [state, setState] = useState({
        isDisplay :false,
        isGroupDisplay:false,
        member:[],
        groupDetails:[],
        modalShow: false,
        assignmentId:'',
        singleAssignment:{},
        failedVaues:{}
    })
    const [tutorialModel, setTutorialmodel] = useState(false);

    const groupSelection = useRef();
    const memberSelection = useRef();
    const memberList = (data) => {
        setState({...state,member:data});
    };
    useEffect(()=>{
        if(props.urlId){
            dispatch(getCourseAssignments(props.urlId))
        }

    },[])
    const [assignmentPopup, setassignmentPopup] = useState(false)
    const groupList = (data) => {
        setState({...state,groupDetails:data});
    };
    const onSave = (basicInformation) => {
        let data = {"groups": state.groupDetails, "learners": state.member, 'name':basicInformation?.name ? basicInformation?.name :state?.singleAssignment?.name,"due_date":basicInformation?.due_date ? basicInformation?.due_date :state?.singleAssignment?.due_date
        };
        if(state?.groupDetails?.length || state?.member?.length ) {
            if(basicInformation?.name?.length || state?.singleAssignment?.name?.length) {
                if (state.assignmentId && props?.urlId) {
                    dispatch(updateAssignCourse(data, props.urlId, state.assignmentId, history,function(err){
                        if(err){
    
                            setState({...state, failedVaues: data})
                            setassignmentPopup(true)
                        }
    
                    }));
                } else {
                    dispatch(assignCourse(data, props?.courseId, history,function (error){
                        if(error){
                            setState({...state, failedVaues: data})
                            setassignmentPopup(true)
                        }
                    }));
                }
            }
        } else {
            toast.warn('Please select any Learner or Group')
        }
        
    };
    const getBasicInformation  =  (data) =>{
        onSave(data);
    };


    function TutorialModal(props) {
 
        return (
            <Modal className="custom-modal info-modal dashboard-modal" show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="module-modal-header my-4">
                        <h4>Assign tab helps you to enroll the created course to either individually to learners or to a group.
</h4>
                    </div>
                   
                    <ol className="info-list">

                      <li><span> After  selecting the required learners/group, please click Enroll and create an enrollment with the due  date.
</span></li>
                      <li><span> Enrollment name helps in maintaining all enrollments in an orderly manner.
</span></li>
                      <li><span>  Click Continue to Publish course without any enrollments for now and later you can enroll by clicking on Enroll New Users in the course detail page.
</span></li>
<li><span> Once you click Enroll, Learners shall be enrolled successfully in the course! They can find it in their Assignment tab.

</span></li>
                     
                    </ol>
                   
                    </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={props.onHide}> Close </Button>
                </div>
                </Modal>
            );
    }
    
    const handleBack = ()=>{
        toast.success('Your course has been successfully published!');
        history.push('/coursemanagement')
    }
    const handleAssignmentsById = (id,list) =>{
         dispatch(getCourseAssignmentsById(id,props.urlId,function (response){
             if(response){
                 memberSelection.current.checked=true;
                 groupSelection.current.checked=true;
                 setState({...state,isGroupDisplay: true,isDisplay: true, modalShow: false,assignmentId: id,singleAssignment:list})
                 setassignmentPopup(true)
             }
         },function (error){
             setState({...state, modalShow: false})
         }))

    }

    const handleLoader = () =>{
        if(updateAssignmentLoading || addAssignmentLoading || assignloading){
            return (
                <div className='loader-full-screen-course'>
                    <div className='loader'>
                        <Spinner animation="border" variant="danger"/>
                    </div>
                </div>
            )
        }
    }

    const handleAssignmentPopUp  = () =>{
        setassignmentPopup(true)
    }
    const handleShow = () =>{
        setState({...state,modalShow: true})
    }

  return (

      <>
    <div className="course-assign-wrap">
    <div className="help_icon_position">
                <OverlayTrigger
                    overlay={
                        <Tooltip id="InfoText">
                            Help
                        </Tooltip>
                    }
                >
                    <span className="info-icon-wrap-tab animated-icon" onClick={() => setTutorialmodel(true)}>
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </span>
                </OverlayTrigger>
            </div>
        {handleLoader()}
      <div className="course-assign-member-type">
        <Form className="d-block">
          <Row>
            <Col md="3">
             <div className='d-flex'>
             <Form.Check
                custom
                type="checkbox"
                id="check1"

                ref={memberSelection}
                label={`Add Learners`}
                  onChange={(e)=>setState({...state,isDisplay:e.target.checked})}
              />
              
             </div>

            </Col>
            <Col md="3"> 
            <div className='d-flex'>
              <Form.Check
              custom
              type="checkbox"
              id="check2"
              label={`Add group`}
              ref={groupSelection}
              onChange={(e)=>setState({...state,isGroupDisplay:e.target.checked})}
            />
            
             {/* <OverlayTrigger overlay={<Tooltip id="InfoText">Enroll the course to existing groups</Tooltip>}>
                <span className="info-icon-wrap ml-2">
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </OverlayTrigger> */}
            </div>
            </Col>
              {props?.urlId && response?.results && response?.results?.length ?
              <Col md="6">
                  <Button variant="primary" className="btn-m" onClick={handleShow}>Edit Existing Enrollments</Button>
              </Col>

                  :
                  ''
              }
          </Row>

        </Form>
      </div>
        <div className="course-assign-box-container">
            {state.isDisplay &&
            <>
                <h4 className="course-assign-type-name">People to whom you want to assign this course</h4>
                <CourseAssignBox singleAssignment={state.singleAssignment} urlId={props.urlId} assignId={state?.assignmentId}  memberList={memberList} />
            </>
            }
        </div>
      <div className="course-assign-box-container">

          <div className="course-assign-box-container">
              {state.isGroupDisplay &&
              <>
                  <h4 className="course-assign-type-name">Groups to which you want to assign this course</h4>
                  <GroupAssignBox singleAssignment={state.singleAssignment} urlId={props.urlId} assignId={state?.assignmentId} groupList={groupList} />
              </>
              }
          </div>
      </div>

    </div>
    <div className="add-module-footer d-flex justify-content-end">
        <div className="button-wrap double-btn">
            <button className="btn btn-m border-btn" onClick={handleBack}>Continue</button>
            {state.assignmentId && props.urlId ?
                <Button variant="primary" className="btn-m" disabled={updateAssignmentLoading} onClick={() => {setassignmentPopup(true)}}>Enroll Users</Button>
                :
                <Button variant="primary" className="btn-m" disabled={addAssignmentLoading} onClick={() => {setassignmentPopup(true)}}>Enroll Users</Button>
            }

            {/*<Button variant="dark" className="btn-m">Preview</Button>*/}
            {/*<Button variant="primary" className="btn-m">Publish</Button>*/}
            {/*<Button variant="primary" className="btn-m">Continue</Button>*/}
        </div>
    </div>
          {
              props.keys == 2 ?
                  <MyVerticallyCenteredModal
                      url={props.urlId}
                      show={state.modalShow}
                      loading={loading}
                      handleAssignment = {handleAssignmentPopUp}
                      handle ={handleAssignmentsById}
                      onHide={() => setState({...state,modalShow:false,assignmentId: ''})}
                  />
                  :
                  ''

          }
          <AssignmentPopup
              show={assignmentPopup}
              failed = {state.failedVaues}
              basicInformation = {getBasicInformation}
              singleAssignment = {state?.singleAssignment}
              focus ={state?.focus}
              onHide={() =>setassignmentPopup(false)}

          />
           <TutorialModal  show={tutorialModel}
                          
                          onHide={() => setTutorialmodel(false)}
                      />

      </>
  );
};
export default CourseAssign;
