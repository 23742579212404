import React from 'react';
import { GoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import { signInWithGoogle } from '~/modules/auth/services';
import GoogleIcon from '~/assets/images/icons/google.png';
import { googleClientId } from "~/shared/constants";
import {useHistory} from 'react-router-dom';
function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const onSuccess = (res) => {

    dispatch(signInWithGoogle(res,history));
  };


  return <div className="social-login-btn">
    <GoogleLogin
      clientId={googleClientId}
      render={renderProps => (
          <button onClick={renderProps.onClick} className="social-login-btn google-login" ><span><img src={GoogleIcon} alt="" /></span>Sign In using Google</button>
      )}
      buttonText="SignUp with Google"
      onSuccess={onSuccess}
      // onFailure={err => console.log('fail', err)}
      cookiePolicy={'single_host_origin'}
    />


  </div>;
}

export default Login;
