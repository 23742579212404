import { combineReducers } from "redux";
import home from "~/modules/home/services";
import auth from "~/modules/auth/services";
import normallogin from "~/modules/auth/normallogin/services";
import reports from "~/modules/reports/services";
import profile from "~/modules/profile/services";
import surveys from "~/modules/surveys/services";
// import courses from '~/modules/coursemanagement/services';
// import learnercourses from '~/modules/courses/services';
import storage from "redux-persist/lib/storage";
// import surveyreport from '../../modules/surveys/surveyReport/surveyreports/services'
import teams from "../../modules/team/services";
import surveyreport from "../../modules/surveys/surveyReport/surveyreports/services";
import settings from "../../modules/settings/services";
import course from "../../modules/coursemanagement/services";
import support from "../../modules/supportus/services";
import learnercourse from "../../modules/courses/services";
import notifications from "../../modules/Notifications/services";
import managerDashboard from "../../modules/dashboard/services";
import quiz from "../../modules/quizmanagement/services";
import learningpath from "../../modules/learningpath/services";
import learningpath_learner from "../../modules/learnerLearningPath/services";
import survey_builder from "../../modules/surveys/surveyManagement/surveybuilder/services";
/**
 * all available reducers are wrapped by the combine reducers function
 */
import $ from "jquery";
const rootReducer = combineReducers({
  home,
  auth,
  normallogin,
  profile,
  surveyreport,
  surveys,
  // courses,
  // learnercourses,
  teams,
  // settings
  settings,
  course,
  learnercourse,
  support,
  notifications,
  managerDashboard,
  quiz,
  learningpath,
  learningpath_learner,
  survey_builder,
});

const appReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    if (window && window?.Beacon) {
      $(
        'script[src*="https://beacon-v2.helpscout.net/static/js/vendor.90fe6783.js"]'
      ).remove();
      $(
        'script[src*="https://beacon-v2.helpscout.net/static/js/main.e62f983e.js"]'
      ).remove();
      $('script[src*="https://beacon-v2.helpscout.net"]').remove();
      $("#beacon-container").remove();
    }
    localStorage.removeItem("token");
    sessionStorage.removeItem("login_time");
    storage.removeItem("persist:root");
    storage.removeItem("persist:DATA_PERSISTANT_KEY");
    state = undefined;
  }

  return rootReducer(state, action);
};

export default appReducer;
