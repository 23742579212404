import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import Button from "react-bootstrap/Button";
import enga from '~/assets/images/logo-small.svg';
import jsPdf from "jspdf";
import {Link, Switch, useHistory, useLocation} from 'react-router-dom';
import { useDispatch, useSelector,u } from 'react-redux';
import     {getLearnerLearningPathCertificateDetail} from '../../../../learnerLearningPath/services'
import Spinner from "react-bootstrap/Spinner";
import PageNoFound from "../../../../errorpages/pagenofound";
import {Helmet} from "react-helmet";

const printPDF = (certifcate) => {
  const domElement = document.getElementById("abc");
  html2canvas(domElement, {
    onclone: document => {
      document.getElementById("print").style.visibility = "hidden";
    }
  }).then(canvas => {

    let pdf = new jsPdf('p', 'mm', 'a4');
    var imgWidth = 208;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    const imgData = canvas.toDataURL('image/png');
    var position = 0;
    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
    let coarsename = certifcate?.learning_path_name ? certifcate?.learning_path_name : certifcate?.course_name
    coarsename.substring(0, coarsename.indexOf(' '));
    coarsename.substring(coarsename.indexOf(' ') + 1); 
    pdf.save(`Certificate - ${coarsename}`);
  });
};

function Certificate({ match }) {
  const history = useHistory()
  const dispatch = useDispatch();
  const location =  useLocation()
  const id = match.params.id
  const response = useSelector(state => state?.learningpath_learner ?.learner_certificate?.response);
  const loading = useSelector(state => state?.learningpath_learner?.learner_certificate?.loading);
  const [certifcate,setCertificate] = useState({})
  const [error,setError] = useState(false)
  const [logo,setLogo] = useState('')
  useEffect(()=>{
    dispatch(getLearnerLearningPathCertificateDetail(id,
    function (res){
      res.institution_logo_extension = res?.institution_logo_extension?.split('.')[1]
      setCertificate(res)
    },
    location?.pathname?.includes('course') ? 'course' : 'learning_path',
    function (reserror){
      if(reserror){
        setError(true)
      }
    }))
  },[])

  if(!error) {
    if (!loading) {
      return (
          <div className="aaa">
              <Helmet>
                <title>Certificate - Cultural Engagement  </title>
            </Helmet>
            <div id="abc" className="certificate-wrap">
              <div className="certificate-content">
                <div className="certificate-body">
                  {certifcate?.institution_logo && (
                    <div className="cert-company-logo">
                    <img
                        src={`data:image/${certifcate?.institution_logo_extension};base64,${certifcate?.institution_logo}`}/>
                  </div>
                  )}
                  
                  <div className="cert-title">
                    <h2>{location?.pathname?.includes('course') ?  ' Course CERTIFICATE' : 'Learning Path CERTIFICATE' }</h2>
                  </div>
                  <div className="cert-date">
                    <span>{certifcate?.completed_date}</span>
                  </div>
                  <div className="certifythat">
                    This is to certify that
                  </div>
                  <h2 className="cert-person-name">
                    {certifcate?.user_name}
                  </h2>
                  <div className="cert-completed-text d-flex" style={{fontSize: '20px'}}>
                    {/* has successfully completed the {location?.pathname?.includes('course') ? 'Course' : 'Learning Path '}- {certifcate?.name} */}
                 has successfully completed the Course
                  </div>
                  <div className="cert-course-name">
                  "{certifcate?.learning_path_name ? certifcate?.learning_path_name : certifcate?.course_name  }" 
                  </div>
                </div>

                <div className="certificate-footer">
            <span className="cert-company-logo">
               <div className="web-details-row  align-items-center m-b-30">
                <img src={enga} alt="logo"/>

                 {/* <h4>Aptree.app</h4> */}
                        </div>

            </span>
                </div>
              </div>

            </div>
            <div className="m-t-20 text-center">
              <Button id="print" onClick={() => printPDF(certifcate)}>Download Certificate</Button>
            </div>
          </div>
      )
    } else {
      return (
          <div className="loader-middle-wrap">
            <Spinner animation="border" variant="danger"/>
          </div>

      )

    }
  }else {
    return (

        <>    <PageNoFound /></>
    )
  }
}

export default Certificate;
