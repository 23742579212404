import React, {useEffect,useRef, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ScrollArea from "react-scrollbar";
import Spinner from "react-bootstrap/Spinner";
import {Formik} from "formik";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";


const  AssignmentPopup  =  (props) => {
    const [startDate, setStartDate] = useState('');
    const nameInputRef = useRef()
    const handleSubmit =(values) => {
        props.basicInformation(values)
        props.onHide()
    };

    useEffect(()=>{
        if(props.focus){
            nameInputRef.current.focus()
        }
    },[props.focus])
const handleDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    return today
}
    function setNativeValue(element, value) {
        const { set: valueSetter } = Object.getOwnPropertyDescriptor(element, 'value') || {}
        const prototype = Object.getPrototypeOf(element)
        const { set: prototypeValueSetter } = Object.getOwnPropertyDescriptor(prototype, 'value') || {}

        if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(element, value)
        } else if (valueSetter) {
            valueSetter.call(element, value)
        } else {
            throw new Error('The given element does not have a value setter')
        }
    }
const {singleAssignment,failed} = props;


    return (

        <>
            <Modal className="common-modal team-member-pop inner-popup"
                   {...props}
                   size="md"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered

            >
                <Modal.Body>
              <div className="form-close-btn d-flex p-relative justify-content-end" onClick={()=>props.onHide()}><span className="transparent-close-icon">×</span></div>
                 <div className="content-modla-wrap">
                    <Formik
                        validationSchema={SignInSchema}
                        onSubmit={handleSubmit}
                        initialValues={failed && failed.name ? failed :singleAssignment && singleAssignment ? singleAssignment :{
                            name: '',
                            due_date: '',
                        } }
                        enableReinitialize={true}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              errors,
                              setFieldValue
                          }) => {

                            const handleChangeDate = (date) => {
                                let datedata = moment(date).format("YYYY-MM-DD");
                                setFieldValue("due_date",datedata);
                            }
    
                            return(

                         
                            

                            <Form noValidate onSubmit={handleSubmit}>
                                <div className="form-input-wrap">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        ref ={nameInputRef}
                                        type="text"
                                        name="name"
                                        placeholder="Enrollment Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </div>
                                <div className="form-input-wrap">
                                    <Form.Label>Due Date</Form.Label>
                                    {/* <Form.Control type="password" placeholder="Your Password" className="input-item" /> */}
                                    {/* <Form.Control
                                        type="date"
                                        id='one'
                                        name="due_date"
                                        min ={handleDate()}
                                        placeholder="Due Date"
                                        value={values.due_date}
                                        onChange={handleChange}
                                    /> */}
                                      <div className="survey-datepicker-box p-relative">
                                     <DatePicker
                                        name="expiry_date"
                                            id='one'
                                            placeholderText={'Select Date'}
                                            minDate={new Date()}
                                            // startDate={startDate}
                                            // endDate={endDate}
                                            onChange={handleChangeDate}
                                            // onChange={(date) => setStartDate(date)}
                                            value={values.due_date}
                                            selected={startDate}
                                            isClearable={false}
                                            autoComplete='off'
                                        />
                                        
                                        </div>
                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                            {errors.due_date}
                                        </Form.Control.Feedback>

                                </div>



                                <div className="button-wrap double-btn mt-4">
                                    <button type="submit" className="btn border-btn">Save</button>
                                </div>

                            </Form>
                               );
                            }
  
                        }
                    </Formik>
                    </div>

                </Modal.Body>
            </Modal>



        </>



    )

}
const SignInSchema = yup.object().shape({
    name: yup.string().required('Name is Required'),
    due_date: yup.string().required('Due Date is Required'),
});
export default AssignmentPopup;
