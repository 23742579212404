import React, {useEffect, useState} from "react";
import {Field, ErrorMessage, Form} from "formik";
import {FormCheck} from "react-bootstrap";


const RadioButton = (props) => {
    const { name,errors,touched,questionId, options,setValues,setFieldValue,values,quesIndex,errorName, ...rest} = props;
    const [state, setState]=useState({

    })
    const handleSelection = (e,index,option,fieldValue) => {
        const newValues =  {...values}
        newValues[name] = e.target.value
         newValues['questions'][quesIndex]  = {id:questionId,answer:e.target.value}
        setValues({...values, ...newValues})

    }


    return (
        <>
            <div className="quiz-builder-pop-list d-flex">
                <span className="ind-numb">{Number(quesIndex)+1}.</span>
                <div dangerouslySetInnerHTML={{ __html: ` ${name ? name :''}` }} className="quiz-title-para"></div>
                </div>
                <div className="radiooption d-flex">
                <div className="integer-radio-block w-100">
<Field name={name} {...rest} >
    {
        ({ field }) => {
            return options.map((option,index) => {
                return (
                    <>
                        <div className="radio-block-wrap-item m-b-20">
                            <FormCheck

                                custom
                                {...field}
                                name={name}
                                id={option?.id ? option?.id : option?.choice}
                                label={`${option?.choice}`}
                                type="radio"
                                value={option?.choice}
                                onChange={e =>handleSelection(e,index,option,field)}
                                checked={field.value === option?.choice}
                            />
                        </div>

                    </>
                );
            })
        }
    }

</Field>
</div>
</div>
            {!!errors && !!errors[errorName] && touched['questions']  ?
                <span className={`form-error text-danger`} style={{display:'block'}}>{errors[errorName]}</span>
                :
                ''
            }
        </>

    );
};

export default RadioButton;
