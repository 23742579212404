import React, { useState } from "react";
import ScrollArea from 'react-scrollbar';
import Form from 'react-bootstrap/Form';

function SurveyAssignBox() {
  return (
    <div className="course-assignbox-wrap">
<div className="course-form-content-sec d-flex">
      <div className="add-quiz-box whitebox shadow-box">
         <div className="add-quiz-box-header">
            <Form>
               <div className="form-input-wrap">
                  <Form.Control type="text" placeholder="Your First Name" className="input-item" />
                  <button type="button" className="icon-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                  </svg>
                  </button>
               </div>
            </Form>
         </div>
         
         <div className="add-quiz-box-content add-survey-box-content">
         <ScrollArea
            speed={0.8}
            className="listscroll"
            contentClassName="content"
            horizontal={false}
            >
            <ul>
               <li className="list-selected">
                  <span>The Cool Nerds</span>
               </li>
               <li>
                  <span>Add Another Title</span>
               </li>
               <li>
                  <span>Add Another Title</span>
               </li>
               <li className="list-selected"> 
                  <span>Study Buddies</span>
               </li>
               <li className="list-selected">
                  <span>Changing Majors</span>
               </li>
               <li>
                  <span>Study Buddies</span>
               </li>
               <li>
                  <span>Add Another Title</span>
               </li>
               <li>
                  <span>Study Buddies</span>
               </li>
            </ul>
            </ScrollArea>
         </div>
         
      </div>
  <div className="course-list-selected-box">
      <div className="course-list-selected-box-content">
          <div className="course-selected-item survey-selected">
             <span className="p-relative survey-selected-relative-box">
              <span className="course-selected-text">The Cool Nerds</span>
              <span className="memb-close-icon">×</span>
              </span>
          </div>
          <div className="course-selected-item survey-selected">
             <span className="p-relative survey-selected-relative-box">
              <span className="course-selected-text">Study Buddies</span>
              <span className="memb-close-icon">×</span>
              </span>
          </div>
          <div className="course-selected-item survey-selected">
             <span className="p-relative survey-selected-relative-box">
              <span className="course-selected-text">Changing Majors</span>
              <span className="memb-close-icon">×</span>
              </span>
          </div>
         
      </div>
  </div>
   </div>
   </div>
  );
};
export default SurveyAssignBox;