/* eslint-disable */
import React, { useEffect,useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from "react-redux";
import { Link,useHistory } from 'react-router-dom';
import { getSubscriptionPlans } from '~/modules/auth/services';
import Button from 'react-bootstrap/esm/Button';
import {
    getSubscription,
    getTransactionHistory,
    cancelSubscription,
    getCardDetails
} from '../../../../services';
import ConstructImg from '~/assets/images/under-construction.png';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {toast, ToastContainer} from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import PaymentPopup from '../components/paymentpopup'
import {clearState, createSubscriptionPlan, signInSuccess} from "../../../../../auth/services";
import {upgradePlan,checkSubscriptionStatus,selectPlan,applyCoupon} from '../../../../../auth/services'
import { Popconfirm } from 'antd';
import deleteIco from '~/assets/images/icons/delete.svg';
import {callback} from "chart.js/helpers";
import {resetStore} from "../../../../../../modules/auth/services";
import { checkMemberCount } from '../../../../../../modules/team/services';

const Subscription = ({shouldShowPlans}) => {
   const dispatch = useDispatch();
   const history = useHistory()
   const { subscriptionPlans } = useSelector(state => state.auth);
   const [showplan, setShowPlan] = useState( false);
   const [isPreview, setIsPreview]= useState(false);
   const [subscribedplan, setsubscribedplan] = useState(true)
   const [selectedPlan, setSelectedPlan] = useState(null);
   const { user } = useSelector(state => state.auth);
    const subStatusResponse = useSelector(state => state?.auth?.subscription_status?.response);
    const selected_plan = useSelector(state => state.auth.selected_plan?.response);
   const response = useSelector(state => state.profile.subscription_details?.response);
    const subDetailLoading = useSelector(state => state.profile.subscription_details?.loading);
    const cancelLoading = useSelector(state => state?.profile?.disable_card?.loading);
//    const membercountloading = useSelector(state => state.teams?.count_member?.loading || false);
   const membercountresponse = useSelector(state => state.teams?.count_member?.response);


   const [subscription, setsubscription]= useState({})
   const planResponse = useSelector(state => state.profile.select_plan);
    const loading = useSelector(state => state.auth?.selected_plan?.loading);
    const [state, setState] = useState({
        plans : [],
        term :true,
        planid:''
    })
    const [membercount,setmembercount] = useState(0)
    const [cancelPop, setCancelPop] = useState(false)
    const [checkplan, setCheckPlan] = useState(true)

    useEffect(()=>{
        if(user?.user_type == 'manager' || user?.user_type == 'admin') {
            let filterArray = subscriptionPlans?.filter((el) => {
                return el.term == 'per_year'
            })
            let newSubscriptions = filterArray?.sort((a, b) => {
                return a.max_members - b.max_members;
            });
            setState({...state, plans: newSubscriptions})
        }
    },[subscriptionPlans])
  useEffect(()=>{
      if(shouldShowPlans){
          setShowPlan(true)
          setsubscribedplan(false)
      }
  },[shouldShowPlans])

    useEffect(()=>{
        if(user?.user_type == 'manager'  || user?.user_type == 'admin') {
            dispatch(getSubscription(`${user?.user_id}`, user?.token))
            dispatch(getSubscriptionPlans());
            dispatch(checkMemberCount())
        }
    },[])


    useEffect(()=>{
        setsubscription(response?.data)
    },[response])

    useEffect(()=> {
        if(membercountresponse?.current_member_count){
            setmembercount(membercountresponse?.current_member_count)
        }
    },[membercountresponse])
    

  const upgrade = () =>{
   setSelectedPlan(null);
   setShowPlan(true);
   setsubscribedplan(false)
  };

  const handleCancelSubscription = () => {
    setCancelPop(true)
  }
 
  const cnacelSubscriptionCOnfirm = () => {
    if (subscription && subscription?.subscription_id) {
        let tempObj = {sub_id: subscription?.subscription_id}
        dispatch(cancelSubscription(tempObj,function (){
            let action = 'USER_LOGOUT'
            dispatch(resetStore(action))
            history.push('/login')
        }))
    }else{
        return false;
    }
  }

  const handleCancel = () => {
    setCancelPop(false);
};


  const preview = (value) => {
    if(membercount > value.max_members){
        toast.error('You cannot activate this plan as your current member count exceeds plan maximum member count')
    } else {
        setSelectedPlan(value);
        setState({...state,planid:value.stripe_id})
        const formdata = new FormData();
        formdata.append("price_id",value.stripe_id);
        dispatch(selectPlan(formdata,history,'post_profile',function (res){
            if(res){
                setShowPlan(false);
                setIsPreview(true);
            }
        }));

    }
  };
//   useEffect(() => {

//    setShowPlan(false);
//    setIsPreview(true);
//    }, [planResponse]);
  const handleClick  = () => {
   setSelectedPlan(null);
   setShowPlan(false);
   setIsPreview(false);
   setsubscribedplan(true)
  };

    const handleChange = (checked) => {
        let filterArray ;
        if(checked){
            filterArray = subscriptionPlans?.filter((el)=>{
                return el.term == 'per_year'
            })
        }else {
            filterArray = subscriptionPlans?.filter((el)=>{
                return el.term == 'per_month'
            })
        }
        let newSubscriptions = filterArray?.sort((a,b) =>{
            return a.max_members - b.max_members;
        });
        setCheckPlan(checked)
        setState({...state, plans:newSubscriptions})
    }

   return (
      <div className="tab-content">
         

           

         <div className="subscription-tab-cont-wrap">
             {!subDetailLoading ?
                 <>
                     {!showplan && subscription?.plan && subscription?.amount ?
                         <div>
                             {subscribedplan ?
                                 <>
                                     <div className="d-flex justify-content-between m-b-20 mob-flex-wrap">
                                         <h3 className="tab-title">Current Subscription
                                             : <span>{subscription?.plan}</span></h3>
                                         <div className="button-wrap double-btn mob-w-100">
                                             {subscription && subscription?.subscription_id ?
                                                <Popconfirm
                                                title="Are you sure you want to cancel the subscription?"
                                                visible={cancelPop}
                                                onConfirm={cnacelSubscriptionCOnfirm}
                                                okText="Yes"
                                                cancelText="No"
                                                placement="bottom"
                                                onCancel={handleCancel}
                                                >
                                                 <button className="btn btn-secondary"
                                                         onClick={handleCancelSubscription}>
                                                     {cancelLoading ?
                                                         <Spinner animation="border" variant="warning"/>
                                                         :
                                                         '    Cancel Subscription'
                                                     }

                                                 </button>
                                                 </Popconfirm>
                                                 :
                                                 ''
                                             }
                                             <button className="btn btn-primary" onClick={upgrade}>Upgrade Plan</button>
                                         </div>
                                     </div>
                                     <div className="subscription-det-row">
                                         <Row>
                                             <Col md={4} className="subcription-det-box d-flex align-items-center">
                       <span className="subsc-det-icon">
                       <svg xmlns="http://www.w3.org/2000/svg" width="14.661" height="18.434"
                            viewBox="0 0 14.661 18.434">
                       <g transform="translate(-52.919 0.1)">
                       <g transform="translate(53.02)">
                       <path className="a"
                             d="M67.469,15.037,66.655,4.776A1.282,1.282,0,0,0,65.382,3.6H63.774V3.525a3.525,3.525,0,0,0-7.049,0V3.6H55.116a1.282,1.282,0,0,0-1.273,1.178l-.815,10.264a2.969,2.969,0,0,0,2.96,3.2h8.52a2.969,2.969,0,0,0,2.96-3.2ZM57.792,3.525a2.457,2.457,0,0,1,4.913,0V3.6H57.792ZM65.9,16.557a1.881,1.881,0,0,1-1.395.609h-8.52a1.9,1.9,0,0,1-1.9-2.045l.814-10.264a.209.209,0,0,1,.208-.193h1.608V5.98a.534.534,0,0,0,1.068,0V4.665h4.913V5.98a.534.534,0,1,0,1.068,0V4.665h1.608a.21.21,0,0,1,.208.194L66.4,15.12A1.881,1.881,0,0,1,65.9,16.557Z"
                             transform="translate(-53.02)"/>
                       </g>
                       <g transform="translate(57.687 8.9)">
                       <g transform="translate(0)">
                       <path className="a"
                             d="M189.02,250.049a.534.534,0,0,0-.755,0l-2.385,2.385-.917-.917a.534.534,0,1,0-.755.755l1.295,1.295a.534.534,0,0,0,.755,0l2.762-2.762A.534.534,0,0,0,189.02,250.049Z"
                             transform="translate(-184.052 -249.893)"/>
                       </g>
                       </g>
                       </g>
                       </svg>
                       </span>
                                                 <span className="subsc-det-label">Order number</span>
                                                 <span
                                                     className="subsc-det-value">{subscription?.subscription_id}</span>
                                             </Col>
                                             <Col md={4} className="subcription-det-box d-flex align-items-center">
                       <span className="subsc-det-icon">
                       <svg xmlns="http://www.w3.org/2000/svg" width="17.2" height="17.2" viewBox="0 0 17.2 17.2">
                       <g transform="translate(0.1 0.1)">
                       <path className="a"
                             d="M8.5,17A8.5,8.5,0,0,1,2.49,2.49,8.5,8.5,0,0,1,14.51,14.51,8.444,8.444,0,0,1,8.5,17Zm0-15.938A7.438,7.438,0,1,0,15.938,8.5,7.446,7.446,0,0,0,8.5,1.062Z"
                             transform="translate(0)"/>
                       <path className="a"
                             d="M186.391,100.781a1.328,1.328,0,1,1,1.328-1.328.531.531,0,0,0,1.063,0,2.394,2.394,0,0,0-1.859-2.33v-.591a.531.531,0,0,0-1.062,0v.591a2.39,2.39,0,0,0,.531,4.721,1.328,1.328,0,1,1-1.328,1.328.531.531,0,0,0-1.062,0,2.394,2.394,0,0,0,1.859,2.33v.591a.531.531,0,1,0,1.063,0V105.5a2.39,2.39,0,0,0-.531-4.721Z"
                             transform="translate(-177.891 -92.813)"/> </g>
                       </svg>
                       </span>
                                                 <span className="subsc-det-label">Price</span>
                                                 <span className="subsc-det-value">${subscription?.amount}</span>
                                             </Col>
                                             <Col md={4} className="subcription-det-box d-flex align-items-center">
                       <span className="subsc-det-icon">
                       <svg xmlns="http://www.w3.org/2000/svg" width="18.357" height="18.357"
                            viewBox="0 0 18.357 18.357">
                       <g transform="translate(0.25 0.25)">
                       <g transform="translate(13.393 1.488)">
                       <path className="a"
                             d="M20.6,4.976H18.372a.372.372,0,0,1,0-.744h1.86V2.372a.372.372,0,1,1,.744,0V4.6A.372.372,0,0,1,20.6,4.976Z"
                             transform="translate(-18 -2)"/>
                       </g>
                       <path className="a"
                             d="M8.929,17.857A8.929,8.929,0,1,1,16.111,3.642a.372.372,0,0,1-.6.44,8.171,8.171,0,1,0,1.6,4.847,7.427,7.427,0,0,0-.071-1.063.372.372,0,0,1,.737-.106,8.138,8.138,0,0,1,.078,1.169A8.939,8.939,0,0,1,8.929,17.857Z"/>
                       <g transform="translate(4.464 4.464)">
                       <path className="a"
                             d="M10.464,16.417a.361.361,0,0,1-.144-.03C10.143,16.313,6,14.522,6,10.863V7.754A.373.373,0,0,1,6.253,7.4l4.092-1.382a.373.373,0,0,1,.238,0L14.676,7.4a.373.373,0,0,1,.253.353v3.109c0,3.659-4.143,5.45-4.32,5.525A.373.373,0,0,1,10.464,16.417Zm-3.72-8.4v2.842c0,2.816,3.017,4.433,3.72,4.772.7-.34,3.72-1.963,3.72-4.772V8.021l-3.72-1.256Z"
                             transform="translate(-6 -6)"/>
                       </g>
                       <g transform="translate(6.696 7.812)">
                       <path className="a"
                             d="M10.86,13.848h-.021a.373.373,0,0,1-.27-.139l-1.488-1.86a.372.372,0,1,1,.581-.465l1.228,1.536,2.31-2.31a.372.372,0,0,1,.526.526l-2.6,2.6A.367.367,0,0,1,10.86,13.848Z"
                             transform="translate(-9 -10.5)"/>
                       </g>
                       </g>
                       </svg>
                       </span>
                                                 <span className="subsc-det-label">Next renewal</span>
                                                 <span className="subsc-det-value">{new Intl.DateTimeFormat('en-GB', {
                                                     month: 'long',
                                                     day: '2-digit',
                                                     year: 'numeric',
                                                 }).format(new Date(subscription?.subscription_end_date ? subscription.subscription_end_date :
                                                     new Date()))}
                       </span>
                                             </Col>
                                         </Row>
                                     </div>
                                 </>
                                 :
                                 ''
                             }
                         </div>

                         :
                         <div>
                             {!showplan && !isPreview ?
                                 <>
                                     <div className="d-flex justify-content-between m-b-20">

                                         <button className="btn btn-primary" onClick={upgrade}>Buy New Plan</button>
                                     </div>

                                     <div>{subStatusResponse && subStatusResponse?.is_trial_plan ? 'Trial Plan'  :  'No Subscriptions' }</div>
                                 </>
                                 :
                                 ''
                             }
                         </div>
                     }

                 </>
                 :

                 <Spinner animation="border" variant="warning"/>
             }
            {showplan &&
                <>
                    <Row className='justify-content-end'>
                        <Col md={2}>

                            <div className="button-wrap">
                                <Button className="w-100" onClick={()=> {setShowPlan(false);setsubscribedplan(true)}}>

                                      My Subscription
                                        </Button>

                            </div>

                        </Col>


                    </Row>

                <div className="subscription-page-wraps text-center">

                    <h4 className="auth-title">Available Plans</h4>

                    <div className="subsciption-box">
                        <div className="swith-icon-wrap d-flex justify-content-end">
                            <span className="monthly-yearly-text">Monthly</span>
                            <BootstrapSwitchButton onChange={handleChange} checked={checkplan} size="xs" />
                            <span className="monthly-yearly-text">Yearly</span>
                        </div>
                        <Row className="subsciption-box-row justify-content-center">


                            {
                                state?.plans?.map(plan => (
                                    <Col key={plan.id} md={3} sm={6} className="subsciption-box-cont">
                                        <div className="subscription-item">
                                            <div className="subcribe-top-contents">
                                                <div className="upto-count">
                                                    <span>Up to {plan?.max_members} members</span>
                                                </div>
                                                {/*<div className="level-number">*/}
                                                {/*    <span>{plan?.name}</span>*/}
                                                {/*</div>*/}
                                                <div className="pricing-wrap mt-4">
                                                    <span className="pricing-unit"><i className="fa fa-usd"></i></span>
                                                    <span className="price-value">{Math.trunc(plan?.price)}</span>
                                                    <span className="validity">/{plan?.term && plan.term == 'per_month' ? 'Month' : 'Year'} </span>
                                                </div>

                                            </div>
                                            <div className="button-wrap">
                                                <Button className="w-100" onClick={()=> preview(plan)}>
                                                    {loading && loading && state?.planid == plan?.stripe_id ?
                                                        <Spinner animation="border" variant="warning"/>
                                                        :
                                                        'Choose Plan'}</Button>

                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }

                            {/* <Col md={3} sm={6} className="subsciption-box-cont">
                                <div className="subscription-item subscr-black-box">
                                    <div className="subcribe-top-contents">
                                        <div className="level-number">
                                            <span>Enterprise</span>
                                        </div>
                                        <div className="level4-text">
                                            <span>For companies with more than 2000 users, please contact us</span>
                                        </div>
                                    </div>
                                    <div className="button-wrap">
                                        <Button className="w-100">Contact Us</Button>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </div>
                </div>
                </>
            }
            { isPreview &&
               <CheckoutProfile onClick={handleClick} data={selectedPlan}/>
            }
         </div>
        
      </div>
   );
};

const  CheckoutProfile =({ onClick, data }) => {
   const { user } = useSelector(state => state.auth);
    const loading = useSelector(state => state.auth.userRegistered?.loading);
   const [coupon, setCoupon] = useState('');
   const coupon_code = useSelector(state => state.profile.coupon_code?.response);
    const selected_plan = useSelector(state => state.auth?.selected_plan?.response);
   const [coupon_res, setcoupon_res] = useState({})
    const [loader, setLoader] = useState(false)
    const couponloading = useSelector(state => state.auth?.selected_plan?.loading);
    const [modalShow, setModalShow] = React.useState(false);
   const dispatch = useDispatch();
     const history = useHistory()
   const handleCoupon = () => {
       if (coupon && coupon !== '') {
           dispatch(applyCoupon({price_id: user?.plan_stripe_id, coupon_code: coupon,customer_id:selected_plan.customer},function (res){
               if(res){

                   // setcoupon_res(res)
               }

           }));
       }
   };

    const handleCreatePlan = () => {

        setLoader(true)

        setTimeout(()=>{
            dispatch(createSubscriptionPlan(user.institution_id,function (res){
                if(res?.is_subscribed){
                    let response = {data:{...user,...res}}

                    dispatch(signInSuccess(response));


                    toast.success('Subscription has been completed successfully')
                    setTimeout(() => {
                        dispatch(getTransactionHistory(user?.token));
                        dispatch(getSubscription(`${user?.user_id}`, user?.token))
                        dispatch(getCardDetails(user?.token));
                        handleLoader(false)
                        onClick()
                    }, 300)
                    setLoader(false)

                }else {
                    toast.error('Something went wrong! \n Please try again')
                    setLoader(false)
                    history.push('/login')
                }

            }),function (err){
                if(err){
                    setLoader(false)
                }
            });
        },5000)

    };
    const handleLoader = (bool) =>{
        setTimeout(()=>{
            setLoader(bool)
        },9000)


    }
    const handleModal = () => {
        setModalShow(true);
    };


   return (

     <div>

         {loader &&
         <div className='loader-full-screen'>
             <div className='loader'>
                 <Spinner animation="border" variant="danger"/>
             </div>
         </div>
         }
       <div className="checkout-page-wrap">
         {/* <div className="text-center">
           <div className="auth-logo"><img src={Logo} alt="" /></div>
           <h2 className="auth-title">Checkout</h2>
         </div> */}
         <div className="checkout-order-boxes-wrap">
           <h3 className="subtitle">Order Details</h3>
           <Row className="checkout-order-box-row">
             <Col xs={12} sm={7}>
               <div className="order-detail-box white-box radius-box">
                 <h4 className="box-subhead">User Details</h4>
                 <div className="order-detail-grey-box">
                   <div className="order-detail-cont-row d-flex">
                     <span className="order-det-name">Name</span>
                     <span className="order-det-coulumn">:</span>
                     <span className="order-det-values">{user.first_name +  ' ' + user.last_name}</span>
                   </div>
                   <div className="order-detail-cont-row d-flex">
                     <span className="order-det-name">Email</span>
                     <span className="order-det-coulumn">:</span>
                     <span className="order-det-values">{user.username}</span>
                   </div>
                   <div className="order-detail-cont-row d-flex">
                     <span className="order-det-name">Company</span>
                     <span className="order-det-coulumn">:</span>
                     <span className="order-det-values">{user?.institution}</span>
                   </div>
                   <div className="order-detail-cont-row d-flex">
                     <span className="order-det-name">Designation</span>
                     <span className="order-det-coulumn">:</span>
                     <span className="order-det-values">{user?.user_type}</span>
                   </div>
                 </div>
               </div>
               <div className="order-detail-box white-box radius-box">
                 <h4 className="box-subhead">Subscription Details</h4>
                 <div className="order-detail-grey-box">
                   <div className="order-detail-cont-row d-flex">
                     <span className="order-det-name">Plan</span>
                     <span className="order-det-coulumn">:</span>
                       <span className="order-det-values">{selected_plan?.plan}</span>
                   </div>
                   <div className="order-detail-cont-row d-flex">
                     <span className="order-det-name">Pricing</span>
                     <span className="order-det-coulumn">:</span>
                     <span className="order-det-values">
                        <span className="subs-det-pricing">${selected_plan?.actual_amount}</span>
                       {/* <span className="subs-det-strikethrough">$25</span> */}
                       {/* <span className="subs-det-pricing-desc">(Coupon code applied)</span> */}
                     </span>
                   </div>
                 </div>
               </div>
             </Col>
             <Col xs={12} sm={5}>
               <div className="order-detail-box white-box radius-box">
                 <div className="coupon-box">
                   <div className="coupon-input-box">
                     <label className="input-label">Coupon Code (Optional)</label>
                     <div className="coupon-input-wrap p-relative">
                       <input type="text" placeholder="Enter Code" value={coupon}
                        onChange={(e => setCoupon(e.target.value))}
                          className="form-control input-item" />
                       <Button onClick={handleCoupon} className="apply-input-btn transparent-btn" >

                                  {couponloading && couponloading ?
                           <Spinner animation="border"/>
                           :
                           'Apply'
                       }
                       </Button>
                     </div>
                   </div>
                   <div className="cart-box">
                     <div className="order-detail-cont-row d-flex">
                       <span className="order-det-name">Sub-Total</span>
                       <span className="order-det-coulumn">:</span>
                         <span className="order-det-values">${selected_plan?.actual_amount}</span>
                     </div>
                     <div className="order-detail-cont-row d-flex">
                       <span className="order-det-name">Discounts</span>
                       <span className="order-det-coulumn">:</span>
                         <span className="order-det-values">${selected_plan?.discount_data?.discounted_amount || 0}</span>
                     </div>
                     <div className="cart-footer">
                       <div className="order-detail-cont-row d-flex">
                         <span className="order-det-name">Total</span>
                         <span className="order-det-coulumn">:</span>
                           <span
                               className="order-det-values">${selected_plan?.final_amount}</span>
                       </div>
                     </div>
                   </div>
                 </div>

               </div>
             </Col>
           </Row>
         </div>
       </div>
         <div className="checkout-btns-wrap">
           <Button className="white-btn border-btn"  onClick ={onClick}>Back</Button>
           <Link onClick={handleModal}>
              <Button>Proceed to Payment</Button>
              </Link>
        </div>
         <PaymentPopup
             show={modalShow}
             amount={selected_plan?.final_amount}
             handleCreatePlan={handleCreatePlan}
             onHide={() => setModalShow(false)}
         />
     </div>

   );
 }

export default Subscription;
