import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { FormCheck } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CourseAssignBox from "./components/courseassignbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GroupAssignBox from "./components/groupselectionbox";
import Modal from "react-bootstrap/Modal";

import {
  assignLearningPath,
  deleteAssignLearning,
  getLearningPathAssignments,
  getLearningPathAssignmentsById,
  updateAssignLearning,
  getGroupsWithOutPagination,
  getMembersWithOutPagination,
  getClassesWithOutPagination,
} from "../../services";
import { edLinkAssignmet } from "./../../../../modules/coursemanagement/services";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ScrollArea from "react-scrollbar";
import Spinner from "react-bootstrap/Spinner";
import AssignmentPopup from "./components/assignmentname";
import EdlinkAssignBox from "./components/edlinkassignbox";
import ClassesAssignBox from "./../../../coursemanagement/components/coursebuilder/components/enrollusers/components/classesAssignBox";
import { Popconfirm } from "antd";

function MyVerticallyCenteredModal(props) {
  const dispatch = useDispatch();
  const response = useSelector(
    (state) => state.learningpath?.get_lp_assignments?.response
  );

  const loading = useSelector(
    (state) => state.learningpath?.get_lp_assignments?.loading
  );

  const delLoading = useSelector(
    (state) => state.learningpath?.delete_lp_assigment?.loading
  );
  const [assignId, setAssignId] = useState("");
  const [LPAssignment, setLPAssignment] = useState({
    enga: false,
    edlink: false,
    assignmentBtn: true,
    LPGoBack: false
  })
  const [state, setState] = useState({
    courseAssignments:  [],
    classesAssignments:  [],
    modalId: "",
    isClassesDisplay: false,
    classes: [],
    isGetResponse: false
  });

  const { user } = useSelector((state) => state.auth);

  const handleAptreeAssignments = () => {
    const platform= "enga";
    if (props.url) {
      dispatch(getLearningPathAssignments(props.url,platform));
    }
    setState({ ...state, isGetResponse:true });

    setLPAssignment({ ...LPAssignment, enga: true, edlink: false, assignmentBtn: false, LPGoBack: true })
    
  }


  const handleEdlinkAssignments = () => {
     const platform= "edlink";
    if (props.url) {
      dispatch(getLearningPathAssignments(props.url,platform));
    }
    setState({ ...state, isGetResponse:true });
    
    setLPAssignment({ ...LPAssignment, edlink: true, enga: false, assignmentBtn: false, LPGoBack: true })
   
}




  useEffect(() => {
    if (props.url && !user?.is_edlink_teacher) {
      dispatch(getLearningPathAssignments(props.url));
    }
  }, []);

  useEffect(() => {  
    if (props.url && state.isGetResponse) {
      setState({ ...state, courseAssignments: response?.results });
    }
  }, [response, state.isGetResponse]);

  useEffect(() => {  
    if (props.url && user?.is_edlink_teacher==false) {
      setState({ ...state, courseAssignments: response?.results });
    }
  }, [response]);
  
  const handleSelection = (id, list) => {
  
    setState({ ...state, modalId: id });
    props.handle(id, list);
  };
  const handleCreate = () => {
    props.onHide();
    props.handleAssignment();
  };
  const handleDelete = (e, item) => {
    e.stopPropagation();
    setAssignId(item.id);
    dispatch(
      deleteAssignLearning(props?.lpId, item.id, history, function (res) {
        if (res) {
          let copy = [...state.courseAssignments];
          copy = copy.filter((el) => el.id != item.id);
          setState({ ...state, courseAssignments: copy });
        }
      })
    );
  };
  
  const handleGoBack = () => {
     setState({...state, courseAssignments:[]})
    setLPAssignment({ ...LPAssignment, assignmentBtn: true, LPGoBack: false,  enga:false, edlink:false })
  }


  return (
    <Modal
      className="common-modal team-member-pop inner-popup edlink-multi-buttonpop"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Header>
        <div className="addmember-header d-flex">
        {LPAssignment.LPGoBack && <div className="back-wrrow"> <div className="back-btn" onClick={handleGoBack}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" 
xmlns="http://www.w3.org/2000/svg">
<path d="M8.99967 17.6663L0.333008 8.99967L8.99967 0.333008L10.1372 1.47051L3.42051 8.18717H17.6663V9.81217H3.42051L10.1372 16.5288L8.99967 17.6663Z" fill="black"/>
</svg></div></div>}
          <div className="lp-enroll-pop-header">
          <h4>List Of Learning Path User Enrollments</h4>
          <p>Choose User Enrollment whom you want to Edit</p>
        </div>
        </div>
        <div
          className="form-close-btn p-relative"
          onClick={() => props.onHide(8)}
        >
          <span className="transparent-close-icon">×</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="add-quiz-box-content enroll-user-popup-content">
        
            <div className="edlink-body">
                   {loading ? (
                ""
              ) : (
                <div className="edlink-pop-header">
                <div className="multi-button-wrap-create">
                  <button
                    className="btn btn-m border-btn"
                    onClick={handleCreate}
                  >
                    <span className="add-arrow"><i className="fa fa-plus" aria-hidden="true"></i></span>Create New
                  </button>
                </div>
                </div>
              )}
             {user?.is_edlink_teacher && LPAssignment.assignmentBtn && (
              <div className="add-quiz-box-content d-flex justify-content-center">
                <div className="multy-button-row">
                <div className="multi-button-wrap">
                  <button
                    className="btn btn-m border-btn"
                    onClick={handleEdlinkAssignments}
                  >
                    Edlink Assignments
                  </button>
                </div>
                <div className="multi-button-wrap">
                  <button
                    className="btn btn-m border-btn"
                    onClick={handleAptreeAssignments}
                  >
                    Cultural Engagement Assignments
                  </button>
                </div>
                </div>
         
              </div>)}
              <ScrollArea
            speed={0.8}
            className="listscroll"
            contentClassName="content"
            horizontal={false}
          >
            <ul className="enroll-user-list">
              {/* /// */}
             
              {/* // */}
              {state?.courseAssignments && (
                state?.courseAssignments &&
                  state?.courseAssignments?.length ? (
                  state?.courseAssignments?.map((el, index) => {
                    return (
                      <>
                        <li
                          onClick={() => handleSelection(el.id, el)}
                          key={index}
                          className={`${el.isChecked && el.isChecked ? "list-selected" : ""
                            }`}
                        >
                          {props.loading &&
                            props.loading &&
                            state.modalId == el?.id ? (
                            <Spinner animation="border" variant="warning" />
                          ) : (
                            <div className="d-flex justify-content-between enroll-users-row">
                              <span className="enrollment-name-show">{` ${el?.name}`}</span>  <span className="enrollment-date-show">{el?.due_date ? el?.due_date : ""}{" "}</span>
                            </div>
                          )}
                        </li>
                      </>
                    );

                  })
                ) : (
                  <div className="row justify-content-center">
                    {loading ? (
                      <Spinner animation="border" variant="danger" />
                    ) : (
                      <>
                        <div className="no-existing-user">
                          <p>No Existing User Enrollment</p>
                        </div>
                      </>
                    )}
                  </div>
                )
              )

              }

              {/* ////////// */}
              
            </ul>
            </ScrollArea>
            </div>
        
        </div>
      </Modal.Body>
    </Modal>
  );
}

const CourseAssign = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let loading = useSelector(
    (state) => state?.learningpath?.get_lp_assigment_by_id?.loading
  );
  let updateAssignmentLoading = useSelector(
    (state) => state?.learningpath?.updated_lp_assigment?.loading
  );
  let addAssignmentLoading = useSelector(
    (state) => state?.learningpath?.assign_lp?.loading
  );


  const courseid = match?.params?.id;
  const [state, setState] = useState({
    isDisplay: false,
    activeTab: "enga",
    isGroupDisplay: false,
    isClassesDisplay: false,
    member: [],
    groupDetails: [],
    modalShow: true,
    assignmentId: "",
    assignmentList: [],
    singleAssignment: {},
    basicInformation: {},
    focus: false,
    failedVaues: {},
    popUp: false
  });



  const [option, setOption] = useState('')

  const [assignmentPopup, setassignmentPopup] = useState(false);
  const groupSelection = useRef();
  const memberSelection = useRef();
  const classesSelection = useRef();


  const handleOk = () => {
    setState({ ...state, activeTab: option, isDisplay:false, isGroupDisplay:false, isClassesDisplay:false})
    setOption('')
  };

  const handleCancel = () => {
    
    setOption('')
  };


  const memberList = (data) => {
    setState({ ...state, member: data });
  };


  const groupList = (data) => {
    setState({ ...state, groupDetails: data });
  };


  const classesList = (data) => {
    setState({ ...state, classes: data });
  };
  

  const getBasicInformation = (data) => {
    setState({ ...state, basicInformation: data });
  };
  const onSave = () => {
    let data = {
      groups: state.groupDetails,
      classes: state.classes,
      learners: state.member,
      name: state?.basicInformation?.name
        ? state?.basicInformation?.name
        : state?.singleAssignment?.name,
      due_date: state?.basicInformation?.due_date
        ? state?.basicInformation?.due_date
        : state?.singleAssignment?.due_date,
    };

    let payload = {
      ed_link_class_ids: state.classes,
      name: state?.basicInformation?.name
        ? state?.basicInformation?.name
        : state?.singleAssignment?.name,
      due_date: state?.basicInformation?.due_date
        ? state?.basicInformation?.due_date
        : state?.singleAssignment?.due_date,
    };

    if (
      state?.basicInformation?.name?.length ||
      state?.singleAssignment?.name?.length
    ) {
      if (state.assignmentId && courseid) {
        dispatch(
          updateAssignLearning(
            data,
            courseid,
            state.assignmentId,
            history,
            function (err) {
              if (err) {
                setState({ ...state, failedVaues: data });
                setassignmentPopup(true);
              }
            }
          )
        );
      } else if (user?.is_edlink_teacher && state.activeTab == "edlink") {


        dispatch(
          edLinkAssignmet(payload, courseid, history, function (error) {
            if (error) {
              setState({ ...state, failedVaues: data });
              setassignmentPopup(true);
            }
          })
        );

      } else {
        dispatch(
          assignLearningPath(data, courseid, history, function (error) {
            if (error) {
              setState({ ...state, failedVaues: data });
              setassignmentPopup(true);
            }
          })
        );
      }
    } else {
      setassignmentPopup(true);
      setState({ ...state, focus: true });
    }
  };

  useEffect(() => {
    let element = document.getElementById("headerTitle");
    if (element) {
      switch (true) {
        case history.location.pathname.includes("enroll_users"):
          element.innerHTML = "Learning Path Library";
          break;
          "Profile";
      }
    }
  }, []);

  const handleBack = () => {
    history.push("/learningpaths");
  };
  const handleAssignmentsById = (id, list) => {
    dispatch(
      getLearningPathAssignmentsById(
        id,
        courseid,
        function (response) {
          if (response) {
            memberSelection.current.checked = true;
            // classesSelection.current.checked = true;
            groupSelection.current.checked = true;
            // list?.ed_link_class_id ? classesSelection.current.checked = true : '';
            setState({
              ...state,
              isGroupDisplay: list.assignment_type==='enga',
              isDisplay: list.assignment_type==='enga',
              modalShow: false,
              assignmentId: id,
              isClassesDisplay: list.assignment_type==='edlink',
              singleAssignment: list,
              activeTab:list.assignment_type
            });
            setassignmentPopup(true);
          }
        },
        function (error) {
          setState({ ...state, modalShow: false });
        }
      )
    );
  };

  const handleLoader = () => {
    if (updateAssignmentLoading || addAssignmentLoading) {
      return (
        <div className="loader-full-screen-course">
          <div className="loader">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      );
    }
  };

  const handleAssignmentPopUp = () => {
    setassignmentPopup(true);
  };
  return (
    <>
      <div className="course-assign-wrap">


        <Modal
          className="common-modal team-member-pop inner-popup"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={option}
        >
          <Modal.Header>
            <div className="addmember-header">
              <h4 className="attention-title"><span className="attention-text">Attention!</span> Once you switch from one assignment to another, your previous assignment will not be saved.</h4>

            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="add-quiz-box-content d-flex justify-content-center">
              <div className=" text-center mt-3">
                <button
                  className="btn btn-m border-btn"
                  onClick={handleCancel}
                >
                  Let's stay
                </button>
              </div>
              <div className=" text-center mt-3  ml-3">
                <Button
                 
                   variant="primary"
              className="btn-m"
                  onClick={handleOk}
                >
                  Go, ahead
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {handleLoader()}
        {user?.is_edlink_teacher && (
        <div className="course-assign-member-type">
          <Form className="d-block">
            <Row>
              <Col md="2">
                <FormCheck
                  custom
                  disabled={(state?.singleAssignment?.assignment_type ) ? true : false}
                  type="radio"
                  id="enga"
                  name="activeTab"
                  value={"enga"}
                  label={`Assign with Enga`}
                  onChange={(e) => {
                    setOption('enga')
                  }}
                  checked={state.activeTab === "enga"}

                />
              </Col>
              <Col md="2">
                {" "}
                <FormCheck
                  custom
                  // disabled={state?.singleAssignment && state?.singleAssignment?.id ? true : false}
                  disabled={(state?.singleAssignment?.assignment_type) ? true : false}
                  // disabled={(state?.singleAssignment?.assignment_type == 'edlink') ? true : false}
                  type="radio"
                  id="edlink"
                  name="activeTab"
                  value="edlink"
                  label={`Assign with Edlink`}
                  onChange={(e) => {
                    // setState({ ...state, activeTab: e.target.value })
                    setOption('edlink')

                  }}
                  checked={state.activeTab == "edlink"}

                />
              </Col>
            </Row>

          </Form>
        </div>
        )}
        <div className="course-assign-member-type">
          <Form className="d-block">
            <Row>
              {state.activeTab == "enga" && <>
                <Col md="3">
                  <Form.Check
                    custom
                    type="checkbox"
                    id="check1"
                    ref={memberSelection}
                    label={`Add member`}
                    onChange={(e) =>
                      setState({ ...state, isDisplay: e.target.checked })
                    }
                  />
                </Col>
                <Col md="3">
                  {" "}
                  <Form.Check
                    custom
                    type="checkbox"
                    id="check2"
                    label={`Add group`}
                    ref={groupSelection}
                    onChange={(e) =>
                      setState({ ...state, isGroupDisplay: e.target.checked })
                    }
                  />
                </Col>
              </>}
              {(
                user && user?.is_edlink_teacher &&

                state.activeTab == "edlink") && (
                  <Col md="3">
                    <Form.Check
                      custom
                      disabled={state.singleAssignment.assignment_type=='edlink'? true: false}
                      type="checkbox"
                      id="check3"
                      checked={state.isClassesDisplay?true:false}
                      label={`Add Classes`}
                      ref={classesSelection}
                      onChange={(e) =>
                        setState({ ...state, isClassesDisplay: e.target.checked })
                      }
                    />
                  </Col>
                )}
            </Row>
          </Form>
        </div>

        <div className="course-assign-box-container">
          {/* {state.isDisplay || state.activeTab == "aptree" && ( */}
          {state.isDisplay && (

            <>
              <h4 className="course-assign-type-name">
              Learners to whom you want to assign this Learning Path
              </h4>
              <CourseAssignBox
                singleAssignment={state.singleAssignment}
                urlId={courseid}
                assignId={state?.assignmentId}
                memberList={memberList}
              />
            </>
          )}
        </div>
        <div className="course-assign-box-container">
          {state.isClassesDisplay && (
            <>
              <h4 className="course-assign-type-name">
                Classes to whom you want to assign this Learning Path
              </h4>
              <ClassesAssignBox
                singleAssignment={state.singleAssignment}
                urlId={courseid}
                assignId={state?.assignmentId}
                classesList={classesList}
                active={state.activeTab}
              />
            </>
          )}
        </div>
        <div className="course-assign-box-container">
          <div className="course-assign-box-container">
            {state.isGroupDisplay && (
              <>
                <h4 className="course-assign-type-name">
                Groups to whom you want to assign this Learning Path
                </h4>
                <GroupAssignBox
                  singleAssignment={state.singleAssignment}
                  urlId={courseid}
                  assignId={state?.assignmentId}
                  groupList={groupList}
                />
              </>
            )}
          </div>
        </div>

        {/* <div className="course-assign-box-container">
          {state.isDisplay && (
            <>
              <h4 className="course-assign-type-name">
                People to whom you want to assign this course
              </h4>
              <EdlinkAssignBox
                singleAssignment={state.singleAssignment}
                urlId={courseid}
                assignId={state?.assignmentId}
                edlinkClassesList={memberList}
              />
            </>
          )}
        </div> */}
      </div>
      <div className="add-module-footer d-flex justify-content-end">
        <div className="button-wrap double-btn">
          <button className="btn btn-m border-btn" onClick={handleBack}>
            Cancel
          </button>
          {state.assignmentId ? (
            <Button
              variant="primary"
              className="btn-m"
              disabled={updateAssignmentLoading}
              onClick={onSave}
            >
              {user?.is_edlink_teacher? 'Save Enrollment':'Enroll Users'}
            </Button>
          ) : (
            <Button
              variant="primary"
              className="btn-m"
              disabled={addAssignmentLoading}
              onClick={onSave}
            >
              Enroll Users
            </Button>
          )}
        </div>
      </div>

      <MyVerticallyCenteredModal
        url={courseid}
        show={state.modalShow}
        loading={loading}
        handle={handleAssignmentsById}
        handleAssignment={handleAssignmentPopUp}
        onHide={() =>
          setState({ ...state, modalShow: false, assignmentId: "" })
        }
      />
      <AssignmentPopup
        show={assignmentPopup}
        failed={state.failedVaues}
        basicInformation={getBasicInformation}
        singleAssignment={state?.singleAssignment}
        focus={state?.focus}
        onHide={() => setassignmentPopup(false)}
      />
    </>
  );
};

export default CourseAssign;
