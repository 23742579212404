import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";

import Select, { components } from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import { format } from "date-fns";

import { getNotifications } from "../../../Notifications/services";
import CustomPagination from "../../../../shared/components/pagination";
import { getManagersList } from "~/modules/surveys/services";
import {
  getDeletedQuiz,
  getDraftQuizzesManagerList,
  getManagerQuizzesList,
  deleteQuizForever,
  restoreDeletedQuiz,
  restoreMultipleQuiz,
  deleteMultipleQuiz,
  handleDeletePagination,
  handleDeletePreviousPagination,
} from "../../services";

import "react-toastify/dist/ReactToastify.css";

const DeletedQuizzes = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [quizzes, setQuizzes] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [createdby, setCreatedby] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deleteAllModalShow, setDeleteAllModalShow] = useState(false);
  const [selectQuizRow, setSelectQuizRow] = useState(null);
  const [selectQuizRows, setSelectQuizRows] = useState([]);
  const [dataProgress, setDataProgress] = useState(false);
  const [multiSelectIds, setMultiselectIds] = useState([]);
  const [activePage, setActivePage] = useState(1);

  const loading = useSelector((state) => state.quiz.deleted_quiz_list?.loading);
  const managers = useSelector(
    (state) => state.surveys.manager_list?.response || [] || []
  );
  const response = useSelector(
    (state) => state.quiz.deleted_quiz_list?.response
  );

  useEffect(() => {
    if (loading) {
      setDataProgress(true);
    }

    if (!loading) {
      setDataProgress(false);
    }
  }, [loading]);

  useEffect(() => {
    setSearch("");
    setDateRange([null, null]);
    setCreatedby("");
    dispatch(
      getDeletedQuiz(
        search,
        startDate === null ? "" : startDate,
        endDate == null ? "" : endDate,
        createdby === "" ? "" : createdby?.id,
        ""
      )
    );
    dispatch(getManagersList());
    dispatch(getNotifications());
  }, []);

  useEffect(() => {
    setQuizzes(response);
  }, [response]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      dispatch(
        getDeletedQuiz(
          search,
          format(new Date(startDate), "yyyy-MM-dd"),
          format(new Date(endDate), "yyyy-MM-dd"),
          createdby === "" ? "" : createdby?.id,
          categorySelected === "" ? null : null
        )
      );
    }
  }, [startDate, endDate]);

  const reset = () => {
    setSearch("");
    setActivePage(1);
    setDateRange([null, null]);
    setCreatedby("");
    setCategorySelected("");
    dispatch(
      getDeletedQuiz(
        search,
        startDate === null ? "" : startDate,
        endDate == null ? "" : endDate,
        createdby === "" ? "" : createdby?.id,
        ""
      )
    );
  };

  const handleDeleteQuiz = (row) => {
    dispatch(deleteQuizForever(row));
    setModalShow(false);
    setDataProgress(true);

    setTimeout(() => {
      dispatch(
        getDeletedQuiz(
          search ===null?"":"",
          startDate === null ? "" : "",
          endDate == null ? "" : "",
          createdby === "" ? "" : "",
          categorySelected === "" ? null : null,
          activePage === "" ? null : activePage,     
        )
      );

      setDataProgress(false);
    }, 1000);
  };

  const RestoreQuiz = (row) => {
    dispatch(restoreDeletedQuiz(row));

    setDataProgress(true);
    setTimeout(() => {
      dispatch(
        getDeletedQuiz(
          "",
          startDate === null ? "" : "",
          endDate == null ? "" : "",
          createdby === "" ? "" : "",
          categorySelected === "" ? null : null,
          ""
        )
      );

      search,
        format(new Date(startDate), "yyyy-MM-dd"),
        format(new Date(endDate), "yyyy-MM-dd"),
        createdby === "" ? "" : createdby?.id;

      dispatch(
        getManagerQuizzesList(
          search ? search : "",
          startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
          endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
          createdby === "" ? "" : createdby?.id
        )
      );

      dispatch(
        getDraftQuizzesManagerList(
          "",
          startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
          endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
          createdby === "" ? "" : createdby?.id
        )
      );

      setDataProgress(false);
    }, 1000);
  };

  const handleRestoreAll = () => {
    dispatch(restoreMultipleQuiz(multiSelectIds));
    setDataProgress(true);
    setTimeout(() => {
      dispatch(
        getDeletedQuiz(
          "",
          startDate === null ? "" : "",
          endDate == null ? "" : "",
          createdby === "" ? "" : "",
          categorySelected === "" ? null : null,
          ""
        )
      );

      dispatch(
        getManagerQuizzesList(
          search ? search : "",
          startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
          endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
          createdby === "" ? "" : createdby?.id
        )
      );

      dispatch(
        getDraftQuizzesManagerList(
          "",
          startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
          endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
          createdby === "" ? "" : createdby?.id
        )
      );

      setDataProgress(false);
      setMultiselectIds([]);
    }, 1000);
  };

  const handleDeleteAll = () => {
    dispatch(deleteMultipleQuiz(multiSelectIds));
    setDeleteAllModalShow(false);
    setDataProgress(true);
    setTimeout(() => {
      dispatch(
        getDeletedQuiz(
          "",
          startDate === null ? "" : "",
          endDate == null ? "" : "",
          createdby === "" ? "" : "",
          categorySelected === "" ? null : null,
          ""
        )
      );

      setDataProgress(false);
      setMultiselectIds([]);
    }, 1000);
  };

  const handleNext = () => {
    if (quizzes?.next) {
      setActivePage((prevState) => prevState + 1);
      dispatch(handleDeletePagination(quizzes?.next));
    } else {
      return false;
    }
  };

  const searchInput = (e) => {
    setSearch(e.target.value);
    dispatch(
      getDeletedQuiz(
        e.target.value,
        startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
        endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
        createdby === "" ? "" : createdby?.id,
        categorySelected === "" ? null : categorySelected.value,
        ""
      )
    );
  };

  const onSelect = (val) => {
    setCreatedby(val);
    dispatch(
      getDeletedQuiz(
        search,
        startDate === null ? "" : startDate,
        endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
        val.id,
        categorySelected === "" ? null : categorySelected.value,
        ""
      )
    );
  };

  const handlePrevious = () => {
    if (quizzes?.previous) {
      setActivePage((prevState) => prevState - 1);
      dispatch(handleDeletePreviousPagination(quizzes?.previous));
    } else {
      return false;
    }
  };

  const handleIndex = (index) => {
    dispatch(
      getDeletedQuiz(
        search,
        startDate === null ? "" : startDate,
        endDate == null ? "" : endDate,
        createdby === "" ? "" : createdby?.id,
        categorySelected === "" ? null : categorySelected.value,
        index
      )
    );

    setActivePage(index);
  };

  const handleMultiSelect = (s) => {

    const temp = [];
    s?.selectedRows?.map((e) => {
      temp.push({ id: e.id });
    });
    setMultiselectIds(temp);
  };

  const CaretDownIcon = () => {
    return (
      <div className="filter-icon-select">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.652"
          height="19.653"
          viewBox="0 0 19.652 19.653"
        >
          <path
            className="a"
            d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0"
            transform="translate(-0.5 0)"
          />
        </svg>
      </div>
    );
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        ref={ref}
        {...rest}
      />
      <label className="custom-control-label" onClick={onClick} />
    </div>
  ));

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  function DeleteVerticallyCenteredModal(props) {
    return (
      <Modal
        className="custom-modal confirmation-popup  delete-confirmation inner-popup"
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-content-wrap text-center">
            <h3 className="modal-title">Delete Quiz Forever?</h3>
            {/* <p>Are You Sure To Delete The Members? </p> */}
            <div className="d-flex justify-content-center buttons-wrap double-btn">
              <button
                type="button"
                className="btn border-btn white-btn"
                onClick={() => props.onHide()}
              >
                No, I need to recheck
              </button>
              <Link className="m-l-20">
                <Button
                  onClick={() => {
                    props.onHide();
                    props.handleSubmit();
                  }}
                  type="button"
                >
                  Yes, Continue
                </Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  } 

  function DeleteAllVerticallyCenteredModal(props) {
    return (
      <Modal
        className="custom-modal confirmation-popup  delete-confirmation inner-popup"
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-content-wrap text-center">
            <h3 className="modal-title">Delete Quizzes Forever?</h3>
            <div className="d-flex justify-content-center buttons-wrap double-btn">
              <button
                type="button"
                className="btn border-btn white-btn"
                onClick={() => props.onHide()}
              >
                No, I need to recheck
              </button>
              <Link className="m-l-20">
                <Button
                  onClick={() => {
                    props.onHide();
                    props.handleSubmit();
                  }}
                  type="button"
                >
                  Yes, Continue
                </Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const tableColumns = [
    {
      name: "Quiz Name",
      // cell: (row) => row.name,
      selector: "name",
      sortable: true,
      center: false,
      grow: 5,
    },

    {
      name: "Question Count",
      // cell: (row) => (row.questions_count ? row.questions_count : 0),
      selector: "questions_count",
      sortable: true,
      center: false,
      grow: 3,
    },

    {
      name: "Action",
      selector: "Action",
      sortable: false,
      center: false,
      grow: 2,
      cell: (row, index) => (
        <div data-tag="allowRowEvents" className="d-flex">
          <div className="action-icons-cell-wrap d-flex">
            <div
              className="action-icon-item stroke-icon"
              onClick={(e) => RestoreQuiz(row)}
            >
              <OverlayTrigger
                overlay={<Tooltip id="InfoText">Restore</Tooltip>}
              >
                <i class="fa fa-undo"></i>
              </OverlayTrigger>
            </div>
            <div
              className="action-icon-item fill-icon"
              onClick={(e) => {
                setModalShow(true);
                setSelectQuizRow(row);
              }}
            >
              <OverlayTrigger
                overlay={<Tooltip id="InfoText">Delete Forever</Tooltip>}
              >
                <svg
                  id="icons_Q2"
                  data-name="icons Q2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.49"
                  height="19.331"
                  viewBox="0 0 17.49 19.331"
                >
                  <path
                    id="Path_8323"
                    data-name="Path 8323"
                    d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                    transform="translate(-4.999 -3)"
                    fill="#fff"
                  />
                  <path
                    id="Path_8324"
                    data-name="Path 8324"
                    d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                    transform="translate(-7.261 -9.477)"
                    fill="#fff"
                  />{" "}
                </svg>
              </OverlayTrigger>
            </div>
            <div> </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="available-course-wrap">
      {/*<ToastContainer autoClose={2000}/>*/}
      <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
        <div className="datatable-filter-left-box d-flex align-items-center">
          <div className="datepicker-box p-relative">
            <DatePicker
              placeholderText={"Created date"}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
            />
            <span className="datepickericon">
              <i className="fa fa-calendar"></i>
            </span>
          </div>
          </div>

        <div className="dt-filter-right d-flex">
          <div className="dt-filter-search-wrap p-relative">
            <input
              type="text"
              placeholder="Search using keyword"
              value={search}
              onChange={searchInput}
              className="form-control input-item"
            />
            <span
              className="search-close-icon"
              style={{ cursor: "pointer" }}
              onClick={reset}
            >
              ×
            </span>
          </div>
          <button className="iconbutton search-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.216"
              height="19.216"
              viewBox="0 0 19.216 19.216"
            >
              <path
                className="a"
                d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"
              />
            </svg>
          </button>
        </div>
      </div>

      {loading && loading ? (
        <>
          <div className="loaderCourses">
           <Spinner animation="border" variant="danger" />
          </div>
       </>

      ) : (  
        <>
          {multiSelectIds?.length > 0 && (
        <div className="d-flex align-items-center justify-content-end">
          <Button className=" mr-2" 
          onClick={(e) => 
          {setDeleteAllModalShow(true);
          // setSelectQuizRow(row);
           setSelectQuizRows([]);
         // setMultiselectIds([]);
        }}
          // onClick={handleDeleteAll}
          >
            Delete All
          </Button>
          <Button className="" onClick={handleRestoreAll}>
            Restore All
          </Button>
        </div>
        )} 
        <div className="datatble-wrap checkbox-datatable">
        <DataTable
          data={quizzes?.results}
          columns={tableColumns}
          center={true}
          selectableRows
          selectableRowsComponent={BootyCheckbox}
          onSelectedRowsChange={handleMultiSelect}
          showPaginationBottom={false}
          noHeader={true}
          progressPending={dataProgress}
         
        />
         </div>
        
        </>

      )}
     
        {!dataProgress ? (
          <>
            {quizzes?.count > 12 ? (
              <CustomPagination
                response={quizzes}
                activePage={activePage}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                handleIndex={handleIndex}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        <DeleteVerticallyCenteredModal
          show={modalShow}
          handleSubmit={() => {
            handleDeleteQuiz(selectQuizRow);
            setModalShow(false);
          }}
          onHide={() => setModalShow(false)}
          // onExited={ModalExit}
        />

        <DeleteAllVerticallyCenteredModal
          show={deleteAllModalShow}
          handleSubmit={() => {
             handleDeleteAll(selectQuizRows);
          // handleDeleteAll(multiSelectIds);
            setDeleteAllModalShow(false);
          }}
          onHide={() => setDeleteAllModalShow(false)}
          // onExited={ModalExit}
        />
     
    </div>
  );
};
export default DeletedQuizzes;
