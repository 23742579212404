import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormControl } from "react-bootstrap";
function TotalNeedScore(props) {
  const { errors, touched, values, setFieldValue, handleChange } = props;
  return (
    <div className="duration-wrap">
      <Row>
        <Col xs={4} md={3}>
          <div className="form-input-wrap">
            <Form.Label>
              {props?.label || "Total Score Required to Pass This Quiz"}
            </Form.Label>
            <Form.Control
              type="text"
              value={values?.pass_score}
              onChange={handleChange}
              name="pass_score"
              isInvalid={
                !!errors?.pass_score && !!touched?.pass_score
                  ? " is-invalid"
                  : ""
              }
            />
            <FormControl.Feedback type="invalid">
              {errors?.pass_score}
            </FormControl.Feedback>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default TotalNeedScore;
