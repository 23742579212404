import React, {useEffect, useState} from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScrollArea from 'react-scrollbar';
import { Formik,Field, Form } from "formik";
import {FormikControlStled} from '../components/quizFormikControl/container/index'
import * as Yup from "yup";

function TabModules(props) {
    return (
        <div className='survey-body'>
            <h2 className="survey-title">{props?.values?.name}</h2>
             <Formik
                    // validationSchema={schema}
                    enableReinitialize={true}
                    // onSubmit={handleSubmit}
                      initialValues={{}}

                >
                    {({
                        // handleSubmit,
                        // handleChange,
                        values,
                        errors,
                        touched,
                        setFieldValue
                    }) => {
                        return (
                            <Form>
                                <div>
                                    <div className="radio-block-main-wrap">
                                    {props.values?.questions && props.values?.questions?.length && props.values?.questions?.map((value, index) => (
                                        <>
                                        
                                        <FormikControlStled
                                            key={value?.question_id}
                                            quesId={value?.question_id ? value?.question_id : 0}
                                            quesIndex={index}
                                            control={value?.question_type ? value?.question_type : 'No luck'}
                                            name={value?.question}
                                            mandatory={value?.mandatory}
                                            quesNumber={index + 1 }
                                            errorName={value?.question_id}
                                            touched={touched}
                                            options={value?.question_type == 'free_response' ? value?.free_response?.answer : value?.multiple_choices?.filter((el) => el?.choice?.length > 0)}
                                            errors={errors}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                
                                    </>
                                    ))}
                                </div>
                                </div>
                              <div className="button-wrap double-btn d-flex justify-content-end m-t-20">
                                    <Button type="button" onClick={()=>props.onHide()}>Close</Button>
                                </div>
                              
                             </Form>
                               
                             );
                        }}
                              
                </Formik>
                </div>        
                        
    );
}
export default TabModules;
