import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditor from '../ckeditor';
import CustomDropzone from "../customDropzone";
function Template10(props) {
    const {values,index,setFieldValue,name,moduleErrors,moduleTouched,handleBlur } = props
    return (
        <div className="templates-wrap">
            <Row>
                <Col md={12}><CustomDropzone moduleErrors={moduleErrors} moduleTouched={moduleTouched} name={`modules.${index}.image`} setFieldValue={setFieldValue} values={values} index={index} />
                    {moduleErrors?.image && moduleTouched?.image &&
                    <span className={`form-error text-danger`} style={{display:'block'}}>{moduleErrors?.image}</span>}
                </Col>
            </Row>
        </div>
    )
};
export default Template10;
