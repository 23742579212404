import React, {useState, useEffect, useCallback} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from 'date-fns';
import Select from 'react-select';
import CourseDummy from '~/assets/images/course_dummy.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import debounce from "lodash.debounce";
import {
    getDeletedCourses,
    handlePaginationDeletedCourses,
    handlePreviousDeletedCourses,
    restoreCourse,
    permanentlyDeleteCourse,
    getManagerCoursesList, getDraftCoursesManagerList, permanentlyBulkDeleteCourse, getAdminCoursesList,

} from '../../../services';
import 'react-toastify/dist/ReactToastify.css';

import {useDispatch, useSelector} from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import Pagination from "react-bootstrap/Pagination";
import {useHistory} from "react-router-dom";
import {getNotifications} from "../../../../Notifications/services";
import CustomPagination from "../../../../../shared/components/pagination";
import InfoModal from "../../../../../shared/components/infoModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const DeletedCourses = ({getCourseIds}) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [search, setSearch] = useState('');
    const response = useSelector(state => state?.course?.deleted_courses?.response);
    const restoreLoading = useSelector(state => state?.course?.restore_course?.loading);
    const permanentLoading = useSelector(state => state?.course?.permanently_delete_course?.loading);
    const permanentBulkLoading = useSelector(state => state?.course?.bulk_delete_course?.loading);
    const permanentRestoreBulkLoading = useSelector(state => state?.course?.bulk_delete_course?.loading);
    const { hideLpQuizes } = useSelector((state) => state.settings);
    const bulk_delete_course = useSelector(state => state?.course?.bulk_delete_course?.response);
    const restore_bulk_course = useSelector(state => state?.course?.restore_bulk_course?.response);
    const loading = useSelector(state => state?.course?.deleted_courses?.loading);
    const [state, setState] =useState({
        courses:response,
        count:response?.count,
        paginationItem:[],
        courseId :'',
        courseIds : [],
    })
    const [activePage, setActivePage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [count,setCount] = useState(0)
    const [showModal,setShowModal] = useState(false)
    const [course,setCourse] = useState({})
    useEffect(() => {
        setSearch('');
        setDateRange([null, null]);
        // dispatch(getDeletedCourses(search,  '' , '' ));
        // dispatch(getNotifications())
    }, []);
    useEffect(()=>{
        if(count > 0 && dateRange[0] == null){
            dispatch(getDeletedCourses("", startDate === null ? '' : '', endDate === null ? '' : ''));

        }
    },[count])
    useEffect(() => {
        setState({...state, courses:response,courseIds: []});
    }, [response]);



    useEffect(() => {

        if (startDate !== null && endDate !== null) {
            setActivePage(1);
            dispatch(getDeletedCourses(search, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd')));
        }

    }, [startDate, endDate]);

    const debouncedGetSearch = useCallback(debounce(query => {

        setActivePage(1)
        dispatch(getDeletedCourses(query, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd')));

    },1000),[])
    const searchText = (e) => {
        setSearch(e.target.value);
        debouncedGetSearch(e.target.value)

    };

    useEffect(() => {
        reset();
    },[bulk_delete_course, restore_bulk_course])

    const reset = () => {
        setSearch('');
        setDateRange([null, null]);
        dispatch(getDeletedCourses("", '', ''));
        setActivePage(1)
    };

    const handleNext = () => {
        if (state.courses?.next) {
            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
            dispatch(handlePaginationDeletedCourses(state.courses?.next));
        } else {
            return false;
        }

    };
    const handlePrevious = () => {
        if (state.courses?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            dispatch(handlePreviousDeletedCourses(state.courses?.previous));

        } else {
            return false;
        }
    };
    const handleIndex = (index) =>{


        dispatch(getDeletedCourses(search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), index));

        setActivePage(index);
    }

    const handleDeleteCourse = (e,row) => {
       setState({...state,courseId: row.id})
       const pgnumber = response?.results?.length > 1 ? activePage : activePage > 1 ? activePage - 1 : '';
        dispatch(permanentlyDeleteCourse(row,function (res){
            if(res){
                setTimeout(()=>{
                    dispatch(getDeletedCourses(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), pgnumber));
                    dispatch(getDraftCoursesManagerList());
                    setCourse({})
                },500)
            }
        }));


    };
    const handleEdit  =(e,row)=>{
        e.stopPropagation()
        setState({...state,courseId: row.id})
        const pgnumber = response?.results?.length > 1 ? activePage : activePage > 1 ? activePage - 1 : '';
        dispatch(restoreCourse(row,function (res){
           if(res){
               setTimeout(()=>{
                   dispatch(getDeletedCourses(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), pgnumber));
                   dispatch(getDraftCoursesManagerList());
               },500)
           }
        }))

    }

   const  handleBulk  = (item,index) =>{

        let {courses,courseIds} = state
       courses.results[index].is_selected = !courses.results[index].is_selected
        if(courseIds.find((el)=>el == item.id )){
            courseIds = courseIds.filter((el)=>el != item.id)
        }else {
            courseIds.push(item.id)
        }
       getCourseIds(courseIds)
       setState({...state,courses: courses,courseIds: courseIds})
   }

    const handleDel  =  (e,row) => {
        e.stopPropagation()
        setCourse(row)
        setShowModal(true)
    }

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
    return (
        <div className="available-course-wrap">
            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="datatable-filter-left-box d-flex align-items-center">
                    <div className="datepicker-box p-relative">
                        <DatePicker
                            placeholderText={'Created date'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setCount(count+1)
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        <span className="datepickericon">
                     <i className="fa fa-calendar"></i>
                  </span>
                    </div>


                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative search-icon-left">
                    <button className="iconbutton search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216"
                             viewBox="0 0 19.216 19.216">
                            <path className="a"
                                  d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"/>
                        </svg>
                    </button>
                        <input type="text" placeholder="Search using keyword"
                               value={search} onChange={searchText}
                               className="form-control input-item"/>
                        <span className="search-close-icon" style={{cursor: "pointer"}} onClick={reset}>×</span>
                    </div>
                    <OverlayTrigger
                overlay={
                    <Tooltip id="InfoText">
                          Help
                    </Tooltip>
                }
            >
                <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </OverlayTrigger>
                </div>
            </div>
            <div className="courseslisting">
                <Row>
                    {loading && loading ?
                        (
                            <>
                                <div className='loaderCourses'>
                                    <Spinner animation="border" variant="danger"/>
                                </div>

                            </>


                        ) : (
                            <>

                                {!loading && state.courses && state.courses && state.courses?.results && state.courses?.results?.length ? state.courses?.results?.map((el, index) => (
                                        <Col md={4} lg={3} className="m-b-30 course-box-outer">

                                            <div className="common-card coursecard" onClick={()=>handleBulk(el,index)}>
                                                <div className="course-box-top">
                                                    <div className="course-listing-img">
                                                        <img src={el?.image ? el?.image :CourseDummy} alt=""/>
                                                    </div>
                                                    <div className="common-card-detail-wrap box-listing">
                                                        <h4>{el?.name}</h4>
                                                        <p>{el?.description}</p>
                                                    </div>
                                                    <div className="coommon-card-det-number d-flex justify-content-between">
                                                        <span className="common-card-oth-det-left">Modules <span
                                                            className="det-numb">{el?.modules_count}</span> </span>
                                                        {!hideLpQuizes && (
                                                            <span className="common-card-oth-det-left">Quizzes <span
                                                            className="det-numb">{el?.quizzes_count}</span> </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="common-card-footer border-top-footer">
                                                    <div
                                                        className="common-card-footer-cont no-border d-flex justify-content-between align-items-center">


                                                            <>
                                                                <button type="button"  onClick={(e)=>handleDel(e,el)}  className="btn border-btn min-delete-button">

                                                                    {el.id == state.courseId && permanentLoading ?

                                                                        <>   <Spinner animation="border" variant="warning"/></>
                                                                        :
                                                                        "Delete Forever"


                                                                    }


                                                                </button>
                                                                <button type="button" className="btn border-btn" onClick={(e)=>handleEdit(e,el)}>
                                                                    {el.id == state.courseId && restoreLoading ?

                                                                    <>   <Spinner animation="border" variant="warning"/></>
                                                                      :
                                                                        "Restore"


                                                                    }




                                                                </button>
                                                            </>

                                                    </div>
                                                    {el?.is_selected ?
                                                        <div className="box-overlay1" >

                                                            <div className="overlay-bg">&nbsp;</div>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>


                                            </div>


                                        </Col>
                                    ))
                                    :(
                                        <>
                                        {search  || dateRange[0] || dateRange[1] ?
                                            <span className='noData'>No matching results found</span>
                                            :

                                            <span className='noData text-center'>You have no deleted courses</span>
                                        }
                                        </>
                                    )
                                    

                                }

                            </>


                        )

                    }

                </Row>
                {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response &&
                <Row className='justify-content-center'>
                    <CustomPagination response = {state?.courses} activePage ={activePage} handleNext = {handleNext} handlePrevious={handlePrevious} handleIndex = {handleIndex}/>
                </Row>

                }
            </div>
            <InfoModal
                show={showModal}
                heading={'Delete Course Forever ?'}
                desctiption ={''}
                button_text={'Yes, Continue'}
                type={'del'}
                handleSubmit={(e)=> {handleDeleteCourse(e,course);setShowModal(false)}}
                onHide={() => setShowModal(false)}

            />
            <Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                      <div className="module-modal-header my-4"><h4>Courses are developed and published by you and others in your organization here.</h4></div>
                   
                     <ol className="info-list">
                  
                        <li><span>The “Create New Course” button enables managers to create courses and enroll learners in them.</span></li>
                        <li><span>When a course draft is published, it will appear in the “Published Courses” tab.</span></li>
                        <li><span>Courses can be: 1&#41;	 previewed, 2&#41;	 deleted, or 3&#41;	 cloned.</span></li>
                        <li><span>Published courses cannot be edited. To duplicate a course, clone it, and the course will appear under the “Drafts” tab.</span></li>
                        <li><span>Search for a course using the: 1&#41;	 Created Date, 2&#41;	 Author, 3&#41;	 Category filters, or 4&#41;	 by keyword. </span></li>
                    </ol>
                </Modal.Body>
                

            
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
        </div>
    );
};
export default DeletedCourses;
