import React,{useState} from 'react';
import Button from "react-bootstrap/Button";
import SurveyAssignBox from './../components/surveyassignbox';
import {Link} from 'react-router-dom'
import {useDispatch} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SurveyAssignment = ({match})=>{

    const id = match.params.id;


    return (

        <>

            <div className="course-assign-wrap">
                <ToastContainer  autoClose={2000}/>
                <div className="course-assign-box-container survey-assignbox">
                    <h4 className="course-assign-type-name">Select Groups To Which You Want To Assign This Survey</h4>
                    <SurveyAssignBox id={id} type='group' />
                </div>

            </div>



        </>








    );
};

export default SurveyAssignment
