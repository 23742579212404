import React, {useState, useEffect, useRef} from 'react';
import ScrollArea from 'react-scrollbar';
import ReactPlayer from 'react-player'

const ModuleSeven = (props) => {
    const [state,setState] = useState({
         duration:0,
         completeDuration:0,
        isCompleted:false
    })

    const {value,index,length} = props
      const iRef = useRef(null)
    function parseUrl(val){

        var vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
        var parsed = val.match(vimeoRegex);

        return "//player.vimeo.com/video/" + parsed[1];
    };


 const  embedUrl = (video)=>{
if(video.includes('youtube')){
    let newUrl = video.replace("com/watch?v=","com/embed/");
    let parts =newUrl.split('&')
    return parts[0]
}else if(video.includes('vimeo')){
   return parseUrl(video)
     }     else{
    let newUrl = video.replace("com/","com/embed/");
    return newUrl
}

    }
   const  handleProgress = data => {

       const {isCompleted}   = state
       if(!isCompleted && data.playedSeconds  >  state.duration - 20  ){
           setState({...state,isCompleted: true})
           alert('module completed')
       }

        // We only want to update time slider if we are not currently seeking

    }


   const handleDuration = (duration) => {
            setState({...state,duration,isCompleted: false})

    }

    return (
        <div className="videomodule">

            <ScrollArea
                speed={0.8}
                className="modulemodalscroll"
                contentClassName="content"
                horizontal={false}
            >
                <div className="video-content-wrap">
                <h3 className="module-content-header">{value?.name}</h3>
                    <div className="iframe-responsive">
                        {/*<ReactPlayer*/}
                        {/*    config={{*/}
                        {/*        youtube: {*/}
                        {/*            playerVars: { showinfo: 1 }*/}
                        {/*        },*/}

                        {/*    }}*/}
                        {/*    controls={true}*/}
                        {/*    className='react-player'*/}
                        {/*    url={embedUrl(value?.video)}*/}
                        {/*    width='100%'*/}
                        {/*    height='100%'*/}
                        {/*    onProgress={handleProgress}*/}
                        {/*    onDuration={handleDuration}*/}
                        {/*/>*/}
                        <iframe ref={iRef} width="420" height="315"
                                src={embedUrl(value?.video)}>
                        </iframe>
                    </div>
                    <div className="module-content m-t-20">
                        <p dangerouslySetInnerHTML={{ __html: `${value?.contents ? value?.contents:''}` }}></p>
                    </div>
                </div>
            </ScrollArea>

        </div>
    )

}

export default ModuleSeven;
