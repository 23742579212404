import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Button } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import AvailableQuizzes from "../components/availablequizzes/container";
import DraftCoursesQuizzes from "../components/draftquizzes";
import DeletedQuizzes from "../components/DeletedQuizzes";
function QuizManagement({ match }) {
  let location = useLocation();
  const matchUrl = match.path;
  const history = useHistory();
  const [key, setKey] = useState("AvailableQuizzes");

  const handlePush = () => {
    history.push({
      pathname: "/quizbuilder",
      state: { active: key },
    });
  };
  useEffect(() => {
    if (matchUrl.indexOf("quizzes") > -1) {
      setKey("Drafts");
    } else if (location?.state?.active) {
      setKey(location?.state?.active);
    }
  }, []);

  return (
    <div className="tab-wrapper">
      <Button className="tab-right-button" onClick={handlePush}>
        Create New Quiz
      </Button>
      <Tabs
        activeKey={key}
        id="controlled-tab-example"
        className="m-b-30"
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="AvailableQuizzes" title="Available Quizzes">
          <AvailableQuizzes />
        </Tab>
        <Tab eventKey="Drafts" title="Drafts">
          <DraftCoursesQuizzes />
        </Tab>
        <Tab eventKey="Deleted Quiz" title="Deleted Quizzes">
          <DeletedQuizzes />
        </Tab>
      </Tabs>
    </div>
  );
}
export default QuizManagement;
