import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from "redux-thunk";
// import AppReducer from "../reducers";
import appReducer from "../reducers";
/*global KEY */
/*eslint no-undef: "error"*/

const persistConfig = {
    key: KEY,
    storage
};

const persistedReducer = persistReducer(
    persistConfig,
    appReducer
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeFunction = process.env.NODE_ENV == 'development' ? composeEnhancers : compose;

function configureStore(initialState) {
    const enhancer = composeFunction(applyMiddleware(thunkMiddleware));
    return createStore(persistedReducer, initialState, enhancer);
}

export const store = configureStore({});
export const persistor = persistStore(store);
