import React, {useEffect, useState} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QuestionIcon from '~/assets/images/icons/question.svg';
import fileIcon from '~/assets/images/icons/test.svg';
import CalendarIcon from '~/assets/images/icons/calendar-ico.svg';
import { Button } from "react-bootstrap";
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { learnerSurveyDetail } from '~/modules/surveys/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "react-bootstrap/Spinner";
function LearnerSurveyDetail({match}) {
    const dispatch = useDispatch();
    const history=useHistory()
    const response = useSelector(state => state.surveys.learner_surveys_detail?.response);
    const loading = useSelector(state => state.surveys.learner_surveys_detail?.loading);
    const [survey, setSurvey] = useState(response)
    const id = match.params.id
    const uuid = match.params.uuid
    const [surveyQuestionsCount, setSurveyQuestions] = useState(0)

    useEffect(()=>{
        dispatch(learnerSurveyDetail(id,uuid,function(error){
            if(error){
                history.push('/linkexpired')
            }


        }))
    },[])

    useEffect(() => {

        
        setSurvey(response);        
        let demographic_questions=(response?.demographic_questions && response?.demographic_questions?.length)? response?.demographic_questions :[]
        let general_questions=((response?.general_questions && response?.general_questions?.length)? response?.general_questions :[])
        
        const ques = demographic_questions.concat(general_questions)
         
       
        setSurveyQuestions(ques?.length)
          
    }, [response]);

    if(loading){
        return (

            <div className='loaderQuizzes'>
                <Spinner animation="border" variant="danger"/>
            </div>
        )

    }else {


    return (
        <div className="survey-details-wrap">
            <ToastContainer  autoClose={2000}/>
            <Row className="justify-content-center">
                <Col xl={9} lg={8}><h3 className="tab-title">{survey?.name}</h3></Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={9} lg={8}>
                    <div className="whitebox shadowbox survey-detail-box">
                        <div className="survey-detail-box-header d-flex justify-content-between">
                            {/*<h4 className="sub-title">Group Name</h4>*/}
                            <div className="survey-detail-box-header-right d-flex">
                                <div className="survey-header-details">
                                    <label>Created on</label>
                                    <span>{survey?.created_date}</span>
                                </div>
                                <div className="survey-header-details">
                                    <label>Created by </label>
                                    <span>{survey?.creator_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="survey-detail-box-body">
                            <div className="acc-content-header d-flex">

                                <div className="acc-header-box">
                                    <img src={QuestionIcon} /><span className="acc-header-box-cont">{surveyQuestionsCount} Questions</span>
                                </div>
                                {/*<div className="acc-header-box">*/}
                                {/*    <img src={fileIcon} /><span className="acc-header-box-cont">Multiple Choice</span>*/}
                                {/*</div>*/}
                                <div className="acc-header-box">
                                    <img src={CalendarIcon} /><span className="acc-header-box-cont">Due Date :{survey?.expiry_date?survey?.expiry_date:'No due date mentioned'}</span>
                                </div>
                            </div>
                            <div className="acc-content-desc">
                                <p>
                                    {survey?.description}
                                </p>
                            </div>
                        </div>
                        <div className="btn-wrap d-flex justify-content-end m-t-20">
                            {id &&  id ?
                                <Link to={`/survey/${id}`}><Button type="button">Start</Button></Link>
                            :
                                <Link to={`/submit/survey/${uuid}`}><Button type="button">Start</Button></Link>
                            }

                        </div>
                    </div>
                </Col>
               
            </Row>
        </div>
    );
 }
};
export default LearnerSurveyDetail;
