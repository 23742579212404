import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  signInWithWorkOs,
  verifyWorkOsProfile,
  verifyEdLinkProfile,
  verifySubDomain,
  signInWithEdLink,
  passwordLessSignIn,
  passwordLessWithOTP
} from "../../services";
import { Link, useHistory, useLocation } from "react-router-dom";
import { redirectUser } from "../../../../shared/utils/checkHost";
import {clearState } from "~/modules/auth/services";
import {ToastContainer} from 'react-toastify'
import SignInWithMagicLink from "../../firsttimelogin/components/magicLink Popup";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

function OtpLogin({signUpEmail}) {
   
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [learner, setLearner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { user, loading, error, signUpError } = useSelector(
    (state) => state.auth
  );
  const [checkDomain, setCheckDomain] = useState(null);
  const loadingSSO = useSelector(
    (state) => state?.auth?.signin_workos?.loading
  );
  const loadingEdLink = useSelector(
    (state) => state?.auth?.signin_edlink?.loading
  );
  const response = useSelector(
    (state) => state.auth?.verify_subdomain?.response
  );
  
  
 
  const userloading = useSelector((state) => state.auth?.userDetails?.loading);
  const subdomainLogo = useSelector(
    (state) => state?.auth?.verify_subdomain?.response?.logo_url || null
  );
  const selectedLogo = useSelector(
    (state) => state.settings.selectedLogo || ""
  );
  const queryString = location.search;
  const host = window.location.host;
  const params = new URLSearchParams(queryString);

  const url = window.location.href;
  const ssoToken = url.includes('sso')
  const edlinkToken = url.includes('edlink')

  useEffect(() => {
    setCheckDomain(response);
  }, [response]);

  useEffect(() => {
    if (subdomainLogo) {
      localStorage.setItem("MainLogo", `${subdomainLogo}`);
    }
  }, [subdomainLogo]);

  useEffect(() => {
    if (selectedLogo) {
      localStorage.setItem("MainLogo", `${selectedLogo}`);
    }
  }, [selectedLogo]);


  useEffect(() => {
    if(user){
      redirectUser(user, history, userloading);
    }
  }, [user]);




   useEffect(() => {
   //  const url = window.location.href;
   //  const ssoToken = url.includes('sso')
    if (params?.get("code")?.length && ssoToken) {
      let code = params.get("code");
      let state = params.get("state");
      dispatch(
        verifyWorkOsProfile(code, state, function (res) {
          if (res) {
          }
        })
      );
      setTimeout(() => {
        dispatch(clearState("error"));
      }, 3000);
    } else if (params?.get("error")?.length) {
      history.push("/login");
      toast.error("Access denied!");
    }
  }, [ssoToken]);

  useEffect(() => {
    
    if (params?.get("code")?.length && edlinkToken ) {
      let code = params.get("code");
      let state = params.get("state");
      dispatch(verifyEdLinkProfile(code,state, history, '','')
      );
      setTimeout(() => {
        dispatch(clearState("error"));
      }, 3000);
    } else if (params?.get("error")?.length) {
      history.push("/login");
      toast.error("Access denied!");
    }
  }, [edlinkToken]);


    
     

    const handleSubmit = async (values) => {
      dispatch(passwordLessSignIn(values, history,() => {
        dispatch(clearState("error"));
      }));

    }


    const handleSSO = () => {
      dispatch(signInWithWorkOs());
    };

  

  return (
    <> 
    {/* <div className="auth-page-wrap otp-form-wrap">
      <div className="auth-page-box d-flex">
        <div className="auth-right d-flex justify-content-center align-items-center">
          <div className="auth-cont-box">
            <div className="auth-cont-box-header text-center">
              <div className="auth-logo">
                <img src={Logo} alt="" />
              </div>
              <h2 className="auth-title">Welcome Back!</h2>
              <p className="auth-desc">
                Enter your email address and click on continue
              </p>
            </div> */}
            {/* <div className="auth-form-box white-box radius-box"> */}
              <Formik
                validationSchema={SignInSchema}
                onSubmit={handleSubmit}
                initialValues={{
                  email: signUpEmail || "",
                  // password: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    {/* ///////////// */}
                    <div className="form-input-wrap">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={values.email}
                        onChange={handleChange}
                        disabled={signUpEmail? true: false}
                        isInvalid={!!errors.email}
                      />
                      {/* <p className='email-error'><span><img src={ErrorImg} /></span>No account associated with this email id</p> */}
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </div>
                    <div className="button-wrap">
                      <Button type="submit" className="w-100">
                        Continue
                      </Button>
                    </div>
                    {/* <div className="edlinkworkos-btn-wrap"> */}


                  {/* <button className="btn edlinkworkos-btn w-100">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.317"
                        height="15.396"
                        viewBox="0 0 21.317 15.396"
                      >
                        <path
                          id="Path_8754"
                          data-name="Path 8754"
                          d="M42,24.35l-7.7-7.7-2.961,2.961-2.961-2.961-7.7,7.7,7.7,7.7,2.961-2.961,2.961,2.961ZM28.384,29.087,23.647,24.35l4.737-4.737,1.48,1.48L26.607,24.35l3.257,3.257Zm5.921-9.474,4.737,4.737-4.737,4.737L29.568,24.35Z"
                          transform="translate(-20.686 -16.652)"
                          fill="#fff"
                        />
                      </svg>
                    </span>
                    Login using Edlink
                  </button> */}

             {
            //  checkDomain?.is_edlink_linked && (<div className="social-login-wrap">
            //    <button
            //       className="social-login-btn google-login"
            //       onClick={handleEdLinkSignIn}
            //     >
            //       <span></span>
            //       {loadingEdLink && loadingEdLink ? (
            //         <Spinner animation="border" variant="danger" />
            //       ) : (
            //         "Sign In With EdLink"
            //       )}
            //     </button>
            //     {/*<p className='mt-2' onClick={handlMagicModal}> <span className="forgot-password-text">Login with Magic Link</span></p>*/}
            //     {/*<button className="social-login-btn google-login" ><span><img src={GoogleIcon} alt="" /></span>Sign In using Google</button>*/}
            //   </div>)
               }


{
              
              checkDomain?.is_workos_linked &&
              
              ( 
                <div className="social-login-wrap">
                  <button
                    className="social-login-btn google-login"
                    onClick={handleSSO}
                  >
                    <span></span>
                    {loadingSSO && loadingSSO ? (
                      <Spinner animation="border" variant="danger" />
                    ) : (
                      "Sign In With WorkOs"
                    )}
                  </button>


                  {/*<p className='mt-2' onClick={handlMagicModal}> <span className="forgot-password-text">Login with Magic Link</span></p>*/}
                  {/*<button className="social-login-btn google-login" ><span><img src={GoogleIcon} alt="" /></span>Sign In using Google</button>*/}
                </div>
            ) 
               }  


      <SignInWithMagicLink show={show} onHide={() => setShow(false)} />




                {/* </div> */}
                  
                  
                  </Form>
                )}
              </Formik>
            {/* </div> */}
          {/* </div>
        </div>
      </div>
    </div> */}
    </>
  );
}
const SignInSchema = yup.object().shape({
    // password: yup
    //   .string()
    //   .required("Passord is Required field")
    //   .min(8, "Password is too short - should be 8 chars minimum."),
    // .matches(/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Password must include one upper case, and at least one number.'),
    email: yup.string().email('Please enter a valid email').required("Email is Required field"),
  });
export default OtpLogin;
