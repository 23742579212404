
import React, { useEffect, useState } from 'react';

import Companylogo from "~/assets/images/logo-name-svg.png";

import { Formik, Field, Form } from "formik";
import FormikControl from '../container/surveyFormikControl/container/index'
import * as Yup from "yup";
import Button from 'react-bootstrap/Button';
import { Link, useHistory } from 'react-router-dom';
import { submitSurvey } from '~/modules/surveys/services';
import { useDispatch } from "react-redux";
import {
    
    getSurvey
    
} from '~/modules/surveys/services';
import Item from 'antd/lib/list/Item';


function TabModules(props) {
    const dispatch = useDispatch();
    const history = useHistory()
    const { values } = props
    const [state, setState] = useState({
        key: 0

    });

    let shape = {};
    let initVal = {}

    for (let i = 0; i < values?.questions?.length; i++) {
        if (values?.questions[i]?.question_id) {
            shape[values?.questions[i]?.question_id] = values?.questions[i]?.mandatory ? Yup.string().required() : Yup.string();
            initVal[values?.questions[i]?.question_id] = ''
        }
    }
    const schema = Yup.object().shape(shape);
    const [initialValues, setInitialValues] = useState({})

    useEffect(() => {
        setInitialValues(initVal)

    }, [values])

    const handleSubmit = async (values) => {
       
       
        const val = JSON.stringify(values)
        dispatch(submitSurvey(val, props?.survey_uuid, function (response) {
            if (response) {
                // dispatch(getSurvey('', '',  '' ,  '' , '' , '' , '',));
                setTimeout(() => {

                    history.push({
                        pathname: '/surveycomplete',
                        state: props?.values?.name
                    });
                    
                }, 200);
              
            }


        }))


    };
    return (
        <div className="survey-link-wrap">
            <div className="survey-header">

                {
                    localStorage.getItem("surveydisplay") ?

                        <img src={localStorage.getItem("surveydisplay")} alt="" /> :

                        <img alt="logo" src={props.logo ? props.logo : Companylogo} />
                }
                {/* <img  alt="logo"  src={props.logo ? props?.logo :Companylogo }  /> */}
            </div>

            <div className="survey-body">

                <h2 className="survey-title">{props?.values?.name}</h2>



                <Formik
                    validationSchema={schema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    initialValues={initialValues ? initialValues : {}}

                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        touched,
                        setFieldValue
                    }) => {

                        return (
                            <Form >
                                <div className="whitebox shadow-box">
                                    <div className="radio-block-main-wrap">



                                        {props.values?.questions && props.values?.questions?.length && props.values?.questions?.map((value, index) => (
                                            <FormikControl
                                                key={value?.question_id}
                                                quesId={value?.question_id ? value?.question_id : 0}
                                                quesIndex={index}
                                                control={value?.question_type ? value?.question_type : 'No luck'}
                                                name={value?.question}
                                                quesNumber={Number(index) + 1}
                                                mandatory={value?.mandatory}
                                                errorName={value?.question_id}
                                                options={value?.question_type == 'free_response' ? value?.free_response?.answer : value?.multiple_choices?.filter((el) => el?.choice?.length > 0)}
                                                errors={errors}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                touched={touched}
                                            />

                                        ))}







                                    </div>
                                </div>
                                <div className="button-wrap double-btn d-flex justify-content-end m-t-20"> <Button variant="primary" className="btn-m" type="submit">Submit</Button> </div>

                            </Form>
                        )
                    }}
                </Formik>










                {/* <br></br> <br></br> <div className="button-wrap d-flex justify-content-end"><Link to={{ pathname: '/surveyComplete', state: props?.values?.name }}><Button >submit</Button></Link></div> */}

            </div>


        </div>
    );
}

export default TabModules;