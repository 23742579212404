import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import { PageItem } from "react-bootstrap";
import FilterImg from "~/assets/images/icons/filter.svg";
import SearchImg from "~/assets/images/icons/magnifiying-glass.svg";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ScrollArea from "react-scrollbar";
import Spinner from "react-bootstrap/Spinner";
import { getManagersList } from "../../../surveys/services";
import {
  getMembersList,
  //handlePreviousPagination,
  //handlePagination,
  removeManager,
  assignManager,
  removeMember,
  removeMemberBulk,
  getSelectedMembers,
  resendEmail,
  handlePaginationTeamsMembers,
  handlePreviousPaginationTeamsMembers,
  checkMemberCount
} from "../../services";
import { set } from "date-fns";
import { getNotifications } from "../../../Notifications/services";
import { resetStore, logOut } from "../../../auth/services";
import { ToastContainer, toast } from "react-toastify";
import { SurveyPropertyDefaultRowValueEditor } from "survey-creator";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      className="custom-modal confirmation-popup delete-confirmation inner-popup"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content-wrap text-center">
          <h3 className="modal-title">Delete Member?</h3>
          {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
          <div className="d-flex justify-content-center buttons-wrap double-btn">
            <button
              type="button"
              className="btn border-btn white-btn"
              onClick={() => props.onHide()}
            >
              No, I need to recheck
            </button>
            <Link className="m-l-20">
              <Button onClick={props.handleSubmit} type="button">
                Yes, Continue
              </Button>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
function MyConfirmDeleteModal(props) {
  return (
    <Modal
      className="custom-modal confirmation-popup delete-confirmation inner-popup"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content-wrap text-center">
          <h3 className="modal-title">Delete Member?</h3>
          {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
          <div className="d-flex justify-content-center buttons-wrap double-btn">
            <button
              type="button"
              className="btn border-btn white-btn"
              onClick={() => props.onHide()}
            >
              No, I need to recheck
            </button>
            <Link className="m-l-20">
              <Button onClick={props.handleSubmit} type="button">
                Yes, Continue
              </Button>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Enrollasownermodal(props) {
    return (
      <Modal
        className="custom-modal confirmation-popup delete-confirmation inner-popup"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-content-wrap text-center">
            <h3 className="modal-title">Are you sure you want to enroll this user as a co-owner ?</h3>
             <p>Enrolling someone as co-owner gives them all the owner features related to subscriptions, etc <br/> Remember - You can only enroll one user as co-owner</p>
             <p></p>
            <div className="d-flex justify-content-center buttons-wrap double-btn">
              <button
                type="button"
                className="btn border-btn white-btn"
                onClick={() => props.onHide()}
              >
                No, I need to recheck
              </button>
              <Link className="m-l-20">
                <Button onClick={props.handleSubmit} type="button">
                  Yes, Continue
                </Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
}

function RemoveAccessModal(props) {
  return (
    <Modal
      className="custom-modal confirmation-popup delete-confirmation inner-popup"
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content-wrap text-center">
          <h3 className="modal-title">Remove {props.owner ? 'Owner' : 'Manager'} Access ?</h3>
          {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
          <div className="d-flex justify-content-center buttons-wrap double-btn">
            <button
              type="button"
              className="btn border-btn white-btn"
              onClick={() => props.onHide()}
            >
              No, I need to recheck
            </button>
            <Link className="m-l-20">
              <Button onClick={props.handleSubmit} type="button">
                Yes, Continue
              </Button>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Members = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const response = useSelector(
    (state) => state.teams.members_list?.response || []
  );
  const countmember = useSelector((state) => state?.teams?.count_member?.response)
  const [search, setSearch] = useState("");
  const [members, setMembers] = useState(response.results);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [memberType, setMemberType] = useState({ value: "", label: "All" });
  const loading = useSelector((state) => state?.teams?.members_list?.loading);
  const [selectedIdList, setSelectedIdList] = useState([]);
  const [pageReset, setPageReset] = useState(false);
  const [dataProgress, setDataProgress] = useState(false);
  const [rowid, setId] = useState("");
  const [managerSelected, setManagerSelected] = useState(false);
  const [ownercount, setOwnercount] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [state, setState] = useState({
    teamsmembers: response,
    count: response?.count,
    paginationItem: [],
  });
//   const [count, setCount] = useState(0);
  const [selectRows, setSelectRows] = useState([]);
  const [selectrow, setSelectRow] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const handleModel = (values) => {
    if (values) {
      setModalShow(true);
    }
  };

  const [modalAccess, setModalAccess] = React.useState(false);
  const [modalAccessOwner, setModalAccessOwner] = React.useState(false);
  

  const [modalAssignOnwer, setModalAssignOnwer] = React.useState(false);
  
  const handleAccessModel = (values) => {
    if (values) {
      setModalAccess(true);
    }
  };

  const [modalConfirm, setModalConfirm] = React.useState(false);
  const ConfirmDeleteModal = (values) => {
    if (values) {
      setModalConfirm(true);
    }
  };
  const options_admin = [
    { value: "", label: "All" },
    { value: "admin", label: "Admin" },
    { value: "manager", label: "Owner" },
    { value: "staff_manager", label: "Account Manager" },
    { value: "learner", label: "Learner" },
  ];
  const options = [
    { value: "", label: "All" },
    { value: "manager", label: "Owner" },
    { value: "staff_manager", label: "Account Manager" },
    { value: "learner", label: "Learner" },
  ];
  useEffect(() => {
    setSearch("");
    setMemberType( { value: "", label: "All" });
    dispatch(getMembersList(user?.token, '', '',''));
    setActivePage(1);
    setMembers(response.results);
    setPageReset(true);
    // dispatch(checkMemberCount());
  }, []);


  useEffect(() => {
    setMembers(response.results);
    dispatch(checkMemberCount());
  }, [response.results]);


  useEffect(() => {
    if(countmember?.owner_count){
      setOwnercount(countmember.owner_count);
    }
   
  }, [countmember]);
  
 

  useEffect(() => {
    if (props.handleChild) {
      setToggledClearRows(!toggledClearRows);
      setSelectedIdList([]);
    }
  }, [props.handleChild]);

//   useEffect(() => {
//     if (count > 0 && dateRange[0] == null) {
//       setActivePage(1);
//       dispatch(getMembersList());
//     }
//   }, [count]);

  useEffect(() => {
    props.setmemeberActivePage({activePage,search, memberType:memberType?.value? memberType?.value : '' })
  }, [activePage, search, memberType]);

  

  useEffect(() => {
    const count = response?.count;
    const perPage = 12;
    const buttonsCount = Math.ceil(count / perPage);
    let items = [];
    for (let number = 1; number <= Number(buttonsCount); number++) {
      items.push(
        <Pagination.Item
          onClick={() => handleIndex(number)}
          key={number}
          active={number == activePage}
        >
          {number}
        </Pagination.Item>
      );
    }
    setState({
      ...state,
      teamsmembers: response,
      count: response?.count,
      paginationItem: items,
    });
  }, [response]);

  const handleIndex = (index) => {
    if (index == 1) {
      dispatch(getMembersList(user?.token, search, memberType.value));
    } else {
      dispatch(getMembersList(user?.token, search, memberType.value, index));
      //dispatch(getMembersList( search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, index));
    }
    setActivePage(index);
    // dispatch(getMembersList(user?.token, "", "", index));
    // setActivePage(index);
  };

  const handleMembersFilter = (e) => {
     setActivePage(1);
    if (e.value != "all") {
      setMemberType(e);
      dispatch(getMembersList(user?.token, search, e.value));
      setMembers(response);
    }
    if (e.value == "all") {
        setMemberType( { value: "", label: "All" });
      var filter = "";
      dispatch(getMembersList(user?.token, search, filter));
    }
  };

  useEffect(() => {
    if (loading) {
      setDataProgress(true);
    }
    if (!loading) {
      setDataProgress(false);
    }
  }, [loading]);

  const openCreateGroup = () => {
    props.parentCallback(true);
    setToggledClearRows(!toggledClearRows);
  };

  const handleNext = () => {
    // if (response?.next)
    if (state.teamsmembers?.next) {
      var regex = /page=\d+/g;
      const [number] = state.teamsmembers?.next?.match(regex);
      setActivePage((prevVal) =>
        prevVal === state.paginationItem ? prevVal : prevVal + 1
      );
      dispatch(handlePaginationTeamsMembers(state.teamsmembers?.next));
      //dispatch(handlePaginationTeamsMembers(response?.next));
    } else {
      return false;
    }
  };

  const handlePrevious = () => {
    // if (response?.previous)
    if (state.teamsmembers?.previous) {
      setActivePage((prevVal) => (prevVal - 1 === 0 ? prevVal : prevVal - 1));
      // dispatch(handlePreviousPagination(response?.previous));
      dispatch(
        handlePreviousPaginationTeamsMembers(state.teamsmembers?.previous)
      );
    } else {
      return false;
    }
  };

  // const removeManagerAccess=()=>{
  //    setModalAccess(false)
  //    dispatch( removeManager(user?.token,selectedIdList))
  //    setTimeout(() => {
  //    dispatch(getMembersList(user?.token,search,memberType))
  // }, 1000)
  // ModalExit()
  // dispatch(getManagersList());
  //     dispatch(getNotifications())
  //
  // }

  const removeManagerAccess = () => {
    if (managerSelected == true) {
      toast.error("Team Manager  Cannot Be Changed");
      ModalExit();
      setModalAccess(false);
    } else {
      if (
        user?.user_id &&
        selectedIdList.length &&
        selectedIdList.find((el) => el.id == user?.user_id)
      ) {
        setModalAccess(false);
        dispatch(removeManager(user?.token, selectedIdList));
        setTimeout(() => {
          let action = "USER_LOGOUT";
          history.push("/");
          dispatch(resetStore(action));
        }, 100);
      } else {
        setModalAccess(false);
        dispatch(removeManager(user?.token, selectedIdList));
        const pgnumber = response?.results?.length > selectedIdList.length ?  activePage : activePage > 1 ? activePage - 1 : '' ;
        const page = memberType?.value === 'staff_manager' ? pgnumber : activePage;
        setTimeout(() => {
          dispatch(getMembersList(user?.token, search, memberType?.value, page));
          setActivePage(page); 
        }, 2000);
        ModalExit();
        dispatch(getManagersList());
        dispatch(getNotifications());
      }
    }
  };

  const ModalExit = () => {
    setSelectedIdList([]);
    dispatch(getSelectedMembers([]));
    setToggledClearRows(!toggledClearRows);
  };

  const assignManagerAccess = () => {
    if (managerSelected == true) {
      toast.error("Team Manager Cannot Be Changed");
      ModalExit();
    } else {
      dispatch(assignManager(user?.token, selectedIdList));
      const pgnumber = response?.results?.length > selectedIdList.length ?  activePage : activePage > 1 ? activePage - 1 : '' ;
      const page = memberType?.value === 'learner' ? pgnumber : activePage;
      setTimeout(() => {
        dispatch(getMembersList(user?.token, search, memberType?.value, page));
        setActivePage(page); 
        dispatch(getNotifications());
      }, 2000);
      ModalExit();
      dispatch(getManagersList());
      dispatch(getNotifications());
    }
  };

  const removeManagerSingle = (rid) => {
    const idobj = [];
    idobj.push({ id: rid });
    dispatch(removeManager(user?.token, idobj));
    const pgnumber = response?.results?.length > 1 ?  activePage : activePage > 1 ? activePage - 1 : '' ;
    const page = memberType?.value === 'staff_manager' ? pgnumber : activePage;
    setTimeout(() => {
      dispatch(getMembersList(user?.token, search, memberType?.value, page));
      setActivePage(page); 
      dispatch(getNotifications());
    }, 2000);
    dispatch(getManagersList());
  };

  const assignManagerSingle = (rid) => {
    const idobj = [];
    idobj.push({ id: rid });
    dispatch(assignManager(user?.token, idobj));
    const pgnumber = response?.results?.length > 1 ?  activePage : activePage > 1 ? activePage - 1 : '' ;
    const page = memberType?.value === 'learner' ? pgnumber : activePage;
    setTimeout(() => {
      dispatch(getMembersList(user?.token, search, memberType?.value, page));
      setActivePage(page); 
      dispatch(getNotifications());
    }, 2000);
    dispatch(getManagersList());
  };

  const deleteMemberBulk = () => {
    const pgnumber = response?.results?.length > selectedIdList.length ?  activePage : activePage > 1 ? activePage - 1 : '';
    const deletecb = () => {
      dispatch(getMembersList(user?.token, search, memberType?.value, pgnumber));
      setActivePage(pgnumber); 
      dispatch(getNotifications());
    }
    dispatch(removeMemberBulk(user?.token, selectedIdList,deletecb));
    setModalShow(false);
    ModalExit();
    
  };

  const deleteSingleMember = (id) => {
    const pgnumber = response?.results?.length > 1 ? activePage : activePage > 1 ? activePage - 1 : '';
    dispatch(removeMember(user?.token, id));
    setTimeout(() => {
      dispatch(getMembersList(user?.token, search, memberType?.value, pgnumber));
      setActivePage(pgnumber); 
      dispatch(getNotifications());
     // setActivePage(1); 
    }, 2000);
   
    setModalShow(false);
    ModalExit();
  };

  const memberDelete = (id) => {
    dispatch(removeMember(user?.token, id));
    setTimeout(() => {
      //  dispatch(getManagerQuizzesList());
      dispatch(getMembersList(user?.token, search, memberType?.value));
      dispatch(getNotifications());
    }, 2000);

    setModalConfirm(false);
    ModalExit();
  };

  const searchInput = (e) => {
    setSearch(e.target.value);
    setActivePage(1);
    dispatch(getMembersList(user?.token, e.target.value, memberType?.value));
  };

  const handleSelect = (state) => {
    var len = state.selectedRows?.length;

    let obj = state.selectedRows.find((o) => o.user_type === "manager");
    if (obj) {
      setManagerSelected(true);
    } else {
      setManagerSelected(false);
    }
    const selectedIds = [];
    const rows = [];
    const row = [];
    for (var i = 0; i < len; i++) {
      selectedIds.push({
        id: state.selectedRows[i].id,
      });
    }

    for (var i = 0; i < len; i++) {
      rows.push({
        id: state.selectedRows[i].id,
        get_full_name:
          state.selectedRows[i].get_full_name.length > 1
            ? state.selectedRows[i].get_full_name
            : state.selectedRows[i].id,
      });
    }

    setSelectRows(rows);

    //setSelectRows(row);
    setSelectedIdList(selectedIds);

    //for creating group

    dispatch(getSelectedMembers(rows));
  };

  const reset = () => {
    setSearch("");
    setMemberType( { value: "", label: "All" });
    setTimeout(() => {
      dispatch(getMembersList(user?.token, "", ""));
      setActivePage(1);
    }, 200);

    setMembers(response.results);
  };

  const rowName = (row) => {
    if (row?.get_full_name) return row?.get_full_name;
    else {
      return row.email;
    }
  };

  const resend_Email = (ide) => {
    if (ide) {
      const a = [];
      const i = {};
      i.id = ide;
      a.push(i);

      dispatch(resendEmail(a));
    } else {
      dispatch(resendEmail(selectedIdList));
    }
    // setTimeout(() => {
    //     dispatch(getMembersList(user?.token, search, memberType));

    // }, 1000);
    ModalExit();
  };

  const tableColumns = [
    {
      name: 'Member name',
      selector: 'get_full_name', 
      sortable: true,  
      center: false,
      cell: (row) => (
        // <Link to={`/memberdetails/${row.id}`}>{rowName(row)}</Link>
        <Link to={`/memberdetails/${row.id}`}>{rowName(row)}</Link>
      ),
      accessor: "",
      

      grow: 3,
    },
    {
      name: "Email address",
      selector: "email",
      sortable: true,
      center: false,
      grow: 3,
    },
    {
      name: "Role",
      selector: "get_user_type_display",
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: "Employee ID",

      cell: (row) => <> {row.employee_id ? row.employee_id : "--"}</>,
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: "Action",
      sortable: false,
      center: false,
      grow: 2,
            cell: (row, index) => (
                <div data-tag="allowRowEvents" className="d-flex">

                    {
                        (user && user?.user_type == 'admin') ? (
                            <>
                                {((row.user_type == "admin")) ? (
                                    ""
                                ) : (
                                    <div className="action-icons-cell-wrap d-flex">
                                        <div
                                            className="action-icon-item fill-icon"
                                            //onClick={(e) => deleteSingleMember(row.id)}
                                            //onClick = {(e) =>  {setModalConfirm(true);memberDelete(row.id); setSelectRow(row)}}
                                            //onClick = {(e) =>  {setModalConfirm(true);  setSelectRows(rows) ; setSelectedIdList(selectedIds)}}
                                            onClick={(e) => {
                                                setModalConfirm(true);
                                                setSelectRow(row.id);
                                            }}
                                        >
                                            <OverlayTrigger
                                                overlay={<Tooltip id="InfoText">Delete</Tooltip>}
                                            >
                                                <svg
                                                    id="icons_Q2"
                                                    data-name="icons Q2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="17.49"
                                                    height="19.331"
                                                    viewBox="0 0 17.49 19.331"
                                                >
                                                    <path
                                                        id="Path_8323"
                                                        data-name="Path 8323"
                                                        d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                                                        transform="translate(-4.999 -3)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        id="Path_8324"
                                                        data-name="Path 8324"
                                                        d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                                                        transform="translate(-7.261 -9.477)"
                                                        fill="#fff"
                                                    />{" "}
                                                </svg>
                                            </OverlayTrigger>
                                        </div>
                                        <div
                                            className="action-icon-item fill-icon big-black-icon resendico"
                                            onClick={(e) => resend_Email(row.id)}
                                        >
                                            <OverlayTrigger
                                                overlay={<Tooltip id="InfoText">Resend Invitation</Tooltip>}
                                            >
                                                <svg
                                                    id="resend_invitaion"
                                                    data-name="resend invitaion"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <rect
                                                        id="Rectangle_3313"
                                                        data-name="Rectangle 3313"
                                                        width="24"
                                                        height="24"
                                                        fill="none"
                                                    />
                                                    <g
                                                        id="Group_7278"
                                                        data-name="Group 7278"
                                                        transform="translate(0 0.453)"
                                                    >
                                                        <g
                                                            id="Group_7276"
                                                            data-name="Group 7276"
                                                            transform="translate(5.25 16.66)"
                                                        >
                                                            <path
                                                                id="Path_8470"
                                                                data-name="Path 8470"
                                                                d="M15.519,66.836a.576.576,0,0,1-.57-.5l-.363-2.574a.576.576,0,0,1,.49-.651l2.578-.365a.576.576,0,0,1,.161,1.141l-2.007.284.282,2a.577.577,0,0,1-.571.657Z"
                                                                transform="translate(-14.581 -62.744)"
                                                            />
                                                            <path
                                                                id="Path_8471"
                                                                data-name="Path 8471"
                                                                d="M21.909,67.753q-.431,0-.864-.035a10.642,10.642,0,0,1-6.273-2.7.576.576,0,1,1,.771-.856,9.525,9.525,0,0,0,11.5.93.576.576,0,0,1,.624.969,10.64,10.64,0,0,1-5.762,1.7Z"
                                                                transform="translate(-14.581 -63.645)"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_7277"
                                                            data-name="Group 7277"
                                                            transform="translate(5.393 2.326)"
                                                        >
                                                            <path
                                                                id="Path_8474"
                                                                data-name="Path 8474"
                                                                d="M53.048,7.147A.576.576,0,0,1,52.968,6l2.007-.284-.282-2a.576.576,0,0,1,1.141-.161L56.2,6.125a.576.576,0,0,1-.49.651l-2.578.365A.582.582,0,0,1,53.048,7.147Z"
                                                                transform="translate(-42.848 -3.039)"
                                                            />
                                                            <path
                                                                id="Path_8475"
                                                                data-name="Path 8475"
                                                                d="M31.848,6.742a.574.574,0,0,1-.385-.148,9.525,9.525,0,0,0-11.5-.93.576.576,0,1,1-.624-.969,10.653,10.653,0,0,1,12.9,1.043.576.576,0,0,1-.386,1Z"
                                                                transform="translate(-19.07 -2.999)"
                                                            />
                                                        </g>
                                                        <g
                                                            id="icons_Q2"
                                                            data-name="icons Q2"
                                                            transform="translate(6.766 7.558)"
                                                        >
                                                            <path
                                                                id="Path_8465"
                                                                data-name="Path 8465"
                                                                d="M11.854,7H2.469A.469.469,0,0,0,2,7.469v7.039a.469.469,0,0,0,.469.469h9.385a.469.469,0,0,0,.469-.469V7.469A.469.469,0,0,0,11.854,7Zm-.469,7.039H2.938V9.276l4.106,2.417a.211.211,0,0,0,.235,0l4.106-2.487ZM7.279,10.613a.211.211,0,0,1-.235,0L2.938,7.938h8.446Z"
                                                                transform="translate(-2 -7)"
                                                                stroke="#000"
                                                                stroke-width="0.3"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </OverlayTrigger>
                                        </div>
                                        {row.user_type == "learner" ? (
                                            <>
                                                <div
                                                    className="action-icon-item fill-icon big-black-icon enroll-mangr"
                                                    onClick={(e) => assignManagerSingle(row.id)}
                                                //onClick = { (e) => setModalAccess(true) }
                                                >
                                                    <OverlayTrigger
                                                        overlay={<Tooltip id="InfoText">Enroll As Manager</Tooltip>}
                                                    >
                                                        <svg
                                                            id="enroll_as_manager"
                                                            data-name="enroll as manager"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <rect
                                                                id="Rectangle_3313"
                                                                data-name="Rectangle 3313"
                                                                width="24"
                                                                height="24"
                                                                fill="none"
                                                            />
                                                            <g id="Group_7280" data-name="Group 7280">
                                                                <g
                                                                    id="Rectangle_3318"
                                                                    data-name="Rectangle 3318"
                                                                    transform="translate(4 3)"
                                                                    fill="none"
                                                                    stroke="#000"
                                                                    stroke-width="1.3"
                                                                >
                                                                    <rect width="16" height="18" rx="2" stroke="none" />
                                                                    <rect
                                                                        x="0.65"
                                                                        y="0.65"
                                                                        width="14.7"
                                                                        height="16.7"
                                                                        rx="1.35"
                                                                        fill="none"
                                                                    />
                                                                </g>
                                                                <g
                                                                    id="icons_Q2"
                                                                    data-name="icons Q2"
                                                                    transform="translate(5.771 3.77)"
                                                                >
                                                                    <g
                                                                        id="Group_7279"
                                                                        data-name="Group 7279"
                                                                        transform="translate(2 2)"
                                                                    >
                                                                        <path
                                                                            id="Path_8467"
                                                                            data-name="Path 8467"
                                                                            d="M17.538,10a1.538,1.538,0,1,0,1.538,1.538A1.538,1.538,0,0,0,17.538,10Zm0,2.307a.769.769,0,1,1,.769-.769A.769.769,0,0,1,17.538,12.307Z"
                                                                            transform="translate(-13.308 -8.462)"
                                                                            stroke="#000"
                                                                            stroke-width="0.3"
                                                                        />
                                                                        <path
                                                                            id="Path_8468"
                                                                            data-name="Path 8468"
                                                                            d="M6.23,2a4.211,4.211,0,1,0,3,1.233A4.23,4.23,0,0,0,6.23,2ZM3.884,8.768A5.076,5.076,0,0,1,6.23,8.153a5.076,5.076,0,0,1,2.346.615,3.442,3.442,0,0,1-4.691,0Zm5.211-.6h0A5.9,5.9,0,0,0,6.23,7.383a5.9,5.9,0,0,0-2.865.788h0a3.4,3.4,0,0,1-.6-1.942,3.461,3.461,0,0,1,6.922,0,3.4,3.4,0,0,1-.6,1.942Z"
                                                                            transform="translate(-2 -2)"
                                                                            stroke="#000"
                                                                            stroke-width="0.3"
                                                                        />
                                                                    </g>
                                                                </g>
                                                                <line
                                                                    id="Line_190"
                                                                    data-name="Line 190"
                                                                    x2="9"
                                                                    transform="translate(7.5 17.5)"
                                                                    fill="none"
                                                                    stroke="#000"
                                                                    stroke-linecap="round"
                                                                    stroke-width="1.3"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </OverlayTrigger>
                                                </div>
                                            </>
                                        ) : (

                                            // row.user_type == 'owner'
                                            <>

                                                {
                                                    row.user_type == 'manager' ? (
                                                        <>
                                                            <div
                                                                className="action-icon-item fill-icon big-black-icon remove-owner"
                                                                // onClick={(e) => removeManagerSingle(row.id)}
                                                                onClick={(e) => {
                                                                    setModalAccessOwner(true);
                                                                    setSelectRow(row.id);
                                                                }}
                                                            >
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="InfoText">Remove Owner Access</Tooltip>
                                                                    }
                                                                >
                                                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 366.89" {...props}>
                                                                        <defs />
                                                                        <path className="cls-1" d="M384 303.79c-.72 3.39-1.38 6.79-2.16 10.16-8.46 36.39-41.94 62.57-78.58 61.46-39-1.18-71-28.44-77.3-66.25-3.56-21.4 1-41.2 13.38-59.09.5-.72 1-1.42 1.54-2.13l1.67-2.28c-38.05-28.92-80.28-38.62-125.27-23C52.39 245.22 23.47 294 23.52 362.3H0v-22.5c.89-6.16 1.45-12.38 2.71-18.45 11.63-56 44.11-95 96.26-118 .91-.4 1.83-.79 2.73-1.2.08 0 .1-.2.25-.56-32.52-21.11-50.51-50.83-50.63-90-.08-29.56 11.33-54.75 32.86-75C127.11-3.89 194.74-.06 233.62 45.06c39.9 46.28 31.5 119.7-24 155.33a159.6 159.6 0 0149.78 28.35c8.74-3.46 16.69-7.47 25.12-9.77 45.81-12.51 93.05 19.54 98.75 66.61A17.09 17.09 0 00384 288zm-149.5-191.6c-.08-43.89-35.92-79.68-79.71-79.6a79.89 79.89 0 00-79.63 79.7c0 43.91 36 79.71 80.1 79.64 43.45-.07 79.32-36.17 79.24-79.74zm69.41 239.25c30 .52 55.58-24.25 56.07-54.27a55.77 55.77 0 00-54.9-57c-30.16-.47-55.62 24.05-56.17 54.12-.59 31.45 23.63 56.6 55 57.15z" transform="translate(0 -8.56)" />
                                                                        <path className="cls-1" d="M266.92 307.54v-23.49h75v23.49z" transform="translate(0 -8.56)" />
                                                                    </svg>

                                                                </OverlayTrigger>
                                                            </div>

                                                        </>


                                                    ) : (
                                                        <>

                                                            {/* usertype = account manager */}
                                                            <div
                                                                className="action-icon-item fill-icon big-black-icon remove-mangr"
                                                                // onClick={(e) => removeManagerSingle(row.id)}
                                                                onClick={(e) => {
                                                                    setModalAccess(true);
                                                                    setSelectRow(row.id);
                                                                }}
                                                            >
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="InfoText">Remove Manager Access</Tooltip>
                                                                    }
                                                                >
                                                                    <svg
                                                                        id="remove_manager_access"
                                                                        data-name="remove manager access"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <rect
                                                                            id="Rectangle_3313"
                                                                            data-name="Rectangle 3313"
                                                                            width="24"
                                                                            height="24"
                                                                            fill="none"
                                                                        />
                                                                        <path
                                                                            id="Subtraction_1"
                                                                            data-name="Subtraction 1"
                                                                            d="M-19102.154-5670.833h-10.111a.694.694,0,0,1-.7-.691v-3.244l.35-.207a10.725,10.725,0,0,1,5.4-1.454,10.772,10.772,0,0,1,5.4,1.449l.35.211v3.244A.694.694,0,0,1-19102.154-5670.833Zm-5.059-4.213a9.47,9.47,0,0,0-4.279,1.027l-.082.043v1.756h8.729v-1.756l-.082-.043A9.482,9.482,0,0,0-19107.213-5675.046Zm0-3.369a3.644,3.644,0,0,1-3.637-3.642,3.644,3.644,0,0,1,3.637-3.642,3.647,3.647,0,0,1,3.643,3.642A3.647,3.647,0,0,1-19107.213-5678.415Zm0-5.9a2.259,2.259,0,0,0-2.254,2.26,2.256,2.256,0,0,0,2.254,2.255,2.26,2.26,0,0,0,2.26-2.255A2.262,2.262,0,0,0-19107.213-5684.317Zm-6.736,3.373h-5.059a.692.692,0,0,1-.689-.691.694.694,0,0,1,.689-.7h5.059a.7.7,0,0,1,.691.7A.692.692,0,0,1-19113.949-5680.944Z"
                                                                            transform="translate(19122.699 5690.699)"
                                                                        />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </div>
                                                            {/* Button for enroll as owner  */}
                                                            <div
                                                                className={ownercount > 1  ? "action-icon-item fill-icon big-black-icon enroll-owner opacity-50" : "action-icon-item fill-icon big-black-icon enroll-owner "}
                                                                    
                                                                onClick={(e) => {
                                                                    setModalAssignOnwer(true)
                                                                    setSelectRow(row.id);
                                                                }}
                                                            // onClick = { (e) => setModalAccess(true) }
                                                            >
                                                                <OverlayTrigger
                                                                    overlay={<Tooltip id="InfoText">Enroll As Owner</Tooltip>}
                                                                >

                                                                    <svg id="Layer_1" style={{width:'13px'}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.34 383.33" {...props}>
                                                                        <defs />
                                                                        <path className="cls-1" d="M13.42 62.37C14 60 14.48 57.56 15 55.16c6.16-26.24 30.23-45.8 57.22-45.87q87.95-.24 175.9 0c31.86.11 58.31 26.63 58.75 58.45.19 13.59.09 27.19 0 40.79 0 8.89-6.36 15.39-14.83 15.46-8.64.07-15-6.5-15.08-15.6-.06-12.59 0-25.19 0-37.79-.05-18.69-12.81-31.42-31.56-31.43H74.74C56.25 39.18 43.39 52 43.38 70.4v260.8c0 18.47 12.82 31.32 31.26 31.34h101.8c11.55 0 18.86 10.52 14.63 20.74-2 4.91-6.07 7.35-10.68 9.19H66.58a12.74 12.74 0 00-2.06-.68 60.13 60.13 0 01-49.4-44.92c-.61-2.5-1.13-5-1.7-7.55z" transform="translate(-13.42 -9.16)" />
                                                                        <path className="cls-1" d="M256 392.49c-7.44-2-14.72-4.29-21-9.07a44.87 44.87 0 0120-80.15c1.46-.22 2.9-.51 4.55-.8-2.33-15.86-4.65-31.48-6.93-47.11-1.85-12.7-4.21-25.35-5.39-38.11-2.8-30.25 19.48-58.52 49.43-63.45 38.32-6.32 72.48 24 69.89 62.71-.88 13-3.52 25.91-5.4 38.85-2.27 15.63-4.59 31.25-6.93 47.13 2.52.43 4.85.73 7.14 1.23a45 45 0 0135.4 44.28c-.13 21.22-15.32 39.41-36.52 43.73a24.13 24.13 0 00-2.41.78zm50.92-29.95c14.84 0 29.69.06 44.53 0 8.78 0 15.38-6.6 15.34-15s-6.64-14.8-15.46-14.9c-4.62-.05-9.23 0-13.85 0-10.22-.12-16.84-8.06-15.33-18.13 4.85-32.53 9.82-65 14.37-97.63 1.73-12.32-3.07-22.42-13.57-29.14s-21.61-6.7-32.1 0-15.27 16.82-13.56 29.15c4.47 32.08 9.34 64.11 14.09 96.15 1.84 12.42-4.3 19.58-16.94 19.59-4.11 0-8.23-.06-12.35 0a14.95 14.95 0 10-.08 29.89c14.99.1 29.99.02 44.92.02zM118.24 242.78a44.91 44.91 0 11-44.92 45 45.06 45.06 0 0144.92-45zm-.13 30a15 15 0 1015.09 14.82 15 15 0 00-15.09-14.87zM160.56 126h71.08c10.74 0 17.89 9.83 14.58 19.72a14.9 14.9 0 01-14.22 10.2c-7.11.09-14.22 0-21.32 0H89.09c-11 0-18.31-9.75-14.95-19.75 2.12-6.31 7.72-10.16 15-10.17h71.42zM145.23 212.83H88.37a14.77 14.77 0 01-14.64-11.58A14.45 14.45 0 0180.86 185a17.88 17.88 0 017.82-2q56.47-.19 113-.07c8.82 0 15.4 6.49 15.42 14.91s-6.56 15-15.35 15c-18.87.04-37.69-.01-56.52-.01zM205.11 99.05h-26.17c-9.1-.05-15.76-6.4-15.76-15s6.64-15 15.73-15q26.16-.06 52.34 0c9.1 0 15.75 6.38 15.76 14.94S240.37 99 231.28 99c-8.73.09-17.45.05-26.17.05z" transform="translate(-13.42 -9.16)" />
                                                                    </svg>


                                                                </OverlayTrigger>
                                                            </div>

                                                        </>
                                                    )
                                                }
                                            </>

                                        )}
                                    </div>
                                )}

                            </>


                        ) : (
                            <>
                                {

                                    (user && user?.user_type == 'manager') ? (
                                        <>
                                            {(user && user?.is_admin_institution == false) ? (

                                                <>
                                                    {(user && user?.is_real_owner == true) ? (
                                                        <>

                                                            {(row.user_type == "admin") || (user.email == row.email) ? ("") : (

                                                                <div className="action-icons-cell-wrap d-flex">
                                                                    <div
                                                                        className="action-icon-item fill-icon"
                                                                        //onClick={(e) => deleteSingleMember(row.id)}
                                                                        //onClick = {(e) =>  {setModalConfirm(true);memberDelete(row.id); setSelectRow(row)}}
                                                                        //onClick = {(e) =>  {setModalConfirm(true);  setSelectRows(rows) ; setSelectedIdList(selectedIds)}}
                                                                        onClick={(e) => {
                                                                            setModalConfirm(true);
                                                                            setSelectRow(row.id);
                                                                        }}
                                                                    >
                                                                        <OverlayTrigger
                                                                            overlay={<Tooltip id="InfoText">Delete</Tooltip>}
                                                                        >
                                                                            <svg
                                                                                id="icons_Q2"
                                                                                data-name="icons Q2"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="17.49"
                                                                                height="19.331"
                                                                                viewBox="0 0 17.49 19.331"
                                                                            >
                                                                                <path
                                                                                    id="Path_8323"
                                                                                    data-name="Path 8323"
                                                                                    d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                                                                                    transform="translate(-4.999 -3)"
                                                                                    fill="#fff"
                                                                                />
                                                                                <path
                                                                                    id="Path_8324"
                                                                                    data-name="Path 8324"
                                                                                    d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                                                                                    transform="translate(-7.261 -9.477)"
                                                                                    fill="#fff"
                                                                                />{" "}
                                                                            </svg>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div
                                                                        className="action-icon-item fill-icon big-black-icon resendico"
                                                                        onClick={(e) => resend_Email(row.id)}
                                                                    >
                                                                        <OverlayTrigger
                                                                            overlay={<Tooltip id="InfoText">Resend Invitation</Tooltip>}
                                                                        >
                                                                            <svg
                                                                                id="resend_invitaion"
                                                                                data-name="resend invitaion"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                            >
                                                                                <rect
                                                                                    id="Rectangle_3313"
                                                                                    data-name="Rectangle 3313"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    fill="none"
                                                                                />
                                                                                <g
                                                                                    id="Group_7278"
                                                                                    data-name="Group 7278"
                                                                                    transform="translate(0 0.453)"
                                                                                >
                                                                                    <g
                                                                                        id="Group_7276"
                                                                                        data-name="Group 7276"
                                                                                        transform="translate(5.25 16.66)"
                                                                                    >
                                                                                        <path
                                                                                            id="Path_8470"
                                                                                            data-name="Path 8470"
                                                                                            d="M15.519,66.836a.576.576,0,0,1-.57-.5l-.363-2.574a.576.576,0,0,1,.49-.651l2.578-.365a.576.576,0,0,1,.161,1.141l-2.007.284.282,2a.577.577,0,0,1-.571.657Z"
                                                                                            transform="translate(-14.581 -62.744)"
                                                                                        />
                                                                                        <path
                                                                                            id="Path_8471"
                                                                                            data-name="Path 8471"
                                                                                            d="M21.909,67.753q-.431,0-.864-.035a10.642,10.642,0,0,1-6.273-2.7.576.576,0,1,1,.771-.856,9.525,9.525,0,0,0,11.5.93.576.576,0,0,1,.624.969,10.64,10.64,0,0,1-5.762,1.7Z"
                                                                                            transform="translate(-14.581 -63.645)"
                                                                                        />
                                                                                    </g>
                                                                                    <g
                                                                                        id="Group_7277"
                                                                                        data-name="Group 7277"
                                                                                        transform="translate(5.393 2.326)"
                                                                                    >
                                                                                        <path
                                                                                            id="Path_8474"
                                                                                            data-name="Path 8474"
                                                                                            d="M53.048,7.147A.576.576,0,0,1,52.968,6l2.007-.284-.282-2a.576.576,0,0,1,1.141-.161L56.2,6.125a.576.576,0,0,1-.49.651l-2.578.365A.582.582,0,0,1,53.048,7.147Z"
                                                                                            transform="translate(-42.848 -3.039)"
                                                                                        />
                                                                                        <path
                                                                                            id="Path_8475"
                                                                                            data-name="Path 8475"
                                                                                            d="M31.848,6.742a.574.574,0,0,1-.385-.148,9.525,9.525,0,0,0-11.5-.93.576.576,0,1,1-.624-.969,10.653,10.653,0,0,1,12.9,1.043.576.576,0,0,1-.386,1Z"
                                                                                            transform="translate(-19.07 -2.999)"
                                                                                        />
                                                                                    </g>
                                                                                    <g
                                                                                        id="icons_Q2"
                                                                                        data-name="icons Q2"
                                                                                        transform="translate(6.766 7.558)"
                                                                                    >
                                                                                        <path
                                                                                            id="Path_8465"
                                                                                            data-name="Path 8465"
                                                                                            d="M11.854,7H2.469A.469.469,0,0,0,2,7.469v7.039a.469.469,0,0,0,.469.469h9.385a.469.469,0,0,0,.469-.469V7.469A.469.469,0,0,0,11.854,7Zm-.469,7.039H2.938V9.276l4.106,2.417a.211.211,0,0,0,.235,0l4.106-2.487ZM7.279,10.613a.211.211,0,0,1-.235,0L2.938,7.938h8.446Z"
                                                                                            transform="translate(-2 -7)"
                                                                                            stroke="#000"
                                                                                            stroke-width="0.3"
                                                                                        />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    {row.user_type == "learner" ? (
                                                                        <>
                                                                            <div
                                                                                className="action-icon-item fill-icon big-black-icon enroll-mangr"
                                                                                onClick={(e) => assignManagerSingle(row.id)}
                                                                            //onClick = { (e) => setModalAccess(true) }
                                                                            >
                                                                                <OverlayTrigger
                                                                                    overlay={<Tooltip id="InfoText">Enroll As Manager</Tooltip>}
                                                                                >
                                                                                    <svg
                                                                                        id="enroll_as_manager"
                                                                                        data-name="enroll as manager"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                    >
                                                                                        <rect
                                                                                            id="Rectangle_3313"
                                                                                            data-name="Rectangle 3313"
                                                                                            width="24"
                                                                                            height="24"
                                                                                            fill="none"
                                                                                        />
                                                                                        <g id="Group_7280" data-name="Group 7280">
                                                                                            <g
                                                                                                id="Rectangle_3318"
                                                                                                data-name="Rectangle 3318"
                                                                                                transform="translate(4 3)"
                                                                                                fill="none"
                                                                                                stroke="#000"
                                                                                                stroke-width="1.3"
                                                                                            >
                                                                                                <rect width="16" height="18" rx="2" stroke="none" />
                                                                                                <rect
                                                                                                    x="0.65"
                                                                                                    y="0.65"
                                                                                                    width="14.7"
                                                                                                    height="16.7"
                                                                                                    rx="1.35"
                                                                                                    fill="none"
                                                                                                />
                                                                                            </g>
                                                                                            <g
                                                                                                id="icons_Q2"
                                                                                                data-name="icons Q2"
                                                                                                transform="translate(5.771 3.77)"
                                                                                            >
                                                                                                <g
                                                                                                    id="Group_7279"
                                                                                                    data-name="Group 7279"
                                                                                                    transform="translate(2 2)"
                                                                                                >
                                                                                                    <path
                                                                                                        id="Path_8467"
                                                                                                        data-name="Path 8467"
                                                                                                        d="M17.538,10a1.538,1.538,0,1,0,1.538,1.538A1.538,1.538,0,0,0,17.538,10Zm0,2.307a.769.769,0,1,1,.769-.769A.769.769,0,0,1,17.538,12.307Z"
                                                                                                        transform="translate(-13.308 -8.462)"
                                                                                                        stroke="#000"
                                                                                                        stroke-width="0.3"
                                                                                                    />
                                                                                                    <path
                                                                                                        id="Path_8468"
                                                                                                        data-name="Path 8468"
                                                                                                        d="M6.23,2a4.211,4.211,0,1,0,3,1.233A4.23,4.23,0,0,0,6.23,2ZM3.884,8.768A5.076,5.076,0,0,1,6.23,8.153a5.076,5.076,0,0,1,2.346.615,3.442,3.442,0,0,1-4.691,0Zm5.211-.6h0A5.9,5.9,0,0,0,6.23,7.383a5.9,5.9,0,0,0-2.865.788h0a3.4,3.4,0,0,1-.6-1.942,3.461,3.461,0,0,1,6.922,0,3.4,3.4,0,0,1-.6,1.942Z"
                                                                                                        transform="translate(-2 -2)"
                                                                                                        stroke="#000"
                                                                                                        stroke-width="0.3"
                                                                                                    />
                                                                                                </g>
                                                                                            </g>
                                                                                            <line
                                                                                                id="Line_190"
                                                                                                data-name="Line 190"
                                                                                                x2="9"
                                                                                                transform="translate(7.5 17.5)"
                                                                                                fill="none"
                                                                                                stroke="#000"
                                                                                                stroke-linecap="round"
                                                                                                stroke-width="1.3"
                                                                                            />
                                                                                        </g>
                                                                                    </svg>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </>
                                                                    ) : (

                                                                        // row.user_type == 'owner'
                                                                        <>

                                                                            {
                                                                                row.user_type == 'manager' ? (
                                                                                    <>
                                                                                        <div
                                                                                            className="action-icon-item fill-icon big-black-icon remove-owner"
                                                                                            // onClick={(e) => removeManagerSingle(row.id)}
                                                                                            onClick={(e) => {
                                                                                                setModalAccessOwner(true);
                                                                                                setSelectRow(row.id);
                                                                                            }}
                                                                                        >
                                                                                            <OverlayTrigger
                                                                                                overlay={
                                                                                                    <Tooltip id="InfoText">Remove Owner Access</Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <svg id="Layer_1"  data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 366.89" {...props}>
                                                                                                    <defs />
                                                                                                    <path classname="cls-1" d="M384 303.79c-.72 3.39-1.38 6.79-2.16 10.16-8.46 36.39-41.94 62.57-78.58 61.46-39-1.18-71-28.44-77.3-66.25-3.56-21.4 1-41.2 13.38-59.09.5-.72 1-1.42 1.54-2.13l1.67-2.28c-38.05-28.92-80.28-38.62-125.27-23C52.39 245.22 23.47 294 23.52 362.3H0v-22.5c.89-6.16 1.45-12.38 2.71-18.45 11.63-56 44.11-95 96.26-118 .91-.4 1.83-.79 2.73-1.2.08 0 .1-.2.25-.56-32.52-21.11-50.51-50.83-50.63-90-.08-29.56 11.33-54.75 32.86-75C127.11-3.89 194.74-.06 233.62 45.06c39.9 46.28 31.5 119.7-24 155.33a159.6 159.6 0 0149.78 28.35c8.74-3.46 16.69-7.47 25.12-9.77 45.81-12.51 93.05 19.54 98.75 66.61A17.09 17.09 0 00384 288zm-149.5-191.6c-.08-43.89-35.92-79.68-79.71-79.6a79.89 79.89 0 00-79.63 79.7c0 43.91 36 79.71 80.1 79.64 43.45-.07 79.32-36.17 79.24-79.74zm69.41 239.25c30 .52 55.58-24.25 56.07-54.27a55.77 55.77 0 00-54.9-57c-30.16-.47-55.62 24.05-56.17 54.12-.59 31.45 23.63 56.6 55 57.15z" transform="translate(0 -8.56)" />
                                                                                                    <path classname="cls-1" d="M266.92 307.54v-23.49h75v23.49z" transform="translate(0 -8.56)" />
                                                                                                </svg>

                                                                                            </OverlayTrigger>
                                                                                        </div>

                                                                                    </>


                                                                                ) : (
                                                                                    <>

                                                                                        {/* usertype = account manager */}
                                                                                        <div
                                                                                            className="action-icon-item fill-icon big-black-icon remove-mangr"
                                                                                            // onClick={(e) => removeManagerSingle(row.id)}
                                                                                            onClick={(e) => {
                                                                                                setModalAccess(true);
                                                                                                setSelectRow(row.id);
                                                                                            }}
                                                                                        >
                                                                                            <OverlayTrigger
                                                                                                overlay={
                                                                                                    <Tooltip id="InfoText">Remove Manager Access</Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <svg
                                                                                                    id="remove_manager_access"
                                                                                                    data-name="remove manager access"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="24"
                                                                                                    height="24"
                                                                                                    viewBox="0 0 24 24"
                                                                                                >
                                                                                                    <rect
                                                                                                        id="Rectangle_3313"
                                                                                                        data-name="Rectangle 3313"
                                                                                                        width="24"
                                                                                                        height="24"
                                                                                                        fill="none"
                                                                                                    />
                                                                                                    <path
                                                                                                        id="Subtraction_1"
                                                                                                        data-name="Subtraction 1"
                                                                                                        d="M-19102.154-5670.833h-10.111a.694.694,0,0,1-.7-.691v-3.244l.35-.207a10.725,10.725,0,0,1,5.4-1.454,10.772,10.772,0,0,1,5.4,1.449l.35.211v3.244A.694.694,0,0,1-19102.154-5670.833Zm-5.059-4.213a9.47,9.47,0,0,0-4.279,1.027l-.082.043v1.756h8.729v-1.756l-.082-.043A9.482,9.482,0,0,0-19107.213-5675.046Zm0-3.369a3.644,3.644,0,0,1-3.637-3.642,3.644,3.644,0,0,1,3.637-3.642,3.647,3.647,0,0,1,3.643,3.642A3.647,3.647,0,0,1-19107.213-5678.415Zm0-5.9a2.259,2.259,0,0,0-2.254,2.26,2.256,2.256,0,0,0,2.254,2.255,2.26,2.26,0,0,0,2.26-2.255A2.262,2.262,0,0,0-19107.213-5684.317Zm-6.736,3.373h-5.059a.692.692,0,0,1-.689-.691.694.694,0,0,1,.689-.7h5.059a.7.7,0,0,1,.691.7A.692.692,0,0,1-19113.949-5680.944Z"
                                                                                                        transform="translate(19122.699 5690.699)"
                                                                                                    />
                                                                                                </svg>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                        {/* Button for enroll as owner  */}
                                                                                        <div
                                                                                            className={ownercount > 1 ? "action-icon-item fill-icon big-black-icon enroll-owner opacity-50" : "action-icon-item fill-icon big-black-icon enroll-owner "}
                                                                                            onClick={() => {
                                                                                                setModalAssignOnwer(true);
                                                                                                setSelectRow(row.id);
                                                                                            }}
                                                                                        // onClick = { (e) => setModalAccess(true) }
                                                                                        >
                                                                                            <OverlayTrigger
                                                                                                overlay={<Tooltip id="InfoText">Enroll As Owner</Tooltip>}
                                                                                            >

                                                                                                <svg id="Layer_1" style={{width:'13px'}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.34 383.33" {...props}>
                                                                                                    <defs />
                                                                                                    <path className="cls-1" d="M13.42 62.37C14 60 14.48 57.56 15 55.16c6.16-26.24 30.23-45.8 57.22-45.87q87.95-.24 175.9 0c31.86.11 58.31 26.63 58.75 58.45.19 13.59.09 27.19 0 40.79 0 8.89-6.36 15.39-14.83 15.46-8.64.07-15-6.5-15.08-15.6-.06-12.59 0-25.19 0-37.79-.05-18.69-12.81-31.42-31.56-31.43H74.74C56.25 39.18 43.39 52 43.38 70.4v260.8c0 18.47 12.82 31.32 31.26 31.34h101.8c11.55 0 18.86 10.52 14.63 20.74-2 4.91-6.07 7.35-10.68 9.19H66.58a12.74 12.74 0 00-2.06-.68 60.13 60.13 0 01-49.4-44.92c-.61-2.5-1.13-5-1.7-7.55z" transform="translate(-13.42 -9.16)" />
                                                                                                    <path className="cls-1" d="M256 392.49c-7.44-2-14.72-4.29-21-9.07a44.87 44.87 0 0120-80.15c1.46-.22 2.9-.51 4.55-.8-2.33-15.86-4.65-31.48-6.93-47.11-1.85-12.7-4.21-25.35-5.39-38.11-2.8-30.25 19.48-58.52 49.43-63.45 38.32-6.32 72.48 24 69.89 62.71-.88 13-3.52 25.91-5.4 38.85-2.27 15.63-4.59 31.25-6.93 47.13 2.52.43 4.85.73 7.14 1.23a45 45 0 0135.4 44.28c-.13 21.22-15.32 39.41-36.52 43.73a24.13 24.13 0 00-2.41.78zm50.92-29.95c14.84 0 29.69.06 44.53 0 8.78 0 15.38-6.6 15.34-15s-6.64-14.8-15.46-14.9c-4.62-.05-9.23 0-13.85 0-10.22-.12-16.84-8.06-15.33-18.13 4.85-32.53 9.82-65 14.37-97.63 1.73-12.32-3.07-22.42-13.57-29.14s-21.61-6.7-32.1 0-15.27 16.82-13.56 29.15c4.47 32.08 9.34 64.11 14.09 96.15 1.84 12.42-4.3 19.58-16.94 19.59-4.11 0-8.23-.06-12.35 0a14.95 14.95 0 10-.08 29.89c14.99.1 29.99.02 44.92.02zM118.24 242.78a44.91 44.91 0 11-44.92 45 45.06 45.06 0 0144.92-45zm-.13 30a15 15 0 1015.09 14.82 15 15 0 00-15.09-14.87zM160.56 126h71.08c10.74 0 17.89 9.83 14.58 19.72a14.9 14.9 0 01-14.22 10.2c-7.11.09-14.22 0-21.32 0H89.09c-11 0-18.31-9.75-14.95-19.75 2.12-6.31 7.72-10.16 15-10.17h71.42zM145.23 212.83H88.37a14.77 14.77 0 01-14.64-11.58A14.45 14.45 0 0180.86 185a17.88 17.88 0 017.82-2q56.47-.19 113-.07c8.82 0 15.4 6.49 15.42 14.91s-6.56 15-15.35 15c-18.87.04-37.69-.01-56.52-.01zM205.11 99.05h-26.17c-9.1-.05-15.76-6.4-15.76-15s6.64-15 15.73-15q26.16-.06 52.34 0c9.1 0 15.75 6.38 15.76 14.94S240.37 99 231.28 99c-8.73.09-17.45.05-26.17.05z" transform="translate(-13.42 -9.16)" />
                                                                                                </svg>


                                                                                            </OverlayTrigger>
                                                                                        </div>

                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>

                                                                    )}
                                                                </div>

                                                            )}

                                                        </>






                                                    ) : (

                                                        <>

                                                    {((row.user_type == "admin") || (row.user_type == "manager")) ? (
                                                        ""
                                                    ) : (
                                                        <div className="action-icons-cell-wrap d-flex">
                                                            <div
                                                                className="action-icon-item fill-icon"
                                                                //onClick={(e) => deleteSingleMember(row.id)}
                                                                //onClick = {(e) =>  {setModalConfirm(true);memberDelete(row.id); setSelectRow(row)}}
                                                                //onClick = {(e) =>  {setModalConfirm(true);  setSelectRows(rows) ; setSelectedIdList(selectedIds)}}
                                                                onClick={(e) => {
                                                                    setModalConfirm(true);
                                                                    setSelectRow(row.id);
                                                                }}
                                                            >
                                                                <OverlayTrigger
                                                                    overlay={<Tooltip id="InfoText">Delete</Tooltip>}
                                                                >
                                                                    <svg
                                                                        id="icons_Q2"
                                                                        data-name="icons Q2"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="17.49"
                                                                        height="19.331"
                                                                        viewBox="0 0 17.49 19.331"
                                                                    >
                                                                        <path
                                                                            id="Path_8323"
                                                                            data-name="Path 8323"
                                                                            d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                                                                            transform="translate(-4.999 -3)"
                                                                            fill="#fff"
                                                                        />
                                                                        <path
                                                                            id="Path_8324"
                                                                            data-name="Path 8324"
                                                                            d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                                                                            transform="translate(-7.261 -9.477)"
                                                                            fill="#fff"
                                                                        />{" "}
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div
                                                                className="action-icon-item fill-icon big-black-icon resendico"
                                                                onClick={(e) => resend_Email(row.id)}
                                                            >
                                                                <OverlayTrigger
                                                                    overlay={<Tooltip id="InfoText">Resend Invitation</Tooltip>}
                                                                >
                                                                    <svg
                                                                        id="resend_invitaion"
                                                                        data-name="resend invitaion"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <rect
                                                                            id="Rectangle_3313"
                                                                            data-name="Rectangle 3313"
                                                                            width="24"
                                                                            height="24"
                                                                            fill="none"
                                                                        />
                                                                        <g
                                                                            id="Group_7278"
                                                                            data-name="Group 7278"
                                                                            transform="translate(0 0.453)"
                                                                        >
                                                                            <g
                                                                                id="Group_7276"
                                                                                data-name="Group 7276"
                                                                                transform="translate(5.25 16.66)"
                                                                            >
                                                                                <path
                                                                                    id="Path_8470"
                                                                                    data-name="Path 8470"
                                                                                    d="M15.519,66.836a.576.576,0,0,1-.57-.5l-.363-2.574a.576.576,0,0,1,.49-.651l2.578-.365a.576.576,0,0,1,.161,1.141l-2.007.284.282,2a.577.577,0,0,1-.571.657Z"
                                                                                    transform="translate(-14.581 -62.744)"
                                                                                />
                                                                                <path
                                                                                    id="Path_8471"
                                                                                    data-name="Path 8471"
                                                                                    d="M21.909,67.753q-.431,0-.864-.035a10.642,10.642,0,0,1-6.273-2.7.576.576,0,1,1,.771-.856,9.525,9.525,0,0,0,11.5.93.576.576,0,0,1,.624.969,10.64,10.64,0,0,1-5.762,1.7Z"
                                                                                    transform="translate(-14.581 -63.645)"
                                                                                />
                                                                            </g>
                                                                            <g
                                                                                id="Group_7277"
                                                                                data-name="Group 7277"
                                                                                transform="translate(5.393 2.326)"
                                                                            >
                                                                                <path
                                                                                    id="Path_8474"
                                                                                    data-name="Path 8474"
                                                                                    d="M53.048,7.147A.576.576,0,0,1,52.968,6l2.007-.284-.282-2a.576.576,0,0,1,1.141-.161L56.2,6.125a.576.576,0,0,1-.49.651l-2.578.365A.582.582,0,0,1,53.048,7.147Z"
                                                                                    transform="translate(-42.848 -3.039)"
                                                                                />
                                                                                <path
                                                                                    id="Path_8475"
                                                                                    data-name="Path 8475"
                                                                                    d="M31.848,6.742a.574.574,0,0,1-.385-.148,9.525,9.525,0,0,0-11.5-.93.576.576,0,1,1-.624-.969,10.653,10.653,0,0,1,12.9,1.043.576.576,0,0,1-.386,1Z"
                                                                                    transform="translate(-19.07 -2.999)"
                                                                                />
                                                                            </g>
                                                                            <g
                                                                                id="icons_Q2"
                                                                                data-name="icons Q2"
                                                                                transform="translate(6.766 7.558)"
                                                                            >
                                                                                <path
                                                                                    id="Path_8465"
                                                                                    data-name="Path 8465"
                                                                                    d="M11.854,7H2.469A.469.469,0,0,0,2,7.469v7.039a.469.469,0,0,0,.469.469h9.385a.469.469,0,0,0,.469-.469V7.469A.469.469,0,0,0,11.854,7Zm-.469,7.039H2.938V9.276l4.106,2.417a.211.211,0,0,0,.235,0l4.106-2.487ZM7.279,10.613a.211.211,0,0,1-.235,0L2.938,7.938h8.446Z"
                                                                                    transform="translate(-2 -7)"
                                                                                    stroke="#000"
                                                                                    stroke-width="0.3"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </div>
                                                            {row.user_type == "learner" ? (
                                                                <div
                                                                    className="action-icon-item fill-icon big-black-icon enroll-mangr"
                                                                    onClick={(e) => assignManagerSingle(row.id)}
                                                                //onClick = { (e) => setModalAccess(true) }
                                                                >
                                                                    <OverlayTrigger
                                                                        overlay={<Tooltip id="InfoText">Enroll As Manager</Tooltip>}
                                                                    >
                                                                        <svg
                                                                            id="enroll_as_manager"
                                                                            data-name="enroll as manager"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <rect
                                                                                id="Rectangle_3313"
                                                                                data-name="Rectangle 3313"
                                                                                width="24"
                                                                                height="24"
                                                                                fill="none"
                                                                            />
                                                                            <g id="Group_7280" data-name="Group 7280">
                                                                                <g
                                                                                    id="Rectangle_3318"
                                                                                    data-name="Rectangle 3318"
                                                                                    transform="translate(4 3)"
                                                                                    fill="none"
                                                                                    stroke="#000"
                                                                                    stroke-width="1.3"
                                                                                >
                                                                                    <rect width="16" height="18" rx="2" stroke="none" />
                                                                                    <rect
                                                                                        x="0.65"
                                                                                        y="0.65"
                                                                                        width="14.7"
                                                                                        height="16.7"
                                                                                        rx="1.35"
                                                                                        fill="none"
                                                                                    />
                                                                                </g>
                                                                                <g
                                                                                    id="icons_Q2"
                                                                                    data-name="icons Q2"
                                                                                    transform="translate(5.771 3.77)"
                                                                                >
                                                                                    <g
                                                                                        id="Group_7279"
                                                                                        data-name="Group 7279"
                                                                                        transform="translate(2 2)"
                                                                                    >
                                                                                        <path
                                                                                            id="Path_8467"
                                                                                            data-name="Path 8467"
                                                                                            d="M17.538,10a1.538,1.538,0,1,0,1.538,1.538A1.538,1.538,0,0,0,17.538,10Zm0,2.307a.769.769,0,1,1,.769-.769A.769.769,0,0,1,17.538,12.307Z"
                                                                                            transform="translate(-13.308 -8.462)"
                                                                                            stroke="#000"
                                                                                            stroke-width="0.3"
                                                                                        />
                                                                                        <path
                                                                                            id="Path_8468"
                                                                                            data-name="Path 8468"
                                                                                            d="M6.23,2a4.211,4.211,0,1,0,3,1.233A4.23,4.23,0,0,0,6.23,2ZM3.884,8.768A5.076,5.076,0,0,1,6.23,8.153a5.076,5.076,0,0,1,2.346.615,3.442,3.442,0,0,1-4.691,0Zm5.211-.6h0A5.9,5.9,0,0,0,6.23,7.383a5.9,5.9,0,0,0-2.865.788h0a3.4,3.4,0,0,1-.6-1.942,3.461,3.461,0,0,1,6.922,0,3.4,3.4,0,0,1-.6,1.942Z"
                                                                                            transform="translate(-2 -2)"
                                                                                            stroke="#000"
                                                                                            stroke-width="0.3"
                                                                                        />
                                                                                    </g>
                                                                                </g>
                                                                                <line
                                                                                    id="Line_190"
                                                                                    data-name="Line 190"
                                                                                    x2="9"
                                                                                    transform="translate(7.5 17.5)"
                                                                                    fill="none"
                                                                                    stroke="#000"
                                                                                    stroke-linecap="round"
                                                                                    stroke-width="1.3"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="action-icon-item fill-icon big-black-icon remove-mangr"
                                                                    // onClick={(e) => removeManagerSingle(row.id)}
                                                                    onClick={(e) => {
                                                                        setModalAccess(true);
                                                                        setSelectRow(row.id);
                                                                    }}
                                                                >
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="InfoText">Remove Manager Access</Tooltip>
                                                                        }
                                                                    >
                                                                        <svg
                                                                            id="remove_manager_access"
                                                                            data-name="remove manager access"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <rect
                                                                                id="Rectangle_3313"
                                                                                data-name="Rectangle 3313"
                                                                                width="24"
                                                                                height="24"
                                                                                fill="none"
                                                                            />
                                                                            <path
                                                                                id="Subtraction_1"
                                                                                data-name="Subtraction 1"
                                                                                d="M-19102.154-5670.833h-10.111a.694.694,0,0,1-.7-.691v-3.244l.35-.207a10.725,10.725,0,0,1,5.4-1.454,10.772,10.772,0,0,1,5.4,1.449l.35.211v3.244A.694.694,0,0,1-19102.154-5670.833Zm-5.059-4.213a9.47,9.47,0,0,0-4.279,1.027l-.082.043v1.756h8.729v-1.756l-.082-.043A9.482,9.482,0,0,0-19107.213-5675.046Zm0-3.369a3.644,3.644,0,0,1-3.637-3.642,3.644,3.644,0,0,1,3.637-3.642,3.647,3.647,0,0,1,3.643,3.642A3.647,3.647,0,0,1-19107.213-5678.415Zm0-5.9a2.259,2.259,0,0,0-2.254,2.26,2.256,2.256,0,0,0,2.254,2.255,2.26,2.26,0,0,0,2.26-2.255A2.262,2.262,0,0,0-19107.213-5684.317Zm-6.736,3.373h-5.059a.692.692,0,0,1-.689-.691.694.694,0,0,1,.689-.7h5.059a.7.7,0,0,1,.691.7A.692.692,0,0,1-19113.949-5680.944Z"
                                                                                transform="translate(19122.699 5690.699)"
                                                                            />
                                                                            {/* <path
                                               id="Addition_1"
                                               data-name="Addition_1"
                                               d="M-19102.154-5670.833h-10.111a.694.694,0,0,1-.7-.691v-3.244l.35-.207a10.725,10.725,0,0,1,5.4-1.454,10.772,10.772,0,0,1,5.4,1.449l.35.211v3.244A.694.694,0,0,1-19102.154-5670.833Zm-5.059-4.213a9.47,9.47,0,0,0-4.279,1.027l-.082.043v1.756h8.729v-1.756l-.082-.043A9.482,9.482,0,0,0-19107.213-5675.046Zm0-3.369a3.644,3.644,0,0,1-3.637-3.642,3.644,3.644,0,0,1,3.637-3.642,3.647,3.647,0,0,1,3.643,3.642A3.647,3.647,0,0,1-19107.213-5678.415Zm0-5.9a2.259,2.259,0,0,0-2.254,2.26,2.256,2.256,0,0,0,2.254,2.255,2.26,2.26,0,0,0,2.26-2.255A2.262,2.262,0,0,0-19107.213-5684.317Zm-6.736,3.373h-5.059a.692.692,0,0,1-.689-.691.694.694,0,0,1,.689-.7h5.059a.7.7,0,0,1,.691.7A.692.692,0,0,1-19113.949-5680.944Z"
                                               transform="translate(19122.699 5690.699)"
                                             /> */}
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}


                                                        </>


                                                    )}


                                                </>
                                            ) : (
                                                <>


                                                    {((row.user_type == "admin") || (row.user_type == "manager")) ? (
                                                        ""
                                                    ) : (
                                                        <div className="action-icons-cell-wrap d-flex">
                                                            <div
                                                                className="action-icon-item fill-icon"
                                                                //onClick={(e) => deleteSingleMember(row.id)}
                                                                //onClick = {(e) =>  {setModalConfirm(true);memberDelete(row.id); setSelectRow(row)}}
                                                                //onClick = {(e) =>  {setModalConfirm(true);  setSelectRows(rows) ; setSelectedIdList(selectedIds)}}
                                                                onClick={(e) => {
                                                                    setModalConfirm(true);
                                                                    setSelectRow(row.id);
                                                                }}
                                                            >
                                                                <OverlayTrigger
                                                                    overlay={<Tooltip id="InfoText">Delete</Tooltip>}
                                                                >
                                                                    <svg
                                                                        id="icons_Q2"
                                                                        data-name="icons Q2"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="17.49"
                                                                        height="19.331"
                                                                        viewBox="0 0 17.49 19.331"
                                                                    >
                                                                        <path
                                                                            id="Path_8323"
                                                                            data-name="Path 8323"
                                                                            d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                                                                            transform="translate(-4.999 -3)"
                                                                            fill="#fff"
                                                                        />
                                                                        <path
                                                                            id="Path_8324"
                                                                            data-name="Path 8324"
                                                                            d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                                                                            transform="translate(-7.261 -9.477)"
                                                                            fill="#fff"
                                                                        />{" "}
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div
                                                                className="action-icon-item fill-icon big-black-icon resendico"
                                                                onClick={(e) => resend_Email(row.id)}
                                                            >
                                                                <OverlayTrigger
                                                                    overlay={<Tooltip id="InfoText">Resend Invitation</Tooltip>}
                                                                >
                                                                    <svg
                                                                        id="resend_invitaion"
                                                                        data-name="resend invitaion"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <rect
                                                                            id="Rectangle_3313"
                                                                            data-name="Rectangle 3313"
                                                                            width="24"
                                                                            height="24"
                                                                            fill="none"
                                                                        />
                                                                        <g
                                                                            id="Group_7278"
                                                                            data-name="Group 7278"
                                                                            transform="translate(0 0.453)"
                                                                        >
                                                                            <g
                                                                                id="Group_7276"
                                                                                data-name="Group 7276"
                                                                                transform="translate(5.25 16.66)"
                                                                            >
                                                                                <path
                                                                                    id="Path_8470"
                                                                                    data-name="Path 8470"
                                                                                    d="M15.519,66.836a.576.576,0,0,1-.57-.5l-.363-2.574a.576.576,0,0,1,.49-.651l2.578-.365a.576.576,0,0,1,.161,1.141l-2.007.284.282,2a.577.577,0,0,1-.571.657Z"
                                                                                    transform="translate(-14.581 -62.744)"
                                                                                />
                                                                                <path
                                                                                    id="Path_8471"
                                                                                    data-name="Path 8471"
                                                                                    d="M21.909,67.753q-.431,0-.864-.035a10.642,10.642,0,0,1-6.273-2.7.576.576,0,1,1,.771-.856,9.525,9.525,0,0,0,11.5.93.576.576,0,0,1,.624.969,10.64,10.64,0,0,1-5.762,1.7Z"
                                                                                    transform="translate(-14.581 -63.645)"
                                                                                />
                                                                            </g>
                                                                            <g
                                                                                id="Group_7277"
                                                                                data-name="Group 7277"
                                                                                transform="translate(5.393 2.326)"
                                                                            >
                                                                                <path
                                                                                    id="Path_8474"
                                                                                    data-name="Path 8474"
                                                                                    d="M53.048,7.147A.576.576,0,0,1,52.968,6l2.007-.284-.282-2a.576.576,0,0,1,1.141-.161L56.2,6.125a.576.576,0,0,1-.49.651l-2.578.365A.582.582,0,0,1,53.048,7.147Z"
                                                                                    transform="translate(-42.848 -3.039)"
                                                                                />
                                                                                <path
                                                                                    id="Path_8475"
                                                                                    data-name="Path 8475"
                                                                                    d="M31.848,6.742a.574.574,0,0,1-.385-.148,9.525,9.525,0,0,0-11.5-.93.576.576,0,1,1-.624-.969,10.653,10.653,0,0,1,12.9,1.043.576.576,0,0,1-.386,1Z"
                                                                                    transform="translate(-19.07 -2.999)"
                                                                                />
                                                                            </g>
                                                                            <g
                                                                                id="icons_Q2"
                                                                                data-name="icons Q2"
                                                                                transform="translate(6.766 7.558)"
                                                                            >
                                                                                <path
                                                                                    id="Path_8465"
                                                                                    data-name="Path 8465"
                                                                                    d="M11.854,7H2.469A.469.469,0,0,0,2,7.469v7.039a.469.469,0,0,0,.469.469h9.385a.469.469,0,0,0,.469-.469V7.469A.469.469,0,0,0,11.854,7Zm-.469,7.039H2.938V9.276l4.106,2.417a.211.211,0,0,0,.235,0l4.106-2.487ZM7.279,10.613a.211.211,0,0,1-.235,0L2.938,7.938h8.446Z"
                                                                                    transform="translate(-2 -7)"
                                                                                    stroke="#000"
                                                                                    stroke-width="0.3"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </div>
                                                            {row.user_type == "learner" ? (
                                                                <div
                                                                    className="action-icon-item fill-icon big-black-icon enroll-mangr"
                                                                    onClick={(e) => assignManagerSingle(row.id)}
                                                                //onClick = { (e) => setModalAccess(true) }
                                                                >
                                                                    <OverlayTrigger
                                                                        overlay={<Tooltip id="InfoText">Enroll As Manager</Tooltip>}
                                                                    >
                                                                        <svg
                                                                            id="enroll_as_manager"
                                                                            data-name="enroll as manager"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <rect
                                                                                id="Rectangle_3313"
                                                                                data-name="Rectangle 3313"
                                                                                width="24"
                                                                                height="24"
                                                                                fill="none"
                                                                            />
                                                                            <g id="Group_7280" data-name="Group 7280">
                                                                                <g
                                                                                    id="Rectangle_3318"
                                                                                    data-name="Rectangle 3318"
                                                                                    transform="translate(4 3)"
                                                                                    fill="none"
                                                                                    stroke="#000"
                                                                                    stroke-width="1.3"
                                                                                >
                                                                                    <rect width="16" height="18" rx="2" stroke="none" />
                                                                                    <rect
                                                                                        x="0.65"
                                                                                        y="0.65"
                                                                                        width="14.7"
                                                                                        height="16.7"
                                                                                        rx="1.35"
                                                                                        fill="none"
                                                                                    />
                                                                                </g>
                                                                                <g
                                                                                    id="icons_Q2"
                                                                                    data-name="icons Q2"
                                                                                    transform="translate(5.771 3.77)"
                                                                                >
                                                                                    <g
                                                                                        id="Group_7279"
                                                                                        data-name="Group 7279"
                                                                                        transform="translate(2 2)"
                                                                                    >
                                                                                        <path
                                                                                            id="Path_8467"
                                                                                            data-name="Path 8467"
                                                                                            d="M17.538,10a1.538,1.538,0,1,0,1.538,1.538A1.538,1.538,0,0,0,17.538,10Zm0,2.307a.769.769,0,1,1,.769-.769A.769.769,0,0,1,17.538,12.307Z"
                                                                                            transform="translate(-13.308 -8.462)"
                                                                                            stroke="#000"
                                                                                            stroke-width="0.3"
                                                                                        />
                                                                                        <path
                                                                                            id="Path_8468"
                                                                                            data-name="Path 8468"
                                                                                            d="M6.23,2a4.211,4.211,0,1,0,3,1.233A4.23,4.23,0,0,0,6.23,2ZM3.884,8.768A5.076,5.076,0,0,1,6.23,8.153a5.076,5.076,0,0,1,2.346.615,3.442,3.442,0,0,1-4.691,0Zm5.211-.6h0A5.9,5.9,0,0,0,6.23,7.383a5.9,5.9,0,0,0-2.865.788h0a3.4,3.4,0,0,1-.6-1.942,3.461,3.461,0,0,1,6.922,0,3.4,3.4,0,0,1-.6,1.942Z"
                                                                                            transform="translate(-2 -2)"
                                                                                            stroke="#000"
                                                                                            stroke-width="0.3"
                                                                                        />
                                                                                    </g>
                                                                                </g>
                                                                                <line
                                                                                    id="Line_190"
                                                                                    data-name="Line 190"
                                                                                    x2="9"
                                                                                    transform="translate(7.5 17.5)"
                                                                                    fill="none"
                                                                                    stroke="#000"
                                                                                    stroke-linecap="round"
                                                                                    stroke-width="1.3"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="action-icon-item fill-icon big-black-icon remove-mangr"
                                                                    // onClick={(e) => removeManagerSingle(row.id)}
                                                                    onClick={(e) => {
                                                                        setModalAccess(true);
                                                                        setSelectRow(row.id);
                                                                    }}
                                                                >
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="InfoText">Remove Manager Access</Tooltip>
                                                                        }
                                                                    >
                                                                        <svg
                                                                            id="remove_manager_access"
                                                                            data-name="remove manager access"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <rect
                                                                                id="Rectangle_3313"
                                                                                data-name="Rectangle 3313"
                                                                                width="24"
                                                                                height="24"
                                                                                fill="none"
                                                                            />
                                                                            <path
                                                                                id="Subtraction_1"
                                                                                data-name="Subtraction 1"
                                                                                d="M-19102.154-5670.833h-10.111a.694.694,0,0,1-.7-.691v-3.244l.35-.207a10.725,10.725,0,0,1,5.4-1.454,10.772,10.772,0,0,1,5.4,1.449l.35.211v3.244A.694.694,0,0,1-19102.154-5670.833Zm-5.059-4.213a9.47,9.47,0,0,0-4.279,1.027l-.082.043v1.756h8.729v-1.756l-.082-.043A9.482,9.482,0,0,0-19107.213-5675.046Zm0-3.369a3.644,3.644,0,0,1-3.637-3.642,3.644,3.644,0,0,1,3.637-3.642,3.647,3.647,0,0,1,3.643,3.642A3.647,3.647,0,0,1-19107.213-5678.415Zm0-5.9a2.259,2.259,0,0,0-2.254,2.26,2.256,2.256,0,0,0,2.254,2.255,2.26,2.26,0,0,0,2.26-2.255A2.262,2.262,0,0,0-19107.213-5684.317Zm-6.736,3.373h-5.059a.692.692,0,0,1-.689-.691.694.694,0,0,1,.689-.7h5.059a.7.7,0,0,1,.691.7A.692.692,0,0,1-19113.949-5680.944Z"
                                                                                transform="translate(19122.699 5690.699)"
                                                                            />
                                                                            {/* <path
                                               id="Addition_1"
                                               data-name="Addition_1"
                                               d="M-19102.154-5670.833h-10.111a.694.694,0,0,1-.7-.691v-3.244l.35-.207a10.725,10.725,0,0,1,5.4-1.454,10.772,10.772,0,0,1,5.4,1.449l.35.211v3.244A.694.694,0,0,1-19102.154-5670.833Zm-5.059-4.213a9.47,9.47,0,0,0-4.279,1.027l-.082.043v1.756h8.729v-1.756l-.082-.043A9.482,9.482,0,0,0-19107.213-5675.046Zm0-3.369a3.644,3.644,0,0,1-3.637-3.642,3.644,3.644,0,0,1,3.637-3.642,3.647,3.647,0,0,1,3.643,3.642A3.647,3.647,0,0,1-19107.213-5678.415Zm0-5.9a2.259,2.259,0,0,0-2.254,2.26,2.256,2.256,0,0,0,2.254,2.255,2.26,2.26,0,0,0,2.26-2.255A2.262,2.262,0,0,0-19107.213-5684.317Zm-6.736,3.373h-5.059a.692.692,0,0,1-.689-.691.694.694,0,0,1,.689-.7h5.059a.7.7,0,0,1,.691.7A.692.692,0,0,1-19113.949-5680.944Z"
                                               transform="translate(19122.699 5690.699)"
                                             /> */}
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}

                                                </>

                                            )}
                                        </>

                                    ) : (

                                        <>

                                            {((row.user_type == "admin") || (row.user_type == "manager") || (row.user_type == "staff_manager")) ? (
                                                ""
                                            ) : (
                                                <div className="action-icons-cell-wrap d-flex">
                                                    <div
                                                        className="action-icon-item fill-icon"
                                                        //onClick={(e) => deleteSingleMember(row.id)}
                                                        //onClick = {(e) =>  {setModalConfirm(true);memberDelete(row.id); setSelectRow(row)}}
                                                        //onClick = {(e) =>  {setModalConfirm(true);  setSelectRows(rows) ; setSelectedIdList(selectedIds)}}
                                                        onClick={(e) => {
                                                            setModalConfirm(true);
                                                            setSelectRow(row.id);
                                                        }}
                                                    >
                                                        <OverlayTrigger
                                                            overlay={<Tooltip id="InfoText">Delete</Tooltip>}
                                                        >
                                                            <svg
                                                                id="icons_Q2"
                                                                data-name="icons Q2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17.49"
                                                                height="19.331"
                                                                viewBox="0 0 17.49 19.331"
                                                            >
                                                                <path
                                                                    id="Path_8323"
                                                                    data-name="Path 8323"
                                                                    d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                                                                    transform="translate(-4.999 -3)"
                                                                    fill="#fff"
                                                                />
                                                                <path
                                                                    id="Path_8324"
                                                                    data-name="Path 8324"
                                                                    d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                                                                    transform="translate(-7.261 -9.477)"
                                                                    fill="#fff"
                                                                />{" "}
                                                            </svg>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div
                                                        className="action-icon-item fill-icon big-black-icon resendico"
                                                        onClick={(e) => resend_Email(row.id)}
                                                    >
                                                        <OverlayTrigger
                                                            overlay={<Tooltip id="InfoText">Resend Invitation</Tooltip>}
                                                        >
                                                            <svg
                                                                id="resend_invitaion"
                                                                data-name="resend invitaion"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <rect
                                                                    id="Rectangle_3313"
                                                                    data-name="Rectangle 3313"
                                                                    width="24"
                                                                    height="24"
                                                                    fill="none"
                                                                />
                                                                <g
                                                                    id="Group_7278"
                                                                    data-name="Group 7278"
                                                                    transform="translate(0 0.453)"
                                                                >
                                                                    <g
                                                                        id="Group_7276"
                                                                        data-name="Group 7276"
                                                                        transform="translate(5.25 16.66)"
                                                                    >
                                                                        <path
                                                                            id="Path_8470"
                                                                            data-name="Path 8470"
                                                                            d="M15.519,66.836a.576.576,0,0,1-.57-.5l-.363-2.574a.576.576,0,0,1,.49-.651l2.578-.365a.576.576,0,0,1,.161,1.141l-2.007.284.282,2a.577.577,0,0,1-.571.657Z"
                                                                            transform="translate(-14.581 -62.744)"
                                                                        />
                                                                        <path
                                                                            id="Path_8471"
                                                                            data-name="Path 8471"
                                                                            d="M21.909,67.753q-.431,0-.864-.035a10.642,10.642,0,0,1-6.273-2.7.576.576,0,1,1,.771-.856,9.525,9.525,0,0,0,11.5.93.576.576,0,0,1,.624.969,10.64,10.64,0,0,1-5.762,1.7Z"
                                                                            transform="translate(-14.581 -63.645)"
                                                                        />
                                                                    </g>
                                                                    <g
                                                                        id="Group_7277"
                                                                        data-name="Group 7277"
                                                                        transform="translate(5.393 2.326)"
                                                                    >
                                                                        <path
                                                                            id="Path_8474"
                                                                            data-name="Path 8474"
                                                                            d="M53.048,7.147A.576.576,0,0,1,52.968,6l2.007-.284-.282-2a.576.576,0,0,1,1.141-.161L56.2,6.125a.576.576,0,0,1-.49.651l-2.578.365A.582.582,0,0,1,53.048,7.147Z"
                                                                            transform="translate(-42.848 -3.039)"
                                                                        />
                                                                        <path
                                                                            id="Path_8475"
                                                                            data-name="Path 8475"
                                                                            d="M31.848,6.742a.574.574,0,0,1-.385-.148,9.525,9.525,0,0,0-11.5-.93.576.576,0,1,1-.624-.969,10.653,10.653,0,0,1,12.9,1.043.576.576,0,0,1-.386,1Z"
                                                                            transform="translate(-19.07 -2.999)"
                                                                        />
                                                                    </g>
                                                                    <g
                                                                        id="icons_Q2"
                                                                        data-name="icons Q2"
                                                                        transform="translate(6.766 7.558)"
                                                                    >
                                                                        <path
                                                                            id="Path_8465"
                                                                            data-name="Path 8465"
                                                                            d="M11.854,7H2.469A.469.469,0,0,0,2,7.469v7.039a.469.469,0,0,0,.469.469h9.385a.469.469,0,0,0,.469-.469V7.469A.469.469,0,0,0,11.854,7Zm-.469,7.039H2.938V9.276l4.106,2.417a.211.211,0,0,0,.235,0l4.106-2.487ZM7.279,10.613a.211.211,0,0,1-.235,0L2.938,7.938h8.446Z"
                                                                            transform="translate(-2 -7)"
                                                                            stroke="#000"
                                                                            stroke-width="0.3"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </OverlayTrigger>
                                                    </div>
                                                    {row.user_type == "learner" ? (
                                                        <div
                                                            className="action-icon-item fill-icon big-black-icon enroll-mangr"
                                                            onClick={(e) => assignManagerSingle(row.id)}
                                                        //onClick = { (e) => setModalAccess(true) }
                                                        >
                                                            <OverlayTrigger
                                                                overlay={<Tooltip id="InfoText">Enroll As Manager</Tooltip>}
                                                            >
                                                                <svg
                                                                    id="enroll_as_manager"
                                                                    data-name="enroll as manager"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <rect
                                                                        id="Rectangle_3313"
                                                                        data-name="Rectangle 3313"
                                                                        width="24"
                                                                        height="24"
                                                                        fill="none"
                                                                    />
                                                                    <g id="Group_7280" data-name="Group 7280">
                                                                        <g
                                                                            id="Rectangle_3318"
                                                                            data-name="Rectangle 3318"
                                                                            transform="translate(4 3)"
                                                                            fill="none"
                                                                            stroke="#000"
                                                                            stroke-width="1.3"
                                                                        >
                                                                            <rect width="16" height="18" rx="2" stroke="none" />
                                                                            <rect
                                                                                x="0.65"
                                                                                y="0.65"
                                                                                width="14.7"
                                                                                height="16.7"
                                                                                rx="1.35"
                                                                                fill="none"
                                                                            />
                                                                        </g>
                                                                        <g
                                                                            id="icons_Q2"
                                                                            data-name="icons Q2"
                                                                            transform="translate(5.771 3.77)"
                                                                        >
                                                                            <g
                                                                                id="Group_7279"
                                                                                data-name="Group 7279"
                                                                                transform="translate(2 2)"
                                                                            >
                                                                                <path
                                                                                    id="Path_8467"
                                                                                    data-name="Path 8467"
                                                                                    d="M17.538,10a1.538,1.538,0,1,0,1.538,1.538A1.538,1.538,0,0,0,17.538,10Zm0,2.307a.769.769,0,1,1,.769-.769A.769.769,0,0,1,17.538,12.307Z"
                                                                                    transform="translate(-13.308 -8.462)"
                                                                                    stroke="#000"
                                                                                    stroke-width="0.3"
                                                                                />
                                                                                <path
                                                                                    id="Path_8468"
                                                                                    data-name="Path 8468"
                                                                                    d="M6.23,2a4.211,4.211,0,1,0,3,1.233A4.23,4.23,0,0,0,6.23,2ZM3.884,8.768A5.076,5.076,0,0,1,6.23,8.153a5.076,5.076,0,0,1,2.346.615,3.442,3.442,0,0,1-4.691,0Zm5.211-.6h0A5.9,5.9,0,0,0,6.23,7.383a5.9,5.9,0,0,0-2.865.788h0a3.4,3.4,0,0,1-.6-1.942,3.461,3.461,0,0,1,6.922,0,3.4,3.4,0,0,1-.6,1.942Z"
                                                                                    transform="translate(-2 -2)"
                                                                                    stroke="#000"
                                                                                    stroke-width="0.3"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                        <line
                                                                            id="Line_190"
                                                                            data-name="Line 190"
                                                                            x2="9"
                                                                            transform="translate(7.5 17.5)"
                                                                            fill="none"
                                                                            stroke="#000"
                                                                            stroke-linecap="round"
                                                                            stroke-width="1.3"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </OverlayTrigger>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="action-icon-item fill-icon big-black-icon remove-mangr"
                                                            // onClick={(e) => removeManagerSingle(row.id)}
                                                            onClick={(e) => {
                                                                setModalAccess(true);
                                                                setSelectRow(row.id);
                                                            }}
                                                        >
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="InfoText">Remove manager Access</Tooltip>
                                                                }
                                                            >
                                                                <svg
                                                                    id="remove_manager_access"
                                                                    data-name="remove manager access"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <rect
                                                                        id="Rectangle_3313"
                                                                        data-name="Rectangle 3313"
                                                                        width="24"
                                                                        height="24"
                                                                        fill="none"
                                                                    />
                                                                    <path
                                                                        id="Subtraction_1"
                                                                        data-name="Subtraction 1"
                                                                        d="M-19102.154-5670.833h-10.111a.694.694,0,0,1-.7-.691v-3.244l.35-.207a10.725,10.725,0,0,1,5.4-1.454,10.772,10.772,0,0,1,5.4,1.449l.35.211v3.244A.694.694,0,0,1-19102.154-5670.833Zm-5.059-4.213a9.47,9.47,0,0,0-4.279,1.027l-.082.043v1.756h8.729v-1.756l-.082-.043A9.482,9.482,0,0,0-19107.213-5675.046Zm0-3.369a3.644,3.644,0,0,1-3.637-3.642,3.644,3.644,0,0,1,3.637-3.642,3.647,3.647,0,0,1,3.643,3.642A3.647,3.647,0,0,1-19107.213-5678.415Zm0-5.9a2.259,2.259,0,0,0-2.254,2.26,2.256,2.256,0,0,0,2.254,2.255,2.26,2.26,0,0,0,2.26-2.255A2.262,2.262,0,0,0-19107.213-5684.317Zm-6.736,3.373h-5.059a.692.692,0,0,1-.689-.691.694.694,0,0,1,.689-.7h5.059a.7.7,0,0,1,.691.7A.692.692,0,0,1-19113.949-5680.944Z"
                                                                        transform="translate(19122.699 5690.699)"
                                                                    />
                                                                </svg>
                                                            </OverlayTrigger>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )

                                }
                            </>

                        )


                    }

                </div>
            ),
        },
    ];
    const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
        <div className="custom-control custom-checkbox">
            <input
                type="checkbox"
                className="custom-control-input"
                ref={ref}
                {...rest}
            />
            <label className="custom-control-label" onClick={onClick} />
        </div>
    ));

    const CaretDownIcon = () => {
        return (
            <div className="filter-icon-select">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.652"
                    height="19.653"
                    viewBox="0 0 19.652 19.653"
                >
                    <path
                        className="a"
                        d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0"
                        transform="translate(-0.5 0)"
                    />
                </svg>
            </div>
        );
    };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  // return (
  //   <div className="available-course-wrap">
  //     <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
  //       <div className="datatable-filter-left-box d-flex align-items-center">
  //         <div className="datepicker-box p-relative">
  //           <span className="datepickericon"></span>
  //         </div>

  //         <div className="dt-filter-left d-flex justify-content-between align-items-center">
  //           <div className="custom-react-select">
  //             <Select
  //               defaultValue={options[0]}
  //               value={options.value}
  //               onChange={handleMembersFilter}
  //               options={options}
  //             />
  //           </div>
  //           <button type="button" className="iconbutton">
  //             <svg
  //               xmlns="http://www.w3.org/2000/svg"
  //               width="19.652"
  //               height="19.653"
  //               viewBox="0 0 19.652 19.653"
  //             >
  //               <path
  //                 className="a"
  //                 d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0"
  //                 transform="translate(-0.5 0)"
  //               />
  //             </svg>
  //           </button>
  //         </div>
  //       </div>
  //       <div className="dt-filter-right d-flex">
  //         <div className="dt-filter-search-wrap p-relative">
  //           <input
  //             type="text"
  //             placeholder="Search using keyword"
  //             value={search}
  //             onChange={searchInput}
  //             className="form-control input-item"
  //           />

  //           <span
  //             className="search-close-icon"
  //             style={{ cursor: "pointer" }}
  //             onClick={reset}
  //           >
  //             ×
  //           </span>
  //         </div>
  //         <button className="iconbutton search-btn">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="19.216"
  //             height="19.216"
  //             viewBox="0 0 19.216 19.216"
  //           >
  //             <path
  //               className="a"
  //               d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"
  //             />
  //           </svg>
  //         </button>
  //       </div>
  //   ;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="available-course-wrap">
      <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
        <div className="datatable-filter-left-box d-flex align-items-center">
          <div className="datepicker-box p-relative">
            <span className="datepickericon"></span>
          </div>

          <div className="dt-filter-left d-flex justify-content-between align-items-center">
            <div className="custom-react-select">
              <Select
                defaultValue={
                  user?.is_admin_institution == true
                    ? options_admin[0]
                    : options[0]
                }
                // value={
                //   user?.is_admin_institution == true
                //     ? options_admin.value
                //     : options.value
                // }
                onChange={handleMembersFilter}
                options={
                  user?.is_admin_institution == true ? options_admin : options
                }
                value={memberType}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
        </div>
        <div className="dt-filter-right d-flex">
          <div className="dt-filter-search-wrap p-relative search-icon-left">
          <button className="iconbutton search-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.216"
              height="19.216"
              viewBox="0 0 19.216 19.216"
            >
              <path
                className="a"
                d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"
              />
            </svg>
          </button>
            <input
              type="text"
              placeholder="Search using keyword"
              value={search}
              onChange={searchInput}
              className="form-control input-item"
            />

            <span
              className="search-close-icon"
              style={{ cursor: "pointer" }}
              onClick={reset}
            >
              ×
            </span>
          </div>
     
          <OverlayTrigger
                overlay={
                    <Tooltip id="InfoText">
                          Help
                    </Tooltip>
                }
            >
                <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </OverlayTrigger>
        </div>
      </div>

      <div className="datatble-wrap checkbox-datatable">
        <MyConfirmDeleteModal
          show={modalConfirm}
          //handleSubmit={memberDelete}
          handleSubmit={() => {
            deleteSingleMember(selectrow);
            setModalConfirm(false);
          }}
          //handleSubmit={deleteSingleMember}
          onHide={() => setModalConfirm(false)}
          //onExited={setModalConfirm(false)}
        />
        <RemoveAccessModal
          show={modalAccess}
          handleSubmit={() => {
            removeManagerSingle(selectrow);
            setModalAccess(false);
          }}
          onHide={() => setModalAccess(false)}
          // onExited={ModalExit}
        />
        <RemoveAccessModal
          owner={true}
          show={modalAccessOwner}
          handleSubmit={() => {
            removeManagerSingle(selectrow);
            setModalAccessOwner(false);
          }}
          onHide={() => setModalAccessOwner(false)}
          // onExited={ModalExit}
        />
        <Enrollasownermodal 
          show={modalAssignOnwer}
          handleSubmit={() => {
            assignManagerSingle(selectrow);
            setModalAssignOnwer(false);
          }}
          onHide={() => setModalAssignOnwer(false)}
          // onExited={ModalExit}
        />

        {selectedIdList.length > 0 ? (
          <div className="datatble-more-opt">
            <ul className="d-flex">
              <li>
                <button
                  type="button"
                  className="option-btn"
                  onClick={openCreateGroup}
                >
                  <i className="fa fa-users" aria-hidden="true"></i>Create Group
                </button>
              </li>
              {managerSelected ? (
                ""
              ) : (
                <li>
                  <button
                    type="button"
                    className="option-btn"
                    onClick={(e) => resend_Email(null)}
                  >
                    <i className="fa fa-envelope-open " aria-hidden="true"></i>
                    Resend Invitation
                  </button>
                </li>
              )}
              {managerSelected ? (
                ""
              ) : (
                <li>
                  <button
                    type="button"
                    className="option-btn"
                    onClick={assignManagerAccess}
                  >
                    <i className="fa fa-user-plus" aria-hidden="true"></i>Enroll
                    As Manager
                  </button>
                </li>
              )}
              {managerSelected ? (
                ""
              ) : (
                <li>
                  <button
                    type="button"
                    className="option-btn"
                    onClick={(e) => handleAccessModel(true)}
                  >
                    <i className="fa fa-minus-circle" aria-hidden="true"></i>
                    Remove Manager Access
                    {/* onClick={deleteMemberBulk}  onClick={removeManagerAccess}> handleAccessModel  */}
                  </button>
                </li>
              )}
              {managerSelected ? (
                ""
              ) : (
                <li>
                  <button
                    type="button"
                    className="option-btn"
                    onClick={(e) => handleModel(true)}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>Delete
                  </button>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    handleSubmit={deleteMemberBulk}
                    onHide={() => setModalShow(false)}
                    onExited={ModalExit}
                  />

                  <RemoveAccessModal
                    show={modalAccess}
                    handleSubmit={removeManagerAccess}
                    onHide={() => setModalAccess(false)}
                    onExited={ModalExit}
                  />
                </li>
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
        {/* </> */}
        {/* ) : ( */}

        {loading && loading ? ( 
          <>
            <div className="loaderCourses">
            <Spinner animation="border" variant="danger" />
            </div>
          </>
         ) : ( 

          <>
            <div className="datatble-wrap">
              <DataTable
              data={members}
              columns={tableColumns}
              selectableRows
              selectableRowsComponent={BootyCheckbox}
              onSelectedRowsChange={handleSelect}
              clearSelectedRows={toggledClearRows}
              showPaginationBottom={false}
              noHeader={true}
              center={true}           
              progressPending={dataProgress}
              
              />
            </div>
         </>
        )}

        {!loading &&
          state?.teamsmembers &&
          state?.teamsmembers &&
          state?.teamsmembers.count > 12 &&
          response && (
            <div className="d-flex justify-content-center">
              <Pagination size="sm" className="custom-pagination">
                <Pagination.First
                  disabled={!state?.teamsmembers?.previous}
                  className={`${
                    state?.teamsmembers?.previous ? "paginationStyle" : ""
                  } `}
                  onClick={handlePrevious}
                />
                {state.paginationItem}
                <Pagination.Last
                  disabled={!state?.teamsmembers?.next}
                  onClick={handleNext}
                  className={`${
                    state?.teamsmembers?.next ? "paginationStyle" : ""
                  } `}
                />
              </Pagination>
            </div>
          )}

        {/* {!loading && state?.teams && state?.teams && state?.teams.count > 12 && response &&
             <div className="d-flex justify-content-center">
                <Pagination size="sm" className="custom-pagination">
                   <Pagination.First disabled={!state?.teams?.previous} className={`${state?.teams?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious}/>
                   {state.paginationItem}
                   <Pagination.Last disabled={!state?.teams?.next} onClick={handleNext} className={`${state?.teams?.next ? 'paginationStyle' :''} `}/>

                </Pagination>
             </div>
             } */}
      </div>
      <Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
                      <div className="module-modal-header my-4"><h4> Learners from your organization are managed here.</h4></div>
                   
                     <ol className="info-list">
                     <li><span>Learners can be added individually or in a group via CSV file by selecting the “Add Member” button. </span></li>
                     <li><span>Create a group by toggling to the “Group” tab and selecting “Create Group”.</span></li>
                     <li><span>The “Action” column enables you to 1&#41; delete learners or 2&#41; resend the invitation to them. If a third button is visible, platform “owners” and “account managers” can promote/demote member roles.</span></li>
                     <li><span>The user list can be filtered according to user role, i.e., Owner, Account Manager and Learner.</span></li>
                     <li><span>Search for members by 1&#41; name, 2&#41; email address, or 3&#41; keyword</span></li>
                     <li><span>Individual user pages show the courses they’ve been assigned and allow new courses to be assigned.</span></li>
                    </ol>
                    


                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
    </div>
  );
};
export default Members;
