import React from 'react';
import ScrollArea from 'react-scrollbar';
import videoImg from '~/assets/images/videoimage.png';

const ModuleOne = (props) => {
    const {value,index,length} = props

    return (
        <div className="videomodule">

            <ScrollArea
                speed={0.8}
                className="modulemodalscroll"
                contentClassName="content"
                horizontal={false}
            >
                <div className="video-content-wrap">
                <div className="d-flex justify-content-between m-b-20">
                            <h3 className="module-content-header">{value?.name}</h3>
                            <span className="module-count">
                      {`${index+1}`} / {length ? length :''} Modules
                            </span>
                        </div>
                    <div className="img-wrap">
                        <img src={value?.image} alt="" />
                    </div>
                    <div className="module-content m-t-20">
                        <p dangerouslySetInnerHTML={{ __html: `${value?.contents ? value?.contents:''}` }}>
                        </p>

                    </div>
                </div>
            </ScrollArea>

        </div>
    )

}

export default ModuleOne;
