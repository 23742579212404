import React, { useEffect, useState } from "react";
// import Breadcrumb from '../../../../../layout/breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AccordianItem from '../accordian';
// import {AccordionWithOpenandCloseIcon } from '../../../../../shared/accordian/accordianComponent'
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getLearnerCourseById } from "../../../../services";
import {getAllQuiz, getManagerCourse} from "../../../../../coursemanagement/services";
import LearnerModulePopView from "../../../learnermodulepopview";
import Spinner from "react-bootstrap/Spinner";


function LearnerCourseDetails({ match }) {
    const dispatch = useDispatch();
    const history = useHistory()
    const id = match.params.id;
    const response = useSelector(state => state.learnercourse?.learner_course?.response);
    const loading = useSelector(state => state.learnercourse?.learner_course?.loading);
        let quizResponse = useSelector(state => state.course.quiz_list?.response);
       const [quizzes, setQuizzes] = useState([])
    const [state, setState] = useState({
        course: {},
        popupView: false,
        other_courses:[],
        courseId: '',
        courseName: '',
        count: 0,
    });

    useEffect(() => {
        dispatch(getLearnerCourseById(id,function (res){
            setState({ ...state, course: res?.result,other_courses:res?.extra_courses,courseName: res?.result?.course_name });
        }))
    }, [state.popupView, id]);





    const checkPercentage = (course) => {
        let percentage = course?.completed_modules / course?.modules_count * 100;
        return percentage;
    };
    const handlePreviewModel = (id, name) => {
        setState({ ...state, popupView: true, courseId: id, courseName: name, count: state.count + 1 });
    };
    const handleModalHide = () => {
        dispatch(getLearnerCourseById(id));
        setState({ ...state, popupView: false })
    }

    const checkTextStatus = (status) => {
        if(status == 'In Progress'){
            return 'Continue'
        }else if(status == 'New'){
            return 'Start'


        }else if(status == 'Completed'){
            return 'Completed'
        }else if(status == 'Failed') {
            return 'Failed'
        }else{
            return 'Start'
        }

    }

    const handleNavigate = (id) => {
        history.push(`/learnercoursedetails/${id}`);
    }


    return (
        <div className="learning-path-listing learner-course">
            <ToastContainer autoClose={2000} />
            {/* <Breadcrumb parent="Learning paths" title="Learning pathname" /> */}
            {loading && loading ?
                <div className='row justify-content-center mt-5'>
                    <Spinner animation="border" variant="danger" />
                </div>
                :


                <>
                {state?.course?.course_name ? (
                    <>
                         <Row>
                        <Col md={9}>
                            <div className="d-flex justify-content-between learning-path-sec-head p-r-50 p-l-35">
                                <h3 className="sec-title">{state?.course?.course_name}</h3>
                                <div className="btn-wrap double-btn double-btn-smallspace d-flex">
                                    {/*<Button variant="danger">Delete</Button>*/}
                                    {/*<Button  variant="dark">Edit</Button>*/}
                                    {/*<Button>Enroll</Button>*/}
                                    <Button type="button" disabled={checkTextStatus(state?.course?.status) == 'Failed'} onClick={() => handlePreviewModel(state?.course?.id, state?.course?.course_name)}>{checkTextStatus(state?.course?.status)}</Button>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <div className="learning-content-wrap p-r-50 p-l-35">
                                <div className="learning-image-wrap">
                                    <div className="learing-banner text-center">
                                        <img src={state?.course?.banner_image} />
                                    </div>
                                    <div className="d-flex justify-content-center text-center learning-path-details">
                                        <div className="learning-path-det-item">
                                            <div className="icons-wrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.088" height="14.728" viewBox="0 0 14.088 14.728">
                                                    <g transform="translate(-5.191 -4.923)">
                                                        <g transform="translate(7.753 4.923)">
                                                            <path className="a" d="M171.868,4.923a4.483,4.483,0,1,0,4.483,4.483A4.455,4.455,0,0,0,171.868,4.923Zm0,7.684a3.2,3.2,0,1,1,3.2-3.2A3.171,3.171,0,0,1,171.868,12.607Z" transform="translate(-167.385 -4.923)" />
                                                        </g>
                                                        <g transform="translate(5.191 12.607)">
                                                            <path className="a" d="M135.044,123.077A7.024,7.024,0,0,0,128,130.121h1.281a5.763,5.763,0,1,1,11.526,0h1.281A7.024,7.024,0,0,0,135.044,123.077Z" transform="translate(-128 -123.077)" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <p className="assigned-by-text">Assigned by</p>
                                            <h5>{state?.course?.assigned_by}</h5>
                                        </div>
                                        <div className="learning-path-det-item">
                                            <div className="icons-wrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.966" height="18.516" viewBox="0 0 17.966 18.516">
                                                    <g transform="translate(0.2 -3.723)">
                                                        <g transform="translate(1.464 13.622)">
                                                            <path className="a" d="M41.947,241.231a2.562,2.562,0,1,0,2.562,2.562A2.546,2.546,0,0,0,41.947,241.231Zm0,4.391a1.83,1.83,0,1,1,1.83-1.83A1.812,1.812,0,0,1,41.947,245.622Z" transform="translate(-39.385 -241.231)" />
                                                        </g>
                                                        <g transform="translate(0 18.014)">
                                                            <path className="a" d="M4.025,359.385A4.014,4.014,0,0,0,0,363.41H.732a3.294,3.294,0,1,1,6.587,0h.732A4.014,4.014,0,0,0,4.025,359.385Z" transform="translate(0 -359.385)" />
                                                        </g>
                                                        <g transform="translate(10.979 13.622)">
                                                            <path className="a" d="M297.947,241.231a2.562,2.562,0,1,0,2.562,2.562A2.546,2.546,0,0,0,297.947,241.231Zm0,4.391a1.83,1.83,0,1,1,1.83-1.83A1.812,1.812,0,0,1,297.947,245.622Z" transform="translate(-295.385 -241.231)" />
                                                        </g>
                                                        <g transform="translate(9.515 18.014)">
                                                            <path className="a" d="M260.025,359.385A4.014,4.014,0,0,0,256,363.41h.732a3.294,3.294,0,1,1,6.587,0h.732A4.014,4.014,0,0,0,260.025,359.385Z" transform="translate(-256 -359.385)" />
                                                        </g>
                                                        <g transform="translate(6.221 3.923)">
                                                            <path className="a" d="M169.947,4.923a2.562,2.562,0,1,0,2.562,2.562A2.546,2.546,0,0,0,169.947,4.923Zm0,4.391a1.83,1.83,0,1,1,1.83-1.83A1.812,1.812,0,0,1,169.947,9.314Z" transform="translate(-167.385 -4.923)" />
                                                        </g>
                                                        <g transform="translate(4.757 8.314)">
                                                            <path className="a" d="M132.025,123.077A4.014,4.014,0,0,0,128,127.1h.732a3.294,3.294,0,1,1,6.587,0h.732A4.014,4.014,0,0,0,132.025,123.077Z" transform="translate(-128 -123.077)" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <p className="assigned-by-text">Group</p>
                                            <h5>-</h5>
                                        </div>
                                        <div className="learning-path-det-item">
                                            <div className="icons-wrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.416" height="16.012" viewBox="0 0 15.416 16.012">
                                                    <path className="a" d="M37.233,1.6h-1.4V.526a.526.526,0,1,0-1.053,0V1.6H28.963V.526a.526.526,0,0,0-1.053,0V1.6h-1.4a2.248,2.248,0,0,0-2.246,2.246v9.725a2.248,2.248,0,0,0,2.246,2.246H37.233a2.248,2.248,0,0,0,2.246-2.246V3.842A2.248,2.248,0,0,0,37.233,1.6ZM25.316,3.842a1.194,1.194,0,0,1,1.193-1.193h1.4v1.07a.526.526,0,0,0,1.053,0V2.649h5.815v1.07a.526.526,0,1,0,1.053,0V2.649h1.4a1.194,1.194,0,0,1,1.193,1.193V6.25H25.316ZM37.233,14.759H26.509a1.194,1.194,0,0,1-1.193-1.193V7.3h13.11v6.264A1.194,1.194,0,0,1,37.233,14.759Z" transform="translate(-24.163 0.1)" />
                                                </svg>
                                            </div>
                                            <p className="assigned-by-text">Assigned On</p>
                                            <h5>{state?.course?.assigned_on}</h5>
                                        </div>

                                        <div className="learning-path-det-item">
                                            <div className="icons-wrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.416" height="16.012"
                                                     viewBox="0 0 15.416 16.012">
                                                    <path className="a"
                                                          d="M37.233,1.6h-1.4V.526a.526.526,0,1,0-1.053,0V1.6H28.963V.526a.526.526,0,0,0-1.053,0V1.6h-1.4a2.248,2.248,0,0,0-2.246,2.246v9.725a2.248,2.248,0,0,0,2.246,2.246H37.233a2.248,2.248,0,0,0,2.246-2.246V3.842A2.248,2.248,0,0,0,37.233,1.6ZM25.316,3.842a1.194,1.194,0,0,1,1.193-1.193h1.4v1.07a.526.526,0,0,0,1.053,0V2.649h5.815v1.07a.526.526,0,1,0,1.053,0V2.649h1.4a1.194,1.194,0,0,1,1.193,1.193V6.25H25.316ZM37.233,14.759H26.509a1.194,1.194,0,0,1-1.193-1.193V7.3h13.11v6.264A1.194,1.194,0,0,1,37.233,14.759Z"
                                                          transform="translate(-24.163 0.1)"/>
                                                </svg>
                                            </div>
                                            <p className="assigned-by-text">Due Date</p>
                                            <h5>{state?.course?.due_date}</h5>
                                        </div>
                                        <div className="learning-path-det-item">
                                            <div className="icons-wrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.632" height="17.632" viewBox="0 0 17.632 17.632">
                                                    <g transform="translate(0.05 0.05)">
                                                        <path className="a" d="M8.766,0a8.766,8.766,0,1,0,8.766,8.766A8.776,8.776,0,0,0,8.766,0Zm0,16.252a7.486,7.486,0,1,1,7.486-7.486A7.495,7.495,0,0,1,8.766,16.252Z" />
                                                        <g transform="translate(7.699 4.287)">
                                                            <path className="a" d="M228.909,129.676h-2.773v-3.839a.64.64,0,1,0-1.28,0v4.479a.64.64,0,0,0,.64.64h3.412a.64.64,0,1,0,0-1.28Z" transform="translate(-224.857 -125.197)" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <p className="assigned-by-text">Duration</p>
                                            <h5 className="d-flex"> {state?.course?.duration} Mins</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="learning-det-cont">
                                    <div className="learning-det-boxes">
                                        <h4 className="sec-sub-head">Description</h4>
                                        <p>
                                            {state?.course?.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="learning-det-cont m-t-50">
                                    <div className="learning-det-boxes">
                                        {state?.course?.modules && state?.course?.modules?.length ?
                                            <div className="d-flex justify-content-between">
                                                <h4 className="sec-sub-head">Details</h4>
                                                <div
                                                    className="d-flex  align-items-center justify-content-end learing-detail-progress">
                                                    <div className="learning-progress progress">
                                                        <div className="progress-bar"
                                                            style={{ width: `${checkPercentage(state?.course)}%` }}>&nbsp;</div>
                                                    </div>

                                                    <span
                                                        className="progress-text">{state?.course?.completed_modules ? state?.course?.completed_modules : 0 }/{state?.course?.modules_count}</span>

                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                                <div>
                                    {state?.course?.modules && state?.course?.modules?.length ?

    < AccordianItem modules={state?.course?.quiz && state?.course?.quiz?.length ? [...state?.course?.modules, ...state?.course?.quiz?.map((el)=>{
                 return {
                       ...el,
                       template:'quiz'
                  }
                }) ] :state?.course?.modules}/>
                                        :
                                        'No modules'
                                    }


                                </div>
                            </div>
                        </Col>
                        <Col md={3} className="m-b-30 course-box-outer">
                            <div className="sideboxes">
                            <h4 className="sec-sub-head m-b-20">Other Assigned Courses</h4>
                                {state?.other_courses && state?.other_courses && state?.other_courses?.length ? state?.other_courses.map((el) => {
                                    return (

                                        <>
                                            <div className="common-card coursecard mb-4">
                                                <div className="course-box-top">
                                                    <div className="course-listing-img">
                                                        <img src={el?.image ? el?.image : ''} alt="course image" />
                                                    </div>
                                                    <div className="common-card-detail-wrap box-listing">
                                                        <h4>{el?.course__name}</h4>
                                                        <p>{el?.course__description}</p>
                                                    </div>
                                                    {/*<div className="coommon-card-det-number d-flex justify-content-between">*/}
                                                    {/*    <span className="common-card-oth-det-left">Modules <span className="det-numb">{el?.modules_count}</span> </span>*/}
                                                    {/*     <span className="common-card-oth-det-left">Quizzes <span className="det-numb">{el?.quizzes_count}</span> </span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="common-card-footer border-top-footer">
                                                    <div className="common-card-footer-cont course-card-footer no-border d-flex flex-wrap justify-content-between align-items-center">
                                                        {/*<div className="grp-name w-100">Group name</div>*/}
                                                        {/* <button type="button"
                                                            className="btn border-btn" onClick={() => handlePreviewModel(el?.id, el?.course__name)}>{checkTextStatus(el?.status)}
                                                        </button> */}
                                                        <button type="button"
                                                            className="btn border-btn" onClick={() => handleNavigate(el?.id, el?.course__name)}>{checkTextStatus(el?.status)}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>

                                        </>


                                    );

                                })


                                    :
                                    <div className="shadow-box whitebox no-course-box text-center">No other courses available</div>

                                }

                            </div>
                        </Col>
                    </Row>
                    </>
                ) : (
                    <>
                     <div className="whitebox no-course-box text-center">Course details not available</div>
                     </>
                )}
                   
                </>

            }

            <LearnerModulePopView
                show={state.popupView}
                name={state?.courseName}
                course={state?.course}
                courseId={state.courseId}
                count={state.count}
                onHide={() => handleModalHide()}

            />
        </div>

    );
};

export default LearnerCourseDetails;
