 import React from 'react';
import Courses from './components/myLearning';
import CompletedLearning from "./components/completedlearning";
import FailedLearning from "./components/failedlearing";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
function myLearningListing() {
    return (
        <div>

            <div className="tab-wrapper">
                <Tabs defaultActiveKey={'AvailableQuizzes'} id="uncontrolled-tab-example" className="m-b-30">
                    <Tab eventKey="AvailableQuizzes" title="Available Learning Paths">
                        <Courses />
                    </Tab>
                    <Tab eventKey="Drafts" title="Completed Learning Paths">
                        <CompletedLearning/>
                    </Tab>
                    <Tab eventKey="Failed" title="Failed Learning Paths">
                        <FailedLearning/>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default myLearningListing;
