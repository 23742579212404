import {createAction, handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {
    _getManagerCoursesList,
    _getManagerDraftCourses,
    _nextAvailableCourse,
    _previousAvailableCourse,
    _previousDraftCourse,
    _nextDraftCourse,
    _deleteCourse,
    _addCourse,
    _addCourseModule,
    _getMembersList, _getAllGroupsList, _getAllQuiz, _assignCourse,_updateCourse,_updateCourseModule,_deleteCourseModule,_getCourseModuleManager,_getManagerCourse,
    _getCourseAssignments,
    _updateAssignCourse,
    _getCourseAssignmentsById,
    _deleteCourseChoice,
    _cloneCourse,
    _getAdminCoursesList,
    _getDeletedCourses,
    _nextAdminCourse,
    _previousAdminCourse,
    _nextDeletedCourse,
    _previousDeletedCourse,
    _getAdminCourse,
    _permanentlyDeleteCourse,
    _restoreCourse,
    _permanentlyBulkDeleteCourse,
    _restoreBulkCourse,
    _edLinkGetAssignment,
    _getCoarseCategories

} from '~shared/httpService/api';
import { ToastContainer, toast } from 'react-toastify';


const initialState = {
    course_listing:{ loading: false, response: {}, hasError: false, error: {} },
    course_listing_admin:{ loading: false, response: {}, hasError: false, error: {} },
    course_listing_admin_draft:{ loading: false, response: {}, hasError: false, error: {} },
    course_listing_draft:{ loading: false, response: {}, hasError: false, error: {} },
    newly_added_course:{ loading: false, response: {}, hasError: false, error: {} },
    newly_added_course_module:{ loading: false, response: {}, hasError: false, error: {} },
    member_list: { loading: false, response: {}, hasError: false, error: {} },
    all_group_list: { loading: false, response: {}, hasError: false, error: {} },
    quiz_list: { loading: false, response: {}, hasError: false, error: {} },
    assign_course: { loading: false, response: {}, hasError: false, error: {} },
    ////

    get_edlink_assignment: { loading: false, response: {}, hasError: false, error: {} },
///
    updated_course:{ loading: false, response: {}, hasError: false, error: {} },
    updated_module:{ loading: false, response: {}, hasError: false, error: {} },
    deleted_module:{ loading: false, response: {}, hasError: false, error: {} },
    get_module_manager:{ loading: false, response: {}, hasError: false, error: {} },
    get_course_manager:{ loading: false, response: {}, hasError: false, error: {} },
    get_course_assignments:{ loading: false, response: {}, hasError: false, error: {} },
    get_course_assigment_by_id:{ loading: false, response: {}, hasError: false, error: {} },
    updated_course_assigment:{ loading: false, response: {}, hasError: false, error: {} },
    deleted_choice:{ loading: false, response: {}, hasError: false, error: {} },
    cloned_course : { loading: false, response: {}, hasError: false, error: {} },
    admin_course : { loading: false, response: {}, hasError: false, error: {} },
    deleted_courses : { loading: false, response: {}, hasError: false, error: {} },
    restore_course : { loading: false, response: {}, hasError: false, error: {} },
    restore_bulk_course : { loading: false, response: {}, hasError: false, error: {} },
    permanently_delete_course : { loading: false, response: {}, hasError: false, error: {} },
    bulk_delete_course : { loading: false, response: {}, hasError: false, error: {} },
    course_categories:{loading: false, response: {}, hasError: false, error: {}}

};

export const getCoursesManagerStart = createAction(ActionTypes.GET_COURSES_MANAGER_LIST);
export const getCoursesManagerSuccess = createAction(ActionTypes.GET_COURSES_MANAGER_LIST_SUCCESS, response => response);
export const getCoursesManagerError = createAction(ActionTypes.GET_COURSES_MANAGER_LIST_ERROR, error => error);

export const getManagerCoursesList = (search, startDate, endDate, createdby,category,page) => (dispatch) => {
    dispatch(getCoursesManagerStart());
    return _getManagerCoursesList(search, startDate, endDate, createdby, category,page).then((response) => {
        dispatch(getCoursesManagerSuccess(response));
    }).catch((error) => {
        dispatch(getCoursesManagerError(error));

    });
};

export const getCoursesAdminStart = createAction(ActionTypes.GET_COURSES_ADMIN_LIST);
export const getCoursesAdminSuccess = createAction(ActionTypes.GET_COURSES_ADMIN_LIST_SUCCESS, response => response);
export const getCoursesAdminError = createAction(ActionTypes.GET_COURSES_ADMIN_LIST_ERROR, error => error);

export const getAdminCoursesList = (search, startDate, endDate, page, category) => (dispatch) => {
    dispatch(getCoursesAdminStart());
    return _getAdminCoursesList(search, startDate, endDate, page, category).then((response) => {
        dispatch(getCoursesAdminSuccess(response));

    }).catch((error) => {
        dispatch(getCoursesAdminError(error));

    });
};

export const getDeletedCoursesStart = createAction(ActionTypes.GET_DELETED_COURSES);
export const getDeletedCoursesSuccess = createAction(ActionTypes.GET_DELETED_COURSES_SUCCESS, response => response);
export const getDeletedCoursesError = createAction(ActionTypes.GET_DELETED_COURSES_ERROR, error => error);

export const getDeletedCourses = ( search, startDate, endDate,page) => (dispatch) => {
    dispatch(getDeletedCoursesStart());
    return _getDeletedCourses(search, startDate, endDate,page).then((response) => {
        dispatch(getDeletedCoursesSuccess(response));
    }).catch((error) => {
        dispatch(getDeletedCoursesError(error));

    });
};

//Deleted Courses
export const paginationDeletedCoursesStart = createAction(ActionTypes.DELETED_COURSES_PAGINATION_NEXT);
export const paginationDeletedCoursesSuccess = createAction(ActionTypes.DELETED_COURSES_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationDeletedCoursesError = createAction(ActionTypes.DELETED_COURSES_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationDeletedCourses = (url) => (dispatch) => {
    dispatch(paginationDeletedCoursesStart());
    return _nextDeletedCourse(url).then((response) => {
        dispatch(paginationDeletedCoursesSuccess(response));
    }).catch((error) => {
        dispatch(paginationDeletedCoursesError(error));
        toast.error('Something went wrong')
    });
};


export const paginationPreviousDeletedCoursesStart = createAction(ActionTypes.DELETED_COURSES_PAGINATION_PREVIOUS);
export const paginationPreviousDeletedCoursesSuccess = createAction(ActionTypes.DELETED_COURSES_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousDeletedCoursesError = createAction(ActionTypes.DELETED_COURSES_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousDeletedCourses = (url) => (dispatch) => {
    dispatch(paginationPreviousDeletedCoursesStart());
    return _previousDeletedCourse(url).then((response) => {
        dispatch(paginationPreviousDeletedCoursesSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousDeletedCoursesError(error));
        toast.error('Something went wrong')

    });
};

export const restoreDeletedCourseStart = createAction(ActionTypes.RESTORE_DELETED_COURSE);
export const restoreDeletedCourseSuccess = createAction(ActionTypes.RESTORE_DELETED_COURSE_SUCCESS, response => response);
export const restoreDeletedCourseError = createAction(ActionTypes.RESTORE_DELETED_COURSE_ERROR, error => error);


export const restoreCourse = (row,callback) => (dispatch) => {
    dispatch(restoreDeletedCourseStart());
    return _restoreCourse(row).then((response) => {
        dispatch(restoreDeletedCourseSuccess(row));
        callback(row)
        toast.success('Course has been  restored successfully')

    }).catch((error) => {
        dispatch(restoreDeletedCourseError(error));
        toast.error('Something went wrong')
    });
};


export const restoreCourseDeletedCourseStart = createAction(ActionTypes.RESTORE_BULK_DELETED_COURSE);
export const restoreCourseDeletedCourseSuccess = createAction(ActionTypes.RESTORE_BULK_DELETED_COURSE_SUCCESS, response => response);
export const restoreCourseDeletedCourseError = createAction(ActionTypes.RESTORE_BULK_DELETED_COURSE_ERROR, error => error);


export const restoreBulkCourse = (row,callback) => (dispatch) => {
    dispatch(restoreCourseDeletedCourseStart());
    return _restoreBulkCourse(row).then((response) => {
        dispatch(restoreCourseDeletedCourseSuccess(row));
        callback(row)
        toast.success('Courses has been  restored successfully')

    }).catch((error) => {
        dispatch(restoreCourseDeletedCourseError(error));
        toast.error('Something went wrong')
    });
};



export const permanentlyDeleteCourseStart = createAction(ActionTypes.PERMANENTLY_DELETE_COURSE);
export const permanentlyDeleteCourseSuccess = createAction(ActionTypes.PERMANENTLY_DELETE_COURSE_SUCCESS, response => response);
export const permanentlyDeleteCourseError = createAction(ActionTypes.PERMANENTLY_DELETE_COURSE_ERROR, error => error);


export const permanentlyDeleteCourse = (row,callback) => (dispatch) => {
    dispatch(permanentlyDeleteCourseStart());
    return _permanentlyDeleteCourse(row).then((response) => {
        dispatch(permanentlyDeleteCourseSuccess(row));
        callback(row)
        toast.success('Course has been  deleted permanently successfully')

    }).catch((error) => {
        dispatch(permanentlyDeleteCourseError(error));
        toast.error('Something went wrong')
    });
};


export const permanentlyDeleteBulkCourseStart = createAction(ActionTypes.PERMANENTLY_DELETE_BULK_COURSE);
export const permanentlyDeleteBulkCourseSuccess = createAction(ActionTypes.PERMANENTLY_DELETE_BULK_COURSE_SUCCESS, response => response);
export const permanentlyDeleteBulkCourseError = createAction(ActionTypes.PERMANENTLY_DELETE_BULK_COURSE_ERROR, error => error);


export const permanentlyBulkDeleteCourse = (row,callback) => (dispatch) => {
    dispatch(permanentlyDeleteBulkCourseStart());
    return _permanentlyBulkDeleteCourse(row).then((response) => {
        dispatch(permanentlyDeleteBulkCourseSuccess(row));
        callback('deleted')
        toast.success('Courses has been  deleted permanently successfully')

    }).catch((error) => {
        dispatch(permanentlyDeleteBulkCourseError(error));
        toast.error('Something went wrong')
    });
};


export const paginationAdminCourseStart = createAction(ActionTypes.ADMIN_COURSE_PAGINATION_NEXT);
export const paginationAdminCourseSuccess = createAction(ActionTypes.ADMIN_COURSE_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationAdminCourseError = createAction(ActionTypes.ADMIN_COURSE_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationAdminCourse = (url) => (dispatch) => {
    dispatch(paginationAdminCourseStart());
    return _nextAdminCourse(url).then((response) => {
        dispatch(paginationAdminCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationAdminCourseError(error));
        toast.error('Something went wrong')
    });
};


export const paginationPreviousAdminCourseStart = createAction(ActionTypes.ADMIN_COURSE_PAGINATION_PREVIOUS);
export const paginationPreviousAdminCourseSuccess = createAction(ActionTypes.ADMIN_COURSE_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousAdminCourseError = createAction(ActionTypes.ADMIN_COURSE_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationAdminCourse = (url) => (dispatch) => {
    dispatch(paginationPreviousAdminCourseStart());
    return _previousAdminCourse(url).then((response) => {
        dispatch(paginationPreviousAdminCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousAdminCourseError(error));
        toast.error('Something went wrong')

    });
};



export const getCourseAdminStart = createAction(ActionTypes.GET_COURSE_ADMIN);
export const getCourseAdminSuccess = createAction(ActionTypes.GET_COURSE_ADMIN_SUCCESS, response => response);
export const getCourseAdminError = createAction(ActionTypes.GET_COURSE_ADMIN_ERROR, error => error);

export const getAdminCourse = (id,callback) => (dispatch) => {
    dispatch(getCourseAdminStart());
    return _getAdminCourse(id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getCourseAdminSuccess(response));
    }).catch((error) => {
        dispatch(getCourseAdminError(error));
    });
};


export const getCourseManagerStart = createAction(ActionTypes.GET_COURSE_MANAGER);
export const getCourseManagerSuccess = createAction(ActionTypes.GET_COURSE_MANAGER_SUCCESS, response => response);
export const getCourseManagerError = createAction(ActionTypes.GET_COURSE_MANAGER_ERROR, error => error);

export const getManagerCourse = (id,callback) => (dispatch) => {
    dispatch(getCourseManagerStart());
    return _getManagerCourse(id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getCourseManagerSuccess(response));

    }).catch((error) => {
        dispatch(getCourseManagerError(error));
    });
};

export const getDraftCoursesManagerStart = createAction(ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT);
export const getDraftCoursesManagerSuccess = createAction(ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT_SUCCESS, response => response);
export const getDraftCoursesManagerError = createAction(ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT_ERROR, error => error);

export const getDraftCoursesManagerList = ( search, startDate, endDate, publish, createdby,category,page) => (dispatch) => {
    dispatch(getDraftCoursesManagerStart());
    return _getManagerDraftCourses(search, startDate, endDate, publish, createdby,category,page).then((response) => {
        dispatch(getDraftCoursesManagerSuccess(response));
    }).catch((error) => {
        dispatch(getDraftCoursesManagerError(error));

    });
};

export const paginationAvailableCourseStart = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT);
export const paginationAvailableCourseSuccess = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationAvailableCourseError = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationAvailableCourse = (url) => (dispatch) => {
    dispatch(paginationAvailableCourseStart());
    return _nextAvailableCourse(url).then((response) => {
        dispatch(paginationAvailableCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationAvailableCourseError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousAvailableCourseStart = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS);
export const paginationPreviousAvailableCourseSuccess = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousAvailableCourseError = createAction(ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationAvailableCourse = (url) => (dispatch) => {
    dispatch(paginationPreviousAvailableCourseStart());
    return _previousAvailableCourse(url).then((response) => {
        dispatch(paginationPreviousAvailableCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousAvailableCourseError(error));
        toast.error('Something went wrong')

    });
};
//Draft
export const paginationDraftCourseStart = createAction(ActionTypes.DRAFT_COURSE_PAGINATION_NEXT);
export const paginationDraftCourseSuccess = createAction(ActionTypes.DRAFT_COURSE_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationDraftCourseError = createAction(ActionTypes.DRAFT_COURSE_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationDraftCourse = (url) => (dispatch) => {
    dispatch(paginationDraftCourseStart());
    return _nextDraftCourse(url).then((response) => {
        dispatch(paginationDraftCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationDraftCourseError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousDraftCourseStart = createAction(ActionTypes.DRAFT_COURSE_PAGINATION_PREVIOUS);
export const paginationPreviousDraftCourseSuccess = createAction(ActionTypes.DRAFT_COURSE_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousDraftCourseError = createAction(ActionTypes.DRAFT_COURSE_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationDraftCourse = (url) => (dispatch) => {
    dispatch(paginationPreviousDraftCourseStart());
    return _previousDraftCourse(url).then((response) => {
        dispatch(paginationPreviousDraftCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousDraftCourseError(error));
        toast.error('Something went wrong')

    });
};


export const deleteCourseStart = createAction(ActionTypes.DELETE_COURSE);
export const deleteCourseSuccess = createAction(ActionTypes.DELETE_COURSE_SUCCESS, response => response);
export const deleteCourseError = createAction(ActionTypes.DELETE_COURSE_ERROR, error => error);

export const deleteCourse = (row,history) => (dispatch) => {
    dispatch(deleteCourseStart());


    return _deleteCourse(row).then((response) => {
        dispatch(deleteCourseSuccess(row));
        toast.success('Course has been  deleted  successfully')
        if(row.is_published){
            history.push('/coursemanagement')
        }else {
            history.push('/draft/courses')
        }


    }).catch((error) => {
        dispatch(deleteCourseError(error));
        toast.error('Something went wrong')
    });
};

export const addCourseStart = createAction(ActionTypes.ADD_COURSE);
export const addCourseSuccess = createAction(ActionTypes.ADD_COURSE_SUCCESS, response => response);
export const addCourseError = createAction(ActionTypes.ADD_COURSE_ERROR, error => error);

export const addCourse = (formData,callback) => (dispatch) => {
    dispatch(addCourseStart());

    return _addCourse(formData).then((response) => {
        if(response){
            toast.success('Course detail has been created Successfully')
            callback(response)
        }
        dispatch(addCourseSuccess(response));
    }).catch((error) => {
        dispatch(addCourseError(error));
        if(error){
            if(error.image){
                toast.error(error.image[0])
            }
        }else{
            toast.error('Something went wrong')
        }


    });
};
export const updateCourseStart = createAction(ActionTypes.UPDATE_COURSE);
export const updateCourseSuccess = createAction(ActionTypes.UPDATE_COURSE_SUCCESS, response => response);
export const updateCourseError = createAction(ActionTypes.UPDATE_COURSE_ERROR, error => error);

export const updateCourse = (formData,id,callback) => (dispatch) => {
    dispatch(updateCourseStart());

    return _updateCourse(formData,id).then((response) => {
        if(response){
            callback(response)
            toast.success('Course detail has been updated Successfully')
        }
        dispatch(updateCourseSuccess(response));
    }).catch((error) => {
        dispatch(updateCourseError(error));
        if(error){
            if(error.image){
                toast.error(error.image[0])
            }
        }else{
            toast.error('Something went wrong')
        }

    });
};

export const cloneCourseStart = createAction(ActionTypes.CLONE_COURSE);
export const cloneCourseSuccess = createAction(ActionTypes.CLONE_COURSE_SUCCESS, response => response);
export const cloneCourseError = createAction(ActionTypes.CLONE_COURSE_ERROR, error => error);

export const cloneCourse = (id,history) => (dispatch) => {
    dispatch(cloneCourseStart());

    return _cloneCourse(id).then((response) => {
        toast.success('Course has  Cloned Successfully')
        if(response.data.course_id){
            history.push(`/editcourse/${response.data.course_id}`)
        }
        dispatch(cloneCourseSuccess(response));
    }).catch((error) => {
        dispatch(cloneCourseError(error));


    });
};


export const addCourseModuleStart = createAction(ActionTypes.ADD_COURSE_MODULE);
export const addCourseModuleSuccess = createAction(ActionTypes.ADD_COURSE_MODULE_SUCCESS, response => response);
export const addCourseModuleError = createAction(ActionTypes.ADD_COURSE_MODULE_ERROR, error => error);

export const addCourseModule = (formData,id,callback) => (dispatch) => {
    dispatch(addCourseModuleStart());

    return _addCourseModule(formData,id).then((response) => {
        if(response){
            callback(response)
            toast.success('Modules has been  added  successfully')
        }
        dispatch(addCourseModuleSuccess(response));
    }).catch((error) => {
        dispatch(addCourseModuleError(error));
        if(error?.template){
            toast.error(error?.template[0])
        }else {
            toast.error('Something went wrong')
        }
    });
};

export const getModuleManagerStart = createAction(ActionTypes.GET_COURSE_MODULE_MANAGER);
export const getModuleManagerSuccess = createAction(ActionTypes.GET_COURSE_MODULE_MANAGER_SUCCESS, response => response);
export const getModuleManagerError = createAction(ActionTypes.GET_COURSE_MODULE_MANAGER_ERROR, error => error);

export const getManagerModule = (id) => (dispatch) => {
    dispatch(getModuleManagerStart());
    return _getCourseModuleManager(id).then((response) => {
        dispatch(getModuleManagerSuccess(response));
    }).catch((error) => {
        dispatch(getModuleManagerError(error));
        toast.error('Something went wrong')
    });
};

export const getMemberListStart = createAction(ActionTypes.GET_MEMBERS);
export const getMemberListSuccess = createAction(ActionTypes.GET_MEMBERS_SUCCESS, response => response);
export const getMemberListError = createAction(ActionTypes.GET_MEMBERS_ERROR, error => error);

export const getMemberList = () => (dispatch) => {
    dispatch(getMemberListStart());
    return _getMembersList().then((response) => {
        dispatch(getMemberListSuccess(response));
    }).catch((error) => {
        dispatch(getMemberListError(error));
    });
};

export const getAllGroupsStart = createAction(ActionTypes.GET_ALL_GROUPS);
export const getAllGroupsSuccess = createAction(ActionTypes.GET_ALL_GROUPS_SUCCESS, response => response);
export const getAllGroupsError = createAction(ActionTypes.GET_ALL_GROUPS_ERROR, error => error);

export const getAllGroup = (callback,callbackError) => (dispatch) => {
    dispatch(getAllGroupsStart());
    return _getAllGroupsList().then((response) => {
        callback(response)
        dispatch(getAllGroupsSuccess(response));
    }).catch((error) => {
        callbackError(error)
        dispatch(getAllGroupsError(error));

    });
};

export const getAllQuizStart = createAction(ActionTypes.GET_ALL_QUIZ);
export const getAllQuizSuccess = createAction(ActionTypes.GET_ALL_QUIZ_SUCCESS, response => response);
export const getAllQuizError = createAction(ActionTypes.GET_ALL_QUIZ_ERROR, error => error);

export const getAllQuiz = () => (dispatch) => {
    dispatch(getAllQuizStart());
    return _getAllQuiz().then((response) => {
        dispatch(getAllQuizSuccess(response));
    }).catch((error) => {
        dispatch(getAllQuizError(error));
    });
};

export const assignCourseStart = createAction(ActionTypes.ASSIGN_COURSE);
export const assignCourseSuccess = createAction(ActionTypes.ASSIGN_COURSE_SUCCESS, response => response);
export const assignCourseError = createAction(ActionTypes.ASSIGN_COURSE_ERROR, error => error);

export const assignCourse = (data, userId,history,callback) => (dispatch) => {
    dispatch(assignCourseStart());
    return _assignCourse(data, userId).then((response) => {
        if(response){
            toast.success('Learners have been enrolled successfully in the course! They can find it in their Assignment tab');
            if(history) {
                history.push('/coursemanagement')
            }
        }
        dispatch(assignCourseSuccess(response));
    }).catch((error) => {
       
        dispatch(assignCourseError(error));
        if(error){
           
            if(error?.error?.errorMessage) {
                toast.error(error?.error?.errorMessage)
            }else{
              
                toast.error('Something went  wrong')
            }


            callback(error)
        }
    });
};
///////////////edlinkAssignment///////


export const getEdLinkassignmentStart = createAction(ActionTypes.GET_EDLINK_ASSIGNMENT_START);
export const getEdLinkassignmentSuccess = createAction(ActionTypes.GET_EDLINK_ASSIGNMENT_SUCCESS, response => response);
export const getEdLinkassignmentError = createAction(ActionTypes.GET_EDLINK_ASSIGNMENT_ERROR, error => error);

export const edLinkAssignmet = (data, userId,history,callback) => (dispatch) => {
    dispatch(getEdLinkassignmentStart());
    return _edLinkGetAssignment(data, userId).then((response) => {
            toast.success('Edlink enrollment published successfully')
            if(history) {
                history.push('/learningpaths')
            }
        dispatch(getEdLinkassignmentSuccess(response));
        // if (response) {
        //     toast.success('Course Assigned')
        //       }
    }).catch((error) => {
      
        dispatch(getEdLinkassignmentError(error));
        if(error){
           
            if(error?.error?.errorMessage) {
                toast.error(error?.error?.errorMessage)
            }else{
              
                toast.error('Something went  wrong')
            }


            callback(error)
        }
    });
};

///////////////////

export const updateCourseModuleStart = createAction(ActionTypes.UPDATE_COURSE_MODULE);
export const updateCourseModuleSuccess = createAction(ActionTypes.UPDATE_COURSE_MODULE_SUCCESS, response => response);
export const updateCourseModuleError = createAction(ActionTypes.UPDATE_COURSE_MODULE_ERROR, error => error);

export const updateCourseModule = (formData,id,callback) => (dispatch) => {
    dispatch(updateCourseModuleStart());

    return _updateCourseModule(formData,id).then((response) => {
        dispatch(updateCourseModuleSuccess(response));
        if(response){
            // toast.success('Modules has been  updated  successfully')
            callback(response)
        }

    }).catch((error) => {
        dispatch(updateCourseModuleError(error));
        if(error?.template){
            toast.error(error?.template[0])
        }else {
            toast.error('Something went wrong')
        }
    });
};
export const deleteCourseModuleStart = createAction(ActionTypes.DELETE_COURSE_MODULE);
export const deleteCourseModuleSuccess = createAction(ActionTypes.DELETE_COURSE_MODULE_SUCCESS, response => response);
export const deleteCourseModuleError = createAction(ActionTypes.DELETE_COURSE_MODULE_ERROR, error => error);

export const deleteCourseModule = (courseId,moduleId,callback) => (dispatch) => {
    dispatch(deleteCourseModuleStart());
    return _deleteCourseModule(courseId,moduleId).then((response) => {
        dispatch(deleteCourseModuleSuccess(response));
        callback('response')
        toast.success('Course Modules has been  deleted  successfully')

    }).catch((error) => {
        dispatch(deleteCourseModuleError(error));
        callback('error')
        toast.error('Something went wrong')
    });
};

export const deleteCourseChoiceStart = createAction(ActionTypes.DELETE_COURSE_CHOICE);
export const deleteCourseChoiceSuccess = createAction(ActionTypes.DELETE_COURSE_CHOICE_SUCCESS, response => response);
export const deleteCourseChoiceError = createAction(ActionTypes.DELETE_COURSE_CHOICE_ERROR, error => error);

export const deleteCourseChoice = (choiceId,callback) => (dispatch) => {
    dispatch(deleteCourseChoiceStart());
    return _deleteCourseChoice(choiceId).then((response) => {
        dispatch(deleteCourseChoiceSuccess(response))
        callback('response')
        toast.success('Choice has been  deleted  successfully')
    }).catch((error) => {
        dispatch(deleteCourseChoiceError(error));
        callback('error')
        toast.error('Something went wrong')
    });
};



export const getCourseAssignmentsStart = createAction(ActionTypes.ASSIGNMENT_LIST_COURSE);
export const getCourseAssignmentsSuccess = createAction(ActionTypes.ASSIGNMENT_LIST_COURSE_SUCCESS, response => response);
export const getCourseAssignmentsError = createAction(ActionTypes.ASSIGNMENT_LIST_COURSE_ERROR, error => error);

export const getCourseAssignments = (id) => (dispatch) => {
    dispatch(getCourseAssignmentsStart());
    return _getCourseAssignments(id).then((response) => {
        dispatch(getCourseAssignmentsSuccess(response));
    }).catch((error) => {
        dispatch(getCourseAssignmentsError(error));
        toast.error('Something went wrong')

    });
};
export const getCourseAssignmentsByIdStart = createAction(ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE);
export const getCourseAssignmentsByIdSuccess = createAction(ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE_SUCCESS, response => response);
export const getCourseAssignmentsByIdError = createAction(ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE_ERROR, error => error);

export const getCourseAssignmentsById = (id,assignid,callback,callbackError) => (dispatch) => {
    dispatch(getCourseAssignmentsByIdStart());
    return _getCourseAssignmentsById(id,assignid).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getCourseAssignmentsByIdSuccess(response));
    }).catch((error) => {
        dispatch(getCourseAssignmentsByIdError(error));
        if(error){
            if(error.detail){
                toast.error('Detail not Found')
                callbackError(error)
            }else{
                toast.error('Something went wrong')
                callbackError(error)
            }

        }

    });
};
export const updateAssignCourseStart = createAction(ActionTypes.UPDATE_COURSE_ASSIGNMENT);
export const updateAssignCourseSuccess = createAction(ActionTypes.UPDATE_COURSE_ASSIGNMENT_SUCCESS, response => response);
export const updateAssignCourseError = createAction(ActionTypes.UPDATE_COURSE_ASSIGNMENT_ERROR, error => error);

export const updateAssignCourse = (data, userId,assignid,history,callback) => (dispatch) => {
    dispatch(updateAssignCourseStart());
    return _updateAssignCourse(data, userId,assignid).then((response) => {
        if(response){
            toast.success('Learners have been enrolled successfully in the course! They can find it in their Assignment tab');
            history.push('/coursemanagement')
        }
        dispatch(updateAssignCourseSuccess(response));
    }).catch((error) => {
        dispatch(updateAssignCourseError(error));
       
        if(error){
            if(error?.error?.errorMessage) {
                toast.error(error?.error?.errorMessage);
            }else{

                toast.error('Something went wrong');
            }


            callback(error);
        }

    });
};

export const getCoarseCategoriesStart = createAction(ActionTypes.GET_COURSES_CATEGORIES);
export const getCoarseCategoriesSucess = createAction(ActionTypes.GET_COURSES_CATEGORIES_SUCCESS,response => response);
export const getCoarseCategoriesError = createAction(ActionTypes.GET_COURSES_CATEGORIES_SUCCESS,error => error);


export const getCoarseCategories = () => (dispatch) => {
    dispatch(getCoarseCategoriesStart());
    return _getCoarseCategories().then((response) => {
        dispatch(getCoarseCategoriesSucess(response));
    }).catch((error) => {
        dispatch(getCoarseCategoriesError(error));
        if(error?.error?.errorMessage) {
            toast.error(error?.error?.errorMessage);
        }else{

            toast.error('Something went wrong');
        }
    });
};


const reducer = handleActions({

    //Get Manger Courses
    [ActionTypes.GET_COURSES_MANAGER_LIST]: (state) => ({
        ...state,
        course_listing: {
            ...state.course_listing,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSES_MANAGER_LIST_SUCCESS]: (state, action) => ({
        ...state,

        course_listing: {
            ...state.course_listing,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSES_MANAGER_LIST_ERROR]: (state, action) => ({
        ...state,

        course_listing: {
            ...state.course_listing,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
    //Get Manger Course
    [ActionTypes.GET_COURSE_MANAGER]: (state) => ({
        ...state,
        get_course_manager: {
            ...state.get_course_manager,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSE_MANAGER_SUCCESS]: (state, action) => ({
        ...state,

        get_course_manager: {
            ...state.get_course_manager,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSE_MANAGER_ERROR]: (state, action) => ({
        ...state,

        get_course_manager: {
            ...state.get_course_manager,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Get Admin Course
    [ActionTypes.GET_COURSE_ADMIN]: (state) => ({
        ...state,
        admin_course: {
            ...state.admin_course,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSE_ADMIN_SUCCESS]: (state, action) => ({
        ...state,

        admin_course: {
            ...state.admin_course,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSE_ADMIN_ERROR]: (state, action) => ({
        ...state,

        admin_course: {
            ...state.admin_course,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

     //Get  Course categories
     [ActionTypes.GET_COURSES_CATEGORIES]: (state) => ({
        ...state,
        course_categories: {
            ...state.course_categories,
             loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSES_CATEGORIES_SUCCESS]: (state, action) => ({
        ...state,

        course_categories: {
            ...state.course_categories,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSES_CATEGORIES_ERROR]: (state, action) => ({
        ...state,

        admin_course: {
            ...state.course_categories,
            loading: false, hasError: false, error: action.payload
        },

    }),

    //Get Draft Manger Courses
    [ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT]: (state) => ({
        ...state,
        course_listing_draft: {
            ...state.course_listing_draft,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT_SUCCESS]: (state, action) => ({
        ...state,

        course_listing_draft: {
            ...state.course_listing_draft,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.GET_COURSES_MANAGER_LIST_DRAFT_ERROR]: (state, action) => ({
        ...state,

        course_listing_draft: {
            ...state.course_listing_draft,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
       //    Available Course Pagination Next
    [ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT]: (state) => ({
        ...state,
        course_listing: {
            ...state.course_listing,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        course_listing: {
            ...state.course_listing,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.AVAILABLE_COURSE_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        course_listing: {
            ...state.course_listing,
            loading: false, hasError: false, error: {}
        }
    }),
    //    Available Course Pagination Previous
    [ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        course_listing: {
            ...state.course_listing,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        course_listing: {
            ...state.course_listing,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.AVAILABLE_COURSE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        course_listing: {
            ...state.course_listing,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),
    //    Draft Course Pagination Next
    [ActionTypes.DRAFT_COURSE_PAGINATION_NEXT]: (state) => ({
        ...state,
        course_listing_draft: {
            ...state.course_listing_draft,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DRAFT_COURSE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        course_listing_draft: {
            ...state.course_listing_draft,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DRAFT_COURSE_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        course_listing_draft: {
            ...state.course_listing_draft,
            loading: false, hasError: false, error: {}
        }
    }),
    //    Draft Course Pagination Previous
    [ActionTypes.DRAFT_COURSE_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        course_listing_draft: {
            ...state.course_listing_draft,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DRAFT_COURSE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,


        course_listing_draft: {
            ...state.course_listing_draft,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DRAFT_COURSE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        course_listing_draft: {
            ...state.course_listing_draft,
            loading: false, hasError: false, error: {}
        }
    }),
    //ADD COURSE
    [ActionTypes.ADD_COURSE]: (state) => ({
        ...state,
        newly_added_course: {
            ...state.newly_added_course,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.ADD_COURSE_SUCCESS]: (state, action) => ({
        ...state,

        newly_added_course: {
            ...state.newly_added_course,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.ADD_COURSE_ERROR]: (state, action) => ({
        ...state,

        newly_added_course: {
            ...state.newly_added_course,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),
    //Update COURSE
    [ActionTypes.UPDATE_COURSE]: (state) => ({
        ...state,
        updated_course: {
            ...state.updated_course,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.UPDATE_COURSE_SUCCESS]: (state, action) => ({
        ...state,

        updated_course: {
            ...state.updated_course,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.UPDATE_COURSE_ERROR]: (state, action) => ({
        ...state,

        updated_course: {
            ...state.updated_course,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //ADD COURSE MODULE

    [ActionTypes.ADD_COURSE_MODULE]: (state) => ({
        ...state,
        newly_added_course_module: {
            ...state.newly_added_course_module,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.ADD_COURSE_MODULE_SUCCESS]: (state, action) => ({
        ...state,

        newly_added_course_module: {
            ...state.newly_added_course_module,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.ADD_COURSE_MODULE_ERROR]: (state, action) => ({
        ...state,

        newly_added_course_module: {
            ...state.newly_added_course_module,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),
    //Get Manger Module
    [ActionTypes.GET_COURSE_MODULE_MANAGER]: (state) => ({
        ...state,
        get_module_manager: {
            ...state.get_module_manager,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSE_MODULE_MANAGER_SUCCESS]: (state, action) => ({
        ...state,

        get_module_manager: {
            ...state.get_module_manager,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSE_MODULE_MANAGER_ERROR]: (state, action) => ({
        ...state,

        get_module_manager: {
            ...state.get_module_manager,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
    //UpdATE COURSE MODULE

    [ActionTypes.UPDATE_COURSE_MODULE]: (state) => ({
        ...state,
        updated_module: {
            ...state.updated_module,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.UPDATE_COURSE_MODULE_SUCCESS]: (state, action) => ({
        ...state,

        updated_module: {
            ...state.updated_module,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.UPDATE_COURSE_MODULE_ERROR]: (state, action) => ({
        ...state,

        updated_module: {
            ...state.updated_module,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Delete COURSE MODULE

    [ActionTypes.DELETE_COURSE_MODULE]: (state) => ({
        ...state,
        deleted_module: {
            ...state.deleted_module,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.DELETE_COURSE_MODULE_SUCCESS]: (state, action) => ({
        ...state,

        deleted_module: {
            ...state.deleted_module,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.DELETE_COURSE_MODULE_ERROR]: (state, action) => ({
        ...state,

        deleted_module: {
            ...state.deleted_module,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Delete COURSE CHOICE

    [ActionTypes.DELETE_COURSE_CHOICE]: (state) => ({
        ...state,
        deleted_choice: {
            ...state.deleted_choice,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),

    [ActionTypes.DELETE_COURSE_CHOICE_SUCCESS]: (state, action) => ({
        ...state,

        deleted_choice: {
            ...state.deleted_choice,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.DELETE_COURSE_CHOICE_ERROR]: (state, action) => ({
        ...state,

        deleted_choice: {
            ...state.deleted_choice,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //CREATE_COURSE
    [ActionTypes.CREATE_COURSE]: (state) => ({
        ...state, create_course: {
            ...state.create_course,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.CREATE_COURSE_SUCCESS]: (state, action) => ({
        ...state,
        create_course: {
            ...state.create_course,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.CREATE_COURSE_ERROR]: (state, action) => ({
        ...state,
        create_course: {
            ...state.create_course,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //MEMBER_LIST
    [ActionTypes.GET_MEMBERS]: (state) => ({
        ...state, member_list: {
            ...state.member_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_MEMBERS_SUCCESS]: (state, action) => ({
        ...state,
        member_list: {
            ...state.member_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_MEMBERS_ERROR]: (state, action) => ({
        ...state,
        member_list: {
            ...state.member_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //ALL_GROUP_LIST
    [ActionTypes.GET_ALL_GROUPS]: (state) => ({
        ...state, all_group_list: {
            ...state.all_group_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_ALL_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        all_group_list: {
            ...state.all_group_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_ALL_GROUPS_ERROR]: (state, action) => ({
        ...state,
        all_group_list: {
            ...state.all_group_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //GET_ALL_QUIZ
    [ActionTypes.GET_ALL_QUIZ]: (state) => ({
        ...state, quiz_list: {
            ...state.quiz_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_ALL_QUIZ_SUCCESS]: (state, action) => ({
        ...state,
        quiz_list: {
            ...state.quiz_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_ALL_QUIZ_ERROR]: (state, action) => ({
        ...state,
        quiz_list: {
            ...state.quiz_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //ASSIGN_COURSE
    [ActionTypes.ASSIGN_COURSE]: (state) => ({
        ...state, assign_course: {
            ...state.assign_course,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.ASSIGN_COURSE_SUCCESS]: (state, action) => ({
        ...state,
        assign_course: {
            ...state.assign_course,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.ASSIGN_COURSE_ERROR]: (state, action) => ({
        ...state,
        assign_course: {
            ...state.assign_course,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    // EDLINK ASSIGNMENT GET
    
    [ActionTypes.GET_EDLINK_ASSIGNMENT_START]: (state) => ({
        ...state, get_edlink_assignment: {
            ...state.get_edlink_assignment,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_EDLINK_ASSIGNMENT_SUCCESS]: (state, action) => ({
        ...state,
        get_edlink_assignment: {
            ...state.get_edlink_assignment,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_EDLINK_ASSIGNMENT_ERROR]: (state, action) => ({
        ...state,
        get_edlink_assignment: {
            ...state.get_edlink_assignment,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),



    //COURSE ASSIGNMENTS
    [ActionTypes.ASSIGNMENT_LIST_COURSE]: (state) => ({
        ...state,
        get_course_assignments: {
            ...state.get_course_assignments,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.ASSIGNMENT_LIST_COURSE_SUCCESS]: (state, action) => ({
        ...state,

        get_course_assignments: {
            ...state.get_course_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.ASSIGNMENT_LIST_COURSE_ERROR]: (state, action) => ({
        ...state,
        get_course_assignments: {
            ...state.get_course_assignments,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //COURSE ASSIGNMENTS BY ASSIGNMENT ID
    [ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE]: (state) => ({
        ...state,
        get_course_assigment_by_id: {
            ...state.get_course_assigment_by_id,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE_SUCCESS]: (state, action) => ({
        ...state,

        get_course_assigment_by_id: {
            ...state.get_course_assigment_by_id,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE_ERROR]: (state, action) => ({
        ...state,
        get_course_assigment_by_id: {
            ...state.get_course_assigment_by_id,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //UPDATE ASSIGNMENTS
    [ActionTypes.UPDATE_COURSE_ASSIGNMENT]: (state) => ({
        ...state,
        updated_course_assigment: {
            ...state.updated_course_assigment,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.UPDATE_COURSE_ASSIGNMENT_SUCCESS]: (state, action) => ({
        ...state,

        updated_course_assigment: {
            ...state.updated_course_assigment,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.UPDATE_COURSE_ASSIGNMENT_ERROR]: (state, action) => ({
        ...state,
        updated_course_assigment: {
            ...state.updated_course_assigment,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //CLONE COURSE

    [ActionTypes.CLONE_COURSE]: (state) => ({
        ...state,
        cloned_course: { loading: true, response: {}, hasError: false, error: {} }
    }),
    [ActionTypes.CLONE_COURSE_SUCCESS]: (state, action) => ({
        ...state,
        cloned_course: {
            ...state.cloned_course,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.CLONE_COURSE_ERROR]: (state, action) => ({
        ...state,
        cloned_course: {
            ...state.cloned_course,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),
///////////////////////////////////

    //Get Draft Admin Courses
    [ActionTypes.GET_DELETED_COURSES]: (state) => ({
        ...state,
        deleted_courses: {
            ...state.deleted_courses,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_DELETED_COURSES_SUCCESS]: (state, action) => ({
        ...state,

        deleted_courses: {
            ...state.deleted_courses,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.GET_DELETED_COURSES_ERROR]: (state, action) => ({
        ...state,

        deleted_courses: {
            ...state.deleted_courses,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
    //    Admin Course Pagination Next
    [ActionTypes.ADMIN_COURSE_PAGINATION_NEXT]: (state) => ({
        ...state,
        course_listing_admin: {
            ...state.course_listing_admin,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.ADMIN_COURSE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        course_listing_admin: {
            ...state.course_listing_admin,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.ADMIN_COURSE_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        course_listing_admin: {
            ...state.course_listing_admin,
            loading: false, hasError: false, error: {}
        }
    }),
    //   Admin Available Course Pagination Previous
    [ActionTypes.ADMIN_COURSE_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        course_listing_admin: {
            ...state.course_listing_admin,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.ADMIN_COURSE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        course_listing_admin: {
            ...state.course_listing_admin,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.ADMIN_COURSE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        course_listing_admin: {
            ...state.course_listing_admin,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),


    //   DELETED COURSES Pagination NEXT
    [ActionTypes.DELETED_COURSES_PAGINATION_NEXT]: (state) => ({
        ...state,
        deleted_courses: {
            ...state.deleted_courses,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DELETED_COURSES_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        deleted_courses: {
            ...state.deleted_courses,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DELETED_COURSES_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        deleted_courses: {
            ...state.deleted_courses,
            loading: false, hasError: false, error: {}
        }
    }),
    //   DELETED COURSES Pagination Previous
    [ActionTypes.DELETED_COURSES_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        deleted_courses: {
            ...state.deleted_courses,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.DELETED_COURSES_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,


        deleted_courses: {
            ...state.deleted_courses,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.DELETED_COURSES_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        deleted_courses: {
            ...state.deleted_courses,
            loading: false, hasError: false, error: {}
        }
    }),

    //   RESTORE DELETED COURSE
    [ActionTypes.RESTORE_DELETED_COURSE]: (state) => ({
        ...state,
        restore_course: {
            ...state.restore_course,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.RESTORE_DELETED_COURSE_SUCCESS]: (state, action) => ({
        ...state,


        restore_course: {
            ...state.restore_course,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.RESTORE_DELETED_COURSE_ERROR]: (state, action) => ({
        ...state,

        restore_course: {
            ...state.restore_course,
            loading: false, hasError: false, error: {}
        }
    }),


    //   RESTORe BULK DELETED COURSE
    [ActionTypes.RESTORE_BULK_DELETED_COURSE]: (state) => ({
        ...state,
        restore_bulk_course: {
            ...state.restore_bulk_course,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.RESTORE_BULK_DELETED_COURSE_SUCCESS]: (state, action) => ({
        ...state,


        restore_bulk_course: {
            ...state.restore_bulk_course,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.RESTORE_BULK_DELETED_COURSE_ERROR]: (state, action) => ({
        ...state,

        restore_bulk_course: {
            ...state.restore_bulk_course,
            loading: false, hasError: false, error: {}
        }
    }),

    //   PERMANENTLY DELETED COURSE
    [ActionTypes.PERMANENTLY_DELETE_COURSE]: (state) => ({
        ...state,
        permanently_delete_course: {
            ...state.permanently_delete_course,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.PERMANENTLY_DELETE_COURSE_SUCCESS]: (state, action) => ({
        ...state,


        permanently_delete_course: {
            ...state.permanently_delete_course,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.PERMANENTLY_DELETE_COURSE_ERROR]: (state, action) => ({
        ...state,

        permanently_delete_course: {
            ...state.permanently_delete_course,
            loading: false, hasError: false, error: {}
        }
    }),

    //   PERMANENTLY DELETED BULK COURSE
    [ActionTypes.PERMANENTLY_DELETE_BULK_COURSE]: (state) => ({
        ...state,
        bulk_delete_course: {
            ...state.bulk_delete_course,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.PERMANENTLY_DELETE_BULK_COURSE_SUCCESS]: (state, action) => ({
        ...state,


        bulk_delete_course: {
            ...state.bulk_delete_course,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.PERMANENTLY_DELETE_BULK_COURSE_ERROR]: (state, action) => ({
        ...state,

        bulk_delete_course: {
            ...state.bulk_delete_course,
            loading: false, hasError: false, error: {}
        }
    }),




     //Get ADMIN Courses
     [ActionTypes.GET_COURSES_ADMIN_LIST]: (state) => ({
        ...state,
        course_listing_admin: {
            ...state.course_listing_admin,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSES_ADMIN_LIST_SUCCESS]: (state, action) => ({
        ...state,

        course_listing_admin: {
            ...state.course_listing_admin,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_COURSES_ADMIN_LIST_ERROR]: (state, action) => ({
        ...state,

        course_listing_admin: {
            ...state.course_listing_admin,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),
////////////////////////////////////////////

}, initialState);

export default reducer;
