import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditor from '../ckeditor';
import 'react-dropzone-uploader/dist/styles.css'
import CustomDropzone from "../customDropzone";

function Template2(props) {
    const {values,index,setFieldValue,name,moduleErrors,moduleTouched,handleBlur} = props


    return (
        <div className="templates-wrap">
            <Row>
                <Col md={6} className="ck-editor-onleft"><CkEditor moduleErrors={moduleErrors} moduleTouched={moduleTouched} name={`modules.${index}.contents`} setFieldValue={setFieldValue} values={values} index={index} handleBlur={handleBlur}/></Col>
                <Col md={6}>
                    <CustomDropzone  moduleErrors={moduleErrors} moduleTouched={moduleTouched} name={`modules.${index}.image`} setFieldValue={setFieldValue} values={values} index={index} handleBlur={handleBlur}/>
                    {moduleErrors?.image && moduleTouched?.image &&
                    <span className={`form-error text-danger`} style={{display:'block'}}>{moduleErrors?.image}</span>}

                </Col>

            </Row>

        </div>
    )
};
export default Template2;
