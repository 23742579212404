import React, {useEffect, useState} from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScrollArea from 'react-scrollbar';
import { Formik,Field, Form } from "formik";
import FormikControl from '../components/quizFormikControl/container/index'
import * as Yup from "yup";
function TabModules(props) {
    const {values} = props
const [value, setValue] = useState(props.values?.questions[0])
const [index,setIndex]=useState(0)
    const [state, setState] = useState({
          key:0

    });


    const increment=()=>{
        let a =index+1
        setIndex(a)
       setValue(props.values?.questions[a])

    }

    const decrement=()=>{
        let a =index-1
        setIndex(a)
       setValue(props.values?.questions[a])
    }

    const submit=()=>{
        alert("quiz completed")
    }
  
    let shape = {};
    for(let i = 0; i < values?.questions?.length; i++){
        shape[values?.questions[i]?.question] = Yup.string();
    }
    const schema = Yup.object().shape(shape);

    return (
        <div>
            <Tab.Container id="controlled-tab-example"
                           activeKey={state?.key}
            >
                <Row>
                    <div  className="module-item-box">
                        {/*<h4>{props?.values?.name}</h4>*/}
                        <ScrollArea
                            speed={0.8}
                            className="modulemodalthumbscroll"
                            contentClassName="content"
                            horizontal={false}
                        >
                            <Nav variant="pills" className="flex-column add-module-pop-tab">
                                            <Nav.Item>
                                                <Nav.Link >
                                                    <div className="d-flex align-items-center">
                                                        <span className="module-tab-title">{props?.values?.name ? props?.values?.name :  props?.values?.quiz_name ? props?.values?.quiz_name : ''}</span>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>


                            </Nav>
                        </ScrollArea>
                    </div>
                    <Col  className="module-content-box">
                        <Tab.Content>

                            <Tab.Pane eventKey={`${state.key}`} id='tabId'>

                                <Formik
                                    validationSchema={schema}
                                    // onSubmit={handleSubmit}
                                    initialValues={{}}
                                >
                                    {({
                                          handleSubmit,
                                          handleChange,
                                          values,
                                        setValues,
                                          errors,
                                          setFieldValue
                                      }) => {

                                        return (
                                            <Form >
                                                 <div className="module-content">
                                                 <div className="whitebox shadow-box single-qstn-containerbox">
                                                <div className="radio-block-main-wrap">


                                                    {/*{JSON.stringify(values)}*/}
                                                    {/* {props.values?.questions && props.values?.questions?.length &&  props.values?.questions?.map((value,index) => ( */}
                                                        <FormikControl
                                                            quesIndex={index}
                                                            control={value?.question_type ? value?.question_type : 'No luck'}
                                                            name={value?.question}
                                                            errorName={value?.question}
                                                            options={value?.question_type == 'fill_in_the_blanks' ? value?.fill_in_the_blanks?.answer : value?.multiple_choices?.filter((el)=>el?.choice?.length > 0)}
                                                            errors={errors}
                                                            values={values}
                                                            setValues={setValues}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                </div>

                                                </div>
                                                 <div className='button-wrap double-btn d-flex justify-content-end m-t-20'>

 { index < 1 ? "" :    <button  type="button" className="btn border-btn" onClick={decrement}>Previous Question</button> }
 {  index ==props.values?.questions?.length-1 || props.values?.questions?.length ==0? "": <button  type="button" className="btn border-btn " onClick={increment}>Next Question</button>}





                                             </div>
                                                </div>
                                                {/* <div className="button-wrap double-btn d-flex justify-content-end m-t-20"> <Button variant="primary" className="btn-m" type="submit">Continue</Button> </div> */}

                                            </Form>
                                        )}}
                                </Formik>





                            </Tab.Pane>


                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>


            <div className="button-wrap double-btn d-flex justify-content-end m-t-20">



                                <Button type="button" onClick={()=>props.onHide()}>Close</Button>

            </div>
        </div>
    );
}
export default TabModules;
