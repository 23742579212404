import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from "./ckQues";
import {useSelector,useDispatch} from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {ToastContainer} from "react-toastify";


function MultipleChoice(props) {
    const dispatch = useDispatch();
    let loading = useSelector(state => state?.survey_builder?.get_survey_manager?.loading);
    const { values,errors,index,setFieldValue,clickedOption,demographic_questionsErrors,demographic_questionsTouched,touched,handleBlur,setValues} = props
   
    const [hideicon, sethideicon] = useState(true)
   
    const [reversMode,setReverseMode] = useState(false)


    useEffect(()=>{
        if(values?.demographic_questions[index]?.multiple_choices[3]?.choice?.length  > 0){
            sethideicon(false)
        }
    },[])


 
    const handleRemoveIcone = (i) =>{
        setReverseMode(true)
        const demographic_questions = [...values.demographic_questions]
        demographic_questions[index]?.multiple_choices?.pop()

        setValues({ ...values, demographic_questions });
    }

    const handleAddIcon = () =>{
        const demographic_questions = [...values.demographic_questions]
        
       demographic_questions[index]?.multiple_choices?.push({choice:''})

        setValues({ ...values, demographic_questions });
    }

    const handleAddIconView = () => {
        if(hideicon){
            return( <span style={{marginTop:'29px'}} className="memb-add-icon-quiz" onClick={handleAddIcon}>+</span>)
        }else {
            return (<span></span>)
        }

    }
   
    return (
        <div className="module-quiz-add-wrap">
            <ToastContainer autoClose={2000} />
            {/*<div className="form-close-btn p-relative"><span className="memb-close-icon">×</span></div>*/}
            <Form>
                <Row className="justify-content-center">
                
                <Col md={12} > 
                <Row >
                <Col md={6} >
                         <div className="form-input-wrap">
                            <Form.Label>Add Question Title</Form.Label>
                            <span className="mandatory-star">*</span>
                            <Form.Control  maxlength="20"
                                    defaultValue={values?.demographic_questions[index]?.title ? values?.demographic_questions[index]?.title: ''}
                                    placeholder={`Question - ${index + 1}`}
                                    name={`demographic_questions.${index}.title`}
                                    onBlur={e=>setFieldValue(`demographic_questions.${index}.title`,e.target.value)}
                                     isInvalid={!!demographic_questionsErrors?.title && demographic_questionsTouched?.title && !!demographic_questionsTouched?.title }
       
                                  minLength="8"    />
                                     <Form.Text id="passwordHelpBlock" muted>
        Your title must not exceed 20 characters
       
      </Form.Text>
       
       
                        </div>
                        </Col>
                        </Row>
                        
                    </Col>


                    <Col md={12} >
                        <div className="form-input-wrap">
                            <Form.Label>Add Question</Form.Label>
                            <span className="mandatory-star">*</span>
                            <Form.Control
                                    defaultValue={values?.demographic_questions[index]?.question ? values?.demographic_questions[index]?.question : ''}
                                    name={`demographic_questions.${index}.question`}
                                    placeholder={'Add a new question'}
                                    onBlur={e=>setFieldValue(`demographic_questions.${index}.question`,e.target.value)}
                                    isInvalid={!!demographic_questionsErrors?.question && demographic_questionsTouched?.question && !!demographic_questionsTouched?.question }
       
                                    as="textarea" rows={4} />
       
       
                        </div>
                    </Col>
                    <Col md={12}>
                        <Row className="">

                            {values?.demographic_questions && values?.demographic_questions?.length && values?.demographic_questions[index] && values?.demographic_questions[index]?.multiple_choices && values?.demographic_questions[index]?.multiple_choices.length &&  values?.demographic_questions[index]?.multiple_choices.map((el,i,arr)=>(
                                <>
                                    <Col md={11}>
                                        <div className="form-input-wrap">

                                            <div className="form-input-wrap">
                                                <Form.Label>Option{i+1}</Form.Label>
                                                {i<=1 ? <span className="mandatory-star">*</span>:''}
                                                <Form.Control
                                                    type="text"  maxlength="150"
                                                    //  placeholder={clickedOption?.choices?.length && clickedOption?.choices?.length >= i && clickedOption?.choices[i]?clickedOption.choices[i]:`Option ${i+1}`}
                                                   placeholder={`Option ${i+1}`}
                                                    defaultValue={values?.demographic_questions[index]?.multiple_choices[i]?.choice}
                                                    name={`demographic_questions.${index}.multiple_choices.${i}.choice`}
                                                    onBlur={e => setFieldValue(`demographic_questions.${index}.multiple_choices.${i}.choice`, e.target.value)}                                                   
                                                    isInvalid={!! demographic_questionsErrors?.multiple_choices && !! demographic_questionsErrors?.multiple_choices?.length && !! demographic_questionsErrors?.multiple_choices[i]  &&    demographic_questionsTouched?.multiple_choices?.length && !! demographic_questionsTouched?.multiple_choices[i] && !! demographic_questionsTouched?.multiple_choices[i]}
                                                    // onChange={e=>setFieldValue(`demographic_questions.${index}.multiple_choices.${0}.choice`,e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    {arr?.length - 1 == i &&
                                    <Col md={1} className="p-l-0">
                                        <div className='d-flex justify-content-end'>

                                        {arr.length < 10 &&
                                            <span style={{marginTop: '29px'}} className="memb-add-icon-quiz mr-2"
                                                  onClick={handleAddIcon}>+</span>
                                            }
                                            {i > 1 &&
                                            <span style={{marginTop: '29px'}} className="memb-add-icon-quiz"
                                                  onClick={() => handleRemoveIcone(i)}>-</span>
                                            }
                                        </div>
                                    </Col>
                                    }
                                </>

                            ))

                            }

                        </Row>

                        <Form.Text id="passwordHelpBlock" muted>
                                At least two options are mandatory
                              </Form.Text>

                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default MultipleChoice;

