/* eslint-disable */
import * as React from 'react';
import { Link } from "react-router-dom";
import ScrollArea from 'react-scrollbar';
import  {getNotificationsDetail,getNotifications} from "../../../modules/Notifications/services"
import {useDispatch} from "react-redux";
import { Alert } from 'react-bootstrap';

function Notification({notifications,dataLoaded}) {
const dispatch =useDispatch()
    const handleClick  = (id) =>{
     dispatch(getNotificationsDetail(id,function (res){
         if(res){
             dispatch(getNotifications())
         }
     }))
    }

    if(dataLoaded === true) {
        return (<ScrollArea
                speed={0.8}
                className="notificationscroll"
                contentClassName="content"
                horizontal={false}
            >
                <div className="notification-wrap">

                    <div className="notification-header d-flex justify-content-between">
                        <h4>Notifications</h4>
                        {notifications && notifications.length && notifications[0]?.unread_count > 0 ?
                            <span
                                 className="notification-new-count"><span>{notifications?.length && notifications[0]?.unread_count > 0 ? notifications[0].unread_count : ''}</span>New</span>
                         :
                            ''
                        }
                    </div>
                    <div className="notification-body">
                        <div className="notification-content">
                            <ul>

                                {notifications && notifications.length ? notifications.map((el) => {

                                        return (
                                             <div >
                                            <li  className='notifi-text'>
                                                {el.unread ?
                                                    <Alert  className="mx-2"  onClick={()=>handleClick(el.id)} variant={"light"}>{el?.description}
                                                        <div className="notifi-time"><i className="fa fa-clock-o" aria-hidden="true"></i>{el?.timesince} ago</div>
                                                    </Alert>
                                                    :
                                                    <Alert  className="mx-2" variant={"secondary"}>{el?.description}
                                                        <div className="notifi-time"><i className="fa fa-clock-o" aria-hidden="true"></i>{el?.timesince} ago</div>
                                                    </Alert>
                                                }


                                            </li>
                                             </div>
                                        )

                                    })
                                    :
                                    ''
                                }

                            </ul>
                        </div>

                    </div>


                </div>
            </ScrollArea>
        );
    }else {
        return (

        <>
        <div className="notification-header d-flex justify-content-between">
            <h4>Notifications</h4>    <span
            className="notification-new-count"><span>You don't have any new notifications</span></span>
        </div>
            </>
            )
    }
}
export default Notification;
