import React, { useState } from 'react';

const ExtensionCompleted = (props) => {



   return (
      <div className="entensioncompleted-wrap d-flex justify-content-center">
            <div className="entension-completed-cont text-center">
                <div className="thankyou-icon">
                    <i className="fas fa-check-circle"></i>
                </div>
                <p>Your assignment due date has been extended to {props?.newDate}</p>
            </div>
        </div>
   );
};
export default ExtensionCompleted;
