import React from 'react';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router';
import{Link} from 'react-router-dom'
function LinkExpired() {
    

    return (
        <div className="linkexpired-wrap">

            <div className="whitebox radiusbox shadow-box d-flex justify-content-center align-items-center">
                <div className="svg-icon">

                    <svg xmlns="http://www.w3.org/2000/svg" width="305.863" height="256.943" viewBox="0 0 305.863 256.943">
                        <g id="Group_8560" data-name="Group 8560" transform="translate(-22646.934 -9305)">
                            <g id="undraw_just_browsing_re_ofnd" transform="translate(22646.934 9305)">
                                <path id="Path_8972" data-name="Path 8972" d="M600.515,227.637H490.331a8.331,8.331,0,1,1,0-16.662H600.515a8.331,8.331,0,1,1,0,16.662Z" transform="translate(-392.492 -210.975)" fill="#ce002a" />
                                <path id="Path_8973" data-name="Path 8973" d="M620.006,264.482H316.791a1.324,1.324,0,1,1,0-2.647H620.006a1.324,1.324,0,1,1,0,2.647Z" transform="translate(-315.467 -234.499)" fill="#e6e6e6" />
                                <ellipse id="Ellipse_178" data-name="Ellipse 178" cx="4.545" cy="4.647" rx="4.545" ry="4.647" transform="translate(11.921 3.762)" fill="#3f3d56" />
                                <ellipse id="Ellipse_179" data-name="Ellipse 179" cx="4.545" cy="4.647" rx="4.545" ry="4.647" transform="translate(27.623 3.762)" fill="#3f3d56" />
                                <ellipse id="Ellipse_180" data-name="Ellipse 180" cx="4.545" cy="4.647" rx="4.545" ry="4.647" transform="translate(43.325 3.762)" fill="#3f3d56" />
                                <path id="Path_8974" data-name="Path 8974" d="M434.74,227.132a.536.536,0,0,1-.384-.162l-3.067-3.135a.537.537,0,0,1,0-.752l3.067-3.135a.537.537,0,0,1,.768.752l-2.7,2.759,2.7,2.759a.537.537,0,0,1-.384.913Z" transform="translate(-368.966 -215.051)" fill="#3f3d56" />
                                <path id="Path_8975" data-name="Path 8975" d="M446.968,227.132a.537.537,0,0,1-.384-.913l2.7-2.759-2.7-2.759a.537.537,0,1,1,.768-.752l3.067,3.135a.537.537,0,0,1,0,.752l-3.067,3.135a.536.536,0,0,1-.384.162Z" transform="translate(-376.04 -215.051)" fill="#e6e6e6" />
                                <path id="Path_8976" data-name="Path 8976" d="M822.315,218.975a1.18,1.18,0,0,0-1.179,1.179v5.7a1.18,1.18,0,0,0,1.179,1.179h5.7a1.18,1.18,0,0,0,1.179-1.179v-5.7a1.18,1.18,0,0,0-1.179-1.179Z" transform="translate(-549.348 -214.675)" fill="#e6e6e6" />
                                <path id="Path_8977" data-name="Path 8977" d="M796.315,218.975a1.18,1.18,0,0,0-1.179,1.179v5.7a1.18,1.18,0,0,0,1.179,1.179h5.7a1.18,1.18,0,0,0,1.179-1.179v-5.7a1.18,1.18,0,0,0-1.179-1.179Z" transform="translate(-537.323 -214.675)" fill="#e6e6e6" />
                                <path id="Path_8978" data-name="Path 8978" d="M846.815,219.475a1.18,1.18,0,0,0-1.179,1.179v5.7a1.18,1.18,0,0,0,1.179,1.179h5.7a1.18,1.18,0,0,0,1.179-1.179v-5.7a1.18,1.18,0,0,0-1.179-1.179Z" transform="translate(-560.68 -214.906)" fill="#e6e6e6" />
                                <path id="Path_8979" data-name="Path 8979" d="M598.845,379.223H462.862A12.376,12.376,0,0,1,450.5,366.86V316.337a12.376,12.376,0,0,1,12.362-12.362H598.845a12.376,12.376,0,0,1,12.362,12.362V366.86A12.376,12.376,0,0,1,598.845,379.223Z" transform="translate(-377.922 -253.989)" fill="#f2f2f2" />
                                <path id="Path_8980" data-name="Path 8980" d="M547.985,226.388H502.4a1.436,1.436,0,1,1,0-2.871h45.584a1.436,1.436,0,1,1,0,2.871Z" transform="translate(-401.263 -216.776)" fill="#fff" />
                                <path id="Path_8981" data-name="Path 8981" d="M475.082,645.548l-.351-.13a43.283,43.283,0,0,1-25.5-25.968,41.993,41.993,0,0,1-2.182-10.305l-.029-.335.331.059c3.952.709,8.1,1.1,9.95,1.255l-10.371-6.863.019-.357a8.209,8.209,0,0,1,5.515-7.362,8.349,8.349,0,0,1,9.085,2.233c1.031,1.119,2.121,2.246,3.175,3.335,3.622,3.744,7.368,7.615,9.737,12.185a26.162,26.162,0,0,1,.526,22.515Z" transform="translate(-376.273 -388.604)" fill="#f2f2f2" />
                                <path id="Path_8982" data-name="Path 8982" d="M623.256,686.178l-209.471.165a.64.64,0,0,1,0-1.28l209.471-.165a.64.64,0,1,1,0,1.28Z" transform="translate(-360.645 -430.173)" fill="#cacaca" />
                                <path id="Path_8983" data-name="Path 8983" d="M709.7,361.8l-2.172-33.651a5.443,5.443,0,0,0-5.711-5.069h0a5.443,5.443,0,0,0-5.132,6l3.328,34.886,1.129,21.284a4.75,4.75,0,1,0,5.915,1.473Z" transform="translate(-491.772 -262.82)" fill="#a0616a" />
                                <path id="Path_8984" data-name="Path 8984" d="M707.559,327.241h0a1.605,1.605,0,0,0,.176-1.938,2.082,2.082,0,0,1-.292-1.236,1.907,1.907,0,0,1,.039-.248A3.14,3.14,0,0,0,706.8,321a9.933,9.933,0,0,1-1.978-4.872,23.682,23.682,0,0,0-1.277-5.551c-1.085-2.523-3.538-4.718-6.735-3.51a6.321,6.321,0,0,0-3.189,2.788c-1.521,2.632-1.427,5.745-1.308,8.721l-.623-1.156a24.168,24.168,0,0,1-1.386,9.565c.716-.166,1.262.785,1.053,1.49a4.226,4.226,0,0,1-1.371,1.691c-.674.636-1.3,1.567-.612,2.478a1.979,1.979,0,0,0,1.4.738,37.4,37.4,0,0,0,15.21-1.723,4.563,4.563,0,0,0,2.786-1.859C709.315,328.784,708.7,327.184,707.559,327.241Z" transform="translate(-488.271 -255.259)" fill="#ce002a" />
                                <path id="Path_8985" data-name="Path 8985" d="M329.679,433.554h-5.932l-2.823-22.883h8.756Z" transform="translate(-148.403 -189.943)" fill="#a0616a" />
                                <path id="Path_8986" data-name="Path 8986" d="M640.68,670.515h-4.254l-.759-4.017-1.945,4.017H622.437A2.537,2.537,0,0,1,621,665.891l9.011-6.223v-4.061l9.478.566Z" transform="translate(-456.273 -416.625)" fill="#2f2e41" />
                                <path id="Path_8987" data-name="Path 8987" d="M382.369,425.184l-5.525,2.16-10.96-20.285,8.155-3.188Z" transform="translate(-169.199 -186.798)" fill="#a0616a" />
                                <path id="Path_8988" data-name="Path 8988" d="M706.867,659.069l-3.963,1.549-2.17-3.465-.349,4.449-10.509,4.108a2.537,2.537,0,0,1-3.026-3.782l6.127-9.076L691.5,649.07l9.034-2.924Z" transform="translate(-487.037 -412.249)" fill="#2f2e41" />
                                <path id="Path_8989" data-name="Path 8989" d="M670.447,388.975H642.769s-10.423,74.439-10.453,74.575,5.882,2.285,3.2,4.973l-2.687,2.687c-2.15,2.15,1.6,9.5,1.6,9.5l-4.283,43.714h17.69l8.646-90.3-11.7,43.29,15.239,47.007,10.97-6.45,2.15-5.912-4.271-4.271a79.834,79.834,0,0,1-1.1-10.211c0-3.792,3.225-.567,0-3.792-1.4-1.4-5.323-18.515-5.323-18.515l12.848-41.145,3.061-18.367a23.074,23.074,0,0,0-2.026-13.909C674.617,398.357,672.473,393.785,670.447,388.975Z" transform="translate(-461.007 -293.303)" fill="#2f2e41" />
                                <path id="Path_8990" data-name="Path 8990" d="M679.46,295.318,677.62,289.8a6.494,6.494,0,0,0-5.758-4.428l-1.634-.1a6.494,6.494,0,0,0-6.608,4.567h0A6.469,6.469,0,0,1,658,294.389c-4.777.407-11.868,1.852-11.868,6.842,0,7.525,8.062,27.412,6.45,29.024s-5.375,8.257-3.225,10.212c.56.509.108,2.959-.071,4.715-.325,3.178,22.108-3.849,28.557-2.027s3.225-11.825,4.3-12.9a27.286,27.286,0,0,0,2.688-3.933,6.474,6.474,0,0,0,.793-2.067l1.851-9.369c.031-.156.055-.309.075-.467.245-2.005,1.676-15.5-3.794-17.488C677.847,294.78,679.46,295.318,679.46,295.318Z" transform="translate(-468.407 -245.332)" fill="#ce002a" />
                                <path id="Path_8991" data-name="Path 8991" d="M577.137,253.432l24.514,23.156a5.443,5.443,0,0,0,7.634-.18h0a5.443,5.443,0,0,0-.333-7.892l-26.2-23.273-15.325-14.813a4.75,4.75,0,1,0-5.334,2.951Z" transform="translate(-427.756 -216.985)" fill="#a0616a" />
                                <path id="Path_8992" data-name="Path 8992" d="M623.409,303.2h0a1.6,1.6,0,0,0,1.191,1.538,2.083,2.083,0,0,1,1.056.706,1.91,1.91,0,0,1,.141.208,3.14,3.14,0,0,0,2.419,1.606,9.934,9.934,0,0,1,4.766,2.22,23.676,23.676,0,0,0,4.716,3.194c2.513,1.108,5.8,1.044,7.322-2.018a6.322,6.322,0,0,0,.436-4.213c-.679-2.963-2.869-5.177-4.984-7.276l1.243.422a24.168,24.168,0,0,1-5.5-7.947c-.411.61-1.458.285-1.786-.373a4.226,4.226,0,0,1-.148-2.172c.06-.925-.117-2.032-1.24-2.231a1.979,1.979,0,0,0-1.53.416,37.4,37.4,0,0,0-9.961,11.622,4.563,4.563,0,0,0-.773,3.258C621.073,303.271,622.611,304.025,623.409,303.2Z" transform="translate(-456.638 -246.067)" fill="#ce002a" />
                                <circle id="Ellipse_181" data-name="Ellipse 181" cx="11.825" cy="11.825" r="11.825" transform="translate(190.09 15.049)" fill="#a0616a" />
                                <path id="Path_8993" data-name="Path 8993" d="M695.726,258.967c-.613-1.242-2.564-1.1-3.558-.129s-1.349,2.386-1.908,3.65-1.564,2.553-2.945,2.607c-1.311.048-2.37-1.016-3.6-1.478a6.049,6.049,0,0,0-4.294.29c-.032-.134-.065-.269-.108-.4a8.531,8.531,0,0,0-.865-1.978,6.6,6.6,0,0,1-.322,2.021,6.155,6.155,0,0,1-.527,1.188,14.61,14.61,0,0,1-4.133,1.645c-1.784.3-3.918-.366-4.59-2.053a4.261,4.261,0,0,1,.7-3.822c.752-1.118,1.784-2.016,2.628-3.064a9.489,9.489,0,0,0,2.188-7.122A7.113,7.113,0,0,0,670.706,245a15.071,15.071,0,0,1-3.214.774,8.534,8.534,0,0,0-.865-1.978,6.6,6.6,0,0,1-.322,2.021,4.2,4.2,0,0,1-2.763-1.091c-1.612-1.526-1.462-4.192-.537-6.213a10.637,10.637,0,0,1,16.925-3.338c1.768-2.311,5.316-2.677,7.949-1.43s4.456,3.746,5.751,6.353A27.833,27.833,0,0,1,695.726,258.967Z" transform="translate(-475.891 -220.843)" fill="#2f2e41" />
                                <circle id="Ellipse_182" data-name="Ellipse 182" cx="27.476" cy="27.476" r="27.476" transform="translate(93.634 115.013) rotate(-85.269)" fill="#ce002a" />
                            </g>
                            <g id="Layer_2" data-name="Layer 2" transform="translate(22755.002 9377.559)">
                                <g id="invisible_box" data-name="invisible box" transform="translate(0 0)">
                                    <rect id="Rectangle_3326" data-name="Rectangle 3326" width="32.249" height="32.249" fill="none" />
                                </g>
                                <g id="Q3_icons" data-name="Q3 icons" transform="translate(2.446 2.446)">
                                    <g id="Group_8559" data-name="Group 8559">
                                        <path id="Path_8994" data-name="Path 8994" d="M12.58,20.783a4,4,0,0,1,5.6,0,1.332,1.332,0,0,0,1.865-1.865,6.529,6.529,0,0,0-9.327,0l-4.8,4.8a6.529,6.529,0,0,0,0,9.327,6.6,6.6,0,0,0,9.327,0l3-3.131a1.332,1.332,0,0,0-1.865-1.865l-3,3.131a3.957,3.957,0,1,1-5.6-5.6Z" transform="translate(-3.632 -7.971)" fill="#fff" />
                                        <path id="Path_8995" data-name="Path 8995" d="M8.885,16.124a1.332,1.332,0,0,0,1.8-.6,1.266,1.266,0,0,0-.533-1.732l-4.73-2.465a1.332,1.332,0,0,0-1.8.6,1.266,1.266,0,0,0,.533,1.732Z" transform="translate(-3.468 -6.043)" fill="#fff" />
                                        <path id="Path_8996" data-name="Path 8996" d="M14.793,10.151a1.266,1.266,0,0,0,1.732.533,1.332,1.332,0,0,0,.6-1.8l-2.465-4.73a1.266,1.266,0,0,0-1.732-.533,1.332,1.332,0,0,0-.6,1.8Z" transform="translate(-6.377 -3.468)" fill="#fff" />
                                        <path id="Path_8997" data-name="Path 8997" d="M39.756,31.46l-4.73-2.4a1.332,1.332,0,1,0-1.2,2.332l4.73,2.465a1.341,1.341,0,1,0,1.2-2.4Z" transform="translate(-13.287 -11.92)" fill="#fff" />
                                        <path id="Path_8998" data-name="Path 8998" d="M30.394,33.827a1.332,1.332,0,1,0-2.332,1.2l2.4,4.73a1.341,1.341,0,0,0,2.4-1.2Z" transform="translate(-11.586 -13.287)" fill="#fff" />
                                        <path id="Path_8999" data-name="Path 8999" d="M24.332,10.928l3-3.131a3.957,3.957,0,1,1,5.6,5.6l-4.064,4.064,1.865,1.865,4.064-4.064a6.6,6.6,0,1,0-9.327-9.327l-3,3.131a1.332,1.332,0,0,0,1.865,1.865Z" transform="translate(-9.721 -3.646)" fill="#fff" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
                <h3>Sorry, This Link Has Expired! </h3>
           
            <Link to={{ pathname: "https://www.enga.app" }} target="_blank"><button className="btn btn-dark">Go Back to Cultural Engagement</button></Link> 
            </div>
        </div>

    );
};

export default LinkExpired;
