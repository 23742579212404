import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getLearnerLearningPathNodes,
    getLearnerLearningPathNodesDetail
} from '../../learnerLearningPath/services';
import Spinner from "react-bootstrap/Spinner";
import {Button} from "react-bootstrap";

function NodsListing({ match }) {
    const dispatch = useDispatch();
    const id = match.params.id
    const response = useSelector(state => state?.learningpath_learner ?.learner_lp_nodes?.response);
    const loading = useSelector(state => state?.learningpath_learner?.learner_lp_nodes?.loading);
    const [state,setState] = useState({
        nodes:[],
        stats:{}

    })



    useEffect(() => {
        dispatch(getLearnerLearningPathNodes(id,function (res){
        }))
    }, [])

        useEffect(()=>{
            setState({...state,nodes:response?.results})
        },[response])

if(!loading) {
    return (
        <div className="node-listing-main-wrap">
            <div className="nodes-count-section">
                <h3 className="tab-title m-b-30">Nodes</h3>
                <div className="nodes-count-box-wrap d-flex">
                    <div className="node-count-box d-flex">
                        <div className="node-count-item">
                            <span className="nodes-count">15%</span>
                            <p>Learning Path Completion</p>
                        </div>
                        <div className="node-count-item">
                            <span className="nodes-count">2</span>
                            <p>Total Nodes Completed</p>
                        </div>
                        <div className="node-count-item">
                            <span className="nodes-count">2</span>
                            <p>Courses Completed</p>
                        </div>
                        <div className="node-count-item">
                            <span className="nodes-count">2</span>
                            <p>Quizzes Completed</p>
                        </div>
                    </div>
                    <div className="node-date-box">
                        <span>Due Date:</span>10 May 2022
                    </div>
                </div>
            </div>

            {state && state?.nodes && state?.nodes?.length ? state?.nodes?.map((el, index) => {

                    return (

                        <div className="status-item d-flex  align-items-center justify-content-between">
                            <div className="status-structure-wrap d-flex  align-items-center justify-content-end">
                                <h4>{el?.node_object_name}</h4>
                                <div className="course-or-quiz-slip">Course</div>
                                <div className="modules-status">
                                    <div
                                        className="d-flex  align-items-center justify-content-between learing-detail-progress">
                                        <div className="learning-progress progress">
                                            <div className="progress-bar" style={{"width": "100%"}}>&nbsp;</div>
                                        </div>
                                        <span className="common-progress-text">4/4 Modules Completed</span>
                                    </div>
                                </div>
                            </div>

                            <div className="status-button-show tick-show">
                                <Button type="button">Start</Button>
                            </div>
                            <div className="status-icon-show tick-show">
                                <i className="fa fa-check" aria-hidden="true"></i>
                            </div>
                        </div>


                    )


                })
                :

                'No Nodes are available'
            }

            {/*<div className="status-item d-flex  align-items-center justify-content-between">*/}
            {/*    <div className="status-structure-wrap d-flex  align-items-center justify-content-between">*/}
            {/*        <h4>Introduction</h4>*/}
            {/*        <div className="course-or-quiz-slip">Quiz</div>*/}
            {/*        <div className="modules-status">*/}
            {/*            <div className="d-flex  align-items-center justify-content-between learing-detail-progress">*/}
            {/*                <span className="persentage-show">65%</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="status-icon-show cross-show">*/}
            {/*        <i class="fa fa-times" aria-hidden="true"></i>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    );
}else {
    return (

        <Spinner animation="border" variant="danger"/>
    )
}
};
export default NodsListing;
