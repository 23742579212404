import React, { Fragment, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addCourse,
  getManagerCourse,
  updateCourse,
} from "../../../../services";
import { getCoarseCategories } from "../../../../services";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
// import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import { callback } from "chart.js/helpers";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal } from 'react-bootstrap';
import CropCustomDetail from "../addcontent/components/customCropForDetail";
import { ToastContainer, toast } from "react-toastify";
function CourseDetails(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const response = useSelector(
    (state) => state?.course?.get_course_manager?.response
  );
  const loading = useSelector(
    (state) => state?.course?.newly_added_course?.loading
  );
  const updatedCourseLoading = useSelector(
    (state) => state?.course?.updated_course?.loading
  );
  const { user, error, signUpError } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [coarseCategory, setCoarseCategory] = useState([]);
  const [fileTypeError, setFileError] = useState(false);
  const [tutorialModel, setTutorialmodel] = useState(false);

  const categoryResponse = useSelector(
    (state) => state?.course?.course_categories?.response
  );
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const location = useLocation()
  const [state, setState] = useState({
    course: response,
    loadForm: true,
  });
  const formikRef = React.useRef(null);
  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(getCoarseCategories())
  }, []);

  useEffect(() => {

    const courseOptionsdata = categoryResponse?.results?.map(({ id, label }) => {
      return { value: id, label };
    });
    setCoarseCategory(courseOptionsdata);
  }, [categoryResponse]);


  useEffect(() => {
    if (props?.urlId) {
      dispatch(
        getManagerCourse(props?.urlId, function (res) {
          if (res?.is_published) {
            toast.error('Published course can not be edited');
            history.push('/coursemanagement');
          }
          if (res) {
            setImgData(res?.image);
          }
        })
      );
    }
  }, []);
  useEffect(() => {
    if (props?.urlId) {

      if (response?.content_category_ids) {

        let result = categoryResponse?.results?.filter((el) => {
          return (
            response?.content_category_ids?.map((i) => Number(i)).indexOf(el.id) != -1
          );
        });
        response.category = result?.map((el) => {
          return {
            label: el.name,
            value: el.id,
          };
        });
      } else {
        response.category = [];
      }


      setState({ ...state, course: response });
    }
  }, [response, categoryResponse]);


  useEffect(() => {
    let element = document.getElementById("headerTitle");

    if (element) {
      switch (true) {
        case history.location.pathname.includes("/editcourse"):
          element.innerHTML = "Course Library";
          break;
        case "/draft/courses":
          element.innerHTML = "Course Library";
          break;
        default:
          "Profile";
      }
    }
  }, []);

  function TutorialModal(props) {

    return (
      <Modal className="custom-modal info-modal dashboard-modal" show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="module-modal-header my-4">
            <h4>Basic details of your course can be added here.</h4>
            {/* <p>Description Comes Here</p> */}
          </div>
          <ol className="info-list">
            <li><span> Select Category helps you to multi-select the type of category for the course you're building.</span></li>
            <li><span> Name allows you to enter the title of your course and description field can be used to enter the sub-text of your course</span></li>
            <li><span> Every course should have a banner image, kindly upload an image whose size shall be above 900px*324px.</span></li>
          </ol>
        </Modal.Body>
        <div className="btn-wrap text-center">
          <Button onClick={props.onHide}> Close </Button>
        </div>
      </Modal>
    );
  }

  const handleSubmit = async (values) => {
    let data = new FormData();

    if (props.urlId) {

      data.append(
        "content_category",
        JSON.stringify(values?.category?.map((el) => Number(el?.value)))
      );

      if (typeof values.image == "string") {
        data.append("name", values.name);
        data.append("description", values.description);

        dispatch(
          updateCourse(data, props?.urlId, function (response) {
            if (response) {
              props.parentState(1, response?.id, response, false);
            }
          })
        );
      } else {
        data.append("name", values.name);
        data.append("description", values.description);
        data.append("image", values.image);
        dispatch(
          updateCourse(data, props?.urlId, function (response) {
            if (response) {
              props.parentState(1, response?.id, response, false);
            }
          })
        );
      }
    } else {
      data.append("name", values.name);
      data.append("image", values.image);
      data.append("description", values.description);

      data.append(
        "content_category",
        JSON.stringify(values?.category?.map((el) => Number(el?.value)))
      );


      if (values.id) {

        dispatch(
          updateCourse(data, values?.id, function (response) {
            if (response) {
              props.parentState(1, response?.id, response, false);
            }
          })
        );


      } else {
        dispatch(
          addCourse(data, function (response) {
            if (response) {
              if (formikRef?.current) {

                formikRef?.current?.setFieldValue('id', response.id)
              }
              props.parentState(1, response?.id, response, false);
            }
          })
        );

      }
    }
  };

  const historyBack = () => {
    if (location?.state?.active) {
      history.push({
        pathname: '/coursemanagement',
        state: { active: location?.state?.active }
      })


    } else {

      history.push("/coursemanagement");
    }



  };
  const handleLoader = () => {
    if (loading || updatedCourseLoading) {
      return (
        <div className="loader-full-screen-course">
          <div className="loader">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      );
    }
  };

  const getImageUrl = (file) => {
    let imageUrl = URL.createObjectURL(file);
    setImgData(imageUrl);
  }

  return (
    <div className="coursedetails-wrap">
      <div className="help_icon_position">
        <OverlayTrigger
          overlay={
            <Tooltip id="InfoText">
              Help
            </Tooltip>
          }
        >
          <span className="info-icon-wrap-tab animated-icon" onClick={() => setTutorialmodel(true)}>
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </span>
        </OverlayTrigger>
      </div>
      <div>{handleLoader()}</div>
      <div className="course-form-content-sec">
        <Formik
          innerRef={formikRef}
          validationSchema={
            CourseDetailSchema
          }
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={
            props?.urlId
              ? state.course
              : user.user_type == "admin"
                ? {
                  name: "",
                  description: "",
                  image: null,
                  category: [],
                }
                : {
                  name: "",
                  description: "",
                  image: null,
                  category: [],
                }
          }
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            setValues,
            handleBlur,
            touched,
          }) => {
            const fileEvent = async (e) => {
              const fsize = e.target.files[0].size;
              const fileinMB = Math.round(fsize / 1024);
              if (fileinMB >= 4096) {
                setFileSizeError(true);
                setFileError(false);
              } else {
                setFileSizeError(false);
                const getFileExtension = e.target.files[0].name
                  .split(".")
                  .pop();
                if (
                  !(
                    getFileExtension === "jpg" ||
                    getFileExtension === "jpeg" ||
                    getFileExtension === "png"
                  )
                ) {
                  //         {
                  setFileError(true);
                } else {
                  setFileError(false);
                  if (e.target.files[0]) {
                    setFileError(false);
                    let imageUrl = URL.createObjectURL(e.target.files[0]);
                    setImgData(imageUrl);
                  }

                  if (props.urlId) {

                    var reader = new FileReader();
                    reader?.readAsDataURL(e?.target?.files[0]);
                    reader.onload = function (e) {
                      var image = new Image();
                      image.src = e?.target?.result;
                      image.onload = async function () {
                        var height = this.height;
                        var width = this.width;
                     
                        if (height < 900 || width < 340) {

                          let error = await formikRef?.current;
                          error?.setFieldError(
                            `image`,
                            "Image dimensions must be greater than or equal to  900 * 324"
                          );
                        };
                      }
                    }
                  }



                  setFieldValue("image", e.target.files[0]);
                }
              }
            };

            const handleRemove = (values) => {
              setImgData("");
              setValues({ ...values, image: "" });
            };

            return (
              <Form noValidate onSubmit={handleSubmit}>

                <div className="form-input-wrap">
                  <Form.Label>Select Category</Form.Label><span className="mandatory-star">*</span>
                  <Select
                    isMulti
                    options={coarseCategory || []}
                    value={values?.category}
                    style={{
                      border: `${errors.category}`
                        ? "2px solid red !important"
                        : "",
                    }}
                    onChange={(option) => setFieldValue("category", option)}
                    labelledBy={"Select Category"}
                    className="multi-select-common-input"
                  />
                  {errors.category && (
                    <span className={"text-danger mt-1"}>
                      {" "}
                      {errors.category}
                    </span>
                  )}
                </div>


                <div className="form-input-wrap">
                  <Form.Label>Name</Form.Label>
                  <span className="mandatory-star">*</span>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Course Name"
                    value={values.name}
                    onChange={handleChange}
                    maxLength="130"
                  />
                  {errors.name && touched.name && (
                    <span className={"text-danger mt-1"}> {errors.name}</span>
                  )}
                </div>
                <div className="form-input-wrap">
                  <div className="d-flex align-items-center imageupload-wrap">
                    <Form.Label>
                      Course Banner Image

                      {errors.image && errors.image && (
                        <span
                          style={{ fontSize: "13px", display: "block" }}
                          className={`form-error text-danger`}
                        >
                          {errors.image}
                        </span>
                      )}
                    </Form.Label>
                    <span className="mandatory-star">*</span>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="InfoText">
                          Image size should be above 900px*324px
                        </Tooltip>
                      }
                    >
                      <span className="info-tooltip">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                    </OverlayTrigger>

                    <div className="imageuploader d-flex align-items-center">
                      <div className="image-upload-button p-relative">
                        <label
                          htmlFor="profilePic"
                          className="btn btn-primary btn-m"
                        >
                          Upload
                        </label>
                        {fileTypeError ? (
                          <span style={{ color: "#ce002a" }}>
                            Select png or jpeg images only
                          </span>
                        ) : (
                          ""
                        )}
                        {fileSizeError ? (
                          <span style={{ color: "#ce002a" }}>
                            {" "}
                            File too big, please select a file less than 5MB
                          </span>
                        ) : (
                          ""
                        )}

                        <input
                          id="profilePic"
                          type="text"
                          name="image"
                          className="fileuploader-hidden"
                          onClick={() => setShow(true)}
                          accept="image/png, image/jpeg,image/jpg"
                        />
                      </div>
                      {imgData && (
                        <div className="previewProfilePic">
                          <div className="course-list-selected-box-content">
                            <div className="course-selected-item p-relative">
                              <img
                                className="playerProfilePic_home_tile"
                                src={imgData}
                              />
                              <span
                                className="memb-close-icon"
                                onClick={() => handleRemove(values)}
                              >
                                ×
                              </span>
                            </div>
                          </div>
                          {/* <span className="uploadedimageclose">×</span> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-input-wrap">
                  <Form.Label>Course Description</Form.Label>
                  <span className="mandatory-star">*</span>
                  <Form.Control
                    as="textarea"
                    placeholder="Course Description"
                    style={{ height: "160px" }}
                    className="textarea-item"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                  {errors.description && (
                    <span className={"text-danger mt-1"}>
                      {" "}
                      {errors.description}
                    </span>
                  )}
                </div>
                <div className="details-footer d-flex justify-content-end">
                  <div className="button-wrap double-btn">
                    <button
                      className="btn btn-m border-btn"
                      onClick={historyBack}
                    >
                      Cancel
                    </button>
                    <Button variant="primary" className="btn-m" type="submit">
                      Continue
                    </Button>
                  </div>
                </div>
                <Modal show={show} className='cropModal common-modal inner-popup add-module-content-modal' centered>
                  <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                  <Modal.Body>
                    <CropCustomDetail moduleErrors={errors} moduleTouched={touched} name={`image`} setFieldValue={setFieldValue} values={values} index={0} handleBlur={handleBlur} onHide={handleClose} getImageUrl={getImageUrl} />
                  </Modal.Body>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      </div>
      <TutorialModal show={tutorialModel}

        onHide={() => setTutorialmodel(false)}
      />
    </div>
  );
}

// const CourseDetailSchema = Yup.object().shape({
//   name: Yup.string().required("Course name is required").max(130),
//   description: Yup.string().required("Description is required"),
//   image: Yup.mixed().when("created_by", {
//     is: (val) => typeof val === "number",
//     then: Yup.mixed().required("Banner is required"),
//     otherwise: Yup.mixed()
//       .required("Banner is required")
//   }),
// });

const CourseDetailSchema = Yup.object().shape({
  name: Yup.string().required("Course name is required").max(130),
  category: Yup.array().min(1, "Category is required"),
  description: Yup.string().required("Description is required"),
  image: Yup.mixed().when("created_by", {
    is: (val) => typeof val === "number",
    then: Yup.mixed().required("Banner is required"),
    otherwise: Yup.mixed()
      .required("Banner is required")
  }),
});
export default CourseDetails;
