export default {
  GET_STARSHIP_DETAILS: "starships/", // ID IS HARDCODED TO THE URL ITSELF, for example purpose
  SIGNUP: "/user/register/",
  STRIPE_PAYMENT_INTENT: "/subscription/",
  VERIFY_STRIPE_PAYMENT_INTENT: "/subscription/",
  PASSWORD_LESS_AUTH: "/user/passwordless_auth/",
  CHECK_SUBDOMAIN: "/user/check-subdomain/",
  DELETE_ORGANIZATION: "/user/delete-workos-organisation/",
  CREATE_ORGANIZATION: "/user/create-workos-organisation/",
  FORGOT_SUBDOMAIN: "/user/forgot-subdomain/",
  SUBSCRIBE_SUBSCRIPTION_PLANS: "subscription/create/",
  COMPLETE_REGISTRATION: "/user/complete-registration/",
  SETUP_DOMAIN: "user/create-subdomain/",
  LOG_OUT: "/user/logout/",
  GET_SUBSCRIPTION_PLANS: "subscription/plans/",
  CHECK_SUBSCRIPTION: "subscription/institutions/",
  SELECT_PLAN: "subscription/create-customer-subscription/",
  CREATE_NEW_PASSWORD: "/user/password/reset/confirm",
  SET_PASSWORD:"user/set-password",
  FORGOT_PASSWORD: "/user/password_reset/",
  GET_LOGIN_DETAILS: "user/login/",
  GET_SURVEY_DETAILS: "survey/get-survey/",
  SURVEY_CATEGORIES_LIST:"survey/content-category/surveys/",
  SIGNIN_WITH_GOOGLE: "user/sign-in/google/",
  SIGNIN: "/user/login/",
  PASSWORDLESS_SIGNIN:'/user/auth/email/',
  PASSWORDLESS_OTP_SIGNIN:'/user/auth/token/',

  WORKOS: "/user/sso-auth/",
  EDLINK_LOGIN:'/providers/login-url',
  EDLINK: '/providers/edlink-token',
  WORKOS_ORG: "user/create-workos-organisation/",
  REGISTER: "/api/user/complete-registration/",
  PROFILE: "user/get-user/",
  CONTACT_MANAGER: "user/contact-manager/",
  CHANGE_PASSWORD: "user/password/change/",
  GROUP_LIST: "user/get-groups/",
  GET_GROUPS: "user/groups/",
  APPLY_COUPON: "/subscription/apply-discount-coupon/",
  TRANSACTION_HISTORY: "subscription/get-transactions/",
  CARD_DETAILS: "user/get-card/",
  CARD_UPDATE: "user/update-card/",
  DELETE_CARD: "user/delete-card/",
  UPGRADE_PLAN: "subscription/create/",
  SUBSCRIPTION_DETAILS: "subscription/detail/",
  GET_SURVEYS: "/survey/survey-list-create/",
  GET_DELETED_SURVEYS: "/survey/manager/deleted-surveys/",
  DELETE_SURVEYS_FOREVER: "/survey/manager/deleted-surveys/multiple-delete",
  RESTORE_SURVEYS: "/survey/manager/deleted-surveys/multiple-restore/",
  GROUPS_LIST: "user/get-groups/",
  ASSIGN_SURVEY: "survey/surveys/",
  SURVEY_DETAIL: "survey/surveys/",
  SUBMIT_SURVEYS: "survey/survey/submit/",
  UPDATE_SURVEY_QUESTIONS: "/survey/survey-details/",
  DOWNLOAD_SURVEY: "survey/surveys/",
  CHECK_TRIAL_PLAN: "user/institution-trial-plan-status/",
  CONSTRUCT_SURVEY_RESULTS: "survey/surveys/",
  CONSTRUCT_RESULTS: "survey/surveys/construct/",
  Learner_Survey: "/survey/attend/",
  Admin_Survey: "survey/manager/admin-surveys/",
  CLONE_SURVEY: "/survey/clone/",
  SURVEY_GRAPH_QUESTIONS: "/survey/list-survey-questions/",
  DEMO_RESULT: "/survey/demographic-report/",
  GENERAL_RESULT: "/survey/survey-report/",
  CANCEL_SUBSCRIPTION: "subscription/cancel/",
  INSTITUTION_CATEGORY: "user/institution-category/",
  GET_COURSES_MANAGER: "learn/manager/courses/",
  GET_DELETED_COURSES: "learn/manager/deleted-courses/",
  CLONE_COURSE: "learn/course",
  ADMIN_COURSE: "learn/admin/courses/",
  ADMIN_LPS: "learn/admin/learning-paths/",
  DELETE_COURSES_CHOICE: "learn/manager/",
  GET_COURSES_LEARNER: "learn/learner/courses/",
  GET_COURSES_LEARNER_NEW: "learn/learner/course-assignments/",
  GET_COURSE_CERTIFICATE_LEARNER: "learn/learner/course/",
  GET_COURSE_CATEGORIES:'learn/content-category/courses/',
  GET_MANAGER_LIST: "user/get-managers/",
  CREATE_COURSE: "learn/manager/courses/",
  MEMBER_LIST: "user/team-members/",
  ADD_MEMBER: "user/add-member/",
  ADD_MEMBER_INDIVIDUAL: "user/add-member-individual/",
  ADD_MEMBER_CSV: "user/add-member-csv/",
  COUNT_MEMBER: "/user/institution-member-count/",
  GET_ALL_GROUPS: "user/groups/",
  QUIZ_LIST: "learn/quizzes/",
  ASSIGN_COURSE: "learn/manager/courses/",
  //EDLINK_GET_ASSIGNMENT
  EDLINK_GET_ASSIGNMENT: "providers/create-edlink-assignment/",

  ASSIGN_LP: "learn/manager/learning-paths/",

  
  REMOVE_MANAGER: "user/team-members/demote-user/",
  ASSIGN_MANAGER: "user/team-members/promote-user/",
  BULK_DELETE: "user/team-members/bulk-delete/",
  DELETE_MEMBER: "/user/team-members/",
  BULK_GROUP_DELETE: "user/groups/bulk-delete/",
  DELETE_GROUP: "/user/groups/",
  CHECK_SITE: "user/check-site/",
  ADD_GROUP: "user/groups/",
  GROUP: "user/groups/",
  CLASS_DETAIL:'providers/edlink-class/learning-paths/',
  GET_GROUP_DETAIL: "user/team-group/",
  GET_GROUP_DETAIL_LP: "/learn/groups/",
  GET_MEMBER_DETAIL: "user/team-members/",
  GET_LP: "learn/manager/learning-paths/",
  GET_LP_MANGER: "learn/teams/learning-paths/",
  GET_LP_Member: "learn/learners/",
  ASSIGN_SURVEY_GROUP: "user/team-group/",
  ASSIGN_SURVEY_MEMBERS: "user/team-members/",
  ASSIGN_COURSE_GROUP: "user/team-group/",
  ASSIGN_COURSE_MEMBERS: "user/team-members/",
  GET_NOTIFICATIONS: "user/notifications/list/",
  DETAIL_NOTIFICATIONS: "user/notifications/",
  ASSIGN_MULTIPLE_LP_GROUP: "learn/learning-paths/",
  POST_SUPPORT: "/user/support/",
  THEME: "user/settings/",
  LEARNER_GROUPS: "",
  DASHBOARD_USER_COUNT: "/user/manager/dashboard/users/",
  DASHBOARD_COURSES: "/user/manager/dashboard/courses/",
  DASHBOARD_SURVEYS: "/user/manager/dashboard/surveys/",
  DASHBOARD_GROUPS: "/user/manager/dashboard/groups/",
  DASHBOARD_COURSES_GROUP: "/user/manager/dashboard/courses-groups/",
  DASHBOARD_COURSES_USERS: "/user/manager/dashboard/courses-users/",
  DASHBOARD_SURVEY_USERS: "/user/manager/dashboard/survey-users/",
  DASHBOARD_COURSE_COMPLETION: "/user/manager/dashboard/course-completion/",
  DASHBOARD_ASSIGNMENT_GRAPH: "/user/manager/dashboard/assignment-graph/",

  SUPPORT_DETAILS: "/user/site-config/",
  RESEND_INVITE: "/user/team-member/resend-invitation/",
  MANAGER_CONTACT: "/user/institution-contact-us/",
  GET_LPS_MANAGER: "learn/manager/learning-paths/",
  GET_LPS_CLASSES: '/providers/',
  GET_LPS_EXTEND: "learn/learning-path/",
  GET_ASSIGNMENTS: "/learn/my-assignments/",
  GET_ASSIGNMENTS_REPORT: "/learn/learner/learning-paths/reports/",
  GET_DELETED_LPS: "learn/manager/deleted-learning-paths/",
  DEL_DELETED_LPS: "learn/manager/deleted-learning-paths/",
  CREATE_SURVEY: "/survey/survey-list-create/",
  GET_DEMO_QS_LIST: "/survey/survey-demographic-questions/",

  GET_LEARNINGPATH_LEARNER: "learn/learner/learning-path-assignments/",
  SUBMIT_LEARNER_NODE: "learn/learner/",

  GET_DELETED_QUIZZES: "learn/deleted-quizzes/",

  DELETED_MULTIPLE_QUIZZES: "learn/deleted-quizzes/multiple-delete/",
  RESTORE_MULTIPLE_QUIZZES: "learn/deleted-quizzes/multiple-restore/",
};
