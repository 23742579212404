import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {useDispatch, useSelector} from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {useHistory} from "react-router-dom";
 import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

 import {
    getManagerSurveys
} from '~/modules/surveys/surveyManagement/surveybuilder/services';
import {
    getSurvey,
    getManagersList,
    deleteSurvey,
   
} from '~/modules/surveys/services';



const SurveyDetails = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const urlId = props?.match?.params?.id;
    const response = useSelector(state => state?.quiz?.get_quiz_manager?.response);
    const loading = useSelector(state => state?.quiz?.get_quiz_manager?.loading);
    const [modalShow, setModalShow] = React.useState(false);

    
    const [state, setState] = useState({
        quiz:[],
        popupView:false,
        previewPopUp:false,
        values:[]
    })


   


    const handlePreviewModel = () =>{

        setState({...state,values:state?.quiz, previewPopUp: true})
    }

    function MyVerticallyCenteredModal(props) {

        return (
            <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
    
            >
                <Modal.Body>
                    <div className="modal-content-wrap text-center">
                        <h3 className="modal-title">Delete Quiz?</h3>
                        {/* <p>Are You Sure To Delete The Members? </p> */}
                        <div className="d-flex justify-content-center buttons-wrap double-btn">
    
                            <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                                need to recheck
                            </button>
                            <Link className="m-l-20"><Button  onClick={props.handleSubmit} type="button">Yes,
                                Continue</Button></Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }


    const handleDeleteQuiz = () => {

        const row={id:urlId}

        dispatch(deleteQuiz(row));
        setTimeout(() => {
           dispatch(getManagerQuizzesList());
        }, 500);
        history.push('/quizmanagement')
  
     };
    useEffect(() => {
        if (urlId) {
            dispatch( getManagerSurveys(urlId, function (res) {

            }))
        }

    }, [])

    useEffect(()=>{

        setState({...state, quiz: response})
        

    },[response])
    if(loading){
        return (

            <div className='loaderQuizzes'>
                <Spinner animation="border" variant="danger"/>
            </div>
        )

    }else {

        return (
            <div className="quiz-det-wrap quiz-limited-width">

                <h3 className="sec-title m-b-30">{state?.survey?.name ? state?.quiz?.name : 'N/A'}</h3>
                <div className="whitebox padding-box shadow-box quiz-det-box">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>Group Name</h4>
                        <div className="d-flex justify-content-end">
                        <div className="quiz-set-buttond double-btn">
                        <button className="btn btn-primary"
                             disabled={state?.quiz?.questions && state?.quiz?.questions?.length && state?.quiz?.questions[0]?.question_type?.length <= 0} 
                            onClick={handlePreviewModel}                                   
                                        >
                                        Preview Survey
                            </button>
                            <button className="btn btn-primary"
                                    onClick={() => history.push(`/quizbuilder/${state?.quiz?.id}`)}>Edit Survey
                            </button>
                            <button className="btn btn-primary"                                  
                                        onClick={e => setModalShow(true)}>
                                        Delete Survey
                            </button>
                          
                            </div>
                     
                         
                        </div>
                    </div>
                    <div className="choice-box-group d-flex justify-content-end m-t-20">
                            <div className="choice-box">
                                <i className="fa fa-file-text-o" aria-hidden="true"></i>
                                {/* <span className="choice-text"><span>Number of questions</span>{state?.quiz?.pass_score ? state?.quiz?.pass_score : 'N/A' }</span> */}
                            </div>
                            <div className="choice-box">
                                <i className="fa fa-user-o" aria-hidden="true"></i>
                                {/* <span className="choice-text"><span>Number of responses</span>{state?.quiz?.questions_count?.count ? state?.quiz?.questions_count?.count : '0'} </span> */}
                            </div>
                            </div>
                    <div className="grey-box-out-quiz">
                        <p>{state?.quiz?.description ? state?.quiz?.description : 'N/A'}</p>
                    </div>
                </div>

                {/* <div className="whitebox shadow-box radioform-box multi-radio-singleline-wrap m-t-30">
                    {state?.quiz && state?.quiz?.questions_count?.count > 0  ?

                        <>
                            <ol className="quiz-det-radio-list">
                                {state.quiz && state?.quiz?.questions?.length && state?.quiz?.questions.map((el,index)=>(

                                    <li>
                                        <p dangerouslySetInnerHTML={{ __html: `${el?.question ? el?.question:''}` }}></p>

                                        <div className="radiooption multi-radio-singleline">
                                            <Form>
                                                <div className="mb-3 d-flex">
                                                    {el?.question_type && el?.question_type == 'multiple_choice' || el?.question_type == 'true_or_false' && el?.multiple_choices?.length ? el?.multiple_choices.map((item, index) => (
                                                            <Form.Check
                                                                custom
                                                                type="radio"
                                                                name="group1"
                                                                id="custom1"
                                                                label={item?.choice}
                                                                disabled
                                                            />
                                                        ))

                                                        :
                                                     <>
                                                         { el?.question_type && el?.question_type == 'fill_in_the_blanks' ?

                                                             <div className="answer-sec">
                                                                 <Form.Label>Answer</Form.Label>
                                                                 <Form.Control disabled type="text" placeholder="Write your answer here" className="input-item" />
                                                             </div>
                                                             :
                                                             ''

                                                         }
                                                     </>
                                                                                                    }
                                                </div>
                                                <QuizModulePopView
              show={state.previewPopUp}
             values={state?.values}
              onHide={() => setState({...state, previewPopUp: false})}

          />
                                            </Form>
                                        </div>



                                    </li>


                                ))}




                            </ol>
                        </>
                        :


                        'No Questions Found'

                    }
                </div> */}
                <MyVerticallyCenteredModal
                                            show={modalShow}
                                            handleSubmit={()=> {handleDeleteQuiz();setModalShow(false)}}                                          
                                           
                                            onHide={() => setModalShow(false)}
                                          //   onExited={ModalExit}
                                        />
            </div>

        )
    }
};
export default SurveyDetails;
