import {createAction, handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {

    _addSurvey,
    _updateSurveys,    
    _getDemographicQuestionsList,
    _updateSurveyQuestion,
    _getManagerSurveys,
   
    
} from '~shared/httpService/api';
import { ToastContainer, toast } from 'react-toastify';


const initialState = {
    newly_added_survey:{ loading: false, response: {}, hasError: false, error: {} },
    updated_survey:{ loading: false, response: {}, hasError: false, error: {} },
    demographicQuestionsList:{ loading: false, response: {}, hasError: false, error: {} },
    updated_survey_question:{ loading: false, response: {}, hasError: false, error: {} },
    get_survey_manager:{ loading: false, response: {}, hasError: false, error: {} },
};


export const addSurveyStart = createAction(ActionTypes.ADD_SURVEY);
export const addSurveySuccess = createAction(ActionTypes.ADD_SURVEY_SUCCESS, response => response);
export const addSurveyError = createAction(ActionTypes.ADD_SURVEY_ERROR, error => error);

export const addSurvey = (formData,callback) => (dispatch) => {
    dispatch(addSurveyStart());

    return _addSurvey(formData).then((response) => {
        if(response){
            toast.success('Survey detail has been created Successfully')
            callback(response)
        }
        dispatch(addSurveySuccess(response));
    }).catch((error) => {
        dispatch(addSurveyError(error));
        if(error){
            if(error?.error?.errorMessage){

                toast.error(error?.error?.errorMessage)
                
            }
        }else{
            toast.error('Something went wrong')
        }


    });
};


export const updateSurveyStart = createAction(ActionTypes.UPDATE_SURVEY);
export const updateSurveySuccess = createAction(ActionTypes.UPDATE_SURVEY_SUCCESS, response => response);
export const updateSurveyError = createAction(ActionTypes.UPDATE_SURVEY_ERROR, error => error);

export const updateSurvey = (formData,id,callback) => (dispatch) => {
    dispatch(updateSurveyStart());

    return  _updateSurveys(formData,id).then((response) => {
        if(response){
            callback(response)
            toast.success('Survey detail has been updated Successfully')
        }
        dispatch(updateSurveySuccess(response));
    }).catch((error) => {
        dispatch(updateSurveyError(error));
        if(error){
            if(error.image){
                toast.error(error.image[0])
            }
        }else{
            toast.error('Somethingss went wrong')
        }

    });
};

export const getDemographicQuestionsStart = createAction(ActionTypes.GET_DEMOGRAPHICQUESTIONS);
export const getDemographicQuestionsSuccess = createAction(ActionTypes.  GET_DEMOGRAPHICQUESTIONS_SUCCESS, response => response);
export const getDemographicQuestionsError = createAction(ActionTypes. GET_DEMOGRAPHICQUESTIONS_ERROR, error => error);

export const getDemographicQuestions = ()=>(dispatch)=>{
   
  dispatch(getDemographicQuestionsStart ());
  return  _getDemographicQuestionsList().then((response)=>{
    dispatch(getDemographicQuestionsSuccess(response));
  }).catch((error)=>{
    dispatch(getDemographicQuestionsError(error));
    toast.error('Something went wrong')
  })
}





export const updateSurveyQuestionStart = createAction(ActionTypes.UPDATE_QUESTIONS_SURVEY);
export const updateSurveyQuestionSuccess = createAction(ActionTypes.UPDATE_QUESTIONS_SURVEY_SUCCESS, response => response);
export const updateSurveyQuestionError = createAction(ActionTypes.UPDATE_QUESTIONS_SURVEY_ERROR, error => error);

export const updateSurveyQuestion = (formData,id,callback) => (dispatch) => {
 dispatch(updateSurveyQuestionStart());

 return _updateSurveyQuestion(formData,id).then((response) => {
  dispatch(updateSurveyQuestionSuccess(response));
  if(response){
//    toast.success('Questions  has been  updated  successfully')
   callback(response)
  }

 }).catch((error) => {
  dispatch(updateSurveyQuestionError(error))
  if(error){
    if(error?.error?.errorMessage){

        toast.error(error?.error?.errorMessage)
        
    }
}else{
    toast.error('Something went wrong')
}

 });
};

export const getSurveysManagerStart = createAction(ActionTypes.GET_MANAGER_SURVEY_DETAIL);
export const getSurveysManagerSuccess = createAction(ActionTypes.GET_MANAGER_SURVEY_DETAIL_SUCCESS, response => response);
export const getSurveysManagerError = createAction(ActionTypes.GET_MANAGER_SURVEY_DETAIL_ERROR, error => error);

export const getManagerSurveys = (id,callback) => (dispatch) => {
    
 dispatch(getSurveysManagerStart());
 return _getManagerSurveys(id,callback).then((response) => {
    if(response){
        callback(response)
    }  
 
  dispatch(getSurveysManagerSuccess(response));
 }).catch((error) => {     
  toast.error('Something went really wrong')
  dispatch(getSurveysManagerError(error));

 });
};

const reducer = handleActions({


//ADD SURVEY
[ActionTypes.ADD_SURVEY]: (state) => ({
    ...state,
    newly_added_survey: {
        ...state.newly_added_survey,
        response: {}, loading:true, hasError: false, error: {}
    },
}),

[ActionTypes.ADD_SURVEY_SUCCESS]: (state, action) => ({
    ...state,

    newly_added_survey: {
        ...state.newly_added_survey,
        response: action.payload, loading: false, hasError: false, error: {}
    },

}),
[ActionTypes.ADD_SURVEY_ERROR]: (state, action) => ({
    ...state,

    newly_added_survey: {
        ...state.newly_added_survey,
        response: {}, loading: false, hasError: false, error: action.payload
    },
}),
//Update SURVEY
[ActionTypes.UPDATE_SURVEY]: (state) => ({
    ...state,
    updated_survey: {
        ...state.updated_survey,
        response: {}, loading:true, hasError: false, error: {}
    },
}),

[ActionTypes.UPDATE_SURVEY_SUCCESS]: (state, action) => ({
    ...state,

    updated_survey: {
        ...state. updated_survey,
        response: action.payload, loading: false, hasError: false, error: {}
    },

}),
[ActionTypes.UPDATE_SURVEY_ERROR]: (state, action) => ({
    ...state,

    updated_survey: {
        ...state. updated_survey,
        response: {}, loading: false, hasError: false, error: action.payload
    },
}),


//get demographic survey
[ActionTypes.  GET_DEMOGRAPHICQUESTIONS]: (state) => ({
    ...state,  demographicQuestionsList: {
      ...state.   demographicQuestionsList,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_DEMOGRAPHICQUESTIONS_SUCCESS]: (state, action) => ({
    ...state,
     demographicQuestionsList: {
      ...state.   demographicQuestionsList,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_DEMOGRAPHICQUESTIONS_ERROR]: (state, action) => ({
    ...state,
     demographicQuestionsList: {
      ...state.  demographicQuestionsList,
      error: action.payload, loading: false, hasError: true, response: {}  }
  }),

//UpdATE SURVEY QUESTION

[ActionTypes.UPDATE_QUESTIONS_SURVEY]: (state) => ({
    ...state,
    updated_survey_question: {
     ...state.updated_survey_question,
     response: {}, loading:true, hasError: false, error: {}
    },
   }),
  
   [ActionTypes.UPDATE_QUESTIONS_SURVEY_SUCCESS]: (state, action) => ({
    ...state,
  
    updated_survey_question: {
     ...state.updated_survey_question,
     response: action.payload, loading: false, hasError: false, error: {}
    },
  
   }),
   [ActionTypes.UPDATE_QUESTIONS_SURVEY_ERROR]: (state, action) => ({
    ...state,
  
    updated_survey_question: {
     ...state.updated_survey_question,
     response: {}, loading: false, hasError: false, error: action.payload
    },
   }),

//Get Manger SURVEY
[ActionTypes.GET_MANAGER_SURVEY_DETAIL]: (state) => ({
    ...state,
    get_survey_manager: {
     ...state.get_survey_manager,
     response: {}, loading:true, hasError: false, error: {}
    },
   }),
   [ActionTypes.GET_MANAGER_SURVEY_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
  
    get_survey_manager: {
     ...state.get_survey_manager,
     response: action.payload, loading: false, hasError: false, error: {}
    },
   }),
   [ActionTypes.GET_MANAGER_SURVEY_DETAIL_ERROR]: (state, action) => ({
    ...state,
  
    get_survey_manager: {
     ...state.get_survey_manager,
     response: {}, loading: false, hasError: false, error: action.payload
    },
  
   }),


}, initialState);

export default reducer;