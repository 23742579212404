import React from 'react';
import ScrollArea from 'react-scrollbar';

const ModuleSeven = (props) => {
    const {value,index,length} = props

    function parseUrl(val){
        var vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
        var parsed = val.match(vimeoRegex);

        return "//player.vimeo.com/video/" + parsed[1];
    };

    const  embedUrl = (video)=>{
        if(video.includes('youtube')){
            let newUrl = video.replace("com/watch?v=","com/embed/");
            let parts =newUrl.split('&')
            return parts[0]
        }else if(video.includes('vimeo')){
            return parseUrl(video)
        }else {
            let newUrl = video.replace("com/","com/embed/");
            return newUrl
        }

    }

    return (
        <div className="videomodule">

            <ScrollArea
                speed={0.8}
                className="modulemodalscroll"
                contentClassName="content"
                horizontal={false}
            >
                <div className="video-content-wrap">
                <h3 className="module-content-header">{value?.name}</h3>
                    <div className="iframe-responsive">
                        <iframe width="420" height="315"
                                src={embedUrl(value?.video)}>
                        </iframe>
                    </div>
                    <div className="module-content m-t-20">
                    <p dangerouslySetInnerHTML={{ __html: `${value?.contents ? value?.contents:''}` }}></p>
                    </div>
                </div>
            </ScrollArea>

        </div>
    )

}

export default ModuleSeven;
