import React, {useEffect, useState} from 'react';
import CheckoutOnSignupMain from "./checkout";
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
const CheckoutOnSignup = () => {
    // const stripe = useStripe();
    return (
   <>
       <Elements stripe={stripePromise} >
       <CheckoutOnSignupMain/>
       </Elements>
        </>
    );
};

export default CheckoutOnSignup;
