import React, {Fragment, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    addLearningPaths,
    getManagerLearningPath,
    updateLearningPaths,
} from "../../services";
import { getInstitutionType } from "../../../auth/services";
import { useHistory,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {ToastContainer, toast} from "react-toastify";
// import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import { callback } from "chart.js/helpers";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal } from 'react-bootstrap';

import CropCustomDetail from "../../../coursemanagement/components/coursebuilder/components/addcontent/components/customCropForDetail";

function LearningPathDetails(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const  location = useLocation()
    const response = useSelector(
        (state) => state?.learningpath?.get_learning_path_manager?.response
    );
    const loading = useSelector(
        (state) => state?.learningpath?.newly_added_lp?.loading
    );
    const updatedCourseLoading = useSelector(
        (state) => state?.learningpath?.updated_lp?.loading
    );
    const { user, error, signUpError } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [category, setcategory] = useState([]);
    const [fileTypeError, setFileError] = useState(false);
    const categoryResponse = useSelector(
        (state) => state?.auth?.institution_type?.response
    );
    const [picture, setPicture] = useState(null);
    const [imgData, setImgData] = useState(null);

    const [state, setState] = useState({
        learningPath: response,
        loadForm: true,
    });
    const formikRef = React.useRef(null);
    const handleClose = () => setShow(false);
    useEffect(() => {
        if (user?.user_type === "admin") {
            dispatch(
                getInstitutionType(function (response) {
                    if (response) {
                        let newMap = response?.results?.map((el) => {
                            return {
                                label: el?.name,
                                value: el?.id,
                            };
                        });
                        setcategory(newMap);
                    }
                })
            );
        }
    }, []);

    useEffect(() => {
        if (props?.urlId) {

            dispatch(
                getManagerLearningPath(props?.urlId, function (res) {
                    if (res) {

                        if(res?.is_published){
                            toast.error('Published  Learning Path is not editable')
                            history.push('/learningpaths')
                        }
                        setImgData(res?.banner_image);
                    }
                })
            );
        }
    }, []);
    useEffect(() => {
        if (props?.urlId) {
            if (user.user_type == "admin") {
                if (response?.category || response?.category_ids) {
                  
                    let result = categoryResponse?.results?.filter((el) => {
                        return (
                            response?.category_ids?.map((i) => Number(i)).indexOf(el.id) != -1
                        );
                    });
                    response.category = result?.map((el) => {
                        return {
                            label: el.name,
                            value: el.id,
                        };
                    });
                }
            }

            setState({ ...state, course: response });
        }
    }, [response,categoryResponse]);


    const handleSubmit = async (values) => {
        let data = new FormData();

        if (props.urlId) {
            if (user?.user_type === "admin") {
                data.append(
                    "category",
                    JSON.stringify(values?.category?.map((el) => Number(el?.value)))
                );
            }

            if (typeof values.banner_image == "string") {
                data.append("name", values.name);
                data.append("description", values.description);

                dispatch(
                    updateLearningPaths(data, props?.urlId, function (response) {
                        if (response) {

                            props.parentState(1, response?.id, response, false);
                        }
                    })
                );
            } else {
                data.append("name", values.name);
                data.append("description", values.description);
                data.append("banner_image", values.banner_image);
                dispatch(
                    updateLearningPaths(data, props?.urlId, function (response) {
                        if (response) {

                            props.parentState(1, response?.id, response, false);

                        }
                    })
                );
            }
        } else {
            data.append("name", values.name);
            data.append("banner_image", values.banner_image);
            data.append("description", values.description);
            if (user?.user_type === "admin") {
                data.append(
                    "category",
                    JSON.stringify(values?.category?.map((el) => Number(el?.value)))
                );
            } else if (user?.user_type === "manager") {
                if (user?.category) {
                    let categoryOne = [user?.category];
                    data.append("category", JSON.stringify(categoryOne));
                }
            }
            dispatch(
                addLearningPaths(data, function (response) {
                    if (response) {
                        props.parentState(1, response?.id, response, false);
                    }
                })
            );
        }
    };
    const historyBack = () => {

        if(location?.state?.tab){
            history.push({
                pathname: '/learningpaths',
                state: { tab: location?.state?.tab}
            })


        }else {

            history.push("/learningpaths");
        }


    };

    const handleLoader = () => {
        if (loading || updatedCourseLoading) {
            return (
                <div className="loader-full-screen-course">
                    <div className="loader">
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
            );
        }
    };
    const options = [
        { label: "Corporate", value: "1" },
        { label: "Higher Education", value: "2" },
        { label: "K-12 Education", value: "3" },
    ];
    const getImageUrl = (file) => {
        let imageUrl = URL.createObjectURL(file);
        setImgData(imageUrl);
    }

    return (
        <div className="coursedetails-wrap">
            <ToastContainer autoClose={2000}/>
            <div>{handleLoader()}</div>
            <div className="course-form-content-sec">
                <Formik
                    innerRef={formikRef}
                    validationSchema={
                        user.user_type == "admin"
                            ? LpDetailSchemaAdmin
                            : LpDetailSchema
                    }
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                    initialValues={
                        props?.urlId
                            ? state.course
                            : user.user_type == "admin"
                            ? {
                                name: "",
                                description: "",
                                banner_image: null,
                                category: [],
                            }
                            : {
                                name: "",
                                description: "",
                                banner_image: null,
                                category: [],
                            }
                    }
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          setFieldValue,
                          setValues,
                          handleBlur,
                          touched,
                      }) => {
                        const fileEvent = async (e) => {
                            const fsize = e.target.files[0].size;
                            const fileinMB = Math.round(fsize / 1024);
                            if (fileinMB >= 4096) {
                                setFileSizeError(true);
                                setFileError(false);
                            } else {
                                setFileSizeError(false);
                                const getFileExtension = e.target.files[0].name
                                    .split(".")
                                    .pop();
                                if (
                                    !(
                                        getFileExtension === "jpg" ||
                                        getFileExtension === "jpeg" ||
                                        getFileExtension === "png"
                                    )
                                ) {
                                    //         {
                                    setFileError(true);
                                } else {
                                    setFileError(false);
                                    if (e.target.files[0]) {
                                        setFileError(false);
                                        let imageUrl = URL.createObjectURL(e.target.files[0]);
                                        setImgData(imageUrl);
                                    }

                                    if(props.urlId){

                                        var reader = new FileReader();
                                        reader?.readAsDataURL(e?.target?.files[0]);
                                        reader.onload = function (e) {
                                            var image = new Image();
                                            image.src = e?.target?.result;
                                            image.onload = async function () {
                                                var height = this.height;
                                                var width = this.width;
                                                if (height <900  || width < 340) {

                                                    let error = await formikRef?.current;
                                                    error?.setFieldError(
                                                        `banner_image`,
                                                        "Image dimensions must be greater than or equal to  900 * 324"
                                                    );
                                                };
                                            }
                                        }
                                    }



                                    setFieldValue("banner_image", e.target.files[0]);
                                }
                            }
                        };

                        const handleRemove = (values) => {
                            setImgData("");
                            setValues({ ...values, banner_image: "" });
                        };

                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                {user.user_type == "admin" ? (
                                    <div className="form-input-wrap">
                                        <Form.Label>Select Institution type</Form.Label>
                                        <Select
                                            isMulti
                                            options={category && category.length ? category : []}
                                            value={values?.category}
                                            style={{
                                                border: `${errors.category}`
                                                    ? "2px solid red !important"
                                                    : "",
                                            }}
                                            onChange={(option) => setFieldValue("category", option)}
                                            labelledBy={"Select Institution Type"}
                                        />
                                        {errors.category && (
                                            <span className={"text-danger mt-1"}>
                        {" "}
                                                {errors.category}
                      </span>
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}

                                <div className="form-input-wrap">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Learning Path Name"
                                        value={values?.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && touched.name && (
                                        <span className={"text-danger mt-1"}> {errors.name}</span>
                                    )}
                                </div>
                                <div className="form-input-wrap">
                                    <div className="d-flex align-items-center imageupload-wrap">
                                        <Form.Label>
                                            Learning Path Banner Image

                                            {errors.banner_image && errors.banner_image && (
                                                <span
                                                    style={{ fontSize: "13px" }}
                                                    className={`form-error text-danger`}
                                                    style={{ display: "block" }}
                                                >
                          {errors.banner_image}
                        </span>
                                            )}
                                        </Form.Label>
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="InfoText">
                                                    Image size should be above 900px*324px
                                                </Tooltip>
                                            }
                                        >
                      <span className="info-tooltip">
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                                        </OverlayTrigger>

                                        <div className="imageuploader d-flex align-items-center" style={{cursor:'pointer'}}>
                                            <div className="image-upload-button p-relative" style={{cursor:'pointer'}}>
                                                <label
                                                    htmlFor="profilePic"
                                                    className="btn btn-primary btn-m"
                                                    style={{cursor:'pointer'}}
                                                >
                                                    Upload
                                                </label>
                                                {fileTypeError ? (
                                                    <span style={{ color: "#ce002a" }}>
                            Select png or jpeg images only
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                {fileSizeError ? (
                                                    <span style={{ color: "#ce002a" }}>
                            {" "}
                                                        File too big, please select a file less than 5MB
                          </span>
                                                ) : (
                                                    ""
                                                )}

                                                <input
                                                    id="profilePic"
                                                    type="text"
                                                    name="image"
                                                    className="fileuploader-hidden"
                                                    style={{cursor:'pointer'}}
                                                    onClick={()=>setShow(true)}
                                                    accept="image/png, image/jpeg,image/jpg"
                                                />
                                            </div>
                                            {imgData && (
                                                <div className="previewProfilePic">
                                                    <div className="course-list-selected-box-content">
                                                        <div className="course-selected-item p-relative">
                                                            <img
                                                                className="playerProfilePic_home_tile"
                                                                src={imgData}
                                                            />
                                                            <span
                                                                className="memb-close-icon"
                                                                onClick={() => handleRemove(values)}
                                                            >
                                ×
                              </span>
                                                        </div>
                                                    </div>
                                                    {/* <span className="uploadedimageclose">×</span> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-input-wrap">
                                    <Form.Label>Learning Path Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Description"
                                        style={{ height: "160px" }}
                                        className="textarea-item"
                                        name="description"
                                        value={values?.description}
                                        onChange={handleChange}
                                    />
                                    {errors.description && (
                                        <span className={"text-danger mt-1"}>
                      {" "}
                                            {errors.description}
                    </span>
                                    )}
                                </div>
                                <div className="details-footer d-flex justify-content-end">
                                    <div className="button-wrap double-btn">
                                        <button
                                            className="btn btn-m border-btn"
                                            onClick={historyBack}
                                        >
                                            Cancel
                                        </button>
                                        <Button variant="primary" className="btn-m" type="submit">
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                                <Modal show={show} className='cropModal common-modal inner-popup add-module-content-modal'>
                                    <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                                    <Modal.Body>
                                        <CropCustomDetail moduleErrors={errors} moduleTouched={touched} name={`banner_image`} setFieldValue={setFieldValue} values={values} index={0} handleBlur={handleBlur} onHide={handleClose} getImageUrl={getImageUrl}  />
                                    </Modal.Body>
                                </Modal>
                            </Form>
                        );
                    }}
                </Formik>
            </div>

        </div>
    );
}

const LpDetailSchema = Yup.object().shape({
    name: Yup.string().required("Course name is required"),
    description: Yup.string().required("Description is required"),
    banner_image: Yup.mixed().when("created_by", {
        is: (val) => typeof val === "number",
        then: Yup.mixed().required("Banner is required"),
        otherwise: Yup.mixed()
            .required("Banner is required")
    }),
});

const LpDetailSchemaAdmin = Yup.object().shape({
    name: Yup.string().required("Course name is required"),
    category: Yup.array().min(1, "Institution type is required"),
    description: Yup.string().required("Description is required"),
    banner_image: Yup.mixed().when("created_by", {
        is: (val) => typeof val === "number",
        then: Yup.mixed().required("Banner is required"),
        otherwise: Yup.mixed()
            .required("Banner is required")
    }),
});
export default LearningPathDetails;
