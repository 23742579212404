
import Companylogo from "~/assets/images/logo-name-svg.png";
import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from "formik";
import FormikControl from './surveyFormikControl/container/index'
import * as Yup from "yup";
import Button from 'react-bootstrap/Button';
import { Link, useHistory } from 'react-router-dom';
import { submitSurvey } from '~/modules/surveys/services';
import { useDispatch, useSelector  } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import uuid from 'react-uuid'
import {
    
    getSurvey
    
} from '~/modules/surveys/services';

function PageModules(props) {
    const dispatch = useDispatch();
    const history = useHistory()
    const { values } = props
    const [state, setState] = useState({
        key: 0
    });
    const [loading,setLoader]=useState(false)
    // const submitLoading= useSelector(state => state?.surveys?. surveys_submit?.loading);
    const [submitValues, setSubmitValues] = useState({})


    let shape = {};
    let initVal = {}
    for (let i = 0; i < values?.questions?.length; i++) {
        if (values?.questions[i]?.question_id) {
            shape[values?.questions[i]?.question_id] = values?.questions[i]?.mandatory ? Yup.string().required() : Yup.string();
            initVal[values?.questions[i]?.question_id] = ''
        }
    }
    const schema = Yup.object().shape(shape);
    const [initialValues,setInitialValues] = useState({})


      useEffect(()=>{
          setInitialValues(initVal)

      },[values])

    const handleSubmit = async (values) => {

  
        const val = JSON.stringify(values)

        if (document.activeElement.dataset.flag === 'action-2') {
            props.increment(true)
            let newvalues = { ...submitValues, ...values }
            setSubmitValues(newvalues)

        }


        else if (document.activeElement.dataset.flag === 'action-1') {
            setLoader(true)
            let newvalues = { ...submitValues, ...values }
            setSubmitValues(newvalues)
            dispatch(submitSurvey(newvalues, props?.survey_uuid, function (response) {
                if (response) {
                    // dispatch(getSurvey('', '',  '' ,  '' , '' , '' , '',));
                    setLoader(false)
                    history.push({
                        pathname: '/surveycomplete',
                        state: props?.name
                    });
                }


            }))
        }



    };

    const handleLoader = () => {

            return (
                <div className="loader-full-screen-course">
                    <div className="loader">
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
            );

    };



    return (
        <div className="survey-link-wrap">
            <div className="survey-header">
                
                      {
                             localStorage.getItem("surveydisplay") ?

                          <img src={localStorage.getItem("surveydisplay")} alt="" /> :

                          <img  alt="logo"  src={props.logo ? props.logo :Companylogo }  /> 
                          }
            {/* <img  alt="logo"  src={props.logo ? props.logo :Companylogo }  /> */}
            </div>
            <div className="survey-body">


                <h2 className="survey-title">{props?.name}</h2>



                <Formik
                    validationSchema={schema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                      initialValues={initialValues ? initialValues : {}}

                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        touched,
                        setFieldValue
                    }) => {
                        return (
                            <Form >
                             
                                <div className="whitebox shadow-box">
                                    <div className="radio-block-main-wrap">
                                    {!props.values?.questions || !props.values?.questions?.length || loading?
                                     <div>{handleLoader()}</div>
                                    :''
                                    }


                                        {props.values?.questions && props.values?.questions?.length && props.values?.questions?.map((value, index) => (
                                            <FormikControl
                                                key={value?.question_id}
                                                quesId={value?.question_id ? value?.question_id : 0}
                                                quesIndex={index}
                                                control={value?.question_type ? value?.question_type : 'No luck'}
                                                name={value?.question}
                                                mandatory={value?.mandatory}
                                                quesNumber={value?.questionNumber}
                                                errorName={value?.question_id}
                                                touched={touched}
                                                options={value?.question_type == 'free_response' ? value?.free_response?.answer : value?.multiple_choices?.filter((el) => el?.choice?.length > 0)}
                                                errors={errors}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />

                                        ))}







                                    </div>
                                </div>
                                {!props.complete ?
                                    <div className="button-wrap double-btn d-flex justify-content-end m-t-20"> <Button variant="primary" className="btn-m" type="submit" data-flag="action-2">Continue</Button> </div>
                                    : ''} {props.complete ? <>
                                        <br></br> <br></br> <div className="button-wrap d-flex justify-content-end"><Button type="submit" data-flag="action-1">Submit</Button></div>
                                    </> : ''}
                            </Form>
                        )
                    }}
                </Formik>










            </div>


        </div>
    );
}

export default PageModules;
