import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { learnerSurveyDetail } from '~/modules/surveys/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageModules from "./container/pagebreak";
import TabModules from "./container/tab"
import { Helmet } from "react-helmet";


function Surveystart({ match }) {
    const dispatch = useDispatch();
    const response = useSelector(state => state.surveys.learner_surveys_detail?.response);
    const [survey, setSurvey] = useState({})
    const [pagesurvey, setpageSurvey] = useState([])
    const id = match.params.id
    const uuid = match.params.uuid
    const [index, setIndex] = useState(0)
    const [value, setValue] = useState({})
    const [complete, setComplete] = useState(false)
    const [pageBreak, setPageBreak] = useState(false)
    const [imgData, setImgData] = useState(null);

    useEffect(() => {
        dispatch(learnerSurveyDetail(id, uuid))
    }, [])

    useEffect(() => {

        if (response.institution_logo) {
            setImgData(response.institution_logo ? response.institution_logo : null);
            localStorage.setItem('surveydisplay', response.institution_logo);
        }
        else setImgData(null)





        let demographic_questions = (response?.demographic_questions && response?.demographic_questions?.length) ? response?.demographic_questions : []
        let general_questions = ((response?.general_questions && response?.general_questions?.length) ? response?.general_questions : [])

        const ques = demographic_questions.concat(general_questions)
        const newQues = ques.map((item, index) => ({ ...item, questionNumber: index + 1 }))

        const val = { ...response, questions: newQues }
   

        setSurvey(val);
    }, [response]);


    useEffect(() => {
        if (survey?.questions && survey?.questions?.length) {
            let array = []; let j = 0; let arr = []; let flag = false;
            arr.splice(0, 0, survey?.questions[0]);
            for (let i = 1; i < survey?.questions?.length; i++) {

                if (survey?.questions[i]?.page_break) {
                    setPageBreak(true)
                    array.splice(j, 0, arr);
                    arr = [];//empty array
                    j++;
                    arr.push(survey?.questions[i])

                } else {
                    arr.push(survey?.questions[i])
                }
            }
            if (arr.length) {
                array.splice(j, 0, arr);
            }

          
            let tempObj = {
                questions: array
            }
            setpageSurvey(tempObj)
            if (pagesurvey?.questions && pagesurvey?.questions?.length) {
                let onj = {
                    questions: pagesurvey?.questions[0]
                }
                setValue(onj)
            }

        }
    }, [survey])

    const increment = (data) => {
        if (data) {
            let a = index + 1
            setIndex(a)
            let onj = {
                questions: pagesurvey?.questions[a]
            }
            setValue(onj)
        }

    }

    const decrement = () => {
        let a = index - 1
        setIndex(a)
        setValue(pagesurvey?.questions[a])
    }

    useEffect(() => {
        if (index == pagesurvey?.questions?.length - 1) {
            setComplete(true)
        }


    }, [index])



    return (
       
        <div className="auth-page-wrap">
             <Helmet> 
             <title>Survey  - Cultural Engagement  </title>
            </Helmet>
            {survey?.questions && survey?.questions?.length ?
                <>
                    {pageBreak ?
                        <PageModules values={value} name={response?.name} increment={increment} complete={complete} survey_uuid={uuid} logo={imgData} />
                        :
                        <TabModules values={survey} survey_uuid={uuid} logo={imgData} />
                    }
                </>

                : 'No Questions Found'

            }
        </div>
    )
}

export default Surveystart
