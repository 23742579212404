import React, { useState, useEffect, useCallback } from "react";
import Pagination from "react-bootstrap/Pagination";
import PageItem from "react-bootstrap/PageItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import Select, { components } from "react-select";
import CourseDummy from "~/assets/images/course_dummy.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import {
  getDraftQuizzesManagerList,
  handlePreviousPaginationDraftCourse,
  handlePaginationDraftCourse,
  deleteQuiz,
  getDeletedQuiz,
} from "../../services";

import Spinner from "react-bootstrap/Spinner";
import { getManagersList } from "~/modules/surveys/services";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getNotifications } from "../../../Notifications/services";
import DataTable from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { getManagerCoursesList } from "../../../coursemanagement/services";

const DraftQuizzes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const response = useSelector(
    (state) => state?.quiz?.quizz_listing_draft?.response
  );
  const loading = useSelector(
    (state) => state?.quiz?.quizz_listing_draft?.loading
  );
  const managers = useSelector(
    (state) => state.surveys?.manager_list?.response || [] || []
  );
  const { user } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    quizzes: response,
    count: response?.count,
    paginationItem: [],
  });
  const [activePage, setActivePage] = useState(1);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [count, setCount] = useState(0);
  const [createdby, setCreatedby] = useState("");
  const [selectrow, setSelectRow] = useState(null);

  const [modalShow, setModalShow] = React.useState(false);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        className="custom-modal confirmation-popup delete-confirmation inner-popup"
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-content-wrap text-center">
            <h3 className="modal-title">Delete Quiz?</h3>
            {/* <p>Are You Sure To Delete The Members? </p> */}
            <div className="d-flex justify-content-center buttons-wrap double-btn">
              <button
                type="button"
                className="btn border-btn white-btn"
                onClick={() => props.onHide()}
              >
                No, I need to recheck
              </button>
              <Link className="m-l-20">
                <Button onClick={props.handleSubmit} type="button">
                  Yes, Continue
                </Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    setSearch("");
    setDateRange([null, null]);
    setCreatedby("");
    dispatch(getDraftQuizzesManagerList());
    dispatch(getManagersList());
    // dispatch(getNotifications())
  }, []);
  useEffect(() => {
    if (count > 0 && dateRange[0] == null) {
      setActivePage(1);
      dispatch(getDraftQuizzesManagerList());
    }
  }, [count]);
  useEffect(() => {
    const count = response?.count;
    const perPage = 12;
    const buttonsCount = Math.ceil(count / perPage);

    let items = [];
    for (let number = 1; number <= Number(buttonsCount); number++) {
      items.push(
        <Pagination.Item
          onClick={() => handleIndex(number)}
          key={number}
          active={number == activePage}
        >
          {number}
        </Pagination.Item>
      );
    }
    setState({
      ...state,
      quizzes: response,
      count: response?.count,
      paginationItem: items,
    });
  }, [response]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      setActivePage(1);
      dispatch(
        getDraftQuizzesManagerList(
          search,
          format(new Date(startDate), "yyyy-MM-dd"),
          format(new Date(endDate), "yyyy-MM-dd"),
          createdby === "" ? "" : createdby?.id
        )
      );
    }
  }, [startDate, endDate]);

  const debouncedGetSearch = useCallback(
    debounce((query, startDate, endDate, createdby) => {
      setActivePage(1);
      dispatch(
        getDraftQuizzesManagerList(
          query,
          startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
          endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
          createdby === "" ? "" : createdby?.id
        )
      );
    }, 1000),
    []
  );

  const searchText = (e) => {
    setSearch(e.target.value);
    debouncedGetSearch(e.target.value, startDate, endDate, createdby);
  };

  const reset = () => {
    setSearch("");
    setDateRange([null, null]);
    setCreatedby("");
    dispatch(
      getDraftQuizzesManagerList(
        "",
        startDate === null ? "" : "",
        endDate == null ? "" : "",
        createdby === "" ? "" : ""
      )
    );
  };
  const onSelect = (val) => {
    setCreatedby(val);
    setActivePage(1);
    dispatch(
      getDraftQuizzesManagerList(
        search,
        startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
        endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
        val.id
      )
    );
  };
  const handleNext = () => {
    if (state.quizzes?.next) {
      setActivePage((prevVal) =>
        prevVal === state.paginationItem ? prevVal : prevVal + 1
      );
      dispatch(handlePaginationDraftCourse(state.quizzes?.next));
    } else {
      return false;
    }
  };

  const handlePrevious = () => {
    if (state.quizzes?.previous) {
      setActivePage((prevVal) => (prevVal - 1 === 0 ? prevVal : prevVal - 1));
      dispatch(handlePreviousPaginationDraftCourse(state.quizzes?.previous));
    } else {
      return false;
    }
  };

  const handleIndex = (index) => {
    dispatch(
      getDraftQuizzesManagerList(
        search ? search : "",
        startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
        endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
        createdby === "" ? "" : createdby?.id,
        index
      )
    );

    setActivePage(index);
  };
  const handleDeleteQuiz = (row) => {
    dispatch(deleteQuiz(row));
    setTimeout(() => {
      dispatch(
        getDraftQuizzesManagerList(
          search ? search : "",
          startDate === null ? "" : format(new Date(startDate), "yyyy-MM-dd"),
          endDate == null ? "" : format(new Date(endDate), "yyyy-MM-dd"),
          createdby === "" ? "" : createdby?.id
        )
      );
      dispatch(getDeletedQuiz("", "", "", "", null));
    }, 1000);
  };

  const handleEdit = (id) => {
    history.push(`/coursemanagementedit/${id}`);
  };

  const tableColumns = [
    {
      name: "Quiz Name",
      selector: "name",
      cell: (row) => <Link to={`/quizdetails/${row?.id}`}>{row.name}</Link>,
      sortable: true,
      center: false,
      grow: 4,
    },

    {
      name: "Questions Count",
      selector: "questions_count",
      sortable: true,
      center: false,
      grow: 4,
    },

    {
      name: "Action",
      selector: "Action",
      sortable: false,
      center: false,
      grow: 4,
      cell: (row, index) => (
        <div data-tag="allowRowEvents" className="d-flex">
          <div className="action-icons-cell-wrap d-flex">
            <div
              className="action-icon-item fill-icon stroke-icon"
              // onClick={() => history.push(`/quizdetails/${row?.id}`)}>
              onClick={() =>
                history.push({
                  pathname: `/quizbuilder/${row?.id}`,
                  state: {
                    active: row?.is_published ? "AvailableQuizzes" : "Drafts",
                  },
                })
              }
            >
              <OverlayTrigger overlay={<Tooltip id="InfoText">Edit</Tooltip>}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.917"
                  height="17.917"
                  viewBox="0 0 17.917 17.917"
                >
                  <g
                    id="_3325116_edit_icon"
                    data-name="3325116_edit_icon"
                    transform="translate(-1.1 -1.1)"
                  >
                    <path
                      id="Path_8321"
                      data-name="Path 8321"
                      d="M16.506,12.591v4.3a1.612,1.612,0,0,1-1.612,1.612H3.612A1.612,1.612,0,0,1,2,16.894V5.612A1.612,1.612,0,0,1,3.612,4h4.3"
                      transform="translate(0 -0.388)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.8"
                    />
                    <path
                      id="Path_8322"
                      data-name="Path 8322"
                      d="M16.059,2l3.223,3.223-8.059,8.059H8V10.059Z"
                      transform="translate(-1.165 0)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.8"
                    />{" "}
                  </g>
                </svg>
              </OverlayTrigger>
            </div>
            <div
              className="action-icon-item fill-icon"
              //  onClick={()=> handleDeleteQuiz(row)}
              onClick={(e) => {
                setModalShow(true);
                setSelectRow(row);
              }}
            >
              <OverlayTrigger overlay={<Tooltip id="InfoText">Delete</Tooltip>}>
                <svg
                  id="icons_Q2"
                  data-name="icons Q2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.49"
                  height="19.331"
                  viewBox="0 0 17.49 19.331"
                >
                  <path
                    id="Path_8323"
                    data-name="Path 8323"
                    d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                    transform="translate(-4.999 -3)"
                    fill="#fff"
                  />
                  <path
                    id="Path_8324"
                    data-name="Path 8324"
                    d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                    transform="translate(-7.261 -9.477)"
                    fill="#fff"
                  />{" "}
                </svg>
              </OverlayTrigger>
            </div>
          </div>

          {/*<Link to={`/edit_survey/${row.id}`} className="btn border-btn link-btn mx-2">*/}
          {/*    Edit</Link>*/}
          {/*<Link onClick={e => handleDeleteSurvey(row)} className="btn border-btn link-btn">*/}
          {/*    Delete</Link>*/}
        </div>
      ),
    },
  ];
  const CaretDownIcon = () => {
    return (
      <div className="filter-icon-select">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.652"
          height="19.653"
          viewBox="0 0 19.652 19.653"
        >
          <path
            className="a"
            d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0"
            transform="translate(-0.5 0)"
          />
        </svg>
      </div>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="available-course-wrap">
      <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
        <div className="datatable-filter-left-box d-flex align-items-center">
          <div className="datepicker-box p-relative">
            <DatePicker
              placeholderText={"Created date"}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setCount(count + 1);
                setDateRange(update);
              }}
              isClearable={true}
            />
            <span className="datepickericon">
              <i className="fa fa-calendar"></i>
            </span>
          </div>

          <div className="dt-filter-left d-flex justify-content-between align-items-center">
            <div className="custom-react-select">
              <Select
                options={managers || []}
                getOptionLabel={(option) =>
                  option.label.length > 1 ? option.label : option.email_label
                }
                value={createdby}
                onChange={onSelect}
                placeholder="Author"
                components={{ DropdownIndicator }}
              />
            </div>
            {/*<button type="button" className="iconbutton">*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">*/}
            {/*        <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />*/}
            {/*    </svg>*/}
            {/*</button>*/}
          </div>
        </div>
        <div className="dt-filter-right d-flex">
          <div className="dt-filter-search-wrap p-relative">
            <input
              type="text"
              placeholder="Search using keyword"
              value={search}
              onChange={searchText}
              className="form-control input-item"
            />
            <span
              className="search-close-icon"
              style={{ cursor: "pointer" }}
              onClick={reset}
            >
              ×
            </span>
          </div>
          <button className="iconbutton search-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.216"
              height="19.216"
              viewBox="0 0 19.216 19.216"
            >
              <path
                className="a"
                d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div>
        <MyVerticallyCenteredModal
          show={modalShow}
          handleSubmit={() => {
            handleDeleteQuiz(selectrow);
            setModalShow(false);
          }}
          onHide={() => setModalShow(false)}
          //   onExited={ModalExit}
        />

        {loading && loading ? (
          <>
            <div className="loaderCourses">
              <Spinner animation="border" variant="danger" />
            </div>
          </>
        ) : (
          <>
            {response?.results?.length > 0 ? (
              <div className="datatble-wrap">
                <DataTable
                  data={response?.results}
                  columns={tableColumns}
                  center={true}
                  showPaginationBottom={false}
                  noHeader={true}
                />
              </div>
            ) : (
              <>
                {search?.length > 0 ||
                createdby ||
                dateRange[0] ||
                dateRange[1] ? (
                  <span className="noData">No data available</span>
                ) : (
                  <span className="noData text-center">
                    You have no draft Quizzes
                  </span>
                )}
              </>
            )}
          </>
        )}

        {!loading &&
          state?.quizzes &&
          state?.quizzes &&
          state?.quizzes.count > 12 &&
          response && (
            <div className="d-flex justify-content-center">
              <Pagination size="sm" className="custom-pagination">
                <Pagination.First
                  disabled={!state?.quizzes?.previous}
                  className={`${
                    state?.quizzes?.previous ? "paginationStyle" : ""
                  } `}
                  onClick={handlePrevious}
                />
                {state.paginationItem}
                <Pagination.Last
                  disabled={!state?.quizzes?.next}
                  onClick={handleNext}
                  className={`${
                    state?.quizzes?.next ? "paginationStyle" : ""
                  } `}
                />
              </Pagination>
            </div>
          )}
      </div>
    </div>
  );
};
export default DraftQuizzes;
