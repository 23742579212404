import React , { useState ,useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from 'react-router-dom';
import ChartistGraph from 'react-chartist';
import {  useHistory } from "react-router-dom";
import { chart1, chartHorizontalData, chart6Data, chart6Options, chart9Data } from '../chartistData';
import Button from 'react-bootstrap/Button'
import {useDispatch, useSelector} from "react-redux";
import {
    getdashboard_Users,
     getdashboard_SurveyCount,
     getdashboard_CourseCount,
     getdashboard_GroupCount,
     getdashboard_Courses_GroupCount,
    getdashboard_CoursesUsersCount,
    getdashboard_CourseCompletion,
    getdashboard_DashboardAssignmentSurvey,
    getdashboard_Survey_Users_Count


} from '../services';
import Spinner from "react-bootstrap/Spinner";

function Dashboard() {

    const dispatch = useDispatch();
    const history=useHistory()
    useEffect(() => {
        let element = document.getElementById('headerTitle')
        if(element){
          switch (history.location.pathname){
             case '/dashboard':
                element.innerHTML = 'Dashboard'
               
    
            default :
               'DASHBOARD'
       
          } }
       
       
    }, [])
    

    const response_Dashboard_users = useSelector(state => state.managerDashboard. dashboard_Users?.response || []);
    const loading_Dashboard_users = useSelector(state => state.managerDashboard. dashboard_Users?.loading || false);

    const response_SurveyCount = useSelector(state => state.managerDashboard.   dashboard_Survey_Count?.response || []);
    const loading_SurveyCount = useSelector(state => state.managerDashboard.   dashboard_Survey_Count?.loading ||false);
    
    const response_CourseCount = useSelector(state => state.managerDashboard.   dashboard_Course_Count?.response || []);
    const loading_CourseCount = useSelector(state => state.managerDashboard.   dashboard_Course_Count?.loading || false);

    const response_GroupCount = useSelector(state => state.managerDashboard.   dashboard_Group_Count?.response || []);
    const loading_GroupCount = useSelector(state => state.managerDashboard.   dashboard_Group_Count?.loading || false);
  
    const response_Courses_GroupCount = useSelector(state => state.managerDashboard.dashboard_Courses_Groups_Count?.response || []);
    
    

    const response_Courses_UsersCount = useSelector(state => state.managerDashboard.  dashboard_Courses_Users_Count?.response || []);
    

    const response_Dashboard_CourseCompletion = useSelector(state => state.managerDashboard.   dahboard_Course_Completion?.response || []);
   
    const response_DashboardAssignmentSurvey = useSelector(state => state.managerDashboard.  dashboard_Assignment_Survey?.response || []);
   
    const response_Dashboard_Survey_Group_Count = useSelector(state => state.managerDashboard.   dashboard_Survey_Users_Count?.response || []);
   
    
    

    const [dashboardUsers, setUsers] = useState(0);
    const [lpCount, setLPCount] = useState( "---");
    

    const [dashboardCourseCompletion , setDashboardCourseCompletion ] = useState(response_Dashboard_CourseCompletion );
  
    const[percentage,setPercentage]=useState(0);
    const[LPpercentage,setLPPercentage]=useState(0);


   
    const [groupSurvey, setGroupSurvey] = useState( 0 );

    const [userCourse, setUserCourse] = useState( 0 );
    const [groupCourse, setGroupCourse] = useState( 0 );

    const [userLP, setUserLP] = useState( 0 );
    const [groupLP, setGroupLP] = useState( 0 );


    

    useEffect(() => {

       
      dispatch (getdashboard_Users());
       dispatch ( getdashboard_SurveyCount());
       dispatch ( getdashboard_CourseCount());
      dispatch ( getdashboard_GroupCount());
      dispatch ( getdashboard_Courses_GroupCount());
      dispatch ( getdashboard_CoursesUsersCount());      
      dispatch ( getdashboard_CourseCompletion());
      dispatch ( getdashboard_DashboardAssignmentSurvey());
    //   dispatch ( getdashboard_Survey_Users_Count());    
       

    }, []);

    useEffect(() => {

         const display_course_percent =  Math.floor(100* (dashboardCourseCompletion?.course_completion?.courses_completed/dashboardCourseCompletion?.course_completion?.courses_assigned));

        setTimeout(() => {
            setPercentage( display_course_percent )
        }, 1500);
       
    }, [dashboardCourseCompletion]);

   

    useEffect(() => {
        setUsers(response_Dashboard_users ?.users || 0);
    }, [response_Dashboard_users]);


    const displayUser=()=>{
      
        if ( dashboardUsers < 1000)
        {
        return dashboardUsers
        }
    
        else{
            var str = dashboardUsers .toString()
            str = str.substring(0, str.length-3).concat("k")
            return str;
        }
    
       }

const [dataArray,setDataArray]=useState({})

const month=(i)=>{    
switch (i) {
  case 1:
    return('Januvary')   
    case 2:
    return('Februvary')    
    case 3:
    return('March')    
    case 4:
    return('April')   
    case 5:
    return('May')    
           
   case 6:
   return('June')    
    case 7:
   return('July')     
     case 8:
   return('August')   
  case 9:
   return('September') 
   case 10:                            
   return('October')                       
    
   case 11:
    return('November')
    case 12:
    return('December')
   
  
  default:
    return('');
    
     }
}

useEffect(() => {
     const labels=[];
     
     let survey_length=response_DashboardAssignmentSurvey?.assignment_graph?.surveys?.length
   
     for (var i = 0; i < survey_length; i++) {
         const mon=response_DashboardAssignmentSurvey?.assignment_graph?.surveys[i].created__date__month;
         const month_display=month(mon)
        labels.push( month_display);
    }
   
   
    
    const series=[]
    const survey_series=[]
    // for (var i = 0; i < survey_length; i++) {
    //     const res=response_DashboardAssignmentSurvey?.assignment_graph?.surveys[i].count;
        
    //     survey_series.push( res);
    //      }
   const LP_series=[]

 series.push( survey_series)

series[1]= LP_series


const data={}
data.labels=labels;
data.series=series
setDataArray(data)


    
}, [ response_DashboardAssignmentSurvey])



    

//
    useEffect(() => {
        setDashboardCourseCompletion ( response_Dashboard_CourseCompletion );
       
    }, [ response_Dashboard_CourseCompletion ]);

   

   


     useEffect(() => {
        
        
        // const survey_group=response_Dashboard_Survey_Group_Count ?.survey_groups ?.count || 0;
         const course_group= response_Courses_GroupCount ?.courses_groups?.count || 0;
        const LP_group =0;

        // const survey_percent=survey_group/(survey_group+course_group+LP_group)*100
        const course_percent=course_group /(course_group+LP_group)*100
       
        setGroupCourse(course_percent);
        //
        setGroupLP(0)
   
        // console.log("suvey percent"+survey_percent)
       
     }, [response_Courses_GroupCount])


     useEffect(() => {        
        
        const survey_user=response_Dashboard_Survey_Group_Count ?.survey_users?.count || 0;
         const course_user= response_Courses_UsersCount?.course_users?.count || 0 ;
        const LP_user =0;

        
        const course_percent=course_user / (course_user+LP_user+survey_user)*100
        const survey_percent=survey_user/(survey_user+course_user+LP_user)*100
       
        setUserCourse(course_percent);
        setGroupSurvey(survey_percent);
       
        setUserLP(0)

       
     }, [response_Courses_UsersCount, response_Dashboard_Survey_Group_Count,])


    
    return (
        <div>
            <Row>
                <Col md={8}>
                    <div className="dashboard-count-box count-boxes text-center" >
                        <Row>
                        
                            <Col md={4}>
                            <Link to="/coursemanagement" style={{cursor:'pointer' ,textDecoration: 'none' , color:' inherit'}}>
                                <div className="whitebox shadow-box count-boxes-item m-b-20">
                                    <span className="dashboard-box-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.04" height="23.892" viewBox="0 0 17.04 23.892">
                                            <g id="connection" transform="translate(-3.237 0.388)">
                                                <path id="Union_5" data-name="Union 5" d="M6.009,23.492A2.385,2.385,0,0,1,3.7,21.046V19.087a6.83,6.83,0,0,0-1.581-4.413A9.089,9.089,0,0,1,0,8.81,8.585,8.585,0,0,1,8.32,0a8.585,8.585,0,0,1,8.32,8.81,9.089,9.089,0,0,1-2.117,5.865,6.829,6.829,0,0,0-1.581,4.413v1.958a2.385,2.385,0,0,1-2.311,2.447ZM4.623,21.046a1.43,1.43,0,0,0,1.386,1.468h4.623a1.43,1.43,0,0,0,1.386-1.468V19.577h-7.4Zm7.41-2.447a7.81,7.81,0,0,1,1.8-4.577A8.074,8.074,0,0,0,15.716,8.81,7.632,7.632,0,0,0,8.32.979,7.632,7.632,0,0,0,.924,8.81a8.075,8.075,0,0,0,1.882,5.212,7.81,7.81,0,0,1,1.8,4.577H7.9a.512.512,0,0,1-.043-.206v-2.1L5.166,11.66a1.748,1.748,0,0,1-.543.086,1.908,1.908,0,0,1-1.85-1.958,1.907,1.907,0,0,1,1.85-1.958A1.907,1.907,0,0,1,6.471,9.789a2.012,2.012,0,0,1-.518,1.358l1.9,3.275V6.789a1.943,1.943,0,0,1-1.386-1.9A1.907,1.907,0,0,1,8.32,2.936a1.907,1.907,0,0,1,1.849,1.958,1.943,1.943,0,0,1-1.386,1.9v7.632l1.9-3.276a2.012,2.012,0,0,1-.518-1.357,1.852,1.852,0,1,1,3.7,0,1.908,1.908,0,0,1-1.85,1.958,1.748,1.748,0,0,1-.544-.086L8.783,16.288v2.1a.512.512,0,0,1-.043.206Zm-.015-7.831a.98.98,0,1,0-.924-.979,1,1,0,0,0,.375.787.442.442,0,0,1,.071.038.468.468,0,0,1,.063.05A.877.877,0,0,0,12.018,10.767ZM3.7,9.789a.954.954,0,0,0,.925.979.877.877,0,0,0,.419-.107.467.467,0,0,1,.059-.046.454.454,0,0,1,.066-.036,1,1,0,0,0,.38-.791.954.954,0,0,0-.924-.979A.954.954,0,0,0,3.7,9.789ZM7.4,4.894a.926.926,0,1,0,1.849,0,.926.926,0,1,0-1.849,0Z" transform="translate(3.437 -0.188)" fill="#ce002a" stroke="#ce002a" stroke-width="0.4" />
                                            </g>
                                        </svg>
                                    </span>
                                    
                                     <h4>{ loading_CourseCount ?  <Spinner animation="border" variant="warning" size="sm" /> :  response_CourseCount?.courses}</h4>
                                    <div className="count-text">Courses</div>
                                </div>
                                </Link>
                            </Col>
                          
                            <Col md={4}>
                                <div className="whitebox shadow-box count-boxes-item m-b-20">
                                    <span className="dashboard-box-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.44" height="25.527" viewBox="0 0 21.44 25.527">
                                            <g id="reading-book" transform="translate(-40.994)">
                                                <path id="Path_6920" data-name="Path 6920" d="M62.434,16.454a2.5,2.5,0,0,0-1.994-2.443V11.318a.748.748,0,0,0-1.011-.7L58,11.153a8.719,8.719,0,0,0-3.325-2.159,4.986,4.986,0,1,0-5.924,0,8.72,8.72,0,0,0-3.325,2.159L44,10.618a.748.748,0,0,0-1.011.7v2.693a2.493,2.493,0,0,0,0,4.886v2.892a.748.748,0,0,0,.485.7l7.978,2.992a.756.756,0,0,0,.525,0l7.978-2.992a.748.748,0,0,0,.485-.7V18.9A2.5,2.5,0,0,0,62.434,16.454ZM48.224,4.986a3.49,3.49,0,1,1,3.49,3.49A3.494,3.494,0,0,1,48.224,4.986Zm3.49,4.986a7.218,7.218,0,0,1,4.732,1.765l-4.732,1.774-4.732-1.774A7.218,7.218,0,0,1,51.714,9.972ZM42.49,16.454a1,1,0,0,1,1-1h.5v1.994h-.5A1,1,0,0,1,42.49,16.454Zm1.994,2.468a1.249,1.249,0,0,0,1-1.221V15.207a1.249,1.249,0,0,0-1-1.221V12.4l6.482,2.431V23.7L44.484,21.27ZM52.462,23.7V14.828L58.943,12.4v1.588a1.249,1.249,0,0,0-1,1.221V17.7a1.249,1.249,0,0,0,1,1.221V21.27Zm7.479-6.25h-.5V15.456h.5a1,1,0,0,1,0,1.994Z" fill="#ce002a" />
                                            </g>
                                        </svg>
                                    </span>
                                    <h4>{lpCount}</h4>
                                    <div className="count-text">Learning Paths</div>
                                </div>
                            </Col>
                            <Col md={4}>
                            <Link to="/surveymanagement" style={{cursor:'pointer' ,textDecoration: 'none' , color:' inherit'}}>
                                <div className="whitebox shadow-box count-boxes-item m-b-20">
                                    <span className="dashboard-box-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.2" height="21.2" viewBox="0 0 17.2 21.2">
                                            <g id="clipboard" transform="translate(21.124 42.895)">
                                                <path id="Union_7" data-name="Union 7" d="M3.2,21A3.145,3.145,0,0,1,0,17.924V5.086A3.109,3.109,0,0,1,2.392,2.11V1.846A1.887,1.887,0,0,1,4.314,0h8.372a1.887,1.887,0,0,1,1.922,1.846V2.11A3.109,3.109,0,0,1,17,5.086V17.924A3.145,3.145,0,0,1,13.8,21ZM1.281,5.086V17.924A1.887,1.887,0,0,0,3.2,19.77H13.8a1.887,1.887,0,0,0,1.922-1.846V5.086a1.847,1.847,0,0,0-1.112-1.673,1.889,1.889,0,0,1-1.921,1.8H4.314a1.889,1.889,0,0,1-1.921-1.8A1.847,1.847,0,0,0,1.281,5.086Zm2.392-3.24V3.363a.629.629,0,0,0,.641.615h8.372a.629.629,0,0,0,.641-.615V1.846a.629.629,0,0,0-.641-.615H4.314A.629.629,0,0,0,3.673,1.846Zm.888,14.586-.333-.448-.333.448a.649.649,0,0,1-.522.258A.655.655,0,0,1,3,16.575a.6.6,0,0,1-.149-.858l.588-.791-.588-.791A.6.6,0,0,1,3,13.277a.658.658,0,0,1,.894.143l.333.448.333-.448a.658.658,0,0,1,.894-.143.6.6,0,0,1,.149.858l-.588.791.588.791a.6.6,0,0,1-.149.858.656.656,0,0,1-.372.115A.648.648,0,0,1,4.562,16.432Zm5.305-.839a.616.616,0,1,1,0-1.23h3.759a.616.616,0,1,1,0,1.23ZM3.984,11.763a.641.641,0,0,1-.5-.336L2.8,10.118a.6.6,0,0,1,.286-.826.653.653,0,0,1,.86.274l.245.47L5.274,8.793a.659.659,0,0,1,.9-.078.6.6,0,0,1,.081.867L4.55,11.547a.652.652,0,0,1-.492.221A.674.674,0,0,1,3.984,11.763Zm5.883-.978a.616.616,0,1,1,0-1.23h3.759a.616.616,0,1,1,0,1.23Z" transform="translate(-21.024 -42.795)" fill="#ce002a" stroke="#ce002a" stroke-width="0.2" />
                                            </g>
                                        </svg>

                                    </span>
                                    <h4>{ loading_SurveyCount ?  <Spinner animation="border" variant="warning" size="sm" /> :   response_SurveyCount?.surveys}</h4>
                                    <div className="count-text">Surveys</div>
                                </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                    <div className="whitebox shadow-box dashboard-graph-box m-b-20">
                        <div className="d-flex justify-content-between">
                            <div className="graph-title-left">
                                <h4 className="graph-title">Assignment Comparison</h4>
                            </div>
                            <div className="graph-title-right d-flex">
                                {/* <div className="graph-info">
                                    <span className="graph-info-color dbd-primary-graph">&nbsp;</span>
                                    <span className="graph-info-text">Surveys</span>
                                </div> */}
                                <div className="graph-info">
                                    <span className="graph-info-color dbd-secondary-graph">&nbsp;</span>
                                    <span className="graph-info-text">Learning Paths</span>
                                </div>
                            </div>
                        </div>
                        <div className="graph-place">
                            <ChartistGraph
                                // data={chart9Data.data}
                                data={dataArray}
                                options={chart9Data.options}
                                responsiveOptions={chart9Data.responsiveOptions}
                                type={'Bar'}
                                className="ct-12 assignment-comp-graph" />
                        </div>
                    </div>

                </Col>
                <Col md={4}>
                    <div className="whitebox shadow-box completion-graph-box m-b-20">
                        <div className="dashboard-completion-graph">
                            <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth="12" />
                        </div>
                        <div className="completion-graph-sub-title">Completion Percentage</div>
                        <div className="d-flex justify-content-between completion-graph-title-wrap">
                            <h4>Courses</h4><div className="view-all-wrap"><Link to="/coursemanagement">View all</Link></div>
                        </div>
                    </div>
                    <div className="whitebox shadow-box completion-graph-box m-b-20">
                        <div className="dashboard-completion-graph">
                            <CircularProgressbar value={LPpercentage} 
                            text="---"
                            // {`${LPpercentage}%`}
                             strokeWidth="12"                           
                            
                            
                            />
                        </div>
                        <div className="completion-graph-sub-title">Completion Percentage</div>
                        <div className="d-flex justify-content-between completion-graph-title-wrap">
                            <h4>Learning Paths</h4><div className="view-all-wrap"><Link to="/dashboard">View all</Link></div>
                        </div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className="whitebox shadow-box d-flex m-b-20">
                    <Link to="/teams" style={{cursor:'pointer' ,textDecoration: 'none' , color:' inherit'}}>
                        <div className="users-graph-count text-center">
                            <p>Users</p>
                          
                            <h3>{ loading_Dashboard_users?  <Spinner animation="border" variant="warning" size="sm" /> :  displayUser()}</h3>
                           
                        </div> </Link>
                        <div className="users-graph">
                            <div className="users-graph-item-wrap d-flex w-100">
                            {/* <span className="users-graph-item srv-item" style={{ width: '28%' }}></span> */}
                            <span className="users-graph-item srv-item" style={{ width: `${groupSurvey}%` }}></span>
                                <span className="users-graph-item lpth-item" style={{ width: `${userLP}%` }}></span>
                                <span className="users-graph-item crss-item" style={{ width: `${userCourse}%` }}></span>
                               
                            </div>
                            <div className="graph-title-right d-flex justify-content-between">
                            {/* <div className="graph-info">
                                    <span className="graph-info-color srv-item">&nbsp;</span>
                                    <span className="graph-info-text">Surveys</span>
                                </div> */}
                                <div className="graph-info">
                                    <span className="graph-info-color lpth-item">&nbsp;</span>
                                    <span className="graph-info-text">Learning Paths</span>
                                </div>
                                <div className="graph-info">
                                    <span className="graph-info-color crss-item">&nbsp;</span>
                                    <span className="graph-info-text">Courses</span>
                                </div>
                               
                            </div>
                        </div>

                    </div>
                </Col>
                <Col md={6}>
                    <div className="whitebox shadow-box d-flex m-b-20">
                    <Link to="/teams" style={{cursor:'pointer' ,textDecoration: 'none' , color:' inherit'}}>
                        <div className="users-graph-count text-center">
                            <p>Groups</p>                           
                            <h3>{  loading_GroupCount ?  <Spinner animation="border" variant="warning" size="sm" /> :   response_GroupCount?.groups}</h3>
                           
                        </div> </Link>
                        <div className="users-graph">
                            <div className="users-graph-item-wrap d-flex w-100">
                            {/* <span className="users-graph-item srv-item" style={{ width: `${groupSurvey}%` }}></span> */}
                                <span className="users-graph-item lpth-item" style={{ width: `${groupLP}%` }}></span>
                                <span className="users-graph-item crss-item" style={{ width:  `${groupCourse}%` }}></span>
                               
                            </div>
                            <div className="graph-title-right d-flex justify-content-between">
                                <div className="graph-info">
                                    <span className="graph-info-color lpth-item">&nbsp;</span>
                                    <span className="graph-info-text">Learning Paths</span>
                                </div>
                                <div className="graph-info">
                                    <span className="graph-info-color crss-item">&nbsp;</span>
                                    <span className="graph-info-text">Courses</span>
                                </div>
                                {/* <div className="graph-info">
                                    <span className="graph-info-color srv-item">&nbsp;</span>
                                    <span className="graph-info-text">Surveys</span>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default Dashboard;
