import React from 'react';
import Button from 'react-bootstrap/Button';
import LearningPath from './components/learningpath';
import AddQuizAndCourse from './components/addquizandcourse';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class AddDetails extends React.Component {
  render() {
    return (
      <div className="coursedetails-wrap">
        <div className="course-content-sec">
          <LearningPath />
        </div>
        <div className="course-content-sec border-top-box">
          <Form className="d-block">
            <Row>
              <Col md="4">
                <Form.Check
                  custom
                  type="checkbox"
                  id="check1"
                  label={`Add Courses & Quizzes`}
                />
              </Col>
              
            </Row>
          </Form>
        </div>
        <div className="course-content-sec border-top-box">
          <AddQuizAndCourse />
        </div>
        <div className="add-module-footer d-flex justify-content-end">
          <div className="button-wrap double-btn">
            <button className="btn btn-m border-btn">Cancel</button>
            <button className="btn btn-m border-btn">Back</button>
            <Button variant="dark" className="btn-m" >Save</Button>
            <Button variant="dark" className="btn-m">Preview</Button>
            <Button variant="primary" className="btn-m">Publish</Button>
            <Button variant="primary" className="btn-m">Continue</Button>
          </div>
        </div>
      </div>
    );
  }
};
export default AddDetails;