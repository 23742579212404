import React, { Fragment, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Classic from "ckeditor5/packages/ckeditor5-build-classic/build/ckeditor";
import Col from "react-bootstrap/Col";

const CkEditorQuestion = ({
  name,
  values,
  setFieldValue,
  questionsErrors,
  questionsTouched,
  moduleTestsError,
  handleBlur,
  index,
}) => {


  return (
    <Fragment>
      <div>
        <CKEditor
          activeclassName="p10"
          config={{ placeholder: "Enter Question" }}
          data={
            values?.questions[index]?.question
              ? values?.questions[index]?.question
              : ""
          }
          onBlur={(event, editor) => {
            const data = editor.getData();
            setFieldValue(name, data);
          }}
          editor={Classic}
        />
        {questionsErrors?.question &&
        questionsTouched?.question &&
        !!questionsTouched?.question ? (
          <span
            className={`form-error text-danger`}
            style={{ display: "block" }}
          >
            {questionsErrors?.question}
          </span>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default CkEditorQuestion;
