import React from "react";
import User from '~/assets/images/User.jpg';
import ScrollArea from 'react-scrollbar';
function CourseMembers() {
    return (
        <div className="coursemembers-wrapper whitebox shadow-box">
<div className="coursemembers-header d-flex justify-content-between">
<h3>Course Members</h3> <span className="member-count">37</span>
</div>
<ScrollArea
            speed={0.8}
            className="memberscroll"
            contentClassName="content"
            horizontal={false}
            >
<div className="members-list-wrap">
    <div className="members-row d-flex">
        <div className="member-img">
            <img src={User} alt=""/>
        </div>
        <div className="member-det">
            <h5>Chris Cooper</h5>
            <p>jasmine.reyes@mail.com</p>
        </div>
        <span className="memb-close-icon">×</span>
    </div>
    <div className="members-row d-flex">
        <div className="member-img">
            <img src={User} alt=""/>
        </div>
        <div className="member-det">
            <h5>Chris Cooper</h5>
            <p>jasmine.reyes@mail.com</p>
        </div>
        <span className="memb-close-icon">×</span>
    </div>
    <div className="members-row d-flex">
        <div className="member-img">
            <img src={User} alt=""/>
        </div>
        <div className="member-det">
            <h5>Chris Cooper</h5>
            <p>jasmine.reyes@mail.com</p>
        </div>
        <span className="memb-close-icon">×</span>
    </div>
    <div className="members-row d-flex">
        <div className="member-img">
            <img src={User} alt=""/>
        </div>
        <div className="member-det">
            <h5>Chris Cooper</h5>
            <p>jasmine.reyes@mail.com</p>
        </div>
        <span className="memb-close-icon">×</span>
    </div>
    <div className="members-row d-flex">
        <div className="member-img">
            <img src={User} alt=""/>
        </div>
        <div className="member-det">
            <h5>Chris Cooper</h5>
            <p>jasmine.reyes@mail.com</p>
        </div>
        <span className="memb-close-icon">×</span>
    </div>
    <div className="members-row d-flex">
        <div className="member-img">
            <img src={User} alt=""/>
        </div>
        <div className="member-det">
            <h5>Chris Cooper</h5>
            <p>jasmine.reyes@mail.com</p>
        </div>
        <span className="memb-close-icon">×</span>
    </div>
    <div className="members-row d-flex">
        <div className="member-img">
            <img src={User} alt=""/>
        </div>
        <div className="member-det">
            <h5>Chris Cooper</h5>
            <p>jasmine.reyes@mail.com</p>
        </div>
        <span className="memb-close-icon">×</span>
    </div>
    <div className="members-row d-flex">
        <div className="member-img">
            <img src={User} alt=""/>
        </div>
        <div className="member-det">
            <h5>Chris Cooper</h5>
            <p>jasmine.reyes@mail.com</p>
        </div>
        <span className="memb-close-icon">×</span>
    </div>
</div>
</ScrollArea>
        </div>
    );
}
export default CourseMembers;