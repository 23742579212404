import React from 'react';
import ServerErrorImg from '~/assets/images/server-error.svg';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function Forbidden (){

   return (
      <div className="error-page-wrap">
      <div className="error-content-wrap forbidden-wrap text-center">
          <div className="error-img">
             
<svg xmlns="http://www.w3.org/2000/svg" width="462.474" height="418.899" viewBox="0 0 462.474 418.899">
  <g id="Group_7441" data-name="Group 7441" transform="translate(-15006.764 -13332.551)">
    <g id="Background_Simple" transform="translate(15024.999 13332.551)">
      <g id="Group_7398" data-name="Group 7398">
        <path id="Path_8921" data-name="Path 8921" d="M55.513,294.974s2.541,79.555,68.947,132.591,158.447,56.793,232.919,61.876,131.707-37.126,140.326-100.88S449.42,306.133,418.482,233.54,390.306,137.079,325,92.44,157.5,75.2,103.909,151.664,55.513,294.974,55.513,294.974Z" transform="translate(-55.376 -70.956)" fill="rgba(246,132,22,0.16)"/>
      </g>
    </g>
    <g id="Padlock" transform="translate(15035.743 13373.04)">
      <g id="Group_7400" data-name="Group 7400">
        <g id="Group_7399" data-name="Group 7399" transform="translate(5.525)">
          <path id="Path_8923" data-name="Path 8923" d="M85.017,187.265V158.758c0-20.11,16.795-36.573,37.347-36.573s37.347,16.353,37.347,36.573v28.507h14.917V158.758c0-28.176-23.424-51.158-52.263-51.158S70.1,130.582,70.1,158.758v28.507Z" transform="translate(-70.1 -107.6)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        </g>
        <rect id="Rectangle_3363" data-name="Rectangle 3363" width="114.029" height="94.361" transform="translate(0 79.886)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8924" data-name="Path 8924" d="M129.85,216.454a12.377,12.377,0,1,0-18.231,10.718l-2.1,21.436h15.911l-2.1-21.436A12.209,12.209,0,0,0,129.85,216.454Z" transform="translate(-60.903 -97.453)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
      </g>
    </g>
    <g id="Character" transform="translate(15111.983 13403.244)">
      <g id="Group_7410" data-name="Group 7410">
        <path id="Path_8925" data-name="Path 8925" d="M240.637,268.22l-28.286-1.878V265.9l-3.536.221-3.536-.221v.442l-28.286,1.878L156,440.368h20.994l31.159-137.232h1.326l31.159,137.232h20.994Z" transform="translate(-131.802 -121.194)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <g id="Group_7401" data-name="Group 7401" transform="translate(0.442 319.174)">
          <path id="Path_8926" data-name="Path 8926" d="M158.245,423.8l-5.083,7.956-15.8,6.961a5.47,5.47,0,0,0-2.762,4.641v6.077h45.081a62.278,62.278,0,0,0,0-9.723c-.442-4.641-.442-15.8-.442-15.8H158.245Z" transform="translate(-134.489 -423.8)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8927" data-name="Path 8927" d="M134.5,442.3v5.083h45.081s.221-2.21.221-5.083Z" transform="translate(-134.5 -421.859)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        </g>
        <g id="Group_7402" data-name="Group 7402" transform="translate(108.086 319.174)">
          <path id="Path_8928" data-name="Path 8928" d="M253.554,423.8l5.083,7.956,15.8,6.961a5.47,5.47,0,0,1,2.762,4.641v6.077H232.119a62.273,62.273,0,0,1,0-9.723c.442-4.641.442-15.8.442-15.8h20.994Z" transform="translate(-231.922 -423.8)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8929" data-name="Path 8929" d="M277.3,442.3v5.083H232.221S232,445.173,232,442.3Z" transform="translate(-231.914 -421.859)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        </g>
        <path id="Path_8930" data-name="Path 8930" d="M167.248,195.5S134.1,242.128,134.1,245.443s5.3,29.723,13.7,32.264c8.4,2.431,13.038-1.878,14.585-10.5,1.547-8.729-3.757-21.1-3.757-21.1L174.1,232.184Z" transform="translate(-134.1 -128.581)" fill="#f68416" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8931" data-name="Path 8931" d="M159.062,259.4s9.613-1.547,12.044-.884,4.309,6.519,4.309,6.519.884,8.729-.663,10.5c-1.547,1.878-8.95,3.978-12.044,4.641s-9.613-6.519-12.044-10.828C148.233,264.924,152.653,257.521,159.062,259.4Z" transform="translate(-132.433 -121.986)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8932" data-name="Path 8932" d="M171.061,194.366l-6.961,2.1,10.939,73.367h65.3l9.944-72.925-8.95-3.425L208.629,186.3Z" transform="translate(-130.952 -129.546)" fill="#f68416" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <g id="Group_7403" data-name="Group 7403" transform="translate(88.505 88.465)">
          <path id="Path_8933" data-name="Path 8933" d="M238.619,215.884l-1.436,10.5L225.8,229.143,214.2,224.5l.773-9.5Z" transform="translate(-214.2 -215)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8934" data-name="Path 8934" d="M223.5,223.689a1.989,1.989,0,1,1,1.989,1.989A1.981,1.981,0,0,1,223.5,223.689Z" transform="translate(-213.224 -214.297)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        </g>
        <path id="Path_8935" data-name="Path 8935" d="M198.257,199.45l6.077,7.182L196.6,256.464l8.176,13.038,9.723-13.7-8.176-48.948L213.284,200l-7.4-9.5Z" transform="translate(-127.542 -129.105)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8936" data-name="Path 8936" d="M195.476,187.3,167.3,194.261l.884,3.425,27.4-7.624Z" transform="translate(-130.616 -129.441)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8937" data-name="Path 8937" d="M213.621,186.5l28.065,6.961-.773,3.425L213.4,189.262Z" transform="translate(-125.779 -129.525)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8938" data-name="Path 8938" d="M239.489,192.8s10.607,2.431,17.126,6.077c6.519,3.757,8.066,12.044,4.309,21.436-3.757,9.281-15.248,8.066-20.441,5.635-5.3-2.431-6.851-11.823-5.856-15.8" transform="translate(-123.582 -128.864)" fill="#f68416" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8939" data-name="Path 8939" d="M194.2,184.052l-3.2,2.1.884,21.436,13.7-9.723,14.364,9.944,1.326-21.215-3.867-3.094Z" transform="translate(-128.13 -129.84)" fill="#f68416" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <g id="Group_7406" data-name="Group 7406" transform="translate(53.995)">
          <path id="Path_8940" data-name="Path 8940" d="M222.781,163.845s7.845-1.878,9.281-9.723-12.707-17.237-22.32-19c-9.723-1.657-24.087,8.4-26.408,15.358s6.74,13.922,16.463,15.358C209.522,167.381,222.781,163.845,222.781,163.845Z" transform="translate(-182.968 -134.936)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8941" data-name="Path 8941" d="M193.22,162.5s-4.2-6.519-5.3-.884c-1.1,5.746,3.425,8.176,5.3,8.066C195.1,169.463,193.22,162.5,193.22,162.5Z" transform="translate(-182.467 -132.377)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8942" data-name="Path 8942" d="M217.579,162.5s4.2-6.519,5.3-.884c1.1,5.746-3.425,8.176-5.3,8.066C215.7,169.463,217.579,162.5,217.579,162.5Z" transform="translate(-179.424 -132.377)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8943" data-name="Path 8943" d="M193.5,173.9v13.922l11.823,11.049,13.37-11.491V174.342Z" transform="translate(-181.863 -130.847)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <g id="Group_7405" data-name="Group 7405" transform="translate(9.672 16.324)">
            <path id="Path_8944" data-name="Path 8944" d="M191.919,159.312s-.663,13.038.442,17.237,8.066,13.038,13.48,14.917c5.525,1.878,13.038-9.5,14.806-13.259s1.1-19.778,1.1-19.778-2.32-5.525-6.961-7.182C210.04,149.589,195.786,146.605,191.919,159.312Z" transform="translate(-191.721 -149.71)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
            <g id="Group_7404" data-name="Group 7404" transform="translate(7.048 14.786)">
              <path id="Path_8945" data-name="Path 8945" d="M200.089,164.869c0,.994-.442,1.768-.994,1.768s-.994-.773-.994-1.768.442-1.768.994-1.768C199.536,162.99,200.089,163.874,200.089,164.869Z" transform="translate(-198.1 -163.092)" fill="#263238" stroke="#707070" stroke-width="1"/>
              <path id="Path_8946" data-name="Path 8946" d="M211.789,164.869c0,.994-.442,1.768-.994,1.768s-.994-.773-.994-1.768.442-1.768.994-1.768C211.347,162.99,211.789,163.874,211.789,164.869Z" transform="translate(-196.872 -163.092)" fill="#263238" stroke="#707070" stroke-width="1"/>
              <path id="Path_8947" data-name="Path 8947" d="M201.968,169.2a1.636,1.636,0,0,0-1.768,1.326c0,.773.773,1.326,1.768,1.326a1.662,1.662,0,0,0,1.215-.442,3.208,3.208,0,0,0,2.652,1.215,3.478,3.478,0,0,0,2.652-1.1,1.867,1.867,0,0,0,1.1.331,1.636,1.636,0,0,0,1.768-1.326c0-.773-.773-1.326-1.768-1.326" transform="translate(-197.88 -162.451)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
              <line id="Line_196" data-name="Line 196" x2="4.42" transform="translate(5.856 15.478)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
            </g>
          </g>
          <path id="Path_8948" data-name="Path 8948" d="M191.5,161.9s.111,4.2,1.547,3.094,1.878-8.287.663-9.281C192.384,154.826,191.5,159.688,191.5,161.9Z" transform="translate(-182.073 -132.767)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8949" data-name="Path 8949" d="M216.1,161.9s.11,4.2,1.547,3.094,1.878-8.287.663-9.281C217.094,154.826,216.1,159.688,216.1,161.9Z" transform="translate(-179.491 -132.767)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8950" data-name="Path 8950" d="M191.512,159.228s.442.331.442.552c1.989,1.1,6.74,3.094,14.364,3.536a30.288,30.288,0,0,0,15.469-3.757v-.884c-.221-4.972-3.867-8.176-8.4-9.5a21.66,21.66,0,0,0-13.48.663,13.792,13.792,0,0,0-5.635,3.978C193.722,154.477,190.739,158.565,191.512,159.228Z" transform="translate(-182.085 -133.523)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8951" data-name="Path 8951" d="M191.9,158.4s5.3,1.436,14.364,1.1c9.06-.442,15.358-1.1,15.358-1.1s-6.188,6.3-15.248,6.74A16.709,16.709,0,0,1,191.9,158.4Z" transform="translate(-182.031 -132.474)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        </g>
        <g id="Group_7407" data-name="Group 7407" transform="translate(40.772 88.465)">
          <path id="Path_8952" data-name="Path 8952" d="M171,215.884l1.436,10.5,11.381,2.762,11.6-4.641-.773-9.5Z" transform="translate(-171 -215)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <path id="Path_8953" data-name="Path 8953" d="M184.178,223.689a1.989,1.989,0,1,0-1.989,1.989A1.981,1.981,0,0,0,184.178,223.689Z" transform="translate(-170.035 -214.297)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        </g>
        <rect id="Rectangle_3364" data-name="Rectangle 3364" width="66.959" height="10.497" transform="translate(43.092 139.623)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8954" data-name="Path 8954" d="M186.468,274.849H171.883a2.971,2.971,0,0,1-2.983-2.983v-7.182a2.971,2.971,0,0,1,2.983-2.983h14.585a2.971,2.971,0,0,1,2.983,2.983v7.182A3.121,3.121,0,0,1,186.468,274.849Z" transform="translate(-130.449 -121.635)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8955" data-name="Path 8955" d="M234.768,274.849H220.183a2.971,2.971,0,0,1-2.983-2.983v-7.182a2.971,2.971,0,0,1,2.983-2.983h14.585a2.971,2.971,0,0,1,2.983,2.983v7.182A3.121,3.121,0,0,1,234.768,274.849Z" transform="translate(-125.38 -121.635)" fill="#4c4c4c" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <rect id="Rectangle_3365" data-name="Rectangle 3365" width="14.585" height="9.281" transform="translate(70.384 140.176)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <path id="Path_8956" data-name="Path 8956" d="M157.507,241.919s-9.281-.663-12.707-6.519" transform="translate(-132.977 -124.394)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <ellipse id="Ellipse_336" data-name="Ellipse 336" cx="15.358" cy="16.132" rx="15.358" ry="16.132" transform="translate(112.592 57.969)" fill="#f68416" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <ellipse id="Ellipse_337" data-name="Ellipse 337" cx="12.044" cy="10.718" rx="12.044" ry="10.718" transform="translate(115.907 55.207)" fill="#f68416" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <g id="Group_7409" data-name="Group 7409" transform="translate(108.614 13.551)">
          <ellipse id="Ellipse_338" data-name="Ellipse 338" cx="12.044" cy="10.718" rx="12.044" ry="10.718" transform="translate(7.293 33.811)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          <g id="Group_7408" data-name="Group 7408">
            <path id="Path_8957" data-name="Path 8957" d="M232.4,168.415s.221,15.911.994,20.552,4.088,10.828,8.508,10.828a26.953,26.953,0,0,0,8.729-1.657s5.3,2.873,10.386,1.989c5.083-.773,4.309-7.955,4.309-11.049S264.664,163,264.664,163a2.2,2.2,0,0,0-1.657-2.652,15.931,15.931,0,0,0-3.094-.442v-4.862s-.773-1.657-3.425-1.657-2.873.663-2.873.663v-4.53s-2.21-3.536-5.083-1.878a4.147,4.147,0,0,0-1.989,3.315l-.221,4.309s-4.862-1.878-5.3.994-1.215,20.331-1.215,20.331a48.632,48.632,0,0,0-1.657-8.176C237.262,165.984,232.4,165.984,232.4,168.415Z" transform="translate(-232.4 -147.2)" fill="#ccc" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
            <line id="Line_197" data-name="Line 197" x2="0.442" y2="18.784" transform="translate(14.033 8.177)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
            <line id="Line_198" data-name="Line 198" x2="0.221" y2="20.22" transform="translate(21.436 6.961)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
            <line id="Line_199" data-name="Line 199" x1="0.442" y2="14.033" transform="translate(27.292 12.928)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
            <line id="Line_200" data-name="Line 200" x2="18.563" y2="0.884" transform="translate(11.381 26.739)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
          </g>
          <path id="Path_8958" data-name="Path 8958" d="M239.2,174a5.159,5.159,0,0,0,1.989,3.978c1.989,1.215,6.519,4.53,7.072,9.834" transform="translate(-231.686 -144.388)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        </g>
      </g>
    </g>
    <g id="Text" transform="translate(15302.362 13385.525)">
      <g id="Group_7415" data-name="Group 7415" transform="translate(0)">
        <g id="Group_7412" data-name="Group 7412" transform="translate(39.556)">
          <g id="Group_7411" data-name="Group 7411">
            <path id="Path_8959" data-name="Path 8959" d="M350.818,129.807v2.431h-5.856v8.618h7.182v2.431H342.2V119.2h9.944v2.431h-7.182v8.176h5.856Z" transform="translate(-342.2 -118.869)" fill="#333"/>
            <path id="Path_8960" data-name="Path 8960" d="M358.814,119.2c3.757,0,5.414,1.878,5.414,5.525v1.989c0,2.652-.884,4.309-2.983,4.972,2.21.663,2.983,2.431,2.983,4.972v3.757a5.846,5.846,0,0,0,.442,2.873h-2.762a5.915,5.915,0,0,1-.442-2.873v-3.757c0-2.762-1.326-3.536-3.536-3.536h-1.878V143.4H353.4V119.31h5.414Zm-.663,11.27c2.1,0,3.425-.663,3.425-3.315v-2.32c0-2.21-.773-3.315-2.762-3.315h-2.652v8.95Z" transform="translate(-341.025 -118.869)" fill="#333"/>
            <path id="Path_8961" data-name="Path 8961" d="M371.114,119.2c3.757,0,5.414,1.878,5.414,5.525v1.989c0,2.652-.884,4.309-2.983,4.972,2.21.663,2.983,2.431,2.983,4.972v3.757a5.846,5.846,0,0,0,.442,2.873h-2.762a5.915,5.915,0,0,1-.442-2.873v-3.757c0-2.762-1.326-3.536-3.536-3.536h-1.878V143.4H365.7V119.31h5.414Zm-.773,11.27c2.1,0,3.425-.663,3.425-3.315v-2.32c0-2.21-.773-3.315-2.762-3.315h-2.652v8.95Z" transform="translate(-339.734 -118.869)" fill="#333"/>
            <path id="Path_8962" data-name="Path 8962" d="M377.8,124.977c0-3.646,1.878-6.077,5.525-6.077,3.757,0,5.525,2.32,5.525,6.077v12.486c0,3.646-1.878,6.077-5.525,6.077s-5.525-2.431-5.525-6.077Zm2.652,12.6c0,2.21.884,3.425,2.873,3.425s2.873-1.326,2.873-3.425V124.867c0-2.21-.884-3.425-2.873-3.425-1.878,0-2.873,1.326-2.873,3.425Z" transform="translate(-338.465 -118.9)" fill="#333"/>
            <path id="Path_8963" data-name="Path 8963" d="M395.514,119.2c3.757,0,5.414,1.878,5.414,5.525v1.989c0,2.652-.884,4.309-2.983,4.972,2.21.663,2.983,2.431,2.983,4.972v3.757a5.846,5.846,0,0,0,.442,2.873h-2.762a5.915,5.915,0,0,1-.442-2.873v-3.757c0-2.762-1.326-3.536-3.536-3.536h-1.878V143.4H390.1V119.31h5.414Zm-.773,11.27c2.1,0,3.425-.663,3.425-3.315v-2.32c0-2.21-.773-3.315-2.762-3.315h-2.652v8.95Z" transform="translate(-337.174 -118.869)" fill="#333"/>
          </g>
        </g>
        <g id="Group_7413" data-name="Group 7413" transform="translate(17.237 149.828)">
          <path id="Path_8964" data-name="Path 8964" d="M330.176,265.97V268.4h-5.525v10.386H322V254.7h9.613v2.431h-6.851v8.729h5.414Z" transform="translate(-322 -254.479)" fill="#333"/>
          <path id="Path_8965" data-name="Path 8965" d="M332.3,260.577c0-3.646,1.878-6.077,5.525-6.077,3.757,0,5.525,2.32,5.525,6.077v12.486c0,3.646-1.878,6.077-5.525,6.077s-5.525-2.431-5.525-6.077Zm2.652,12.6c0,2.21.884,3.425,2.873,3.425s2.873-1.326,2.873-3.425V260.467c0-2.21-.884-3.425-2.873-3.425-1.878,0-2.873,1.326-2.873,3.425Z" transform="translate(-320.919 -254.5)" fill="#333"/>
          <path id="Path_8966" data-name="Path 8966" d="M350.014,254.7c3.757,0,5.414,1.878,5.414,5.525v1.989c0,2.652-.884,4.309-2.983,4.972,2.21.663,2.983,2.431,2.983,4.972v3.757a5.846,5.846,0,0,0,.442,2.873h-2.762a5.915,5.915,0,0,1-.442-2.873v-3.757c0-2.762-1.326-3.536-3.536-3.536h-1.878V278.9H344.6V254.81h5.414Zm-.663,11.381c2.1,0,3.425-.663,3.425-3.315v-2.32c0-2.21-.773-3.315-2.762-3.315h-2.652v8.95Z" transform="translate(-319.629 -254.479)" fill="#333"/>
          <path id="Path_8967" data-name="Path 8967" d="M362.325,254.71c3.757,0,5.193,1.768,5.193,5.3v1.1c0,2.541-.773,4.088-2.873,4.751,2.32.663,3.2,2.541,3.2,5.193v1.989c0,3.646-1.768,5.635-5.525,5.635H356.8V254.6h5.525Zm-.663,10.276c2.1,0,3.2-.663,3.2-3.2v-1.436c0-2.1-.773-3.2-2.652-3.2h-2.652v7.734h2.1Zm.773,11.381c1.989,0,2.873-.994,2.873-3.315v-2.1c0-2.652-1.1-3.536-3.425-3.536h-2.32v8.95Z" transform="translate(-318.348 -254.489)" fill="#333"/>
          <path id="Path_8968" data-name="Path 8968" d="M371.852,254.7v24.087H369.2V254.7Z" transform="translate(-317.047 -254.479)" fill="#333"/>
          <path id="Path_8969" data-name="Path 8969" d="M379.746,254.7c3.757,0,5.525,2.32,5.525,5.967v12.154c0,3.646-1.768,5.967-5.525,5.967H374V254.7Zm-.111,21.657c1.878,0,2.873-1.215,2.873-3.425V260.556c0-2.21-.884-3.425-2.873-3.425h-2.873v19.226Z" transform="translate(-316.544 -254.479)" fill="#333"/>
          <path id="Path_8970" data-name="Path 8970" d="M392.035,254.7c3.757,0,5.525,2.32,5.525,5.967v12.154c0,3.646-1.768,5.967-5.525,5.967H386.4V254.7Zm0,21.657c1.878,0,2.873-1.215,2.873-3.425V260.556c0-2.21-.884-3.425-2.873-3.425h-2.873v19.226Z" transform="translate(-315.243 -254.479)" fill="#333"/>
          <path id="Path_8971" data-name="Path 8971" d="M407.318,265.407v2.431h-5.856v8.618h7.182v2.431H398.7V254.8h9.944v2.431h-7.182v8.176Z" transform="translate(-313.952 -254.469)" fill="#333"/>
          <path id="Path_8972" data-name="Path 8972" d="M412.331,278.787H409.9V254.7h3.425l5.525,17.458V254.7h2.32v24.087h-2.762l-6.188-19.447v19.447Z" transform="translate(-312.777 -254.479)" fill="#333"/>
        </g>
        <g id="Group_7414" data-name="Group 7414" transform="translate(0 39.556)">
          <path id="Path_8973" data-name="Path 8973" d="M306.51,213.819,331.04,155.7h14.7v58.119h6.409v12.265h-6.409v15.579H332.476V226.084H306.4V213.819Zm26.076,0V181.445L319,213.819Z" transform="translate(-306.4 -154.595)" fill="#333"/>
          <path id="Path_8974" data-name="Path 8974" d="M352.5,176.357c0-13.812,7.293-21.657,20.552-21.657s20.552,7.845,20.552,21.657v44.75c0,13.812-7.293,21.657-20.552,21.657S352.5,234.918,352.5,221.106Zm13.48,45.634c0,6.188,2.652,8.508,6.961,8.508s6.961-2.32,6.961-8.508V175.583c0-6.188-2.652-8.508-6.961-8.508s-6.961,2.32-6.961,8.508Z" transform="translate(-301.563 -154.7)" fill="#333"/>
          <path id="Path_8975" data-name="Path 8975" d="M423.639,176.9c0-7.734-2.652-9.834-6.961-9.834s-6.961,2.32-6.961,8.508V181.1H396.9v-4.641c0-13.812,6.851-21.657,20.11-21.657s20.11,7.845,20.11,21.657v2.21c0,9.171-2.983,15.027-9.5,17.679,6.74,2.983,9.5,9.392,9.5,18.01v6.74c0,13.812-6.851,21.657-20.11,21.657S396.9,234.907,396.9,221.1v-7.182h12.817v7.955c0,6.188,2.652,8.508,6.961,8.508s6.961-2.1,6.961-9.723v-6.74c0-7.955-2.652-10.939-8.839-10.939h-4.53V190.71h5.3c5.083,0,8.066-2.21,8.066-9.06V176.9Z" transform="translate(-296.904 -154.689)" fill="#333"/>
        </g>
      </g>
    </g>
    <g id="Group_7416" data-name="Group 7416" transform="translate(15044.029 13601.097)">
      <path id="Path_8976" data-name="Path 8976" d="M88.4,460.4H72.6L93.594,314h15.8Z" transform="translate(-72.6 -314)" fill="#263238"/>
      <path id="Path_8977" data-name="Path 8977" d="M117.394,460.4h15.69L112.09,314H96.4Z" transform="translate(-70.103 -314)" fill="#c4c4c4"/>
    </g>
    <path id="Path_8978" data-name="Path 8978" d="M290.7,460.4H274.9L295.894,314h15.8Z" transform="translate(14992.656 13287.097)" fill="#263238" stroke="#263238" stroke-width="1.166"/>
    <path id="Path_8979" data-name="Path 8979" d="M319.694,460.4h15.8L314.39,314H298.7Z" transform="translate(14995.154 13287.097)" fill="#c4c4c4"/>
    <rect id="Rectangle_3366" data-name="Rectangle 3366" width="300.43" height="29.17" transform="translate(15025.356 13578.114)" fill="#f68416"/>
    <rect id="Rectangle_3367" data-name="Rectangle 3367" width="300.43" height="29.17" transform="translate(15032.76 13578.114)" fill="#f68416"/>
    <g id="Group_7417" data-name="Group 7417" transform="translate(15037.621 13578.114)">
      <path id="Path_8980" data-name="Path 8980" d="M66.8,322.37H92.324l13.149-29.17H79.949Z" transform="translate(-66.8 -293.2)" fill="#333"/>
      <path id="Path_8981" data-name="Path 8981" d="M161.872,293.2H136.349L123.2,322.37h25.413Z" transform="translate(-60.882 -293.2)" fill="#333"/>
      <path id="Path_8982" data-name="Path 8982" d="M330.872,293.2H305.349L292.2,322.37h25.524Z" transform="translate(-43.149 -293.2)" fill="#333"/>
      <path id="Path_8983" data-name="Path 8983" d="M274.572,293.2H249.049L235.9,322.37h25.413Z" transform="translate(-49.057 -293.2)" fill="#333"/>
      <path id="Path_8984" data-name="Path 8984" d="M218.172,293.2H192.649L179.5,322.37h25.524Z" transform="translate(-54.975 -293.2)" fill="#333"/>
    </g>
    <g id="Group_7418" data-name="Group 7418" transform="translate(15031.434 13589.274)">
      <line id="Line_201" data-name="Line 201" x1="49.722" transform="translate(250.377 1.768)" fill="none" stroke="#333" stroke-width="1.166"/>
      <line id="Line_202" data-name="Line 202" x1="7.845" transform="translate(291.37 6.63)" fill="none" stroke="#333" stroke-width="1.166"/>
      <line id="Line_203" data-name="Line 203" x2="49.722" transform="translate(0 5.414)" fill="none" stroke="#333" stroke-width="1.166"/>
      <line id="Line_204" data-name="Line 204" x2="62.76" transform="translate(0.884)" fill="none" stroke="#333" stroke-width="1.166"/>
    </g>
    <g id="Group_7424" data-name="Group 7424" transform="translate(15329.985 13620.544)">
      <g id="Group_7423" data-name="Group 7423">
        <rect id="Rectangle_3368" data-name="Rectangle 3368" width="85.19" height="6.409" transform="translate(0 120.548)" fill="#333"/>
        <g id="Group_7422" data-name="Group 7422" transform="translate(14.364)">
          <path id="Path_8985" data-name="Path 8985" d="M400.862,452.037H344.4L366.5,331.6h12.265Z" transform="translate(-344.4 -331.6)" fill="#f68416"/>
          <path id="Path_8986" data-name="Path 8986" d="M357,373.517h28.618L382.855,358.6H359.762Z" transform="translate(-343.078 -328.767)" fill="#fff"/>
        </g>
      </g>
      <line id="Line_205" data-name="Line 205" x2="1.436" y2="8.176" transform="translate(62.318 100.327)" fill="#c6ff00"/>
      <line id="Line_206" data-name="Line 206" x2="14.364" y2="83.312" transform="translate(46.186 6.63)" fill="#c6ff00"/>
    </g>
    <g id="Floor" transform="translate(15007.347 13747.5)">
      <g id="Group_7425" data-name="Group 7425">
        <line id="Line_207" data-name="Line 207" x2="7.624" transform="translate(453.683)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
        <line id="Line_208" data-name="Line 208" x2="443.076" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.166"/>
      </g>
    </g>
  </g>
</svg>

          </div>
          <h4>Access Denied!</h4>
          <div className="error-btn-wrap">
              <Link to="/my-assignments"><Button>Go to Assignments</Button></Link>
          </div>
      </div>
  </div>
   );
};
export default Forbidden;
