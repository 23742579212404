import React, { useState, useEffect, useCallback } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import CourseDummy from '~/assets/images/course_dummy.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import debounce from "lodash.debounce";
import {

    getAdminCoursesList,
    deleteCourse,
    handlePaginationAdminCourse,
    handlePreviousPaginationAdminCourse,
    cloneCourse, getDraftCoursesManagerList, getManagerCoursesList
} from '../../../services';
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { getNotifications } from "../../../../Notifications/services";
import Select, { components } from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const AvailableCourses = (props) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const response = useSelector(state => state?.course?.course_listing_admin?.response);
    const loading = useSelector(state => state?.course?.course_listing_admin?.loading);
    const cloneloading = useSelector(state => state?.course?.cloned_course?.loading);
    const { hideLpQuizes } = useSelector((state) => state.settings);
    const categoryResponse = useSelector(
        (state) => state?.course?.course_categories?.response
    );
    const [state, setState] = useState({
        courses: response,
        count: response?.count,
        paginationItem: []
    })


    const [courseid, setcourseid] = useState('')
    const [activePage, setActivePage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [count, setCount] = useState(0)
    const [categorySelected, setCategorySelected] = useState('');

    const [createdby, setCreatedby] = useState('');
    useEffect(() => {
        if (props?.type == 'staff_manager' || props?.type == 'manager') {
            setSearch('');
            setDateRange([null, null]);
            dispatch(getAdminCoursesList('', '', ''));
            // dispatch(getNotifications())
        }
    }, [])
    useEffect(() => {
        if (count > 0 && dateRange[0] == null) {
            setActivePage(1);
            dispatch(getAdminCoursesList('', '', ''));
        }
    }, [count])
    useEffect(() => {
        const count = response?.count
        const perPage = 12;
        const buttonsCount = Math.ceil(count / perPage);
        let items = []
        for (let number = 1; number <= Number(buttonsCount); number++) {
            items.push(
                <Pagination.Item onClick={() => handleIndex(number)} key={number} active={number == activePage}>
                    {number}
                </Pagination.Item>,
            );
        }
        setState({ ...state, courses: response, count: response?.count, paginationItem: items });
    }, [response]);

    useEffect(() => {

        if (startDate !== null && endDate !== null) {
            setActivePage(1)
            dispatch(getAdminCoursesList(search, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd'), '', categorySelected?.id));
        }
    }, [startDate, endDate]);

    const debouncedGetSearch = useCallback(debounce(query => {

        setActivePage(1)
        dispatch(getAdminCoursesList(query, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), '', categorySelected?.id));

    }, 1000), [categorySelected])

    const searchText = (e) => {
        setSearch(e.target.value);
        debouncedGetSearch(e.target.value)
    }


    const reset = () => {
        setSearch('');
        setDateRange([null, null]);
        setCategorySelected('');
        dispatch(getAdminCoursesList("", startDate === null ? '' : '', endDate == null ? '' : ''));
        setActivePage(1)
    };
    const handleNext = () => {

        if (state.courses?.next) {
            var regex = /page=\d+/g;
            const [number] = state.courses?.next?.match(regex)
            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
            dispatch(handlePaginationAdminCourse(state.courses?.next))
        } else {
            return false
        }

    }
    const handlePrevious = () => {

        if (state.courses?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            dispatch(handlePreviousPaginationAdminCourse(state.courses?.previous))

        } else {
            return false
        }

    }

    const handleIndex = (index) => {

        dispatch(getAdminCoursesList(search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), index, categorySelected?.id));

        setActivePage(index);
    }

    const handleCardClick = (el) => {
        history.push(`/course/admin/${el?.id}`)
    }

    const CaretDownIcon = () => {
        return (
            <div className="filter-icon-select">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
                    <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
                </svg>
            </div>
        );
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon />
            </components.DropdownIndicator>
        );
    };


    const onCategorySelect = (val) => {
        setCategorySelected(val);
        setActivePage(1)

        dispatch(getAdminCoursesList(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), '', val.id));

    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="available-course-wrap">

            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="datatable-filter-left-box d-flex align-items-center">
                    <div className="datepicker-box p-relative">
                        <DatePicker
                            placeholderText={'Created date'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setCount(count + 1)
                                setDateRange(update);
                            }}
                            isClearable={true}

                        />
                        <span className="datepickericon">
                            <i className="fa fa-calendar"></i>
                        </span>
                    </div>

                    {/*<div className="dt-filter-left d-flex justify-content-between align-items-center">*/}
                    {/*    <div className="custom-react-select">*/}
                    {/*        <Select options={managers || []}  value={createdby}  onChange={onSelect} placeholder="Created"/>*/}
                    {/*    </div>*/}
                    {/*    <button type="button" className="iconbutton">*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">*/}
                    {/*            <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />*/}
                    {/*        </svg>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
                <div className="dt-filter-left d-flex justify-content-between align-items-center">
                    <div className="custom-react-select">
                        <Select options={categoryResponse?.results || []}
                            value={categorySelected} onChange={onCategorySelect} placeholder="Category"
                            getOptionLabel={(option) => option.name} getOptionValue={option => option.id}
                            components={{ DropdownIndicator }}

                        />
                    </div>


                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative search-icon-left">
                        <button className="iconbutton search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                                <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                            </svg>
                        </button>
                        <input type="text" placeholder="Search using keyword"
                            value={search} onChange={searchText}
                            className="form-control input-item" />
                        <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                    </div>

                    <OverlayTrigger
                        overlay={
                            <Tooltip id="InfoText">
                                Help
                            </Tooltip>
                        }
                    >
                        <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                        </span>
                    </OverlayTrigger>
                </div>
            </div>
            <div className="courseslisting">
                <Row>
                    {loading && loading ?
                        (
                            <>
                                <div className='loaderCourses'>
                                    <Spinner animation="border" variant="danger" />
                                </div>

                            </>
                        ) : (
                            <>
                                {!loading && state.courses && state.courses && state.courses?.results && state.courses?.results.length ? state.courses?.results?.map((el, index) => (
                                    <Col style={{ cursor: 'pointer' }} lg={4} xl={3} className="m-b-30 course-box-outer">
                                        <div className="common-card coursecard" onClick={() => handleCardClick(el)}>
                                            <div className="course-box-top">
                                                <div className="course-listing-img">
                                                    <img src={el?.image ? el?.image : CourseDummy} alt="" />
                                                </div>
                                                <div className="common-card-detail-wrap box-listing">
                                                    <h4>{el?.name}</h4>
                                                    <p>{el?.description}</p>
                                                </div>
                                                <div className="coommon-card-det-number d-flex justify-content-between">
                                                    <span className="common-card-oth-det-left">Modules <span className="det-numb">{el?.modules_count}</span> </span>
                                                    {!hideLpQuizes && (<span className="common-card-oth-det-left">Quizzes <span className="det-numb">{el?.quizzes_count}</span> </span>)}
                                                </div>
                                            </div>
                                            {/*<div className="common-card-footer border-top-footer">*/}
                                            {/*    <div className="common-card-footer-cont course-card-footer no-border d-flex flex-wrap justify-content-between align-items-center">*/}
                                            {/*<div className="grp-name w-100">Group name</div>*/}
                                            {/*{el?.is_owned_by_admin ?*/}
                                            {/*    <button type="button" className="btn border-btn" onClick={()=>handleClone(el?.id)}>*/}

                                            {/*        {cloneloading && cloneloading && courseid == el?.id ?*/}
                                            {/*            <Spinner animation="border" variant="warning" />*/}
                                            {/*            :*/}
                                            {/*            <span>Clone</span>*/}
                                            {/*        }*/}

                                            {/*    </button>*/}
                                            {/*    :*/}
                                            {/*    <>*/}
                                            {/*        /!*<button type="button" onClick={e=>handleDeleteCourse(el)} className="btn border-btn">Delete</button>*!/*/}
                                            {/*        /!*<button type="button" className="btn border-btn" onClick={()=>handleClone(el?.id)}>Edit</button>*!/*/}
                                            {/*    </>*/}
                                            {/*}*/}
                                            <div className="box-overlay">
                                                <span className="overlay-viewmore">View More<i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                                                <div className="overlay-bg">&nbsp;</div>
                                            </div>

                                        </div>



                                    </Col>
                                ))
                                    : (

                                        <>
                                        {search || categorySelected || dateRange[0] || dateRange[1] ?
                                            <span className='noData'>No matching results found</span>
                                            :

                                            <span className='noData text-center'>You have no courses in your Template Library.</span>
                                        }
                                    </>
                                    )
                                    

                                }

                            </>


                        )

                    }
                </Row>
                {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response &&
                    <div className="d-flex justify-content-center">
                        <Pagination size="sm" className="custom-pagination">
                            <Pagination.First disabled={!state?.courses?.previous} className={`${state?.courses?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious} />
                            {state.paginationItem}
                            <Pagination.Last disabled={!state?.courses?.next} onClick={handleNext} className={`${state?.courses?.next ? 'paginationStyle' : ''} `} />

                        </Pagination>
                    </div>
                }
            </div>
            <Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                      <div className="module-modal-header my-4"><h4>Courses are developed and published by you and others in your organization here.</h4></div>
                   
                     <ol className="info-list">
                  
                        <li><span>The “Create New Course” button enables managers to create courses and enroll learners in them.</span></li>
                        <li><span>When a course draft is published, it will appear in the “Published Courses” tab.</span></li>
                        <li><span>Courses can be: 1&#41;	 previewed, 2&#41;	 deleted, or 3&#41;	 cloned.</span></li>
                        <li><span>Published courses cannot be edited. To duplicate a course, clone it, and the course will appear under the “Drafts” tab.</span></li>
                        <li><span>Search for a course using the: 1&#41;	 Created Date, 2&#41;	 Author, 3&#41;	 Category filters, or 4&#41;	 by keyword. </span></li>
                    </ol>
                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
        </div>
    );
};
export default AvailableCourses;
