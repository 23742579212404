import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { listGeneralSurveyReport } from "../../../../services";
import ScrollArea from "react-scrollbar";
import Table from "react-bootstrap/Table";
const { Option } = Select;
const label = "ABCDEFGHIJKLMN";
const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const initialOptions = {
  responsive: true,
  maintainAspectRatio: false,
  pan: {
    enabled: true,
    mode: "xy",
  },
  zoom: {
    enabled: true,
    mode: "xy",
  },
  skipNull: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "center",
      labels: {
        usePointStyle: true,
      },
    },
  },
};

const MyChart = (props) => {
  const { demographicQuestions, generalQuestions, surveyId, generalQuestion,title } =
    props;
  const type =title;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    filters: [],
  });

  const [surveyReport, setSurveyReport] = useState({});

  const sampleData = {
    legend: {
      display: false,
      position: "right",
    },

    labels: [],
    datasets: [
      {
        label: type,
        data: [],
        barPercentage: 0.6,
        // barThickness:30,
        backgroundColor: ["#1b59ff", "#6610f2", "#084298", "#8c4efc"],
      },
    ],
  };

  const [dataChart, setDataChart] = useState(sampleData);

  const [age_data_set, setAgeData] = useState([]);
  const [gender_data_set, setGenderData] = useState([]);
  const [ethnicity_data_set, setEthnicityData] = useState([]);

  const [age_api_data, setAgeApiData] = useState([]);
  const [gender_api_data, setGenderApiData] = useState([]);
  const [ethnicity_api_data, setEthnicityApiData] = useState([]);

  const [age_id, setAgeId] = useState("");
  const [gender_id, setGenderId] = useState("");
  const [ethnicity_id, setEthnicityId] = useState("");

  useEffect(() => {
    var key = ethnicity_id;
    var data = {
      question: generalQuestion?.question_id,
    };
    data["question"] = generalQuestion?.question_id;
    data[ethnicity_id] = ethnicity_api_data;
    data[age_id] = age_api_data;
    data[gender_id] = gender_api_data;

    dispatch(
      listGeneralSurveyReport(surveyId, data, function (response) {
        let newArray = response;
        response?.choices.map((entry, index) => {
          let array1 = response?.count?.filter(
            (item) => item[Object.keys(item)[0]] === entry?.choice
          );

          if (!array1?.length) {
            let obj = {
              answer: entry?.choice,
              count: 0,
              percentage: 0,
            };
            newArray.count?.push(obj);
          }
        });

        let array2 = newArray?.count.filter(
          (item) => item[Object.keys(item)[0]].length
        );
        let obj = {
          count: array2,
        };

        setSurveyReport(obj);
      })
    );
  }, [ethnicity_api_data, age_api_data, gender_api_data]);

  useEffect(() => {
    if (props?.demographicQuestions && props?.demographicQuestions?.length) {
      let allFilters = [];
      demographicQuestions?.map((item, index) => {
        let mulchoices = item?.multiple_choices?.map((entry, index) => {
          return {
            value: entry?.choice,
            label: entry?.choice,
          };
        });
        allFilters.push({
          id: item?.question_id,
          choices: mulchoices,
          apiData: mulchoices.map((a) => a.value),
        });

        if (
          item?.question_category?.includes("Ethnicity") ||
          item?.question_category?.includes("ethnicity")
        ) {
          const arr1 = item?.multiple_choices?.map((entry, index) => {
            return {
              value: entry?.choice,
              label: entry?.choice,
            };
          });
          setEthnicityData(arr1);
          let result = arr1.map((a) => a.value);

          setEthnicityApiData(result);
          setEthnicityId(item?.question_id);
        } else if (
          item?.question_category?.includes("Age") ||
          item?.question_category?.includes("age")
        ) {
          const arr2 = item?.multiple_choices?.map((entry, index) => {
            return {
              value: entry.choice,
              label: entry.choice,
            };
          });

          setAgeData(arr2);
          let result = arr2.map((a) => a.value);
          setAgeApiData(result);
          setAgeId(item?.question_id);
        } else if (
          item?.question_category?.includes("Gender") ||
          item?.question_category?.includes("gender")
        ) {
          const arr3 = item?.multiple_choices?.map((entry, index) => {
            return {
              value: entry.choice,
              label: entry.choice,
            };
          });
          setGenderData(arr3);
          let result = arr3.map((a) => a.value);

          setGenderApiData(result);
          setGenderId(item?.question_id);
        }
      });
      setState({ ...state, filters: allFilters });
    }
  }, [props?.demographicQuestions]);

  useEffect(() => {
    let labelArray = [];
    let dataArray = [];

    if (surveyReport?.count && surveyReport?.count?.length) {
      surveyReport?.count?.map((item, index) => {
        dataArray.push(item?.count);
        if (surveyReport?.count?.length > 4) {

           labelArray.push(label.charAt(index))
          // labelArray.push(item[Object.keys(item)[0]].slice(0, 8) + '..')        
         
        } else if (item[Object.keys(item)[0]].length <= 24) {
          labelArray.push(item[Object.keys(item)[0]]);
        } else {
          labelArray.push(item[Object.keys(item)[0]].slice(0, 22) + "..");
        }
      });
    }

    const newsampleData = {
      legend: {
        display: false,
        position: "right",
      },

      labels: labelArray,
      datasets: [
        {
          label: type,

          data: dataArray,
          backgroundColor: ["#1b59ff", "#6610f2", "#084298", "#8c4efc"],
        },
      ],
    };

    setDataChart(newsampleData);
  }, [surveyReport]);


  const barRef = useRef(null);

  const handleEthnicityChange = (value) => {
    setEthnicityApiData(value);
  };

  const handleAgeChange = (value, el, index) => {
    const filters = [...state.filters];
    filters[index].apiData = value;

    var data = {
      question: generalQuestion?.question_id,
    };

    data["question"] = generalQuestion?.question_id;

    for (let i = 0; i < filters?.length; i++) {
      data[filters[i].id] = filters[i].apiData;
    }

    dispatch(
      listGeneralSurveyReport(surveyId, data, function (response) {
        let newArray = response;
        response?.choices.map((entry, index) => {
          let array1 = response?.count?.filter(
            (item) => item[Object.keys(item)[0]] === entry?.choice
          );

          if (!array1?.length) {
            let obj = {
              answer: entry?.choice,
              count: 0,
              percentage: 0,
            };
            newArray.count?.push(obj);
          }
        });
        let array2 = newArray?.count.filter(
          (item) => item[Object.keys(item)[0]].length
        );
        let obj = {
          count: array2,
        };
        setSurveyReport(obj);
      })
    );

    setState({ state, filters: filters });
  };

  const handleGenderChange = (value) => {
    setGenderApiData(value);
  };


  return (
    <div className="questions-graph-wrap">
      <Row>
        <Col md={4}>
          <div className="graph-data-counthere">
            <div className="graph-data-text">
              <h4 className="graph-title">{generalQuestion?.question}</h4>
              <p>
                The following represents various responses received for your
                survey
              </p>
            </div>
          </div>
          {props?.demographicQuestions?.length ? (
            <div className="whitebox padding-box radiusbox shadow-box border-box">
              <p>
                <b>Various demographic choices</b>
              </p>
              <div className="graph-filter-wrap">
                <ScrollArea
                  speed={0.8}
                  className="graph-filter-scroll"
                  contentClassName="content"
                  horizontal={false}
                >
                  {state.filters &&
                    state.filters.length &&
                    state.filters.map((el, index) => (
                      <div className="multiselect-wrap">
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Please select"
                          defaultValue={[...el.choices]}
                          options={el.choices}
                          onChange={(e) => handleAgeChange(e, el, index)}
                        />
                      </div>
                    ))}

                  {/* {age_data_set?.length ?*/}
                  {/*   <div className="multiselect-wrap">*/}
                  {/*     <Select mode="multiple" allowClear style={{ width: '100%', }} placeholder="Please select" defaultValue={[...age_data_set]}*/}
                  {/*       options={age_data_set} onChange={e => handleAgeChange(e)}*/}

                  {/*     />*/}
                  {/*   </div>*/}
                  {/*:'' }*/}
                  {/* {ethnicity_data_set?.length ?*/}
                  {/*   <div className="multiselect-wrap">*/}
                  {/*     <Select mode="multiple" allowClear style={{ width: '100%', }} placeholder="Please select" defaultValue={[...ethnicity_data_set]}*/}
                  {/*       options={ethnicity_data_set} onChange={e => handleEthnicityChange(e)} />*/}
                  {/*   </div>*/}
                  {/*:'' }*/}
                  {/* {gender_data_set?.length ?*/}
                  {/*   <div className="multiselect-wrap">*/}
                  {/*     <Select mode="multiple" allowClear style={{ width: '100%', }} placeholder="Please select"*/}
                  {/*       defaultValue={[...gender_data_set]}*/}
                  {/*       onChange={e => handleGenderChange(e)} options={gender_data_set} />*/}
                  {/*   </div>*/}
                  {/*   : ''}*/}
                </ScrollArea>
              </div>
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col md={8}>
          <div className="whitebox padding-box radiusbox shadow-box border-box">
            <Bar data={dataChart} options={initialOptions} />
          </div>
         
          <Row>
          <Col md={surveyReport?.count?.length > 4? 12 :8}>
          <div className="graph-data-counthere m-t-20">
          <div className="graph-data-text">
             
              <h5 className="graph-info-title m-b-10">Breakdown of Responses</h5>
            </div>
            <Table striped hover className="normal-table graph-info-table">
            {surveyReport?.count?.length > 4 ?
              <tr>
                <th>Label For Choices</th>
                <th>Choices</th>
                <th>Count</th>
              </tr>
              :''}

              <tbody>

                {surveyReport?.count?.map((item, index) => {
                  return (<tr>
                    {surveyReport?.count?.length > 4 ?
                      <td >{label.charAt(index)}</td>
                      : ''}
                    <td  >{item[Object.keys(item)[0]]}</td>

                    <td align="right"  style={{ wordBreak: 'keep-all'}}>{item?.count}</td>
                  </tr>
                  )
                })
                }

              </tbody>
            </Table>
            </div>
            </Col>
            </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MyChart;
