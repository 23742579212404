import React, { useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import MyLearning from '../components/myLearning'
import LearnerCourseListing from './components/courseslisting'
import LearnerLearningPathDetails from './components/learnerslearningpathdetails';
import LearnerProfilePic from './components/profilepic/container/index';
import { useSelector } from 'react-redux';

function LearnerDashboard(props) {
   const { hideLpQuizes } = useSelector((state) => state.settings);

   return (
      <div>

      <div className="tab-wrapper">
<div className="whitebox radius-30 padding-box">
         <Tabs defaultActiveKey={"Courses"} id="uncontrolled-tab-example" className="m-b-30">
            {!hideLpQuizes && (
                <Tab eventKey="MyLearning" title="Learning Paths">
                     {/* <MyLearning /> */}
                     <LearnerLearningPathDetails />
      
                  </Tab>
            )}
           
            <Tab eventKey="Courses" title="Courses">
               <LearnerCourseListing category={props?.location?.state?.category} />
            </Tab>
         </Tabs>

      </div>
      </div>
      </div>
   );
};
export default LearnerDashboard;
