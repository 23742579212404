import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LearningPathAssignBox from './components/learningpathassignbox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function LearningPathAssign() {
  return (
    <div className="course-assign-wrapper">
      <div className="course-assign-member-type">
        <Form className="d-block">
          <Row>
            <Col md="3">
              <Form.Check
                custom
                type="checkbox"
                id="check1"
                label={`Add member`}
              />
            </Col>
            <Col md="3">
              <Form.Check
                custom
                type="checkbox"
                id="check2"
                label={`Add group`}
              /></Col>
          </Row>
        </Form>
      </div>
      <div className="course-assign-box-container">
        <h4 className="course-assign-type-name">Select Individuals To Whom You Want To Assign This Course To</h4>
        <LearningPathAssignBox />
      </div>
      <div className="course-assign-box-container">
        <h4 className="course-assign-type-name">Select Groups To Which You Want To Assign This Course</h4>
        <LearningPathAssignBox />
      </div>
      <div className="add-module-footer d-flex justify-content-end">
        <div className="button-wrap double-btn">
          <button className="btn btn-m border-btn">Cancel</button>
          <button className="btn btn-m border-btn">Back</button>
          <Button variant="primary" className="btn-m" >Save</Button>
        </div>
      </div>
    </div>
  );
};
export default LearningPathAssign;