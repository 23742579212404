import React, {useState, useEffect, useCallback} from 'react';
import DataTable from 'react-data-table-component';
import Spinner from "react-bootstrap/Spinner";
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import {Button} from "react-bootstrap";
import {
    getLearnerCourseCertificates,getLearnerCourseCertificateWithPage,nextLearnerCourseCertificates,previousLearnerCourseCertificate
} from "../../../../../learnerLearningPath/services";
import {getNotifications} from "../../../../../Notifications/services";
import debounce from "lodash.debounce";

const LearnerLearningPathDetails = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const location = useLocation();
    const response = useSelector(state => state.learningpath_learner?.lp_courses_cert?.response);
    const loading = useSelector(state => state?.learningpath_learner?.lp_courses_cert?.loading);
    const [state, setState] =useState({
        courses:{},
        count:response?.count,
        paginationItem:[]
    })
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');



    const options = [
        { value: 'new', label: 'New' },
        { value: 'in_progress', label: 'In progress' },
        { value: 'completed', label: 'Completed' }
    ];


    useEffect(() => {


            let option =  { value: 'completed', label: 'Completed' }
            setStatus(option);
            dispatch(getLearnerCourseCertificates(function (res){

            }));



    }, []);




    useEffect(() => {
        const count = response?.count
        const perPage = 12;
        const buttonsCount = Math.ceil(count / perPage);
        let ellipsisLeft = false;
        let ellipsisRight = false;
        let items = []
        for (let i = 1; i <= Number(buttonsCount); i++) {
            if (i === activePage) {
                items.push(
                    {id: i, current: true, ellipsis: false}
                );
            } else {
                if (i < 10 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
                    items.push(
                        {id: i, current: false, ellipsis: false}
                    );
                } else if (i > 1 && i < activePage && !ellipsisLeft) {
                    items.push(
                        {id: i, current: false, ellipsis: true}
                    );
                    ellipsisLeft = true;
                } else if (i < buttonsCount && i > activePage && !ellipsisRight) {
                    items.push(
                        {id: i, current: false, ellipsis: true}
                    );
                    ellipsisRight = true;

                }
            }

        }
        setState({...state, courses: response, count: response?.count, paginationItem: items});

    }, [response]);







    const handleNext = () => {

        if (state.courses?.next) {
            var regex = /page=\d+/g;
            const [number ] = state.courses?.next?.match(regex)
            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
            dispatch(nextLearnerCourseCertificates(state.courses?.next))
        } else {
            return false
        }

    }

    const handlePrevious = () => {
        if (state.courses?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            dispatch(previousLearnerCourseCertificate(state.courses?.previous))
        } else {
            return false
        }

    }
    const handleIndex = (index) =>{
        if(index == 1){
            dispatch(getLearnerCourseCertificates(function (res){

            }));

        }else{
            dispatch(getLearnerCourseCertificateWithPage(index));
        }
        setActivePage(index);
    }
    const checkTextStatus = (status) => {
        if(status == 'In Progress'){
            return 'Continue'
        }else if(status == 'New'){
            return 'Start'
        }else{
            return 'Start'
        }

    }
    const columns = [


        {
            name: 'Course Name',
            selector: 'course_name',
            sortable: true,
            center: false,
            grow: 3,

        },


         {
            name: 'Completed Date',
             selector: 'completed_date',
             sortable: true,
             center: false,
             grow: 3,
             cell: row => <div>
                 {row?.completed_date}

             </div>

         },
        {
            name: 'Download Certificate',
            selector: 'certificate',
            sortable: false,
            center: false,
            grow: 3,
            cell: row => <div>

                <Button className='text-white' type="button" onClick={ ()=>  history.push(`/course/certificate/${row?.id}/`)}>Download</Button>



            </div>

        },
    ];



    return (
        <div className="learner-accordian-wrap datatble-wrap ">

            {loading ?
                <div className="loader-middle-wrap">
                    <Spinner animation="border" variant="danger"/>
                </div>

                :
                <>
                    <DataTable
                        columns={columns}
                        data={state?.courses?.results}
                        center={true}
                        showPaginationBottom={false}
                        paginationIconFirstPage={false}
                        paginationIconLastPage={false}
                        noHeader={true}
                        noDataComponent={<div style={{padding:'25px'}}>No Certificates to display</div>}
                    />
                    {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response &&
                    <div className="d-flex justify-content-center">
                        <Pagination size="sm" className="custom-pagination">
                        <Pagination.First disabled={!state?.courses?.previous} className={`${state?.courses?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious}/>
                            {state.paginationItem.map(page => {
                                if (!page.ellipsis) {
                                    return <li key={page.id}>
                                        <Pagination.Item
                                            onClick={() => handleIndex(page.id)} key={page.id}
                                            active={page.id == activePage}
                                        >
                                            {page.id}
                                        </Pagination.Item>
                                    </li>
                                } else {
                                    return <Pagination.Ellipsis></Pagination.Ellipsis>
                                }
                            })}
                            <Pagination.Last disabled={!state?.courses?.next} onClick={handleNext}
                                             className={`${state?.courses?.next ? 'paginationStyle' : ''} `}/>

                        </Pagination>

                    </div>
                    }

                </>
            }

        </div>
    );
};
export default LearnerLearningPathDetails;
