import React, {useState, useEffect, useRef} from 'react';
import  {Modal, Button} from 'react-bootstrap';
import TabModules from './components/tabs'

import {getLearnerLearningPathNodesDetail} from "../../../services";
import {useDispatch,useSelector} from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import CourseCompleted from '~/shared/components/coursecompleted';
import CourseTransition from "./components/transitionmessagecourse";
import LpCompleted from "../../../../../shared/components/lpcompleted";

const  LearnerCoursePopView  = (props) => {
    const dispatch =useDispatch()
    const response = useSelector(state => state?.learningpath_learner ?.learner_lp_node_detail?.response);
    const loading = useSelector(state => state?.learningpath_learner?.learner_lp_node_detail?.loading);
    const [state, setState] =useState({
        node:null,
        completed:null
    })

   const [isTransition,setIsTransition] = useState(false)
    const[complete,setComplete]=useState(false)

    const handleCallback = (childData) =>{
       setComplete(true)
    }
    useEffect(()=>{

        if(props.nodeId){
            dispatch(getLearnerLearningPathNodesDetail(props.lpId,props.nodeId,function (res){


            }))
        }
    },[])

    useEffect(()=>{
        setState({...state,node: response,completed: response?.completed_modules_count})
        if(response?.transition_message && response?.completed_modules_count == 0){
            setIsTransition(true)
        }else{
            setIsTransition(false)

        }
    },[response])
    const onHide = () => {

        props.onHide()
        setIsTransition(false)
    }
    return (
       <div>
          <Modal show={props.show} onHide={onHide} onExit={()=>setComplete(false)} className="common-modal full-screen-modal inner-popup add-module-content-modal">
             <Modal.Header closeButton>
             </Modal.Header>
             <Modal.Body>

             <div className="h-100">
                 {loading ?

                     <div className='row justify-content-center mt-1'>
                         <Spinner animation="border" variant="danger"/>

                     </div>
                     :

                     <>
                     
                         { !loading && state?.node?.course_modules && state?.node?.course_modules.length ?
                             <>
                                 {isTransition ? <CourseTransition setIsTransition={setIsTransition} data={state?.node}/> :
                                     <span>
                        {complete ?
                            <LpCompleted data={state?.node} name={props.node?.node_object_name} callingComp={'lp'}
                                             id={props.lpId} onHide={props.onHide}></LpCompleted> :

                            <TabModules onHide={onHide} lpId={props.lpId} courseId={state?.node?.id}
                                        parentCallback={handleCallback}
                                        values={state?.node?.course_modules} course={state?.node}
                                        name={state?.node?.node_object_name} completedCount={state?.completed}/>
                        }
                </span>
                                 }
                             </>
                             :
                             <>
                             <div className='row justify-content-center mt-5'>
                                 No Modules Available

                             </div>

                             </>

                         }

                     </>

                 }


             </div>

             </Modal.Body>
          </Modal>
        </div>
    )

}
export default LearnerCoursePopView;
