/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionHistory } from '../../../../services';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import ConstructImg from '~/assets/images/under-construction.png';
import Pagination from "react-bootstrap/Pagination";
import moment from "moment";

const SubscriptionHistory = () => {
  const dispatch = useDispatch();
  const {response, loading} = useSelector(state => state.profile.transaction_history);
  const { user } = useSelector(state => state.auth);
  const [state, setState] =useState({
    transactions:response,
    count:response?.count,
    paginationItem:[]
  })
  const [activePage, setActivePage] = useState(1);
  const [count,setCount] = useState(0)
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if(user?.user_type == 'manager' || user?.user_type == 'admin') {
      dispatch(getTransactionHistory(user?.token));
    }
  }, []);


  useEffect(() => {
    const count = response?.count
    const perPage =12;
    const buttonsCount = Math.ceil(count/perPage);
    let ellipsisLeft = false;
    let ellipsisRight = false;
    let items =[]
    for (let i = 1; i <= Number(buttonsCount); i++) {
      if(i === activePage) {
        items.push(
            { id: i, current: true, ellipsis: false }
        );
      }else {
        if(i < 10 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
          items.push(
              { id: i, current: false, ellipsis: false }
          );
        }else if(i > 1 && i < activePage && !ellipsisLeft) {
          items.push(
              { id: i, current: false, ellipsis: true }
          );
          ellipsisLeft = true;
        }else if(i < buttonsCount && i > activePage && !ellipsisRight) {
          items.push(
              { id: i, current: false, ellipsis: true }
          );
          ellipsisRight = true;

        }
      }

    }
    setState({...state, transactions:response,paginationItem:items});
  }, [response]);

  const printDocument = (value) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Subscription Details";
    const headers = [["ORDER NO", "ORDER DATE", "PLAN NAME" ]];

    const data = [value.transaction_id,value.completed_date,value.plan_name]

    let content = {
      startY: 50,
      head: headers,
      body: [data]
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    let mmyy = moment(value.completed_date).format("MM-YYYY");
    doc.save(`invoice${mmyy}.pdf`)
  }

  const handleNext = () => {

    setActivePage(prevVal =>   prevVal + 1);
    dispatch(getTransactionHistory(user?.token,activePage+1));
  }

  const handlePrevious = () => {
    setActivePage(prevVal =>   prevVal - 1);
    dispatch(getTransactionHistory(user?.token,activePage-1));

  }

  const handleIndex = (index) => {
    dispatch(getTransactionHistory(user?.token,index));
    setActivePage(index);
  }
  return (
    <div className="tab-content">
      <div className="subscription-history table-responsive">
        <Table striped hover className="normal-table">
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Order Date</th>
              <th>Plan Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {state?.transactions && state?.transactions.results && state?.transactions?.results?.length ? (
              state?.transactions?.results.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.transaction_id}</td>
                    <td>{new Intl.DateTimeFormat('en-GB', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                    }).format(new Date(item.completed_date))}</td>
                    <td>{item.plan_name}</td>
                    <td><button className="btn border-btn link-btn" onClick={()=>printDocument(item)}>Download Invoice</button></td>
                  </tr>
                )
              })
            ) : <tr><td><span className="no-data-text">No data available</span></td></tr>
            }
          </tbody>
        </Table>

        {state?.transactions && state?.transactions && state?.transactions.count > 12 &&
        <div className="d-flex justify-content-center">
          <Pagination size="sm" className="custom-pagination">
            <Pagination.First disabled={!state?.transactions?.previous} className={`${state?.transactions?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious}/>
            {state.paginationItem.map(page => {
              if(!page.ellipsis) {
                return <li key={page.id}>
                  <Pagination.Item
                      onClick={()=>handleIndex(page.id)} key={page.id} active={page.id == activePage}
                  >
                    {page.id}
                  </Pagination.Item>
                </li>
              }else {
                return <Pagination.Ellipsis></Pagination.Ellipsis>
              }
            })}
            <Pagination.Last disabled={!state?.transactions?.next} onClick={handleNext} className={`${state?.transactions?.next ? 'paginationStyle' :''} `}/>

          </Pagination>

        </div>
        }

      </div>
    </div>

  );
};
export default SubscriptionHistory;
