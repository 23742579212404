import React, { useCallback, useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FormCheck, Form } from "react-bootstrap";
import { FlowContext } from "../container";
import { ToastContainer, toast } from "react-toastify";
import { Select } from "antd";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ScrollArea from "react-scrollbar";
import debounce from "lodash.debounce";
import { getManagerLearningPathList } from "../../../services";
import { format } from "date-fns";

const { Option } = Select;
const LearningPathRight = ({ nodeId, setNodeId, nodeType }) => {
  const [elements, setElements] = useContext(FlowContext);
  const [state, setState] = useState({
    tempObj: {},
    ranges: [],
  });
  const [key, setKey] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [gotValues, setGotValues] = useState([]);
  const toastId = React.useRef(null);
  useEffect(() => {
    if (nodeId) {
      let parentObj = elements?.find((el) => el.id == nodeId);

      let childrens = elements?.filter((el) => el.source == nodeId);
      let temp = {};
      let copyElements = [...elements];
      temp["parent"] = parentObj;
      temp["childs"] = childrens;
      let findIndex = elements?.findIndex((el) => el.id == nodeId);
      if (
        (childrens?.length < 2 || childrens?.length > 2) &&
        parentObj?.criteria == "pass_or_fail"
      ) {
        toast.warn(
          "Pass or fail only can be selected if childs node equal to 2"
        );

        copyElements[findIndex].criteria = "percentage_range";
        copyElements[findIndex].is_default_pass_percentage = false;
      }

      if (
        (childrens?.length > 1 || childrens?.length == 0) &&
        parentObj?.criteria == "pass_through_quiz"
      ) {
        toast.warn(
          "Pass through quiz only can be selected if child node equal to 1"
        );

        copyElements[findIndex].criteria = "percentage_range";
        copyElements[findIndex].is_default_pass_percentage = false;
      } else if (
        childrens?.length >= 1 &&
        parentObj?.criteria == "terminal_quiz"
      ) {
        toast.warn("Final/Evaluating Terminal will not have any node");
        copyElements[findIndex].is_default_pass_percentage = false;
        copyElements[findIndex].criteria = "percentage_range";
      }

      if (
        childrens?.length > 1 &&
        parentObj?.criteria == "final_evaluating_terminal"
      ) {
        toast.warn(
          "Final/Evaluating Terminal only can be selected if childs node equal to 1"
        );

        copyElements[findIndex].criteria = "percentage_range";
      }

      let tempoptions = [];
      let gotValuesq = [];
      let passOne = "pass";

      let tmpRanges = [];
      if (
        parentObj?.criteria == "percentage_range" ||
        parentObj?.criteria == "pass_or_fail"
      ) {
        // debugger
        for (let i = 0; i < childrens.length; i++) {
          // tmpRanges.push({from:'',to:''})

          if (childrens[i].range) {
            // alert('hello')
            let findIndex = copyElements?.findIndex(
              (el) => el.id == childrens[i].id
            );
            if (parentObj.data.childLength != childrens.length) {
              copyElements[findIndex].range = { from: null, to: null };
              copyElements[findIndex].range_start = null;
              copyElements[findIndex].range_end = null;
              tmpRanges.push({ from: "", to: "" });
            } else {
              tmpRanges.push({ ...copyElements[findIndex].range });
            }
          } else {
            tmpRanges.push({ from: "", to: "" });
          }
        }

        if (parentObj.data.childLength != childrens.length) {
          setElements(copyElements);
        }
      }
      // } else {
      //     for (let i = 0; i < childrens.length; i++) {
      //         if (childrens[i].range) {
      //             let findIndex = copyElements?.findIndex((el) => el.id == childrens[i].id);
      //
      //             gotValuesq.push(`${copyElements[findIndex].range}`);
      //
      //         }
      //
      //         tempoptions.push(`${passOne}`);
      //         passOne = 'fail';
      //     }
      // }
      // setElements(copyElements);
      // setElements(copyElements)  // setElements(copyElements)  // setElements(copyElements)  // setElements(copyElements)
      setSelectOptions(tempoptions);
      setGotValues(gotValuesq);
      setState({ ...state, tempObj: temp, ranges: tmpRanges });
    }
  }, [nodeId, elements?.length]);

  useEffect(() => {
    setKey(nodeType == "quiz" ? "Ranges" : "Overview");
  }, [nodeType]);
  const { tempObj } = state;
  const [swt, setSwt] = React.useState(true);
  const handleRangeSelector = (e) => {
    let copyElements = [...elements];
    if (e.target.value.length) {
      if (
        (tempObj.childs.length > 2 || tempObj.childs.length < 2) &&
        e.target.value == "pass_or_fail"
      ) {
        toast.warn("The Pass or Fail Quiz should have only two child nodes.");
        return false;
      }
      if (
        (tempObj.childs.length > 1 || tempObj.childs.length == 0) &&
        e.target.value == "pass_through_quiz"
      ) {
        toast.warn("Pass through Quiz should have only one child node.");
        return false;
      } else if (
        tempObj.childs.length >= 1 &&
        e.target.value == "terminal_quiz"
      ) {
        toast.warn("Final/Terminal Quiz should have no child node.");
        return false;
      } else {
        let findIndex = elements?.findIndex((el) => el.id == nodeId);
        copyElements[findIndex].criteria = e.target.value;
        copyElements[findIndex].data = {
          ...copyElements[findIndex].data,
          childLength: tempObj.childs.length,
        };
        for (let i = 0; i < tempObj.childs.length; i++) {
          if (tempObj.childs[i].range) {
            let findIndex = copyElements?.findIndex(
              (el) => el.id == tempObj.childs[i].id
            );
            // copyElements[findIndex].criteria  = copyElements[findIndex].criteria ? copyElements[findIndex].criteria : ''
            copyElements[findIndex].range = { from: null, to: null };
            copyElements[findIndex].range_start = null;
            copyElements[findIndex].range_end = null;
          }
        }
        setElements(copyElements);
      }
    }
  };

  const handleRangeSelectorChild = (e, item) => {
    let copyElements = [...elements];
    if (e.length) {
      let findIndex = elements?.findIndex((el) => el.id == item.id);
      let findParentIndex = elements?.findIndex((el) => el.id == nodeId);
      copyElements[findIndex].range =
        e == "fail" ? { from: 0, to: 50 } : { from: 50, to: 100 };
      copyElements[findParentIndex].data = {
        ...copyElements[findParentIndex].data,
        childLength: tempObj.childs.length,
      };

      setElements(copyElements);
      let gotValuesq = [...gotValues];
      gotValuesq.push(`${e}`);
      setGotValues(gotValuesq);
    }
  };
  const handleReset = (e, item, index) => {
    let copyElements = [...elements];
    const copyRanges = [...state.ranges];

    let findIndex = elements?.findIndex((el) => el.id == item.id);

    if (copyElements[findIndex].range) {
      delete copyElements[findIndex].range;
      delete copyElements[findIndex].range_start;
      delete copyElements[findIndex].range_end;
      setElements(copyElements);
    } else {
      if (copyRanges.length) {
        copyRanges[index].from = "";
        copyRanges[index].to = "";
      }
      setState({ ...state, ranges: copyRanges });
    }
  };
  const handleTypeRange = (e, item, index, prop) => {
    const tempRanges = [...state.ranges];
    let { value, maxLength } = e.target;
    let finalValue = value.slice(0, maxLength);
    tempRanges[index][prop] = finalValue;
    tempRanges[index].id = item?.id;

    setState({ ...state, ranges: tempRanges });
    const copyElements = [...elements];
    let findParentIndex = elements?.findIndex((el) => el.id == nodeId);
    copyElements[findParentIndex].data.childLength =
      state?.tempObj?.childs?.length;
    setElements(copyElements);
  };
  const handleSave = () => {
    let copyRanges = [...state.ranges];
    let isValid = false;
    let sum = 0;

    for (let i = 0; i < copyRanges.length; i++) {
      if (copyRanges[0].from != 0) {
        // alert('Value should start from zero')
        copyRanges[0].errorMessage = "Value should start from zero";
        toast.error("First Value should start from zero");
        isValid = false;
        break;
      } else {
        delete copyRanges[0].errorMessage;
        isValid = true;
      }
      if (
        Number(copyRanges[i].from) >= Number(copyRanges[i].to) ||
        Number(copyRanges[i].to) <= Number(copyRanges[i].from)
      ) {
        // alert('Value should be bigger from 1st value')
        copyRanges[i].errorMessage = "Value should be bigger from 1st value";
        toast.error("Value should be bigger from 1st value");
        isValid = false;
        break;
      } else {
        delete copyRanges[i].errorMessage;
        isValid = true;
      }

      // if(i > 0 && Number(copyRanges[i].from) <= Number(copyRanges[i-1] .to)){
      //     // alert('Needs Big value')
      //
      //     copyRanges[i].errorMessage = 'Needs Big value'
      //     toast.error("Value should be bigger from 2nd value")
      //     isValid = false
      //     break
      // }else{
      //     delete copyRanges[i].errorMessage
      //     isValid = true
      // }


      if (
        (i > 0 && Number(copyRanges[i].from) <= Number(copyRanges[i - 1].to)) ||
        Math.abs(Number(copyRanges[i]?.from) - Number(copyRanges[i - 1]?.to)) <=
          0 ||
        Math.abs(Number(copyRanges[i]?.from) - Number(copyRanges[i - 1]?.to)) >
          1
      ) {
        // alert('Needs Big value')

        copyRanges[i].errorMessage = "Needs Big value";
        toast.error(
          "Value should be bigger to last 'To' value with the difference of 1"
        );
        isValid = false;
        break;
      } else {
        delete copyRanges[i].errorMessage;
        isValid = true;
      }
      if (Number(copyRanges[copyRanges.length - 1].to) != 100) {
        // alert('Last value should be 100')
        copyRanges[copyRanges.length - 1].errorMessage =
          "Last value should be 100";
        toast.error("Last value should be 100");
        isValid = false;
        break;
      } else {
        delete copyRanges[i].errorMessage;
        isValid = true;
      }
      sum += Number(copyRanges[i].to) - Number(copyRanges[i].from);
    }

    // if(sum < 100 && isValid ){
    //     let isFalse = false
    //     for(let i =0; i < copyRanges.length; i++){
    //
    //         copyRanges[i].errorMessage ='Value should be divide into 100'
    //         isFalse = true
    //
    //         isValid = false
    //
    //     }
    //     if(isFalse){
    //         toast.error('Value should be divide into 100')
    //     }
    // }else if(sum == 100 ){
    //     for(let i =0; i < copyRanges.length; i++){
    //         isValid = true
    //        delete copyRanges[i].errorMessage
    //
    //     }
    //
    // }

    if (isValid) {
      const copyElements = [...elements];
      for (let i = 0; i < copyRanges.length; i++) {
        let findIndex = elements?.findIndex((el) => el.id == copyRanges[i].id);
        let findParentIndex = elements?.findIndex((el) => el.id == nodeId);
        copyElements[findParentIndex].data = {
          ...copyElements[findParentIndex].data,
          childLength: tempObj.childs.length,
        };
        copyElements[findIndex].range = copyRanges[i];
        copyElements[findIndex].range_start = copyRanges[i].from;
        copyElements[findIndex].range_end = copyRanges[i].to;
      }
      setElements(copyElements);
      toast.success("Range Successfully assigned");
    }
    setState({ ...state, ranges: copyRanges });
  };
  const handleTab = (k) => {
    setKey(k);
  };

  const handleClose = () => {
    setNodeId(null);
  };

  const handleOverviewChange = (e) => {
    const { value, name } = e.target;
    const copyElements = [...elements];
    let findParentIndex = elements?.findIndex((el) => el.id == nodeId);
    copyElements[findParentIndex][name] = value;
    setElements(copyElements);
  };

  const handDefaultCheck = (e) => {
    let copyRanges = [...state.ranges];
    const { tempObj } = state;
    const copyElements = [...elements];
    let findParentIndex = elements?.findIndex((el) => el.id == nodeId);

    if (e.target.checked) {
      copyElements[findParentIndex].is_default_pass_percentage = true;
      if (tempObj?.parent?.criteria == "pass_or_fail") {
        const ranges = [...state.ranges];
        for (let i = 0; i < ranges.length; i++) {
          if (i == 0) {
            ranges[i].from = "0";
            ranges[i].to = tempObj?.parent.data.pass_score - 1;
            ranges[i].id = tempObj?.childs[i].id;
          } else {
            ranges[i].from = Number(ranges[i - 1].to) + 1;
            ranges[i].to = 100;
            ranges[i].id = tempObj?.childs[i].id;
          }
        }
        setState({ ...state, ranges: ranges });
      } else if (
        tempObj?.parent?.criteria == "pass_through_quiz" ||
        tempObj?.parent?.criteria == "terminal_quiz"
      ) {
        copyElements[findParentIndex].pass_percentage =
          tempObj?.parent.data.pass_score;
      }
    } else {
      for (let i = 0; i < tempObj.childs.length; i++) {
        if (tempObj.childs[i].range) {
          let findIndex = copyElements?.findIndex(
            (el) => el.id == tempObj.childs[i].id
          );
          // copyElements[findIndex].criteria  = copyElements[findIndex].criteria ? copyElements[findIndex].criteria : ''
          copyElements[findIndex].range = { from: null, to: null };
          copyElements[findIndex].range_start = null;
          copyElements[findIndex].range_end = null;
        }
      }
      copyElements[findParentIndex].is_default_pass_percentage = false;
    }
    setElements(copyElements);
  };

  const handleSaveCheckDefault = () => {
    let copyRanges = [...state.ranges];
    const copyElements = [...elements];
    for (let i = 0; i < copyRanges.length; i++) {
      let findIndex = elements?.findIndex((el) => el.id == copyRanges[i].id);
      let findParentIndex = elements?.findIndex((el) => el.id == nodeId);
      copyElements[findParentIndex].data = {
        ...copyElements[findParentIndex].data,
        childLength: tempObj.childs.length,
      };
      copyElements[findIndex].range = copyRanges[i];
      copyElements[findIndex].range_start = copyRanges[i].from;
      copyElements[findIndex].range_end = copyRanges[i].to;
    }
    setElements(copyElements);
    toast.success("Range Successfully assigned");
    setState({ ...state, ranges: copyRanges });
  };

  const handlePercentage = (e) => {
    const copyElements = [...elements];
    let findIndex = elements?.findIndex((el) => el.id == nodeId);
    copyElements[findIndex].pass_percentage = e.target.value;
    setElements(copyElements);
  };



  if (nodeId) {
    return (
      <>
        <div className="side-bar">
          <div className="close-icon" onClick={handleClose}>
            x
          </div>
          {/*
            <Col style= {{cursor:'pointer'}}  lg={4} xl={3} className="m-b-30 course-box-outer">

                <div className="common-card coursecard"  style={{width:'250px'}} >
                    <div className="course-box-top">
                        <div className="course-listing-img">
                            <img src={course?.image} alt="" />
                        </div>
                        <div className="common-card-detail-wrap box-listing">
                            <h4>{course?.name}</h4>
                            <p>{course?.description}</p>
                        </div>
                        <div className="coommon-card-det-number d-flex justify-content-between">
                            {course?.modules_count >  0 &&
                                <>
                            <span className="common-card-oth-det-left">Modules <span
                                className="det-numb">{course?.modules_count}</span> </span>
                                <span className="common-card-oth-det-left">Quizzes <span className="det-numb">{data?.quizzes_count}</span> </span>
                                </>
                            }
                        </div>
                    </div>
                    <div className="box-overlay">
                        <span className="overlay-viewmore">View More<i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                        <div className="overlay-bg">&nbsp;</div>
                    </div>

                </div>
            </Col> */}
          <div className="sidebox mt-3">
            {/*{JSON.stringify(gotValues)}*/}
            <h3 className="sidebox-title">{tempObj?.parent?.data?.name}</h3>
            {/*<div className="mandatory-row d-flex justify-content-between">*/}
            {/*    <div className="mandatory-text">Mandatory</div>*/}
            {/*    <FormCheck custom type="switch">*/}
            {/*        <FormCheck.Input isInvalid checked={swt}/>*/}
            {/*        <FormCheck.Label onClick={() => setSwt(!swt)}>*/}
            {/*        </FormCheck.Label>*/}
            {/*    </FormCheck>*/}
            {/*</div>*/}
            <div className="tab-wrapper">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => handleTab(k)}
                className="workflow-left-tab mt-3"
              >
                {nodeType == "quiz" && (
                  <Tab className={`mt-3`} eventKey="Ranges" title="Ranges">
                    <div className="range-selector-row">
                      <Form.Control
                        as="select"
                        value={
                          tempObj?.parent?.criteria
                            ? tempObj?.parent?.criteria
                            : ""
                        }
                        onChange={handleRangeSelector}
                      >
                        <option value="" disabled>
                          Select Ranger Selector
                        </option>
                        <option value="percentage_range">Range</option>
                        <option value="pass_or_fail">Pass / Fail</option>
                        <option value="pass_through_quiz">
                          Pass Through Quiz
                        </option>
                        <option value="terminal_quiz">Terminal Quiz</option>
                      </Form.Control>
                    </div>
                    {state?.tempObj?.parent?.criteria == "pass_or_fail" ||
                    state?.tempObj?.parent?.criteria == "pass_through_quiz" ||
                    state?.tempObj?.parent?.criteria == "terminal_quiz" ? (
                      <div
                        className="custom-control custom-checkbox mt-4 mb-4"
                        style={{ zIndex: "1" }}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="is_default_pass_percentage"
                          style={{ zIndex: "1" }}
                          checked={
                            state?.tempObj?.parent?.is_default_pass_percentage
                          }
                          onChange={handDefaultCheck}
                        />
                        <label
                          htmlFor="is_default_pass_percentage"
                          className="custom-control-label"
                        >
                          Do you want to use the same Pass Percentage set in the
                          Quiz Library?
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <>
                      {state?.tempObj?.parent?.criteria ==
                        "pass_through_quiz" ||
                      state?.tempObj?.parent?.criteria == "terminal_quiz" ? (
                        <div className="form-input-wrap">
                          <label>Pass Percentage</label>
                          <Form.Control
                            type="number"
                            placeholder={`Enter percentage`}
                            name="available_on"
                            value={
                              tempObj?.parent?.pass_percentage
                                ? tempObj?.parent?.pass_percentage
                                : ""
                            }
                            onChange={handlePercentage}
                            className="input-item"
                          />
                        </div>
                      ) : (
                        <>
                          {tempObj &&
                            tempObj?.parent &&
                            tempObj?.parent?.data &&
                            tempObj?.parent?.criteria?.length &&
                            tempObj?.childs &&
                            tempObj?.childs?.length > 0 &&
                            tempObj.childs.map((el, index) => (
                              <div className="quiz-related-course-box">
                                <div className="qr-course-row d-flex justify-content-between">
                                  <div className="qr-course-name">
                                    {el?.data?.label}
                                  </div>
                                  <div className="qr-course-det-box d-flex">
                                    {/*<Form.Control as="select" value={el?.data?.data?.range ? el?.data?.data?.range : ''}  onChange={(e)=>handleRangeSelectorChild(e,el)}>*/}
                                    {/*      <option value='' selected={el?.data?.data?.range?.length == 0 || !el?.data?.data?.range } disabled>select</option>*/}
                                    {/*    {selectOptions.map((el)=>{*/}
                                    {/*        if(true){*/}
                                    {/*      return(  <option disabled={gotValues.includes(el)}>{el}</option>*/}
                                    {/*    )}else{*/}
                                    {/*            return null*/}
                                    {/*        }*/}
                                    {/*    })*/}
                                    {/*    }*/}

                                    {/*</Form.Control>*/}
                                    {/*{tempObj?.parent?.criteria == 'pass_or_fail' ?*/}

                                    {/*    <Select*/}
                                    {/*        style={{width: 93}}*/}
                                    {/*        placeholder="Search to Select"*/}
                                    {/*        value={el?.range ? el?.range : ''}*/}

                                    {/*        onChange={(e) => handleRangeSelectorChild(e, el)}*/}
                                    {/*    >*/}
                                    {/*        {selectOptions.map((el) => {*/}
                                    {/*            if (true) {*/}
                                    {/*                return (*/}
                                    {/*                    <> <Option value={el} disabled={gotValues.includes(el)}>{el}</Option>*/}

                                    {/*                    </>*/}
                                    {/*                );*/}
                                    {/*            } else {*/}
                                    {/*                return null;*/}
                                    {/*            }*/}
                                    {/*        })*/}
                                    {/*        }*/}
                                    {/*    </Select>*/}

                                    <>
                                      {" "}
                                      <span className="qr-sel-wrap d-flex">
                                        <span className="integer-input-wrap">
                                          <input
                                            type="number"
                                            placeholder="From"
                                            className={
                                              state?.ranges[index]?.errorMessage
                                                ? "sidebar_input"
                                                : ""
                                            }
                                            maxLength={2}
                                            name="from"
                                            value={
                                              state?.ranges[index]?.from ||
                                              state?.ranges[index]?.from == 0
                                                ? state?.ranges[index]?.from
                                                : ""
                                            }
                                            onChange={(e) =>
                                              handleTypeRange(
                                                e,
                                                el,
                                                index,
                                                "from"
                                              )
                                            }
                                          />
                                        </span>
                                        <span className="integer-input-wrap">
                                          <input
                                            type="number"
                                            placeholder="To"
                                            name="to"
                                            className={
                                              state?.ranges[index]?.errorMessage
                                                ? "sidebar_input"
                                                : ""
                                            }
                                            maxLength={
                                              index == state.ranges.length - 1
                                                ? 3
                                                : 2
                                            }
                                            value={
                                              state?.ranges[index]?.to
                                                ? state?.ranges[index]?.to
                                                : ""
                                            }
                                            onChange={(e) =>
                                              handleTypeRange(
                                                e,
                                                el,
                                                index,
                                                "to"
                                              )
                                            }
                                          />
                                        </span>
                                      </span>
                                    </>
                                    {/*<span className="qr-sel-wrap">*/}
                                    {/*<Form.Control as="select">*/}
                                    {/*    <option>10</option>*/}
                                    {/*    <option>2</option>*/}
                                    {/*</Form.Control>*/}
                                    {/*</span>*/}
                                    {/*<span className="qr-sel-close" onClick={(e) => handleReset(e, el,index)}>clear</span>*/}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    </>
                    {/*{state?.tempObj?.parent?.is_default_pass_percentage ?*/}

                    {/*<Button*/}
                    {/*    disabled={state?.ranges?.find((el) => el?.from?.length <= 0 || el?.to?.length <= 0)}*/}
                    {/*    onClick={handleSaveCheckDefault} className="m-t-10">Save</Button>*/}

                    {/*:*/}
                    {state?.tempObj?.parent?.criteria == "pass_through_quiz" ||
                    state?.tempObj?.parent?.criteria == "terminal_quiz" ? (
                      <Button
                        onClick={() =>
                          toast.success("Range Successfully assigned")
                        }
                        className="m-t-10"
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        disabled={state?.ranges?.find(
                          (el) => el?.from?.length <= 0 || el?.to?.length <= 0
                        )}
                        onClick={handleSave}
                        className="m-t-10"
                      >
                        Save
                      </Button>
                    )}

                    {/*}*/}
                  </Tab>
                )}
                <Tab eventKey="Overview" title="Overview" className="mt-3">
                  <div className="form-input-wrap">
                    <label>Transition message </label>
                    <Form.Control
                      as="textarea"
                      style={{ height: "79px" }}
                      rows={20}
                      placeholder={`Enter Text`}
                      name="transition_message"
                      value={
                        tempObj?.parent?.transition_message
                          ? tempObj?.parent?.transition_message
                          : ""
                      }
                      onChange={handleOverviewChange}
                      className="input-item"
                    />
                  </div>
                  <div className="form-input-wrap">
                    <label>Completion message </label>
                    <Form.Control
                      style={{ height: "79px" }}
                      as="textarea"
                      rows={20}
                      placeholder={`Enter Text`}
                      name="completion_message"
                      value={
                        tempObj?.parent?.completion_message
                          ? tempObj?.parent?.completion_message
                          : ""
                      }
                      onChange={handleOverviewChange}
                      className="input-item"
                    />
                  </div>
                  <div className="form-input-wrap">
                    <label>Drip Date</label>
                    <Form.Control
                      type="date"
                      placeholder={`Drip Date`}
                      name="available_on"
                      value={
                        tempObj?.parent?.available_on
                          ? tempObj?.parent?.available_on
                          : ""
                      }
                      onChange={handleOverviewChange}
                      className="input-item"
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
export default LearningPathRight;
