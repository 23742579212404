import React, {useState, useEffect, useCallback} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from 'date-fns';
import Select from 'react-select';
import CourseDummy from '~/assets/images/course_dummy.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import debounce from "lodash.debounce";
import {
    getDeletedLearningPaths,
    handlePaginationDeletedLPS,
    handlePreviousDeletedLPS,
    restoreLearningPath,
    permanentlyDeleteLearningPath,
    getManagerDraftLearningPaths,

} from '../../services';
import 'react-toastify/dist/ReactToastify.css';

import {useDispatch, useSelector} from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import Pagination from "react-bootstrap/Pagination";
import {useHistory} from "react-router-dom";
import {getNotifications} from "../../../Notifications/services";
import CustomPagination from "../../../../shared/components/pagination";
import {getManagerCoursesList} from "../../../coursemanagement/services";
 import infoModal from "../../../../shared/components/infoModal";
import InfoModal from "../../../../shared/components/infoModal";
const DeletedCourses = ({getCourseIds}) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [search, setSearch] = useState('');
    const response = useSelector(state => state?.learningpath?.deleted_learning_paths?.response);
    const restoreLoading = useSelector(state => state?.learningpath?.restore_learning_paths?.loading);
    const permanentLoading = useSelector(state => state?.learningpath?.permanently_delete_learning_paths?.loading);
    const loading = useSelector(state => state?.learningpath?.deleted_learning_paths?.loading);
    const [state, setState] =useState({
        learningPaths:response,
        count:response?.count,
        paginationItem:[],
        learnId :'',
        learnIds : [],
    })
    const [activePage, setActivePage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [count,setCount] = useState(0)
    const [showModal,setShowModal] = useState(false)
    const [lp,setLp] = useState({})
    useEffect(() => {
        setSearch('');
        setDateRange([null, null]);
        dispatch(getDeletedLearningPaths(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate));
        // dispatch(getNotifications())
    }, []);
    useEffect(()=>{
        if(count > 0 && dateRange[0] == null){
            setActivePage(1);
            dispatch(getDeletedLearningPaths("", startDate === null ? '' : '', endDate === null ? '' : ''));

        }
    },[count])
    useEffect(() => {
        setState({...state, learningPaths:response,learnIds: []});
    }, [response]);



    useEffect(() => {

        if (startDate !== null && endDate !== null) {
            setActivePage(1);
            dispatch(getDeletedLearningPaths(search, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd')));
        }

    }, [startDate, endDate]);


    const debouncedGetSearch = useCallback(debounce(query => {

        setActivePage(1)
        dispatch(getDeletedLearningPaths(query, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd')));
    },1000),[])

    const searchText = (e) => {
        setSearch(e.target.value);
        debouncedGetSearch(e.target.value)
    };

    const reset = () => {
        setSearch('');
        setDateRange([null, null]);
        dispatch(getDeletedLearningPaths("", startDate === null ? '' : '', endDate === null ? '' : ''));
    };

    const handleNext = () => {
        if (state.learningPaths?.next) {
            var regex = /page=\d+/g;
            const [number ] = state.learningPaths?.next?.match(regex)
            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
            dispatch(handlePaginationDeletedLPS(state.learningPaths?.next));
        } else {
            return false;
        }

    };
    const handlePrevious = () => {
        if (state.learningPaths?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            dispatch(handlePreviousDeletedLPS(state.learningPaths?.previous));

        } else {
            return false;
        }
    };
    const handleIndex = (index) =>{


            dispatch(getDeletedLearningPaths(search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), index));

        setActivePage(index);
    }

    const handleDeleteCourse = (e,row) => {

        setState({...state,learnId: row.id})
        dispatch(permanentlyDeleteLearningPath(row,function (res){
            if(res){
                setTimeout(()=>{
                    dispatch(getDeletedLearningPaths(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate));
                    dispatch(getManagerDraftLearningPaths('', '', '', "False", '','1', ''));
                    setLp({})
                },500)
            }
        }));


    };
    const handleEdit  =(e,row)=>{
        e.stopPropagation()
        setState({...state,learnId: row.id})
        dispatch(restoreLearningPath(row,function (res){
            if(res){
                setTimeout(()=>{
                    dispatch(getDeletedLearningPaths(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate));
                    dispatch(getManagerDraftLearningPaths('', '', '', "False", '','1', ''));
                },500)
            }
        }))

    }

    const  handleBulk  = (item,index) =>{

        let {learningPaths,learnIds} = state
        learningPaths.results[index].is_selected = !learningPaths.results[index].is_selected
        if(learnIds.find((el)=>el == item.id )){
            learnIds = learnIds.filter((el)=>el != item.id)
        }else {
            learnIds.push(item.id)
        }
        getCourseIds(learnIds)
        setState({...state,learningPaths: learningPaths,learnIds: learnIds})
    }


    // console.log('ccc',state.courseIds)
    const handleDel  =  (e,row) => {
        e.stopPropagation()
        setLp(row)
            setShowModal(true)
    }
    return (
        <div className="available-course-wrap">
            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="datatable-filter-left-box d-flex align-items-center">
                    <div className="datepicker-box p-relative">
                        <DatePicker
                            placeholderText={'Created date'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setCount(count+1)
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        <span className="datepickericon">
                     <i className="fa fa-calendar"></i>
                  </span>
                    </div>


                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative">
                        <input type="text" placeholder="Search using keyword"
                               value={search} onChange={searchText}
                               className="form-control input-item"/>
                        <span className="search-close-icon" style={{cursor: "pointer"}} onClick={reset}>×</span>
                    </div>
                    <button className="iconbutton search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216"
                             viewBox="0 0 19.216 19.216">
                            <path className="a"
                                  d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="courseslisting">
                <Row>
                    {loading && loading ?
                        (
                            <>
                                <div className='loaderCourses'>
                                    <Spinner animation="border" variant="danger"/>
                                </div>

                            </>


                        ) : (
                            <>

                                {!loading && state.learningPaths && state.learningPaths && state.learningPaths?.results && state.learningPaths?.results?.length ? state.learningPaths?.results?.map((el, index) => (
                                        <Col md={4} lg={3} className="m-b-30 course-box-outer">

                                            <div className="common-card coursecard" onClick={()=>handleBulk(el,index)}>
                                                <div className="course-box-top">
                                                    <div className="course-listing-img">
                                                        <img src={el?.banner_image ? el?.banner_image :CourseDummy} alt=""/>
                                                    </div>
                                                    <div className="common-card-detail-wrap box-listing">
                                                        <h4>{el?.name?.substring(0, 50) + ' '  +  (el?.name?.length > 50 ?  '...' : '')}</h4>
                                                        <p>{el?.description?.substring(0, 50) + ' '  +  (el?.description?.length > 50 ?  '...' : '')}</p>
                                                    </div>
                                                    {/*<div className="coommon-card-det-number d-flex justify-content-between">*/}
                                                    {/*    <span className="common-card-oth-det-left">Modules <span*/}
                                                    {/*        className="det-numb">{el?.modules_count}</span> </span>*/}
                                                    {/*    <span className="common-card-oth-det-left">Quizzes <span*/}
                                                    {/*        className="det-numb">{el?.quizzes_count}</span> </span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="common-card-footer border-top-footer">
                                                    <div
                                                        className="common-card-footer-cont no-border d-flex justify-content-between align-items-center">


                                                        <>
                                                            <button type="button" onClick={(e)=>handleDel(e,el)} className="btn border-btn min-delete-button">

                                                                {el.id == state.learnId && permanentLoading ?

                                                                    <>   <Spinner animation="border" variant="warning"/></>
                                                                    :
                                                                    "Delete Forever"


                                                                }


                                                            </button>
                                                            <button type="button" className="btn border-btn" onClick={(e)=>handleEdit(e,el)}>
                                                                {el.id == state.learnId && restoreLoading ?

                                                                    <>   <Spinner animation="border" variant="warning"/></>
                                                                    :
                                                                    "Restore"


                                                                }




                                                            </button>
                                                        </>

                                                    </div>
                                                    {el?.is_selected ?
                                                        <div className="box-overlay1" >

                                                            <div className="overlay-bg">&nbsp;</div>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>


                                            </div>


                                        </Col>
                                    ))
                                    :
                                    <span className='noData'>No data available</span>

                                }

                            </>


                        )

                    }

                </Row>
                {!loading && state?.learningPaths && state?.learningPaths && state?.learningPaths.count > 12 && response &&
                <Row className='justify-content-center'>
                    <CustomPagination response = {state?.learningPaths} activePage ={activePage} handleNext = {handleNext} handlePrevious={handlePrevious} handleIndex = {handleIndex}/>
                </Row>

                }
            </div>
            <InfoModal
                show={showModal}
                heading={'Delete Learning Path Forever ?'}
                desctiption ={''}
                button_text={'Yes, Continue'}
                type={'del'}
                handleSubmit={(e)=> {handleDeleteCourse(e,lp);setShowModal(false)}}
                onHide={() => setShowModal(false)}

            />
        </div>
    );
};
export default DeletedCourses;
