import React from "react";
import RadioButton from "../radioButton/container";
import QuizInput from  "../input/container"

function FormikControl(props){
    const {control,...rest} =props
    if(control.length >1)
    switch (control){
        case "multiple_choice":
            return <RadioButton {...rest} />
        case 'true_or_false':
            return <RadioButton {...rest} />
        case 'fill_in_the_blanks':
            return <QuizInput {...rest} />
        default :
            return false
    }

}
export default FormikControl;
