import React, { Fragment, useState } from 'react';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Classic from 'ckeditor5/packages/ckeditor5-build-classic/build/ckeditor'
import Col from "react-bootstrap/Col";


const CkEditor = ({name,values,setFieldValue,moduleErrors,moduleTouched,index,handleBlur}) => {



    return (
        <Fragment>
            <div>
            <CKEditor
                name={name}
                activeclassName="p10"
                config={{placeholder: "Enter Course Information"}}
                editor={Classic}
                onBlur={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue(name, data)
                }}
                data={values?.modules[index]?.contents ? values?.modules[index]?.contents : ""}


            />
            </div>
            {moduleErrors?.contents && moduleTouched?.contents &&
            <span className={`form-error text-danger`} style={{display:'block'}}>{moduleErrors?.contents}</span>}
        </Fragment>
    );
}

export default CkEditor;
