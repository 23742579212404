import React, {useEffect, useState} from 'react';
import ScrollArea from 'react-scrollbar';
import Form from 'react-bootstrap/Form';
import HeaderSearch from '~/assets/images/icons/magnifiying-glass.svg';
import {useDispatch, useSelector} from 'react-redux';
import {getMemberList} from "../../../../coursemanagement/services";
import Spinner from "react-bootstrap/Spinner";
import {getMembersWithOutPagination} from "../../../services"
import GroupAssignBox from "./groupselectionbox";

const CourseAssignBox = ({memberList,assignId,urlId,singleAssignment}) => {
    const dispatch = useDispatch();
    const response = useSelector(state => state?.learningpath?.members_without_pagination?.response);
    const loading = useSelector(state => state?.learningpath?.members_without_pagination?.loading);
    // const assignmentById = useSelector(state => state.course?.get_course_assigment_by_id?.response);

    const [state, setState] = useState({
        member: response?.results || [],
        tempMember: response?.results || [],
        selectedMember: [],
        memberId: [],
        tempoMem :[]
    });

    useEffect(() => {
        dispatch(getMembersWithOutPagination());
    }, []);
    useEffect(() => {
             if(assignId && urlId){
                 let {memberId} = state;
                 let newArray = response?.results?.map(item => {
                     let temp = {...item, isChecked: singleAssignment?.learners?.includes(item.id)};
                     return temp
                 })
                 let selectedArray = newArray?.filter((el) => {
                     return el.isChecked
                 })
                 let membersId = selectedArray?.map((el) => el.id)
                 memberList(membersId)
                 setState({...state,tempMember:newArray, member: newArray, selectedMember: selectedArray, memberId: membersId})
             }else{
                 setState({...state,member:response?.results,tempMember:response?.results})
             }

    }, [response,assignId]);
    const handleClick = (item, index) => {
        let {member, selectedMember, memberId,tempMember} = state;
        let tempArray =  JSON.parse(JSON.stringify(tempMember))
        member[index].isChecked = !member[index].isChecked;

        let tempIndex = tempArray.findIndex((el)=>{
            return el.id == item.id
        })
        tempArray[tempIndex].isChecked = !tempArray[tempIndex].isChecked;

        if(item.get_full_name){
            if (selectedMember.find((f) => f.get_full_name == item.get_full_name)) {
                selectedMember = selectedMember.filter((el) => el.get_full_name != item.get_full_name);
                memberId = memberId.filter((el) => el != item.id);
            } else {
                selectedMember.push(item);
                memberId.push(item.id);
            }
        }else{
            if (selectedMember.find((f) => f.email == item.email)) {
                selectedMember = selectedMember.filter((el) => el.email != item.email);
                memberId = memberId.filter((el) => el != item.id);
            } else {
                selectedMember.push(item);
                memberId.push(item.id);
            }
        }

        memberList(memberId)
        setState({...state, selectedMember: selectedMember, memberId: memberId,member: member,tempMember:tempArray});
    };
    const handleRemove = (item,j) =>{
        const {selectedMember,memberId,member,tempMember} =state
        selectedMember.splice(j,1)
        let tempArray =  JSON.parse(JSON.stringify(tempMember))
        let tempIndex = member.findIndex((el)=>{
            return el.id == item.id
        })
        let tempIndextemp = tempArray.findIndex((el)=>{
            return el.id == item.id
        })
        if(tempIndex > -1){
            member[tempIndex].isChecked = false
        }

        tempArray[tempIndextemp].isChecked = false
        let membersId = selectedMember?.map((el)=>el.id)
        memberList(membersId)
        setState({...state,selectedMember: selectedMember,member: member,memberId:membersId,tempMember:tempArray})
    }
    const handleSearch = (e) => {
        let val = e.target.value;
        if (val.length > 0) {
            if(assignId && urlId) {
                let results = state?.tempMember?.filter(item =>
                    item.get_full_name?.toLowerCase().includes(val?.toLowerCase().trim()) || item?.email?.toLowerCase().includes(val?.toLowerCase().trim())
                );
                setState({...state, member: results})
            }else{
                let results = state?.tempMember?.filter(item =>
                    item.get_full_name.toLowerCase().includes(val?.toLowerCase().trim()) || item?.email?.toLowerCase().includes(val?.toLowerCase().trim())
                );
                setState({...state, member: results})
            }
        } else {
            const {tempMember} =state
            if(assignId && urlId){

                setState({...state,member: tempMember})
            }else {
                setState({...state,member: tempMember})
            }

        }
    }
    return (
        <div className="course-assignbox-wrap height-auto">
            <div className="course-form-content-sec d-flex">
                <div className="add-quiz-box whitebox shadow-box">
                    <div className="add-quiz-box-header">
                        <Form>
                            <div className="form-input-wrap">
                                <Form.Control type="text" placeholder="Select learner" className="input-item" onChange={handleSearch}
                                />
                                <button type="button" className="icon-btn">
                                    <img/>
                                </button>
                            </div>
                        </Form>
                    </div>

                    <div className="add-quiz-box-content">
                        <ScrollArea
                            speed={0.8}
                            className="listscroll"
                            contentClassName="content"
                            horizontal={false}
                        >
                            <ul>
                                { !loading && state?.member && state?.member && state?.member?.length ? state?.member?.map((el, index) => {

                                        return (
                                            <li onClick={() => handleClick(el, index)} key={index} className={`${el.isChecked && el.isChecked  ? 'list-selected' : ''}`}>
                                                <span >{el?.get_full_name ? el?.get_full_name :el?.email} </span>
                                            </li>
                                        );
                                    })
                                    :

                                    <div className='row justify-content-center mt-2'>
                                        {loading ?
                                            <Spinner animation="border" variant="danger"/>
                                            :
                                            'NO DATA'
                                        }

                                    </div>


                                }


                            </ul>
                        </ScrollArea>
                    </div>

                </div>
                <div className="course-list-selected-box">
                    <div className="course-list-selected-box-content">

                        {state?.selectedMember && state.selectedMember.length ? state?.selectedMember?.map((item, j) => {
                                return (
                                    <div className="course-selected-item p-relative">
                                        <span className="course-selected-text">{item?.get_full_name ? item?.get_full_name :item?.email }</span>
                                        <span className="memb-close-icon" onClick={()=>handleRemove(item,j)}>×</span>
                                    </div>
                                );
                            })
                            :
                            ''
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};
export default CourseAssignBox;
