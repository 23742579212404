import React from 'react';
import PasswordChecklist from "react-password-checklist"

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Logo from '~/assets/images/logo.png';
import ResetPasswordImg from '~/assets/images/resetpassword-img.svg';
import { Link } from 'react-router-dom';

function ResetPassword() {
   const [showPassword, setShowPassword] = useState({
      newPassword: false,
      confirmNewPassword: false
   });

   const handleClickShowNewPassword = () => {
      setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword });
   };

   const handleClickShowConfirmNewPassword = () => {
      setShowPassword({ ...showPassword, confirmNewPassword: !showPassword.confirmNewPassword });
   };
   return (
      <div className="auth-page-wrap">
         <div className="auth-page-box d-flex">
            <div className="auth-right d-flex justify-content-center align-items-center">
               <div className="auth-cont-box">
                  <div className="auth-cont-box-header text-center">
                     <div className="auth-logo"><img src={Logo} alt="" /></div>
                     <h2 className="auth-title">Reset Password</h2>
                     <p className="auth-desc">Please enter your new password.</p>
                  </div>
                  <div className="auth-form-box white-box radius-box">
                     <Form>
                        <div className="form-input-wrap" style={{ position: 'relative' }}>
                           <Form.Label>Password</Form.Label>
                           <Form.Control
                           
                           type={showPassword.newPassword ? 'text' : 'password'}
                           placeholder="Your New Password" className="input-item" />

                           <span
                              style={{ position: 'absolute', top: 30, right: 10 }}
                              onClick={handleClickShowNewPassword}

                           >
                              {showPassword.newPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                 <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                 <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                              </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                 <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                 <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                              </svg>}
                           </span>

                        </div>
                        <div className="form-input-wrap" style={{ position: 'relative' }}>
                           <Form.Label>Confirm New Password</Form.Label>
                           <Form.Control
                            type={showPassword.confirmNewPassword ? 'text' : 'password'}
                            placeholder="Confirm Password" className="input-item" />

<div >
                                    <PasswordChecklist
                                        rules={["minLength", "number", "capital",]}
                                        minLength={8}
                                        value={values.new_password1}
                                        // valueAgain={passwordAgain}
                                        onChange={(isValid) => { }}
                                    />
                                    </div>

                           <span
                                        style={{ position: 'absolute', top: 30, right: 10 }}
                                        onClick={handleClickShowConfirmNewPassword}

                                    >
                                        {showPassword.confirmNewPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>}
                                    </span>

                     


                        </div>

                        <div className="button-wrap">
                           <Link to='/subscriptiononsignup'> <Button type="submit" className="w-100">Sign Up</Button></Link>
                        </div>
                     </Form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
export default ResetPassword;