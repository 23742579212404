import React, {memo, useContext, useState} from 'react';
import Form from 'react-bootstrap/Form'
import {Handle, removeElements} from 'react-flow-renderer';
import {FlowContext} from "../container";
import {ToastContainer, toast} from "react-toastify";
import {deleteElementByIdLp} from "../../../services";
import {useDispatch, useSelector} from "react-redux";

export default memo(({ data, isConnectable,...rest }) => {
    const [elements,setElements,nodeId,setNodeId,lpId] =useContext(FlowContext)
    const [nestedNode,setNestedNode] = useState(null)
    const deleteLoading = useSelector(state => state?.learningpath?.delete_lp_module?.loading);
    const toastId = React.useRef(null);
    let dispatch = useDispatch();

    const isValidConnection = (connection) => {
       let findParent = elements.find((el)=>el.id == connection.source)
        let findChild = elements.find(((el)=>el.id == connection.target))
      let childrens = elements.filter((el)=>el.source == findParent.id)

        if(childrens.length  == 4 ){

            if(childrens.length  == 4){
                if( !toast.isActive(toastId.current)) {
                   toast.error("Only 4 courses can be connected to quiz!", {
                       toastId: 'success1',
                   });

                }else{
                    // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                }

            }
         return false
        }else {
            if(childrens.find((el)=>el.sourceHandle == connection.sourceHandle) || childrens.find((el)=>el?.data?.id == findChild?.data?.id)){
                if(childrens.find((el)=>el.sourceHandle == connection.sourceHandle)){
                    if( !toast.isActive(toastId.current)) {
                       toast.error("Only one node can be connected to one handle!", {
                           toastId: 'success1',
                       });

                    }else{
                        // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                    }
                }else{
                    if( !toast.isActive(toastId.current)) {
                         toast.error("Course can not be repeated on same node !", {
                             toastId: 'success1',
                         });

                    }else{
                        // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                    }
                }
                    return  false
            }else{
                return true
            }


        }

    }
    const handleRemove = (e) =>{
        e.stopPropagation();
        if(!deleteLoading) {
            let elmentsToRemove = elements?.filter((el) => el.id == rest.id)
            let singleElement = elements?.find((el) => el.id == rest.id)
            setNestedNode(singleElement.id)
            let copyElements = [...elements]
            copyElements = copyElements.map((el) => {
                return {
                    ...el,
                    ...(!el?.source?.length && {parent: el.parent == rest.id ? null : el.parent ? el.parent : null}),
                    ...(!el?.source?.length && {criteria: el.parent == rest.id ? null : el.criteria ? el.criteria : null}),

                }
            })
            if (nodeId == rest.id) {
                setNodeId('')
            }

            if (singleElement.is_created) {
                dispatch(deleteElementByIdLp(lpId, rest.id, function (res) {
                    if (res) {
                        // let findIndex = elements.findIndex((el)=>el.id == rest.id)
                        // copyElements.splice(findIndex,1)
                        // setElements(copyElements)
                        let findIndex = elements.findIndex((el) => el.id == rest.id)
                        copyElements.splice(findIndex, 1)

                        setElements(copyElements)
                        setTimeout(() => {
                            setElements((els) => removeElements(elmentsToRemove, els));

                        }, 40)

                    }
                }))
            } else {
                let findIndex = elements.findIndex((el) => el.id == rest.id)
                copyElements.splice(findIndex, 1)

                setElements(copyElements)
                setTimeout(() => {
                    setElements((els) => removeElements(elmentsToRemove, els));

                }, 40)
            }
        }
    }
const handleRoot = (e,id) => {
        e.stopPropagation()
      let findIndex = elements.findIndex((el)=>el.id == id)
      let copyElements = [...elements]
    copyElements[findIndex].is_root = true
    setElements(copyElements)
    // alert(id)
    setNodeId('')


}
// console.log('rast',rest)
    return (
        <>

            <div className="close-icon nodrag" disabled={deleteLoading} onClick={(e)=>handleRemove(e)}>
                x
            </div>
            <div className="custom-node itsquiz  handle-custom ">
                <div className="nodes-wrap">
            <div className='handle-custom-one'>
            <Handle
                type="source"
                position="bottom"
                id='a'
                // onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
                isValidConnection={isValidConnection}
            />
            </div>
                <div className='handle-custom-two'>
                <Handle
                    type="source"
                    position="bottom"
                    id='b'
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnection}
                />
                </div>
                <div className='handle-custom-three'>
                <Handle
                    type="source"
                    position="bottom"
                    id='c'
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnection}
                />
                </div>
                <div className='handle-custom-four'>
                <Handle
                    type="source"
                    position="bottom"
                    id='d'
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnection}
                />
                </div>
                </div>
                <div >
                    <div className="" >
                        <div className="course-box-top">
                            <div  className="course-listing-img">
                                {/*<img  src={data?.image ? data?.image :QuizDummy} alt="" />*/}
                            </div>
                            <div className="common-card-detail-wrap box-listing">
                                <h4>{data?.name}</h4>
                            </div>
                        { elements?.find((el,index)=>el.id == rest.id)?.parent == null && elements?.filter((el,index)=>el?.is_root == true)?.length == 0 &&
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"

                            />
                            <label className="custom-control-label"
                                   onClick={(e) => handleRoot(e, rest.id)}>Make root node</label>
                        </div>
                        }
                            <div className="coommon-card-det-number">
                                {elements?.find((el,index)=>el.id == rest.id) ?
                                    <>

                                    <span className="common-card-oth-det-left">Range Type<span className="det-numb">{elements?.find((el)=>el.id == rest.id).criteria}</span> </span><br/>
                                        <span className="common-card-oth-det-left">Questions Count <span className="det-numb">{data?.questions_count}</span> </span>
                                    </>
                                :
                                    ''
                                }

                                {deleteLoading && nestedNode && nestedNode == rest.id  ?

                                    <div className="box-overlay1" >

                                        <div className="overlay-bg"><span className='span-node'>...deleting</span></div>
                                    </div>
                                    :
                                    <></>

                                }









                            </div>

                        </div>
                    </div>
                </div>


            {/*<Handle*/}
            {/*    type="source"*/}
            {/*    position="left"*/}
            {/*    id="a"*/}
            {/*    style={{ top: 10, background: '#555' }}*/}
            {/*    isConnectable={isConnectable}*/}
            {/*/>*/}
            {/*<Handle*/}
            {/*    type="source"*/}
            {/*    position="right"*/}
            {/*    id="b"*/}
            {/*    style={{ bottom: 10, top: 'auto', background: '#555' }}*/}
            {/*    isConnectable={isConnectable}*/}
            {/*/>*/}
            </div>
        </>
    );
});
