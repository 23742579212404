import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import CourseTransitonIcon from './transitionMessageIcon'
import { Link, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import CourseCompletedIcon from "../../../../../../shared/components/coursecompleted/completedicon";


function CourseTransition(props) {

    const dispatch = useDispatch();
    const history = useHistory()


    const handlePush = () => {

 props.setIsTransition(false)
    }

    return (
        <div className="course-completed-wrap">
            {/*<h3 className="sec-title m-b-30">Introduction To Course Library</h3>*/}
            <div className="quiz-completed-outer-box">
                    <div className="shadow-icon-box">
                        <CourseTransitonIcon />
                    </div>
                    <div className="quiz-completed-textwrap">
          <h4>  {props?.data?.node_object_name}</h4>
                    <div className="quiz-attended-statustext">

                        {props?.data && props?.data?.transition_message &&
                        <p> {props?.data?.transition_message }</p>

                        }

                    </div>
                    <div className="btn-wrap double-btn d-flex justify-content-center">
                    <button className="btn border-btn">Cancel</button>
                    <Button onClick={handlePush}>Continue Learning</Button>
                    </div>
                </div>
                </div>
        </div>
    );
};

export default CourseTransition;
