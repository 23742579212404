import React, {useCallback,useState,useEffect} from 'react';
import ScrollArea from 'react-scrollbar';
import Form from 'react-bootstrap/Form';
import {FormControl} from "react-bootstrap";
import debounce from "lodash.debounce";



const ModuleFour = (props) => {
    const {name,setFieldValue,options,errors,values,touched,quesIndex,errorName,setValues} = props
    const DEBOUNCE_SAVE_DELAY_MS = 200;
    const [isCorrectAnswer,setIsCorrectAnswer] = useState(false)
    const [isWrongAnswer,setIsWrongAnswer] = useState(false)
      useEffect(()=>{
          if( values && values[name] && values[name]?.length > 0 && options && options?.toLowerCase() == values[name]?.toLowerCase()){
              debouncedSave(values[name])

          }else if(values && values[name] && values[name]?.length > 0) {
              setIsCorrectAnswer(false)
              setIsWrongAnswer(true)

          }else{

              setIsCorrectAnswer(false)
              setIsWrongAnswer(false)
          }


      },[])

    const saveElements = (query) => {

        if(query && query?.length > 0) {
            if (options && options.toLowerCase() == query.toLowerCase()) {
                setIsWrongAnswer(false)
                setIsCorrectAnswer(true)

            } else {

                setIsWrongAnswer(true)
                setIsCorrectAnswer(false)
            }
        }else {
            setIsWrongAnswer(false)
            setIsCorrectAnswer(false)

        }

    }
    const debouncedSave = useCallback(
        debounce(async (query) => {
            saveElements(query);
        }, DEBOUNCE_SAVE_DELAY_MS),
        []
    );

    const handleAnswer = (name,e) => {

        const newValues =  {...values}
        newValues[name] = e.target.value
        setValues({...values, ...newValues})
        debouncedSave(e.target.value)

    }

    return (
                        <div className="">

                                    {/*<h4>Cras gravida bibendum dolor eu varius. ____________________________, eget vehicula lorem sodales eget. Donec quis volutpat orci. Sed ipsum felis, tri stique id egestas et, convallis ac velit. In consequat dolor libero, nec luctus orci rutrum nec?</h4>*/}
                            {/* <div style={{display:'flex'}}><div className='mr-3'><b>{Number(quesIndex)+1} : </b></div><p dangerouslySetInnerHTML={{ __html: ` ${name ? name :''}` }}></p></div> */}
                            <div className="quiz-builder-pop-list d-flex">
                <span className="ind-numb">{Number(quesIndex)+1}.</span>
                <div dangerouslySetInnerHTML={{ __html: ` ${name ? name :''}` }} className="quiz-title-para"></div>
                </div>
                            <div className="answer-sec" style={{marginLeft:"15px"}}>
                                        <Form.Label>Answer</Form.Label>
                                <FormControl
                                    type="string"
                                    name={name}
                                    value={values[name]}
                                    placeholder="Please write your answer here"
                                    onChange={(e)=>handleAnswer(name,e)}
                                    isInvalid={!!errors && !!errors[errorName] && !!errors[errorName]
                                        ? ' is-invalid'
                                        : ''}

                                />

                                {!!errors && !!errors[errorName] ?
                                    <span className={`form-error text-danger`} style={{display:'block'}}>{errors[errorName]}</span>
                                    :
                                    ''
                                }
                                    </div>


                            {isCorrectAnswer &&
                            <p className="radio-validation label-validation">
                                <span className="answer-sucess"><span className="answer-icon"><i
                                    className="fa fa-check"></i></span>{'Right answer'}</span>
                            </p>
                            }
                            {isWrongAnswer &&
                            <p className="radio-validation label-validation">

                                <span className="answer-wrong"><span className="answer-icon"><i
                                    className="fa fa-times"></i></span>{'Your answer is wrong '}</span>


                            </p>
                            }

                </div>
    )

}

export default ModuleFour;
