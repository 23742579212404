import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Logo from "~/assets/images/logo.png";
import AutosizeInput from "react-input-autosize";
import { Formik } from "formik";
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUpDomain } from "~/modules/auth/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetStore } from "../../services";
import { simpleRedirect } from "../../../../shared/utils/checkHost";
import {Helmet} from "react-helmet";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      className="custom-modal confirmation-popup readytogo-popup"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content-wrap text-center">
          <h2 className="modal-title">Ready to Go?</h2>
          <p style={{maxWidth:'545px'}}>
            {" "}
            Please confirm if the domain you entered is the right one. Once you
            confirm this domain, it cannot be changed.<br/>
            By clicking Yes, Continue, you will be redirected to your sub-domain and then you may kindly choose your login mode and proceed.
          </p>
          <div className="d-flex justify-content-center buttons-wrap double-btn">
            {/* <button type="button" className="btn border-btn white-btn" onClick={props.onHide} >No, I need to recheck</button> */}
            <button
              type="button"
              className="btn border-btn white-btn"
              onClick={() => props.onHide()}
            >
              No, I need to recheck
            </button>
            <Link className="m-l-20">
              <Button onClick={props.handleSubmit} type="button">
                Yes, Continue
              </Button>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function SubDomainSignUp() {
  const [modalShow, setModalShow] = React.useState(false);
  const [state, setState] = useState({
    domainName: true,
  });
  const host = window.location.host;
  const { subscriptionPlans, user, isSuccessFull } = useSelector(
    (state) => state.auth
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = () => {
    let action = "USER_LOGOUT";
    dispatch(resetStore(action));
  };
  useEffect(() => {
    simpleRedirect(user, history, logout);
  }, []);
  // },[])
  const handleModel = (values) => {
    if (values) {
      setModalShow(true);
    }
  };
  return (
    <div className="auth-page-wrap setup-sub-domain-wrap">
       <Helmet>
                <title>Set Up Sub-Domain - Cultural Engagement  </title>
            </Helmet>
      <ToastContainer autoClose={2000} />
      <div className="auth-page-box d-flex">
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="auth-cont-box">
            <div className="auth-cont-box-header text-center">
              <div className="auth-logo">
                <img src={Logo} alt="" />
              </div>
              <h2 className="auth-title">Set Up Sub-Domain</h2>
              <p className="auth-desc">Every organisation would have their own sub-domain, where they can access unlimited content and create new themselves.
Kindly create your own unique sub-domain and start experiencing the platform.</p>
            </div>
            <div className="auth-form-box white-box radius-box">
              <Formik
                validationSchema={SetUpDomain}
                initialValues={{
                  name: "",
                }}
                onSubmit={(values) => {
                  let deepCopy = { ...values };
                  // var url = window.location.href
                  // var newUrl = url.replace(/^http:\/\//, 'http://' + values.name + '.')
                  // let newUrl1 = newUrl.replace('/subdomainsignup','')
                  // setModalShow(false)
                  // values.name=newUrl1

                  // var url = window.location.href
                  // var newUrl = url.replace('https://www.', 'https://' + values.name + '.')
                  // let newUrl1 = newUrl.replace('/subdomainsignup','')
                  // setModalShow(false)
                  // values.name=newUrl1
                  if (host && host.split(".")[1] == "enga") {
                    deepCopy.name = `https://${deepCopy.name}.enga.app`;
                  } else if (
                    host &&
                    host.split(".")[1] == "culturalengagement"
                  ) {
                    deepCopy.name = `https://${deepCopy.name}.culturalengagement.sayone.team`;
                  } else if (host && host.includes("localhost")) {
                    deepCopy.name = `http://${deepCopy.name}.localhost:8080`;
                  }
                  setModalShow(false);
                  if (deepCopy.name.length >= 50) {
                    toast.error("Only 50 characters Allowed");
                    return false;
                  }
                  //
                  // var url = window.location.href
                  // let parts = url.split(".");
                  // var newUrl = url.replace(`${parts[0]}.`, 'https://' + deepCopy.name + '.')
                  // let newUrl1 = newUrl.replace('/subdomainsignup','')
                  // setModalShow(false)

                  dispatch(
                    setUpDomain(
                      deepCopy,
                      history,
                      function (response) {
                        if (response) {
                          setState({ ...state, domainName: false });
                          setTimeout(() => {
                            let action = "USER_LOGOUT";
                            dispatch(resetStore(action));
                            window.location = deepCopy.name + "/login";
                          }, 1000);
                        }
                      },
                      function (error) {
                        if (error) {
                          // values.name=''
                          setState({ ...state, domainName: true });
                        }
                      }
                    )
                  );
                }}
              >
                {({ handleSubmit, setFieldValue, values, errors }) => {
                  const myHandleChange = (e) => {
                    const value = e.target.value.trim();
                    setFieldValue("name", value);
                  };
                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      <div className="form-input-wrap">
                        <Form.Label>Sub-Domain</Form.Label>
                        <div className="workspace-input-wrap">
                          <div className="workspace-input">
                            <AutosizeInput
                              placeholder="Your-team-url"
                              className="auto-width-input"
                              autoFocus
                              name="name"
                              value={values.name}
                              onChange={myHandleChange}
                            />
                          </div>

                          <div className="workspace-text">
                            {host && host?.split(".")[1] == "enga"
                              ? ".enga.app"
                              : ".culturalengagement.sayone.team"}
                          </div>
                        </div>

                        <div>
                          {errors.name && (
                            <span className={`form-error text-danger`}>
                              {errors.name}
                            </span>
                          )}
                        </div>
                        <small className="text-black">
                          Please enter only lowercase characters
                        </small>
                      </div>
                      <div className="button-wrap">
                        <Button
                          disabled={isSuccessFull}
                          onClick={(e) => handleModel(values.name)}
                          className="w-100"
                        >
                          {isSuccessFull ? "...Please Wait" : "Sign Up"}
                        </Button>
                      </div>
                      <MyVerticallyCenteredModal
                        show={modalShow}
                        handleSubmit={handleSubmit}
                        onHide={() => setModalShow(false)}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SetUpDomain = yup.object().shape({
  name: yup.string().required("Required").max(50, "Must be exactly 50 digits"),
  // .matches(/^[a-z][a-z0-9_.]*$/, "Please enter only lowercase characters "),
});
export default SubDomainSignUp;
