
import React, {useEffect, useState} from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import "./style.css";
import {useSelector} from 'react-redux';
import country from  '../../../shared/country.json'
import Form from "react-bootstrap/Form";
const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#fff",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883"
            },
            "::placeholder": {
                color: "#87bbfd"
            }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
};

const CardField = ({ onChange }) => (
    <div className="FormRow" s>
        <CardElement style={{color:'black !important'}} options={CARD_OPTIONS} onChange={onChange} />
    </div>
);

const Field = ({
                   label,
                   id,
                   type,
                   placeholder,
                   required,
                   autoComplete,
                   value,
                   onChange
               }) => (
    <div className="FormRow">
        <label htmlFor={id} className="FormRowLabel">
            {label}
        </label>
        {type == "select" ?
        <select name="pets" id={id}
                placeholder={placeholder}
                required={required}
                value={value}
                onChange={onChange}  className="FormRowInput">
            <option> Please Select Country</option>
            {country && country.length ? country.map((item)=>(
                    <option value={item?.value}>{item?.label}</option>
                ))
                :
                ""
            }


        </select>
    :

            <input
                className="FormRowInput"
                id={id}
                type={type}
                placeholder={placeholder}
                required={required}
                autoComplete={autoComplete}
                value={value}
                onChange={onChange}
            />

        }

    </div>
);

const SubmitButton = ({ processing, error, children, disabled,loading,updateloading }) => (
    <button
        className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
        type="submit"
        disabled={processing || disabled ||  loading || updateloading}
    >
        {loading || updateloading || processing ? 'Processing....': children}
    </button>
);

const ErrorMessage = ({ children }) => (
    <div className="ErrorMessage" style={{color:'red'}} role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);


export const  CheckoutForm = (props) => {
    const {user} = useSelector(state => state.auth);
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const loading = useSelector(state => state.auth.userRegistered?.loading);
    const updateloading = useSelector(state => state.profile.update_card?.loading);
    const [, setPaymentMethod] = useState(null);
    const [billingDetails, setBillingDetails] = useState({
        email: "",
        phone: "",
        name: "",
        address:{
            country:'',
            city: '',
            line1: '',
            state: '',
            postal_code:''

            }
    });
    useEffect(()=>{

     },[user]);
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement("card").focus();
            return;
        }
        setProcessing(true);


        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: billingDetails
        });



        if (payload.error) {
            setError(payload.error);
        } else {

            setPaymentMethod(payload.paymentMethod.id);

            props.props.handleCreatePlan(payload.paymentMethod.id,event);

            props.props.handleGoNext();
        }
    };

    return (
        <form className="Form" onSubmit={(e)=>handleSubmit(e)}>
            <fieldset className="FormGroup">
                <Field
                    label="Name"
                    id="name"
                    type="text"
                    placeholder="Jane Doe"
                    required
                    autoComplete="name"
                    value={billingDetails.name}
                    onChange={(e) => {
                        setBillingDetails({ ...billingDetails, name: e.target.value });
                    }}
                />
                <Field
                    label="Email"
                    id="email"
                    type="email"
                    placeholder="janedoe@gmail.com"
                    required
                    autoComplete="email"
                    value={billingDetails.email}
                    onChange={(e) => {
                        setBillingDetails({ ...billingDetails, email: e.target.value });
                    }}
                />
                <Field
                    label="Phone"
                    id="phone"
                    type="tel"
                    placeholder="(941) 555-0123"
                    required
                    autoComplete="tel"
                    value={billingDetails.phone}
                    onChange={(e) => {
                        setBillingDetails({ ...billingDetails, phone: e.target.value });
                    }}
                />
                <Field
                    label="Country"
                    id="country"
                    type="select"
                    placeholder="Country Name"
                    required
                    autoComplete="country"
                    value={billingDetails?.address?.country}
                    onChange={(e) => {
                        setBillingDetails({ ...billingDetails, address:{...billingDetails.address, country:e.target.value }});
                    }}
                />
                <Field
                    label="City"
                    id="city"
                    type="text"
                    placeholder="San Francisco"
                    required
                    autoComplete="city"
                    value={billingDetails.address.city}
                    onChange={(e) => {
                        setBillingDetails({ ...billingDetails, address:{...billingDetails.address, city:e.target.value }});
                    }}
                />
                <Field
                    label="Line 1"
                    id="line1"
                    type="text"
                    placeholder="Line 1"
                    required
                    autoComplete="line1"
                    value={billingDetails.address.line1}
                    onChange={(e) => {
                        setBillingDetails({ ...billingDetails, address:{...billingDetails.address, line1:e.target.value }});
                    }}
                />
                <Field
                    label="State"
                    id="state"
                    type="text"
                    placeholder="California"
                    required
                    autoComplete="state"
                    value={billingDetails.address.state}
                    onChange={(e) => {
                        setBillingDetails({ ...billingDetails, address:{...billingDetails.address, state:e.target.value }});
                    }}
                />

            </fieldset>
            <fieldset className="FormGroup strip-card-entry">
                <CardField
                    onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                    }}
                />
            </fieldset>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            <SubmitButton processing={processing} error={error} disabled={!stripe} loading={loading} updateloading={updateloading}>

                   <span> Pay  {props?.props?.amount}$</span>


            </SubmitButton>
        </form>
    );
};

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
        }
    ]
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const Stripe = (props) => {
    return (
        <div className="AppWrapper">
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CheckoutForm props={props} />
            </Elements>
        </div>
    );
};
export default Stripe;
