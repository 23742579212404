import React, { useState,useEffect } from "react";
import ScrollArea from 'react-scrollbar';
import Form from 'react-bootstrap/Form';
import {useDispatch, useSelector} from "react-redux";
import { getGroupsList } from '~/modules/surveys/services';
import {Link,useHistory} from "react-router-dom";
import Button from "react-bootstrap/Button";
import { assignSurvey } from '~/modules/surveys/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {detailSurvey} from '~/modules/surveys/services';
import {getNotifications} from "../../../Notifications/services";

function MyVerticallyCenteredModal(props) {
    const [text, setText] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const history =useHistory()
    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
            history.push('/surveymanagement')
        }, 200);
    };
    return (

        <Modal className="custom-modal confirmation-popup"
               {...props}
               size="md"
               aria-labelledby="contained-modal-title-vcenter"
               centered

        >
            <Modal.Body>
                <div onClick={()=>history.push('/surveymanagement')}> <div className="form-close-btn p-relative"><span className="memb-close-icon-card">×</span></div></div>
                <div>
                    {isCopied && isCopied ?
                        <span className="copied-text">
                             Copied!
                            </span>
                        :
                        ''
                    }

                    {/*<span className="search-close-icon-modal" style={{cursor: "pointer"}} onClick={() => props.onHide()}>×</span>*/}

                    <Row>
                        <Col xs={12} sm={12}>
                            <Form.Label>Survey Link</Form.Label>
                            <div className="form-input-wrap d-flex copylink-input-wrap">

                                <Form.Control type="text"
                                              name='last_name'
                                              placeholder="Your Last Name"
                                              value ={props?.surveyLink}
                                              disabled
                                              className="input-item"/>
                                <CopyToClipboard text={props?.surveyLink} onCopy={onCopyText}>

                                    <Button  className="copy-btn" >Copy</Button>


                                </CopyToClipboard>

                            </div>



                        </Col>


                    </Row>
                    {/*<button type="button" className="btn border-btn white-btn ml-5" style={{*/}
                    {/*    position: "absolute",*/}
                    {/*    transform: "translate(60%, -100%)"*/}
                    {/*}} onClick={() => props.onHide()}>Handle Model*/}
                    {/*</button>*/}
                </div>
            </Modal.Body>
        </Modal>
    );
}



function SurveyAssignBox(props) {
    const dispatch = useDispatch();
    const history = useHistory()
    const response = useSelector(state => state.surveys.group_list?.response);
    const {surveyDetail} = useSelector(state => state.surveys);

    const [state, setState] = useState({
        selected:[],
        groups:response?.results,
        tempGroups:response?.results,
        groupId:[],
        modalShow:false,
        surveyLink:'',
        survey:{}
    })

    useEffect(()=>{
        dispatch(getGroupsList())
        dispatch(detailSurvey(props?.id,function (){
           
        }))
    },[])
    useEffect(() => {

        setState({...state,groups: response.results,survey: surveyDetail});
    }, [response,surveyDetail]);

    const handleListClick = (item,index) => {
       let {selected,groups,groupId} = state;
          groups[index].isChecked = !groups[index].isChecked
        if(selected.find(f => f === item.name)) {
            selected = selected.filter(f => f !== item.name);
            groupId = groupId.filter(id => id !=item.id)
        } else {
            selected.push(item.name)
            groupId.push(item.id)
        }

        setState({...state, selected: selected,groups:groups,groupId: groupId})
    }


    const handleRemove = (item,index)=>{
        const {selected,groups} =state
        selected.splice(index,1)

      let tempIndex = groups.findIndex((el)=>{
          return el.name == item
      })
        groups[tempIndex].isChecked = false


        setState({...state,selected: selected,groups: groups})

    }

    const handleChange = (e)=>{
        const {groups,tempGroups} = state

            let newArray =tempGroups?.filter((el)=>{
                return el.name.toLowerCase().includes(e.target.value)
            })
            setState({...state,groups: newArray})



    }
    const handleDispatch = () =>{

            dispatch(assignSurvey(state?.groupId,history,props?.id,function (response){
                if(response){

                        setState({...state, modalShow: true,surveyLink:surveyDetail?.link })

                }
            }))
        dispatch(getNotifications())
        // }else{
        //
        //     toast.error('No Group Selected')
        //     return false
        // }

    }

    return (
        <>
    <div className="course-assignbox-wrap">
        <ToastContainer autoClose={2000} />
<div className="course-form-content-sec d-flex">
      <div className="add-quiz-box whitebox shadow-box">
         <div className="add-quiz-box-header">
            <Form>
               <div className="form-input-wrap">
                  <Form.Control type="text" placeholder="Search Group" onChange={e =>handleChange(e)} className="input-item" />
                  <button type="button" className="icon-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                  </svg>
                  </button>
               </div>
            </Form>
         </div>
         
         <div className="add-quiz-box-content add-survey-box-content">
         <ScrollArea
            speed={0.8}
            className="listscroll"
            contentClassName="content"
            horizontal={false}
            >
            <ul>

                {state.groups && state.groups.length  ? state.groups.map((item,index) => (
                        <li className={`${item.isChecked && item.isChecked  ? 'list-selected' : ''}`} onClick={()=>handleListClick(item,index)}>
                            <span value={item.id} >{item.name}</span>
                        </li>
                ))
                :
                'No Group Available'
                }


            </ul>
            </ScrollArea>
         </div>
         
      </div>
  <div className="course-list-selected-box">

      {state.selected && state.selected.length  ? state.selected.map((item,index) => (
              <div className="course-list-selected-box-content">
                  <div className="course-selected-item survey-selected">
             <span className="p-relative survey-selected-relative-box">
              <span className="course-selected-text">{item}</span>
              <span className="memb-close-icon" onClick={()=>handleRemove(item,index)}>×</span>
              </span>
                  </div>


              </div>
          ))
          :
          ''
      }


  </div>

   </div>
   </div>
    <div className="add-module-footer d-flex justify-content-end">
        <div className="button-wrap double-btn">
            <Link to="/surveymanagement">  <button className="btn btn-m border-btn">Cancel</button></Link>

            <Button variant="primary" onClick={handleDispatch} className="btn-m" >Enroll Users</Button>
        </div>
    </div>

            <MyVerticallyCenteredModal
                show={state.modalShow}
                surveyLink={state.surveyLink}
                onHide={() => setState({...state,modalShow: false})}
            />
        </>
  );
};
export default SurveyAssignBox;
