import React, { useState, } from "react";
import wallClockBlackImg from '~/assets/images/icons/wall-clock-black.svg';
import QuestionIcon from '~/assets/images/icons/question.svg';
import fileIcon from '~/assets/images/icons/test.svg';
import {
  AccordionWithHeader,
  AccordionNode,
  AccordionHeader,
  AccordionPanel
} from 'react-accordion-with-header';
import { Link, useHistory } from 'react-router-dom';
// import {AccordionWithOpenandCloseIcon } from '../../../../../shared/accordian/accordianComponent'


const AccordianItem  = (props) => {
  const history = useHistory()
  const [activePanel,setActivePanel] = useState([])
const handleHistory = () => {

  history.push(`/learningpath/${props?.lp?.id}/nodes`)

}

const actionCallback = (panels,state) => {
  setActivePanel(state?.active)
  }

  return(
      <div className="default-according">


                <AccordionWithHeader className="acc-card-wrapper" actionCallback={actionCallback} >
                  {props.nodes && props.nodes && props.nodes.length ? props.nodes.map((el,i)=>{
                    return(
                <AccordionNode className="accordian-item card" key ={i}>
                  <AccordionHeader className="bg-white card-header"
                                   horizontalAlignment="centerSpaceAround"
                                   verticalAlignment="center"
                  >
                    <div className="d-flex w-100 acc-title align-items-center">
                      <h3>{el?.node_object_name}</h3>
                      <div className=" d-flex ml-auto">
                        <div className="acc-right-box-1" >
                          {activePanel.includes(i)    ?

                              <i className="fa fa-minus" aria-hidden="true"></i>
                              :

                              <i className="fa fa-plus" aria-hidden="true"></i>
                          }

                        </div>
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionPanel>
                    <div className="card-body">
                      <div className="acc-content-header d-flex">
                        <div className="acc-header-box">

                            <p>

                            Node Type:{el?.node_type  == 'quiz' ? 'Quiz': 'Course' }
                            </p>
                            <p className='ml-5'> <img src={fileIcon}/><span className="acc-header-box-cont">{el?.node_type  == 'quiz' ? 'Questions' : 'Modules'}: <span>{el?.node_type  == 'quiz' ? el?.total_course_modules :el?.total_course_modules}</span></span></p>
                            </div>

                      </div>

                    </div>
                  </AccordionPanel>
                </AccordionNode>
                    )



                      }) :
                      <span>No Data</span>

                  }
          </AccordionWithHeader>





          {/*<AccordionNode className="accordian-item card">*/}
          {/*  <AccordionHeader className="bg-white card-header"*/}
          {/*                   horizontalAlignment="centerSpaceAround"*/}
          {/*                   verticalAlignment="center"*/}
          {/*  >*/}
          {/*    <div className="d-flex w-100 acc-title align-items-center">*/}
          {/*      <h3>Introduction to Course Library</h3>*/}
          {/*      <div className="acc-right-title">*/}
          {/*        <div className="acc-right-box-1">*/}
          {/*          <img src={QuestionIcon}/><span className="acc-right-time-text">5 Questions</span>*/}
          {/*        </div>*/}
          {/*        <div className="acc-right-box-1">*/}
          {/*          <img src={fileIcon}/><span className="acc-right-time-text">Fill in the blanks</span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </AccordionHeader>*/}
          {/*  <AccordionPanel>*/}
          {/*    <div className="card-body">*/}
          {/*      <div className="acc-content-header d-flex">*/}
          {/*        <div className="acc-header-box">*/}
          {/*          <img src={fileIcon}/><span className="acc-header-box-cont">Modules: <span>2</span></span>*/}
          {/*        </div>*/}
          {/*        <div className="acc-header-box">*/}
          {/*          <img src={QuestionIcon}/><span className="acc-header-box-cont">Quizzes: <span>2</span></span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="acc-content-desc">*/}
          {/*        <p>Scripture casts a vision for God’s multicultural people, demonstrates how it’s possible to have a*/}
          {/*          blind*/}
          {/*          spot, and gives*/}
          {/*          Christians tools to engage our racial bias. As you work through this course, it is important to*/}
          {/*          remember*/}
          {/*          that everyChristian is a sinner saved by God’s grace. While we are not automatically perfect,*/}
          {/*          we are given the power of the Holy Spirit to address the implicit or explicit racial bias of our*/}
          {/*          past or*/}
          {/*          present.*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </AccordionPanel>*/}
          {/*</AccordionNode>*/}

          {/*<AccordionNode className="accordian-item card">*/}
          {/*  <AccordionHeader className="bg-white card-header"*/}
          {/*                   horizontalAlignment="centerSpaceAround"*/}
          {/*                   verticalAlignment="center"*/}
          {/*  >*/}
          {/*    <div className="d-flex w-100 acc-title align-items-center">*/}
          {/*      <h3>Introduction to Course Library</h3>*/}
          {/*      <div className="acc-right-title d-flex">*/}
          {/*        <div className="acc-right-box-1">*/}
          {/*          <img src={wallClockBlackImg}/><span className="acc-right-time-text">10 / 60 Mins</span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </AccordionHeader>*/}
          {/*  <AccordionPanel>*/}
          {/*    <div className="card-body">*/}
          {/*      <div className="acc-content-header d-flex">*/}
          {/*        <div className="acc-header-box">*/}
          {/*          <img src={fileIcon}/><span className="acc-header-box-cont">Modules: <span>2</span></span>*/}
          {/*        </div>*/}
          {/*        <div className="acc-header-box">*/}
          {/*          <img src={QuestionIcon}/><span className="acc-header-box-cont">Quizzes: <span>2</span></span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="acc-content-desc">*/}
          {/*        <p>Scripture casts a vision for God’s multicultural people, demonstrates how it’s possible to have a*/}
          {/*          blind*/}
          {/*          spot, and gives*/}
          {/*          Christians tools to engage our racial bias. As you work through this course, it is important to*/}
          {/*          remember*/}
          {/*          that everyChristian is a sinner saved by God’s grace. While we are not automatically perfect,*/}
          {/*          we are given the power of the Holy Spirit to address the implicit or explicit racial bias of our*/}
          {/*          past or*/}
          {/*          present.*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </AccordionPanel>*/}

          {/*</AccordionNode>*/}
        {props?.lp && props?.lp?.status == 'Completed' &&
        <div className="accordian-link-box radius-box d-flex justify-content-between align-items-center">
          <div className="acc-link-box-1">
            <div className="d-flex  align-items-center">
              <span className="acc-link-ico"><i className="fa fa-check-circle" aria-hidden="true"></i></span>
              <span className="acc-link-text">Learning Path Completed</span>
            </div>
          </div>
          <div className="acc-link-box-2">
            <ul className="acc-link-list">
              <li>
                <div className="d-flex align-items-center">
                  <span className="acc-link-count">{props?.lp?.courses_count ? props?.lp?.courses_count : 0}</span>
                  <span className="acc-link-list-text">Courses</span>
                </div>
              </li>
              <li>
                <div className="d-flex  align-items-center">
                  <span className="acc-link-count">{props?.lp?.quizzes_count ? props?.lp?.quizzes_count : 0}</span>
                  <span className="acc-link-list-text">Quizzes</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="acc-link-box-3">
            <button className="btn border-btn white-border-btn" onClick={handleHistory}>View Details</button>
          </div>
        </div>
        }
      </div>
)
}
export default AccordianItem;
