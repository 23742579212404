import React from 'react'
import Table from 'react-bootstrap/Table'
import quizIco from '~/assets/images/icons/question.svg';

function ExpandedComponent(props) {

    return (
        <div className="learner-row-expanded">
            <div className="learner-row-expanded-content">
                <Table>
                    {props?.data && props?.data?.nodes && props?.data?.nodes.length && props?.data?.nodes.map((el) => (

                        <tr>
                            <td width="22%">{el?.node_object_name}</td>
                            {el.node_type == 'course' ?

                                <td width="25%">Modules : <span>{el?.total_course_modules}</span></td>
                                :

                                <td width="25%">Marks<span className="rounded-priamry-bg round-value">{el?.mark_scored ? el?.mark_score + ' ' + '%' : '-'}</span></td>
                            }


                            {el.completed_date ?
                                <td width="33%">Completed On <span className="rounded-priamry-bg round-value">{el?.completed_date}</span></td>

                                :
                                <>
                                    {el.available_on ?
                                        <td width="33%">Due Date <span className="rounded-priamry-bg round-value">{el?.available_on}</span></td>

                                        :
                                        <td width="33%">Due Date <span className="rounded-priamry-bg round-value">{props?.data?.due_date}</span></td>
                                    }


                                </>
                            }
                            <td width="20%">Assigned On <span className="rounded-priamry-bg round-value">{el?.assigned_date}</span></td>
                        </tr>

                    ))
                    }

                </Table>
            </div>

        </div>
    )
}

export default ExpandedComponent
