import URLS from "../../constants/urls";
import API from "../config";
import axios from "axios";
/**
 * get starship details
 *
 * @param {Number} starshipId starship ID
 * @returns api response
 */
export const _getStarshipDetails = (starshipId) => {
  return API.get(`${URLS.GET_STARSHIP_DETAILS}${starshipId}/`);
};

/**
 * signUp
 *
 * @returns api response
 */
export const _signUp = async (body) => {
  return API.post(`${URLS.SIGNUP}`, body, { timeout: 10000 });
};
/**
 * STRIPE PAYMENT INTENT
 *
 * @returns api response
 */
export const _getStipePaymentIntent = async (body) => {
  return API.post(`${URLS.STRIPE_PAYMENT_INTENT}create-payment-intent/`, body, {
    timeout: 10000,
  });
};
/**
 * STRIPE PAYMENT INTENT
 *
 * @returns api response
 */
export const _verifyStipePaymentIntent = async (body) => {
  return API.post(
    `${URLS.VERIFY_STRIPE_PAYMENT_INTENT}create-subscription/`,
    body,
    { timeout: 2000 }
  );
};
export const _register = (body) => {
  return API.post(`${URLS.REGISTER}/${body.user_id}`, body, { timeout: 10000 });
};

export const _logOut = () => {
  return API.get(`${URLS.LOG_OUT}`, { timeout: 10000 });
};

export const _signInWithWorkOs = () => {
  return API.get(`${URLS.WORKOS}`, { timeout: 10000 });
};
export const _signInWithEdLink = () => {
  return API.get(`${URLS.EDLINK_LOGIN}`, { timeout: 10000 });
};
export const _verifyWorkOsProfile = (code, state) => {
  return API.get(`${URLS.WORKOS}callback/?code=${code}&state=${state}`, {
    timeout: 10000,
  });
};

export const _verifyEdLinkProfile = (code, state) => {
  return API.get(`${URLS.EDLINK}/?code=${code}&state=${state}`, {
    timeout: 10000,
  });
};

export const _syncWithWorkOs = () => {
  return API.post(`${URLS.WORKOS_ORG}`, { timeout: 10000 });
};

export const _unsyncWithWorkOs = () => {
  return API.post(`${URLS.DELETE_ORGANIZATION}`, { timeout: 10000 });
};
export const _createOrgWithWorkOs = () => {
  return API.post(`${URLS.CREATE_ORGANIZATION}`, { timeout: 10000 });
};

export const _signInWithMagicLinkWithWorkOs = async (body) => {
  return API.post(`${URLS.PASSWORD_LESS_AUTH}`, body, { timeout: 10000 });
};
/**
 * signIn with Google
 *
 * @returns api response
 */
export const _signInWithGoogle = (body) => {
  // let host = window.location.host;
  // let protocol = window.location.protocol;
  // let parts = host.split(".");
  //
  // if (parts.length >= 3) {
  //     body.subDomain = parts[0] ? parts[0] :''
  // }else{
  //     body.subDomain ='jutt'
  // }

  return API.post(`${URLS.SIGNIN_WITH_GOOGLE}`, body, { timeout: 10000 });
};

/**
 * verify domain
 *
 * @param {Number} verify domain
 * @returns api response
 */
export const _verifySubDomain = (host) => {
  return API.get(`${URLS.CHECK_SITE}`, { timeout: 20000 });
};

/**
 * signIn
 *
 * @returns api response
 */
export const _signIn = (body, code, state) => {
  // let host = window.location.host;
  // let protocol = window.location.protocol;
  // let parts = host.split(".");
  //
  // if (parts.length >= 3) {
  //     body.subDomain = parts[0] ? parts[0] :''
  // }else{
  //     body.subDomain =''
  // }
  if (!body && code && state) {

    return API.get(`${URLS.WORKOS}callback/?code=${code}&state=${state}`, {
      timeout: 10000,
    });
  } else {
    return API.post(`${URLS.SIGNIN}`, body, { timeout: 10000 });
  }
};

export const _passwordlessSignIn = (body) => {
    return API.post(`${URLS.PASSWORDLESS_SIGNIN}`, body, { timeout: 10000 });
};
export const _passwordlessWithOtpSignIn = (body) => {
 
  return API.post(`${URLS.PASSWORDLESS_OTP_SIGNIN}`, body, { timeout: 10000 });
};


/**
 * get Subscriptionon Plans
 *
 * @returns api response
 */
export const _getSubscriptionPlans = () => {
  return API.get(`${URLS.GET_SUBSCRIPTION_PLANS}`, { timeout: 20000 });
};

/**
 * create new password
 *
 * @returns api response
 */
export const _createNewPassword = (body) => {
  return API.post(
    `${URLS.CREATE_NEW_PASSWORD}/${body.uid}/${body.token}/`,
    body,
    { timeout: 8000 }
  );
};

/**
 * set password
 *
 * @returns api response
 */
 export const _setPassword = (body) => {
  return API.post(
    `${URLS.SET_PASSWORD}/`,
    body,
    { timeout: 8000 }
  );
};

/**
 * complete registration
 *
 * @returns api response
 */
export const _completeRegistration = (data, id) => {
  return API.put(`${URLS.COMPLETE_REGISTRATION}${id}/`, data, {
    timeout: 10000,
  });
};

/**
 * forgot password
 *
 * @returns api response
 */
export const _forgotPassword = (body) => {
  return API.post(`${URLS.FORGOT_PASSWORD}`, body, { timeout: 8000 });
};

/**
 * forgot sudomain
 *
 * @returns api response
 */
export const _forgotSubDomain = (body) => {
  return API.post(`${URLS.FORGOT_SUBDOMAIN}`, body, { timeout: 8000 });
};

/**
 * forgot Subdomain Signin
 *
 * @returns api response
 */
export const _subdomainSignIn = (body) => {
  return API.post(`${URLS.CHECK_SUBDOMAIN}`, body, { timeout: 8000 });
};

/**
 * forgot Subdomain Signin
 *
 * @returns api response
 */
export const _subdomainExits = (body) => {
  return API.post(`${URLS.FORGOT_PASSWORD}`, body, { timeout: 8000 });
};

/**
 * login
 *
 * @param {FormData} data data
 * @returns api response
 */
export const _getLoginDetails = (data) => {
  return API.post(`${URLS.GET_LOGIN_DETAILS}`, data);
};

/**
 * get survey details
 *
 * @param {Number} surveyId survey ID
 * @returns api response
 */
export const _getSurveyDescription = (surveyId) => {
  return API.get(`${URLS.GET_SURVEY_DETAILS}${surveyId}/` + "description/");
};

/**
 * get survey questions
 *
 * @param {Number} surveyId survey ID
 * @returns api response
 */
export const _getSurveyQuestions = (surveyId) => {
  return API.get(`${URLS.GET_SURVEY_DETAILS}${surveyId}/`);
};

/**
 * get survey details
 *
 * @param {Number} surveyId survey ID
 * @returns api response
 */
export const _getSurveyDemographics = (surveyId) => {
  return API.get(
    `${URLS.GET_SURVEY_DETAILS}${surveyId}/` + "demographic-results/"
  );
};

/**
 * get survey results
 *
 * @param {Number} surveyId survey ID
 * @returns api response
 */
export const _getSurveyResults = (surveyId) => {
  return API.get(
    `${URLS.GET_SURVEY_DETAILS}${surveyId}/` + "construct-results/"
  );
};
/**
 * get survey construct list
 *
 * @param {Number} surveyId survey ID
 * @returns api response
 */
export const _getSurveyConstructList = (surveyId, token) => {
  return API.get(`${URLS.SURVEY_DETAIL}${surveyId}/` + "construct-list/", {
    timeout: 20000,
  });
};
/**
 * get survey scoring
 *
 * @param {Number} surveyId survey ID
 * @returns api response
 */
export const _getSurveyScoring = (surveyId, token) => {
  return API.get(`${URLS.SURVEY_DETAIL}${surveyId}/` + "scoring/", {
    timeout: 20000,
  });
};

/**
 * get survey categories
 *
 * @returns api response
 */
 export const _getSurveyCategories = () => {
  return API.get(`${URLS.SURVEY_CATEGORIES_LIST}`, {
    timeout: 20000,
  });
};


/**
 * get profile
 *
 * @param {Number} userId user ID
 * @returns api response
 */
export const _getProfile = (userId) => {
  return API.get(`${URLS.PROFILE}${userId}/`, { timeout: 20000 });
};

/**
 * update profile
 *
 * @param {Number} userId user ID
 * @returns api response
 */
export const _updateProfile = (userId, token, body) => {
  return API.put(`${URLS.PROFILE}${userId}/` + "edit/", body, {
    timeout: 20000,
  });
};

/**
 * update profile
 *
 * @param {Number} userId user ID
 * @returns api response
 */
export const _updateProfileImage = (userId, token, body) => {
  return API.put(`${URLS.PROFILE}${userId}/` + "image/" + "edit/", body, {
    timeout: 20000,
  });
};

/**
 * contact manager
 *
 * @returns api response
 */
export const _getContactManager = (token) => {
  return API.get(`${URLS.CONTACT_MANAGER}`, { timeout: 20000 });
};

/**
 * change password
 *
 * @param {object} body data
 * @returns api response
 */
export const _changePassword = (token, body) => {
  return API.post(`${URLS.CHANGE_PASSWORD}`, body, { timeout: 20000 });
};

/**
 * Group List
 *
 * @returns api response
 */
export const _getGroupList = (token) => {
  return API.get(`${URLS.GROUP_LIST}`, { timeout: 20000 });
};

/**
 * setup domain
 *
 * @returns api response
 */
export const _setupDomain = (data) => {
  return API.post(`${URLS.SETUP_DOMAIN}`, data);
};

/**
 * select plan
 *
 * @returns api response
 */
export const _selectPlan = (data) => {
  return API.post(`${URLS.SELECT_PLAN}`, data, { timeout: 10000 });
};

/**
 * cancel subscritption
 *
 * @returns api response
 */
export const _cancelSubscription = (formData) => {
  return API.post(`${URLS.CANCEL_SUBSCRIPTION}`, formData, { timeout: 10000 });
};

/**
 * Apply Coupon
 *
 * @returns api response
 */
export const _applyCoupon = (formData) => {
  return API.post(`${URLS.APPLY_COUPON}`, formData, { timeout: 10000 });
};
export const _checkSubscriptionStatus = (id) => {
  return API.get(`${URLS.CHECK_SUBSCRIPTION}${id}/`, { timeout: 20000 });
};

/**
 * Apply Coupon
 *
 * @returns api response
 */
export const _setSubscriptionPlan = (formData) => {
  return API.post(`${URLS.SUBSCRIBE_SUBSCRIPTION_PLANS}`, formData, {
    timeout: 20000,
  });
};

export const _clearState = async (formData) => {
  return formData;
};
/**
 * Transaction History
 *
 * @returns api response
 */
export const _getTransactionHistory = (token, page) => {
  let url = `${URLS.TRANSACTION_HISTORY}`;
  if (page) {
    url += `?page=${page}`;
  }
  return API.get(url, { timeout: 20000 });
};

/**
 * Card Details
 *
 * @returns api response
 */
export const _getCardDetails = (token) => {
  return API.get(`${URLS.CARD_DETAILS}`, { timeout: 20000 });
};

/**
 * Delete Card Details
 *
 * @returns api response
 */
export const _deleteCardDetails = (token) => {
  return API.post(
    `${URLS.DELETE_CARD}`,
    { timeout: 20000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 * ADD card details
 *
 * @param {object} body data
 * @returns api response
 */
export const _saveCard = (token, body) => {
  return API.post(
    `${URLS.CARD_UPDATE}`,
    body,
    { timeout: 20000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 * Upgrade plan
 *
 * @param {object} body data
 * @returns api response
 */
export const _upgradePlan = (token, body) => {
  return API.post(
    `${URLS.UPGRADE_PLAN}`,
    body,
    { timeout: 20000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};
export const _getSubscription = (userId, token) => {
  return API.get(`${URLS.SUBSCRIPTION_DETAILS}${userId}/`, { timeout: 20000 });
};

/**
 * Selected plan
 *
 * @param {object} body data
 * @returns api response
 */
export const _selectedPlan = (token, body) => {
  return API.post(`${URLS.SELECT_PLAN}`, body, { timeout: 20000 });
};

/**
 * CREATE SURVEY
 *
 * @param {object} body data
 * @returns api response
 */

export const _createSurvey = async (body, demo, array, user) => {

  let tempObj = {
    description: body?.description,
    name: body?.title,
    questions: body?.pages,
    expiry_date: body?.Expiry,
    is_published: body.is_published ? body.is_published : false,
    demographic: demo ? demo : "",
  };
  if (user?.user_type === "admin") {
    tempObj.category = array ? array : [];
  } else if (user?.user_type === "manager") {
    if (user?.category) {
      tempObj.category = [user?.category];
    }
  }
  return API.post(`${URLS.GET_SURVEYS}`, tempObj, { timeout: 10000 });
};

/**
 * CREATE SURVEY
 *
 * @param {object} body data
 * @returns api response
 */

export const _submitSurvey = async (body, uuid) => {
  return API.post(`${URLS.SUBMIT_SURVEYS}${uuid}/`, body, { timeout: 10000 });
};

/*
 * DOWNLOAD SURVEY
 *
 * @param {object} body data
 * @returns api response
 */

export const _downloadSurvey = async (id) => {
  return API.get(`${URLS.DOWNLOAD_SURVEY}${id}/download`, { timeout: 10000 });
};

/**
 * CLONE SURVEY
 *
 * @param {object} body data
 * @returns api response
 */

export const _cloneSurvey = async (id) => {
  return API.get(`${URLS.CLONE_SURVEY}${id}`, { timeout: 20000 });
};

/**
 * list SURVEYreport qs
 *
 * @param {object} body data
 * @returns api response
 */

export const _listSurveyReportQuestions = async (id) => {
  return API.get(`${URLS.SURVEY_GRAPH_QUESTIONS}${id}/`, { timeout: 20000 });
};

/**
 * CLONE SURVEY
 *
 * @param {object} body data
 * @returns api response
 */

export const _listDemographicSurveyReport = async (id, uuid) => {
  return API.get(`${URLS.DEMO_RESULT}${id}/?demographic_uuid=${uuid}`, {
    timeout: 20000,
  });
};

/**
 *
 *
 * @param {object} body data
 * @returns api response
 */

export const _listGeneralSurveyReport = async (id, data) => {
  return API.post(`${URLS.GENERAL_RESULT}${id}/`, data, { timeout: 10000 });
};

/**
 * CONSTRUCT SURVEY RESULTS
 *
 * @param {object} body data
 * @returns api response
 */
export const _getConstructResults = async (id, token, construct, choice) => {
  if (construct && choice) {
    return API.get(
      `${URLS.CONSTRUCT_SURVEY_RESULTS}${id}/construct-results/?${construct}=${choice}/`,
      { timeout: 20000 }
    );
  } else if (!construct && !choice) {
    return API.get(`${URLS.CONSTRUCT_SURVEY_RESULTS}${id}/construct-results`, {
      timeout: 20000,
    });
  }
};

/**
 * construct_results
 *
 * @param {object} body data
 * @returns api response
 */

export const _getConstructResultsSurvey = async (id) => {
  return API.get(`${URLS.CONSTRUCT_RESULTS}${id}/results/`, { timeout: 20000 });
};

/**
 * construct_results_by_choices
 *
 * @param {object} body data
 * @returns api response
 */

export const _getConstructResultsByChoices = async (
  id,
  constructOne,
  constructTwo,
  choiceOne,
  choiceTwo
) => {
  // console.log(id,constructOne,constructTwo,choiceOne,choiceTwo)
  return API.get(
    `${URLS.CONSTRUCT_RESULTS}${id}/results/?${constructOne}=${choiceOne}&${constructTwo}=${choiceTwo}`,
    { timeout: 20000 }
  );
};

/**
 * GENDER  SURVEY RESULTS
 *
 * @param {object} body data
 * @returns api response
 */

export const _getGenderDemographicResults = async (id, token) => {
  return API.get(`${URLS.CONSTRUCT_SURVEY_RESULTS}${id}/gender-demographics/`, {
    timeout: 20000,
  });
};
/**
 * role-demographics  SURVEY RESULTS
 *
 * @param {object} body data
 * @returns api response
 */

export const _getRoleDemographicResults = async (id, token) => {
  return API.get(`${URLS.CONSTRUCT_SURVEY_RESULTS}${id}/role-demographics/`, {
    timeout: 30000,
  });
};

/**
 * racial-demographics  SURVEY RESULTS
 *
 * @param {object} body data
 * @returns api response
 */

export const _getRacialDemographicResults = async (id, token) => {
  return API.get(`${URLS.CONSTRUCT_SURVEY_RESULTS}${id}/racial-demographics/`, {
    timeout: 20000,
  });
};

/**
 * racial-demographics  SURVEY RESULTS
 *
 * @param {object} body data
 * @returns api response
 */

export const _getDemographicFilter = async (id) => {
  return API.get(`${URLS.GET_SURVEYS}${id}/demographic-filter/`, {
    timeout: 20000,
  });
};
/**
 * racial-demographics-by-age SURVEY RESULTS
 *
 * @param {object} body data
 * @returns api response
 */

export const _getRacialByAgeDemographicResults = async (id, token) => {
  return API.get(
    `${URLS.CONSTRUCT_SURVEY_RESULTS}${id}/racial-demographics-by-age`,
    { timeout: 40000 }
  );
};

/**
 * LEARNER SURVEY LIST
 *
 * @param {object} body data
 * @returns api response
 */

export const _learnerSurveyList = async (
  search,
  startDate,
  endDate,
  assigned_by,
  page
) => {
  if (assigned_by || search) {
    return API.get(
      `${URLS.Learner_Survey}?search=${search}&assigned_by=${assigned_by}`,
      { timeout: 10000 }
    );
  } else if (page) {
    return API.get(`${URLS.Learner_Survey}?page=${page}`, { timeout: 10000 });
  } else {
    return API.get(`${URLS.Learner_Survey}`, { timeout: 10000 });
  }
};

/**
 * LEARNER SURVEY DETAIL
 *
 * @param {object} body data
 * @returns api response
 */
export const _learnerSurveyDetail = async (id, uuid) => {
  if (id) {
    return API.get(`${URLS.Learner_Survey}${id}/`, { timeout: 10000 });
  } else {
    return API.get(`${URLS.Learner_Survey}${uuid}/`, { timeout: 10000 });
  }
};

/**
 * GET SURVEY
 *
 * @param {object} body data
 * @returns api response
 */

export const _getSurvey = async (
  search,
  startDate,
  endDate,
  createdby,
  category,
  status,
  index
) => {
  // if(index){
  //     return API.get(`${URLS.GET_SURVEYS}?is_published=True&is_active=True&page=${page}`,{timeout:10000});
  // }

  if (index && category) {
    return API.get(
      `${URLS.GET_SURVEYS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&status=${status}&is_published=True&is_active=True&content_category=${category}&page=${index}`,
      { timeout: 10000 }
    );
  } else if (category) {
    return API.get(
      `${URLS.GET_SURVEYS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&status=${status}&content_category=${category}&is_published=True&is_active=True`,
      { timeout: 10000 }
    );
  } else if (index){
    return API.get(
      `${URLS.GET_SURVEYS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&status=${status}&is_published=True&is_active=True&page=${index}`,
      { timeout: 10000 }
    );
  } else {
    return API.get(
      `${URLS.GET_SURVEYS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&status=${status}&is_published=True&is_active=True`,
      { timeout: 10000 }
    );
  }
};

/**
 * GET GROUPS
 *
 * @param {object} body data
 * @returns api response
 */

export const _getGroups = async () => {
  return API.get(`${URLS.GET_GROUPS}`, { timeout: 20000 });
};

/**
 * GET  DELETEDSURVEY
 *
 * @param {object} body data
 * @returns api response
 */

export const _getDeletedSurvey = async (
  search,
  startDate,
  endDate,
  createdby,
  category,
  page=''
) => {
  // if (page) {
  //   return API.get(`${URLS.GET_DELETED_SURVEYS}?&page=${page}`, {
  //     timeout: 10000,
  //   });
  // }
  
  if (category) {
    if(page){
      return API.get(
        `${URLS.GET_DELETED_SURVEYS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&category=${category}&page=${page}`,
        { timeout: 10000 }
      );
    } else {
      return API.get(
        `${URLS.GET_DELETED_SURVEYS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&category=${category}`,
        { timeout: 10000 }
      );
    }
    
  } else {
    if(page){
    return API.get(
      `${URLS.GET_DELETED_SURVEYS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&page=${page}`,
      { timeout: 10000 }
    );
    } else {
      return API.get(
        `${URLS.GET_DELETED_SURVEYS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}`,
        { timeout: 10000 }
      );
    }
  }
};

/**
 * SURVEY ASSIGN
 *
 * @param {object} body data
 * @returns api response
 */

export const _assignSurvey = async (data, history, id) => {
  let tempObj = {
    groups: data,
  };
  return API.post(`${URLS.ASSIGN_SURVEY}${id}/assign/`, tempObj, {
    timeout: 10000,
  });
};

/**
 * ASSIGN MULTIPLE SURVEYS TO GROUP
 *
 * @param {object} body data
 * @returns api response
 */

export const _assignSurveysToGroup = async (id, data) => {
  return API.post(
    `${URLS.ASSIGN_SURVEY_GROUP}${id}/surveys/assign/`,
    data,
    { timeout: 10000 },
    { headers: { "content-type": "application/json" } }
  );
};

/**
 * ASSIGN MULTIPLE SURVEYS TO MEMBERS
 *
 * @param {object} body data
 * @returns api response
 */

export const _assignSurveysToMembers = async (id, data) => {
  // let tempObj = {
  //     groups:data
  // }
  return API.post(`${URLS.ASSIGN_SURVEY_MEMBERS}${id}/assign/`, data, {
    timeout: 10000,
  });
};

/**
 *SURVEY DETAIL
 *
 * @param {object} body data
 * @returns api response
 */

export const _surveyDetail = async (id) => {
  return API.get(`${URLS.GET_SURVEYS}${id}`, { timeout: 10000 });
};

/**
 * Draft SURVEY List
 *
 * @param {object} body data
 * @returns api response
 */
export const _getDraftSurveys = (
  search,
  startDate,
  endDate,
  publish,
  createdby,
  category,
  page
) => {

  if (category) {
    if(page){
      return API.get(
        `${URLS.GET_SURVEYS}?is_published=${publish}&is_active=true&search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&category=${category}&page=${page}`,
        { timeout: 10000 }
      );
    } else {
      return API.get(
        `${URLS.GET_SURVEYS}?is_published=${publish}&is_active=true&search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&category=${category}`,
        { timeout: 10000 }
      );
    }
    
  } else {
    if(page){
    return API.get(
      `${URLS.GET_SURVEYS}?is_published=${publish}&is_active=true&search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&page=${page}`,
      { timeout: 10000 }
    );
    } else {
      return API.get(
        `${URLS.GET_SURVEYS}?is_published=${publish}&is_active=true&search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}`,
        { timeout: 10000 }
      );
    }
  }
};

/**
 * UPDATE SURVEY
 *
 * @param {object} body data
 * @returns api response
 */

export const _updateSurvey = async (
  body,
  id,
  demo,
  array,
  user,
  surveyDetail
) => {
 
  let tempObj = {
    description: body?.description,
    name: body?.title,
    questions: body?.pages,
    expiry_date: body?.Expiry,
    is_published: body.is_published ? body.is_published : false,
    demographic: demo ? demo : "",
  };
  if (user?.user_type === "admin") {
    tempObj.category = array ? array : [];
  } else if (user?.user_type === "manager") {
    if (surveyDetail && surveyDetail?.category) {
      tempObj.category = surveyDetail?.category;
    } else if (user?.category) {
      tempObj.category = [user?.category];
    }
  }

  return API.patch(`${URLS.GET_SURVEYS}${id}/`, tempObj, { timeout: 10000 });
};

/**
 *SURVEY DELETE forever
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteSurveyForever = async (body) => {
  // let body = {
  //   survey_ids: [row.id],
  // };
  return API.delete(
    `${URLS.DELETE_SURVEYS_FOREVER}/`,
    { data: body },
    { timeout: 10000 }
  );
};
/**
 *SURVEY DELETE
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteSurvey = async (row) => {
  return API.delete(`${URLS.UPDATE_SURVEY_QUESTIONS}${row.id}/`);
};
/**
 *SURVEY restore
 *
 * @param {object} body data
 * @returns api response
 */

export const _surveyRestore = async (body) => {
  return API.patch(`${URLS.RESTORE_SURVEYS}`, body);
};


// ADMIN SURVEY 

export const _getSingleAdminSurvey = async (id) => {
  return API.get(`${URLS.Admin_Survey}${id}`, { timeout: 10000 });
};

/**
 *SURVEY PAGINATION NEXT
 *
 * @param {object} body data
 * @returns api response
 */

export const _nextSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *SURVEY PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _previousAdminSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _nextAdminSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 * Draft SURVEY List
 *
 * @param {object} body data
 * @returns api response
 */
export const _getAdminSurveys = (search, startDate, endDate, page, category) => {
  // if (page) {
  //   return API.get(`${URLS.Admin_Survey}?page=${page}`, { timeout: 10000 });
  // }
  if(page && category){
    return API.get(
      `${URLS.Admin_Survey}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&page=${page}&content_category=${category}`,
      { timeout: 10000 }
    );
  } else if(category){
    return API.get(
      `${URLS.Admin_Survey}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&content_category=${category}`,
      { timeout: 10000 }
    );
  } else if(page){
    return API.get(
      `${URLS.Admin_Survey}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&page=${page}`,
      { timeout: 10000 }
    );
  } else {
    return API.get(
      `${URLS.Admin_Survey}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}`,
      { timeout: 10000 }
    );
  }
  
};

/**
 *SURVEY PAGINATION NEXT
 *
 * @param {object} body data
 * @returns api response
 */

export const _LearnerNextSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *SURVEY PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _LearnerPreviousSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 *SURVEY PAGINATION NEXT
 *
 * @param {object} body data
 * @returns api response
 */

export const _draftNextSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *SURVEY PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _draftPreviousSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _deleteNextSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _deletePreviousSurvey = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 * Apply Coupon
 *
 * @returns api response
 */
export const _couponCode = (formData) => {
  return API.post(`${URLS.APPLY_COUPON}`, formData, { timeout: 20000 });
};

/**
 * Manager list
 *
 * @returns api response
 */
export const _getManagers = (token) => {
  return API.get(`${URLS.GET_MANAGER_LIST}`, {
    headers: { Authorization: `Token ` + `${token}` },
  });
};

/**
 * Create course
 *
 * @returns api response
 */
export const _createCourse = (token, body) => {
  return API.post(`${URLS.CREATE_COURSE}`, body, {
    headers: {
      Authorization: `Token ` + `${token}`,
      "content-type": "multipart/form-data",
    },
  });
};

/**
 * Managers list
 *
 * @returns api response
 */

export const _getManagersList = () => {
  return API.get(`${URLS.GET_MANAGER_LIST}`, { timeout: 10000 });
};

/**
 * Member list
 * * @param {object} body data
 * @returns api response
 */
export const _getMembersListALL = (token, search, memberType, index) => {
  if (index) {
    return API.get(
      `${URLS.MEMBER_LIST}?search=${search}&user_type=${memberType}&page=${index}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  } else {
    return API.get(
      `${URLS.MEMBER_LIST}?search=${search}&user_type=${memberType}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  }
};

/**
 *MEMBERS PAGINATION NEXT
 *
 * @param {object} body data
 * @returns api response
 */

export const _nextMembers = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *MEMBERS PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousMembers = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 *Group PAGINATION NEXT
 *
 * @param {object} body data
 * @returns api response
 */

export const _nextGroups = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Group PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousGroups = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 * Add Member
 *
 * @param {object} body data
 * @returns api response
 */
export const _addMemberList = (data, type) => {
  //   return API.post(`${URLS.ADD_MEMBER}`,data , {timeout:10000}, { headers: {  'content-type': 'multipart/form-data' }})
  if (type == "csv") {
    return API.post(
      `${URLS.ADD_MEMBER_CSV}`,
      data,
      { timeout: 10000 },
      { headers: { "content-type": "multipart/form-data" } }
    );
  } else {
    return API.post(
      `${URLS.ADD_MEMBER_INDIVIDUAL}`,
      data,
      { timeout: 10000 },
      { headers: { "content-type": "multipart/form-data" } }
    );
  }
};

/**
 * count Member
 *
 * @param {object} body data
 * @returns api response
 */
export const _countMemberTeams = () => {
  return API.get(`${URLS.COUNT_MEMBER}`, { timeout: 10000 });
};
/**
 * Add Group
 *
 * @param {object} body data
 * @returns api response
 */
export const _addNewGroup = (token, name, data) => {

  return API.post(
    `${URLS.ADD_GROUP}`,
    { name: name, learners: data },
    { timeout: 10000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 * Get all groups
 *
 * @returns api response
 */
export const _getAllGroupsListALL = (token, search, created, index) => {
  if (index) {
    return API.get(
      `${URLS.GET_ALL_GROUPS}?search=${search}&created_by=${created}&page=${index}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  } else {
    return API.get(
      `${URLS.GET_ALL_GROUPS}?search=${search}&created_by=${created}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  }
};

/**
 * update Group
 *
 * @returns api response
 */
export const _updateGroup = async (id, body) => {
  return API.patch(`${URLS.GET_ALL_GROUPS}${id}/`, body, { timeout: 10000 });
};

/**
 * Get member details
 *
 * @returns api response
 */
export const _getMemberDetail = async (token, id) => {

  return API.get(
    `${URLS.GET_MEMBER_DETAIL}${id}/`,
    { timeout: 10000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 * Get group detail
 *
 * @returns api response
 */
export const _getGroupDetail = (token, id) => {

  return API.get(
    `${URLS.GROUP}${id}/`,
    { timeout: 10000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 * Get class detail
 *
 * @returns api response
 */
 export const _getClassDetail = (token, id) => {

  return API.post(
    `${URLS.CLASS_DETAIL}`,{class_id:id},
    { timeout: 10000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 *GET Team member Couses
 *
 * @returns api response
 */
export const _getMemberCourses = (token, id, search, index) => {

  if (index) {
    return API.get(
      `${URLS.GET_MEMBER_DETAIL}${id}/courses/?search=${search}&page=${index}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  } else {
    return API.get(
      `${URLS.GET_MEMBER_DETAIL}${id}/courses/?search=${search}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  }
};

/**
 *Delete Team member Couses
 *
 * @returns api response
 */
export const _deleteMemberCourses = (token, id, body) => {

  return API.delete(
    `${URLS.GET_MEMBER_DETAIL}${id}/courses/`,
    { data: body },
    { timeout: 10000 },
    {
      headers: {
        Authorization: `Token ` + `${token}`,
        "content-type": "multipart/form-data",
      },
    }
  );
};

/**
 *GET Team member Learning path
 *
 * @returns api response
 */
export const _getMemberLearningPath = (token, id, search, index) => {
  if (index) {
    return API.get(
      `${URLS.GET_LP_Member}${id}/learning-paths/?search=${
        search ? search : ""
      }&page=${index}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  } else {
    return API.get(
      `${URLS.GET_LP_Member}${id}/learning-paths/?search=${
        search ? search : ""
      }`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  }
};

/**
 *Delete Team member Learning path
 *
 * @returns api response
 */
export const _deleteMemberLearningPath = (token, id, rowId) => {
  return API.delete(
    `${URLS.GET_LP_Member}${id}/learning-paths/${rowId}`,
    { timeout: 10000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

//   **
//   *GET Team member survey
//   *
//   * @returns api response
//   */
export const _getMemberSurvey = (token, id, search) => {

  return API.get(
    `${URLS.GET_MEMBER_DETAIL}${id}/surveys/?search=${search}`,
    { timeout: 10000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 *Delete Team member survey
 *
 * @returns api response
 */
export const _deleteMemberSurvey = (token, id, body) => {

  return API.delete(
    `${URLS.GET_MEMBER_DETAIL}${id}/surveys/`,
    { data: body },
    { timeout: 10000 },
    {
      headers: {
        Authorization: `Token ` + `${token}`,
        "content-type": "multipart/form-data",
      },
    }
  );
};

/**
 *GET Team group Couses
 *
 * @returns api response
 */
export const _getGroupCourses = (token, id, search, index) => {
  if (index) {

    return API.get(
      `${URLS.GET_GROUP_DETAIL}${id}/courses/?search=${search}&page=${index}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  } else {

    return API.get(
      `${URLS.GET_GROUP_DETAIL}${id}/courses/?search=${search}`,
      { timeout: 10000 },
      { headers: { Authorization: `Token ` + `${token}` } }
    );
  }
};

/**
 *Delete Team  group  Couses
 *
 * @returns api response
 */
export const _deleteGroupCourses = (token, id, formdata) => {

  return API.delete(
    `${URLS.GET_GROUP_DETAIL}${id}/courses/`,
    { data: formdata },
    { headers: { "content-type": "multipart/form-data" } }
  );
};

/**
 * check trial plan
 *
 * @returns api response
 */
export const _checkTrialPlan = async () => {
  return API.get(`${URLS.CHECK_TRIAL_PLAN}`, { timeout: 10000 });
};

/**
 *GET Team group  Learning path
 *
 * @returns api response
 */
export const _getGroupLearningPath = (token, id, search) => {
 
  return API.get(
    `${URLS.GET_GROUP_DETAIL_LP}${id}/learning-paths/?search=${search}`,
    { timeout: 10000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 *Delete Team  group  Learning path
 *
 * @returns api response
 */
export const _deleteGroupLearningPath = (token, id, body) => {
 
  return API.delete(
    `${URLS.GET_GROUP_DETAIL}${id}/learning-paths/`,
    { data: body },
    { timeout: 10000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

//   **
//   *GET Team  group  survey
//   *
//   * @returns api response
//   */
export const _getGroupSurvey = (token, id, search) => {
 
  return API.get(
    `${URLS.GET_GROUP_DETAIL}${id}/surveys/?search=${search}`,
    { timeout: 20000 },
    { headers: { Authorization: `Token ` + `${token}` } }
  );
};

/**
 *Delete Team  group  survey
 *
 * @returns api response
 */
export const _deleteGroupSurvey = (token, id, data) => {
  const formData = new FormData();
  formData.append("survey_assign_id", data);

  return API.delete(
    `${URLS.GET_GROUP_DETAIL}${id}/surveys/`,
    { data: formData },
    { headers: { "content-type": "multipart/form-data" } }
  );
};

// /**
//  * Get all Quizzes
//  *
//  * @returns api response
//  */
//  export const _getAllQuiz = (token) => {
//   return API.get(`${URLS.QUIZ_LIST}`, { headers: { Authorization: `Token ` + `${token}` }});
// };

/**
 * Remove manager access
 *
 * @returns api response
 */
export const _removeManager = (token, data) => {

  return API.patch(
    `${URLS.REMOVE_MANAGER}`,
    { user_ids: data },
    {
      headers: {
        Authorization: `Token ` + `${token}`,
        "content-type": "application/json",
      },
    }
  );
};
/**
 * Assign as Manager
 *
 * @returns api response
 */
export const _assignManager = (token, data) => {

  return API.patch(
    `${URLS.ASSIGN_MANAGER}`,
    { user_ids: data },
    {
      headers: {
        Authorization: `Token ` + `${token}`,
        "content-type": "application/json",
      },
    }
  );
};
/**
 *Members Bulk delete
 * * @param {object} body data
 * @returns api response
 */

export const _membersBulkDelete = (token, data) => {
  const body = { user_ids: data };
  return API.delete(`${URLS.BULK_DELETE}`, { data: body ,timeout:0});
};

/**
 *Groups Bulk delete
 * * @param {object} body data
 * @returns api response
 */
export const _groupsBulkDelete = (token, data) => {
  const body = { group_ids: data };
  return API.delete(`${URLS.BULK_GROUP_DELETE}`, { data: body });
};

/**
 *Member delete
 *
 * @returns api response
 */
export const _memberDelete = (token, id) => {

 
    return API.delete(`${URLS.DELETE_MEMBER}${id}/`);

  
};

/**
 *group single delete
 *
 * @returns api response
 */
export const _groupDelete = (token, id) => {

  return API.delete(`${URLS.DELETE_GROUP}${id}/`, {
    headers: { Authorization: `Token ` + `${token}` },
  });
};

/**
 * ASSIGN MULTIPLE COURSES TO GROUP
 *
 * @param {object} body data
 * @returns api response
 */

export const _assignCoursesToGroup = async (id, data) => {
  return API.post(`${URLS.ASSIGN_COURSE_GROUP}${id}/courses/assign/`, data);
};

/**
 * ASSIGN MULTIPLE COURSES TO MEMBERS
 *
 * @param {object} body data
 * @returns api response
 */

export const _assignCoursesToMembers = async (id, data) => {

  return API.post(`${URLS.MEMBER_LIST}${id}/courses/assign/`, data);
};

/**
 *get all manager Learning path
 *
 *
 * @returns api response
 */

export const _getLP = () => {
  return API.get(`${URLS.GET_LP_MANGER}`, { timeout: 10000 });
};

/**
 * ASSIGN MULTIPLE LP TO GROUP
 *
 * @param {object} body data
 * @returns api response
 */

export const _assignLPToGroup = async (id, data) => {
  return API.post(
    `${URLS.ASSIGN_COURSE_GROUP}${id}/learning-paths/assign/`,
    data,
    { timeout: 10000 }
  );
};

/**
 * ASSIGN MULTIPLE LP TO MEMBERS
 *
 * @param {object} body data
 * @returns api response
 */

export const _assignLPToMembers = async (id, data) => {
  return API.post(`${URLS.MEMBER_LIST}${id}/learning-paths/assign/`, data);
};

/**
 * ASSIGN MULTIPLE GROUPS an LP
 * @param {object} body data
 * @returns api response
 */

export const _assignLPToMultipleGroups = async (id, data) => {
  return API.patch(`${URLS.ASSIGN_MULTIPLE_LP_GROUP}${id}/manager/`, data, {
    timeout: 10000,
  });
};

// /**
//  * Theme Settings
//  *
//  * @returns api response
//  */
// export const _themeSettings = async(theme) => {
//     // eslint-disable-next-line no-unused-expressions
//     return theme
// };

/**
 * Theme Settings
 *
 * @returns api response
 */
export const _themeSettings = async (Theme, Logo) => {
  const data = new FormData();
  if (Logo == false) {
    data.append("theme", Theme);
    return API.patch(`${URLS.THEME}`, data, { timeout: 40000 });
  } else {
    data.append("logo", Logo);
    return API.patch(`${URLS.THEME}`, data, { timeout: 40000 });
  }
};

/**
 * COURSES MANAGER LIST
 *
 * @param {object} body data
 * @returns api response
 */

export const _getCoursesList = async (
  search,
  startDate,
  endDate,
  assigned_by
) => {
  if (assigned_by || search) {
    return API.get(
      `${URLS.Learner_Survey}?search=${search}&assigned_by=${assigned_by}`,
      { timeout: 10000 }
    );
  } else {
    return API.get(`${URLS.Learner_Survey}`, { timeout: 10000 });
  }
};
/**
 * COURSES MANAGER LIST
 *
 * @param {object} body data
 * @returns api response
 */

export const _getManagerCoursesList = async (
  search,
  startDate,
  endDate,
  createdby,
  category,
  page
) => {

  if (page) {
    if (!category) {
      return API.get(
        `${URLS.GET_COURSES_MANAGER}?search=${
          search ? search : ""
        }&created_date_after=${
          startDate ? startDate : ""
        }&created_date_before=${
          endDate ? endDate : ""
        }&is_published=True&created_by=${
          createdby ? createdby : ""
        }&page=${page}`,
        { timeout: 10000 }
      );
    } else {
      return API.get(
        `${URLS.GET_COURSES_MANAGER}?content_category=${
          category ? category : ""
        }&search=${
          search ? search : ""
        }&created_date_after=${
          startDate ? startDate : ""
        }&created_date_before=${
          endDate ? endDate : ""
        }&is_published=True&created_by=${createdby ? createdby : ""}&page=${page}`,
        { timeout: 10000 }
      );
    } // return API.get(`${URLS.GET_COURSES_MANAGER}?&is_published=True&page=${page}`,{timeout:20000});
  } else if (createdby || search || startDate || endDate || category) {
    if (!category) {
      return API.get(
        `${URLS.GET_COURSES_MANAGER}?search=${
          search ? search : ""
        }&created_date_after=${
          startDate ? startDate : ""
        }&created_date_before=${
          endDate ? endDate : ""
        }&is_published=True&created_by=${createdby}`,
        { timeout: 10000 }
      );
    } else {
      return API.get(
        `${
          URLS.GET_COURSES_MANAGER
        }?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&is_published=True&created_by=${createdby}&content_category=${
          category ? category : ""
        }`,
        { timeout: 10000 }
      );
    }
  } else {
    return API.get(`${URLS.GET_COURSES_MANAGER}?is_published=True`, {
      timeout: 20000,
    });
  }
};

/**
 * CLONE COURSE
 *
 * @param {object} body data
 * @returns api response
 */

export const _cloneCourse = async (id) => {
  return API.get(`${URLS.CLONE_COURSE}/${id}/clone/`, { timeout: 20000 });
};

/**
 * Draft Courses List
 *
 * @param {object} body data
 * @returns api response
 */
export const _getManagerDraftCourses = (
  search,
  startDate,
  endDate,
  publish,
  createdby,
  category,
  page
) => {
  if (page) {
   
    // return API.get(`${URLS.GET_COURSES_MANAGER}?page=${page}`,{timeout:20000});
    if (!category) {
      return API.get(
        `${URLS.GET_COURSES_MANAGER}?search=${
          search ? search : ""
        }&created_date_after=${
          startDate ? startDate : ""
        }&created_date_before=${
          endDate ? endDate : ""
        }&is_published=False&created_by=${
          createdby ? createdby : ""
        }&page=${page}`,
        { timeout: 20000 }
      );
    } else {
      return API.get(
        `${URLS.GET_COURSES_MANAGER}?search=${
          search ? search : ""
        }&created_date_after=${
          startDate ? startDate : ""
        }&created_date_before=${
          endDate ? endDate : ""
        }&is_published=False&created_by=${
          createdby ? createdby : ""
        }&category=${category ? category : ""}&page=${page}`,
        { timeout: 20000 }
      );
    }
  } else if (
    search ||
    startDate ||
    endDate ||
    publish ||
    createdby ||
    category
  ) {
    if (!category) {
      return API.get(
        `${URLS.GET_COURSES_MANAGER}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&is_published=${publish}&created_by=${createdby} `,
        { timeout: 20000 }
      );
    } else {
      return API.get(
        `${
          URLS.GET_COURSES_MANAGER
        }?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&is_published=${publish}&created_by=${createdby}&category=${
          category ? category : ""
        }`,
        { timeout: 20000 }
      );
    }
  } else {
    return API.get(`${URLS.GET_COURSES_MANAGER}?is_published=False`, {
      timeout: 20000,
    });
  }
};

/////////////////////////////////////

/**
 * COURSES ADMIN LIST
 *
 * @param {object} body data
 * @returns api response
 */

export const _getAdminCoursesList = async (
  search,
  startDate,
  endDate,
  page,
  category
) => {
  if (page && category) {
    // return API.get(`${URLS.ADMIN_COURSE}?page=${page}`,{timeout:20000});
    return API.get(
      `${URLS.ADMIN_COURSE}?search=${search ? search : ""}&created_date_after=${
        startDate ? startDate : ""
      }&created_date_before=${endDate ? endDate : ""}&page=${page}&content_category=${category}`,
      { timeout: 20000 }
    );
  } else if (category){
    return API.get(
      `${URLS.ADMIN_COURSE}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&content_category=${category}`,
      { timeout: 10000 }
    );
  } else if (page){
    return API.get(
      `${URLS.ADMIN_COURSE}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&page=${page}`,
      { timeout: 10000 }
    );
  } else {
    return API.get(
      `${URLS.ADMIN_COURSE}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}`,
      { timeout: 10000 }
    );
  }
};

/**
 * Draft Courses ADMIN List
 *
 * @param {object} body data
 * @returns api response
 */
export const _getDeletedCourses = (search, startDate, endDate, page) => {
  if (page) {
    // return API.get(`${URLS.GET_DELETED_COURSES}?page=${page}`,{timeout:20000});
    return API.get(
      `${URLS.GET_DELETED_COURSES}?search=${
        search ? search : ""
      }&created_date_after=${startDate ? startDate : ""}&created_date_before=${
        endDate ? endDate : ""
      }&page=${page}`,
      { timeout: 20000 }
    );
  }
  return API.get(
    `${URLS.GET_DELETED_COURSES}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}`,
    { timeout: 20000 }
  );
};

/**
 *Admin Available Course PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextAdminCourse = async (url) => {
  return API.get(`${url}`, { timeout: 20000 });
};
/**
 *Admin Available Course PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousAdminCourse = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Deleted Course PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextDeletedCourse = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Admin Draft Course PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousDeletedCourse = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

///////////////////////////////////////////
/**
 *Available Course PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextAvailableCourse = async (url) => {
  return API.get(`${url}`, { timeout: 20000 });
};
/**
 *Available Course PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousAvailableCourse = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Draft Course PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextDraftCourse = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Draft Course PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousDraftCourse = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 *COURSE DELETE
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteCourse = async (row) => {
  return API.delete(`${URLS.GET_COURSES_MANAGER}${row.id}/`, {
    timeout: 20000,
  });
};
/**
 *COURSE DELETE
 *
 * @param {object} body data
 * @returns api response
 */

export const _permanentlyDeleteCourse = async (row) => {
  return API.delete(`${URLS.GET_DELETED_COURSES}${row.id}/`, {
    timeout: 20000,
  });
};

/**
 *COURSE DELETE Bulk
 *
 * @param {object} body data
 * @returns api response
 */

export const _permanentlyBulkDeleteCourse = async (row) => {
  return API.delete(
    `${URLS.GET_DELETED_COURSES}multiple-delete/`,
    { data: row },
    { timeout: 20000 }
  );
};

/**
 *COURSE DELETE
 *
 * @param {object} body data
 * @returns api response
 */

export const _restoreCourse = async (row) => {
  return API.patch(`${URLS.GET_DELETED_COURSES}${row.id}/restore/`, {
    timeout: 10000,
  });
};
/**
 *COURSE Restore Bulk
 *
 * @param {object} body data
 * @returns api response
 */

export const _restoreBulkCourse = async (row) => {
  return API.patch(`${URLS.GET_DELETED_COURSES}multiple-restore/`, row, {
    timeout: 20000,
  });
};

/**
 *ADD COURSE
 *
 * @param {object} body data
 * @returns api response
 */

export const _addCourse = (data) => {
  return API.post(`${URLS.GET_COURSES_MANAGER}`, data, { timeout: 10000 });
};

/**
 *Update COURSE
 *
 * @param {object} body data
 * @returns api response
 */

export const _updateCourse = (data, id) => {
  return API.patch(`${URLS.GET_COURSES_MANAGER}${id}/`, data, {
    timeout: 10000,
  });
};

export const _getManagerCourse = (id) => {
  return API.get(`${URLS.GET_COURSES_MANAGER}${id}/`, { timeout: 20000 });
};

export const _getAdminCourse = (id) => {
  return API.get(`${URLS.ADMIN_COURSE}${id}/`, { timeout: 20000 });
};

/**
 *ADD COURSE Module
 *
 * @param {object} body data
 * @returns api response
 */

export const _addCourseModule = (data, id) => {
  return API.post(`${URLS.GET_COURSES_MANAGER}${id}/modules/`, data, {
    timeout: 10000,
  });
};

/**
 *Update COURSE Module
 *
 * @param {object} body data
 * @returns api response
 */

export const _updateCourseModule = (data, id) => {
  return API.patch(`${URLS.GET_COURSES_MANAGER}${id}/modules/`, data, {
    timeout: 20000,
  });
};

/**
 *Update COURSE Module
 *
 * @param {object} body data
 * @returns api response
 */

export const _getCourseModuleManager = (id) => {
  return API.get(`${URLS.GET_COURSES_MANAGER}${id}/list-modules/`, {
    timeout: 10000,
  });
};

/**
 *Delete COURSE Module
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteCourseModule = (courseId, moduleId) => {

  return API.delete(
    `${URLS.GET_COURSES_MANAGER}${courseId}/modules/${moduleId}/`,
    { timeout: 10000 }
  );
};

/**
 *Delete COURSE CHOICE
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteCourseChoice = (choiceId) => {
  return API.delete(
    `${URLS.DELETE_COURSES_CHOICE}test-choice/${choiceId}/delete/`,
    { timeout: 10000 }
  );
};

/**
 * Get all groups
 *
 * @returns api response
 */
export const _getAllGroupsList = () => {
  return API.get(`${URLS.GET_ALL_GROUPS}`, { timeout: 10000 });
};

/**
 * Get all Quizzes
 *
 * @returns api response
 */
export const _getAllQuiz = (token) => {
  return API.get(`${URLS.QUIZ_LIST}`, { timeout: 10000 });
};

/**
 * Assign course
 *
 * @returns api response
 */
export const _assignCourse = (body, userId) => {
  return API.post(`${URLS.ASSIGN_COURSE}${userId}/` + "assign/", body, {
    timeout: 10000,
  });
  
};


/**
 * edlink get Assignment
 *
 * @returns api response
 */
 export const _edLinkGetAssignment = (body, userId) => {
  return API.post(`${URLS.EDLINK_GET_ASSIGNMENT}${userId}/`,body, {
    timeout: 10000,
  });
};


/**
 * Update Assign course
 *
 * @returns api response
 */
export const _updateAssignCourse = (body, userId, assignid) => {
  return API.patch(
    `${URLS.ASSIGN_COURSE}${userId}/assignment/${assignid}/`,
    body,
    { timeout: 10000 }
  );
};
/**
 * GEt course assignments
 *
 * @returns api response
 */
export const _getCourseAssignments = (userId) => {
  return API.get(`${URLS.ASSIGN_COURSE}${userId}/` + "assign/", {
    timeout: 10000,
  });
};
/**
 * GEt course assignments by id
 *
 * @returns api response
 */
export const _getCourseAssignmentsById = (courseId, userId) => {
  return API.get(`${URLS.ASSIGN_COURSE}${userId}/assignment/${courseId}/`, {
    timeout: 10000,
  });
};
/**
 * Member list
 *
 * @returns api response
 */
export const _getMembersList = (token) => {
  return API.get(`${URLS.MEMBER_LIST}`, { timeout: 10000 });
};

/**
 * Courses List Learner
 *
 * @returns api response
 */
export const _getLearnerCourses = (search, status, page) => {
  if (page) {
    // return API.get(`${URLS.GET_COURSES_LEARNER}?page=${page}`,{timeout:20000});
    return API.get(
      `${URLS.GET_COURSES_LEARNER_NEW}?search=${search ? search : ""}&status=${
        status ? status : ""
      }&page=${page}`,
      { timeout: 20000 }
    );
  }
  return API.get(
    `${URLS.GET_COURSES_LEARNER_NEW}?search=${search}&status=${status}`,
    { timeout: 20000 }
  );
};
/**
 *lEARNER Course PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _learnerPaginationCourseNext = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Learner Course PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _learnerPaginationCoursePrevious = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 * Course Learner BY ID
 *
 * @returns api response
 */
export const _getLearnerCourseById = (id) => {
  return API.get(`${URLS.GET_COURSES_LEARNER_NEW}${id}/`, { timeout: 10000 });
};

/**
 * Course Learner Modules
 *
 * @returns api response
 */

export const _getLearnerCourseModules = (id) => {
  return API.get(`${URLS.GET_COURSES_LEARNER}${id}/modules/`, {
    timeout: 10000,
  });
};
/**
 * Course Learner Module By Id
 *
 * @returns api response
 */
export const _getLearnerCourseModuleById = (id, moduleId) => {
  return API.get(`${URLS.GET_COURSES_LEARNER}/${id}/modules/${moduleId}`, {
    timeout: 10000,
  });
};
/**
 * Course Learner Complete Module
 *
 * @returns api response
 */
export const _getLearnerCourseCompleteModule = (id, moduleId) => {
  return API.get(
    `${URLS.GET_COURSES_LEARNER}/${id}/modules/${moduleId}/module-complete/`,
    { timeout: 10000 }
  );
};
/**
 * Course Learner Test Attempt
 *
 * @returns api response
 */
export const _learnerCourseTestAttempt = (id, moduleId) => {
  return API.patch(`${URLS.GET_COURSES_LEARNER}/${id}/modules/${moduleId}/`, {
    timeout: 10000,
  });
};
/**
 * Course Learner Test Attempt
 *
 * @returns api response
 */
export const _patchLearnerCourseModules = (id, moduleId) => {
  return API.get(
    `${URLS.GET_COURSES_LEARNER}${id}/modules/${moduleId}/module-complete/`,
    { timeout: 10000 }
  );
};

/**
 * Post Support *
 *  body data
 * @returns api response
 */

export const _postSupport = async (body) => {
  return API.post(`${URLS.POST_SUPPORT}`, body);
};

/**
 * Notifications *
 *  body data
 * @returns api response
 */

export const _getNotifications = async () => {
  return API.get(`${URLS.GET_NOTIFICATIONS}`, { timeout: 20000 });
};
/**
 * Notifications *
 *  body data
 * @returns api response
 */

export const _getNotificationsDetail = async (id) => {
  return API.get(`${URLS.DETAIL_NOTIFICATIONS}${id}/detail/`, {
    timeout: 40000,
  });
};

/**
 * learner Groups
 * @param {Number} userId user ID
 * @returns api response
 */
export const _learnerGroups = (search, created, page) => {
  // return API.get(`${URLS. LEARNER_GROUPS}${userId}/`,{timeout:20000});
  // return API.get(`${URLS.GET_GROUPS}?search=${search}`, {timeout:10000});
  return API.get(`${URLS.GROUP_LIST}?search=${search}&created_by=${created}&page=${page}`, {
    timeout: 10000,
  });
};
/**
 * INSTITUTION TYPE*
 *  body data
 * @returns api response
 */

export const _getInstitutionType = async () => {
  return API.get(`${URLS.INSTITUTION_CATEGORY}`, { timeout: 20000 });
};
//Dashboard

/**
 * dashboard Users Count
 *
 * @returns api response
 */
export const _dashboardUsersCount = () => {
  return API.get(`${URLS.DASHBOARD_USER_COUNT}`, { timeout: 10000 });
};

// QUIZ MANAGEMENT

/**
 *Draft Quiz PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextDraftManagerQuiz = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 *Draft Quiz PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousDraftManagerQuiz = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 *  dashboardCourseCount
 *
 * @returns api response
 */
export const _dashboardCourseCount = () => {
  return API.get(`${URLS.DASHBOARD_COURSES}`, { timeout: 10000 });
};

/***Available Course PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextAvailableManagerQuiz = async (url) => {
  return API.get(`${url}`, { timeout: 20000 });
};
/**
 *Available Course PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousAvailableManagerQuiz = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 * dashboardSurveyCount,
 *
 * @returns api response
 */
export const _dashboardSurveyCount = () => {
  return API.get(`${URLS.DASHBOARD_SURVEYS}`, { timeout: 10000 });
};

/**
 * Quizzes Manager LIST
 *
 * @param {object} body data
 * @returns api response
 */

export const _getManagerQuizzesList = async (
  search,
  startDate,
  endDate,
  createdby,
  page
) => {
  if (page) {
    return API.get(
      `${URLS.QUIZ_LIST}?is_published=True&search=${
        search ? search : ""
      }&created_date_after=${startDate ? startDate : ""}&created_date_before=${
        endDate ? endDate : ""
      }&page=${page}`,
      { timeout: 20000 }
    );
  } else if (createdby || search || startDate || endDate) {
    return API.get(
      `${URLS.QUIZ_LIST}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&is_published=True&created_by=${createdby}`,
      { timeout: 10000 }
    );
  } else {
    return API.get(`${URLS.QUIZ_LIST}?is_published=True`, { timeout: 20000 });
  }
};

/**
 *  dashboardGroupCount,
 *
 * @returns api response
 */
export const _dashboardGroupCount = () => {
  return API.get(`${URLS.DASHBOARD_GROUPS}`, { timeout: 10000 });
};

/**
 *dashboardCoursesGroupsCount
 *
 * @returns api response
 */
export const _dashboardCoursesGroupsCount = () => {
  return API.get(`${URLS.DASHBOARD_COURSES_GROUP}`, { timeout: 10000 });
};

/**
 * dashboardCoursesUsersCount
 *
 * @returns api response
 */
export const _dashboardCoursesUsersCount = () => {
  return API.get(`${URLS.DASHBOARD_COURSES_USERS}`, { timeout: 10000 });
};

/**
 *  dahboardCourseCompletion
 *
 * @returns api response
 */
export const _dahboardCourseCompletion = () => {
  return API.get(`${URLS.DASHBOARD_COURSE_COMPLETION}`, { timeout: 10000 });
};

/**
 *dashboardSurveyUsersCount
 *
 * @returns api response
 */
export const _dashboardSurveyUsersCount = () => {
  return API.get(`${URLS.DASHBOARD_SURVEY_USERS}`, { timeout: 10000 });
};

/**
 *dashboardAsiignmentSurvey
 *
 * @returns api response
 */
export const _dashboardAssignmentSurvey = () => {
  return API.get(`${URLS.DASHBOARD_ASSIGNMENT_GRAPH}`, { timeout: 10000 });
};

/**
 * Quizzes Manager LIST
 *
 * @param {object} body data
 * @returns api response
 */

export const _getManagerDraftQuizzes = async (
  search,
  startDate,
  endDate,
  createdby,
  page
) => {
  if (page) {
    // return API.get(`${URLS.QUIZ_LIST}?is_published=False&page=${page}`,{timeout:20000});

    return API.get(
      `${URLS.QUIZ_LIST}?is_published=False&search=${
        search ? search : ""
      }&created_date_after=${startDate ? startDate : ""}&created_date_before=${
        endDate ? endDate : ""
      }&page=${page}`,
      { timeout: 20000 }
    );
  } else if (createdby || search || startDate || endDate) {
    return API.get(
      `${URLS.QUIZ_LIST}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&is_published=False&created_by=${createdby}`,
      { timeout: 10000 }
    );
  } else {
    return API.get(`${URLS.QUIZ_LIST}?is_published=False`, { timeout: 20000 });
  }
};

/**
 *COURSE DELETE
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteQuiz = async (row) => {
  return API.delete(`${URLS.QUIZ_LIST}${row.id}/`, { timeout: 10000 });
};

export const _addQuiz = (data) => {
  return API.post(`${URLS.QUIZ_LIST}`, data, { timeout: 10000 });
};

/**
 * Assign quiz
 *
 * @returns api response
 */
export const _assignQuiz = (body, userId) => {
  return API.patch(`learn/questions/${userId}/`, body, { timeout: 10000 });
};

/**
 *Delete Quiz Question
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteQuizQuestion = (quizId, questionId) => {

  return API.delete(`learn/questions/${questionId}/delete/`, {
    timeout: 10000,
  });
};

export const _getManagerQuiz = (id) => {
  return API.get(`${URLS.QUIZ_LIST}${id}/`, { timeout: 20000 });
};

/**
 *ADD COURSE Module
 *
 * @param {object} body data
 * @returns api response
 */

export const _addQuizQuestion = (data, id) => {
  return API.post(`${URLS.QUIZ_LIST}${id}/modules/`, data, { timeout: 10000 });
};

/**
 *Update Quiz Question
 *
 * @param {object} body data
 * @returns api response
 */

export const _updateQuizQuestion = (data, id) => {
  return API.patch(`learn/questions/${id}/`, data, { timeout: 20000 });
};

/**
 *Update QUIZ
 *
 * @param {object} body data
 * @returns api response
 */

export const _updateQuiz = (data, id) => {
  return API.patch(`${URLS.QUIZ_LIST}${id}/`, data, { timeout: 10000 });
};

/**
 *Delete Quiz CHOICE
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteQuizChoice = (choiceId) => {
  return API.delete(`learn/questions/multiple-choice/${choiceId}/delete/`, {
    timeout: 10000,
  });
};
/**
 *Attempt QUIZ
 *
 * @param {object} body data
 * @returns api response
 */

export const _attemptQuiz = (data) => {
  return API.post(`${URLS.QUIZ_LIST}user-quiz-stats/`, data, {
    timeout: 10000,
  });
};

/**
 * Get all Quizzes Paths
 *
 * @returns api response
 */
export const _getAllQuizPaths = (token) => {
  return API.get(`${URLS.QUIZ_LIST}`, {
    headers: { Authorization: `Token ` + `${token}` },
  });
};

/**
 * GET  DELETEDQUIZZES
 *
 * @param {object} body data
 * @returns api response
 */

export const _getDeletedQuiz = async (
  search,
  startDate,
  endDate,
  createdby,
  category,
  page
) => {
  if (page) {
    return API.get(`${URLS.GET_DELETED_QUIZZES}?&page=${page}`, {
      timeout: 10000,
    });
  }
  if (category) {
    return API.get(
      `${URLS.GET_DELETED_QUIZZES}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}&category=${category}`,
      { timeout: 10000 }
    );
  } else {
    return API.get(
      `${URLS.GET_DELETED_QUIZZES}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&created_by=${createdby}`,
      { timeout: 10000 }
    );
  }
};

/**
 *QUIZZES DELETE forever
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteQuizForever = async (row) => {
  return API.delete(`${URLS.GET_DELETED_QUIZZES}${row.id}`, { timeout: 10000 });
};
/**
 *QUIZ restore
 *
 * @param {object} body data
 * @returns api response
 */

export const _quizDeletedRestore = async (row) => {
  return API.patch(`${URLS.GET_DELETED_QUIZZES}${row.id}/restore/`);
};
/**
 *QUIZ MULTIPLE restore
 *
 * @param {object} body data
 * @returns api response
 */

export const _restoreMultipleQuiz = async (row) => {
  return API.patch(`${URLS.RESTORE_MULTIPLE_QUIZZES}`, {
    quiz_ids: row,
  });
};
/**
 *QUIZ MULTIPLE delete
 *
 * @param {object} body data
 * @returns api response
 */

export const _deleteMultipleQuiz = async (row) => {
  return API.delete(`${URLS.DELETED_MULTIPLE_QUIZZES}`, {
    data: { quiz_ids: row },
  });
};

export const _deleteNextQuiz = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _deletePreviousQuiz = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 * support contact details *
 *  body data
 * @returns api response
 */

export const _getSupportContact = async () => {
  return API.get(`${URLS.SUPPORT_DETAILS}`, { timeout: 20000 });
};

/**
 * GET MANAGER contact details *
 *
 * @returns api response
 */

export const _getManagerContactManager = async () => {
  return API.get(`${URLS.MANAGER_CONTACT}`, { timeout: 20000 });
};

/**
 * edit manager contact details *
 *  body data
 * @returns api response
 */

export const _editManagerContactManager = async (id, formData) => {
  return API.patch(`${URLS.MANAGER_CONTACT}${id}/`, formData, {
    timeout: 20000,
  });
};

/**
 * Create manager contact  *
 *  body data
 * @returns api response
 */

export const _createManagerContactManager = async (data) => {
  return API.post(`${URLS.MANAGER_CONTACT}`, data, { timeout: 20000 });
};

/**
 * Delete manager contact  *
 *  body data
 * @returns api response
 */

export const _deleteManagerContactManager = async (id) => {
  return API.delete(`${URLS.MANAGER_CONTACT}${id}/`, { timeout: 20000 });
};

/**
 * resend email *
 *  body data
 * @returns api response
 */

export const _resendEmail = async (data) => {
  return API.post(
    `${URLS.RESEND_INVITE}`,
    { user_ids: data },
    { timeout: 20000 }
  );
};

export const _getWorkElement = async () => {
  return true;
};

export const _addWorkElement = async () => {
  return true;
};
export const _removeWorkElement = async () => {
  return true;
};
export const _updateWorkElement = async () => {
  return true;
};
// Learning Paths

export const _getManagerLearningPathList = async (
  search,
  startDate,
  endDate,
  createdby,
  page,
  category
) => {
  // return API.get(`${URLS.GET_LPS_MANAGER}?is_published=True&page=${page}`,{timeout:20000});
  let url = `${URLS.GET_LPS_MANAGER}?is_published=True&search=${
    search ? search : ""
  }&created_date_after=${startDate ? startDate : ""}&created_date_before=${
    endDate ? endDate : ""
  }&page=${page}&created_by=${createdby ? createdby : ""}`;
  if (category) {
    url += `&category=${category ? category : ""}`;
  }
  return API.get(url, { timeout: 20000 });
};

export const _getManagerDraftLearningPaths = (
  search,
  startDate,
  endDate,
  publish,
  createdby,
  page,
  category
) => {
  let url = `${URLS.GET_LPS_MANAGER}?is_published=False&search=${
    search ? search : ""
  }&created_date_after=${startDate ? startDate : ""}&created_date_before=${
    endDate ? endDate : ""
  }&page=${page ? page : ""}&created_by=${createdby ? createdby : ""}`;
  if (category) {
    url += `&category=${category ? category : ""}`;
  }
  return API.get(url, { timeout: 20000 });
};

export const _getManagerLearningPath = (id) => {
  return API.get(`${URLS.GET_LPS_MANAGER}${id}/`, { timeout: 20000 });
};

export const _getDeletedLearningPaths = (search, startDate, endDate, page) => {
  if (page) {
    // return API.get(`${URLS.GET_DELETED_LPS}?page=${page}`,{timeout:20000});
    return API.get(
      `${URLS.GET_DELETED_LPS}?search=${
        search ? search : ""
      }&created_date_after=${startDate ? startDate : ""}&created_date_before=${
        endDate ? endDate : ""
      }&page=${page}`,
      { timeout: 20000 }
    );
  }
  return API.get(
    `${URLS.GET_DELETED_LPS}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}`,
    { timeout: 20000 }
  );
};

export const _deleteLearningPath = async (row) => {
  return API.delete(`${URLS.GET_LPS_MANAGER}${row.id}/`, { timeout: 20000 });
};

export const _permanentlyDeleteLearningPath = async (row) => {
  return API.delete(`${URLS.GET_DELETED_LPS}${row.id}/`, { timeout: 20000 });
};

export const _permanentlyBulkDeleteLearningPath = async (row) => {
  return API.delete(
    `${URLS.GET_DELETED_LPS}multiple-delete-lp/`,
    { data: row },
    { timeout: 20000 }
  );
};

export const _restoreLearningPath = async (row) => {
  return API.patch(`${URLS.DEL_DELETED_LPS}${row.id}/restore-lp/`, {
    timeout: 10000,
  });
};

export const _restoreBulkLearningPath = async (row) => {
  return API.patch(`${URLS.GET_DELETED_LPS}multiple-restore-lp/`, row, {
    timeout: 20000,
  });
};

export const _nextAvailableLearningPath = async (url) => {
  return API.get(`${url}`, { timeout: 20000 });
};

export const _previousAvailableLearningPath = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 *Draft Learning Path PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextDraftLearningPath = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Draft Learning Path PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousDraftLearningPath = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 *Deleted LP PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextDeletedLP = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Admin LP PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousDeletedLP = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _addLearningPaths = (data) => {
  return API.post(`${URLS.GET_LPS_MANAGER}`, data, { timeout: 10000 });
};

export const _updateLearningPaths = (data, id) => {
  return API.patch(`${URLS.GET_LPS_MANAGER}${id}/`, data, { timeout: 10000 });
};
export const _cloneLearningPath = async (id) => {
  return API.get(`${URLS.GET_LPS_EXTEND}${id}/clone/`, { timeout: 20000 });
};

export const _getAdminLearningPathsList = async (
  search,
  startDate,
  endDate,
  page
) => {
  if (page) {
    // return API.get(`${URLS.ADMIN_COURSE}?page=${page}`,{timeout:20000});
    return API.get(
      `${URLS.ADMIN_COURSE}?search=${search ? search : ""}&created_date_after=${
        startDate ? startDate : ""
      }&created_date_before=${endDate ? endDate : ""}&page=${page}`,
      { timeout: 20000 }
    );
  } else if (search || startDate || endDate) {
    return API.get(
      `${URLS.ADMIN_COURSE}?search=${search}&created_date_after=${startDate}&created_date_before=${endDate}&is_published=True`,
      { timeout: 10000 }
    );
  } else {
    return API.get(`${URLS.ADMIN_COURSE}?is_published=True`, {
      timeout: 20000,
    });
  }
};

/**
 *Admin Available LP PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextAdminLp = async (url) => {
  return API.get(`${url}`, { timeout: 20000 });
};
/**
 *Admin Available Lp PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousAdminLp = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _getAdminLp = (id) => {
  return API.get(`${URLS.ADMIN_COURSE}${id}/`, { timeout: 20000 });
};

/**
 * LP List Learner
 *
 * @returns api response
 */
export const _getLearnerLearningPath = (search, status, page) => {

  if (page) {
    return API.get(
      `${URLS.GET_LEARNINGPATH_LEARNER}?search=${search ? search : ""}&status=${
        status ? status : ""
      }&page=${page ? page : ""}`,
      { timeout: 20000 }
    );
  } else {
    return API.get(
      `${URLS.GET_LEARNINGPATH_LEARNER}?search=${search ? search : ""}&status=${
        status ? status : ""
      }`,
      { timeout: 20000 }
    );
  }
};
/**
 *lEARNER LP PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _learnerPaginationLearningPathNext = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/////////////////////
/**
 * LP List Learner
 *
 * @returns api response
 */
export const _getLearnerLearningPathComplete = (search, status, page) => {
  if (page) {
    return API.get(
      `${URLS.GET_LEARNINGPATH_LEARNER}?search=${
        search ? search : ""
      }&status=completed&page=${page}`,
      { timeout: 20000 }
    );
  }
  return API.get(
    `${URLS.GET_LEARNINGPATH_LEARNER}?search=${search}&status=completed`,
    { timeout: 20000 }
  );
};
/**
 *lEARNER LP PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _learnerPaginationLearningPathCompleteNext = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
export const _learnerPaginationLearningPathCompletePrevious = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _getLearnerLearningPathFail = (search, status, page) => {
  if (page) {
    return API.get(
      `${URLS.GET_LEARNINGPATH_LEARNER}?search=${
        search ? search : ""
      }&status=failed&page=${page}`,
      { timeout: 20000 }
    );
  }
  return API.get(
    `${URLS.GET_LEARNINGPATH_LEARNER}?search=${search}&status=failed`,
    { timeout: 20000 }
  );
};
/**
 *lEARNER LP PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _learnerPaginationLearningPathFailNext = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
export const _learnerPaginationLearningPathFailPrevious = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

//Without Pagination

export const _getCoursesWithOutPagination = () => {
  return API.get(`${URLS.GET_LPS_MANAGER}courses-list/`, { timeout: 20000 });
};
export const _getQuizzesWithOutPagination = () => {
  return API.get(`${URLS.GET_LPS_MANAGER}quizzes-list`, { timeout: 20000 });
};
// export const _getQuizzesWithOutPagination = () => {
//     return API.get(`${URLS.QUIZ_LIST}`, {timeout:20000});
// };
// export const _getCoursesWithOutPagination = () => {
//     return API.get(`${URLS.GET_COURSES_MANAGER}`, {timeout:20000});
// };
export const _getMembersWithOutPagination = () => {
  return API.get(`${URLS.GET_LPS_MANAGER}members-list/`, { timeout: 20000 });
};
export const _getGroupsWithOutPagination = () => {
  return API.get(`${URLS.GET_LPS_MANAGER}groups-list/`, { timeout: 20000 });
};
export const _getClassesWithOutPagination = () => {
  return API.get(`${URLS.GET_LPS_CLASSES}list-classes`, { timeout: 20000 });
};
//Assign learning path

/**
 * Assign lp
 *
 * @returns api response
 */
export const _assignLearningPath = (body, userId) => {
  return API.post(`${URLS.ASSIGN_LP}${userId}/` + "assignments/", body, {
    timeout: 10000,
  });
};

/**
 * Update Assign course
 *
 * @returns api response
 */
export const _updateAssignLearning = (body, userId, assignid) => {
  return API.patch(
    `${URLS.ASSIGN_LP}${userId}/assignments/${assignid}/`,
    body,
    { timeout: 10000 }
  );
};

/**
 * Update Assign course
 *
 * @returns api response
 */
export const _deleteAssignLearning = (userId, assignid) => {
  return API.delete(`${URLS.ASSIGN_LP}${userId}/assignments/${assignid}/`, {
    timeout: 10000,
  });
};
/**
 * GEt course assignments
 *
 * @returns api response
 */
export const _getLearningPathAssignments = (userId,platform) => {
  if(platform){
    return API.get(`${URLS.ASSIGN_LP}${userId}/assignments/?assignment_type=${platform}`, {
      timeout: 10000,
    });
  }else{
    return API.get(`${URLS.ASSIGN_LP}${userId}/assignments/?assignment_type=enga`, {
      timeout: 10000,
    });
  }
};
/**
 * GEt course assignments by id
 *
 * @returns api response
 */
export const _getLearningPathAssignmentsById = (courseId, userId) => {
  return API.get(`${URLS.ASSIGN_LP}${userId}/assignments/${courseId}/`, {
    timeout: 10000,
  });
};

/**
 *Update COURSE Module
 *
 * @param {object} body data
 * @returns api response
 */

export const _learnerPaginationLearningPathPrevious = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
export const _updateLpModule = (data, id) => {
  return API.patch(`${URLS.GET_LP}${id}/elements/`, data, { timeout: 20000 });
};
export const _getNodesLpList = (id, type) => {
  return API.get(
    `${type ? URLS.ADMIN_LPS : URLS.GET_LPS_MANAGER}${id}/elements/`,
    { timeout: 20000 }
  );
};
export const _getElementByIdLp = (id) => {
  return API.patch(`${URLS.GET_COURSES_MANAGER}${id}/modules/`, data, {
    timeout: 20000,
  });
};
export const _deleteElementByIdLp = (id, nodeId) => {
  return API.delete(`${URLS.GET_LP}${id}/elements/${nodeId}`, {
    timeout: 20000,
  });
};

export const _getManagerLearningPathDetail = (id) => {
  return API.get(`${URLS.GET_LPS_MANAGER}${id}/`, { timeout: 20000 });
};

export const _getLearnerAssignedAssignments = (userId) => {
  return API.get(`${URLS.ASSIGN_LP}${userId}/` + "assignments/", {
    timeout: 10000,
  });
};

/**
 *ADD survey
 *
 * @param {object} body data
 * @returns api response
 */

export const _addSurvey = (data) => {
  return API.post(`${URLS.CREATE_SURVEY}`, data, { timeout: 10000 });
};

/**
 *Update survey
 *
 * @param {object} body data
 * @returns api response
 */

export const _updateSurveys = (data, id) => {
  return API.patch(`${URLS.GET_SURVEYS}${id}/`, data, { timeout: 10000 });
};

export const _getManagerSurvey = (id) => {
  return API.get(`${URLS.GET_COURSES_MANAGER}${id}/`, { timeout: 20000 });
};

export const _getManagerSurveys = (id) => {
  return API.get(`${URLS.UPDATE_SURVEY_QUESTIONS}${id}/`, { timeout: 20000 });
};

/**
 *Update Survey Question
 *
 * @param {object} body data
 * @returns api response
 */

export const _updateSurveyQuestion = (data, id) => {
  return API.patch(`${URLS.UPDATE_SURVEY_QUESTIONS}${id}/`, data, {
    timeout: 20000,
  });
};

/**
 *List course categories
 *
 * @returns api response
 */

 export const _getCoarseCategories = () => {
  return API.get(`${URLS.GET_COURSE_CATEGORIES}`, {
    timeout: 20000,
  });
};



/**
 * GET demo queations
 *
 * @param {object} body data
 * @returns api response
 */

export const _getDemographicQuestionsList = async () => {
  return API.get(`${URLS.GET_DEMO_QS_LIST}`, { timeout: 20000 });
};

export const _getManagerLearningPathNodes = (id, type) => {

  return API.get(
    `${type ? URLS.ADMIN_LPS : URLS.GET_LPS_MANAGER}${id}/nodes/`,
    { timeout: 20000 }
  );
};
export const _getManagerLearningPathNodesDetail = (id, elementId, type) => {
  return API.get(
    `${type ? URLS.ADMIN_LPS : URLS.GET_LPS_MANAGER}${id}/nodes/${elementId}/`,
    { timeout: 20000 }
  );
};
export const _getManagerLearningPathLearners = (id, elementId) => {
  return API.get(`${URLS.GET_LPS_MANAGER}${id}/nodes/${elementId}/learners/`, {
    timeout: 20000,
  });
};
export const _getManagerLearningPathLearnersWithPage = (
  id,
  elementId,
  page,
  type
) => {
  return API.get(
    `${
      type ? URLS.ADMIN_LPS : URLS.GET_LPS_MANAGER
    }${id}/nodes/${elementId}/learners/?page=${page}`,
    { timeout: 20000 }
  );
};

export const _nextgetManagerLearningPathLearnersWithPage = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _prevgetManagerLearningPathLearnersWithPage = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/////

export const _getManagerLearningPathLearnersAll = (id) => {
  return API.get(`${URLS.GET_LPS_EXTEND}${id}/learner-assignments/`, {
    timeout: 20000,
  });
};
export const _getManagerLearningPathLearnersAllWithPage = (id, page) => {
  return API.get(
    `${URLS.GET_LPS_EXTEND}${id}/learner-assignments/?page=${page}`,
    { timeout: 20000 }
  );
};

export const _nextgetManagerLearningPathLearnersAllWithPage = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
export const _prevgetManagerLearningPathLearnersAllWithPage = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _extendLearningPath = (body, id, assimentId) => {
  return API.patch(
    `${URLS.GET_LPS_EXTEND}${id}/learner-assignments/${assimentId}/extend/`,
    body,
    { timeout: 10000 }
  );
};

export const _extendLearningPathLearner = (body, userId) => {
  return API.post(`${URLS.ASSIGN_LP}${userId}/` + "assignments/", body, {
    timeout: 10000,
  });
};
export const _getManagerLearningPathLearnersAssigment = (id, elementId) => {
  return API.get(`${URLS.GET_LPS_MANAGER}${id}/nodes/${elementId}/learners/`, {
    timeout: 20000,
  });
};

////
export const _getLearnerLearningPathNodes = (id) => {
  return API.get(`${URLS.GET_LEARNINGPATH_LEARNER}${id}/nodes/`, {
    timeout: 40000,
  });
};
export const _getLearnerLearningPathNodesDetail = (id, elementId) => {
  return API.get(`${URLS.GET_LEARNINGPATH_LEARNER}${id}/nodes/${elementId}/`, {
    timeout: 20000,
  });
};

export const _getLearnerLearningPathDetail = (id) => {
  return API.get(`${URLS.GET_LEARNINGPATH_LEARNER}${id}/`, { timeout: 20000 });
};
export const _submitCourseLearner = (nodeId, moduleId) => {
  return API.post(
    `${URLS.SUBMIT_LEARNER_NODE}nodes/${nodeId}/course-module/${moduleId}/course-submit/`,
    { timeout: 20000 }
  );
};
export const _submitQuizLearner = (data, nodeId) => {
  return API.post(
    `${URLS.SUBMIT_LEARNER_NODE}nodes/${nodeId}/quiz-submit/`,
    data,
    { timeout: 20000 }
  );
};
export const _startAssignmentLearner = (nodeId) => {
  return API.get(`${URLS.SUBMIT_LEARNER_NODE}nodes/${nodeId}/`, {
    timeout: 20000,
  });
};
export const _getLearnerLearningCertificates = () => {
  return API.get(`${URLS.SUBMIT_LEARNER_NODE}learning-path/certificates/`, {
    timeout: 20000,
  });
};
export const _getLearnerLearningCertificateDetail = (id) => {
  return API.get(
    `${URLS.SUBMIT_LEARNER_NODE}learning-path/certificates/${id}/`,
    { timeout: 20000 }
  );
};

export const _getLearnerCourseCertificateDetail = (id) => {
  return API.get(`${URLS.GET_COURSE_CERTIFICATE_LEARNER}certificates/${id}/`, {
    timeout: 20000,
  });
};

export const _getLearnerAssignmentCourses = (page) => {
  if (page) {
    // return API.get(`${URLS.GET_COURSES_LEARNER}?page=${page}`,{timeout:20000});
    return API.get(`${URLS.GET_ASSIGNMENTS}courses/?page=${page}/`, {
      timeout: 20000,
    });
  }
  return API.get(`${URLS.GET_ASSIGNMENTS}courses/`, { timeout: 20000 });
};
/**
 *lEARNER Course PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _learnerPaginationAssignmentCourseNext = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
/**
 *Learner Course PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _learnerPaginationAssignmentCoursePrevious = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _getLearnerLearningPathAssigments = () => {
  return API.get(`${URLS.GET_ASSIGNMENTS}learning-path/`, { timeout: 20000 });
};
export const _getLearnerLearningPathAssigmentsWithPage = (page) => {
  return API.get(`${URLS.GET_ASSIGNMENTS}learning-path/?page=${page}`, {
    timeout: 20000,
  });
};

export const _nextLearnerLearningPathAssigments = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
export const _prevLearnerLearningPathAssigments = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

////////////
export const _getLearnerLearningPathReportDetail = () => {
  return API.get(`${URLS.GET_ASSIGNMENTS_REPORT}`, { timeout: 20000 });
};
export const _getLearnerLearningPathReportDetailWithPage = (page) => {
  return API.get(`${URLS.GET_ASSIGNMENTS_REPORT}?page=${page}`, {
    timeout: 20000,
  });
};

export const _nextLearnerLearningPathReportDetail = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
export const _prevLearnerLearningPathReportDetail = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

/**
 * COURSES ADMIN LIST
 *
 * @param {object} body data
 * @returns api response
 */

export const _getAdminLPsList = async (search, startDate, endDate, page) => {
  let url = `${URLS.ADMIN_LPS}?search=${
    search ? search : ""
  }&created_date_after=${startDate ? startDate : ""}&created_date_before=${
    endDate ? endDate : ""
  }`;
  if (page) {
    url += `&page=${page}`;
  }
  // return API.get(`${URLS.ADMIN_COURSE}?page=${page}`,{timeout:20000});
  return API.get(url, { timeout: 20000 });
};

/**
 *Admin Available Course PAGINATION Next
 *
 * @param {object} body data
 * @returns api response
 */
export const _nextAdminLpPagination = async (url) => {
  return API.get(`${url}`, { timeout: 20000 });
};
/**
 *Admin Available Course PAGINATION PREVIOUS
 *
 * @param {object} body data
 * @returns api response
 */

export const _previousAdminLpPagination = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};

export const _getAdminLpDetail = (id) => {
  return API.get(`${URLS.ADMIN_LPS}${id}/`, { timeout: 20000 });
};

//////////////////////////////////////////////////////

export const _getLearnerCourseCertificates = () => {
  return API.get(`${URLS.GET_COURSE_CERTIFICATE_LEARNER}certificates/`, {
    timeout: 20000,
  });
};

export const _nextLearnerCourseCertificates = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
export const _prevLearnerCourseCertificates = async (url) => {
  return API.get(`${url}`, { timeout: 10000 });
};
export const _getLearnerCourseCertificateWithPage = (page) => {
  return API.get(
    `${URLS.GET_COURSE_CERTIFICATE_LEARNER}certificates/?page=${page}`,
    { timeout: 20000 }
  );
};
