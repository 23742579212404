import {createAction, handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {
    _getLearnerCourses,
    _learnerPaginationCourseNext,
    _learnerPaginationCoursePrevious,
    _getLearnerCourseById,
    _getLearnerCourseModules,
    _getLearnerCourseModuleById,
    _getLearnerCourseCompleteModule,
    _learnerCourseTestAttempt,
    _patchLearnerCourseModules,
    _attemptQuiz

} from '~shared/httpService/api';

import { ToastContainer, toast } from 'react-toastify';


const initialState = {
    learner_courses_list: { loading: false, response: {}, hasError: false, error: {} },
    learner_course: { loading: false, response: {}, hasError: false, error: {} },
    learner_course_modules_list: { loading: false, response: {}, hasError: false, error: {} },
    learner_course_module: { loading: false, response: {}, hasError: false, error: {} },
    learner_course_complete_module: { loading: false, response: {}, hasError: false, error: {} },
    learner_course_test_attempt: { loading: false, response: {}, hasError: false, error: {} },
    patch_learner_course_module:{ loading: false, response: {}, hasError: false, error: {} },
    attempted_learner_quiz:{ loading: false, response: {}, hasError: false, error: {} },

};

export const getLearnerCoursesStart = createAction(ActionTypes.GET_LEARNER_COURSES);
export const getLearnerCoursesSuccess = createAction(ActionTypes.GET_LEARNER_COURSES_SUCCESS, response => response);
export const getLearnerCoursesError = createAction(ActionTypes.GET_LEARNER_COURSES_ERROR, error => error);

export const getLearnerCoursesList = (search, status,index) => (dispatch) => {
    dispatch(getLearnerCoursesStart());
    return _getLearnerCourses(search, status,index).then((response) => {
        dispatch(getLearnerCoursesSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerCoursesError(error));
    });
};


export const getLearnerCompletedCoursesStart = createAction(ActionTypes.GET_LEARNER_COMPLETED_COURSES);
export const getLearnerCompletedCoursesSuccess = createAction(ActionTypes.GET_LEARNER_COMPLETED_COURSES_SUCCESS, response => response);
export const getLearnerCompletedCoursesError = createAction(ActionTypes.GET_LEARNER_COMPLETED_COURSES_ERROR, error => error);

export const getLearnerCompletedCoursesList = (search,index) => (dispatch) => {
    dispatch(getLearnerCompletedCoursesStart());
    return _getLearnerCourses(search, 'completed',index).then((response) => {
        dispatch(getLearnerCompletedCoursesSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerCompletedCoursesError(error));
    });
};


export const getLearnerOngoingCoursesStart = createAction(ActionTypes.GET_LEARNER_ONGOING_COURSES);
export const getLearnerOngoingCoursesSuccess = createAction(ActionTypes.GET_LEARNER_ONGOING_COURSES_SUCCESS, response => response);
export const getLearnerOngoingCoursesError = createAction(ActionTypes.GET_LEARNER_ONGOING_COURSES_ERROR, error => error);

export const getLearnerOngoingCoursesList = (search,index) => (dispatch) => {
    dispatch(getLearnerOngoingCoursesStart());
    return _getLearnerCourses(search, '', index).then((response) => {
        dispatch(getLearnerOngoingCoursesSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerOngoingCoursesError(error));
    });
};

export const getLearnerFailedCoursesStart = createAction(ActionTypes.GET_LEARNER_FAILED_COURSES);
export const getLearnerFailedCoursesSuccess = createAction(ActionTypes.GET_LEARNER_FAILED_COURSES_SUCCESS, response => response);
export const getLearnerFailedCoursesError = createAction(ActionTypes.GET_LEARNER_FAILED_COURSES_ERROR, error => error);

export const getLearnerFailedCoursesList = (search,index) => (dispatch) => {
    dispatch(getLearnerFailedCoursesStart());
    return _getLearnerCourses(search, 'failed', index).then((response) => {
        dispatch(getLearnerFailedCoursesSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerFailedCoursesError(error));
    });
};


export const paginationLearnerCourseStart = createAction(ActionTypes.LEARNER_COURSE_PAGINATION_NEXT);
export const paginationLearnerCourseSuccess = createAction(ActionTypes.LEARNER_COURSE_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationLearnerCourseError = createAction(ActionTypes.LEARNER_COURSE_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationLearnerCourse = (url) => (dispatch) => {
    dispatch(paginationLearnerCourseStart());
    return _learnerPaginationCourseNext(url).then((response) => {
        dispatch(paginationLearnerCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearnerCourseError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousLearnerCourseStart = createAction(ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS);
export const paginationPreviousLearnerCourseSuccess = createAction(ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearnerCourseError = createAction(ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationLearnerCourse = (url) => (dispatch) => {
    dispatch(paginationPreviousLearnerCourseStart());
    return _learnerPaginationCoursePrevious(url).then((response) => {
        dispatch(paginationPreviousLearnerCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearnerCourseError(error));
        toast.error('Something went wrong')

    });
};
export const getLearnerCourseByIdStart = createAction(ActionTypes.GET_LEARNER_COURSE_BY_ID);
export const getLearnerCourseByIdSuccess = createAction(ActionTypes.GET_LEARNER_COURSE_BY_ID_SUCCESS, response => response);
export const getLearnerCourseByIdError = createAction(ActionTypes.GET_LEARNER_COURSE_BY_ID_ERROR, error => error);

export const getLearnerCourseById = (id,callback) => (dispatch) => {
    dispatch(getLearnerCourseByIdStart());
    return _getLearnerCourseById(id).then((response) => {
        dispatch(getLearnerCourseByIdSuccess(response));
        if(response){
            callback(response)
        }
    }).catch((error) => {
        dispatch(getLearnerCourseByIdError(error));
    });
};
export const getLearnerCourseModulesStart = createAction(ActionTypes.GET_LEARNER_COURSE_MODULES_LIST);
export const getLearnerCourseModulesSuccess = createAction(ActionTypes.GET_LEARNER_COURSE_MODULES_LIST_SUCCESS, response => response);
export const getLearnerCourseModulesError = createAction(ActionTypes.GET_LEARNER_COURSE_MODULES_LIST_ERROR, error => error);

export const getLearnerCourseModules = (id) => (dispatch) => {
    dispatch(getLearnerCourseModulesStart());
    return _getLearnerCourseModules(id).then((response) => {
        dispatch(getLearnerCourseModulesSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerCourseModulesError(error));
        toast.error('Something went wrong')
    });
};

export const getLearnerCourseModulesByIdStart = createAction(ActionTypes.GET_LEARNER_COURSE_MODULE_BY_ID);
export const getLearnerCourseModulesByIdSuccess = createAction(ActionTypes.GET_LEARNER_COURSE_MODULE_BY_ID_SUCCESS, response => response);
export const getLearnerCourseModulesByIdError = createAction(ActionTypes.GET_LEARNER_COURSE_MODULE_BY_ID_ERROR, error => error);

export const getLearnerCourseModulesById = (id) => (dispatch) => {
    dispatch(getLearnerCourseModulesByIdStart());
    return _getLearnerCourseModuleById(id).then((response) => {
        dispatch(getLearnerCourseModulesByIdSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerCourseModulesByIdError(error));
    });
};

export const getLearnerCourseCompleteModuleStart = createAction(ActionTypes.GET_LEARNER_COURSE_MODULE_COMPLETE);
export const getLearnerCourseCompleteModuleSuccess = createAction(ActionTypes.GET_LEARNER_COURSE_MODULE_COMPLETE_SUCCESS, response => response);
export const getLearnerCourseCompleteModuleError = createAction(ActionTypes.GET_LEARNER_COURSE_MODULE_COMPLETE_ERROR, error => error);

export const getLearnerCourseCompleteModule = (id) => (dispatch) => {
    dispatch(getLearnerCourseCompleteModuleStart());
    return _getLearnerCourseCompleteModule(id).then((response) => {
        dispatch(getLearnerCourseCompleteModuleSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerCourseCompleteModuleError(error));
    });
};
export const LearnerCourseTestAttemptStart = createAction(ActionTypes.LEARNER_COURSE_TEST_ATTEMPT);
export const LearnerCourseTestAttemptSuccess = createAction(ActionTypes.LEARNER_COURSE_TEST_ATTEMPT_SUCCESS, response => response);
export const LearnerCourseTestAttemptError = createAction(ActionTypes.LEARNER_COURSE_TEST_ATTEMPT_ERROR, error => error);

export const LearnerCourseTestAttempt = (id) => (dispatch) => {
    dispatch(LearnerCourseTestAttemptStart());
    return _learnerCourseTestAttempt(id).then((response) => {
        dispatch(LearnerCourseTestAttemptSuccess(response));
    }).catch((error) => {
        dispatch(LearnerCourseTestAttemptError(error));
    });
};
export const patchLearnerCourseModuleStart = createAction(ActionTypes.PATCH_LEARNER_COURSE_MODULE_COMPLETE);
export const patchLearnerCourseModuleSuccess = createAction(ActionTypes.PATCH_LEARNER_COURSE_MODULE_COMPLETE_SUCCESS, response => response);
export const patchLearnerCourseModuleError = createAction(ActionTypes.PATCH_LEARNER_COURSE_MODULE_COMPLETE_ERROR, error => error);

export const patchLearnerCourseModuleComplete = (id,moduleId,callback,callbackError) => (dispatch) => {
    dispatch(patchLearnerCourseModuleStart());
    return _patchLearnerCourseModules(id,moduleId).then((response) => {

            callback(response)

        dispatch(patchLearnerCourseModuleSuccess(response));
    }).catch((error) => {
        callbackError(callbackError)
        dispatch(patchLearnerCourseModuleError(error));
    });
};

export const attemptLearnerQuizStart = createAction(ActionTypes.ATTEMPT_QUIZ);
export const attemptLearnerQuizSuccess = createAction(ActionTypes.ATTEMPT_QUIZ_SUCCESS, response => response);
export const attemptLearnerQuizError = createAction(ActionTypes.ATTEMPT_QUIZ_ERROR, error => error);

export const attemptQuiz = (data,callback,callbackError) => (dispatch) => {
    dispatch(attemptLearnerQuizStart());
    return _attemptQuiz(data).then((response) => {

        callback(response)

        dispatch(attemptLearnerQuizSuccess(response));
    }).catch((error) => {
        callbackError(callbackError)
        dispatch(attemptLearnerQuizError(error));
    });
};



const reducer = handleActions({
    //COURSE_DETAILS
    [ActionTypes.GET_LEARNER_COURSES]: (state) => ({
        ...state, learner_courses_list: {
            ...state.learner_courses_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSES_SUCCESS]: (state, action) => ({
        ...state,
        learner_courses_list: {
            ...state.learner_courses_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSES_ERROR]: (state, action) => ({
        ...state,
        learner_courses_list: {
            ...state.learner_courses_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COMPLETED_COURSES]: (state) => ({
        ...state, learner_completed_courses_list: {
            ...state.learner_completed_courses_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COMPLETED_COURSES_SUCCESS]: (state, action) => ({
        ...state,
        learner_completed_courses_list: {
            ...state.learner_completed_courses_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COMPLETED_COURSES_ERROR]: (state, action) => ({
        ...state,
        learner_completed_courses_list: {
            ...state.learner_completed_courses_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    [ActionTypes.GET_LEARNER_ONGOING_COURSES]: (state) => ({
        ...state, learner_ongoing_courses_list: {
            ...state.learner_ongoing_courses_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_ONGOING_COURSES_SUCCESS]: (state, action) => ({
        ...state,
        learner_ongoing_courses_list: {
            ...state.learner_ongoing_courses_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_ONGOING_COURSES_ERROR]: (state, action) => ({
        ...state,
        learner_ongoing_courses_list: {
            ...state.learner_ongoing_courses_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    [ActionTypes.GET_LEARNER_FAILED_COURSES]: (state) => ({
        ...state, learner_failed_courses_list: {
            ...state.learner_failed_courses_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_FAILED_COURSES_SUCCESS]: (state, action) => ({
        ...state,
        learner_failed_courses_list: {
            ...state.learner_failed_courses_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_FAILED_COURSES_ERROR]: (state, action) => ({
        ...state,
        learner_failed_courses_list: {
            ...state.learner_failed_courses_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //    Learner Course Pagination Next
    [ActionTypes.LEARNER_COURSE_PAGINATION_NEXT]: (state) => ({
        ...state,
        learner_courses_list: {
            ...state.learner_courses_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_COURSE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        learner_courses_list: {
            ...state.learner_courses_list,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_COURSE_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        learner_courses_list: {
            ...state.learner_courses_list,
            loading: false, hasError: false, error: {}
        }
    }),
    //    Learner Course Pagination Previous
    [ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        learner_courses_list: {
            ...state.learner_courses_list,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        learner_courses_list: {
            ...state.learner_courses_list,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LEARNER_COURSE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        learner_courses_list: {
            ...state.learner_courses_list,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //learner course by id
    [ActionTypes.GET_LEARNER_COURSE_BY_ID]: (state) => ({
        ...state, learner_course: {
            ...state.learner_course,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSE_BY_ID_SUCCESS]: (state, action) => ({
        ...state,
        learner_course: {
            ...state.learner_course,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSE_BY_ID_ERROR]: (state, action) => ({
        ...state,
        learner_course: {
            ...state.learner_course,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //learner course modules
    [ActionTypes.GET_LEARNER_COURSE_MODULES_LIST]: (state) => ({
        ...state, learner_course_modules_list: {
            ...state.learner_course_modules_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSE_MODULES_LIST_SUCCESS]: (state, action) => ({
        ...state,
        learner_course_modules_list: {
            ...state.learner_course_modules_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSE_MODULES_LIST_ERROR]: (state, action) => ({
        ...state,
        learner_course_modules_list: {
            ...state.learner_course_modules_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //learner course modules by id
    [ActionTypes.GET_LEARNER_COURSE_MODULE_BY_ID]: (state) => ({
        ...state, learner_course_module: {
            ...state.learner_course_module,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSE_MODULE_BY_ID_SUCCESS]: (state, action) => ({
        ...state,
        learner_course_module: {
            ...state.learner_course_module,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSE_MODULE_BY_ID_ERROR]: (state, action) => ({
        ...state,
        learner_course_module: {
            ...state.learner_course_module,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //learner course complete module
    [ActionTypes.GET_LEARNER_COURSE_MODULE_COMPLETE]: (state) => ({
        ...state, learner_course_complete_module: {
            ...state.learner_course_complete_module,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSE_MODULE_COMPLETE_SUCCESS]: (state, action) => ({
        ...state,
        learner_course_complete_module: {
            ...state.learner_course_complete_module,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_COURSE_MODULE_COMPLETE_ERROR]: (state, action) => ({
        ...state,
        learner_course_complete_module: {
            ...state.learner_course_complete_module,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //learner course Test Attempt
    [ActionTypes.LEARNER_COURSE_TEST_ATTEMPT]: (state) => ({
        ...state, learner_course_test_attempt: {
            ...state.learner_course_test_attempt,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_COURSE_TEST_ATTEMPT_SUCCESS]: (state, action) => ({
        ...state,
        learner_course_test_attempt: {
            ...state.learner_course_test_attempt,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_COURSE_TEST_ATTEMPT_ERROR]: (state, action) => ({
        ...state,
        learner_course_test_attempt: {
            ...state.learner_course_test_attempt,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //learner patch course module
    [ActionTypes.PATCH_LEARNER_COURSE_MODULE_COMPLETE]: (state) => ({
        ...state, patch_learner_course_module: {
            ...state.patch_learner_course_module,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.PATCH_LEARNER_COURSE_MODULE_COMPLETE_SUCCESS]: (state, action) => ({
        ...state,
        patch_learner_course_module: {
            ...state.patch_learner_course_module,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.PATCH_LEARNER_COURSE_MODULE_COMPLETE_ERROR]: (state, action) => ({
        ...state,
        patch_learner_course_module: {
            ...state.patch_learner_course_module,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    //learner patch course module
    [ActionTypes.ATTEMPT_QUIZ]: (state) => ({
        ...state, attempted_learner_quiz: {
            ...state.attempted_learner_quiz,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.ATTEMPT_QUIZ_SUCCESS]: (state, action) => ({
        ...state,
        attempted_learner_quiz: {
            ...state.attempted_learner_quiz,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.ATTEMPT_QUIZ_ERROR]: (state, action) => ({
        ...state,
        attempted_learner_quiz: {
            ...state.attempted_learner_quiz,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

}, initialState);

export default reducer;
