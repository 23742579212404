import React, {useEffect, useRef, useState,useCallback} from "react";
import DataTable from "react-data-table-component";
import Tab from "react-bootstrap/Tab";
import Spinner from "react-bootstrap/Spinner";
import {
    getManagerLearningPathLearners, getManagerLearningPathLearnersWithPage,
    nextgetManagerLearningPathLearnersWithPage,
    prevgetManagerLearningPathLearnersWithPage
} from "../../../../services";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from 'react-redux';
import ScrollArea from 'react-scrollbar';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useLocation } from "react-router-dom";

function CourseSection(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const nodesLearnerResponse = useSelector(state => state?.learningpath?.nodes_learner?.response);
    const nodesLearnerLoading= useSelector(state => state?.learningpath?.nodes_learner?.loading);
    const imgRef = useRef(null);
    const [state,setState] = useState({
        data:{},
        learners:{},
        paginationItem:[]
    })
    // useEffect(()=>{
    //     dispatch(getManagerLearningPathLearnersWithPage(props?.urlId,props?.node?.id,activePage,function (resLearners){
    //     }))
    //
    // },[])

    useEffect(()=>{

        const count = nodesLearnerResponse?.count
        const perPage =12;
        const buttonsCount = Math.ceil(count/perPage);
        let ellipsisLeft = false;
        let ellipsisRight = false;
        let items =[]
        for (let i = 1; i <= Number(buttonsCount); i++) {
            if(i === activePage) {
                items.push(
                    { id: i, current: true, ellipsis: false }
                );
            }else {
                if(i < 4 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
                    items.push(
                        { id: i, current: false, ellipsis: false }
                    );
                }else if(i > 1 && i < activePage && !ellipsisLeft) {
                    items.push(
                        { id: i, current: false, ellipsis: true }
                    );
                    ellipsisLeft = true;
                }else if(i < buttonsCount && i > activePage && !ellipsisRight) {
                    items.push(
                        { id: i, current: false, ellipsis: true }
                    );
                    ellipsisRight = true;

                }
            }

        }
        setState({...state,data:props?.node,learners: nodesLearnerResponse,paginationItem:items})
        // setState({...state,paginationItem:items});

    },[nodesLearnerResponse])

    const onLoad = () =>{
        setLoading(true);

    }

    const handleNext = () => {

        if (state?.learners?.next) {


            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);

            dispatch(nextgetManagerLearningPathLearnersWithPage(state.learners?.next,function (res){
                setState({...state,learners: res})
            }))

        } else {
            return false;
        }

    }

    const handlePrevious = () => {

        if (state.learners?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            dispatch(prevgetManagerLearningPathLearnersWithPage(state.learners?.previous,function (res){
                setState({...state,learners: res})
            }))

        } else {
            return false

        }

    }
    const handleIndex = (index) =>{

        dispatch(getManagerLearningPathLearnersWithPage(props?.urlId,state?.data?.id,index,function (resLearners){
            setState({...state,learners: resLearners})
        }))

        setActivePage(index);
    }
    const columns = [
        {
            name: 'Name',
            selector: row => row.learner_name,
        },
        {
            name: 'Completed Modules',
            selector: row => row.completed_modules_count,
        },
        {
            name: 'Completed',
            selector: row => <div >{row.has_completed ?  <div className={`status-icon-show tick-show`}>

                    <i className="fa fa-check" aria-hidden="true"></i>





                </div>

                :
                <div className={`status-icon-show cross-show`}>




                    <i className="fa fa-times" aria-hidden="true"></i>


                </div>}</div>,
        },

    ];

     if(props.node && Object?.keys(props?.node)?.length ) {
         return (
             <div className="grey-bg-content-box">
                 <div className="grey-bg-content-box-header">
                     <OverlayTrigger
                         overlay={
                             <Tooltip id="InfoText">
                                 {props?.lp?.name}
                             </Tooltip>
                         }
                     >
                     <h4 className="sec-sub-head  m-b-15">{state?.data?.name?.substring(0, 120) + ' '  +  (state?.data?.name?.length > 120 ?  '...' : '') }</h4>
                     </OverlayTrigger>
                     <ul className="listbreadcrumb">
                         <OverlayTrigger
                             overlay={
                                 <Tooltip id="InfoText">
                                     {props?.lp?.name}
                                 </Tooltip>
                             }
                         >
                         <li>{props?.lp?.name?.substring(0, 60) + ' '  +  (props?.lp?.name?.length > 60 ?  '...' : '') }</li>
                         </OverlayTrigger>
                         <OverlayTrigger
                             overlay={
                                 <Tooltip id="InfoText">
                                     {state?.data?.name}
                                 </Tooltip>
                             }
                         >

                         <li>{state?.data?.name?.substring(0, 50) + ' '  +  (state?.data?.name?.length > 50 ?  '...' : '') }</li>
                         </OverlayTrigger>
                     </ul>
                     {!location?.pathname?.includes('/learning_path_admin') &&
                     <span
                         className="enrolled-students-count primary-border-rounded">{props?.node?.enrollment_percentage ? props?.node?.enrollment_percentage : '0'}% of students enrolled</span>}
                 </div>
                 <div className="grey-bg-content-box-body">
                     <div className="box-changable-wrap1">
                         <div className="course-content-rightbox-img">
                             {!loading &&
                             <Spinner animation="border" variant="danger"/>

                             }
                             <img src={props?.node?.banner_image} ref={imgRef} onLoad={onLoad} />


                         </div>
                         <p>{state?.data?.description}</p>
                         <div className="content-line-gap">&nbsp;</div>

                         <div className="content-modules-list-box">

                             <h4 className="sec-sub-head m-b-20">Modules</h4>
                             <ScrollArea
                                               speed={0.8}
                                               className="module-list-scroll"
                                               contentClassName="module-list-content"
                                               horizontal={false}
                                           >

                             <ul className="learning-module-list">
                                 {props?.node?.modules && props?.node?.modules?.length ? props?.node?.modules?.map((el, index) => {

                                         return (
                                             <li>
                                                 <span className="modules-tag-box">{index + 1}.{el?.name}</span>
                                             </li>

                                         )

                                     })
                                     :

                                     <li>
                                         <span className="modules-tag-box">No Modules</span>
                                     </li>

                                 }


                             </ul>
                             </ScrollArea>
                         </div>
                     </div>
                     <div className="content-line-gap">&nbsp;</div>
                     <div className="table-listing-wraper">
                         <div className="datatble-wrap checkbox-datatable">
                             {nodesLearnerLoading && nodesLearnerLoading ?
                                 (
                                     <>

                                             <Spinner animation="border" variant="danger"/>


                                     </>


                                 ) : (
                                     <>
                                     <h4 className="sec-sub-head  m-b-15 ml-2">Learners</h4>

                                     <DataTable
                                         columns={columns}
                                         data={state?.learners?.results}
                                         showPaginationBottom={false}
                                     />

                                   </>
                                 )}

                             {!nodesLearnerLoading && state?.learners && state?.learners && state?.learners?.count > 12 &&
                             <div className="d-flex justify-content-center">
                                 <Pagination size="sm" className="custom-pagination">
                                     <Pagination.First disabled={!state?.learners?.previous}
                                                       className={`${state?.learners?.previous ? 'paginationStyle' : ''} `}
                                                       onClick={handlePrevious}/>
                                     {state.paginationItem.map(page => {
                                         if (!page.ellipsis) {
                                             return <li key={page.id}>
                                                 <Pagination.Item
                                                     onClick={() => handleIndex(page.id)} key={page.id}
                                                     active={page.id == activePage}
                                                 >
                                                     {page.id}
                                                 </Pagination.Item>
                                             </li>
                                         } else {
                                             return <Pagination.Ellipsis></Pagination.Ellipsis>
                                         }
                                     })}
                                     <Pagination.Last disabled={!state?.learners?.next} onClick={handleNext}
                                                      className={`${state?.learners?.next ? 'paginationStyle' : ''} `}/>

                                 </Pagination>

                             </div>
                             }
                         </div>
                     </div>
                 </div>
             </div>
         );
     }else {
         return (
             <p>No Data</p>


         )

     }
}
export default CourseSection;
