import React, { useState, useEffect, useRef, useCallback } from "react";
import Pagination from "react-bootstrap/Pagination";
import { PageItem } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import DataTable from "react-data-table-component";
import {
  getMemberDetail,
  getMemberCourses,
  getMemberLP,
  deleteMemberCourses,
  deleteMemberLP,
  removeMember,
  getMembersList,
  handlePagination_LP,
  handlePagination_Courses,
  handlePreviousPagination_Courses,
  handlePreviousPagination_LP,
} from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ScrollArea from "react-scrollbar";
import { Button } from "react-bootstrap";
import { getManagerCoursesList } from "../../../coursemanagement/services";
import Spinner from "react-bootstrap/Spinner";
import { getManagersList, getSurvey } from "../../../surveys/services";
//   import SkillAssignMembers from '../../utilities';

import SkillAssignBox from "../../utilities/memberAssign";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CalendarListing from "../../../learningpath/components/managerlearningpathdetail/components/learnerslist/components/extendcalendar";
import ExtensionCompleted from "../../../learningpath/components/managerlearningpathdetail/components/learnerslist/components/extensioncompleted";
import moment from "moment";
import { extendLearningPath } from "../../../learningpath/services";
import { toast } from "react-toastify";
import ExpandedComponent from "../../../learnerLearningPath/learningdashboard/expandComponent";
import {Helmet} from "react-helmet";

function RemoveCourse(props) {
  return (
    <Modal
      className="custom-modal confirmation-popup delete-confirmation inner-popup"
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content-wrap text-center">
          <h3 className="modal-title">Remove Course?</h3>
          {/* <p>Are You Sure To Delete The Members? </p> */}
          <div className="d-flex justify-content-center buttons-wrap double-btn">
            <button
              type="button"
              className="btn border-btn white-btn"
              onClick={() => props.onHide()}
            >
              No, I need to recheck
            </button>
            <Link className="m-l-20">
              <Button onClick={props.handleSubmit} type="button">
                Yes, Continue
              </Button>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function RemoveLearningPath(props) {
  return (
    <Modal
      className="custom-modal confirmation-popup delete-confirmation inner-popup"
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content-wrap text-center">
          <h3 className="modal-title">Remove Learning Path ?</h3>
          {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
          <div className="d-flex justify-content-center buttons-wrap double-btn">
            <button
              type="button"
              className="btn border-btn white-btn"
              onClick={() => props.onHide()}
            >
              No, I need to recheck
            </button>
            <Link className="m-l-20">
              <Button onClick={props.handleSubmit} type="button">
                Yes, Continue
              </Button>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function RemoveMember(props) {
  return (
    <Modal
      className="custom-modal confirmation-popup delete-confirmation inner-popup"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content-wrap text-center">
          <h3 className="modal-title">Delete Member?</h3>
          {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
          <div className="d-flex justify-content-center buttons-wrap double-btn">
            <button
              type="button"
              className="btn border-btn white-btn"
              onClick={() => props.onHide()}
            >
              No, I need to recheck
            </button>
            <Link className="m-l-20">
              <Button onClick={props.handlesubmit} type="button">
                Yes, Continue
              </Button>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const MemberDetails = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataColumns, setDataColumns] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("Courses");
  const memberDetails = useSelector(
    (state) => state.teams.memberDetails?.response || [] || []
  );
  const detailsLoading = useSelector(
    (state) => state.teams.memberDetails?.loading
  );
  const responseCourses = useSelector(
    (state) => state.teams.memberCourses?.response || [] || []
  );
  const responseLP = useSelector(
    (state) => state.teams.memberLP?.response || [] || []
  );
  const loadingDeleteLP = useSelector(
    (state) => state?.teams?.memberLPDelete?.loading
  );
  const responseTotalCourse = useSelector(
    (state) => state?.course?.course_listing?.response.results || [] || []
  );
  const responseTotalLP = useSelector(
    (state) => state.teams.totalLP?.response.results || [] || []
  );

  const [member, setMemberDetails] = useState([]);
  const [courses, setCourses] = useState([]);
  const [LP, setLP] = useState([]);
  const { id } = useParams();
  const [assign, assignShow] = useState(false);
  const [category, setCategory] = useState("course");
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const loading = useSelector((state) => state?.teams?.memberCourses?.loading);
  const learningPathLoading = useSelector(
    (state) => state?.teams?.memberLP?.loading
  );
  const { hideLpQuizes } = useSelector((state) => state.settings);

  const [dataProgress, setDataProgress] = useState(false);
  const [rowId, setRowId] = useState("");
  const [count, setCount] = useState("");
  const extendResponse = useSelector(
    (state) => state?.learningpath?.extend_lp?.response
  );
  const extendLoading = useSelector(
    (state) => state?.learningpath?.extend_lp?.loading
  );
  const [isDateExtended, setIsDateExtended] = useState(false);
  const [assigmentId, setAssignmentId] = useState("");
  const [assigmentNewDate, setAssigmentNewDate] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [activeLPPage, setActiveLPPage] = useState(1);
  const [activeCoursesPage, setActiveCoursesPage] = useState(1);
  const [state, setState] = useState({
    memberCourses: responseCourses,
    memberLP: responseLP,
    courses: [],
    countCourses: responseCourses?.count,
    countLP: responseLP?.count,
    paginationLPItem: [],
    paginationCoursesItem: [],
    paginationItem: [],
  });

  const [modalShow, setModalShow] = React.useState(false);
  const handleModel = (values) => {
    if (values) {
      setModalShow(true);
    }
  };

  const [modalLP, setModalLP] = React.useState(false);
  const handleModelLP = (values) => {
    if (values) {
      setModalLP(true);
    }
  };

  const [modalDEL, setModalDEL] = React.useState(false);
  const handleModelDEL = (values) => {
    if (values) {
      setModalDEL(true);
    }
  };

  useEffect(() => {
    const countCourses = responseCourses?.count;
    const perPageCourses = 12;
    const buttonsCountCourses = Math.ceil(countCourses / perPageCourses);
    let itemsCourses = [];
    for (let number = 1; number <= Number(buttonsCountCourses); number++) {
      itemsCourses.push(
        <Pagination.Item
          onClick={() => handleCoursesIndex(number)}
          key={`${number}.courses`}
          active={number == activeCoursesPage}
        >
          {number}
        </Pagination.Item>
      );
    }
    setState({
      ...state,
      memberCourses: responseCourses,
      countCourses: responseCourses?.count,
      paginationCoursesItem: itemsCourses,
    });
  }, [responseCourses]);

  useEffect(() => {
    const countLP = responseLP?.count;
    const perPageLp = 12;
    const buttonsCount = Math.ceil(countLP / perPageLp);
    let itemsLp = [];
    for (let number = 1; number <= Number(buttonsCount); number++) {
      itemsLp.push(
        <Pagination.Item
          onClick={() => handleLpIndex(number)}
          key={`${number}.LP`}
          active={number == activeLPPage}
        >
          {number}
        </Pagination.Item>
      );
    }
    setState({
      ...state,
      memberLP: responseLP,
      countLP: responseLP?.count,
      paginationLPItem: itemsLp,
    });
  }, [responseLP]);

  const handleCoursesIndex = (index) => {
    if (index == 1) {
      dispatch(getMemberCourses(user?.token, id, search));
    } else {
      dispatch(getMemberCourses(user?.token, id, search, index));

      // dispatch(handlePagination_Courses(responseCourses?.next))
      // dispatch(getMemberCourses( search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, index));
    }
    setActiveCoursesPage(index);
  };

  const handleLpIndex = (index) => {
    if (index == 1) {
      dispatch(getMemberLP(user?.token, id, search));
    } else {
      dispatch(getMemberLP(user?.token, id, search, index));
      // dispatch(getMembersList( search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, index));
    }
    setActiveLPPage(index);
  };

  const handleNext = () => {
    if (filter == "Courses") {
      if (state.memberCourses?.next) {
        var regex = /page=\d+/g;
        const [number] = state.memberCourses?.next?.match(regex);
        setActiveCoursesPage((prevVal) =>
          prevVal === state.paginationCoursesItem ? prevVal : prevVal + 1
        );
        // dispatch(handlePaginationLearnerCourse(state.courses?.next))

        dispatch(handlePagination_Courses(responseCourses?.next));
        // dispatch(handlePagination_Courses(state.memberCourses?.next))
      } else {
        return false;
      }
    }

    if (filter == "Learning Path") {
      //if(responseLP?.next)
      if (state.memberLP?.next) {
        var regex = /page=\d+/g;
        const [number] = responseLP?.next?.match(regex);
        setActiveLPPage((prevVal) =>
          prevVal === state.paginationLPItem ? prevVal : prevVal + 1
        );
        dispatch(handlePagination_LP(state.memberLP?.next));
      } else {
        return false;
      }
    }
  };

  const handlePrevious = () => {
    if (filter == "Courses") {
      if (state.memberCourses?.previous) {
        setActiveCoursesPage((prevVal) =>
          prevVal - 1 === 0 ? prevVal : prevVal - 1
        );
        dispatch(
          handlePreviousPagination_Courses(state.memberCourses?.previous)
        );
      } else {
        return false;
      }
    }
    if (filter == "Learning Path") {
      if (state.memberLP?.previous) {
        setActiveLPPage((prevVal) =>
          prevVal - 1 === 0 ? prevVal : prevVal - 1
        );
        dispatch(handlePreviousPagination_LP(state.memberLP?.previous));
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setSearch("");
    if(!hideLpQuizes){
      setDataColumns(tableColumnsLearningPath);
      dispatch(getMemberLP(user?.token, id, ""));
      setFilter("Learning Path");
    }
   
    dispatch(getMemberDetail(user?.token, id));

    dispatch(getMemberCourses(user?.token, id, ""));
    dispatch(getManagerCoursesList(search, search));

    setCourses(responseCourses.results);

    setData(responseCourses.results);
  }, []);

  useEffect(() => {
    if(!hideLpQuizes){
    if (filter == "Learning Path") {
      setData(responseLP.results);
      setDataColumns(tableColumnsLearningPath);
      setCount(responseLP.count);
      setFilter("Learning Path");
    }
    setMemberDetails(memberDetails);
  }
  }, [responseLP.results]);

  useEffect(() => {
    if (filter == "Courses") {
      setData(responseCourses.results);
      setDataColumns(tableColumnsCourses);
      setCount(responseCourses.count);
      setFilter("Courses");
    }

    return () => {};
  }, [responseCourses.results]);

  useEffect(() => {
    // debugger
    setMemberDetails(memberDetails);
  }, [memberDetails]);

  const handleCallback = (childData) => {
    if (childData) {
      assignShow(false);
    }
  };

  useEffect(() => {
    if (loading || learningPathLoading) {
      setDataProgress(true);
    }

    if (!loading && !learningPathLoading) {
      setDataProgress(false);
    }
  }, [loading, learningPathLoading]);

  const deleteMemberSingle = () => {
    dispatch(removeMember(user?.token, id));

    setTimeout(() => {
      dispatch(getMembersList(user?.token, search, ""));
    }, 1000);

    history.push("/team");
  };

  const searchInput = (e) => {
    setSearch(e.target.value);

    if (filter == "Learning Path") {
      dispatch(getMemberLP(user?.token, id, e.target.value));
      setActiveCoursesPage(1);
    }
    if (filter == "Courses") {
      dispatch(getMemberCourses(user?.token, id, e.target.value));
      setActiveCoursesPage(1);
    }
  };

  const reset = () => {
    setSearch("");
    const sch = "";
    if (filter == "Learning Path") {
      dispatch(getMemberLP(user?.token, id, ""));
      setActiveCoursesPage(1);
    }
    if (filter == "Courses") {
      dispatch(getMemberCourses(user?.token, id, ""));
      setActiveCoursesPage(1);
    }
  };

  const handleMembersFilter = (e) => {
    if (e.value == "Courses") {
      setFilter("Courses");
      setData(responseCourses.results);
      setDataColumns(tableColumnsCourses);
      setCount(responseCourses.count);
    }

    // if (e.value == "Learning Path") {
    //   setDataColumns(tableColumnsLearningPath);
    //   setData(responseLP.results);
    //   setFilter("Learning Path");
    //   setCount(responseLP.count);
    // }
  };

  const options = [
    { value: "Learning Path", label: "Learning Path" },
    { value: "Courses", label: "Courses" },
  ];

  const handleDeleteCourse = () => {
    setModalShow(false);
    const formdata = new FormData();
    formdata.append("stats_id", rowId);
    dispatch(deleteMemberCourses(user?.token, id, formdata));
    const pgnumber = responseCourses?.results?.length > 1 ?  activeCoursesPage : activeCoursesPage > 1 ? activeCoursesPage - 1 : '' ;
    setTimeout(() => {
      dispatch(getMemberCourses(user?.token, id, search, pgnumber));
    }, 1000);
  };

  const getDate = useCallback((e) => {
    setAssigmentNewDate(e);
  }, []);

  const handleDeleteLP = () => {
    setModalLP(false);

    dispatch(
      deleteMemberLP(user?.token, id, rowId, function (res) {
        if (res == "success") {
          dispatch(getMemberLP(user?.token, id, ""));
        }
      })
    );
  };

  const rowStatus = (row) => {
    if (row?.status) return row.status;
  };

  useEffect(() => {
    let element = document.getElementById("headerTitle");
    if (element) {
      // switch (history.loca     tion.pathname){
      //         case '/memberdetails/':
      element.innerHTML = "Teams";
      //   break;

      //  default :
      //     'TEAM'
    }
  }, []);


  const tableColumnsCourses = [
    {
      name: "Course Name",
      selector: "course_name",
      sortable: true,
      center: false,
      grow: 3,
      cell: (row) => (
        <OverlayTrigger
          overlay={<Tooltip id="InfoText">{row?.course_name}</Tooltip>}
        >
          <div>
            {row?.course_name?.substring(0, 50) +
              " " +
              (row?.course_name?.length > 50 ? "..." : "")}
          </div>
        </OverlayTrigger>
      ),
    },
    {
      name: "Status",
      cell: (row) => rowStatus(row),
      sortable: true,
      center: false,
      grow: 3,
    },
    {
      name: "Assigned On",
      selector: "assigned_on",
      sortable: true,
      center: false,

      grow: 2,
    },
    {
      name: "Created By",
      selector: "created_by",
      sortable: true,
      center: false,

      grow: 2,
    },
    {
      name: "Action",
      selector: "Action",
      sortable: false,
      center: false,
      grow: 2,
      cell: (row) => (
        <div
          className="action-icon-item fill-icon"
          onClick={(e) => {
            handleModel(true), setRowId(row.id);
          }}
        >
          <OverlayTrigger
            overlay={<Tooltip id="InfoText">Delete Course</Tooltip>}
          >
            <svg
              id="icons_Q2"
              data-name="icons Q2"
              xmlns="http://www.w3.org/2000/svg"
              width="17.49"
              height="19.331"
              viewBox="0 0 17.49 19.331"
            >
              <path
                id="Path_8323"
                data-name="Path 8323"
                d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                transform="translate(-4.999 -3)"
                fill="#fff"
              />
              <path
                id="Path_8324"
                data-name="Path 8324"
                d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                transform="translate(-7.261 -9.477)"
                fill="#fff"
              />{" "}
            </svg>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const tableColumnsLearningPath = [
    {
      name: "Learning Path",
      selector: "learning_path_name",
      sortable: true,
      center: false,
      grow: 3,
      cell: (row) => (
        <OverlayTrigger
          overlay={<Tooltip id="InfoText">{row?.learning_path_name}</Tooltip>}
        >
          <div>
            {row?.learning_path_name?.substring(0, 50) +
              " " +
              (row?.learning_path_name?.length > 50 ? "..." : "")}
          </div>
        </OverlayTrigger>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: "Due Date",
      selector: "due_date",
      sortable: true,
      center: false,

      grow: 2,
    },
    {
      name: "Completed",
      selector: "created_by",
      sortable: true,
      center: false,
      cell: (row) => <p>{row.status == "Completed" ? "Completed" : "--"}</p>,
      grow: 2,
    },
    {
      name: "Action",
      selector: "Action",
      sortable: false,
      center: false,
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          {loadingDeleteLP && row.id == rowId ? (
            <Spinner animation="border" variant="warning" />
          ) : (
            <div
              className="action-icon-item fill-icon"
              onClick={(e) => {
                handleModelLP(true), setRowId(row.id);
              }}
            >
              <OverlayTrigger
                overlay={
                  <Tooltip id="InfoText">Un Assign Learning Path</Tooltip>
                }
              >
                <svg
                  id="icons_Q2"
                  data-name="icons Q2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.49"
                  height="19.331"
                  viewBox="0 0 17.49 19.331"
                >
                  <path
                    id="Path_8323"
                    data-name="Path 8323"
                    d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                    transform="translate(-4.999 -3)"
                    fill="#fff"
                  />
                  <path
                    id="Path_8324"
                    data-name="Path 8324"
                    d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                    transform="translate(-7.261 -9.477)"
                    fill="#fff"
                  />
                </svg>
              </OverlayTrigger>
            </div>
          )}
          <div data-tag="allowRowEvents">
            {row?.status != "Completed" ? (
              <button
                className="btn border-btn"
                onClick={() => {
                  handleShow(row?.id), setRowId(row?.learning_path_id);
                }}
              >
                Extend Assignment
              </button>
            ) : (
              <p>
                <b>...</b>
              </p>
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleClose = () => {
    setShow(false);
    setAssignmentId("");
    setAssigmentNewDate("");
    setIsDateExtended(false);
  };
  const handleShow = (id) => {
    setAssignmentId(`${id}`);
    setIsDateExtended(false);
    setShow(true);
  };

  const handleExtend = () => {
    if (assigmentNewDate?.length) {
      if (assigmentId?.length) {
        let data = new FormData();
        data.append(
          "extended_date",
          `${moment(assigmentNewDate).format("YYYY-MM-DD")}`
        );
        dispatch(
          extendLearningPath(data, rowId, assigmentId, function (res) {
            if (res.error) {
              toast.error(res?.error?.error_message);
            } else if (res?.status) {
              dispatch(getMemberLP(user?.token, id, ""));
              toast.success(res?.data?.message);
              setIsDateExtended(true);
            }
         
          })
        );
      }
    } else {
      toast.error("Please Select New Date");
    }
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        ref={ref}
        {...rest}
      />
      <label className="custom-control-label" onClick={onClick} />
    </div>
  ));
  const CaretDownIcon = () => {
    return (
      <div className="filter-icon-select">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.652"
          height="19.653"
          viewBox="0 0 19.652 19.653"
        >
          <path
            className="a"
            d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0"
            transform="translate(-0.5 0)"
          />
        </svg>
      </div>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  const [showNew, setShowNew] = useState(false);
  const handleCloseNew = () => setShowNew(false);
  const handleShowNew = () => setShowNew(true);
  return (
    <div className="available-course-wrap">
        <Helmet>
                <title>Member Details - Cultural Engagement  </title>
            </Helmet>
      <div className="teammember-details-header">
        {detailsLoading ? (
          ""
        ) : (
          <div className="d-flex justify-content-between align-items-center m-b-20">
            <h3 className="common-card-title">
              {memberDetails?.get_full_name
                ? memberDetails?.get_full_name
                : memberDetails?.email}
            </h3>
            <div className="button-wrap double-btn">
              {/* <button
                className="btn border-btn"
                onClick={(e) => handleModelDEL(true)}
              >
                Delete
              </button> */}
             
             <button className="btn border-btn" onClick={(e)=>history.push({pathname: '/team',state:{key:'Members'} })}>Go Back</button>
             
              <button
                className="btn btn-primary"
                onClick={() => assignShow(true)}
              >
                Enroll
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="teammember-other-details-wrap">
        <div className="d-flex align-items-center w-100 m-b-20">
          {/*<div className="oth-det-select">*/}
          {/*<Select placeholder={memberDetails?.get_user_type_display} >*/}

          {/*   </Select>*/}
          {/*</div>*/}
          <div className="teammember-other-details d-flex">
            <div className="oth-det-item">
              <span>Groups</span>
              {memberDetails?.no_of_groups ? memberDetails?.no_of_groups : 0}
            </div>
            {/* <div className="oth-det-item"><span>Learning Paths</span>{responseLP?.count}</div> */}
            <div className="oth-det-item">
              <span>Courses</span>
              {responseCourses?.count}
            </div>
             {!hideLpQuizes && (
              <div className="oth-det-item">
                <span>Learning Paths</span>
              {responseLP?.count}
            </div>
             )} 
            
          </div>
        </div>
      </div>

      <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
        {!hideLpQuizes && (
          <div className="datatable-filter-left-box d-flex align-items-center">
          <div className="datepicker-box p-relative"></div>

          <div className="dt-filter-left d-flex justify-content-between align-items-center">
            <div className="custom-react-select ">
              <Select
                options={options}
                defaultValue={options[0]}
                placeholder={filter}
                value={filter}
                onChange={handleMembersFilter}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
        </div>
        )}
        
        <div className="dt-filter-right d-flex">
          <div className="dt-filter-search-wrap p-relative search-icon-left">
          <button className="iconbutton search-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.216"
              height="19.216"
              viewBox="0 0 19.216 19.216"
            >
              <path
                className="a"
                d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"
              />
            </svg>
          </button>
            <input
              type="text"
              placeholder="Search using keyword"
              value={search}
              onChange={searchInput}
              className="form-control input-item"
            />
            <span
              className="search-close-icon"
              style={{ cursor: "pointer" }}
              onClick={reset}
            >
              ×
            </span>
          </div>
          <OverlayTrigger
                overlay={
                    <Tooltip id="InfoText">
                          Help
                    </Tooltip>
                }
            >
                <span className="searchbar-info-icon animated-icon" onClick={handleShowNew}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </OverlayTrigger>
        </div>
      </div>
      <RemoveCourse
        show={modalShow}
        handleSubmit={handleDeleteCourse}
        onHide={() => setModalShow(false)}
        onExited={() => setRowId("")}
      />

      <RemoveLearningPath
        show={modalLP}
        handleSubmit={handleDeleteLP}
        onHide={() => setModalLP(false)}
        onExited={() => setRowId("")}
      />
      <RemoveMember
        show={modalDEL}
        handlesubmit={deleteMemberSingle}
        onHide={() => setModalDEL(false)}
      />

      <div className="datatble-wrap">
      {loading ? (  <div className='text-center'>
                           <Spinner animation="border" variant="danger" />
                        </div>) : (

                            <>
                              { data?.length ? ( <DataTable
                                  data={data}
                                  columns={dataColumns}
                                  // selectableRows
                                  // noDataComponent={<div style={{padding:'24px'}}>No Enrollments has been made yet</div>}
                                  progressPending={dataProgress}
                                  progressComponent={
                                    <div>
                                      <Spinner animation="border" variant="warning" />
                                    </div>
                                  }
                                  // pagination --
                                  // paginationResetDefaultPage={pageReset}

                                  //    paginationServer
                                  //    paginationTotalRows={count}
                                  //    paginationComponentOptions={{
                                  //        noRowsPerPage: true
                                  //    }}
                                  //    paginationPerPage={12}

                                  // selectableRowsComponent={BootyCheckbox}
                                  center={true}
                                  expandableRows={filter == "Learning Path" ? true : false}
                                  expandableRowExpanded={(row) => row.defaultExpanded}
                                  expandableRowsComponent={ExpandedComponent}
                                  //    paginationIconFirstPage={false}
                                  //    paginationIconLastPage={false}
                                  //    paginationIconNext={<i className="fa fa-caret-right" onClick={handleNext}></i>}
                                  //    paginationIconPrevious={<i className="fa fa-caret-left" onClick={handlePrevious}></i>}

                                  noHeader={true}
                                />
                                ) : (
                                  <>
                                  {search ? (
                                    <div className='text-center'>No Enrollments found</div>
                                  ) : (
                                    <div className='text-center'>No Enrollments has been made yet</div>
                                  )}
                            
                                  </>
                                )
                              }
                            </>


                        )}
        
      </div>
      {filter == "Courses" ? (
        <>
          {!loading &&
            state?.memberCourses &&
            state?.memberCourses &&
            state?.memberCourses.count > 12 &&
            responseCourses && (
              <div className="d-flex justify-content-center">
                <Pagination size="sm" className="custom-pagination">
                  <Pagination.First
                    disabled={!state?.memberCourses?.previous}
                    className={`${
                      state?.memberCourses?.previous ? "paginationStyle" : ""
                    } `}
                    onClick={handlePrevious}
                  />
                  {state.paginationCoursesItem}
                  <Pagination.Last
                    disabled={!state?.memberCourses?.next}
                    onClick={handleNext}
                    className={`${
                      state?.memberCourses?.next ? "paginationStyle" : ""
                    } `}
                  />
                </Pagination>
              </div>
            )}
        </>
      ) : (
        ""
      )}
      {filter == "Learning Path" ? (
        <>
          {!learningPathLoading &&
            state?.memberLP &&
            state?.memberLP &&
            responseLP.count > 12 &&
            responseLP && (
              <div className="d-flex justify-content-center">
                <Pagination size="sm" className="custom-pagination">
                  <Pagination.First
                    disabled={!state?.memberLP?.previous}
                    className={`${
                      state?.memberLP?.previous ? "paginationStyle" : ""
                    } `}
                    onClick={handlePrevious}
                  />
                  {state.paginationLPItem}
                  <Pagination.Last
                    disabled={!state?.memberLP?.next}
                    onClick={handleNext}
                    className={`${
                      state?.memberLP?.next ? "paginationStyle" : ""
                    } `}
                  />
                </Pagination>
              </div>
            )}
        </>
      ) : (
        ""
      )}
      <Modal
        size="md"
        show={assign}
        onHide={() => assignShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="common-modal team-member-pop inner-popup"
        centered
      >
        <Modal.Header closeButton>
          <div className="addmember-header">
            <h4>Enroll</h4>
            <p>Learners can be enrolled to the course</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <SkillAssignBox
            id={id}
            parentCallback={handleCallback}
            setFilter={setFilter}
            filter={{activeCoursesPage,search}}
          />

                
        </Modal.Body>
      </Modal>
      <Modal
        className="common-modal team-member-pop inner-popup medium-size-pop"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div className="addmember-header">
            {/*<h4>Extend Assignment</h4>*/}
            {/*<p>Select a date to which you want to extend</p>*/}
          </div>
        </Modal.Header>
        <Modal.Body>
          {!extendLoading ? (
            <>
              {!isDateExtended ? (
                <>
                  <div className="extension-screen-1">
                    <CalendarListing getDate={getDate} />
                  </div>

                  <div className="d-flex justify-content-end extension-pop-footer double-btn">
                    <button
                      type="btn"
                      className="btn border-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <Button disabled={extendLoading} onClick={handleExtend}>
                      Extend
                    </Button>
                  </div>
                </>
              ) : (
                <ExtensionCompleted
                  newDate={`${moment(assigmentNewDate).format("YYYY-MM-DD")}`}
                />
              )}
            </>
          ) : (
            <Spinner animation="border" variant="danger" />
          )}
        </Modal.Body>
      </Modal>
      <Modal className="custom-modal info-modal dashboard-modal" show={showNew} onHide={handleCloseNew} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
               <Modal.Body>
                    <ol className="info-list">
                        <li>
                            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </span>
                        </li>
                        <li>
                            <span>dummy text of the printing and typesetting industry. Lorem Ipsum hass </span>
                        </li>
                        <li>
                            <span>Lorem Ipsum is simply dummy text of the printing and user_type</span>
                        </li>
               
                    </ol>
                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleCloseNew}> Close </Button>
                </div>
            </Modal>
    </div>
  );
};
export default MemberDetails;
