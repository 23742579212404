import React, {useState, useRef,useCallback, useEffect,createContext} from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    MiniMap,
    Controls,
    Background,
   removeElements,
    useZoomPanHelper

} from 'react-flow-renderer';
import Sidebar from "./components/sidebar";
import {ToastContainer, toast} from "react-toastify";
import Window from "./components/window";
import LeftBar from './components/leftbar'
import ButtonEdge from './components/buttonEdge'
import {useDispatch, useSelector} from "react-redux";
import CustomNode from './components/customNode';
import CustomNodeDefault from './components/coutomNodeDefault';
import FirstCustomNode from  './components/firstCustomNode';
import CustomNodeDefaultOne from './components/customNodeDefaultOne'
import uuid from 'react-uuid'
import { v4 as uuidv4 } from 'uuid';
import Button from "react-bootstrap/Button";
import {updateLpModule, updateLpModuleAutoSave,getNodesLpList, getManagerLearningPath} from "../../../../services";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
export const  FlowContext = createContext();
const edgeTypes = {
    buttonedge: ButtonEdge,
};
const nodeTypes = {
    selectorNode: CustomNode,
    selectorNodeOne:CustomNodeDefault,
    selectorNodeTwo:FirstCustomNode,
    selectorNodeThree:CustomNodeDefaultOne,
};
let node_id = 0;
const getId = () => `${uuidv4()}`;
const LearningPathContainerDetail = (props) => {
    let dispatch = useDispatch();
    const history = useHistory();
    const response = useSelector(state => state?.learningpath?.elements?.response);
    const saveLoading = useSelector(state => state?.learningpath?.updated_lp_module?.loading);
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const { transform } = useZoomPanHelper();
    const [elements, setElements] = useState( []);
    const [nodeId,setNodeId] = useState(null)
    const [captureElementClick, setCaptureElementClick] = useState(true);
    const [nodeType,setNodeType] = useState('')
    const DEBOUNCE_SAVE_DELAY_MS = 1000;


    useEffect(()=>{
        if(reactFlowInstance){
            reactFlowInstance?.fitView()
        }
    },[reactFlowInstance])
    const onLoad = (_reactFlowInstance) => {
        setReactFlowInstance(_reactFlowInstance);
        // _reactFlowInstance.setTransform({ x, y, zoom });
        _reactFlowInstance.project({x: 100, y: 100,});

    }



    // const saveElements = (elements,reactFlowInstance) => {
    //         let flow = reactFlowInstance?.toObject()
    //         if(flow) {
    //             let [x = 0, y = 0] = flow?.position
    //
    //
    //             dispatch(
    //                 updateLpModuleAutoSave(
    //                     {
    //                         elements:flow.elements,
    //                         is_published: false,
    //                         flow_position_x: x,
    //                         flow_position_y: y,
    //                         flow_zoom: flow.zoom || 0
    //                     },
    //                     props?.lpId,
    //                     function (response) {
    //
    //                     }
    //                 )
    //             );
    //
    //         }
    // }





    useEffect(() => {
        if (props.urlId) {
            // debugger
            // let  zoom =  1
            // let x     = props?.nodes?.flow_position_x || 0
            // let y     = props?.nodes?.flow_position_y  || 0
            // transform({ x, y, zoom: 0.5 });
            //  reactFlowInstance?.setTransform({ x, y, zoom });
            setElements(props?.nodes?.elements)

        }
    }, [props?.nodes,reactFlowInstance]);





    let {lpId} = props

    const handleLoader = () => {
        if (saveLoading) {
            return (
                <div className="loader-full-screen-course">
                    <div className="loader zIndex">
                        <Spinner animation="border" variant="danger"/>
                    </div>
                </div>
            );
        } else {
            return <span></span>;
        }
    };
    return (
        <>
            <div>{handleLoader()}</div>
        <FlowContext.Provider value={[elements,setElements,nodeId,setNodeId,lpId,setNodeType]}>
            <div className="dndflow dndDetail">
                <ReactFlowProvider>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            elements={elements}


                            onLoad={onLoad}

                            edgeTypes={edgeTypes}

                            deleteKeyCode={false}
                            fitView
                            nodeTypes={nodeTypes}
                            key="edge-with-button"
                            nodesDraggable={false}
                            nodesConnectable={false}
                            defaultZoom={0.6}
                        >


                            {/*<MiniMap />*/}
                            <Controls />

                            <Background />
                        </ReactFlow>

                    </div>

                </ReactFlowProvider>

                {/*<Window/>*/}

            </div>
        </FlowContext.Provider>

            </>
    );
};
export default LearningPathContainerDetail;
