
import React from 'react';
import ScrollArea from 'react-scrollbar';
// import Form from 'react-bootstrap/Form';
import { Formik,Field, Form } from "formik";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import FormikControl from "../../formcomponents/formikControl/container";


const ModuleFive = (props) => {
    const {value,index,length} = props

    let shape = {};
    shape[value?.questions[0]?.question] = Yup.string().required();
    const schema = Yup.object().shape(shape);
    // const handleSubmit = async (values) => {


    // };

    return (
        <div className="videomodule">

            <ScrollArea
                speed={0.8}
                className="modulemodalscroll"
                contentClassName="content"
                horizontal={false}
            >
                <div className="video-content-wrap">
                    <div className="module-content">
                        <div className="whitebox shadow-box radioform-box">
                            <ol className="list-unstyled">
                                <li>
                                    <p dangerouslySetInnerHTML={{ __html: `${value?.questions[0]?.question ? value?.questions[0]?.question:''}` }}></p>
                                    <div className="radiooption radiooption-single-qstn ">
                                        <Formik
                                            validationSchema={schema}
                                            // onSubmit={handleSubmit}
                                            initialValues={{}}
                                        >
                                            {({
                                                  handleSubmit,
                                                  handleChange,
                                                  values,
                                                  errors,
                                                  setFieldValue
                                              }) => {

                                                return (

                                                    <Form >

                                                        <div className="radio-block-wrap col-12">
                                                    <FormikControl
                                                       control='radio'
                                                       name={value?.questions[0]?.question}
                                                       options={value?.questions[0]?.question_choices}
                                                       errors={errors}
                                                       setFieldValue={setFieldValue}
                                                       showResponse = {value?.questions[0]?.show_response}
                                                       values={values}
                                                        />



                                                </div>
                                                        {/*<Button variant="primary" className="btn-m" type="submit">Continue</Button>*/}

                                        </Form>
                                                )}}
                                        </Formik>
                                    </div>
                                </li>
                            </ol>

                        </div>
                        {/*<h4>{value?.name}</h4>*/}
                        {/*<p>{value?.description}</p>*/}
                    </div>
                </div>
            </ScrollArea>

        </div>
    )

}

export default ModuleFive;
