import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { FormLabel, FormControl } from 'react-bootstrap';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Quiz from '../adddetails/components/quiz';
import MultipleChoice from "../addDemographicQs/components/multipleChoice";
import ExtraMultipleChoice from "../addDemographicQs/components/extraMultiple";

import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import { ToastContainer, toast } from "react-toastify";
import QuizModulePopView from "../managerQuizPreview";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteQuizQuestion } from "../../../../services";
import Modal from 'react-bootstrap/Modal';
import { getDemographicQuestions, updateSurveyQuestion, getManagerSurveys } from '../../services'
import Select from 'react-select';
import uuid from 'react-uuid'
import { set } from 'date-fns';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Link } from 'react-router-dom';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const AddDetails = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const history = useHistory();
    const dispatch = useDispatch()
    const [infoPopUp, showInfoPopup] = useState(false);
    const [clickedOption, selectedOption] = useState({});
    const [demographic_questionsDropdown, setDropdown] = useState([]);
    const auto = useRef()
    const question_type_ref = useRef(null)
    const publish_ref = useRef(null)
    // let response = useSelector(state => state?.survey_builder?.get_survey_manager?.response);
    const addQuestionLoading = useSelector(state => state?.survey_builder?.get_survey_manager?.loading) || false;
    let deleteQuestionLoading = useSelector(state => state?.survey_builder?.get_survey_manager?.loading);
    const [modalShow, setModalShow] = React.useState(false);
    const [activeButton, setActiveButton] = useState(undefined)
    const [response, setResponse] = useState({})
    const [tutorialModel, setTutorialmodel] = useState(false);


    const defaultdemographic_questions = useSelector(state => state?.survey_builder?.demographicQuestionsList?.response?.results || []);
    let updateLoading = useSelector(state => state?.quiz?.updated_quiz_question?.loading);
    const [state, setState] = useState({
        questionIndex: '',
        popupView: false,
        initialValuesState:
        {

            demographic_questions: [{
                question_type: '',
            }],
        }
    })
    const formikRef = useRef(null);
    const [surveyDetails, setSurveyDetails] = useState({})

    useEffect(() => {

        setSurveyDetails(props?.survey)
        dispatch(getDemographicQuestions())


    }, [])

    function TutorialModal(props) {

        return (
            <Modal className="custom-modal info-modal dashboard-modal" show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="module-modal-header my-4">

                        <h4>Demographics are characteristics of a population, hence Demographic questions are particularly useful if you’re looking to gather background information about your organisation members
                        </h4>
                    </div>
                    <ol className="info-list">
                        <li><span> Add Question enables authors to add questions to their surveys
                        </span></li>
                        <li><span> Question type has three default types like Ethnicity, Gender, Age and you can also add new types of questions.</span></li>
                        <li><span> Each question must have a minimum of 2 options  and can have a maximum of 10 options.</span></li>
                        <li><span>Clicking on + adds new option and - removes the option; you can edit the options anytime while creating/editing the survey</span></li>
                        <li><span>Enabling the Mandatory button  will make the particular question mandatory for learners to answer</span></li>
                        <li><span>Enabling Page Break for a question will add the particular question into a new page.</span></li>
                        <li><span>Add a type for every question and reorder positions with the arrows you may find in the border once there are two questions in your surveys</span></li>
                        <li><span>Clicking on  the Next button takes you to the General  Questions creation tab.</span></li>
                        <li><span>Once you complete adding questions to your survey, you can click Preview Button at the end to view how your survey shall be displayed to your learners</span></li>
                        <li><span>Click on Save and find your survey in Drafts and click on Publish to find it in Published surveys</span></li>
                        <li><span>Each time you click Add question, all the questions get auto-saved hence you can find the same survey in Drafts anytime.</span></li>
                        <li><span>If you publish your survey from the Demographic  tab, your survey would not contain any general questions and remember, once published you cannot edit the survey ever.</span></li>
                    </ol>
                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={props.onHide}> Close </Button>
                </div>
            </Modal>
        );
    }



    function VerticallyCenteredModal(props) {

        return (
            <Modal className="custom-modal confirmation-popup  delete-confirmation inner-popup"
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Body>
                    <div className="modal-content-wrap text-center">
                        <h3 className="modal-title">Publish Survey?</h3>
                        <p>Survey once published cannot be edited </p>
                        <div className="d-flex justify-content-center buttons-wrap double-btn">

                            <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                                need to recheck
                            </button>
                            <Link className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes,
                                Continue</Button></Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }





    useEffect(() => {
        if (defaultdemographic_questions && defaultdemographic_questions?.length) {
            const newQuestion = [{
                question: 'Add a new question',
                type: 'extra',
                choices: [],
                question_key: 'Add a new question'

            }]
            let newArray = defaultdemographic_questions.concat(newQuestion)

            setDropdown(newArray)
        }
    }, [defaultdemographic_questions])


    const [popValues, setPop] = useState({})



    useEffect(() => {


        if (props.urlId) {
            if (response?.demographic_questions?.length == 0 || !response?.demographic_questions) {
                const { initialValuesState } = state
                initialValuesState.demographic_questions = []
                initialValuesState?.demographic_questions?.push({
                    question_type: '',
                })


                setState({ ...state, initialValuesState: initialValuesState })
            } else {


                setState({ ...state, initialValuesState: response })
            }
        }
    }, [response?.demographic_questions])



    function onSubmit(values) {

        if (activeButton == 'save') {
            if (values?.demographic_questions && values?.demographic_questions?.length) {
                for (let i = 0; i < values?.demographic_questions?.length; i++) {
                    if (values?.demographic_questions[i]?.multiple_choices?.length && values?.demographic_questions[i].question_type == 'multiple_choice') {
                        for (let j = 0; j < values?.demographic_questions[i]?.multiple_choices?.length; j++) {
                            if (values.demographic_questions[i].multiple_choices[j].choice.length == 0) {
                                values.demographic_questions[i].multiple_choices = values?.demographic_questions[i]?.multiple_choices?.filter((el) => el?.choice?.length)
                            }
                        }
                    }
                    values.demographic_questions[i].position = i + 1;
                }
            }
            values.is_published = 'False'
            values.is_active = 'true'
            if (!("survey_uuid" in values)) {
                const new_values = { ...response, ...values };

                dispatch(updateSurveyQuestion(JSON.stringify(new_values), props?.surveyId, function (response) {
                    toast.success('Questions  has been  updated  successfully')
                    props.parentState(2, props?.surveyId, props?.survey, true)

                    history.push({
                        pathname: '/surveymanagement',
                        state: {
                            key: 1,

                        }
                    })

                }))
            }
            else {
                dispatch(updateSurveyQuestion(JSON.stringify(values), props?.surveyId, function (response) {
                    toast.success('Questions  has been  updated  successfully')
                    props.parentState(2, props?.surveyId, props?.survey, true)
                    history.push({
                        pathname: '/surveymanagement',
                        state: {
                            key: 1,

                        }
                    })
                }))
            }

        }




        else if (activeButton == 'next') {
            if (values?.demographic_questions && values?.demographic_questions?.length) {
                for (let i = 0; i < values?.demographic_questions?.length; i++) {
                    if (values?.demographic_questions[i]?.multiple_choices?.length && values?.demographic_questions[i].question_type == 'multiple_choice') {
                        for (let j = 0; j < values?.demographic_questions[i]?.multiple_choices?.length; j++) {
                            if (values.demographic_questions[i].multiple_choices[j].choice.length == 0) {
                                values.demographic_questions[i].multiple_choices = values?.demographic_questions[i]?.multiple_choices?.filter((el) => el?.choice?.length)
                            }
                        }
                    }
                    values.demographic_questions[i].position = i + 1;
                }
            }
            values.is_published = 'False'
            values.is_active = 'true'
            if (!("survey_uuid" in values)) {
                const new_values = { ...response, ...values };

                dispatch(updateSurveyQuestion(JSON.stringify(new_values), props?.surveyId, function (response) {
                    toast.success('Questions  has been  updated  successfully')
                    setTimeout(() => {

                        props.parentState(2, props?.surveyId, props?.survey, true)

                    }, 300);



                }))
            }
            else {
                dispatch(updateSurveyQuestion(JSON.stringify(values), props?.surveyId, function (response) {
                    toast.success('Questions  has been  updated  successfully')
                    setTimeout(() => {
                        props.parentState(2, props?.surveyId, props?.survey, true)


                    }, 300);

                }))
            }

        }




        else if (activeButton == 'publish') {

            if (values?.demographic_questions && values?.demographic_questions?.length) {

                for (let i = 0; i < values?.demographic_questions?.length; i++) {
                    if (values?.demographic_questions[i]?.multiple_choices?.length && values?.demographic_questions[i].question_type == 'multiple_choice') {
                        for (let j = 0; j < values?.demographic_questions[i]?.multiple_choices?.length; j++) {
                            if (values.demographic_questions[i].multiple_choices[j].choice.length == 0) {
                                values.demographic_questions[i].multiple_choices = values?.demographic_questions[i]?.multiple_choices?.filter((el) => el?.choice?.length)
                            }
                        }
                    }
                    values.demographic_questions[i].position = i + 1;

                }




                values.is_published = 'True'
                values.is_active = 'true'



                if (!("survey_uuid" in values)) {
                    const new_values = { ...response, ...values };

                    dispatch(updateSurveyQuestion(JSON.stringify(new_values), props?.surveyId, function (response) {
                        toast.success('Survey  has been  published  successfully')
                        props.parentState(2, props?.surveyId, props?.survey, true)
                        history.push({
                            pathname: '/surveymanagement',
                            state: {
                                key: 0,

                            }
                        })
                    }))
                }
                else {
                    dispatch(updateSurveyQuestion(JSON.stringify(values), props?.surveyId, function (response) {
                        toast.success('Survey  has been  published  successfully')

                        props.parentState(2, props?.surveyId, props?.survey, true)
                        showInfoPopup(true)
                        setTimeout(() => {
                            showInfoPopup(false)
                            history.push({
                                pathname: '/surveymanagement',
                                state: {
                                    key: 0,

                                }
                            })
                        }, 3000);

                        //

                        history.push({
                            pathname: '/surveymanagement',
                            state: {
                                key: 0,

                            }
                        })

                    }))
                }




            } else {
                toast.error("Please add Question");
            }


        }

    }
    async function handleSurveys(e, values, setValues, errors, validateField, validateForm) {


        if (values?.demographic_questions && values?.demographic_questions?.length >= 1) {

            let error = await formikRef.current.validateForm();


            if (error.demographic_questions) {
                error.demographic_questions = errors?.demographic_questions?.filter(function (x) {
                    return x !== undefined;
                });
            }
            if (error?.demographic_questions && error?.demographic_questions?.length && values?.demographic_questions?.find((el) => el.question_type == '')) {

                for (let i = 0; i < values?.demographic_questions?.length; i++) {
                    if (values?.demographic_questions[i].question_type == '') {
                      
                        formikRef.current.setFieldTouched(`demographic_questions.${i}.question_type`)

                        break;
                    }
                }
            } else if (Object.keys(error).includes('demographic_questions') && error?.demographic_questions?.length && error.constructor === Object) {

                if (values?.demographic_questions?.length) {
                    auto?.current?.click()
                }
                // }



            }

            if (Object.keys(error).length === 0 && error.constructor === Object) {
                setTimeout(() => {

                    if (values?.demographic_questions && values?.demographic_questions?.length) {
                        for (let i = 0; i < values?.demographic_questions?.length; i++) {
                            if (values?.demographic_questions?.length && values?.demographic_questions[i].question_type == 'multiple_choice') {

                                for (let j = 0; j < values?.demographic_questions[i]?.multiple_choices?.length; j++) {
                                    if (values.demographic_questions[i].multiple_choices[j].choice.length == 0) {
                                        values.demographic_questions[i].multiple_choices = values?.demographic_questions[i]?.multiple_choices.filter((el) => el?.choice?.length)

                                    }

                                }


                            }
                            values.demographic_questions[i].position = i + 1;
                        }

                    }

                    values.is_active = 'true'
                    values.is_published = 'false'
              


                    const new_values = { ...response, ...values };




                    dispatch(updateSurveyQuestion(JSON.stringify(new_values), props?.surveyId, function (res) {
                        toast.success('The above-added questions are auto-saved!')
                        if (res) {
                            dispatch(getManagerSurveys(props?.surveyId, function (res) {
                                if (res) {



                                    res?.demographic_questions?.push({
                                        question_type: ' ',
                                    })


                                    setValues({ ...values, demographic_questions: [] })
                                    setState({ ...state, initialValuesState: res })
                                }
                            }
                            ))
                        }
                    }
                    ))




                }, 10)
            } else {
                toast.error("Some fields are empty, please check!");
            }
        } else {

            const demographic_questions = [...values.demographic_questions];
            const { initialValuesState } = state

            demographic_questions.push({
                question_type: '',

            })
            initialValuesState?.demographic_questions?.push({
                question_type: '',
            })
          
            setValues({ ...values, demographic_questions })
            setState({ ...state, initialValuesState: initialValuesState })
        }

    }

    const publishSurvey = () => {
        publish_ref?.current?.focus();
        publish_ref?.current?.click();

    }

    const deleteQuestion = (index, values, setValues) => {
        const demographic_questions = [...values.demographic_questions]
        const { initialValuesState } = state
        initialValuesState?.demographic_questions?.splice(index, 1)
        demographic_questions.splice(index, 1)
        setValues({ ...values, demographic_questions })

        setState({ ...state, initialValuesState: initialValuesState })
    }
    const handleQuestionDelete = (index, values, setValues, question) => {
        setState({ ...state, quizId: question?.id })
        const demographic_questions = [...values.demographic_questions]
        dispatch(deleteQuizQuestion(props.quizId, question?.id, function (response) {
            if (response == 'response') {

                demographic_questions.splice(index, 1)
                setValues({ ...values, demographic_questions })
            }

        }))


    }
    const der = (demographic_questionsErrors) => {

        if (Object.keys(demographic_questionsErrors).length != 0) {
            props.demographicError(true)
        }
        else { props.demographicError(false) }
    }

    const handleSwitchChange = (e, i, values, setFieldValue, question) => {
  
        setFieldValue(`demographic_questions.${i}.mandatory`, e)
    }
    const handlePageBreakChange = (e, i, values, setFieldValue, question) => {
        setFieldValue(`demographic_questions.${i}.page_break`, e)

    }

    useEffect(() => {
        if (props?.defaultKey == 1) {
            dispatch(getManagerSurveys(props?.surveyId, function (res) {
                setResponse(res)
            }))
        }
    }, [props?.defaultKey, props?.surveyId])



    useEffect(() => {
        if (props.defaultKey == 1) {
            if (!surveyDetails?.demographic_questions && state?.initialValuesState && state?.initialValuesState?.demographic_questions && state?.initialValuesState?.demographic_questions?.length == 1 && state?.initialValuesState?.demographic_questions[0].question_type.length == 0) {
                question_type_ref?.current?.focus();
                question_type_ref?.current?.click();
            }
        }
    }, [props?.defaultKey, response?.demographic_questions])

    const handlePreviewModel = () => {

        setState({ ...state, popupView: true })
    }
    const handleLoader = () => {
        if (addQuestionLoading || updateLoading) {
            return (
                <div className='loader-full-screen-course'>
                    <div className='loader'>
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
            )
        } else {
            return (
                <span></span>
            )

        }
    }
    return (
        <div className="coursedetails-wrap">
            <div className="help_icon_position">
                <OverlayTrigger
                    overlay={
                        <Tooltip id="InfoText">
                            Help
                        </Tooltip>
                    }
                >
                    <span className="info-icon-wrap-tab animated-icon" onClick={() => setTutorialmodel(true)}>
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </span>
                </OverlayTrigger>
            </div>
            {addQuestionLoading ?

                <div className='loader-full-screen-course'>
                    <div className='loader'>
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
                : ''}


            {/* <div>{handleLoader()}</div> */}
            <Formik
                innerRef={formikRef}
                initialValues={state.initialValuesState}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}

            >
                {({ errors, values, setFieldValue, touched, setValues, handleChange, handleBlur, validateField, validateForm, handleSubmit, }) => {

                    const handleSubmitTrigger = async () => {
                        let error = await validateForm();
                        if (Object.keys(error).length === 0 && error.constructor === Object) {
                            handleSubmit()
                        } else {
                            auto?.current?.click();
                            toast.error("Some fields are empty, please check!");
                        }
                    }

                    const handleContent = (question_key, selectedIndex) => {
                        const clickedOption = demographic_questionsDropdown.find(item => item?.question_key === question_key);

                        selectedOption(clickedOption)
                        const { initialValuesState } = state;
                        if (clickedOption?.type == 'default') {

                            const demographic_questions = [...values.demographic_questions];

                            demographic_questions[selectedIndex].question_type = 'multiple_choice';
                            initialValuesState.demographic_questions[selectedIndex].question_type = 'multiple_choice'
                            initialValuesState.demographic_questions[selectedIndex].question = ''
                            initialValuesState.demographic_questions[selectedIndex].header = ''
                            initialValuesState.demographic_questions[selectedIndex].question_id = ''
                            initialValuesState.demographic_questions[selectedIndex].question_category = ''
                            initialValuesState.demographic_questions[selectedIndex].title = ''
                            initialValuesState.demographic_questions[selectedIndex].multiple_choices = [{ choice: '' }, { choice: '' }]
                            demographic_questions[selectedIndex].question = clickedOption.question
                            demographic_questions[selectedIndex].question_id = uuid()
                            demographic_questions[selectedIndex].header = 'default'
                            demographic_questions[selectedIndex].question_category = clickedOption.question_key
                            demographic_questions[selectedIndex].title = clickedOption.question_key
                            demographic_questions[selectedIndex].page_break = false
                            demographic_questions[selectedIndex].mandatory = false
                            demographic_questions[selectedIndex].options = clickedOption
                            demographic_questions[selectedIndex].multiple_choices = []

                            if (clickedOption?.choices && clickedOption?.choices?.length) {
                                for (let i = 0; i < 2; i++) {

                                    if (clickedOption?.choices && clickedOption?.choices?.length >= i) {
                                        demographic_questions[selectedIndex].multiple_choices.push({ choice: clickedOption?.choices[i] })
                                    }

                                }
                            }

                            setState({ ...state, initialValuesState: initialValuesState, questionIndex: selectedIndex })
                            setValues({ ...values, demographic_questions });





                        } if (clickedOption?.type == 'extra') {

                            const demographic_questions = [...values.demographic_questions];

                            demographic_questions[selectedIndex].question_type = 'multiple_choice';
                            initialValuesState.demographic_questions[selectedIndex].question_type = 'multiple_choice'
                            initialValuesState.demographic_questions[selectedIndex].question = ''
                            initialValuesState.demographic_questions[selectedIndex].question = ''
                            initialValuesState.demographic_questions[selectedIndex].question_id = ''
                            initialValuesState.demographic_questions[selectedIndex].question_category = ''
                            initialValuesState.demographic_questions[selectedIndex].header = ''
                            initialValuesState.demographic_questions[selectedIndex].title = ''
                            initialValuesState.demographic_questions[selectedIndex].multiple_choices = [{ choice: '' }, { choice: '' }]
                            demographic_questions[selectedIndex].question = ''
                            demographic_questions[selectedIndex].question_id = uuid()
                            demographic_questions[selectedIndex].question_category = clickedOption.question_key
                            demographic_questions[selectedIndex].header = 'extra'
                            demographic_questions[selectedIndex].page_break = false
                            demographic_questions[selectedIndex].mandatory = false
                            demographic_questions[selectedIndex].title = ""
                            demographic_questions[selectedIndex].multiple_choices = [{ choice: '' }, { choice: '' }]
                            setState({ ...state, initialValuesState: initialValuesState, questionIndex: selectedIndex })
                            setValues({ ...values, demographic_questions });






                        }
                    };

                    return (
                        <Form className="d-block mb-4">
                            <div className="course-content-sec">
                                {/*<Quiz*/}
                                {/*    touched={touched}*/}
                                {/*    setFieldValue={setFieldValue}*/}
                                {/*    values={values}*/}
                                {/*    handleChange={handleChange}*/}
                                {/*    errors={errors}*/}
                                {/*/>*/}
                            </div>
                            <div className="course-content-sec ">
                                <FieldArray name="demographic_questions">
                                    {() => (values?.demographic_questions && values?.demographic_questions?.length ? values?.demographic_questions?.map((question, i) => {

                                        const demographic_questionsErrors = errors.demographic_questions?.length && errors.demographic_questions[i] || {};
                                        const demographic_questionsTouched = touched?.demographic_questions?.length && touched?.demographic_questions[i] || {};

                                        const goUp = (index) => {
                                            if (index !== 0) {
                                                let questionscopy = JSON.parse(JSON.stringify(values.demographic_questions));
                                                let item = questionscopy[index];
                                                questionscopy[index] = questionscopy[index - 1];
                                                questionscopy[index - 1] = item;
                                                setFieldValue('demographic_questions', questionscopy);
                                            }

                                        };

                                        const goDown = (index) => {
                                            if (index < values.demographic_questions.length - 1) {
                                                let questionscopy = JSON.parse(JSON.stringify(values.demographic_questions));
                                                let item = questionscopy[index];
                                                questionscopy[index] = questionscopy[index + 1];
                                                questionscopy[index + 1] = item;
                                                setFieldValue('demographic_questions', questionscopy);
                                            }
                                        };



                                        return (

                                            <>
                                             

                                    

                                                {der(demographic_questionsErrors)}
                                                <div className="course-form-content-sec p-relative" key={i}>
                                                    {values?.demographic_questions?.length > 1 && (

                                                        <div className="top-bottom-carret-wrap">
                                                            {i !== 0 && <div onClick={() => goUp(i)} className="move-to-top move-arrow-wrap ">
                                                                <OverlayTrigger
                                                                    overlay={<Tooltip id="InfoText">Move up</Tooltip>}>
                                                                    <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                                                </OverlayTrigger>
                                                            </div>}
                                                            {i !== values?.demographic_questions?.length - 1 && <div onClick={() => goDown(i)} className="move-to-bottom move-arrow-wrap">
                                                                <OverlayTrigger
                                                                    overlay={<Tooltip id="InfoText">Move down</Tooltip>}>
                                                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                </OverlayTrigger>
                                                            </div>}
                                                        </div>

                                                    )}

                                                    <h3 className="tab-title">Demographic Question {i + 1}</h3>
                                                    <div className="whitebox shadow-box module-form-box">
                                                        {/* {question?.id ?
                                                            ''
                                                            :
                                                            <div onClick={() => deleteQuestion(i, values, setValues)}> <div className="form-close-btn p-relative"><span className="memb-close-icon-card">×</span></div></div>
                                                        } */}
                                                        {/*<div onClick={()=>deleteModule(i,values, setValues)}> <div className="form-close-btn p-relative"><span className="memb-close-icon-card">×</span></div></div>*/}
                                                        <Row>
                                                            <Col md={2}>
                                                                <div key={i} className="form-input-wrap">
                                                                    <FormLabel>
                                                                        Position
                                                                    </FormLabel>
                                                                    <FormControl
                                                                        type="number"
                                                                        name={`modules.${i}.position`}
                                                                        placeholder="Position"
                                                                        className="position"
                                                                        readOnly
                                                                        value={i + 1} />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="form-input-wrap">
                                                                    <FormLabel>Question Type</FormLabel>

                                                                    <FormControl as="select"
                                                                        name={`demographic_questions.${i}.question_category `}
                                                                        onChange={e => handleContent(e.target.value, i)}
                                                                        value={values?.demographic_questions[i]?.question_category}
                                                                        ref={question_type_ref}
                                                                        // onClick={e => { handleContent(e.target.value, i); }}
                                                                        isInvalid={!!demographic_questionsErrors?.question

                                                                        }
                                                                        onBlur={handleBlur}
                                                                        

                                                                    >
                                                                         <option value="" label="Choose question type" selected disabled>Choose question type</option>
                                                                        {demographic_questionsDropdown.map((item, i) =>
                                                                            <>

                                                                                   
                                                                                    <option key={i} value={item?.question_key} label={item?.question_key}>{item?.question_key}</option>
                                                                                
                                                                            </>
                                                                        )}
                                                                       

                                                                    </FormControl>

                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {question?.header == 'extra' ?
                                                            <div className="text-editor-wrap">

                                                                {(() => {


                                                                    return <ExtraMultipleChoice
                                                                        setFieldValue={setFieldValue}
                                                                        key={uuid()}
                                                                        values={values}
                                                                        handleChange={handleChange}
                                                                        setValues={setValues}
                                                                        clickedOption={clickedOption}
                                                                        demographic_questionsErrors={demographic_questionsErrors}
                                                                        demographic_questionsTouched={demographic_questionsTouched}
                                                                        touched={touched}
                                                                        handleBlur={handleBlur}
                                                                        index={i}
                                                                    />;



                                                                })()}
                                                            </div>
                                                            :
                                                            ''
                                                        }

                                                        {question?.header == 'default' ?
                                                            <div className="text-editor-wrap">


                                                                {(() => {


                                                                    return <MultipleChoice
                                                                        setFieldValue={setFieldValue}
                                                                        key={uuid()}
                                                                        values={values}
                                                                        handleChange={handleChange}
                                                                        setValues={setValues}
                                                                        clickedOption={clickedOption}
                                                                        demographic_questionsErrors={demographic_questionsErrors}
                                                                        demographic_questionsTouched={demographic_questionsTouched}
                                                                        touched={touched}
                                                                        handleBlur={handleBlur}
                                                                        index={i}
                                                                    />;



                                                                })()}
                                                            </div>
                                                            :
                                                            ''
                                                        }








                                                        {question?.question_type == 'multiple_choice' ?
                                                            <Row className="survey-page-break">
                                                                <Col md={4} lg={3}>
                                                                    <div className='d-flex switch-inmodule-wrap'>
                                                                        <span>Mandatory</span>
                                                                        <div className="switch-align-right">
                                                                            <BootstrapSwitchButton onChange={(e) => handleSwitchChange(e, i, values, setFieldValue, question)} checked={values?.demographic_questions[i]?.mandatory} size="xs" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4} lg={3}>
                                                                    <div className='d-flex switch-inmodule-wrap'>
                                                                        <span>Page Break</span>
                                                                        <div className="switch-align-right">
                                                                            <BootstrapSwitchButton onChange={(e) => handlePageBreakChange(e, i, values, setFieldValue, question)} checked={values?.demographic_questions[i]?.page_break} size="xs" />
                                                                        </div>
                                                                    </div></Col>

                                                            </Row>

                                                            : ''}

                                                        <Col xs={12} className="d-flex justify-content-end">

                                                            {question?.id ?

                                                                <button className="btn border-btn ml-auto" type="button"
                                                                    onClick={e => handleQuestionDelete(i, values, setValues, question)}>
                                                                    {deleteQuestionLoading && deleteQuestionLoading && state.quizId == question.id ?
                                                                        <Spinner animation="border" variant="warning" />
                                                                        :
                                                                        'Delete'
                                                                    }
                                                                </button>
                                                                : (
                                                                    <button className="btn border-btn ml-auto" type="button"
                                                                        onClick={e => deleteQuestion(i, values, setValues)}>
                                                                        Delete
                                                                    </button>
                                                                )

                                                            }

                                                        </Col>



                                                    </div>
                                                </div>

                                            </>

                                        );
                                    })
                                        :
                                        ''
                                    )

                                    }
                                </FieldArray>
                                <div className="d-flex align-items-center mt-4">
                                    <Button variant="primary" onClick={e => handleSurveys(e, values, setValues, errors, validateField, validateForm)}>{values?.demographic_questions?.length ? 'Add Another Question' : 'Add New Question'}</Button>
                                    {/*  </div>*/}

                                </div>
                            </div>

                            <div className="details-footer d-flex justify-content-end">
                                <div className="button-wrap double-btn">
                                    <Button variant="primary" type="submit" data-flag="action-3" ref={auto} className="btn-m d-none">Hidden</Button>
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="overlay-example">Move to Next page</Tooltip>}>
                                        <Button variant="dark" className="btn-m" data-flag="action-4" onClick={() => { setActiveButton('next'); setTimeout(() => { handleSubmitTrigger() }, 100) }}
                                        >Next</Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="overlay-example">Save to Drafts</Tooltip>}>
                                        <Button variant="dark" onClick={() => { setActiveButton('save'); setTimeout(() => { handleSubmitTrigger() }, 100) }} className="btn-m" data-flag="action-1">Save</Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="overlay-example">View a Preview</Tooltip>}>
                                        <Button variant="dark" className="btn-m" onClick={handlePreviewModel}>Preview</Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="overlay-example"> Publish to Published tab</Tooltip>}>
                                        <Button variant="primary"
                                            type="button" onClick={() => setModalShow(true)} className="btn-m">Publish</Button>
                                    </OverlayTrigger>
                                    <Button variant="primary"
                                        onClick={() => { setActiveButton('publish'); setTimeout(() => { handleSubmitTrigger() }, 100) }} data-flag="action-2" ref={publish_ref} className="btn-m d-none">Publish-hidden</Button>
                                </div>
                            </div>
                            <QuizModulePopView
                                show={state.popupView}
                                values={{ ...values, questions: values?.demographic_questions }}
                                onHide={() => setState({ ...state, popupView: false })}

                            />
                            <VerticallyCenteredModal

                                show={modalShow}
                                handleSubmit={() => { publishSurvey(); setModalShow(false) }}
                                onHide={() => setModalShow(false)}
                            // onExited={ModalExit}
                            />

                            <TutorialModal show={tutorialModel}

                                onHide={() => setTutorialmodel(false)}
                            />
                        </Form>
                    );
                }}
            </Formik>
            <Modal
                size="sm"
                show={infoPopUp}
                //    onHide={() =>
                //     showInfoPopup(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="common-modal quiz-flash-popup inner-popup"
                centered
                closeButton={false}
            >
                <Modal.Body>
                    <div className="addmember-header text-center">
                        <p><b>This assessments is now available to be used in your learning path builder , or can be attached to individual
                            modules found in your library</b>
                        </p>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                className="custom-modal info-modal dashboard-modal"
                show={show} onHide={handleClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has survived not only five centuries, but al</p>
                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </div>
            </Modal>

        </div>
    );

};
const validationSchema = Yup.object().shape({

    demographic_questions: Yup.array().of(
        Yup.object().shape({
            question_type: Yup.string().required('Question type is required'),
            title: Yup.string().required('Title is required'),

            question: Yup.string()
                .required('Question is required'),


            multiple_choices: Yup.array().of(
                Yup.object().shape({
                    choice: Yup.string(),

                })


            )
                .test({
                    name: 'multiple_choices',
                    message: 'Required',
                    test: val =>
                        val?.every(
                            ({ choice }, index) => {
                                if (index === 0 || index === 1) {
                                    return !!choice
                                }
                                return true
                            }
                        )
                }),

        })
    )
});
export default AddDetails;
