import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  createContext,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  MiniMap,
  Controls,
  Background,
  removeElements,
  useZoomPanHelper,
} from "react-flow-renderer";
import Sidebar from "./components/sidebar";
import { ToastContainer, toast } from "react-toastify";
import Window from "./components/window";
import LeftBar from "./components/leftbar";
import ButtonEdge from "./components/buttonEdge";
import { useDispatch, useSelector } from "react-redux";
import CustomNode from "./components/customNode";
import CustomNodeDefault from "./components/coutomNodeDefault";
import FirstCustomNode from "./components/firstCustomNode";
import CustomNodeDefaultOne from "./components/customNodeDefaultOne";
import uuid from "react-uuid";
import { v4 as uuidv4 } from "uuid";
import Button from "react-bootstrap/Button";
import {
  updateLpModule,
  updateLpModuleAutoSave,
  getNodesLpList,
  getManagerLearningPath,
} from "../../services";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import InfoModal from "../../../../shared/components/infoModal";
export const FlowContext = createContext();
const edgeTypes = {
  buttonedge: ButtonEdge,
};
const nodeTypes = {
  selectorNode: CustomNode,
  selectorNodeOne: CustomNodeDefault,
  selectorNodeTwo: FirstCustomNode,
  selectorNodeThree: CustomNodeDefaultOne,
};
let node_id = 0;
const getId = () => `${uuidv4()}`;
const LearningPathContainer = (props) => {
  let dispatch = useDispatch();
  const history = useHistory();
  const response = useSelector(
    (state) => state?.learningpath?.elements?.response
  );
  const saveLoading = useSelector(
    (state) => state?.learningpath?.updated_lp_module?.loading
  );
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { transform } = useZoomPanHelper();
  const [elements, setElements] = useState([]);
  const [nodeId, setNodeId] = useState(null);
  const [captureElementClick, setCaptureElementClick] = useState(true);
  const [nodeType, setNodeType] = useState("");
  const DEBOUNCE_SAVE_DELAY_MS = 2000;
  const [showModal, setShowModal] = useState(false);

  const onLoad = (_reactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);
    // _reactFlowInstance.setTransform({ x, y, zoom });
    _reactFlowInstance.project({ x: 100, y: 100 });
  };

  const saveElements = useCallback((elements, reactFlowInstance) => {
    let flow = reactFlowInstance?.toObject();
    if (flow) {
      let [x = 0, y = 0] = flow?.position;

      dispatch(
        updateLpModuleAutoSave(
          {
            elements: flow.elements,
            is_published: false,
            flow_position_x: x,
            flow_position_y: y,
            flow_zoom: flow.zoom || 0,
          },
          props?.lpId,
          function (response) {}
        )
      );
    }
  }, []);
  // const saveElements = (elements,reactFlowInstance) => {
  //         let flow = reactFlowInstance?.toObject()
  //         if(flow) {
  //             let [x = 0, y = 0] = flow?.position
  //
  //
  //             dispatch(
  //                 updateLpModuleAutoSave(
  //                     {
  //                         elements:elements,
  //                         is_published: false,
  //                         flow_position_x: x,
  //                         flow_position_y: y,
  //                         flow_zoom: flow.zoom || 0
  //                     },
  //                     props?.lpId,
  //                     function (response) {
  //
  //                     }
  //                 )
  //             );
  //
  //         }
  // }

  const debouncedSave = useCallback(
    debounce(async (elements, reactFlowInstance) => {
      saveElements(elements, reactFlowInstance);
    }, DEBOUNCE_SAVE_DELAY_MS),
    []
  );

  useEffect(() => {
    if (elements?.length >= 0) {
      debouncedSave(elements, reactFlowInstance);
      // saveElements(elements,reactFlowInstance);
    }
  }, [elements]);

  useEffect(() => {
    if (props.urlId) {
   
      // if(props?.nodes?.elements > 0) {
      let zoom =
        props?.nodes?.flow_zoom != 0 && props?.nodes?.flow_zoom
          ? props?.nodes?.flow_zoom
          : 1;
      let x = props?.nodes?.flow_position_x || 0;
      let y = props?.nodes?.flow_position_y || 0;
      transform({ x, y, zoom: zoom || 1 });
      reactFlowInstance?.setTransform({ x, y, zoom });
      // }
      setElements(props?.nodes?.elements);
    }
  }, [props?.nodes, reactFlowInstance]);

  const onConnect = (params) => {
    // if(findParent.data.selType != params.data.selType) {
    let mockId = getId();
    params.type = "buttonedge";
    params.animated = true;
    params.id = mockId;
    params.object_type = "edge";
    params.element_type = "buttonedge";
    params.is_root = false;
    params.source_handle = params.sourceHandle;
    params.data = elements.find((el) => el.id == params.target)?.data;
    let copyElements = [...elements];
    let secondCopy = [...elements];
    let arrayIndex = elements.findIndex((el) => el.id == params.source);
    let childrenIndex = elements?.findIndex((el) => el.id == params.target);
    copyElements[arrayIndex].is_default_pass_percentage =
      copyElements[arrayIndex].is_default_pass_percentage == true
        ? true
        : false;
    copyElements[childrenIndex].parent = copyElements[arrayIndex].id;
    copyElements[childrenIndex].parent = copyElements[arrayIndex].id;
    secondCopy.push(params);

    if (secondCopy[arrayIndex].content_type_name != "quiz") {

      let parentOfParent = secondCopy.find(
        (el) => el.id == secondCopy[childrenIndex]?.parent
      );
      let grandParent = secondCopy.find((el) => el.id == parentOfParent.parent);
      if (grandParent && grandParent?.content_type_name == "quiz") {
        let childrens = secondCopy.filter((el) => el.source == grandParent?.id);
        if (
          childrens.length &&
          childrens.find((el) => !el.range || el?.range?.from == null)
        ) {
          toast.error("Ranges are mandatory for quiz");
          setNodeId(grandParent.id);
          setNodeType(grandParent.data.selType);
          copyElements[childrenIndex].parent = null;
          return false;
        }
      }
    }

        setElements(copyElements)
        setTimeout(()=>{
            setElements((els) => addEdge(params, els));
    }, 10);
  };

  const onElementsRemove = (elementsToRemove) => {
    if (elements.length == 1) {
      setNodeId(null);
    }

    setElements((els) => removeElements(elementsToRemove, els));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const course = JSON.parse(event.dataTransfer.getData("application/course"));
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    let mockId = getId();
    const newNode = {
      id: mockId,
      type,
      is_root:
        type == "selectorNode" || type == "selectorNodeThree" ? true : false,
      position,
      parent: null,
      criteria: course.selType == "quiz" ? "percentage_range" : null,
      content_type_name: course.selType,
      object_id: course.id,
      transition_message: null,
      element_type: type,
      completion_message: null,
      available_on: null,
      object_type: "node",
      data: { label: `${course.name} `, ...course },
      x_position: position.x,
      y_position: position.y,
      is_created: false,
    };
    setElements((es) => es.concat(newNode));
  };

  const onElementClick = (event, element) => {
    event.stopPropagation();
    setNodeId(element.id);
    setNodeType(element.data.selType);
  };
  const onNodeDragStop = (event, node) => {
    let copyElements = [...elements];
    let findNodeIndex = elements.findIndex((el) => el.id == node.id);
    copyElements[findNodeIndex].position = node.position;
    copyElements[findNodeIndex].x_position = node.position.x;
    copyElements[findNodeIndex].y_position = node.position.y;
    setElements(copyElements);
  };

  // const onMoveEnd = (...rest) => {
  //   console.log("flowTransform", rest);
  // };
  // const onConnectStop = (event) => {
  //   console.log(event);
  // };

  const handleSave = (bool) => {
    let flow = reactFlowInstance.toObject();
    let [x = 0, y = 0] = flow.position;
    let isBool = false;
    let edges = [...flow.elements.filter((el) => el.object_type == "edge")];
    if (edges.length) {
      edges = edges.map((el) => {
        return {
          ...el,
          source: flow.elements.find((item) => item.id == el.source),
        };
      });
      for (let i = 0; i < edges.length; i++) {

        if (
          flow?.elements?.find((el) => el?.id == edges[i]?.source.id)
            ?.criteria != "pass_through_quiz" &&
          flow?.elements?.find((el) => el?.id == edges[i]?.source.id)
            ?.criteria != "terminal_quiz"
        ) {
          if (edges[i].source.content_type_name == "quiz" && !edges[i].range) {
            toast.error("Ranges are mandatory for quiz");
            setNodeId(edges[i].source.id);
            setNodeType(edges[i].source.content_type_name);
            isBool = true;
            break;
          } else if (
            edges[i].source.content_type_name == "quiz" &&
            edges[i].range &&
            edges[i]?.range?.from == null
          ) {
            toast.error("Ranges are mandatory for quiz");
            setNodeId(edges[i].source.id);
            setNodeType(edges[i].source.content_type_name);
            isBool = true;
            break;
          }
        }
      }
    }
    if (!isBool) {

      if (flow.elements.filter((el) => el.is_root == true).length == 0) {
        return toast.error("Elements must have root node");
      }

      if (
        flow.elements.filter(
          (el) =>
            el.is_root == false && el.type != "buttonedge" && el.parent == null
        ).length >= 1
      ) {
        let culprits = flow.elements.filter(
          (el) =>
            el.is_root == false && el.type != "buttonedge" && el.parent == null
        );
        return toast.error("Elements must have parent");
      }

      dispatch(
        updateLpModule(
          {
            elements: flow.elements,
            is_published: bool,
            flow_position_x: x,
            flow_position_y: y,
            flow_zoom: flow.zoom || 0,
          },
          props?.lpId,
          function (response) {
            // history.push('/learningpaths')
            if (bool) {
              props.parentState(2, props?.lpId, props?.learningPath, true);
            } else {
              history.push({
                pathname: "/learningpaths",
                state: { tab: "Drafts" },
              });
            }
          }
        )
      );
    }
  };


  let { lpId } = props;

  const handleLoader = () => {
    if (saveLoading) {
      return (
        <div className="loader-full-screen-course">
          <div className="loader zIndex">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      );
    } else {
      return <span></span>;
    }
  };
  return (
    <>
      <div>{handleLoader()}</div>
      <FlowContext.Provider
        value={[elements, setElements, nodeId, setNodeId, lpId, setNodeType]}
      >
        <div className="dndflow">
          <ReactFlowProvider>
            <LeftBar />
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                elements={elements}
                onConnect={onConnect}
                onElementsRemove={onElementsRemove}
                onElementClick={
                  captureElementClick ? onElementClick : undefined
                }
                onLoad={onLoad}
                // onConnectStop={onConnectStop}
                edgeTypes={edgeTypes}
                onDrop={onDrop}
                deleteKeyCode={false}
                onNodeDragStop={onNodeDragStop}
                nodeTypes={nodeTypes}
                key="edge-with-button"
                onDragOver={onDragOver}
                // onMoveEnd={onMoveEnd}
                defaultZoom={1}
              >
                <MiniMap />
                <Controls />

                <Background />
              </ReactFlow>
            </div>
          </ReactFlowProvider>

          {/*<Window/>*/}
          <Sidebar
            nodeId={nodeId}
            setNodeId={setNodeId}
            nodeType={nodeType}
            transition
          />
        </div>
      </FlowContext.Provider>
      <div className="add-module-footer d-flex justify-content-end">
        <div className="button-wrap double-btn">
          <Button
            variant="dark"
            className="btn-m"
            name="save"
            data-flag="action-1"
            disabled={elements?.length == 0 || saveLoading}
            onClick={() => handleSave(false)}
          >
            Save
          </Button>

          <Button
            variant="primary"
            name="publish"
            data-flag="action-2"
            className="btn-m"
            disabled={elements?.length == 0 || saveLoading}
            onClick={() => setShowModal(true)}
          >
            Publish
          </Button>
          {/*<Button variant="primary" className="btn-m">Continue</Button>*/}
        </div>
      </div>
      <InfoModal
        show={showModal}
        heading={"Publish Learning Paths"}
        desctiption={"Learning Paths once published cannot be edited"}
        button_text={"Yes, Continue"}
        type={"info"}
        handleSubmit={() => {
          handleSave(true);
          setShowModal(false);
        }}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};
export default LearningPathContainer;
