import {createAction, handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {
    _themeSettings

} from '~shared/httpService/api';


const initialState = {
       selectedTheme:'',
       selectedLogo:'',
    theme : { loading: false, response: {}, hasError: false, error: {} },
       loading: false, response: {}, hasError: false, error: {},
       hideLpQuizes:true
};

export const themeStart = createAction(ActionTypes.THEME_SELECT);
export const themeSuccess = createAction(ActionTypes.THEME_SELECT_SUCCESS, response => response);
export const themeError = createAction(ActionTypes.THEME_SELECT_ERROR, error => error);

export const themeSettings = (theme,logo,callback) => (dispatch) => {
    dispatch(themeStart());

    return _themeSettings(theme,logo).then((response) => {
        dispatch(themeSuccess(response));
        if(response) {
            callback(response)
        }
    }).catch((error) => {
        dispatch(themeError(error));
    });
};

export const logoStart = createAction(ActionTypes.LOGO_SELECT);
export const logoSuccess = createAction(ActionTypes.LOGO_SELECT_SUCCESS, response => response);
export const logoError = createAction(ActionTypes.LOGO_SELECT_ERROR, error => error);

export const logoSettings = (theme,logo) => (dispatch) => {
    dispatch(logoStart());
    return _themeSettings(theme,logo).then((response) => {
        dispatch(logoSuccess(response));
    }).catch((error) => {
        dispatch(logoError(error));
    });
};


const reducer = handleActions({
    //Theme settings
    [ActionTypes.THEME_SELECT]: (state) => ({
        ...state,
        theme:{ loading: true, response: {}, hasError: false, error: {} },
       
    }),
    [ActionTypes.THEME_SELECT_SUCCESS]: (state, action) => ({
        ...state,
        theme: {
        ...state.theme,
        response: action.payload, loading: false, hasError: false, error: {}
    }
      
    }),
    [ActionTypes.THEME_SELECT_ERROR]: (state, action) =>  ({
        ...state,
        theme: {
            ...state.theme,
            error: action.payload, loading: false, hasError: true, response: {}
        }
       
    }),


    //LOGO
    [ActionTypes.LOGO_SELECT]: (state) => ({
        ...state,
        selectedLogo:'',
        loading: true, response: {}, hasError: false, error: {},
    }),
    [ActionTypes.LOGO_SELECT_SUCCESS]: (state, action) => ({
        ...state,
        selectedLogo:action.payload,
        loading: false, response:action.payload, hasError: false, error: {},
    }),
    [ActionTypes.LOGO_SELECT_ERROR]: (state, action) =>  ({
        ...state,
        selectedLogo:'',
        loading: false, response:{}, hasError: true, error: action.payload,
    }),





}, initialState);

export default reducer;
