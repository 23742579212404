import React, {useEffect,useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Button} from 'react-bootstrap';
import {Link,useLocation,useHistory} from 'react-router-dom';
import AvailableCourses from '../components/availablecourses/container';
import DraftCourses from "../components/draftcourses/container";
import AdminCourse from '../components/admincourses/container';
import AdminDraftCourse from '../components/admindraftcourses/container'
import DeletedCourses from "../components/deletedCourses/container";
import {ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    restoreBulkCourse,
    permanentlyBulkDeleteCourse,
    getDeletedCourses,
    getDraftCoursesManagerList
} from '../services';
import Spinner from "react-bootstrap/Spinner";
import infoModal from "../../../shared/components/infoModal";
import InfoModal from "../../../shared/components/infoModal";
import {Helmet} from "react-helmet";

function CourseManagement ({match}){
    const dispatch = useDispatch();
    const history = useHistory()
    let location = useLocation()
    const [dateRange, setDateRange] = useState([null, null]);
    const [showModal,setShowModal] = useState(false)
    const [startDate, endDate] = dateRange;
    const permanentBulkLoading = useSelector(state => state?.course?.bulk_delete_course?.loading);
    const permanentBulkRestoreLoading = useSelector(state => state?.course?.restore_bulk_course?.loading);

    const [search, setSearch] = useState('');
    const [key, setKey] = useState('AvailableCourses');
    const [state,setState] = useState({
        courseIds:[]

    })
    const getCourseIds = (data) =>{
        setState({...state,courseIds:data})
    }
     const matchUrl = match.path;
    const { user } = useSelector(state => state.auth);
    const handleBulkDelete = (e) =>{
        let course_ids = state.courseIds.map((el)=>{
            return {
                id:el
            }
        })
        let {courseIds} = state
        let tempObj ={course_ids}
        dispatch(permanentlyBulkDeleteCourse(tempObj,function (res){
            if(res){
                setTimeout(()=>{
                    dispatch(getDeletedCourses(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate));
                    dispatch(getDraftCoursesManagerList());
                    courseIds = []
                    setState({...state, courseIds:courseIds})
                },500)
            }
        }));
    }

    useEffect(()=>{
        if(matchUrl.indexOf('courses') > -1){

            setKey('Drafts')
        }else if(location?.state?.active){
             setKey( location?.state?.active  )
        }

    },[])

    useEffect(()=>{
       if(location?.state?.key){
            setKey(location.state.key);
       }

    },[location]);

    const handleBulkRestore = (e) =>{
        e.stopPropagation()

        let course_ids = state.courseIds.map((el)=>{
            return {
                id:el
            }
        })
        let {courseIds} = state
        let tempObj ={course_ids}
        dispatch(restoreBulkCourse(tempObj,function (res){
            if(res){
                setTimeout(()=>{
                    dispatch(getDeletedCourses(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate));
                    dispatch(getDraftCoursesManagerList());
                    courseIds = []
                    setState({...state, courseIds:courseIds})
                },500)
            }
        }));

    }

    const setKeyFromTab = (value) => {
        setKey(value)
    }

    const handlePush = () => {
        history.push({
            pathname: '/coursebuilder',
            state: { active: key }
        })

    }
    const handleModal = (e) => {
        e.stopPropagation()
        setShowModal(true)

    }
   return (
    <div className="tab-wrapper">
          <Helmet>
                <title>Course Library - Cultural Engagement  </title>
            </Helmet>
        <ToastContainer autoClose={2000} />
        {state.courseIds && state.courseIds.length ?
        <>
            <Button className="tab-right-button1" onClick={(e)=>handleBulkRestore(e)}>

                {permanentBulkRestoreLoading ?
                    <Spinner animation="border" variant="warning"/>

                    :
                    <>
                        Restore All</>
                }



            </Button>
            <Button className="tab-right-butto2" onClick={(e)=>handleModal(e)}>

                {permanentBulkLoading ?
                    <Spinner animation="border" variant="warning"/>

                    :
                    <>
                        Delete All</>
                }




            </Button>


        </>

        :

            ''

        }

      <Button className="tab-right-button" onClick={handlePush}>Create New Course</Button>
        <Tabs activeKey={key}
            id="controlled-tab-example" className="m-b-30"
            onSelect={(k) => setKey(k)}
        >
       <Tab eventKey="AvailableCourses" title="Published Courses">
          <AvailableCourses  setKeyFromTab={setKeyFromTab}/>
       </Tab>
       <Tab eventKey="Drafts" title="Drafts">
       <DraftCourses/>
       </Tab>
        {user && user?.is_admin_institution == false ?
        < Tab eventKey="Admin" title="Template Library">
            <AdminCourse type = {user?.user_type}/>
            </Tab>
          :
            ''

        }

        <Tab eventKey="Deleted" title="Deleted Courses">
            <DeletedCourses getCourseIds={getCourseIds}/>
        </Tab>
        {/*<Tab eventKey="Admin Draft" title="Draft Courses By Admin">*/}
        {/*    <AdminDraftCourse/>*/}
        {/*</Tab>*/}

    </Tabs>
        <InfoModal
            show={showModal}
            heading={'Delete Courses Forever ?'}
            desctiption ={''}
            button_text={'Yes, Continue'}
            type={'del'}
            handleSubmit={()=> {handleBulkDelete();setShowModal(false)}}
            onHide={() => setShowModal(false)}

        />
 </div>
   );
}
export default CourseManagement;
