import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TabModules from "./components/tabs";
// import {getLearnerLearningPathNodesDetail} from "../../../services";
import { getLearnerLearningPathNodesDetail } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import CourseCompleted from "~/shared/components/coursecompleted";
import CourseTransition from "../learnercoursepopview/components/transitionmessagecourse";

const LearnerModulePopView = (props) => {
  const dispatch = useDispatch();
  const response = useSelector(
    (state) => state?.learningpath_learner?.learner_lp_node_detail?.response
  );
  const loading = useSelector(
    (state) => state?.learningpath_learner?.learner_lp_node_detail?.loading
  );
  const [state, setState] = useState({
    node: null,
    completed: null,
  });
  const [isTransition, setIsTransition] = useState(false);

  const [complete, setComplete] = useState(false);

  const handleCallback = (childData) => {
    setComplete(true);
  };

  useEffect(() => {
    if (props.nodeId) {
      if (props.nodeId) {
        dispatch(
          getLearnerLearningPathNodesDetail(
            props.lpId,
            props.nodeId,
            function (res) {
              let tempObj = {
                ...res,
                template: "quiz",
                questions: res.quiz_questions,
              };
              setState({ ...state, node: tempObj });
              if (res?.transition_message) {
                setIsTransition(true);
              } else {
                setIsTransition(false);
              }
            }
          )
        );
      }
    }
  }, []);
  const onHide = () => {
    props.onHide();
    setIsTransition(false);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={onHide}
        onExit={() => setComplete(false)}
        className="common-modal full-screen-modal inner-popup add-module-content-modal quiz-completed-pop"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ overflowY: "auto" }}>
          {loading ? (
            <div className="row justify-content-center mt-1">
              <Spinner animation="border" variant="danger" />
            </div>
          ) : (
            <>
              {!loading ? (
                <div className="h-100">
                  {isTransition ? (
                    <CourseTransition
                      setIsTransition={setIsTransition}
                      data={state?.node}
                    />
                  ) : (
                    <TabModules
                      onHide={onHide}
                      lpId={props.lpId}
                      parentCallback={handleCallback}
                      values={state?.node}
                      node={state?.node}
                      name={state?.node?.node_object_name}
                      completedCount={state?.node?.completed_modules_count}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div className="row justify-content-center mt-5">
                    No Modules Available
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default LearnerModulePopView;
