import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from "./ckQues";
import {FormControl, FormLabel} from "react-bootstrap";
function TrueFalse(props) {

    const { values,errors,index,setFieldValue,questionsErrors,questionsTouched,handleBlur,setValues} = props
    const handleAnswer = (e) =>{

        const questions =[...values.questions]
        questions[index].right_answer = e.target.value
        if(e.target.value == 'True'){
            questions[index].multiple_choices[0]={id:questions[index].multiple_choices[0].id ? questions[index].multiple_choices[0].id :'' , choice: 'True',is_correct_answer:'True'}
            questions[index].multiple_choices[1]={id:questions[index].multiple_choices[1].id ? questions[index].multiple_choices[1].id :'',choice: 'False',is_correct_answer:'False'}

        }else if(e.target.value == 'False'){
            questions[index].multiple_choices[0]={id:questions[index].multiple_choices[0].id ? questions[index].multiple_choices[0].id :'' , choice: 'True',is_correct_answer:'False'}
            questions[index].multiple_choices[1]={id:questions[index].multiple_choices[1].id ? questions[index].multiple_choices[1].id :'',choice: 'False',is_correct_answer:'True'}

        }
        setValues({...values, questions})
       
    }

    return (
        <div className="module-quiz-add-wrap">


            <Row>

                <Col md={12}>
                    <div className="form-input-wrap">
                        <Form.Label>Add Question</Form.Label>
                        <CkEditorQuestion questionsErrors={questionsErrors} questionsTouched={questionsTouched} name={`questions.${index}.question`} setFieldValue={setFieldValue} values={values} handleBlur={handleBlur} index={index}/>

                    </div>
                </Col>
                <Col xs={12}>
                    <Form.Control
                        type="hidden" placeholder="Option 1"
                        value={values?.questions[index]?.multiple_choices[0]?.choice}
                        name={`questions.${index}.multiple_choices.${0}.choice`}
                        onBlur={e=>setFieldValue(`questions.${index}.multiple_choices.${0}.choice`,e.target.value)}
                        // onChange={e=>setFieldValue(`questions.${index}.multiple_choices.${0}.choice`,e.target.value)}
                    />

                    <Form.Control
                        type="hidden" placeholder="Option 2"
                        name={`questions.${index}.multiple_choices.${1}.choice`}
                        value={values?.questions[index]?.multiple_choices[1]?.choice}
                        onBlur={e=>setFieldValue(`questions.${index}.multiple_choices.${1}.choice`,e.target.value)}
                        defaultValue={e=>setFieldValue(`questions.${index}.multiple_choices.${1}.choice`,e.target.value)}
                    />

                    <Row>

                            <Col md={4}>
                                <div className="form-input-wrap">
                                    <FormLabel>Right Answer</FormLabel>
                                    <FormControl as="select"
                                                 name={`questions.${index}.right_answer`}
                                                 onChange={e=> handleAnswer(e)}
                                                 onBlur={handleBlur}
                                                 value = {values?.questions[index]?.right_answer}
                                                 isInvalid={!!questionsErrors?.right_answer && questionsTouched?.right_answer }
                                    >

                                        <option value="">Choose question type</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>



                                    </FormControl>

                                </div>
                            </Col>
                


                        <Col md={4}>
                            <div className="form-input-wrap" >
                                <Form.Label>Score for the Question</Form.Label>
                                <Form.Control
                                    type="text" placeholder="Score"
                                    name={`questions.${index}.score`}
                                    defaultValue={values?.questions[index]?.score}
                                    // onBlur={e => setFieldValue(`questions.${index}.score`, e.target.value)}
                                    isInvalid={!!questionsErrors?.score && !!questionsTouched?.score }
                                    onChange={e => setFieldValue(`questions.${index}.score`, e.target.value)}
                                />
                                <FormControl.Feedback type="invalid">
                                    {questionsErrors?.score}
                                </FormControl.Feedback>
                            </div>


                        </Col>

                    </Row>
                </Col>
            </Row>

        </div>
    );
}

export default TrueFalse;

