import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { getManagerQuiz } from "../../../services";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import { deleteQuiz, getManagerQuizzesList } from "../../../services";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import QuizModulePopView from "../../managerQuizPreview";

const QuizDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlId = props?.match?.params?.id;
  const response = useSelector(
    (state) => state?.quiz?.get_quiz_manager?.response
  );
  const loading = useSelector(
    (state) => state?.quiz?.get_quiz_manager?.loading
  );
  const [modalShow, setModalShow] = React.useState(false);

  const [state, setState] = useState({
    quiz: [],
    popupView: false,
    previewPopUp: false,
    values: [],
  });

  const handlePreviewModel = () => {
    setState({ ...state, values: state?.quiz, previewPopUp: true });
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        className="custom-modal confirmation-popup delete-confirmation inner-popup"
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-content-wrap text-center">
            <h3 className="modal-title">Delete Quiz?</h3>
            {/* <p>Are You Sure To Delete The Members? </p> */}
            <div className="d-flex justify-content-center buttons-wrap double-btn">
              <button
                type="button"
                className="btn border-btn white-btn"
                onClick={() => props.onHide()}
              >
                No, I need to recheck
              </button>
              <Link className="m-l-20">
                <Button onClick={props.handleSubmit} type="button">
                  Yes, Continue
                </Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const handleDeleteQuiz = () => {
    const row = { id: urlId };

    dispatch(deleteQuiz(row));
    setTimeout(() => {
      dispatch(getManagerQuizzesList());
    }, 500);
    history.push("/quizmanagement");
  };
  useEffect(() => {
    if (urlId) {
      dispatch(getManagerQuiz(urlId, function (res) {}));
    }
  }, []);

  useEffect(() => {
    setState({ ...state, quiz: response });
  }, [response]);
  const handleEdit = () => {
    history.push({
      pathname: `/quizbuilder/${state?.quiz?.id}`,
      state: {
        active: state?.quiz?.is_published ? "AvailableQuizzes" : "Drafts",
      },
    });
  };

  if (loading) {
    return (
      <div className="loaderQuizzes">
        <Spinner animation="border" variant="danger" />
      </div>
    );
  } else {
    return (
      <div className="quiz-det-wrap quiz-limited-width">
        {/*<h3 className="sec-title m-b-30">Title here</h3>*/}
        <div className="whitebox padding-box shadow-box quiz-det-box">
          <div className="d-flex justify-content-between align-items-start">
            <h4>{state?.quiz?.name}</h4>
            <div className="d-flex justify-content-end">
              <div className="quiz-set-buttond double-btn">
                <button
                  className="btn btn-primary"
                  disabled={
                    state?.quiz?.questions &&
                    state?.quiz?.questions?.length &&
                    state?.quiz?.questions[0]?.question_type?.length <= 0
                  }
                  onClick={handlePreviewModel}
                >
                  Preview Quiz
                </button>
                <button className="btn btn-primary" onClick={handleEdit}>
                  Edit Quiz
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => setModalShow(true)}
                >
                  Delete Quiz
                </button>
              </div>
            </div>
          </div>
          <div className="choice-box-group d-flex justify-content-end m-t-20">
            <div className="choice-box">
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
              <span className="choice-text">
                <span>Pass Score:</span>
                {state?.quiz?.pass_score ? state?.quiz?.pass_score : "N/A"}
              </span>
            </div>
            <div className="choice-box">
              <i class="fa fa-user-o" aria-hidden="true"></i>
              <span className="choice-text">
                <span>Questions Count:</span>
                {state?.quiz?.questions_count?.count
                  ? state?.quiz?.questions_count?.count
                  : "0"}{" "}
              </span>
            </div>
          </div>
          <div className="grey-box-out-quiz">
            <p>{state?.quiz?.description ? state?.quiz?.description : "N/A"}</p>
          </div>
        </div>

        <MyVerticallyCenteredModal
          show={modalShow}
          handleSubmit={() => {
            handleDeleteQuiz();
            setModalShow(false);
          }}
          onHide={() => setModalShow(false)}
          //   onExited={ModalExit}
        />
        <QuizModulePopView
          show={state.previewPopUp}
          values={state?.values}
          onHide={() => setState({ ...state, previewPopUp: false })}
        />
      </div>
    );
  }
};
export default QuizDetails;
