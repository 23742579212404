import React, { useEffect,useState } from "react";
// import Breadcrumb from '../../../../../layout/breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AccordianItem from '../accordian'
// import {AccordionWithOpenandCloseIcon } from '../../../../../shared/accordian/accordianComponent'
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { detailSurvey } from '~/modules/surveys/services';
import {getLearnerLearningPathDetail,getLearnerLearningPathNodes} from '../../../../learnerLearningPath/services'
import Spinner from "react-bootstrap/Spinner";

function LearningPathLearnerDetails({ match }) {
   const history = useHistory()
   const dispatch = useDispatch();
   const id = match.params.id
   const response = useSelector(state => state?.learningpath_learner ?.learner_lp_detail?.response);
   const loading = useSelector(state => state?.learningpath_learner?.learner_lp_detail?.loading);
      const [state,setState] = useState({
         learningPath:{},
         other_learning_paths:[],
         nodes:[]

      })
   useEffect(() => {
      dispatch(getLearnerLearningPathDetail(id,function (res){
      }))
   }, [])
   useEffect(()=>{
      setState({...state,learningPath: response.result,other_learning_paths:response?.extra_learning_paths,nodes:response?.result?.nodes})
    },[response,])


   const checkTextStatus  = () => {
         switch (state?.learningPath?.status){
            case 'Completed' :

               return 'Completed'
            case 'In Progress' :
               return  'Continue'
            case 'New' :
               return  'Start'
            default :
                return  'Start'


         }
   }
   if(!loading) {
      return (
          <div className="learning-path-listing learner-course">
             {/* <Breadcrumb parent="Learning paths" title="Learning pathname" /> */}
             <Row>

                <div className="det-middle-align">
                <div className="d-flex justify-content-between learning-path-sec-head">
                      <h3 className="sec-title">{state?.learningPath?.learning_path_name}</h3>
                     <Button type="button"   disabled={ state?.nodes?.length == 0}  onClick={()=>history.push(`/learningpath/${id}/nodes`)}>
                         {state?.nodes && state?.nodes?.length > 0 ? checkTextStatus() : "Don't have Nodes"}
                         </Button>
                   </div>
                   <div className="learning-content-wrap p-r-50">
                      <div className="learning-image-wrap">
                         <div className="learing-banner text-center">
                            <img src={state?.learningPath?.banner_image}/>
                         </div>
                         <div className="d-flex justify-content-center text-center learning-path-details">
                            <div className="learning-path-det-item">
                               <div className="icons-wrap">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14.088" height="14.728"
                                       viewBox="0 0 14.088 14.728">
                                     <g transform="translate(-5.191 -4.923)">
                                        <g transform="translate(7.753 4.923)">
                                           <path class="a"
                                                 d="M171.868,4.923a4.483,4.483,0,1,0,4.483,4.483A4.455,4.455,0,0,0,171.868,4.923Zm0,7.684a3.2,3.2,0,1,1,3.2-3.2A3.171,3.171,0,0,1,171.868,12.607Z"
                                                 transform="translate(-167.385 -4.923)"/>
                                        </g>
                                        <g transform="translate(5.191 12.607)">
                                           <path class="a"
                                                 d="M135.044,123.077A7.024,7.024,0,0,0,128,130.121h1.281a5.763,5.763,0,1,1,11.526,0h1.281A7.024,7.024,0,0,0,135.044,123.077Z"
                                                 transform="translate(-128 -123.077)"/>
                                        </g>
                                     </g>
                                  </svg>
                               </div>
                               <p className="assigned-by-text">Due Date</p>
                               <h5>{state?.learningPath?.due_date}</h5>

                            </div>
                            {/*<div className="learning-path-det-item">*/}
                            {/*   <div className="icons-wrap">*/}
                            {/*      <svg xmlns="http://www.w3.org/2000/svg" width="17.966" height="18.516"*/}
                            {/*           viewBox="0 0 17.966 18.516">*/}
                            {/*         <g transform="translate(0.2 -3.723)">*/}
                            {/*            <g transform="translate(1.464 13.622)">*/}
                            {/*               <path class="a"*/}
                            {/*                     d="M41.947,241.231a2.562,2.562,0,1,0,2.562,2.562A2.546,2.546,0,0,0,41.947,241.231Zm0,4.391a1.83,1.83,0,1,1,1.83-1.83A1.812,1.812,0,0,1,41.947,245.622Z"*/}
                            {/*                     transform="translate(-39.385 -241.231)"/>*/}
                            {/*            </g>*/}
                            {/*            <g transform="translate(0 18.014)">*/}
                            {/*               <path class="a"*/}
                            {/*                     d="M4.025,359.385A4.014,4.014,0,0,0,0,363.41H.732a3.294,3.294,0,1,1,6.587,0h.732A4.014,4.014,0,0,0,4.025,359.385Z"*/}
                            {/*                     transform="translate(0 -359.385)"/>*/}
                            {/*            </g>*/}
                            {/*            <g transform="translate(10.979 13.622)">*/}
                            {/*               <path class="a"*/}
                            {/*                     d="M297.947,241.231a2.562,2.562,0,1,0,2.562,2.562A2.546,2.546,0,0,0,297.947,241.231Zm0,4.391a1.83,1.83,0,1,1,1.83-1.83A1.812,1.812,0,0,1,297.947,245.622Z"*/}
                            {/*                     transform="translate(-295.385 -241.231)"/>*/}
                            {/*            </g>*/}
                            {/*            <g transform="translate(9.515 18.014)">*/}
                            {/*               <path class="a"*/}
                            {/*                     d="M260.025,359.385A4.014,4.014,0,0,0,256,363.41h.732a3.294,3.294,0,1,1,6.587,0h.732A4.014,4.014,0,0,0,260.025,359.385Z"*/}
                            {/*                     transform="translate(-256 -359.385)"/>*/}
                            {/*            </g>*/}
                            {/*            <g transform="translate(6.221 3.923)">*/}
                            {/*               <path class="a"*/}
                            {/*                     d="M169.947,4.923a2.562,2.562,0,1,0,2.562,2.562A2.546,2.546,0,0,0,169.947,4.923Zm0,4.391a1.83,1.83,0,1,1,1.83-1.83A1.812,1.812,0,0,1,169.947,9.314Z"*/}
                            {/*                     transform="translate(-167.385 -4.923)"/>*/}
                            {/*            </g>*/}
                            {/*            <g transform="translate(4.757 8.314)">*/}
                            {/*               <path class="a"*/}
                            {/*                     d="M132.025,123.077A4.014,4.014,0,0,0,128,127.1h.732a3.294,3.294,0,1,1,6.587,0h.732A4.014,4.014,0,0,0,132.025,123.077Z"*/}
                            {/*                     transform="translate(-128 -123.077)"/>*/}
                            {/*            </g>*/}
                            {/*         </g>*/}
                            {/*      </svg>*/}
                            {/*   </div>*/}
                            {/*   <p className="assigned-by-text">Group</p>*/}
                            {/*   <h5>Designers</h5>*/}
                            {/*</div>*/}
                            {/*<div className="learning-path-det-item">*/}
                            {/*   <div className="icons-wrap">*/}
                            {/*      <svg xmlns="http://www.w3.org/2000/svg" width="15.416" height="16.012"*/}
                            {/*           viewBox="0 0 15.416 16.012">*/}
                            {/*         <path class="a"*/}
                            {/*               d="M37.233,1.6h-1.4V.526a.526.526,0,1,0-1.053,0V1.6H28.963V.526a.526.526,0,0,0-1.053,0V1.6h-1.4a2.248,2.248,0,0,0-2.246,2.246v9.725a2.248,2.248,0,0,0,2.246,2.246H37.233a2.248,2.248,0,0,0,2.246-2.246V3.842A2.248,2.248,0,0,0,37.233,1.6ZM25.316,3.842a1.194,1.194,0,0,1,1.193-1.193h1.4v1.07a.526.526,0,0,0,1.053,0V2.649h5.815v1.07a.526.526,0,1,0,1.053,0V2.649h1.4a1.194,1.194,0,0,1,1.193,1.193V6.25H25.316ZM37.233,14.759H26.509a1.194,1.194,0,0,1-1.193-1.193V7.3h13.11v6.264A1.194,1.194,0,0,1,37.233,14.759Z"*/}
                            {/*               transform="translate(-24.163 0.1)"/>*/}
                            {/*      </svg>*/}
                            {/*   </div>*/}
                            {/*   <p className="assigned-by-text">Assigned On</p>*/}
                            {/*   <h5>08 October 2020</h5>*/}
                            {/*</div>*/}
                            <div className="learning-path-det-item">
                               <div className="icons-wrap">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17.632" height="17.632"
                                       viewBox="0 0 17.632 17.632">
                                     <g transform="translate(0.05 0.05)">
                                        <path class="a"
                                              d="M8.766,0a8.766,8.766,0,1,0,8.766,8.766A8.776,8.776,0,0,0,8.766,0Zm0,16.252a7.486,7.486,0,1,1,7.486-7.486A7.495,7.495,0,0,1,8.766,16.252Z"/>
                                        <g transform="translate(7.699 4.287)">
                                           <path class="a"
                                                 d="M228.909,129.676h-2.773v-3.839a.64.64,0,1,0-1.28,0v4.479a.64.64,0,0,0,.64.64h3.412a.64.64,0,1,0,0-1.28Z"
                                                 transform="translate(-224.857 -125.197)"/>
                                        </g>
                                     </g>
                                  </svg>
                               </div>
                               <p className="assigned-by-text">{state?.learningPath?.days_left && state?.learningPath?.days_left < 0  ?
                               'Days Passed'
                                   :
                                   'Days Left'
                               }
                                </p>
                               <h5>{state?.learningPath?.days_left && state?.learningPath?.days_left < 0 ?   `${state?.learningPath?.days_left}`?.split('-')[1] :  state?.learningPath?.days_left}</h5>
                            </div>
                         </div>
                      </div>
                      <div className="learning-det-cont">
                         <div className="learning-det-boxes">
                            <h4 className="sec-sub-head">Description</h4>
                            <p>{state?.learningPath?.description}
                            </p>
                         </div>
                      </div>
                      <div className="learning-det-cont m-t-50">
                         <div className="learning-det-boxes">
                            <div className="d-flex justify-content-between">
                               <h4 className="sec-sub-head">Nodes</h4>
                               {/*<div className="d-flex  align-items-center justify-content-end learing-detail-progress">*/}
                                  {/*<div className="learning-progress progress">*/}
                                  {/*   <div className="progress-bar" style={{width: '70%'}}>&nbsp;</div>*/}
                                  {/*</div>*/}
                                  {/*<span className="progress-text">1/4</span>*/}
                               {/*</div>*/}
                            </div>
                            {/*<p>Welcome to the course library. The foundational sessions are aimed at establishing a*/}
                            {/*   biblical and theological*/}
                            {/*   framework on what is often a politicallyor socially motivated dialogue. Terms like race,*/}
                            {/*   multiculturalism,*/}
                            {/*   and diversity are essential topics to address on your campus to equip students how to*/}
                            {/*   engage them with a Christian worldview.*/}
                            {/*   We'd like to take some time to think about these things and consider what the Bible has*/}
                            {/*   to say about them.*/}
                            {/*</p>*/}
                         </div>
                      </div>
                      <div>
                         <AccordianItem nodes={state?.nodes} lp={state?.learningPath}/>
                      </div>
                   </div>
                </div>
                {/* <Col md={3}> */}
                   {/*<div className="sideboxes">*/}
                   {/*   {state.other_learning_paths && state.other_learning_paths.length  ? state.other_learning_paths.map((item)=>{*/}

                   {/*      return(*/}
                   {/*          <div className="common-card m-b-30">*/}
                   {/*             <div className="common-card-header">*/}
                   {/*                <h3 className="common-card-title">{item?.learning_path__name}</h3>*/}
                   {/*             </div>*/}
                   {/*             <div className="common-card-detail-wrap">*/}
                   {/*                <p>{item?.learning_path__description}</p>*/}
                   {/*                <div className="common-box-progress-wrap">*/}
                   {/*                   <div*/}
                   {/*                       className="d-flex  align-items-center justify-content-between w-100 learing-detail-progress">*/}
                   {/*                      <div className="learning-progress progress">*/}
                   {/*                         <div className="progress-bar" style={{width: '70%'}}>&nbsp;</div>*/}
                   {/*                      </div>*/}
                   {/*                      <span className="common-progress-text">7/10 Modules</span>*/}
                   {/*                   </div>*/}
                   {/*                </div>*/}
                   {/*                <div className="coommon-card-det-number d-flex justify-content-between">*/}
                   {/*            <span className="common-card-oth-det-left">Courses <span*/}
                   {/*                className="det-numb">6</span> </span>*/}
                   {/*                   <span className="common-card-oth-det-left">Quizzes <span*/}
                   {/*                       className="det-numb">2</span> </span>*/}
                   {/*                </div>*/}
                   {/*             </div>*/}
                   {/*             <div className="common-card-footer">*/}
                   {/*                <div className="common-card-footer-cont d-flex justify-content-between align-items-center">*/}

                   {/*                   <Link to={`/learningpath/${item?.learning_path__id}/nodes`}><Button type="button">Start</Button></Link>*/}
                   {/*                </div>*/}
                   {/*             </div>*/}
                   {/*          </div>*/}

                   {/*      )*/}
                   {/*   })*/}
                   {/*    :*/}
                   {/*       'No other learning paths'*/}
                   {/*   }*/}


                   {/*</div>*/}
                {/* </Col> */}
             </Row>
          </div>
      )
   }else{
      return (
         <div className="loader-middle-wrap">
         <Spinner animation="border" variant="danger" />
      </div>
      )
   }
};
export default LearningPathLearnerDetails;
