import React, { useEffect, useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PasswordChecklist from "react-password-checklist"
// import Button from 'react-bootstrap/esm/Button';
import { getProfile, updateProfile, changePassword } from '../../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import country from '../../../../../../shared/country.json';
import 'react-toastify/dist/ReactToastify.css';
import { resetStore, setPassword } from "../../../../../auth/services";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from 'react-toastify';

const ProfileBasicDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const response = useSelector(state => state.profile.profile_details.response);
    const loading = useSelector(state => state.profile.profile_update.loading);
    const update = useSelector(state => state.profile.profile_update.response);
    const { user } = useSelector(state => state.auth);

    const [isEdit, setIsEdit] = useState(false);
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false
    });

    const responsePassword = useSelector(state => state.profile.change_password.response);
    const loadingPwd = useSelector(state => state.profile.change_password.loading);
    const formikRef = React.useRef(null);
    // useEffect(() => {
    //     dispatch(getProfile(user?.user_id, user?.token));
    // }, []);


    const handleSubmit = (values) => {
        dispatch(updateProfile(user?.user_id, user?.token, values, function (response) {
            if (response) {
                setIsEdit(false);
                dispatch(getProfile(user?.user_id, user?.token));
            }
        }));
    };

    const handlePasswordSubmit = (values, onSubmitProps) => {


if((user?.is_password_set==false)) {
const {new_password1,new_password2}=values
    dispatch(setPassword({ new_password1, new_password2, token:user?.token }, history, function (response) {

       
        if (response) {
           
            let action = 'USER_LOGOUT'
            dispatch(resetStore(action))    
            history.push('/login')

           
    

        }

     }, function (err) {


     }));
} else{

        dispatch(changePassword(user?.token, values, function (response) {
            if (response) {
                let action = 'USER_LOGOUT'
                
                dispatch(resetStore(action))    
                history.push('/login')

            }
        }
            , function (error) {
                if (error) {
                    setTimeout(() => {
                        onSubmitProps.resetForm();
                    }, 1000);
                }
            }));
    };

}

    const handleHide = () => {
        setIsEdit(false)
    }

    const handleClickShowCurrentPassword = () => {
        setShowPassword({ ...showPassword, currentPassword: !showPassword.currentPassword });
    };

    const handleClickShowNewPassword = () => {
        setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword });
    };

    const handleClickShowConfirmNewPassword = () => {
        setShowPassword({ ...showPassword, confirmNewPassword: !showPassword.confirmNewPassword });
    };




    return (
        <>
        {/* <ToastContainer/> */}
        <div className="tab-content p-relative">
            <div className="d-flex justify-content-between m-b-20"><h3 className="tab-title">Basic Information</h3>
                <div className="btn-wrapper double-btn">
                    {/* <button type="button" className="btn border-btn">Cancel</button> */}
                    {!isEdit &&
                        <button className="btn btn-primary" onClick={() => setIsEdit(true)}>Edit</button>
                    }

                </div>
            </div>
            {!isEdit &&
                <Row>
                    <Col xs={12} sm={4}>
                        <div className="form-input-wrap">
                            <Form.Label>First Name</Form.Label>
                            <Form.Text>{response?.first_name}</Form.Text>
                        </div>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="form-input-wrap">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Text>{response?.last_name}</Form.Text>

                        </div>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="form-input-wrap">
                            <Form.Label>Email</Form.Label>
                            <div className="coupon-input-wrap p-relative">
                                <Form.Text>{response?.email}</Form.Text>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="form-input-wrap">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Text>{response?.mobile_number?  response?.mobile_number : '--'}</Form.Text>
                        </div>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="form-input-wrap">
                            <Form.Label>Employee ID</Form.Label>
                            <Form.Text>{response?.employee_id ? response?.employee_id : '--'}</Form.Text>
                        </div>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="form-input-wrap">
                            <Form.Label>Designation</Form.Label>
                            <Form.Text>{response?.designation? response?.designation : '--'}</Form.Text>
                        </div>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="form-input-wrap">
                            <Form.Label>Location</Form.Label>
                            <Form.Text>{response?.location ? response?.location : "--"}</Form.Text>
                        </div>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className="form-input-wrap">
                            <Form.Label>Country</Form.Label>
                            <Form.Text>{response?.country? response?.country : '--'}</Form.Text>
                        </div>
                    </Col>
                    <Col xs={12} sm={4}>
                            <div className="form-input-wrap">
                                <Form.Label>Institution</Form.Label>
                                <Form.Text>{response?.institution_name ? response?.institution_name : '--'}</Form.Text>
                            </div>
                        </Col>
                    {/* {user && user.user_type != 'learner' ?
                        <Col xs={12} sm={4}>
                            <div className="form-input-wrap">
                                <Form.Label>Institution</Form.Label>
                                <Form.Text>{response?.institution_name}</Form.Text>
                            </div>
                        </Col>
                        :
                        ''
                    } */}
                     {/* {user && user.user_type != 'learner' ?
                        <Col xs={12} sm={4}>
                            <div className="form-input-wrap">
                                <Form.Label>Institution</Form.Label>
                                <Form.Text>{response?.category_name? response?.category_name : 'No category is chosen'}</Form.Text>
                            </div>
                        </Col>
                        :
                        ''
                    } */}
                </Row>
            }
            {isEdit &&
                <Formik enableReinitialize={true} initialValues={{ first_name: response.first_name ? response.first_name : "", last_name: response.last_name ? response.last_name : "", email: response.email ? response.email : "", mobile_number: response.mobile_number ? response.mobile_number : "", employee_id: response.employee_id ? response.employee_id : "", designation: response.designation ? response.designation : "", location: response.location ? response.location : "", country: response.country ? response.country : "", institution_name: response.institution_name ? response.institution_name : "" }}
                    validationSchema={basicDetailsSchema}
                    onSubmit={handleSubmit}>
                    {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className="btn-wrapper double-btn d-flex justify-content-end after-edit-btn">
                                <button type="button" className="btn border-btn" onClick={handleHide} >Cancel</button>
                                <button type="submit" className="btn btn-primary"> {loading ?
                                    <Spinner animation="border" variant="danger" />
                                    :
                                    'Save'

                                }</button>
                            </div>
                            <Row>
                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" name="first_name" placeholder="Your First Name" className="input-item" value={values.first_name} onChange={handleChange} isInvalid={!!errors.first_name} />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.first_name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" name="last_name" placeholder="Your Last Name" className="input-item" value={values.last_name} onChange={handleChange} isInvalid={!!errors.last_name} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.last_name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>Email</Form.Label>
                                        <div className="coupon-input-wrap p-relative">
                                            <input type="text" name="email" placeholder="Email" className="form-control input-item" value={values.email} onChange={handleChange} isInvalid={!!errors.email} readOnly />
                                            {/* <Button type="submit" className="apply-input-btn transparent-btn">Verify</Button> */}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </div>

                                </Col>
                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" name="mobile_number" placeholder="Your Phone Number" className="input-item" value={values.mobile_number} onChange={handleChange} isInvalid={!!errors.mobile_number} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.mobile_number}
                                        </Form.Control.Feedback>
                                    </div>

                                </Col>
                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>Employee ID</Form.Label>
                                        <Form.Control type="text" name="employee_id" placeholder="Your Employee ID" className="input-item" value={values.employee_id} onChange={handleChange} isInvalid={!!errors.employee_id} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.employee_id}
                                        </Form.Control.Feedback>
                                    </div>

                                </Col>
                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>Designation</Form.Label>
                                        <Form.Control type="text" name="designation" placeholder="Your Designation" className="input-item" value={values.designation} onChange={handleChange} isInvalid={!!errors.designation} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.designation}
                                        </Form.Control.Feedback>
                                    </div>

                                </Col>
                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>Location</Form.Label>
                                        <Form.Control type="text" name="location" placeholder="Your Location" className="input-item" value={values.location} onChange={handleChange} isInvalid={!!errors.location} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.location}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>

                                
                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>Country</Form.Label>
                                        {/* <Form.Control type="text" name="country" placeholder="Country" className="input-item" value={values.country} onChange={handleChange} isInvalid={!!errors.country}/> */}
                                        <Select name="country" options={country} value={country ? country.find(option => option.value === values.country) : ''} onChange={(e) => { handleChange, setFieldValue("country", e.value); }} placeholder="Country" className="input-item" isInvalid={!!errors.country} />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.country}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>

                                <Col xs={12} sm={4}>
                                    <div className="form-input-wrap">
                                        <Form.Label>Institution</Form.Label>
                                        <Form.Control type="text" name="institution" placeholder="Institution" className="input-item" value={values.institution_name} onChange={handleChange} isInvalid={!!errors.institution_name} readOnly />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.institution_name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>

            }
            <Formik
                innerRef={formikRef}
                validationSchema={resetPasswordSchema}
                onSubmit={handlePasswordSubmit}
                initialValues={{
                    old_password: '',
                    new_password1: '',
                    new_password2: '',
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="form-footer  border-footer d-flex justify-content-between">
                            <h3 className="tab-title"> {user?.is_password_set ?'Change Password': 'Create Password'}</h3>
                            <div className="btn-wrapper double-btn">
                                <button type="reset" className="btn border-btn" onClick={() => formikRef?.current?.resetForm()}>Cancel</button>
                                <button type="submit" className="btn btn-primary">{loadingPwd ?
                                    <Spinner animation="border" variant="danger" />
                                    :
                                    'Save'

                                }</button>
                            </div>
                        </div>
                        <Row>
                            <Col xs={12} sm={4} className={user?.is_password_set == false && `d-none` }>
                                {user?.is_password_set &&
                                <div className= 'form-input-wrap cus-icon' style={{ position: 'relative' }} >
                                    <Form.Label>Current Password</Form.Label>
                                    <Form.Control
                                        type={showPassword.currentPassword ? 'text' : 'password'}
                                        name="old_password" placeholder="Your Current Password" className="input-item" value={values.old_password}
                                        onChange={handleChange}
                                        isInvalid={!!errors.old_password} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.old_password}
                                    </Form.Control.Feedback>


                                    <span
                                        style={{ position: 'absolute', top: 30, right: 10, cursor: 'pointer'}}
                                        onClick={handleClickShowCurrentPassword}

                                    >
                                        {showPassword.currentPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>}
                                    </span>


                                </div>
}
                            </Col>
                            <Col xs={12} sm={4}>
                                <div className="form-input-wrap" style={{ position: 'relative' }}>
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type={showPassword.newPassword ? 'text' : 'password'}
                                        name="new_password1" placeholder="Your New Password" className="input-item" value={values.new_password1}
                                        onChange={handleChange}
                                    // isInvalid={!!errors.new_password1}


                                    />

                                    <div >
                                    <PasswordChecklist
                                        rules={["minLength", "number", "capital",]}
                                        minLength={8}
                                        value={values.new_password1}
                                        // valueAgain={passwordAgain}
                                        onChange={(isValid) => { }}
                                    />
                                    </div>
                                   
                                    <Form.Control.Feedback type="invalid">
                                        {errors.new_password1}
                                    </Form.Control.Feedback>


                                    <span
                                        style={{ position: 'absolute', top: 30, right: 10, cursor: 'pointer' }}
                                        onClick={handleClickShowNewPassword}

                                    >
                                        {showPassword.newPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>}
                                    </span>

                                </div>
                            </Col>
                            <Col xs={12} sm={4}>
                                <div className="form-input-wrap cus-icon" style={{ position: 'relative' }}>
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control
                                        type={showPassword.confirmNewPassword ? 'text' : 'password'}

                                        name="new_password2" placeholder="Confirm New Password" className="input-item" value={values.new_password2}
                                        onChange={handleChange}
                                        isInvalid={!!errors.new_password2} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.new_password2}
                                    </Form.Control.Feedback>

                                    <span
                                        style={{ position: 'absolute', top: 30, right: 10, cursor: 'pointer' }}
                                        onClick={handleClickShowConfirmNewPassword}

                                    >
                                        {showPassword.confirmNewPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>}
                                    </span>


                                </div>
                            </Col>
                            {/*<Col xs={12} className="m-t-20">*/}
                            {/*   <button className="text-link green-link bold-text">Click here to view learner profile ( Demo pupose link )</button>*/}
                            {/*</Col>*/}
                        </Row>
                    </Form>
                )}

            </Formik>

        </div>
        </>
    );
};
var regex = /^[0-9 !@#\$%\^\&*\)\(+=._-]+$/g
const basicDetailsSchema = yup.object().shape({
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required'),
    email: yup.string().required('Email is required'),
    mobile_number: yup.string().required('Phone Number is required')
        .matches(regex, 'Please provide valid number'),
    employee_id: yup.string(),
    designation: yup.string(),
    institution_name: yup.string(),
    location: yup.string(),
    country: yup.string().required('Country is required'),
});

const resetPasswordSchema = yup.object().shape({
    // old_password: yup.string().required('Current Password is required'),
    new_password1: yup.string().required('New Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        // .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
        // .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
        // .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
           .matches(/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Password must include one upper case, and at least one number.'),
        // .matches(/^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/, 'Atleast one special character'),
    new_password2: yup.string().required('Confirm new password is required')
        .test('passwords-match', 'New Password and Confirm Password must match', function (value) {
            return this.parent.new_password1 === value;
        })
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Confirm new password must include one upper case, and at least one number.'),
});

export default ProfileBasicDetails;
