import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import QuizModulePopView from "../../surveyManagement/surveybuilder/components/managerQuizPreview"
import {Helmet} from "react-helmet";

import {
    getManagerSurveys
} from '~/modules/surveys/surveyManagement/surveybuilder/services';

import {

    getSurvey,
    deleteSurvey,
    cloneSurvey,
    getDraftSurveysList

} from '~/modules/surveys/services';



const SurveyDetails = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const urlId = props?.match?.params?.id;
    const response = useSelector(state => state?.survey_builder?.get_survey_manager?.response);
    const loading = useSelector(state => state?.survey_builder?.get_survey_manager?.loading);
    const [modalShow, setModalShow] = React.useState(false);


    const [state, setState] = useState({
        survey: [],
        popupView: false,

        values: []
    })







    function MyVerticallyCenteredModal(props) {

        return (
            <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Body>
                    <div className="modal-content-wrap text-center">
                        <h3 className="modal-title">Are you sure you want to delete this survey?{state?.survey?.is_published && (<><br/> <span style={{fontSize:'12px'}}>Please note, the related survey reports shall be deleted too!</span></>)}
                        </h3>
                       
                        {/* <p>Are You Sure To Delete The Members? </p> */}
                        <div className="d-flex justify-content-center buttons-wrap double-btn">

                            <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                                need to recheck
                            </button>
                            <Link className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes,
                                Continue</Button></Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const handlePreviewModel = () => {

        let demographic_questions = (response?.demographic_questions && response?.demographic_questions?.length) ? response?.demographic_questions : []
        let general_questions = ((response?.general_questions && response?.general_questions?.length) ? response?.general_questions : [])

        const ques = demographic_questions.concat(general_questions)
        // const ques=response?.demographic_questions.concat(response?.general_questions)
        //const ques = response?.demographic_questions
        //ques.push(response?.general_questions);
        const val = { ...response, questions: ques }
        setState({ ...state, values: val, popupView: true })
    }

    const handleDeleteQuiz = () => {

        const row = { id: urlId }

        dispatch(deleteSurvey(row, () => {
            dispatch(getSurvey('', '', '', ''));
        }));

        history.push('/surveymanagement');

    };
    useEffect(() => {
        if (urlId) {
            dispatch(getManagerSurveys(urlId, function (res) {

            }))
        }

    }, [])

    const handleClone = () => {
        dispatch(cloneSurvey(urlId, function (res) {
            if (res.data.survey_id) {
                history.push({
                    pathname : `/survey-edit/${res.data.survey_id}`
                })
            }
        }))
    }

    useEffect(() => {

        setState({ ...state, survey: response })


    }, [response])
    if (loading) {
        return (

            <div className='loaderQuizzes'>
                <Spinner animation="border" variant="danger" />
            </div>
        )

    } else {

        return (
            <div className="quiz-det-wrap quiz-limited-width">
                 <Helmet>
                    <title>Survey Library - Cultural Engagement  </title>
                </Helmet>
                <div className="teammember-details-header">
                  
                  <div className="d-flex justify-content-between align-items-center m-b-20">
                      
                        <h3 className="sec-title m-b-30">{state?.survey?.name ? state?.survey?.name : 'N/A'}</h3>
                      <div className="button-wrap double-btn">
                      
                      <button className="btn border-btn" onClick={(e)=>history.push({pathname: '/surveymanagement',state:{key:state?.survey?.is_published  ? 0 : 1} })}>Go Back</button>
                      
                      </div>
                  </div>
                </div>

               
                <div className="whitebox padding-box shadow-box quiz-det-box">
                    <div className="d-flex justify-content-between align-items-center">
                        <p>  <b>Due date  :</b> {state?.survey?.expiry_date}</p>
                        <div className="d-flex justify-content-end">
                            <div className="quiz-set-buttond double-btn">
                                {/* <button className="btn btn-primary"s
                            //  disabled={state?.survey?.questions && state?.survey?.questions?.length && state?.quiz?.questions[0]?.question_type?.length <= 0} 
                            onClick={handlePreviewModel}                                   
                                        >
                                        Preview Survey
                            </button> */}
                                {state?.survey?.is_published && state?.survey?.attended_count > 0 ?
                                    <button className="btn btn-primary"
                                        onClick={() => history.push(`/survey-graph/${urlId}`)}> Survey Reports
                                    </button>
                                    : ''}

                                {!state?.survey?.is_published ?
                                    <button className="btn btn-primary"
                                        onClick={() => history.push(`/survey-edit/${urlId}`)}>Edit Survey
                                    </button>
                                    : <button className="btn btn-primary"
                                        onClick={() => handleClone()}>Clone Survey
                                    </button>}
                                <button className="btn btn-primary"
                                    onClick={e => setModalShow(true)}>
                                    Delete Survey
                                </button>
                                <button className="btn btn-primary"
                                    onClick={e => handlePreviewModel()}>
                                    Preview Survey
                                </button>

                            </div>


                        </div>
                    </div>
                    <div className="choice-box-group d-flex justify-content-end m-t-20">
                        <div className="choice-box">
                            <i className="fa fa-file-text-o" aria-hidden="true"></i>
                            <span className="choice-text"><span>Number of Responses :</span>{state?.survey?.attended_count ? state?.survey?.attended_count : 0}</span>
                        </div>
                        <div className="choice-box">
                            <i className="fa fa-user-o" aria-hidden="true"></i>
                            <span className="choice-text"><span>Created By :</span>{state?.survey?.creator_name ? state?.survey?.creator_name : ''} </span>
                        </div>
                    </div>
                    <div className="grey-box-out-quiz">
                        <p>{state?.survey?.description ? state?.survey?.description : 'N/A'}</p>
                    </div>
                </div>
                <QuizModulePopView
                    show={state?.popupView}
                    values={state?.values}
                    // values={...response,questions:{...response?.demographic_questions,...response?.general_questions}}
                    onHide={() => setState({ ...state, popupView: false })}

                />

                <MyVerticallyCenteredModal
                    show={modalShow}
                    handleSubmit={() => { handleDeleteQuiz(); setModalShow(false) }}

                    onHide={() => setModalShow(false)}
                //   onExited={ModalExit}
                />
            </div>

        )
    }
};
export default SurveyDetails;
