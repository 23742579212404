import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router-dom";
import ChartistGraph from "react-chartist";
import { useSelector, useDispatch } from "react-redux";
import { chart9Data } from "../chartistData";
import Button from "react-bootstrap/Button";
import {
  genderReport,
  getRoleResults,
  getRacialResults,
  getRacialByAgeResults,
} from "~/modules/surveys/surveyReport/surveyreports/services";

function Summary({ id,survey }) {
  const [state, setState] = useState({
    genderDemographicResult: [],
    roleDemographicResult: [],
    racialDemographicResult: [],
    racialDemoGraphicByAge: [],
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const genReport = useSelector(
    (state) => state.surveyreport.gender_report?.response
  );
  const roleReport = useSelector(
    (state) => state.surveyreport.role_report?.response
  );
  const racialReport = useSelector(
    (state) => state.surveyreport.racial_demographics?.response
  );
  const racialReportByAge = useSelector(
    (state) => state.surveyreport.racialByAge_demographics?.response
  );
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    let element = document.getElementById("headerTitle");
    if (element) {
      switch (history.location.pathname) {
        case "/surveyreports":
          element.innerHTML = "Survey Reports";
          break;
        default:
          "Profile";
      }
    }
  }, []);
  useEffect(() => {
    dispatch(genderReport(id,user?.token));
    dispatch(getRoleResults(id,user?.token));
    dispatch(getRacialResults(id,user?.token));
    dispatch(getRacialByAgeResults(id,user?.token));
  }, []);

  useEffect(() => {
    let temp = {
      labels:
        racialReport?.labels && racialReport?.labels
          ? racialReport?.labels
          : [],
      series:
        racialReport && racialReport?.series?.length
          ? racialReport?.series?.flat(2)
          : [],
    };

    setState({
      ...state,
      genderDemographicResult: genReport,
      roleDemographicResult: roleReport,
      racialDemographicResult: temp,
      racialDemoGraphicByAge: racialReportByAge,
    });
  }, [genReport, roleReport, racialReport, racialReportByAge]);
  const percentage = 0;


  const { racialDemoGraphicByAge } = state;
  let dataByEthnicity = [];
  let dataRacialCount = [];

  for (let i = 0; i < racialDemoGraphicByAge?.length; i++) {
    dataByEthnicity.push(
      racialDemoGraphicByAge[i]?.ethnicity +
        " " +
        racialDemoGraphicByAge[i]?.age
    );
    dataRacialCount.push(racialDemoGraphicByAge[i]?.count);
  }
  let tempObj = {
    labels: dataByEthnicity && dataByEthnicity?.length ? dataByEthnicity : [],
    series: dataRacialCount && dataRacialCount?.length ? dataRacialCount : [],
  };
  const checkSecondLable = () => {
    if (
      state?.genderDemographicResult &&
      state?.genderDemographicResult?.length == 3
    ) {
      return state?.genderDemographicResult[1]?.gender;
    } else if (
      state?.genderDemographicResult &&
      state?.genderDemographicResult?.length == 2
    ) {
      if (state?.genderDemographicResult[0]?.gender == "Male") {
        return "Female";
      } else if (state?.genderDemographicResult[0]?.gender == "Female") {
        return "Male";
      } else {
        return "Female";
      }
    }
  };
  if(true) {
    return (
        <div className="survey-summary-wrap">
          <Row>
            <Col md={6}>
              <div className="whitebox shadow-box survey-graph-box m-b-20">
                <h4 className="graph-title">Demographics</h4>
                <p>Who took your survey</p>
                <div className="graph-place">
                  <Row>
                    <Col md={4}>
                      <div className="d-flex align-items-center">
                        <div className="circular-graph-wrap blue-graph">
                          <CircularProgressbar
                              value={
                                state?.genderDemographicResult[0]?.perc
                                    ? state?.genderDemographicResult[0]?.perc
                                    : "0"
                              }
                              text={`${
                                  state?.genderDemographicResult[0]?.perc
                                      ? state?.genderDemographicResult[0]?.perc
                                      : percentage
                              }%`}
                              strokeWidth="3"
                          />
                        </div>
                        <div className="demographocs-text">
                          <h5>
                            {state?.genderDemographicResult[0]?.count
                                ? state?.genderDemographicResult[0]?.count
                                : "0"}
                          </h5>
                          <p>
                            {state?.genderDemographicResult[0]?.gender
                                ? state?.genderDemographicResult[0]?.gender
                                : "Male"}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="d-flex align-items-center">
                        <div className="circular-graph-wrap green-graph">
                          <CircularProgressbar
                              value={
                                state?.genderDemographicResult[1]?.perc
                                    ? state?.genderDemographicResult[1]?.perc
                                    : "0"
                              }
                              text={`${
                                  state?.genderDemographicResult[1]?.perc
                                      ? state?.genderDemographicResult[1]?.perc
                                      : percentage
                              }%`}
                              strokeWidth="3"
                          />
                        </div>
                        <div className="demographocs-text">
                          <h5>
                            {state?.genderDemographicResult?.length == 3 &&
                            state?.genderDemographicResult[1]?.count
                                ? state?.genderDemographicResult[1]?.count
                                : "0"}
                          </h5>
                          <p>{checkSecondLable()}</p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="d-flex" style={{flexDirection: "column"}}>
                        <div className="circular-graph-wrap yellow-graph">
                          {/*<CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth="3" />*/}
                        </div>
                        <div className="demographocs-text">
                          <h5>
                            {state?.genderDemographicResult &&
                            state?.genderDemographicResult?.length &&
                            state?.genderDemographicResult?.length == 3 &&
                            state?.genderDemographicResult[2]
                                ? state?.genderDemographicResult[2]?.total_results
                                : state?.genderDemographicResult[1]?.total_results}
                          </h5>
                          <p>Total Responses</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="demographics-footer">
                  <p>
                    <span className="esclamation">!</span> Total responses of all
                    survey takers
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="count-boxes">
                <Row>
                  <Col md={6}>
                    <div className="whitebox shadow-box count-boxes-item m-b-20">
                      <h4>
                        {state.roleDemographicResult &&
                        state.roleDemographicResult.length &&
                        state.roleDemographicResult[0].i_am_a != null
                            ? state.roleDemographicResult[0].i_am_a
                            : "Student"}
                      </h4>
                      <div className="count-text">
                        {state.roleDemographicResult &&
                        state.roleDemographicResult.length &&
                        state.roleDemographicResult[0]
                            ? state.roleDemographicResult[0]?.count
                            : "0"}
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="whitebox shadow-box count-boxes-item m-b-20">
                      <h4>
                        {state.roleDemographicResult &&
                        state.roleDemographicResult.length &&
                        state.roleDemographicResult[1]?.i_am_a
                            ? state.roleDemographicResult[1]?.i_am_a
                            : "Teacher"}
                      </h4>
                      <div className="count-text">
                        {state.roleDemographicResult &&
                        state.roleDemographicResult.length &&
                        state.roleDemographicResult[1]?.i_am_a
                            ? state.roleDemographicResult[1]?.count
                            : "0"}
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="whitebox shadow-box count-boxes-item m-b-20">
                      <h4>
                        {state.roleDemographicResult &&
                        state.roleDemographicResult.length &&
                        state.roleDemographicResult[2]?.i_am_a
                            ? state.roleDemographicResult[2]?.i_am_a
                            : "Parent"}
                      </h4>
                      <div className="count-text">
                        {state.roleDemographicResult &&
                        state.roleDemographicResult.length &&
                        state.roleDemographicResult[2]?.i_am_a
                            ? state.roleDemographicResult[2]?.count
                            : "0"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {state?.racialDemographicResult &&
            state?.racialDemographicResult &&
            state?.racialDemographicResult.series &&
            state?.racialDemographicResult.series.length ? (
                <Col md={12}>
                  <div
                      className={
                        state?.racialDemographicResult?.series?.length > 5
                            ? `whitebox shadow-box racial-demo survey-graph-box m-b-20`
                            : `whitebox shadow-box racial-demo survey-graph-box m-b-20 survey-less-data`
                      }
                  >
                    <div className="d-flex justify-content-between">
                      <div className="graph-title-left">
                        <h4 className="graph-title">Racial Demographics</h4>
                        {/*<p>2017-2018</p>*/}
                      </div>
                      {/*<div className="graph-title-right d-flex">*/}
                      {/*   <div className="graph-info">*/}
                      {/*      <span className="graph-info-color primary-graph">&nbsp;</span>*/}
                      {/*      <span className="graph-info-text">Students</span>*/}
                      {/*   </div>*/}
                      {/*   <div className="graph-info">*/}
                      {/*      <span className="graph-info-color secondary-graph">&nbsp;</span>*/}
                      {/*      <span className="graph-info-text">Staff</span>*/}
                      {/*   </div>*/}
                      {/*</div>*/}
                    </div>
                    <div className="graph-place">
                      {state?.racialDemographicResult &&
                      state?.racialDemographicResult &&
                      state?.racialDemographicResult.series &&
                      state?.racialDemographicResult.series.length ? (
                          <ChartistGraph
                              data={
                                state?.racialDemographicResult &&
                                state?.racialDemographicResult
                                    ? state?.racialDemographicResult
                                    : ""
                              }
                              // data={chart9Data}
                              options={chart9Data.options}
                              responsiveOptions={chart9Data.responsiveOptions}
                              type={"Bar"}
                              className="ct-12 survey-medium-graph"
                              style={{height: "327px !important"}}
                          />
                      ) : (
                          "No Data"
                      )}
                    </div>
                  </div>
                </Col>
            ) : (
                ""
            )}
            {state?.racialDemographicResult &&
            state?.racialDemographicResult &&
            state?.racialDemographicResult.series &&
            state?.racialDemographicResult.series.length ? (
                <Col md={12}>
                  <div
                      className={
                        state?.racialDemographicResult.series.length > 5
                            ? `whitebox shadow-box survey-graph-box long-label-graph m-b-20 `
                            : `whitebox shadow-box survey-graph-box long-label-graph m-b-20 survey-less-data`
                      }
                  >
                    <h4 className="graph-title">Racial Demographics by Age</h4>
                    <div className="graph-place">
                      {state?.racialDemographicResult &&
                      state?.racialDemographicResult &&
                      state?.racialDemographicResult.series &&
                      state?.racialDemographicResult?.series?.length ? (
                          <ChartistGraph
                              data={tempObj && tempObj ? tempObj : ""}
                              // data={chart9Data}
                              options={chart9Data.options}
                              responsiveOptions={chart9Data.responsiveOptions}
                              type={"Bar"}
                              className="ct-12 survey-medium-graph "
                              style={{height: "327px !important"}}
                          />
                      ) : (
                          "No Data"
                      )}
                    </div>
                  </div>
                </Col>
            ) : (
                ""
            )}
          </Row>
          {/*<div className="btn-wrap double-btn d-flex justify-content-end">*/}
          {/*         <button className="btn border-btn">Previous Page</button>*/}
          {/*         <Button>Next Page</Button>*/}
          {/*      </div>*/}
        </div>
    )
  }else{
    return (

        <div className="noresults">
          <div style="padding: 24px;">There are no records to display</div>
        </div>
    )
  }
}
export default Summary;
