import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AssignSurvey from '../components/assignsurvey'

function SurveyBuilder (){
   return (
    <div className="tab-wrapper">
    <Tabs defaultActiveKey="Assign" id="uncontrolled-tab-example" className="m-b-30">
       <Tab eventKey="AddDetails" title="Add Details">
       Details Here
       </Tab>
       <Tab eventKey="Assign" title="Assign">
      <AssignSurvey/>
       </Tab>

    </Tabs>
 </div>
   );
};
export default SurveyBuilder;