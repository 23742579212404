import React, { useState, useEffect } from 'react';
import Pagination from "react-bootstrap/Pagination";
import { PageItem } from "react-bootstrap";
import { useHistory } from 'react-router-dom'
import FilterImg from '~/assets/images/icons/filter.svg';
import SearchImg from '~/assets/images/icons/magnifiying-glass.svg';
import ScrollArea from 'react-scrollbar'
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import Form from 'react-bootstrap/Form';
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { assignSurvey } from '../../../surveys/services'
import { assignCourse } from '../../../coursemanagement/services'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {format} from 'date-fns';
import {
   getAllGroups,
   removeGroupBulk,
   removeGroup,
   handleGroupPagination,
   handlePreviousGroupPagination,
   getMembersList,
   updateGroups,
   getLP,
   assignLP_groups_multiple
} from '../../services';
import { getManagersList } from '../../../surveys/services';
import { getManagerCoursesList } from '../../../coursemanagement/services';
import Spinner from "react-bootstrap/Spinner";
import { getNotifications } from "../../../Notifications/services";


import SkillAssignBox from '../../utilities/multipleGroupAssign';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getClassesWithOutPagination } from '../../../learningpath/services';

function MyVerticallyCenteredModal(props) {

   return (
      <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
         {...props}
         size="sm"
         aria-labelledby="contained-modal-title-vcenter"
         centered

      >
         <Modal.Body>
            <div className="modal-content-wrap text-center">
               <h3 className="modal-title">Remove Groups?</h3>
               {/* <p>Are You Sure To Delete These Groups? </p> */}
               <div className="d-flex justify-content-center buttons-wrap double-btn">

                  <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                     need to recheck
                  </button>
                  <Link className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes, Continue</Button></Link>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   );
}

function MyConfirmDeleteModal(props) {

   return (
      <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
         {...props}
         size="sm"
         aria-labelledby="contained-modal-title-vcenter"
         centered

      >
         <Modal.Body>
            <div className="modal-content-wrap text-center">
               <h3 className="modal-title">Remove Groups?</h3>
               {/* <p>Are You Sure To Delete These Groups? </p> */}
               <div className="d-flex justify-content-center buttons-wrap double-btn">

                  <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                     need to recheck
                  </button>
                  <Link className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes, Continue</Button></Link>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   );
}



const AddClasses = (props) => {
    const responseClasses = useSelector(state => state?.learningpath?.classes_without_pagination?.response);
   const responseMembers = useSelector(state => state.teams.members_list?.response || []);
   const [editGroup, editGroupShow] = useState(false);
   const dispatch = useDispatch();
   const [select, setSelect] = useState(0);
   const [search, setSearch] = useState('');
   const { user } = useSelector(state => state.auth);
   const response = useSelector(state => state.teams.group_list?.response || [] || []);
   const [selectedGroupIdList, setSelectedGroupIdList] = useState([]);
   const managers = useSelector(state => state.surveys.manager_list?.response || [] || [])

   const loading = useSelector(state => state?.teams?.groupCourses?.loading);
   const [category, setCategory] = useState('course');
   const [groups, setGroups] = useState([]);
   const [classes, setClasses] = useState([]);
   
   const [createdby, setCreatedby] = useState('');
   const filterDefault = [{ "id": '', "label": "All", "value": "All", "email_label": "ALL" }];
   const [newManagers, setNewManagers] = useState([]);

   //   const newManagers=filterDefault.concat(managers)
   const [pageReset, setPageReset] = useState(false)
   const [dataProgress, setDataProgress] = useState(false)
   const [assign, assignShow] = useState(false);
   const responseTotalCourse = useSelector(state => state?.course?.course_listing?.response?.results || [] || []);
   const [selectedSkills, setSelectedSkills] = useState(null)
   const responseTotalLP = useSelector(state => state.teams.totalLP?.response?.results || [] || []);
   const responseTotalSurvey = useSelector(state => state?.surveys?.surveys_list?.response?.results || [] || [])
   const [skills, setSkills] = useState([])
   const history = useHistory()
   const [name, setName] = useState('')
   const [id, setId] = useState('')
   const [toggledClearRows, setToggledClearRows] = useState(false);
   const [groupMembers, setMembers] = useState([])
   const [newMembers, setNewMembers] = useState([])
   const [newEntry, setNewEntry] = useState([])
   const [memberExist, setMemberExist] = useState(false)
   const [selectrow, setSelectRow] = useState(null);



   const [modalShow, setModalShow] = React.useState(false);
   const handleModel = (values) => {
      if (values) {
         setModalShow(true)
      }

   }

   const [modalConfirm, setModalConfirm] = React.useState(false);
   const ConfirmDeleteModal = (values) => {
      if (values) {
         setModalConfirm(true);
      }
   };
   const [activePage, setActivePage] = useState(1);
   const [state, setState] = useState({
      groupsmembers: response,
      count: response?.count,
      paginationItem: [],
   });
   const [count, setCount] = useState(0);



   useEffect(() => {
      let componentMounted = true;
      const fetchData = () => {
         if (componentMounted) {
            setSearch('')
            setCreatedby('')
            setCategory('course')
            dispatch(getManagersList());
            dispatch(getManagerCoursesList(''))
            //  dispatch( getLP());
            dispatch(getNotifications())
            dispatch(getAllGroups(user?.token, search, createdby))
            dispatch(getMembersList(user?.token, search, ''))
            dispatch(getClassesWithOutPagination())
            setGroups(response.results)
            setSkills(responseTotalCourse)
            setPageReset(true)
            setNewManagers(filterDefault.concat(managers))

         }
      };
      fetchData();
      return () => {
         componentMounted = false;
      }
   }, []);

   useEffect(() => {
      setSelectedSkills(null)

   }, [category])



   useEffect(() => {
      setNewManagers(filterDefault.concat(managers))

   }, [managers])

   useEffect(() => {
      setGroups(response.classes)
   }, [response.results]);


   useEffect(() => {
      if (loading) { setDataProgress(true) }

      if (!loading) { setDataProgress(false) }
   }, [loading])

   useEffect(() => {
      if (count > 0 && dateRange[0] == null) {
         setActivePage(1);
         dispatch(getAllGroups());
      }
   }, [count])

   useEffect(() => {
      const count = response?.count
      const perPage = 12;
      const buttonsCount = Math.ceil(count / perPage);
      let items = []
      for (let number = 1; number <= Number(buttonsCount); number++) {
         items.push(
            <Pagination.Item onClick={() => handleIndex(number)} key={number} active={number == activePage}>
               {number}
            </Pagination.Item>
         );
      }
      setState({ ...state, groupsmembers: response, count: response?.count, paginationItem: items });
   }, [response]);

   //   const handleNext = () =>
   //   {
   //      if (response?.next) {
   //          dispatch(handleGroupPagination(response?.next))
   //      } else {
   //          return false
   //      }

   //   }
   //   const handlePrevious = () =>
   //   {
   //     if (response?.previous) {
   //         dispatch(handlePreviousGroupPagination(response?.previous))
   //     } else {
   //         return false
   //     }
   //  }

   const handleIndex = (index) => {

      if (index == 1) {
         dispatch(getAllGroups(user?.token, search, createdby));
      }
      else {
         dispatch(getAllGroups(user?.token, "", "", index));
      }
      setActivePage(index);

   };

   const handleNext = () => {
      // if (response?.next)
      if (state.groupsmembers?.next) {
         var regex = /page=\d+/g;
         const [number] = state.groupsmembers?.next?.match(regex)
         setActivePage((prevVal) => prevVal === state.paginationItem ? prevVal : prevVal + 1);
         //dispatch(handleGroupPagination(state.groupsmembers?.next));
         dispatch(handleGroupPagination(state.groupsmembers?.next))
         //dispatch(handlePaginationTeamsMembers(response?.next));
      } else {
         return false;
      }
   };

   const handlePrevious = () => {
      // if (response?.previous)
      if (state.groupsmembers?.previous) {
         setActivePage((prevVal) => (prevVal - 1 === 0 ? prevVal : prevVal - 1));
         // dispatch(handlePreviousPagination(response?.previous));
         dispatch(
            handlePreviousGroupPagination(state.groupsmembers?.previous)
         );
      } else {
         return false;
      }
   };

   useEffect(() => {
      if (props.handleChild) { assignShow(true) }
   }, [props.handleChild])


   useEffect(() => {
      setGroups(response.results)
   }, [response.results]);

   useEffect(() => {
    setClasses(responseClasses)
 }, [responseClasses]);


   const updateGroup = () => {
      const selectlist = groupMembers.map(a => a.id);
      let tempObj = {
         name: name,
         learners: selectlist
      }

      dispatch(updateGroups(id, tempObj))

      setTimeout(() => {
         dispatch(getAllGroups(user?.token, '', ''))

      }, 1000)

      editGroupShow(false)
   }




   ///searching and adding new members to existing group
   const addNewMembers = () => {

      const result = groupMembers.find(member => member.id == newEntry?.id);
      if (result) {
         setMemberExist(true)
         setTimeout(() => {
            setMemberExist(false)
         }, 3000);
      }

      if (!result && newEntry.id) {
         let tempObj = []
         tempObj = groupMembers;
         tempObj.push(newEntry)
         setMembers(tempObj);

      }

   }


   const handleRemove = (item, index) => {
      const tempselcted = groupMembers.filter(member => member.id != item?.id)
      setMembers(tempselcted)

   }


   //edit group

   const handleEdit = (row) => {

      setId(row.id)
      setName(row.name)
      const learners = row.learners_detail;
      const newArray = [];

      for (var i = 0; i < learners.length; i++) {
         newArray.push({
            id: learners[i].id,
            get_full_name: learners[i].get_full_name
         });
      }

      setMembers(newArray)

   }


   //searching for members to add to a group
   const searchMembers = (e) => {
      dispatch(getMembersList(user?.token, e, ''))
      setNewMembers(responseMembers.results)

   }



   //changing Assign category

   //    const handleCategory=(e)=>{

   //       if(e.target.value=='course'){
   //          setCategory('course')
   //          setSkills(responseTotalCourse)
   //       }
   //       if(e.target.value=='LP'){
   //       setCategory('Learning Path')
   //       setSkills(responseTotalLP)
   //       }
   //       if(e.target.value=='Survey'){setCategory('Survey')
   //       setSkills(responseTotalSurvey)      }
   //       }


   // //set skills for assign
   //       const skillSelect=(e)=>{
   //          setSelectedSkills(e.id)

   //          }



   // const assignSkills=(e)=>
   // {

   //     if(category=='course'){
   //       const selectlist =selectedGroupIdList.map(a => a.id);
   //        var userId=selectedSkills;
   //        var data= {
   //            groups:selectlist
   //         }

   //        dispatch( assignCourse(data,userId))
   //        setTimeout(() => {
   //       dispatch( getAllGroups(user?.token,'',''))
   //                  }, 1000)
   //                       }

   //    if(category=='Learning Path'){
   //       const selectlist =[]
   //        for (var i = 0; i < selectedGroupIdList.length; i++) {
   //        selectlist.push({
   //          id:selectedGroupIdList[i].id,
   //          });
   //          }
   //     var Id=selectedSkills;
   //     var data= {
   //      groups:selectlist
   //         }

   //       dispatch(assignLP_groups_multiple(Id,data))
   //       setTimeout(() => {
   //       dispatch( getAllGroups(user?.token,'',''))
   //     }, 1000)

   // }

   //    if(category=='Survey'){

   //    const selectlist =selectedGroupIdList.map(a => a.id);
   //    var userId=selectedSkills;
   //     dispatch( assignSurvey(selectlist,history,userId))
   //     setTimeout(() => {
   //       dispatch( getAllGroups(user?.token,'',''))
   //    }, 1000)
   // }
   // assignShow(false)
   // props.parentCallback(false)
   // setSelectedSkills(null)
   // setSelectedGroupIdList([])
   // }


   const handleAssignExit = () => {

      assignShow(false)
      props.parentCallback(false)
      setSelectedSkills(null)
      setSelectedGroupIdList([])
      setTimeout(() => {
         dispatch(getAllGroups(user?.token, search, createdby))
      }, 2000)




      setToggledClearRows(!toggledClearRows)
      dispatch(getNotifications())
      setSelect(0)

      setTimeout(() => {
         setSelect(0)
      }, 500)

   }


   const AddNew = (e) => {

      setNewEntry(e)

   }




   const onFilter = (val) => {
      setCreatedby(val.id)
      dispatch(getAllGroups(user?.token, search, val.id))


   };



   //group search
   const searchInput = (e) => {
      setSearch(e.target.value);
      dispatch(getAllGroups(user?.token, e.target.value, createdby))
      setGroups(response.results)

   }

   const handleGroupNameChange = (e) => {
      var value = e.target.value;
      setName(value)
   }




   const handleSelect = (state) => {
      setSelect(state.selectedCount)
      const selectedGroupIds = [];
      var len = state.selectedRows.length;
      for (var i = 0; i < len; i++) {
         selectedGroupIds.push({
            id: state.selectedRows[i].id,
         });
      }

      setSelectedGroupIdList(selectedGroupIds)



   };


   const deleteSingleGroup = (id) => {

      dispatch(removeGroup(user?.token, id))
      setTimeout(() => {
         dispatch(getAllGroups(user?.token, '', ''))
      }, 1000)
      // setGroups(response.results)
   }



   const deleteGroupBulk = () => {
      setModalShow(false)
      dispatch(removeGroupBulk(user?.token, selectedGroupIdList))
      setTimeout(() => {
         dispatch(getAllGroups(user?.token, search, createdby))
      }, 1000)

      // setGroups(response.results)
      setToggledClearRows(!toggledClearRows)
      setSelect(0)

   }



   const reset = () => {
      setSearch('');
      // setCreatedby('');
      setTimeout(() => {
         dispatch(getAllGroups(user?.token, '', createdby))
      }, 200)
      // setGroups(response.results)
   }

   const HideAssign = () => {
      props.parentCallback(false)
      assignShow(false)
      setSelectedSkills(null)
      setSelectedGroupIdList([])
      // setToggledClearRows(!toggledClearRows)
   }

   const handleCallback = (childData) => {
      if (childData) {
         assignShow(false)

      }
   }



   const tableColumns = [

      {
         name: 'Class name',
         selector: 'name',
         center: false,
         grow: 4,
         cell: (row) => <Link to={`/classdetails/${row?.id}`}>{row.name}</Link>,
         sortable: true,
      },
      {
         name: 'Created Date',
         selector: 'created_date',
         cell: (row) => format(new Date(row.created_date), 'yyyy-MM-dd'),
         sortable: true,
         center: false,
         grow: 4,
      },

   ];
   const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
      <div className="custom-control custom-checkbox">
         <input
            type="checkbox"
            className="custom-control-input"
            ref={ref}
            {...rest}
         />
         <label className="custom-control-label" onClick={onClick} />
      </div>
   ));
   const CaretDownIcon = () => {
      return (
         <div className="filter-icon-select">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
               <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
            </svg>
         </div>
      );
   };

   const DropdownIndicator = (props) => {
      return (
         <components.DropdownIndicator {...props}>
            <CaretDownIcon />
         </components.DropdownIndicator>
      );
   };
   return (
      <div className="available-course-wrap">
         <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
            <div className="datatable-filter-left-box d-flex align-items-center">
               <div className="datepicker-box p-relative">

               </div>

               <div className="dt-filter-left d-flex justify-content-between align-items-center">
                  <div className="custom-react-select">
                     <Select options={newManagers || []} getOptionLabel={(option) => option.label.length > 1 ? option.label : option?.email_label} defaultValue={newManagers.length > 0 ? newManagers[0].value : ''}
                        value={newManagers?.value}
                        onChange={onFilter} placeholder="Author" components={{ DropdownIndicator }} />

                  </div>
               </div>
            </div>
            <div className="dt-filter-right d-flex">
               <div className="dt-filter-search-wrap p-relative">
                  <input type="text" placeholder="Search using keyword"
                     value={search} onChange={searchInput}
                     className="form-control input-item" />
                  <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
               </div>

               <button className="iconbutton search-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                  </svg>
               </button>
            </div>
         </div>
         <div className="datatble-wrap checkbox-datatable">

            {/* <button
              type="button"
              className="option-btn"
              onClick={(e) => ConfirmDeleteModal(true)}
            >
           <i className="fa fa-trash" aria-hidden="true"></i>Delete
           </button> */}
            <MyConfirmDeleteModal
               show={modalConfirm}
               //handleSubmit={memberDelete}
               handleSubmit={() => {
                  deleteSingleGroup(selectrow);
                  setModalConfirm(false);
               }}
               //handleSubmit={deleteSingleMember}
               onHide={() => setModalConfirm(false)}
            //onExited={setModalConfirm(false)}
            />

            {select > 0 ?
               <div className="datatble-more-opt">
                  <ul className="d-flex">
                     <li>
                        <button type="button" className="option-btn" onClick={() => assignShow(true)}>
                           <i class="fa fa-file-text-o" aria-hidden="true"></i>Enroll
                        </button>
                     </li>
                     <li>
                        <button type="button" className="option-btn" onClick={e => handleModel(true)}>
                           <i className="fa fa-trash" aria-hidden="true" ></i>Delete
                        </button>
                     </li>
                     <MyVerticallyCenteredModal
                        show={modalShow}
                        handleSubmit={deleteGroupBulk}
                        onHide={() => setModalShow(false)}
                        onExited={() => { setToggledClearRows(!toggledClearRows), setSelectedGroupIdList([]), setSelect(0) }}
                     />
                  </ul>

               </div> : ""
            }


            {loading && loading ? (
               <>
                  <div className="loaderCourses">
                     <Spinner animation="border" variant="danger" />
                  </div>
               </>
            ) : (
               <>

                 <div className="datatble-wrap">
                        <DataTable
                           data={classes}
                           columns={tableColumns}
                           selectableRows
                           clearSelectedRows={toggledClearRows}
                           selectableRowsComponent={BootyCheckbox}
                           onSelectedRowsChange={handleSelect}
                           //center={true}
                           progressPending={dataProgress}
                           noHeader={true}
                        />
                     </div>
                 
               </>
            )}


            {!loading && state?.groupsmembers && state?.groupsmembers && state?.groupsmembers.count > 12 && response && (

               <div className="d-flex justify-content-center">
                  <Pagination size="sm" className="custom-pagination">
                     <Pagination.First disabled={!state?.groupsmembers?.previous} className={`${state?.groupsmembers?.previous ? "paginationStyle" : ""} `} onClick={handlePrevious} />
                     {state.paginationItem}
                     <Pagination.Last disabled={!state?.groupsmembers?.next} onClick={handleNext} className={`${state?.groupsmembers?.next ? "paginationStyle" : ""} `} />
                  </Pagination>
               </div>
            )}

         </div>
         <Modal
            size="md"
            show={assign}
            onHide={HideAssign}
            onExited={() => handleAssignExit()}
            aria-labelledby="example-modal-sizes-title-lg"
            className="common-modal team-member-pop inner-popup"
            centered
         >
            <Modal.Header closeButton>
               <div className="addmember-header">
                  <h4>Enroll</h4>
                  <p>Description text will be here</p>
               </div>
            </Modal.Header>
            <Modal.Body>
               <SkillAssignBox selectlist={selectedGroupIdList} parentCallback={handleCallback} />
            </Modal.Body>
         </Modal>






         {/* <Modal
   size="md"
   show={assign}
   onHide={HideAssign}
   onExited={()=>handleAssignExit()}
   aria-labelledby="example-modal-sizes-title-lg"
   className="common-modal team-member-pop inner-popup"
   centered
   >
   <Modal.Header closeButton>
      <div className="addmember-header">
         <h4>Enroll</h4>
         <p>Description text will be here</p>
      </div>
   </Modal.Header>
   <Modal.Body>
      <div className="assignbox-wrap">
         <div className="form-input-wrap">
            <Form.Label>Select Category</Form.Label>
            <select className="form-control select-item" onChange={handleCategory}>
               <option value="course">Course</option>
               {/* <option value="LP">Learning Path</option> */}
         {/* <option value="Survey">Survey</option>
            </select>
         </div>
         <div className="form-input-wrap">
        {category=='course'?
            <div className="select-item">
            <Select
            placeholder={`Select a Course` }
            onChange={skillSelect}
             isSearchable={true}
             name={category}
             options={skills||[]}
             getOptionLabel ={(option)=>option.name}
             getOptionValue={(option) => option.id}
      />
      </div>:'' }








       {category=='Survey'?
            <div>
            <Select
            placeholder={`Select a Survey` }
            onChange={skillSelect}
             isSearchable={true}
             name={category}
             options={skills||[]}
             getOptionLabel ={(option)=>option.name}
             getOptionValue={(option) => option.id}
      />
      </div>:'' }  {category=='Learning Path'?
            <div>
            <Select
            placeholder={`Select a ${category }` }
            onChange={skillSelect}
             isSearchable={true}
             name={category}
             options={skills||[]}
             getOptionLabel ={(option)=>option.name}
             getOptionValue={(option) => option.id}
      />
      </div>:'' }
         </div> {selectedGroupIdList.length<=0 ? <span style={{color:'red'}}>Select Groups To Enroll </span> :''}
         <div className="button-wrapper d-flex justify-content-end m-t-20 m-b-10"><Button  disabled = { !selectedSkills || selectedGroupIdList.length<=0 ? "disabled" : ""}  onClick={assignSkills}>Enroll</Button></div>
      </div>
      
   </Modal.Body> */}
         {/* </Modal>  */}




         <Modal
            size="md"
            show={editGroup}
            onHide={() =>
               editGroupShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            className="common-modal team-member-pop inner-popup"
            centered
         >
            <Modal.Header closeButton>
               <div className="addmember-header">
                  <h4>Edit Group</h4>
                  <p>You can edit the details of your group here</p>
               </div>
            </Modal.Header>
            <Modal.Body>
               <div className="form-input-wrap">
                  <Form.Label>Group Title</Form.Label>
                  <div className="d-flex w-100">
                     <div className="group-title-create-left">
                        <Form.Control type="text" placeholder="Enter group name" className="input-item" value={name} onChange={handleGroupNameChange} />
                     </div>
                     <div className="group-title-create-right">
                        <Button disabled={name?.length <= 0 ? "disabled" : ""} onClick={updateGroup}>Update Group</Button>
                     </div>
                  </div>
               </div>
               <div className="group-list-selected-box-content">
                  {/* <ScrollArea
            speed={0.8}
            className="assign-poplist-scroll"
            contentClassName="content"
            horizontal={false}
            > */}

                  {groupMembers && groupMembers.length ? groupMembers?.map((item, index) => (

                     <div className="course-selected-item p-relative">

                        {item?.id ? <span className="course-selected-text">{item?.get_full_name.length > 1 ? item?.get_full_name : item?.id}</span> : ''}
                        <span className="memb-close-icon" onClick={() => handleRemove(item, index)}>×</span>

                     </div>

                  ))
                     :
                     ''
                  }
                  {/* </ScrollArea> */}

               </div>
               <div className="group-pop-footer">
                  <h4>Add New Members To The Group  </h4>
                  <div className="form-input-wrap">
                     <Form.Label>Email Address </Form.Label>
                     <div className="d-flex w-100">
                        <div className="group-title-create-left">
                           <Select
                              //   setValue={category}
                              placeholder="Enter Email Address"
                              isClearable={true}
                              onChange={AddNew}
                              onInputChange={searchMembers}
                              //  isSearchable={true}
                              name={category}
                              options={newMembers || []}
                              getOptionLabel={(option) => option.email}
                              getOptionValue={(option) => option.id}

                           />
                           {/* <Form.Control type="email" placeholder="Enter email address" className="input-item" /> */}
                        </div>

                        <div className="group-title-create-right">

                           <Button disabled={newEntry?.length <= 0 ? "disabled" : ""} onClick={addNewMembers}>Add</Button>

                        </div>
                     </div>

                     {memberExist ? <span style={{ color: '#ce002a' }}>Member Already selected </span> : ''}
                  </div>

               </div>
            </Modal.Body>
         </Modal>



      </div>
   );
};
export default AddClasses;   