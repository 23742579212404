import React, { useState, useRef, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Members from "../components/members";
import Groups from "../components/groups";
import Classes from '../components/addClasses'
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ScrollArea from "react-scrollbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import {
  addNewMember,
  getMembersList,
  addNewGroup,
  getNewMembers,
  getAllGroups,
  checkTrialPlan,
  getSelectedMembers,
  checkMemberCount,
} from "../services";

import {
  syncWithWorkOs,
  checkSubscriptionStatus,
  unSyncWithWorkOs,
} from "../../auth/services";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../Notifications/services";
import Dropdown from "react-bootstrap/Dropdown";
import ImportCsv from "./components/importcsv";
import UploadCsv from "./components/uploadcsv";
import { Link, useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import classesAssignBox from '../../coursemanagement/components/coursebuilder/components/enrollusers/components/classesAssignBox.js'

const Team = () => {
  const responseMembers = useSelector(
    (state) => state.teams?.memberslist?.response || []
  );
  const loadingSync = useSelector((state) => state?.auth?.sync_workos?.loading);
  const loading = useSelector(
    (state) => state.teams?.count_member?.loading || false
  );
  const loadingUnSync = useSelector(
    (state) => state?.auth?.unsync_workos?.loading
  );
  const workOrg = useSelector(
    (state) => state.auth?.verify_subdomain?.response
  );
  const workOrgResponse = useSelector(
    (state) => state.auth?.verify_subdomain?.response
  );
  const subStatusResponse = useSelector(
    (state) => state?.auth?.subscription_status?.response
  );
  const dispatch = useDispatch();
  const [addMember, setAddMember] = useState(false);
  const [createGroup, createGroupShow] = useState(false);
  const [NoPart, setNoPart] = useState(false);

  const [assign, assignShow] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { hideLpQuizes } = useSelector((state) => state.settings);
  const selectedIds = useSelector(
    (state) => state.teams.selectdMembers_list || []
  );
  const [file, setFile] = useState(null);
  const history = useHistory();
  const [deselect, setDeselect] = useState(false);
  const [memberExist, setMemberExist] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [csvShow, setCSVShow] = useState(false);
  const [selectedData, setData] = useState([]);
  const [newMembers, setNewMembers] = useState([]);
  const [newEntry, setNewEntry] = useState([]);
  const [beforeupload, setBeforeUpload] = useState(true);
  const [memberLimitError, setMemberLimitError] = useState(false);
  const [subStatus, setSubStatus] = useState(subStatusResponse);
  const [nottrialPlan, setTrialPlan] = useState(true);
  const [memeberActivePage, setmemeberActivePage] = useState(1);
  const [groupActivePage, setgroupActivePage] = useState(1);
  
  const [memberkey, setMemberkey] = useState('Members');
  let location = useLocation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .when("file", {
        is: (file) => file == null,
        then: Yup.string().required("At least one of the fields is required"),
      }),
  });
  useEffect(() => {
    setData(selectedIds);
    dispatch(getNewMembers(user?.token,"",""));
  }, []);

  useEffect(() => {
    if(responseMembers?.results?.length){
      setNewMembers(responseMembers.results.slice(0, 5));
    }
  }, [responseMembers]);

  useEffect(() => {
   if(location?.state?.key){
    setMemberkey(location.state.key)
   }
  }, [location])

//   useEffect(()=>{
//     if(matchUrl.indexOf('groupdetails') > -1){

//         setKey('Groups')
//     }else {
//          setKey( 'Members'  )
//     }

// },[])

  
  useEffect(() => {
    setData(selectedIds);
  }, [selectedIds]);

  const handleRemove = (item, index) => {
    const tempselcted = selectedData.filter((member) => member?.id != item?.id);
    setData(tempselcted);
  };
  const handleCreateGroup = (values) => {
    let result = selectedData?.map((a) => a?.id);
    dispatch(addNewGroup(user?.token, values.groupname, result));
    setTimeout(() => {
      let {activePage,search, memberType} = groupActivePage
      dispatch(getAllGroups(user?.token, search, memberType, activePage));
    }, 1000);
    createGroupShow(false);
    history.push("/team");
  };
  const addNewMembers = () => {
    const result = selectedData.find((member) => member?.id == newEntry?.id);
    if (result) {
      setMemberExist(true);
      setTimeout(() => {
        setMemberExist(false);
      }, 3000);
    }
    if (!result && newEntry?.id) {
      let tempObj = [];
      tempObj = selectedData;
      tempObj.push(newEntry);
      setData(tempObj);
    }
    setNewEntry([]);
  };
  const AddNew = (e) => {
    setNewEntry(e);
  };
  const searchMembers = (e) => {
    dispatch(getNewMembers(user?.token, e, ""));
    setNewMembers(responseMembers.results);
  };
  const handleCallback = (childData) => {
    createGroupShow(true);
  };
  const handleGroupback = (childData) => {
    if (!childData) {
      assignShow(false);
    }
  };
  const handleGroupExit = () => {
    const selected = [];
    dispatch(getSelectedMembers(selected));
    setDeselect(true);
  };
  const handleGroupEntry = () => {
    setDeselect(false);
  };
  const [state, setState] = useState({
    Max_members: 5,
    Current_users: 0,
    Members_to_add: 0,
  });

  useEffect(() => {
    setSubStatus(subStatusResponse);
  }, [subStatusResponse]);

  useEffect(() => {
    dispatch(
      checkTrialPlan(function (response) {
        if (response) {
          if (!response.status) {
            setTrialPlan(false);
          }
        }
      })
    );
    checkLimitError();
  }, []);

  const handleLoader = () => {
    if (loading) {
      return (
        <div className="loader-full-screen-course">
          <div className="loader">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      );
    }
  };

  const checkLimitError = () => {
    dispatch(
      checkMemberCount(function (res) {
        if (res) {
          if (res?.maximum_member_count <= res?.current_member_count) {
            setMemberLimitError(true);
          }
        }
      })
    );
  };

  const parentExit = (data) => {
    setCSVShow(false);
    setFile(null);
    setBeforeUpload(true);
    checkLimitError();
  };

  const handleAddSingleUser = (values) => {

    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("mobile_number", values.number);
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.secondName);

    dispatch(
      addNewMember(
        formData,
        "individual",
        function (response) {
          if (response) {
            // toast.success('Member has been added successfully')
          }
        },
        function (error) {
          if (error) {
            toast.error(error?.error?.errorMessage);
          }
        }
      )
    );
    setTimeout(() => {
      const {activePage,search,memberType} = memeberActivePage
      dispatch(getMembersList(user?.token, search, memberType, activePage));
      checkLimitError();
    }, 2000);
    setSmShow(false);
  };

  // }
  //    const  handleSync = () => {
  //     if(user && user?.is_workos_linked ){
  //         toast.warn('Already Linked')
  //     }
  //    dispatch(syncWithWorkOs())
  // }

  const handleWorkOsSync = () => {
    dispatch(
      syncWithWorkOs(function (res) {
        if (res) {
          toast.success("Successfully synced with workOs");
          dispatch(
            checkSubscriptionStatus(user?.institution_id, function (res) {
              if (res) {
              }
            })
          );
        }
      })
    );
  };

  const handleWorkOsUnSync = () => {
    dispatch(
      unSyncWithWorkOs(function (res) {
        if (res) {
          toast.success("Successfully Unsynced");
          dispatch(
            checkSubscriptionStatus(user?.institution_id, function (res) {
              if (res) {
              }
            })
          );
        }
      })
    );
  };
  return (
    <div className="tab-wrapper team-only-tab">
      <Helmet>
                <title>Teams - Cultural Engagement  </title>
            </Helmet>
      {/* <Tabs
        defaultActiveKey="Members"
        id="uncontrolled-tab-example"
        className="m-b-30"
      > */}

         <Tabs activeKey={memberkey}
            id="controlled-tab-example" className="m-b-30"
            onSelect={(k) => setMemberkey(k)}
        >

       
        <Tab eventKey="Members" title="Members">
          <Members parentCallback={handleCallback} handleChild={deselect} setmemeberActivePage={(page) => setmemeberActivePage(page)}/>
          <div className="tab-right-buttons-wrap double-btn d-flex">
            {/* {subStatus && subStatus?.is_workos_linked ? (
              <>
                <Button
                  className="mr-2"
                  disabled={true}
                  onClick={handleWorkOsUnSync}
                >
                  {loadingUnSync && loadingUnSync ? (
                    <Spinner animation="border" variant="danger" />
                  ) : (
                    <>
                      <span>Linked With WorkOs</span>
                    </>
                  )}
                </Button>
                <Button className="mr-2" onClick={handleWorkOsUnSync}>
                  {loadingUnSync && loadingUnSync ? (
                    <Spinner animation="border" variant="danger" />
                  ) : (
                    <>
                      <span>Un-Link With WorkOs</span>
                    </>
                  )}
                </Button>
              </>
            ) : (
              <Button className="mr-2" onClick={handleWorkOsSync}>
                {loadingSync && loadingSync ? (
                  <Spinner animation="border" variant="danger" />
                ) : (
                  <>
                    <span>Link With WorkOs</span>
                  </>
                )}
              </Button>
            )} */}
            {/*<Button onClick={handleWorkOsSync}>*/}

            {/*    {loadingSync && loadingSync ?*/}

            {/*   <Spinner animation="border" variant="danger"/>*/}

            {/*      :*/}
            {/*         <>*/}
            {/*       {user && user?.is_workos_linked ?*/}
            {/*               <span>Linked With WorkOs</span>*/}
            {/*                :*/}
            {/*              <span>Link With WorkOs</span>*/}
            {/*           }*/}
            {/*        </>*/}
            {/*   }*/}

            {/*</Button>*/}

            {/* <button type="button" className="btn border-btn" onClick={() => setAddMember(true)}><span className="btn-inside-icon"><i class="fa fa-plus" aria-hidden="true"></i></span>Add Member</button> */}
            <Dropdown className="team-pop-dropdowns">
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className="btn border-btn"
              >
                <span className="btn-inside-icon">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
                Add Member
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <div
                    className="team-dropdown-button"
                    onClick={() => {
                      nottrialPlan ? setSmShow(true) : setNoPart(true);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g
                          id="Layer_2"
                          data-name="Layer 2"
                          transform="translate(0.475 0.673)"
                        >
                          <g
                            id="invisible_box"
                            data-name="invisible box"
                            transform="translate(0 -0.198)"
                          >
                            <rect
                              id="Rectangle_3309"
                              data-name="Rectangle 3309"
                              width="20"
                              height="20"
                              transform="translate(-0.475 -0.475)"
                              fill="none"
                            />
                          </g>
                          <g
                            id="icons_Q2"
                            data-name="icons Q2"
                            transform="translate(1.568 1.599)"
                          >
                            <path
                              id="Path_8527"
                              data-name="Path 8527"
                              d="M19.443,9.791,13.916,4.226a.745.745,0,0,0-1.1,0L4.235,12.809A.784.784,0,0,0,4,13.358v5.526a.784.784,0,0,0,.784.784H10.31a.784.784,0,0,0,.549-.235l8.584-8.545a.745.745,0,0,0,0-1.1ZM10,18.1H5.568V13.672l4.9-4.86,4.39,4.429Zm6-5.958L11.565,7.714l1.8-1.842L17.8,10.34Z"
                              transform="translate(-4 -3.985)"
                              fill="#ce002a"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Manual user entry
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div
                    className="team-dropdown-button"
                    onClick={() => {
                      nottrialPlan ? setCSVShow(true) : setNoPart(true);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g
                          id="Layer_2"
                          data-name="Layer 2"
                          transform="translate(20) rotate(90)"
                        >
                          <g id="invisible_box" data-name="invisible box">
                            <rect
                              id="Rectangle_3313"
                              data-name="Rectangle 3313"
                              width="20"
                              height="20"
                              fill="none"
                            />
                          </g>
                          <g
                            id="icons_Q2"
                            data-name="icons Q2"
                            transform="translate(0.833 0.833)"
                          >
                            <path
                              id="Path_8530"
                              data-name="Path 8530"
                              d="M7.883,5.208a.833.833,0,0,1,.25-.583A9,9,0,0,1,14.55,2a9.167,9.167,0,0,1,0,18.333,9,9,0,0,1-6.417-2.625A.833.833,0,1,1,9.3,16.541a7.5,7.5,0,1,0,0-10.75.792.792,0,0,1-1.167,0,.833.833,0,0,1-.25-.583Z"
                              transform="translate(-5.383 -1.999)"
                              fill="#ce002a"
                            />
                            <path
                              id="Path_8531"
                              data-name="Path 8531"
                              d="M15.083,17.584l-3.292-3.333a.875.875,0,0,0-1.125-.083.792.792,0,0,0-.083,1.25L12.5,17.334H2.833A.833.833,0,0,0,2,18.167H2A.833.833,0,0,0,2.833,19H12.5l-1.917,1.917a.792.792,0,0,0,.083,1.25.875.875,0,0,0,1.125-.083l3.292-3.333a.792.792,0,0,0,0-1.167Z"
                              transform="translate(-2 -9)"
                              fill="#ce002a"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Import from CSV
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Button
              //  disabled={!nottrialPlan} onClick={() => createGroupShow(true)}
              onClick={() => {
                nottrialPlan ? createGroupShow(true) : setNoPart(true);
              }}
            >
              Create Group
            </Button>
          </div>
        </Tab>


        <Tab eventKey="Groups" title="Groups">
          <Groups handleChild={assign} parentCallback={handleGroupback} setgroupActivePage={(page) => setgroupActivePage(page)}/>

          <div className="tab-right-buttons-wrap double-btn d-flex">
            <Dropdown className="team-pop-dropdowns">
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className="btn border-btn"
              >
                <span className="btn-inside-icon">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
                Add Member
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <div
                    className="team-dropdown-button"
                    onClick={() => {
                      nottrialPlan ? setSmShow(true) : setNoPart(true);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g
                          id="Layer_2"
                          data-name="Layer 2"
                          transform="translate(0.475 0.673)"
                        >
                          <g
                            id="invisible_box"
                            data-name="invisible box"
                            transform="translate(0 -0.198)"
                          >
                            <rect
                              id="Rectangle_3309"
                              data-name="Rectangle 3309"
                              width="20"
                              height="20"
                              transform="translate(-0.475 -0.475)"
                              fill="none"
                            />
                          </g>
                          <g
                            id="icons_Q2"
                            data-name="icons Q2"
                            transform="translate(1.568 1.599)"
                          >
                            <path
                              id="Path_8527"
                              data-name="Path 8527"
                              d="M19.443,9.791,13.916,4.226a.745.745,0,0,0-1.1,0L4.235,12.809A.784.784,0,0,0,4,13.358v5.526a.784.784,0,0,0,.784.784H10.31a.784.784,0,0,0,.549-.235l8.584-8.545a.745.745,0,0,0,0-1.1ZM10,18.1H5.568V13.672l4.9-4.86,4.39,4.429Zm6-5.958L11.565,7.714l1.8-1.842L17.8,10.34Z"
                              transform="translate(-4 -3.985)"
                              fill="#ce002a"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Manual user entry
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div
                    className="team-dropdown-button"
                    onClick={() => {
                      nottrialPlan ? setCSVShow(true) : setNoPart(true);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g
                          id="Layer_2"
                          data-name="Layer 2"
                          transform="translate(20) rotate(90)"
                        >
                          <g id="invisible_box" data-name="invisible box">
                            <rect
                              id="Rectangle_3313"
                              data-name="Rectangle 3313"
                              width="20"
                              height="20"
                              fill="none"
                            />
                          </g>
                          <g
                            id="icons_Q2"
                            data-name="icons Q2"
                            transform="translate(0.833 0.833)"
                          >
                            <path
                              id="Path_8530"
                              data-name="Path 8530"
                              d="M7.883,5.208a.833.833,0,0,1,.25-.583A9,9,0,0,1,14.55,2a9.167,9.167,0,0,1,0,18.333,9,9,0,0,1-6.417-2.625A.833.833,0,1,1,9.3,16.541a7.5,7.5,0,1,0,0-10.75.792.792,0,0,1-1.167,0,.833.833,0,0,1-.25-.583Z"
                              transform="translate(-5.383 -1.999)"
                              fill="#ce002a"
                            />
                            <path
                              id="Path_8531"
                              data-name="Path 8531"
                              d="M15.083,17.584l-3.292-3.333a.875.875,0,0,0-1.125-.083.792.792,0,0,0-.083,1.25L12.5,17.334H2.833A.833.833,0,0,0,2,18.167H2A.833.833,0,0,0,2.833,19H12.5l-1.917,1.917a.792.792,0,0,0,.083,1.25.875.875,0,0,0,1.125-.083l3.292-3.333a.792.792,0,0,0,0-1.167Z"
                              transform="translate(-2 -9)"
                              fill="#ce002a"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Import from CSV
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <button type="button" className="btn border-btn" onClick={() => setAddMember(true)}><span className="btn-inside-icon"><i class="fa fa-plus" aria-hidden="true"></i></span>Add Member</button> */}
            <Button
              onClick={() => {
                nottrialPlan ? createGroupShow(true) : setNoPart(true);
              }}
            >
              Create Group
            </Button>
            {/* <button className="btn border-btn" onClick={(e)=>history.push({pathname: '/team',state:{key:state?.team?.groupDetails ? 'Groups' : 'Members'} })}>Go Back</button>  */}
            {/* <Button
              onClick={() => {
                nottrialPlan ? assignShow(true) : setNoPart(true);
              }}
            >
              Enroll
            </Button> */}
          </div>
        </Tab>
        {/* //////////////// */}


        {user?.is_edlink_teacher && <Tab eventKey="Classes" title="Edlink Classes">
          {/* <Groups handleChild={assign} parentCallback={handleGroupback} /> */}
<Classes  handleChild={assign} parentCallback={handleGroupback} />


          <div className="tab-right-buttons-wrap double-btn d-flex">
            <Dropdown className="team-pop-dropdowns">
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className="btn border-btn"
              >
                <span className="btn-inside-icon">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
                Add Member
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <div
                    className="team-dropdown-button"
                    onClick={() => {
                      nottrialPlan ? setSmShow(true) : setNoPart(true);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g
                          id="Layer_2"
                          data-name="Layer 2"
                          transform="translate(0.475 0.673)"
                        >
                          <g
                            id="invisible_box"
                            data-name="invisible box"
                            transform="translate(0 -0.198)"
                          >
                            <rect
                              id="Rectangle_3309"
                              data-name="Rectangle 3309"
                              width="20"
                              height="20"
                              transform="translate(-0.475 -0.475)"
                              fill="none"
                            />
                          </g>
                          <g
                            id="icons_Q2"
                            data-name="icons Q2"
                            transform="translate(1.568 1.599)"
                          >
                            <path
                              id="Path_8527"
                              data-name="Path 8527"
                              d="M19.443,9.791,13.916,4.226a.745.745,0,0,0-1.1,0L4.235,12.809A.784.784,0,0,0,4,13.358v5.526a.784.784,0,0,0,.784.784H10.31a.784.784,0,0,0,.549-.235l8.584-8.545a.745.745,0,0,0,0-1.1ZM10,18.1H5.568V13.672l4.9-4.86,4.39,4.429Zm6-5.958L11.565,7.714l1.8-1.842L17.8,10.34Z"
                              transform="translate(-4 -3.985)"
                              fill="#ce002a"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Manual user entry
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div
                    className="team-dropdown-button"
                    onClick={() => {
                      nottrialPlan ? setCSVShow(true) : setNoPart(true);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g
                          id="Layer_2"
                          data-name="Layer 2"
                          transform="translate(20) rotate(90)"
                        >
                          <g id="invisible_box" data-name="invisible box">
                            <rect
                              id="Rectangle_3313"
                              data-name="Rectangle 3313"
                              width="20"
                              height="20"
                              fill="none"
                            />
                          </g>
                          <g
                            id="icons_Q2"
                            data-name="icons Q2"
                            transform="translate(0.833 0.833)"
                          >
                            <path
                              id="Path_8530"
                              data-name="Path 8530"
                              d="M7.883,5.208a.833.833,0,0,1,.25-.583A9,9,0,0,1,14.55,2a9.167,9.167,0,0,1,0,18.333,9,9,0,0,1-6.417-2.625A.833.833,0,1,1,9.3,16.541a7.5,7.5,0,1,0,0-10.75.792.792,0,0,1-1.167,0,.833.833,0,0,1-.25-.583Z"
                              transform="translate(-5.383 -1.999)"
                              fill="#ce002a"
                            />
                            <path
                              id="Path_8531"
                              data-name="Path 8531"
                              d="M15.083,17.584l-3.292-3.333a.875.875,0,0,0-1.125-.083.792.792,0,0,0-.083,1.25L12.5,17.334H2.833A.833.833,0,0,0,2,18.167H2A.833.833,0,0,0,2.833,19H12.5l-1.917,1.917a.792.792,0,0,0,.083,1.25.875.875,0,0,0,1.125-.083l3.292-3.333a.792.792,0,0,0,0-1.167Z"
                              transform="translate(-2 -9)"
                              fill="#ce002a"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Import from CSV
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <button type="button" className="btn border-btn" onClick={() => setAddMember(true)}><span className="btn-inside-icon"><i class="fa fa-plus" aria-hidden="true"></i></span>Add Member</button> */}
            <Button
              onClick={() => {
                nottrialPlan ? createGroupShow(true) : setNoPart(true);
              }}
            >
              Create Group
            </Button>
            <Button
              onClick={() => {
                nottrialPlan ? assignShow(true) : setNoPart(true);
              }}
            >
              Enroll
            </Button>
          </div>
        </Tab>}
        {/* ////////classes end///////// */}

      </Tabs>

      <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        className="common-modal team-member-pop inner-popup adduser-popup"
      >
        <Modal.Header closeButton>
          <div className="addmember-header">
            <h4>Add User</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>{handleLoader()}</div>
          {!memberLimitError ? (
            <div>
              <Formik
                validationSchema={supportSchema}
                onSubmit={handleAddSingleUser}
                initialValues={{
                  firstName: "",
                  secondName: "",
                  number: "",
                  email: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="form-input-wrap">
                      <Form.Label>
                        First Name<span className="mandatory">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        pattern="[a-zA-Z]+"
                        placeholder=" First Name"
                        className="input-item"
                        name="firstName"
                        onChange={handleChange}
                        value={values.firstName}
                        isInvalid={!!errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input-wrap">
                      <Form.Label>
                        Last Name<span className="mandatory">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        className="input-item"
                        name="secondName"
                        onChange={handleChange}
                        value={values.secondName}
                        isInvalid={!!errors.secondName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.secondName}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input-wrap">
                      <Form.Label>
                        Email<span className="mandatory">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder=" Email"
                        className="input-item"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input-wrap">
                      <Form.Label>
                        Phone Number<span className="mandatory">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=" Phone Number"
                        className="input-item"
                        name="number"
                        value={values.number}
                        onChange={handleChange}
                        isInvalid={!!errors.number}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.number}
                      </Form.Control.Feedback>
                    </div>
                    <div className="button-wrap double-btn add-user-btn-wrap d-flex justify-content-between m-t-20">
                      <button
                        type="button"
                        className="btn border-btn"
                        onClick={() => setSmShow(false)}
                      >
                        Cancel
                      </button>
                      <Button type="submit" variant="primary">
                        Add User
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <div className="csv-error-show">
              <div className="csv-error-icon">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
              </div>

              <div className="csv-error-content">
                <p>
                  You have more members than what your current plan offers.
                  Please remove the excess members and add Or
                </p>
                <div className="error-link">
                  <span className='d-inline text-primary' style={{cursor:'pointer'}} onClick={() => history.push({pathname:'/profile', state:{key:'Subscription'}})}>upgrade your plan</span>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={csvShow}
        onHide={() => setCSVShow(false)}
        onExit={() => {
          setFile(null);
          setBeforeUpload(true);
        }}
        className="common-modal team-member-pop inner-popup adduser-popup"
        centered
      >
        <Modal.Header closeButton>
          <div className="addmember-header">
            {beforeupload ? (
              <h4>Import from CSV</h4>
            ) : (
              <h4
                style={{ cursor: "pointer" }}
                onClick={() => setBeforeUpload(true)}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>Upload CSV
              </h4>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          {beforeupload ? (
            <div className="import-csv-container">
              {" "}
              <ImportCsv fileAdded={setBeforeUpload} />
            </div>
          ) : (
            <div className="import-csv-container">
              {" "}
              <UploadCsv modalClose={setCSVShow} parentExit={parentExit} />
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* create group */}
      <Modal
        size="md"
        show={createGroup}
        // scrollable={true}
        backdrop="static"
        onEntered={() => handleGroupEntry()}
        onHide={() => createGroupShow(false)}
        onExited={() => handleGroupExit()}
        aria-labelledby="example-modal-sizes-title-lg"
        className="common-modal team-member-pop inner-popup"
      >
        <Modal.Header closeButton>
          <div className="addmember-header">
            <h4>Create Group</h4>
            <p>Adding Members shall create a group</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-input-wrap">
            <Formik
              initialValues={{ groupname: "" }}
              validationSchema={Yup.object({
                groupname: Yup.string()
                  .required("Required")
              })}
              onSubmit={handleCreateGroup}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit} className="">
                  <div className="form-input-wrap ">
                    <div className="form-group">
                      <label>Group Title</label>
                      <div className="d-flex justify-content-between">
                        <input
                          className="form-control input-item w-75"
                          type="text"
                          placeholder="Enter Group Name"
                          onChange={handleChange}
                          value={values.groupname}
                          name="groupname"
                        />
                        <div className=" d-flex justify-content-end">
                          {/* <Button variant="danger" type="submit"  >Create</Button> */}
                          <div className="group-title-create-right">
                            <Button type="submit">Create</Button>
                          </div>
                        </div>
                      </div>
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        {errors.groupname}
                      </span>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="group-list-selected-box-content">
            {selectedData && selectedData?.length
              ? selectedData?.map((item, index) => (
                  <div className="course-selected-item p-relative">
                    <span className="course-selected-text">
                      {item?.get_full_name?.length > 1
                        ? item?.get_full_name
                        : item?.id}
                    </span>
                    <span
                      className="memb-close-icon"
                      onClick={() => handleRemove(item, index)}
                    >
                      ×
                    </span>
                  </div>
                ))
              : ""}
          </div>
          <div className="group-pop-footer">
            <h4>Add New Members To The Group</h4>
            <div className="form-input-wrap">
              <Form.Label>Search and Select Email Address</Form.Label>
              <div className="d-flex w-100">
                <div className="group-title-create-left">
                  <Select
                    placeholder="Enter Email Address"
                    isClearable={true}
                    onChange={AddNew}
                    onInputChange={searchMembers}
                    options={newMembers || []}
                    getOptionLabel={(option) => option?.email}
                    getOptionValue={(option) => option?.id}
                  />
                </div>
                <div className="group-title-create-right">
                  <Button
                    onClick={addNewMembers}
                    disabled={newEntry?.length <= 0 ? "disabled" : ""}
                  >
                    Add
                  </Button>
                </div>
              </div>
              {memberExist ? (
                <span style={{ color: "#ce002a" }}>
                  Member Already selected{" "}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="custom-modal inner-popup letsgo-pop common-modal add-module-content-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={NoPart}
        onHide={() => setNoPart(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="letsgo-pop-wrap  text-center">
            <div className="nopart-img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="339.178"
                height="279.188"
                viewBox="0 0 339.178 279.188"
              >
                <g
                  id="undraw_selected_options_re_vtjd"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_8630"
                    data-name="Path 8630"
                    d="M521.214,657.958l-11.836,1.817,12.364-5.844c1-11.379-1.662-23.586-1.662-23.586s-25.536,4.441-33.4,16.138-3.655,20.457,4.924,26.223,18.278,6.357,26.141-5.341A24.721,24.721,0,0,0,521.214,657.958Z"
                    transform="translate(-373.301 -398.076)"
                    fill="#f2f2f2"
                  />
                  <path
                    id="Path_8631"
                    data-name="Path 8631"
                    d="M516.693,658.354l-.388.577c-4.54,6.679-9.185,11.149-13.878,13.34-.068.033-.137.062-.2.095l-.147-.318-.137-.308c5.133-2.322,9.782-7.358,13.743-13.182.13-.187.261-.376.387-.569,1.655-2.481,3.186-5.086,4.579-7.681.11-.2.218-.4.327-.609,1.82-3.435,3.4-6.827,4.689-9.824.092-.211.182-.425.269-.635,2.169-5.082,3.485-8.934,3.771-9.79.037-.1.055-.162.06-.175l.323.105h0l.326.107c0,.013-.066.209-.189.565-.454,1.336-1.724,4.938-3.665,9.509-.088.206-.178.419-.27.63-1.047,2.433-2.274,5.115-3.664,7.861q-.522,1.035-1.052,2.028c-.106.207-.214.411-.324.611Q519,654.9,516.693,658.354Z"
                    transform="translate(-382.741 -397.555)"
                    fill="#fff"
                  />
                  <path
                    id="Path_8632"
                    data-name="Path 8632"
                    d="M541.738,642.415c-.209-.1-.42-.208-.63-.32a18.978,18.978,0,0,1-1.666-1,18.457,18.457,0,0,1-5.617-5.947l-.669.213a19.141,19.141,0,0,0,5.9,6.305,19.606,19.606,0,0,0,1.78,1.064c.208.111.419.215.629.315a18.979,18.979,0,0,0,5.48,1.682q-.026-.359-.049-.7A18.4,18.4,0,0,1,541.738,642.415Z"
                    transform="translate(-397.916 -400.411)"
                    fill="#fff"
                  />
                  <path
                    id="Path_8633"
                    data-name="Path 8633"
                    d="M531.005,655q-.307-.182-.6-.38c-.062-.037-.125-.08-.185-.12a18.555,18.555,0,0,1-8.2-15.1c-.227.092-.455.183-.681.28a19.244,19.244,0,0,0,8.5,15.389c.08.054.16.107.242.158.2.131.4.258.605.382a19.142,19.142,0,0,0,10.459,2.721c.022-.23.044-.46.063-.691A18.428,18.428,0,0,1,531.005,655Z"
                    transform="translate(-392.17 -402.48)"
                    fill="#fff"
                  />
                  <path
                    id="Path_8634"
                    data-name="Path 8634"
                    d="M521.028,665.2c-.211-.117-.419-.24-.624-.366s-.438-.274-.652-.417a18.605,18.605,0,0,1-7.336-21.06c-.284.145-.566.287-.844.436a19.287,19.287,0,0,0,7.8,21.2c.214.144.43.285.649.415s.414.253.623.374a19.23,19.23,0,0,0,13.989,1.939c.082-.251.154-.506.227-.763A18.545,18.545,0,0,1,521.028,665.2Z"
                    transform="translate(-387.076 -404.401)"
                    fill="#fff"
                  />
                  <path
                    id="Path_682"
                    data-name="Path 682"
                    d="M304.579,338.007a13.358,13.358,0,1,1,13.358-13.358h0a13.374,13.374,0,0,1-13.358,13.359Zm0-25.467a12.108,12.108,0,1,0,12.108,12.108h0a12.122,12.122,0,0,0-12.108-12.107Z"
                    transform="translate(-280.306 -242.976)"
                    fill="#b5b5b5"
                  />
                  <path
                    id="Path_8635"
                    data-name="Path 8635"
                    d="M587.557,337.993a13.892,13.892,0,1,1,9.8-4.066,13.892,13.892,0,0,1-9.8,4.066Zm0-26.716A12.836,12.836,0,1,0,600.4,324.119a12.836,12.836,0,0,0-12.847-12.843Z"
                    transform="translate(-417.599 -242.449)"
                    fill="#b5b5b5"
                  />
                  <path
                    id="Path_682-2"
                    data-name="Path 682"
                    d="M878.947,326.421a5.58,5.58,0,1,0,5.584,5.584,5.58,5.58,0,0,0-5.584-5.584Z"
                    transform="translate(-563.303 -250.331)"
                    fill="#ce002a"
                  />
                  <path
                    id="Path_680"
                    data-name="Path 680"
                    d="M839.609,178.35a2.122,2.122,0,1,0,0,4.244H882.66a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-545.859 -178.35)"
                    fill="#6c63ff"
                  />
                  <path
                    id="Path_681"
                    data-name="Path 681"
                    d="M839.609,202.682a2.122,2.122,0,0,0,0,4.244H882.66a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-545.859 -190.178)"
                    fill="#6c63ff"
                  />
                  <path
                    id="Path_681-2"
                    data-name="Path 681"
                    d="M839.609,227.271a2.122,2.122,0,0,0,0,4.244H882.66a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-545.859 -202.132)"
                    fill="#6c63ff"
                  />
                  <path
                    id="Path_8636"
                    data-name="Path 8636"
                    d="M407.813,454.656a5.167,5.167,0,0,0-1.534-7.774l3.076-18.1-8.734,3.845-1.582,16.6a5.2,5.2,0,0,0,8.774,5.431Z"
                    transform="translate(-332.482 -300.089)"
                    fill="#ffb6b6"
                  />
                  <path
                    id="Path_8637"
                    data-name="Path 8637"
                    d="M394.223,371.77,392.63,326.5v-.031l8.549-41.555a6.477,6.477,0,0,1,12.71,2.5l-6.635,35.273-.141,49.377Z"
                    transform="translate(-329.602 -227.637)"
                    fill="#ce002a"
                  />
                  <path
                    id="Path_8649"
                    data-name="Path 8649"
                    d="M394.223,371.77,392.63,326.5v-.031l8.549-41.555a6.477,6.477,0,0,1,12.71,2.5l-6.635,35.273-.141,49.377Z"
                    transform="translate(-329.602 -227.637)"
                    opacity="0.1"
                  />
                  <path
                    id="Path_8638"
                    data-name="Path 8638"
                    d="M208.046,506.769h6.3l3-24.3h-9.3Z"
                    transform="translate(-101.135 -234.54)"
                    fill="#ffb6b6"
                  />
                  <path
                    id="Path_8639"
                    data-name="Path 8639"
                    d="M474.9,704.1h12.408a7.907,7.907,0,0,1,7.907,7.906v.257H474.9Z"
                    transform="translate(-369.596 -433.932)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_8640"
                    data-name="Path 8640"
                    d="M155.046,506.769h6.3l3-24.3h-9.3Z"
                    transform="translate(-75.371 -234.54)"
                    fill="#ffb6b6"
                  />
                  <path
                    id="Path_8641"
                    data-name="Path 8641"
                    d="M421.9,704.1h12.407a7.907,7.907,0,0,1,7.907,7.906v.257H421.9Z"
                    transform="translate(-343.831 -433.932)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_8642"
                    data-name="Path 8642"
                    d="M449.118,378.177l2.4,5.156,7.222,67.153-7.1,89.147-12.544.412,2.167-82.365-15.529-33.391-.915,117.153H413.858L403.491,423.88s-3.575-32.709,9.4-45.7Z"
                    transform="translate(-334.663 -275.491)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_8643"
                    data-name="Path 8643"
                    d="M415.844,327.057l-.19-.025-6.317-39.372a11.294,11.294,0,0,1,6.743-12.2l6.53-2.777,20.411-.182,8.1,4.834a18.561,18.561,0,0,1,8.9,18.337l-4.747,36.58Z"
                    transform="translate(-337.654 -224.117)"
                    fill="#ce002a"
                  />
                  <path
                    id="Path_8644"
                    data-name="Path 8644"
                    d="M495.441,462.1a5.167,5.167,0,0,1,1.43-7.794l-3.318-18.061,8.784,3.727,1.8,16.58a5.2,5.2,0,0,1-8.7,5.548Z"
                    transform="translate(-378.664 -303.719)"
                    fill="#ffb6b6"
                  />
                  <path
                    id="Path_8645"
                    data-name="Path 8645"
                    d="M478.945,333.549l-.005-.03,2.779-42.334a6.512,6.512,0,0,1,5.057-5.9h0a6.477,6.477,0,0,1,7.876,6.636l-1.743,35.849,6.621,48.931-12.808,1.475Z"
                    transform="translate(-371.56 -230.259)"
                    fill="#ce002a"
                  />
                  <circle
                    id="Ellipse_321"
                    data-name="Ellipse 321"
                    cx="13.478"
                    cy="13.478"
                    r="13.478"
                    transform="translate(84.203 18.066)"
                    fill="#ffb6b6"
                  />
                  <path
                    id="Path_8646"
                    data-name="Path 8646"
                    d="M411.662,240.414a50.7,50.7,0,0,1,4.744-3.041,6.824,6.824,0,0,0,3.26-4.4c.311-2.237-1.126-4.284-2.049-6.344A14.746,14.746,0,0,1,442.3,211.044a5.627,5.627,0,1,1,6.686,8.6c-2.208.95-4.9.445-6.973,1.659-2.166,1.267-2.985,4.11-2.6,6.59a18.972,18.972,0,0,0,3.047,6.825l-2.909-4.422c1.59,3.365,3.207,6.831,3.448,10.544s-1.207,7.784-4.461,9.59c-2.852,1.583-6.77,1.335-8.856,3.843a5.669,5.669,0,0,0-1.206,3.708c-3.143-.484-6.3-.91-9.446-1.355l-10.506-1.485a21.709,21.709,0,0,1-.673-3.43C407.407,247.6,408.512,243.095,411.662,240.414Z"
                    transform="translate(-336.958 -191.787)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_680-2"
                    data-name="Path 680"
                    d="M556.106,178.35a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-408.041 -178.35)"
                    fill="#b5b5b5"
                  />
                  <path
                    id="Path_681-3"
                    data-name="Path 681"
                    d="M556.106,202.682a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-408.041 -190.178)"
                    fill="#b5b5b5"
                  />
                  <path
                    id="Path_681-4"
                    data-name="Path 681"
                    d="M556.106,227.271a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-408.041 -202.132)"
                    fill="#b5b5b5"
                  />
                  <path
                    id="Path_680-3"
                    data-name="Path 680"
                    d="M273.106,178.35a2.122,2.122,0,0,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-270.468 -178.35)"
                    fill="#b5b5b5"
                  />
                  <path
                    id="Path_681-5"
                    data-name="Path 681"
                    d="M273.106,202.682a2.122,2.122,0,0,0,0,4.245h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-270.468 -190.178)"
                    fill="#b5b5b5"
                  />
                  <path
                    id="Path_681-6"
                    data-name="Path 681"
                    d="M273.106,227.271a2.122,2.122,0,0,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-270.468 -202.132)"
                    fill="#b5b5b5"
                  />
                  <path
                    id="Path_680-4"
                    data-name="Path 680"
                    d="M840.106,178.35a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-546.101 -178.35)"
                    fill="#ce002a"
                  />
                  <path
                    id="Path_681-7"
                    data-name="Path 681"
                    d="M840.106,202.682a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-546.101 -190.178)"
                    fill="#ce002a"
                  />
                  <path
                    id="Path_681-8"
                    data-name="Path 681"
                    d="M840.106,227.271a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                    transform="translate(-546.101 -202.132)"
                    fill="#ce002a"
                  />
                  <path
                    id="Path_8647"
                    data-name="Path 8647"
                    d="M460.363,720.492H270.592a.612.612,0,1,1,0-1.224H460.363a.612.612,0,1,1,0,1.224Z"
                    transform="translate(-269.98 -441.304)"
                    fill="#cacaca"
                  />
                  <path
                    id="Path_8648"
                    data-name="Path 8648"
                    d="M870.128,338.091a14.9,14.9,0,1,1,14.9-14.9A14.9,14.9,0,0,1,870.128,338.091Zm0-28.772A13.872,13.872,0,1,0,884,323.191a13.872,13.872,0,0,0-13.872-13.872Z"
                    transform="translate(-554.484 -241.517)"
                    fill="#ce002a"
                  />
                </g>
              </svg>
            </div>
            <h3>
              To access this feature, please subscribe to any of our plans.
            </h3>
            <div className="button-wrap double-btn no-part-btns">
              <button
                type="button"
                onClick={() => setNoPart(false)}
                className="btn border-btn"
              >
                Cancel
              </button>
              <Button
                variant="primary"
                onClick={
                  () => {
                    history.push({
                      pathname:"/profile",state:{key:'Subscription'}})
                  }
                }

              >
                Choose Plan
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
var reg = /^[0-9\b]+$/;
var re = /^[A-Za-z]+$/;
const supportSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .matches(re, "Enter Valid Name"),
  secondName: Yup.string()
    .required("Last Name is required")
    .matches(re, "Enter Valid Name"),
  number: Yup.string("").required("Phone number is required")
    //   .matches(regex, 'Phone add + and valid country code')
    .matches(reg, "Enter valid phone number"),
    //.min(10, "too short"),
  //   .max(13, "too long").required('Mobile Number is required'),
  email: Yup.string().email('Please enter a valid email').required("Email is required"),
});

export default Team;
