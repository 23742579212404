import React, {Fragment, useEffect, useState} from 'react';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Classic from 'ckeditor5/packages/ckeditor5-build-classic/build/ckeditor'
import Col from "react-bootstrap/Col";


const CkEditorQuestion = ({name,values,setFieldValue,moduleErrors,moduleTouched,moduleTestsError,handleBlur,index}) => {



    return (
        <Fragment>
            <div>

                <CKEditor
                    activeclassName="p10"
                    config={{placeholder: "Enter Question"}}
                    data={values?.modules[index]?.module_tests[0]?.question ? values?.modules[index]?.module_tests[0]?.question : ""}
                    onBlur={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue(name, data)

                    }}
                    editor={Classic}


                />
                {moduleErrors?.module_tests && moduleErrors?.module_tests.length   && moduleErrors?.module_tests[0]?.question && moduleTouched?.module_tests && moduleTouched?.module_tests.length && !!moduleTouched?.module_tests[0]?.question?
                    <span className={`form-error text-danger`} style={{display:'block'}}>{moduleErrors?.module_tests[0]?.question}</span>
                    :
                    ''
                }
            </div>

        </Fragment>
    );
}

export default CkEditorQuestion;
