import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from "./ckQues";
import {useSelector,useDispatch} from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {ToastContainer} from "react-toastify";
import {FormControl} from "react-bootstrap";
import {deleteQuizChoice} from "../../../../../services";

function MultipleChoice(props) {
    const dispatch = useDispatch();
    let loading = useSelector(state => state?.quiz?.get_quiz_manager?.loading);
    const { values,errors,index,setFieldValue,questionsErrors,questionsTouched,handleBlur,setValues} = props
    const [showinput, setshowinput] = useState(false)
    const [hideicon, sethideicon] = useState(true)
    const [choiceid, setchoiceid] = useState('')

    useEffect(()=>{
        if(values?.questions[index]?.multiple_choices[3]?.choice?.length  > 0){
            sethideicon(false)
        }
    },[])

    const handleAnswer = (e)=> {
        setFieldValue(`questions.${index}.right_answer`, e.target.value)
        const questions = [...values.questions]
        questions[index].multiple_choices.forEach((el)=>{
            el.is_correct_answer='False'
        })
        questions[index].right_answer=e.target.value
        questions[index].multiple_choices[e.target.value].is_correct_answer = 'True';

        setValues({...values, questions})
    }
    const handleThird = (e) =>{
        setFieldValue(`questions.${index}.multiple_choices.${2}.choice`,e.target.value)
    }
    const handleRemoveIcone = (choice) =>{

        if(choice?.id && choice?.choice?.length && choice?.id != choiceid  ) {
            setchoiceid(choice?.id)

            dispatch(deleteQuizChoice(choice?.id, function (res) {
                if (res) {
                    setshowinput(false)
                    sethideicon(true)
                    setFieldValue(`questions.${index}.multiple_choices.${3}.id`,'')
                }
            }))
            if(choice?.is_correct_answer){
                setFieldValue(`questions.${index}.right_answer`,'')

            }

        }else{
            setshowinput(false)
            sethideicon(true)
        }
        if(choice?.is_correct_answer == 'True' || choice?.is_correct_answer == true){
            setFieldValue(`questions.${index}.right_answer`,'')

        }
        setFieldValue(`questions.${index}.multiple_choices.${3}.choice`,'')

    }

    const handleAddIcon = () =>{
        const questions = [...values.questions]
        if( questions[index]?.multiple_choices &&  questions[index]?.multiple_choices.length > 3){
        questions[index].multiple_choices[3].is_correct_answer = false
            }else{
            questions[index].multiple_choices.push({choice:'',is_correct_answer:false})
        }
        setshowinput(true)
        sethideicon(false)
        setValues({...values, questions})
    }

    const handleAddIconView = () => {
        if(hideicon){
            return( <span style={{marginTop:'29px'}} className="memb-add-icon-quiz" onClick={handleAddIcon}>+</span>)
        }else {
            return (<span></span>)
        }

    }
    const SELECT_OPTIONS = [1,2,3,4];
    const SELECT_OPTIONS_THIRD = [1,2,3]
    return (
        <div className="module-quiz-add-wrap">
            <ToastContainer autoClose={2000} />
            {/*<div className="form-close-btn p-relative"><span className="memb-close-icon">×</span></div>*/}
            <Form>
                <Row className="justify-content-center">

                    <Col md={12} >
                        <div className="form-input-wrap">
                            <Form.Label>Add Question</Form.Label>
                            <CkEditorQuestion    handleBlur={handleBlur} questionsErrors={questionsErrors} questionsTouched={questionsTouched} name={`questions.${index}.question`} setFieldValue={setFieldValue} values={values} handleBlur={handleBlur} index={index}/>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Row className="">
                            <Col md={12}>
                                <div className="form-input-wrap">

                                    <div  className="form-input-wrap">
                                        <Form.Label>Option1</Form.Label>
                                        <Form.Control
                                            type="text" placeholder="Option 1"
                                            defaultValue={values?.questions[index]?.multiple_choices[0]?.choice}
                                            name={`questions.${index}.multiple_choices.${0}.choice`}
                                            onBlur={e=>setFieldValue(`questions.${index}.multiple_choices.${0}.choice`,e.target.value)}
                                            isInvalid={!!questionsErrors?.multiple_choices && !!questionsErrors?.multiple_choices?.length && !!questionsErrors?.multiple_choices[0]  && questionsTouched?.multiple_choices?.length && questionsTouched?.multiple_choices?.length && !!questionsTouched?.multiple_choices[0] && !!questionsTouched?.multiple_choices[0]}
                                            // onChange={e=>setFieldValue(`questions.${index}.multiple_choices.${0}.choice`,e.target.value)}
                                        />

                                    </div>

                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-input-wrap">
                                    <div  className="form-input-wrap">
                                        <Form.Label>Option2</Form.Label>
                                        <Form.Control

                                            type="text" placeholder="Option 2"
                                            name={`questions.${index}.multiple_choices.${1}.choice`}
                                            defaultValue={values?.questions[index]?.multiple_choices[1]?.choice}
                                            onBlur={e=>setFieldValue(`questions.${index}.multiple_choices.${1}.choice`,e.target.value)}
                                            isInvalid={!!questionsErrors?.multiple_choices && !!questionsErrors?.multiple_choices?.length && !!questionsErrors?.multiple_choices?.length && !!questionsErrors?.multiple_choices[1] && questionsTouched?.multiple_choices && questionsTouched?.multiple_choices?.length && questionsTouched?.multiple_choices?.length && !!questionsTouched?.multiple_choices[1] && !!questionsTouched?.multiple_choices[1] }
                                            // onChange={e=>setFieldValue(`questions.${index}.multiple_choices.${1}.choice`,e.target.value)}
                                        />

                                    </div>

                                </div>
                            </Col>


                        </Row>

                        <Row>

                            <Col md={11}>
                                <div className="form-input-wrap" >
                                    <Form.Label>Option3</Form.Label>
                                    <Form.Control
                                        type="text" placeholder="Option 3"
                                        name={`questions.${index}.multiple_choices.${2}.choice`}
                                        defaultValue={values?.questions[index]?.multiple_choices[2]?.choice}
                                        onBlur={handleThird}
                                        isInvalid={!!questionsErrors?.multiple_choices && !!questionsErrors?.multiple_choices?.length && !!questionsErrors?.multiple_choices?.length && !!questionsErrors?.multiple_choices[2] && questionsTouched?.multiple_choices && questionsTouched?.multiple_choices?.length && questionsTouched?.multiple_choices?.length && !!questionsTouched?.multiple_choices[2] && !!questionsTouched?.multiple_choices[2]}
                                        // onChange={handleThird}
                                    />

                                </div>

                            </Col>
                            <Col md={1}>
                                {handleAddIconView()}

                            </Col>

                        </Row>
                        <Row>
                            {values?.questions[index]?.multiple_choices[3]?.choice?.length || showinput  ?
                                <>
                                    <Col md={11}>
                                        <div className="form-input-wrap"   >
                                            <Form.Label>Option4</Form.Label>
                                            <Form.Control type="text" placeholder="Option 4"
                                                          name={`questions.${index}.multiple_choices.${3}.choice`}
                                                          defaultValue={values?.questions[index]?.multiple_choices[3]?.choice}
                                                          onBlur={e => setFieldValue(`questions.${index}.multiple_choices.${3}.choice`, e.target.value)}
                                                // isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.choices?.length && !!moduleErrors?.module_tests[0]?.choices[3] && moduleTouched?.module_tests && moduleTouched?.module_tests.length && !!moduleTouched?.module_tests[0]?.choices[3]}
                                                //           onChange={e => setFieldValue(`questions.${index}.multiple_choices.${3}.choice`, e.target.value)}
                                            />


                                        </div>
                                    </Col>
                                    <Col md={1}>
                                        <span className="memb-add-icon-quiz" style={{marginTop:'29px'}} onClick={()=>handleRemoveIcone(values?.questions[index]?.multiple_choices[3])}>
                                                 {loading && choiceid == values?.questions[index]?.multiple_choices[3]?.choice?.id ?
                                                     <Spinner animation="border" variant="warning"/>
                                                     :
                                                     '-'
                                                 }
                                        </span>
                                    </Col>
                                </>
                                :
                                ''
                            }

                        </Row>
                        <Row>
                            <Col md={4}>
                                {/*onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.rightAnswer`,e.target.value)}*/}
                                <div className="form-input-wrap" >
                                    <Form.Label>Right Answer</Form.Label>
                                    {values.questions[index].id ?
                                           <>
                                        <Form.Control as="select"
                                                      name={`questions.${index}.right_answer`} onChange={handleAnswer}
                                                      value={values?.questions[index]?.right_answer}
                                                      isInvalid={!!questionsErrors?.right_answer &&  questionsTouched?.right_answer &&  !!questionsTouched?.right_answer}
                                        >

                                            <option value="">Choose right answer</option>
                                            {SELECT_OPTIONS && SELECT_OPTIONS?.length && values?.questions[index]?.multiple_choices[3]?.choice?.length ? SELECT_OPTIONS.map((item,index)=>(
                                                    <option value={index}>Option {index + 1}</option>
                                                ))
                                                :
                                                SELECT_OPTIONS_THIRD?.map((item,index)=>(
                                                    <option value={index}>Option {index + 1}</option>
                                                ))
                                            }

                                        </Form.Control>
                                               <FormControl.Feedback type="invalid">
                                                   {questionsErrors?.right_answer}
                                               </FormControl.Feedback>
                                           </>
                                        :
                                        <>
                                        <Form.Control as="select"
                                                      name={`questions.${index}.right_answer`} onChange={handleAnswer}
                                                      value={values?.questions[index]?.right_answer}
                                                      onBlur={handleBlur}
                                                      isInvalid={!!questionsErrors?.right_answer &&  questionsTouched?.right_answer &&  !!questionsTouched?.right_answer}
                                        >

                                            <option value="">Choose right answer</option>
                                            {SELECT_OPTIONS && SELECT_OPTIONS?.length && values?.questions[index]?.multiple_choices[3]?.choice?.length ? SELECT_OPTIONS.map((item,index)=>(
                                                    <option value={index}>Option {index + 1}</option>
                                                ))
                                                :
                                                SELECT_OPTIONS_THIRD?.map((item,index)=>(
                                                    <option value={index}>Option {index + 1}</option>
                                                ))
                                            }

                                        </Form.Control>
                                            <FormControl.Feedback type="invalid">
                                                {questionsErrors?.right_answer}
                                            </FormControl.Feedback>
                                            </>

                                    }
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form-input-wrap" >
                                    <Form.Label>Score for the Question</Form.Label>
                                    <Form.Control
                                        type="text" placeholder="Score"
                                        name={`questions.${index}.score`}
                                        value={values?.questions[index]?.score}
                                        // onBlur={e => setFieldValue(`questions.${index}.score`, e.target.value)}
                                        isInvalid={!!questionsErrors?.score && !!questionsTouched?.score }
                                        onChange={e => setFieldValue(`questions.${index}.score`, e.target.value)}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {questionsErrors?.score}
                                    </FormControl.Feedback>
                                </div>


                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default MultipleChoice;

