import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

const CustomPagination = ({
  response,
  activePage,
  handleNext,
  handlePrevious,
  handleIndex,
}) => {
  const [state, setState] = useState({
    data: response,
    count: response?.count,
    paginationItem: [],
  });

  useEffect(() => {
    const count = response?.count;
    const perPage = 12;
    const buttonsCount = Math.ceil(count / perPage);

    let items = [];
    for (let number = 1; number <= Number(buttonsCount); number++) {
      items.push(
        <Pagination.Item
          onClick={() => handleIndex(number)}
          key={number}
          active={number == activePage}
        >
          {number}
        </Pagination.Item>
      );
    }
    setState({ ...state, data: response, paginationItem: items });
  }, [response]);
  return (
    <>
      <div className="d-flex justify-content-center">
        <Pagination size="sm" className="custom-pagination">
          <Pagination.First
            disabled={!state?.data?.previous}
            className={`${state?.data?.previous ? "paginationStyle" : ""} `}
            onClick={handlePrevious}
          />
          {state.paginationItem}
          <Pagination.Last
            disabled={!state?.data?.next}
            onClick={handleNext}
            className={`${state?.data?.next ? "paginationStyle" : ""} `}
          />
        </Pagination>
      </div>
    </>
  );
};
export default CustomPagination;
