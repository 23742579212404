import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import {FormControl, FormLabel} from "react-bootstrap";
import Form from "react-bootstrap/Form";

function Quiz(props) {
  const {values,index,setFieldValue,touched,errors,handleChange} = props
  return (
    <div className="add-quiz-wrap">
      <div className="course-form-content-sec">
        <div className="whitebox shadow-box module-form-box">
            <div className="form-input-wrap">
              <FormLabel>Quiz Title</FormLabel>
              <FormControl
                  type="string"
                  name="name"
                  placeholder="Name"
                  onChange={handleChange}
                  value={values?.name}
                  isInvalid={!!errors?.name && !!touched?.name
                      ? ' is-invalid'
                      : ''}

              />
              <FormControl.Feedback type="invalid">
                {errors?.name}
              </FormControl.Feedback>
            </div>
            <div className="form-input-wrap">
                <Form.Label>Quiz Description</Form.Label>
                <Form.Control as="textarea" placeholder="Course Description"
                              style={{ height: '120px' }}
                              className="textarea-item"
                              name="description"
                              value={values?.description}
                              onChange={handleChange}
                              isInvalid={!!errors?.description && !!touched?.description
                                  ? ' is-invalid'
                                  : ''}


                />
                {errors.description && touched?.description &&
                <span className={'text-danger mt-1'}>  {errors.description}</span>}

            </div>


        </div>
      </div>
    </div>

  );
};
export default Quiz;
