import React, {useCallback, useEffect, useState} from 'react';
import FilterImg from '~/assets/images/icons/filter.svg';
import SearchImg from '~/assets/images/icons/magnifiying-glass.svg';
import CourseDummy from '~/assets/images/course_dummy.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem'
import { getLearnerCoursesList,handlePaginationLearnerCourse,handlePreviousPaginationLearnerCourse } from '../../../services';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "react-bootstrap/Spinner";
import {Link, useHistory, useLocation} from 'react-router-dom';
import Button from "react-bootstrap/Button";
import {getNotifications} from "../../../../Notifications/services";
import debounce from "lodash.debounce";
import {getManagerCoursesList} from "../../../../coursemanagement/services";
import {format} from "date-fns";
const Courses = ({match}) => {
   const history = useHistory()
   const dispatch = useDispatch();
   const location = useLocation();
   const response = useSelector(state => state.learnercourse?.learner_courses_list?.response);
   const loading = useSelector(state => state?.learnercourse?.learner_courses_list?.loading);
   const [state, setState] =useState({
      courses:[],
      count:response?.count,
      paginationItem:[]
   })
   const [activePage, setActivePage] = useState(1);
   const [search, setSearch] = useState('');
   const [status, setStatus] = useState('');
        const  url = match?.url


   const options = [
      { value: 'new', label: 'New' },
      { value: 'in_progress', label: 'In progress' },
      { value: 'completed', label: 'Completed' }
   ];


   useEffect(() => {

       if(location?.state && location?.state?.category  && location?.state?.category == 'complete'){
         let option =  { value: 'completed', label: 'Completed' }
         setStatus(option);
         dispatch(getLearnerCoursesList(search, 'completed'));

      }else if(location?.state && location?.state?.category  && location?.state?.category == 'ongoing'){
        let option = { value: 'in_progress', label: 'In progress' }
         setStatus(option);
         dispatch(getLearnerCoursesList(search, 'in_progress'));
      }else  {
          setSearch('');
          setStatus('');

             dispatch(getLearnerCoursesList(search, status));
       }
      dispatch(getNotifications())

   }, []);


   useEffect(() => {
      const count = response?.count
      const perPage =12;
      const buttonsCount = Math.ceil(count/perPage);

      let items =[]
      for (let number = 1; number <= Number(buttonsCount); number++) {
         items.push(
             <Pagination.Item onClick={()=>handleIndex(number)} key={number} active={number == activePage}>
                {number}
             </Pagination.Item>,
         );
      }
      setState({...state, courses:response,count: response?.count,paginationItem:items});
   }, [response]);

   const debouncedGetSearch = useCallback(debounce(query => {

      setActivePage(1)
      dispatch(getLearnerCoursesList( query, status === '' ? '' : status?.value));
   },1000),[])

   const searchText = (e) => {
      setSearch(e.target.value);
      debouncedGetSearch(e.target.value)

   };

   const onSelect = (val) =>{
      setStatus(val);
      setActivePage(1);
      dispatch(getLearnerCoursesList(search, val.value));
   };

   const reset = () => {
      setSearch('');
      setStatus('');
      dispatch(getLearnerCoursesList( '', ''));
   };
   const handleNext = () => {

      if (state.courses?.next) {
       var regex = /page=\d+/g;
         const [number ] = state.courses?.next?.match(regex)
         setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
         dispatch(handlePaginationLearnerCourse(state.courses?.next))
      } else {
         return false
      }

   }

   const handlePrevious = () => {
      if (state.courses?.previous) {
         setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
         dispatch(handlePreviousPaginationLearnerCourse(state.courses?.previous))
      } else {
         return false
      }

   }
   const handleIndex = (index) =>{
      if(index == 1){
         dispatch(getLearnerCoursesList('','',''));
      }else{
         dispatch(getLearnerCoursesList(search ? search : '',status ? status.value  : '', index));
      }
      setActivePage(index);
   }

   const checkPercentage = (course) => {
      let percentage = course?.completed_modules / course?.modules_count * 100
      return percentage
   }

   // const checkStatus = (item) =>{
   //    if(item?.completed_modules == item?.modules_count ){
   //       return 'Complete'
   //    }else if(item?.completed_modules < 0){
   //       return 'Start'
   //    }else if(item?.completed_modules > 0 && item?.completed_modules != item?.modules_count ){
   //       return 'Resume'
   //    }else {
   //       return 'Start'
   //    }
   //
   //
   // }
   const handleCardClick  =(item)=>{
      history.push(`/learnercoursedetails/${item?.id}`)
   }

   return (
       <div className="couses-listing-wrap">
          <ToastContainer autoClose={2000} />
          <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
             <div className="dt-filter-left d-flex justify-content-between">
                <div className="custom-react-select">
                   <Select options={options}  value={status}  onChange={onSelect} placeholder="Status" />
                </div>
                {/* <button type="button" className="iconbutton"><img src={FilterImg} /></button> */}
             </div>
             <div className="dt-filter-right d-flex">
                <div className="dt-filter-search-wrap p-relative">
                   <input type="text" placeholder="Search using keyword" value={search}
                          onChange={searchText}
                          className="form-control input-item" />
                   <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                </div>
                <button className="iconbutton search-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                  </svg>
                </button>
             </div>

          </div>
          <div className="courseslisting">
             <Row>
                {loading && loading ?
                    (
                        <>
                           <div className='loaderCoursesLearner'>
                              <Spinner animation="border" variant="danger"/>
                           </div>

                        </>


                    ):(
                        <>
                        { !loading && state.courses && state.courses && state.courses?.results && state.courses?.results?.length ? state.courses?.results?.map((item,index)=>(

                           <Col style= {{cursor:'pointer'}}  md={4} lg={3}  className="m-b-30 course-box-outer">
                              <div className="common-card coursecard" onClick={()=>handleCardClick(item)}>
                                 <div className="course-box-top">
                                    <div className="course-listing-img">
                                       <img src={item?.image_url} alt="" />
                                    </div>
                                    <div className="common-card-detail-wrap box-listing">
                                       <h4>{item?.name}</h4>
                                       <p>{item?.description?.length > 40
                                           ? `${item?.description?.substr(0, 51)}...`
                                           : item?.description}</p>
                                    </div>
                                 </div>
                                 <div className="common-card-footer ">
                                    <div className="common-box-progress-wrap">
                                       <div className="d-flex  align-items-center justify-content-between w-100 learing-detail-progress">
                                          <div className="learning-progress progress">
                                             <div className="progress-bar" style={{ width: `${checkPercentage(item)}%` }}>&nbsp;</div>
                                          </div>
                                          <span className="common-progress-text">{item?.completed_modules}/{item?.modules_count} Modules</span>
                                       </div>
                                    </div>
                                    <div className="common-card-footer-cont no-border d-flex justify-content-between align-items-center">
                                       {/*<div className="grp-name">Group name</div>*/}
                                       {/*<Link to={`/learnercoursedetails/${item?.id}`}><button type="button" className="btn border-btn">{checkStatus(item)}</button></Link>*/}
                                    </div>
                                 </div>
                                 <div className="box-overlay">
                                    <span className="overlay-viewmore">View More<i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                                    <div className="overlay-bg">&nbsp;</div>
                                 </div>
                              </div>
                           </Col>
                            ))
                            :
                            <span className='noDataOne'>No data available</span>

                        }

                        </>


                    )

                }
             </Row>
             {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response &&
             <div className="d-flex justify-content-center">
             <Pagination size="sm" className="custom-pagination">
                <Pagination.First disabled={!state?.courses?.previous} className={`${state?.courses?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious}/>
                {state.paginationItem}
                <Pagination.Last disabled={!state?.courses?.next} onClick={handleNext} className={`${state?.courses?.next ? 'paginationStyle' :''} `}/>

             </Pagination>
             </div>
             }

          </div>
       </div>
   );
};
export default Courses;
