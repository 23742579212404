import React, { Fragment, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from "yup";
import { getInstitutionType } from "../../../../../auth/services";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {
    addSurvey,
    updateSurvey,
    getManagerSurveys,
    updateSurveyQuestion,
    getDemographicQuestions,
} from '../../services'
import { getSurveyCategories } from '../../../../services'
import { callback } from "chart.js/helpers";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { setSeconds } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function SurveyIntroduction(props) {
    const dispatch = useDispatch()
    const history = useHistory()

    const response = useSelector(state => state?.survey_builder?.get_survey_manager?.response);

    const loading = useSelector(
        state => state?.survey_builder?.newly_added_survey?.loading
    );
    const updatedSurveyLoading = useSelector(
        (state) => state?.survey_builder?.updated_survey?.loading
    );
    const { user, error, signUpError } = useSelector(state => state.auth);
    const [detailsloading, setDetailsLoading] = useState(false);
    const [serveyOptions, setServeyOptions] = useState([]);
    const [tutorialModel, setTutorialmodel] = useState(false);


    // const [startDate, endDate] = dateRange;
    const [startDate, setStartDate] = useState('');
    useEffect(() => {
        setDetailsLoading(true)
        setTimeout(() => {
            setDetailsLoading(false)
        }, 500);
    }, [])



    const categoryResponse = useSelector(
        (state) => state?.auth?.institution_type?.response
    );

    const surveyCategory = useSelector(
        (state) => state?.surveys?.survey_category?.response
    );

    const [newresponse, setNewResponse] = useState({});

    const [state, setState] = useState({
        survey: {},
        loadForm: true
    });
    const formikRef = React.useRef(null);

    useEffect(() => {
        const serveyOptionsdata = surveyCategory?.results?.map(({ id, label }) => {
            return { value: id, label };
        });
        setServeyOptions(serveyOptionsdata);

    }, [surveyCategory]);



    useEffect(() => {
        dispatch(getDemographicQuestions())
        dispatch(getSurveyCategories())

        if (props?.urlId) {



            dispatch(getManagerSurveys(props?.urlId, function (res) {

            }
            ))

        }

    }, [])


    useEffect(() => {

        if (props?.urlId) {

            if (response?.content_category) {

                let result = surveyCategory?.results?.filter((el) => {
                    return (
                        response?.content_category?.map((i) => Number(i)).indexOf(el.id) != -1
                    );
                });
                let categoryids = result?.map((el) => {
                    return {
                        label: el.name,
                        value: el.id,
                    };
                });
                let expDate = response?.expiry_date ? response?.expiry_date : ''
                let neresponse = { ...response, expiry_date: expDate, category: categoryids }

                setState({ ...state, survey: neresponse });
            }

        }
    }, [response, surveyCategory]);

    function TutorialModal(props) {

        return (
            <Modal className="custom-modal info-modal dashboard-modal" show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="module-modal-header my-4">
                        <h4>Basic details of your survey can be added here</h4>
                    </div>
                    <ol className="info-list">
                        <li><span> Select Cateogry helps you to multi-select the type of category for the survey your are buliding.</span></li>
                        <li><span> Name allows you to enter the title of your survey and description field can be used to enter the sub-text of your survey</span></li>
                    </ol>
                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={props.onHide}> Close </Button>
                </div>
            </Modal>
        );
    }



    const handleDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today
    }



    const handleSubmit = async (values) => {
        setStartDate('')
        let newcategory = []

        if (props.urlId) {

            newcategory = values?.category?.map((el) => Number(el?.value))


            let data = {
                content_category: newcategory,
                name: values.name,
                description: values.description,
                expiry_date: values?.expiry_date && values?.expiry_date.length > 1 ? values?.expiry_date : null

            }

            const newData = { ...response, ...data };

            dispatch(
                updateSurveyQuestion(newData, props?.urlId, function (response) {
                    if (response) {
                        dispatch(getManagerSurveys(response?.id, function (res) { }))
                        props.parentState(1, response?.id, response, false);
                    }
                })
            );

        } else {



            newcategory = values?.category?.map((el) => Number(el?.value))


            let data = {
                content_category: newcategory,
                name: values.name,
                description: values.description,
                expiry_date: values?.expiry_date && values?.expiry_date.length > 1 ? values?.expiry_date : null

            }
            dispatch(
                addSurvey(data, function (response) {
                    if (response) {
                        dispatch(getManagerSurveys(response?.id, function (res) { }))
                        props.parentState(1, response?.id, response, false);
                    }
                })
            );
        }
    };





    const historyBack = () => {
        history.push({
            pathname: '/surveymanagement',
            data: {
                tabIndex: 1,

            }
        })


    }
    const handleLoader = () => {
        if (detailsloading) {
            return (
                <div className='loader-full-screen-course'>
                    <div className='loader'>
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
            )
        }
    }
    useEffect(() => {

        return () => {
            setStartDate('')
        }
    }, [])


    return (
        <div className="survey-indroduction">
            <div className="help_icon_position">
                <OverlayTrigger
                    overlay={
                        <Tooltip id="InfoText">
                            Help
                        </Tooltip>
                    }
                >
                    <span className="info-icon-wrap-tab animated-icon" onClick={() => setTutorialmodel(true)}>
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </span>
                </OverlayTrigger>
            </div>
            <div>{handleLoader()}</div>

            <div className="course-form-content-sec">
                <Formik
                    innerRef={formikRef}
                    validationSchema={
                        user.user_type == "admin"
                            ? CourseDetailSchemaAdmin
                            : CourseDetailSchema
                    }
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                    initialValues={
                        props?.urlId
                            ? state.survey
                            : user.user_type == "admin"
                                ? {
                                    name: "",
                                    description: "",
                                    category: [],
                                    expiry_date: ""

                                }
                                : {
                                    name: "",
                                    description: "",
                                    category: [],
                                    expiry_date: startDate || ""
                                }
                    }
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue,
                        setValues,
                        handleBlur,
                        touched,
                    }) => {

                        const handleChangeDate = (date) => {
                            let datedata = moment(date).format("YYYY-MM-DD");
                            setFieldValue("expiry_date", datedata);
                        }

                        return (

                            <Form noValidate onSubmit={handleSubmit}>

                                <div className="form-input-wrap">
                                    <Form.Label>Select Category</Form.Label>
                                    <span className="mandatory-star">*</span>
                                    <Select
                                        isMulti
                                        options={serveyOptions || []}
                                        value={values?.category}
                                        onChange={(option) => setFieldValue("category", option)}
                                        labelledBy={"Select Category"}
                                    />
                                    {errors.category && (
                                        <span className={"text-danger mt-1"}>
                                            {" "}
                                            {errors.category}
                                        </span>
                                    )}
                                </div>

                                <Row>
                                    <Col md={6}>
                                        <div className="form-input-wrap">

                                            <Form.Label>Survey Name</Form.Label>
                                            <span className="mandatory-star">*</span>
                                            <Form.Control type="text"
                                                name="name"
                                                placeholder="Survey Name"
                                                value={values.name}
                                                onChange={handleChange}
                                                maxLength="130"
                                            />
                                            {errors.name && touched.name && (
                                                <span className={"text-danger mt-1"}> {errors.name}</span>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-input-wrap">
                                            <Form.Label>Due Date</Form.Label>
                                            {user?.user_type === "admin" ? '' :
                                                <span className="mandatory-star">*</span>}
                                            <div className="survey-datepicker-box p-relative">

                                                {/* <Form.Control

                                                    //  selectsRange={true}
                                                    //  startDate={}
                                                    //  endDate={}
                                                    //  isClearable={true}
                                                    type="date"
                                                    id='one'
                                                    name="expiry_date"
                                                    min ={handleDate()}
                                                    placeholder="Select Date"
                                                    value={values.expiry_date}
                                                    onChange={handleChange}

                                                /> */}




                                                <DatePicker
                                                    name="expiry_date"
                                                    id='one'
                                                    placeholderText={'Select Date'}
                                                    minDate={new Date()}
                                                    // startDate={startDate}
                                                    // endDate={endDate}
                                                    onChange={handleChangeDate}
                                                    // onChange={(date) => setStartDate(date)}
                                                    value={values.expiry_date}
                                                    selected={startDate}
                                                    isClearable={false}
                                                    autoComplete='off'
                                                />




                                                {/* <span className="datepickericon">
                     <i className="fa fa-calendar"></i>
                  </span> */}
                                            </div>
                                            {errors.expiry_date && touched.expiry_date && (
                                                <span className={"text-danger mt-1"}> {errors.expiry_date}</span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="form-input-wrap">
                                    <Form.Label>Description</Form.Label>
                                    <span className="mandatory-star">*</span>
                                    <Form.Control
                                        as="textarea" rows={7}
                                        placeholder=" Description"
                                        style={{ height: "160px" }}
                                        className="textarea-item"
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                    />
                                    {errors.description && (
                                        <span className={"text-danger mt-1"}>
                                            {" "}
                                            {errors.description}
                                        </span>
                                    )}
                                </div>

                                {/* </Form> */}
                                {/* </div>     */}
                                <div className="details-footer d-flex justify-content-end">
                                    <div className="button-wrap double-btn">
                                        <button className="btn btn-m border-btn" onClick={() => historyBack()}>
                                            Cancel
                                        </button>
                                        <Button disabled={loading} variant="primary" className="btn-m" type="submit">
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <TutorialModal show={tutorialModel}

                onHide={() => setTutorialmodel(false)}
            />
        </div>
    );
}


const CourseDetailSchema = Yup.object().shape({
    name: Yup.string().required("Course name is required").max(130),
    category: Yup.array().min(1, "Category is required"),
    description: Yup.string().required("Description is required"),
    expiry_date: Yup.string("Due date should be required format").required("Due date is required"),
});

const CourseDetailSchemaAdmin = Yup.object().shape({
    name: Yup.string().required("Course name is required").max(130),
    category: Yup.array().min(1, "Category is required"),
    description: Yup.string().required("Description is required"),
    expiry_date: Yup.string("Due date should be required format").nullable(),

});
export default SurveyIntroduction;
