import React,{useState} from 'react';
import  {Modal, Button} from 'react-bootstrap';
import TabModules from './components/tabs'

const  QuizModulePopView  = (props) => {

    const [state, setState] = useState({
        show:false,
    })


    return (
        <div>
            <Modal show={props.show} onHide={props.onHide} className="common-modal full-screen-modal inner-popup add-module-content-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div><TabModules values={props.values} onHide={props.onHide}   courseId={props?.courseId}
                                     />
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )

}
export default QuizModulePopView;
