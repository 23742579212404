import React, { useEffect, useState, useCallback } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollArea from "react-scrollbar";
import MultiChoice from "./moduleMultiChoice";
import LearnerQuiz from "./learnerQuiz";
import TrueFalse from "./moduleTrueFalse";
import EmbedVideo from "./modulembedVideo";
import ModuleImageRight from "./moduleImageRight";
import ModuleImageBottom from "./moduleImageBottom";
import ModuleImageTop from "./moduleImageTop";
import ModuleEight from "./module8";
import TextOnly from "./textOnly";
import ImageOnly from "./imageonly";
import videothumb from "~/assets/images/module-thumb.png";
import img9 from "~/assets/images/moduleselection/img9.svg";
import img10 from "~/assets/images/moduleselection/img10.svg";
import img5 from "~/assets/images/moduleselection/img5.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  getLearnerCourseById,
  patchLearnerCourseModuleComplete,
} from "../../../services";
import $ from "jquery";

function TabModules(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [courseCompletedCount, setCourseCompletedCount] = useState(null);
  const [quizNotComplete, setQuizNotComplete] = useState(false);
  const [state, setState] = useState({
    key: 0,
    count: null,
    selectedModuleIds: [],
    vimeoImg: null,
    completeButton: false,
  });
  useEffect(() => {
    setCourseCompletedCount(props?.completedCount);
  }, [props?.completedCount]);
  useEffect(() => {
    if (props?.values?.length - 1 == state.key) {
      setState({ ...state, completeButton: true });
    } else {
      setState({ ...state, completeButton: false });
    }
  }, [state.key]);
  const fileEvent = (file) => {
    if (file) {
      let imageUrl = URL.createObjectURL(file);
      return imageUrl;
    }
  };
  const checkPercentage = (course) => {
    let percentage =
      (courseCompletedCount / props?.course?.modules_count) * 100;
    return percentage;
  };

  function get_vimeo_thumbnail(url, size, callback) {
    var result;
    if ((result = url.match(/vimeo\.com.*[\\\/](\d+)/))) {
      var video_id = result.pop();
      $.getJSON(
        "http://vimeo.com/api/v2/video/" + video_id + ".json",
        function (data) {
          if (data) {
            if (typeof callback !== "undefined") {
              var thumbnail_src = data[0].thumbnail_large;
              if (thumbnail_src) {
                callback(thumbnail_src);
              }
            }
          }
        }
      );
    }
  }

  const handleQuiz = (childData) => {
    if (childData) {
      setQuizNotComplete(true);
    } else {
      setQuizNotComplete(false);
    }
  };

  function get_youtube_thumbnail(url, quality, index) {
    if (url.includes("vimeo")) {
      get_vimeo_thumbnail(url, "large", function (url) {
        let vimeos = document.getElementsByClassName("vim");
        vimeos[index]?.setAttribute("src", "");
        vimeos[index]?.setAttribute("src", url);
      });
    } else if (url) {
      var video_id, thumbnail, result;
      if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
        video_id = result.pop();
      } else if ((result = url.match(/youtu.be\/(.{11})/))) {
        video_id = result.pop();
      }
      if (video_id) {
        if (typeof quality == "undefined") {
          quality = "high";
        }
        var quality_key = "maxresdefault"; // Max quality
        if (quality == "low") {
          quality_key = "sddefault";
        } else if (quality == "medium") {
          quality_key = "mqdefault";
        } else if (quality == "high") {
          quality_key = "hqdefault";
        }

        var thumbnail =
          "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return thumbnail;
      }
    }
    return false;
  }

  const checkImageAvailability = (item, index) => {
    if (item.image) {
      return item?.image;
    } else if (!item.image && item.template == "question_multiple_choice") {
      return img9;
    } else if (!item.image && item.template == "quiz") {
      return img9;
    } else if (!item.image && item.template == "text_only") {
      return img5;
    } else if (!item.image && item.template == "question_true_or_false") {
      return img10;
    } else if (!item.image && item.template == "text_with_embedded_video") {
      return get_youtube_thumbnail(`${item?.video}`, "max", index);
    }
  };

  const handleSelect = (item, index) => {
    const { selectedModuleIds } = state;
    if (
      item?.is_completed == false &&
      selectedModuleIds.indexOf(item?.id) == -1
    ) {
      dispatch(
        patchLearnerCourseModuleComplete(
          props?.course_id,
          item?.id,
          function (response) {
            selectedModuleIds.push(item?.id);
            setState({
              ...state,
              key: index,
              selectedModuleIds: selectedModuleIds,
            });
            setCourseCompletedCount(courseCompletedCount + 1);
          },
          function (error) {
            setState({ ...state, key: index });
          }
        )
      );
    } else {
      setState({ ...state, key: index });
    }
  };
  const handleNext = () => {
    // debugger
    if (props?.values?.length - 1 == state.key) {
      setState({ ...state, key: 0 });
    } else {
      let tempObj = props?.values?.find((el, index) => {
        return index == state.key;
      });
      const { selectedModuleIds } = state;
      if (
        tempObj &&
        tempObj?.is_completed == false &&
        selectedModuleIds.indexOf(tempObj?.id) == -1
      ) {
        dispatch(
          patchLearnerCourseModuleComplete(
            props?.course_id,
            tempObj?.id,
            function (response) {
              selectedModuleIds.push(tempObj?.id);
              setState({
                ...state,
                key: state.key + 1,
                selectedModuleIds: selectedModuleIds,
              });
              setCourseCompletedCount(courseCompletedCount + 1);
            },
            function (error) {
              setState({
                ...state,
                key: state.key + 1,
                count: state.count + 1,
              });
            }
          )
        );
      } else {
        setState({ ...state, key: state.key + 1 });
      }
    }
  };
  const handlePrevious = () => {
    if (state?.key == 0) {
      let length = props?.values?.length - 1;
      setState({ ...state, key: `${length}` });
    } else {
      setState({ ...state, key: state.key - 1 });
    }
  };

  const handleComplete = () => {
    if (props?.values?.length == 1) {
      const { selectedModuleIds } = state;
      let tempObj = props?.values?.find((el, index) => {
        return index == 0;
      });
      if (
        tempObj &&
        tempObj?.is_completed == false &&
        selectedModuleIds.indexOf(tempObj?.id) == -1
      ) {
        dispatch(
          patchLearnerCourseModuleComplete(
            props?.course_id,
            tempObj?.id,
            function (response) {
              dispatch(
                getLearnerCourseById(props?.courseId, function (res) {
                  if (res?.completed_modules == res?.modules_count) {
                    props.parentCallback(true);
                    // history.push(`/coursecompleted/${props?.courseId}`)
                  } else {
                    props.onHide();
                  }
                })
              );
            },
            function (error) {
              props.onHide();
            }
          )
        );
      } else {
        props.parentCallback(true);
        // history.push(`/coursecompleted/${props?.courseId}`)
      }
    } else {
      const { selectedModuleIds } = state;
      let tempObj = props?.values?.find((el, index) => {
        return index == state.key;
      });
      if (
        tempObj &&
        tempObj?.is_completed == false &&
        selectedModuleIds.indexOf(tempObj?.id) == -1
      ) {
        dispatch(
          patchLearnerCourseModuleComplete(
            props?.course_id,
            tempObj?.id,
            function (response) {
              dispatch(
                getLearnerCourseById(props?.courseId, function (res) {
                  if (res?.completed_modules == res?.modules_count) {
                    // history.push(`/coursecompleted/${props?.courseId}`)
                    props.parentCallback(true);
                  } else {
                    props.onHide();
                  }
                })
              );
            },
            function (error) {
              props.onHide();
            }
          )
        );
      } else {
        dispatch(
          getLearnerCourseById(props?.courseId, function (res) {
            if (res?.completed_modules == res?.modules_count) {
              // history.push(`/coursecompleted/${props?.courseId}`)
              props.parentCallback(true);
            } else {
              props.onHide();
            }
          })
        );
      }
    }
  };
  return (
    <div className="modulemodalscroll-barprogress">
      <Tab.Container id="controlled-tab-example" activeKey={state?.key}>
        <div className="d-flex  align-items-center justify-content-between w-100 learing-detail-progress">
          <div className="module-pop-progress progress">
            <div
              className="progress-bar"
              style={{ width: `${checkPercentage(props?.course)}%` }}
            >
              &nbsp;
            </div>
          </div>
          {/* <span className="common-progress-text">2/8 Modules</span> */}
        </div>
        <Row>
          <div className="module-item-box">
            {/*<h4>{props?.name}</h4> */}
            <ScrollArea
              speed={0.8}
              className="modulemodalthumbscroll"
              contentClassName="content"
              horizontal={false}
            >
              <Nav variant="pills" className="flex-column add-module-pop-tab">
                {props?.values &&
                props?.values &&
                props?.values?.length &&
                props?.values?.length
                  ? props?.values?.map((el, index) => {
                      return (
                        <Nav.Item onClick={() => handleSelect(el, index)}>
                          <Nav.Link eventKey={`${index}`}>
                            <div className="d-flex align-items-center">
                              <span className="module-tab-icon">
                                <img
                                  className="vim"
                                  dataSet={index}
                                  src={checkImageAvailability(el, index)}
                                  alt=""
                                />
                              </span>
                              <span className="module-tab-title">
                                {el?.template == "quiz" ? "Quiz :" : ""}{" "}
                                {el?.name}
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })
                  : ""}
              </Nav>
            </ScrollArea>
          </div>
          <Col className="module-content-box">
            <div className="w-100">
              {" "}
              <h4 className="module-pop-title">{props?.name}</h4>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey={`${state.key}`} id="tabId">
                {(() => {
                  switch (props?.values[state.key]?.template) {
                    case "text_with_image_on_left":
                      return (
                        <ModuleEight
                          value={props?.values[state.key]}
                          index={state.key}
                          length={props?.values?.length}
                        />
                      );
                    case "text_with_image_on_right":
                      return (
                        <ModuleImageRight
                          value={props?.values[state.key]}
                          index={state.key}
                          length={props?.values?.length}
                        />
                      );
                    case "text_with_image_on_top":
                      return (
                        <ModuleImageTop
                          value={props?.values[state.key]}
                          index={state.key}
                          length={props?.values?.length}
                        />
                      );
                    case "text_with_image_at_the_bottom":
                      return (
                        <ModuleImageBottom
                          value={props?.values[state.key]}
                          index={state.key}
                          length={props?.values?.length}
                        />
                      );
                    case "text_with_embedded_video":
                      return (
                        <EmbedVideo
                          value={props?.values[state.key]}
                          index={state.key}
                          length={props?.values?.length}
                        />
                      );
                    case "question_multiple_choice":
                      return (
                        <MultiChoice
                          value={props?.values[state.key]}
                          index={state.key}
                          length={props?.values?.length}
                        />
                      );
                    case "question_true_or_false":
                      return (
                        <TrueFalse
                          value={props?.values[state.key]}
                          index={state.key}
                          length={props?.values?.length}
                        />
                      );
                    case "image_only":
                      return (
                        <ImageOnly
                          value={props?.values[state?.key]}
                          index={state?.key}
                          length={props?.values?.length}
                        />
                      );
                    case "text_only":
                      return (
                        <TextOnly
                          value={props?.values[state?.key]}
                          index={state?.key}
                          length={props?.values?.length}
                        />
                      );
                    case "quiz":
                      return (
                        <LearnerQuiz
                          value={props?.values[state?.key]}
                          index={state?.key}
                          length={props?.values?.length}
                          parentQuizCallback={handleQuiz}
                        />
                      );

                    default:
                      return null;
                  }
                })()}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      <div className="button-wrap double-btn module-footer-btn d-flex justify-content-end">
        {!state.completeButton && props.values && props.values.length > 1 ? (
          <>
            <button
              className="btn light-gray-bg"
              disabled={state.key === 0}
              onClick={handlePrevious}
            >
              Previous
            </button>
            <Button type="button" onClick={handleNext}>
              Next
            </Button>
          </>
        ) : (
          <>
            {(props.values && props.values.length == 1) ||
            state.completeButton ? (
              <>
                <button
                  className="btn light-gray-bg"
                  disabled={state.key === 0}
                  onClick={handlePrevious}
                >
                  Previous
                </button>
               {props?.course?.status !== "Completed" ? (
                 <Button
                 type="button"
                 disabled={quizNotComplete}
                 onClick={handleComplete}
               >
                 Complete
               </Button>
               ) : (
                <Button
                 type="button"
                 onClick={() => { props.onHide()}}
               >
                 Close
               </Button>
               )}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default TabModules;
