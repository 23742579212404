import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import { tableData } from '../dummyTableData';
import ExpandedComponent from '../expandedcomponent';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getSurveyConstructList,getSurveyScoring} from '~/modules/surveys/surveyReport/surveyreports/services';


const Scoring = (props) => {
   const dispatch = useDispatch();
   const scoringresponse = useSelector(state => state?.surveyreport?.survey_scoring?.response);
   const constructresponse = useSelector(state => state?.surveyreport?.survey_construct_list?.response);
   const { user } = useSelector(state => state.auth);
const [state,setState] = useState({
   scoring:{},
   constructs:{}
})

   useEffect(()=>{
      dispatch(getSurveyScoring(props?.id,user?.token))
      dispatch(getSurveyConstructList(props?.id,user?.token))
   },[])

   useEffect(()=>{
          setState({...state,scoring:scoringresponse?.results,constructs:constructresponse?.results})
   },[scoringresponse,constructresponse])

   const getScore = (row) =>{
   if(row.construct_score?.score_percentile){
      let score = row?.construct_score?.score_percentile
      return Math.ceil(score)

   }else {
      return '0'
   }

   }
const checkPriority =  (row) =>{
   if(row?.construct_score?.impact){
      let priority = row?.construct_score?.impact;
      return priority.toUpperCase()
   }else {
      return 'NA'
   }
}
const checkDistribution  = (distribution) => {
   if(distribution){
      const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);

      let averageObj = {
         '3' : Math.ceil(distribution[3]/sumValues(distribution)*100),
         '1_or_2':Math.ceil(distribution['1_or_2']/sumValues(distribution)*100),
         '4_or_5':Math.ceil(distribution['4_or_5']/sumValues(distribution)*100)

      }

   return (
       <>
   <div className="distribution-item high-distribution" style={{width: `${averageObj['4_or_5']}%`}}>&nbsp;</div>
   <div className="distribution-item medium-distribution" style={{width: `${averageObj[3]}%`}}>&nbsp;</div>
   <div className="distribution-item low-distribution" style={{width: `${averageObj['1_or_2']}%`}}>&nbsp;</div>
       </>
)
   }else {
      return (
      <>
      <h5>NA</h5>
      </>
      )
   }

}


const checkRating = (row) =>{
   if(row?.construct_score?.score_percentile){
      let percentage = Math.ceil(row?.construct_score?.score_percentile)
          switch (true){
             case percentage > '92' :
                return <><i className="fas fa-star fa-xs"></i>
                   <i className="fas fa-star fa-xs"></i>
                   <i className="fas fa-star fa-xs"></i>
                   <i className="fas fa-star fa-xs"></i>
                   <i className="fas fa-star fa-xs"></i>
                </>
             break;
             case percentage >'85' && percentage <=92 :
             return <><i className="fas fa-star fa-xs"></i>
                <i className="fas fa-star fa-xs"></i>
                <i className="fas fa-star fa-xs"></i>
                <i className="fas fa-star fa-xs"></i>
             </>
             break;
             case percentage >= 75 && percentage <=84 :
                return <><i className="fas fa-star fa-xs"></i>
                   <i className="fas fa-star fa-xs"></i>
                   <i className="fas fa-star fa-xs"></i>
                </>
                break;
             case percentage < 75 :
                return <><i className="fas fa-star fa-xs"></i>
                   <i className="fas fa-star fa-xs"></i>

                </>
                break;
          }

   }else {
      return 'NA'
   }
}

   const [data, setData] = useState(tableData);
   const tableColumns = [
      {
         name: 'Domain',
         selector: 'construct',
         sortable: true,
         center: false,
         grow: 3
      },

      {
         name: 'Impact',
         selector: 'Group_name',
         sortable: true,
         center: false,
         cell: row => <div className="impact-cell">
           <div className="impact-result high-impact">{checkRating(row)}</div>
           <div>{checkPriority(row)}</div>
         </div>,
         grow: 1,
      },
      {
         name: 'Distribution',
         selector: 'Courses',
         sortable: true,
         center: false,
         cell: row =>
            <div className="distribution-cell d-flex">
               {checkDistribution(row?.construct_score?.distribution)}
            </div>,
         grow: 2,
      },
      {
         name: 'Score',
         selector: 'construct_score',
         sortable: true,
         center: false,
         cell: row => <span>{getScore(row)}</span>,
         grow: 1,
      },
      
   ];
   return (
      <div className="datatble-wrap">
         <DataTable
            data={state.scoring ? state.scoring :[]}
            columns={tableColumns}
            center={true}
            noHeader={true}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
         />
      </div>
   );
};
export default Scoring;
