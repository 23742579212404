import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

function LearningPath() {
  const [setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  return (
    <div className="add-quiz-wrap">
      <div className="course-form-content-sec">
        <div className="whitebox shadow-box module-form-box">
          <Form>
            <div className="form-input-wrap">
              <Form.Label>Learning path</Form.Label>
              <Form.Control type="text" className="input-item" />
            </div>
            <div className="d-flex align-items-center imageupload-wrap form-input-wrap">
                  <Form.Label>Learning path banner image</Form.Label>
                  <div className="imageuploader d-flex align-items-center">
                    <div className="image-upload-button p-relative">
                      <label htmlFor="profilePic" className="btn btn-primary btn-m">Upload</label>
                      <Form.Control name="coursebanner" id="profilePic" type="file" className="fileuploader-hidden" onChange={e => {
                        setPicture(e.target.files[0]);
                        const reader = new FileReader();
                        reader.addEventListener("load", () => {
                          setImgData(reader.result);
                        });
                        reader.readAsDataURL(e.target.files[0]);
                      }}
                      />
                    </div>
                    <div className="previewProfilePic">
                      {/* <span className="uploadedimageclose">×</span> */}
                      <img className="playerProfilePic_home_tile" src={imgData} />
                    </div>
                  </div>
                </div>
            <div className="form-input-wrap">
              <Form.Label>Course Description</Form.Label>
              <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '120px' }} className="textarea-item" />
            </div>
          </Form>
        </div>
      </div>
    </div>

  );
};
export default LearningPath;