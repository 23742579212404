import {createAction, handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import { 

    _dashboardUsersCount,
    _dashboardCourseCount,
    _dashboardSurveyCount,
    _dashboardGroupCount,
    _dashboardCoursesGroupsCount,
    _dashboardCoursesUsersCount,
    _dahboardCourseCompletion,
    _dashboardSurveyUsersCount,
    _dashboardAssignmentSurvey
    } from '~shared/httpService/api';

    
import { ToastContainer, toast } from 'react-toastify';



    const initialState = {
       
        dashboard_Users: { loading: false, response: {}, hasError: false, error: {} },
        dashboard_Course_Count: { loading: false, response: {}, hasError: false, error: {} },
        dashboard_Survey_Count: { loading: false, response: {}, hasError: false, error: {} },
        dashboard_Group_Count: { loading: false, response: {}, hasError: false, error: {} },
        dashboard_Courses_Groups_Count: { loading: false, response: {}, hasError: false, error: {} },      
        dashboard_Courses_Users_Count: { loading: false, response: {}, hasError: false, error: {} },
        dahboard_Course_Completion: { loading: false, response: {}, hasError: false, error: {} },
        dashboard_Survey_Users_Count: { loading: false, response: {}, hasError: false, error: {} },
        dashboard_Assignment_Survey: { loading: false, response: {}, hasError: false, error: {} },        
       
      };


      

//dashboard_Users

export const getdashboard_Users_Start = createAction(ActionTypes. GET_DASBOARD_USERS);
export const getdashboard_Users_Success = createAction(ActionTypes.  GET_DASBOARD_USERS_SUCCESS, response => response);
export const getdashboard_Users_Error = createAction(ActionTypes. GET_DASBOARD_USERS_ERROR, error => error);

export const getdashboard_Users= ()=>(dispatch)=>{
  dispatch(getdashboard_Users_Start ());
  return   _dashboardUsersCount().then((response)=>{
    dispatch(getdashboard_Users_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_Users_Error(error));
    toast.error('Something went wrong')
  })
}

//dashboard_survey count

export const getdashboard_SurveyCount_Start = createAction(ActionTypes. GET_DASHBOARD_SURVEY_COUNT);
export const getdashboard_SurveyCount_Success = createAction(ActionTypes.  GET_DASHBOARD_SURVEY_COUNT_SUCCESS, response => response);
export const getdashboard_SurveyCount_Error = createAction(ActionTypes. GET_DASHBOARD_SURVEY_COUNT_ERROR, error => error);

export const getdashboard_SurveyCount= ()=>(dispatch)=>{
  dispatch(getdashboard_SurveyCount_Start ());
  return  _dashboardSurveyCount().then((response)=>{
    dispatch(getdashboard_SurveyCount_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_SurveyCount_Error(error));
     toast.error('Something went wrong')
  })
}


//dashboard_course count

export const getdashboard_CourseCount_Start = createAction(ActionTypes.  GET_DASBOARD_COURSES_COUNT);
export const getdashboard_CourseCount_Success = createAction(ActionTypes.   GET_DASBOARD_COURSES_COUNT_SUCCESS, response => response);
export const getdashboard_CourseCount_Error = createAction(ActionTypes.  GET_DASBOARD_COURSES_COUNT_ERROR, error => error);

export const getdashboard_CourseCount= ()=>(dispatch)=>{
  dispatch(getdashboard_CourseCount_Start ());
  return  _dashboardCourseCount().then((response)=>{
    dispatch(getdashboard_CourseCount_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_CourseCount_Error(error));
     toast.error('Something went wrong')
  })
}

// dashboardGroupCount

export const getdashboard_GroupCount_Start = createAction(ActionTypes.  GET_DASBOARD_GROUP);
export const getdashboard_GroupCount_Success = createAction(ActionTypes.   GET_DASBOARD_GROUP_SUCCESS, response => response);
export const getdashboard_GroupCount_Error = createAction(ActionTypes.  GET_DASBOARD_GROUP_ERROR, error => error);

export const getdashboard_GroupCount= ()=>(dispatch)=>{
  dispatch(getdashboard_GroupCount_Start ());
  return  _dashboardGroupCount().then((response)=>{
    dispatch(getdashboard_GroupCount_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_GroupCount_Error(error));
    toast.error('Something went wrong')
  })
}

//  dashboard_Courses_Groups_Count

export const getdashboard_Courses_GroupCount_Start = createAction(ActionTypes. GET_COURSES_GROUP);
export const getdashboard_Courses_GroupCount_Success = createAction(ActionTypes.  GET_COURSES_GROUP_SUCCESS, response => response);
export const getdashboard_Courses_GroupCount_Error = createAction(ActionTypes. GET_COURSES_GROUP_ERROR, error => error);

export const getdashboard_Courses_GroupCount= ()=>(dispatch)=>{
  dispatch(getdashboard_Courses_GroupCount_Start ());
  return  _dashboardCoursesGroupsCount().then((response)=>{
    dispatch(getdashboard_Courses_GroupCount_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_Courses_GroupCount_Error(error));
    toast.error('Something went wrong')
  })
}


//  _dashboardCoursesUsersCount,

export const getdashboard_CoursesUsersCount_Start = createAction(ActionTypes. GET_DASBOARD_COURSES_USERS_COUNT);
export const getdashboard_CoursesUsersCount_Success = createAction(ActionTypes.  GET_DASBOARD_COURSES_USERS_COUNT_SUCCESS, response => response);
export const getdashboard_CoursesUsersCount_Error = createAction(ActionTypes. GET_DASBOARD_COURSES_USERS_COUNT_ERROR, error => error);

export const getdashboard_CoursesUsersCount= ()=>(dispatch)=>{
  dispatch(getdashboard_CoursesUsersCount_Start ());
  return   _dashboardCoursesUsersCount().then((response)=>{
    dispatch(getdashboard_CoursesUsersCount_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_CoursesUsersCount_Error(error));
    toast.error('Something went wrong')
  })
}


// dahboardCourseCompletion

export const getdashboard_CourseCompletion_Start = createAction(ActionTypes. GET_DASHBOARD_COURSE_COMPLETION);
export const getdashboard_CourseCompletion_Success = createAction(ActionTypes.  GET_DASHBOARD_COURSE_COMPLETION_SUCCESS, response => response);
export const getdashboard_CourseCompletion_Error = createAction(ActionTypes. GET_DASHBOARD_COURSE_COMPLETION_ERROR, error => error);

export const getdashboard_CourseCompletion= ()=>(dispatch)=>{
  dispatch(getdashboard_CourseCompletion_Start ());
  return    _dahboardCourseCompletion().then((response)=>{
    dispatch(getdashboard_CourseCompletion_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_CourseCompletion_Error(error));
    toast.error('Something went wrong')
  })
}


// _dashboardAssignmentSurvey

export const getdashboard_DashboardAssignmentSurvey_Start = createAction(ActionTypes. GET_ASSIGNMENT_SURVEY);
export const getdashboard_DashboardAssignmentSurvey_Success = createAction(ActionTypes.  GET_ASSIGNMENT_SURVEY_SUCCESS, response => response);
export const getdashboard_DashboardAssignmentSurvey_Error = createAction(ActionTypes. GET_ASSIGNMENT_SURVEY_ERROR, error => error);

export const getdashboard_DashboardAssignmentSurvey= ()=>(dispatch)=>{
  dispatch(getdashboard_DashboardAssignmentSurvey_Start ());
  return    _dashboardAssignmentSurvey().then((response)=>{
    dispatch(getdashboard_DashboardAssignmentSurvey_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_DashboardAssignmentSurvey_Error(error));
    toast.error('Something went wrong')
  })
}


//  dashboard_Survey_Users_Count

export const getdashboard_Survey_Users_Count_Start = createAction(ActionTypes. GET_DASHBOARD_SURVEY_USER);
export const getdashboard_Survey_Users_Count_Success = createAction(ActionTypes.GET_DASHBOARD_SURVEY_USER_SUCCESS, response => response);
export const getdashboard_Survey_Users_Count_Error = createAction(ActionTypes. GET_DASHBOARD_SURVEY_USER_ERROR, error => error);

export const getdashboard_Survey_Users_Count= ()=>(dispatch)=>{
  dispatch(getdashboard_Survey_Users_Count_Start ());
  return    _dashboardSurveyUsersCount().then((response)=>{
    dispatch(getdashboard_Survey_Users_Count_Success(response));
  }).catch((error)=>{
    dispatch(getdashboard_Survey_Users_Count_Error(error));
    toast.error('Something went wrong')
  })
}



const reducer = handleActions({
    ////dashboard_Users
    [ActionTypes.GET_DASBOARD_USERS]: (state) => ({
        ...state,
        dashboard_Users:{ loading: true, response: {}, hasError: false, error: {} },
       
    }),
    [ActionTypes.GET_DASBOARD_USERS_SUCCESS]: (state, action) => ({
        ...state,
        dashboard_Users: {
        ...state.dashboard_Users,
        response: action.payload, loading: false, hasError: false, error: {}
    }
      
    }),
    [ActionTypes.GET_DASBOARD_USERS_ERROR]: (state, action) =>  ({
        ...state,
        dashboard_Users: {
            ...state.dashboard_Users,
            error: action.payload, loading: false, hasError: true, response: {}
        }
       
    }),


    // //GET_DASBOARD_SURVEY_COUNT
    [ActionTypes.GET_DASHBOARD_SURVEY_COUNT]: (state) => ({
        ...state,  dashboard_Survey_Count: {
            ...state.  dashboard_Survey_Count,
            loading: true, hasError: false, error: {}
          }
    }),
    [ActionTypes.GET_DASHBOARD_SURVEY_COUNT_SUCCESS]: (state, action) => ({
        ...state,
        dashboard_Survey_Count: {
          ...state.  dashboard_Survey_Count,
          response: action.payload, loading: false, hasError: false, error: {}
      }
    }),
    [ActionTypes.GET_DASHBOARD_SURVEY_COUNT_ERROR]: (state, action) =>  ({
        ...state,
        dashboard_Survey_Count: {
          ...state. dashboard_Survey_Count,
          error: action.payload, loading: false, hasError: true, response: {}  }
     }),

    // //GET_DASBOARD_COURSES_COUNT
    [ActionTypes.GET_DASBOARD_COURSES_COUNT]: (state) => ({
        ...state,   dashboard_Course_Count: {
            ...state.   dashboard_Course_Count,
            loading: true, hasError: false, error: {}
          }
    }),
    [ActionTypes. GET_DASBOARD_COURSES_COUNT_SUCCESS]: (state, action) => ({
        ...state,
        dashboard_Course_Count: {
          ...state.   dashboard_Course_Count,
          response: action.payload, loading: false, hasError: false, error: {}
      }
    }),
    [ActionTypes. GET_DASBOARD_COURSES_COUNT_ERROR]: (state, action) =>  ({
        ...state,
        dashboard_Course_Count: {
          ...state.  dashboard_Course_Count,
          error: action.payload, loading: false, hasError: true, response: {}  }
    }),


    // ////dashboard_GROUP
    [ActionTypes.GET_DASBOARD_GROUP]: (state) => ({
        ...state,
        dashboard_Group_Count:{ loading: true, response: {}, hasError: false, error: {} },
       
    }),
    [ActionTypes.GET_DASBOARD_GROUP_SUCCESS]: (state, action) => ({
        ...state,
        dashboard_Group_Count: {
        ...state.dashboard_Group_Count,
        response: action.payload, loading: false, hasError: false, error: {}
    }
      
    }),
    [ActionTypes.GET_DASBOARD_GROUP_ERROR]: (state, action) =>  ({
        ...state,
        dashboard_Group_Count: {
            ...state.dashboard_Group_Count,
            error: action.payload, loading: false, hasError: true, response: {}
        }
       
    }),


    // // dashboard_Courses_users_Count
    [ActionTypes.GET_DASBOARD_COURSES_USERS_COUNT]: (state) => ({
        ...state,    dashboard_Courses_Users_Count: {
            ...state.    dashboard_Courses_Users_Count,
            loading: true, hasError: false, error: {}
          }
    }),
    [ActionTypes.GET_DASBOARD_COURSES_USERS_COUNT_SUCCESS]: (state, action) => ({
        ...state,
        dashboard_Courses_Users_Count: {
          ...state.    dashboard_Courses_Users_Count,
          response: action.payload, loading: false, hasError: false, error: {}
      }
    }),
    [ActionTypes.GET_DASBOARD_COURSES_USERS_COUNT_ERROR]: (state, action) =>  ({
        ...state,
        dashboard_Courses_Users_Count: {
          ...state.  dashboard_Courses_Users_Count,
          error: action.payload, loading: false, hasError: true, response: {}  }
    }),

    // // dashboard_Courses_Groups_Count
    [ActionTypes.GET_COURSES_GROUP]: (state) => ({
        ...state,    dashboard_Courses_Groups_Count: {
            ...state.    dashboard_Courses_Groups_Count,
            loading: true, hasError: false, error: {}
          }
    }),
    [ActionTypes.GET_COURSES_GROUP_SUCCESS]: (state, action) => ({
        ...state,
        dashboard_Courses_Groups_Count: {
          ...state.    dashboard_Courses_Groups_Count,
          response: action.payload, loading: false, hasError: false, error: {}
      }
    }),
    [ActionTypes.GET_COURSES_GROUP_ERROR]: (state, action) =>  ({
        ...state,
        dashboard_Courses_Groups_Count: {
          ...state.  dashboard_Courses_Groups_Count,
          error: action.payload, loading: false, hasError: true, response: {}  }
    }),

   
    //  // COURSE COMPLETION
     [ActionTypes.GET_DASHBOARD_COURSE_COMPLETION]: (state) => ({
        ...state,    dahboard_Course_Completion: {
            ...state.     dahboard_Course_Completion,
            loading: true, hasError: false, error: {}
          }
    }),
    [ActionTypes. GET_DASHBOARD_COURSE_COMPLETION_SUCCESS]: (state, action) => ({
        ...state,
        dahboard_Course_Completion: {
          ...state.    dahboard_Course_Completion,
          response: action.payload, loading: false, hasError: false, error: {}
      }
    }),
    [ActionTypes. GET_DASHBOARD_COURSE_COMPLETION_ERROR]: (state, action) =>  ({
        ...state,
        dahboard_Course_Completion: {
          ...state.  dahboard_Course_Completion,
          error: action.payload, loading: false, hasError: true, response: {}  }
    }),



    //      //  dashboard_Survey_Users_Count
         [ActionTypes.GET_DASHBOARD_SURVEY_USER]: (state) => ({
            ...state,      dashboard_Survey_Users_Count: {
                ...state.     dashboard_Survey_Users_Count,
                loading: true, hasError: false, error: {}
              }
        }),
        [ActionTypes. GET_DASHBOARD_SURVEY_USER_SUCCESS]: (state, action) => ({
            ...state,
            dashboard_Survey_Users_Count: {
              ...state.    dashboard_Survey_Users_Count,
              response: action.payload, loading: false, hasError: false, error: {}
          }
        }),
        [ActionTypes. GET_DASHBOARD_SURVEY_USER_ERROR]: (state, action) =>  ({
            ...state,
            dashboard_Survey_Users_Count: {
              ...state.  dashboard_Survey_Users_Count,
              error: action.payload, loading: false, hasError: true, response: {}  }
        }),


    //       //  dashboard_Assignment_Survey
          [ActionTypes.GET_ASSIGNMENT_SURVEY]: (state) => ({
            ...state,      dashboard_Assignment_Survey: {
                ...state.     dashboard_Assignment_Survey,
                loading: true, hasError: false, error: {}
              }
        }),
        [ActionTypes. GET_ASSIGNMENT_SURVEY_SUCCESS]: (state, action) => ({
            ...state,
            dashboard_Assignment_Survey: {
              ...state.    dashboard_Assignment_Survey,
              response: action.payload, loading: false, hasError: false, error: {}
          }
        }),
        [ActionTypes. GET_ASSIGNMENT_SURVEY_ERROR]: (state, action) =>  ({
            ...state,
            dashboard_Assignment_Survey: {
              ...state.  dashboard_Assignment_Survey,
              error: action.payload, loading: false, hasError: true, response: {}  }
        }),
    
    

}, initialState);

export default reducer;