import React, { useEffect, useState } from "react";
import ScrollArea from "react-scrollbar";
import Form from "react-bootstrap/Form";
import HeaderSearch from "~/assets/images/icons/magnifiying-glass.svg";
import { getGroupsWithOutPagination } from "../../../services";
import { getAllGroup } from "../../../../coursemanagement/services";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

const GroupAssignBox = ({ groupList, assignId, urlId, singleAssignment }) => {
  const dispatch = useDispatch();
  const response = useSelector(
    (state) => state?.learningpath?.groups_without_pagination?.response
  );
  const loading = useSelector(
    (state) => state?.learningpath?.groups_without_pagination?.loading
  );

  // const assignmentById = useSelector(state => state.course?.get_course_assigment_by_id?.response);
  const [state, setState] = useState({
    group: [],
    tempGroup: response?.results || [],
    selectedGroup: [],
    tempoGroup: [],
    groupId: [],
  });
  useEffect(() => {
    dispatch(
      getGroupsWithOutPagination(
        function (response) {
          if (response) {
          }
        },
        function (error) {}
      )
    );
  }, []);
  useEffect(() => {
    if (assignId && urlId) {
      let { groupId } = state;
      let newArray = response?.results?.map((item) => {
        let temp = {
          ...item,
          isChecked: singleAssignment?.groups?.includes(item.id),
        };
        return temp;
      });
      let selectedArray = newArray?.filter((el) => {
        return el.isChecked;
      });
      let groupsId = selectedArray?.map((el) => el.id);
      groupList(groupsId);

      setState({
        ...state,
        group: newArray,
        tempGroup: newArray,
        selectedGroup: selectedArray,
        groupId: groupsId,
      });
    } else {
      setState({
        ...state,
        group: response?.results,
        tempGroup: response?.results,
      });
    }
  }, [response, assignId]);

  const handleClick = (item, index) => {
    let { group, selectedGroup, groupId, tempGroup } = state;
    let tempArray = JSON.parse(JSON.stringify(tempGroup));
    group[index].isChecked = !group[index].isChecked;

    let tempIndex = tempArray.findIndex((el) => {
      return el.id == item.id;
    });
    tempArray[tempIndex].isChecked = !tempArray[tempIndex].isChecked;
    if (selectedGroup.find((f) => f.id == item.id)) {
      selectedGroup = selectedGroup.filter((el) => el.id != item.id);
      groupId = groupId.filter((el) => el != item.id);
    } else {
      selectedGroup.push(item);
      groupId.push(item.id);
    }

    groupList(groupId);
    setState({
      ...state,
      selectedGroup: selectedGroup,
      groupId: groupId,
      group: group,
      tempGroup: tempGroup,
    });
  };
  const handleRemove = (item, j) => {
    const { selectedGroup, groupId, group, tempGroup } = state;
    selectedGroup.splice(j, 1);
    let tempArray = JSON.parse(JSON.stringify(tempGroup));

    let tempIndextemp = tempArray.findIndex((el) => {
      return el.id == item.id;
    });
    let tempIndex = group.findIndex((el) => {
      return el.id == item.id;
    });
    if (tempIndex > -1) {
      group[tempIndex].isChecked = false;
    }
    tempArray[tempIndextemp].isChecked = false;
    let groupsId = selectedGroup?.map((el) => el.id);
    groupList(groupsId);
    setState({
      ...state,
      selectedGroup: selectedGroup,
      group: group,
      groupId: groupsId,
      tempGroup: tempArray,
    });
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    if (val.length > 0) {
      if (assignId && urlId) {
        let results = state?.tempGroup?.filter((item) =>
          item?.name.includes(val)
        );
        setState({ ...state, group: results });
      } else {
        let results = state?.tempGroup?.filter((item) =>
          item?.name.includes(val)
        );
        setState({ ...state, group: results });
      }
    } else {
      const { tempGroup } = state;
      if (assignId && urlId) {
        setState({ ...state, group: tempGroup });
      } else {
        setState({ ...state, group: tempGroup });
      }
    }
  };

  return (
    <div className="course-assignbox-wrap height-auto">
      <div className="course-form-content-sec d-flex">
        <div className="add-quiz-box whitebox shadow-box">
          <div className="add-quiz-box-header">
            <Form>
              <div className="form-input-wrap">
                <Form.Control
                  type="text"
                  placeholder="Select group"
                  className="input-item"
                  onChange={handleSearch}
                />
                {/* <button type="button" className="icon-btn">
                                    <img src={HeaderSearch} />
                                </button> */}
              </div>
            </Form>
          </div>

          <div className="add-quiz-box-content">
            <ScrollArea
              speed={0.8}
              className="listscroll"
              contentClassName="content"
              horizontal={false}
            >
              <ul>
                {state?.group && state?.group && state?.group?.length ? (
                  state?.group?.map((el, index) => {
                    return (
                      <li
                        onClick={() => handleClick(el, index)}
                        key={index}
                        className={`${
                          el.isChecked && el.isChecked ? "list-selected" : ""
                        }`}
                      >
                        <span>{el?.name} </span>
                      </li>
                    );
                  })
                ) : (
                  <div className="row justify-content-center mt-2">
                    {loading ? (
                      <Spinner animation="border" variant="danger" />
                    ) : (
                      "NO DATA"
                    )}
                  </div>
                )}
              </ul>
            </ScrollArea>
          </div>
        </div>
        <div className="course-list-selected-box">
          <div className="course-list-selected-box-content">
            {state?.selectedGroup && state.selectedGroup.length
              ? state?.selectedGroup?.map((item, j) => {
                  return (
                    <div className="course-selected-item p-relative">
                      <span className="course-selected-text">{item.name}</span>
                      <span
                        className="memb-close-icon"
                        onClick={() => handleRemove(item, j)}
                      >
                        ×
                      </span>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GroupAssignBox;
