import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { listGeneralSurveyReport } from '../../../services'
import ScrollArea from 'react-scrollbar';
function ExtraResponse(props) {
    const { demographicQuestions, generalQuestions, surveyId, generalQuestion } = props
    const type = generalQuestion?.question
    const dispatch = useDispatch();
    const [surveyReport, setSurveyReport] = useState({})

    const[noResponse,setNoResponse]=useState(false)

  
    

    useEffect(() => {

        var data = {
            question: generalQuestion?.question_id,
        };


        dispatch(listGeneralSurveyReport(surveyId, data, function (response) {

            let newArray=response?.count.filter((item)=>item[Object.keys(item)[0]].length)


            setSurveyReport(newArray)

        }))

    }, [])

    return (
        <div>
            <div className="graph-data-counthere">
                <div className="graph-data-text">
                    <h4 className="graph-title">{generalQuestion?.question}</h4>
                    <p>The following are the responses received for your survey</p>

                </div>
            </div>
            <div>
                <ScrollArea
                    speed={0.8}
                    className="freeresponsescroll"
                    contentClassName="content"
                    horizontal={false}
                >
                    {surveyReport && surveyReport?.length &&
                        surveyReport?.map((item, index) => {

                            return (<p>{item[Object.keys(item)[0]]?Number(index) + 1:''} .
                                {item[Object.keys(item)[0]]}
                            </p>)

                        })
                    }
                    {
                        !surveyReport?.length ? <p>No responses received</p>:''
                    }

                   
                </ScrollArea>
            </div>

        </div>
    )
}

export default ExtraResponse
