import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import CourseCompletedIcon from './completedicon'
import { Link, useHistory } from 'react-router-dom';
import {getLearnerCourseById} from "../../../courses/services";
import { useDispatch, useSelector } from "react-redux";
import SrveyCompleted from '~/assets/images/survey-completed-icon.svg'
// function CourseCompleted({ match }) {

    function CourseCompleted({location}) {

    // const dispatch = useDispatch();
    // const history = useHistory()
    // // const id = match.params.id;
    // const name=props.name;
    // const [state, setState] = useState({
    //     course: {},
    // });
   

    return (
        <div className="course-completed-wrap survey-completed-wrap">
            {/*<h3 className="sec-title m-b-30">Introduction To Course Library</h3>*/}
            <div className="whitebox shadow-box">
                <div className="course-completed-outer-box">
                    <div className="shadow-icon-box-survey">
                      <img src={SrveyCompleted} alt="" />
                    </div>
                    <div className="course-completed-description">
                        <h4 className="completed-text">Survey Completed!</h4>
                        <p>You Have Completed The Survey  - <span> 
                        {location?.state ? location?.state :''}.{'    '}
                            {/* {state?.course?.name}. */}
                            </span> Keep Up The Great Work!</p>
                    </div>
                    <Link to={{ pathname: "https://www.enga.app" }} target="_blank"><button className="btn btn-dark">Go Back to Cultural Engagement</button></Link> 
                </div>
            </div>
        </div>
    );
};

export default CourseCompleted;
