
import React, { useState, useEffect } from "react";
import ScrollArea from 'react-scrollbar';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { // assignSurvey
    getAllGroups,
    assignCourse_groups,
    assignLP_groups, getLP, getGroupCourses, getGroupLP, getGroupSurvey, getGroupDetail
} from '~/modules/team/services';
import { getManagerCoursesList } from '~/modules/coursemanagement/services';
import { getSurvey } from '~/modules/surveys/services';
import Select from 'react-select';

import { assignSurvey } from '~/modules/surveys/services'
import { assignMemberCourse } from '../services'
import { assignCourse } from '~/modules/coursemanagement/services'
import { getNotifications } from '~/modules/Notifications/services'
import { Formik } from "formik";
import * as yup from "yup";
import { assignLearningPath } from "../../learningpath/services";
import DatePicker from "react-datepicker";
import moment from "moment";



function GroupAssignBox(props) {
    const selectedGroups = props.selectlist;
    const dispatch = useDispatch();
    const history = useHistory()
    const response = useSelector(state => state.surveys.group_list?.response);
    const responseTotalCourse = useSelector(state => state?.course?.course_listing?.response?.results);
    const responseTotalLP = useSelector(state => state.teams.totalLP?.response.results || [] || []);
    const responseTotalSurvey = useSelector(state => state?.surveys?.surveys_list?.response?.results || [] || [])
    const { hideLpQuizes } = useSelector((state) => state.settings);
    const [category, setCategory] = useState('course');
    const { user } = useSelector(state => state.auth);
    const [skills, setSkills] = useState([])
    const [selectedSkills, setSelectedSkills] = useState(null)
    const [assignmentPopup, setassignmentPopup] = useState(false)

    const SignInSchema = yup.object().shape({
        name: yup.string().required('Name is Required field'),
        due_date: yup.string().required('Date is Required field')
    });

    useEffect(() => {
        let tempSearch = ''
        if(!hideLpQuizes){
            dispatch(getLP());
        }
        dispatch(getManagerCoursesList(tempSearch));
        // dispatch(getSurvey(tempSearch,tempSearch,tempSearch,tempSearch))


    }, [])







    useEffect(() => {
        let componentMounted = true;
        const fetchData = () => {
            if (componentMounted) {
                setCategory('course')

                dispatch(getNotifications())

                setSkills(responseTotalCourse)

            }
        };
        fetchData();
        return () => {
            componentMounted = false;
        }
    }, []);



    const handleCategory = (e) => {

        if (e.target.value == 'course') {
            setCategory('course')
            setSkills(responseTotalCourse)
        }
        if (e.target.value == 'LP') {
            setCategory('Learning Path')
            setSkills(responseTotalLP)
        }
        if (e.target.value == 'Survey') {
            setCategory('Survey')
            setSkills(responseTotalSurvey)
        }
    }

    //set skills for assign
    const skillSelect = (e) => {

        setSelectedSkills(e.id)

    }

    // const handleChange=()=>{}
    const handleSubmit = (values) => {

        props.parentCallback(true)
        const selectlist = selectedGroups.map(a => a.id);
        var skillId = selectedSkills;

        var data = {
            name: values.name,
            groups: selectlist,
            due_date: values.due_date
        }

        if (category == 'course') {
            dispatch(assignMemberCourse(data, skillId, history, function () { 
                setSelectedSkills(null)
            }));
        } else {
            dispatch(assignLearningPath(data, skillId, null, function () {  
                setSelectedSkills(null)
          }));

        }

    }




    const handleDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today
    }






    const assignSkills = (e) => {
        setassignmentPopup(true)

        //    if(category=='Learning Path'){
        //       const selectlist =[]
        //        for (var i = 0; i < selectedGroupIdList.length; i++) {
        //        selectlist.push({
        //          id:selectedGroupIdList[i].id,
        //          });
        //          }
        //     var Id=selectedSkills;
        //     var data= {
        //      groups:selectlist
        //         }

        //       dispatch(assignLP_groups_multiple(Id,data))
        //       setTimeout(() => {
        //       dispatch( getAllGroups(user?.token,'',''))
        //     }, 1000)

        // }

        if (category == 'Survey') {
            const group = selectedGroups

            const selectlist = group;

            dispatch(assignSurvey(selectlist, history, selectedSkills))
            setTimeout(() => {

            }, 2000)
            //    assignShow(false)
            props.parentCallback(true)
            setSelectedSkills(null)
        }


    }






    return (
        <>
            <div className="assignbox-wrap">
                <div className="assignbox-wrap">

                    {!assignmentPopup ?

                        <>

                            <div className="form-input-wrap">
                            {!hideLpQuizes ? (
                                <>
                                    <Form.Label>Select Category</Form.Label>
                                    <select className="form-control select-item" onChange={handleCategory}>
                                          <option value="course">Course</option>
                                    
                                        <option value="LP">Learning Path</option>
                                    
                                
                                        {/* <option value="Survey">Survey</option> */}
                                        </select>
                                </>
                               
                                ) : (
                                    <Form.Label>Course</Form.Label>
                                )}
                                
                            </div>
                            <div className="form-input-wrap">
                                {category == 'course' ?
                                    <div className="select-item">

                                        <Select
                                            placeholder={`Select a Course`}
                                            onChange={skillSelect}
                                            isSearchable={true}
                                            name={category}
                                            options={skills || []}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}

                                        />

                                    </div> : ''}


                                {category == 'Survey' ?
                                    <div>

                                        <Select

                                            placeholder={`Select a Survey`}
                                            onChange={skillSelect}

                                            isSearchable={true}
                                            name={category}
                                            options={skills || []}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}

                                        />
                                    </div> : ''}  {category == 'Learning Path' ?
                                        <div>

                                            <Select

                                                placeholder={`Select a ${category}`}
                                                onChange={skillSelect}
                                                isSearchable={true}
                                                name={category}
                                                options={skills || []}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}

                                            />
                                        </div> : ''}


                            </div>  {selectedGroups.length <= 0 ? <span style={{ color: 'red' }}>Select Groups To Enroll </span> : ''}
                            <div className="button-wrapper d-flex justify-content-end m-t-20 m-b-10"><Button disabled={selectedGroups.length <= 0 || !selectedSkills ? "disabled" : ""} onClick={assignSkills}>Enroll</Button></div>





                        </> : ''}

                    {/*  */}

                    {assignmentPopup ?
                        <div className="content-modla-wrap">
                            <Formik
                                validationSchema={SignInSchema}
                                onSubmit={handleSubmit}
                                initialValues={
                                    // failed && failed.name ? failed :singleAssignment && singleAssignment ? singleAssignment :
                                    {
                                        name: '',
                                        due_date: '',
                                    }}
                                enableReinitialize={true}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    errors,
                                    setFieldValue
                                }) => {
                                    const handleChangeDate = (date) => {
                                        let datedata = moment(date).format("YYYY-MM-DD");
                                        setFieldValue("due_date",datedata);
                                    }
                                  return  (

                                        <Form noValidate onSubmit={handleSubmit}>
                                            <div className="form-input-wrap">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    // ref ={nameInputRef}
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enrollment Name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="form-input-wrap">
                                                <Form.Label>Due Date</Form.Label>
                                                
                                                {/* <Form.Control type="password" placeholder="Your Password" className="input-item" /> */}
                                                <div className="survey-datepicker-box p-relative">
                                                    <DatePicker
                                                        // name="due_date"
                                                        id='one'
                                                        placeholderText={'Select Date'}
                                                        minDate={new Date()}
                                                        // startDate={startDate}
                                                        // endDate={endDate}
                                                        onChange={handleChangeDate}
                                                        // onChange={(date) => setStartDate(date)}
                                                        value={values.due_date}
                                                        // selected={values.due_date}
                                                        isClearable={false}
                                                        autoComplete='off'
                                                    />
                                                    </div>
                                                    <Form.Control.Feedback type="invalid" className="d-block">
                                                            {errors.due_date}
                                                    </Form.Control.Feedback>
                                            </div>
    
    
    
                                            <div className="button-wrap double-btn mt-4">
                                                <button type="submit" className="btn border-btn">Save</button>
                                            </div>
    
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>

                        : ''}

                    {/*  */}
                </div>
            </div>


        </>
    );
};
export default GroupAssignBox;
