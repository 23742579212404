import React, { useEffect, useState } from "react";
import ScrollArea from "react-scrollbar";
import Form from "react-bootstrap/Form";
import HeaderSearch from "~/assets/images/icons/magnifiying-glass.svg";
import { getAllGroup, getMemberList } from "../../../../../services";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {
  getClassesWithOutPagination,
  getGroupsWithOutPagination,
} from "../../../../../../learningpath/services";

const ClassesAssignBox = ({
  classesList,
  assignId,
  urlId,
  singleAssignment,
  active,
}) => {
  const dispatch = useDispatch();
  const response = useSelector(
    (state) => state?.learningpath?.classes_without_pagination?.response
  );
  const loading = useSelector(
    (state) => state?.learningpath?.classes_without_pagination?.loading
  );
  const [state, setState] = useState({
    classes: (response?.length > 0 && response) || [],
    tempClasses: response || [],
    selectedClasses: [],
    tempoGroup: [],
    classesId: [],
  });
  useEffect(() => {
    dispatch(getClassesWithOutPagination());
  }, []);

  useEffect(() => {
    if (assignId && urlId) {
      let { classesId } = state;
      let newArray = response?.map?.((item) => {
        let temp = {
          ...item,
          isChecked: singleAssignment?.ed_link_class_id?.includes(item.id),
        };
        return temp;
      });
      let selectedArray = newArray?.filter((el) => {
        return el.isChecked;
      });
      let classId = selectedArray?.map((el) => el.id);
      classesList(classId);

      setState({
        ...state,
        classes: newArray,
        tempClasses: newArray,
        selectedClasses: selectedArray,
        classesId: classId,
      });
    } else {
      setState({ ...state, classes: response, tempClasses: response });
    }
  }, [response, assignId]);

  const handleClick = (item, index) => {
    let { classes, selectedClasses, classesId, tempClasses } = state;

    let tempArray = JSON.parse(JSON.stringify(tempClasses));

    if(!classes[index].isChecked){
    classes.map((el) => {
      return (el.isChecked = false);
    });
    }

   

    classes[index].isChecked = !classes[index].isChecked;

    let tempIndex = tempArray.findIndex((el) => {
      return el.id == item.id;
    });

    tempArray.map((el) => {
      return (el.isChecked = false);
    });

    tempArray[tempIndex].isChecked = !tempArray[tempIndex].isChecked;

    if (selectedClasses.find((f) => f.id == item.id)) {
      selectedClasses = selectedClasses.filter((el) => el.id != item.id);
      classesId = classesId.filter((el) => el != item.id);
    } else {
      selectedClasses = [item];
      classesId = [item.id];
    }

    classesList(classesId);
    setState({
      ...state,
      selectedClasses: selectedClasses,
      classesId: classesId,
      classes: classes,
      tempClasses: tempArray,
    });
  };

  const handleRemove = (item, j) => {
    const { selectedClasses, classesId, classes, tempClasses } = state;
    selectedClasses.splice(j, 1);
    let tempArray = JSON.parse(JSON.stringify(tempClasses));

    let tempIndextemp = tempArray.findIndex((el) => {
      return el.id == item.id;
    });
    let tempIndex = classes.findIndex((el) => {
      return el.id == item.id;
    });
    if (tempIndex > -1) {
      classes[tempIndex].isChecked = false;
    }
    tempArray[tempIndextemp].isChecked = false;
    let classId = selectedClasses?.map((el) => el.id);
    classesList(classId);
    setState({
      ...state,
      selectedClasses: selectedClasses,
      classes: classes,
      classesId: classId,
      tempClasses: tempArray,
    });
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    if (val.length > 0) {
      if (assignId && urlId) {
        let results = state?.tempClasses?.filter((item) =>
          item?.name.includes(val)
        );
        setState({ ...state, classes: results });
      } else {
        let results = state?.tempClasses?.filter((item) =>
          item?.name.includes(val)
        );
        setState({ ...state, classes: results });
      }
    } else {
      const { tempClasses } = state;
      if (assignId && urlId) {
        setState({ ...state, classes: tempClasses });
      } else {
        setState({ ...state, classes: tempClasses });
      }
    }
  };

  return (
    <div className="course-assignbox-wrap height-auto">
      <div className="course-form-content-sec d-flex">
        <div className="add-quiz-box whitebox shadow-box">
          <div className="add-quiz-box-header">
            <Form>
              <div className="form-input-wrap">
                <Form.Control
                  type="text"
                  placeholder="Select classes"
                  className="input-item"
                  onChange={handleSearch}
                />
                {/* <button type="button" className="icon-btn">
                                    <img src={HeaderSearch} />
                                </button> */}
              </div>
            </Form>
          </div>

          <div className="add-quiz-box-content">
            <ScrollArea
              speed={0.8}
              className="listscroll"
              contentClassName="content"
              horizontal={false}
            >
              <ul>
                {state?.classes && state?.classes && state?.classes?.length ? (
                  state?.classes?.map((el, index) => {
                    return (
                      <li
                        onClick={() =>
                          singleAssignment?.assignment_type !== "edlink" &&
                          handleClick(el, index)
                        }
                        key={index}
                        className={`${
                          el.isChecked && el.isChecked ? "list-selected" : ""
                        }`}
                      >
                        <span>{el?.name} </span>
                      </li>
                    );
                  })
                ) : (
                  <div className="row justify-content-center mt-2">
                    {loading ? (
                      <Spinner animation="border" variant="danger" />
                    ) : (
                      "NO DATA"
                    )}
                  </div>
                )}
              </ul>
            </ScrollArea>
          </div>
        </div>
        <div className="course-list-selected-box">
          <div className="course-list-selected-box-content">
            {state?.selectedClasses && state.selectedClasses.length
              ? state?.selectedClasses?.map((item, j) => {
                  return (
                    <div className="course-selected-item p-relative">
                      <span className="course-selected-text">{item.name}</span>
                      {singleAssignment &&
                      singleAssignment?.ed_link_class_id?.length > 0 ? (
                        ""
                      ) : (
                        <span
                          className="memb-close-icon"
                          onClick={() => handleRemove(item, j)}
                        >
                          ×
                        </span>
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClassesAssignBox;
