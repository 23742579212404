import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { tableData } from './dummyTableData';
import FilterImg from '~/assets/images/icons/filter.svg';
import { Link, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { useDispatch, useSelector } from "react-redux";
import CsvDownload from 'react-json-to-csv'
import Spinner from 'react-bootstrap/Spinner';
import { CSVLink } from "react-csv";
import CustomPagination from "../../../../shared/components/pagination";

import {
    getSurvey,
    getManagersList,
    handlePagination,
    handlePreviousPagination,
    downloadSurvey
} from '~/modules/surveys/services';
import Select from 'react-select';
import { format } from "date-fns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const AvailableSurveys = () => {
    const history = useHistory();
    const csvdownload = useRef();
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState([null, null]);
    const [createdby, setCreatedby] = useState('');
    const [startDate, endDate] = dateRange;
    const [assignedby, setAssignedby] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [surveys, setSurveys] = useState(response);
    // const [reports, setReports] = useState(report)
    const [search, setSearch] = useState('');
    const [state, setState] = useState({
        reports: report,
        rowId: ''
    })
    const response = useSelector(state => state.surveys.surveys_list?.response);
    const report = useSelector(state => state.surveys.downloaded_report?.response);
    const loading = useSelector(state => state.surveys.downloaded_report?.loading);
    const managers = useSelector(state => state.surveys.manager_list?.response || []);
    useEffect(() => {
        setSearch('')
        setDateRange([null, null]);
        setAssignedby('');
        dispatch(getSurvey(search, startDate === null ? '' : startDate, endDate == null ? '' : endDate, createdby === '' ? '' : createdby?.id,''))
        dispatch(getManagersList());
    }, [])

    useEffect(() => {

        setSurveys(response);
    }, [response]);


    useEffect(() => {
        if (report && report?.demographic_results && report?.demographic_results.length) {
            let newArray = report?.demographic_results.map((el) => {

                let element = { ...el, ...el.other_results }
                delete element.other_results
                let properties = {
                    ...element,

                }
                return properties
            })


            setState({ ...state, reports: newArray });
        }

    }, [report]);

    const onSelect = (val) => {
        setAssignedby(val);
        dispatch(getSurvey(search, startDate === null ? '' : startDate, endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), val.id,''));
    };
    const reset = () => {
        setSearch('');
        setDateRange([null, null]);
        setAssignedby('');
        setActivePage(1)
        dispatch(getSurvey("", startDate === null ? '' : '', endDate == null ? '' : '', createdby === '' ? '' : '',''));
    };
    const searchInput = (e) => {
        setSearch(e.target.value);
        dispatch(getSurvey(e.target.value, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id,''));
    }

    const handleNext = () => {
        if (surveys?.next) {
            setActivePage(activePage + 1);
            dispatch(handlePagination(surveys?.next));
        } else {
            return false;
        }

    };

    const handlePrevious = () => {
        if (surveys?.previous) {
            setActivePage(activePage - 1);
            dispatch(handlePreviousPagination(surveys?.previous));

        } else {
            return false;
        }

    };
    const data = [
        { firstName: "Warren", lastName: "Morrow", email: "sokyt@mailinator.com", age: "36" },
        { firstName: "Gwendolyn", lastName: "Galloway", email: "weciz@mailinator.com", age: "76" },
        { firstName: "Astra", lastName: "Wyatt", email: "quvyn@mailinator.com", age: "57" },
        { firstName: "Jasmine", lastName: "Wong", email: "toxazoc@mailinator.com", age: "42" },
        { firstName: "Brooke", lastName: "Mcconnell", email: "vyry@mailinator.com", age: "56" },
        { firstName: "Christen", lastName: "Haney", email: "pagevolal@mailinator.com", age: "23" },
        { firstName: "Tate", lastName: "Vega", email: "dycubo@mailinator.com", age: "87" },
        { firstName: "Amber", lastName: "Brady", email: "vyconixy@mailinator.com", age: "78" },
        { firstName: "Philip", lastName: "Whitfield", email: "velyfi@mailinator.com", age: "22" },
        { firstName: "Kitra", lastName: "Hammond", email: "fiwiloqu@mailinator.com", age: "35" },
        { firstName: "Charity", lastName: "Mathews", email: "fubigonero@mailinator.com", age: "63" }
    ];

    const handleRefClick = () => {
        csvdownload.current.link.click();


    }

    const handleDownload = (id) => {
        setState({ ...state, rowId: id })
        dispatch(downloadSurvey(id, csvdownload, function (response) {

            if (response) {


                setTimeout(handleRefClick, 100)

            }

        }))

    }
    const handleIndex = (index) =>{
        if(index == 1){
            dispatch(getSurvey(search, startDate === null ? '' : startDate, endDate == null ? '' : endDate, createdby === '' ? '' : createdby?.id,''));
        }else{
            dispatch(getSurvey(null,null,null,null, index));
        }
        setActivePage(index);
    }
    const { reports } = state;
    const tableColumns = [
        {
            name: 'Survey Name',
            selector: 'name',
            sortable: true,
            center: false,
            grow: 5
        },
        {
            name: 'Number of Responses',
            selector: 'attended_count',
            sortable: true,
            center: false,
            grow: 3
        },
        {
            name: 'Created By',
            selector: 'creator_name',
            sortable: true,
            center: false,
            grow: 3,

        },
        {
            name: 'Expiry Date',
            selector: 'expiry_date',
            sortable: true,
            center: false,
            grow: 3,

        },


        {
            name: 'Action',
            selector: 'Action',
            sortable: true,
            center: false,
            grow: 2,
            cell: (row, index) => {

                return (
                    <div data-tag="allowRowEvents" className='d-flex'>
                    <div className="action-icons-cell-wrap d-flex">
                    {/* <div className="action-icon-item fill-icon big-black-icon" disabled={loading} onClick={() => handleDownload(row.id)}>
                                    {loading && loading && state.rowId == row.id ?
                                    <Spinner animation="border" variant="warning" />
                                    :
                                    <OverlayTrigger
                                    overlay={
                                      <Tooltip id="InfoText">
                                        Download
                                      </Tooltip>
                                    }
                                  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.4" height="20.4" viewBox="0 0 22.4 20.4">
                            <g transform="translate(-170.799 -400.8)">
                                <path class="a" d="M-7222.479-2405a2.544,2.544,0,0,1-2.521-2.562v-4.192a.693.693,0,0,1,.688-.7.693.693,0,0,1,.688.7v4.192a1.157,1.157,0,0,0,1.146,1.165h16.958a1.157,1.157,0,0,0,1.146-1.165v-4.192a.693.693,0,0,1,.688-.7.693.693,0,0,1,.688.7v4.192a2.544,2.544,0,0,1-2.521,2.562Zm8.475-5.822h-.016a.673.673,0,0,1-.154-.022.675.675,0,0,1-.208-.1.683.683,0,0,1-.119-.1l-3.65-3.71a.707.707,0,0,1,0-.988.679.679,0,0,1,.972,0l2.493,2.533V-2424.3a.693.693,0,0,1,.688-.7.693.693,0,0,1,.688.7v11.094l2.494-2.535a.68.68,0,0,1,.973,0,.707.707,0,0,1,0,.988l-3.667,3.727a.69.69,0,0,1-.336.188.7.7,0,0,1-.131.017H-7214Z" transform="translate(7396 2826)"/>
                            </g>
                            </svg>
                              </OverlayTrigger>
                                }</div> */}

                                <CSVLink
                                    data={reports && reports?.length ? reports : []}
                                    filename="data.csv"
                                    className="hidden"
                                    ref={csvdownload}
                                />
                                        <div className="action-icon-item fill-icon big-black-icon remove-mangr" onClick={() => history.push(`/survey-graph/${row?.id}`)}>
                                        <OverlayTrigger
                                    overlay={
                                      <Tooltip id="InfoText">
                                        Reports
                                      </Tooltip>
                                    }
                                  >
                          <svg id="reports" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <g id="Group_6642" data-name="Group 6642" transform="translate(10328.672 81.404)">
      <path id="Path_8215" data-name="Path 8215" d="M14.545,17.205H3.792A1.8,1.8,0,0,1,2,15.413V1.792A1.8,1.8,0,0,1,3.792,0H14.545a1.8,1.8,0,0,1,1.792,1.792V15.413A1.8,1.8,0,0,1,14.545,17.205ZM3.792.717A1.077,1.077,0,0,0,2.717,1.792V15.413a1.077,1.077,0,0,0,1.075,1.075H14.545a1.077,1.077,0,0,0,1.075-1.075V1.792A1.077,1.077,0,0,0,14.545.717Z" transform="translate(-10325.67 -78.404)" stroke="#000" stroke-width="0.7"/>
      <path id="Path_8216" data-name="Path 8216" d="M17.426,11.717H16.285a.368.368,0,0,1,0-.717h1.141a.368.368,0,0,1,0,.717Z" transform="translate(-10329.195 -83.172)" stroke="#000" stroke-width="0.5"/>
      <path id="Path_8217" data-name="Path 8217" d="M17.426,8.717H16.285a.368.368,0,0,1,0-.717h1.141a.368.368,0,0,1,0,.717Z" transform="translate(-10329.195 -82.322)" stroke="#000" stroke-width="0.5"/>
      <path id="Path_8218" data-name="Path 8218" d="M17.426,14.717H16.285a.368.368,0,0,1,0-.717h1.141a.368.368,0,0,1,0,.717Z" transform="translate(-10329.195 -84.021)" stroke="#000" stroke-width="0.5"/>
      <path id="Path_8219" data-name="Path 8219" d="M17.426,17.717H16.285a.368.368,0,0,1,0-.717h1.141a.368.368,0,0,1,0,.717Z" transform="translate(-10329.195 -84.871)" stroke="#000" stroke-width="0.5"/>
      <path id="Path_8220" data-name="Path 8220" d="M14.678,20.717H5.358a.358.358,0,1,1,0-.717h9.319a.358.358,0,1,1,0,.717Z" transform="translate(-10326.52 -84.066)" stroke="#000" stroke-width="0.6"/>
      <g id="Group_6639" data-name="Group 6639" transform="translate(-10321.52 -73.964)">
         <g id="Group_6638" data-name="Group 6638">
            <path id="Path_8225" data-name="Path 8225" d="M56.7,54.729a.337.337,0,0,0-.337.337v.606a3.1,3.1,0,1,0,3.418,3.418h.606a.337.337,0,0,0,.337-.337A4.028,4.028,0,0,0,56.7,54.729Zm0,6.449a2.425,2.425,0,0,1-.337-4.827v2.4a.337.337,0,0,0,.337.337h2.4A2.429,2.429,0,0,1,56.7,61.178Zm.337-2.762h0v-3a3.356,3.356,0,0,1,3,3Z" transform="translate(-53.602 -54.729)" stroke="#000" stroke-width="0.5"/>
         </g>
      </g>
   </g>
   <rect id="Rectangle_3313" data-name="Rectangle 3313" width="24" height="24" fill="none"/>
</svg>
                              </OverlayTrigger>
                                        </div>
                                        </div>

                    </div>
                )

            }



        },
    ]
    return (
        <div className="available-course-wrap">
            <ToastContainer autoClose={2000} />
            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="dt-filter-left d-flex justify-content-between align-items-center">

                    <div className="custom-react-select">
                        <Select options={managers || []} value={assignedby} onChange={onSelect}
                            placeholder="Created" />
                    </div>
                    {/* <button type="button" className="iconbutton"><img src={FilterImg} /></button> */}
                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative">
                        <input type="text" placeholder="Search using keyword"
                            value={search} onChange={searchInput}
                            className="form-control input-item" />
                        <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                    </div>
                    <button className="iconbutton search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                            <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="datatble-wrap">
                <DataTable
                    data={surveys?.results}
                    columns={tableColumns}
                    center={true}
                    noHeader={true}
                />
            </div>
            {surveys?.count  > 10 ?
                <CustomPagination response = {surveys} activePage ={activePage} handleNext = {handleNext} handlePrevious={handlePrevious} handleIndex = {handleIndex}/>
                :
                ""

            }
        </div>
    );
};
export default AvailableSurveys;
