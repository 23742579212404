

import React from 'react';

function InstitutionContactIcon() {
return (
   <React.Fragment>

<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" 
        viewBox="0 0 24.3 24.3"  >
        <g>
        <path className="a" d="M4.8,8.4L7.4,11c0.3,0.3,0.3,0.8,0,1.1l-1.6,1.6l0,0L2.1,10l0,0l1.6-1.6C4,8.1,4.5,8.1,4.8,8.4z"/>
        <path className="a" d="M2.1,10c0,0-3.9,3.3,2.5,9.7"/>
        <path className="a" d="M5.8,13.7c0,0-2,1.3,0.7,4"/>
        <path className="a" d="M14.3,22.2l-3.8-3.8l0,0l1.6-1.6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0l2.7,2.7c0.3,0.3,0.3,0.8,0,1.1
            c0,0,0,0,0,0L14.3,22.2L14.3,22.2z"/>
        <path className="a" d="M14.3,22.2c0,0-3.3,3.9-9.7-2.5"/>
        <path className="a" d="M10.5,18.4c0,0-1.3,1.9-4-0.7"/>
        <path className="a" d="M15.6,1c-4.3,0-7.5,2.8-7.5,6.4c-0.1,1.6,0.7,3.1,2,4.1l0,0c0.2,1,0.2,2-0.1,3c-0.1,0.3,0.1,0.5,0.3,0.6
            c0.1,0,0.2,0,0.3,0l3.9-1.4c0.4,0.1,0.8,0.1,1.2,0.1c4.3,0,7.7-2.9,7.7-6.4S19.9,1,15.6,1z"/>
        <circle cx="12.5" cy="7.6" r="0.9"/>
        <circle cx="15.9" cy="7.6" r="0.9"/>
        <circle cx="19.3" cy="7.6" r="0.9"/>
        </g>
        </svg>
</React.Fragment>
);
}
export default InstitutionContactIcon;