import React, {useEffect, useRef, useState} from 'react';
import CalendarImg from '~/assets/images/icons/calendar-ico.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {getConstructResults,getConstructResultsByChoices,getConstructResultsNew,getSurveyConstructList,getDemographicFilter } from '~/modules/surveys/surveyReport/surveyreports/services';
import {useSelector,useDispatch} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import Select from 'react-select';



function Questions(props) {

    const response = useSelector(state => state.surveyreport.construct_report?.response);
    const filterResponse = useSelector(state => state.surveyreport.demographic_filter?.response);
    const constructresponse = useSelector(state => state?.surveyreport?.survey_construct_list?.response);
    const constructResultResponse = useSelector(state => state?.surveyreport?.construct_results?.response);
    const { user } = useSelector(state => state.auth);
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        ConstructResults:response?.results,
        ConstructObj:{},
        averageArray:[],
        empty :'',
        constructFilter:{},
        constructOne:'',
        constructTwo:'',
        constructs:[],
        construct_results:[],
        demographic_filters:[],
        filterChoicesOne:[],
        filterChoicesTwo:[],
        constructId:''

    })

    const [choiceOne, setchoiceOne] = useState('')

    const [choiceTwo, setchoiceTwo] = useState('')

    const selectRef = useRef(null)
    const selectOneRef = useRef(null)
    const choice1 = useRef(null)
    const choice2 = useRef(null)
    const construct1 = useRef(null)
    const construct2 = useRef(null)

    useEffect(()=>{
        dispatch(getSurveyConstructList(props?.id,user?.token))
        dispatch(getDemographicFilter(props?.id,user?.token))
    },[])

    useEffect(()=>{
        setState({...state,constructs:constructresponse?.results})
    },[constructresponse])
    useEffect(()=>{
        let newArray = filterResponse?.results?.map((el)=>{
            return {
                ...el,
                value:el.id,
                label:el.question,
                label1:el.label
            }
        })
        setState({...state,demographic_filters:newArray})
    },[filterResponse])

    // const constructResult = (response,prop,constructid) =>{
    //     if(response?.results?.length){
    //         let newData = response.results.map((el, index) => {
    //
    //             var superSecret = function (spy) {
    //                 Object.keys(spy).forEach(function (key, value) {
    //
    //                     if (typeof spy[key] == 'string') {
    //                         spy[key] = Number(spy[key].substr(0, 1));
    //
    //                     } else {
    //                         spy[key] = spy[key]
    //                     }
    //
    //
    //                 });
    //                 return spy;
    //             }
    //             return superSecret(el)
    //         })
    //
    //         const groupedArray = [];
    //
    //         newData.forEach(item => {
    //
    //             const found = groupedArray.find(x => x === item);
    //
    //             if (found) {
    //                 found.x(item);
    //             } else {
    //                 groupedArray.push({x: item, exchange_pair_id: [item.exchange_pair_id]});
    //             }
    //
    //         });
    //
    //
    //         var avg = Array.from(newData.reduce(
    //             (acc, obj) => Object.keys(obj).reduce(
    //                 (acc, key) => typeof obj[key] == "number"
    //                     ? acc.set(key, (acc.get(key) || []).concat(obj[key]))
    //                     : acc,
    //                 acc),
    //             new Map()),
    //
    //             ([name, values]) =>
    //
    //
    //                 ({name, average: values.reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {})})
    //         );
    //
    //         console.log("ave", avg)
    //
    //         let finalArray = avg?.map((el) => {
    //             const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);
    //             var superSecret = function (spy) {
    //                 Object.keys(spy).forEach(function (key) {
    //                     spy[key] = (Number(key) * spy[key])
    //                 })
    //                 return spy;
    //             }
    //
    //             let tempObj = {...superSecret(el.average)}
    //             let totalValue = sumValues(tempObj)
    //
    //             var superSecret1 = function (spy) {
    //
    //                 Object.keys(spy).forEach(function (key) {
    //                     spy[key] = Math.round(spy[key] / totalValue * 100)
    //                 })
    //                 return spy;
    //             }
    //
    //             let property = {
    //                 ...el,
    //                 average: superSecret1(tempObj)
    //             }
    //             return property
    //         })
    //
    //         if(prop == 'constructSelect'){
    //             setchoiceOne('')
    //             setchoiceTwo('')
    //             setState({...state, averageArray: finalArray,empty: '',constructOne: '',constructTwo: '',filterChoicesOne:[],filterChoicesTwo: [],constructId: constructid})
    //         }else{
    //             setState({...state, averageArray: finalArray,empty: ''})
    //         }
    //
    //
    //
    //     }else {
    //         setState({...state, empty: ''})
    //         setTimeout(()=>{
    //             setState({...state, empty: 'No Data'})
    //         },400)
    //     }
    //
    // }

    const constructResult = (response,prop,constructid) =>{
        if(response?.results){


           let questions = [];
            let values =  [];
            for(let key in response?.results) {
                questions.push(key)
                    values.push(response?.results[key])

            }

            let finalArray = questions?.map((el,index)=>{

                return {
                    name:el,
                    average:values[index]
                }
            })


            if(prop == 'constructSelect'){
                setchoiceOne('')
                setchoiceTwo('')
                setState({...state, averageArray: finalArray,empty: '',constructOne: '',constructTwo: '',filterChoicesOne:[],filterChoicesTwo: [],constructId: constructid})
            }else{
                setState({...state, averageArray: finalArray,empty: ''})
            }



        }else {
            setState({...state, empty: ''})
            setTimeout(()=>{
                setState({...state, empty: 'No Data'})
            },400)
        }

    }


    const handleConstructs = (e) =>{

        if(e.target.value){
            dispatch(getConstructResultsNew(e.target.value,function (response){
                if(response){
                    constructResult(response,'constructSelect',response?.id)
                }
            }))

        }

    }

    const applyColorClass = (rating) => {
        if (rating) {
            if (rating >= 0 && rating <= 20 ) {
                return 'density-color-least'
            } else if (rating >= 21 && rating <= 40) {
                return 'density-color-high'
            } else if (rating >= 41 && rating <= 60) {
                return 'density-color-red'
            } else if (rating >= 61 && rating <= 80) {
                return 'density-color-dark-red'
            } else if (rating >= 81 && rating <= 100) {
                return 'density-color-black'
            }
        } else {
            return '';
        }
    }

    const handleChange = (e,prop) =>{


        if(prop == 'one'){

            choice1.current.value = 'Filter Name'
            setState({...state,constructOne: e.label1,filterChoicesOne:e})
        }else if(prop ='two'){
            choice2.current.value = 'Filter Name'
            setState({...state,constructTwo: e.label1,filterChoicesTwo:e})
        }



    }

    const handleFinalChange = (e,prop) =>{
        if(e.target.value) {
            if (prop == 'one') {

                setchoiceOne(e.target.value)
                dispatch(getConstructResultsByChoices(state.constructId,state.constructOne ? state.constructOne : ' ', state.constructTwo ? state.constructTwo : ' ', e.target.value ? e.target.value : ' ', choiceTwo ? choiceTwo : ' ', function (response) {
                    if (response) {

                        constructResult(response,'choiceSelect',state?.constructId)
                    }
                }))



            } else if (prop == 'two') {
                setchoiceTwo(e.target.value)

                dispatch(getConstructResultsByChoices(state.constructId,state.constructOne ? state.constructOne : ' ', state.constructTwo ? state.constructTwo : ' ', choiceOne ? choiceOne : ' ', e.target.value ? e.target.value : ' ', function (response) {
                    if (response) {
                        constructResult(response,'choiceSelect',state?.constructId)
                    }
                }))


            }
        }
    }


    return (
        <div className="questions-wrap">
            <h4 className="page-head">Question Summary</h4>
            <div className="paragraph-with-icon d-flex">
                <span><img src={CalendarImg} /></span><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a</p>
            </div>

            <Row className='mb-3'>
                <Col md={4}>
                    <div className="question-select-wrap">
                        <div className="question-select-item">
                            <label>Construct:</label>
                            <select onChange={handleConstructs} aria-label="Default select example" className="form-control select-item" style={{width:'300px'}}>
                                {state?.constructs?.length > 0 ?
                                    <>
                                        <option disabled selected> Please Select Construct</option>
                                        {state?.constructs && state?.constructs?.length ? state?.constructs?.map((item)=>(
                                                <option value={item?.id} >{item?.construct}</option>
                                            ))
                                            :
                                            ""
                                        }

                                    </>
                                    :
                                    <>
                                        <option selected disabled> Please Select Construct</option>
                                        <option disabled> No Constructs</option>
                                    </>
                                }
                            </select>
                        </div>
                    </div>
                </Col>


            </Row>

            <Row className="m-b-30">
                <Col md={4}>
                    <div className="question-select-wrap">
                        <div className="question-select-item">
                            <label>Filter:</label>
                            <div className="react-select-box-wrap">
                                <Select
                                     style={{width:'300px'}}
                                    isDisabled={!state.constructId}
                                    value ={state.demographic_filters && state.constructOne ? state?.demographic_filters?.find((el)=>el?.label1 == state?.constructOne) : ''}
                                    onChange={(e)=>handleChange(e,'one')}
                                    options={state.demographic_filters && state.constructTwo ? state?.demographic_filters?.filter((el)=>el.label1 != state.constructTwo) : state?.demographic_filters}

                                />


                            </div>

                        </div>
                    </div>
                </Col>


                <Col md={4}>
                    <div className="question-select-wrap">
                        <div className="question-select-item">
                            <label>Filter:</label>
                            <select  ref={choice1} onChange={(e)=>handleFinalChange(e,'one')}  aria-label="Default select example" className="form-control select-item" style={{width:'300px'}}>
                                {state?.filterChoicesOne?.choices?.length > 0 ?
                                    <>
                                        <option> Filter Name</option>
                                        {state.filterChoicesOne && state?.filterChoicesOne?.choices?.length ? state?.filterChoicesOne?.choices?.map((item) => (
                                                <option value={item}>{item}</option>
                                            ))
                                            :
                                            ""
                                        }
                                    </>

                                    :
                                    <>
                                        <option selected disabled>Filter Name</option>
                                        <option disabled> No Choices, Please Select Question</option>
                                    </>

                                }
                            </select>
                        </div>
                    </div>
                </Col>

            </Row>
            <Row className="m-b-30">
                {state.constructOne && choiceOne   ?
                    <>
                        <Col md={4}>
                            <div className="question-select-wrap">
                                <div className="question-select-item">
                                    <label>Filter:</label>
                                    <div className="react-select-box-wrap">
                                    <Select className="form-control select-item"
                                            disabled={!state.constructId}
                                        value ={state.demographic_filters && state.constructTwo ? state?.demographic_filters?.find((el)=>el?.label1 == state?.constructTwo) : ''}
                                        onChange={(e)=>handleChange(e,'two')}
                                        options={state?.demographic_filters?.filter((el)=>el.label1 != state.constructOne)}

                                    />
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="question-select-wrap">
                                <div className="question-select-item">
                                    <label>Filter:</label>
                                    <select ref={choice2} onChange={(e)=>handleFinalChange(e,'two')}  aria-label="Default select example" className="form-control select-item" style={{width:'300px'}}>
                                        {state?.filterChoicesTwo?.choices?.length > 0 ?
                                            <>
                                                < option > Filter Name</option>
                                                {state.filterChoicesTwo && state?.filterChoicesTwo?.choices?.length ? state?.filterChoicesTwo?.choices?.map((item)=>(
                                                        <option value={item}>{item}</option>
                                                    ))
                                                    :
                                                    ""
                                                }
                                            </>
                                            :

                                            <>
                                                <option selected disabled>Filter Name</option>
                                                <option disabled> No Choices, Please Select Question</option>
                                            </>

                                        }

                                    </select>
                                </div>
                            </div>
                        </Col>

                    </>
                    :
                    ""

                }


            </Row>

            {!state.empty ?
                <div className="questions-density-graph">
                    {state?.averageArray && state?.averageArray.length ?
                        <Row>
                            <Col md={4}>
                                &nbsp;
                            </Col>
                            <Col md={8}>
                                <Row className="density-box-row m-b-10">
                                    <Col className="density-col-title-wrap">
                                        <span className="density-col-title">Strongly Disagree</span>
                                    </Col>
                                    <Col className="density-col-title-wrap">
                                        <span className="density-col-title">Disagree</span>
                                    </Col>
                                    <Col className="density-col-title-wrap">
                                        <span className="density-col-title">Neutral</span>
                                    </Col>
                                    <Col className="density-col-title-wrap">
                                        <span className="density-col-title">Agree</span>
                                    </Col>
                                    <Col className="density-col-title-wrap">
                                        <span className="density-col-title">Strongly Agree</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        :
                        ''
                    }
                    {state?.averageArray && state?.averageArray?.length ?
                        state?.averageArray.map((el,index)=>(
                                <>
                                    <Row>
                                        <Col md={4}>
                                            <label className="label-primary">{el?.name}</label>
                                        </Col>
                                        <Col md={8}>
                                            <Row className="density-box-row m-b-10">
                                                <Col className="density-box">

                                                    <span className={`density-box-item ${applyColorClass(Math.round(el?.average["1- Strongly Disagree"]))}`}> </span>


                                                </Col>
                                                <Col className="density-box">

                                                    <span className={`density-box-item ${applyColorClass(Math.round(el?.average["2- Disagree"]))}`}>&nbsp;</span>

                                                </Col>
                                                <Col className="density-box">

                                                    <span className={`density-box-item ${applyColorClass(Math.round(el?.average["3- Neutral"]))}`}>&nbsp;</span>


                                                </Col>
                                                <Col className="density-box">

                                                    <span className={`density-box-item ${applyColorClass(Math.round(el?.average["4- Agree"]))}`}>&nbsp; </span>


                                                </Col>
                                                <Col className="density-box">

                                                    <span className={`density-box-item ${applyColorClass(Math.round(el?.average["5- Strongly Agree"]))}`}>&nbsp; </span>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </>

                            )
                        )
                        :
                        ''
                    }


                    {state?.averageArray && state?.averageArray.length  ?
                        <Row className="m-t-20">
                            <Col md={2}>
                                <div className="density-notify d-flex align-items-center">
                                    <span className="density-block high1-density-block">&nbsp;</span><span
                                    style={{fontSize:'10px'}}    className="">( 0 to 20%) </span>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="density-notify d-flex align-items-center">
                                    <span className="density-block high5-density-block">&nbsp;</span><span
                                    style={{fontSize:'10px'}}    className="">(21 to 40%) </span>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="density-notify d-flex align-items-center">
                                    <span className="density-block high3-density-block">&nbsp;</span><span
                                    style={{fontSize:'10px'}}    className="">(41 to 60%)</span>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="density-notify d-flex align-items-center">
                                    <span className="density-block high4-density-block">&nbsp;</span><span
                                    style={{fontSize:'10px'}}    className="">(61 to 80%)</span>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="density-notify d-flex align-items-center">
                                    <span className="density-block high2-density-block">&nbsp;</span><span
                                    style={{fontSize:'10px'}}     className="">(81 > 100%)</span>
                                </div>
                            </Col>
                        </Row>
                        :
                        ''
                    }
                </div>
                :
                <div className='row justify-content-center mt-5'> <h4>No Results</h4></div>
            }



        </div>
    );
};
export default Questions;
