
import React from 'react';

function SupportIcon() {
return (
   <React.Fragment>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
   <g transform="translate(82.126 28.126)">
      <path className="a" d="M11.818,21a.615.615,0,0,1-.615-.615V16.038a3.566,3.566,0,0,1,1.592-2.974l3.055-2.037a1.628,1.628,0,0,1,1.43-.182l.337-3.7a2.78,2.78,0,0,1,2.769-2.528A.615.615,0,0,1,21,5.228v9.226a2.192,2.192,0,0,1-.646,1.56l-3.308,3.308v1.063a.615.615,0,0,1-.615.615Zm4.714-8.949-3.055,2.037a2.339,2.339,0,0,0-1.044,1.95V19.77h3.382v-.7a.615.615,0,0,1,.18-.435l3.488-3.488a.969.969,0,0,0,.286-.69h0V5.969a1.542,1.542,0,0,0-.928,1.284L18.4,12.107a2.629,2.629,0,0,1-.682,1.93l-1.511,1.511a.615.615,0,1,1-.87-.87l1.511-1.511a.969.969,0,0,0,.286-.69c0-.377-.2-.489-.375-.489A.425.425,0,0,0,16.532,12.051ZM4.569,21a.615.615,0,0,1-.615-.615V19.322L.646,16.014A2.192,2.192,0,0,1,0,14.454V5.228a.615.615,0,0,1,.615-.615A2.78,2.78,0,0,1,3.384,7.141l.337,3.7a1.628,1.628,0,0,1,1.43.182l3.055,2.037A3.566,3.566,0,0,1,9.8,16.038v4.347A.615.615,0,0,1,9.182,21ZM1.23,14.454a.969.969,0,0,0,.286.69L5,18.632a.615.615,0,0,1,.18.435v.7H8.567V16.038a2.339,2.339,0,0,0-1.044-1.95L4.468,12.051c-.164-.109-.6-.137-.6.426a.969.969,0,0,0,.286.69l1.511,1.511a.615.615,0,1,1-.87.87L3.282,14.037a2.629,2.629,0,0,1-.682-1.93L2.158,7.253A1.542,1.542,0,0,0,1.23,5.969ZM7.205,9.8A1.275,1.275,0,0,1,5.931,8.523,4.568,4.568,0,0,1,8.608,4.362a2.591,2.591,0,1,1,3.783,0,4.566,4.566,0,0,1,2.678,4.161A1.276,1.276,0,0,1,13.795,9.8ZM7.161,8.523a.044.044,0,0,0,.044.044h6.59a.044.044,0,0,0,.044-.044,3.339,3.339,0,0,0-6.677,0ZM9.138,2.592A1.362,1.362,0,1,0,10.5,1.231,1.362,1.362,0,0,0,9.138,2.592Z" transform="translate(-82.126 -28.126)"/>
   </g>
</svg>
</React.Fragment>
);
}
export default SupportIcon;