import React from 'react';
import ScrollArea from 'react-scrollbar';
import videoImg from '~/assets/images/videoimage.png';
import contentImg from '~/assets/images/content-pic-dummy.jpeg';

const TextOnly = (props) => {
    const {value,index,length} = props

    return (
        <div className="videomodule">

            <ScrollArea
                speed={0.8}
                className="modulemodalscroll"
                contentClassName="content"
                horizontal={false}
            >
                <div className="image-content-wrap">
                    <div className="module-content">
                        <div className="shadow-box whitebox">
                        <h3 className="module-content-header">{value?.name}</h3>
                            <p dangerouslySetInnerHTML={{ __html: `${value?.contents ? value?.contents:''}` }}>
                        </p>
                        </div>

                    </div>

                </div>
            </ScrollArea>

        </div>
    )

}

export default TextOnly;
