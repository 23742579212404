import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import CourseCompletedIcon from './completedicon'
import { Link, useHistory } from 'react-router-dom';
import {getLearnerCourseById} from "../../../modules/courses/services";
import { useDispatch, useSelector } from "react-redux";
import Badge from 'react-bootstrap/Badge';
import ScrollArea from 'react-scrollbar';

// function CourseCompleted({ match }) {

    function LpCompleted(props) {

    const dispatch = useDispatch();
    const history = useHistory()
    // const id = match.params.id;
    const name=props.name;
    const [state, setState] = useState({
        course: {},
    });
    // useEffect(() => {
    //     dispatch(getLearnerCourseById(id,function (res){
    //         setState({ ...state, course: res });
    //     }))
    // }, []);
    const handlePush = () => {
        if(props.callingComp){

        if(props.callingComp == 'lp'){
             props?.onHide()
            return;
        }else {
            history.push('/learnerCourses')

        }


        }
        history.push('/learnerCourses')

    }

    return (
        <div className="course-completed-wrap lp-completed">
            {/*<h3 className="sec-title m-b-30">Introduction To Course Library</h3>*/}
            <ScrollArea
                speed={0.8}
                className="modulemodalscroll"
                contentClassName="content"
                horizontal={false}
            >
            <div className="whitebox shadow-box">
                <div className="course-completed-outer-box">
                    <div className="shadow-icon-box">
                        <CourseCompletedIcon />
                    </div>
                    <div className="text-left">
                        <h4 className="completed-text mt-3 mb-3 text-center">Course Completed!</h4>
                        <p>Well Done! You have completed {props?.data?.node_object_name}. Below is the list of modules you have completed in this course</p>
                        <div>
                        {props?.data && props?.data?.course_modules && props?.data?.course_modules.length ? props?.data?.course_modules.map((el,index)=>{

                            return(
                                <div className='d-flex course-completed-modules-list'>


<span className="course-completed-list-icon"><i aria-hidden="true" class="fa fa-check"></i></span>
<p className='ml-2'>{el?.name}</p>


                                </div>
                            )
                        })
                          :
                            ''
                        }
                        </div>





                        {props?.data && props?.data?.completion_message &&
                        <p className='text-left'>{props?.data?.completion_message }</p>

                        }

                    </div>
                    <Button className='mt-2' onClick={handlePush}>Back to Learning Path</Button>
                </div>
            </div>
            </ScrollArea>
        </div>
    );
};

export default LpCompleted;
