import React from "react";
import Modal from "react-bootstrap/Modal";
import Stripe from "../../../../../auth/stripePayment";
import NewStripeElement from '../../../../../auth/newstripeelment'
import NewStripe from "../../../../../auth/newstripeelment";


function MyVerticallyCenteredModal(props) {

    return (
        <Modal  className="custom-modal1 confirmation-popup stripe-popup"
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
        >
            <Modal.Body>
                <div className="stripe-custom-design">
                    {/*<Stripe handleCreatePlan={props.handleCreatePlan} amount={props.amount} handleGoNext={() => props.onHide()}/>*/}
                    <NewStripe plan ={props.plan} amount={props.amount} coupon={props.coupon} handleCreatePlan={props.handleCreatePlan}  handleGoNext={() => props.onHide()}/>
                    <div className="m-t-20 d-flex justify-content-center"><button type="button" className="btn border-btn white-btn"  onClick={() => props.onHide()}>No, I
                        need to recheck
                    </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default MyVerticallyCenteredModal
