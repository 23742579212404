import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import FilterImg from '~/assets/images/icons/filter.svg';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Select, { components } from "react-select" ;
import { useDispatch, useSelector } from "react-redux";
import { getDraftSurveysList, getManagersList, deleteSurvey, handleDraftPagination, handleDraftPreviousPagination, getDeletedSurvey } from '~/modules/surveys/services';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Col from "react-bootstrap/Col";
import { getInstitutionType } from "../../../../auth/services";
import CustomPagination from "../../../../../shared/components/pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

function PublishedSurveys() {
    const dispatch = useDispatch();
    const managers = useSelector(state => state.surveys.manager_list?.response || []);
    const response = useSelector(state => state.surveys.surveys_draft_list?.response);
    const restoreresponse = useSelector((state) => state.surveys.survey_restore?.response);
    const surveyCategory = useSelector(
        (state) => state?.surveys?.survey_category?.response
    );
    const [activePage, setActivePage] = useState(1);
    const { surveysData, paginatedSurveys } = useSelector(state => state.surveys);
    const history = useHistory();
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('');
    const [surveys, setSurveys] = useState(response);
    const { user } = useSelector(state => state.auth)
    const [count, setCount] = useState(1)
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [createdby, setCreatedby] = useState('');
    const [dataProgress, setDataProgress] = useState(false)
    const loading = useSelector(state => state.surveys.surveys_draft_list?.loading);
    const [modalShow, setModalShow] = React.useState(false);
    const [selectrow, setSelectRow] = useState(null);
    const [category, setCategory] = useState([]);
    const [categorySelected, setCategorySelected] = useState('');
    const [flag, setFlag] = useState(false)


    useEffect(() => {
        if (loading) { setDataProgress(true) }

        if (!loading) { setDataProgress(false) }
    }, [loading])



    useEffect(() => {
        setSearch('');
        setDateRange([null, null]);
        setCreatedby('');
        setActivePage(1)
        // dispatch(getDraftSurveysList('','','','False','','',''));
        // dispatch(
        //     getInstitutionType(function (response) {
        //         if (response) {
        //             let newMap = response?.results?.map((el) => {
        //                 return {
        //                     label: el?.name,
        //                     value: el?.id,
        //                 };
        //             });
        //             setCategory(newMap);

        //         }
        //     })
        // );
    }, [])

    useEffect(() => {
        setSurveys(response);
    }, [response]);

    useEffect(() => {

        if (startDate !== null && endDate !== null) {
            setActivePage(1)
            dispatch(getDraftSurveysList(search, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd'), "False", createdby === '' ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.id, ''));
        }

    }, [startDate, endDate]);

    useEffect(() => {
            setActivePage(1)
            dispatch(getDraftSurveysList(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'),  endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), "False", createdby === '' ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.id, ''));

    }, [search, createdby, categorySelected]);

    useEffect(() => {
        if (user?.is_admin_institution) {
            setFlag(true)
        }
    }, [])

    useEffect(() => {
        setSearch('');
        setDateRange([null, null]);
        setCategorySelected('');
        setCreatedby('');
        setActivePage(1);
    },[restoreresponse]);


    const reset = () => {
        setSearch('');
        setDateRange([null, null]);
        setCategorySelected('');
        setCreatedby('');
        setActivePage(1);
        dispatch(getDraftSurveysList('','','','False','','',''));
    };

    const handleDeleteSurvey = (row) => {
        setDataProgress(true);
        dispatch(deleteSurvey(row, () => {
            const pgnumber = response?.results?.length > 1 ?  activePage : activePage > 1 ? activePage - 1 : '';
            dispatch(getDraftSurveysList(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'),"False", createdby === '' ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.id, pgnumber));
            dispatch(getDeletedSurvey('','','','','',''));
            setActivePage(pgnumber);
            setDataProgress(false);
        }));
    };

    const onCategorySelect = (val) => {
        setCategorySelected(val);
    };

    function DeleteVerticallyCenteredModal(props) {

        return (
            <Modal className="custom-modal confirmation-popup  delete-confirmation inner-popup"
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Body>
                    <div className="modal-content-wrap text-center">
                        <h3 className="modal-title">Are you sure you want to delete this survey?</h3>
                        
                        {/* <p>Are You Sure To Delete The Members? </p> */}
                        <div className="d-flex justify-content-center buttons-wrap double-btn">

                            <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                                need to recheck
                            </button>
                            <Link className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes,
                                Continue</Button></Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }


    const handleNext = () => {
        if (surveys?.next) {
            setActivePage((prevState) => prevState + 1);
            dispatch(handleDraftPagination(surveys?.next));
        } else {
            return false;
        }

    };

    const searchInput = (e) => {
        setSearch(e.target.value);
    };

    const onSelect = (val) => {
        setCreatedby(val);
    };

    const handlePrevious = () => {
        if (surveys?.previous) {
            setActivePage((prevState) => prevState - 1);
            dispatch(handleDraftPreviousPagination(surveys?.previous));

        } else {
            return false;
        }

    };

    const handleIndex = (index) => {
        dispatch(getDraftSurveysList(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'),"False", createdby === '' ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.id, index));
        setActivePage(index);
    }

    const tableColumns = [
        {
            name: 'Survey Name',
            cell: row => <Link to={`/surveydetails/${row?.id}`}>{row.name}</Link>,
            sortable: true,
            selector: 'name',
            center: false,
            grow: 5
        },
        {
            name: 'Created By',
            selector: 'creator_name',
            sortable: true,
            center: false,
            grow: 3,

        },
        {
            name: 'Due Date',
            cell: row => row?.expiry_date ? row?.expiry_date : 'Not Available',
            sortable: true,
            selector: 'expiry_date',
            center: false,
            grow: 3,

        }
        ,


        {
            name: 'Action',
            selector: 'Action',
            sortable: false,
            center: false,
            grow: 2,
            cell: (row, index) =>
                <div data-tag="allowRowEvents" className='d-flex' >
                    <div className="action-icons-cell-wrap d-flex">
                        <div className="action-icon-item fill-icon" onClick={() => history.push(`/survey-edit/${row.id}`)}>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="InfoText">
                                        Edit
                                    </Tooltip>
                                }
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.917" height="17.917" viewBox="0 0 17.917 17.917">
                                    <g id="_3325116_edit_icon" data-name="3325116_edit_icon" transform="translate(-1.1 -1.1)">
                                        <path id="Path_8321" data-name="Path 8321" d="M16.506,12.591v4.3a1.612,1.612,0,0,1-1.612,1.612H3.612A1.612,1.612,0,0,1,2,16.894V5.612A1.612,1.612,0,0,1,3.612,4h4.3" transform="translate(0 -0.388)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                                        <path id="Path_8322" data-name="Path 8322" d="M16.059,2l3.223,3.223-8.059,8.059H8V10.059Z" transform="translate(-1.165 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" /> </g>
                                </svg>
                            </OverlayTrigger>
                        </div>
                        <div className="action-icon-item fill-icon" onClick={e => { setModalShow(true); setSelectRow(row) }}>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="InfoText">
                                        Delete
                                    </Tooltip>
                                }
                            >
                                <svg id="icons_Q2" data-name="icons Q2" xmlns="http://www.w3.org/2000/svg" width="17.49" height="19.331" viewBox="0 0 17.49 19.331">
                                    <path id="Path_8323" data-name="Path 8323" d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z" transform="translate(-4.999 -3)" fill="#fff" />
                                    <path id="Path_8324" data-name="Path 8324" d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z" transform="translate(-7.261 -9.477)" fill="#fff" /> </svg>
                            </OverlayTrigger>
                        </div>



                    </div>
                    {/*<Link to={`/edit_survey/${row.id}`} className="btn border-btn link-btn mx-2">*/}
                    {/*    Edit</Link>*/}
                    {/*<Link  onClick = {e =>handleDeleteSurvey(row)}className="btn border-btn link-btn">*/}
                    {/*    Delete</Link>*/}
                </div>
            ,
        },
    ]

    const CaretDownIcon = () => {
        return (
           <div className="filter-icon-select">
           <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
           <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
           </svg>
           </div>
        );
      };
      
      const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownIcon />
          </components.DropdownIndicator>
        );
      };
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
    return (
        <div className="available-course-wrap">

            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="datatable-filter-left-box d-flex align-items-center">
                    <div className="datepicker-box p-relative">
                        <DatePicker
                            placeholderText={'Created date'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        <span className="datepickericon">
                            <i className="fa fa-calendar"></i>
                        </span>
                    </div>

                    <div className="dt-filter-left d-flex justify-content-between align-items-center">
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="InfoText">
                                    {createdby ? createdby?.label :  "Author"}
                                </Tooltip>
                            }
                        >
                        <div className="custom-react-select">

                            <Select options={managers || []} getOptionLabel={(option) => option.label.length > 1 ? option.label : option.email_label}
                            getOptionValue={(option) => option.id }
                            value={createdby} onChange={onSelect} placeholder="Author" components={{ DropdownIndicator }} />
                        </div>
                        </OverlayTrigger>
                        {/* <button type="button" className="iconbutton"><img src={FilterImg} /></button> */}
                    </div>
                    {/* {flag ?
                        <div className="dt-filter-left d-flex justify-content-between align-items-center">
                            <div className="custom-react-select">
                                <Select options={category || []}
                                    value={categorySelected} onChange={onCategorySelect} placeholder="Category" components={{ DropdownIndicator }} />
                            </div>

                          
                        </div>
                        : ''
                    } */}
                      <div className="dt-filter-left d-flex justify-content-between align-items-center">
                        <div className="custom-react-select">
                            <Select options={surveyCategory?.results || []} getOptionLabel={(option) => option.name}   getOptionValue={option => option.id}

                                value={categorySelected} onChange={onCategorySelect} placeholder="Category" components={{ DropdownIndicator }} />
                            </div>
                        </div>

                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative search-icon-left">
                    <button className="iconbutton search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                            <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                        </svg>
                    </button>
                        <input type="text" placeholder="Search using keyword"
                            value={search} onChange={searchInput}
                            className="form-control input-item" />
                        <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                    </div>
                    <OverlayTrigger
                overlay={
                    <Tooltip id="InfoText">
                          Help
                    </Tooltip>
                }
            >
                <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </OverlayTrigger>
                </div>
            </div>

            { loading && loading ? (
                <>
                <div className="loaderCourses">
                 <Spinner animation="border" variant="danger" />
                </div>
              </>

            ) : (

                <>
                 
                  {surveys?.results?.length > 0 ? (

                       <div className="datatble-wrap">
                    <DataTable
                        data={surveys?.results}
                        columns={tableColumns}
                        center={true}
                        showPaginationBottom={false}
                        noHeader={true}
                        progressPending={dataProgress}
                        // progressComponent={
                        //     <div >
                        //         <Spinner animation="border" variant="warning" />
                        //     </div>
                        // }
                    />
                     </div> 

                  ) : ( 


                    <>
                        {search?.length > 0 || createdby || categorySelected || dateRange[0] || dateRange[1]  ? (

                           <span className='noData'>No matching results found</span>
                        ) : (
                            <span className='noData text-center'>You have no draft Surveys</span>
                        )}
                    </>

                    )}

                {!dataProgress ?  
                    <>
                     {surveys?.count > 12 ? 
                         <CustomPagination response={surveys} activePage={activePage} handleNext={handleNext} handlePrevious={handlePrevious} handleIndex={handleIndex} />
                         :
                         ""

                      }
                    </>  : ''
                }
                </>

            )}

               

                <DeleteVerticallyCenteredModal
                    show={modalShow}
                    handleSubmit={() => { handleDeleteSurvey(selectrow); setModalShow(false) }}
                    onHide={() => setModalShow(false)}
                // onExited={ModalExit}
                />

<Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
<Modal.Body>
                    <div className="module-modal-header my-4"><h4>Surveys are developed and published by you and others in your organization here.</h4></div>
                    <ol className="info-list">
                        <li><span>The “Create New Survey” button enables managers to create a survey.</span></li>
                        <li><span>After a survey is published, it will appear under the “Published Surveys” tab.</span></li>
                        <li><span>Surveys can be: 1&#41;	 previewed, 2&#41;	 deleted, or 3&#41;	 cloned. </span></li>
                        <li><span>Published surveys cannot be edited. To duplicate a survey, clone it and the survey will appear under the “Drafts” tab. </span></li>
                        <li><span>Send out the survey by selecting the “Copy Survey Link” under the “Action” column, then paste and send the link to respondents via email, interoffice communication, etc. </span></li>
                        <li><span>Note that the identities of the survey respondents will not be saved, survey managers will only see their selected demographic information.</span></li>
                        <li><span>Select the “Reports” icon in the “Action” column to view real time survey results &#40;note: refresh the window for most up-to-date results&#41;	.</span></li>
                        <li><span>Search for a survey using the: 1&#41;	 Created Date, 2&#41;	 Author, 3&#41;	 Category, 4&#41;	status filters, or 4&#41;	 by keyword. </span></li>
                    </ol>



                </Modal.Body>


                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
        </div>
    );
};
export default PublishedSurveys;
