import React, {useEffect, useState} from 'react';
import AddModuleFormatted from './components/addmoduleformatted';

const AddContent = (props) => {
    const [state, setState] = useState({
        show: false,
    });

    return (
        <div className="coursedetails-wrap">
            <div className="course-content-sec">
                {/*<AddModule />*/}
                <div className="addmodule-repeat">
                    <AddModuleFormatted parentState={props.parentState} courseId={props?.courseId}
                                        course={props?.course} urlId={props?.urlId}
                    /></div>
            </div>
        </div>
    );
};
export default AddContent;
