import React, {useState, useEffect} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from 'date-fns';
import Select from 'react-select';
import CourseDummy from '~/assets/images/course_dummy.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
    getDraftCoursesAdminList,
    handlePaginationDraftAdminCourse,
    handlePreviousPaginationDraftAdminCourse,
    deleteCourse
} from '../../../services';
import 'react-toastify/dist/ReactToastify.css';

import {useDispatch, useSelector} from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import Pagination from "react-bootstrap/Pagination";
import {useHistory} from "react-router-dom";
import {getNotifications} from "../../../../Notifications/services";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const DraftCourses = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [search, setSearch] = useState('');
    const response = useSelector(state => state?.course?.course_listing_admin_draft?.response);
    const loading = useSelector(state => state?.course?.course_listing_admin_draft?.loading);
    const [state, setState] =useState({
        courses:response,
        count:response?.count,
        paginationItem:[]
    })
    const [activePage, setActivePage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [count,setCount] = useState(0)
    useEffect(() => {
        setSearch('');
        setDateRange([null, null]);
        dispatch(getDraftCoursesAdminList(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate, "False"));
        dispatch(getNotifications())
    }, []);
    useEffect(()=>{
        if(count > 0 && dateRange[0] == null){
            dispatch(getDraftCoursesAdminList("", startDate === null ? '' : '', endDate === null ? '' : '', "False"));

        }
    },[count])
    useEffect(() => {
        const count = response?.count
        const perPage =12;
        const buttonsCount = Math.ceil(count/perPage);

        let items =[]
        for (let number = 1; number <= Number(buttonsCount); number++) {
            items.push(
                <Pagination.Item onClick={()=>handleIndex(number)} key={number} active={number == activePage}>
                    {number}
                </Pagination.Item>,
            );
        }
        setState({...state, courses:response,count: response?.country,paginationItem:items});
    }, [response]);
    useEffect(() => {

        if (startDate !== null && endDate !== null) {

            dispatch(getDraftCoursesAdminList(search, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd'), "False"));
        }

    }, [startDate, endDate]);


    const searchText = (e) => {
        setSearch(e.target.value);
        dispatch(getDraftCoursesAdminList(e.target.value, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), "False"));
    };

    const reset = () => {
        setSearch('');
        setDateRange([null, null]);
        dispatch(getDraftCoursesAdminList("", startDate === null ? '' : '', endDate === null ? '' : '', "False"));
    };

    const handleNext = () => {
        if (state.courses?.next) {
            setActivePage(state.courses?.next?.charAt(state.courses?.next?.length-1));
            dispatch(handlePaginationDraftAdminCourse(state.courses?.next));
        } else {
            return false;
        }

    };
    const handlePrevious = () => {
        if (state.courses?.previous) {
            setActivePage(1);
            dispatch(handlePreviousPaginationDraftAdminCourse(state.courses?.previous));

        } else {
            return false;
        }
    };
    const handleIndex = (index) =>{
        if(index == 1){

            dispatch(getDraftCoursesAdminList(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate, "False"));
        }else{

            dispatch(getDraftCoursesAdminList(null,null,null,'False', index));
        }
        setActivePage(index);
    }

    const handleDeleteCourse = (row) => {
        dispatch(deleteCourse(row));
        setTimeout(()=>{
            dispatch(getDraftCoursesAdminList(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate, "False", createdby === '' ? '' : createdby?.id));
        },500)
    };
    const handleEdit  =(id)=>{
        history.push(`/editcourse/${id}`)
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="available-course-wrap">
            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="datatable-filter-left-box d-flex align-items-center">
                    <div className="datepicker-box p-relative">
                        <DatePicker
                            placeholderText={'Created date'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setCount(count+1)
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        <span className="datepickericon">
                     <i className="fa fa-calendar"></i>
                  </span>
                    </div>


                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative search-icon-left">
                    <button className="iconbutton search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216"
                             viewBox="0 0 19.216 19.216">
                            <path className="a"
                                  d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"/>
                        </svg>
                    </button>
                        <input type="text" placeholder="Search using keyword"
                               value={search} onChange={searchText}
                               className="form-control input-item"/>
                        <span className="search-close-icon" style={{cursor: "pointer"}} onClick={reset}>×</span>
                    </div>
                    <OverlayTrigger
                overlay={
                    <Tooltip id="InfoText">
                          Help
                    </Tooltip>
                }
            >
                <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </OverlayTrigger>
                </div>
            </div>
            <div className="courseslisting">
                <Row>
                    {loading && loading ?
                        (
                            <>
                                <div className='loaderCourses'>
                                    <Spinner animation="border" variant="danger"/>
                                </div>

                            </>


                        ) : (
                            <>

                                {!loading && state.courses && state.courses && state.courses?.results && state.courses?.results?.length ? state.courses?.results?.map((el, index) => (
                                        <Col md={4} lg={3} className="m-b-30 course-box-outer">

                                            <div className="common-card coursecard">
                                                <div className="course-box-top">
                                                    <div className="course-listing-img">
                                                        <img src={el?.image ? el?.image :CourseDummy} alt=""/>
                                                    </div>
                                                    <div className="common-card-detail-wrap box-listing">
                                                        <h4>{el?.name}</h4>
                                                        <p>{el?.description}</p>
                                                    </div>
                                                    <div className="coommon-card-det-number d-flex justify-content-between">
                                                        <span className="common-card-oth-det-left">Modules <span
                                                            className="det-numb">{el?.modules_count}</span> </span>
                                                        <span className="common-card-oth-det-left">Quizzes <span
                                                            className="det-numb">{el?.quizzes_count}</span> </span>
                                                    </div>
                                                </div>
                                                <div className="common-card-footer border-top-footer">
                                                    <div
                                                        className="common-card-footer-cont no-border d-flex justify-content-between align-items-center">
                                                        {/*<div className="grp-name">Group name</div>*/}
                                                        {el?.is_owned_by_admin ?
                                                            <></>
                                                            :
                                                            <>
                                                                <button type="button" onClick={e=>handleDeleteCourse(el)} className="btn border-btn">Delete</button>
                                                                <button type="button" className="btn border-btn" onClick={()=>handleEdit(el?.id)}>Edit</button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                        </Col>
                                    ))
                                    :
                                    <span className='noData'>No data available</span>

                                }

                            </>


                        )

                    }

                </Row>
                {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response &&
                <Row className='justify-content-center'>
                    <Pagination size="sm" className="custom-pagination">
                        <Pagination.First disabled={!state?.courses?.previous} className={`${state?.courses?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious}/>
                        {state.paginationItem}
                        <Pagination.Last disabled={!state?.courses?.next} onClick={handleNext} className={`${state?.courses?.next ? 'paginationStyle' :''} `}/>

                    </Pagination>
                </Row>

                }
            </div>
            <Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
               <Modal.Body>
                    <ol className="info-list">
                        <li>
                            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </span>
                        </li>
                        <li>
                            <span>dummy text of the printing and typesetting industry. Lorem Ipsum hass </span>
                        </li>
                        <li>
                            <span>Lorem Ipsum is simply dummy text of the printing and user_type</span>
                        </li>
               
                    </ol>
                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
        </div>
    );
};
export default DraftCourses;
