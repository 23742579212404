
import React from 'react';

function TeamIcon() {
return (
   <React.Fragment>
<svg xmlns="http://www.w3.org/2000/svg" width="22.003" height="22.68" viewBox="0 0 22.003 22.68">
   <g transform="translate(0.2 -3.723)">
      <g transform="translate(1.8 15.852)">
         <path className="a" d="M42.535,241.231a3.15,3.15,0,1,0,3.15,3.15A3.131,3.131,0,0,0,42.535,241.231Zm0,5.4a2.25,2.25,0,1,1,2.25-2.25A2.229,2.229,0,0,1,42.535,246.632Z" transform="translate(-39.385 -241.231)"/>
      </g>
      <g transform="translate(0 21.252)">
         <path className="a" d="M4.951,359.385A4.936,4.936,0,0,0,0,364.336H.9a4.051,4.051,0,1,1,8.1,0h.9A4.936,4.936,0,0,0,4.951,359.385Z" transform="translate(0 -359.385)"/>
      </g>
      <g transform="translate(13.502 15.852)">
         <path className="a" d="M298.535,241.231a3.15,3.15,0,1,0,3.15,3.15A3.131,3.131,0,0,0,298.535,241.231Zm0,5.4a2.25,2.25,0,1,1,2.25-2.25A2.229,2.229,0,0,1,298.535,246.632Z" transform="translate(-295.385 -241.231)"/>
      </g>
      <g transform="translate(11.702 21.252)">
         <path className="a" d="M260.951,359.385A4.937,4.937,0,0,0,256,364.336h.9a4.051,4.051,0,1,1,8.1,0h.9A4.936,4.936,0,0,0,260.951,359.385Z" transform="translate(-256 -359.385)"/>
      </g>
      <g transform="translate(7.651 3.923)">
         <path className="a" d="M170.535,4.923a3.15,3.15,0,1,0,3.15,3.15A3.131,3.131,0,0,0,170.535,4.923Zm0,5.4a2.25,2.25,0,1,1,2.25-2.25A2.228,2.228,0,0,1,170.535,10.324Z" transform="translate(-167.385 -4.923)"/>
      </g>
      <g transform="translate(5.851 9.324)">
         <path className="a" d="M132.951,123.077A4.936,4.936,0,0,0,128,128.028h.9a4.051,4.051,0,1,1,8.1,0h.9A4.936,4.936,0,0,0,132.951,123.077Z" transform="translate(-128 -123.077)"/>
      </g>
   </g>
</svg>
</React.Fragment>
);
}
export default TeamIcon;