import React, { useState, useEffect } from 'react';
import ProfileDummy from '~/assets/images/user.png';
import CameraIcon from '~/assets/images/icons/feather-camera.svg';
import { Modal } from 'react-bootstrap';
import Cropcontent from './cropcontent';
import { useDispatch, useSelector } from 'react-redux';
import {getProfile} from '../../../../services';
import Spinner from "react-bootstrap/Spinner";

const ProfilePic = () => {
   const dispatch = useDispatch();
   const [show, setShow] = useState(false);
   const response = useSelector(state => state.profile.profile_details.response);
   const responseLoading = useSelector(state => state.profile.profile_details.loading);
   const imageResponse = useSelector(state => state.profile.profile_image.response);
   const imageResponseLoading = useSelector(state => state.profile.profile_image.loading);
   const [profilePic, setProfilePic] = useState(response.profile_image);
   const { user } = useSelector(state => state.auth);

   useEffect(() => {

      dispatch(getProfile(user?.user_id, user?.token));
   },[imageResponse]);
   useEffect(()=>{
      if(imageResponse.profile_image){
         setProfilePic(`${imageResponse.profile_image}?${Date.now()}`);
      }else {
         setProfilePic(response.profile_image);
      }

   },[response])

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   return (
      <div className="whitebox shadow-box profile-stat-box">
         <div className="profile-stat-contentbox d-flex">
            <div className="profile-img">
               {imageResponseLoading  || responseLoading?
                   <Spinner animation="border" variant="danger"/>
                   :
                   <>
                   <img src={profilePic != null ? profilePic : ProfileDummy} />
                  <span className='prof-edit-icon' onClick={handleShow}>
                  <img src={CameraIcon} />
                  </span>
                   </>
               }

            </div>
            <div className="profile-det-text-wrap">
               <h4>{response.first_name} &nbsp; {response.last_name}</h4>
               <p>{response.mobile_number}</p>
               <p>{response.email}</p>
               <p>{response.institution_name}</p>
            </div>
         </div>
         <Modal show={show} onHide={handleClose} className='cropModal common-modal inner-popup add-module-content-modal'>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
               <Cropcontent onHide={handleClose} />
            </Modal.Body>
         </Modal>
      </div>
   );
};

export default ProfilePic;
