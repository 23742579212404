import { createAction, handleActions } from 'redux-actions';
import { _getSurveyDescription, _getSurveyQuestions, _getSurveyDemographics, _getSurveyResults } from '~shared/httpService/api';
import ActionTypes from '~shared/constants/actionTypes';

const initialState = {
  report_description: { loading: false, response: {}, hasError: false, error: {} },
  report_questions: { loading: false, response: {}, hasError: false, error: {} },
  report_demographics: { loading: false, response: {}, hasError: false, error: {} },
  report_survey_results: { loading: false, response: {}, hasError: false, error: {} },

};

export const getSurveyDescriptionStart = createAction(ActionTypes.GET_SURVEY_DESCRIPTION);
export const getSurveyDescriptionSuccess = createAction(ActionTypes.GET_SURVEY_DESCRIPTION_SUCCESS, response => response);
export const getSurveyDescriptionError = createAction(ActionTypes.GET_SURVEY_DESCRIPTION_ERROR, error => error);

export const getSurveyDescription = (surveyId) => (dispatch) => {
  dispatch(getSurveyDescriptionStart());
  return _getSurveyDescription(surveyId).then((response) => {
    dispatch(getSurveyDescriptionSuccess(response));
  }).catch((error) => {
    dispatch(getSurveyDescriptionError(error));
  });
};

export const getSurveyQuestionsStart = createAction(ActionTypes.GET_SURVEY_QUESTIONS);
export const getSurveyQuestionsSuccess = createAction(ActionTypes.GET_SURVEY_QUESTIONS_SUCCESS, response => response);
export const getSurveyQuestionsError = createAction(ActionTypes.GET_SURVEY_QUESTIONS_ERROR, error => error);

export const getSurveyQuestions = (surveyId) => (dispatch) => {
  dispatch(getSurveyQuestionsStart());
  return _getSurveyQuestions(surveyId).then((response) => {
    dispatch(getSurveyQuestionsSuccess(response));
  }).catch((error) => {
    dispatch(getSurveyQuestionsError(error));
  });
};

export const getSurveyDemographicsStart = createAction(ActionTypes.GET_SURVEY_DEMOGRAPHICS);
export const getSurveyDemographicsSuccess = createAction(ActionTypes.GET_SURVEY_DEMOGRAPHICS_SUCCESS, response => response);
export const getSurveyDemographicsError = createAction(ActionTypes.GET_SURVEY_DEMOGRAPHICS_ERROR, error => error);

export const getSurveyDemographics = (surveyId) => (dispatch) => {
  dispatch(getSurveyDemographicsStart());
  return _getSurveyDemographics(surveyId).then((response) => {
    dispatch(getSurveyDemographicsSuccess(response));
  }).catch((error) => {
    dispatch(getSurveyDemographicsError(error));
  });
};

export const getSurveyResultsStart = createAction(ActionTypes.GET_SURVEY_RESULTS);
export const getSurveyResultsSuccess = createAction(ActionTypes.GET_SURVEY_RESULTS_SUCCESS, response => response);
export const getSurveyResultsError = createAction(ActionTypes.GET_SURVEY_RESULTS_ERROR, error => error);

export const getSurveyResults = (surveyId) => (dispatch) => {
  dispatch(getSurveyResultsStart());
  return _getSurveyResults(surveyId).then((response) => {
    dispatch(getSurveyResultsSuccess(response));
  }).catch((error) => {
    dispatch(getSurveyResultsError(error));
  });
};

const reducer = handleActions({
  
  //REPORT_DESCRIPTION
  [ActionTypes.GET_SURVEY_DESCRIPTION]: (state) => ({
    ...state, report_description: {
      ...state.report_description,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_SURVEY_DESCRIPTION_SUCCESS]: (state, action) => ({
    ...state,
    report_description: {
      ...state.report_description,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_SURVEY_DESCRIPTION_ERROR]: (state, action) => ({
    ...state,
    report_questions: {
      ...state.report_questions,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

   //REPORT_QUESTIONS
   [ActionTypes.GET_SURVEY_QUESTIONS]: (state) => ({
    ...state, report_questions: {
      ...state.report_questions,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_SURVEY_QUESTIONS_SUCCESS]: (state, action) => ({
    ...state,
    report_questions: {
      ...state.report_questions,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_SURVEY_QUESTIONS_ERROR]: (state, action) => ({
    ...state,
    report_questions: {
      ...state.report_questions,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

   //REPORT_DEMOGRAPHICS
   [ActionTypes.GET_SURVEY_DEMOGRAPHICS]: (state) => ({
    ...state, report_demographics: {
      ...state.report_demographics,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_SURVEY_DEMOGRAPHICS_SUCCESS]: (state, action) => ({
    ...state,
    report_demographics: {
      ...state.report_demographics,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_SURVEY_DEMOGRAPHICS_ERROR]: (state, action) => ({
    ...state,
    report_demographics: {
      ...state.report_demographics,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

   //REPORT_SURVEY_RESULTS
   [ActionTypes.GET_SURVEY_RESULTS]: (state) => ({
    ...state, report_survey_results: {
      ...state.report_survey_results,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_SURVEY_RESULTS_SUCCESS]: (state, action) => ({
    ...state,
    report_survey_results: {
      ...state.report_survey_results,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_SURVEY_RESULTS_ERROR]: (state, action) => ({
    ...state,
    report_survey_results: {
      ...state.report_survey_results,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

}, initialState);

export default reducer;