import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LearningPathAssign from '../components/addtolearningpath'
import AddDetails from '../components/adddetails';
function LearningPathBuilder (){
   return (
    <div className="tab-wrapper">
    <Tabs defaultActiveKey="AddDetails" id="uncontrolled-tab-example" className="m-b-30">
       <Tab eventKey="AddDetails" title="Add Details">
       <AddDetails/>
       </Tab>
       <Tab eventKey="Assign" title="Assign">
      <LearningPathAssign/>
       </Tab>

    </Tabs>
 </div>
   );
};
export default LearningPathBuilder;