import React  ,{useState,useEffect} from 'react'
import {
  ToggleButton,
  ToggleButtonGroup
} from "react-bootstrap";

function LikertScale(props) {
  const [state, setState] = useState({
    stateOptions: [],

})

const [invalid,setInvalid]=useState(false)

const mystyle = {
  border:'1px solid #ce002a',
  borderRadius:'5px',
  padding: "10px",

};

useEffect(() => {

    setState({ ...state, stateOptions: props?.options })

}, [props?.options])

const handleSelection=(e)=>{

var myValues = state?.stateOptions;
let valueAtIndex = myValues[e]
setFieldValue(quesId, valueAtIndex?.choice)

}

const { name, errors, options, setFieldValue, values, mandatory, quesNumber,quesIndex,quesId, errorName,touched, ...rest } = props;


 
  return (
    <div className="survey-box whitebox shadow-box m-b-30">
    <h4 className="survey-qstn"><span>{Number( quesNumber) }.</span>{props?.name ?props?.name  : ''}
    {
                mandatory ?  <span className="mandatory-star">*</span> :''
            }
    
    </h4>

    <div className="likertscale-wrap"  style={!!errors && !!errors[errorName] && !!errors[errorName] && !!touched && !!touched[errorName] ? mystyle : {}} >
      <ToggleButtonGroup  onChange={e => handleSelection(e)} type="radio" name={quesId} className="custom-toggle-radio"
       isInvalid={!!errors && !!errors[errorName] && !!errors[errorName] && !!touched && !!touched[errorName]
        ? ' is-invalid'
        : ''}

      >

      { state?.stateOptions.map((option, index) => {
                return( <ToggleButton key={index} value={index}>
                    <div className="d-flex justify-content-between custom-radio-survey align-items-center">
                        <span>{option?.choice}</span>
                        <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
                    </div>
                </ToggleButton>
                )
            })

            }

        {/* <ToggleButton value={1}>
          <div className="d-flex justify-content-between custom-radio-survey align-items-center">
            <span>Every Day</span>
            <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
          </div>
        </ToggleButton>
        <ToggleButton value={2}> <div className="d-flex justify-content-between custom-radio-survey align-items-center">
          <span> 2 times in a week</span>
          <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
        </div></ToggleButton>
        <ToggleButton value={3}> <div className="d-flex justify-content-between custom-radio-survey align-items-center">
          <span>During Break time</span>
          <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
        </div></ToggleButton>
        <ToggleButton value={4}> <div className="d-flex justify-content-between custom-radio-survey align-items-center">
          <span>I don't Charge</span>
          <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
        </div></ToggleButton> */}
      </ToggleButtonGroup>

    </div>
    </div>
  )
}

export default LikertScale
