import {createAction, handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {
    _postSupport,
    _getSupportContact
} from '~shared/httpService/api';
import { ToastContainer, toast } from 'react-toastify';

const initialState = {

    supportData: { loading: false, response: {}, hasError: false, error: {} },
    get_contact_support: { loading: false, response: {}, hasError: false, error: {} },
   


};



export const supportStart = createAction(ActionTypes.GET_SURVEYS_LISTING);
export const supportSuccess = createAction(ActionTypes.GET_SURVEYS_LISTING_SUCCESS, response => response);
export const supportError = createAction(ActionTypes.GET_SURVEYS_LISTING_ERROR, error => error);

export const supportPost = (data) => (dispatch) => {
    dispatch(supportStart());
    return _postSupport(data).then((response) => {

        dispatch(supportSuccess (response));
        if (response) {
            toast.success('Thanks For Contacting Us')
              }
    }).catch((error) => {
        dispatch(supportError (error));
       
        if(error) {
             toast.success('Thanks For Contacting Us')
           
        }
    });
};


export const  get_Support_ContactStart = createAction(ActionTypes.GET_SUPPORT_CONTACT);
export const  get_Support_ContactSuccess = createAction(ActionTypes.GET_SUPPORT_CONTACT_SUCCESS, response => response);
export const  get_Support_ContactError = createAction(ActionTypes.GET_SUPPORT_CONTACT_ERROR, error => error);

export const  get_Support_Contact = () => (dispatch) => {
    dispatch(get_Support_ContactStart());
    return _getSupportContact().then((response) => {

        dispatch(get_Support_ContactSuccess (response));
       
    }).catch((error) => {
        dispatch(get_Support_ContactError (error));
       
        if(error) {
            toast.error('Something went wrong')
           
        }
    });
};




const reducer = handleActions({
//post support
[ActionTypes.GET_SURVEYS_LISTING]: (state) => ({
    ...state,  
    supportData: {
        ...state. supportData,
        loading: false, hasError: false, error: {}
    }
}),
[ActionTypes.GET_SURVEYS_LISTING_SUCCESS]: (state, action) => ({
    ...state,    
    supportData: {
        ...state.  supportData,
        response: action.payload, loading: false, hasError: false, error: {}
    },
   
}),
[ActionTypes.GET_SURVEYS_LISTING_ERROR]: (state, action) => ({
    ...state,   
    supportData: {
        ...state.  supportData,
        response: {}, loading: false, hasError: false, error: action.payload
    },
    
}),




// GETt contact support

[ActionTypes.GET_SUPPORT_CONTACT]: (state) => ({
    ...state,  
    get_contact_support: {
        ...state.  get_contact_support,
        loading: true, hasError: false, error: {}
    }
}),
[ActionTypes.GET_SUPPORT_CONTACT_SUCCESS]: (state, action) => ({
    ...state,    
    get_contact_support: {
        ...state.   get_contact_support,
        response: action.payload, loading: false, hasError: false, error: {}
    },
   
}),
[ActionTypes.GET_SUPPORT_CONTACT_ERROR]: (state, action) => ({
    ...state,   
    get_contact_support: {
        ...state.  get_contact_support,
        response: {}, loading: false, hasError:true, error: action.payload
    },
    
}),


}, initialState);

export default reducer;
