import React, { useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import MyLearning from '../components/myLearning'
import LearnerCourseListing from './components/courseslisting'
import LearnerCourseListingWithStatus from './components/courseslistingWithStatus'
import LearnerLearningPathDetails from './components/learnerslearningpathdetails';
import LearnerProfilePic from './components/profilepic/container/index';
import {useLocation} from "react-router-dom";
import { useSelector } from 'react-redux';
import {Helmet} from "react-helmet";

function LearnerDashboard(props) {
   const location = useLocation();
   const { hideLpQuizes } = useSelector((state) => state.settings);
   const [key, setKey] = useState('Courses');

   useEffect(()=>{
      if(location?.state?.category){
           setKey(location?.state?.category);
      }
    },[location]);

   return (
      <div>
          <Helmet>
                <title>Assignments - Cultural Engagement  </title>
            </Helmet>
      <div className="tab-wrapper">
      {!hideLpQuizes ? (
         <Tabs defaultActiveKey="Courses" id="uncontrolled-tab-example" className="m-b-30">
            <Tab eventKey="MyLearning" title="Learning Paths">
               <LearnerLearningPathDetails />
            </Tab>
           
            <Tab eventKey="Courses" title="Courses">
               <LearnerCourseListing category={props?.location?.state?.category} />
            </Tab>
         </Tabs>
      ):(
         <Tabs  activeKey={key} onSelect={(k) => setKey(k)} defaultActiveKey="Courses" id="uncontrolled-tab-example" className="m-b-30">
            <Tab eventKey="Courses" title="New/In Progress">
               <LearnerCourseListingWithStatus category='New' />
            </Tab>
            <Tab eventKey="Completed" title="Completed">
               <LearnerCourseListingWithStatus category="Completed" />
            </Tab>
            <Tab eventKey="Failed" title="Failed">
               <LearnerCourseListingWithStatus category="Failed" />
            </Tab>
          </Tabs>
      )}
      </div>
      </div>
   );
};
export default LearnerDashboard;
