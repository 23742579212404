
import React,{useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from "formik";
import * as yup from "yup";
import { forgotSubDomain } from '~/modules/auth/services';
import {useDispatch,useSelector} from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet";

function SendReminder() {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const loading = useSelector(state => state.auth?.forgot_subdomain?.loading);
    const handleSubmit = (values) => {
        dispatch(forgotSubDomain(values,function (res){
            if(res){
                setShow(true)
            }

        }));

    };

    return (
        <div className="auth-page-wrap">
            <Helmet>
                <title>Sent remainder - Cultural Engagement  </title>
            </Helmet>
            <ToastContainer  autoClose={2000}/>
            <div className="auth-page-box d-flex">
                <div className="auth-right d-flex justify-content-center align-items-center">
                    <div className="auth-cont-box">
                        <h1 className="auth-title text-center">Send Reminder</h1>
                        <p className="text-center">Fill in your email below and we'll send you a quick reminder of the unique web address you choose when setting up your account</p>
                        <div className="auth-form-box white-box radius-box sendreminder-wrap">
                            {!show ?
                                <div className="before-submitted" style={{ "display": "block" }}>
                                    <Formik
                                        validationSchema={forgotPasswordSchema}
                                        onSubmit={handleSubmit}
                                        initialValues={{
                                            email: ''
                                        }}
                                    >
                                        {({
                                              handleSubmit,
                                              handleChange,
                                              values,
                                              errors,
                                          }) => (

                                            <Form noValidate onSubmit={handleSubmit}>
                                                <div className="form-input-wrap">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Your Email"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.email} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </div>
                                                {/*disabled={loading}*/}
                                                <Button variant="primary" type="submit" className="w-100 m-t-10" >

                                                    {loading ?
                                                        <Spinner style={{color:'white'}} animation="border" variant="warning"/>
                                                        :
                                                        'Remind Me'

                                                    }

                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                            :

                                <div className="submitted-thankyou text-center" >
                                    <div className="thankyou-icon">
                                        <i className="fas fa-check-circle"></i>
                                    </div>
                                    <p>Thanks!. We are checking our records and if you have an account you should receive an email within few minutes</p>
                                </div>

                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email('Please enter a valid email').required('Email is Required field'),
});
export default SendReminder;
