import React, {Fragment, useEffect, useState} from 'react';
import Col from "react-bootstrap/Col";
import { Modal } from 'react-bootstrap';
import CropCustom from "./cropCustom";


const CustomDropzone = ({name,values,setFieldValue,moduleErrors,moduleTouched,moduleTestsError,handleBlur,index}) => {
    const [show, setShow] = useState(false);
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleRemove = ()=>{
        setFieldValue(
            name,''
        )
    }
    const handleChange = (e)=>{
        getBase64(e?.target?.files[0]).then(
            data => setFieldValue(name,data)
        );
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Fragment>
            <div className='dzu-dropzone ' >
                {values?.modules[index]?.image ?
                    <div className=" customDropzone dzu-inputLabel">
                        <div className="course-list-selected-box-content justify-content-center">
                            <div className="course-selected-item p-relative">
                                <img className="playerProfilePic_home_tile" src={values?.modules[index]?.image ? values?.modules[index]?.image : ''}  />
                                <span className="memb-close-icon" onClick={handleRemove}>×</span>
                            </div>
                        </div>
                        {/* <span className="uploadedimageclose">×</span> */}

                    </div>

                    :
                    <label className="dzu-inputLabel" onClick={()=>setShow(true)}>Upload Image
                        {/*<input className="dzu-input" type="file"*/}
                        {/*       onChange={e =>handleChange(e)}*/}
                        {/*       value={values.modules[index].image}*/}
                        {/*       name={`modules.${index}.image`}*/}
                        {/*       accept="image/*"*/}
                        {/*       multiple=""/>*/}
                    </label>
                }

            </div>
            <Modal show={show} className='cropModal common-modal inner-popup add-module-content-modal' centered>
                <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                <Modal.Body>
                    <CropCustom moduleErrors={moduleErrors} moduleTouched={moduleTouched} name={`modules.${index}.image`} setFieldValue={setFieldValue} values={values} index={index} handleBlur={handleBlur} onHide={handleClose}  />
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default CustomDropzone;
