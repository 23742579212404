import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Logo from '~/assets/images/logo.png';
import Modal from 'react-bootstrap/Modal';
import ResetPasswordImg from '~/assets/images/resetpassword-img.svg';
import { useDispatch, useSelector } from 'react-redux';
import { signInMagicLinkWithWorkOs,clearState, } from '~/modules/auth/services';
import { Formik, } from "formik";
import * as yup from "yup";
import {Alert} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "react-bootstrap/Spinner";

function SignInWithMagicLink(props) {

    const dispatch = useDispatch();
    const { loading,response,isForgotEmailSent } = useSelector(state => state.auth);

    const handleSubmit = (values) => {
        let formData = new FormData();

        formData.append('email', values.email);   //append the values with key, value pair
        dispatch(signInMagicLinkWithWorkOs(formData,function (res){
            if(res){

           props.onHide()
                toast.success('....Redirecting Please wait')
                window.location.href = response?.magic_link
            }

        }));

    };
    return (
        <Modal className="custom-modal inner-popup letsgo-pop"
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               show={props.show} onHide={props.onHide}

        >
            <Modal.Body>
        <div className="">
            <div className="auth-page-box d-flex">
                <div className="auth-right d-flex justify-content-center align-items-center">
                    <div className="auth-cont-box">
                        <div className="auth-cont-box-header text-center">
                            <div className="auth-logo"><img src={Logo} alt="" /></div>
                            <h2 className="auth-title">Login With Magic Link</h2>
                            <p className="auth-desc">Please enter email.</p>
                        </div>
                        <div className="auth-form-box white-box radius-box">
                            <Formik
                                validationSchema={forgotPasswordSchema}
                                onSubmit={handleSubmit}
                                initialValues={{
                                    email: ''
                                }}
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                      errors,
                                  }) => (

                                    <Form noValidate onSubmit={handleSubmit}>
                                        <div className="form-input-wrap">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Your Email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </div>

                                        <div className="button-wrap cus-bg">

                                            <Button type="submit" className="w-100" disabled={loading}>
                                                {loading ?
                                                    <Spinner animation="border" variant="danger"/>
                                                    :
                                                  'Submit'
                                                }


                                               </Button>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </Modal.Body>
        </Modal>
    );
}
const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email('Please enter a valid email').required('Email is required'),
});
export default SignInWithMagicLink;
