import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import React from "react";

const  InfoModal = (props) => {

    return (
        <Modal className="custom-modal confirmation-popup  delete-confirmation inner-popup"
               {...props}
               size="sm"
               aria-labelledby="contained-modal-title-vcenter"
               centered

        >
            <Modal.Body>
                <div className="modal-content-wrap text-center">
                    <h3 className="modal-title">{props?.heading}</h3>
                    {props?.type == 'info' &&
                    <p>{props?.desctiption}</p>}
                    <div className="d-flex justify-content-center buttons-wrap double-btn">

                        <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                            need to recheck
                        </button>
                        <Link className="m-l-20"><Button  onClick={props.handleSubmit} type="button">{props?.button_text}</Button></Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default InfoModal
