import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from "formik";
import  {Modal, Button} from 'react-bootstrap';
import FormikControl
    from '../../../../modules/quizmanagement/components/managerQuizPreview/components/quizFormikControl/container';
import * as Yup from "yup";
import ScrollArea from 'react-scrollbar';

const QuizText = (props) => {
    const [activeIndex,setActiveIndex] = useState(0)
    const { value, index, length } = props;
    useEffect(()=>{
        setActiveIndex(0)
    },[value])
    let shape = {};
    for (let i = 0; i < value?.questions?.length; i++) {
        shape[value?.questions[i]?.question] = Yup.string();
    }
    const schema = Yup.object().shape(shape);
    const handleSubmit = async (values) => {

        alert(JSON.stringify(values));
    };

    const handleNext = () =>{
        setActiveIndex(activeIndex+1)
    }
    const handlePrevious = () =>{
        setActiveIndex(activeIndex-1)

    }


    return (


        <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={{}}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue
              }) => {

                return (
                    <Form>
                        <div className="videomodule">
                            <ScrollArea
                                speed={0.8}
                                className="modulemodalscroll"
                                contentClassName="content"
                                horizontal={false}
                            >
                                <div className="video-content-wrap">
                                    <div className="module-content">
                                        <div className="whitebox shadow-box single-qstn-containerbox">



                                            {/*{JSON.stringify(values)}*/}

                                            <div className="radiooption radiooption-single-qstn ">

                                                <FormikControl
                                                    quesIndex={activeIndex}
                                                    control={value?.questions[activeIndex]?.question_type ? value?.questions[activeIndex]?.question_type : 'No luck'}
                                                    name={value?.questions[activeIndex]?.question}
                                                    errorName={value?.questions[activeIndex]?.question}
                                                    options={value?.questions[activeIndex]?.question_type == 'fill_in_the_blanks' ? value?.questions[activeIndex]?.fill_in_the_blanks?.answer : value?.questions[activeIndex]?.multiple_choices?.filter((el) => el?.choice?.length > 0)}
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                />
                                            </div>




                                        </div>
                                    </div>
                                    <div className='d-flex '>

                                            <div className="button-wrap double-btn d-flex justify-content-end m-t-20 ml-auto pr-4"> <Button variant="primary" className="btn-m" disabled={activeIndex == 0} onClick={handlePrevious}  >Back</Button> </div>



                                            <div className="button-wrap double-btn d-flex justify-content-end m-t-20"> <Button variant="primary" className="btn-m" disabled={activeIndex+1 == value?.questions?.length} onClick={handleNext}  >Next</Button> </div>


                                    </div>
                                    {/* <div className="button-wrap double-btn d-flex justify-content-end m-t-20"> <Button variant="primary" className="btn-m" type="submit">Continue</Button> </div> */}
                                </div>
                            </ScrollArea>
                        </div>
                    </Form>
                );
            }}
        </Formik>

    );
};
export default QuizText;
