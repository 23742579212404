import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useDispatch, useSelector } from 'react-redux';
import { getStipePaymentIntent,clearState, } from '~/modules/auth/services';
import "./style.css";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
import Spinner from "react-bootstrap/Spinner";
export default function App(props) {
    const dispatch = useDispatch();
    const { user, loading,error,signUpError,stripe_payment_intent } = useSelector(state => state.auth);
    const selected_plan = useSelector(state => state.auth.selected_plan?.response);
    const Plan_loading = useSelector(state => state.auth.selected_plan?.loading);
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {

        setClientSecret(selected_plan?.client_secret);

    },[selected_plan])

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };


    return (
        <div className="App">
            {clientSecret  ? (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm amount ={props?.amount} handleCreatePlan={props?.handleCreatePlan} handleGoNext={props?.handleGoNext} />
                </Elements>
            ):
                <Spinner animation="border" variant="danger"/>

            }
        </div>
    );
}
