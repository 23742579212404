import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, Form } from "formik";
import { FormCheck } from "react-bootstrap";


const RadioButton = (props) => {

    const [state, setState] = useState({
        stateOptions: [],
        rightAnswerMessage: false,
        wrongAnswer: false
    })


    useEffect(() => {
        // let deepOptions =JSON.parse(JSON.stringify(props?.options))
        setState({ ...state, stateOptions: props?.options })
    }, [props?.options])


    const handleSelection = (e, index, option, fieldValue) => {


        setFieldValue(name, e.target.value)
        const { stateOptions } = state
        if (stateOptions[index].is_correct_answer == 'True' || stateOptions[index].is_correct_answer == true) {
            stateOptions.forEach((el) => {
                el.rightSelection = false
            })
            stateOptions[index].rightSelection = true
            stateOptions[index].rightAnswerMessage = true

            setState({ ...state, stateOptions, rightAnswerMessage: true, wrongAnswer: false })
        } else if (stateOptions[index].is_correct_answer == 'False' || stateOptions[index].is_correct_answer == false) {
            stateOptions[index].wrongSelection = true
            stateOptions[index].wrongAnswer = true
            stateOptions.forEach((el) => {
                el.rightAnswerMessage = false
            })
            stateOptions.forEach((el) => {
                el.rightSelection = false
            })
            const tempIndex = props?.options?.findIndex((el) => {
                return el.is_correct_answer == 'True' || el.is_correct_answer == true
            })
            stateOptions[tempIndex].rightSelection = true
            setState({ ...state, stateOptions: stateOptions, rightAnswerMessage: false, wrongAnswer: true })
        }



    }
    const { name, errors, options, setFieldValue, values, quesIndex, errorName, ...rest } = props;

    return (
        <>
            {/* <div style={{display:'flex'}}><div className='mr-3'><b>{Number(quesIndex)+1} : </b></div><p dangerouslySetInnerHTML={{ __html: ` ${name ? name :''}` }}></p></div> */}
            <div className="quiz-builder-pop-list d-flex">
                <span className="ind-numb">{Number(quesIndex) + 1}.</span>
                <div dangerouslySetInnerHTML={{ __html: ` ${name ? name : ''}` }} className="quiz-title-para"></div>
            </div>
            <div className="radiooption d-flex">
                <div className="integer-radio-block w-100">
                    <Field name={name} {...rest} >
                        {
                            ({ field }) => {
                                return state.stateOptions.map((option, index) => {
                                    return (
                                        <>
                                            <div className="radio-block-wrap-item m-b-20">
                                                <FormCheck

                                                    custom
                                                    {...field}
                                                    name={name}
                                                    id={option?.id ? option?.id : option?.choice}
                                                    label={`${option?.choice}`}
                                                    type="radio"

                                                    value={option?.choice}
                                                    onChange={e => handleSelection(e, index, option, field)}
                                                    checked={field.value === option?.choice}
                                                />



                                                {option.rightSelection && field.value ?

                                                    (

                                                        <p className="radio-validation label-validation">
                                                            <span className="answer-sucess"><span className="answer-icon"><i className="fa fa-check"></i></span>{option?.choice_response && option?.choice_response ? option?.choice_response : 'Right answer'}</span>
                                                        </p>

                                                    ) : (

                                                        <p className="radio-validation label-validation">
                                                            {option.wrongSelection && field.value === option?.choice ?
                                                                <span className="answer-wrong"><span className="answer-icon"><i className="fa fa-times"></i></span>{option?.choice_response && option?.choice_response ? option?.choice_response : 'Your answer is wrong '}</span>
                                                                :
                                                                ''
                                                            }
                                                        </p>
                                                    )
                                                }
                                            </div>

                                        </>
                                    );
                                })
                            }
                        }

                    </Field>
                </div>
            </div>

        </>

    );
};

export default RadioButton;
