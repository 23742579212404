import React from 'react'

export const tableData = [
    {
        id:"1",
        name: "Product Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"2",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"3",
        name: "Subcategory Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"4",
        name: "Sales  Menu",
        status: <i className="fa fa-circle font-danger f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"5",
        name: "Vendor Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"6",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    {
        id:"7",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"8",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"9",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"10",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"11",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"12",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"13",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"14",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"15",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    

    {
        id:"16",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    {
        id:"17",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2018-04-18T00:00:00"
    },
    
    
]





