import React, { useState ,useEffect} from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { PageItem } from "react-bootstrap"; 
import Select, { components } from "react-select" ;
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory,useParams} from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import {  getGroupDetail, getGroupCourses,getGroupLP, deleteGroupCourses,deleteGroupLP,
          getGroupSurvey,deleteGroupSurvey,
          getAllGroups,removeGroup,handlePagination_Survey, handlePreviousPagination_Survey,handlePagination_Courses_Groups,
          handlePreviousPagination_Courses_Groups, handlePagination_LP_Groups, handlePreviousPagination_LP_Groups ,getMembersList,updateGroups,getLP, getClassDetail
} from '../../services';
import Modal from 'react-bootstrap/Modal';
// import SkillAssignBox from '../../utilities/skillassign';
import {Formik} from "formik";
import {getNotifications} from "../../../Notifications/services";
import SkillAssignBox from '../../utilities/groupAssign';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function RemoveCourse(props) {
   return (
       <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
              {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered

       >
           <Modal.Body>
               <div className="modal-content-wrap text-center">
                   <h3 className="modal-title">Remove Course?</h3>
                   {/* <p>Are You Sure To Delete The Members? </p> */}
                   <div className="d-flex justify-content-center buttons-wrap double-btn">

                       <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                           need to recheck
                       </button>
                       <Link  className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes, Continue</Button></Link>
                   </div>
               </div>
           </Modal.Body>
       </Modal>
   );
}

function RemoveSurvey(props) {

   return (
       <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
              {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered

       >
           <Modal.Body>
               <div className="modal-content-wrap text-center">
                   <h3 className="modal-title">Remove Survey ?</h3>
                   {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
                   <div className="d-flex justify-content-center buttons-wrap double-btn">

                       <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                           need to recheck
                       </button>
                       <Link  className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes, Continue</Button></Link>
                   </div>
               </div>
           </Modal.Body>
       </Modal>
   );
}
function RemoveLearningPath(props) {

   return (
       <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
              {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered

       >
           <Modal.Body>
               <div className="modal-content-wrap text-center">
                   <h3 className="modal-title">Remove Learning Path ?</h3>
                   {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
                   <div className="d-flex justify-content-center buttons-wrap double-btn">

                       <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                           need to recheck
                       </button>
                       <Link  className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes, Continue</Button></Link>
                   </div>
               </div>
           </Modal.Body>
       </Modal>
   );
}

function RemoveMember(props) {

   return (
       <Modal className="custom-modal confirmation-popup inner-popup delete-confirmation"
              {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
       >
           <Modal.Body>
               <div className="modal-content-wrap text-center">
                   <h3 className="modal-title">Delete The Group?</h3>
                   {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
                   <div className="d-flex justify-content-center buttons-wrap double-btn">

                       <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                           need to recheck
                       </button>
                       <Link  className="m-l-20"><Button onClick={props.handlesubmit} type="button">Yes, Continue</Button></Link>
                   </div>
               </div>
           </Modal.Body>
       </Modal>
   );
}

const ClassDetails = () => {

   const { user } = useSelector(state => state.auth);
   const dispatch = useDispatch();
   const history=useHistory()
   const[dataColumns,setDataColumns]=useState([] )
   const [data, setData] = useState([]);
   const[filter,setFilter]=useState('Learning Path');
   const {id} = useParams();
   const [assign, assignShow] = useState(false);
   const groupDetails = useSelector(state => state.teams. groupDetails?.response|| [] ||[]);
   const classDetails = useSelector(state => state.teams. classDetails?.response|| [] ||[]);

   const responseCourses = useSelector(state => state.teams.groupCourses?.response|| [] ||[]);
   const responseLP = useSelector(state => state.teams. groupLP?.response|| [] ||[]);
   const responseSurvey = useSelector(state => state.teams. groupSurvey?.response|| [] ||[]);

   const loading = useSelector(state => state?.teams?. group_list?.loading);
   const lploading = useSelector(state => state?.teams?. groupLP?.loading);
   const surveyloading = useSelector(state => state?.teams?.groupSurvey?.loading);
   const Courseloading = useSelector(state => state?.teams?.groupCourses?.loading);
   const[rowId,setRowId]=useState('')
   const[count,setCount]=useState('')
   const[groupMembers,setMembers]=useState([])
   const[newMembers,setNewMembers]=useState([])
   const[newEntry,setNewEntry]=useState([])
   const [editGroup, editGroupShow] = useState(false);
   const [name, setName] = useState('')
   const responseMembers = useSelector(state => state.teams.members_list?.response || []);
   const[dataProgress,setDataProgress]=useState(false)
   const[memberExist,setMemberExist]=useState(false)
   const [activePage, setActivePage] = useState(1); 
    const [activeLPPage, setActiveLPPage] = useState(1);
    const [activeCoursesPage, setActiveCoursesPage] = useState(1);
    const [state, setState] = useState({
      groupCourses: responseCourses,
      groupLP: responseLP,
      courses:[],
      countCourses: responseCourses?.count,
      countLP : responseLP?.count,
      paginationGroupLPitem: [],
      paginationGroupCoursesItem: [],
      paginationItem: [],
      });

   const[courses,setCourses]=useState([]);
   const[group,setGroupDetails]=useState([]);
   const[classDetail, setClassDetails] =useState();
   
 
   const [search, setSearch] = useState('');
   const modalExit=()=>{
      dispatch(getNotifications())
   }

   const [modalShow, setModalShow] = React.useState(false);
   const handleModel = (values) =>{
     if(values){
         setModalShow(true)
     }

  }

  const [modalSurvey, setModalSurvey] = React.useState(false);
  const handleModalSurvey = (values) =>{
    if(values){
        setModalSurvey(true)
    }

 }


useEffect(()=>{
      dispatch(getLP())
},[])
 const [modalLP, setModalLP] = React.useState(false);
 const handleModelLP = (values) =>{
   if(values){
       setModalLP(true)
   }

}

const [modalDEL, setModalDEL] = React.useState(false);
const handleModelDEL = (values) =>{
  if(values){
      setModalDEL(true)
  }

}
//pagination
useEffect(() => {

   const countCourses = responseCourses?.count
   const perPageCourses = 12;
   const buttonsCountCourses = Math.ceil(countCourses / perPageCourses);
   let itemsCourses = []
   for (let number = 1; number <= Number(buttonsCountCourses); number++) {
       itemsCourses.push(
           <Pagination.Item onClick={() => handleGroupCoursesIndex(number)} key={`${number}.courses`} active={number == activeCoursesPage}>

               {number}
           </Pagination.Item>
       );
   }
   setState({ ...state, groupCourses: responseCourses, countCourses: responseCourses?.count, paginationGroupCoursesItem: itemsCourses });


}, [responseCourses]);

useEffect(() => {
   const countLP = responseLP?.count
   const perPageLp = 12;
   const buttonsCount = Math.ceil(countLP / perPageLp);
   let itemsLp = []
   for (let number = 1; number <= Number(buttonsCount); number++) {
       itemsLp.push(
           <Pagination.Item onClick={() => handleLpIndex(number)} key={`${number}.LP`} active={number == activeLPPage}>

               {number}
           </Pagination.Item>
       );
   }
   setState({ ...state, groupLP: responseLP, countLP: responseLP?.count, paginationGroupLPitem: itemsLp});
}, [responseLP]);

const handleGroupCoursesIndex = (index) => {
        
   if (index == 1) {
       dispatch(getGroupCourses(user?.token,id,search));
   }
   else {
       dispatch(getGroupCourses(user?.token, id, '', index));
       // dispatch(handlePagination_Courses(responseCourses?.next))
       // dispatch(getMemberCourses( search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, index));
   }
   setActiveCoursesPage(index);
}

const handleLpIndex = (index) => {

   if (index == 1) {
       dispatch(getGroupLP(user?.token, id, search));

   }
   else {
       dispatch(getGroupLP(user?.token, id, '', index));
       // dispatch(getMembersList( search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, index));
   }
   setActiveLPPage(index);
}
const handleNext = () => {

   if(filter=='Courses')
   {
   if ( responseCourses?.next) {
   var regex = /page=\d+/g;
      const [number ] = state.groupCourses?.next?.match(regex)
      setActiveCoursesPage(prevVal => prevVal === state.paginationGroupCoursesItem ? prevVal : prevVal + 1);
     // dispatch(handlePaginationLearnerCourse(state.courses?.next))
           
       dispatch(handlePagination_Courses_Groups(responseCourses?.next))
      // dispatch(handlePagination_Courses(state.memberCourses?.next))

   } else {
       return false   }
   }

   if(filter=='Learning Path')
   {
       //if(responseLP?.next)
         if ( responseLP?.next) 
         {
           var regex = /page=\d+/g;
           const [number ] = responseLP?.next?.match(regex)
           setActiveLPPage(prevVal => prevVal === state.paginationGroupLPitem ? prevVal : prevVal + 1); 
           dispatch(handlePagination_LP_Groups( responseLP?.next))
           } 
           else {
          return false               
        }
   }

}

const handlePrevious = () => {
   if(filter=='Courses')
   {
       if ( responseCourses?.previous) {
              setActiveCoursesPage((prevVal) => (prevVal - 1 === 0 ? prevVal : prevVal - 1));
           dispatch(handlePreviousPagination_Courses_Groups(responseCourses?.previous))

         } else {
        return false          
            }
 } 

 if(filter=='Learning Path')
 {
       if ( responseLP?.previous) {
          setActiveLPPage((prevVal) => (prevVal - 1 === 0 ? prevVal : prevVal - 1));
           dispatch(handlePreviousPagination_LP_Groups( responseLP?.previous))

         } else {
        return false             
      }
 }

}

// const handleNext = () => {
//    if(filter=='Courses')
//    {
//    if ( responseCourses?.next) {
//        dispatch(handlePagination_Courses_Groups( responseCourses?.next))
//    } else {
//        return false   }

//    }
//    if(filter=='Learning Path')
//    {
//          if ( responseLP?.next) {
//              dispatch(handlePagination_LP_Groups( responseLP?.next))
//            } else {
//           return false                }
//    }
//    if(filter=='Survey')
//    {
//          if ( responseSurvey?.previous) {
//              dispatch(handlePagination_Survey( responseSurvey?.next))
//            } else {
//           return false                }
//    }
// }

// const handlePrevious = () => {
//    if(filter=='Courses')
//    {
//          if ( responseCourses?.previous) {
//              dispatch( handlePreviousPagination_Courses_Groups( responseCourses?.previous))
//            } else {
//           return false                }
//    }
//    if(filter=='Learning Path')
//    {
//          if ( responseLP?.previous) {
//              dispatch(handlePreviousPagination_LP_Groups( responseLP?.previous))
//            } else {
//           return false                }
//    }
//    if(filter=='Surveys')
//    {
//          if ( responseSurvey?.previous) {
//              dispatch(handlePreviousPagination_Survey( responseSurvey?.previous))

//            } else {
//           return false                }
//    }

// }

useEffect(() => {
   let element = document.getElementById('headerTitle')
   if(element){
   //   switch (history.location.pathname){
   //      case '/team':
           element.innerHTML = 'Teams'
   //         break;

   //     default :
   //        'TEAM'

   //   }
 }


}, [])

   useEffect(() => {

      setDataColumns( tableColumnsCourses)

      //  setTimeout(()=>{
      //      dispatch( getGroupLP(user?.token,id,''));
      //  },100)

      // dispatch(getGroupSurvey(user?.token,id,''));

      //  dispatch(getGroupDetail(user?.token,id))
       //d.
       dispatch(getClassDetail(user?.token,id))
setClassDetails(classDetails)
      //  dispatch( getGroupCourses(user?.token,id,''));
       setFilter('Courses')
      setGroupDetails(groupDetails);
       setCourses(responseCourses.results);

       setData(classDetails)



      return () => {

      }
   }, [])


useEffect(() => {
 if( !Courseloading){  setData(classDetails) }

   return () => {

   }
}, [])

useEffect(() => {

   setGroupDetails(groupDetails);

}, [groupDetails])

useEffect(() => {

    setClassDetails(classDetails);
 
 }, [classDetails])
 



   useEffect(() => {

      setGroupDetails(groupDetails);
      setClassDetails(classDetails);

      
      setData(classDetails);
      setDataColumns( tableColumnsCourses)
      setFilter('Courses')

      setCount(responseCourses.count)
   }, [responseCourses.results])

   useEffect(() => {

      setGroupDetails(groupDetails);
      setClassDetails(classDetails);


       setData(responseLP.results)
      setDataColumns( tableColumnsLearningPath)
       setFilter('Learning Path')


      setCount(responseLP.count)
   }, [responseLP.results])

   // useEffect(() => {
   //
   //    setGroupDetails(groupDetails);
   //
   //     setData(responseSurvey.results);
   //    setDataColumns(tableColumnsSurveys);
   //    setFilter('Surveys')
   //
   //    setCount(responseSurvey.count)
   //
   //  }, [responseSurvey.results])


   useEffect(() => {
      if(loading||lploading||surveyloading||Courseloading){ setDataProgress(true)}

     if (!loading&& !lploading&& !surveyloading && !Courseloading){setDataProgress(false)}


    }, [loading,lploading,surveyloading,Courseloading])


const handleGroupsFilter=(e)=>{

    const sch='';

    if(e.value=='Survey')
          {  dispatch(getGroupSurvey(user?.token,id,sch));

            setData(responseSurvey.results);
             setDataColumns(tableColumnsSurveys);
             setFilter('Surveys')
             setCount(responseSurvey.count)

         }
     if(e.value=='Courses')
         {
            dispatch( getGroupCourses(user?.token,id,sch));
            setData(responseCourses.results);
            setDataColumns( tableColumnsCourses)
            setFilter('Courses')
           setCount(responseCourses.count)

          }

       if(e.value=='Learning Path')
         { dispatch( getGroupLP(user?.token,id,sch));
            setData(responseLP.results);
           setDataColumns( tableColumnsLearningPath)
           setFilter('Learning Path')
           setCount(responseLP.count)

        }        }


        const deleteSingleGroup=()=>{
         dispatch( removeGroup(user?.token,id))

         setTimeout(() => {
         dispatch( getAllGroups(user?.token,'',''))
      }, 1000)
         history.push('/team')
       }


        const searchInput = (e) =>
        {
      setSearch(e.target.value);

      if(filter=='Learning Path'){
         dispatch( getGroupLP(user?.token,id,e.target.value));

      }
      if(filter=='Courses'){
         dispatch( getGroupCourses(user?.token,id,e.target.value));

      }
      if(filter=='Surveys'){
         dispatch(getGroupSurvey(user?.token,id,e.target.value));


      }         }


      const reset=()=>{
         setSearch('');

         if(filter=='Learning Path'){
            dispatch( getGroupLP(user?.token,id,''));

         }
         if(filter=='Courses'){
            dispatch( getGroupCourses(user?.token,id,''));

         }
         if(filter=='Surveys'){
            dispatch(getGroupSurvey(user?.token,id,''));

         }

      }


        const options = [
         { value: 'Courses', label: 'Courses' },
         // { value: 'Survey', label: 'Surveys' },
         { value: 'Learning Path', label: 'Learning Path' }
      ]


      const handleCallback = (childData) =>{
       if(childData) {
          assignShow(false)

      }
     }


const deleteCourse=()=>{
   setModalShow(false)
  
   const formdata = new FormData();
   formdata.append('course_assign_id',rowId);

   dispatch(deleteGroupCourses(user?.token,id,formdata))

   setTimeout(() => {
   dispatch(getGroupCourses(user?.token,id,''))
}, 1000)

}



const handleDeleteLP=()=>{
setModalLP(false)

 const formdata = new FormData() ;
 formdata.append("learningpath_id",rowId);
dispatch(deleteGroupLP(user?.token,id,formdata))
setTimeout(() => {
dispatch( getGroupLP(user?.token,id,''));
}, 1000)

}


const handleDeleteSurvey=()=>{
setModalSurvey(false)

   dispatch(deleteGroupSurvey(user?.token,id,rowId))

   setTimeout(() => {
   dispatch( getGroupSurvey(user?.token,id,''));
}, 1000)


   }

   const handleRemove = (item,index)=>
   {
      const tempselcted = groupMembers.filter(member=> member?.id!= item?.id)
       setMembers(tempselcted)

  }


   const AddNew=(e)=>{

      setNewEntry(e)

   }


// searching for members to add to a group
const searchMembers=(e)=>{

   dispatch(getMembersList(user?.token,e,''))
   setNewMembers(responseMembers.results)

   }


   const updateGroup=()=>{
      const selectlist =groupMembers.map(a => a.id);
     let tempObj={
        name: name,
        learners: selectlist
     }

        dispatch(updateGroups(id,tempObj))

        setTimeout(() => {
           dispatch(getGroupDetail(user?.token,id))

        }, 1000)

        editGroupShow(false)}


   //edit group

 const handleEdit=()=>{

   setName(groupDetails?.name)
   const learners=groupDetails?.learners_detail;

   const newArray=[];

   for (var i = 0; i < learners.length; i++) {
    newArray.push({
           id:learners[i].id,
         get_full_name:learners[i].get_full_name
       });   }

   setMembers(newArray)
   }


      const handleGroupNameChange=(e)=>{
      var value=e.target.value;

      setName(value)
      }


   //searching and adding new members to existing group
const addNewMembers=()=>{
   const result =groupMembers .find(member=> member?.id == newEntry?.id);
   if(result )
   {
       setMemberExist(true)
       setTimeout(() => {
         setMemberExist(false)
       }, 3000);
   }


   if((!result) &&  (newEntry?.id)){
   let tempObj=[]
    tempObj=groupMembers;
   tempObj.push(newEntry)
   setMembers(tempObj );

 }


}


   const tableColumnsCourses = [
      {
         name: 'Course Name',
         sortable: true,
         center: false,
         // cell:row => <Link to={`/commingsoon`}>{row.course_name}</Link>,
         grow: 3,
          cell: row =>
              <OverlayTrigger
                  overlay={
                      <Tooltip id="InfoText">
                          {row?.course_name}
                      </Tooltip>
                  }
              >
                  <div>
                      {row?.course_name?.substring(0, 50) + ' '  +  (row?.course_name?.length > 50 ?  '...' : '')}

                  </div>
              </OverlayTrigger>
      },

      {
         name: 'Assigned By',
         selector: 'assigner_name',
         sortable: true,
         center: false,
         grow: 4,
      },
      {
         name: 'Due Date',
         selector: 'due_date',
         sortable: true,
         center: false,
         grow: 2,

      },

      {
         name: 'Action',
         selector: 'Action',
         sortable: true,
         center: false,
         grow: 2,
         cell: row =>
         <button className="btn border-btn" onClick={e=>{handleModel(true),setRowId(row.id)}}>Remove</button>,
         //onClick={(e)=>deleteCourse(row.id)}

      },
   ];

   const tableColumnsLearningPath = [
       {
           name: 'Learning Path',
           selector: 'learning_path__name',
           sortable: true,
           center: false,
           grow: 3,
           cell: row =>
               <OverlayTrigger
                   overlay={
                       <Tooltip id="InfoText">
                           {row?.learning_path__name}
                       </Tooltip>
                   }
               >
                   <div>
                       {row?.learning_path__name?.substring(0, 50) + ' '  +  (row?.learning_path__name?.length > 50 ?  '...' : '')}

                   </div>
               </OverlayTrigger>
       },
       // {
       //     name: 'Status',
       //     selector: 'status',
       //     sortable: true,
       //     center: false,
       //     grow: 2
       //
       // },
       {
           name: 'Due Date',
           selector: 'due_date',
           sortable: true,
           center: false,

           grow: 2,
       },
      //  {
      //      name: 'Completed',
      //      selector: 'created_by',
      //      sortable: true,
      //      center: false,
      //      cell: row =>
      //          <p>{row.completed ? 'Complete' : '--'}</p>
      //      ,
      //      grow: 2,
      //  },

       // {
       //     name: 'Action',
       //     selector: 'Action',
       //     sortable: true,
       //     center: false,
       //     grow: 2,
       //     cell: row =>
       //         <button className="btn border-btn"  onClick={e=>{ handleModelLP (true),setRowId(row.id)}}>Remove</button>
       //     ,
       // },
   ];

   const tableColumnsSurveys= [
      {
         name: 'Survey Name',
         sortable: true,
         center: false,
         // cell:row => <Link to={`/survey/${row.id}`}>{row.survey_name}</Link>,
         cell:row => row.survey_name,
         grow: 3
      },

      {
         name: 'Assigned By',
         selector: 'assigner_name',
         sortable: true,
         center: false,
         grow: 2,
      },
      {
         name: 'Created By',
         selector: 'created_by',
         sortable: true,
         center: false,
         grow: 2,
      },
      {
         name: 'Action',
         selector: 'Action',
         sortable: true,
         center: false,
         grow: 2,
         cell: row =>
         <button className="btn border-btn" onClick={(e)=>{handleModalSurvey(true);setRowId(row.id)}}>Remove</button>
         ,
      },
   ];

   const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
      <div className="custom-control custom-checkbox">
         <input
            type="checkbox"
            className="custom-control-input"
            ref={ref}
            {...rest}
         />
         <label className="custom-control-label" onClick={onClick} />
      </div>
   ));
   const CaretDownIcon = () => {
      return (
         <div className="filter-icon-select">
         <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
         <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
         </svg>
         </div>
      );
    };
    
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    };
   return (
      <div className="available-course-wrap">
         
         <div className="datatble-wrap">
            <DataTable
               data={classDetails}
               columns={dataColumns}
               sortable={true}
               //selectableRows
               //selectableRowsComponent={BootyCheckbox}
               progressPending={dataProgress}
              progressComponent={
               <div >
               <Spinner animation="border" variant="warning"/>
              </div>

            }
          
             noHeader={true}
            />
           
         </div>
          
         {
            filter == 'Learning Path' ?
             <>
               {
                 !lploading && state?.groupLP && state?.groupLP && responseLP.count > 12 && responseLP &&
                 <div className="d-flex justify-content-center">
                <Pagination size="sm" className="custom-pagination" key='lp'>
                <Pagination.First disabled={!state?.groupLP?.previous} className={`${state?.groupLP?.previous ? "paginationStyle" : "" } `} onClick={handlePrevious}/>
                {state?.paginationGroupLPitem}
                 <Pagination.Last disabled={!state?.groupLP?.next} onClick={handleNext} className={`${ state?.groupLP?.next ? "paginationStyle" : "" } `} />
                </Pagination>
                </div>
            }
            </>
            : ""

         }

          <Modal
   size="md"
   show={editGroup}
   onHide={() =>
   editGroupShow(false)}
   aria-labelledby="example-modal-sizes-title-lg"
   className="common-modal team-member-pop inner-popup"
   centered
   >
   <Modal.Header closeButton>
      <div className="addmember-header">
         <h4>Edit Group</h4>
         <p>You can edit the details of your group here</p>
      </div>
   </Modal.Header>
   <Modal.Body>
      <div className="form-input-wrap">
         <Form.Label>Group Title</Form.Label>
         <div className="d-flex w-100">
            <div className="group-title-create-left">
               <Form.Control type="text" placeholder="Enter group name" className="input-item" value={name} onChange={handleGroupNameChange}/>
            </div>
            <div className="group-title-create-right">
                <Button disabled = { name?.length <=0? "disabled" : ""} onClick={updateGroup}>Update Group</Button>
            </div>
         </div>
      </div>
      <div className="group-list-selected-box-content">


      {groupMembers &&groupMembers.length  ? groupMembers.map((item,index) => (

                  <div className="course-selected-item p-relative">

        {item?.id ?      <span className="course-selected-text">{item?.get_full_name.length > 1 ? item?.get_full_name : item?.id }</span>:''}
           <span className="memb-close-icon" onClick={()=>handleRemove(item,index)}>×</span>

                  </div>

          ))
          :
          ''
      }


      </div>
      <div className="group-pop-footer">
         <h4>Add New Members To The Group</h4>
         <div className="form-input-wrap">
            <Form.Label>Email Address</Form.Label>

            <div className="d-flex w-100">
               <div className="group-title-create-left">
               <Select

            placeholder="Enter Email Address"
            isClearable={true}
              onChange={AddNew}
              onInputChange={searchMembers}

             options={newMembers||[]}
             getOptionLabel ={(option)=>option.email}
             getOptionValue={(option) => option.id}

      />
               </div>

               <div className="group-title-create-right">

               <Button  onClick={addNewMembers}>Add</Button>

               </div>
            </div>
            { memberExist ? <span  style={{  color:'#ce002a' }}>Member Already Selected </span>:''}
         </div>

      </div>
   </Modal.Body>
</Modal>



<Modal
   size="md"
   show={assign}
   onHide={() =>
   assignShow(false)}
   onExit={modalExit}
   aria-labelledby="example-modal-sizes-title-lg"
   className="common-modal team-member-pop inner-popup"
   centered
   >
   <Modal.Header closeButton>
      <div className="addmember-header">
         <h4>Enroll</h4>
         <p>Description text will be here</p>
      </div>
   </Modal.Header>
   <Modal.Body>
      <SkillAssignBox  id={id}  parentCallback = {handleCallback}/>
   </Modal.Body>
</Modal>

      </div>
   );
};
export default ClassDetails;
