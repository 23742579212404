import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import { addNewMember, getMembersList} from '../../services';
import {useHistory} from "react-router";

function UploadCsv(props) {

    const dispatch = useDispatch() 
   const [file, setFile] = useState(null);  
   const response = useSelector(state => state.teams?.count_member?.response );
   const { user } = useSelector(state => state.auth);
   const history = useHistory();
   const [err, setErr] = useState({
      error:false,
      errorMessage:'',
      fileTypeError:false

   })

   const [state, setState] = useState({
      Max_members: 0,
      Current_users: 0,
      Members_to_add: 0,

   })
   useEffect(() => {

      setState({ ...state, Max_members: response?.maximum_member_count, Current_users: response?.current_member_count, Members_to_add: (response?.maximum_member_count - response?.current_member_count) })

   }, [response])

   const fileHandler = (e) => {
      const filecontent = e.target.files[0];
      const getFileExtension = filecontent.name.split('.').pop();
      if (getFileExtension === 'csv') {
         setFile(filecontent) 
         setErr({...err,error:false,  fileTypeError:false })             

        

      } else {
         setErr({...err,  fileTypeError:true })        

         fileReset()
      }
   }


   const fileReset = () => {
      setFile(null)
   }

   const handleSubmit=()=>{
      const data = new FormData() ;
      const search=''
      if(file!=null)
      {
      data.append('user_list',file);
      let type='csv'  
       dispatch( addNewMember(data,type,function (res) {
                  if (res) {                     
                 fileReset()
                 props.parentExit(true)                
      
                  }
               },function (error) {
                           if (error) {
                    
                              setFile(null)                               
                              
                              setErr({...err,error:true, errorMessage: error?.error?.errorMessage})
              
                            
                           }
                        }));    


       setTimeout(() => {
         dispatch( getMembersList(user?.token, '',''))
           
      }, 1000)
      

      }
   }







   return (

      <div className="csv-upload-wrap">


         <div className="csv-upload-success">
            <p>
               {/* Exceeding {state?.Max_members} users will result in moving to pro plan , */}
               With the current plan you can add {state?.Max_members - state?.Current_users} more members Or </p>
          {user.is_admin_institution !== true && user?.user_type == "manager" ? (<div className="error-link text-primary" onClick={ () => history.push({pathname:'/profile',state:{key:'Subscription'}})} style={{fontWeight:"bold",cursor:'pointer'}}>
            upgrade your plan
               
            </div>) : (
               <div className="error-link" style={{fontWeight:"bold"}}>
               upgrade your plan
                 
              </div>
            )}  
         </div>



         <div className="csv-icons-wrap d-flex">
            <div className="user-count-show">
               <div className="count-number-round">{state?.Max_members}</div>
               <p>Max Users</p>
            </div>
            <div className="user-count-show">
               <div className="count-number-round">{state?.Current_users}</div>
               <p>Current Users</p>
            </div>
         </div>

<div className="csv-file-upload-wrap d-flex m-t-10">
         <div className="file-upload-wrap p-relative">

            <Form.Control type="file" title=" " className="w-100" accept=" .csv" onChange={(e) => fileHandler(e)} />

            <label htmlFor="file" className="btn btn-primary">Choose File</label>
         </div>
<div className="csv-upload-error-show">
         {file != null ? <div className="d-flex align-items-center" > <span className="csv-error-span">{file?.name}</span>
            <span className="ml-3 memb-close-icon-add-member " onClick={fileReset}> x</span></div>
            : ''}

         {err.fileTypeError ? <span style={{ color: 'red' }} >Select CSV  File</span>
            : ''}

            {
               err.error?<>{err.errorMessage}</>:''
            }
            </div>
</div>


         <div className="csv-button-wrap double-btn d-flex justify-content-between">
            <button type="button" className="btn border-btn" onClick={(e) => props.parentExit(true)}>Cancel</button>

            <div className="fileuploader d-flex align-items-center" >

                  <div className="image-upload-button p-relative" onClick={(e) =>handleSubmit ()} disabled={!file} >
                     <label
                        htmlFor="profilePic"
                        className="btn btn-primary btn-m"
                     >
                        Upload CSV
                     </label>
                     {/* <button
             onClick={(e)=>props.addMemberList(true)}
               id="uploadCsv"
               type="button"
               className="fileuploader-hidden"
               
               /> */}
                  </div>
            </div>



         </div>
      </div>
   );
};
export default UploadCsv;