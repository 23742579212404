import React from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SurveyAssignBox from './components/surveyassignbox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function AssignSurvey() {
  return (
    <div className="course-assign-wrap">
      <div className="course-assign-box-container survey-assignbox">
        <h4 className="course-assign-type-name">Select Groups To Which You Want To Assign This Survey</h4>
        <SurveyAssignBox />
      </div>
      <div className="add-module-footer d-flex justify-content-end">
        <div className="button-wrap double-btn">
          <button className="btn btn-m border-btn">Cancel</button>
          <button className="btn btn-m border-btn">Back</button>
          <Button variant="primary" className="btn-m" >Save</Button>
        </div>
      </div>
    </div>
  );
};
export default AssignSurvey;