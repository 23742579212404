
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { listDemographicSurveyReport } from '../../../../services'
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';



const DoughnutOptions = {
  plugins: {
    legend: {
      display: true,
      position: "right",
      align: "start",
      labels: {
        usePointStyle: true,
      },
    },
  },
}
function GenderGraph(props) {



  const loading = useSelector(state => state?.surveys?.listSurveyReportDemographic?.loading || false)
  const { demoQuestion, questionId, surveyId } = props;
  const [surveyReport, setSurveyReport] = useState({})
  const dispatch = useDispatch();

  const data = {

    labels: [],
    datasets: [
      {
        label: '# of Votes',
        data: [],
        backgroundColor: [
          '#7d2df3',
          '#449dd1',
          "#084298",
          "#8c4efc"

        ],
        aspectRatio: 1,
      },
    ],

  };



  const [dataChart, setDataChart] = useState(data)

  useEffect(() => {

    if (props?.questionId && props?.surveyId && props?.questionId !== undefined) {
      dispatch(listDemographicSurveyReport(surveyId, questionId, function (response) {



        let newArray = response
        response?.choices.map((entry, index) => {


          let array1 = response?.count?.filter((item) => item[Object.keys(item)[0]] === entry?.choice)


          if (!array1?.length) {

            let obj = {
              answer: entry?.choice,
              count: 0,
              percentage: 0
            }
            newArray.count?.push(obj)


          }

        })
        let array2= newArray?.count.filter((item)=>item[Object.keys(item)[0]].length)
        let obj={
          count:array2
        }
        setSurveyReport(obj)





        // setSurveyReport(response)
      }))
    }


  }, [props?.questionId])



  useEffect(() => {


    let labelArray = []
    let dataArray = []

    if (surveyReport?.count && surveyReport?.count?.length) {
      surveyReport?.count?.map((item, index) => {

        if (item[Object.keys(item)[0]] && item[Object.keys(item)[0]].length) {
          if (item[Object.keys(item)[0]].length <= 25) { labelArray.push(item[Object.keys(item)[0]]) }
          else { labelArray.push(item[Object.keys(item)[0]].slice(0, 24)) }
          dataArray.push(item?.count)
        }


      })
    }

    const datas = {
      labels: labelArray,
      datasets: [
        {
          label: '# of Votes',
          data: dataArray,
          backgroundColor: [
            '#7d2df3',
            '#449dd1',
            "#084298",
            "#8c4efc"

          ],
          aspectRatio: 1,
        },
      ],

    };


    setDataChart(datas)

  }, [surveyReport])






  return (
    <div className="gendergraph-wrapper">
      <Row>
        <Col md={4}>

          <div className="graph-data-counthere">

            <div className="graph-data-text">
              <h4 className="graph-title">{props?.demoQuestion?.question}</h4>
              <p>Breakdown of Participants</p>
            </div>




            <Table striped hover className="normal-table">
              

              <tbody>

                {surveyReport?.count?.map((item, index) => {
                  return (<tr>
                    {/* <td>{item[Object.keys(item)[0]]}</td> */}
                    <td>{item[Object.keys(item).find(element => element.includes("answer"))]}</td>
                    <td align="right"  style={{ wordBreak: 'keep-all'}}>{item?.count}</td>
                  </tr>
                  )
                })
                }

              </tbody>
            </Table>

          </div>
        </Col>

        <Col md={4}>
          <div className="gendergraph-box">
            <Doughnut data={dataChart} options={DoughnutOptions} width="500" height="500" />
          </div>
        </Col>

      </Row>

    </div>
  );
};
export default GenderGraph;