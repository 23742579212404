import React, { useState } from "react";
import { List, arrayMove, arrayRemove } from "react-movable";

function ItemSelected() {
  const [items, setItems] = useState([
    "Courses Name",
    "Quiz Name",
    "Courses Name",
    "Quiz Name",
  ]);
  return (
    <List
      values={items}
      onChange={({ oldIndex, newIndex }) =>
        setItems(arrayMove(items, oldIndex, newIndex))
      }
      renderList={({ children, props, isDragged }) => (
        <ul className="movable-listing"
          {...props}
        >
          {children}
        </ul>
      )}
      renderItem={({ value, props, isDragged, isSelected, index }) => (
        <li className={isDragged? "dragging": "liststaying"}
          {...props}
          style={{
            ...props.style,
            width: isDragged ?  "80%" : "100%",
            padding: "23px",
            margin: "15px 0",
            listStyleType: "none",
            border: "1px solid #E1E1E1",
            borderRadius: "10px",
            borderLeft:  "15px solid #E1E1E1",
            borderRight:  "15px solid #E1E1E1",
            cursor: isDragged ? "grabbing" : "inherit",
            backgroundColor: isDragged || isSelected ? "#fff" : "#FFF"
          }}
        >
          <div className="movable-item-main-content d-flex justify-content-between">
            <div className="movable-btn-text-wrap d-flex">
            <button data-movable-handle
             className="movable-button"
              tabIndex={-1}
            >
              <HandleIcon />
            </button>

            <div className="movable-content">
              {value}
            </div>
            </div>
            <button
             className="movable-button"
              onClick={() => {
                if (typeof index !== "undefined") {
                  setItems(arrayRemove(items, index));
                } else {
                  setItems(items);
                }
              }}
            >
              <RemovableIcon />
            </button>
          </div>
        </li>
      )}
    />
  );
}

const HandleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-move"
  >
    <polyline points="5 9 2 12 5 15" />
    <polyline points="9 5 12 2 15 5" />
    <polyline points="15 19 12 22 9 19" />
    <polyline points="19 9 22 12 19 15" />
    <line x1="2" y1="12" x2="22" y2="12" />
    <line x1="12" y1="2" x2="12" y2="22" />
  </svg>
);

const RemovableIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.54" height="22.248" viewBox="0 0 18.54 22.248">
    <path className="a" d="M97.854,83.781a2.476,2.476,0,0,0,2.472,2.466h9.888a2.476,2.476,0,0,0,2.472-2.466V69.562H97.854ZM114.54,65.854H109.9L108.353,64h-6.166l-1.552,1.854H96v1.854h18.54Z" transform="translate(-96 -64)" />
  </svg>
);

export default ItemSelected;
