import React, {DragEvent, useEffect, useState,useContext} from 'react';
import {getAllQuiz, getManagerCoursesList} from "../../../../coursemanagement/services";
import {getCoursesWithOutPagination,getQuizzesWithOutPagination} from "./../../../services"
import {useSelector,useDispatch} from "react-redux";
import ScrollArea from "react-scrollbar";
import Form from "react-bootstrap/Form";
import {FlowContext} from "../container";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const LearningPathLeft= (props) => {
    const dispatch = useDispatch()
    const [elements,setElements] =useContext(FlowContext)
    const response = useSelector(state => state?.learningpath?.courses_without_pagination?.response);

    let quizResponse = useSelector(state => state?.learningpath?.quizzes_without_pagination?.response);
    const [key, setKey] = useState('Course_tab');
    const [isSelected,setIsSelected] = useState(false)
    const [courseSearch,setCourseSearch] = useState('')
    const [quizSearch,setQuizSearch] = useState('')
    const [state, setState] =useState({
        courses:response?.results,
        quiz: [],
        selectedCourseQuiz:[],
        filterQuiz:[],
        filterCourse:[]
    })

    useEffect(()=>{
        dispatch(getCoursesWithOutPagination());
        dispatch(getQuizzesWithOutPagination());
    },[])
    useEffect(() => {
        if(response?.results && response?.results?.length){
            response.results = response.results.map((el)=>{return{...el,selType:'course'}})
        }
        if(quizResponse?.results && quizResponse?.results?.length){
            quizResponse.results = quizResponse.results.map((el)=>{return{...el,selType:'quiz'}})
        }
        setState({...state, courses:response?.results,quiz:quizResponse?.results,filterCourse:response?.results,filterQuiz: quizResponse?.results });
    }, [response,quizResponse]);

    const onDragStart = (event, nodeType,el) => {

        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.setData('application/course', JSON.stringify(el));
        event.dataTransfer.effectAllowed = 'move';
    };

    const  handleChangeInput = (e,filterPro,prop) =>{
        let val = e?.target?.value?.toLowerCase();
          if(prop == 'courses'){

              setCourseSearch(val)
          }else{

              setQuizSearch(val)
          }



        if (val.length > 0) {
            let results = state[filterPro].filter(item =>
                item?.name?.toLowerCase().includes(val)
            );
            setState({...state, [prop]: results})

        } else {

            setState({...state,[prop]: filterPro == 'filterQuiz' ? state.filterQuiz : state.filterCourse})


        }
    }
    const handleListClickQuiz = (item,index,filterPro,prop) => {
        let {selectedCourseQuiz,filterQuiz,quiz} = state;

        quiz[index].isChecked = !quiz[index].isChecked

        if(selectedCourseQuiz.filter((el)=>el.selType == 'quiz').find(f => f.name === item.name)) {
            selectedCourseQuiz = selectedCourseQuiz.filter(f=> f.name !== item.name);

        } else {
            selectedCourseQuiz.push(item)

        }

        setState({...state, selectedCourseQuiz: selectedCourseQuiz,filterQuiz:filterQuiz,quiz:quiz})
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    const handleListClickCourse    = (item,index,filterPro,prop) => {
        let {selectedCourseQuiz,filterCourse,courses} = state;
        courses[index].isChecked = !courses[index].isChecked

        if(selectedCourseQuiz.filter((el)=>el.selType == 'course').find(f => f.name === item.name)) {
            selectedCourseQuiz = selectedCourseQuiz.filter(f=> f.name !== item.name);

        } else {
            selectedCourseQuiz.push(item)

        }
        setState({...state, selectedCourseQuiz: selectedCourseQuiz,filterCourse:filterCourse,courses: courses})
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    const handleRemoveQuiz = (item,index)=>{
        const {selectedCourseQuiz,quiz} =state
        selectedCourseQuiz.splice(index,1)
        let tempIndex = quiz.findIndex((el)=>{
            return el.id == item.id
        })
        quiz[tempIndex].isChecked = false
        setState({...state,selectedCourseQuiz: selectedCourseQuiz,quiz: quiz})

    }
    const handleRemoveCourse = (item,index)=>{
        const {selectedCourseQuiz,courses} =state
        selectedCourseQuiz.splice(index,1)
        let tempIndex = courses.findIndex((el)=>{
            return el.id == item.id
        })
        courses[tempIndex].isChecked = false
        setState({...state,selectedCourseQuiz: selectedCourseQuiz,courses: courses})

    }
    const handleChange = (checked) => {

           setIsSelected(checked)

    }

    const {selectedCourseQuiz,courses,quiz} =state


    const handleTab = (k) => {

        if(k == 'Course_tab'){
            setIsSelected(false)
        }else if(k == "Quiz" ){
            setIsSelected(true)
        }



        setKey(k)
    }

    return (
        <aside>
            <div className="left-bar mb-2">
                <div className="course-quiz-selected-list">
                <div className="tab-wrapper">
<Tabs
    id="controlled-tab-example"
    activeKey={key}
    onSelect={(k) => handleTab(k)}
    className="workflow-left-tab">
       <Tab eventKey="Course_tab" title="Course">
           <>
               <div className="add-quiz-box-header mt-3">

                   <div className="form-input-wrap">
                       <Form.Control type="text" placeholder={`${!isSelected ? 'Search Courses' : 'Search Quizzes'}`}
                                     className="input-item"
                                     onChange={e => handleChangeInput(e, `${!isSelected ? 'filterCourse' : 'filterQuiz'}`, `${!isSelected ? 'courses' : 'quiz'}`)}/>
                       <button type="button" className="icon-btn">
                           <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216"
                                viewBox="0 0 19.216 19.216">
                               <path className="a"
                                     d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"/>
                           </svg>
                       </button>
                   </div>

               </div>
               <ScrollArea
                   speed={0.8}
                   className="workflow-card-scroll"
                   contentClassName="content"
                   horizontal={false}
               >
                       <>
                           {courses && courses.length ? courses.map((el, i) => {
                                   return (
                                       <div className={`dndnode p-relative d-flex flex-wrap`}

                                           onDragStart={(event) => onDragStart(event, el.selType == 'course' ? elements?.filter((el) => el?.data?.selType == 'course').length >= 1 || elements.length > 0 ? 'selectorNodeOne' : 'selectorNodeThree' : 'selectorNodeThree', {name:el.name,id:el.id,selType:el.selType,image:el.image,modules_count:el.modules_count,quizzes_count:el.quizzes_count}
                                       )} draggable>
                                           {/*<div style={{textAlign:'left'}}>  {el.selType}</div>*/}

                                           <span
                                               className={`memb-close-icon-left  ${el?.selType == 'quiz' ? 'itsquiz-card' : 'itscourse-card'} flowquizleft`}>
                                                 <OverlayTrigger
                                                     key={i}
                                                     overlay={
                                                         <Tooltip id="InfoText">
                                                             {el?.name}
                                                         </Tooltip>
                                                     }
                                                 >
                                               <span>{el?.name?.substring(0, 70) + ' '  +  (el?.name?.length > 70 ?  '...' : '')}<span
                                               className="move-arrows"><i className="fa fa-arrows"></i></span>

                                               </span>
                                                        </OverlayTrigger>
                                           </span>
                                           <span className="common-card-oth-det-left mr-auto">Modules<span className="det-numb">{el?.modules_count}</span> </span>
                                           {/* <span className="flow-memb-close-icon flowquiz"
                                                  onClick={() => el?.selType == 'quiz' ? handleRemoveQuiz(el, i) : handleRemoveCourse(el, i)}>
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </span> */}
                                       </div>

                                   )

                               })
                               :
                               <>

                                   {courseSearch?.length > 0 ?

                                       <span className="no-items-selected">No Courses Found</span>

                                       :

                                       <span className="no-items-selected">No Courses Published Yet</span>


                                   }
                               </>

                           }

                 </>


                 </ScrollArea>
           </>
       </Tab>
       <Tab eventKey="Quiz" title="Quiz" tabClassName='zIndex'>
           <div className="add-quiz-box-header mt-3">

               <div className="form-input-wrap">
                   <Form.Control type="text" placeholder={`${!isSelected  ? 'Search Courses' : 'Search Quizzes'}`} className="input-item" onChange={e =>handleChangeInput(e,`${!isSelected  ? 'filterCourse' : 'filterQuiz'}`,`${!isSelected  ? 'courses' : 'quiz'}`)}/>
                   <button type="button" className="icon-btn">
                       <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                           <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                       </svg>
                   </button>
               </div>

           </div>
           <ScrollArea
               speed={0.8}
               className="workflow-card-scroll"
               contentClassName="content"
               horizontal={false}
           >

               <>

                   {quiz && quiz.length ? quiz.map((el, i) => {
                           return (
                               <div className={`dndnode p-relative d-flex  flex-wrap`}
                                    onDragStart={(event) => onDragStart(event, el.selType == 'quiz' ? elements?.filter((el) => el?.data?.selType == 'quiz').length >= 1 || elements.length > 0 ? 'selectorNodeTwo' : 'selectorNode' : 'selectorNodeOne',  {name:el?.name,id:el?.id,selType:el?.selType,questions_count:el?.questions_count,pass_score:el?.pass_score}
                                    )} draggable>
                                   {/*<div style={{textAlign:'left'}}>  {el.selType}</div>*/}

                                   <span className={`memb-close-icon-left ${el?.selType == 'quiz' ? 'itsquiz-card' : 'itscourse-card'} flowquizleft`}>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="InfoText">
                                                {el?.name}
                                            </Tooltip>
                                        }
                                    >
                                       <span>{el?.name?.substring(0, 70) + ' '  +  (el?.name?.length > 70 ?  '...' : '')}<span className="move-arrows"><i class="fa fa-arrows"></i></span>

                                   </span>
                                        </OverlayTrigger>

                                   </span>
                                   <span className="common-card-oth-det-left mr-auto">Questions<span className="det-numb">{el?.questions_count}</span> </span>
                                   {/* <span className="flow-memb-close-icon flowquiz"
                                              onClick={() => el?.selType == 'quiz' ? handleRemoveQuiz(el, i) : handleRemoveCourse(el, i)}>
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </span> */}


                               </div>

                           )

                       })
                       :
                      <>
                          <>
                              {quizSearch?.length > 0 ?

                                  <span className="no-items-selected">No Quizzes Found</span>

                                  :

                                  <span className="no-items-selected">No Quizzes Published Yet</span>


                              }
                          </>
                      </>
                   }
               </>

           </ScrollArea>
       </Tab>

    </Tabs>
    </div>
            {/*        <div className="add-quiz-box-header">*/}

            {/*            <div className="form-input-wrap">*/}
            {/*                <Form.Control type="text" placeholder={`${!isSelected  ? 'Search Courses' : 'Search Quizzes'}`} className="input-item" onChange={e =>handleChangeInput(e,`${!isSelected  ? 'filterCourse' : 'filterQuiz'}`,`${!isSelected  ? 'courses' : 'quiz'}`)}/>*/}
            {/*                <button type="button" className="icon-btn">*/}
            {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">*/}
            {/*                        <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />*/}
            {/*                    </svg>*/}
            {/*                </button>*/}
            {/*            </div>*/}

            {/*        </div>*/}
            {/*        <ScrollArea*/}
            {/*    speed={0.8}*/}
            {/*    className="workflow-card-scroll"*/}
            {/*    contentClassName="content"*/}
            {/*    horizontal={false}*/}
            {/*>*/}
            {/*        {!isSelected ?*/}
            {/*            <>*/}
            {/*                {courses && courses.length ? courses.map((el, i) => {*/}
            {/*                        return (*/}
            {/*                            <div className={`dndnode p-relative d-flex flex-wrap`}*/}
            {/*                                 onDragStart={(event) => onDragStart(event, el.selType == 'quiz' ? elements?.filter((el) => el?.data?.selType == 'quiz').length >= 1 ? 'selectorNodeTwo' : 'selectorNode' : 'selectorNodeOne', el*/}
            {/*                                 )} draggable>*/}
            {/*                                /!*<div style={{textAlign:'left'}}>  {el.selType}</div>*!/*/}
            {/*                                <span className={`memb-close-icon-left ${el?.selType == 'quiz' ? 'itsquiz-card' : 'itscourse-card'} flowquizleft`}><span>{el?.selType?.toUpperCase().charAt(0)+ el?.selType?.slice(1)}<span className="move-arrows"><i class="fa fa-arrows"></i></span></span></span>*/}
            {/*                                <div className="course-quiz-namespot">{el.name}</div>*/}
            {/*                                /!* <span className="flow-memb-close-icon flowquiz"*/}
            {/*                                      onClick={() => el?.selType == 'quiz' ? handleRemoveQuiz(el, i) : handleRemoveCourse(el, i)}>*/}
            {/*                        <i class="fa fa-trash-o" aria-hidden="true"></i>*/}
            {/*                        </span> *!/*/}


            {/*                            </div>*/}

            {/*                        )*/}

            {/*                    })*/}
            {/*                    :*/}
            {/*                    <span className="no-items-selected">No items selected</span>*/}
            {/*                }*/}

            {/*            </>*/}

            {/*            :*/}
            {/*            <>*/}

            {/*            {quiz && quiz.length ? quiz.map((el, i) => {*/}
            {/*                    return (*/}
            {/*                        <div className={`dndnode p-relative d-flex  flex-wrap`}*/}
            {/*                             onDragStart={(event) => onDragStart(event, el.selType == 'quiz' ? elements?.filter((el) => el?.data?.selType == 'quiz').length >= 1 ? 'selectorNodeTwo' : 'selectorNode' : 'selectorNodeOne', el*/}
            {/*                             )} draggable>*/}
            {/*                            /!*<div style={{textAlign:'left'}}>  {el.selType}</div>*!/*/}
            {/*                            <span className={`memb-close-icon-left ${el?.selType == 'quiz' ? 'itsquiz-card' : 'itscourse-card'} flowquizleft`}><span>{el?.selType?.charAt(0).toUpperCase() + el?.selType?.slice(1)}<span className="move-arrows"><i class="fa fa-arrows"></i></span></span></span>*/}
            {/*                            <div className="course-quiz-namespot">{el.name}</div>*/}
            {/*                            /!* <span className="flow-memb-close-icon flowquiz"*/}
            {/*                                  onClick={() => el?.selType == 'quiz' ? handleRemoveQuiz(el, i) : handleRemoveCourse(el, i)}>*/}
            {/*                        <i class="fa fa-trash-o" aria-hidden="true"></i>*/}
            {/*                        </span> *!/*/}


            {/*                        </div>*/}

            {/*                    )*/}

            {/*                })*/}
            {/*                :*/}
            {/*                <span className="no-items-selected">No items selected</span>*/}
            {/*        }*/}
            {/*            </>*/}
            {/*        }*/}
            {/*         </ScrollArea>*/}
                </div>

            </div>
        </aside>
    );
};
export default LearningPathLeft;
