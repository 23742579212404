import { Formik } from "formik";
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Alert, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import FirsttimeLoginImg from '~/assets/images/firsttimelogin-img.svg';
import Logo from '~/assets/images/logo.png';
import { createNewPassword, clearState, signIn } from '~/modules/auth/services';
import { useHistory } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetStore } from "../../services";
import Spinner from "react-bootstrap/Spinner";
import { signInSuccess, signInStart } from '../../services'
import { redirectUser } from "../../../../shared/utils/checkHost";
import PasswordChecklist from "react-password-checklist"


function CreateNewPassword({ match }) {
   const newHistory = useHistory();
   const { uid, token } = match?.params;

   const dispatch = useDispatch();
   const { user, loading, signUpError } = useSelector(state => state.auth);
   const userloading = useSelector(state => state.auth?.userDetails?.loading);
   const [showPassword, setShowPassword] = useState({
      newPassword: false,
      confirmNewPassword: false
   });


 
   const handleSubmit = (values) => {
      dispatch(createNewPassword({ ...values, uid, token }, newHistory, function (response) {
         if (response) {
            let action = 'USER_LOGOUT'
            dispatch(resetStore(action))
            // toast.success("Password created successfully")
    
            dispatch(signInStart());
            
            localStorage.setItem('token', response?.data?.token);
            var now = new Date().getTime();
            sessionStorage.setItem('login_time', `${now}`);
            dispatch(signInSuccess(response));
            redirectUser(response?.data, newHistory, userloading)

         }
      }, function (err) {


      }));
      setTimeout(() => {
         dispatch(clearState("error"));
      }, 9000);
   };
   const handleLoader = () => {
      if (loading) {
         return (
            <div className="loader-full-screen-course">
               <div className="loader">
                  <Spinner animation="border" variant="danger" />
               </div>
            </div>
         );
      }
   };


   const handleClickShowNewPassword = () => {
      setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword });
   };

   const handleClickShowConfirmNewPassword = () => {
      setShowPassword({ ...showPassword, confirmNewPassword: !showPassword.confirmNewPassword });
   };



   return (
      <div className="auth-page-wrap">
         {handleLoader()}
         <ToastContainer autoClose={2000} />

         <div className="auth-page-box d-flex">
            <div className="auth-right d-flex justify-content-center align-items-center">
               <div className="auth-cont-box">
                  <div className="auth-cont-box-header text-center">
                     <div className="auth-logo"><img src={Logo} alt="" /></div>
                     <h2 className="auth-title">Create New Password</h2>
                     <p className="auth-desc">Password should be a minimum of 8 characters, and include one upper case, and at least one number.</p>
                  </div>
                  <div className="auth-form-box white-box radius-box">
                     <Formik
                        validationSchema={CreatePasswordSchema}
                        onSubmit={handleSubmit}
                        initialValues={{
                           new_password1: '',
                           new_password2: '',
                        }}
                     >
                        {({
                           handleSubmit,
                           handleChange,
                           values,
                           errors,
                           touched,
                           handleBlur
                        }) => (

                           <Form noValidate onSubmit={handleSubmit}>
                              <div className="form-input-wrap cus-icon" style={{ position: 'relative' }}>
                                 <Form.Label>New Password</Form.Label>
                                 <Form.Control
                                    type={showPassword.newPassword ? 'text' : 'password'}
                                    name="new_password1"
                                    placeholder="Create New Password"
                                    value={values.new_password1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.new_password1 && !!touched.new_password1} />
                                 <span
                                    style={{ position: 'absolute', top: 30, right: 10, cursor:'pointer' }}
                                    onClick={handleClickShowNewPassword}

                                 >
                                    {showPassword.newPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                       <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                       <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                       <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                       <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>}
                                 </span>
                                 <Form.Control.Feedback type="invalid">
                                    {errors.new_password1}
                                 </Form.Control.Feedback>

                                 <div>
                                    <PasswordChecklist
                                        rules={["minLength", "number", "capital",]}
                                        minLength={8}
                                        value={values.new_password1}
                                        // valueAgain={passwordAgain}
                                       //  onChange={(isValid) => { handleChecklist(isValid) }}
                                    />
                                    </div>

                              </div>
                              <div className="form-input-wrap cus-icon" style={{ position: 'relative' }}>
                                 <Form.Label>Confirm New Password</Form.Label>
                                 {/* <Form.Control type="password" placeholder="Your Password" className="input-item" /> */}
                                 <Form.Control
                                    type={showPassword.confirmNewPassword ? 'text' : 'password'}
                                    name="new_password2"
                                    placeholder="Re-Enter Password"
                                    value={values.new_password2}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.new_password2 && !!touched.new_password2} />

                                 <span
                                    style={{ position: 'absolute', top: 30, right: 10, cursor:'pointer' }}
                                    onClick={handleClickShowConfirmNewPassword}

                                 >
                                    {showPassword.confirmNewPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                       <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                       <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                       <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                       <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>}
                                 </span>


                                 <Form.Control.Feedback type="invalid">
                                    {errors.new_password2}
                                 </Form.Control.Feedback>
                              </div>

                              <div className="button-wrap">
                                 <Button type="submit" className="w-100" disabled={loading}>Continue</Button>
                              </div>
                           </Form>
                        )}
                     </Formik>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

const CreatePasswordSchema = yup.object().shape({
   new_password1: yup.string().required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Password must include one upper case, and at least one number.'),
   new_password2: yup.string().required('Provide confirm password').oneOf([yup.ref('new_password1'), null], 'Passwords must match')
});


// const CreatePasswordSchema = yup.object().shape({
//    old_password: yup.string().required('Current Password is required'),
//    new_password1: yup.string().required('New Password is required')
//        .min(8, 'Password is too short - should be 8 chars minimum.')
//        .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
//        .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
//        .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
//        //    .matches(/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Password must include one upper case, and at least one number.')
//        .matches(/^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/, 'Atleast one special character'),
//    new_password2: yup.string().required('Confirm new password is required')
//        .test('passwords-match', 'New Password and Confirm Password must match', function (value) {
//            return this.parent.new_password1 === value;
//        })
//        .min(8, 'Password is too short - should be 8 chars minimum.')
//        .matches(/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Confirm new password must include one upper case, and at least one number.'),
// });

export default CreateNewPassword;
