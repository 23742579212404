import React from 'react';
import Form from 'react-bootstrap/Form';
import ScrollArea from 'react-scrollbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ItemSelected from './itemselected'
function AddQuizAndCourse() {
   return (
      <div className="coursedetails-wrap">
         <Row className="learningpath-select-sec">
            <Col md={5}>

               <div className="learning-path-selectbox">
                  <h3 className="tab-title">Add Courses</h3>
                  <div className="add-quiz-box whitebox shadow-box">
                     <div className="add-quiz-box-header">
                        <Form>
                           <div className="form-input-wrap">
                              <Form.Control type="text" placeholder="Your First Name" className="input-item" />
                              <button type="button" className="icon-btn">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                                    <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                                 </svg>
                              </button>
                           </div>
                        </Form>
                     </div>

                     <div className="add-quiz-box-content">
                        <ScrollArea
                           speed={0.8}
                           className="listscroll"
                           contentClassName="content"
                           horizontal={false}
                        >
                           <ul>
                              <li className="list-selected">
                                 <span>Select all</span>
                              </li>
                              <li>
                                 <span>Course name</span>
                              </li>
                              <li>
                                 <span>Course name</span>
                              </li>
                              <li className="list-selected">
                                 <span>Course name</span>
                              </li>
                              <li>
                                 <span>Course name</span>
                              </li>
                              <li>
                                 <span>Course name</span>
                              </li>
                              <li>
                                 <span>Course name</span>
                              </li>
                              <li>
                                 <span>Course name</span>
                              </li>
                           </ul>
                        </ScrollArea>
                     </div>

                  </div>
               </div>
            </Col>
            <Col md={2}>
               <span className="border-here">&nbsp;</span>
            </Col>
            <Col md={5}>

               <div className="learning-path-selectbox learning-path-selectbox-right">
                  <h3 className="tab-title">Add Quiz</h3>
                  <div className="add-quiz-box whitebox shadow-box">
                     <div className="add-quiz-box-header">
                        <Form>
                           <div className="form-input-wrap">
                              <Form.Control type="text" placeholder="Your First Name" className="input-item" />
                              <button type="button" className="icon-btn">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                                    <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                                 </svg>
                              </button>
                           </div>
                        </Form>
                     </div>

                     <div className="add-quiz-box-content">
                        <ScrollArea
                           speed={0.8}
                           className="listscroll"
                           contentClassName="content"
                           horizontal={false}
                        >
                           <ul>
                              <li>
                                 <span>A Quiz Title</span>
                              </li>
                              <li>
                                 <span>Add Another Title</span>
                              </li>
                              <li className="list-selected">
                                 <span>Quiz title 3</span>
                              </li>
                              <li>
                                 <span>A Quiz Title</span>
                              </li>
                              <li>
                                 <span>Add Another Title</span>
                              </li>
                              <li className="list-selected">
                                 <span>A Quiz Title</span>
                              </li>
                              <li>
                                 <span>Add Another Title</span>
                              </li>
                              <li>
                                 <span>A Quiz Title</span>
                              </li>
                           </ul>
                        </ScrollArea>
                     </div>
                  </div>
               </div>
            </Col>
         </Row>

         <div className="m-t-20">
            <ItemSelected />
         </div>
      </div>
   );
};
export default AddQuizAndCourse;