import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from "./ckQues";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer } from "react-toastify";
import { FormControl } from "react-bootstrap";
import { deleteQuizChoice } from "../../../../../services";

function MultipleChoice(props) {
    const dispatch = useDispatch();
    let loading = useSelector(state => state?.survey_builder?.get_survey_manager?.loading);
    const { values, errors, index, setFieldValue, general_questionsErrors, general_questionsTouched, handleBlur, setValues } = props
    const [showinput, setshowinput] = useState(false)
    const [hideicon, sethideicon] = useState(true)
    const [choiceid, setchoiceid] = useState('')
    const [reversMode, setReverseMode] = useState(false)


    useEffect(() => {
        if (values?.general_questions[index]?.multiple_choices[3]?.choice?.length > 0) {
            sethideicon(false)
        }
    }, [])


    // }
    const handleThird = (e) => {
        setFieldValue(`general_questions.${index}.multiple_choices.${2}.choice`, e.target.value)
    }
    const handleRemoveIcone = (i) => {
        setReverseMode(true)
        const general_questions = [...values.general_questions]
        general_questions[index]?.multiple_choices?.pop()

        setValues({ ...values, general_questions });
    }

    const handleAddIcon = () => {
        const general_questions = [...values.general_questions]
        general_questions[index]?.multiple_choices?.push({ choice: '' })

        setValues({ ...values, general_questions });
    }

    const handleAddIconView = () => {
        if (hideicon) {
            return (<span style={{ marginTop: '29px' }} className="memb-add-icon-quiz" onClick={handleAddIcon}>+</span>)
        } else {
            return (<span></span>)
        }

    }
    const SELECT_OPTIONS = [1, 2, 3, 4];
    const SELECT_OPTIONS_THIRD = [1, 2, 3]
    return (
        <div className="module-quiz-add-wrap">
            <ToastContainer autoClose={2000} />
            {/*<div className="form-close-btn p-relative"><span className="memb-close-icon">×</span></div>*/}
            <Form>
                <Row className="justify-content-center">


                     <Col md={12} >
                        <Row >
                            <Col md={6} >
                                <div className="form-input-wrap">
                                    <Form.Label>Add Question Title</Form.Label>
                                    <span className="mandatory-star">*</span>
                                    <Form.Control maxlength="20"
                                        value={values?.general_questions[index]?.title ? values?.general_questions[index]?.title : ''}
                                        name={`general_questions.${index}.title`}
                                        onChange={e => setFieldValue(`general_questions.${index}.title`, e.target.value)}
                                        isInvalid={!!general_questionsErrors?.title && general_questionsTouched?.title && !!general_questionsTouched?.title}
                                        placeholder={`Question - ${index + 1}`}       
                                        minLength="8" />
                                    <Form.Text id="passwordHelpBlock" muted>
                                    Your title must not exceed 20 characters

                                    </Form.Text>


                                </div>
                            </Col>
                        </Row>

                    </Col> 

                    <Col md={12} >
                        <div className="form-input-wrap">
                            <Form.Label>Add Question</Form.Label>
                            {/* <CkEditorQuestion    handleBlur={handleBlur} general_questionsErrors={general_questionsErrors} general_questionsTouched={general_questionsTouched} name={`general_questions.${index}.question`} setFieldValue={setFieldValue} values={values} index={index}/> */}
                            <span className="mandatory-star">*</span>
                            <Form.Control
                                // onChange={(e) => { console.log("toggle value", e.target.value) }}

                                value={values?.general_questions[index]?.question}
                                name={`general_questions.${index}.question`}
                                onChange={e => setFieldValue(`general_questions.${index}.question`, e.target.value)}
                                isInvalid={!!general_questionsErrors?.question && general_questionsTouched?.question && !!general_questionsTouched?.question}


                                as="textarea" rows={4} />

                        </div>
                    </Col>
                    <Col md={12}>
                        <Row className="">

                            {values?.general_questions && values?.general_questions?.length && values?.general_questions[index] && values?.general_questions[index]?.multiple_choices && values?.general_questions[index]?.multiple_choices.length && values?.general_questions[index]?.multiple_choices.map((el, i, arr) => (
                                <>
                                    <Col md={11}>
                                        <div className="form-input-wrap">

                                            <div className="form-input-wrap">
                                                <Form.Label>Option{i + 1}</Form.Label>
                                                {i <= 1 ? <span className="mandatory-star">*</span> : ''}
                                                <Form.Control
                                                    type="text" placeholder={`Option ${i + 1}`} maxlength="150"
                                                    value={values?.general_questions[index]?.multiple_choices[i]?.choice}
                                                    name={`general_questions.${index}.multiple_choices.${i}.choice`}
                                                    onChange={e => setFieldValue(`general_questions.${index}.multiple_choices.${i}.choice`, e.target.value)}
                                                    isInvalid={!!general_questionsErrors?.multiple_choices && !!general_questionsErrors?.multiple_choices?.length && !!general_questionsErrors?.multiple_choices[i] && general_questionsTouched?.multiple_choices?.length && general_questionsTouched?.multiple_choices?.length && !!general_questionsTouched?.multiple_choices[i] && !!general_questionsTouched?.multiple_choices[i]}
                                                // onChange={e=>setFieldValue(`general_questions.${index}.multiple_choices.${0}.choice`,e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    {arr?.length - 1 == i &&
                                        <Col md={1} className="p-l-0">
                                            <div className='d-flex justify-content-end'>

                                                {arr.length < 10 &&
                                                    <span style={{ marginTop: '29px' }} className="memb-add-icon-quiz mr-2"
                                                        onClick={handleAddIcon}>+</span>
                                                }
                                                {i > 2 &&
                                                    <span style={{ marginTop: '29px' }} className="memb-add-icon-quiz"
                                                        onClick={() => handleRemoveIcone(i)}>-</span>
                                                }
                                            </div>
                                        </Col>
                                    }
                                </>

                            ))

                            }

                        </Row>
                        <Form.Text id="passwordHelpBlock" muted>
                            At least two options are mandatory
                        </Form.Text>



                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default MultipleChoice;

