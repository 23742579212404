import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardDummy from '~/assets/images/card.svg';
import { Link } from 'react-router';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav'
import { useDispatch, useSelector } from 'react-redux';
import {getCardDetails, deleteCard, updateCard} from '../../../../services';
import { Stripe, CardElement, injectStripe, Elements, ElementsConsumer,useElements, useStripe } from "@stripe/react-stripe-js";
 import { loadStripe } from "@stripe/stripe-js";
 import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import {callback} from "chart.js/helpers";
import Spinner from "react-bootstrap/Spinner";

 const CheckoutForm = ({onClick}) => {
   const stripe = useStripe();
   const elements = useElements();
   const dispatch = useDispatch();
   const { user } = useSelector(state => state.auth);
   const history = useHistory();
     const subStatusResponse = useSelector(state => state?.auth?.subscription_status?.response);
   const updateResponse = useSelector(state => state?.profile?.update_card?.response);
     const updateLoading = useSelector(state => state?.profile?.update_card?.loading);
   useEffect(() => {
    if (updateResponse) {
      history.push('/profile');
   }
    }, [updateResponse]);

   const handleSubmit = async (event) => {
     event.preventDefault();

     if (!stripe || !elements) {
       return;
     }

     const cardElement = elements.getElement(CardElement);

     const {error, paymentMethod} = await stripe.createPaymentMethod({
       type: 'card',
       card: cardElement,
     });

     if (!error) {
       const formdata = new FormData();
       formdata.append('payment_method_id',paymentMethod.id)
       dispatch(updateCard(user?.token, formdata,function (res){
           if(res){
               onClick()
               setTimeout(()=>{
                   dispatch(getCardDetails(user?.token));
               },300)
           }

       }));

     }
   };

   return (
       <>

     <form onSubmit={handleSubmit}>

       <CardElement
         options={{
           style: {
             base: {
               fontSize: '16px',
               color: '#424770',
               '::placeholder': {
                 color: '#aab7c4',
               },
             },
             invalid: {
               color: '#9e2146',
             },
           },
           hidePostalCode: true

         }}
       />
       <br />
       <button type="submit"  className="btn btn-primary small-btn" disabled={!stripe}>
           {updateLoading && updateLoading ?

               <Spinner animation="border" variant="warning"/>
               :

               'Save'
           }
       </button> &nbsp;
       <button  className="btn btn-primary small-btn" onClick ={onClick}>
         Cancel
       </button>
     </form>
           </>
   );
 };
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);


const MyCards = () => {
   const dispatch = useDispatch();
   const { user } = useSelector(state => state.auth);
   const cardResponse = useSelector(state => state.profile?.card_details?.response);
    const cardLoadind = useSelector(state => state.profile?.card_details?.loading);
   const deleteResponse = useSelector(state => state.profile?.delete_card_details?.response);
    const deleteLoading = useSelector(state => state.profile?.delete_card_details?.loading);
    const subStatusResponse = useSelector(state => state?.auth?.subscription_status?.response);
    const responseDetails = useSelector(state => state.profile.subscription_details?.response);
    const [response,setResponse] = useState(cardResponse)
   const [show, setShow] = useState(false);
    const [addbtn, setaddbtn] = useState(true);
  //  useEffect(() => {
  //      if(user?.user_type == 'manager' || user?.user_type == 'admin') {
  //          dispatch(getCardDetails(user?.token));
  //      }
  //   }, []);

    useEffect(() => {
        if(user?.user_type == 'manager' || user?.user_type == 'admin') {
            dispatch(getCardDetails(user?.token));
        }
      }, [deleteResponse]);

    useEffect(()=>{
        setResponse(cardResponse)
    },[cardResponse])

    const deleteCardDetails = () =>{

      dispatch(deleteCard(user?.token,function (){
          toast.success('Card deleted successfully')
          setShow(false);
      }));
    };

    const cardDetails = () =>{
      setShow(true);
        setaddbtn(false);
    };

    const hideCardDetails = () =>{
      setShow(false);
        setaddbtn(true);
    };
  if(responseDetails?.data?.amount){
   return (
       <>
      <div className="tab-content">
          {!cardLoadind ?




         <Row>


                     {!show && response?.data?.card?.last4 ?
                         <Col xs={12} md={4} lg={3}>
                             <div className="common-card cards-card ">
                                 <div className="payment-cards-photo">
                                     <img src={CardDummy} alt=""/>
                                 </div>
                                 <p>********{response?.data?.card?.last4}</p>

                             </div>
                             <div className="cards-card-footer d-flex justify-content-between align-items-center">
                                 {/*<a className="text-link" onClick={cardDetails}>Update payment details</a>*/}
                                 {/*{response?.data?.card?.card?.last4 &&*/}
                                 {/*// <button className="btn btn-primary small-btn" onClick={deleteCardDetails}>*/}
                                 {/*//*/}
                                 {/*//     {deleteLoading && deleteLoading ?*/}
                                 {/*//*/}
                                 {/*//         <Spinner animation="border" variant="warning"/>*/}
                                 {/*//         :*/}
                                 {/*//*/}
                                 {/*//         'Delete'*/}
                                 {/*//     }*/}
                                 {/*// </button>*/}
                                 {/*}*/}
                             </div>
                         </Col>
                         :
                         <>
                             {addbtn ?
                                 <>
                                     <div className='col-12 text-center pt-2'>No Existing Card</div>
                                     <div>
                                         {/*<button className="btn btn-primary small-btn" onClick={cardDetails}>Add New*/}
                                         {/*    Card*/}
                                         {/*</button>*/}
                                     </div>
                                 </>

                                 :
                                 ''
                             }
                         </>

                     }
                     {show &&
                     <Col xs={12} md={4} lg={6}>
                         <Elements stripe={stripePromise}>
                             <CheckoutForm onClick={hideCardDetails}/>
                         </Elements>

                     </Col>
                     }


         </Row>
              :

              <Spinner animation="border" variant="warning"/>

          }
      </div>
       </>
   )}
   else {
    return (
        <>


            <span> {subStatusResponse && subStatusResponse?.is_trial_plan ? 'You are on trial plan'  :  ' There are no active subscriptions' }</span>
        </>
    )
  }
};



export default MyCards;
