import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import PageWrapper from '~/shared/components/pagewraper';
import CheckoutOnSignup from "../modules/auth/checkoutonsignup/container";
import CreateNewPassword from "../modules/auth/createnewpassword/container";
import Firsttimelogin from "../modules/auth/firsttimelogin/container";
// import NormalLogin from "../modules/auth/normallogin/container";
import SurveysManagement from "../modules/surveys/surveyManagement/mainContainer";
import ForgotPassword from "../modules/auth/forgotpassword/container";
import Registration from "../modules/auth/registration/container";
import ResetPassword from "../modules/auth/resetpassword/container";
import SignUp from '../modules/auth/signup/container';
import SubdomainSignIn from "../modules/auth/subdomainsignin/container";
import SubscriptinOnSignup from "../modules/auth/subscriptiononsignup/container";
import Dashboard from "../modules/dashboard/container";
// import Reports from "../modules/reports/container";
import LearningPathListing from '../modules/learningpath/components/learningpathlisting/container';
import LearningPathDetails from '../modules/learningpath/components/learningpathdetails/container';
// import Courses from '../modules/courses/container';
import Profile from '../modules/profile/container';
import CourseManagement from '../modules/coursemanagement/container';
import CourseManagementEdit from '../modules/coursemanagement/components/coursemanagementedit/container';
import CourseBuilder from '../modules/coursemanagement/components/coursebuilder/container';
import Payment from '../modules/profile/container/components/payment/container';
// import CreateSurvey from '../modules/surveys/createsurvey/container';
import Surveystart from "../modules/surveys/surveyDisplay/surveystart";
import SurveyCreation from "../modules/surveys/surveyManagement/container";
import QuizManagement from '../modules/quizmanagement/container';
import QuizDetails from '../modules/quizmanagement/components/availablequizzes/quizdetails';
import QuizBuilder from '../modules/quizmanagement/components/quizbuilder/container';
import SurveyManagement from '../modules/surveymanagement/container';
import SurveyAssignment from "../modules/surveys/surveyAssignment/container";
import Team from '../modules/team/container';
import MemberDetails from '../modules/team/components/memberdetails';
import ClassDetails from '../modules/team/components/classesdetails';

import GroupDetails from '../modules/team/components/groupdetails';
// import SurveyListing from '../modules/surveys/container';
import SurveyDetail from '../modules/surveys/components/surveydetails';
import SurveyComplete from  '../modules/surveys/surveyDisplay/surveycomplete';
import SurveyBuilder from '../modules/surveymanagement/components/surveybuilder/container';
import LearningPathManagement from '../modules/learningpathmanagement/container';
import LearningPathBuilder from '../modules/learningpathmanagement/components/learningpathbuilder/container';
import ManagerSurveyListing from "../modules/surveys/managerSurvey/container";
import LearnerSurveyDetail from "../modules/surveys/learnerSurvey/components/learnerSurveyDetail";
// import SurveyReports from "../modules/surveyreports/container";
import ComingSoon from "../modules/comingsoon/container";
import SurveyReports from "../modules/surveys/surveyReport/availablesurveys"
import ThankYou from "../modules/thankyou/container";
import PageNotFound from "../modules/errorpages/pagenotfound";
import PageNoFound from "../modules/errorpages/pagenofound";
import ServerError from "../modules/errorpages/servererror";
import ReportsNew from "../modules/surveys/surveyReport/surveyreports/container";
import Settings from "../modules/settings/container";
import SubdomainSignUp from "../modules/auth/setupsubdomain/container";
import SubDomainSign from "../modules/auth/subdomainsignin/container";
import PrivacyPolicy from '../modules/privacypolicy';
import SupportUs from '../modules/supportus';
import LearnerCourseListing from '../modules/courses/components/learnerlisting/container'
import LearnerCourseDetails from "../modules/courses/components/learnerlisting/learnercoursedetail/container";
import EnrollUsers from '../modules/coursemanagement/components/coursebuilder/components/enrollusers'
import LearnerGroups from '../modules/team/components/groups/learnerGroups.js'
import CourseCompleted from '../shared/components/coursecompleted';
import LearningPath from '../modules/learningpath'
import LearningManagement from "../modules/learningpath/container";
import LearningPathLearnerDetails from '../modules/learningpath/components/learningpathdetails/container';
import ManagerLearingPathDetail from '../modules/learningpath/components/managerlearningpathdetail';
import LearnersDashboard from '../modules/learnersdashboard';
import LearnerLearningPathDashboard from '../modules/learnerLearningPath/learningdashboard'
import LearningManagementEdit from "../modules/learningpath/components/learningpathedit";
import LearningPathContainer from "../modules/learningpath/components/workFlow/container";
import LearnerLearningPathListing from  "../modules/learnerLearningPath/container/components/myLearning";
import Certificate from '../modules/learningpath/components/learningpathdetails/components/certificate';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    MiniMap,
    Controls,
    Background,
    removeElements,
    useZoomPanHelper

} from 'react-flow-renderer';
import SurveyCreate from '../modules/surveys/surveyManagement/surveybuilder/container';
import myLearningListing from '../modules/learnerLearningPath/container';
import SurveyDetails from '../modules/surveymanagement/components/availablesurveys/surveydetail';
import LpAssigment from  "../modules/learningpath/components/enrollusersLP"
import NodsListing from '../modules/surveys/nodeslisting/nodeslisting';
import EnterSubdomain from '../modules/auth/entersubdomain/container';
import SendReminder from '../modules/auth/sendreminder/container';
import MamagerSurveyDetail from  '../modules/surveys/components/surveydetails';
import NodeList from "../modules/learnerLearningPath/container/components/nodeslisting/nodeslisting";
import SurveyGraph from "../modules/surveys/survey-graph"
import LearnersListing from '../modules/learningpath/components/managerlearningpathdetail/components/learnerslist';
import AdminCourseDetail from '../modules/coursemanagement/components/coursemanagementedit/container/courseDetailAdmin'
import LearningPathDetailAdmin from '../modules/learningpath/components/managerlearningpathdetail/learningPathAdmin'
import LinkExpired from '../shared/components/linkexpired';
import OtpLogin from '../modules/auth/otplogin/container';
import EnterOtp from '../modules/auth/enterotp/container';
import LoginTab from '../modules/auth/logintab/container';
import Forbidden from '../modules/errorpages/forbidden';
import InstitutionContact from '../modules/institutioncontact/container'
import SignpTab from '../modules/auth/signuptab/container';
import SubscriptionCancelled from '../shared/components/subscriptioncancelled';



/**
 * Application main router component
 *
 * contains all the available routes and components in the application
 */
const Router = ({ match }) => {
    const auth = useSelector(state => state.auth);
    const { hideLpQuizes } = useSelector((state) => state.settings);


//     const [params, setParams] = useState(null)
//    const location=  useLocation()
   
//    const a ='http://aptree.localhost:8080/api/providers/edlink-token/?code=xNdVKwprdxVU22POQSyEKInk8cRP4QNq&state=%7B%27origin%27%3A+%27http%3A%2F%2Faptree.localhost%3A8080%27%2C+%27state%27%3A+%27login_url%27%7D'

//    useEffect(()=>{
//     const queryParams= new URLSearchParams(location.search)
//     const singleValue= queryParams.get('code')
//     setParams(singleValue)
//    },[])

//    console.log('params', params)
    return (
        <Switch>
            <Route exact path='/' component={EnterSubdomain} />
            {/* <Route exact path='/login' component={Firsttimelogin} /> */}
            <Route exact path='/login' component={LoginTab} />

            <Route exact path='/otplogin' component={OtpLogin} />
            <Route exact path='/enterotp' component={EnterOtp} />
            <Route exact path='/logintab' component={LoginTab} />
            {/* <Route exact path='/signuptab' component={SignpTab} /> */}

            <Route path='/signUp' component={SignUp} />
            <Route path='/subscriptiononsignup' component={SubscriptinOnSignup} />
            <Route path='/checkoutonsignup' component={CheckoutOnSignup} />
            <Route path='/firsttimelogin' component={Firsttimelogin} />
            <Route path='/sso-auth' component={Firsttimelogin} />
            {/* <Route path={`/providers/edlink-token/?code=${}&state=${}`}component={Firsttimelogin} /> */}
            <Route path='/edlink-token' component={Firsttimelogin} />
            <Route path='/resetpassword' component={ResetPassword} />
            {/* <Route  path='/login' component={NormalLogin} /> */}
            <Route path='/forgotpassword' component={ForgotPassword} />
            <Route path='/reset-password/:uid/:token' component={CreateNewPassword} />
            <Route path='/subdomain/signin' component={SubDomainSign} />
            <Route path='/survey/details/:uuid' component={LearnerSurveyDetail} />
            <Route path='/api/survey/attend/:uuid' component={LearnerSurveyDetail} />
            <Route path='/submit/survey/:uuid' component={Surveystart} />
            <Route  path='/thankyou' component={ThankYou} />
            <Route path='/complete-profile' component={Registration} />
            <Route path='/subdomainsignup' component={SubdomainSignUp} />
            <Route path='/sendreminder' component={SendReminder} />
            <Route path='/surveyComplete' component={SurveyComplete} />
                <Route  path='/servererror'   component={ServerError} />
                <Route  path='/linkexpired'   component={LinkExpired} />
                <Route  path='/subscriptioncancelled'   component={SubscriptionCancelled} />
                <Route  path='/forbidden'   component={Forbidden} />
                <RestrictedRoute forbidden={true} path='/dashboard' roles= {['admin','staff_manager','manager','learner']} component={Dashboard} auth={auth} routeName ='Dashboard'/>
                <RestrictedRoute  path='/Learningpathlisting'  roles= {['admin','staff_manager','manager']} component={LearningPathListing} auth={auth} routeName ='Learning Paths'  />
                <RestrictedRoute  path='/learningpathdetails/:id'  roles= {['admin','staff_manager','manager','learner']} component={LearningPathDetails} auth={auth} routeName ='Assignments'/>
                <RestrictedRoute  path='/learnercoursedetails/:id'  roles= {['admin','staff_manager','manager','learner']} component={LearnerCourseDetails} auth={auth} routeName ='Assignments' />
                <RestrictedRoute  path='/profile' roles= {['admin','staff_manager','manager','learner']} component={Profile} auth={auth} routeName ='Profile'  />
                <RestrictedRoute  path='/payment' roles= {['admin','staff_manager','manager']}  component={Payment} auth={auth} routeName ='Payment'  />
                {/* <RestrictedRoute  path='/surveys' roles= {['admin','staff_manager','manager']} component={CreateSurvey} auth={auth} routeName ='Survey Library' /> */}
                {/* <RestrictedRoute  path='/survey/:id' roles= {['admin','staff_manager','manager','learner']} component={Surveystart} auth={auth} routeName ='Surveys'  /> */}
                <RestrictedRoute  path='/survey_report/:id' roles= {['admin','staff_manager','manager']} component={ReportsNew} auth={auth} routeName ='Survey Reports' />
                <RestrictedRoute  path='/create_survey' roles= {['admin','staff_manager','manager']} component={SurveysManagement} auth={auth} routeName ='Survey Library' />
                <RestrictedRoute  path='/edit_survey/:id' roles= {['admin','staff_manager','manager']} component={SurveysManagement} auth={auth} routeName ='Survey Library'  />
                <RestrictedRoute forbidden={hideLpQuizes}   path='/quizmanagement' roles= {['admin','staff_manager','manager']} component={QuizManagement} auth={auth} routeName ='Quiz Library'  />
            <RestrictedRoute  path='/draft/quizzes' roles= {['admin','staff_manager','manager']} component={QuizManagement} auth={auth} routeName ='Quiz Library'  />
            <RestrictedRoute forbidden={hideLpQuizes}  path='/quizbuilder/:id' roles= {['admin','staff_manager','manager']} component={QuizBuilder} auth={auth}  routeName ='Quiz Library'/>
                <RestrictedRoute forbidden={hideLpQuizes} path='/quizbuilder' roles= {['admin','staff_manager','manager']} component={QuizBuilder} auth={auth}  routeName ='Quiz Library'/>
                <RestrictedRoute forbidden={hideLpQuizes} path='/quizdetails/:id' roles= {['admin','staff_manager','manager']} component={QuizDetails} auth={auth}  routeName ='Quiz Library'/>
                <RestrictedRoute  path='/surveymanagement'  roles= {['admin','staff_manager','manager']} component={ManagerSurveyListing} auth={auth}  routeName ='Survey Library'/>

                <RestrictedRoute  path='/coursemanagement' roles= {['admin','staff_manager','manager']} component={CourseManagement} auth={auth} routeName ='Course Library'  />
                <RestrictedRoute  path='/course/:id/enroll_users' roles= {['admin','staff_manager','manager']} component={EnrollUsers} auth={auth} routeName ='Course Library'  />
                <RestrictedRoute  path='/draft/courses' roles= {['admin','staff_manager','manager']} component={CourseManagement} auth={auth} routeName ='Course Library'  />
                <RestrictedRoute  path='/coursemanagementedit/:id' roles= {['admin','staff_manager','manager']} component={CourseManagementEdit} auth={auth} routeName ='Course Library' />
                <RestrictedRoute  path='/course/admin/:id' roles= {['admin','staff_manager','manager']} component={AdminCourseDetail} auth={auth} routeName ='Course Library'   />

                <RestrictedRoute  path='/coursebuilder' roles= {['admin','staff_manager','manager']} component={CourseBuilder} auth={auth} routeName ='Course Library'   />
                <RestrictedRoute  path='/editcourse/:id' roles= {['admin','staff_manager','manager']} component={CourseBuilder} auth={auth} routeName ='Course Library'   />
                {/* <RestrictedRoute  path='/survey_list' roles= {['admin','staff_manager','manager','learner']} component={LearnerSurvey} auth={auth} routeName ='Surveys'  /> */}
                <RestrictedRoute path='/survey_assignment/:id' roles= {['admin','staff_manager','manager']}  component={SurveyAssignment} auth={auth} routeName ='Survey Library'  />
                <RestrictedRoute  path='/team' roles= {['admin','staff_manager','manager']} component={Team} auth={auth} routeName ='Teams'   />
                <RestrictedRoute  path='/edit_member/:id' roles= {['admin','staff_manager','manager']} component={MemberDetails} auth={auth} routeName ='Member Details'   />
                <RestrictedRoute  path='/edit_group/:id' roles= {['admin','staff_manager','manager']} component={GroupDetails} auth={auth} routeName ='Group Details'  />
                {/* <RestrictedRoute  path='/surveys' roles= {['admin','staff_manager','manager']} component={SurveyListing} auth={auth} routeName ='Surveys'  /> */}
                {/* <RestrictedRoute  path='/surveydetails/:id' roles= {['admin','staff_manager','manager','learner']} component={LearnerSurveyDetail} auth={auth} routeName ='Surveys'  /> */}
                <RestrictedRoute  path='/createsurveys/:id' roles= {['admin','staff_manager','manager']} component={SurveyBuilder} auth={auth} routeName ='Survey Library'   />
                <RestrictedRoute  path='/learningpathmanagement' roles= {['admin','staff_manager','manager']} component={LearningPathManagement} auth={auth} routeName ='Learning Paths'  />
                <RestrictedRoute forbidden={hideLpQuizes}   path='/learningpathbuilder'  roles= {['admin','staff_manager','manager']} component={LearningPathBuilder} auth={auth} routeName ='Learning Paths'  />
                <RestrictedRoute  path='/surveyreports' roles= {['admin','staff_manager','manager']} component={SurveyReports} auth={auth} routeName ='Survey Reports'   />
            <RestrictedRoute  path='/surveydetails/:id' roles= {['admin','staff_manager','manager']} component={MamagerSurveyDetail} auth={auth} routeName ='Survey Library'  />
                <RestrictedRoute  path='/thankyou' roles= {['admin','staff_manager','manager','learner']} component={ThankYou} auth={auth} routeName ='Surveys'   />
                <RestrictedRoute  path='/reportsnew' roles= {['admin','staff_manager','manager']} component={ReportsNew} auth={auth} routeName ='Survey Reports'  />
                <RestrictedRoute  path='/servererror' roles= {['admin','staff_manager','manager','learner']} component={ServerError} auth={auth} routeName ='Access Denied'  />
                <RestrictedRoute  path='/settings' roles= {['admin','manager']} component={Settings} auth={auth} routeName ='Settings'  />
                <RestrictedRoute  path='/memberdetails/:id' roles= {['admin','staff_manager','manager']} component={MemberDetails} auth={auth} routeName ='Member Details'  />
                <RestrictedRoute  path='/classdetails/:id' roles= {['admin','staff_manager','manager']} component={ClassDetails} auth={auth} routeName ='Class Details'   />
        
                <RestrictedRoute  path='/groupdetails/:id' roles= {['admin','staff_manager','manager']} component={GroupDetails} auth={auth} routeName ='Group Details'   />
                <RestrictedRoute  path='/privacypolicy' roles= {['admin','staff_manager','manager']} component={PrivacyPolicy} auth={auth} routeName ='Privacy Policy'  />
                <RestrictedRoute  path='/help' roles= {['manager']} component={SupportUs} auth={auth} routeName ='Help'  />
                {/*<RestrictedRoute path='/learnerCourses' roles= {['admin','staff_manager','manager','learner']} component={LearnerCourseListing} auth={auth} routeName ='Assignments' />*/}
            {/*<RestrictedRoute path='/learner-dashboard' roles= {['admin','staff_manager','manager','learner']} component={LearnerLearningPathDashboard} auth={auth} routeName ='Learner Dashboard' />*/}
                <RestrictedRoute path='/completedCourses' roles= {['admin','staff_manager','manager','learner']}  component={LearnerCourseListing} auth={auth} routeName ='Completed Courses'  />
                <RestrictedRoute path='/onGoingCourses' roles= {['admin','staff_manager','manager','learner']}  component={LearnerCourseListing} auth={auth} routeName ='Ongoing Courses'  />
                <RestrictedRoute path='/groupList' roles= {['admin','staff_manager','manager','learner']}  component={LearnerGroups} auth={auth} routeName ='Profile' />
                <RestrictedRoute  path='/coursecompleted/:id' roles= {['admin','staff_manager','manager']} component={CourseCompleted} auth={auth} routeName ='Course Completed'  />
            <RestrictedRoute  path='/institution_contact' roles= {['learner', 'staff_manager']} component={InstitutionContact} auth={auth} routeName ='Institution Contact'  />
            <RestrictedRoute forbidden={hideLpQuizes} path='/learningpaths' roles= {['admin','staff_manager','manager']} component={LearningManagement} auth={auth} routeName ='Learning Paths'  />
            <RestrictedRoute forbidden={hideLpQuizes}  path='/learning_path_builder' roles= {['admin','staff_manager','manager']} component={LearningPath} auth={auth} routeName ='Learning Path Builder'  />
            <RestrictedRoute forbidden={hideLpQuizes}  path='/edit_learning_path/:id' roles= {['admin','staff_manager','manager']} component={LearningPath} auth={auth} routeName ='Learning Path Builder'   />
            <RestrictedRoute  path='/LearningPathDetails' roles= {['admin','staff_manager','manager']} component={LearningPathLearnerDetails} auth={auth} routeName ='Learning Path Details'  />
            <RestrictedRoute  path='/learning-path/certificate/:id/' roles= {['admin','staff_manager','manager','learner']} component={Certificate} auth={auth} routeName ='Learning Path Certificate'  />
            <RestrictedRoute  path='/course/certificate/:id/' roles= {['admin','staff_manager','manager','learner']} component={Certificate} auth={auth} routeName ='Profile'  />
            <RestrictedRoute  path='/learningpathdetails/:id' roles= {['admin','staff_manager','manager']} component={ManagerLearingPathDetail} auth={auth} routeName ='Learning Path Details'  />
            <RestrictedRoute  path='/draft/learningpaths' roles= {['admin','staff_manager','manager']} component={LearningManagement} auth={auth} routeName ='Learning Path Builder'  />
            <RestrictedRoute  path='/my-assignments' roles= {['admin','staff_manager','manager','learner']} component={LearnersDashboard} auth={auth} routeName ='Assignments'  />
            <RestrictedRoute path='/surveybuilder' roles= {['admin','staff_manager','manager']} component={SurveyCreate} auth={auth} routeName ='Create Survey' />
            <RestrictedRoute  path='/survey-edit/:id'  roles= {['admin','staff_manager','manager']} component={SurveyCreate} auth={auth}  routeName ='Create Survey'/>
            <RestrictedRoute path='/surveydetail' roles= {['admin','staff_manager','manager','learner']} component={SurveyDetails} auth={auth} routeName ='Create Survey' />
            <RestrictedRoute path='/nodes' roles= {['admin','staff_manager','manager','learner']} component={NodsListing} auth={auth} routeName ='Create Survey' />
            <RestrictedRoute path='/surveydetail' roles= {['admin','staff_manager','manager','learner']} component={SurveyDetails} auth={auth} routeName ='Create Survey' />
            <RestrictedRoute path='/learningpath/:id/nodes' roles= {['admin','staff_manager','manager','learner']} component={NodeList} auth={auth} routeName ='Assignments' />
            {/* <RestrictedRoute path='/myLearnings' roles= {['admin','staff_manager','manager','learner']} component={LearnerLearningPathListing} auth={auth} routeName ='My Learnings' /> */}
            <RestrictedRoute path='/myLearnings' roles= {['admin','staff_manager','manager','learner']} component={myLearningListing} auth={auth} routeName ='Assignments' />
            <RestrictedRoute  path='/learningpath/:id/enroll_users' roles= {['admin','staff_manager','manager']} component={LpAssigment} auth={auth} routeName ='Learning Paths'  />

            <RestrictedRoute  path='/draft/learningpaths' roles= {['admin','staff_manager','manager']} component={LearningManagement} auth={auth} routeName ='Learning Path Builder'  />
            <RestrictedRoute forbidden={hideLpQuizes}  path='/learningmanagementedit/:id' roles= {['admin','staff_manager','manager']} component={ManagerLearingPathDetail} auth={auth} routeName ='Learning Paths' />
            <RestrictedRoute  path='/survey-graph/:id' roles= {['admin','staff_manager','manager']} component={SurveyGraph} auth={auth} routeName ='Survey Reports' />
            {/* <RestrictedRoute  path='/learningmanagementedit/:id' roles= {['admin','staff_manager','manager']} component={ManagerLearingPathDetail} auth={auth} routeName ='Learning Paths' /> */}
            <RestrictedRoute  path='/learning_path_admin/:id/' roles= {['admin','staff_manager','manager']} component={LearningPathDetailAdmin } auth={auth} routeName ='Learning Paths' />
            <RestrictedRoute  path='/learning-path/:id/assigment-lists' roles= {['admin','staff_manager','manager']} component={LearnersListing} auth={auth} routeName ='Learners List' />
            {/*<RestrictedRoute  path='/workFlow' roles= {['admin','staff_manager','manager']} component={LearningPathContainer} auth={auth} routeName ='Learning Path' />*/}
            <PageNoFound />

                {/* <RestrictedRoute path='/home' component={HomeContent} auth={auth} /> */}
                {/* <Route path='/home' component={HomeContent} /> */}
                {/*/!* <Route path='/dashboard' component={Dashboard} />*/}
                {/*<Route path='/reports' component={Reports} />*/}




        </Switch>
    );
};
export default Router;

// const RestrictedRouter = () => {
//   const auth = useSelector(state => state.auth);
//   return (
//     <Switch>
//
//       <PageWrapper>
//
//         <Route path='/dashboard'  component={Dashboard} />
//         {/*<Route path='/reports' component={SurveyReports} />*/}
//         <Route  path='/Learningpathlisting' component={LearningPathListing} />
//           <Route  path='/learningpathdetails/:id' component={LearningPathDetails} />
//           <Route  path='/learnercoursedetails/:id' component={LearnerCourseDetails} />
//
//           {/* <Route  path='/courses' component={Courses} /> */}
//           <Route  path='/profile' component={Profile} />
//           <Route  path='/payment' component={Payment} />
//           <Route  path='/surveys' component={CreateSurvey} />
//           <Route  path='/survey/:id' componentStartSurveystart} />
//           <Route  path='/survey_report/:id' component={ReportsNew} />
//
//           <Route  path='/create_survey' component={SurveysManagement} />
//           <Route  path='/edit_survey/:id' component={SurveysManagement} />
//           <Route  path='/quizmanagement' component={QuizManagement} />
//           <Route  path='/quizbuilder' component={QuizBuilder} />
//           <Route  path='/surveymanagement'  allowedRoles={['manger','admin','staff_manager']} component={ManagerSurveyListing} />
//           <Route  path='/coursemanagement' component={CourseManagement} />
//
//           <Route  path='/course/:id/enroll_users' component={EnrollUsers} />
//           <Route  path='/draft/courses' component={CourseManagement} />
//           <Route  path='/coursemanagementedit/:id' component={CourseManagementEdit} />
//           <Route  path='/course/admin/:id' component={CourseManagementEdit} />
//           <Route  path='/coursebuilder' component={CourseBuilder} />
//           <Route  path='/editcourse/:id' component={CourseBuilder} />
//           <Route  path='/survey_list' component={LearnerSurvey} />
//           <Route path='/survey_assignment/:id' component={SurveyAssignment}/>
//
//            <Route  path='/team' component={Team} />
//            <Route  path='/edit_member/:id' component={MemberDetails} />
//            <Route  path='/edit_group/:id' component={GroupDetails} />
//           <Route  path='/surveys' component={SurveyListing} />
//           <Route  path='/surveydetails/:id' component={LearnerSurveyDetail} />
//           <Route  path='/createsurveys/:id' component={SurveyBuilder} />
//           <Route  path='/learningpathmanagement' component={LearningPathManagement} />
//           <Route  path='/learningpathbuilder' component={LearningPathBuilder} />
//           <Route  path='/surveyreports' component={SurveyReports} />
//
//           {/*<Route  path='/team' component={Team} />*/}
//           <Route  path='/thankyou' component={ThankYou} />
//           <Route  path='/reportsnew' component={ReportsNew} />
//           <Route  path='/pagenotfound' component={PageNotFound} />
//           <Route  path='/servererror' component={ServerError} />
//           <Route  path='/settings' component={Settings} />
//           <Route  path='/memberdetails/:id' component={MemberDetails} />
//           <Route  path='/groupdetails/:id' component={GroupDetails} />
//           <Route  path='/privacypolicy' component={PrivacyPolicy} />
//           <Route  path='/support' component={SupportUs} />
//           <Route path='/learnerCourses' component={LearnerCourseListing}/>
//           <Route path='/completedCourses' component={LearnerCourseListing}/>
//           <Route path='/onGoingCourses' component={LearnerCourseListing}/>
//            <Route path='/groupList' component={LearnerGroups}/>
//
//
//
//       </PageWrapper>
//     </Switch>
//   );
// };



const  renderedComponent = (user, roles,Component,props,name) => {

    if(user && user?.user_id && roles.includes(user?.user_type)){
        return <PageWrapper name={name}><Component {...props} /></PageWrapper>

    }else if(user && user?.user_id && !roles.includes(user?.user_type)) {
        return <PageWrapper><ServerError {...props} /></PageWrapper>
    }


}



const RestrictedRoute = ({ component: Component, auth: { user },roles,forbidden,routeName, ...rest }) => {

    if(forbidden){
        return   <Forbidden />;
    } else {
        return <Route
        {...rest}
        render={props =>
            user?.user_id
                ? renderedComponent(user,roles,Component,props,routeName)
                :   <EnterSubdomain {...props} />
        }
    />;
    }
   
};