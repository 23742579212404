import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from "~/assets/images/logo.png";
import ErrorImg from "~/assets/images/icons/email-error.svg";
import { useLocation, useHistory } from "react-router";
import { Formik } from "formik";
import * as yup from "yup";
import { Modal } from 'react-bootstrap';
import OtpInput from "react-otp-input";
import {
  signInWithWorkOs,
  verifyWorkOsProfile,
  verifyEdLinkProfile,
  verifySubDomain,
  signInWithEdLink,
  passwordLessSignIn,
  passwordLessWithOTP,
  clearState
} from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { redirectUser } from "../../../../shared/utils/checkHost";
import {Helmet} from "react-helmet";


function EnterOtp() {
  const passwordless_signin = useSelector(
    (state) => state.auth?.passwordless_signin
  );
  const {IsSignUpEmailSent, user} = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [learner, setLearner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState();
  const [PLType, setPLType] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState(30);


  const handleOtpChange = (code) =>{
    setCode(code);
  }

  useEffect(() => {
    if(user){
      redirectUser(user, history, passwordless_signin.loading);
    }
  }, [user]);

  const handleSubmit = async (values) => {
    dispatch(passwordLessWithOTP({...values, token:code}, history));

   setTimeout(() => {
      dispatch(clearState("error"));
    }, 3000);
  }
  const handleClose = () =>setModalShow(false);

 

  useEffect(()=>{
    setModalShow(true)
  },[])

  useEffect(() => {

    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleResendCode = () => {
    
    setCounter(30)

    dispatch(passwordLessSignIn({email:location?.state?.email}, history, () => {
      toast.success("Code has been resent, please check your mailbox");
    }));
    
    // dispatch
  }

  return (
    <>
    <div className="auth-page-wrap otp-form-wrap">
    <Helmet>
                <title>Login - Cultural Engagement  </title>
            </Helmet>
      <div className="auth-page-box d-flex">
        <div className="auth-right d-flex justify-content-center align-items-center">
          <div className="auth-cont-box">
            <div className="auth-cont-box-header text-center">
              <div className="auth-logo">
                <img src={Logo} alt="" />
              </div>
              {/* <h2 className="auth-title">Welcome Back!</h2> */}
              <p className="auth-desc">
              Kindly enter the OTP you received in your mail and click Login
              </p>
            </div>
            <div className="auth-form-box white-box radius-box otp-box">
              <Formik
                validationSchema={otpSchema}
                onSubmit={handleSubmit}
                initialValues={{
                  email:
                    (location?.state?.email && location?.state?.email) || (location?.state?.signupEmail) || "",
                  // password: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-input-wrap">
                      <Form.Label>Email Address</Form.Label>
                      {/* <Form.Control type="email" placeholder="xyz@xyz.com" value="xyz@xyz.com" className="input-item" disabled /> */}
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={values.email}
                        className="input-item"
                        disabled
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                    </div>
                    <div className="form-input-wrap">
                      <Form.Label>OTP</Form.Label>
                      <div className="otp-input-wrap d-flex justify-content-between">
                        {/* <Form.Control type="number" className="input-item" />
                        <Form.Control type="number" className="input-item" />
                        <Form.Control type="number" className="input-item" />
                        <Form.Control type="number" className="input-item" />
                        <Form.Control type="number" className="input-item" />
                        <Form.Control type="number" className="input-item" /> */}

                        <OtpInput
                          value={code}
                          onChange={handleOtpChange}
                          numInputs={6}
                          separator={<span style={{ width: "8px" }}></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          inputStyle={{
                            width: "2.5rem",
                            height: "2.5rem",
                            margin: "0 0.7rem",
                            fontSize: "1rem",
                            borderRadius: 4,
                            color: "#000",
                            border: "1px solid rgba(0,0,0,0.3)"
                          }}
                        //   focusStyle={{
                        //     border: "1px solid #CFD3DB",
                        //     outline: "none",
                        //   }}
                        />
{/* /////////////// */}





{/* <OtpInput
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        inputStyle={{
                          width: "2rem",
                          height: "2rem",
                          margin: "0 0.5rem",
                          fontSize: "1.5rem",
                          borderRadius: 4,
                          // color:'black',
                          border: "1px solid rgba(0,0,0,0.3)",
                        }}
                        separator={<span>-</span>}
                      /> */}
                    





                      </div> 
                      {

                        counter > 0 ? (<div className="resend-time text-center mt-3">Resend Code in 00:{counter}</div>) :
                         <div className="resend-time text-center mt-3" > <span onClick={handleResendCode} style={{color:'#F69525', cursor:'pointer',display:'inline-block'}}>Resend Code</span></div>
                      }
                     

                      {/* <div className="invalid-otp">Invalid OTP</div> */}
                      {/* <button className="resend-btn link-btn">Resend Code</button> */}
                    </div>
                    <div className="button-wrap pb-3">
                      <Button type="submit" className="w-100" disabled={code.length > 5 ? false : true}>
                        Login
                      </Button>
                    </div>
                    {/* <div className="edlinkworkos-btn-wrap">
                      <button className="btn edlinkworkos-btn w-100">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21.317"
                            height="15.396"
                            viewBox="0 0 21.317 15.396"
                          >
                            <path
                              id="Path_8754"
                              data-name="Path 8754"
                              d="M42,24.35l-7.7-7.7-2.961,2.961-2.961-2.961-7.7,7.7,7.7,7.7,2.961-2.961,2.961,2.961ZM28.384,29.087,23.647,24.35l4.737-4.737,1.48,1.48L26.607,24.35l3.257,3.257Zm5.921-9.474,4.737,4.737-4.737,4.737L29.568,24.35Z"
                              transform="translate(-20.686 -16.652)"
                              fill="#fff"
                            />
                          </svg>
                        </span>
                        Login using Edlink
                      </button>
                      <button className="btn edlinkworkos-btn w-100">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.795"
                            height="15.423"
                            viewBox="0 0 17.795 15.423"
                          >
                            <g
                              id="Group_6786"
                              data-name="Group 6786"
                              transform="translate(9023 -5424)"
                            >
                              <path
                                id="logo-icon"
                                d="M0,7.712a1.907,1.907,0,0,0,.261.967l3.121,5.4a2.63,2.63,0,0,0,1.411,1.2,2.532,2.532,0,0,0,3.014-1.133l.753-1.305L5.588,7.712l3.138-5.44.754-1.3A3.072,3.072,0,0,1,10.363,0H5.523A2.363,2.363,0,0,0,3.47,1.186L.261,6.745A1.907,1.907,0,0,0,0,7.712Z"
                                transform="translate(-9023 5424)"
                                fill="#fff"
                              />
                              <path
                                id="logo-icon-2"
                                data-name="logo-icon"
                                d="M21.929,7.713a1.906,1.906,0,0,0-.261-.967L18.506,1.271A2.546,2.546,0,0,0,15.492.138a2.63,2.63,0,0,0-1.411,1.2l-.712,1.227,2.972,5.144L13.2,13.152l-.754,1.305a3.131,3.131,0,0,1-.884.967h4.84a2.363,2.363,0,0,0,2.053-1.186L21.668,8.68A1.9,1.9,0,0,0,21.929,7.713Z"
                                transform="translate(-9027.134 5423.999)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </span>
                        Login using WorkOS
                      </button>
                    </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
{/* ////////////////// */}

<Modal show={modalShow} onHide={handleClose} className='common-modal inner-popup otpsend-modal' centered >
<Modal.Header closeButton>
             </Modal.Header>
             <Modal.Body>
<div className="otp-send-wrap">
   <div className="otp-img">
<svg xmlns="http://www.w3.org/2000/svg" width="239.611" height="144.461" viewBox="0 0 239.611 144.461">
  <g id="undraw_forgot_password_re_hxwm" transform="translate(0 -14.776)">
    <path id="Path_8761" data-name="Path 8761" d="M436.651,424.005c8.212-14.445,23.875-25.536,40.456-24.468a76.556,76.556,0,0,0-20.134,48.551c-.272,6.963.15,14.736-4.473,19.95-2.877,3.244-7.274,4.816-11.6,5.145s-8.646-.452-12.912-1.229l-1.035.318C426.562,455.661,428.44,438.45,436.651,424.005Z" transform="translate(-350.703 -332.363)" fill="#d1cecb"/>
    <path id="Path_8762" data-name="Path 8762" d="M477.109,398.961a65.436,65.436,0,0,0-29.135,27.784,28.178,28.178,0,0,0-3.084,8.273,16.161,16.161,0,0,0,.841,8.464c.911,2.533,2.136,5.018,2.415,7.728a9.9,9.9,0,0,1-2.649,7.647c-2.315,2.594-5.424,4.2-8.518,5.667-3.436,1.628-7.031,3.264-9.464,6.3-.295.368-.849-.111-.554-.479,4.234-5.286,11.482-6.279,16.508-10.537,2.345-1.987,4.106-4.692,3.995-7.862-.1-2.772-1.358-5.336-2.3-7.893a17.245,17.245,0,0,1-1.13-8.245,26.05,26.05,0,0,1,2.755-8.361,63.484,63.484,0,0,1,11.674-16.043,66.6,66.6,0,0,1,18.413-13.138c.424-.2.658.49.237.693Z" transform="translate(-350.679 -331.439)" fill="#fff"/>
    <path id="Path_8763" data-name="Path 8763" d="M506.148,458.174a9.817,9.817,0,0,1-2.963-12.362c.214-.42.876-.106.662.314a9.091,9.091,0,0,0,2.779,11.494C507.008,457.9,506.527,458.449,506.148,458.174Z" transform="translate(-406.973 -366.885)" fill="#fff"/>
    <path id="Path_8764" data-name="Path 8764" d="M498.26,552.344a18.922,18.922,0,0,0,11.98-6.829c.3-.367.85.112.554.479a19.681,19.681,0,0,1-12.481,7.081c-.467.067-.518-.664-.054-.73Z" transform="translate(-403.84 -441.524)" fill="#fff"/>
    <path id="Path_8765" data-name="Path 8765" d="M573.123,432.423a5.557,5.557,0,0,0,4.967,1.771c.466-.073.517.658.054.73a6.227,6.227,0,0,1-5.5-1.947.378.378,0,0,1-.038-.516A.368.368,0,0,1,573.123,432.423Z" transform="translate(-459.638 -356.952)" fill="#fff"/>
    <path id="Path_8766" data-name="Path 8766" d="M508.2,553.624c-.29.054-.58.107-.872.167a73.179,73.179,0,0,0-11.442,3.163c-.289.1-.581.207-.867.316a77.151,77.151,0,0,0-24.265,14.767,74.924,74.924,0,0,0-7.859,8.236c-3.324,4.061-6.6,8.729-10.949,11.377a12.845,12.845,0,0,1-1.4.758l-25.023-10.379c-.045-.052-.093-.1-.138-.151l-1.018-.369c.114-.16.235-.324.348-.484.065-.093.137-.184.2-.277.045-.062.091-.123.129-.181.015-.021.03-.041.043-.056.037-.058.078-.108.114-.16q1.013-1.377,2.048-2.744c0-.006,0-.006.01-.009a110.164,110.164,0,0,1,17.889-18.93c.2-.164.406-.333.619-.493a71.493,71.493,0,0,1,9.664-6.537,63.231,63.231,0,0,1,5.733-2.835,52.557,52.557,0,0,1,15.923-4.061c10.94-1.016,22.083,1.478,30.474,8.351C507.779,553.269,507.988,553.443,508.2,553.624Z" transform="translate(-348.804 -440.881)" fill="#d1cecb"/>
    <path id="Path_8767" data-name="Path 8767" d="M509.046,575.927a65.434,65.434,0,0,0-39.991,4.643,28.179,28.179,0,0,0-7.443,4.748,16.161,16.161,0,0,0-4.425,7.264c-.8,2.571-1.316,5.292-2.725,7.625a9.9,9.9,0,0,1-6.719,4.511c-3.41.677-6.86.089-10.213-.6-3.724-.769-7.579-1.627-11.351-.667-.457.116-.611-.6-.155-.716,6.563-1.671,12.948,1.9,19.525,1.526,3.069-.174,6.1-1.275,7.923-3.873,1.591-2.272,2.128-5.078,2.913-7.688a17.245,17.245,0,0,1,4.062-7.264,26.048,26.048,0,0,1,7.233-5.017,63.483,63.483,0,0,1,18.98-5.781,66.6,66.6,0,0,1,22.612.6c.461.092.23.788-.228.7Z" transform="translate(-349.837 -462.889)" fill="#fff"/>
    <path id="Path_8768" data-name="Path 8768" d="M611.981,557.278a9.817,9.817,0,0,1,5.078-11.654c.424-.206.764.443.339.649a9.091,9.091,0,0,0-4.7,10.851C612.836,557.573,612.119,557.725,611.981,557.278Z" transform="translate(-488.843 -441.674)" fill="#fff"/>
    <path id="Path_8769" data-name="Path 8769" d="M549.781,642.407a18.922,18.922,0,0,0,13.677,1.761c.457-.115.611.6.155.716a19.682,19.682,0,0,1-14.228-1.861C548.971,642.795,549.37,642.181,549.781,642.407Z" transform="translate(-442.198 -514.071)" fill="#fff"/>
    <path id="Path_8770" data-name="Path 8770" d="M694.252,575.218a5.557,5.557,0,0,0,2.9,4.4c.416.223.017.837-.4.615a6.227,6.227,0,0,1-3.218-4.865.378.378,0,0,1,.281-.435A.368.368,0,0,1,694.252,575.218Z" transform="translate(-550.164 -463.628)" fill="#fff"/>
    <path id="Path_8771" data-name="Path 8771" d="M236.113,519.339c-3.5-16.944-7.885-22.593-7.885-22.593l-1.673-1.3-2.012-1.563.01-.2-.477-.162-.112-.087-.184-.141-.03.032-.062.065-9.107-3.1-11.553-3.924-5.35-6.954a9.253,9.253,0,0,0-7.4-3.611l-17.086.126a9.253,9.253,0,0,0-6.224,2.469l-11.23,10.417-17.5,8.357-.03-.03-.184.131-2.7,1.293.112.581-1.673,1.208s-4.38,5.257-7.885,21.023c-.891,4.012-1.33,12.617-1.489,23.222a4.048,4.048,0,0,0,4.046,4.108H233.56a4.048,4.048,0,0,0,4.046-4.1C237.451,533.067,237.012,523.689,236.113,519.339Z" transform="translate(-124.382 -389.472)" fill="#2f2e41"/>
    <path id="Path_8772" data-name="Path 8772" d="M574.828,191.732a13.711,13.711,0,1,0,0,27.422H677.007a13.711,13.711,0,1,0,0-27.422Z" transform="translate(-451.106 -176.955)" fill="#e5e5e5"/>
    <path id="Path_8773" data-name="Path 8773" d="M583.069,202.747a10.936,10.936,0,0,0,0,21.872H685.248a10.936,10.936,0,0,0,0-21.872Z" transform="translate(-459.347 -185.196)" fill="#fff"/>
    <circle id="ab6171fa-7d69-4734-b81c-8dff60f9761b" cx="22.384" cy="22.384" r="22.384" transform="translate(37.649 35.146)" fill="#ffb6b6"/>
    <path id="Path_8774" data-name="Path 8774" d="M339.122,444.091q-.143-.316-.286-.634c.037,0,.073.006.11.007Z" transform="translate(-284.816 -365.272)" fill="#2f2e41"/>
    <path id="Path_8775" data-name="Path 8775" d="M245.32,245.129a6.23,6.23,0,0,1,3.9-1.687c1.447.047,2.915,1.354,2.615,2.771a23,23,0,0,1,27.682-10.38c3.6,1.269,7.125,3.808,7.952,7.533a6.784,6.784,0,0,0,.855,2.781A3.174,3.174,0,0,0,291.7,247l.036-.01a1.058,1.058,0,0,1,1.278,1.493l-1.018,1.9a8.157,8.157,0,0,0,3.886-.083,1.057,1.057,0,0,1,1.122,1.644,18.419,18.419,0,0,1-14.686,7.549c-4.067-.025-8.176-1.427-12.134-.491a10.54,10.54,0,0,0-7.089,14.8c-1.216-1.33-3.567-1.015-4.81.289a6.6,6.6,0,0,0-1.44,5.048,23.423,23.423,0,0,0,2.4,7.862,23.5,23.5,0,0,1-13.933-41.869Z" transform="translate(-208.294 -208.965)" fill="#2f2e41"/>
    <path id="Path_8776" data-name="Path 8776" d="M87.464,495.253l6.278,34.159,3.767.328Z" transform="translate(-65.432 -370.502)" opacity="0.2"/>
    <path id="Path_8777" data-name="Path 8777" d="M332.173,485.026l-6.278,36.71-3.767.353Z" transform="translate(-240.985 -362.851)" opacity="0.2"/>
    <path id="Path_8779" data-name="Path 8779" d="M643.317,269.213h-20.33a.49.49,0,1,1,0-.979h20.33a.49.49,0,1,1,0,.979Z" transform="translate(-497.025 -234.187)" fill="#f69525"/>
    <path id="Path_8780" data-name="Path 8780" d="M745.7,269.861h-20.33a.49.49,0,1,1,0-.979H745.7a.49.49,0,1,1,0,.979Z" transform="translate(-573.617 -234.672)" fill="#f69525"/>
    <path id="Path_8781" data-name="Path 8781" d="M848.08,270.509h-20.33a.49.49,0,1,1,0-.979h20.33a.49.49,0,1,1,0,.979Z" transform="translate(-650.21 -235.157)" fill="#f69525"/>
    <path id="Path_8782" data-name="Path 8782" d="M950.458,271.157h-20.33a.49.49,0,1,1,0-.979h20.33a.49.49,0,0,1,0,.979Z" transform="translate(-726.799 -235.641)" fill="#f69525"/>
    <circle id="Ellipse_331" data-name="Ellipse 331" cx="3.778" cy="3.778" r="3.778" transform="translate(132.304 22.963)" fill="#f69525"/>
    <circle id="Ellipse_332" data-name="Ellipse 332" cx="3.778" cy="3.778" r="3.778" transform="translate(158.249 22.963)" fill="#f69525"/>
    <circle id="Ellipse_333" data-name="Ellipse 333" cx="3.778" cy="3.778" r="3.778" transform="translate(184.194 22.963)" fill="#f69525"/>
    <circle id="Ellipse_334" data-name="Ellipse 334" cx="3.778" cy="3.778" r="3.778" transform="translate(210.139 22.963)" fill="#f69525"/>
  </g>
</svg>

   </div>
   <h3 className="otp-title">OTP Sent</h3>
   <p className="otp-descr">{IsSignUpEmailSent ? 'An OTP has been sent to your email ID. Please check your mail and follow the directions to complete the sign up process'  : 'An OTP has been sent to your email ID. Please check you mail and enter your OTP to complete the login process'  }</p>
   <Button variant="primary" style={{background: '#F69525'}} className="mt-2" onClick={()=>setModalShow(false)}>OK</Button>{' '}
</div>
            
            </Modal.Body>
         </Modal>



</>
  );
}
const otpSchema = yup.object().shape({
  // password: yup
  //   .string()
  //   .required("Passord is Required field")
  //   .min(8, "Password is too short - should be 8 chars minimum."),
  // .matches(/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Password must include one upper case, and at least one number.'),
  email: yup.string().email('Please enter a valid email').required("Email is Required field"),
});

export default EnterOtp;
