import React, { useState,useEffect } from 'react';
import FilterImg from '~/assets/images/icons/filter.svg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components }  from 'react-select';
import DataTable from 'react-data-table-component';
import { tableData } from './dummyTableData';
import Dropdown from 'react-bootstrap/Dropdown'
import { useHistory} from 'react-router-dom';
import getProfile from '../../../profile/services'
import {useDispatch, useSelector} from "react-redux";
import {getAllLearnerGroups,handleGroupPagination,handlePreviousGroupPagination} from '../../services';
import  {getManagersList} from '../../../surveys/services';
import CustomPagination from "../../../../shared/components/pagination";
import {Helmet} from "react-helmet";
import { getManagerCoursesList }  from '../../../coursemanagement/services';
import Spinner from "react-bootstrap/Spinner";

const LearnerGroups = () => {
    const dispatch = useDispatch();
   const [dateRange, setDateRange] = useState([null, null]);
   const [startDate, endDate] = dateRange;
   const response = useSelector(state => state?.teams?. learner_group_list ?.response||[]);
   const loading = useSelector(state => state?.teams?. learner_group_list ?.loading);
   const response_managers = useSelector(state => state.surveys.manager_list?.response || []|| [])
   const [managers, setManagers] = useState(response_managers);
   const[dataProgress,setDataProgress]=useState(false)
   const[createdby,setCreatedby]=useState('');
   const history=useHistory();
   const options = [
      { value: 'One', label: 'One' },
      { value: 'Two', label: 'Two' },
      { value: 'Three', label: 'Three' }
   ]
   const { user } = useSelector(state => state.auth);
   const [groups, setGroups] = useState(response.results);
   const [search, setSearch] = useState('');
   const filterDefault=[{"id":'',"label":"All","value":"All"}];
  const [newManagers,setNewManagers]=useState([]);
  const [activePage, setActivePage] = useState(1);

   useEffect(() => {
         dispatch(getManagersList());
         dispatch(getAllLearnerGroups(search,createdby,1));
         setActivePage(1)
       

    }, []);

    useEffect(() => {
        setGroups(response.results)
   }, [response]);

      useEffect(() => {
         if(response_managers){
            setNewManagers(filterDefault.concat( response_managers ))
         }
      }, [response_managers]);


   useEffect(() => {
    let element = document.getElementById('headerTitle')
    if(element){
      switch (history.location.pathname){
         case '/groupList':
            element.innerHTML = 'Groups'
            break;

        default :
           'TEAM'

      } }


}, [])

//group search
const searchInput = (e) =>
{
setSearch(e.target.value);
   dispatch(getAllLearnerGroups(e.target.value, createdby?.id ? createdby?.id : '', 1))
   setActivePage(1)
}

const reset=()=>{
    setSearch('');
    setCreatedby('');
    setTimeout(() => {
            dispatch( getAllLearnerGroups('','',1));
            setActivePage(1)
    }, 200)
              // setGroups(response.results)
}

const onFilter = (val) =>
{
   setCreatedby(val);
   dispatch(  getAllLearnerGroups(search,val?.id ? val?.id : '',1))
   setActivePage(1)

};




   const handleNext = () =>
   {
      if (response?.next) {
         setActivePage((prevState) => prevState + 1);
          dispatch(handleGroupPagination(response?.next))
          
      } else {
          return false
      }

   }
   const handlePrevious = () =>
   {
     if (response?.previous) {
         setActivePage((prevState) => prevState - 1);
         dispatch(handlePreviousGroupPagination(response?.previous))
        
     } else {
         return false
     }
  }

  const handleIndex = (index) => {
   dispatch(getAllLearnerGroups(search, createdby?.id ? createdby?.id : '', index));
   setActivePage(index);
  }



  useEffect(() => {
   let element = document.getElementById('headerTitle')
   if(element){
     switch (history.location.pathname){
        case '/grouplist':
           element.innerHTML = 'Profile'
           break;

       default :
          'TEAM'

     } }


}, [])

const CaretDownIcon = () => {
   return (
      <div className="filter-icon-select">
         <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
            <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
         </svg>
      </div>
   );
};

const DropdownIndicator = (props) => {
   return (
      <components.DropdownIndicator {...props}>
         <CaretDownIcon />
      </components.DropdownIndicator>
   );
};

useEffect(() => {
   if(loading){ setDataProgress(true)}

  if (!loading){setDataProgress(false)}

 }, [loading])


   const tableColumns = [
      {
         name: 'Group name',
         selector: 'name',
         sortable: true,
         center: false,
        //  cell: row => <span>Lannisters</span>,
         grow: 3
      },
      {
         name: 'Members',
         // selector: 'member_count',
         sortable: true,
         center: false,
         grow: 3,
         cell: row => <span>{row.members?.count}</span>,
      },
      {
         name: 'Courses',
         selector: 'course_assigned',
         sortable: true,
         center: false,
        //  cell: row => <span>Manager</span>,
         grow: 2,
      },
    //   {
    //      name: 'Learning paths',
    //      selector: 'learning_path_count',
    //      sortable: true,
    //      center: false,
    //     //  cell: row => <span>4</span>,
    //      grow: 2,
    //   },
      {
         name: 'Created by',
         selector: 'created_by',
         sortable: true,
         center: false,
        //  cell: row => <span>John Doe</span>,
         grow: 2,
      },

   ];
   
   return (
      <div className="available-course-wrap">
         <Helmet>
               <title>Groups - Cultural Engagement  </title>
         </Helmet>
         <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
            <div className="datatable-filter-left-box d-flex align-items-center">
               <div className="datepicker-box p-relative">

               </div>

             <div className="dt-filter-left d-flex justify-content-between align-items-center">
                  <div className="custom-react-select">
                     <Select  options={newManagers || [] }  getOptionValue={(option) => option.id }  defaultValue={newManagers.length >0 ? newManagers[0].id : ''}  value={createdby}  onChange={onFilter} placeholder="Created By"  components={{ DropdownIndicator }}/>
                  </div>

               </div>
            </div>
            <div className="dt-filter-right d-flex">
               <div className="dt-filter-search-wrap p-relative">
                  <input type="text" placeholder="Search using keyword"
                    value={search} onChange={searchInput}
                     className="form-control input-item" />
                  <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
               </div>
               <button className="iconbutton search-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                  </svg>
               </button>
            </div>
         </div>
         <div className="datatble-wrap checkbox-datatable">

            <DataTable
                data={groups}
               columns={tableColumns}
            //    selectableRows
            //    selectableRowsComponent={BootyCheckbox}
               center={true}
               progressPending={dataProgress}
               progressComponent={
                  <div >
                  <Spinner animation="border" variant="warning"/>
              </div>

               }
               showPaginationBottom={false}
               // pagination
               // paginationResetDefaultPage={reset}
               // paginationServer
               // paginationTotalRows={response?.count}
               // paginationComponentOptions={{
               //     noRowsPerPage: true
               // }}
               // paginationPerPage={10}
               // //  paginationServerOptions={{
               // //       persistSelectedOnPageChange: true,
               // //    }}
               //  paginationIconFirstPage={false}
               // paginationIconLastPage={false}
               // // paginationIconLastPage={<i className="fa fa-toggle-right" onClick={handleFirstPage}></i>}
               // //  paginationIconFirstPage={<i className="fa fa-toggle-left" onClick={handleLastPage}></i>}
               // paginationIconNext={<i className="fa fa-caret-right" onClick={handleNext}></i>}
               // paginationIconPrevious={<i className="fa fa-caret-left" onClick={handlePrevious}></i>}
               noHeader={true}
            />
            {!loading ?  
                    <>
                     {response?.count > 12 ? 
                         <CustomPagination response={response} activePage={activePage} handleNext={handleNext} handlePrevious={handlePrevious} handleIndex={handleIndex} />
                         :
                         ""

                      }
                    </>  : ''
                }
         </div>
         
      </div>
   );
};
export default LearnerGroups;
