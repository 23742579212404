import { createAction, handleActions } from "redux-actions";
import ActionTypes from "~shared/constants/actionTypes";
import {
  _createSurvey,
  _getSurvey,
  _surveyDetail,
  _updateSurvey,
  _deleteSurvey,
  _nextSurvey,
  _previousSurvey,
  _listGeneralSurveyReport,
  _listSurveyReportQuestions,
  _listDemographicSurveyReport,
  _getManagersList,
  _getDraftSurveys,
  _submitSurvey,
  _learnerSurveyList,
  _learnerSurveyDetail,
  _getGroups,
  _assignSurvey,
  _LearnerNextSurvey,
  _LearnerPreviousSurvey,
  _draftNextSurvey,
  _draftPreviousSurvey,
  _downloadSurvey,
  _cloneSurvey,
  _previousAdminSurvey,
  _nextAdminSurvey,
  _getAdminSurveys,
  _getSingleAdminSurvey,
  _getDeletedSurvey,
  _deletePreviousSurvey,
  _deleteNextSurvey,
  _deleteSurveyForever,
  _surveyRestore,
  _getSurveyCategories
} from "~shared/httpService/api";
import { ToastContainer, toast } from "react-toastify";
import { callback } from "chart.js/helpers";

const initialState = {
  surveysData: [],
  paginatedSurveys: {},
  surveyDetail: {},
  IsSurveyData: false,
  editData: [],
  loading: false,
  response: {},
  hasError: false,
  error: {},
  survey_assignment: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  downloaded_report: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  create_survey: { loading: false, response: {}, hasError: false, error: {} },
  group_list: { loading: false, response: {}, hasError: false, error: {} },
  manager_list: { loading: false, response: {}, hasError: false, error: {} },
  surveys_list: { loading: false, response: {}, hasError: false, error: {} },
  learner_surveys_list: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  learner_surveys_detail: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  surveys_draft_list: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  surveys_completion: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  cloned_survey: { loading: false, response: {}, hasError: false, error: {} },
  report_Loading: false,
  survey_detail_Complete: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  admin_surveys_list: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  admin_surveys_detail: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  surveys_submit: { loading: false, response: {}, hasError: false, error: {} },
  survey_restore: { loading: false, response: {}, hasError: false, error: {} },
  deleted_survey_list: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  listSurveyReportQuestions: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  listSurveyReportDemographic: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  listSurveyReportGeneral: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  delete_survey:{
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  survey_category:{
    loading:false,
    response:[],
    hasError:false,
    error:{}
  }
};

export const createSurveyStart = createAction(ActionTypes.CREATE_SURVEY);
export const createSurveySuccess = createAction(
  ActionTypes.CREATE_SURVEY_SUCCESS,
  (response) => response
);
export const createSurveyError = createAction(
  ActionTypes.CREATE_SURVEY_ERROR,
  (error) => error
);

export const createSurvey =
  (
    formData,
    history,
    prop,
    demo,
    instituteArray,
    user,
    callback,
    errorCallBack
  ) =>
  (dispatch) => {
    dispatch(createSurveyStart());

    return _createSurvey(formData, demo, instituteArray, user)
      .then((response) => {
        if (response && prop == "publish") {
          toast.success("Survey Successfully Created");
          callback(response);
        } else if (response && prop == "not_published") {
          toast.success("Survey Successfully Created");
          history.push("/surveymanagement");
        }
        dispatch(createSurveySuccess(response));
      })
      .catch((error) => {
        dispatch(createSurveyError(error));
        if (error?.expiry_date && error?.name) {
          toast.error(`Title and Expiry date  is required`);
          errorCallBack(error);
        } else if (error?.expiry_date) {
          toast.error(" Expiry date  is required");
          errorCallBack(error);
        } else if (error?.name) {
          toast.error(" Title   required");
          errorCallBack(error);
        } else {
          toast.error(" Something went wrong");
        }
      });
  };

export const submitSurveyStart = createAction(ActionTypes.SURVEY_SUBMIT);
export const submitSurveySuccess = createAction(
  ActionTypes.SURVEY_SUBMIT_SUCCESS,
  (response) => response
);
export const submitSurveyError = createAction(
  ActionTypes.SURVEY_SUBMIT_ERROR,
  (error) => error
);

export const submitSurvey = (data, uuid, callback) => (dispatch) => {
  dispatch(submitSurveyStart());
  return _submitSurvey(data, uuid)
    .then((response) => {
      if (response) {
        toast.success("Survey has been submitted successfully");
        callback(response);
      }

      dispatch(submitSurveySuccess(response));
    })
    .catch((error) => {
      dispatch(submitSurveyError(error));
      toast.error("Something went wrong");
    });
};

export const assignSurveyStart = createAction(ActionTypes.ASSIGN_SURVEY);
export const assignSurveySuccess = createAction(
  ActionTypes.ASSIGN_SURVEY_SUCCESS,
  (response) => response
);
export const assignSurveyError = createAction(
  ActionTypes.ASSIGN_SURVEY_ERROR,
  (error) => error
);

export const assignSurvey = (values) => (dispatch) => {
  dispatch(assignSurveyStart());
  return _submitSurvey(values)
    .then((response) => {
      if (response) {
        toast.success("Survey has been  successfully assigned");
        // callback(response)
      }

      dispatch(assignSurveySuccess(response));
    })
    .catch((error) => {
      dispatch(assignSurveyError(error));
    });
};

export const downloadSurveyStart = createAction(
  ActionTypes.DOWNLOAD_SURVEY_REPORT
);
export const downloadSurveySuccess = createAction(
  ActionTypes.DOWNLOAD_SURVEY_REPORT_SUCCESS,
  (response) => response
);
export const downloadSurveyError = createAction(
  ActionTypes.DOWNLOAD_SURVEY_REPORT_ERROR,
  (error) => error
);

export const downloadSurvey = (id, ref, callBack) => (dispatch) => {
  dispatch(downloadSurveyStart());

  return _downloadSurvey(id)
    .then((response) => {
      if (response && ref) {
        callBack(response);

        toast.success("Report Downloaded Successfully");
      }

      dispatch(downloadSurveySuccess(response));
    })
    .catch((error) => {
      dispatch(downloadSurveyError(error));
      toast.error("Something went wrong");
    });
};

export const cloneSurveyStart = createAction(ActionTypes.CLONE_SURVEY_REPORT);
export const cloneSurveySuccess = createAction(
  ActionTypes.CLONE_SURVEY_REPORT_SUCCESS,
  (response) => response
);
export const cloneSurveyError = createAction(
  ActionTypes.CLONE_SURVEY_REPORT_ERROR,
  (error) => error
);

export const cloneSurvey = (id, callback) => (dispatch) => {
  dispatch(cloneSurveyStart());

  return _cloneSurvey(id)
    .then((response) => {
      dispatch(cloneSurveySuccess(response));
      if (response) {
        toast.success("Survey has  Cloned Successfully");
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(cloneSurveyError(error));
      toast.error("Something went wrong");
    });
};

export const getSurveyStart = createAction(ActionTypes.GET_SURVEYS_LISTING);
export const getSurveySuccess = createAction(
  ActionTypes.GET_SURVEYS_LISTING_SUCCESS,
  (response) => response
);
export const getSurveyError = createAction(
  ActionTypes.GET_SURVEYS_LISTING_ERROR,
  (error) => error
);

export const getSurvey =
  (search, startDate, endDate, createdby, category, status, index) =>
  (dispatch) => {
    dispatch(getSurveyStart());
    return _getSurvey(
      search,
      startDate,
      endDate,
      createdby,
      category,
      status,
      index
    )
      .then((response) => {
        dispatch(getSurveySuccess(response));
      })
      .catch((error) => {
        dispatch(getSurveyError(error));
      });
  };

export const getLearnerSurveyStart = createAction(
  ActionTypes.LEARNER_SURVEY_LIST
);
export const getLearnerSurveySuccess = createAction(
  ActionTypes.LEARNER_SURVEY_LIST_SUCCESS,
  (response) => response
);
export const getLearnerSurveyError = createAction(
  ActionTypes.LEARNER_SURVEY_LIST_ERROR,
  (error) => error
);

export const getLearnerSurvey =
  (search, startDate, endDate, assigned_by, page) => (dispatch) => {
    dispatch(getLearnerSurveyStart());
    return _learnerSurveyList(search, startDate, endDate, assigned_by, page)
      .then((response) => {
        dispatch(getLearnerSurveySuccess(response));
      })
      .catch((error) => {
        dispatch(getLearnerSurveyError(error));
      });
  };

export const getSurveyDetailStart = createAction(
  ActionTypes.GET_SINGLE_SURVEY_LIST
);
export const getSurveyDetailSuccess = createAction(
  ActionTypes.GET_SINGLE_SURVEY_LIST_SUCCESS,
  (response) => response
);
export const getSurveyDetailError = createAction(
  ActionTypes.GET_SINGLE_SURVEY_LIST_ERROR,
  (error) => error
);

export const detailSurvey = (id, callback) => (dispatch) => {
  dispatch(getSurveyDetailStart());
  return _surveyDetail(id)
    .then((response) => {
      dispatch(getSurveyDetailSuccess(response));
    })
    .catch((error) => {
      dispatch(getSurveyDetailError(error));
      let tempError = "Something went wrong";
      callback(tempError);
      toast.error("Something went wrong");
    });
};

export const getSurveyDetailInstStart = createAction(
  ActionTypes.GET_SINGLE_SURVEY_LIST
);
export const getSurveyDetailInstSuccess = createAction(
  ActionTypes.GET_SINGLE_SURVEY_LIST_SUCCESS,
  (response) => response
);
export const getSurveyDetailInstError = createAction(
  ActionTypes.GET_SINGLE_SURVEY_LIST_ERROR,
  (error) => error
);

export const detailSurveyInst = (id, callback) => (dispatch) => {
  dispatch(getSurveyDetailInstStart());
  return _surveyDetail(id)
    .then((response) => {
      dispatch(getSurveyDetailInstSuccess(response));
      callback(response);
    })
    .catch((error) => {
      dispatch(getSurveyDetailInstError(error));
      let tempError = "Something went wrong";
      toast.error("Something went wrong");
    });
};

export const getLearnerSurveyDetailStart = createAction(
  ActionTypes.LEARNER_SURVEY_DETAIL
);
export const getLearnerSurveyDetailSuccess = createAction(
  ActionTypes.LEARNER_SURVEY_DETAIL_SUCCESS,
  (response) => response
);
export const getLearnerSurveyDetailError = createAction(
  ActionTypes.LEARNER_SURVEY_DETAIL_ERROR,
  (error) => error
);

export const learnerSurveyDetail = (id, uuid, callBackError) => (dispatch) => {
  dispatch(getLearnerSurveyDetailStart());
  return _learnerSurveyDetail(id, uuid)
    .then((response) => {

      dispatch(getLearnerSurveyDetailSuccess(response));
    })
    .catch((error) => {
      dispatch(getLearnerSurveyDetailError(error));
      callBackError(error);
      toast.error("Something went wrong");
    });
};

export const updateSurveyStart = createAction(ActionTypes.UPDATE_SURVEY);
export const updateSurveySuccess = createAction(
  ActionTypes.UPDATE_SURVEY_SUCCESS,
  (response) => response
);
export const updateSurveyError = createAction(
  ActionTypes.UPDATE_SURVEY_ERROR,
  (error) => error
);

export const updateSurvey =
  (
    body,
    id,
    history,
    prop,
    demo,
    arr,
    user,
    surveyDetail,
    callback,
    errorCallBack
  ) =>
  (dispatch) => {
    dispatch(updateSurveyStart());
    return _updateSurvey(body, id, demo, arr, user, surveyDetail)
      .then((response) => {
        if (response && prop == "publish") {
          toast.success("Survey has been updated successfully");
          callback(response);
        } else if (response && prop == "not_published") {
          toast.success("Survey has been updated successfully");
          history.push("/surveymanagement");
        }
        dispatch(updateSurveySuccess(response));
      })
      .catch((error) => {
        dispatch(updateSurveyError(error));
        if (error?.error?.errorMessage) {
          toast.error(error?.error?.errorMessage);
          errorCallBack(error);
        } else if (error?.expiry_date && error?.name) {
          toast.error("Title and Expiry date  is required");
          errorCallBack(error);
        } else if (error?.expiry_date) {
          toast.error(" Expiry date  is required");
          errorCallBack(error);
        } else if (error?.name) {
          toast.error(" Title  is required");
          errorCallBack(error);
        } else {
          toast.error(" Something went wrong");
        }
      });
  };

export const getGroupsListStart = createAction(ActionTypes.GROUPS_LIST);
export const getGroupsListSuccess = createAction(
  ActionTypes.GROUPS_LIST_SUCCESS,
  (response) => response
);
export const getGroupsListError = createAction(
  ActionTypes.GROUPS_LIST_ERROR,
  (error) => error
);

export const getGroupsList = (token) => (dispatch) => {
  dispatch(getGroupsListStart());
  return _getGroups(token)
    .then((response) => {
      dispatch(getGroupsListSuccess(response));
    })
    .catch((error) => {
      dispatch(getGroupsListError(error));
      toast.error("Something went wrong");
    });
};

export const deleteSurveyStart = createAction(ActionTypes.DELETE_SURVEY);
export const deleteSurveySuccess = createAction(
  ActionTypes.DELETE_SURVEY_SUCCESS,
  (response) => response
);
export const deleteSurveyError = createAction(
  ActionTypes.DELETE_SURVEY_ERROR,
  (error) => error
);

export const deleteSurvey = (row, callBack) => (dispatch) => {
  dispatch(deleteSurveyStart());
  return _deleteSurvey(row)
    .then((response) => {
      dispatch(deleteSurveySuccess(response));
      callBack()
      toast.success("Survey has been  deleted  successfully");
    })
    .catch((error) => {
      dispatch(deleteSurveyError(error));
      toast.error("Something went wrong");
    });
};

export const deleteSurveyForeverStart = createAction(
  ActionTypes.DELETE_SURVEY_FOREVER
);
export const deleteSurveyForeverSuccess = createAction(
  ActionTypes.DELETE_SURVEY_FOREVER_SUCCESS,
  (response) => response
);
export const deleteSurveyForeverError = createAction(
  ActionTypes.DELETE_SURVEY_FOREVER_ERROR,
  (error) => error
);

export const deleteSurveyForever = (data, callback) => (dispatch) => {
  dispatch(deleteSurveyForeverStart());
  return _deleteSurveyForever(data)
    .then((response) => {
      dispatch(deleteSurveyForeverSuccess(response));
      callback()
      toast.success("Survey has been  deleted  successfully");
    })
    .catch((error) => {
      dispatch(deleteSurveyForeverError(error));
      callback()
      toast.error("Something went wrong");
    });
};

export const restoreSurveyStart = createAction(ActionTypes.RESTORE_SURVEY);
export const restoreSurveySuccess = createAction(
  ActionTypes.RESTORE_SURVEY_SUCCESS,
  (response) => response
);
export const restoreSurveyError = createAction(
  ActionTypes.RESTORE_SURVEY_ERROR,
  (error) => error
);

export const restoreSurvey = (data, callback) => (dispatch) => {
  dispatch(restoreSurveyStart());
  return _surveyRestore(data)
    .then((response) => {
      dispatch(restoreSurveySuccess(response));
      callback();
      toast.success("Survey has been  restored successfully");
    })
    .catch((error) => {
      dispatch(restoreSurveyError(error));
      callback();
      toast.error("Something went wrong");
    });
};

export const getDeletedSurveyStart = createAction(
  ActionTypes.GET_DELETED_SURVEYS_LISTING
);
export const getDeletedSurveySuccess = createAction(
  ActionTypes.GET_DELETED_SURVEYS_LISTING_SUCCESS,
  (response) => response
);
export const getDeletedSurveyError = createAction(
  ActionTypes.GET_DELETED_SURVEYS_LISTING_ERROR,
  (error) => error
);

export const getDeletedSurvey =
  (search, startDate, endDate, createdby, category, page) => (dispatch) => {
    dispatch(getDeletedSurveyStart());
    return _getDeletedSurvey(
      search,
      startDate,
      endDate,
      createdby,
      category,
      page
    )
      .then((response) => {
        dispatch(getDeletedSurveySuccess(response));
      })
      .catch((error) => {
        dispatch(getDeletedSurveyError(error));
      });
  };

export const paginationStart = createAction(ActionTypes.PAGINATION_NEXT);
export const paginationSuccess = createAction(
  ActionTypes.PAGINATION_NEXT_SUCCESS,
  (response) => response
);
export const paginationError = createAction(
  ActionTypes.PAGINATION_NEXT_ERROR,
  (error) => error
);

export const handlePagination = (url) => (dispatch) => {
  dispatch(paginationStart());
  return _nextSurvey(url)
    .then((response) => {
      dispatch(paginationSuccess(response));
    })
    .catch((error) => {
      dispatch(paginationError(error));
      toast.error("Something went wrong");
    });
};

export const paginationPreviousStart = createAction(
  ActionTypes.PAGINATION_PREVIOUS
);
export const paginationPreviousSuccess = createAction(
  ActionTypes.PAGINATION_PREVIOUS_SUCCESS,
  (response) => response
);
export const paginationPreviousError = createAction(
  ActionTypes.PAGINATION_PREVIOUS_ERROR,
  (error) => error
);

export const handlePreviousPagination = (url) => (dispatch) => {
  dispatch(paginationPreviousStart());
  return _previousSurvey(url)
    .then((response) => {
      dispatch(paginationPreviousSuccess(response));
    })
    .catch((error) => {
      dispatch(paginationPreviousError(error));
      toast.error("Something went wrong");
    });
};

export const getSurveyAdminStart = createAction(ActionTypes.GET_SURVEY_ADMIN);
export const getSurveyAdminSuccess = createAction(
  ActionTypes.GET_SURVEY_ADMIN_SUCCESS,
  (response) => response
);
export const getSurveyAdminError = createAction(
  ActionTypes.GET_SURVEY_ADMIN_ERROR,
  (error) => error
);

export const getSurveyAdmin =
  (search, startDate, endDate, page, category) => (dispatch) => {
    dispatch(getSurveyAdminStart());
    return _getAdminSurveys(search, startDate, endDate, page, category)
      .then((response) => {
        dispatch(getSurveyAdminSuccess(response));
      })
      .catch((error) => {
        dispatch(getSurveyAdminError(error));
      });
  };


export const getSingleAdminSurveyStart = createAction(ActionTypes.GET_SINGLE_SURVEY_ADMIN);
export const getSingleAdminSurveySuccess = createAction(
  ActionTypes.GET_SINGLE_SURVEY_ADMIN_SUCCESS,
  (response) => response
);
export const getSingleAdminSurveyError = createAction(
  ActionTypes.GET_SINGLE_SURVEY_ADMIN_ERROR,
  (error) => error
);

  export const getSingleAdminSurvey  = (id, callback) => (dispatch) => {
    dispatch(getSingleAdminSurveyStart());
    return _getSingleAdminSurvey(id)
      .then((response) => {
        dispatch(getSingleAdminSurveySuccess(response));
        callback(response)
      })
      .catch((error) => {
        dispatch(getSingleAdminSurveyError(error));
      });
  }

export const paginationAdminStart = createAction(
  ActionTypes.SURVEY_ADMIN_PAGINATION_NEXT
);
export const paginationAdminSuccess = createAction(
  ActionTypes.SURVEY_ADMIN_PAGINATION_NEXT_SUCCESS,
  (response) => response
);
export const paginationAdminError = createAction(
  ActionTypes.SURVEY_ADMIN_PAGINATION_NEXT_ERROR,
  (error) => error
);

export const handlePaginationAdmin = (url) => (dispatch) => {
  dispatch(paginationAdminStart());
  return _nextAdminSurvey(url)
    .then((response) => {
      dispatch(paginationAdminSuccess(response));
    })
    .catch((error) => {
      dispatch(paginationAdminError(error));
      toast.error("Something went wrong");
    });
};

export const paginationPreviousAdminStart = createAction(
  ActionTypes.SURVEY_ADMIN_PAGINATION_PREVIOUS
);
export const paginationPreviousAdminSuccess = createAction(
  ActionTypes.SURVEY_ADMIN_PAGINATION_PREVIOUS_SUCCESS,
  (response) => response
);
export const paginationPreviousAdminError = createAction(
  ActionTypes.SURVEY_ADMIN_PAGINATION_PREVIOUS_ERROR,
  (error) => error
);

export const handlePreviousPaginationAdmin = (url) => (dispatch) => {
  dispatch(paginationPreviousAdminStart());
  return _previousAdminSurvey(url)
    .then((response) => {
      dispatch(paginationPreviousAdminSuccess(response));
    })
    .catch((error) => {
      dispatch(paginationPreviousAdminError(error));
      toast.error("Something went wrong");
    });
};

export const LearnerPaginationStart = createAction(
  ActionTypes.LEARNER_PAGINATION_NEXT
);
export const LearnerPaginationSuccess = createAction(
  ActionTypes.LEARNER_PAGINATION_NEXT_SUCCESS,
  (response) => response
);
export const LearnerPaginationError = createAction(
  ActionTypes.LEARNER_PAGINATION_NEXT_ERROR,
  (error) => error
);

export const handleLearnerPagination = (url) => (dispatch) => {
  dispatch(LearnerPaginationStart());
  return _LearnerNextSurvey(url)
    .then((response) => {
      dispatch(LearnerPaginationSuccess(response));
    })
    .catch((error) => {
      dispatch(LearnerPaginationError(error));
      toast.error("Something went wrong");
    });
};

export const LearnerPaginationPreviousStart = createAction(
  ActionTypes.LEARNER_PAGINATION_PREVIOUS
);
export const LearnerPaginationPreviousSuccess = createAction(
  ActionTypes.LEARNER_PAGINATION_PREVIOUS_SUCCESS,
  (response) => response
);
export const LearnerPaginationPreviousError = createAction(
  ActionTypes.LEARNER_PAGINATION_PREVIOUS_ERROR,
  (error) => error
);

export const handleLearnerPreviousPagination = (url) => (dispatch) => {
  dispatch(LearnerPaginationPreviousStart());
  return _LearnerPreviousSurvey(url)
    .then((response) => {
      dispatch(LearnerPaginationPreviousSuccess(response));
    })
    .catch((error) => {
      dispatch(LearnerPaginationPreviousError(error));
      toast.error("Something went wrong");
    });
};

export const DraftPaginationStart = createAction(
  ActionTypes.DRAFT_PAGINATION_NEXT
);
export const DraftPaginationSuccess = createAction(
  ActionTypes.DRAFT_PAGINATION_NEXT_SUCCESS,
  (response) => response
);
export const DraftPaginationError = createAction(
  ActionTypes.DRAFT_PAGINATION_NEXT_ERROR,
  (error) => error
);

export const handleDraftPagination = (url) => (dispatch) => {
  dispatch(DraftPaginationStart());
  return _draftNextSurvey(url)
    .then((response) => {
      dispatch(DraftPaginationSuccess(response));
    })
    .catch((error) => {
      dispatch(DraftPaginationError(error));
      toast.error("Something went wrong");
    });
};

export const DraftPaginationPreviousStart = createAction(
  ActionTypes.DRAFT_PAGINATION_PREVIOUS
);
export const DraftPaginationPreviousSuccess = createAction(
  ActionTypes.DRAFT_PAGINATION_PREVIOUS_SUCCESS,
  (response) => response
);
export const DraftPaginationPreviousError = createAction(
  ActionTypes.DRAFT_PAGINATION_PREVIOUS_ERROR,
  (error) => error
);

export const handleDraftPreviousPagination = (url) => (dispatch) => {
  dispatch(DraftPaginationPreviousStart());
  return _deletePreviousSurvey(url)
    .then((response) => {
      dispatch(DraftPaginationPreviousSuccess(response));
    })
    .catch((error) => {
      dispatch(DraftPaginationPreviousError(error));
      toast.error("Something went wrong");
    });
};

export const DeletePaginationStart = createAction(
  ActionTypes.DELETE_PAGINATION_NEXT
);
export const DeletePaginationSuccess = createAction(
  ActionTypes.DELETE_PAGINATION_NEXT_SUCCESS,
  (response) => response
);
export const DeletePaginationError = createAction(
  ActionTypes.DELETE_PAGINATION_NEXT_ERROR,
  (error) => error
);

export const handleDeletePagination = (url) => (dispatch) => {
  dispatch(DraftPaginationStart());
  return _deleteNextSurvey(url)
    .then((response) => {
      dispatch(DeletePaginationSuccess(response));
    })
    .catch((error) => {
      dispatch(DeletePaginationError(error));
      toast.error("Something went wrong");
    });
};

export const DeletePaginationPreviousStart = createAction(
  ActionTypes.DELETE_PAGINATION_PREVIOUS
);
export const DeletePaginationPreviousSuccess = createAction(
  ActionTypes.DELETE_PAGINATION_PREVIOUS_SUCCESS,
  (response) => response
);
export const DeletePaginationPreviousError = createAction(
  ActionTypes.DELETE_PAGINATION_PREVIOUS_ERROR,
  (error) => error
);

export const handleDeletePreviousPagination = (url) => (dispatch) => {
  dispatch(DeletePaginationPreviousStart());
  return _deletePreviousSurvey(url)
    .then((response) => {
      dispatch(DeletePaginationPreviousSuccess(response));
    })
    .catch((error) => {
      dispatch(DeletePaginationPreviousError(error));
      toast.error("Something went wrong");
    });
};

export const getManagersListStart = createAction(ActionTypes.GET_MANAGERS_LIST);
export const getManagersListSuccess = createAction(
  ActionTypes.GET_MANAGERS_LIST_SUCCESS,
  (response) => response
);
export const getManagersListError = createAction(
  ActionTypes.GET_MANAGERS_LIST_ERROR,
  (error) => error
);

export const getManagersList = () => (dispatch) => {
  dispatch(getManagersListStart());
  return _getManagersList()
    .then((response) => {
      dispatch(getManagersListSuccess(response));
    })
    .catch((error) => {
      dispatch(getManagersListError(error));
    });
};
export const getDraftSurveysStart = createAction(ActionTypes.GET_DRAFT_SURVEYS);
export const getDraftSurveysSuccess = createAction(
  ActionTypes.GET_DRAFT_SURVEYS_SUCCESS,
  (response) => response
);
export const getDraftSurveysError = createAction(
  ActionTypes.GET_DRAFT_SURVEYS_ERROR,
  (error) => error
);

export const getDraftSurveysList =
  (search, startDate, endDate, publish, createdby, category, page) =>
  (dispatch) => {
    dispatch(getDraftSurveysStart());
    return _getDraftSurveys(
      search,
      startDate,
      endDate,
      publish,
      createdby,
      category,
      page
    )
      .then((response) => {
        dispatch(getDraftSurveysSuccess(response));
      })
      .catch((error) => {
        dispatch(getDraftSurveysError(error));
      });
  };
export const listSurveyReportQuestionsStart = createAction(
  ActionTypes.LIST_SURVEY_REPORT_QUESTIONS
);
export const listSurveyReportQuestionsSuccess = createAction(
  ActionTypes.LIST_SURVEY_REPORT_QUESTIONS_SUCCESS,
  (response) => response
);
export const listSurveyReportQuestionsError = createAction(
  ActionTypes.LIST_SURVEY_REPORT_QUESTIONS_ERROR,
  (error) => error
);

export const listSurveyReportQuestions = (id, callback) => (dispatch) => {
  dispatch(listSurveyReportQuestionsStart());
  return _listSurveyReportQuestions(id)
    .then((response) => {
      dispatch(listSurveyReportQuestionsSuccess(response));
      callback(response);
    })
    .catch((error) => {
      dispatch(listSurveyReportQuestionsError(error));
    });
};

export const listDemographicSurveyReportStart = createAction(
  ActionTypes.LIST_SURVEY_REPORT_DEMOGRAPHIC
);
export const listDemographicSurveyReportSuccess = createAction(
  ActionTypes.LIST_SURVEY_REPORT_DEMOGRAPHIC_SUCCESS,
  (response) => response
);
export const listDemographicSurveyReportError = createAction(
  ActionTypes.LIST_SURVEY_REPORT_DEMOGRAPHIC_ERROR,
  (error) => error
);

export const listDemographicSurveyReport =
  (id, uuid, callback) => (dispatch) => {
    dispatch(listDemographicSurveyReportStart());

    return _listDemographicSurveyReport(id, uuid)
      .then((response) => {
        dispatch(listDemographicSurveyReportSuccess(response));
        callback(response);
      })
      .catch((error) => {
        dispatch(listDemographicSurveyReportError(error));
      });
  };

export const listGeneralSurveyReportStart = createAction(
  ActionTypes.LIST_SURVEY_REPORT_GENERAL
);
export const listGeneralSurveyReportSuccess = createAction(
  ActionTypes.LIST_SURVEY_REPORT_GENERAL_SUCCESS,
  (response) => response
);
export const listGeneralSurveyReportError = createAction(
  ActionTypes.LIST_SURVEY_REPORT_GENERAL_ERROR,
  (error) => error
);

export const listGeneralSurveyReport = (id, data, callback) => (dispatch) => {
  dispatch(listGeneralSurveyReportStart());

  return _listGeneralSurveyReport(id, data)
    .then((response) => {
      dispatch(listGeneralSurveyReportSuccess(response));
      callback(response);
    })
    .catch((error) => {
      dispatch(listGeneralSurveyReportError(error));
    });
};

export const getSurveyCategoriesStart = createAction(
  ActionTypes.GET_SURVEY_CATEGORIES
);
export const getSurveyCategoriesSuccess = createAction(
  ActionTypes.GET_SURVEY_CATEGORIES_SUCCESS,
  (response) => response
);
export const getSurveyCategoriesError = createAction(
  ActionTypes.GET_SURVEY_CATEGORIES_ERROR,
  (error) => error
);

export const getSurveyCategories = () => (dispatch) => {
  dispatch(getSurveyCategoriesStart());
  return _getSurveyCategories().then((response) => {
    dispatch(getSurveyCategoriesSuccess(response));

  }).catch((error) => {
    dispatch(getSurveyCategoriesError(error));
    if(error){
      if(error?.error?.errorMessage){

          toast.error(error?.error?.errorMessage)
          
      }
      }else{
          toast.error('Something went wrong')
      }
  });
};

const reducer = handleActions(
  {
    //CREATE SURVEY
    [ActionTypes.CREATE_SURVEY]: (state) => ({
      ...state,
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
      create_survey: {
        loading: false,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.CREATE_SURVEY_SUCCESS]: (state, action) => ({
      ...state,
      response: action.payload,
      IsSurveyData: false,
      create_survey: {
        ...state.create_survey,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.CREATE_SURVEY_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
      IsSurveyData: false,
      create_survey: {
        ...state.create_survey,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //DOWNLOAD SURVEY

    [ActionTypes.DOWNLOAD_SURVEY_REPORT]: (state) => ({
      ...state,
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
      downloaded_report: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DOWNLOAD_SURVEY_REPORT_SUCCESS]: (state, action) => ({
      ...state,
      response: action.payload,
      IsSurveyData: false,
      downloaded_report: {
        ...state.downloaded_report,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DOWNLOAD_SURVEY_REPORT_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
      IsSurveyData: false,
      downloaded_report: {
        ...state.downloaded_report,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),


    //LIST SURVEY CATEGORY

    [ActionTypes.GET_SURVEY_CATEGORIES]: (state) => ({
      ...state,
      survey_category: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_SURVEY_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      survey_category: {
        ...state.survey_category,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_SURVEY_CATEGORIES_ERROR]: (state, action) => ({
      ...state,
      survey_category: {
        ...state.survey_category,
        response: {},
        loading: false,
        hasError: true,
        error: action.payload,
      },
    }),

    //CLONE SURVEY

    [ActionTypes.CLONE_SURVEY_REPORT]: (state) => ({
      ...state,
      cloned_survey: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.CLONE_SURVEY_REPORT_SUCCESS]: (state, action) => ({
      ...state,
      cloned_survey: {
        ...state.cloned_survey,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.CLONE_SURVEY_REPORT_ERROR]: (state, action) => ({
      ...state,
      cloned_survey: {
        ...state.cloned_survey,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //list SURVEY report questions

    [ActionTypes.LIST_SURVEY_REPORT_QUESTIONS]: (state) => ({
      ...state,
      listSurveyReportQuestions: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LIST_SURVEY_REPORT_QUESTIONS_SUCCESS]: (state, action) => ({
      ...state,
      listSurveyReportQuestions: {
        ...state.listSurveyReportQuestions,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LIST_SURVEY_REPORT_QUESTIONS_ERROR]: (state, action) => ({
      ...state,
      listSurveyReportQuestions: {
        ...state.listSurveyReportQuestions,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //list SURVEY report demographic

    [ActionTypes.LIST_SURVEY_REPORT_DEMOGRAPHIC]: (state) => ({
      ...state,
      listSurveyReportDemographic: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LIST_SURVEY_REPORT_DEMOGRAPHIC_SUCCESS]: (state, action) => ({
      ...state,
      listSurveyReportDemographic: {
        ...state.listSurveyReportDemographic,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LIST_SURVEY_REPORT_DEMOGRAPHIC_ERROR]: (state, action) => ({
      ...state,
      listSurveyReportDemographic: {
        ...state.listSurveyReportDemographic,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //list SURVEY report general

    [ActionTypes.LIST_SURVEY_REPORT_GENERAL]: (state) => ({
      ...state,
      listSurveyReportGeneral: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LIST_SURVEY_REPORT_GENERAL_SUCCESS]: (state, action) => ({
      ...state,
      listSurveyReportGeneral: {
        ...state.listSurveyReportGeneral,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LIST_SURVEY_REPORT_GENERAL_ERROR]: (state, action) => ({
      ...state,
      listSurveyReportGeneral: {
        ...state.listSurveyReportGeneral,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //ASSIGN SURVEY

    [ActionTypes.ASSIGN_SURVEY]: (state) => ({
      ...state,
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
      survey_assignment: {
        loading: false,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.ASSIGN_SURVEY_SUCCESS]: (state, action) => ({
      ...state,
      response: action.payload,
      IsSurveyData: false,
      survey_assignment: {
        ...state.survey_assignment,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.ASSIGN_SURVEY_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
      IsSurveyData: false,
      survey_assignment: {
        ...state.survey_assignment,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //Get surveys
    [ActionTypes.GET_SURVEYS_LISTING]: (state) => ({
      ...state,
      surveysData: [],
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_list: {
        ...state.surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_SURVEYS_LISTING_SUCCESS]: (state, action) => ({
      ...state,
      surveysData: action.payload?.results,
      editData: [...action?.payload?.results],
      surveys_list: {
        ...state.surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      paginatedSurveys: { ...action.payload },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.GET_SURVEYS_LISTING_ERROR]: (state, action) => ({
      ...state,
      surveysData: [],
      surveys_list: {
        ...state.surveys_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
    }),

    //Get surveys admin
    [ActionTypes.GET_SURVEY_ADMIN]: (state) => ({
      ...state,
      surveysData: [],
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      admin_surveys_list: {
        ...state.admin_surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_SURVEY_ADMIN_SUCCESS]: (state, action) => ({
      ...state,
      admin_surveys_list: {
        ...state.admin_surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      paginatedSurveys: { ...action.payload },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.GET_SURVEY_ADMIN_ERROR]: (state, action) => ({
      ...state,
      surveysData: [],
      admin_surveys_list: {
        ...state.admin_surveys_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
    }),
    [ActionTypes.GET_SINGLE_SURVEY_ADMIN]: (state) => ({
      ...state,
      admin_surveys_detail: {
        ...state.admin_surveys_detail,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_SINGLE_SURVEY_ADMIN_SUCCESS]: (state, action) => ({
      ...state,
      admin_surveys_detail: {
        ...state.admin_surveys_detail,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      }
    }),
    [ActionTypes.GET_SINGLE_SURVEY_ADMIN_ERROR]: (state, action) => ({
      ...state,
      admin_surveys_detail: {
        ...state.admin_surveys_detail,
        response: {},
        loading: false,
        hasError: true,
        error: action.payload,
      }
    }),


    //Get learner surveys
    [ActionTypes.LEARNER_SURVEY_LIST]: (state) => ({
      ...state,

      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      learner_surveys_list: {
        ...state.learner_surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LEARNER_SURVEY_LIST_SUCCESS]: (state, action) => ({
      ...state,

      learner_surveys_list: {
        ...state.learner_surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.LEARNER_SURVEY_LIST_ERROR]: (state, action) => ({
      ...state,

      learner_surveys_list: {
        ...state.learner_surveys_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
    }),

    //Get deleted surveys
    [ActionTypes.GET_DELETED_SURVEYS_LISTING]: (state) => ({
      ...state,

      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      deleted_survey_list: {
        ...state.deleted_survey_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_DELETED_SURVEYS_LISTING_SUCCESS]: (state, action) => ({
      ...state,

      deleted_survey_list: {
        ...state.deleted_survey_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.GET_DELETED_SURVEYS_LISTING_ERROR]: (state, action) => ({
      ...state,

      deleted_survey_listist: {
        ...state.deleted_survey_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
    }),

    //Get learner surveys detail
    [ActionTypes.LEARNER_SURVEY_DETAIL]: (state) => ({
      ...state,

      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      learner_surveys_detail: {
        ...state.learner_surveys_detail,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LEARNER_SURVEY_DETAIL_SUCCESS]: (state, action) => ({
      ...state,

      learner_surveys_detail: {
        ...state.learner_surveys_detail,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.LEARNER_SURVEY_DETAIL_ERROR]: (state, action) => ({
      ...state,

      learner_surveys_detail: {
        ...state.learner_surveys_detail,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
    }),

    //Survey detail
    [ActionTypes.GET_SINGLE_SURVEY_LIST]: (state) => ({
      ...state,
      surveyDetail: {},

      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      report_Loading: true,
      survey_detail_Complete: {
        ...state.survey_detail_Complete,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_SINGLE_SURVEY_LIST_SUCCESS]: (state, action) => ({
      ...state,
      surveyDetail: { ...action.payload },

      IsSurveyData: true,
      report_Loading: false,
      loading: false,
      hasError: false,
      error: {},
      survey_detail_Complete: {
        ...state.survey_detail_Complete,
        response: {},
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_SINGLE_SURVEY_LIST_ERROR]: (state, action) => ({
      ...state,
      surveyDetail: {},
      report_Loading: false,
      IsSurveyData: true,
      survey_detail_Complete: {
        ...state.survey_detail_Complete,
        response: {},
        loading: false,
        hasError: false,
        error: {},
      },
      error: action.payload,
      loading: false,
      hasError: false,
    }),

    //Update Survey
    [ActionTypes.UPDATE_SURVEY]: (state) => ({
      ...state,
      surveyDetail: {},
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: true,
    }),
    [ActionTypes.UPDATE_SURVEY_SUCCESS]: (state, action) => ({
      ...state,
      surveyDetail: { ...action.payload },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: true,
    }),
    [ActionTypes.UPDATE_SURVEY_ERROR]: (state, action) => ({
      ...state,
      surveyDetail: {},
      error: action.payload,
      loading: false,
      hasError: false,
      IsSurveyData: true,
    }),

    //restore survey

    [ActionTypes.RESTORE_SURVEY]: (state) => ({
      ...state,
      survey_restore: {
        ...state.survey_restore,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.RESTORE_SURVEY_SUCCESS]: (state, action) => ({
      ...state,
      survey_restore: {
        ...state.survey_restore,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.RESTORE_SURVEY_ERROR]: (state, action) => ({
      ...state,
      survey_restore: {
        ...state.survey_restore,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    //submit Survey
    [ActionTypes.SURVEY_SUBMIT]: (state) => ({
      ...state,
      surveys_submit: {},
      loading: true,
      hasError: false,
      error: {},
    }),
    [ActionTypes.SURVEY_SUBMIT_SUCCESS]: (state, action) => ({
      ...state,
      surveys_submit: { ...action.payload },
      loading: false,
      hasError: false,
      error: {},
    }),
    [ActionTypes.SURVEY_SUBMIT_ERROR]: (state, action) => ({
      ...state,
      surveys_submit: {},
      error: action.payload,
      loading: false,
      hasError: false,
    }),

    //Delete Survey
    [ActionTypes.DELETE_SURVEY]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.DELETE_SURVEY_SUCCESS]: (state, action) => ({
      ...state,
      delete_survey: { 
        ...state.delete_survey,
        response:{ ...action.payload } 
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.DELETE_SURVEY_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
    }),
    //Get paginated surveys
    [ActionTypes.PAGINATION_NEXT]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_list: {
        ...state.surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    //Delete Survey
    [ActionTypes.DELETE_SURVEY_FOREVER]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.DELETE_SURVEY_FOREVER_SUCCESS]: (state, action) => ({
      ...state,
      response: { ...action.payload },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.DELETE_SURVEY_FOREVER_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
    }),

    //Get paginated surveys
    [ActionTypes.PAGINATION_NEXT]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_list: {
        ...state.surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    [ActionTypes.PAGINATION_NEXT_SUCCESS]: (state, action) => ({
      ...state,
      paginatedSurveys: { ...action.payload },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_list: {
        ...state.surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.PAGINATION_NEXT_ERROR]: (state, action) => ({
      ...state,
      paginatedSurveys: {},
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      surveys_list: {
        ...state.surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    //handle previous pagination
    [ActionTypes.PAGINATION_PREVIOUS]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_list: {
        ...state.surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
      ...state,
      paginatedSurveys: { ...action.payload },
      surveys_list: {
        ...state.surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
      ...state,
      paginatedSurveys: {},
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      surveys_list: {
        ...state.surveys_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //Get paginated admin surveys
    [ActionTypes.SURVEY_ADMIN_PAGINATION_NEXT]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      admin_surveys_list: {
        ...state.admin_surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SURVEY_ADMIN_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
      ...state,
      paginatedSurveys: { ...action.payload },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
      admin_surveys_list: {
        ...state.admin_surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SURVEY_ADMIN_PAGINATION_NEXT_ERROR]: (state, action) => ({
      ...state,
      paginatedSurveys: {},
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      admin_surveys_list: {
        ...state.admin_surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    //handle previous pagination admin
    [ActionTypes.SURVEY_ADMIN_PAGINATION_PREVIOUS]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      admin_surveys_list: {
        ...state.admin_surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SURVEY_ADMIN_PAGINATION_PREVIOUS_SUCCESS]: (
      state,
      action
    ) => ({
      ...state,
      paginatedSurveys: { ...action.payload },
      admin_surveys_list: {
        ...state.admin_surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.SURVEY_ADMIN_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
      ...state,
      paginatedSurveys: {},
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      admin_surveys_list: {
        ...state.admin_surveys_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    // Learner Pagination
    [ActionTypes.LEARNER_PAGINATION_NEXT]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      learner_surveys_list: {
        loading: false,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LEARNER_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
      ...state,

      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
      learner_surveys_list: {
        ...state.learner_surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LEARNER_PAGINATION_NEXT_ERROR]: (state, action) => ({
      ...state,
      paginatedSurveys: {},
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      learner_surveys_list: {
        ...state.learner_surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    //handle previous pagination leaner
    [ActionTypes.LEARNER_PAGINATION_PREVIOUS]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      learner_surveys_list: {
        ...state.learner_surveys_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LEARNER_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
      ...state,

      learner_surveys_list: {
        ...state.learner_surveys_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.LEARNER_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
      ...state,

      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      learner_surveys_list: {
        ...state.learner_surveys_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    // Draft Pagination
    [ActionTypes.DRAFT_PAGINATION_NEXT]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_draft_list: {
        loading: false,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DRAFT_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
      ...state,

      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_draft_list: {
        ...state.surveys_draft_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DRAFT_PAGINATION_NEXT_ERROR]: (state, action) => ({
      ...state,
      paginatedSurveys: {},
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      surveys_draft_list: {
        ...state.surveys_draft_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    //handle previous pagination draft
    [ActionTypes.DRAFT_PAGINATION_PREVIOUS]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_draft_list: {
        ...state.surveys_draft_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DRAFT_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
      ...state,

      surveys_draft_list: {
        ...state.surveys_draft_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.DRAFT_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
      ...state,

      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      surveys_draft_list: {
        ...state.surveys_draft_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    // Deleted Pagination
    [ActionTypes.DELETE_PAGINATION_NEXT]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      deleted_survey_list: {
        loading: false,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
      ...state,

      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
      deleted_survey_list: {
        ...state.deleted_survey_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_PAGINATION_NEXT_ERROR]: (state, action) => ({
      ...state,
      paginatedSurveys: {},
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      deleted_survey_list: {
        ...state.deleted_survey_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    //handle previous pagination draft
    [ActionTypes.DELETE_PAGINATION_PREVIOUS]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      deleted_survey_list: {
        ...state.deleted_survey_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
      ...state,

      deleted_survey_list: {
        ...state.deleted_survey_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.DELETE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
      ...state,

      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
      deleted_survey_list: {
        ...state.deleted_survey_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //MANAGER_LIST
    [ActionTypes.GET_MANAGERS_LIST]: (state) => ({
      ...state,
      manager_list: {
        ...state.manager_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MANAGERS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      manager_list: {
        ...state.manager_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MANAGERS_LIST_ERROR]: (state, action) => ({
      ...state,
      manager_list: {
        ...state.manager_list,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //MANAGER_LIST
    [ActionTypes.GET_MANAGERS_LIST]: (state) => ({
      ...state,
      manager_list: {
        ...state.manager_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MANAGERS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      manager_list: {
        ...state.manager_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MANAGERS_LIST_ERROR]: (state, action) => ({
      ...state,
      manager_list: {
        ...state.manager_list,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    //Get DRAFT surveys
    [ActionTypes.GET_DRAFT_SURVEYS]: (state) => ({
      ...state,
      surveysData: [],
      loading: true,
      hasError: false,
      error: {},
      IsSurveyData: false,
      surveys_draft_list: {
        ...state.surveys_draft_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_DRAFT_SURVEYS_SUCCESS]: (state, action) => ({
      ...state,
      surveysData: action.payload?.results,
      editData: [...action?.payload?.results],
      surveys_draft_list: {
        ...state.surveys_draft_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
      paginatedSurveys: { ...action.payload },
      loading: false,
      hasError: false,
      error: {},
      IsSurveyData: false,
    }),
    [ActionTypes.GET_DRAFT_SURVEYS_ERROR]: (state, action) => ({
      ...state,
      surveysData: [],
      surveys_draft_list: {
        ...state.surveys_draft_list,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
      error: action.payload,
      loading: false,
      hasError: true,
      IsSurveyData: false,
    }),

    //GET_GROUP_LIST

    [ActionTypes.GROUPS_LIST]: (state) => ({
      ...state,
      group_list: {
        ...state.group_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GROUPS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      group_list: {
        ...state.group_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GROUPS_LIST_ERROR]: (state, action) => ({
      ...state,
      group_list: {
        ...state.group_list,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
  },
  initialState
);

export default reducer;
