import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from "./ckQues";
import {FormControl} from "react-bootstrap";
function FillBlanks(props) {

    const { values,errors,index,setFieldValue,questionsErrors,questionsTouched,handleBlur,setValues} = props
    const handleAnswer = (e) =>{
        setFieldValue(`questions.${index}.right_answer`, e.target.value)
    }

    return (
        <div className="module-quiz-add-wrap">


            <Row>

                <Col md={12}>
                    <div className="form-input-wrap">
                        <Form.Label>Add Question</Form.Label>
                        <CkEditorQuestion questionsErrors={questionsErrors} questionsTouched={questionsTouched} name={`questions.${index}.question`} setFieldValue={setFieldValue} values={values} handleBlur={handleBlur} index={index}/>

                    </div>
                </Col>
                <Col xs={12}>
                    <Row>
                        <Col md={4}>
                            <div className="form-input-wrap">
                                <Form.Label>Right Answer</Form.Label>
                                <Form.Control
                                    placeholder="Add Right Answer"
                                    name={`questions.${index}.fill_in_the_blanks.answer`}
                                    onBlur={handleBlur}
                                    defaultValue={values?.questions[index]?.fill_in_the_blanks?.answer}
                                    isInvalid={!!questionsErrors?.fill_in_the_blanks?.answer &&  questionsTouched?.fill_in_the_blanks?.answer &&  !!questionsTouched?.fill_in_the_blanks?.answer}
                                    onChange={e => setFieldValue(`questions.${index}.fill_in_the_blanks.answer`, e.target.value)}
                                />
                                <FormControl.Feedback type="invalid">
                                    {questionsErrors?.fill_in_the_blanks?.answer}
                                </FormControl.Feedback>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="form-input-wrap" >
                                <Form.Label>Score for the Question</Form.Label>
                                <Form.Control
                                    type="text" placeholder="Score"
                                    name={`questions.${index}.score`}
                                    value={values?.questions[index]?.score}
                                    onBlur={handleBlur}
                                    isInvalid={!!questionsErrors?.score && !!questionsTouched?.score }
                                    onChange={e => setFieldValue(`questions.${index}.score`, e.target.value)}
                                />
                                <FormControl.Feedback type="invalid">
                                    {questionsErrors?.score}
                                </FormControl.Feedback>
                            </div>


                        </Col>

                    </Row>
                </Col>
            </Row>

        </div>
    );
}

export default FillBlanks;

