import 'chartjs-plugin-datalabels';

export const chart1 = {
	fullWidth: true
};

//Stacked bar chart

export const chart6Data = {
	labels: ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10', 'Q11', 'Q13', 'Q14'],
	series: [
		[100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300],
		[100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300],
		[100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300]
	]
};

export const chart6Options = {
	stackBars: true,
	axisY: {
		labelInterpolationFnc: function (value) {
			return (value / 1000) + 'k';
		}
	},
};


// Chart 9 Extreme responsive configuration
export const chart9Data = {
	data: {
		labels: ['2010-11', '2011-12', '2012-13', '2013-13', '2014-15', '2015-16'],
		series: [2,7,8,6,2,6,3,4,8,5,5,4,5,4,1,9,5,5,5,5,5,5,5,5,5,5,1,4,3,2]
	},
	options: {
		distributeSeries: true,

		horizontalBars: false,
		// axisX: {
		// 	offset: 50
		// },

	},
	responsiveOptions: [
		[
			"screen and (max-width: 1440px)",
			{
				seriesBarDistance: 11.5,
			}
		],
		[
			"screen and (max-width: 480px)",
			{
				stackBars: true,
				seriesBarDistance: 10
			}
		]
	]
}

export const chart2 = {
    low: 0,
    showArea: true,
    showPoint: false,
    fullWidth: true
}


// Chart 3 Animating a Donut with Svg.animate
export const chart2Data = {
	series: [10, 20, 50, 20, 5, 40, 15],
	labels: ['3.6%', '40.7%', '30.2%', '42%', '36%', '12%', '18%']
}
export const chart2Option = {
	donut: true,
	showLabel: true,
	donutWidth:150,
}


