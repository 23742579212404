import React, {useState,} from "react";
import wallClockBlackImg from '~/assets/images/icons/wall-clock-black.svg';
import img9 from '~/assets/images/moduleselection/img9.svg';
import img10 from '~/assets/images/moduleselection/img10.svg';
import Spinner from "react-bootstrap/Spinner";
import {useSelector} from "react-redux";
import $ from "jquery";
import img5 from '~/assets/images/moduleselection/img5.svg';


const AccordianItem = (props) => {
    const {modules} = props;
    const loading = useSelector(state => state.learnercourse?.learner_course?.loading);


    function get_vimeo_thumbnail(url, size, callback) {
        var result;
        if(result = url.match(/vimeo\.com.*[\\\/](\d+)/)){
            var video_id   = result.pop();
            $.getJSON('http://vimeo.com/api/v2/video/' + video_id + '.json', function(data) {
                if(data){
                    if (typeof(callback) !== 'undefined') {
                        var thumbnail_src = data[0].thumbnail_large;
                        if(thumbnail_src){
                            callback(thumbnail_src);
                        }
                    }
                }
            });

        }


    }

    function get_youtube_thumbnail(url, quality,index) {

        if(url.includes('vimeo')){
            get_vimeo_thumbnail(url, 'large', function(url){
                let vimeos = document.getElementsByClassName('vimeos')
                vimeos[index]?.setAttribute('src',url)
            })

        }else if (url) {
            var video_id, thumbnail, result;
            if (result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
                video_id = result.pop();
            } else if (result = url.match(/youtu.be\/(.{11})/)) {
                video_id = result.pop();
            }

            if (video_id) {
                if (typeof quality == "undefined") {
                    quality = 'high';
                }

                var quality_key = 'maxresdefault'; // Max quality
                if (quality == 'low') {
                    quality_key = 'sddefault';
                } else if (quality == 'medium') {
                    quality_key = 'mqdefault';
                } else if (quality == 'high') {
                    quality_key = 'hqdefault';
                }

                var thumbnail = "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
                return thumbnail;
            }
        }
        return false;
    }

    const checkImageAvailability = (item,index) => {
        if (item.image) {
            return item?.image
        } else if (!item.image && item.template == 'question_multiple_choice') {
            return img9;
        }else if (!item.image && item.template == 'text_only') {
            return img5;
        }else if (!item.image && item.template == 'quiz') {
      return img9;
    }
        else if (!item.image && item.template == 'question_true_or_false') {
            return img10;
        } else if (!item.image && item.template == 'text_with_embedded_video') {
            return get_youtube_thumbnail(`${item?.video}`, 'max',index);
        }
    };

    const fileEvent = (file) => {
        if (file) {
            let imageUrl = URL.createObjectURL(file);
            return imageUrl;
        }

    };

    const checkTemplate = (el) => {

        if (el.template == 'question_multiple_choice') {
            return 'Multiple Choice';
        } else if (el.template == 'question_true_or_false') {
            return 'True False';
        }else if (el.template == 'text_only') {
            return 'Text only';
        }else if (el.template == 'image_only') {
            return 'Image only';
        }else if (el.template == 'quiz') {
            return 'Quiz';
        }
        else if (el.template == 'text_with_image_on_left') {
            return 'Text with Image';
        } else if (el.template == 'text_with_image_on_right') {
            return 'Text with Image';
        } else if (el.template == 'text_with_image_on_top') {
            return 'Text with Image';
        } else if (el.template == 'text_with_image_at_the_bottom') {
            return 'Text with Image';
        } else if (el.template == 'text_with_embedded_video') {
            return 'Text with Video';
        }

    };
    const handleTitle  = (text)=>{
        return text?.substr(0, 45);
    }
    return (

        <div className="default-according">
            <div className="acc-card-wrapper">
                {!loading && modules && modules.length ? modules.map((el,index) => {
                        return (
                            <div className=" card mb-3">
                                <div className='bg-white card-header1 learner-acc-item'>
                                    <div className="d-flex w-100 acc-title align-items-center">
                                    <div className="course-acc-title-wrap"> <h3>{el?.name}</h3></div>
                                        <div className="acc-right-title">
                                            {el?.template == 'question_multiple_choice' || el?.template == 'question_true_or_false' || el?.template == 'text_only'  ?
                                                   <>
                                                <div className="acc-right-box-1 acc-right-box-img">
                                                    <img
                                                        className='vimeos'
                                                        dataSet={index} src={checkImageAvailability(el)}/><span
                                                    className="cc-right-time-text"></span>
                                                </div>
                                                <div className="acc-right-box-1">
                                                    <img    dataSet={index} src={wallClockBlackImg}/><span
                                                    className="cc-right-time-text">{checkTemplate(el)}</span>
                                                </div>
                                                   </>
                                                :
                                                <>
                                                <div className="acc-right-box-1 acc-right-box-img">
                                                <img dataSet={index}
                                                     className='vimeos'
                                                src={checkImageAvailability(el,index)}/><span
                                                className="cc-right-time-text"></span>
                                                </div>
                                                    <div className="acc-right-box-1">
                                                        <img    dataSet={index} src={wallClockBlackImg}/><span
                                                        className="cc-right-time-text">{checkTemplate(el)}</span>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>

                        );
                    })
                    :
                    <div className='loaderCourses'>
                        {loading ?
                            <Spinner animation="border" variant="danger"/>
                            :
                            'No Data'
                        }

                    </div>
                }

            </div>
        </div>
        //         <AccordionNode  className="accordian-item card">
        //           <AccordionHeader className="bg-white card-header"
        //             horizontalAlignment="centerSpaceAround"
        //             verticalAlignment="center"
        //           >
        //             <div className="d-flex w-100 acc-title">
        //               <h3>Introduction to Course Library</h3>
        //               <div className="acc-right-title">
        //                <div className="acc-right-box-1">
        //                   <img src={book}/><span className="acc-right-time-text">5 Questions</span>
        //                </div>
        //                <div className="acc-right-box-1">
        //                   <img src={wallClockBlackImg}/><span className="acc-right-time-text">Fill in the blanks</span>
        //                </div>
        //             </div>
        //             </div>
        //           </AccordionHeader>
        //           <AccordionPanel>
        //             <div className="card-body">
        //
        //             </div>
        //           </AccordionPanel>
        //         </AccordionNode>
        //
        //
        //
        //   </div>
        // </div>
    );

};

export default AccordianItem;
