import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from "./ckQues";
import { FormControl } from "react-bootstrap";
function FreeResponse(props) {

    const { values, errors, index, setFieldValue, general_questionsErrors, general_questionsTouched, handleBlur, setValues } = props
    const handleAnswer = (e) => {
        setFieldValue(`general_questions.${index}.right_answer`, e.target.value)
    }

    return (
        <div className="module-quiz-add-wrap">


            <Row>

                 <Col md={12} >
                    <Row >
                        <Col md={6} >
                            <div className="form-input-wrap">
                                <Form.Label>Add Question Title</Form.Label>
                                <span className="mandatory-star">*</span>
                                <Form.Control maxlength="20"
                                    value={values?.general_questions[index]?.title ? values?.general_questions[index]?.title : ''}
                                    name={`general_questions.${index}.title`}
                                    onChange={e => setFieldValue(`general_questions.${index}.title`, e.target.value)}
                                    isInvalid={!!general_questionsErrors?.title && general_questionsTouched?.title && !!general_questionsTouched?.title}
                                    placeholder={`Question - ${index + 1}`}
                                    minLength="8" />
                                <Form.Text id="passwordHelpBlock" muted>
                                Your title must not exceed 20 characters

                                </Form.Text>


                            </div>
                        </Col>
                    </Row>

                </Col> 

                <Col md={12}>
                    <div className="form-input-wrap">
                        <Form.Label>Add Question</Form.Label>
                        <span className="mandatory-star">*</span>
                        {/* <CkEditorQuestion general_questionsErrors={general_questionsErrors} general_questionsTouched={general_questionsTouched} name={`general_questions.${index}.question`} setFieldValue={setFieldValue} values={values} handleBlur={handleBlur} index={index}/> */}
                        <Form.Control

                            value={values?.general_questions[index]?.question}
                            name={`general_questions.${index}.question`}
                            onChange={e => setFieldValue(`general_questions.${index}.question`, e.target.value)}
                            isInvalid={!!general_questionsErrors?.question && general_questionsTouched?.question && !!general_questionsTouched?.question}


                            as="textarea" rows={4} />



                    </div>
                </Col>
                <Col xs={12}>
                    <Row>
                        {/* <Col md={4}>
                            <div className="form-input-wrap">
                                <Form.Label>Right Answer</Form.Label>
                                <Form.Control
                                    placeholder="Add Right Answer"
                                    name={`general_questions.${index}.fill_in_the_blanks.answer`}
                                    onChange={handleBlur}
                                    value={values?.general_questions[index]?.fill_in_the_blanks?.answer}
                                    isInvalid={!!general_questionsErrors?.fill_in_the_blanks?.answer &&  general_questionsTouched?.fill_in_the_blanks?.answer &&  !!general_questionsTouched?.fill_in_the_blanks?.answer}
                                    onChange={e => setFieldValue(`general_questions.${index}.fill_in_the_blanks.answer`, e.target.value)}
                                />
                                <FormControl.Feedback type="invalid">
                                    {general_questionsErrors?.fill_in_the_blanks?.answer}
                                </FormControl.Feedback>
                            </div>
                        </Col> */}
                        {/* <Col md={4}>
                            <div className="form-input-wrap" >
                                <Form.Label>Score for the Question</Form.Label>
                                <Form.Control
                                    type="text" placeholder="Score"
                                    name={`general_questions.${index}.score`}
                                    value={values?.general_questions[index]?.score}
                                    onChange={handleBlur}
                                    isInvalid={!!general_questionsErrors?.score && !!general_questionsTouched?.score }
                                    onChange={e => setFieldValue(`general_questions.${index}.score`, e.target.value)}
                                />
                                <FormControl.Feedback type="invalid">
                                    {general_questionsErrors?.score}
                                </FormControl.Feedback>
                            </div>


                        </Col> */}

                    </Row>
                </Col>
            </Row>

        </div>
    );
}

export default FreeResponse;

