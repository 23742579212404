import React from 'react';
import ScrollArea from 'react-scrollbar';
import videoImg from '~/assets/images/videoimage.png';

const ImageOnly = (props) => {
    const {value,index,length} = props

    return (
        <div className="videomodule">

            <ScrollArea
                speed={0.8}
                className="modulemodalscroll"
                contentClassName="content"
                horizontal={false}
            >
                <div className="video-content-wrap">
                <div className="module-content">
                        <div className="d-flex justify-content-between m-b-20">
                            <h3 className="module-content-header">{value?.name}</h3>
                        </div>
                    </div>
                    <div className="img-wrap">
                        <div className="image-wrap-item imageonly-wrap">
                        <img src={value?.image} alt="" />
                    </div>
                    </div>
          
                </div>
            </ScrollArea>

        </div>
    )

}

export default ImageOnly;
