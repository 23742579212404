import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import ScrollArea from 'react-scrollbar';
// import Quizz from '../../../../../../../shared/quizz.json'
import {getAllQuiz} from "../../../../../services";
import {useDispatch, useSelector} from "react-redux";
import {getQuizzesWithOutPagination} from "../../../../../../learningpath/services";

function AddQuiz(props) {
   const {setFieldValue,values,handleChange,errors,touched,urlId} = props
   const dispatch = useDispatch();
  let response = useSelector(state => state?.learningpath?.quizzes_without_pagination?.response);
    const courseDetail = useSelector(state => state?.course?.get_course_manager?.response);
    const { hideLpQuizes } = useSelector((state) => state.settings);

   const [state, setState] = useState({
      quiz: [],
      selectedQuiz: [],
       tempoQuiz:[],
      quizId: []
   })
   useEffect(() => {
      if(!hideLpQuizes){
         dispatch(getQuizzesWithOutPagination());
      }
      
   }, []);
   useEffect(() => {
       if(urlId) {
           if (urlId && response?.results && response?.results?.length || courseDetail?.quizzes?.length ) {

               let {quizId} = state;
               let newArray = response?.results?.map(item => {
                   let temp = {...item, isChecked: courseDetail?.quizzes?.includes(item.id)};
                   return temp
               })
               let selectedArray = newArray?.filter((el) => {
                   return el.isChecked
               })
               let coursesId = selectedArray?.map((el) => el.id)
                 setFieldValue('quizzes',selectedArray)
               setState({...state, quiz: newArray,tempoQuiz:newArray, selectedQuiz: selectedArray, quizId: coursesId})
           } else {
               setState({...state, quiz: response?.results})
           }
       }else{
           setState({...state, quiz: response?.results})
       }
   }, [response,courseDetail]);

   const handleListClick = (item,index) => {
      let {selectedQuiz,quiz,quizId} = state;
      quiz[index].isChecked = !quiz[index].isChecked
      if(selectedQuiz.find(f => f.name === item.name)) {
         selectedQuiz = selectedQuiz.filter(f=> f.name !== item.name);
         quizId = quizId.filter(id => id !=item.id)
      } else {
         selectedQuiz.push(item)
         quizId.push(item.id)
      }

      setFieldValue('quizzes',selectedQuiz)
      setState({...state, selectedQuiz: selectedQuiz,quiz:quiz,quizId: quizId})
   }


   const handleRemove = (item,index)=>{
      const {selectedQuiz,quiz} =state
      selectedQuiz.splice(index,1)
      let tempIndex = quiz.findIndex((el)=>{
         return el.id == item.id
      })
      quiz[tempIndex].isChecked = false
      let quizsId = selectedQuiz?.map((el)=>el.id)
      setFieldValue('quizzes',selectedQuiz)
      setState({...state,selectedQuiz: selectedQuiz,quiz: quiz,quizId:quizsId})

   }

   const handleChangeInput = (e)=>{
      let val = e.target.value;
       if (val.length > 0) {
           if(urlId) {
               let results = state?.tempoQuiz?.filter(item =>
                   item.name.toLowerCase().includes(val.toLowerCase())
               );
               setState({...state, quiz: results})
           }else{
               let results = state?.quiz?.filter(item =>
                   item.name.toLowerCase().includes(val.toLowerCase())
               );
               setState({...state, quiz: results})
           }
       } else {
           if(urlId){
               const {tempoQuiz} =state
               setState({...state,quiz: tempoQuiz})
           }else {
               setState({...state,quiz: response?.results})
           }

       }
   }
   return (
      <div className="coursedetails-wrap">
         <div className="course-form-content-sec d-flex">
            <div className="add-quiz-box whitebox shadow-box">
               <div className="add-quiz-box-header">

                     <div className="form-input-wrap">
                        <Form.Control type="text" placeholder="Search Quizzes" className="input-item" onChange={e =>handleChangeInput(e)}/>
                        <button type="button" className="icon-btn">
                           <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                              <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                           </svg>
                        </button>
                     </div>

               </div>

               <div className="add-quiz-box-content">
                  <ScrollArea
                     speed={0.8}
                     className="listscroll"
                     contentClassName="content"
                     horizontal={false}
                  >
                     <ul>
                        {state?.quiz && state?.quiz?.length  ? state?.quiz?.map((item,index) => (
                                <li className={`${item.isChecked && item.isChecked  ? 'list-selected' : ''}`} onClick={()=>handleListClick(item,index)}>
                                   <span value={item.id} >{item.name}</span>
                                </li>
                            ))
                            :
                            'No QUIZ Available'
                        }

                     </ul>
                  </ScrollArea>
               </div>

            </div>

            <div className="course-list-selected-box">
               {state?.selectedQuiz && state?.selectedQuiz?.length  ? state?.selectedQuiz?.map((item,index) => (
               <div className="course-list-selected-box-content">
                  <div className="course-selected-item p-relative">
                     <span className="course-selected-text">{item.name}</span>
                     <span className="memb-close-icon" onClick={()=>handleRemove(item,index)}>×</span>
                  </div>
            </div>
                   ))
                   :
                   ''
               }
         </div>

      </div>
      </div>
   );
};
export default AddQuiz;
