import React, { memo,useContext,useState } from 'react';

import {Handle, removeElements} from 'react-flow-renderer';
import {FlowContext} from "../container";
import {ToastContainer, toast} from "react-toastify";
import QuizDummy from '~/assets/images/quiz-demo.jpg';
import {deleteElementByIdLp} from "../../../services";
import {useDispatch, useSelector} from "react-redux";
export default memo(({ data, isConnectable,...rest }) => {
    const [elements,setElements,nodeId,setNodeId,lpId,setNodeType] =useContext(FlowContext)
    const [nestedNode,setNestedNode] = useState(null)
    const toastId = React.useRef(null);
    const deleteLoading = useSelector(state => state?.learningpath?.delete_lp_module?.loading);
    let dispatch = useDispatch();
    const handleRemove = (e) =>{
        e.stopPropagation();
        let elmentsToRemove = elements?.filter((el)=>el.id == rest.id)
        let singleElement = elements?.find((el)=>el.id == rest.id)
        let parent = elements.find((el)=>el.id == singleElement?.source)

        setNestedNode(singleElement.id)
        let copyElements = [...elements]
        if(singleElement.is_created) {
            dispatch(deleteElementByIdLp(lpId,rest.id,function (res){
                if(res){


                    if(parent && parent?.content_type_name == 'quiz'){
                        setNodeId(parent?.id)
                        setNodeType(parent?.content_type_name)
                    }
                    if (nodeId == rest.id) {
                        setNodeId('')
                    }
                    setElements(copyElements)
                    setTimeout(() => {
                        setElements((els) => removeElements(elmentsToRemove, els));
                    }, 10)
                }
            }))
        }else{


            if(parent && parent?.content_type_name == 'quiz'){
                setNodeId(parent?.id)
                setNodeType(parent?.content_type_name)
            }
            if (nodeId == rest.id) {
                setNodeId('')
            }
            setElements(copyElements)
            setTimeout(() => {
                setElements((els) => removeElements(elmentsToRemove, els));
            }, 10)
        }


    }
    const isValidConnectionSource = (connection) => {
        let findParent = elements.find((el)=>el.id == connection.source)
        let findChild = elements.find(((el)=>el.id == connection.target))
        let childrens = elements.filter((el)=>el.source == findParent.id)
        if(findParent?.data?.selType == findChild?.data?.selType || childrens.length  == 4){
           if(findParent?.data?.selType == findChild?.data?.selType){
               if( !toast.isActive(toastId.current)) {
                toast.error("Quiz can not be connected to Quiz!", {
                    toastId: 'success1',
                });

               }else{
                   // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
               }

           }
           if(childrens.length  == 4){

               if( !toast.isActive(toastId.current)) {
                   toast.error("Only 4 courses can be connected to quiz!", {
                       toastId: 'success1',
                   });

               }else{
                   // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
               }

           }
            return false
        }else {
            return true
        }
    }
    const isValidConnection = (connection) => {
        let findParent = elements.find((el)=>el.id == connection.source)
        let findChild = elements.find(((el)=>el.id == connection.target))
        let childrens = elements.filter((el)=>el.source == findParent.id)
        if(findParent?.data?.selType == findChild?.data?.selType ||  findParent?.data?.selType =='course' && childrens.length  == 1){
            if(findParent?.data?.selType == findChild?.data?.selType){

                if( !toast.isActive(toastId.current)) {
             toast.error("Quiz can not be connected to Quiz!", {
                 toastId: 'success1',
             });

                }else{
                    // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                }



            }
            if(childrens.length  == 1){
                if( !toast.isActive(toastId.current)) {
             toast.error("Only 1 quiz can be connected to course", {
                 toastId: 'success1',
             });

                }else{
                    // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                }

            }

            return false
        } else{
            if(childrens.find((el)=>el.sourceHandle == connection.sourceHandle) ||  childrens.find((el)=>el?.data?.id == findChild?.data?.id)){
                 if(childrens.find((el)=>el.sourceHandle == connection.sourceHandle)){
                     if( !toast.isActive(toastId.current)) {
                       toast.error("Only one node can be connected to one handle!", {
                           toastId: 'success1',
                       });

                     }else{
                         // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                     }
                 }else{
                     if( !toast.isActive(toastId.current)) {
                        toast.error("Course can not be repeated on same node !", {
                            toastId: 'success1',
                        });

                     }else{
                         // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                     }
                 }
                return  false
            }else{
                return true
            }


        }
    }
    return (
        <>
            {/*<div className="close-icon nodrag" onClick={(e)=>handleRemove(e)}>*/}
            {/*    x*/}
            {/*</div>*/}
            <div className="custom-node itsquiz  handle-custom ">
                <Handle
                    type="target"
                    position="top"
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnection}
                />
                <div >
                    <div className="" >
                        <div className="course-box-top">
                            <div  className="course-listing-img">
                                {/*<img  src={data?.image ? data?.image :QuizDummy} alt="" />*/}
                            </div>
                            <div className="common-card-detail-wrap box-listing">
                                <h4>{data?.name}</h4>
                            </div>
                            <div className="coommon-card-det-number ">
                                {elements.find((el)=>el.id == rest.id) ?
                                    <>
                                        <span className="common-card-oth-det-left">Range Type<span className="det-numb">{elements.find((el)=>el.id == rest.id).criteria}</span> </span><br/>
                                        <span className="common-card-oth-det-left">Questions Count <span className="det-numb">{data?.questions_count}</span> </span>
                                    </>

                                    :
                                    ''
                                }
                                {deleteLoading && nestedNode && nestedNode == rest.id ?

                                    <div className="box-overlay1" >

                                        <div className="overlay-bg"><div className='delete-text-here d-flex justify-content-center align-items-center
                                    '>Deleting...</div></div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className="nodes-wrap">
                <div className='handle-custom-one'>
                <Handle
                    type="source"
                    position="bottom"
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnectionSource}
                />
                </div>
                <div className='handle-custom-two'>
                <Handle
                    type="source"
                    position="bottom"
                    id='a'
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnectionSource}
                />
                </div>
                <div className='handle-custom-three'>
                <Handle
                    type="source"
                    position="bottom"
                    id='b'
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnectionSource}
                />
                </div>
                <div className='handle-custom-four'>
                <Handle
                    type="source"
                    position="bottom"
                    id='c'
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnectionSource}
                />
                </div>
                </div>
                {/*<Handle*/}
                {/*    type="source"*/}
                {/*    position="bottom"*/}
                {/*    id='d'*/}
                {/*    style={{ background: '#555' }}*/}
                {/*    onConnect={(params) => console.log('handle onConnect', params)}*/}
                {/*    isConnectable={isConnectable}*/}
                {/*    isValidConnection={isValidConnectionSource}*/}
                {/*/>*/}

                {/*<Handle*/}
                {/*    type="source"*/}
                {/*    position="left"*/}
                {/*    id="a"*/}
                {/*    style={{ top: 10, background: '#555' }}*/}
                {/*    isConnectable={isConnectable}*/}
                {/*/>*/}
                {/*<Handle*/}
                {/*    type="source"*/}
                {/*    position="right"*/}
                {/*    id="b"*/}
                {/*    style={{ bottom: 10, top: 'auto', background: '#555' }}*/}
                {/*    isConnectable={isConnectable}*/}
                {/*/>*/}
            </div>
        </>
    );
});
