import React from 'react';

function ThankYou() {

    return (
        <div className="thankyoupage-wrap d-flex justify-content-center">
            <div className="thankyou-cont">
                <div className="thankyou-icon">
                    <i className="fas fa-check-circle"></i>
                </div>
                <h1>Thank you!</h1>
                <p>Your Survey has been submitted successfully </p>
            </div>
        </div>
    );
};
export default ThankYou;
