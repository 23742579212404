import { createAction, handleActions } from 'redux-actions';
import {
  _getConstructResults,_getGenderDemographicResults,_getRoleDemographicResults,_getRacialDemographicResults,
  _getRacialByAgeDemographicResults,_getSurveyConstructList,_getSurveyScoring,_getDemographicFilter,_getConstructResultsSurvey,_getConstructResultsByChoices
} from '~shared/httpService/api';
import ActionTypes from '~shared/constants/actionTypes';
import {toast} from "react-toastify";

const initialState = {
  construct_report:{ loading: false, response: {}, hasError: false, error: {} },
  gender_report:{ loading: false, response: {}, hasError: false, error: {} },
  role_report:{ loading: false, response: {}, hasError: false, error: {} },
  racial_demographics:{ loading: false, response: {}, hasError: false, error: {} },
  racialByAge_demographics:{ loading: false, response: {}, hasError: false, error: {} },
  survey_scoring:{ loading: false, response: {}, hasError: false, error: {} },
  survey_construct_list:{ loading: false, response: {}, hasError: false, error: {} },
  demographic_filter :{ loading: false, response: {}, hasError: false, error: {} },
  construct_results :{ loading: false, response: {}, hasError: false, error: {} },
  construct_results_by_choices :{ loading: false, response: {}, hasError: false, error: {} }
};

// CONSTRUCT REPORT

export const constructSurveyResultStart = createAction(ActionTypes.SURVEY_CONSTRUCT_RESULTS);
export const constructSurveyResultSuccess = createAction(ActionTypes.SURVEY_CONSTRUCT_RESULTS_SUCCESS, response => response);
export const constructSurveyResultError = createAction(ActionTypes.SURVEY_CONSTRUCT_RESULTS_ERROR, error => error);


export const getConstructResults = (id,token,construct,choice,callBack) => (dispatch) => {
  dispatch(constructSurveyResultStart());
  return _getConstructResults(id,token,construct,choice).then((response) => {
    dispatch(constructSurveyResultSuccess(response));
    callBack(response)
  }).catch((error) => {
    dispatch(constructSurveyResultError(error));
    toast.error('Something went wrong')
  });
};


//GENDER REPORT

export const genderReportStart = createAction(ActionTypes.SURVEY_GENDER_DEMOGRAPHIC_RESULTS);
export const genderReportSuccess = createAction(ActionTypes.SURVEY_GENDER_DEMOGRAPHIC_RESULTS_SUCCESS, response => response)
export const genderReportError = createAction(ActionTypes.SURVEY_GENDER_DEMOGRAPHIC_RESULTS_ERROR, error => error)

export const genderReport = (id,token) =>(dispatch) => {
  dispatch(genderReportStart())
  return _getGenderDemographicResults(id,token).then((response)=>{
    dispatch(genderReportSuccess(response))
  }).catch((err)=>{
    dispatch(genderReportError(err))
    toast.error('Something went wrong')
  })
}

// role-demographics REPORT

export const roleSurveyResultStart = createAction(ActionTypes.SURVEY_ROLE_DEMOGRAPHIC_RESULTS);
export const roleSurveyResultSuccess = createAction(ActionTypes.SURVEY_ROLE_DEMOGRAPHIC_RESULTS_SUCCESS, response => response);
export const roleSurveyResultError = createAction(ActionTypes.SURVEY_ROLE_DEMOGRAPHIC_RESULTS_ERROR, error => error);


export const getRoleResults = (id,token) => (dispatch) => {

  dispatch(roleSurveyResultStart());
  return _getRoleDemographicResults(id,token).then((response) => {
    dispatch(roleSurveyResultSuccess(response));
  }).catch((error) => {
    dispatch(roleSurveyResultError(error));
    toast.error('Something went wrong')
  });
};


export const constructResultsStart = createAction(ActionTypes.CONSTRUCT_RESULTS_NEW);
export const constructResultsSuccess = createAction(ActionTypes.CONSTRUCT_RESULTS_NEW_SUCCESS, response => response);
export const constructResultsError = createAction(ActionTypes.CONSTRUCT_RESULTS_NEW_ERROR, error => error);


export const getConstructResultsNew = (id,callback) => (dispatch) => {
  dispatch(constructResultsStart());
  return _getConstructResultsSurvey(id).then((response) => {
    dispatch(constructResultsSuccess(response));
    if(response){
      callback(response)
    }
  }).catch((error) => {
    dispatch(constructResultsError(error));

    toast.error('Something went wrong')
  });
};

export const constructResultsByChoicesStart = createAction(ActionTypes.CONSTRUCT_RESULTS_BY_CHOICES);
export const constructResultsByChoicesSuccess = createAction(ActionTypes.CONSTRUCT_RESULTS_BY_CHOICES_SUCCESS, response => response);
export const constructResultsByChoicesError = createAction(ActionTypes.CONSTRUCT_RESULTS_BY_CHOICES_ERROR, error => error);


export const getConstructResultsByChoices = (id,constructOne,constructTwo,choiceOne,choiceTwo,callback) => (dispatch) => {
  dispatch(constructResultsByChoicesStart());
  return _getConstructResultsByChoices(id,constructOne,constructTwo,choiceOne,choiceTwo).then((response) => {
    dispatch(constructResultsByChoicesSuccess(response));
    callback(response)
  }).catch((error) => {
    dispatch(constructResultsByChoicesError(error));
    toast.error('Something went wrong')
  });
};





// racial-demographics REPORT

export const racialSurveyResultStart = createAction(ActionTypes.SURVEY_RACIAL_DEMOGRAPHIC_RESULTS);
export const racialSurveyResultSuccess = createAction(ActionTypes.SURVEY_RACIAL_DEMOGRAPHIC_RESULTS_SUCCESS, response => response);
export const racialSurveyResultError = createAction(ActionTypes.SURVEY_RACIAL_DEMOGRAPHIC_RESULTS_ERROR, error => error);


export const getRacialResults = (id,token) => (dispatch) => {
  dispatch(racialSurveyResultStart());
  return _getRacialDemographicResults(id,token).then((response) => {
    dispatch(racialSurveyResultSuccess(response));
  }).catch((error) => {
    dispatch(racialSurveyResultError(error));
    toast.error('Something went wrong')
  });
};

// racial-demographics REPORT by age

export const racialByAgeSurveyResultStart = createAction(ActionTypes.SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS);
export const racialByAgeSurveyResultSuccess = createAction(ActionTypes.SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS_SUCCESS, response => response);
export const racialByAgeSurveyResultError = createAction(ActionTypes.SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS_ERROR, error => error);


export const getRacialByAgeResults = (id,token) => (dispatch) => {
  dispatch(racialByAgeSurveyResultStart());
  return _getRacialByAgeDemographicResults(id,token).then((response) => {
    dispatch(racialByAgeSurveyResultSuccess(response));
  }).catch((error) => {
    dispatch(racialByAgeSurveyResultError(error));
    toast.error('Something went wrong')
  });
};

// Survey scoring

export const surveyScoringStart = createAction(ActionTypes.SURVEY_SCORING);
export const surveyScoringSuccess = createAction(ActionTypes.SURVEY_SCORING_SUCCESS, response => response);
export const surveyScoringError = createAction(ActionTypes.SURVEY_SCORING_ERROR, error => error);


export const getSurveyScoring = (id,token) => (dispatch) => {
  dispatch(surveyScoringStart());
  return _getSurveyScoring(id,token).then((response) => {
    dispatch(surveyScoringSuccess(response));
  }).catch((error) => {
    dispatch(surveyScoringError(error));
    toast.error('Something went wrong')
  });
};

// Survey scoring

export const surveyConstructListStart = createAction(ActionTypes.SURVEY_CONSTRUCT_LISTS);
export const surveyConstructListSuccess = createAction(ActionTypes.SURVEY_CONSTRUCT_LISTS_SUCCESS, response => response);
export const surveyConstructListError = createAction(ActionTypes.SURVEY_CONSTRUCT_LISTS_ERROR, error => error);


export const getSurveyConstructList = (id,token) => (dispatch) => {
  dispatch(surveyConstructListStart());
  return _getSurveyConstructList(id,token).then((response) => {
    dispatch(surveyConstructListSuccess(response));
  }).catch((error) => {
    dispatch(surveyConstructListError(error));

    toast.error('Something went wrong')
  });
};
// Survey filter

export const demographicFilterStart = createAction(ActionTypes.CONSTRUCT_RESULTS_FILTER);
export const demographicFilterSuccess = createAction(ActionTypes.CONSTRUCT_RESULTS_FILTER_SUCCESS, response => response);
export const demographicFilterError = createAction(ActionTypes.CONSTRUCT_RESULTS_FILTER_ERROR, error => error);


export const getDemographicFilter = (id) => (dispatch) => {
  dispatch(demographicFilterStart());
  return _getDemographicFilter(id).then((response) => {
    dispatch(demographicFilterSuccess(response));
  }).catch((error) => {
    dispatch(demographicFilterError(error));

  });
};
const reducer = handleActions({

  //CONSTRUCT SURVEY RESULTS

  [ActionTypes.SURVEY_CONSTRUCT_RESULTS]: (state) => ({
    ...state,
    construct_report: { loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.SURVEY_CONSTRUCT_RESULTS_SUCCESS]: (state, action) => ({
    ...state,
    construct_report: {
      ...state.construct_report,
      response: action.payload, loading: false, hasError: false, error: {}
    },

  }),
  [ActionTypes.SURVEY_CONSTRUCT_RESULTS_ERROR]: (state, action) => ({
    ...state,
    construct_report: {
      ...state.construct_report,
      response: {}, loading: false, hasError: false, error: action.payload
    },
  }),

  //GENDER SURVEY RESULTS
  [ActionTypes.SURVEY_GENDER_DEMOGRAPHIC_RESULTS]:(state) =>({
    ...state,
    gender_report:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.SURVEY_GENDER_DEMOGRAPHIC_RESULTS_SUCCESS]:(state,action) =>({
    ...state,
    gender_report: {
      ...state.gender_report,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.SURVEY_GENDER_DEMOGRAPHIC_RESULTS_ERROR]:(state,action) =>({
    ...state,
    gender_report: {
      ...state.gender_report,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),

  // role-demographics REPORT
  [ActionTypes.SURVEY_ROLE_DEMOGRAPHIC_RESULTS]:(state) =>({
    ...state,
    role_report:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.SURVEY_ROLE_DEMOGRAPHIC_RESULTS_SUCCESS]:(state,action) =>({
    ...state,
    role_report: {
      ...state.role_report,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.SURVEY_ROLE_DEMOGRAPHIC_RESULTS_ERROR]:(state,action) =>({
    ...state,
    role_report: {
      ...state.role_report,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),


  // construct results
  [ActionTypes.CONSTRUCT_RESULTS_NEW]:(state) =>({
    ...state,
    construct_results:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.CONSTRUCT_RESULTS_NEW_SUCCESS]:(state,action) =>({
    ...state,
    construct_results: {
      ...state.construct_results,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.CONSTRUCT_RESULTS_NEW_ERROR]:(state,action) =>({
    ...state,
    construct_results: {
      ...state.construct_results,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),


  // construct results by choices

  [ActionTypes.CONSTRUCT_RESULTS_BY_CHOICES]:(state) =>({
    ...state,
    construct_results_by_choices:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.CONSTRUCT_RESULTS_BY_CHOICES_SUCCESS]:(state,action) =>({
    ...state,
    construct_results_by_choices: {
      ...state.construct_results_by_choices,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.CONSTRUCT_RESULTS_BY_CHOICES_ERROR]:(state,action) =>({
    ...state,
    construct_results_by_choices: {
      ...state.construct_results_by_choices,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),


  // racial-demographics REPORT

  [ActionTypes.SURVEY_RACIAL_DEMOGRAPHIC_RESULTS]:(state) =>({
    ...state,
    racial_demographics:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.SURVEY_RACIAL_DEMOGRAPHIC_RESULTS_SUCCESS]:(state,action) =>({
    ...state,
    racial_demographics: {
      ...state.racial_demographics,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.SURVEY_RACIAL_DEMOGRAPHIC_RESULTS_ERROR]:(state,action) =>({
    ...state,
    racial_demographics: {
      ...state.racial_demographics,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),


  // racial-demographics by age REPORT

  [ActionTypes.SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS]:(state) =>({
    ...state,
    racialByAge_demographics:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS_SUCCESS]:(state,action) =>({
    ...state,
    racialByAge_demographics: {
      ...state.racialByAge_demographics,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS_ERROR]:(state,action) =>({
    ...state,
    racialByAge_demographics: {
      ...state.racialByAge_demographics,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),
  // Survey Scoring

  [ActionTypes.SURVEY_SCORING]:(state) =>({
    ...state,
    survey_scoring:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.SURVEY_SCORING_SUCCESS]:(state,action) =>({
    ...state,
    survey_scoring: {
      ...state.survey_scoring,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.SURVEY_SCORING_ERROR]:(state,action) =>({
    ...state,
    survey_scoring: {
      ...state.survey_scoring,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),

  // Survey Construct List

  [ActionTypes.SURVEY_CONSTRUCT_LISTS]:(state) =>({
    ...state,
    survey_construct_list:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.SURVEY_CONSTRUCT_LISTS_SUCCESS]:(state,action) =>({
    ...state,
    survey_construct_list: {
      ...state.survey_construct_list,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.SURVEY_CONSTRUCT_LISTS_ERROR]:(state,action) =>({
    ...state,
    survey_construct_list: {
      ...state.survey_construct_list,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),

  // Survey Construct fILTER

  [ActionTypes.CONSTRUCT_RESULTS_FILTER]:(state) =>({
    ...state,
    demographic_filter:{ loading: true, response: {}, hasError: false, error: {} }
  }),
  [ActionTypes.CONSTRUCT_RESULTS_FILTER_SUCCESS]:(state,action) =>({
    ...state,
    demographic_filter: {
      ...state.demographic_filter,
      response: action.payload, loading: false, hasError: false, error: {}
    },
  }),
  [ActionTypes.CONSTRUCT_RESULTS_FILTER_ERROR]:(state,action) =>({
    ...state,
    demographic_filter: {
      ...state.demographic_filter,
      response: {}, loading: false, hasError: false, error: action.payload
    },

  }),

}, initialState);

export default reducer;
