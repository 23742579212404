import React from 'react';

function ProfileIcon() {
return (
   <React.Fragment>
<svg xmlns="http://www.w3.org/2000/svg" width="16.209" height="20" viewBox="0 0 16.209 20">
   <g transform="translate(11.021 38)">
      <path className="a" d="M14.689,20A6.591,6.591,0,1,0,1.52,20H0a8.16,8.16,0,0,1,8.1-8.383A8.159,8.159,0,0,1,16.209,20ZM2.938,5.167A5.167,5.167,0,1,1,8.1,10.333,5.172,5.172,0,0,1,2.938,5.167Zm1.52,0A3.647,3.647,0,1,0,8.1,1.52,3.652,3.652,0,0,0,4.458,5.167Z" transform="translate(-11.021 -38)"/>
   </g>
</svg>
</React.Fragment>
);
}
export default ProfileIcon;