import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import AvailableLearningPaths from '../components/availablelearningpaths/container'
function LearningPathManagement (){

   return (
    <div className="tab-wrapper">
       <Link to="/learningpathbuilder"><Button className="tab-right-button">Create New Learning Path</Button></Link>
    <Tabs defaultActiveKey="AvailableLearningPaths" id="uncontrolled-tab-example" className="m-b-30">
       <Tab eventKey="AvailableLearningPaths" title="Available Learning Paths">
          <AvailableLearningPaths />
       </Tab>
       <Tab eventKey="Published" title="Published">
       kkkk
       </Tab>
       <Tab eventKey="Drafts" title="Drafts">
       kkkk
       </Tab>
    </Tabs>

 </div>
   );
};
export default LearningPathManagement;
