import React, {useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from "react-redux";
import Logo from '~/assets/images/logo.png';
import { getSubscriptionPlans,checkSubscriptionStatus,signInSuccess } from '~/modules/auth/services';
import { subscriptionPlanTerms } from "~/shared/constants";
import { selectPlan } from '~/modules/auth/services';
import Spinner from "react-bootstrap/Spinner";
import {useHistory} from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {resetStore} from "../../services";
import {simpleRedirect} from "../../../../shared/utils/checkHost";


function SubscriptinOnSignup() {
   const history = useHistory();
   const dispatch = useDispatch();
   const { subscriptionPlans, user } = useSelector(state => state.auth);
    const loading = useSelector(state => state.auth?.selected_plan?.loading);
    const subloading = useSelector(state => state.auth?.subscription_status?.loading);
   const [count,setCount] = useState(0)
   const [state, setState] = useState({
      plans : [],
      term :true,
      planid:''
   })
   const [checkplan, setCheckPlan] = useState(true)

    useEffect(() => {
     if(user && user?.is_subscribed == false) {
         dispatch(checkSubscriptionStatus(user?.institution_id, function (res) {
             if (res) {

                 if (res?.is_subscribed == true) {
                     let response = {data: {...user, is_subscribed: true}}
                     dispatch(signInSuccess(response))
                     setCount((prevState) => prevState + 1)

                 }

             }
         }));
     }
    }, []);
   useEffect(()=>{
       dispatch(getSubscriptionPlans());

    },[])

useEffect(()=>{
     let filterArray = subscriptionPlans?.filter((el)=>{
        return el.term == 'per_year'
     })
   let newSubscriptions = filterArray?.sort((a,b) =>{
      return a.max_members - b.max_members;
   });
   setState({...state, plans:newSubscriptions})
},[subscriptionPlans])

    const logout = () => {
        let action = 'USER_LOGOUT'
        dispatch(resetStore(action))

    }

   useEffect(()=>{
       simpleRedirect(user,history,logout)
   },[count])

  const handleSelectPlan = (plan) =>{
    setState({...state, planid: plan?.stripe_id})
     dispatch(selectPlan({price_id:plan.stripe_id},history,'pre_profile',null));
  };
  
const handleChange = (checked) => {
   let filterArray ;
 if(checked){
    filterArray = subscriptionPlans?.filter((el)=>{
       return el.term == 'per_year'
    })
 }else {
     filterArray = subscriptionPlans?.filter((el)=>{
       return el.term == 'per_month'
    })
 }
   let newSubscriptions = filterArray?.sort((a,b) =>{
      return a.max_members - b.max_members;
   });
   setCheckPlan(checked)
   setState({...state, plans:newSubscriptions})
   }

    const handleLoader = () => {
        if (subloading) {
            return (
                <div className="loader-full-screen-course">
                    <div className="loader">
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
            );
        }
    };
// console.log('user sub',user)
//     console.log('fff',subloading)
   return (
      <div className="auth-page-wrap subscription-page-auth auth-bg d-flex justify-content-center align-items-center m-t-50">
          <div>{handleLoader()}</div>
         <div className="subscription-page-wrap text-center">
            <div className="auth-logo"><img src={Logo} alt="" /></div>
            <h2 className="auth-title mb-4">Cultural Engagement</h2>
            {/* <p className="auth-desc">Sign up to start using the platform.</p> */}
            <div className="subsciption-box">
                <div className="swith-icon-wrap d-flex justify-content-end">
               <span className="monthly-yearly-text">Monthly</span>
                  <BootstrapSwitchButton onChange={handleChange} checked={checkplan} size="xs" />
                  <span className="monthly-yearly-text">Yearly</span>
                  </div>
               <Row className="subsciption-box-row justify-content-center">

                  <ToastContainer autoClose={2000} />
                  {
                     state.plans?.map(plan => (
                        <Col key={plan.id} md={3} sm={6} className="subsciption-box-cont">
                           <div className="subscription-item">
                              <div className="subcribe-top-contents">
                                 {/*<div className="level-number">*/}
                                 {/*   <span>{plan?.name}</span>*/}
                                 {/*</div>*/}
                                  <div className="upto-count">
                                      <span>Up to {plan?.max_members} members</span>
                                  </div>
                                 <div className="pricing-wrap mt-4">
                                    <span className="pricing-unit"><i className="fa fa-usd"></i></span>
                                    <span className="price-value">{Math.trunc(plan?.price)}</span>
                                    <span className="validity">/{plan?.term && plan.term == 'per_month' ? 'Month' : 'Year'} </span>
                                 </div>
                                 {/*<div className="upto-count">*/}
                                 {/*   <span>Up to {plan?.max_members} members</span>*/}
                                 {/*</div>*/}
                              </div>
                              <div className="button-wrap">
                                    <Button className="w-100" onClick={()=> handleSelectPlan(plan)}>
                                        {loading && loading && state?.planid == plan?.stripe_id ?
                                            <Spinner style={{color:'white'}} animation="border" variant="warning"/>
                                            :
                                        'Choose Plan'}</Button>

                              </div>
                           </div>
                        </Col>
                     ))
                  }

                  {/* <Col md={3} sm={6} className="subsciption-box-cont">
                     <div className="subscription-item subscr-black-box">
                        <div className="subcribe-top-contents">
                           <div className="level-number">
                              <span>Enterprise</span>
                           </div>
                           <div className="level4-text">
                              <span>For companies with more than 2000 users, please contact us</span>
                           </div>
                        </div>
                        <div className="button-wrap">
                           <Button className="w-100">Contact Us</Button>
                        </div>
                     </div>
                  </Col> */}
               </Row>
            </div>
         </div>
      </div>
   );
}
export default SubscriptinOnSignup;
