import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CourseAssignBox from "../lpassign/components/courseassignbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GroupAssignBox from "../lpassign/components/groupselectionbox";
import Modal from "react-bootstrap/Modal";
import {
  assignLearningPath,
  getLearningPathAssignments,
  getLearningPathAssignmentsById,
  updateAssignLearning,
  deleteAssignLearning,
} from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ScrollArea from "react-scrollbar";
import Spinner from "react-bootstrap/Spinner";
import AssignmentPopup from "../enrollusersLP/components/assignmentname";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { edLinkAssignmet } from "./../../../../modules/coursemanagement/services";
import ClassesAssignBox from "./../../../coursemanagement/components/coursebuilder/components/enrollusers/components/classesAssignBox";

function MyVerticallyCenteredModal(props) {
  const dispatch = useDispatch();
  const response = useSelector(
    (state) => state.learningpath?.get_lp_assignments?.response
  );
  const loading = useSelector(
    (state) => state.learningpath?.get_lp_assignments?.loading
  );
  const delLoading = useSelector(
    (state) => state.learningpath?.delete_lp_assigment?.loading
  );
  const [assignId, setAssignId] = useState("");
  const [state, setState] = useState({
    courseAssignments: response?.results || [],
    modalId: "",
    isClassesDisplay: false,
    classes: [],
  });

  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    if (props.url) {
      dispatch(getLearningPathAssignments(props.url));
    }
  }, []);
  useEffect(() => {
    if (props.url) {
      setState({ ...state, courseAssignments: response?.results });
    }
  }, [response]);
  const handleSelection = (id, list) => {
    setState({ ...state, modalId: id });
    props.handle(id, list);
  };

  const handleCreate = () => {
    props.onHide();
    props.handleAssignment();
  };
  const handleDelete = (e, item) => {
    e.stopPropagation();
    setAssignId(item.id);
    dispatch(
      deleteAssignLearning(props?.lpId, item.id, history, function (res) {
        if (res) {
          let copy = [...state.courseAssignments];
          copy = copy.filter((el) => el.id != item.id);
          setState({ ...state, courseAssignments: copy });
        }
      })
    );
  };

  return (
    <Modal
      className="custom-modal confirmation-popup inner-popup"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="add-quiz-box-content">
          <div onClick={() => props.onHide(8)}>
            {" "}
            <div className="form-close-btn p-relative">
              <span className="memb-close-icon-card">×</span>
            </div>
          </div>
          {props.url ? (
            <>
              <h4 className="course-assign-type-name">
                List Of Learning Path User Enrollments
              </h4>
              <h4 className="course-assign-type-name">
                Choose User Enrollment whom you want to Edit
              </h4>
            </>
          ) : (
            ""
          )}

          <ScrollArea
            speed={0.8}
            className="listscroll"
            contentClassName="content"
            horizontal={false}
          >
            <ul>
              {props.url ? (
                <div>
                  {state?.courseAssignments &&
                  state?.courseAssignments &&
                  state?.courseAssignments?.length ? (
                    state?.courseAssignments?.map((el, index) => {
                      return (
                        <li
                          onClick={() => handleSelection(el.id, el)}
                          key={index}
                          className={`cus-list-i ${
                            el.isChecked && el.isChecked ? "list-selected" : ""
                          }`}
                        >
                          {props.loading &&
                          props.loading &&
                          state.modalId == el?.id ? (
                            <Spinner animation="border" variant="warning" />
                          ) : (
                            <span>
                              {` ${el?.name} ${
                                el?.due_date ? el?.due_date : ""
                              }`}
                              <div
                                onClick={(e) => handleDelete(e, el)}
                                className="del-i"
                              >
                                {delLoading && assignId == el.id ? (
                                  <>
                                    <Spinner
                                      animation="border"
                                      variant="warning"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      id="icons_Q2"
                                      data-name="icons Q2"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17.49"
                                      height="19.331"
                                      viewBox="0 0 17.49 19.331"
                                    >
                                      <path
                                        id="Path_8323"
                                        data-name="Path 8323"
                                        d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                                        transform="translate(-4.999 -3)"
                                        fill="#fff"
                                      />
                                      <path
                                        id="Path_8324"
                                        data-name="Path 8324"
                                        d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                                        transform="translate(-7.261 -9.477)"
                                        fill="#fff"
                                      />{" "}
                                    </svg>
                                  </>
                                )}
                              </div>
                            </span>
                          )}
                        </li>
                      );
                    })
                  ) : (
                    <div className="row justify-content-center mt-2">
                      {loading ? (
                        <Spinner animation="border" variant="danger" />
                      ) : (
                        <>
                          <div className="col-12 text-center">
                            <p>No Existing User Enrollment</p>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className=" text-center mt-3">
                <button className="btn btn-m border-btn" onClick={handleCreate}>
                  Create New User Enrollment
                </button>
              </div>
            </ul>
          </ScrollArea>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const CourseAssign = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let loading = useSelector(
    (state) => state?.learningpath?.get_lp_assigment_by_id?.loading
  );
  const response = useSelector(
    (state) => state.learningpath?.get_lp_assignments?.response
  );
  let updateAssignmentLoading = useSelector(
    (state) => state?.learningpath?.updated_lp_assigment?.loading
  );
  let addAssignmentLoading = useSelector(
    (state) => state?.learningpath?.assign_lp?.loading
  );

  const user = useSelector((state) => state.auth?.user);
  const [option, setOption] = useState('')
  const [state, setState] = useState({
    isDisplay: false,
    isGroupDisplay: false,
    activeTab:"enga",
    isClassesDisplay: false,
    classes: [],
    member: [],
    groupDetails: [],
    modalShow: false,
    assignmentId: "",
    singleAssignment: {},
    failedVaues: {},
    popUp: false
  });
  useEffect(() => {
    if (props?.urlId) {
      dispatch(getLearningPathAssignments(props.urlId));
    }
  }, [props?.keys]);
  
  const groupSelection = useRef();
  const memberSelection = useRef();
  const classesSelection = useRef();

  const handleOk = () => {
    setState({ ...state, activeTab: option,isDisplay:false, isGroupDisplay:false, isClassesDisplay:false })
    setOption('')
  };

  const handleCancel = () => {
    
    setOption('')
  };

  const memberList = (data) => {
    setState({ ...state, member: data });
  };
  const [assignmentPopup, setassignmentPopup] = useState(false);
  const groupList = (data) => {
    setState({ ...state, groupDetails: data });
  };

  const classesList = (data) => {
    setState({ ...state, classes: data });
  };

  const onSave = () => {
    let data = {
      groups: state.groupDetails,
      learners: state.member,
      classes: state.classes,
      name: state?.basicInformation?.name
        ? state?.basicInformation?.name
        : state?.singleAssignment?.name,
      due_date: state?.basicInformation?.due_date
        ? state?.basicInformation?.due_date
        : state?.singleAssignment?.due_date,
    };

    let payload = {
      ed_link_class_ids: state.classes,
      name: state?.basicInformation?.name
        ? state?.basicInformation?.name
        : state?.singleAssignment?.name,
      due_date: state?.basicInformation?.due_date
        ? state?.basicInformation?.due_date
        : state?.singleAssignment?.due_date,
    };

    if (
      state?.basicInformation?.name?.length ||
      state?.singleAssignment?.name?.length
    ) {
      if (state.assignmentId && props?.urlId) {
        dispatch(
          updateAssignLearning(
            data,
            props.urlId,
            state.assignmentId,
            history,
            function (err) {
              if (err) {
                setState({ ...state, failedVaues: data });
                setassignmentPopup(true);
              }
            }
          )
        );
      } else if (user?.is_edlink_teacher && state.activeTab == "edlink"){
        dispatch(
          edLinkAssignmet(payload, props?.courseId, history, function (error) {
            if (error) {
              setState({ ...state, failedVaues: data });
              setassignmentPopup(true);
            }
          })
        );
        } else {
        dispatch(
          assignLearningPath(data, props?.courseId, history, function (error) {
            if (error) {
              setState({ ...state, failedVaues: data });
              setassignmentPopup(true);
            }
          })
        );
      }
    } else {
      setassignmentPopup(true);
      setState({ ...state, focus: true });
    }
  };
  const getBasicInformation = (data) => {
    setState({ ...state, basicInformation: data });
  };

  const handleBack = () => {
    history.push("/learningpaths");
  };
  const handleAssignmentsById = (id, list) => {
    dispatch(
      getLearningPathAssignmentsById(
        id,
        props.urlId,
        function (response) {
          if (response) {
            memberSelection.current.checked = true;
            groupSelection.current.checked = true;
            classesSelection.current.checked = true;
            setState({
              ...state,
              isGroupDisplay: true,
              isDisplay: true,
              modalShow: false,
              assignmentId: id,
              isClassesDisplay: true,
              singleAssignment: list,
            });
            setassignmentPopup(true);
          }
        },
        function (error) {
          setState({ ...state, modalShow: false });
        }
      )
    );
  };
  const handleShow = () => {
    setState({ ...state, modalShow: true });
  };

  const handleLoader = () => {
    if (updateAssignmentLoading || addAssignmentLoading) {
      return (
        <div className="loader-full-screen-course">
          <div className="loader">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      );
    }
  };

  const handleAssignmentPopUp = () => {
    setassignmentPopup(true);
  };
  return (
    <>
    
      <div className="course-assign-wrap">


      <Modal
          className="common-modal team-member-pop inner-popup"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={option}
        >
          <Modal.Header>
            <div className="addmember-header">
              <h4>Attention! Once you switch from one assignment to another, your previous assignment will not be saved.</h4>

            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="add-quiz-box-content d-flex justify-content-center">
              <div className=" text-center mt-3">
                <button
                  className="btn btn-m border-btn"
                  onClick={handleCancel}
                >
                  Let's stay
                </button>
              </div>
              <div className=" text-center mt-3  ml-3">
                <Button
                 
                   variant="primary"
              className="btn-m"
                  onClick={handleOk}
                >
                  Go, ahead
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>


        {handleLoader()}
       { user?.is_edlink_teacher && (  <div className="course-assign-member-type">
          <Form className="d-block">
          <Row>
              <Col md="2">
                <FormCheck
                  custom
                  type="radio"
                  id="enga"
                  name="activeTab"
                  value={"enga"}
                  label={`Assign with Enga`}
                  // onChange={(e) =>{
                  //   setState({ ...state, activeTab: e.target.value })
                  // }}
                  onChange={(e) => {
                    setOption('enga')
                  }}
                  checked={state.activeTab==="enga"}
                  
                />
              </Col>
              <Col md="2">
                {" "}
                <FormCheck
                  custom
                  type="radio"
                  id="edlink"
                  name="activeTab"
                  value="edlink"
                  label={`Assign with Edlink`}
                  onChange={(e) =>{
                    // setState({ ...state, activeTab: e.target.value })
                  
                      setOption('edlink')
                   
                  }}
                  checked={state.activeTab == "edlink"}

                 
                  
                />
              </Col>
            </Row>
            
          </Form>
        </div>
      )}
        <div className="course-assign-member-type">
          <Form className="d-block">
            <Row>
              {state.activeTab == "enga" && <>
              <Col md="3">
                <Form.Check
                  custom
                  type="checkbox"
                  id="check1"
                  ref={memberSelection}
                  label={`Add member`}
                  onChange={(e) =>
                    setState({ ...state, isDisplay: e.target.checked })
                  }
                />
              </Col>
              <Col md="3">
                {" "}
                <Form.Check
                  custom
                  type="checkbox"
                  id="check2"
                  label={`Add group`}
                  ref={groupSelection}
                  onChange={(e) =>
                    setState({ ...state, isGroupDisplay: e.target.checked })
                  }
                />
              </Col>
              </>}
              {(user && user?.is_edlink_teacher && state.activeTab == "edlink") && (
                <Col md="3">
                  <Form.Check
                    custom
                    type="checkbox"
                    id="check3"
                    label={`Add Classes`}
                    ref={classesSelection}
                    onChange={(e) =>
                      setState({ ...state, isClassesDisplay: e.target.checked })
                    }
                  />
                </Col>
              )}
              {response?.results &&
              response?.results?.length &&
              props?.urlId ? (
                <Col md="6" className="justify-content-end d-flex">
                  <Button
                    variant="primary"
                    className="btn-m"
                    onClick={handleShow}
                  >
                    Edit Existing Enrollments
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Form>
        </div>
        <div className="course-assign-box-container">
          {state.isDisplay && (
            <>
              <h4 className="course-assign-type-name">
                Learners to whom you want to assign this Learning Path
              </h4>
              <CourseAssignBox
                singleAssignment={state.singleAssignment}
                urlId={props.urlId}
                assignId={state?.assignmentId}
                memberList={memberList}
              />
            </>
          )}
        </div>
        <div className="course-assign-box-container">
          <div className="course-assign-box-container">
            {state.isGroupDisplay && (
              <>
                <h4 className="course-assign-type-name">
                Groups to whom you want to assign this Learning Path
                </h4>
                <GroupAssignBox
                  singleAssignment={state.singleAssignment}
                  urlId={props.urlId}
                  assignId={state?.assignmentId}
                  groupList={groupList}
                />
              </>
            )}
          </div>
          <div className="course-assign-box-container">
            {state.isClassesDisplay && (
              <>
                <h4 className="course-assign-type-name">
                  Classes to whom you want to assign this course
                </h4>
                <ClassesAssignBox
                  singleAssignment={state.singleAssignment}
                  urlId={props.urlId}
                  assignId={state?.assignmentId}
                  classesList={classesList}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="add-module-footer d-flex justify-content-end">
        <div className="button-wrap double-btn">
          <button className="btn btn-m border-btn" onClick={handleBack}>
            Cancel
          </button>
          {state.assignmentId && props.urlId ? (
            <Button
              variant="primary"
              className="btn-m"
              disabled={updateAssignmentLoading}
              onClick={onSave}
            >
              Enroll Users
            </Button>
          ) : (
            <Button
              variant="primary"
              className="btn-m"
              disabled={addAssignmentLoading}
              onClick={onSave}
            >
              Enroll Users
            </Button>
          )}

          {/*<Button variant="dark" className="btn-m">Preview</Button>*/}
          {/*<Button variant="primary" className="btn-m">Publish</Button>*/}
          {/*<Button variant="primary" className="btn-m">Continue</Button>*/}
        </div>
      </div>
      {props.keys == 2 ? (
        <MyVerticallyCenteredModal
          url={props.urlId}
          show={state.modalShow}
          loading={loading}
          lpId={props?.courseId}
          handleAssignment={handleAssignmentPopUp}
          handle={handleAssignmentsById}
          onHide={() =>
            setState({ ...state, modalShow: false, assignmentId: "" })
          }
        />
      ) : (
        ""
      )}
      <AssignmentPopup
        show={assignmentPopup}
        failed={state.failedVaues}
        basicInformation={getBasicInformation}
        singleAssignment={state?.singleAssignment}
        focus={state?.focus}
        onHide={() => setassignmentPopup(false)}
      />
    </>
  );
};
export default CourseAssign;
