import React, { useState, useEffect,useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
    getSurveyAdmin,
    handlePaginationAdmin,
    handlePreviousPaginationAdmin,
    cloneSurvey,
    getDraftSurveysList,
    getSingleAdminSurvey
} from '~/modules/surveys/services';
import { format } from 'date-fns';
import { element } from "prop-types";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getNotifications } from "../../../../Notifications/services";
import { getInstitutionType } from "../../../../auth/services";
import CustomPagination from "../../../../../shared/components/pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import previewIco from '~/assets/images/icons/preview.svg';
import QuizModulePopView from "../../../surveyManagement/surveybuilder/components/managerQuizPreview"
import Select, { components } from "react-select" ;
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';



const AvailableSurveys = () => {

    const dispatch = useDispatch();
    // const response = useSelector(state => state.surveys.surveys_list?.response);

    const detailLoading = useSelector(state => state.surveys.admin_surveys_detail?.loading);
    const response = useSelector(state => state.surveys.admin_surveys_list?.response);
    const surveyCategory = useSelector(
        (state) => state?.surveys?.survey_category?.response
    );
    
    const { user } = useSelector(state => state.auth);
    const history = useHistory();
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [surveys, setSurveys] = useState(response);
    const [dateRange, setDateRange] = useState([null, null]);
    const [previewPop, setPreviewPop] = useState(false);
    const [previewValues, setPreviewValues] = useState([]);
    const [categorySelected, setCategorySelected] = useState('');

    const [startDate, endDate] = dateRange;
    const [createdby, setCreatedby] = useState('');

    const [state, setState] = useState({
        modalShow: false,
        surveyLink: ''

    });

    const prevActive = useRef(null)

    const[dataProgress,setDataProgress]=useState(false)
    const loading = useSelector(state => state.surveys.admin_surveys_list?.loading);


    useEffect(() => {
        if(loading){ setDataProgress(true)}

       if (!loading){setDataProgress(false)}
      }, [loading])

      

    useEffect(() => {

        setSearch('');
        setCategorySelected('')
        setDateRange([null, null]);
        setCreatedby('');
        // dispatch(getSurveyAdmin(search, startDate === null ? '' : startDate, endDate == null ? '' : endDate, '',''));
        // dispatch(getNotifications())


    }, []);

    useEffect(() => {

        setSurveys(response);
    }, [response]);



    useEffect(() => {

        if (startDate !== null && endDate !== null) {

            dispatch(getSurveyAdmin(search, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd'), '',categorySelected ? categorySelected?.id : ''));
            setActivePage(1);
        }

    }, [startDate, endDate]);



    const reset = () => {
        setSearch('');
        setCategorySelected('')
        setDateRange([null, null]);
        setCreatedby('');
        setActivePage(1)
        dispatch(getSurveyAdmin("", startDate === null ? '' : '', endDate == null ? '' : '', ''));
    };


    const handleNext = () => {
        if (surveys?.next) {

            setActivePage((prevState) => prevState+1);
            dispatch(handlePaginationAdmin(surveys?.next));
        } else {
            return false;
        }

    };
    const handleClone = (id) => {
        dispatch(cloneSurvey(id, function (res) {
            if (res.data.survey_id) {
                history.push({
                    pathname : `/survey-edit/${res.data.survey_id}`
                })
            }
        }))
    }

    const handlePreview = (id) => {
        prevActive.current = id;
        dispatch(
            getSingleAdminSurvey(id, (res) => {
                const demographic_questions = (res?.demographic_questions && res?.demographic_questions?.length) ? res?.demographic_questions : [];
                const general_questions = ((res?.general_questions && res?.general_questions?.length) ? res?.general_questions : []);
        
                const ques = demographic_questions.concat(general_questions);
                setPreviewValues({ ...res, questions: ques });
                setPreviewPop(true);
            })
        )
    }

    const searchInput = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        dispatch(getSurveyAdmin(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), '',categorySelected ? categorySelected?.id : ''));
        setActivePage(1);
    },[search,categorySelected]);



    const handlePrevious = () => {
        if (surveys?.previous) {
            setActivePage((prevState) => prevState-1);
            dispatch(handlePreviousPaginationAdmin(surveys?.previous));

        } else {
            return false;
        }

    };

    const handleIndex = (index) => {
            dispatch(getSurveyAdmin(search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), index,categorySelected ? categorySelected?.id : ''));

        setActivePage(index);
    };

    const tableColumns = [
        {
            name: 'Survey Name',
            selector: 'name',
            sortable: true,
            center: false,
            grow: 5
        },

        // {
        //     name: 'Number of Responses',
        //     selector: 'attended_count',
        //     sortable: true,
        //     center: false,
        //     grow: 2
        // },


        // {
        //     name: 'Due Date',
        //     selector: 'expiry_date',
        //     sortable: true,
        //     center: false,
        //     grow: 2,

        // },


        {
            name: 'Action',
            selector: 'Action',
            sortable: true,
            center: false,
            grow: 1,
            cell: (row, index) =>
                <div data-tag="allowRowEvents" className='d-flex' >
                    <div className="action-icons-cell-wrap d-flex">

                        <div className="action-icon-item fill-icon copy-icon" onClick={() => handleClone(row?.id)}>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="InfoText">
                                        Clone
                                    </Tooltip>
                                }
                            >
                                <svg id="copy" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <rect id="Rectangle_3299" data-name="Rectangle 3299" width="24" height="24" fill="none" />
                                    <g id="icons_Q2" data-name="icons Q2" transform="translate(3.869 3.868)">
                                        <path id="Path_8326" data-name="Path 8326" d="M17.011,7.253V4.813A.813.813,0,0,0,16.2,4H4.813A.813.813,0,0,0,4,4.813V16.2a.813.813,0,0,0,.813.813H7.253V19.45a.813.813,0,0,0,.813.813H19.45a.813.813,0,0,0,.813-.813V8.066a.813.813,0,0,0-.813-.813ZM5.626,15.384V5.626h9.758v9.758Zm13.011,3.253H8.879V17.011H16.2a.813.813,0,0,0,.813-.813V8.879h1.626Z" transform="translate(-4 -4)" fill="#fff" /> </g>
                                </svg>
                            </OverlayTrigger>
                        </div>


                    </div>
                    <div className="action-icons-cell-wrap d-flex">

                      
                            {(detailLoading && (prevActive.current === row?.survey_uuid))? (
                               <Spinner animation="border" role="status"/>
                                     
                            ): (
                                <div className="action-icon-item fill-icon copy-icon" onClick={() => handlePreview(row?.survey_uuid)}>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id="InfoText">
                                            Preview
                                        </Tooltip>
                                    }
                                >
                                    <img src={previewIco}/>
                                </OverlayTrigger>
                                </div>
                            )}
                            
                      


                    </div>
                    {/*<Link to={`/edit_survey/${row.id}`} className="btn border-btn link-btn mx-2">*/}
                    {/*    Edit</Link>*/}
                    {/*<Link onClick={e => handleDeleteSurvey(row)} className="btn border-btn link-btn">*/}
                    {/*    Delete</Link>*/}
                </div>
            ,
        },
    ];

    const CaretDownIcon = () => {
        return (
           <div className="filter-icon-select">
           <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
           <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
           </svg>
           </div>
        );
      };

    const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownIcon />
          </components.DropdownIndicator>
        );
      };

      const onCategorySelect = (val) => {
        setCategorySelected(val);
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="available-course-wrap">

            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="datatable-filter-left-box d-flex align-items-center">
                    <div className="datepicker-box p-relative">
                        <DatePicker
                            placeholderText={'Created date'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        <span className="datepickericon">
                            <i className="fa fa-calendar"></i>
                        </span>
                    </div>


                </div>
                <div className="dt-filter-left d-flex justify-content-between align-items-center">
                     <div className="custom-react-select">
                        <Select options={surveyCategory?.results || []} getOptionLabel={(option) => option.name}   getOptionValue={option => option.id}

                        value={categorySelected} onChange={onCategorySelect} placeholder="Category" components={{ DropdownIndicator }} />
                    </div>
                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative search-icon-left">
                    <button className="iconbutton search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216"
                            viewBox="0 0 19.216 19.216">
                            <path className="a"
                                d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                        </svg>
                    </button>
                        <input type="text" placeholder="Search using keyword"
                            value={search} onChange={searchInput}
                            className="form-control input-item" />
                        <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                    </div>
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="InfoText">
                                Help
                            </Tooltip>
                        }
                    >
                        <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                        </span>
                    </OverlayTrigger>
                </div>
            </div>

            {loading && loading ? (
                <>
                    <div className="loaderCourses">
                        <Spinner animation="border" variant="danger" />
                    </div>
                </>
            ) : (
                <>
                {surveys?.results?.length > 0 ? (
                    <div className="datatble-wrap">
                        <DataTable
                            data={surveys?.results}
                            columns={tableColumns}
                            center={true}
                            noHeader={true}
                            progressPending={dataProgress}
                            progressComponent={
                             <div >
                                 <Spinner animation="border" variant="warning"/>
                             </div>
                            }
                        />
                   
                    
                       
                        {!dataProgress && surveys?.count > 12 ?
                            <CustomPagination response={surveys} activePage={activePage} handleNext={handleNext} handlePrevious={handlePrevious} handleIndex={handleIndex} />
                            :
                            ""
                        } 
                       
                        
                 </div>
                 ) : (
                     <>
                        {search||  categorySelected || dateRange[0] || dateRange[1]  ? (
            
                           <span className='noData'>No matching results found</span>
                        ) : (
                            <span className='noData text-center'>You have no  surveys in your Template Library</span>
                        )}
                     </>
                 )
                 }  
                </>
            )  }
          
                  <QuizModulePopView
                        show={previewPop}
                        values={previewValues}
                        // values={...response,questions:{...response?.demographic_questions,...response?.general_questions}}
                        onHide={() => setPreviewPop(false)}

                />
                <Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="module-modal-header my-4"><h4>Surveys are developed and published by you and others in your organization here.</h4></div>
                    <ol className="info-list">
                        <li><span>The “Create New Survey” button enables managers to create a survey.</span></li>
                        <li><span>After a survey is published, it will appear under the “Published Surveys” tab.</span></li>
                        <li><span>Surveys can be: 1&#41;	 previewed, 2&#41;	 deleted, or 3&#41;	 cloned. </span></li>
                        <li><span>Published surveys cannot be edited. To duplicate a survey, clone it and the survey will appear under the “Drafts” tab. </span></li>
                        <li><span>Send out the survey by selecting the “Copy Survey Link” under the “Action” column, then paste and send the link to respondents via email, interoffice communication, etc. </span></li>
                        <li><span>Note that the identities of the survey respondents will not be saved, survey managers will only see their selected demographic information.</span></li>
                        <li><span>Select the “Reports” icon in the “Action” column to view real time survey results &#40;note: refresh the window for most up-to-date results&#41;	.</span></li>
                        <li><span>Search for a survey using the: 1&#41;	 Created Date, 2&#41;	 Author, 3&#41;	 Category, 4&#41;	status filters, or 4&#41;	 by keyword. </span></li>
                    </ol>



                </Modal.Body>
                

                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
        </div>
    );
};
export default AvailableSurveys;
