import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements, Elements,
} from "@stripe/react-stripe-js";
import { ToastContainer, toast } from 'react-toastify';
import { verifyStipePaymentIntent} from '~/modules/auth/services';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
export default function CheckoutForm(props) {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {user, signUpError,stripe_payment_intent} = useSelector(state => state.auth);
    const [iLoading,setILoading] = useState(true)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsLoading(true);
        const { error,...rest } = await stripe.confirmPayment({
            elements,
           redirect:'if_required'
        });
       if(!error && rest.paymentIntent.status == 'succeeded' ){



           // dispatch(verifyStipePaymentIntent(values,function (res){
               setMessage("Success");
               setIsLoading(false);
           // }))
           props.handleCreatePlan()
           props.handleGoNext()


           return false
       }
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }
        setIsLoading(false);
    };
    const handleLoad = () =>{
        setILoading(false)


    }
  

    return (
        <form id="payment-form" onSubmit={handleSubmit}>

            <PaymentElement  onReady={handleLoad} id="payment-element" />
            {iLoading &&
            <Spinner animation="border" variant="danger"/>

            }
            {!iLoading &&
            <button className='sub-btn' disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : `Pay now`}
        </span>
            </button>
            }
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}
