import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Logo from "~/assets/images/logo.png";
import AutosizeInput from "react-input-autosize";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { verifySubDomain } from "~/modules/auth/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubdomainSignIn = () => {
  const dispatch = useDispatch();
  const handleSetUpDomain = (values) => {
    dispatch(
      verifySubDomain(
        values,
        function (response) {
          if (response) {
            if (response.status == true) {
              toast.success("Sign In Successful");
              var url = window.location.href;
              var newUrl = url.replace(/^http:\/\//, "http://" + values + ".");
              return false;
              window.location = newUrl;
            }
          }
        },
        function (error) {
          if (error) {
            toast.error("Something went wrong");
          }
        }
      )
    );
  };

  return (
    <div className="auth-page-wrap">
      <div className="auth-page-box d-flex">
        <div className="auth-right d-flex justify-content-center align-items-center">
          <div className="auth-cont-box">
            <div className="auth-cont-box-header text-center">
              <div className="auth-logo">
                <img src={Logo} alt="" />
              </div>
              <h2 className="auth-title">Sign In</h2>
              <p className="auth-desc">Please enter your unique domain.</p>
            </div>
            <div className="auth-form-box white-box radius-box">
              <Formik
                validationSchema={SetUpDomain}
                onSubmit={handleSetUpDomain}
                initialValues={{
                  name: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="form-input-wrap">
                      <Form.Label>Sub-Domain</Form.Label>
                      <div className="workspace-input-wrap">
                        <div className="workspace-input">
                          <AutosizeInput
                            placeholder="Your-team-url"
                            className="auto-width-input"
                            autoFocus
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="workspace-text">.enga.com</div>
                      </div>
                      {errors.name && (
                        <span className={`form-error text-danger`}>
                          {errors.name}
                        </span>
                      )}
                    </div>
                    <div className="button-wrap">
                      <Button type="submit" className="w-100">
                        Sign In
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const SetUpDomain = yup.object().shape({
  name: yup.string().required("Required"),
});
export default SubdomainSignIn;
