import React, { useEffect, useState } from "react";
import Logo from "~/assets/images/logo.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import NormalLogin from "../../normallogin/container";
import OtpLogin from "../../otplogin/container";
import { useLocation } from "react-router";
import FirstTimeLogin from "../../firsttimelogin/container";
import {Helmet} from "react-helmet";

function LoginTab() {
    const location = useLocation();
    const [loginTab, setLoginTab] = useState("");
    
    const handleSelect = (key)=>{

        setLoginTab(key)
    }


  return (
    <div className="auth-page-wrap">
       <Helmet>
                <title>Login - Cultural Engagement  </title>
            </Helmet>
      <div className="auth-page-box d-flex">
        <div className="auth-right d-flex justify-content-center align-items-center">
          <div className="auth-cont-box">
            <div className="auth-cont-box-header text-center">
              {/* <div className="auth-logo">
                <img src={Logo} alt="" />
              </div> */}
              <div className="auth-logo">
                {localStorage.getItem("MainLogo") ? (
                  <img src={localStorage.getItem("MainLogo")} alt="" />
                ) : (
                  <img src={Logo} alt="" />
                )}
              </div>
             
              <h2 className="auth-title">Welcome</h2>
            
                <p className="auth-desc">
                {loginTab == "Password"
                  ? (<>Please enter your email and password.<br/>If you haven't set a password yet, please login with OTP.</>)
                  : " Enter your email address and click on continue"}
                
              </p>
              
              
            
            </div>
            <div className="white-box radius-box tab-box">
              <Tabs
                onSelect={handleSelect}
                defaultActiveKey="Otp"
                id="uncontrolled-tab-example"
                className="login-tab"
              >
                <Tab
                  eventKey="Otp"
                  title="Login Using OTP"
                  // onClick={() => setLoginTab("password")}
                >
                  <OtpLogin signUpEmail={location?.state?.signUpEmail}/>
                </Tab>
                <Tab
                disabled={(location?.state?.signUpEmail) ? true :false}
                  eventKey="Password"
                  title="Login Using Password"
                  // onClick={() => setLoginTab("passwordless")}
                >
                  {/* <NormalLogin /> */}
                  <FirstTimeLogin/>
                
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginTab;
