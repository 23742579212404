import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { tableData } from './dummyTableData';
import FilterImg from '~/assets/images/icons/filter.svg';
import { Link } from 'react-router-dom';
const LearningPathListing = (props) => {
   const [data, setData] = useState(tableData);
   const tableColumns = [
      {
         name: 'Learning Path Name',
         selector: 'id',
         sortable: true,
         center: false,
         cell: row => <a href="#">Onboarding</a>,
         grow: 4
      },
      {
         name: 'Progress',
         selector: 'Progress',
         sortable: true,
         center: false,
         grow: 3,
         cell: row =>
            <div className="d-flex w-100 align-items-center">
               <div className="learning-progress progress">
                  <div className="progress-bar" style={{ width: '70%' }}>&nbsp;</div>
               </div>
               <span className="progress-text">1/4</span>
            </div>
         ,
      },
      {
         name: 'Group name',
         selector: 'Group_name',
         sortable: true,
         center: false,
         cell: row => <span>Lannisters</span>,
         grow: 1,
      },
      {
         name: 'Courses',
         selector: 'Courses',
         sortable: true,
         center: false,
         cell: row => <span>16</span>,
         grow: 1,
      },
      {
         name: 'Quiz',
         selector: 'Quiz',
         sortable: true,
         center: false,
         cell: row => <span>2</span>,
         grow: 1,
      },
      {
         name: 'Action',
         selector: 'Action',
         sortable: true,
         center: false,
         grow: 1,
         cell: row =>
            <div data-tag="allowRowEvents">
               <Link to="/learningpathdetails" className="btn border-btn link-btn">
                  Complete</Link>
            </div>
         ,
      },
   ];
   return (
      <div className="datatble-wrap">
         <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
            <div className="dt-filter-left d-flex justify-content-between">
               <select className="form-control filter-select">
                  <option>In progress</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
               </select>
               <button type="button" className="iconbutton"><img src={FilterImg} /></button>
            </div>
            <div className="dt-filter-right">
               <button className="iconbutton search-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                  </svg>
               </button>
            </div>
         </div>
         <DataTable
            data={data}
            columns={tableColumns}
            center={true}
            pagination
            paginationIconLastPage={<i className="fa fa-toggle-right"></i>}
            paginationIconFirstPage={<i className="fa fa-toggle-left"></i>}
            paginationIconNext={<i className="fa fa-caret-right"></i>}
            paginationIconPrevious={<i className="fa fa-caret-left"></i>}
            noHeader={true}
         />
      </div>
   );
};
export default LearningPathListing;