import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditor from '../ckeditor';
import {FormControl} from "react-bootstrap";

function Template6(props) {
    const {name,values,errors,index,setFieldValue,moduleErrors,moduleTouched,handleBlur} = props
    return (
        <div className="templates-wrap">
            <Row>
                <Col md={12}>
                    <div className="video-only-temp">
                        <div className="form-input-wrap">
                            <Form.Label className="w-100">Embedded Video URL (Youtube and Vimeo links and supported)</Form.Label>
                            <div className="d-flex video-embed-wrap">
                                <Form.Control type="text" placeholder="Add Video URL"
                                              name={`modules.${index}.video`}
                                              defaultValue={values.modules[index].video}
                                              isInvalid={moduleErrors?.video && moduleTouched?.video }
                                              onChange={e=>setFieldValue(name,e.target.value)}
                                              onBlur={handleBlur}
                                />

                            </div>
                            {moduleErrors?.video && moduleTouched?.video &&
                                    <span className='text-danger mt-1'> {moduleErrors?.video}</span>
                            }
                         <span></span>
                        </div>
                        <CkEditor  index={index} moduleErrors={moduleErrors} moduleTouched={moduleTouched} name={`modules.${index}.contents`} setFieldValue={setFieldValue} values={values} index={index}/>
                    </div>

                </Col>

            </Row>

        </div>
    )
};
export default Template6;
