import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import ModuleQuizAdded2 from "./modulequizadded2";
function CourseDuration(props) {
    const {setFieldValue,values,handleChange,errors,touched,handleBlur} = props

    return (
        <div className="duration-wrap">
                <Row>
                    <Col xs={12}>
                        <Form.Label>Course Duration</Form.Label>
                    </Col>
                    <Col xs={4} md={6}>
                        <div className="form-input-wrap">
                            <div className='d-flex'>
                            <Form.Control type="number" placeholder="Course Duration"
                            value = {values?.duration}
                                          name='duration'
                                          onBlur={handleBlur}
                             onChange={handleChange} isInvalid={ !!errors?.duration &&
                                !!touched?.duration
                                ? " is-invalid"
                                : ""}
                             style={{maxWidth:'180px'}}
                            />
                                <span className='ml-2 mt-2 mr-2'><b>Minutes</b></span>
                                <Form.Control.Feedback type="invalid" className='mt-2'>
                                {errors?.duration}
                            </Form.Control.Feedback>
                            </div>
                            
                        </div>
                    </Col>
                    {/*<Col xs={2} md={2}>*/}
                    {/*    <Form.Control as="select" name='timeScale' disabled>*/}

                    {/*        <option value='hours'>Hrs</option>*/}

                    {/*    </Form.Control>*/}
                    {/*</Col>*/}
                    {/*<Col xs={2} md={2}>*/}
                    {/*    <Form.Control as="select" name='timeScale'*/}
                    {/*                isInvalid={!!errors.timeScale && touched?.timeScale}  value = {values?.timeScale}*/}
                    {/*                  onChange={e=>setFieldValue('timeScale',e.target.value)}*/}
                    {/*                  onBlur={handleBlur}>*/}
                    {/*        <option value=''>Choose Time</option>*/}
                    {/*        <option value='hours'>Hrs</option>*/}
                    {/*        <option value='days'>Days</option>*/}
                    {/*        <option value='months'>Months</option>*/}
                    {/*        <option value='years'>Years</option>*/}
                    {/*    </Form.Control>*/}
                    {/*</Col>*/}
                </Row>



        </div>
    )
};
export default CourseDuration;
