import { createAction, handleActions } from "redux-actions";
import ActionTypes from "~shared/constants/actionTypes";
import {
  _createNewPassword,
  _setPassword,
  _forgotPassword,
  _getSubscriptionPlans,
  _signIn,
  _signInWithGoogle,
  _signUp,
  _completeRegistration,
  _setupDomain,
  _selectPlan,
  _applyCoupon,
  _setSubscriptionPlan,
  _clearState,
  _verifySubDomain,
  _logOut,
  _getInstitutionType,
  _signInWithWorkOs,
  _signInWithEdLink,
  _syncWithWorkOs,
  _checkSubscriptionStatus,
  _subdomainExits,
  _subdomainSignIn,
  _forgotSubDomain,
  _upgradePlan,
  _unsyncWithWorkOs,
  _createOrgWithWorkOs,
  _signInWithMagicLinkWithWorkOs,
  _verifyWorkOsProfile,
  _verifyEdLinkProfile,
  _getStipePaymentIntent,
  _verifyStipePaymentIntent,
} from "~shared/httpService/api";
import { ToastContainer, toast } from "react-toastify";
import AppReducer from "../../../redux/reducers";
import { store } from "../../../redux/store";
import { _passwordlessSignIn, _passwordlessWithOtpSignIn } from "../../../shared/httpService/api";
import { callback } from "chart.js/helpers";

const initialState = {
  user: {},
  userDetails: { loading: false, response: {}, hasError: false, error: {} },
  subscriptionPlans: [],
  plSendToken: false,
  plSendTokenError:false,
  domain: {},
  loading: false,
  response: {},
  hasError: false,
  error: {},
  newPasswordCreated: false,
  isForgotEmailSent: false,
  completeRegistered: false,
  isSuccessFull: false,
  IsSignUpEmailSent: false,
  isPlanSelected: false,
  googleErrors: {},
  signUpError: false,
  planSubscribed: false,
  userRegistered: { loading: false, response: {}, hasError: false, error: {} },
  verify_subdomain: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  applied_coupon: { loading: false, response: {}, hasError: false, error: {} },
  selected_plan: { loading: false, response: {}, hasError: false, error: {} },
  institution_type: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  signin_workos: { loading: false, response: {}, hasError: false, error: {} },
  signin_edlink: { loading: false, response: {}, hasError: false, error: {} },
  passwordless_signin: { loading: false, response: {}, hasError: false, error: {} },

  sync_workos: { loading: false, response: {}, hasError: false, error: {} },
  subscription_status: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  subdomain: { loading: false, response: {}, hasError: false, error: {} },
  forgot_subdomain: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  subdomain_validity: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  create_org_workos: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  unsync_workos: { loading: false, response: {}, hasError: false, error: {} },
  signin_magic_workos: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  stripe_payment_intent: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  verfiy_stripe_payment_intent: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
};
export const resetStore = () => {
  return {
    type: "USER_LOGOUT",
  };
};

export const signUpStart = createAction(ActionTypes.SIGNUP);
export const signUpSuccess = createAction(
  ActionTypes.SIGNUP_SUCCESS,
  (response) => response
);
export const signUpError = createAction(
  ActionTypes.SIGNUP_ERROR,
  (error) => error
);

export const signUp = (formData, callback) => (dispatch) => {
  dispatch(signUpStart());
  return _signUp(formData)
    .then((response) => {
      if (response) {
        callback(response);
      }
      dispatch(signUpSuccess(response));
    })
    .catch((error) => {
      dispatch(signUpError(error));
      if (error?.error?.errorMessage) {
        toast.error(error?.error?.errorMessage);
      } else if(error?.email) {
        toast.error(error.email[0]); 
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const stripePaymentIntentStart = createAction(
  ActionTypes.STRIPE_PAYMENT_INTENT
);
export const stripePaymentIntentSuccess = createAction(
  ActionTypes.STRIPE_PAYMENT_INTENT_SUCCESS,
  (response) => response
);
export const stripePaymentIntentError = createAction(
  ActionTypes.STRIPE_PAYMENT_INTENT_ERROR,
  (error) => error
);

export const getStipePaymentIntent = (formData, callback) => (dispatch) => {
  dispatch(stripePaymentIntentStart());
  return _getStipePaymentIntent(formData)
    .then((response) => {
      if (response) {
        callback(response);
      }
      dispatch(stripePaymentIntentSuccess(response));
    })
    .catch((error) => {
      dispatch(stripePaymentIntentError(error));
      if (error?.error?.errorMessage) {
        toast.error(error?.error?.errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const verifyStripePaymentIntentStart = createAction(
  ActionTypes.STRIPE_PAYMENT_INTENT_VERIFY
);
export const verifyStripePaymentIntentSuccess = createAction(
  ActionTypes.STRIPE_PAYMENT_INTENT_VERIFY_SUCCESS,
  (response) => response
);
export const verifyStripePaymentIntentError = createAction(
  ActionTypes.STRIPE_PAYMENT_INTENT_VERIFY_ERROR,
  (error) => error
);

export const verifyStipePaymentIntent = (body, callback) => (dispatch) => {
  dispatch(verifyStripePaymentIntentStart());
  return _verifyStipePaymentIntent(body)
    .then((response) => {
      dispatch(verifyStripePaymentIntentSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(verifyStripePaymentIntentError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const registerStart = createAction(ActionTypes.REGISTER);
export const getRegisterSuccess = createAction(
  ActionTypes.REGISTER_SUCCESS,
  (response) => response
);
export const getRegisterError = createAction(
  ActionTypes.REGISTER_ERROR,
  (error) => error
);

export const register = (formData, id, history, callback) => (dispatch) => {
  dispatch(registerStart());

  // eslint-disable-next-line no-undef
  return _completeRegistration(formData, id)
    .then((response) => {
   
      if (
        (response &&
          response?.data?.user_id &&
          response?.data?.user_type == "manager") ||
        store.getState()?.auth?.user?.user_type == "manager"
      ) {
        setTimeout(() => {
          toast.success("Registered successfully");
        }, 100);
        callback(response);
        // history.push('/subscriptiononsignup');
      } else if (
        response &&
        response?.data?.user_id &&
        response?.data?.user_type == "learner"
      ) {
        setTimeout(() => {
          toast.success("Registered successfully");
        }, 100);
        history.push("/survey_list");
      } else {
        setTimeout(() => {
          toast.success("Registered successfully");
        }, 100);
        history.push("/surveymanagement");
      }
      dispatch(getRegisterSuccess(response));
    })
    .catch((error) => {
      dispatch(getRegisterError(error));
      if (error?.error?.errorMessage) {
        toast.error(error?.error?.errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

//verify subdomain
export const domainVerifyStart = createAction(ActionTypes.VERIFY_SUBDOMAIN);
export const domainVerifySuccess = createAction(
  ActionTypes.VERIFY_SUBDOMAIN_SUCCESS,
  (response) => response
);
export const domainVerifyError = createAction(
  ActionTypes.VERIFY_SUBDOMAIN_ERROR,
  (error) => error
);

export const verifySubDomain =
  (host, callback, callbackError) => (dispatch) => {
    dispatch(domainVerifyStart());
    return _verifySubDomain(host)
      .then((response) => {
        dispatch(domainVerifySuccess(response));
        callback(response);
      })
      .catch((error) => {
        dispatch(domainVerifyError(error));
        callbackError(error);
      });
  };

export const logOutStart = createAction(ActionTypes.LOG_OUT);
export const logOutSuccess = createAction(
  ActionTypes.LOG_OUT_SUCCESS,
  (response) => response
);
export const logOutError = createAction(
  ActionTypes.LOG_OUT_ERROR,
  (error) => error
);

export const logOut = () => (dispatch) => {
  dispatch(logOutStart());
  // eslint-disable-next-line no-undef
  return _logOut()
    .then((response) => {
      dispatch(logOutSuccess(response));
      if (response) {
        localStorage.clear();
      }
    })
    .catch((error) => {
      dispatch(logOutError(error));
      toast.error("Something went wrong");
    });
};

// signIn with google
export const signInWithGoogleStart = createAction(ActionTypes.SIGNIN_GOOGLE);
export const signInWithGoogleSuccess = createAction(
  ActionTypes.SIGNIN_GOOGLE_SUCCESS,
  (response) => response
);
export const signInWithGoogleError = createAction(
  ActionTypes.SIGNIN_GOOGLE_ERROR,
  (error) => error
);

export const signInWithGoogle = (formData, history) => (dispatch) => {
  dispatch(signInWithGoogleStart());
  const { accessToken, profileObj } = formData;
  return _signInWithGoogle({ access_token: accessToken, ...profileObj })
    .then((response) => {
      let userObj = response.data;
      localStorage.setItem("token", userObj.token);

      if (response && response?.data?.is_profile_completed == false) {
        setTimeout(() => {
          toast.success("Logged in successfully");
        }, 100);
        history.push("/complete-profile");
      } else if (response && response?.data?.is_profile_completed == true) {
        setTimeout(() => {
          toast.success("Logged in successfully");
        }, 100);
        history.push("/surveymanagement");
      }
      // if (response && response?.data?.is_profile_completed == false) {
      //     history.push('/complete-profile');
      // } else if (response && response?.data?.is_profile_completed == true && response?.data?.is_subscribed == false) {
      //     history.push('/subscriptiononsignup');
      // } else if (response && response?.data?.is_profile_completed == true && response?.data?.is_subscribed == true && response?.data?.user_type == 'manager') {
      //     toast.success('Logged in successfully')
      //     history.push('/surveymanagement');
      // }else if(response && response?.data?.is_profile_completed == true && response?.data?.is_subscribed == true && response?.data?.user_type == 'learner'){
      //     toast.success('Logged in successfully')
      //     history.push('/survey_list');
      // }else if(response && response?.data?.is_profile_completed == true && response?.data?.is_subscribed == true && response?.data?.user_type == 'admin'){
      //     toast.success('Logged in successfully')
      //     history.push('/surveymanagement');
      // }
      dispatch(signInWithGoogleSuccess(response));
    })
    .catch((error) => {
      dispatch(signInWithGoogleError(error));
      if (error?.error?.errorMessage) {
        toast.error(error?.error?.errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};


///////////////setPassword//////////////

export const setPasswordStart = createAction(
  ActionTypes.SET_PASSWORD
);
export const setPasswordSuccess = createAction(
  ActionTypes.SET_PASSWORD_SUCCESS,
  (response) => response
);
export const setPasswordError = createAction(
  ActionTypes.SET_PASSWORD_ERROR,
  (error) => error
);

export const setPassword =
  (formData, history, callback, ) => (dispatch) => {
    dispatch(setPasswordStart());
    return _setPassword(formData)
      .then((response) => {
        
        if (response && response?.data) {
          window?.location?.replace('/login')
    // history.push('/login')

          callback(response);
          setTimeout(() => {
            toast.success("Password set successfully");
          }, 100);

        }
        dispatch(setPasswordSuccess(response));
      })
      .catch((error) => {
        dispatch(setPasswordError(error));
        callBackError(error);
        if (error?.error?.errorMessage) {
          toast.error(error?.error?.errorMessage);
        } else if (
          error &&
          Object?.keys(error) &&
          Object?.keys(error)?.length
        ) {
          toast.error(`${error[Object?.keys(error)[0]]}`);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

//////////////////////////////////////

// create New Password
export const createNewPasswordStart = createAction(
  ActionTypes.CREATE_NEW_PASSWORD
);
export const createNewPasswordSuccess = createAction(
  ActionTypes.CREATE_NEW_PASSWORD_SUCCESS,
  (response) => response
);
export const createNewPasswordError = createAction(
  ActionTypes.CREATE_NEW_PASSWORD_ERROR,
  (error) => error
);

export const createNewPassword =
  (formData, newHistory, callback, callBackError) => (dispatch) => {
    dispatch(createNewPasswordStart());
    return _createNewPassword(formData)
      .then((response) => {
        if (response && response.data.user_id) {
          callback(response);
          setTimeout(() => {
            toast.success("Password created successfully");
          }, 100);

          // newHistory.push('/login');
        }
        dispatch(createNewPasswordSuccess(response));
      })
      .catch((error) => {
        dispatch(createNewPasswordError(error));
        callBackError(error);
        if (error?.error?.errorMessage) {
          toast.error(error?.error?.errorMessage);
        } else if (
          error &&
          Object?.keys(error) &&
          Object?.keys(error)?.length
        ) {
          toast.error(`${error[Object?.keys(error)[0]]}`);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

// SignIn
export const signInStart = createAction(ActionTypes.SIGNIN);
export const signInSuccess = createAction(
  ActionTypes.SIGNIN_SUCCESS,
  (response) => response
);
export const signInError = createAction(
  ActionTypes.SIGNIN_ERROR,
  (error) => error
);

export const signIn = (formData, history, code, state) => (dispatch) => {

  dispatch(signInStart());
  return _signIn(formData, code, state)
    .then((response) => {
      toast.success("Logged in successfully");

      localStorage.setItem("token", response?.data?.token);
      var now = new Date().getTime();
      sessionStorage.setItem("login_time", `${now}`);
      // response.data.user_type = 'admin'
      // response.data.is_subscribed = false
      // response.data.is_admin_institution = true
     
      // if (
      //   (response &&
      //     response?.data?.is_admin_institution == false &&
      //     response?.data?.user_type == "manager") ||
      //   response?.data?.user_type == "admin" ||
      //   response?.data?.user_type == "staff_manager"
      // ) {
      //   if (
      //     response &&
      //     response?.data?.is_profile_completed == false &&
      //     response?.data?.user_type == "manager"
      //   ) {
      //     setTimeout(() => {
      //       toast.success("Logged in successfully");
      //     }, 100);
      //     history.push("/complete-profile");
      //   } else if (
      //     response?.data?.is_profile_completed == true &&
      //     response?.data?.is_subscribed == false
      //   ) {
      //     setTimeout(() => {
      //       toast.success("Logged in successfully");
      //     }, 100);
      

      //     history.push("/subscriptiononsignup");
      //     // parts.includes('www')
      //   } else if (
      //     response &&
      //     response?.data?.is_profile_completed == true &&
      //     response?.data?.is_subscribed == true &&
      //     response?.data?.is_domain_created == false
      //   ) {
      //     setTimeout(() => {
      //       toast.success("Logged in successfully");
      //     }, 100);
      //     history.push("/subdomainsignup");
      //   } else if (
      //     response &&
      //     response?.data?.is_profile_completed == true &&
      //     response?.data?.is_subscribed == true &&
      //     response?.data?.is_domain_created == true
      //   ) {
      //     setTimeout(() => {
      //       toast.success("Logged in successfully");
      //     }, 100);
      //     history.push("/my-assignments");
      //   }
      // } else if (
      //   response &&
      //   response?.data?.is_admin_institution == false &&
      //   response?.data?.user_type == "learner"
      // ) {
      //   if (response.data.is_subscribed) {
      //     history.push("/my-assignments");
      //   } else {
      //     let action = "USER_LOGOUT";
      //     store?.dispatch(resetStore(action));
      //     toast.error("Subscriptions has expired!");
      //     return false;
      //   }
      // } else if (response && response?.data?.is_admin_institution == true) {
        
      //   history.push("/my-assignments");
      // } else {
      //   history.push("/login");
      // }

      //
      // if(response && response?.data?.user_type == 'manager'){
      //     if (response && response?.data?.is_profile_completed == false) {
      //         toast.success('Logged in successfully')
      //         history.push('/complete-profile');
      //     } else if (response && response?.data?.is_profile_completed == true && response?.data?.is_subscribed == false) {
      //         toast.success('Logged in successfully')
      //         history.push('/subscriptiononsignup');
      //     } else if (response && response?.data?.is_profile_completed == true && response?.data?.is_subscribed == true && response?.data?.user_type == 'manager') {
      //         toast.success('Logged in successfully')
      //         history.push('/surveymanagement');
      //     }else if(response && response?.data?.is_profile_completed == true && response?.data?.is_subscribed == true && response?.data?.user_type == 'admin'){
      //         toast.success('Logged in successfully')
      //         history.push('/surveymanagement');
      //     }
      // }else{
      //     history.push('/survey_list');
      // }
      // response.data.user_type = 'learner'

      dispatch(signInSuccess(response));
    })
    .catch((error) => {
      dispatch(signInError(error));
      if (error?.error?.errorMessage) {
        toast.error(error?.error?.errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

//passwordLessSignIn


export const passwordLessSignInStart = createAction(ActionTypes.PASSWORDLESS_SIGNIN);
export const passwordLessSignInSuccess = createAction(
  ActionTypes.PASSWORDLESS_SIGNIN_SUCCESS,
  (response) => response
);
export const passwordLessSignInError = createAction(
  ActionTypes.PASSWORDLESS_SIGNIN_ERROR,
  (error) => error
);

export const passwordLessSignIn = (formData, history, callback) => (dispatch) => {

  dispatch(passwordLessSignInStart());
  return _passwordlessSignIn(formData)
    .then((response) => {
      history.push('/enterotp', formData)
      callback()
      dispatch(passwordLessSignInSuccess(response));
    })
    .catch((error) => {
      dispatch(passwordLessSignInError(error));
      if (error?.error?.errorMessage) {
        toast.error(error?.error?.errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

//passwordLessWithOTP

export const passwordLessWithOTPStart = createAction(ActionTypes.PASSWORDLESS_WITH_OTP_START);
export const passwordLessWithOTPSuccess = createAction(
  ActionTypes.PASSWORDLESS_WITH_OTP_SUCCESS,
  (response) => response
);
export const passwordLessWithOTPError = createAction(
  ActionTypes.PASSWORDLESS_WITH_OTP_ERROR,
  (error) => error
);

export const passwordLessWithOTP = (formData, history) => (dispatch) => {

  dispatch(passwordLessWithOTPStart());
  return _passwordlessWithOtpSignIn(formData, history)
    .then((response) => {
      toast.success("Logged in successfully");
      localStorage.setItem("token", response?.data?.token);
      var now = new Date().getTime();
      sessionStorage.setItem("login_time", `${now}`);
      
      // if (
      //   (response &&
      //     response?.data?.is_admin_institution == false &&
      //     response?.data?.user_type == "manager") ||
      //   response?.data?.user_type == "admin" ||
      //   response?.data?.user_type == "staff_manager"
      // ) {
      //   if (
      //     response &&
      //     response?.data?.is_profile_completed == false &&
      //     response?.data?.user_type == "manager"
      //   ) {
      //     setTimeout(() => {
      //       toast.success("Logged in successfully");
      //     }, 100);
      //     history.push("/complete-profile");
      //   } else if (
      //     response?.data?.is_profile_completed == true &&
      //     response?.data?.is_subscribed == false
      //   ) {
      //     setTimeout(() => {
      //       toast.success("Logged in successfully");
      //     }, 100);
      

      //     history.push("/subscriptiononsignup");
      //     // parts.includes('www')
      //   } else if (
      //     response &&
      //     response?.data?.is_profile_completed == true &&
      //     response?.data?.is_subscribed == true &&
      //     response?.data?.is_domain_created == false
      //   ) {
      //     setTimeout(() => {
      //       toast.success("Logged in successfully");
      //     }, 100);
      //     history.push("/subdomainsignup");
      //   } else if (
      //     response &&
      //     response?.data?.is_profile_completed == true &&
      //     response?.data?.is_subscribed == true &&
      //     response?.data?.is_domain_created == true
      //   ) {
      //     setTimeout(() => {
      //       toast.success("Logged in successfully");
      //     }, 100);
        
          
      //     history.push("/my-assignments");
          
      //   }
      // } else if (
      //   response &&
      //   response?.data?.is_admin_institution == false &&
      //   response?.data?.user_type == "learner"
      // ) {
      //   if (response?.data?.is_subscribed) {
      //     history.push("/my-assignments");
      //   } else {
      //     let action = "USER_LOGOUT";
      //     store?.dispatch(resetStore(action));
      //     toast.error("Subscriptions has expired!");
      //     return false;
      //   }
      // } else if (response && response?.data?.is_admin_institution == true) {
        
      //   history.push("/my-assignments");
      // } else {
      //   history.push("/login");
      // }

 
        dispatch(passwordLessWithOTPSuccess(response));

    })
    .catch((error) => {
      dispatch(passwordLessWithOTPError(error));
      if (error?.error?.errorMessage) {
        toast.error(error?.error?.errorMessage);
      } else if(error?.token.length > 0){
        toast.error(error?.token[0]);
      } else {
        toast.error("Something went wrong");
      }
    });
};

// forgot Password
export const forgotPasswordStart = createAction(ActionTypes.FORGOT_PASSWORD);
export const forgotPasswordSuccess = createAction(
  ActionTypes.FORGOT_PASSWORD_SUCCESS,
  (response) => response
);
export const forgotPasswordError = createAction(
  ActionTypes.FORGOT_PASSWORD_ERROR,
  (error) => error
);

export const forgotPassword = (formData) => (dispatch) => {
  dispatch(forgotPasswordStart());
  return _forgotPassword(formData)
    .then((response) => {
      dispatch(forgotPasswordSuccess(response));
    })
    .catch((error) => {
      dispatch(forgotPasswordError(error));
      if (error.email) {
        toast.error("Email Does not Exist");
        return;
      } else {
        toast.error("Something went wrong");
      }
    });
};

// forgot subdomain
export const forgotSubDomainStart = createAction(ActionTypes.FORGOT_SUBDOMAIN);
export const forgotSubDomainSuccess = createAction(
  ActionTypes.FORGOT_SUBDOMAIN_SUCCESS,
  (response) => response
);
export const forgotSubDomainError = createAction(
  ActionTypes.FORGOT_SUBDOMAIN_ERROR,
  (error) => error
);

export const forgotSubDomain = (formData, callBack) => (dispatch) => {
  dispatch(forgotSubDomainStart());
  return _forgotSubDomain(formData)
    .then((response) => {
      if (response) {
        callBack(response);
      }
      dispatch(forgotSubDomainSuccess(response));
    })
    .catch((error) => {
      dispatch(forgotSubDomainError(error));
      if (error) {
        if (error?.error?.errorMessage) {
          toast.error(error?.error?.errorMessage);
        } else {
          toast.error("Something went wrong");
        }
      }
    });
};

//subdomain signin
export const subdomainSignStart = createAction(ActionTypes.SUBDOMAIN_LOGIN);
export const subdomainSignSuccess = createAction(
  ActionTypes.SUBDOMAIN_LOGIN_SUCCESS,
  (response) => response
);
export const subdomainSignError = createAction(
  ActionTypes.SUBDOMAIN_LOGIN_ERROR,
  (error) => error
);

export const subdomainSignIn =
  (formData, callBack, callbackError) => (dispatch) => {
    
    dispatch(subdomainSignStart());
    return _subdomainSignIn(formData)
      .then((response) => {
        if (response) {
          callBack(response);
        }
        dispatch(subdomainSignSuccess(response));
      })
      .catch((error) => {
        dispatch(subdomainSignError(error));
        callbackError(error);
        if (error?.error?.errorMessage) {
          toast.error(error?.error?.errorMessage);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

//subdomain existence
export const subdomainExistStart = createAction(ActionTypes.SUBDOMAIN_EXISTS);
export const subdomainExistSuccess = createAction(
  ActionTypes.SUBDOMAIN_EXISTS_SUCCESS,
  (response) => response
);
export const subdomainExistError = createAction(
  ActionTypes.SUBDOMAIN_EXISTS_ERROR,
  (error) => error
);

export const subdomainExits = (formData, callBack) => (dispatch) => {
  dispatch(subdomainExistStart());
  return _subdomainExits(formData)
    .then((response) => {
      if (response) {
        callBack(response);
      }
      dispatch(subdomainExistSuccess(response));
    })
    .catch((error) => {
      dispatch(subdomainExistError(error));
      if (error.email) {
        toast.error("Email Does not Exist");
        return;
      } else {
        toast.error("Something went wrong");
      }
    });
};

//subScriptions
export const getSubscriptionPlansStart = createAction(
  ActionTypes.GET_SUBSCRIPTION_PLANS
);
export const getSubscriptionPlansSuccess = createAction(
  ActionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS,
  (response) => response
);
export const getSubscriptionPlansError = createAction(
  ActionTypes.GET_SUBSCRIPTION_PLANS_ERROR,
  (error) => error
);

export const getSubscriptionPlans = () => (dispatch) => {
  dispatch(getSubscriptionPlansStart());
  return _getSubscriptionPlans()
    .then((response) => {
      dispatch(getSubscriptionPlansSuccess(response));
    })
    .catch((error) => {
      dispatch(getSubscriptionPlansError(error));
      toast.error("Something went wrong");
    });
};

//institution type

export const getInstitutionTypeStart = createAction(
  ActionTypes.INSTITUTION_CATEGORY
);
export const getInstitutionTypeSuccess = createAction(
  ActionTypes.INSTITUTION_CATEGORY_SUCCESS,
  (response) => response
);
export const getInstitutionTypeError = createAction(
  ActionTypes.INSTITUTION_CATEGORY_ERROR,
  (error) => error
);

export const getInstitutionType = (callback) => (dispatch) => {
  dispatch(getInstitutionTypeStart());
  return _getInstitutionType()
    .then((response) => {
      dispatch(getInstitutionTypeSuccess(response));
      callback(response);
    })
    .catch((error) => {
      dispatch(getInstitutionTypeError(error));
      toast.error("Something went wrong");
    });
};

//CLEAR STATE
export const clearStateStart = createAction(ActionTypes.CLEAR_ERROR);
export const clearStateSuccess = createAction(
  ActionTypes.CLEAR_ERROR_SUCCESS,
  (response) => response
);
export const clearStateError = createAction(
  ActionTypes.CLEAR_ERROR_ERROR,
  (error) => error
);

export const clearState = (formData) => (dispatch) => {
  dispatch(clearStateStart());
  return _clearState(formData)
    .then((response) => {
      dispatch(clearStateSuccess(response));
    })
    .catch((error) => {
      dispatch(clearStateError(error));
    });
};

//setUp Domains

export const setupDomainStart = createAction(ActionTypes.SETUP_DOMAIN);
export const setupDomainSuccess = createAction(
  ActionTypes.SETUP_DOMAIN_SUCCESS,
  (response) => response
);
export const setupDomainError = createAction(
  ActionTypes.SETUP_DOMAIN_ERROR,
  (error) => error
);

export const setUpDomain =
  (formData, history, callback, callbackerror) => (dispatch) => {
    dispatch(setupDomainStart());
    return _setupDomain(formData, history)
      .then((response) => {
        if (response) {
          callback(response);
          setTimeout(() => {
            toast.success("Subdomain registered successfully");
          }, 100);
        }
        dispatch(setupDomainSuccess(response));
      })
      .catch((error) => {
        dispatch(setupDomainError(error));
        callbackerror(error);
    
        if (error && error?.error?.errorMessage) {
          toast.error(error?.error?.errorMessage);
        } else if (
          error &&
          Object?.keys(error) &&
          Object?.keys(error)?.length
        ) {
          toast.error(`${error[Object?.keys(error)[0]]}`);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

//select subScriptions
export const setSubscriptionPlanStart = createAction(
  ActionTypes.POST_SUBSCRIPTION_PLAN
);
export const setSubscriptionPlanSuccess = createAction(
  ActionTypes.POST_SUBSCRIPTION_PLAN_SUCCESS,
  (response) => response
);
export const setSubscriptionPlanError = createAction(
  ActionTypes.POST_SUBSCRIPTION_PLAN_ERROR,
  (error) => error
);

export const selectPlan =
  (formData, history, callingComp, setShowPlan) => (dispatch) => {
    dispatch(setSubscriptionPlanStart(formData));
    return _selectPlan(formData)
      .then((response) => {
        if (callingComp == "pre_profile") {
          if (response && response?.customer) {
            history.push("/checkoutonsignup");
          }
        }
        if (callingComp == "post_profile") {
          setShowPlan(response);
        }

        dispatch(setSubscriptionPlanSuccess(response));
      })
      .catch((error) => {
        dispatch(setSubscriptionPlanError(error));
        toast.error("Something went wrong");
      });
  };
// apply coupon
export const applyCouponStart = createAction(
  ActionTypes.POST_SUBSCRIPTION_PLAN
);
export const applyCouponSuccess = createAction(
  ActionTypes.POST_SUBSCRIPTION_PLAN_SUCCESS,
  (response) => response
);
export const applyCouponError = createAction(
  ActionTypes.POST_SUBSCRIPTION_PLAN_ERROR,
  (error) => error
);

export const applyCoupon = (formData, callback) => (dispatch) => {
  dispatch(applyCouponStart(formData));
  return _applyCoupon(formData)
    .then((response) => {
      if (response) {
        toast.success("Coupon Applied");

        callback(response);
      }
      dispatch(applyCouponSuccess(response));
    })
    .catch((error) => {
      dispatch(applyCouponError(error));
      if (error?.error?.errorMessage) {
        toast.error(error?.error?.errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

// CREATE PLAN
export const subscribeSubscriptionPlanStart = createAction(
  ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN
);
export const subscribeSubscriptionPlanSuccess = createAction(
  ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN_SUCCESS,
  (response) => response
);
export const subscribeSubscriptionPlanError = createAction(
  ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN_ERROR,
  (error) => error
);

export const createSubscriptionPlan =
  (id, callback, callbackerror) => (dispatch) => {
    dispatch(subscribeSubscriptionPlanStart());
    return _checkSubscriptionStatus(id)
      .then((response) => {
        if (response) {
          callback(response);
        }
        dispatch(subscribeSubscriptionPlanSuccess(response));
      })
      .catch((error) => {
        dispatch(subscribeSubscriptionPlanError(error));
        if (error) {
          callbackerror(error);
        }
        if (error?.plan?.error?.errorMessage) {
          toast.error(error?.plan?.error?.errorMessage);
        } else if (error?.email?.error?.errorMessage) {
          toast.error(error?.email?.error?.errorMessage);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

// CREATE SSO LInk
export const signInWorkOsStart = createAction(ActionTypes.SIGNIN_WORKOS);
export const signInWorkOsSuccess = createAction(
  ActionTypes.SIGNIN_WORKOS_SUCCESS,
  (response) => response
);
export const signInWorkOsError = createAction(
  ActionTypes.SIGNIN_WORKOS_ERROR,
  (error) => error
);

export const signInWithWorkOs = () => (dispatch) => {
  dispatch(signInWorkOsStart());
  return _signInWithWorkOs()
    .then((response) => {
      dispatch(signInWorkOsSuccess(response));
      if (response) {
        toast.success("....Redirecting Please wait");
        window.location.href = response?.url;
      }
    })
    .catch((error) => {
      dispatch(signInWorkOsError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};
// CREATE ED_LINK
export const signInEdLinkStart = createAction(ActionTypes.SIGNIN_EDLINK);
export const signInEdLinkSuccess = createAction(
  ActionTypes.SIGNIN_EDLINK_SUCCESS,
  (response) => response
);
export const signInEdLinkError = createAction(
  ActionTypes.SIGNIN_EDLINK_ERROR,
  (error) => error
);

export const signInWithEdLink = () => (dispatch) => {
  dispatch(signInEdLinkStart());
  return _signInWithEdLink()
    .then((response) => {
      dispatch(signInEdLinkSuccess(response));
      if (response) {
        toast.success("....Redirecting Please wait");
        window.location.href = response?.login_url
          ;
      }
    })
    .catch((error) => {
      dispatch(signInEdLinkError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};
// CREATE SSO Org
export const createWorkOsOrgStart = createAction(ActionTypes.SYNC_WORKOS);
export const createWorkOsOrgSuccess = createAction(
  ActionTypes.SYNC_WORKOS_SUCCESS,
  (response) => response
);
export const createWorkOsOrgError = createAction(
  ActionTypes.SYNC_WORKOS_ERROR,
  (error) => error
);

export const syncWithWorkOs = (callback) => (dispatch) => {
  dispatch(createWorkOsOrgStart());
  return _syncWithWorkOs()
    .then((response) => {
      dispatch(createWorkOsOrgSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(createWorkOsOrgError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

// signin with magiclink LInk
export const signInWithMagicLinkWorkOsStart = createAction(ActionTypes.SIGNIN);
export const signInWithMagicLinkWorkOsStarSuccess = createAction(
  ActionTypes.SIGNIN_SUCCESS,
  (response) => response
);
export const signInWithMagicLinkWorkOsStarError = createAction(
  ActionTypes.SIGNIN_ERROR,
  (error) => error
);


export const signInMagicLinkWithWorkOs = (values, callback) => (dispatch) => {
  dispatch(signInWithMagicLinkWorkOsStart());
  return _signInWithMagicLinkWithWorkOs(values)
    .then((response) => {
      dispatch(signInWithMagicLinkWorkOsStarSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(signInWithMagicLinkWorkOsStarError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};
// signin with magiclink LInk
export const verifyProfileWorkOsStart = createAction(ActionTypes.SIGNIN);
export const verifyProfileWorkOsStarSuccess = createAction(
  ActionTypes.SIGNIN_SUCCESS,
  (response) => response
);
export const verifyProfileWorkOsStarError = createAction(
  ActionTypes.SIGNIN_ERROR,
  (error) => error
);

export const verifyWorkOsProfile = (code, state, callback) => (dispatch) => {
  dispatch(signInWithMagicLinkWorkOsStart());
  return _verifyWorkOsProfile(code, state)
    .then((response) => {
      dispatch(verifyProfileWorkOsStarSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(verifyProfileWorkOsStarError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

// signin with EdLInk
export const verifyProfileEdLinkStart = createAction(ActionTypes.SIGNIN_ADLINK);
export const verifyProfileEdLinkStarSuccess = createAction(
  ActionTypes.SIGNIN_ADLINK_SUCCESS,
  (response) => response
);
export const verifyProfileEdLinkStarError = createAction(
  ActionTypes.SIGNIN_ADLINK_ERROR,
  (error) => error
);

export const verifyEdLinkProfile = (code, state, history) => (dispatch) => {
  dispatch(verifyProfileEdLinkStart());
  return _verifyEdLinkProfile(code, state)
    .then((response) => {
      dispatch(verifyProfileEdLinkStarSuccess(response));
      if (response) {
        localStorage.setItem("token", response?.data?.data?.token);
        var now = new Date().getTime();
        sessionStorage.setItem("login_time", `${now}`);
        if (
          (response &&
            response?.data?.data?.is_admin_institution == false &&
            response?.data?.data?.user_type == "manager") ||
          response?.data?.data?.user_type == "admin" ||
          response?.data?.data?.user_type == "staff_manager"
        ) {
          if (
            response &&
            response?.data?.data?.is_profile_completed == false &&
            response?.data?.data?.user_type == "manager"
          ) {
            setTimeout(() => {
              toast.success("Logged in successfully");
            }, 100);
            history.push("/complete-profile");
          } else if (
            response?.data?.data?.is_profile_completed == true &&
            response?.data?.data?.is_subscribed == false
          ) {
            setTimeout(() => {
              toast.success("Logged in successfully");
            }, 100);

            history.push("/subscriptiononsignup");
      
          } else if (
            response &&
            response?.data?.data?.is_profile_completed == true &&
            response?.data?.data?.is_subscribed == true &&
            response?.data?.data?.is_domain_created == false
          ) {
            setTimeout(() => {
              toast.success("Logged in successfully");
            }, 100);
            history.push("/subdomainsignup");
          } else if (
            response &&
            response?.data?.data?.is_profile_completed == true &&
            response?.data?.data?.is_subscribed == true &&
            response?.data?.data?.is_domain_created == true
          ) {
            setTimeout(() => {
              toast.success("Logged in successfully");
            }, 100);
            history.push("/my-assignments");
          }
        } else if (
          response &&
          response?.data?.data?.is_admin_institution == false &&
          response?.data?.data?.user_type == "learner"
        ) {
          if (response?.data?.data?.is_subscribed) {
            history.push("/my-assignments");
          } else {
            let action = "USER_LOGOUT";
            store?.dispatch(resetStore(action));
            toast.error("Subscriptions has expired!");
            return false;
          }
        } else if (response && response?.data?.data?.is_admin_institution == true) {
          
          if (response?.data?.assignment_id && response?.data?.assignment_id !== null) {
            history.push(`/learningpathdetails/${response?.data?.assignment_id}`)
          } else {
            history.push("/my-assignments");
          }

        } else {
          history.push("/login");
        }

      }
      dispatch(signInSuccess(response?.data));

    })
    .catch((error) => {
      dispatch(verifyProfileEdLinkStarError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

// signin with magiclink LInk
export const unSyncWorkOsStart = createAction(ActionTypes.UNSYNC_WORKOS);
export const unSyncWorkOsSuccess = createAction(
  ActionTypes.UNSYNC_WORKOS_SUCCESS,
  (response) => response
);
export const unSyncWorkOsError = createAction(
  ActionTypes.UNSYNC_WORKOS_ERROR,
  (error) => error
);

export const unSyncWithWorkOs = (callback) => (dispatch) => {
  dispatch(unSyncWorkOsStart());
  return _unsyncWithWorkOs()
    .then((response) => {
      dispatch(unSyncWorkOsSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(unSyncWorkOsError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

// signin with magiclink LInk
export const createOrgWorkOsStart = createAction(
  ActionTypes.CREATE_ORGANIZATION_WITH_WORKOS
);
export const createOrgWorkOsSuccess = createAction(
  ActionTypes.CREATE_ORGANIZATION_WITH_SUCCESS,
  (response) => response
);
export const createOrgWorkOsError = createAction(
  ActionTypes.CREATE_ORGANIZATION_WITH_ERROR,
  (error) => error
);

export const createOrgWithWorkOs = () => (dispatch) => {
  dispatch(createOrgWorkOsStart());
  return _createOrgWithWorkOs()
    .then((response) => {
      dispatch(createOrgWorkOsSuccess(response));
      if (response) {
        window.location.href = response?.url;
      }
    })
    .catch((error) => {
      dispatch(createOrgWorkOsError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const subscriptionStatusStart = createAction(
  ActionTypes.CHECK_SUBSCRIPTION
);
export const subscriptionStatusSuccess = createAction(
  ActionTypes.CHECK_SUBSCRIPTION_SUCCESS,
  (response) => response
);
export const subscriptionStatusError = createAction(
  ActionTypes.CHECK_SUBSCRIPTION_ERROR,
  (error) => error
);

export const checkSubscriptionStatus = (id, callback) => (dispatch) => {
  dispatch(subscriptionStatusStart());
  return _checkSubscriptionStatus(id)
    .then((response) => {
      dispatch(subscriptionStatusSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(subscriptionStatusError(error));
      if (error?.error?.erroMessage) {
        toast.error(error?.error?.erroMessage);
      } else {
        toast.error("Something went wrong");
      }
    });
};
export const upgradePlanStart = createAction(
  ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN
);
export const upgradePlanSuccess = createAction(
  ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN_SUCCESS,
  (response) => response
);
export const upgradePlanError = createAction(
  ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN_ERROR,
  (error) => error
);

export const upgradePlan = (token, data, callback) => (dispatch) => {
  dispatch(upgradePlanStart());
  return _upgradePlan(token, data)
    .then((response) => {
      dispatch(upgradePlanSuccess(response));
      toast.success("Plan updated Successfully");
      callback("resolved");
    })
    .catch((error) => {
      dispatch(upgradePlanError(error));
      toast.error("Something went wrong");
    });
};
const reducer = handleActions(
  {
    //SIGNUP
    [ActionTypes.SIGNUP]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      IsSignUpEmailSent: false,
    }),
    [ActionTypes.SIGNUP_SUCCESS]: (state, action) => ({
      ...state,
      // user: { ...action.payload?.data },
      response: { ...action.payload?.data },
      loading: false,
      hasError: false,
      error: {},
      signUpError: false,
      IsSignUpEmailSent: true,
    }),
    [ActionTypes.SIGNUP_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
      signUpError: true,
      IsSignUpEmailSent: false,
    }),
    //Logout

    [ActionTypes.LOG_OUT]: (state) => ({
      ...state,

      loading: true,
      hasError: false,
      error: {},
      user: {},
    }),
    [ActionTypes.LOG_OUT_SUCCESS]: (state, action) => {
      localStorage.clear();
    },
    [ActionTypes.LOG_OUT_ERROR]: (state, action) => ({
      ...state,

      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
    }),

    //Register

    [ActionTypes.REGISTER]: (state) => ({
      ...state,
      completeRegistered: true,
      loading: true,
      hasError: false,
      error: {},
    }),
    [ActionTypes.REGISTER_SUCCESS]: (state, action) => ({
      ...state,
      user: { ...state.user, ...action?.payload?.data },
      completeRegistered: false,
      response: action.payload,
      loading: false,
      hasError: false,
      error: {},
    }),
    [ActionTypes.REGISTER_ERROR]: (state, action) => ({
      ...state,
      completeRegistered: false,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
    }),

    //SIGNIN
    [ActionTypes.SIGNIN]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      userDetails: {
        ...state.userDetails,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SIGNIN_SUCCESS]: (state, action) => ({
      ...state,
      user: action.payload?.data,
      response: action.payload,
      loading: false,
      hasError: false,
      error: {},
      userDetails: {
        ...state.userDetails,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SIGNIN_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
      signUpError: true,
      userDetails: {
        ...state.userDetails,
        loading: false,
        hasError: false,
        error: {},
      },
    }),

    //////////////////////

    [ActionTypes.SIGNIN_ADLINK]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      userDetails: {
        ...state.userDetails,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SIGNIN_ADLINK_SUCCESS]: (state, action) => ({
      ...state,
      user: action.payload?.data,
      response: action.payload,
      loading: false,
      hasError: false,
      error: {},
      userDetails: {
        ...state.userDetails,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SIGNIN_ADLINK_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
      signUpError: true,
      userDetails: {
        ...state.userDetails,
        loading: false,
        hasError: false,
        error: {},
      },
    }),


    ////////////////////_ADLINK////////////

    //////PASSWORDLESS SEND LOGIN TOKEN/////////////

    [ActionTypes.PASSWORDLESS_SIGNIN]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      plSendToken:false,
      error: {},
      plSendTokenError: false,

    }),
    [ActionTypes.PASSWORDLESS_SIGNIN_SUCCESS]: (state, action) => ({
      ...state,
      user: action.payload?.data,
      response: {},
      plSendToken:true,
      loading: false,
      hasError: false,
      error: {},
      plSendTokenError: false,

    }),
    [ActionTypes.PASSWORDLESS_SIGNIN_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
      plSendToken: false,
      plSendTokenError: true,
    }),

    /////////////////////PASSWORDLESS GET LOGIN TOKEN//////////////////////////

    [ActionTypes.PASSWORDLESS_WITH_OTP_START]: (state) => ({
      ...state,
      passwordless_signin: {
        ...state.passwordless_signin,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.PASSWORDLESS_WITH_OTP_SUCCESS]: (state, action) => ({
      ...state,
      user: { ...action.payload?.data },
      response: { ...action.payload?.data },
      passwordless_signin: {
        ...state.passwordless_signin,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.PASSWORDLESS_WITH_OTP_ERROR]: (state, action) => ({
      ...state,
      passwordless_signin: {
        ...state.passwordless_signin,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    ////////////////////////////////////////

    // SignIn with Google
    [ActionTypes.SIGNIN_GOOGLE]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      signedInwithGoogleSuccess: false,
      isSignupSuccessfull: "false",
      isSignupNotSuccessfull: false,
    }),
    [ActionTypes.SIGNIN_GOOGLE_SUCCESS]: (state, action) => ({
      ...state,
      isSignupSuccessfull: true,
      user: { ...action?.payload?.data },
      response: action.payload?.data,
      loading: false,
      hasError: false,
      error: {},
      signedInwithGoogleSuccess: true,
      isSignupNotSuccessfull: true,
    }),
    [ActionTypes.SIGNIN_GOOGLE_ERROR]: (state, action) => ({
      ...state,
      user: action.payload,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
      signedInwithGoogleSuccess: false,
      isSignupSuccessfull: false,
      isSignupNotSuccessfull: false,
    }),

    //create new password
    [ActionTypes.CREATE_NEW_PASSWORD]: (state) => ({
      ...state,
      newPasswordCreated: false,
      loading: true,
      hasError: false,
      error: {},
    }),
    [ActionTypes.CREATE_NEW_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      newPasswordCreated: true,
      loading: false,
      hasError: false,
      error: {},
      signUpError: false,
    }),
    [ActionTypes.CREATE_NEW_PASSWORD_ERROR]: (state, action) => ({
      ...state,
      newPasswordCreated: false,
      error: action.payload,
      loading: false,
      hasError: true,
      signUpError: true,
    }),

    //forgot password
    [ActionTypes.FORGOT_PASSWORD]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      isForgotEmailSent: false,
    }),
    [ActionTypes.FORGOT_PASSWORD_SUCCESS]: (state, action) => ({
      ...state,
      isForgotEmailSent: true,
      response: action.payload,
      loading: false,
      hasError: false,
      error: {},
    }),
    [ActionTypes.FORGOT_PASSWORD_ERROR]: (state, action) => ({
      ...state,
      isForgotEmailSent: false,
      error: action.payload,
      loading: false,
      hasError: true,
    }),

    //subScriptions
    [ActionTypes.GET_SUBSCRIPTION_PLANS]: (state) => ({
      ...state,
      subscriptionPlans: [],
      loading: true,
      hasError: false,
      error: {},
    }),
    [ActionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS]: (state, action) => ({
      ...state,
      subscriptionPlans: action.payload?.results,
      loading: false,
      hasError: false,
      error: {},
    }),
    [ActionTypes.GET_SUBSCRIPTION_PLANS_ERROR]: (state, action) => ({
      ...state,
      subscriptionPlans: [],
      error: action.payload,
      loading: false,
      hasError: true,
    }),

    //Setup Domain
    [ActionTypes.SETUP_DOMAIN]: (state) => ({
      ...state,
      loading: false,
      hasError: false,
      error: {},
      IsSignUpEmailSent: false,
      isSuccessFull: true,
    }),
    [ActionTypes.SETUP_DOMAIN_SUCCESS]: (state, action) => ({
      ...state,
      isSuccessFull: false,
      IsSignUpEmailSent: false,
      domain: action.payload?.data,
      response: action.payload?.data,
      loading: false,
      hasError: false,
      error: {},
    }),
    [ActionTypes.SETUP_DOMAIN_ERROR]: (state, action) => ({
      ...state,
      isSuccessFull: false,
      IsSignUpEmailSent: false,
      error: action.payload,
      loading: false,
      hasError: true,
      response: {},
    }),

    // set subScriptions
    [ActionTypes.POST_SUBSCRIPTION_PLAN]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      selected_plan: {
        ...state.selected_plan,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.POST_SUBSCRIPTION_PLAN_SUCCESS]: (state, action) => ({
      ...state,
      user: { ...state.user, ...action.payload },
      loading: false,
      hasError: false,
      error: {},
      selected_plan: {
        ...state.selected_plan,
        response: action?.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.POST_SUBSCRIPTION_PLAN_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      selected_plan: {
        ...state.selected_plan,
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    // CREATE subScriptions
    [ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      userRegistered: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN_SUCCESS]: (state, action) => ({
      ...state,
      user: { ...state.user, ...action.payload },
      userRegistered: {
        ...state.userRegistered,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },

      loading: false,
      hasError: false,
      error: {},
      planSubscribed: true,
      signUpError: false,
    }),
    [ActionTypes.SUBSCRIBE_SUBSCRIPTION_PLAN_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      signUpError: true,
      userRegistered: {
        ...state.userRegistered,
        loading: false,
        hasError: false,
        error: {},
      },
    }),

    // apply coupon
    [ActionTypes.APPLY_COUPON]: (state) => ({
      ...state,
      loading: true,
      hasError: false,
      error: {},
      applied_coupon: {
        ...state.applied_coupon,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.APPLY_COUPON_SUCCESS]: (state, action) => ({
      ...state,
      user: { ...state.user, ...action?.payload?.data },

      loading: false,
      hasError: false,
      error: {},
      applied_coupon: {
        ...state.applied_coupon,
        response: action?.payload?.data,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.APPLY_COUPON_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
      hasError: true,
      applied_coupon: {
        ...state.applied_coupon,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    // CLEAR STATE
    [ActionTypes.CLEAR_ERROR]: (state) => ({
      ...state,
      signUpError: false,
      IsSignUpEmailSent: false,
      isForgotEmailSent: false,
    }),
    [ActionTypes.CLEAR_ERROR_SUCCESS]: (state) => ({
      ...state,
      signUpError: false,
      IsSignUpEmailSent: false,
      isForgotEmailSent: false,
    }),
    [ActionTypes.CLEAR_ERROR_ERROR]: (state) => ({
      ...state,
      signUpError: false,
      IsSignUpEmailSent: false,
      isForgotEmailSent: false,
    }),

    // verify subdomain
    [ActionTypes.VERIFY_SUBDOMAIN]: (state) => ({
      ...state,
      verify_subdomain: {
        loading: false,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.VERIFY_SUBDOMAIN_SUCCESS]: (state, action) => ({
      ...state,
      verify_subdomain: {
        ...state.verify_subdomain,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.VERIFY_SUBDOMAIN_ERROR]: (state, action) => ({
      ...state,
      verify_subdomain: {
        ...state.verify_subdomain,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //get institution type

    [ActionTypes.INSTITUTION_CATEGORY]: (state) => ({
      ...state,
      institution_type: {
        ...state.institution_type,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.INSTITUTION_CATEGORY_SUCCESS]: (state, action) => ({
      ...state,
      institution_type: {
        ...state.institution_type,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.INSTITUTION_CATEGORY_ERROR]: (state, action) => ({
      ...state,
      institution_type: {
        ...state.institution_type,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //workos
    [ActionTypes.SIGNIN_WORKOS]: (state) => ({
      ...state,
      signin_workos: {
        ...state.signin_workos,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SIGNIN_WORKOS_SUCCESS]: (state, action) => ({
      ...state,
      signin_workos: {
        ...state.signin_workos,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SIGNIN_WORKOS_ERROR]: (state, action) => ({
      ...state,
      signin_workos: {
        ...state.signin_workos,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //ED_LINK
    [ActionTypes.SIGNIN_EDLINK]: (state) => ({
      ...state,
      signin_edlink: {
        ...state.signin_edlink,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SIGNIN_EDLINK_SUCCESS]: (state, action) => ({
      ...state,
      signin_edlink: {
        ...state.signin_edlink,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SIGNIN_EDLINK_ERROR]: (state, action) => ({
      ...state,
      signin_edlink: {
        ...state.signin_edlink,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //subscription status
    [ActionTypes.CHECK_SUBSCRIPTION]: (state) => ({
      ...state,
      subscription_status: {
        ...state.subscription_status,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.CHECK_SUBSCRIPTION_SUCCESS]: (state, action) => ({
      ...state,
      subscription_status: {
        ...state.subscription_status,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.CHECK_SUBSCRIPTION_ERROR]: (state, action) => ({
      ...state,
      subscription_status: {
        ...state.subscription_status,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //workos org
    [ActionTypes.SYNC_WORKOS]: (state) => ({
      ...state,
      sync_workos: {
        ...state.sync_workos,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SYNC_WORKOS_SUCCESS]: (state, action) => ({
      ...state,
      sync_workos: {
        ...state.sync_workos,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SYNC_WORKOS_ERROR]: (state, action) => ({
      ...state,
      sync_workos: {
        ...state.sync_workos,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //subdomain login
    [ActionTypes.SUBDOMAIN_LOGIN]: (state) => ({
      ...state,
      subdomain: {
        ...state.subdomain,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SUBDOMAIN_LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      subdomain: {
        ...state.subdomain,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SUBDOMAIN_LOGIN_ERROR]: (state, action) => ({
      ...state,
      subdomain: {
        ...state.subdomain,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //subdomain reminder
    [ActionTypes.FORGOT_SUBDOMAIN]: (state) => ({
      ...state,
      forgot_subdomain: {
        ...state.forgot_subdomain,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.FORGOT_SUBDOMAIN_SUCCESS]: (state, action) => ({
      ...state,
      forgot_subdomain: {
        ...state.forgot_subdomain,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.FORGOT_SUBDOMAIN_ERROR]: (state, action) => ({
      ...state,
      forgot_subdomain: {
        ...state.forgot_subdomain,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //subdomain existence
    [ActionTypes.SUBDOMAIN_EXISTS]: (state) => ({
      ...state,
      subdomain_validity: {
        ...state.subdomain_validity,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SUBDOMAIN_EXISTS_SUCCESS]: (state, action) => ({
      ...state,
      subdomain_validity: {
        ...state.subdomain_validity,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SUBDOMAIN_EXISTS_ERROR]: (state, action) => ({
      ...state,
      subdomain_validity: {
        ...state.subdomain_validity,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //unsync workos
    [ActionTypes.UNSYNC_WORKOS]: (state) => ({
      ...state,
      unsync_workos: {
        ...state.unsync_workos,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.UNSYNC_WORKOS_SUCCESS]: (state, action) => ({
      ...state,
      unsync_workos: {
        ...state.unsync_workos,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.UNSYNC_WORKOS_ERROR]: (state, action) => ({
      ...state,
      unsync_workos: {
        ...state.unsync_workos,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //create org
    [ActionTypes.CREATE_ORGANIZATION_WITH_WORKOS]: (state) => ({
      ...state,
      create_org_workos: {
        ...state.create_org_workos,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.CREATE_ORGANIZATION_WITH_SUCCESS]: (state, action) => ({
      ...state,
      create_org_workos: {
        ...state.create_org_workos,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.CREATE_ORGANIZATION_WITH_ERROR]: (state, action) => ({
      ...state,
      create_org_workos: {
        ...state.create_org_workos,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //subdomain existence
    [ActionTypes.STRIPE_PAYMENT_INTENT]: (state) => ({
      ...state,
      stripe_payment_intent: {
        ...state.stripe_payment_intent,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.STRIPE_PAYMENT_INTENT_SUCCESS]: (state, action) => ({
      ...state,
      stripe_payment_intent: {
        ...state.stripe_payment_intent,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.STRIPE_PAYMENT_INTENT_ERROR]: (state, action) => ({
      ...state,
      stripe_payment_intent: {
        ...state.stripe_payment_intent,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),
    //subdomain existence
    [ActionTypes.STRIPE_PAYMENT_INTENT_VERIFY]: (state) => ({
      ...state,
      verfiy_stripe_payment_intent: {
        ...state.verfiy_stripe_payment_intent,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.STRIPE_PAYMENT_INTENT_VERIFY_SUCCESS]: (state, action) => ({
      ...state,
      verfiy_stripe_payment_intent: {
        ...state.verfiy_stripe_payment_intent,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.STRIPE_PAYMENT_INTENT_VERIFY_ERROR]: (state, action) => ({
      ...state,
      verfiy_stripe_payment_intent: {
        ...state.verfiy_stripe_payment_intent,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),
  },
  initialState
);

export default reducer;
