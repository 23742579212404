import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {getManagerContact} from '../../profile/services';
import PhoneIco from "~/assets/images/icons/phone-call-black.svg";
import EmailIco from "~/assets/images/icons/email.svg";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Helmet} from "react-helmet";

const InstitutionContact = () => {
  const dispatch = useDispatch();


  const [emptyContact,setEmptyContact] = useState(true);

  const response_contact = useSelector(state => state.profile?.manager_contact_details?.response);
  const loading = useSelector(state => state.profile?.manager_contact_details?.loading);

  useEffect(() => {
    dispatch(getManagerContact());
  },[]);

  useEffect(() => {
    if(response_contact?.results?.length > 0){
       setEmptyContact(false);
    } else {
      setEmptyContact(true);
    }
  }, [response_contact]);

  return (
    <Row><Col lg="4">
    <div className="whitebox shadow-box ">
          <Helmet>
                <title>Institution Contact - Cultural Engagement  </title>
            </Helmet>
      {!loading ? (
        <>
        {!emptyContact ? (
             <div className="saved-content-show">
             <div className="contact-info-sec">
              <h4 className="settings-sub-title">Primary contact info</h4>
              <div className="form-item-show d-flex align-items-center">
                <span className="items-show-img"><img src={PhoneIco} alt="phone" /></span><span>{response_contact?.results[0]?.phone_number}</span>
              </div>
              <div className="form-item-show d-flex align-items-center">
              <a href={`mailto:${response_contact?.results[0]?.email}`} style={{color: '#333333'}}><span className="items-show-img"><img src={EmailIco} alt="email" /></span><span>{response_contact?.results[0]?.email}</span></a> 
              </div>
            </div>
          
            <div className="contact-info-sec">
            {(response_contact?.results[0]?.alternate_phone_number || response_contact?.results[0]?.alternate_email) && (
                <>  <h4 className="settings-sub-title">Secondary contact info</h4>
                {response_contact?.results[0]?.alternate_phone_number && (
                    <div className="form-item-show d-flex align-items-center">
                    <span className="items-show-img"><img src={PhoneIco} alt="phone" /></span><span>{response_contact?.results[0]?.alternate_phone_number}</span>
                  </div>
                )}
              {response_contact?.results[0]?.alternate_email && (
                <div className="form-item-show d-flex align-items-center">
                 <a href={`mailto:${response_contact?.results[0]?.alternate_email}`} style={{color: '#333333'}}><span className="items-show-img"><img src={EmailIco} alt="email" /></span><span>{response_contact?.results[0]?.alternate_email}</span></a> 
                </div>
                )}
                </>
              )}
            
            </div>

          </div>
        ):(
          <div ><b>No contact details provided </b>
          </div>
        )}
        </>
      ) : (
        <><Spinner animation="border" />;</>
      )}
    
    </div>
    </Col></Row>
  );
};

export default InstitutionContact;