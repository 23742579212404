import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Header from '~/shared/components/header';
import Sidebar from '~/shared/components/sidebar';
import {useDispatch, useSelector} from "react-redux";
import {checkSubscriptionStatus,resetStore ,signInSuccess} from '~/modules/auth/services';
import {useHistory} from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {beaconChat} from "../../utils/utilities";
import LetsGoImg from '~/assets/images/letsgo.svg';



    function PageWrapper(props) {
        const history = useHistory();
        const dispatch = useDispatch();
        const [show,setShow] = useState(false)
        const [trialEnd,setTrialEndDate] = useState('')
    const  selectedTheme  = useSelector(state => state.settings.selectedTheme);
    const { user } = useSelector(state => state.auth);
    const [collapsed, setCollapsed] = useState(false);
    const [image, setImage] = useState(true);
    const [toggled, setToggled] = useState(false);
    const [theme, setTheme]=useState(selectedTheme)
    

        useEffect(()=>{
            beaconChat()
        },[])
        useEffect(()=>{
            let localTheme =localStorage.getItem('theme')

            setTheme(selectedTheme ? selectedTheme :localTheme)

        },[selectedTheme])

        useEffect(() => {

                dispatch(checkSubscriptionStatus(user?.institution_id, function (res) {
                    if (res) {

                        if (res?.is_subscribed == false) {

                            toast.error('Subscription has expired!')
                            setTimeout(()=>{

                                let action = 'USER_LOGOUT'
                                dispatch(resetStore(action))
                                history.push({
                                    pathname: "/login",
                                    state: { due_to: "Subscription" }
                                })
                            },1700)


                        }else if(res?.is_subscribed == true ) {
                            setTrialEndDate(res?.subscription_end_date)
                            let response = {data: {...user, is_trial_plan: res.is_trial_plan}}
                            if(res?.is_trial_plan){
                                setShow(true)
                            }

                            dispatch(signInSuccess(response))

                        }

                    }
                }));

        }, []);


    const handleCollapsedChange = (checked) => {
      setCollapsed(checked);
    };
    const handleImageChange = (checked) => {
      setImage(checked);
    };
    useEffect(() => {
     localStorage.setItem('logo',`${user.logo}`)

    }, [])
    const handleToggleSidebar = (value) => {
      setToggled(value);
    };
        const handleHistory = () => {

            history.push({pathname:'/profile',state:{key:'Subscription'}})
            setShow(false)
        }

        return (
        <>
        <div className="page-wrapper compact-wrapper" id="pageWrapper"  >
            <ToastContainer  autoClose={2000}/>
    <div className={`app ${toggled ? 'toggled' : ''}`}>

            <div className="page-body-wrapper">
                <Sidebar image={image}
        collapsed={collapsed}
        toggled={toggled}
        onToggle={handleToggleSidebar}
        handleToggleSidebar={handleToggleSidebar}
                         name={props?.name}
/>
                <div className="page-body">
                <Header image={image}
        toggled={toggled}
           name={props?.name}
        collapsed={collapsed}
        onToggle={handleToggleSidebar}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        handleImageChange={handleImageChange} />
        <div className="page-contents">
                    {props.children}
                    </div>
                </div>
            </div>
        </div>

        </div>


            <Modal className="custom-modal inner-popup letsgo-pop add-module-content-modal"
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   onHide={() => setShow(false)}
                   show={show}

            >
        <Modal.Header closeButton>
             </Modal.Header>
                <Modal.Body>
                    <div className="letsgo-pop-wrap text-center">
                        <div className="letsgo-img common-letsgo-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="229.304" height="350.11" viewBox="0 0 229.304 350.11">
  <g id="undraw_certification_re_ifll" transform="translate(-0.466 -32.676)">
    <path id="Path_8487" data-name="Path 8487" d="M435.276,268.57a61.071,61.071,0,0,1-6.714,2.123c-3.008.733-4.706,1.073-7.609-.054-.1-.04-.2-.079-.291-.117a30.839,30.839,0,0,1-18.769-27.962c-.015-.205-.015-.421-.015-.631a30.04,30.04,0,0,1,.145-3.034,30.671,30.671,0,0,1,.658-4.081l-.029-.005a11.214,11.214,0,0,1-2.955-9,14.881,14.881,0,0,1,6.722-11.128,23.542,23.542,0,0,1,9.774-3.45,24.756,24.756,0,0,1,2.81-.163h8.821a38.106,38.106,0,0,1,5.538-.873h.014c17.754-1.473,31.755,10.3,30.449,24.618-.01.016-.024.032-.034.047-.377.615-.711,1.2-1.006,1.757-.285.521-.527,1.02-.735,1.494-1.62,3.66,6.079,60.45,3.318,61.312-1.572.489-7.907-41.77-6.771-33.734C460.029,275.766,443.455,265.546,435.276,268.57Z" transform="translate(-352.997 -164.463)" fill="#2f2e41"/>
    <path id="Path_8488" data-name="Path 8488" d="M143.064,389.135l24.61,52.429L73,431.976l19.762-50.331Z" transform="translate(-34.04 -182.369)" fill="#ffb6b6"/>
    <path id="Path_8489" data-name="Path 8489" d="M496.535,641.065a137.9,137.9,0,0,1-51.135,6.849L437.866,635.7s-.315,4.55-.786,11.506a133.034,133.034,0,0,1-24.311-5.154c-15.03-4.7-29.118-11.982-43.4-18.7-2.893-17.243-3.978-43.832,12.014-59.311l86.076,15.773s13.81,10.33,10.116,14.03-4.267,3.122-1.839,5.55,7.672,5.673,7.726,7.8C483.484,608.017,489.37,623.047,496.535,641.065Z" transform="translate(-338.108 -329.18)" fill="#2f2e41"/>
    <path id="Path_8490" data-name="Path 8490" d="M441.711,322.007l-20.8,1.451-13.441,12.988-4.707.84a17.55,17.55,0,0,0-14.439,18.31c.968,16.4,3.136,40.917,7.667,48.869,7.026,12.331,12.83,8.462,7.026,12.331s-9.7.829-7.026,6.943-3.613,13.957-3.613,13.957l-2.5,2.5,2.654,1.088,84.582,11.006s-7.314-32.883-2.74-40.961,15.664-32.5,5.766-42.049l-9.677-34.055-16.646-4.91Z" transform="translate(-347.718 -216.554)" fill="#f69525"/>
    <path id="Path_8491" data-name="Path 8491" d="M681.168,529.941a9.614,9.614,0,0,1-9.133-11.445l-39.676-26.87,4.734-12.8,46.89,32.259a9.6,9.6,0,0,1-2.816,18.858Z" transform="translate(-461.288 -289.526)" fill="#ffb6b6"/>
    <path id="Path_8492" data-name="Path 8492" d="M509.032,342.265l-5.765,2.283,30.755,60.067,63.667,43.4,9.178-15.511L558.4,390.639,539,353.428A23.881,23.881,0,0,0,509.032,342.265Z" transform="translate(-401.217 -225.198)" fill="#f69525"/>
    <circle id="Ellipse_292" data-name="Ellipse 292" cx="20.882" cy="20.882" r="20.882" transform="translate(61.632 58.407)" fill="#ffb6b6"/>
    <path id="Path_8493" data-name="Path 8493" d="M453.653,238.785c-.348.184-.692.353-1.04.522s-.725.348-1.093.513c-6.988,3.168-12.681,3.521-16.134-1.035a19.34,19.34,0,0,0-1.591-6.954,11.176,11.176,0,0,1-1.843,6.954H425c-7.162,4.754-10.838,9.121-5.866,24.728.324,1.025,2.544,37.452,3.177,40.252-10.843-4.159-20.2-48.319-20.433-59.954-.015-.189-.015-.387-.015-.58a25.45,25.45,0,0,1,.145-2.79,26.127,26.127,0,0,1,.658-3.753l-.029,0a9.8,9.8,0,0,1-2.955-8.28,23.882,23.882,0,0,1,6.635-5.949,42.346,42.346,0,0,1,7.264-2.728l.179-.043c.764-.208,1.548-.4,2.331-.561a43.782,43.782,0,0,1,4.889-.74c.121,0,.237-.015.343-.015a3.664,3.664,0,0,1,1.867.513s0,0,.015,0A3.684,3.684,0,0,1,425,222.019h7.748c.285,0,.571.01.856.024,10.79.43,19.461,5.044,20.017,15.65C453.644,238.06,453.653,238.418,453.653,238.785Z" transform="translate(-352.981 -168.327)" fill="#2f2e41"/>
    <circle id="Ellipse_293" data-name="Ellipse 293" cx="13.757" cy="13.757" r="13.757" transform="translate(41.11 43.784)" fill="#2f2e41"/>
    <path id="Path_8494" data-name="Path 8494" d="M426.743,204.392c-1.563,7.435-9.274,11.882-16.651,10.063A13.757,13.757,0,0,1,400.029,197.8c1.819-7.377,10.772-5.25,16.651-10.063C425,180.933,428.851,194.365,426.743,204.392Z" transform="translate(-352.989 -153.214)" fill="#2f2e41"/>
    <path id="Path_8495" data-name="Path 8495" d="M391.815,497.664c11.725-47.814,34.957-88.911,73.432-121.109a10.825,10.825,0,0,1,14.852-3.64l91.464,55.457a10.825,10.825,0,0,1,3.64,14.851c-14.685,49.062-36.681,91.635-73.432,121.109a10.825,10.825,0,0,1-14.851,3.64l-91.464-55.457A10.825,10.825,0,0,1,391.815,497.664Z" transform="translate(-348.627 -239.515)" fill="#f1ebe3"/>
    <path id="Path_8496" data-name="Path 8496" d="M408.072,513.777a10.825,10.825,0,0,1-3.64-14.851c11.725-47.814,34.957-88.911,73.432-121.109a10.685,10.685,0,0,1,4.034-3.811l-1.8-1.091a10.825,10.825,0,0,0-14.852,3.64c-38.475,32.2-61.707,73.3-73.432,121.11a10.825,10.825,0,0,0,3.64,14.851l91.465,55.457a10.777,10.777,0,0,0,10.817.171Z" transform="translate(-348.627 -239.515)" opacity="0.2"/>
    <path id="Path_8497" data-name="Path 8497" d="M568.955,548.626l-61.4-36.993A4.3,4.3,0,0,1,512,504.266l61.4,36.993a4.3,4.3,0,0,1-4.439,7.367Z" transform="translate(-402.246 -301.077)" fill="#fff"/>
    <path id="Path_8498" data-name="Path 8498" d="M543.824,481.982l-13.507-8.138a4.3,4.3,0,1,1,4.416-7.381l.023.014,13.507,8.138a4.3,4.3,0,1,1-4.439,7.367Z" transform="translate(-412.83 -283.489)" fill="#fff"/>
    <path id="Path_8499" data-name="Path 8499" d="M550.7,578.913l-61.4-36.993a4.3,4.3,0,0,1,4.43-7.373l.009.005,61.4,36.993a4.3,4.3,0,1,1-4.439,7.367Z" transform="translate(-393.75 -315.17)" fill="#fff"/>
    <circle id="Ellipse_294" data-name="Ellipse 294" cx="17.932" cy="17.932" r="17.932" transform="translate(162.072 145.206)" fill="#3f3d56"/>
    <circle id="Ellipse_295" data-name="Ellipse 295" cx="54.34" cy="54.34" r="54.34" transform="translate(89.255 273.347)" fill="#fff"/>
    <path id="Path_8500" data-name="Path 8500" d="M563.384,748.986a55.1,55.1,0,1,1,55.1-55.1,55.1,55.1,0,0,1-55.1,55.1Zm0-108.681a53.582,53.582,0,1,0,53.583,53.582A53.582,53.582,0,0,0,563.384,640.3Z" transform="translate(-419.789 -366.199)" fill="#3f3d56"/>
    <path id="Path_8501" data-name="Path 8501" d="M564.53,730.728a6.044,6.044,0,0,1-4.835-2.418L544.87,708.543a6.045,6.045,0,1,1,9.671-7.253l9.7,12.931,24.911-37.365a6.045,6.045,0,0,1,10.059,6.706l-29.65,44.475a6.047,6.047,0,0,1-4.862,2.69Z" transform="translate(-428.347 -374.758)" fill="#f69525"/>
    <path id="Path_8503" data-name="Path 8503" d="M374,550.109a10.2,10.2,0,0,1-4.831-14.76L339.7,493.934l9.452-10.966,34.634,49.446a10.183,10.183,0,0,1-9.792,17.7Z" transform="translate(-325.104 -291.454)" fill="#ffb6b6"/>
    <path id="Path_8504" data-name="Path 8504" d="M362.374,362.2h0a7.271,7.271,0,0,0-9.293,1.636l-39.809,55.674,36.892,52.4L364.6,456.4l-22.456-35.288L371.988,392.7A23.881,23.881,0,0,0,362.374,362.2Z" transform="translate(-312.806 -234.789)" fill="#f69525"/>
  </g>
</svg>
                        </div>
                        <h3>You are on 14-day free trial , Your plan trial plan will expire on {new Intl.DateTimeFormat('en-GB', {
                            month: 'long',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(trialEnd  ? trialEnd:
                            new Date()))}.</h3>
                        <div className="btn-wrap"><Button onClick={handleHistory}>Buy Regular Plan</Button></div>
                    </div>

                </Modal.Body>

            </Modal>
            </>
    );
}

PageWrapper.propTypes = {
    children: PropTypes.node,
};
export default PageWrapper;
