import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChartistGraph from 'react-chartist';
import { chart1, chartHorizontalData, chart6Data, chart6Options, chart9Data } from './chartistData';
const ExpandedComponent = ({data,...props}) => {

    const [chartData, setchartData] = useState({})
    useEffect(()=>{
        if(data && data.construct_score && data?.construct_score?.question_scores) {
            let initialLables = []
            let initialValues = []
            initialLables.push(Object.keys(data?.construct_score?.question_scores))
            const chartDataObj = {
                data: {
                    labels: Object.keys(data?.construct_score?.question_scores),
                    series: [
                        Object?.values(data?.construct_score?.question_scores),

                    ]
                },
                options: {
                    seriesBarDistance: 10,
                    reverseData: true,
                    horizontalBars: true,

                    axisY: {
                        offset: 70
                    },
                    axisX: {
                        offset: 10,
                        onlyInteger: true,
                        type: ChartistGraph.AutoScaleAxis,
                    },



                },

            }
            setchartData(chartDataObj)
        }
    },[data])

if(data?.construct_score?.question_scores && data?.construct_score?.question_scores) {
    return (
        <div className="expanded-table-wrap">
            <Row>
                <Col md={6}>
                    <div className="graph-wrap">
                        <div className="qstn-graph-box whitebox shadow-box">
                            <div className="qstn-graph-box-place">
                                <ChartistGraph
                                    data={chartData ? chartData.data : {}}
                                    options={chartData?.options}
                                    type={'Bar'}
                                    className="ct-chart flot-chart-container ct-purpose-graph scoring-table-graph"/>

                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="expanded-row-text-sec m-t-20">
                        <h5>{data?.construct ? data?.construct : 'NA'}</h5>
                        <p>{data?.construct_score  && data?.construct_score?.description   ?  data?.construct_score?.description : 'NA'}</p>
                    </div>
                </Col>

            </Row>
        </div>
    )
}else {
    return (
    <div className="expanded-table-wrap">
        <Row>
            <Col md={6}>
                <div className="graph-wrap">
                    <div className="qstn-graph-box whitebox shadow-box">
                        <div className="qstn-graph-box-place">
                          <span>No Record</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="expanded-row-text-sec">
                    <h3>Purpose</h3>
                    <p>{data?.description && data?.description != null ? data?.description : 'NA'}</p>
                </div>
            </Col>

        </Row>
    </div>
    )
}
};
export default ExpandedComponent;
