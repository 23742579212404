import React, {useState, useEffect, useCallback} from 'react';
import DataTable from 'react-data-table-component';
import Spinner from "react-bootstrap/Spinner";
import {Link, useHistory, useLocation} from 'react-router-dom';
import {getLearnerAssignmentCourses,handlePaginationLearnerAssignmentCourse,handlePreviousPaginationLearnerAssignmentCourse} from '../../../modules/learnerLearningPath/services'
import {useDispatch, useSelector} from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import {Button} from "react-bootstrap";
import Select, { components } from "react-select" ;
import CourseDummy from '~/assets/images/course_dummy.png';
import {
    getLearnerCoursesList,
    handlePaginationLearnerCourse,
    handlePreviousPaginationLearnerCourse
} from "../../courses/services";
import {getNotifications} from "../../Notifications/services";
import debounce from "lodash.debounce";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";

const LearnerLearningPathDetails = ({match}) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const location = useLocation();
    const response = useSelector(state => state.learnercourse?.learner_courses_list?.response);
    const loading = useSelector(state => state?.learnercourse?.learner_courses_list?.loading);
    const [state, setState] =useState({
        courses:[],
        count:response?.count,
        paginationItem:[]
    })
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const  url = match?.url


    const options = [
        { value: 'new', label: 'New' },
        { value: 'in_progress', label: 'In progress' },
        { value: 'completed', label: 'Completed' },
        { value: 'failed', label: 'Failed' }
    ];


    useEffect(() => {
        if(location?.state && location?.state?.category  && location?.state?.category == 'complete'){
            let option =  { value: 'completed', label: 'Completed' }
            setStatus(option);
            dispatch(getLearnerCoursesList(search, 'completed'));

        }else if(location?.state && location?.state?.category  && location?.state?.category == 'ongoing'){
            let option = { value: 'in_progress', label: 'In progress' }
            setStatus(option);
            dispatch(getLearnerCoursesList(search, 'in_progress'));
        }else  {
            setSearch('');

            dispatch(getLearnerCoursesList(search, ''));
        }
        // dispatch(getNotifications())

    }, []);




    useEffect(() => {
        const count = response?.count
        const perPage = 12;
        const buttonsCount = Math.ceil(count / perPage);
        let ellipsisLeft = false;
        let ellipsisRight = false;
        let items = []
        for (let i = 1; i <= Number(buttonsCount); i++) {
            if (i === activePage) {
                items.push(
                    {id: i, current: true, ellipsis: false}
                );
            } else {
                if (i < 10 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
                    items.push(
                        {id: i, current: false, ellipsis: false}
                    );
                } else if (i > 1 && i < activePage && !ellipsisLeft) {
                    items.push(
                        {id: i, current: false, ellipsis: true}
                    );
                    ellipsisLeft = true;
                } else if (i < buttonsCount && i > activePage && !ellipsisRight) {
                    items.push(
                        {id: i, current: false, ellipsis: true}
                    );
                    ellipsisRight = true;

                }
            }

        }
        setState({...state, courses: response, count: response?.count, paginationItem: items});
    }, [response]);

    const debouncedGetSearch = useCallback(debounce((query,stat) => {

        setActivePage(1)
        dispatch(getLearnerCoursesList( query, stat ? stat.value  : ''));
    },1000),[])

    const searchText = (e) => {
        setSearch(e.target.value);
        debouncedGetSearch(e.target.value,status)

    };

    const onSelect = (val) =>{
        setStatus(val);
        setActivePage(1);
        dispatch(getLearnerCoursesList(search, val.value));
    };

    const reset = () => {
        setSearch('');
        setStatus('');
        dispatch(getLearnerCoursesList( '', ''));
    };
    const handleNext = () => {

        if (state.courses?.next) {
            var regex = /page=\d+/g;
            const [number ] = state.courses?.next?.match(regex)
            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
            dispatch(handlePaginationLearnerCourse(state.courses?.next))
        } else {
            return false
        }

    }

    const handlePrevious = () => {
        if (state.courses?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            dispatch(handlePreviousPaginationLearnerCourse(state.courses?.previous))
        } else {
            return false
        }

    }
    const handleIndex = (index) =>{
        if(index == 1){
            dispatch(getLearnerCoursesList(search ? search : '',status ? status.value  : '', index));
        }else{
            dispatch(getLearnerCoursesList(search ? search : '',status ? status.value  : '', index));
        }
        setActivePage(index);
    }

    const checkPercentage = (course) => {
        let percentage = course?.completed_modules / course?.modules_count * 100
        return percentage
    }
    const checkTextStatus = (status) => {
        if(status == 'In Progress'){
            return 'Continue'
        }else if(status == 'New'){
            return 'Start'
        }else{
            return 'Start'
        }

    }
    const columns = [
        // {
        //    name: 'No#',
        //    selector: 'learning_path_name',
        //    sortable: true,
        //    center: false,
        //    grow: 2,
        //    cell: (row,index) => <div>
        //       {index+1}
        //    </div>
        //
        // },

        {
            name: 'Course Name',
            selector: 'name',
            sortable: true,
            center: false,
            grow: 2,

        },


        {
            name: 'Completion Percentage',
            selector: 'due_date',
            sortable: true,
            center: false,
            grow: 2,
            cell: (row, index) =>

                <div className="d-flex  align-items-center justify-content-between w-100" data-tag="allowRowEvents">
                    <div className="learning-progress progress">
                        <div className="progress-bar" style={{ width: `${checkPercentage(row) > 0 ? checkPercentage(row) : 5}%` }}>&nbsp;</div>
                    </div>

                </div>
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            center: false,
            grow: 2,
            cell: row => <div>
                {checkPercentage(row) >= 100 ?
                    <p className='' type="button" disabled={true}>Completed</p>
                    :
                    <>
                        {checkPercentage(row) > 5 && checkPercentage(row) < 100 ?
                            <p className='' type="button"
                                 >In progress</p> :
                            <p className='' type="button"
                                    >New</p>

                        }
                    </>

                }
            </div>
        },
        // {
        //     name: 'Completed Date',
        //     selector: 'completed_date',
        //     sortable: true,
        //     center: false,
        //     grow: 2,
        //     cell: row => <div>
        //         {row?.status == 'Completed' || row?.status == 'Failed' ?
        //             <div> {row?.completed_date}</div>
        //             :
        //             '-'
        //
        //         }
        //     </div>
        //
        //
        // },
        {
            name: 'Action',
            selector: 'certificate',
            sortable: true,
            center: false,
            grow: 2,
            cell: row => <div>
                {checkPercentage(row) >= 100 ?
                    <Button className='text-white' type="button" disabled={true}>Completed</Button>
                    :
                    <>
                        {checkPercentage(row) > 5 && checkPercentage(row) < 100 ?
                            <Button className='text-white' type="button"
                                    onClick={() => history.push(`/learnercoursedetails/${row?.id}`)}>Continue</Button> :
                            <Button className='text-white' type="button"
                                    onClick={() => history.push(`/learnercoursedetails/${row?.id}`)}>Start</Button>

                        }
                    </>

                }
            </div>

        },
    ];


    const handleCardClick  =(item)=>{
        history.push(`/learnercoursedetails/${item?.id}`)
    }
    const CaretDownIcon = () => {
        return (
           <div className="filter-icon-select">
           <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
           <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
           </svg>
           </div>
        );
      };

      const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownIcon />
          </components.DropdownIndicator>
        );
      };
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

    return (
        <div className="learner-accordian-wrap datatble-wrap ">
            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="dt-filter-left d-flex justify-content-between">
                    <div className="custom-react-select">
                        <Select options={options}  value={status}  onChange={onSelect} placeholder="Status" components={{ DropdownIndicator }} />
                    </div>
                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative search-icon-left">
                    <button className="iconbutton search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                            <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                        </svg>
                    </button>
                        <input type="text" placeholder="Search using keyword" value={search}
                               onChange={searchText}
                               className="form-control input-item" />
                        <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                    </div>
                    <OverlayTrigger
                overlay={
                    <Tooltip id="InfoText">
                          Help
                    </Tooltip>
                }
            >
                <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </OverlayTrigger>
                </div>

            </div>

            <div className="courseslisting">
                <Row>
                    {loading && loading ?
                        (
                            <>
                                <div className='loaderCourses'>
                                    <Spinner animation="border" variant="danger"/>
                                </div>

                            </>


                        ):(
                            <>

                                { !loading && state.courses && state.courses && state.courses?.results && state.courses?.results.length ? state.courses?.results?.map((el,index)=>(
                                        <Col style= {{cursor:'pointer'}}  lg={4} xl={3} className="m-b-30 course-box-outer">

                                            <div className="common-card coursecard" onClick={()=>handleCardClick(el)}>
                                                <div className="course-box-top">
                                                    <div className="course-listing-img">
                                                        <img src={el?.banner_image ? el?.banner_image :CourseDummy} alt="" />
                                                    </div>
                                                    <div className="common-card-detail-wrap box-listing">
                                                        <h4>{el?.course_name}</h4>
                                                        <p>{el?.description?.substring(0, 120) + ' '  +  (el?.description?.length > 120 ?  '...' : '')}</p>
                                                    </div>
                                                    {/*<div className="coommon-card-det-number d-flex justify-content-between">*/}
                                                    {/*    <span className="common-card-oth-det-left">Modules <span className="det-numb">{el?.modules_count}</span> </span>*/}
                                                    {/*    <span className="common-card-oth-det-left">Quizzes <span className="det-numb">{el?.quizzes_count}</span> </span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="box-overlay">
                                                    <span className="overlay-viewmore">View More<i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                                                    <div className="overlay-bg">&nbsp;</div>
                                                </div>

                                            </div>



                                        </Col>
                                    ))
                                    :
                                    <span className='noData'>No Assignments has been enrolled to you yet, kindly contact your organisation.</span>

                                }

                            </>


                        )

                    }
                </Row>
                {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response || state?.courses?.previous ?
                <div className="d-flex justify-content-center">
                    <Pagination size="sm" className="custom-pagination">
                        <Pagination.First disabled={!state?.courses?.previous} className={`${state?.courses?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious}/>
                        {state.paginationItem.map(page => {
                            if(!page.ellipsis) {
                                return <li key={page.id}>
                                    <Pagination.Item
                                        onClick={()=>handleIndex(page.id)} key={page.id} active={page.id == activePage}
                                    >
                                        {page.id}
                                    </Pagination.Item>
                                </li>
                            }else {
                                return <Pagination.Ellipsis></Pagination.Ellipsis>
                            }
                        })}
                        <Pagination.Last disabled={!state?.courses?.next} onClick={handleNext} className={`${state?.courses?.next ? 'paginationStyle' :''} `}/>

                    </Pagination>

                </div>
                    :
                    ''
                }
            </div>

            <Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
               <Modal.Body>
                 <div className="module-modal-header my-4"><h4> Courses enrolled to you by your managers will be listed here  </h4></div>
                    <ol className="info-list">
                        
                    <li><span>New/In progress tab displays all your pending enrollments which should be completed before the due date.</span></li>
                    <li><span>Completed tab displays all the courses completed from your end before the due date.</span></li>
                    <li><span>Failed tab displays all courses that lapsed the due date before your completion</span></li>
               
                    </ol>
                    

                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
        </div>
    );
};

export default LearnerLearningPathDetails;
