import React from 'react';
import Button from 'react-bootstrap/Button'
import Spinner from "react-bootstrap/Spinner";

const Intro = ({survey}) => {
   return (
       <>
      <div className="intro-text-wrap">
         <p>{survey?.description ? survey?.description : 'Not Available'}</p>
      </div>

       </>
   );
};
export default Intro;
