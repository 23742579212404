
import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MyChart from './components/mychart';
import MyChart2 from "./components/mychart2";


function Responses(props) {
    const {demographicQuestions, generalQuestions,surveyId } = props
    return (
        <div className="whitebox padding-box radiusbox">
            <div className="tab-wrapper">
                <Tabs defaultActiveKey={0} id="uncontrolled-tab-example" className="m-b-30">
                    <p>Individual responses can be viewed in these interactive graphics. Select the desired variable with which to view. </p>
                    {generalQuestions?.length && generalQuestions.map((item, index) => {

                if(item?.question_type.length >0 && item?.question_type!="free_response"){
                   return( <Tab eventKey={index} title={ item?.title? item?.title :`Question${index+1}`}>
                        <MyChart {...props} generalQuestion={item} title={ item?.title? item?.title :item?.question}/>
                    </Tab>
                   )
                }
                 }
                   
                   )}

                   
                </Tabs>
            </div>
        </div>
    );
};

export default Responses;
