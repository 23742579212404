import React, {useEffect, useState} from 'react';
import  {Modal, Button} from 'react-bootstrap';
import LearningPathContainerDetail from "../workFlowDetail/container";
import ReactFlow, {
    ReactFlowProvider,

} from 'react-flow-renderer';
import {getNodesLpList} from "../../../../services";
import {useDispatch, useSelector} from "react-redux";

const  ModulePopView  = (props) => {
    let dispatch = useDispatch();
    const [state, setState] = useState({
        show:false,
        nodes:[]
    })
    const parentState = (key,id,item,yes) =>{

        setState({...state, defaultKey: key,lpId:id,learningPath:item,moduleAdded:yes})
    }

    useEffect(() => {
        if (props?.urlId) {
            dispatch(
                getNodesLpList(props?.urlId, function (res) {
                    if (res) {
                            if(res?.elements?.length > 0){
                                res.elements =  res?.elements?.filter((el)=>Object?.keys(el)?.includes('data'))

                            }
                        setState({...state,nodes: res})
                    }
                },'admin')
            );

        }
    }, []);
    return (


            <Modal show={props.show} onHide={props.onHide} className="common-modal full-screen-modal inner-popup add-module-content-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ReactFlowProvider>
            <LearningPathContainerDetail isEditable = {false} parentState={parentState} lpId={props?.lpId} learningPath={props?.lp} urlId={props?.urlId ? props?.urlId :null} nodes={state?.nodes} />
                    </ReactFlowProvider>
                </Modal.Body>
            </Modal>

    )

}
export default ModulePopView;
