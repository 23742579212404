import React, {useState, useEffect, useCallback} from 'react';
import Spinner from "react-bootstrap/Spinner";
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import CourseDummy from '~/assets/images/course_dummy.png';
import {Button} from "react-bootstrap";
import {
    getLearnerCompletedCoursesList,
    getLearnerOngoingCoursesList,
    getLearnerFailedCoursesList,
    handlePaginationLearnerCourse,
    handlePreviousPaginationLearnerCourse
} from "../../courses/services";
import debounce from "lodash.debounce";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";

const LearnerCourseListingWithStatus = ({category}) => {

    const key = {
        New:'learner_ongoing_courses_list',
        Completed:'learner_completed_courses_list',
        Failed:'learner_failed_courses_list'
    };


    const history = useHistory()
    const dispatch = useDispatch();
    const response = useSelector(state => state.learnercourse[key[category]]?.response);
    const loading = useSelector(state => state?.learnercourse[key[category]]?.loading);
    const [state, setState] =useState({
        courses:[],
        count:response?.count,
        paginationItem:[]
    });
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  

    // const options = [
    //     { value: 'new', label: 'New' },
    //     { value: 'in_progress', label: 'In progress' },
    //     { value: 'completed', label: 'Completed' },
    //     { value: 'failed', label: 'Failed' }
    // ];
 

    useEffect(() => {
        getLearnerCoursesList();
    }, [category]);

    const getLearnerCoursesList = (search='',index='') => {
        if(category === 'New'){
            dispatch(getLearnerOngoingCoursesList(search,index));
        } else if(category === 'Completed'){
            dispatch(getLearnerCompletedCoursesList(search,index));
        } else  {
            dispatch(getLearnerFailedCoursesList(search,index));
        }
    };


    useEffect(() => {
        const count = response?.count
        const perPage = 12;
        const buttonsCount = Math.ceil(count / perPage);
        let ellipsisLeft = false;
        let ellipsisRight = false;
        let items = []
        for (let i = 1; i <= Number(buttonsCount); i++) {
            if (i === activePage) {
                items.push(
                    {id: i, current: true, ellipsis: false}
                );
            } else {
                if (i < 10 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
                    items.push(
                        {id: i, current: false, ellipsis: false}
                    );
                } else if (i > 1 && i < activePage && !ellipsisLeft) {
                    items.push(
                        {id: i, current: false, ellipsis: true}
                    );
                    ellipsisLeft = true;
                } else if (i < buttonsCount && i > activePage && !ellipsisRight) {
                    items.push(
                        {id: i, current: false, ellipsis: true}
                    );
                    ellipsisRight = true;

                }
            }

        }
        setState({...state, courses: response, count: response?.count, paginationItem: items});
    }, [response]);

    const debouncedGetSearch = useCallback(debounce((value) => {
        setActivePage(1);
        getLearnerCoursesList(value)
    },1000),[]);

    const searchText = (e) => {
        setSearch(e.target.value);
        debouncedGetSearch(e.target.value);
    };


    const reset = () => {
        setSearch('');
        getLearnerCoursesList( '', '');
        setActivePage(1);
    };

    const handleNext = () => {

        if (state.courses?.next) {
            // var regex = /page=\d+/g;
            // const [number ] = state.courses?.next?.match(regex);
            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
            // dispatch(handlePaginationLearnerCourse(state.courses?.next));
            getLearnerCoursesList(search ? search : '', activePage + 1);
        } else {
            return false;
        }

    };

    const handlePrevious = () => {
        if (state.courses?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            // dispatch(handlePreviousPaginationLearnerCourse(state.courses?.previous))
            getLearnerCoursesList(search ? search : '', activePage - 1);
        } else {
            return false;
        }
    };

    const handleIndex = (index) =>{
        if(index == 1){
           getLearnerCoursesList(search ? search : '', index);
        }else{
           getLearnerCoursesList(search ? search : '', index);
        }
        setActivePage(index);
    };


    const handleCardClick  =(item)=>{
        if(category !== 'Failed'){
            history.push(`/learnercoursedetails/${item?.id}`);
        }
    };



    return (
        <div className="learner-accordian-wrap datatble-wrap ">
            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative search-icon-left">
                        <button className="iconbutton search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                                <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                            </svg>
                        </button>
                        <input type="text" placeholder="Search using keyword" value={search}
                               onChange={searchText}
                               className="form-control input-item" />
                        <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                    </div>
                        <OverlayTrigger
                        overlay={
                            <Tooltip id="InfoText">
                                Help
                            </Tooltip>
                            }
                        >
                            <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                            </span>
                        </OverlayTrigger>
                </div>


            </div>

            <div className="courseslisting">
                <Row>
                    {loading && loading ?
                        (
                            <>
                                <div className='loaderCourses'>
                                    <Spinner animation="border" variant="danger"/>
                                </div>

                            </>


                        ):(
                            <>

                                { !loading && state.courses && state.courses && state.courses?.results && state.courses?.results.length ? state.courses?.results?.map((el,index)=>(
                                        <Col key={index} style={{cursor:category !== 'Failed' ? 'pointer' : 'auto'}}  lg={4} xl={3} className="m-b-30 course-box-outer">

                                            <div className="common-card coursecard" onClick={()=>handleCardClick(el)}>
                                                <div className="course-box-top">
                                                    <div className="course-listing-img">
                                                        <img src={el?.banner_image ? el?.banner_image :CourseDummy} alt="" />
                                                    </div>
                                                    <div className="common-card-detail-wrap box-listing">
                                                        <h4>{el?.course_name}</h4>
                                                        <p>{el?.description?.substring(0, 120) + ' '  +  (el?.description?.length > 120 ?  '...' : '')}</p>
                                                    </div>
                                                    <div className="coommon-card-det-number d-flex justify-content-between">
                                                        <span className="common-card-oth-det-left">Status <span className={el?.status === "New" ? "det-numb text-danger" : "det-numb"} >{el?.status}</span> 
                                                        </span>
                                                    </div>
                                                </div>
                                                { category !== 'Failed' && (
                                                     <div className="box-overlay">
                                                        <span className="overlay-viewmore">View More<i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                                                        <div className="overlay-bg">&nbsp;</div>
                                                    </div>
                                                )}
                                               

                                            </div>



                                        </Col>
                                    ))
                                    : (<>
                                       { search ? <span className='noData'>No matching results found</span> : <span className='noData'>{category === 'New' ? 'No Assignment has been enrolled to you yet, kindly contact your organisation.' : category === 'Completed' ? 'No Assignment has been completed by you yet':'No Assignment due date has been lapsed from your end'}</span>}

                                        </> )

                                }

                            </>


                        )

                    }
                </Row>
                {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response || state?.courses?.previous ?
                <div className="d-flex justify-content-center">
                    <Pagination size="sm" className="custom-pagination">
                        <Pagination.First disabled={!state?.courses?.previous} className={`${state?.courses?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious}/>
                        {state.paginationItem.map(page => {
                            if(!page.ellipsis) {
                                return <li key={page.id}>
                                    <Pagination.Item
                                        onClick={()=>handleIndex(page.id)} key={page.id} active={page.id == activePage}
                                    >
                                        {page.id}
                                    </Pagination.Item>
                                </li>
                            }else {
                                return <Pagination.Ellipsis></Pagination.Ellipsis>
                            }
                        })}
                        <Pagination.Last disabled={!state?.courses?.next} onClick={handleNext} className={`${state?.courses?.next ? 'paginationStyle' :''} `}/>

                    </Pagination>

                </div>
                    :
                    ''
                }
            </div>
            <Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
               <Modal.Body>
                     <div className="module-modal-header my-4"><h4> Courses you are assigned will appear here.  </h4></div>
                    <ol className="info-list">
                        
                    <li><span>The “New/In Progress” tab displays courses that should be completed before the posted due date. </span></li>
                    <li><span>The “Completed” tab displays all courses completed by the posted due date.</span></li>
                    <li><span>The “Failed” tab displays courses completed after the posted due date. </span></li>
               
                    </ol>
                    
                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>

        </div>
    );
};

export default LearnerCourseListingWithStatus;
