import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Logo from '~/assets/images/logo.png';
import {Alert} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {applyCoupon, createSubscriptionPlan, clearState, resetStore} from '../../services';
import {signInSuccess,signInStart} from '../../services'
import Modal from 'react-bootstrap/Modal';
import Stripe from '../../stripePayment';
import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import NewStripe from  '../../newstripeelment/index'
import {useHistory} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function MyVerticallyCenteredModal(props) {
    return (
        <Modal  className="custom-modal1 confirmation-popup stripe-popup"
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered

        >
            <Modal.Body>
                <div className="stripe-custom-design">
                    {/*<Stripe amount={props.amount} handleCreatePlan={props.handleCreatePlan} handleGoNext={() => props.onHide()}/>*/}
                    <NewStripe plan ={props.plan} amount={props.amount} coupon={props.coupon} handleCreatePlan={props.handleCreatePlan}  handleGoNext={() => props.onHide()}/>
                    <div className="m-t-20 d-flex justify-content-center"><button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                        need to recheck
                    </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const CheckoutOnSignupMain = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [coupon_res, setcoupon_res] = useState({})
    const stripe = useStripe();
    const {user, signUpError, planSubscribed,stripe_payment_intent} = useSelector(state => state.auth);
    const response = useSelector(state => state.auth.userRegistered?.response);
    const loading = useSelector(state => state.auth.userRegistered?.loading);
    const selected_plan = useSelector(state => state.auth.selected_plan?.response);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const couponloading = useSelector(state => state.auth.selected_plan?.loading);
    const [subscribed, setSubscribed] = useState(response)
    const [coupon, setCoupon] = useState('');
    const [modalShow, setModalShow] = React.useState(false);

    // useEffect(() => {
    //     if (!stripe) {
    //         return;
    //     }
    //
    //     const clientSecret = selected_plan?.client_secret
    //
    //     if (!clientSecret) {
    //         return;
    //     }
    //
    //     stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //         switch (paymentIntent.status) {
    //             case "succeeded":
    //                 toast.success('Plan has been successfully subscribed')
    //                 setMessage("Payment succeeded!");
    //                 break;
    //             case "processing":
    //                 setMessage("Your payment is processing.");
    //                 break;
    //             case "requires_payment_method":
    //                 toast.success('Your payment was not successful, please try again.')
    //                 setMessage("Your payment was not successful, please try again.");
    //                 break;
    //             default:
    //                 toast.success('Your payment was not successful, please try again.')
    //                 setMessage("Something went wrong.");
    //                 break;
    //         }
    //     });
    // }, [stripe]);



    const handleCoupon = () => {
        if (coupon && coupon !== '') {
            dispatch(applyCoupon({price_id: user?.plan_stripe_id, coupon_code: coupon,customer_id:selected_plan.customer},function (res){
                if(res){

                    // setcoupon_res(res)
                }

            }));
        }
    };
    const handleModal = () => {
        setModalShow(true);
    };
    useEffect(() => {
        if (coupon_res?.data?.discount_amount) {
            setCoupon('');
        }
    }, [coupon_res]);
    useEffect(()=>{
        setSubscribed(response)
    },[response])
    const handleNext = () => {
        history.push('/subscriptiononsignup');
    };
    // const handleLoader = (bool) =>{
    //     setTimeout(()=>{
    //         setLoader(bool)
    //     },9000)
    //
    //
    // }

    useEffect(()=>{
        
        if (user?.is_subscribed) {
            history.push('/my-assignments');
        } 

    },[user])


    const handleCreatePlan = () => {

        setLoader(true)

        setTimeout(()=>{
            dispatch(createSubscriptionPlan(user.institution_id,function (res){
                if(res?.is_subscribed){
                    
                    let response = {data:{...user,...res}}
                    dispatch(signInSuccess(response));
                    setLoader(false)
                    toast.success('Subscription has been updated successfully')
                }else {
                    toast.error('Something went wrong! \n Please try again')
                    setLoader(false)
                    // history.push('/login')
                }

            }),function (err){
                if(err){
                    setLoader(false)
                }
            });
        },5000)



        // setTimeout(() => {
        //     dispatch(clearState("error"));
        //     // setLoader(false)
        // }, 9000);


        // handleLoader(false)
    }


    return (
        <>
            {loader &&
            <div className='loader-full-screen'>
                <div className='loader'>
                    <Spinner animation="border" variant="danger"/>
                </div>
            </div>
            }
            <div className="auth-page-wrap auth-bg fixed-footer-auth d-flex justify-content-center align-items-center">
                <div className="checkout-page-wrap">

                    <div className="text-center">
                        <div className="auth-logo"><img src={Logo} alt=""/></div>

                        {/*{*/}
                        {/*    planSubscribed && <Alert variant={"success"}>Plan successfully subscribed</Alert>*/}
                        {/*}*/}
                        <h2 className="auth-title">Checkout</h2>
                    </div>
                    <div className="checkout-order-boxes-wrap">
                        <ToastContainer autoClose={2000} />
                        <h3 className="subtitle">Order Details</h3>
                        <Row className="checkout-order-box-row">
                            <Col xs={12} sm={7}>
                                <div className="order-detail-box white-box radius-box">
                                    <h4 className="box-subhead">User Details</h4>
                                    <div className="order-detail-grey-box">
                                        <div className="order-detail-cont-row d-flex">
                                            <span className="order-det-name">Name</span>
                                            <span className="order-det-coulumn">:</span>
                                            <span className="order-det-values">{user?.first_name} {user?.last_name}</span>
                                        </div>
                                        <div className="order-detail-cont-row d-flex">
                                            <span className="order-det-name">Email</span>
                                            <span className="order-det-coulumn">:</span>
                                            <span className="order-det-values">{user?.email}</span>
                                        </div>
                                        <div className="order-detail-cont-row d-flex">
                                            <span className="order-det-name">Company</span>
                                            <span className="order-det-coulumn">:</span>
                                            <span className="order-det-values">{user?.institution}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="order-detail-box white-box radius-box">
                                    <h4 className="box-subhead">Subscription Details</h4>
                                    <div className="order-detail-grey-box">
                                        <div className="order-detail-cont-row d-flex">
                                            <span className="order-det-name">Plan</span>
                                            <span className="order-det-coulumn">:</span>
                                            <span className="order-det-values">{selected_plan?.plan}</span>
                                        </div>
                                        <div className="order-detail-cont-row d-flex">
                                            <span className="order-det-name">Pricing</span>
                                            <span className="order-det-coulumn">:</span>
                                            <span className="order-det-values">
                      <span className="subs-det-pricing">${selected_plan?.actual_amount}</span>
                                                {/*<span className="subs-det-strikethrough">$25</span>*/}
                                                {/*<span className="subs-det-pricing-desc">(Coupon code applied)</span>*/}
                    </span>
                                        </div>
                                        <div className="order-detail-cont-row d-flex">
                                            <span className="order-det-name">Company</span>
                                            <span className="order-det-coulumn">:</span>
                                            <span className="order-det-values">{user?.institution}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={5}>
                                <div className="order-detail-box white-box radius-box">
                                    <div className="coupon-box">
                                        <div className="coupon-input-box">
                                            <label className="input-label">Coupon Code (Optional)</label>
                                            <div className="coupon-input-wrap p-relative">
                                                <input type="text" placeholder="Enter Code" value={coupon}
                                                       onChange={(e => setCoupon(e.target.value))}
                                                       className="form-control input-item"/>
                                                <Button onClick={handleCoupon}
                                                        className="apply-input-btn transparent-btn">{couponloading && couponloading ?
                                                    <Spinner animation="border" variant="danger"/>
                                                    :
                                                    'Apply'
                                                }
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="cart-box">
                                            <div className="order-detail-cont-row d-flex">
                                                <span className="order-det-name">Sub-Total</span>
                                                <span className="order-det-coulumn">:</span>
                                                <span className="order-det-values">${selected_plan?.actual_amount}</span>
                                            </div>
                                            <div className="order-detail-cont-row d-flex">
                                                <span className="order-det-name">Discounts</span>
                                                <span className="order-det-coulumn">:</span>
                                                <span className="order-det-values">${selected_plan?.discount_data?.discounted_amount || 0}</span>
                                            </div>
                                            <div className="cart-footer">
                                                <div className="order-detail-cont-row d-flex">
                                                    <span className="order-det-name">Total</span>
                                                    <span className="order-det-coulumn">:</span>
                                                    <span
                                                        className="order-det-values">${selected_plan?.final_amount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="checkout-footer">
                    <div className="checkout-btns-wrap">
                        <Button className="" onClick={handleNext}>Back</Button>
                        <Button onClick={handleModal}>Proceed to Payment</Button>
                    </div>
                </div>
                <MyVerticallyCenteredModal
                    show={modalShow}

                    amount={selected_plan?.actual_amount}
                    coupon ={coupon_res?.data?.coupon ? coupon_res?.data?.coupon : null}
                    handleCreatePlan={handleCreatePlan}
                    plan={user?.stripe_id}
                    onHide={() => setModalShow(false)}
                />
            </div>

        </>
    );
};

export default CheckoutOnSignupMain;
