/* eslint-disable */
import React, { PureComponent, useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch, useSelector } from 'react-redux';
import {getProfile, updateProfileImage} from "../../../../../../profile/services";
const CropCustom = (props) => {
    const {values,index,setFieldValue,name,moduleErrors,moduleTouched,handleBlur,onHide} = props
    const dispatch = useDispatch();
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 25 / 16 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [roundImage, setRoundImage]=useState(null);
    const[fileTypeError,setFileError]=useState(false)
    const[fileSizeError,setFileSizeError]=useState(false)   ;


    const onSelectFile = (e) => {
        const fsize = e.target.files[0].size;
        const fileinMB= Math.round((fsize / 1024));
        if ( fileinMB >= 5120) {
            setFileSizeError(true)
            setFileError(false)
        }

        else{
            setFileSizeError(false)
            const getFileExtension=e.target.files[0].name.split('.').pop();
            if (!(getFileExtension==='jpg'|| getFileExtension==='jpeg'|| getFileExtension==='png'))        //         {
            {
                setFileError(true)
            }

            else{
                setFileError(false)
                if (e.target.files && e.target.files.length > 0) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => setUpImg(reader.result));
                    reader.readAsDataURL(e.target.files[0]);
                }
            }
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }


        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        canvas.toBlob(blob => {
             const image = imgRef?.current?.src && imgRef?.current?.src?.length ? imgRef?.current?.src  : 'png'
            const type = image?.split(';')[0].split('/')[1];
            blob.name = "img";
            const blobFile = new File([blob],`image.${type}`,{ type: `image/${type == 'jpeg' ? 'jpg' :type}` });
            setRoundImage(blobFile)
        }, 'image/png', 1);


    }, [completedCrop]);


    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const imageUpload =() =>{

        getBase64(roundImage).then(
            data => setFieldValue(name,data)
        );
        onHide();

    };

    return (
        <div className="cropp-wrapper">
            <div className="prof-file-upload-wrap">
                <input type="file"   accept="image/png, image/jpeg,image/jpg"
                       onChange={onSelectFile} id="file" className="file prof-file-upload" />
                <label htmlFor="file">Choose file</label>
                {fileTypeError ?   <span  style={{  color:'#ce002a' }} >Select png or jpeg images only</span>
                    :''}
                {fileSizeError ?   <span  style={{  color:'#ce002a' }} >File too big, please select a file less than 5MB</span>
                    :''}

            </div>
            <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
            />
            <div className="cropped-image-view">
                {completedCrop &&  (
                    <div>
                        <div className="cropped-image">

                            <canvas
                                ref={previewCanvasRef}
                                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                style={{
                                    maxWidth: '100%'
                                }}
                            />

                        </div>
                        <div className="cropped-savebutton"><button type="submit" className="btn btn-dark" onClick={imageUpload} >Save</button></div>
                    </div>
                )}
            </div>

        </div>
    );
}

export default CropCustom;
