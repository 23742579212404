import { createAction, handleActions } from "redux-actions";
import ActionTypes from "~shared/constants/actionTypes";
import {
  _getManagerQuizzesList,
  _getManagerDraftQuizzes,
  _nextAvailableManagerQuiz,
  _previousAvailableManagerQuiz,
  _nextDraftManagerQuiz,
  _previousDraftManagerQuiz,
  _deleteQuiz,
  _addQuiz,
  _updateQuiz,
  _assignQuiz,
  _deleteQuizQuestion,
  _getManagerQuiz,
  _addQuizQuestion,
  _updateQuizQuestion,
  _deleteQuizChoice,
  _getAllQuizPaths,
  _getDeletedQuiz,
  _deleteQuizForever,
  _quizDeletedRestore,
  _restoreMultipleQuiz,
  _deleteMultipleQuiz,
  _deleteNextQuiz,
  _deletePreviousQuiz,
} from "~shared/httpService/api";
import { toast } from "react-toastify";

const initialState = {
  quizz_listing: { loading: false, response: {}, hasError: false, error: {} },
  quizz_listing_draft: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  newly_added_quiz: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  newly_added_quiz_question: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  updated_quiz_question: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  updated_quiz: { loading: false, response: {}, hasError: false, error: {} },
  deleted_quiz: { loading: false, response: {}, hasError: false, error: {} },
  deleted_quiz_question: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  assign_quiz: { loading: false, response: {}, hasError: false, error: {} },
  deleted_question_choice: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  get_quiz_manager: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  get_quiz_paths: { loading: false, response: {}, hasError: false, error: {} },
  deleted_quiz_list: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  delete_quiz_forever: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  quiz_restore: { loading: false, response: {}, hasError: false, error: {} },
  quiz_all_restore: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  deleted_all_quiz: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
};

export const getQuizzesManagerStart = createAction(
  ActionTypes.GET_QUIZZES_MANAGER_LIST
);
export const getQuizzesManagerSuccess = createAction(
  ActionTypes.GET_QUIZZES_MANAGER_LIST_SUCCESS,
  (response) => response
);
export const getQuizzesManagerError = createAction(
  ActionTypes.GET_QUIZZES_MANAGER_LIST_ERROR,
  (error) => error
);

export const getManagerQuizzesList =
  (search, startDate, endDate, createdby, page) => (dispatch) => {
    dispatch(getQuizzesManagerStart());
    return _getManagerQuizzesList(search, startDate, endDate, createdby, page)
      .then((response) => {
        dispatch(getQuizzesManagerSuccess(response));
      })
      .catch((error) => {
        dispatch(getQuizzesManagerError(error));
      });
  };

export const getDraftQuizzesManagerStart = createAction(
  ActionTypes.GET_DRAFT_MANAGER_QUIZZES
);
export const getDraftQuizzesManagerSuccess = createAction(
  ActionTypes.GET_DRAFT_MANAGER_QUIZZES_SUCCESS,
  (response) => response
);
export const getDraftQuizzesManagerError = createAction(
  ActionTypes.GET_DRAFT_MANAGER_QUIZZES_ERROR,
  (error) => error
);

export const getDraftQuizzesManagerList =
  (search, startDate, endDate, createdby, page) => (dispatch) => {
    dispatch(getDraftQuizzesManagerStart());
    return _getManagerDraftQuizzes(search, startDate, endDate, createdby, page)
      .then((response) => {
        dispatch(getDraftQuizzesManagerSuccess(response));
      })
      .catch((error) => {
        dispatch(getDraftQuizzesManagerError(error));
      });
  };

export const paginationAvailableQuizzesStart = createAction(
  ActionTypes.MANAGER_QUIZ_PAGINATION_NEXT
);
export const paginationAvailableQuizzesSuccess = createAction(
  ActionTypes.MANAGER_QUIZ_PAGINATION_NEXT_SUCCESS,
  (response) => response
);
export const paginationAvailableQuizzesError = createAction(
  ActionTypes.MANAGER_QUIZ_PAGINATION_NEXT_ERROR,
  (error) => error
);

export const handlePaginationAvailableQuizzes = (url) => (dispatch) => {
  dispatch(paginationAvailableQuizzesStart());
  return _nextAvailableManagerQuiz(url)
    .then((response) => {
      dispatch(paginationAvailableQuizzesSuccess(response));
    })
    .catch((error) => {
      dispatch(paginationAvailableQuizzesError(error));
      toast.error("Something went wrong");
    });
};

export const paginationPreviousAvailableQuizzesStart = createAction(
  ActionTypes.MANAGER_QUIZ_PAGINATION_PREVIOUS
);
export const paginationPreviousAvailableQuizzesSuccess = createAction(
  ActionTypes.MANAGER_QUIZ_PAGINATION_PREVIOUS_SUCCESS,
  (response) => response
);
export const paginationPreviousAvailableQuizzesError = createAction(
  ActionTypes.MANAGER_QUIZ_PAGINATION_PREVIOUS_ERROR,
  (error) => error
);

export const handlePreviousPaginationAvailableQuizzes = (url) => (dispatch) => {
  dispatch(paginationPreviousAvailableQuizzesStart());
  return _previousAvailableManagerQuiz(url)
    .then((response) => {
      dispatch(paginationPreviousAvailableQuizzesSuccess(response));
    })
    .catch((error) => {
      dispatch(paginationPreviousAvailableQuizzesError(error));
      toast.error("Something went wrong");
    });
};

//Draft
export const paginationDraftQuizzesStart = createAction(
  ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_NEXT
);
export const paginationDraftQuizzesSuccess = createAction(
  ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_NEXT_SUCCESS,
  (response) => response
);
export const paginationDraftQuizzesError = createAction(
  ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_NEXT_ERROR,
  (error) => error
);

export const handlePaginationDraftCourse = (url) => (dispatch) => {
  dispatch(paginationDraftQuizzesStart());
  return _nextDraftManagerQuiz(url)
    .then((response) => {
      dispatch(paginationDraftQuizzesSuccess(response));
    })
    .catch((error) => {
      dispatch(paginationDraftQuizzesError(error));
      toast.error("Something went wrong");
    });
};

export const paginationPreviousDraftQuizzesStart = createAction(
  ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS
);
export const paginationPreviousDraftQuizzesSuccess = createAction(
  ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS_SUCCESS,
  (response) => response
);
export const paginationPreviousDraftQuizzesError = createAction(
  ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS_ERROR,
  (error) => error
);

export const handlePreviousPaginationDraftCourse = (url) => (dispatch) => {
  dispatch(paginationPreviousDraftQuizzesStart());
  return _previousDraftManagerQuiz(url)
    .then((response) => {
      dispatch(paginationPreviousDraftQuizzesSuccess(response));
    })
    .catch((error) => {
      dispatch(paginationPreviousDraftQuizzesError(error));
      toast.error("Something went wrong");
    });
};

export const addQuizStart = createAction(ActionTypes.ADD_QUIZ);
export const addQuizSuccess = createAction(
  ActionTypes.ADD_QUIZ_SUCCESS,
  (response) => response
);
export const addQuizError = createAction(
  ActionTypes.ADD_QUIZ_ERROR,
  (error) => error
);

export const addQuiz = (formData, callback) => (dispatch) => {
  dispatch(addQuizStart());

  return _addQuiz(formData)
    .then((response) => {
      if (response) {
        toast.success("Quiz detail has been created Successfully");
        callback(response);
      }
      dispatch(addQuizSuccess(response));
    })
    .catch((error) => {
      dispatch(addQuizError(error));

      if (error) {
        if (error.image) {
          toast.error(error.image[0]);
        } else {
          toast.error("Something went wrong");
        }
      }
    });
};

export const addQuizQuestionStart = createAction(ActionTypes.ADD_COURSE_MODULE);
export const addQuizQuestionSuccess = createAction(
  ActionTypes.ADD_COURSE_MODULE_SUCCESS,
  (response) => response
);
export const addQuizQuestionError = createAction(
  ActionTypes.ADD_COURSE_MODULE_ERROR,
  (error) => error
);

export const addQuizQuestion = (formData, id, callback) => (dispatch) => {
  dispatch(addQuizQuestionStart());

  return _addQuizQuestion(formData, id)
    .then((response) => {
      if (response) {
        callback(response);
        toast.success("Questions has been  added  successfully");
      }
      dispatch(addQuizQuestionSuccess(response));
    })
    .catch((error) => {
      dispatch(addQuizQuestionError(error));
      if (error?.template) {
        toast.error(error?.template[0]);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const updateQuizQuestionStart = createAction(
  ActionTypes.UPDATE_QUESTIONS_QUIZ
);
export const updateQuizQuestionSuccess = createAction(
  ActionTypes.UPDATE_QUESTIONS_QUIZ_SUCCESS,
  (response) => response
);
export const updateQuizQuestionError = createAction(
  ActionTypes.UPDATE_QUESTIONS_QUIZ_ERROR,
  (error) => error
);

export const updateQuizQuestion = (formData, id, callback) => (dispatch) => {
  dispatch(updateQuizQuestionStart());

  return _updateQuizQuestion(formData, id)
    .then((response) => {
      dispatch(updateQuizQuestionSuccess(response));
      if (response) {
        toast.success("Questions  has been  updated  successfully");
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(updateQuizQuestionError(error));
      if (error?.template) {
        toast.error(error?.template[0]);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const updateQuizStart = createAction(ActionTypes.UPDATE_QUIZ);
export const updateQuizSuccess = createAction(
  ActionTypes.UPDATE_QUIZ_SUCCESS,
  (response) => response
);
export const updateQuizError = createAction(
  ActionTypes.UPDATE_QUIZ_ERROR,
  (error) => error
);

export const updateQuiz = (formData, id, callback) => (dispatch) => {
  dispatch(updateQuizStart());

  return _updateQuiz(formData, id)
    .then((response) => {
      if (response) {
        callback(response);
        toast.success("Quiz detail has been updated Successfully");
      }
      dispatch(updateQuizSuccess(response));
    })
    .catch((error) => {
      dispatch(v(error));
      if (error) {
        if (error.image) {
          toast.error(error.image[0]);
        }
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const deleteQuizStart = createAction(ActionTypes.DELETE_COURSE);
export const deleteQuizSuccess = createAction(
  ActionTypes.DELETE_COURSE_SUCCESS,
  (response) => response
);
export const deleteQuizError = createAction(
  ActionTypes.DELETE_COURSE_ERROR,
  (error) => error
);

export const deleteQuiz = (row, history) => (dispatch) => {
  dispatch(deleteQuizStart());

  return _deleteQuiz(row)
    .then((response) => {
      dispatch(deleteQuizSuccess(row));
      toast.success("Quiz has been  deleted  successfully");
    })
    .catch((error) => {
      dispatch(deleteQuizError(error));
      toast.error("Something went wrong");
    });
};
export const deleteQuizQuestionStart = createAction(
  ActionTypes.DELETE_QUIZ_QUESTION
);
export const deleteQuizQuestionSuccess = createAction(
  ActionTypes.DELETE_QUIZ_QUESTION_SUCCESS,
  (response) => response
);
export const deleteQuizQuestionError = createAction(
  ActionTypes.DELETE_QUIZ_QUESTION_ERROR,
  (error) => error
);

export const deleteQuizQuestion =
  (courseId, moduleId, callback) => (dispatch) => {
    dispatch(deleteQuizQuestionStart());
    return _deleteQuizQuestion(courseId, moduleId)
      .then((response) => {
        dispatch(deleteQuizQuestionSuccess(response));
        callback("response");
        toast.success("Quiz Question has been  deleted  successfully");
      })
      .catch((error) => {
        dispatch(deleteQuizQuestionError(error));
        callback("error");
        toast.error("Something went wrong");
      });
  };

export const assignQuizStart = createAction(ActionTypes.ASSIGN_QUIZ);
export const assignQuizSuccess = createAction(
  ActionTypes.ASSIGN_QUIZ_SUCCESS,
  (response) => response
);
export const assignQuizError = createAction(
  ActionTypes.ASSIGN_QUIZ_ERROR,
  (error) => error
);

export const assignQuiz = (data, userId, history, callback) => (dispatch) => {
  dispatch(assignQuizStart());
  return _assignQuiz(data, userId)
    .then((response) => {
      if (response) {
        toast.success("Quiz has been  assigned  successfully");
        history.push("/coursemanagement");
      }
      dispatch(assignQuizSuccess(response));
      if (response) {
        toast.success("Quiz Assigned");
      }
    })
    .catch((error) => {
      dispatch(assignQuizError(error));
      if (error) {
        // console.log(error)
        // console.log(error?.error.errorMessage)
        toast.error(error?.error.errorMessage);
        callback(error);
      }
    });
};

export const deleteQuizChoiceStart = createAction(
  ActionTypes.DELETE_COURSE_CHOICE
);
export const deleteQuizChoiceSuccess = createAction(
  ActionTypes.DELETE_COURSE_CHOICE_SUCCESS,
  (response) => response
);
export const deleteQuizChoiceError = createAction(
  ActionTypes.DELETE_COURSE_CHOICE_ERROR,
  (error) => error
);

export const deleteQuizChoice = (choiceId, callback) => (dispatch) => {
  dispatch(deleteQuizChoiceStart());
  return _deleteQuizChoice(choiceId)
    .then((response) => {
      dispatch(deleteQuizChoiceSuccess(response));
      callback("response");
      toast.success("Choice has been  deleted  successfully");
    })
    .catch((error) => {
      dispatch(deleteQuizChoiceError(error));
      callback("error");
      toast.error("Something went wrong");
    });
};

export const getQuizManagerStart = createAction(
  ActionTypes.GET_MANAGER_QUIZ_DETAIL
);
export const getQuizManagerSuccess = createAction(
  ActionTypes.GET_MANAGER_QUIZ_DETAIL_SUCCESS,
  (response) => response
);
export const getQuizManagerError = createAction(
  ActionTypes.GET_MANAGER_QUIZ_DETAIL_ERROR,
  (error) => error
);

export const getManagerQuiz = (id, callback) => (dispatch) => {
  dispatch(getQuizManagerStart());
  return _getManagerQuiz(id)
    .then((response) => {
      if (response) {
        callback(response);
      }
      dispatch(getQuizManagerSuccess(response));
    })
    .catch((error) => {
      dispatch(getQuizManagerError(error));
    });
};

export const getAllQuizPathsStart = createAction(ActionTypes.GET_ALL_QUIZ);
export const getAllQuizPathsSuccess = createAction(
  ActionTypes.GET_ALL_QUIZ_SUCCESS,
  (response) => response
);
export const getAllQuizPathsError = createAction(
  ActionTypes.GET_ALL_QUIZ_ERROR,
  (error) => error
);

export const getAllQuizPaths = () => (dispatch) => {
  dispatch(getAllQuizPathsStart());
  return _getAllQuizPaths()
    .then((response) => {
      dispatch(getAllQuizPathsSuccess(response));
    })
    .catch((error) => {
      dispatch(getAllQuizPathsError(error));
    });
};

// for Deleted Quizzes

export const getDeletedQuizStart = createAction(
  ActionTypes.GET_DELETED_QUIZZES_LISTING
);
export const getDeletedQuizSuccess = createAction(
  ActionTypes.GET_DELETED_QUIZZES_LISTING_SUCCESS,
  (response) => response
);
export const getDeletedQuizError = createAction(
  ActionTypes.GET_DELETED_QUIZZES_LISTING_ERROR,
  (error) => error
);

export const getDeletedQuiz =
  (search, startDate, endDate, createdby, category, page) => (dispatch) => {
    dispatch(getDeletedQuizStart());

    return _getDeletedQuiz(
      search,
      startDate,
      endDate,
      createdby,
      category,
      page
    )
      .then((response) => {
        dispatch(getDeletedQuizSuccess(response));
      })
      .catch((error) => {
        dispatch(getDeletedQuizError(error));
      });
  };

// for Deleted Quiz forever
export const deleteQuizForeverStart = createAction(
  ActionTypes.DELETE_QUIZ_FOREVER
);
export const deleteQuizForeverSuccess = createAction(
  ActionTypes.DELETE_QUIZ_FOREVER_SUCCESS,
  (response) => response
);
export const deleteQuizForeverError = createAction(
  ActionTypes.DELETE_QUIZ_FOREVER_ERROR,
  (error) => error
);

export const deleteQuizForever = (data) => (dispatch) => {
  dispatch(deleteQuizForeverStart());
  return _deleteQuizForever(data)
    .then((response) => {
      dispatch(deleteQuizForeverSuccess(response));
      toast.success("Quiz has been  deleted  successfully");
    })
    .catch((error) => {
      dispatch(deleteQuizForeverError(error));
      toast.error("Something went wrong");
    });
};

// for restore deleted Quiz
export const restoreQuizStart = createAction(ActionTypes.RESTORE_DELETED_QUIZ);
export const restoreQuizSuccess = createAction(
  ActionTypes.RESTORE_DELETED_QUIZ_SUCCESS,
  (response) => response
);
export const restoreQuizError = createAction(
  ActionTypes.RESTORE_DELETED_QUIZ_ERROR,
  (error) => error
);

export const restoreDeletedQuiz = (data) => (dispatch) => {
  dispatch(restoreQuizStart());
  return _quizDeletedRestore(data)
    .then((response) => {
      dispatch(restoreQuizSuccess(response));
      toast.success("Quiz has been  restore successfully");
    })
    .catch((error) => {
      dispatch(restoreQuizError(error));
      toast.error("Something went wrong");
    });
};

// for Multiple restore deleted Quiz
export const restoreMultipleQuizStart = createAction(
  ActionTypes.RESTORE_MULTIPLE_QUIZ
);
export const restoreMultiQuizSuccess = createAction(
  ActionTypes.RESTORE_MULTIPLE_QUIZ_SUCCESS,
  (response) => response
);
export const restoreMultipleQuizError = createAction(
  ActionTypes.RESTORE_MULTIPLE_QUIZ_ERROR,
  (error) => error
);

export const restoreMultipleQuiz = (data) => (dispatch) => {
  dispatch(restoreMultipleQuizStart());
  return _restoreMultipleQuiz(data)
    .then((response) => {
      dispatch(restoreMultiQuizSuccess(response));
      toast.success("Quiz has been  restored successfully");
    })
    .catch((error) => {
      dispatch(restoreMultipleQuizError(error));
      toast.error("Something went wrong");
    });
};
// for Multiple All deleted Quiz
export const deleteMultipleQuizStart = createAction(
  ActionTypes.DELETE_MULTIPLE_QUIZ
);
export const deleteMultiQuizSuccess = createAction(
  ActionTypes.DELETE_MULTIPLE_QUIZ_SUCCESS,
  (response) => response
);
export const deleteMultipleQuizError = createAction(
  ActionTypes.DELETE_MULTIPLE_QUIZ_ERROR,
  (error) => error
);

export const deleteMultipleQuiz = (data) => (dispatch) => {
  dispatch(deleteMultipleQuizStart());
  return _deleteMultipleQuiz(data)
    .then((response) => {
      dispatch(deleteMultiQuizSuccess(response));
      toast.success("Quiz has been deleted successfully");
    })
    .catch((error) => {
      dispatch(deleteMultipleQuizError(error));
      toast.error("Something went wrong");
    });
};

export const DeletePaginationStart = createAction(
  ActionTypes.DELETE_PAGINATION_NEXT
);
export const DeletePaginationSuccess = createAction(
  ActionTypes.DELETE_PAGINATION_NEXT_SUCCESS,
  (response) => response
);
export const DeletePaginationError = createAction(
  ActionTypes.DELETE_PAGINATION_NEXT_ERROR,
  (error) => error
);

export const handleDeletePagination = (url) => (dispatch) => {
  dispatch(DeletePaginationStart());
  return _deleteNextQuiz(url)
    .then((response) => {
      dispatch(DeletePaginationSuccess(response));
    })
    .catch((error) => {
      dispatch(DeletePaginationError(error));
      toast.error("Something went wrong");
    });
};

export const DeletePaginationPreviousStart = createAction(
  ActionTypes.DELETE_PAGINATION_PREVIOUS
);
export const DeletePaginationPreviousSuccess = createAction(
  ActionTypes.DELETE_PAGINATION_PREVIOUS_SUCCESS,
  (response) => response
);
export const DeletePaginationPreviousError = createAction(
  ActionTypes.DELETE_PAGINATION_PREVIOUS_ERROR,
  (error) => error
);

export const handleDeletePreviousPagination = (url) => (dispatch) => {
  dispatch(DeletePaginationPreviousStart());
  return _deletePreviousQuiz(url)
    .then((response) => {
      dispatch(DeletePaginationPreviousSuccess(response));
    })
    .catch((error) => {
      dispatch(DeletePaginationPreviousError(error));
      toast.error("Something went wrong");
    });
};

const reducer = handleActions(
  {
    //Get Manger Quizzes
    [ActionTypes.GET_QUIZZES_MANAGER_LIST]: (state) => ({
      ...state,
      quizz_listing: {
        ...state.quizz_listing,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_QUIZZES_MANAGER_LIST_SUCCESS]: (state, action) => ({
      ...state,

      quizz_listing: {
        ...state.quizz_listing,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_QUIZZES_MANAGER_LIST_ERROR]: (state, action) => ({
      ...state,

      quizz_listing: {
        ...state.quizz_listing,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //    Available Quiz Pagination Next
    [ActionTypes.MANAGER_QUIZ_PAGINATION_NEXT]: (state) => ({
      ...state,
      quizz_listing: {
        ...state.quizz_listing,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.MANAGER_QUIZ_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
      ...state,

      quizz_listing: {
        ...state.quizz_listing,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.MANAGER_QUIZ_PAGINATION_NEXT_ERROR]: (state, action) => ({
      ...state,

      quizz_listing: {
        ...state.quizz_listing,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    //    Available Quiz Pagination Previous
    [ActionTypes.MANAGER_QUIZ_PAGINATION_PREVIOUS]: (state) => ({
      ...state,
      quizz_listing: {
        ...state.quizz_listing,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.MANAGER_QUIZ_PAGINATION_PREVIOUS_SUCCESS]: (
      state,
      action
    ) => ({
      ...state,

      quizz_listing: {
        ...state.quizz_listing,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.MANAGER_QUIZ_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
      ...state,
      quizz_listing: {
        ...state.quizz_listing,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //Get Draft Manger Quizzes
    [ActionTypes.GET_DRAFT_MANAGER_QUIZZES]: (state) => ({
      ...state,
      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_DRAFT_MANAGER_QUIZZES_SUCCESS]: (state, action) => ({
      ...state,

      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_DRAFT_MANAGER_QUIZZES_ERROR]: (state, action) => ({
      ...state,

      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //    Draft Quiz Pagination Next
    [ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_NEXT]: (state) => ({
      ...state,
      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_NEXT_SUCCESS]: (
      state,
      action
    ) => ({
      ...state,

      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_NEXT_ERROR]: (
      state,
      action
    ) => ({
      ...state,

      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    //    Draft Quiz Pagination Previous
    [ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS]: (state) => ({
      ...state,
      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS_SUCCESS]: (
      state,
      action
    ) => ({
      ...state,

      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS_ERROR]: (
      state,
      action
    ) => ({
      ...state,

      quizz_listing_draft: {
        ...state.quizz_listing_draft,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    //ADD QUIZ
    [ActionTypes.ADD_QUIZ]: (state) => ({
      ...state,
      newly_added_quiz: {
        ...state.newly_added_quiz,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    [ActionTypes.ADD_QUIZ_SUCCESS]: (state, action) => ({
      ...state,

      newly_added_quiz: {
        ...state.newly_added_quiz,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.ADD_QUIZ_ERROR]: (state, action) => ({
      ...state,

      newly_added_quiz: {
        ...state.newly_added_quiz,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),
    //UpdATE QUIZ QUESTION

    [ActionTypes.UPDATE_QUESTIONS_QUIZ]: (state) => ({
      ...state,
      updated_quiz_question: {
        ...state.updated_quiz_question,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    [ActionTypes.UPDATE_QUESTIONS_QUIZ_SUCCESS]: (state, action) => ({
      ...state,

      updated_quiz_question: {
        ...state.updated_quiz_question,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.UPDATE_QUESTIONS_QUIZ_ERROR]: (state, action) => ({
      ...state,

      updated_quiz_question: {
        ...state.updated_quiz_question,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //Update Quiz
    [ActionTypes.UPDATE_QUIZ]: (state) => ({
      ...state,
      updated_quiz: {
        ...state.updated_quiz,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    [ActionTypes.UPDATE_QUIZ_SUCCESS]: (state, action) => ({
      ...state,

      updated_quiz: {
        ...state.updated_quiz,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.UPDATE_QUIZ_ERROR]: (state, action) => ({
      ...state,

      updated_quiz: {
        ...state.updated_quiz,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //Delete Quiz

    [ActionTypes.DELETE_QUIZ]: (state) => ({
      ...state,
      deleted_quiz: {
        ...state.deleted_quiz,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    [ActionTypes.DELETE_QUIZ_SUCCESS]: (state, action) => ({
      ...state,

      deleted_quiz: {
        ...state.deleted_quiz,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_QUIZ_ERROR]: (state, action) => ({
      ...state,

      deleted_quiz: {
        ...state.deleted_quiz,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //Delete QUiz Question

    [ActionTypes.DELETE_QUIZ_QUESTION]: (state) => ({
      ...state,
      deleted_quiz_question: {
        ...state.deleted_quiz_question,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    [ActionTypes.DELETE_QUIZ_QUESTION_SUCCESS]: (state, action) => ({
      ...state,

      deleted_quiz_question: {
        ...state.deleted_quiz_question,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_QUIZ_QUESTION_ERROR]: (state, action) => ({
      ...state,

      deleted_quiz_question: {
        ...state.deleted_quiz_question,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //ASSIGN_Quiz
    [ActionTypes.ASSIGN_QUIZ]: (state) => ({
      ...state,
      assign_quiz: {
        ...state.assign_quiz,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.ASSIGN_QUIZ_SUCCESS]: (state, action) => ({
      ...state,
      assign_quiz: {
        ...state.assign_quiz,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.ASSIGN_QUIZ_ERROR]: (state, action) => ({
      ...state,
      assign_quiz: {
        ...state.assign_quiz,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    //Delete QUIZ CHOICE

    [ActionTypes.DELETE_QUIZ_QUESTION_CHOICE]: (state) => ({
      ...state,
      deleted_question_choice: {
        ...state.deleted_question_choice,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),

    [ActionTypes.DELETE_QUIZ_QUESTION_CHOICE_SUCCESS]: (state, action) => ({
      ...state,

      deleted_question_choice: {
        ...state.deleted_question_choice,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_QUIZ_QUESTION_CHOICE_ERROR]: (state, action) => ({
      ...state,

      deleted_question_choice: {
        ...state.deleted_question_choice,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),
    //Get Manger Quiz
    [ActionTypes.GET_MANAGER_QUIZ_DETAIL]: (state) => ({
      ...state,
      get_quiz_manager: {
        ...state.get_quiz_manager,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MANAGER_QUIZ_DETAIL_SUCCESS]: (state, action) => ({
      ...state,

      get_quiz_manager: {
        ...state.get_quiz_manager,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MANAGER_QUIZ_DETAIL_ERROR]: (state, action) => ({
      ...state,

      get_quiz_manager: {
        ...state.get_quiz_manager,
        response: {},
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //GET_ALL_QUIZ_PATHS
    [ActionTypes.GET_MANAGER_QUIZ_LEARNING_PATHS]: (state) => ({
      ...state,
      get_quiz_paths: {
        ...state.get_quiz_paths,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MANAGER_QUIZ_LEARNING_PATHS_SUCCESS]: (state, action) => ({
      ...state,
      get_quiz_paths: {
        ...state.get_quiz_paths,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MANAGER_QUIZ_LEARNING_PATHS_ERROR]: (state, action) => ({
      ...state,
      get_quiz_paths: {
        ...state.get_quiz_paths,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    //Get Deleted Quizzes
    [ActionTypes.GET_DELETED_QUIZZES_LISTING]: (state) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_DELETED_QUIZZES_LISTING_SUCCESS]: (state, action) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_DELETED_QUIZZES_LISTING_ERROR]: (state, action) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    // for deleted quiz forever
    [ActionTypes.DELETE_QUIZ_FOREVER]: (state) => ({
      ...state,
      delete_quiz_forever: {
        ...state.delete_quiz_forever,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_QUIZ_FOREVER_SUCCESS]: (state) => ({
      ...state,
      delete_quiz_forever: {
        ...state.delete_quiz_forever,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_QUIZ_FOREVER_ERROR]: (state, action) => ({
      ...state,
      delete_quiz_forever: {
        ...state.delete_quiz_forever,
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    //restore deleted quiz
    [ActionTypes.RESTORE_DELETED_QUIZ]: (state) => ({
      ...state,
      quiz_restore: {
        ...state.quiz_restore,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.RESTORE_DELETED_QUIZ_SUCCESS]: (state) => ({
      quiz_restore: {
        ...state.quiz_restore,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.RESTORE_DELETED_QUIZ_ERROR]: (state, action) => ({
      ...state,
      quiz_restore: {
        ...state.quiz_restore,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    //restore multiple quiz
    [ActionTypes.RESTORE_MULTIPLE_QUIZ]: (state) => ({
      ...state,
      quiz_all_restore: {
        ...state.quiz_all_restore,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.RESTORE_MULTIPLE_QUIZ_SUCCESS]: (state) => ({
      quiz_all_restore: {
        ...state.quiz_all_restore,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.RESTORE_MULTIPLE_QUIZ_ERROR]: (state, action) => ({
      ...state,
      quiz_all_restore: {
        ...state.quiz_all_restore,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //delete multiple quiz
    [ActionTypes.DELETE_MULTIPLE_QUIZ]: (state) => ({
      ...state,
      deleted_all_quiz: {
        ...state.deleted_all_quiz,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_MULTIPLE_QUIZ_SUCCESS]: (state) => ({
      deleted_all_quiz: {
        ...state.deleted_all_quiz,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_MULTIPLE_QUIZ_ERROR]: (state, action) => ({
      ...state,
      deleted_all_quiz: {
        ...state.deleted_all_quiz,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //for pagination
    [ActionTypes.DELETE_PAGINATION_NEXT]: (state) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_PAGINATION_NEXT_ERROR]: (state, action) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),

    [ActionTypes.DELETE_PAGINATION_PREVIOUS]: (state) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        response: {},
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
      ...state,
      deleted_quiz_list: {
        ...state.deleted_quiz_list,
        loading: false,
        hasError: false,
        error: action.payload,
      },
    }),
  },
  initialState
);

export default reducer;
