import React, {useCallback, useEffect, useRef, useState,} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AccordianItem from './components/accordian/container/accordian'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ModulePopView from './components/learningmodulepopview/index'
import editIco from '~/assets/images/icons/edit.svg';
import deleteIco from '~/assets/images/icons/delete.svg';
import previewIco from '~/assets/images/icons/preview.svg';
import enrolIco from '~/assets/images/icons/enroll_users.svg';
import clonIcon from '~/assets/images/icons/copy.svg';
import DataTable from 'react-data-table-component';
import QuizSection from "./components/quizSection";
import CourseSection from "./components/courseSection";
import debounce from "lodash.debounce";
import {
   cloneLearningPath,
   deleteLearningPath,
   getManagerLearningPathDetail,
   getManagerLearningPathNodes,
   getManagerLearningPathNodesDetail,
   getManagerLearningPathLearners,
   getAdminLpNew,
   getManagerLearningPathLearnersWithPage
} from "../../services";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory,useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
// import { Popconfirm } from 'antd';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import ScrollArea from 'react-scrollbar';
import { Popconfirm } from 'antd';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


function ManagerLearingPathDetail(props) {
   const urlId = props?.match?.params?.id;
   const dispatch = useDispatch()
   const history = useHistory()

   const response = useSelector(state => state?.learningpath?.get_learning_path_manager_details?.response);
   // const adminResponse = useSelector(state => state.learningPath?.admin_course?.response);
   const loading = useSelector(state => state?.learningpath?.get_learning_path_manager_details?.loading);
   const adminResponse = useSelector(state => state?.learningpath?.admin_detail_lp?.response);
   const adminLoading = useSelector(state => state?.learningpath?.admin_detail_lp?.loading);
   const nodesresponse = useSelector(state => state?.learningpath?.list_nodes?.response);

   const nodesDetailloading = useSelector(state => state?.learningpath?.nodes_detail?.loading);


   const { user } = useSelector(state => state.auth);
   const [visible, setVisible] = React.useState(false);
   const [state, setState] = useState({
      course: {},
      popupView: false,
      defaultKey:'0'
   })
   const [key, setKey] = useState('0');
   const [loadingImage, setLoadingImage] = useState(false);
   const [learningPathnodes,setLearningPathNodes] = useState([])
   const [learningPathFilternodes,setLearningPathFilterNodes] = useState([])
   const [selectedNode,setSelectedNode] = useState({})
   const [learnerLists,setLearnerList] = useState([])
   const [node_content_name,set_node_content_name] = useState('')
   const [confirmLoading, setConfirmLoading] = React.useState(false);


   useEffect(()=>{
      // dispatch(getAllQuiz());
      // console.log("user"+JSON.stringify(user))
      if(history.location.pathname.includes('/learning_path_admin')) {
         dispatch(getAdminLpNew(urlId, function (res) {
         }))
      }else {
         dispatch(getManagerLearningPathDetail(urlId, function (res) {
         }))

      }
      dispatch(getManagerLearningPathNodes(urlId,function (res){
         if(res && res?.length){
            dispatch(getManagerLearningPathNodesDetail(urlId,res[0]?.id,function (resNode){
               set_node_content_name(res[0]?.content_type_name)
               setSelectedNode(resNode)
            }))

            dispatch(getManagerLearningPathLearnersWithPage(urlId,res[0]?.id,1,function (resLearners){
               setLearnerList(resLearners)
            }))
         }

      }))
      // }else {
      //     dispatch(getAdminCourse(urlId,function (res){
      //     }))
      // }

   },[])

   useEffect(()=>{
      if(history.location.pathname.includes('/learning_path_admin')) {
         setState({...state, course: adminResponse})

      }else{

         setState({...state, course: response})
      }
      setLearningPathNodes(nodesresponse)
      setLearningPathFilterNodes(nodesresponse)
      // }else {
      //
      //     setState({...state, course: adminResponse})
      // }
   },[response,nodesresponse,adminResponse])
   const showPopconfirm = () => {
      setVisible(true);
      setConfirmLoading(false)
   };

   const handleOk = () => {
      setConfirmLoading(true)
      handleDeleteCourse(state.course)
   };

   const handleEdit = () =>{
      history.push({
         pathname: `/edit_learning_path/${urlId}`,
         state: {tab: state?.course?.is_published ? 'AvailableCourses' : 'Drafts'}
      })
   }
   const handleCancel = () => {
      setVisible(false);
   };
   const handlePreview = () =>{
      setState({...state, popupView: true})
   }
   const handleDeleteCourse = (row) => {
      dispatch(deleteLearningPath(row,history));
   };

   const handleClone  = (id) =>{

      dispatch(cloneLearningPath(id,history))

   }
   const handleEnroll = (el) =>{
      history.push(`/learningpath/${el?.id}/enroll_users`)
   }

   const handleSelect = (key,id,data) =>{
      set_node_content_name(data.content_type_name)
      setKey(key)
      dispatch(getManagerLearningPathNodesDetail(urlId,id,function (res){

         setSelectedNode(res)
      }))

      dispatch(getManagerLearningPathLearnersWithPage(urlId,id,1,function (res){
         setLearnerList(res)
      }))

   }
   const onLoad = () =>{
      setLoadingImage(true);

   }

   const debouncedGetSearch = useCallback(debounce((query,learningPathFilternodes) => {
      let findArr = learningPathFilternodes.filter((el)=>el?.name?.toLowerCase().search(query.toLowerCase().trim()) != -1)
      if(findArr && findArr?.length){
         setLearningPathNodes(findArr)
         dispatch(getManagerLearningPathNodesDetail(urlId,findArr[0]?.id,function (resNode){
            set_node_content_name(findArr[0]?.content_type_name)
            setKey('0')
            setSelectedNode(resNode)
         }))

         dispatch(getManagerLearningPathLearnersWithPage(urlId,findArr[0]?.id,1,function (resLearners){
            setLearnerList(resLearners)
         }))

      }else{

         setLearningPathNodes(findArr)
         setSelectedNode({})
         setKey('0')
      }

   },1000),[])


   const searchText = (e) => {

      debouncedGetSearch(e.target.value,learningPathFilternodes)

   };
   let adminFull = false
   if (loading) {
      return (
          <div className="loader-middle-wrap">
             <Spinner animation="border" variant="danger" />
          </div>
      );

   } else {
      return (
          <>
             <div className="learning-path-listing">
                <ToastContainer autoClose={2000} />

                {/* <Breadcrumb parent="Learning paths" title="Learning pathname" /> */}
                <Row>
                   <div>
                      <h3 className="sec-title m-b-20">{state?.course?.name}</h3>
                      <div className="coursemanageedit-wrap learning-content-wrap ">
                         <div className="learning-image-wrap d-flex">
                            <div className="learing-banner">
                               {!loadingImage &&

                               <Spinner animation="border" variant="danger" />}
                               <img src={state?.course?.banner_image} onLoad={onLoad} />

                            </div>
                            <div className="btn-icons-rightfloat d-flex align-items-center m-b-20">

                               <div className="btn-wrap vertical-btn d-flex flex-column p-l-30">

                                  {user.user_type == 'admin' || user.user_type == 'manager' || state?.course?.created_by == user?.user_id ?
                                      <>

                                         {!state?.course?.is_published &&
                                         <button onClick={() => handleEdit(state?.course?.id)}
                                                 className="btn new-icon-btn"><span
                                             className="icon-round-box"><img
                                             src={editIco}/></span><span>Edit</span></button>
                                         }

                                         <Popconfirm
                                             title="Are you sure to delete this Learning Path?"
                                             visible={visible}
                                             onConfirm={handleOk}
                                             okText="Yes"
                                             cancelText="No"
                                             placement="bottom"
                                             okButtonProps={{loading: confirmLoading}}
                                             onCancel={handleCancel}
                                         >
                                            <button onClick={showPopconfirm} className="btn new-icon-btn"><span
                                                className="icon-round-box"><img
                                                src={deleteIco}/></span><span>Delete</span></button>
                                         </Popconfirm>

                                         {state?.course?.is_published ?
                                             <button onClick={() => handleEnroll(state?.course)}
                                                     className="btn new-icon-btn"><span
                                                 className="icon-round-box"><img
                                                 src={enrolIco}/></span><span>Enroll New Users</span></button>
                                             : ''}

                                         {state.course && state.course?.elements?.length ?
                                             <>
                                                <button onClick={() => handlePreview()}
                                                        className="btn new-icon-btn"><span
                                                    className="icon-round-box"><img
                                                    src={previewIco}/></span><span>Preview</span></button>
                                                {state?.course?.is_published ?
                                                    <>
                                                       <button onClick={() => handleClone(state?.course?.id)}
                                                               className="btn new-icon-btn"><span
                                                           className="icon-round-box"><img
                                                           src={clonIcon}/></span><span>Clone</span></button>
                                                    </>
                                                    :
                                                    ''
                                                }
                                             </>

                                             :
                                             ''
                                         }

                                      </>

                                      :
                                      <>
                                         <button onClick={() => handlePreview()}
                                                 className="btn new-icon-btn"><span
                                             className="icon-round-box"><img
                                             src={previewIco}/></span><span>Preview</span></button>

                                         <>
                                            <button onClick={() => handleClone(state?.course?.id)}
                                                    className="btn new-icon-btn"><span
                                                className="icon-round-box"><img
                                                src={clonIcon}/></span><span>Clone</span></button>
                                         </>



                                      </>
                                  }
                               </div>
                            </div>

                         </div>
                         <div className="learning-det-cont">
                            <div className="learning-det-boxes">
                               <h4 className="sec-sub-head">Description</h4>
                               <p>{state?.course?.description}
                               </p>
                            </div>
                         </div>

                      </div>
                   </div>
                </Row>
                <Row>
                   <div className="contents-wrap border-top-contents">
                      <div className="d-flex justify-content-between align-items-center m-b-20">
                         {}
                         <h4 className="sec-sub-head">Contents</h4>{state?.course?.is_published && history?.location?.pathname?.includes('/learningmanagementedit') &&  <Button onClick={()=>history.push(`/learning-path/${urlId}/assigment-lists`)} className="primary-link-btn"><span>View All Learners List</span></Button> }
                      </div>

                      <div className="white-box content-box-wrap">
                         {learningPathFilternodes && learningPathFilternodes?.length  ?
                             <Tab.Container id="controlled-tab-example"  activeKey={key}
                             >
                                <Row>
                                   <Col sm={4}>
                                      <div className="learningpath-list-box-left">
                                         <div className="add-quiz-box-header">

                                            <div className="form-input-wrap">
                                               <Form.Control type="text" placeholder="Search Nodes" className="input-item" onChange={e => searchText(e)} />
                                               <button type="button" className="icon-btn">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                                                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                                                  </svg>
                                               </button>
                                            </div>

                                            <div className="add-quiz-box-content">
                                               <ScrollArea
                                                   speed={0.8}
                                                   className="tab-list-scroll"
                                                   contentClassName="tab-list-content"
                                                   horizontal={false}
                                               >
                                                  {learningPathnodes && learningPathnodes?.length ? learningPathnodes.map((el,index)=>{
                                                         return(
                                                             <Nav variant="pills" className="flex-column learners-tab-nav">



                                                                <Nav.Item onClick={()=>handleSelect(index,el?.id,el)}>
                                                                   <OverlayTrigger
                                                                       overlay={
                                                                          <Tooltip id="InfoText">
                                                                             {el?.name}
                                                                          </Tooltip>
                                                                       }
                                                                   >
                                                                      <Nav.Link eventKey={index}>{el?.name?.substring(0, 80) + ' '  +  (el?.name?.length > 80 ?  '...' : '')}</Nav.Link>
                                                                   </OverlayTrigger>
                                                                </Nav.Item>




                                                             </Nav>
                                                         )

                                                      })
                                                      :
                                                      <>No Records</>

                                                  }

                                               </ScrollArea>
                                            </div>

                                         </div>
                                      </div>

                                   </Col>
                                   <Col sm={8}>
                                      <div className="learningpath-list-box-right">
                                         <Tab.Content>

                                            <Tab.Pane eventKey={key}>
                                               {(() => {
                                                  switch (node_content_name) {
                                                     case 'quiz' :
                                                        return (
                                                            <>
                                                               {nodesDetailloading ?

                                                                   <Spinner animation="border" variant="danger" />
                                                                   :

                                                                   <QuizSection
                                                                       node={selectedNode}
                                                                       learners ={learnerLists}
                                                                       urlId={urlId}
                                                                   />

                                                               }

                                                            </>
                                                        )

                                                     case 'course' :

                                                        return (
                                                            <>
                                                               {nodesDetailloading ?

                                                                   <Spinner animation="border" variant="danger" />
                                                                   :

                                                                   <CourseSection
                                                                       node={selectedNode}
                                                                       learners ={learnerLists}
                                                                       lp ={state?.course}
                                                                       urlId={urlId}
                                                                   />

                                                               }

                                                            </>
                                                        )




                                                     default:
                                                        return null;
                                                  }
                                               })()}

                                            </Tab.Pane>

                                         </Tab.Content>
                                      </div>
                                   </Col>
                                </Row>
                             </Tab.Container>

                             :
                             'No Elements Found'

                         }



                      </div>
                   </div>
                </Row>

             </div>

             <ModulePopView
                 lp={state?.course}
                 urlId ={urlId ? urlId : null}
                 show={state?.popupView}
                 onHide={() => setState({ ...state, popupView: false })}

             />
          </>
      )
   }
};
export default ManagerLearingPathDetail;
