import React, {useCallback, useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import { tableData } from './dummyTableData';
import {Link, useHistory} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CalendarListing from './components/extendcalendar';
import {
   getManagerLearningPathLearnersAll,getManagerLearningPathLearnersAllWithPage,nextgetManagerLearningPathLearnersAllWithPage,prevgetManagerLearningPathLearnersAllWithPage,extendLearningPath
} from '../../../../services';
import moment from 'moment'
import {ToastContainer,toast} from "react-toastify";
import ExtensionCompleted from './components/extensioncompleted';
import Button from 'react-bootstrap/Button'
import {useDispatch, useSelector} from "react-redux";
import {format} from "date-fns";
import Pagination from "react-bootstrap/Pagination";
import Spinner from "react-bootstrap/Spinner";
const LearnersListing = (props) => {
   const urlId = props?.match?.params?.id;
   const dispatch = useDispatch()
   const history = useHistory()
   const {response, loading} = useSelector(state => state?.learningpath?.all_learner);
   const extendResponse = useSelector(state => state?.learningpath?.extend_lp?.response);
   const extendLoading = useSelector(state => state?.learningpath?.extend_lp?.loading);
   const [isDateExtended, setIsDateExtended] = useState(false)
   const [assigmentId,setAssignmentId] = useState('')
   const [state, setState] = useState({
      assignments: response,
      count: response?.count,
      paginationItem: []
   })
   const [activePage, setActivePage] = useState(1);
   const [assigmentNewDate, setAssigmentNewDate] = useState('')

   useEffect(() => {
      dispatch(getManagerLearningPathLearnersAll(urlId, function (res) {

      }))
   }, [])

   useEffect(() => {

      const count = response?.count
      const perPage = 12;
      const buttonsCount = Math.ceil(count / perPage);
      let ellipsisLeft = false;
      let ellipsisRight = false;
      let items = []
      for (let i = 1; i <= Number(buttonsCount); i++) {
         if (i === activePage) {
            items.push(
                {id: i, current: true, ellipsis: false}
            );
         } else {
            if (i < 10 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
               items.push(
                   {id: i, current: false, ellipsis: false}
               );
            } else if (i > 1 && i < activePage && !ellipsisLeft) {
               items.push(
                   {id: i, current: false, ellipsis: true}
               );
               ellipsisLeft = true;
            } else if (i < buttonsCount && i > activePage && !ellipsisRight) {
               items.push(
                   {id: i, current: false, ellipsis: true}
               );
               ellipsisRight = true;

            }
         }

      }
      setState({...state, assignments: response, count: response?.count, paginationItem: items});
   }, [response]);

   const handleNext = () => {

      if (state?.assignments?.next) {
         var regex = /page=\d+/g;
         setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
         dispatch(nextgetManagerLearningPathLearnersAllWithPage(state.assignments?.next));
      } else {
         return false;
      }

   }

   const handlePrevious = () => {

      if (state.assignments?.previous) {
         setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
         dispatch(prevgetManagerLearningPathLearnersAllWithPage(state.assignments?.previous))

      } else {
         return false

      }

   }


   const handleIndex = (index) => {
      if (index == 1) {
         dispatch(getManagerLearningPathLearnersAll(urlId, function (res) {

         }))
      } else {
         dispatch(getManagerLearningPathLearnersAllWithPage(urlId, index));
      }
      setActivePage(index);
   }

   const tableColumns = [
      {
         name: 'Learners Name',
         selector: 'id',
         sortable: true,
         center: false,
         cell: row => <p>{row?.user_name ? row?.user_name : 'N/A'}</p>,
         grow: 5
      },
      {
         name: 'Learners Path',
         selector: 'id',
         sortable: true,
         center: false,
         cell: row => <Link to={`/learningmanagementedit/${urlId}`}>{row?.learning_path_name ? row?.learning_path_name : 'N/A'}</Link>,
         grow: 3
      },
      {
         name: 'Due Date',
         selector: 'Progress',
         sortable: true,
         center: false,
         grow: 2,
         cell: row => <span>{row?.due_date ? row?.due_date : 'N/A'}</span>,
      },
      {
         name: 'Completion Date',
         selector: 'Group_name',
         sortable: true,
         center: false,
         cell: row => <span>{row?.completed_date ? row?.completed_date : 'N/A'}</span>,
         grow: 2,
      },
      {
         name: 'Status',
         selector: 'Group_name',
         sortable: true,
         center: false,
         cell: row => <span>{row?.status}</span>,
         grow: 2,
      },

      {
         name: 'Extension Request',
         selector: 'Action',
         sortable: true,
         center: false,
         grow: 3,
         cell: row =>

             <div data-tag="allowRowEvents">
                {row?.status != 'Completed' ?
                    <button className="btn border-btn" onClick={()=>handleShow(row?.id)}>Extend Assignment</button>
                    :
                    <p><b>...</b></p>
                }
             </div>
         ,
      },
   ]
   const [show, setShow] = useState(false);

   const getDate = useCallback((e)=>{
             setAssigmentNewDate(e)
   },[])


   const handleClose = () =>{
      setShow(false)
      setIsDateExtended(false)
      setAssignmentId('')
      setAssigmentNewDate('')


   }
   const handleShow = (id) => {
      setAssignmentId(`${id}`)
      setIsDateExtended(false)
      setShow(true)
   };

   const handleExtend  = () => {
      if(assigmentNewDate?.length) {
         if (assigmentId?.length) {
            let data = new FormData()
            data.append('extended_date', `${moment(assigmentNewDate).format('YYYY-MM-DD')}`)
            dispatch(extendLearningPath(data, urlId, assigmentId, function (res) {
               if (res.error) {
                  toast.error(res?.error?.error_message)
               } else if (res?.status) {
                  toast.success(res?.data?.message)
                  setIsDateExtended(true)
                   dispatch(getManagerLearningPathLearnersAllWithPage(urlId, activePage));
               }
            }))
         }
      }else {
         toast.error('Please Select New Date')
      }
   }


      return (
          <div className="learners-listing-wrap">
              {loading ?
                  <div className="loader-middle-wrap">
                      <Spinner animation="border" variant="danger"/>
                  </div>

                  :
                  <>
                      <div className="learners-listing-table-header">
                          <h3 className="sec-title m-b-20">
                              <Link to={`/learningmanagementedit/${urlId}`}><span><i className="fa fa-arrow-left"
                                                                                     aria-hidden="true"></i></span>Learners
                                  List</Link>
                          </h3>
                      </div>
                      <div className="datatble-wrap">
                          <DataTable
                              data={state?.assignments && state?.assignments && state?.assignments?.results?.length ? state?.assignments?.results : []}
                              columns={tableColumns}
                              center={true}
                          />
                          {!loading && state?.assignments && state?.assignments && state?.assignments.count > 12 && response &&
                          <div className="d-flex justify-content-center">
                              <Pagination size="sm" className="custom-pagination">
                                  <Pagination.First disabled={!state?.assignments?.previous}
                                                    className={`${state?.assignments?.previous ? 'paginationStyle' : ''} `}
                                                    onClick={handlePrevious}/>
                                  {state.paginationItem.map(page => {
                                      if (!page.ellipsis) {
                                          return <li key={page.id}>
                                              <Pagination.Item
                                                  onClick={() => handleIndex(page.id)} key={page.id}
                                                  active={page.id == activePage}
                                              >
                                                  {page.id}
                                              </Pagination.Item>
                                          </li>
                                      } else {
                                          return <Pagination.Ellipsis></Pagination.Ellipsis>
                                      }
                                  })}
                                  <Pagination.Last disabled={!state?.assignments?.next} onClick={handleNext}
                                                   className={`${state?.assignments?.next ? 'paginationStyle' : ''} `}/>

                              </Pagination>

                          </div>
                          }

                      </div>

                  </>
              }

             <Modal className="common-modal team-member-pop inner-popup medium-size-pop"
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show} onHide={handleClose}

             >
                <Modal.Header closeButton>
                   <div className="addmember-header">
                      {/*<h4>Extend Assignment</h4>*/}
                      {/*<p>Select a date to which you want to extend</p>*/}
                   </div>
                </Modal.Header>
                <Modal.Body>
                   {!extendLoading ?

                       <>
                          {!isDateExtended ?
                              <>
                                 <div className="extension-screen-1">
                                    <CalendarListing getDate={getDate}/>
                                 </div>

                                 <div className="d-flex justify-content-end extension-pop-footer double-btn">
                                    <button type="btn" className="btn border-btn" onClick={handleClose}>Cancel</button>
                                    <Button disabled={extendLoading} onClick={handleExtend}>Extend</Button>
                                 </div>
                              </>
                              :
                              <ExtensionCompleted newDate = {`${moment(assigmentNewDate).format('YYYY-MM-DD')}`}/>

                          }
                       </>
                       :

                       <Spinner animation="border" variant="danger"/>

                   }
                </Modal.Body>
             </Modal>
          </div>
      );
}
export default LearnersListing;
