import React,{useEffect,useState} from 'react';
import { Route,BrowserRouter } from 'react-router-dom';
import PageNotFound from "./app/modules/errorpages/pagenotfound";
import Router from './app/router/router';
import { verifySubDomain } from '~/modules/auth/services';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'intro.js/introjs.css';
import Spinner from "react-bootstrap/Spinner";
import {resetStore} from "./app/modules/auth/services";
// import 'antd/dist/antd.css';
const App = () => {
    const dispatch = useDispatch();
    const  selectedLogo  = useSelector(state => state.settings.selectedLogo?.data||'');
    const  selectedTheme = useSelector(state => state.settings?.theme?.response || '');
    const response = useSelector(state => state.auth?.verify_subdomain?.response)
    const error = useSelector(state => state.auth?.verify_subdomain?.error)
    const { user } = useSelector(state => state.auth);
    const [state, setState] = useState({
        subdomain:null,
        maindomain:false,
        availableDomain:{},
        showloader:true
    })


    useEffect(()=>{

        if(user && user?.token){

            var hours = 48; // to clear the localStorage after 2 days
                           // (if someone want to clear after 8hrs simply change hours=8)
            var now = new Date().getTime();
            var login_time = sessionStorage.getItem('login_time');
            if (login_time == null) {
                sessionStorage.setItem('login_time', `${now}`)
            } else {
                // 1*60*1000  expire after 1 minute
                if(now-login_time > hours*60*60*1000) {
                    toast.error('Session has expired !!!!!')
                    setTimeout(()=>{
                        let action = 'USER_LOGOUT'
                        dispatch(resetStore(action))
                    },100)

                }
            }

        }

    },[user])

useEffect(()=>{
    let body = document.querySelector('body')
    if(body){
        if(selectedTheme && selectedTheme?.theme){
            // body.setAttribute('data-theme',`${selectedTheme?.theme}`)
            body.setAttribute('data-theme',`dynamic`)
            body?.style.setProperty(
                "--primary-color",
                `${selectedTheme?.theme}`
              );

            }else {
                body.setAttribute('data-theme',`dynamic`)
                body?.style?.setProperty( "--primary-color", `${user?.theme}`)
            }
        }else{
            body.setAttribute('data-theme',`dynamic`)
            body?.style?.setProperty( "--primary-color", "#F69525")

        }
},[user])

    useEffect(() => {

        let host = window.location.host;
        let protocol = window.location.protocol;
        let parts = host.split(".");
    if (parts.length >= 2) {
        dispatch(verifySubDomain(host, function (response) {
            if (response) {
                setState({...state, subdomain: parts[0], availableDomain: response, showloader: false})
            }

        }, function (error) {
            let obj = {
                status: false
            }
            setState({...state, subdomain: parts[0], availableDomain: obj, showloader: false})

        }));
    }else{
        setState({...state, maindomain:true,showloader: false})
    }
    }, []);

    const domainVerification = () => {
            if(state?.availableDomain?.status == true ||  state.maindomain){
                return <NextApp />

            }else if(state?.availableDomain?.status == false ){
               return <PageNotFound/>
            }
    }

return (
  <React.Fragment>
    <BrowserRouter>
        {state.showloader &&
        <div className="loader-full-screen">
            <div className="loader">
                <Spinner animation="border" variant="warning"/>

            </div>


        </div>
        }

        {domainVerification()}
    </BrowserRouter>
  </React.Fragment>
);
}

const NextApp = () => (
  <React.Fragment>
    <Route path="/" name="home" component={Router} />
    <ToastContainer autoClose={2000} />
  </React.Fragment>
);

export default App;
