import React, { useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CourseDetails from '../components/details'
import AddContent from '../components/addcontent';
import CourseAssign from "../components/courseassign";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Helmet} from "react-helmet";

function CourseBuilder(props) {
    const urlId = props?.match?.params?.id;
    const [state, setState] = useState({
        defaultKey: '0',
        courseId: '',
        course: {},
        moduleAdded: false
    })
    const parentState = (key, id, item, yes) => {

        setState({ ...state, defaultKey: key, courseId: id, course: item, moduleAdded: yes })
    }

    useEffect(() => {
        let element = document.getElementById('headerTitle')
        if (element) {
            switch (props?.match?.url) {
                case '/coursebuilder':
                    element.innerHTML = 'Course Library'
                    break;
                case '/draft/courses':
                    element.innerHTML = 'Course Library'
                    break;
                default:
                    'Profile'
            }

        }
    }, [])

    return (
        <div className="tab-wrapper">
             <Helmet>
                    <title>{urlId ? 'Course Edit - Cultural Engagement ' : 'Course Create - Cultural Engagement '} </title>
                </Helmet>
            <Tabs activeKey={state?.defaultKey}
                onSelect={(k) => setState({ ...state, defaultKey: k })} id="controlled-tab-example" className="m-b-30">
                <Tab eventKey="0" title="Details" disabled={state?.moduleAdded}>
                    <CourseDetails parentState={parentState} urlId={urlId ? urlId : null} />
                </Tab>

                <Tab disabled={!state?.courseId || state?.moduleAdded || state.defaultKey == '2'} eventKey="1" title="Add Content">
                    <AddContent parentState={parentState} courseId={state?.courseId} course={state?.course} urlId={urlId ? urlId : null} />
                </Tab>

                <Tab disabled={!state?.courseId || state.defaultKey == '0' || state.defaultKey == '1'} eventKey="2" title="Assign">
                    <CourseAssign keys={state?.defaultKey} urlId={urlId ? urlId : null} parentState={parentState} courseId={state?.courseId} course={state?.course} />
                </Tab>
            </Tabs>
           
        </div>
    );
};
export default CourseBuilder;
