import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { FormLabel, FormControl } from "react-bootstrap";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Quiz from "./components/quiz";
import MultipleChoice from "./components/multipleChoice";
import TrueFalse from "./components/trueFalse";
import FillBlanks from "./components/fillBlanks";
import TotalNeedScore from "./components/totalneedscore";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import { ToastContainer, toast } from "react-toastify";
import QuizModulePopView from "../../../managerQuizPreview";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQuizQuestion,
  updateQuizQuestion,
  getManagerQuiz,
} from "../../../../services";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const AddDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [infoPopUp, showInfoPopup] = useState(false);
  const auto = useRef();
  let response = useSelector(
    (state) => state?.quiz?.get_quiz_manager?.response
  );
  let addQuestionLoading = useSelector(
    (state) => state?.quiz?.newly_added_quiz_question?.loading
  );
  let deleteQuestionLoading = useSelector(
    (state) => state?.quiz?.deleted_quiz_question?.loading
  );
  let updateLoading = useSelector(
    (state) => state?.quiz?.updated_quiz_question?.loading
  );
  const [state, setState] = useState({
    questionIndex: "",
    popupView: false,
    initialValuesState: {
      pass_score: "10",
      questions: [
        {
          question_type: "",
        },
      ],
    },
  });
  const formikRef = useRef(null);
  const btnref = useRef(null);

  useEffect(() => {

    if (props.urlId) {
      if (response?.questions?.length == 0) {
        const { initialValuesState } = state;
        initialValuesState.questions = [];
        initialValuesState.pass_score = response?.pass_score || "10";
        initialValuesState?.questions?.push({
          question_type: "",
        });

        setState({ ...state, initialValuesState: initialValuesState });
      } else {
        for (let i = 0; i < response?.questions?.length; i++) {
          if (
            response?.questions[i]?.multiple_choices?.length &&
            response?.questions[i]?.question_type == "multiple_choice"
          ) {
            let findIndex = response?.questions[i]?.multiple_choices?.findIndex(
              (el) => {
                return el.is_correct_answer;
              }
            );
            response.questions[i].right_answer = findIndex;
          }
          if (
            response?.questions[i]?.multiple_choices?.length &&
            response?.questions[i].question_type == "true_or_false"
          ) {
            let findIndex = response?.questions[i]?.multiple_choices?.findIndex(
              (el) => {
                return el.is_correct_answer;
              }
            );
            response.questions[i].right_answer =
              response.questions[i]?.multiple_choices[findIndex].choice;
          }
        }
        let newRes = { ...response };
        newRes.quiz_name = newRes?.name;
        delete newRes?.name;
        delete newRes?.description;

        setState({ ...state, initialValuesState: newRes });
      }
    }
  }, [response]);

  function onSubmit(values) {
    if (btnref.current  === "save") {
      if (values?.questions && values?.questions?.length) {
        for (let i = 0; i < values?.questions?.length; i++) {
          if (
            values?.questions[i]?.multiple_choices?.length &&
            values?.questions[i].question_type == "multiple_choice"
          ) {
            for (
              let j = 0;
              j < values?.questions[i]?.multiple_choices?.length;
              j++
            ) {
              if (values.questions[i].multiple_choices[j].choice.length == 0) {
                values.questions[i].multiple_choices = values?.questions[
                  i
                ]?.multiple_choices?.filter((el) => el?.choice?.length);
              }
            }
          }
          values.questions[i].position = i + 1;
        }
      }
      values.is_published = "False";
      values.is_active = "True";
      
      dispatch(
        updateQuizQuestion(
          JSON.stringify(values),
          props?.quizId,
          function (response) {
            props.parentState(2, props?.urlId, props?.quiz, true);
            history.push("/draft/quizzes");
          }
        )
      );
    } else if (btnref.current  === "publish") {
      if (values?.questions && values?.questions?.length) {
        for (let i = 0; i < values?.questions?.length; i++) {
          if (
            values?.questions[i]?.multiple_choices?.length &&
            values?.questions[i].question_type == "multiple_choice"
          ) {
            for (
              let j = 0;
              j < values?.questions[i]?.multiple_choices?.length;
              j++
            ) {
              if (values.questions[i].multiple_choices[j].choice.length == 0) {
                values.questions[i].multiple_choices = values?.questions[
                  i
                ]?.multiple_choices?.filter((el) => el?.choice?.length);
              }
            }
          }
          values.questions[i].position = i + 1;
        }
        values.is_published = "True";
        values.is_active = "True";

        dispatch(
          updateQuizQuestion(
            JSON.stringify(values),
            props?.quizId,
            function (response) {
              props.parentState(2, props?.quizId, props?.quiz, true);
              showInfoPopup(true);
              // setTimeout(() => {
              //   showInfoPopup(false);
              //   history.push("/quizmanagement");
              // }, 6000);

              //

              //  history.push('/quizmanagement')
            }
          )
        );
      }
    }
  }
  async function handleQuizzes(
    e,
    values,
    setValues,
    errors,
    validateField,
    validateForm
  ) {
    if (values?.questions && values?.questions?.length >= 1) {

      let error = await formikRef.current.validateForm();

      if (error.questions) {
        error.questions = errors?.questions?.filter(function (x) {
          return x !== undefined;
        });
      }
      if (
        error?.questions &&
        error?.questions?.length &&
        values?.questions?.find((el) => el.question_type == "")
      ) {
        for (let i = 0; i < values?.questions?.length; i++) {
          if (values?.questions[i].question_type == "") {
            formikRef.current.setFieldTouched(`questions.${i}.question_type`);

            break;
          }
        }
      } else if (
        Object.keys(error).includes("questions") &&
        error?.questions?.length &&
        error.constructor === Object
      ) {
        if (values?.questions?.length) {
          auto?.current?.click();
        }
        // }

      }

      if (Object.keys(error).length === 0 && error.constructor === Object) {
        setTimeout(() => {
  
          if (values?.questions && values?.questions?.length) {
            for (let i = 0; i < values?.questions?.length; i++) {
              if (
                values?.questions?.length &&
                values?.questions[i].question_type == "multiple_choice"
              ) {
                for (
                  let j = 0;
                  j < values?.questions[i]?.multiple_choices?.length;
                  j++
                ) {
                  if (
                    values.questions[i].multiple_choices[j].choice.length == 0
                  ) {
                    values.questions[i].multiple_choices = values?.questions[
                      i
                    ]?.multiple_choices.filter((el) => el?.choice?.length);
                  }
                }
              }
              values.questions[i].position = i + 1;
            }
          }
          values.is_published = "False";
          values.is_active = "True";
          dispatch(
            updateQuizQuestion(
              JSON.stringify(values),
              props?.quizId,
              function (res) {
                if (res) {
                  dispatch(
                    getManagerQuiz(props?.quizId, function (res) {
                      if (res) {
                        if (res?.questions?.length) {
                          for (let i = 0; i < res?.questions?.length; i++) {
                            if (
                              res?.questions[i]?.multiple_choices?.length &&
                              res?.questions[i].question_type ==
                                "multiple_choice"
                            ) {
                              let findIndex = res?.questions[
                                i
                              ]?.multiple_choices?.findIndex((el) => {
                                return el.is_correct_answer;
                              });
   
                              res.questions[i].right_answer = findIndex;
                            }
                            if (
                              res?.questions[i]?.multiple_choices?.length &&
                              res?.questions[i].question_type == "true_or_false"
                            ) {
                              let findIndex = res?.questions[
                                i
                              ]?.multiple_choices?.findIndex((el) => {
                                return el.is_correct_answer;
                              });

                              res.questions[i].right_answer =
                                res?.questions[i]?.multiple_choices[
                                  findIndex
                                ].choice;
                            }
                          }
                        }

                        res?.questions?.push({
                          question_type: "",
                        });

                        setValues({ ...values, questions: [] });
                        setState({ ...state, initialValuesState: res });
                      }
                    })
                  );
                }
              }
            )
          );
        }, 10);
      } else {
        auto?.current?.click();
        toast.error("Some fields are empty, please check!");
      }
    } else {
      const questions = [...values?.questions];
      const { initialValuesState } = state;

      questions.push({
        question_type: "",
      });
      initialValuesState?.questions?.push({
        question_type: "",
      });

      setValues({ ...values, questions });
      setState({ ...state, initialValuesState: initialValuesState });
    }
    // autoSave(values,props?.courseId,props.urlId)
  }

  const deleteQuestion = (index, values, setValues) => {
    const questions = [...values.questions];
    const { initialValuesState } = state;
    initialValuesState?.questions?.splice(index, 1);
    questions.splice(index, 1);
    setValues({ ...values, questions });

    setState({ ...state, initialValuesState: initialValuesState });
  };
  const handleQuestionDelete = (index, values, setValues, question) => {
    setState({ ...state, quizId: question?.id });
    const questions = [...values.questions];
    dispatch(
      deleteQuizQuestion(props.quizId, question?.id, function (response) {
        if (response == "response") {
          questions.splice(index, 1);
          setValues({ ...values, questions });
        }
      })
    );
  };
  const handlePreviewModel = () => {
    setState({ ...state, popupView: true });
  };
  const handleLoader = () => {
    if (addQuestionLoading || updateLoading) {
      return (
        <div className="loader-full-screen-course">
          <div className="loader">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      );
    } else {
      return <span></span>;
    }
  };

  return (
    <div className="coursedetails-wrap">
      <ToastContainer autoClose={2000} />
      <div>{handleLoader()}</div>
      <Formik
        innerRef={formikRef}
        initialValues={state.initialValuesState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          values,
          setFieldValue,
          touched,
          setValues,
          handleChange,
          handleBlur,
          validateField,
          validateForm,
          handleSubmit
        }) => {

          const handleClickSubmit = async(button) => {

            let error = await formikRef.current.validateForm();

            if (Object.keys(error).length === 0 && error.constructor === Object) {
              if(button === 'save'){
                btnref.current = 'save';
              } else {
                btnref.current = 'publish';
              }
              handleSubmit();
            } else {
              auto?.current?.click();
              toast.error("Some fields are empty, please check!");
            }
            
          }

          const handleContent = (template, selectedIndex) => {
            const { initialValuesState } = state;
            if (template) {
              const questions = [...values.questions];
              switch (template) {
                case "multiple_choice":
                  questions[selectedIndex].question_type = template;
                  initialValuesState.questions[selectedIndex].question_type =
                    template;
                  initialValuesState.questions[selectedIndex].question = "";
                  initialValuesState.questions[selectedIndex].score = "";
                  initialValuesState.questions[selectedIndex].right_answer = "";
                  // initialValuesState.questions[selectedIndex].fill_in_the_blanks ={answer:''}
                  initialValuesState.questions[selectedIndex].multiple_choices =
                    [
                      { choice: "" },
                      { choice: "" },
                      { choice: "" },
                      { choice: "" },
                    ];
                  questions[selectedIndex].question = "";
                  questions[selectedIndex].score = "";
                  questions[selectedIndex].right_answer = "";
                  // questions[selectedIndex].fill_in_the_blanks ={answer:''}
                  questions[selectedIndex].multiple_choices = [
                    { choice: "" },
                    { choice: "" },
                    { choice: "" },
                    { choice: "" },
                  ];
                  setState({
                    ...state,
                    initialValuesState: initialValuesState,
                    questionIndex: selectedIndex,
                  });
                  setValues({ ...values, questions });
                  break;
                case "true_or_false":
                  initialValuesState.questions[selectedIndex].question_type =
                    template;
                  questions[selectedIndex].question_type = template;
                  initialValuesState.questions[selectedIndex].question = "";
                  initialValuesState.questions[selectedIndex].score = "";
                  initialValuesState.questions[selectedIndex].right_answer = "";
                  // initialValuesState.questions[selectedIndex].fill_in_the_blanks ={answer:''}
                  questions[selectedIndex].multiple_choices = [
                    { choice: "" },
                    { choice: "" },
                  ];
                  questions[selectedIndex].question = "";
                  questions[selectedIndex].score = "";
                  questions[selectedIndex].right_answer = "";
                  // questions[selectedIndex].fill_in_the_blanks ={answer:''}
                  questions[selectedIndex].multiple_choices = [
                    { choice: "" },
                    { choice: "" },
                  ];
                  setState({
                    ...state,
                    initialValuesState: initialValuesState,
                    questionIndex: selectedIndex,
                  });
                  setValues({ ...values, questions });
                  break;
                case "fill_in_the_blanks":
                  questions[selectedIndex].question_type = template;
                  initialValuesState.questions[selectedIndex].question_type =
                    template;
                  initialValuesState.questions[selectedIndex].question = "";
                  initialValuesState.questions[selectedIndex].score = "";
                  initialValuesState.questions[
                    selectedIndex
                  ].fill_in_the_blanks = { answer: "" };
                  questions[selectedIndex].multiple_choices = [];
                  questions[selectedIndex].question = "";
                  questions[selectedIndex].score = "";
                  questions[selectedIndex].fill_in_the_blanks = { answer: "" };
                  questions[selectedIndex].multiple_choices = [];
                  setState({
                    ...state,
                    initialValuesState: initialValuesState,
                    questionIndex: selectedIndex,
                  });
                  setValues({ ...values, questions });
                  break;
              }
            }
          };

          const goUp = (index) => {
            if (index !== 0) {
                let questionscopy = JSON.parse(JSON.stringify(values.questions));
                let item = questionscopy[index];
                questionscopy[index] = questionscopy[index - 1];
                questionscopy[index - 1] = item;
                setFieldValue('questions', questionscopy);
            }

        };

        const goDown = (index) => {
            if (index < values.questions.length - 1) {
                let questionscopy = JSON.parse(JSON.stringify(values.questions));
                let item = questionscopy[index];
                questionscopy[index] = questionscopy[index + 1];
                questionscopy[index + 1] = item;
                setFieldValue('questions', questionscopy);
            }
        };

          return (
            <Form className="d-block mb-4">
              <div className="course-content-sec">
                {/*<Quiz*/}
                {/*    touched={touched}*/}
                {/*    setFieldValue={setFieldValue}*/}
                {/*    values={values}*/}
                {/*    handleChange={handleChange}*/}
                {/*    errors={errors}*/}
                {/*/>*/}
              </div>
              <div className="course-content-sec ">
                <FieldArray name="questions">
                  {() =>
                    values?.questions && values?.questions?.length
                      ? values?.questions?.map((question, i) => {
                          const questionsErrors =
                            (errors.questions?.length && errors.questions[i]) ||
                            {};
                          const questionsTouched =
                            (touched?.questions?.length &&
                              touched?.questions[i]) ||
                            {};

                          return (
                            <>


                              <div className="course-form-content-sec p-relative">

                              {values?.questions?.length > 1 && (

                                <div className="top-bottom-carret-wrap">
                                { i !== 0 && <div onClick={() => goUp(i)} className="move-to-top move-arrow-wrap ">
                                    <OverlayTrigger
                                        overlay={<Tooltip id="InfoText">Move up</Tooltip>}>
                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>}
                                {i !== values?.questions?.length - 1 && <div onClick={() => goDown(i)} className="move-to-bottom move-arrow-wrap">
                                <OverlayTrigger
                                        overlay={<Tooltip id="InfoText">Move down</Tooltip>}>
                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                    </div>}
                                </div>

                              )}
                              

                                <h3 className="tab-title">Question {i + 1}</h3>
                                <div className="whitebox shadow-box module-form-box">
                                  {/* {question?.id ? (
                                    ""
                                  ) : (
                                    <div
                                      onClick={() =>
                                        deleteQuestion(i, values, setValues)
                                      }
                                    >
                                      {" "}
                                      <div className="form-close-btn p-relative">
                                        <span className="memb-close-icon-card">
                                          ×
                                        </span>
                                      </div>
                                    </div>
                                  )} */}
                                  {/*<div onClick={()=>deleteModule(i,values, setValues)}> <div className="form-close-btn p-relative"><span className="memb-close-icon-card">×</span></div></div>*/}
                                  <Row>
                                    <Col md={6}>
                                      <div className="form-input-wrap">
                                        <FormLabel>Question Type</FormLabel>
                                        <FormControl
                                          as="select"
                                          name={`questions.${i}.question_type`}
                                          onChange={(e) =>
                                            handleContent(e.target.value, i)
                                          }
                                          value={
                                            values?.questions[i]?.question_type
                                          }
                                          isInvalid={
                                            !!questionsErrors?.question_type &&
                                            questionsTouched?.question_type
                                          }
                                          onBlur={handleBlur}
                                        >
                                          <option value="" selected disabled>
                                            Choose question type
                                          </option>
                                          <option value="multiple_choice">
                                            Multiple Choice
                                          </option>
                                          <option value="true_or_false">
                                            True False
                                          </option>
                                          <option value="fill_in_the_blanks">
                                            Fill in the Blanks
                                          </option>
                                        </FormControl>
                                      </div>
                                    </Col>
                                  </Row>
                                  {question?.question_type ? (
                                    <div className="text-editor-wrap">
                                      {(() => {
                                        switch (question.question_type) {
                                          case "multiple_choice":
                                            return (
                                              <MultipleChoice
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                handleChange={handleChange}
                                                setValues={setValues}
                                                questionsErrors={
                                                  questionsErrors
                                                }
                                                questionsTouched={
                                                  questionsTouched
                                                }
                                                touched={touched}
                                                handleBlur={handleBlur}
                                                index={i}
                                              />
                                            );
                                          case "true_or_false":
                                            return (
                                              <TrueFalse
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                handleChange={handleChange}
                                                setValues={setValues}
                                                questionsErrors={
                                                  questionsErrors
                                                }
                                                questionsTouched={
                                                  questionsTouched
                                                }
                                                touched={touched}
                                                handleBlur={handleBlur}
                                                index={i}
                                              />
                                            );
                                          case "fill_in_the_blanks":
                                            return (
                                              <FillBlanks
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                setValues={setValues}
                                                handleChange={handleChange}
                                                questionsErrors={
                                                  questionsErrors
                                                }
                                                questionsTouched={
                                                  questionsTouched
                                                }
                                                handleBlur={handleBlur}
                                                index={i}
                                              />
                                            );

                                          default:
                                            return null;
                                        }
                                      })()}
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <Col
                                    xs={12}
                                    className="d-flex justify-content-end"
                                  >
                                    {question?.id ? (
                                      <button
                                        className="btn border-btn ml-auto"
                                        type="button"
                                        onClick={(e) =>
                                          handleQuestionDelete(
                                            i,
                                            values,
                                            setValues,
                                            question
                                          )
                                        }
                                      >
                                        {deleteQuestionLoading &&
                                        deleteQuestionLoading &&
                                        state.quizId == question.id ? (
                                          <Spinner
                                            animation="border"
                                            variant="warning"
                                          />
                                        ) : (
                                          "Delete"
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                      className="btn border-btn ml-auto"
                                      type="button"
                                      onClick={() =>
                                        deleteQuestion(i, values, setValues)
                                      }
                                     >
                                      Delete
                                      </button>
                                    )}
                                   
                                  </Col>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : ""
                  }
                </FieldArray>
                {/*<div className="questionbox">*/}
                {/*  <h3 className="tab-title">Question 1</h3>*/}
                {/*  <Question1/>*/}
                {/*  </div>*/}
                {/*<div className="questionbox">*/}
                {/*<h3 className="tab-title">Question 2</h3>*/}
                {/*  <Question2/></div>*/}
                {/*<div className="questionbox">*/}
                {/*<h3 className="tab-title">Question 3</h3>*/}
                {/*  <Question2/></div>*/}
                {/*<div className="button-wrap m-t-20">*/}

                <Button
                  variant="primary"
                  className="mt-4"
                  onClick={(e) =>
                    handleQuizzes(
                      e,
                      values,
                      setValues,
                      errors,
                      validateField,
                      validateForm
                    )
                  }
                >
                  <span className="btn-icon">+</span>Add Another Question
                </Button>
                {/*  </div>*/}
              </div>
              <div className="course-content-sec border-top-box">
                <TotalNeedScore
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Total Percentage Required to Pass This Quiz"
                />
                <small
                  className="text-dark d-block"
                  style={{ marginTop: "-6px" }}
                >
                  at least 10 % or above should be the minimum passing
                  percentage.
                </small>
              </div>
              <div className="add-module-footer d-flex justify-content-end">
                <div className="button-wrap double-btn">
                  <Button
                    variant="primary"
                    type="submit"
                    data-flag="action-3"
                    ref={auto}
                    className="btn-m d-none"
                  >
                    Hidden
                  </Button>
                  <Button
                    variant="dark"
                    // type="submit"
                    className="btn-m"
                    data-flag="action-1"
                    onClick={() => handleClickSubmit('save')}
                  >
                    Save
                  </Button>
                  <Button
                    variant="dark"
                    className="btn-m"
                    disabled={
                      values?.questions &&
                      values?.questions?.length &&
                      values?.questions[0]?.question_type?.length <= 0
                    }
                    onClick={handlePreviewModel}
                  >
                    Preview
                  </Button>
                  <Button
                    variant="primary"
                    // onClick={() => showInfoPopup(true)}
                    // type="submit"
                    onClick={() => handleClickSubmit('publish')}
                    data-flag="action-2"
                    className="btn-m"
                  >
                    Publish
                  </Button>
                </div>
              </div>
              <QuizModulePopView
                show={state.popupView}
                values={values}
                onHide={() => setState({ ...state, popupView: false })}
              />
            </Form>
          );
        }}
      </Formik>
      <Modal
        size="sm"
        show={infoPopUp}
        //    onHide={() =>
        //     showInfoPopup(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="common-modal quiz-flash-popup inner-popup"
        centered
        closeButton={false}
      >
        <Modal.Body>
          <div className="addmember-header ">
            <p className="text-center">
              <b>
                This assessments is now available to be used in your learning
                path builder , or can be attached to individual modules found in
                your library
              </b>
            </p>
            <div className="d-flex justify-content-center">
              <Button
                variant="dark"
                onClick={() => {
                  showInfoPopup(false);
                  history.push("/quizmanagement");
                }}
                type="submit"
                data-flag="action-2"
                className="btn"
              >
                Dismiss
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  pass_score: Yup.number()
    .required("Required")
    .typeError("Only numbers are allowed for this field")
    .min(10, "minimum value should be 10%"),
  questions: Yup.array().of(
    Yup.object().shape({
      question_type: Yup.string().required("Question type is required"),

      question: Yup.string().required("Question is required"),
      score: Yup.number()
        .typeError("Only numbers are allowed for this field")
        .required("Score is required"),

      fill_in_the_blanks: Yup.object().when("question_type", {
        is: (val) => ["fill_in_the_blanks"].includes(val),
        then: Yup.object().shape({
          answer: Yup.string().required("Answer is required field").nullable(),
        }),
        otherwise: Yup.object()
          .shape({
            answer: Yup.string().nullable(),
          })
          .nullable(),
      }),

      right_answer: Yup.string().when("question_type", {
        is: (val) => ["true_or_false", "multiple_choice"].includes(val),
        then: Yup.string().required("Right answer is required"),
        otherwise: Yup.string(),
      }),

      multiple_choices: Yup.array()
        .of(
          Yup.object().shape({
            choice: Yup.string(),
          })
        )
        .test({
          name: "multiple_choices",
          message: "Required",
          test: (val) =>
            val?.every(({ choice }, index) => {
              if (index === 0 || index === 1 || index === 2) {
                return !!choice;
              }
              return true;
            }),
        }),
    })
  ),
});

export default AddDetails;
