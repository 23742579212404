import React, {useContext, useState} from "react";
import {
    addEdge,
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd, removeElements
} from 'react-flow-renderer';
import {useDispatch, useSelector} from "react-redux";
import  {FlowContext} from "../container";
import {deleteElementByIdLp} from "../../../services";
import Spinner from "react-bootstrap/Spinner";


const foreignObjectSize = 40;
export default function CustomEdge({
                                       id,
                                       sourceX,
                                       sourceY,
                                       targetX,
                                       targetY,
                                       sourcePosition,
                                       targetPosition,
                                       style = {},
                                       data,
                                       arrowHeadType,
                                       markerEndId,

                                   }) {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });
    let dispatch = useDispatch();

    const [elements,setElements,nodeId,setNodeId,lpId,setNodeType] =useContext(FlowContext)

    const [nestedNode,setNestedNode] = useState(null)
    const deleteLoading = useSelector(state => state?.learningpath?.delete_lp_module?.loading);
    const onEdgeClick = (evt,id, elements,setElements,lpId) => {
        evt.stopPropagation();
        let elmentsToRemove = elements?.filter((el)=>el.id == id)
        let singleElement = elements?.find((el)=>el.id == id)
        let parent = elements.find((el)=>el.id == singleElement?.source)

         if(parent && parent?.content_type_name == 'quiz'){
             setNodeId(parent?.id)
             setNodeType(parent?.content_type_name)
         }

        setNestedNode(singleElement.id)
        let copyElements = [...elements]
        let childIndex = elements.findIndex((el) => el.id == elmentsToRemove[0].target)
        copyElements[childIndex].parent = null
        if(singleElement.is_created) {
            dispatch(deleteElementByIdLp(lpId,id,function (res){
                if(res){

                    setElements(copyElements)
                    setTimeout(() => {
                        setElements((els) => removeElements(elmentsToRemove, els));
                    }, 10)

                }
            }))

        }else{

            setElements(copyElements)
            setTimeout(() => {
                setElements((els) => removeElements(elmentsToRemove, els));
            }, 10)
        }

    };
    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <body>

                    {deleteLoading && nestedNode && nestedNode == id ?

                        <button
                            className="edgebutton1"
                            onClick={(event) => (onEdgeClick(event,id, elements,setElements,lpId))}
                        >
                            <Spinner animation="border" size="sm" variant="danger" className="edge-button-spiner"/>
                        </button>

                        :

                        <button
                        className="edgebutton"
                        onClick={(event) => (onEdgeClick(event,id, elements,setElements,lpId))}
                        >
                            x
                        </button>

                    }



                </body>
            </foreignObject>
        </>
    );
}
