import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Logo from "~/assets/images/logo.png";
import AutosizeInput from "react-input-autosize";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  verifySubDomain,
  subdomainSignIn,
  subdomainExits,
} from "~/modules/auth/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { checkHost } from "../../../../shared/utils/checkHost";
import { useHistory } from "react-router";

const EnterSubdomain = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const host = window.location.host;
  const { user, error, signUpError } = useSelector((state) => state.auth);
  const userloading = useSelector((state) => state.auth?.userDetails?.loading);
  const loading = useSelector((state) => state.auth?.subdomain?.loading);
  // useEffect(()=>{
  //
  //     if(user?.user_type) {
  //         if (user?.user_type === 'learner') {
  //             history.push('/profile');
  //
  //         } else if (user?.user_type === 'manager') {
  //
  //             if (user?.is_profile_completed == false) {
  //                 history.push('/complete-profile');
  //             } else if (user?.is_profile_completed == true && user?.is_subscribed == false) {
  //                 history.push('/subscriptiononsignup');
  //                 // parts.includes('www') == false
  //             } else if (user?.is_profile_completed == true && user?.is_subscribed == true &&  user?.is_domain_created == false) {
  //                 history.push('/subdomainsignup');
  //             } else if (user?.is_profile_completed == true && user?.is_subscribed == true && user.subdomain?.length && user?.is_domain_created == true) {
  //                 history.push('/profile');
  //
  //             }
  //
  //
  //         } else if (user?.user_type === 'admin') {
  //             history.push('/profile');
  //
  //         }
  //     }else{
  //         if(userloading == false && history?.location?.search?.length == 0){
  //
  //             history.push('/login');
  //         }
  //
  //     }
  // },[user])
  useEffect(() => {
    if (host && host.split(".")[1] == "enga") {
      if (host.split(".")[0] != "www") {
        history.push("/login");
      }
    } else if (host && host.split(".")[1] == "cultural-frontend-demo") {
      if (host.split(".")[0] != "www") {
        history.push("/login");
      }
    } else if (host && host.includes("localhost")) {
      if (host.split(".").length >= 2) {
        history.push("/login");
      }
    }
  }, []);

  const handleSetUpDomain = (values) => {
    let deepCopy = { ...values };
    if (host && host.split(".")[1] == "enga") {
      deepCopy.name = `https://${deepCopy.name}.enga.app`;
    } else if (host && host.split(".")[1] == "cultural-frontend-demo") {
      deepCopy.name = `https://${deepCopy.name}.cultural-frontend-demo.dev.sayone.team`;
    } else if (host && host.includes("localhost")) {
      deepCopy.name = `http://${deepCopy.name}.localhost:8080`;
    }

    dispatch(
      subdomainSignIn(
        deepCopy,
        function (res) {
          if (res) {
            setTimeout(() => {
              toast.success("Switching to Subdomain");
              window.location.assign(res?.data?.url);
            }, 200);
          }
        },
        function (error) {
          if (error) {
          }
        }
      )
    );
  };
  const handleClick = () => {
    checkHost(host);
  };

  return (
    <div className="auth-page-wrap">
      <ToastContainer autoClose={2000} />
      <div className="auth-page-box d-flex">
        <div className="auth-right d-flex justify-content-center align-items-center">
          <div className="auth-cont-box">
            <div className="auth-cont-box-header text-center">
              <div className="auth-logo">
                <img src={Logo} alt="" />
              </div>
              <h2 className="auth-title">Sign in to Cultural Engagement</h2>
            </div>
            <div className="auth-form-box white-box radius-box">
              <Formik
                validationSchema={SetUpDomain}
                onSubmit={handleSetUpDomain}
                initialValues={{
                  name: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="form-input-wrap">
                      <Form.Label>Your Cultural Engagement Subdomain</Form.Label>
                      <div className="workspace-input-wrap">
                        <div className="workspace-input">
                          <AutosizeInput
                            placeholder="Subdomain"
                            className="auto-width-input"
                            autoFocus
                            name="name"
                            value={values.name?.toLocaleLowerCase()}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="workspace-text">
                          {host && host?.split(".")[1] == "enga"
                            ? ".enga.app"
                            : ".cultural-frontend-demo.dev.sayone.team"}
                        </div>
                      </div>
                      {errors.name && (
                        <span className={`form-error text-danger`}>
                          {errors.name}
                        </span>
                      )}
                    </div>
                    <div className="dontremember d-flex justify-content-end">
                      <Link to="/sendreminder">
                        {" "}
                        <span className="forgot-password-text">
                          Don't remember your company's url?
                        </span>
                      </Link>
                    </div>
                    <div className="button-wrap">
                      <Button
                        type="submit"
                        className="w-100"
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner
                            style={{ color: "white" }}
                            animation="border"
                            variant="warning"
                          />
                        ) : (
                          "Sign In"
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="new-button-wrap m-t-30">
                <Link onClick={handleClick} className="w-100 btn border-btn">
                  New to Cultural Engagement? Start your free trial
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const SetUpDomain = yup.object().shape({
  name: yup.string().required("Required"),
});
export default EnterSubdomain;
