import React, {useEffect, useState} from 'react';
import 'survey-creator/survey-creator.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as SurveyCreator from 'survey-creator';
import * as SurveyKO from 'survey-knockout'
import {useDispatch, useSelector} from "react-redux";
import {createSurvey} from '../../services';
import {useHistory} from 'react-router-dom';
import {detailSurvey, updateSurvey} from '~/modules/surveys/services';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Button from "react-bootstrap/Button";



function MyVerticallyCenteredModal(props) {
    const [text, setText] = useState("");
    const [isCopied, setIsCopied] = useState(false);
       const history =useHistory()
    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
            history.push('/surveymanagement')
        }, 200);
    };
    return (

        <Modal className="custom-modal confirmation-popup"
               {...props}
               size="md"
               aria-labelledby="contained-modal-title-vcenter"
               centered

        >
            <Modal.Body>
                <div>
                    {isCopied && isCopied ?
                        <span className="copied-text">
                             Copied!
                            </span>
                        :
                        ''
                    }

                    {/*<span className="search-close-icon-modal" style={{cursor: "pointer"}} onClick={() => props.onHide()}>×</span>*/}

                    <Row>
                        <Col xs={12} sm={12}>
                            <Form.Label>Survey Link</Form.Label>
                            <div className="form-input-wrap d-flex copylink-input-wrap">

                                <Form.Control type="text"
                                              name='last_name'
                                              placeholder="Your Last Name"
                                              value ={props?.surveyLink}
                                              disabled
                                              className="input-item"/>
                                <CopyToClipboard text={props?.surveyLink} onCopy={onCopyText}>

                                    <Button  className="copy-btn" >Copy</Button>


                                </CopyToClipboard>

                            </div>



                        </Col>


                    </Row>
                    {/*<button type="button" className="btn border-btn white-btn ml-5" style={{*/}
                    {/*    position: "absolute",*/}
                    {/*    transform: "translate(60%, -100%)"*/}
                    {/*}} onClick={() => props.onHide()}>Handle Model*/}
                    {/*</button>*/}
                </div>
            </Modal.Body>
        </Modal>
    );
}






const SurveyCreation = ({id,instituteArray}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [host, setHost] = useState('http://culturalengagement-dev.sayone.team')
    const { user,error,signUpError } = useSelector(state => state.auth);
    const [state, setState]= useState({
        modalShow:false,
        surveyLink:''

    })

    const [isCreateForm, setIsCreateForm] = useState(null);


    useEffect(() => {

        if (id) {
            dispatch(detailSurvey(id,function (error){
                if(error){
                   
                    history.push('/surveymanagement')
                }
            }))
        }

    }, [id])


    const {surveyDetail, IsSurveyData, editData} = useSelector(state => state.surveys);
    const hasError = useSelector(state => state.surveys.create_survey?.hasError)

    const handleModal = (link) => {

        setState({...state, modalShow: true,surveyLink:link })
    };

    useEffect(() => {
     
        var mainColor = "#808080";
        var mainHoverColor = "#808080";
        var textColor = "#808080";
        var headerColor = "#808080";
        var headerBackgroundColor = "#989390";
        var bodyContainerBackgroundColor = "#F4F4F4";

        var defaultThemeColorsSurvey = SurveyKO
            .StylesManager
            .ThemeColors["default"];
        defaultThemeColorsSurvey["$main-color"] = mainColor;
        defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
        defaultThemeColorsSurvey["$text-color"] = textColor;
        defaultThemeColorsSurvey["$header-color"] = headerColor;
        defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
        defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;

        var defaultThemeColorsEditor = SurveyCreator
            .StylesManager
            .ThemeColors["default"];
        defaultThemeColorsEditor["$primary-color"] = mainColor;
        defaultThemeColorsEditor["$secondary-color"] = mainColor;
        defaultThemeColorsEditor["$primary-hover-color"] = mainHoverColor;
        defaultThemeColorsEditor["$primary-text-color"] = textColor;
        defaultThemeColorsEditor["$selection-border-color"] = mainColor;


        SurveyKO
            .StylesManager
            .applyTheme();
        SurveyCreator
            .StylesManager
            .applyTheme();
        SurveyCreator
            .StylesManager
            .applyTheme();
        // if (!SurveyKO.Serializer.findClass("demographic questions")) {
        //     SurveyKO
        //         .ComponentCollection
        //         .Instance
        //         .add({
        //             name: "demographic questions",
        //             title: "Demographic Questions",
        //             elementsJSON: [
        //                 {
        //                     type: "dropdown",
        //                     name: "I am a",
        //                     title: "I am a",
        //                     choices: [
        //                         "None",
        //                         "Parent",
        //                         'Faculty',
        //                         'Staff'
        //                     ],
        //                     isRequired: true
        //                 }, {
        //                     type: "text",
        //                     name: "age",
        //                     title: "What is your Age?",
        //                     isRequired: true
        //                 },
        //                 {
        //                     type: "dropdown",
        //                     name: "gender",
        //                     title: "What is your Gender?",
        //                     choices: [
        //                         'Male',
        //                         'Female'
        //                     ],
        //                     isRequired: true
        //                 },
        //                 {
        //                     type: "radiogroup",
        //                     name: "heterosexual",
        //                     title: "Do you identify as Heterosexual?",
        //                     choices: [
        //                         "Yes",
        //                         "No"
        //
        //                     ],
        //                     isRequired: true
        //                 },
        //                 {
        //                     type: "dropdown",
        //                     name: "ethnicity",
        //                     title: "What is your Ethinicty?",
        //                     choices: [
        //                         "African American/Black",
        //                         "African American/Black, Dominican",
        //                         'African American/Black, Latino/a',
        //                         'Anglo/White',
        //                         'Asian Descent',
        //                         'Biracial',
        //                         'Latino/a',
        //                         'Mediterranean',
        //                         'Mixed ethnicity Asian and White',
        //
        //                     ],
        //                     isRequired: true
        //                 },
        //                 {
        //                     type: "text",
        //                     name: "academic",
        //                     title: "What is your Academic Classification?",
        //                     isRequired: true
        //                 },
        //             ],
        //         })
        // }

        if (!SurveyKO.Serializer.findClass("i am a")) {
            SurveyKO
                .ComponentCollection
                .Instance
                .add({
                    //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
                    name: "i am a",
                    //The text that shows on toolbox
                    title: "I am a",
                    //The actual question that will do the job
                    questionJSON: {
                        type: "dropdown",
                        choices: [
                            "Parent",
                            'Student',
                            'Teacher'
                        ],
                        isRequired: true
                    }
                });
        }
        if (!SurveyKO.Serializer.findClass("age")) {
            SurveyKO
                .ComponentCollection
                .Instance
                .add({
                    //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
                    name: "age",
                    //The text that shows on toolbox
                    title: "Age",
                    //The actual question that will do the job
                    questionJSON: {
                        type: "dropdown",
                        choices: [
                            "<18",
                            "18-25",
                            '26-32',
                            '33-40',
                            '>40'
                        ],
                        isRequired: true
                    }
                });
        }
        if (!SurveyKO.Serializer.findClass("gender")) {
            SurveyKO
                .ComponentCollection
                .Instance
                .add({
                    //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
                    name: "gender",
                    //The text that shows on toolbox
                    title: "Gender",
                    //The actual question that will do the job
                    questionJSON: {
                        type: "dropdown",
                        name: "gender",
                        title: "What is your Gender?",
                        choices: [
                            'Male',
                            'Female'
                        ],
                        isRequired: true
                    }
                });
        }

        if (!SurveyKO.Serializer.findClass("heterosexual")) {
            SurveyKO
                .ComponentCollection
                .Instance
                .add({
                    //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
                    name: "heterosexual",
                    //The text that shows on toolbox
                    title: "Heterosexual",
                    //The actual question that will do the job
                    questionJSON: {
                        type: "radiogroup",
                        choices: [
                            'Yes',
                            'No'
                        ],
                        isRequired: true
                    }
                });
        }
        if (!SurveyKO.Serializer.findClass("ethnicity")) {
            SurveyKO
                .ComponentCollection
                .Instance
                .add({
                    //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
                    name: "ethnicity",
                    //The text that shows on toolbox
                    title: "Ethnicity",
                    //The actual question that will do the job
                    questionJSON: {
                        type: "dropdown",
                        choices: [
                            "African American/Black",
                            "African American/Black, Dominican",
                            'African American/Black, Latino/a',
                            'Anglo/White',
                            'Asian Descent',
                            'Biracial',
                            'Latino/a',
                            'Mediterranean',
                            'Mixed ethnicity Asian and White',
                        ],
                        isRequired: true
                    }
                });
        }
        if (!SurveyKO.Serializer.findClass("academic")) {
            SurveyKO
                .ComponentCollection
                .Instance
                .add({
                    //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
                    name: "academic",
                    //The text that shows on toolbox
                    title: "Academic",
                    //The actual question that will do the job
                    questionJSON: {
                        type: "text",
                        isRequired: true

                    }
                });
        }
        const DateEditor = {
            render: function (editor, htmlElement) {
                var input = document.createElement("input");
                input.className = "form-control svd_editor_control";
                input.type = "date";
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();
                today = yyyy + '-' + mm + '-' + dd;
                input.setAttribute('min',today)
                input.style.width = "100%";
                htmlElement.appendChild(input);
                input.onchange = function () {
                    editor.koValue(input.value);
                }
                editor.onValueUpdated = function (newValue) {
                    input.value = editor.koValue() || "";
                }
                input.value = editor.koValue() || "";
            }
        };

        SurveyCreator
            .SurveyPropertyEditorFactory
            .registerCustomEditor("date", DateEditor);
        SurveyKO.Serializer.addProperty("survey",
            {name: "Expiry:date", category: "general", visibleIndex: 3}
        );
        SurveyKO.Serializer.findProperty("page", "title").visible = false;
        SurveyKO.Serializer.findProperty("page", "description").visible = false;
        SurveyKO.settings.allowShowEmptyTitleInDesignMode = false;
        if (id, IsSurveyData) {


            var options = {showEmbeddedSurveyTab: false,
                showJSONEditorTab: false,
                showLogicTab:false,
                showTestSurveyTab:false,
                showTranslationTab:false,
                questionTypes: ["text", "checkbox", "radiogroup", "dropdown",'comment','rating','raking','boolean','image','html','imagepicker'],
            }; //see examples below

            var surveyCreator = new SurveyCreator.SurveyCreator("surveyCreatorContainer", options);

            const widgetNames = ["demographic questions",'i am a','age','gender','heterosexual','ethnicity','academic'];
            const widgetToolboxItems = [];
            for(let i = 0; i < widgetNames.length; i ++) {
                widgetToolboxItems.push(surveyCreator.toolbox.getItemByName(widgetNames[i]));
            }



            surveyCreator
                .onQuestionAdded
                .add(function (sender, options) {
                    var q = options.question;
                    var t = q.getType();
                    switch (options.question.getType()){
                        case "demographic questions" :
                            q.name = 'Demographic Questions'
                            surveyCreator.toolbox.removeItem(widgetNames[0]);
                            break
                        case 'i am a':
                            q.name = 'I am a'
                            surveyCreator.toolbox.removeItem(widgetNames[1]);
                            break
                        case 'age':
                            q.name = 'What is your Age?'
                            surveyCreator.toolbox.removeItem(widgetNames[2]);
                            break
                        case 'gender':
                            q.name = 'What is your Gender?'
                            surveyCreator.toolbox.removeItem(widgetNames[3]);
                            break
                        case 'heterosexual':
                            q.name = 'Do you identify as Heterosexual?'
                            surveyCreator.toolbox.removeItem(widgetNames[4]);
                            break
                        case 'ethnicity':
                            q.name = 'What is your Ethnicity?'
                            surveyCreator.toolbox.removeItem(widgetNames[5]);
                            break
                        case 'academic':
                            q.name = 'What is your Academic Classification?'
                            surveyCreator.toolbox.removeItem(widgetNames[6]);
                            break

                        default  :
                            q.name = ''

                    }
                });


            surveyCreator.onElementDeleting.add(function(sender, options) {

                if(options.element.getType() == widgetNames[0]) {
                    // add the question back to the toolbox
                    surveyCreator.toolbox.addItem(widgetToolboxItems[0]);
                }else if(options.element.getType() == widgetNames[1]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[1]);
                }else if(options.element.getType() == widgetNames[2]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[2]);
                }else if(options.element.getType() == widgetNames[3]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[3]);
                }else if(options.element.getType() == widgetNames[4]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[4]);
                }else if(options.element.getType() == widgetNames[5]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[5]);
                }else if(options.element.getType() == widgetNames[6]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[6]);
                }
            });


            surveyCreator.showToolbox = "right";
            surveyCreator.showPropertyGrid = false;
            surveyCreator.allowControlSurveyTitleVisibility =false

            let tempObj = {
                title: surveyDetail?.name,
                description: surveyDetail?.description,
                pages: surveyDetail?.questions,
                Expiry: surveyDetail?.expiry_date
            }
            surveyCreator.text = tempObj ? JSON.stringify(tempObj) : {}

            surveyCreator
                .toolbarItems
                .push({
                    id: "custom-preview",
                    visible: true,
                    title: "Publish",
                    action: function () {
                        if (surveyCreator?.koObjects()?.find((el) => el?.value?.customQuestion)) {
                        let yourNewJSON = JSON.parse(surveyCreator.text)
                            let newArray = [];
                            for(let i = 0; i<surveyCreator?.koObjects()?.length; i++){
                                if(surveyCreator?.koObjects()[i].value?.customQuestion){
                                    newArray.push(surveyCreator?.koObjects()[i].value?.customQuestion?.json)
                                }

                            }

                        yourNewJSON.is_published = true
                        let prop="publish"
                        dispatch(updateSurvey(yourNewJSON,id, history,prop,newArray ? newArray :[],instituteArray ? instituteArray :[],user ? user : {},surveyDetail ? surveyDetail : {},function (response){
                            // handleModal(`${response?.link}`)
                            history.push(`/survey_assignment/${response.id}`)

                        },function (error){
                            if(error){
                                surveyCreator.showQuestionEditor(surveyCreator.survey);
                            }
                        }))

                    }else {
                            let yourNewJSON = JSON.parse(surveyCreator.text)
                            yourNewJSON.is_published = true
                            let prop="publish"
                            dispatch(updateSurvey(yourNewJSON,id, history,prop,'',instituteArray ? instituteArray :[],user ? user : {},surveyDetail ? surveyDetail : {},function (response){
                                // handleModal(`${response?.link}`)
                                history.push(`/survey_assignment/${response.id}`)

                            },function (error){
                                if(error){
                                    surveyCreator.showQuestionEditor(surveyCreator.survey);
                                }
                            }))

                        }
                    }
                });


            function saveMySurvey() {
                var yourNewSurveyJSON = surveyCreator.text;
                if (surveyCreator?.koObjects()?.find((el) => el?.value?.customQuestion)) {
                    let newArray = [];
                    for(let i = 0; i<surveyCreator?.koObjects()?.length; i++){
                        if(surveyCreator?.koObjects()[i].value?.customQuestion){
                            newArray.push(surveyCreator?.koObjects()[i].value?.customQuestion?.json)
                        }

                    }

                    let prop = 'not_published'
                    dispatch(updateSurvey(JSON.parse(yourNewSurveyJSON), id, history, prop,newArray ? newArray : [],instituteArray ? instituteArray :[],user ? user : {},surveyDetail ? surveyDetail : {}, function (response) {

                    }, function (error) {
                        if (error) {
                            surveyCreator.showQuestionEditor(surveyCreator.survey);
                        }
                    }))
                }else{
                    let prop = 'not_published'
                    dispatch(updateSurvey(JSON.parse(yourNewSurveyJSON), id, history, prop,'',instituteArray ? instituteArray :[],user ? user :{},surveyDetail ? surveyDetail : {}, function (response) {

                    }, function (error) {
                        if (error) {
                            surveyCreator.showQuestionEditor(surveyCreator.survey);
                        }
                    }))
                }

            }
            surveyCreator.saveSurveyFunc = saveMySurvey;
            var questions = surveyCreator.survey.getAllQuestions();

            questions.forEach(q => {
                if(widgetNames.length){
                    for(let i=0; i<widgetNames.length; i++) {
                        if (q.getType() == widgetNames[i]) {
                            surveyCreator.toolbox.removeItem(widgetNames[i]);
                        }
                    }
                }


                })

        } else if (!id) {
            setIsCreateForm(true);



            var options = {showEmbeddedSurveyTab: false,
                showJSONEditorTab: false,
                showLogicTab:false,
                showTestSurveyTab:false,
                showTranslationTab:false,
                questionTypes: ["text", "checkbox", "radiogroup", "dropdown",'comment','rating','raking','boolean','image','html','imagepicker']
            }; //see examples below

            var surveyCreator = new SurveyCreator.SurveyCreator("surveyCreatorContainer", options);


            const widgetNames = ["demographic questions",'i am a','age','gender','heterosexual','ethnicity','academic'];
            const widgetToolboxItems = [];


            for(let i = 0; i < widgetNames.length; i ++) {
                widgetToolboxItems.push(surveyCreator.toolbox.getItemByName(widgetNames[i]));
            }
            function getOneTimeToolboxItem(name) {
                for(var i = 0; i < widgetToolboxItems.length; i ++)
                    if(widgetToolboxItems[i].name === name) return widgetToolboxItems[i];
                return null;
            }
            function isOneTimeWidget(name) {
                return widgetNames.indexOf(name) > -1;
            }
            function hasWidgetQuestion(widgetName) {
                var questions = surveyCreator.survey.getAllQuestions();
                for(var i = 0; i < questions.length; i ++) {
                    if(questions[i].getType() === widgetName) return true;
                }
                return false;
            }
            function checkUndoRedo() {
                widgetToolboxItems.forEach(item => {
                    var hasQ = hasWidgetQuestion(item.name);
                    var hasToolboxItem = !!surveyCreator.toolbox.getItemByName(item.name);
                    if(hasQ !== hasToolboxItem) return;
                    if(hasQ) {
                        surveyCreator.toolbox.removeItem(item.name);
                    } else {
                        surveyCreator.toolbox.addItem(item);
                    }
                });
            }
            surveyCreator.onAfterUndo.add(checkUndoRedo);
            surveyCreator.onAfterRedo.add(checkUndoRedo);
            surveyCreator
                .onQuestionAdded
                .add(function (sender, options) {
                    var q = options.question;
                    var t = q.getType();
                    switch (options.question.getType()){
                        case "demographic questions" :
                            q.name = 'Demographic Questions'
                            surveyCreator.toolbox.removeItem(widgetNames[0]);
                            break
                        case 'i am a':
                            q.name = 'I am a'
                            surveyCreator.toolbox.removeItem(widgetNames[1]);
                            break
                        case 'age':
                            q.name = 'What is your Age?'
                            surveyCreator.toolbox.removeItem(widgetNames[2]);
                            break
                        case 'gender':
                            q.name = 'What is your Gender?'
                            surveyCreator.toolbox.removeItem(widgetNames[3]);
                            break
                        case 'heterosexual':
                            q.name = 'Do you identify as Heterosexual?'
                            surveyCreator.toolbox.removeItem(widgetNames[4]);
                            break
                        case 'ethnicity':
                            q.name = 'What is your Ethnicity?'
                            surveyCreator.toolbox.removeItem(widgetNames[5]);
                            break
                        case 'academic':
                            q.name = 'What is your Academic Classification?'
                            surveyCreator.toolbox.removeItem(widgetNames[6]);
                            break

                        default  :
                            q.name = '';

                    }
                });


            surveyCreator.onElementDeleting.add(function(sender, options) {

                if(options.element.getType() == widgetNames[0]) {
                    // add the question back to the toolbox
                    surveyCreator.toolbox.addItem(widgetToolboxItems[0]);
                }else if(options.element.getType() == widgetNames[1]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[1]);
                }else if(options.element.getType() == widgetNames[2]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[2]);
                }else if(options.element.getType() == widgetNames[3]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[3]);
                }else if(options.element.getType() == widgetNames[4]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[4]);
                }else if(options.element.getType() == widgetNames[5]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[5]);
                }else if(options.element.getType() == widgetNames[6]){
                    surveyCreator.toolbox.addItem(widgetToolboxItems[6]);
                }
            });



            surveyCreator.showToolbox = "right";
            surveyCreator.showPropertyGrid = false;
            surveyCreator.allowControlSurveyTitleVisibility =false

            surveyCreator
                .toolbarItems
                .push({
                    id: "custom-preview",
                    visible: true,
                    title: "Publish",
                    action: function () {
                        if(surveyCreator?.koObjects()?.find((el)=>el?.value?.customQuestion)) {
                            let newArray = [];
                            for(let i = 0; i<surveyCreator?.koObjects()?.length; i++){
                                if(surveyCreator?.koObjects()[i].value?.customQuestion){
                                    newArray.push(surveyCreator?.koObjects()[i].value?.customQuestion?.json)
                                }

                            }
                            let yourNewJSON = JSON.parse(surveyCreator.text)
                            yourNewJSON.is_published = true
                            let prop = 'publish'
                   
                            dispatch(createSurvey(yourNewJSON, history, prop,newArray ? newArray : [],instituteArray ? instituteArray :[],user ? user : {},   function (response) {
                                // handleModal(`${response?.link}`)
                                history.push(`/survey_assignment/${response.id}`)
                            }, function (error) {
                                if (error) {
                                    surveyCreator.showQuestionEditor(surveyCreator.survey);
                                }
                            }))
                        }else {

                            let yourNewJSON = JSON.parse(surveyCreator.text)
                            yourNewJSON.is_published = true
                            let prop = 'publish'
                            dispatch(createSurvey(yourNewJSON, history, prop,'',instituteArray ? instituteArray :[],user ? user : {}, function (response) {
                                // handleModal(`${response?.link}`)
                                history.push(`/survey_assignment/${response.id}`)
                            }, function (error) {
                                if (error) {
                                    surveyCreator.showQuestionEditor(surveyCreator.survey);
                                }
                            }))
                        }

                    }
                });

            function saveMySurvey() {
                var yourNewSurveyJSON = surveyCreator.text;
                //send updated json in your storage

                if(surveyCreator?.koObjects()?.find((el)=>el?.value?.customQuestion)){
                    let newArray = [];
                    for(let i = 0; i<surveyCreator?.koObjects()?.length; i++){
                        if(surveyCreator?.koObjects()[i].value?.customQuestion){
                            newArray.push(surveyCreator?.koObjects()[i].value?.customQuestion?.json)
                        }

                    }
                    let prop = 'not_published'
                    dispatch(createSurvey(JSON.parse(yourNewSurveyJSON), history,prop,newArray ? newArray : [],instituteArray ? instituteArray :[],user ? user : {},function(response){

                    },function (error){
                        if(error){
                            surveyCreator.showQuestionEditor(surveyCreator.survey);
                        }
                    }))
                }else {
                    let prop = 'not_published'
                    dispatch(createSurvey(JSON.parse(yourNewSurveyJSON), history,prop,'',instituteArray ? instituteArray :[],user ? user : {},function(response){

                    },function (error){
                        if(error){
                            surveyCreator.showQuestionEditor(surveyCreator.survey);
                        }
                    }))
                }

                          }

            surveyCreator.saveSurveyFunc = saveMySurvey;
            var questions = surveyCreator.survey.getAllQuestions();
            questions.forEach(q => {if(q.getType()==widgetName) surveyCreator.toolbox.removeItem(widgetName); })

        }

        // let tabs = document.querySelector('.editor-tabs')
        // if (tabs) {
        //     tabs.setAttribute('style', 'display:none')
        // }


        let pageBody = document.getElementsByClassName('page-body')


        Array.from(pageBody).forEach((el) => {
            el.setAttribute('style', 'width:1280px; overflow:hidden')
        })
        let elementPaid = document.getElementsByClassName('svd_commercial_product svd-main-color')
        Array.from(elementPaid).forEach((el) => {
            el.setAttribute('style', 'display:none')
        })
        let elmentPaidText = document.getElementsByClassName('svd_commercial_text')
        Array.from(elmentPaidText).forEach((el) => {
            el.setAttribute('style', 'display:none')
        })
        let sidebar = document.getElementsByClassName('svd-designer-container--left-side')
        Array.from(sidebar).forEach((el) => {
            el.setAttribute('style', 'width:35%; max-width: 40%;flex-basis: 10%;')
        })
    }, [id,IsSurveyData,instituteArray])


    return (
        <>
            {id && surveyDetail && surveyDetail ?

                (
                    <>
                        {
                            !IsSurveyData ?
                                <div className='loader'>
                                    <Spinner animation="border" variant="danger"/>
                                </div>
                                :
                                <div className="profile-wrapper">
                                    <Row>
                                        <Col md={12}>

                                            <div id="surveyCreatorContainer" ></div>
                                            <ToastContainer  autoClose={2000}/>

                                        </Col>
                                    </Row>

                                    <MyVerticallyCenteredModal
                                        show={state.modalShow}
                                        surveyLink={state.surveyLink}
                                        onHide={() => setState({...state,modalShow: false})}
                                    />
                                </div>
                        }

                    </>

                ) : (

                    <div className="profile-wrapper">
                        <Row>
                            <Col md={12}>

                                <div id="surveyCreatorContainer" ></div>
                                <ToastContainer autoClose={2000} />

                            </Col>
                        </Row>

                        <MyVerticallyCenteredModal
                            show={state.modalShow}
                            surveyLink={state.surveyLink}
                            onHide={() => setState({...state,modalShow: false})}
                        />
                    </div>

                )

            }


        </>

    );
}

export default SurveyCreation;
