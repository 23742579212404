import React, {useEffect, useRef, useState,} from "react";
// import Breadcrumb from '../../../../../layout/breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AccordianItem from './components/accordian/container/accordian'
import Modal from 'react-bootstrap/Modal';
import CourseMembers from './components/coursemembers/container/'
import {useDispatch, useSelector} from 'react-redux';
import ModulePopView from '../../../../../shared/components/modulepopview/index';
import editIco from '~/assets/images/icons/edit.svg';
import deleteIco from '~/assets/images/icons/delete.svg';
import previewIco from '~/assets/images/icons/preview.svg';
import enrolIco from '~/assets/images/icons/enroll_users.svg';
import clonIcon from '~/assets/images/icons/copy.svg';
import {
    cloneCourse,
    deleteCourse,
    getDraftCoursesManagerList,
    getManagerCourse,
    getManagerCoursesList,
    getManagerModule,
    getAdminCourse,
    getAllQuiz
} from "../../../services";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {Form} from "formik";
import { Popconfirm } from 'antd';


function CourseManagementEdit(props) {
    const urlId = props?.match?.params?.id;
    const dispatch = useDispatch()
    const history = useHistory()
    const response = useSelector(state => state?.course?.get_course_manager?.response);
    const adminResponse = useSelector(state => state?.course?.admin_course?.response);
    const loading = useSelector(state => state?.course?.get_course_manager?.loading);
    const adminloading = useSelector(state => state?.course?.admin_course?.loading);
    let quizResponse = useSelector(state => state?.course?.quiz_list?.response);
    const { hideLpQuizes } = useSelector((state) => state.settings);
    const { user } = useSelector(state => state.auth);
    const [visible, setVisible] = React.useState(false);
    const [state, setState] = useState({
        course:[],
        popupView:false,
    })
    const [quizzes, setQuizzes] = useState([])

    useEffect(()=>{
        if(!hideLpQuizes){
            dispatch(getAllQuiz());
        }
        if(history.location.pathname.includes('/coursemanagementedit')){
            dispatch(getManagerCourse(urlId,function (res){
            }))
        }else {
            dispatch(getAdminCourse(urlId,function (res){
            }))
        }

    },[])

    useEffect(()=>{
        if(history.location.pathname.includes('/coursemanagementedit')) {

            setState({...state, course: response})
        }else {

            setState({...state, course: adminResponse})
        }
    },[response,adminResponse])

    useEffect(()=>{
        if(history.location.pathname.includes('/coursemanagementedit')) {

            if(response?.quizzes && quizResponse?.results &&  response?.quizzes?.length && quizResponse?.results?.length){
                let newArray = quizResponse?.results?.map(item => {
                    let temp = {...item, isChecked: response?.quizzes?.includes(item.id)};
                    return temp
                })

                let selectedArray = newArray?.filter((el) => {
                    return el.isChecked
                })
                setQuizzes(selectedArray)
            }
        }else {

            if(adminResponse?.quizzes && quizResponse?.results &&  adminResponse?.quizzes?.length && quizResponse?.results?.length){
                let newArray = quizResponse?.results?.map(item => {
                    let temp = {...item, isChecked: adminResponse?.quizzes?.includes(item.id)};
                    return temp
                })

                let selectedArray = newArray?.filter((el) => {
                    return el.isChecked
                })
                setQuizzes(selectedArray)
            }
        }

    },[response,adminResponse,quizResponse])


    const handleEdit = () =>{
        history.push(`/editcourse/${state?.course?.id}`)
    }
    const handleDeleteCourse = (row) => {
        dispatch(deleteCourse(row,history));
    };

    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const showPopconfirm = () => {
        setVisible(true);
        setConfirmLoading(false)
    };

    const handleOk = () => {
        setConfirmLoading(true)
        handleDeleteCourse(state.course)
    };

    const handleCancel = () => {
        setVisible(false);
    };

    useEffect(()=>{
        let element = document.getElementById('headerTitle')
        if(element){
            switch (true){
                case history.location.pathname.includes('/coursemanagementedit'):
                    element.innerHTML = 'Course Library'
                    break;
                case history.location.pathname.includes('/course/admin'):
                    element.innerHTML = 'Course Library'
                    break;
                case '/draft/courses':
                    element.innerHTML = 'Course Library'
                    break;
                default :
                    'Profile'
            }

        }
    },[])
    const handleEnroll = (el) =>{
        history.push(`/course/${el?.id}/enroll_users`)
    }
    const handleClone  = (id) =>{
        dispatch(cloneCourse(id,history))
    }
    let adminLoad = false
    const handlePreview = () =>{
        setState({...state, popupView: true})
    }
    if(loading || adminloading) {
        return (
            <div className="loader-middle-wrap">
                <Spinner animation="border" variant="danger" />
            </div>
        );

    }else{
        return (
            <div className="learning-path-listing">
                <ToastContainer autoClose={2000}/>
                <div className="teammember-details-header">
                  
                  <div className="d-flex justify-content-end align-items-center m-b-20">
                      
                      <div className="button-wrap double-btn">
                      
                      <button className="btn border-btn" onClick={(e)=>history.push({pathname: '/coursemanagement',state:{key:'Admin'} })}>Go Back</button>
                      
                      </div>
                  </div>
              </div>
                {/* <Breadcrumb parent="Learning paths" title="Learning pathname" /> */}
                <Row className="justify-content-center">
                    <div className="det-middle-align">
                        <h3 className="sec-title m-b-20">{state?.course?.name}</h3>
                        <div className="coursemanageedit-wrap learning-content-wrap ">
                            <div className="learning-image-wrap d-flex">
                                <div className="learing-banner">
                                    <img src={state?.course?.image}/>
                                </div>
                                <div className="btn-icons-rightfloat d-flex align-items-center m-b-20">

                                    <div className="btn-wrap vertical-btn d-flex flex-column p-l-30">

                                        {state?.course && state?.course?.is_owned_by_admin && user?.is_admin_institution == true  ?
                                                  <>

                                                      { user?.user_type == 'admin' || user?.user_type == 'manager' || state?.course?.created_by == user?.user_id   ?
                                                                  <>
                                                              {!state.course.is_published &&
                                                              < button onClick={() => handleEdit(state?.course?.id)}
                                                                       className="btn new-icon-btn"><span className="icon-round-box"><img
                                                                  src={editIco}/></span><span>Edit</span></button>




                                                              }
                                                                  </>
                                                          :
                                                          <>
                                                          <>
                                                              <button onClick={() => handlePreview()} className="btn new-icon-btn"><span
                                                                  className="icon-round-box"><img
                                                                  src={previewIco}/></span><span>Preview</span></button>

                                                          </>

                                                          <>
                                                          <button  onClick={() => handleClone(state?.course?.id)} className="btn new-icon-btn"><span
                                                          className="icon-round-box"><img src={clonIcon}/></span><span>Clone</span></button>
                                                          </>


                                                      </>
                                                      }

                                                      { user.user_type == 'admin' || user.user_type == 'manager' || state?.course?.created_by == user?.user_id   ?
                                                      <Popconfirm
                                                         title={<div>Are you sure you want to delete this course? <br/> Please note, all the enrollments shall be deleted too!</div>}
                                                          visible={visible}
                                                          onConfirm={handleOk}
                                                          okText="Yes"
                                                          cancelText="No"
                                                          placement="bottom"
                                                          okButtonProps={{loading: confirmLoading}}
                                                          onCancel={handleCancel}
                                                      >
                                                          <button onClick={showPopconfirm} className="btn new-icon-btn"><span
                                                              className="icon-round-box"><img
                                                              src={deleteIco}/></span><span>Delete</span></button>
                                                      </Popconfirm>

                                                          :
                                                          ''

                                                        }

                                                        <>
                                                            {user.user_type == 'admin' || user.user_type == 'manager' || state?.course?.created_by == user?.user_id ?
                                                                <>
                                                                    {state?.course?.is_published &&
                                                                    <button
                                                                        onClick={() => handleEnroll(state?.course)}
                                                                        className="btn new-icon-btn"><span
                                                                        className="icon-round-box"><img
                                                                        src={enrolIco}/></span><span>Enroll New Users</span>
                                                                    </button>

                                                                    }
                                                                </>
                                                                :
                                                                ''
                                                            }
                                                            </>

                                                      {user.user_type == 'admin' || user.user_type == 'manager' || state?.course?.created_by == user?.user_id ?
                                                          <>
                                                              <button onClick={() => handlePreview()}
                                                                      className="btn new-icon-btn"><span
                                                                  className="icon-round-box"><img
                                                                  src={previewIco}/></span><span>Preview</span></button>

                                                          </>
                                                          :
                                                          ''
                                                      }
                                                      {user.user_type == 'admin' || user.user_type == 'manager' || state?.course?.created_by == user?.user_id ?
                                                          <>
                                                              {state?.course?.is_published ?
                                                                  <>
                                                                      <button
                                                                          onClick={() => handleClone(state?.course?.id)}
                                                                          className="btn new-icon-btn"><span
                                                                          className="icon-round-box"><img src={clonIcon}/></span><span>Clone</span>
                                                                      </button>
                                                                  </>
                                                                  :
                                                                  ''
                                                              }
                                                          </>
                                                          :
                                                          ''
                                                      }

                                                  </>
                                                    :

                                                 <>

                                                     <button  onClick={() => handleClone(state?.course?.id)} className="btn new-icon-btn"><span
                                                         className="icon-round-box"><img src={clonIcon}/></span><span>Clone</span></button>

                                                         <>
                                                             <button onClick={() => handlePreview()} className="btn new-icon-btn"><span
                                                                 className="icon-round-box"><img
                                                                 src={previewIco}/></span><span>Preview</span></button>

                                                         </>


                                                 </>

                                        }


                                    </div>

                                </div>

                            </div>
                            <div className="learning-det-cont">
                                <div className="learning-det-boxes">
                                    <h4 className="sec-sub-head">Description</h4>
                                    <p>{state?.course?.description}
                                    </p>
                                </div>
                            </div>
                            <div className="learning-det-cont m-t-50">
                                <div className="learning-det-boxes">
                                    <div className="d-flex justify-content-between">

                                        <h4 className="sec-sub-head">Modules</h4>


                                        <div className="d-flex  align-items-center justify-content-end learing-detail-progress">
                                            {/*<div className="learning-progress progress">*/}
                                            {/*<div className="progress-bar" style={{ width: '70%' }}>&nbsp;</div>*/}
                                            {/*</div>*/}
                                            {/*<span className="progress-text">1/4</span>*/}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/*<div>*/}
                            {state?.course?.modules && state?.course?.modules?.length > 0 ?
                                < AccordianItem modules={state.course.quizzes && state?.course?.quizzes?.length ? [...state?.course?.modules, ...quizzes?.map((el)=>{
                                    return {
                                        ...el,
                                        template:'quiz'
                                    }
                                }) ] :state?.course?.modules}/>
                                :
                                'No Modules'
                            }


                        </div>
                    </div>

                </Row>
                <ModulePopView
                    show={state?.popupView}
                    values={state?.course?.quizzes && state?.course?.quizzes?.length ? [...state?.course?.modules, ...quizzes?.map((el)=>{
                        return {
                            ...el,
                            template:'quiz'
                        }
                    }) ] :state?.course?.modules}

                    courseId={state?.course?.courseId}
                    name={state?.course?.name}
                    course={state?.course}
                    onHide={() => setState({...state, popupView: false})}

                />
            </div>

        )
    }
};
export default CourseManagementEdit;
