import React from "react";
import { Bar } from "react-chartjs-2";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Select } from 'antd';
const { Option } = Select;
const children = [];

for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}


const mocked_data = {
    labels: ["1", "2", "3", "4", "5"],
    spend_per_channel: {
        gender: [
            {
                label: "Male",
                data: [1, 0, 10, 0, 1]
            },
            {
                label: "Female",
                data: [10, 0, 1, 0, 0]
            },

        ],
        age: [
            {
                label: "15-16",
                data: [0, 0, 0, 0, 0]
            },
            {
                label: "17-18",
                data: [0, 10, 0, 0, 0]
            },
            {
                label: "19-20",
                data: [0, 10, 0, 0, 0]
            },
            {
                label: "21-22",
                data: [0, 10, 0, 0, 0]
            },
            {
                label: "23-24",
                data: [0, 10, 0, 0, 0]
            }
        ],
        ethnicity: [
            {
                label: "Black",
                data: [0, 10, 0, 0, 10]
            },
            {
                label: "White",
                data: [10, 0, 0, , 0]
            },
            {
                label: "Asian",
                data: [0, 10, 1, 10, 0]
            },
            {
                label: "Prefer not to say",
                data: [0, 0, 10, 0, 0]
            }

        ]
    }
};

const MyChart2 = () => {
    const CHART_COLORS = [
        "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#1b59ff", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"
    ];

    const genderdata = mocked_data.spend_per_channel.gender.map(
        (gender, index) => {
            return {
                value:gender.label,
                label: gender.label,
                backgroundColor: CHART_COLORS[index],
                data: gender.data,
                hidden: false,
                stack: 1
            };
        }
    );

    const ethnicitydata = mocked_data.spend_per_channel.ethnicity.map((ethnicity, index) => {
        return {
            label: ethnicity.label,
            value:ethnicity.label,
            backgroundColor: CHART_COLORS[index],
            data: ethnicity.data,
            hidden: false,
            stack: 1
        };
    });
    const agedata = mocked_data.spend_per_channel.age.map((age, index) => {
        return {
            label: age.label,
            value:age.label,
            backgroundColor: CHART_COLORS[index],
            data: age.data,
            hidden: false,
            stack: 1
        };
    });

    const newdataset = [genderdata, ethnicitydata, agedata];
    const spnedperchanneldata = newdataset.flat();

    const [data, setData] = React.useState(spnedperchanneldata);
    const options = {
        legend: {
            position: "right",
            labels: {
                generateLabels: function (chart) {
                    return Chart.defaults.global.legend.labels.generateLabels
                        .apply(this, [chart])
                        .filter(function (item, i) {
                            return i > 1;
                        });
                },
                boxWidth: 10,
                usePointStyle: true
            },
            onClick: (e, legend) => {
                alert('ddddd')
                onclick(legend);
            }
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                align: "center",
                labels: {
                    usePointStyle: true,
                },
            },
        },
    };

    const onclick = React.useCallback(
        legend => {

            let newData = data.map(item => {
                if (item.label === legend.text) item.hidden = !item.hidden;
                return item;
            });

            setData(newData);
        },
        [data, setData]
    );
    const  onDeselect = (value) => {
        let dummyData = [...data]
        let findIndex = dummyData.findIndex((el)=>el.label == value)
        if(findIndex > -1) {

            dummyData[findIndex].hidden   = true
            // dummyData.splice(findIndex, 1)
            setData(dummyData)
        }
    }



    const handleSelect = (value) => {
        let dummyData = [...data]
        let findIndex = dummyData.findIndex((el)=>el.label == value)
        if(findIndex > -1) {

            dummyData[findIndex].hidden   = false
            // dummyData.splice(findIndex, 1)
            setData(dummyData)
        }

    }
    return (
        <div className="questions-graph-wrap">
            <Row>
                <Col md={4}>
                    <div className="whitebox padding-box radiusbox shadow-box border-box">
                        <div className="graph-filter-wrap">
                            <div className="multiselect-wrap">
                                <Select mode="multiple" allowClear style={{ width: '100%', }} placeholder="Please select" defaultValue={[...genderdata]}
                                        options={genderdata}    onDeselect={onDeselect} onSelect={handleSelect}

                                />
                            </div>
                            <div className="multiselect-wrap">
                                <Select mode="multiple" allowClear style={{ width: '100%', }} placeholder="Please select" defaultValue={[...ethnicitydata]}
                                        onSelect={handleSelect} options={ethnicitydata}   onDeselect={onDeselect} />
                            </div>
                            <div className="multiselect-wrap">
                                <Select mode="multiple" allowClear style={{ width: '100%', }} placeholder="Please select" defaultValue={[...agedata]}
                                         onSelect={handleSelect} options={agedata} onDeselect={onDeselect} />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="whitebox padding-box radiusbox shadow-box border-box">
                        <Bar
                            data={{
                                labels: ["1", "2", "3", "4", "5"],
                                datasets: data
                            }}
                            width={100}
                            height={50}
                            options={options}
                        />
                    </div>
                </Col>
            </Row>
        </div>

    );
};

export default MyChart2;
