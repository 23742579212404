import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from "formik";
import { supportPost,
    get_Support_Contact,

 } from './services';
 import * as yup from "yup";
 import {useDispatch, useSelector} from "react-redux";
 import  { useState ,useEffect} from 'react';
 import { useHistory} from 'react-router-dom';
 import Spinner from "react-bootstrap/Spinner";
import DefaultLogo from "~/assets/images/logo-small.svg";
import {Helmet} from "react-helmet";

function SupportUs() {
    const history=useHistory();
    const dispatch = useDispatch();
    const responseContact=useSelector(state => state.support.get_contact_support?.response)
    const loadingContact=useSelector(state => state.support.get_contact_support?.loading)


    useEffect(() => {
        let element = document.getElementById('headerTitle')
        if(element){
          switch (history.location.pathname){
             case '/support':
                element.innerHTML = 'Support'
                break;

            default :
               'SUPPORT'

          } }


    }, [])

    useEffect(() => {

         dispatch(get_Support_Contact())
    }, [])




    const handleSubmit = (values,onSubmitProps) => {

        const formData=new FormData();

        formData.append('email',values.email)
        formData.append('message',values.message)
        formData.append('mobile_number',values.number)
        formData.append('name',values.Name);

        dispatch(supportPost(formData));
         setTimeout(()=>{
            onSubmitProps.resetForm();
         },2000);

     };

    return (
        <div className="supportus-page-wrap">
            <Helmet>
                <title>Help - Cultural Engagement  </title>
            </Helmet>
            <Row>
                <Col md={6} lg={4}>
                    <div className="whitebox shadow-box support-box">
                        <h4 className="common-card-title m-b-20">Leave A Message</h4>

                        <Formik
                        validationSchema={supportSchema}
                        onSubmit={handleSubmit}
                        initialValues={{
                           Name:'' ,
                           number:'',
                           email: '',
                           message:''
                        }}
                     >
                        {({
                           handleSubmit,
                           handleChange,
                           values,
                           errors,
                        }) => (



                        <Form noValidate onSubmit={handleSubmit} className="d-block">
                            <div className="form-input-wrap">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                 type="text"
                                 name="Name"
                                 className="input-item"
                                 placeholder="Your Name"
                                 value={values.Name}
                                 onChange={handleChange}
                                isInvalid={!!errors.Name}
                               />
                                 <Form.Control.Feedback type="invalid">
                                       {errors.Name}
                                 </Form.Control.Feedback>

                            </div>
                            <div className="form-input-wrap">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" name="number" placeholder="Your Phone Number" className="input-item" value={values.number} onChange={handleChange} isInvalid={!!errors.number} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.number}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-input-wrap">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                type="email"
                                name="email"
                                className="input-item"
                                 placeholder="Your Email address"
                                 value={values.email}
                                 onChange={handleChange}
                                 isInvalid={!!errors.email}
                               />
                                 <Form.Control.Feedback type="invalid">
                                       {errors.email}
                                 </Form.Control.Feedback>
                            </div>
                            <div className="form-input-wrap">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea"
                                placeholder="Your message"
                                name="message"
                                 style={{ height: '120px' }}
                                 className="textarea-item"
                                 value={values.message}
                                 onChange={handleChange}
                                 isInvalid={!!errors.message}
                               />
                                 <Form.Control.Feedback type="invalid">
                                       {errors.message}
                                 </Form.Control.Feedback>
                            </div>
                            <div className="btn-wrap d-flex justify-content-end"><Button
                             type="submit" className="w-100">Submit</Button></div>
                        </Form>

                        )}
                         </Formik>
                    </div>

                </Col>
                <Col md={6} lg={4}>
                    <div className="whitebox shadow-box support-box support-bg-box">
                        <h4 className="common-card-title">Contact Us</h4>
                        <div className="web-details-row d-flex align-items-center m-b-30">
                        <img src={DefaultLogo} alt="logo"/>
                            {/* <h4>Aptree.app</h4> */}
                        </div>
                    { loadingContact ?  <Spinner animation="border" variant="warning" size="sm" /> : 
                        <>
                        {/* <div className="contact-text-row d-flex align-items-center">
                       
                            <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.239" height="18.265" viewBox="0 0 18.239 18.265">
                                <g id="phone-call" transform="translate(-0.344 0)">
                                    <g id="Group_6537" data-name="Group 6537" transform="translate(0.344 0)">
                                        <path id="Path_7939" data-name="Path 7939" d="M14.767,37.664a1.8,1.8,0,0,0-1.3-.6,1.858,1.858,0,0,0-1.318.594l-1.218,1.214c-.1-.054-.2-.1-.3-.154-.139-.069-.27-.135-.382-.2a13.24,13.24,0,0,1-3.173-2.891,7.811,7.811,0,0,1-1.041-1.642c.316-.289.609-.59.894-.879.108-.108.216-.22.324-.328a1.769,1.769,0,0,0,0-2.668L6.2,29.056c-.12-.12-.243-.243-.359-.366-.231-.239-.474-.486-.725-.717a1.825,1.825,0,0,0-1.291-.567,1.888,1.888,0,0,0-1.311.567l-.008.008L1.2,29.3A2.82,2.82,0,0,0,.36,31.1a6.758,6.758,0,0,0,.493,2.86A16.6,16.6,0,0,0,3.8,38.875,18.141,18.141,0,0,0,9.844,43.6a9.412,9.412,0,0,0,3.392,1c.081,0,.166.008.243.008a2.9,2.9,0,0,0,2.224-.956c0-.008.012-.012.015-.019a8.763,8.763,0,0,1,.675-.7c.166-.158.335-.324.5-.5a1.923,1.923,0,0,0,.582-1.334,1.853,1.853,0,0,0-.594-1.322Zm1.38,4.059s0,0,0,0c-.15.162-.3.308-.47.47a10.136,10.136,0,0,0-.744.771,1.858,1.858,0,0,1-1.449.613c-.058,0-.12,0-.177,0a8.361,8.361,0,0,1-3.007-.9,17.124,17.124,0,0,1-5.69-4.456,15.651,15.651,0,0,1-2.776-4.622A5.5,5.5,0,0,1,1.4,31.18a1.77,1.77,0,0,1,.532-1.145l1.315-1.315a.876.876,0,0,1,.586-.274.825.825,0,0,1,.563.27l.012.012c.235.22.459.447.694.69.12.123.243.247.366.374l1.052,1.052a.736.736,0,0,1,0,1.2c-.112.112-.22.224-.332.332-.324.332-.632.64-.968.941-.008.008-.015.012-.019.019a.786.786,0,0,0-.2.875l.012.035a8.449,8.449,0,0,0,1.245,2.032l0,0a14.149,14.149,0,0,0,3.423,3.115,5.264,5.264,0,0,0,.474.258c.139.069.27.135.382.2.015.008.031.019.046.027a.836.836,0,0,0,.382.1.824.824,0,0,0,.586-.266l1.318-1.318a.872.872,0,0,1,.582-.289.785.785,0,0,1,.555.281l.008.008,2.124,2.124A.762.762,0,0,1,16.147,41.724Z" transform="translate(-0.344 -26.35)" fill="#333" />
                                        <path id="Path_7940" data-name="Path 7940" d="M245.372,86.958a4.961,4.961,0,0,1,4.04,4.04.517.517,0,0,0,.513.432.688.688,0,0,0,.089-.008.521.521,0,0,0,.428-.6,6,6,0,0,0-4.888-4.888.523.523,0,0,0-.6.424A.515.515,0,0,0,245.372,86.958Z" transform="translate(-235.514 -82.613)" fill="#333" />
                                        <path id="Path_7941" data-name="Path 7941" d="M257.429,8.057A9.878,9.878,0,0,0,249.38.008a.52.52,0,1,0-.17,1.025A8.823,8.823,0,0,1,256.4,8.227a.517.517,0,0,0,.513.432.688.688,0,0,0,.089-.008A.511.511,0,0,0,257.429,8.057Z" transform="translate(-239.198 0)" fill="#333" />
                                    </g>
                                </g>
                            </svg>
                            </span>
                            <p>{responseContact?.phone_number}</p>
                        </div> */}
                        <div className="contact-text-row d-flex align-items-center">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.067" height="13" viewBox="0 0 17.067 13">
                                <g id="email_9_" data-name="email (9)" transform="translate(0 -61)">
                                    <g id="Group_6538" data-name="Group 6538" transform="translate(0 61)">
                                        <path id="Path_7942" data-name="Path 7942" d="M15.567,61H1.5A1.5,1.5,0,0,0,0,62.5v10A1.5,1.5,0,0,0,1.5,74H15.567a1.5,1.5,0,0,0,1.5-1.5v-10A1.5,1.5,0,0,0,15.567,61Zm-.207,1L8.565,68.794,1.712,62ZM1,72.293V62.7l4.816,4.775ZM1.707,73l4.819-4.819,1.689,1.674a.5.5,0,0,0,.706,0l1.646-1.646L15.36,73Zm14.36-.707L11.274,67.5l4.793-4.793Z" transform="translate(0 -61)" fill="#333" />
                                    </g>
                                </g>
                            </svg>
                            </span>
                            <a style={{textDecoration:"none"}} href={`mailto:${responseContact?.email}`}>
                            <p>{responseContact?.email}</p>
                            </a>
                        </div>
                        </>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
};


var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const supportSchema = yup.object().shape({
    Name: yup.string().required(),
    number: yup.number().typeError("That doesn't look like a phone number").required('Phone number is a required field'),
    //     .matches(regex, 'Phone add + and valid country code')
    //     .min(13, "to short")s
    //     .max(13, "to long"),
    email: yup.string().email('Please enter a valid email').required("Email is a required field"),
    message: yup.string().required('Message is a required field'),


});
export default SupportUs;
