
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

function FreeResponse(props) {

  const [state, setState] = useState({
    stateOptions: [],
    name: ''
  })

  useEffect(() => {

    setState({ ...state, stateOptions: props?.options })

  }, [props?.options])

  const { name, errors, options, setFieldValue, mandatory,quesNumber, values, quesIndex,quesId, errorName,touched, ...rest } = props;


  return (
    <div className="survey-box whitebox shadow-box m-b-30">
      <h4 className="survey-qstn"><span>{Number( quesNumber) }.</span>{props?.name ? props?.name : ''}
      {
                mandatory ?  <span className="mandatory-star">*</span> :''
       }


      </h4>

      <div className="free-response-wrap">
        <Form.Control
          type="string"
          name={quesId}
          placeholder="Enter your response"
          onChange={(e) => setFieldValue(quesId, e.target.value)}
          isInvalid={!!errors && !!errors[errorName] && !!errors[errorName] && !!touched && !!touched[errorName]
            ? ' is-invalid'
            : ''}

          as="textarea" rows={6} />
      </div>
    </div>
  )
}

export default FreeResponse
