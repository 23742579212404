import React, {useState, useEffect, useCallback} from 'react';
import DataTable from 'react-data-table-component';
import Spinner from "react-bootstrap/Spinner";
import Select, { components } from "react-select" ;
import FilterImg from '~/assets/images/icons/filter.svg';
import ExpandedComponent from './expandedcomponent';
import {Link, useHistory,useLocation} from 'react-router-dom';
import CourseDummy from '~/assets/images/course_dummy.png';
import {
   getLearnerLearningPathAssigments,
   getLearnerLearningPathAssigmentsWithPage,
   getLearnerLearningPathList, handlePaginationLearnerLearningPath, handlePreviousPaginationLearnerLearningPath,
   nextLearnerLearningPathAssigments,
   prevLearnerLearningPathAssigments
} from '../../learnerLearningPath/services';
import {useDispatch, useSelector} from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import {Button} from "react-bootstrap";
import {getNotifications} from "../../Notifications/services";
import debounce from "lodash.debounce";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const LearnerLearningPathDetails = ({match}) => {
   const history = useHistory()
   const dispatch = useDispatch();
   const location = useLocation();
   const response = useSelector(state => state?.learningpath_learner ?.learner_LearningPath_list?.response);
   const loading = useSelector(state => state?.learningpath_learner?.learner_LearningPath_list?.loading);
   const [state, setState] =useState({
      C:[],
      count:response?.count,
      paginationItem:[]
   })
   const [activePage, setActivePage] = useState(1);
   const [search, setSearch] = useState('');
   const [status, setStatus] = useState('');
   const  url = match?.url


   const options = [
      { value: 'new', label: 'New' },
      { value: 'in_progress', label: 'In progress' },
      { value: 'completed', label: 'Completed' },
      { value: 'failed', label: 'Failed' }
   ];



   useEffect(() => {

      if(location?.state && location?.state?.category  && location?.state?.category == 'complete') {
         let option = {value: 'completed', label: 'Completed'}
         setStatus(option);
         dispatch( getLearnerLearningPathList(search, 'completed',''));
      }else if(location?.state && location?.state?.category  && location?.state?.category == 'ongoing'){
         let option = { value: 'in_progress', label: 'In progress' }
         setStatus(option);
         dispatch( getLearnerLearningPathList(search, 'in_progress',''));

      }else{
         setSearch('');

         dispatch( getLearnerLearningPathList(search, '',''));
      }

      dispatch(getNotifications())

   }, []);

   const checkTextStatus = (status) => {
      if(status == 'In Progress'){
         return 'Continue'
      }else if(status == 'New'){
         return 'Start'
      }else{
         return 'Start'
      }

   }


   useEffect(() => {
      const count = response?.count
      const perPage = 12;
      const buttonsCount = Math.ceil(count / perPage);
      let ellipsisLeft = false;
      let ellipsisRight = false;
      let items = []
      for (let i = 1; i <= Number(buttonsCount); i++) {
         if (i === activePage) {
            items.push(
                {id: i, current: true, ellipsis: false}
            );
         } else {
            if (i < 10 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
               items.push(
                   {id: i, current: false, ellipsis: false}
               );
            } else if (i > 1 && i < activePage && !ellipsisLeft) {
               items.push(
                   {id: i, current: false, ellipsis: true}
               );
               ellipsisLeft = true;
            } else if (i < buttonsCount && i > activePage && !ellipsisRight) {
               items.push(
                   {id: i, current: false, ellipsis: true}
               );
               ellipsisRight = true;

            }
         }

      }
      setState({...state, courses: response, count: response?.count, paginationItem: items});
   }, [response]);

   const debouncedGetSearch = useCallback(debounce((query,stat) => {

      setActivePage(1)

      dispatch( getLearnerLearningPathList( query,stat ? stat.value : '',''));

   },1000),[])


   const searchText = (e) => {
      setSearch(e.target.value);

      debouncedGetSearch(e.target.value,status)
   };

   const onSelect = (val) =>{
      setStatus(val);
      setActivePage(1);
      dispatch( getLearnerLearningPathList(search, val.value, 1));
   };

   const reset = () => {
      setSearch('');
      setStatus('');
      dispatch( getLearnerLearningPathList( '', '',''));
   };
   const handleNext = () => {

      if (state.courses?.next) {
         setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
         dispatch(handlePaginationLearnerLearningPath(state.courses?.next))
      } else {
         return false
      }

   }

   const handlePrevious = () => {
      if (state.courses?.previous) {
         setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
         dispatch(handlePreviousPaginationLearnerLearningPath(state.courses?.previous))
      } else {
         return false
      }

   }
   const handleIndex = (index) =>{

         dispatch(getLearnerLearningPathList(search ? search : '',status ? status.value : '', index));

      setActivePage(index);
   }

   const checkPercentage = (course) => {
      let percentage = course?.completed_modules / course?.modules_count * 100
      return percentage
   }
   const columns = [
      // {
      //    name: 'No#',
      //    selector: 'learning_path_name',
      //    sortable: true,
      //    center: false,
      //    grow: 2,
      //    cell: (row,index) => <div>
      //       {index+1}
      //    </div>
      //
      // },

      {
         name: 'Learning Path Name',
         selector: 'learning_path_name',
         sortable: true,
         center: false,
         grow: 2,

      },


      {
         name: 'Due Date',
         selector: 'due_date',
         sortable: true,
         center: false,
         grow: 2
      },
      {
         name: 'Status',
         selector: 'status',
         sortable: true,
         center: false,
         grow: 2
      },
      {
         name: 'Completed Date',
         selector: 'completed_date',
         sortable: true,
         center: false,
         grow: 2,
         cell: row => <div>
            {row?.status == 'Completed' || row?.status == 'Failed' ?
                <div> {row?.completed_date}</div>
                :
                '-'

            }
         </div>


      },
      {
         name: 'Action',
         selector: 'certificate',
         sortable: true,
         center: false,
         grow: 2,
         cell: row => <div>
            {row?.status == 'Completed' || row?.status == 'Failed' ?
                <Button className='text-white' type="button" disabled={true}>{row?.status}</Button>
                :
                <Button className='text-white' type="button"  onClick={()=> history.push(`/learningpathdetails/${row?.id}`)}>{checkTextStatus(row?.status)}</Button>

            }
         </div>

      },
   ];
   const CaretDownIcon = () => {
      return (
         <div className="filter-icon-select">
         <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
         <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
         </svg>
         </div>
      );
    };
    
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    };



   const handleCardClick  =(item)=>{
      history.push(`/learningpathdetails/${item?.id}`)
   }
   return (
      <div className="learner-accordian-wrap datatble-wrap ">

         {loading ?
             <div className="loader-middle-wrap">
                <Spinner animation="border" variant="danger"/>
             </div>

             :
             <>
                <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                   <div className="dt-filter-left d-flex justify-content-between">
                   <div className="custom-react-select">
                      <Select options={options}  value={status}  onChange={onSelect} placeholder="Status" components={{ DropdownIndicator }} />
                   </div>
                    {/*<button type="button" className="iconbutton"><img src={FilterImg} /></button>*/}
                   </div>
                   <div className="dt-filter-right d-flex">
                      <div className="dt-filter-search-wrap p-relative">
                         <input type="text" placeholder="Search using keyword" value={search}
                                onChange={searchText}
                                className="form-control input-item" />
                         <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                      </div>
                      <button className="iconbutton search-btn">
                         <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                            <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                         </svg>
                      </button>
                   </div>

                </div>
                <div className="courseslisting">
                   <Row>
                      {loading && loading ?
                          (
                              <>
                                 <div className='loaderCourses'>
                                    <Spinner animation="border" variant="danger"/>
                                 </div>

                              </>


                          ):(
                              <>

                                 { !loading && state.courses && state.courses && state.courses?.results && state.courses?.results.length ? state.courses?.results?.map((el,index)=>(
                                         <Col style= {{cursor:'pointer'}}  lg={4} xl={3} className="m-b-30 course-box-outer">

                                            <div className="common-card coursecard" onClick={()=>handleCardClick(el)}>
                                               <div className="course-box-top">
                                                  <div className="course-listing-img">
                                                     <img src={el?.banner_image ? el?.banner_image :CourseDummy} alt="" />
                                                  </div>
                                                  <div className="common-card-detail-wrap box-listing">
                                                     <h4>{el?.learning_path_name }</h4>
                                                     <p>{el?.description?.substring(0, 120) + ' '  +  (el?.description?.length > 120 ?  '...' : '')}</p>
                                                  </div>
                                                  {/*<div className="coommon-card-det-number d-flex justify-content-between">*/}
                                                  {/*    <span className="common-card-oth-det-left">Modules <span className="det-numb">{el?.modules_count}</span> </span>*/}
                                                  {/*    <span className="common-card-oth-det-left">Quizzes <span className="det-numb">{el?.quizzes_count}</span> </span>*/}
                                                  {/*</div>*/}
                                               </div>
                                               <div className="box-overlay">
                                                  <span className="overlay-viewmore">View More<i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                                                  <div className="overlay-bg">&nbsp;</div>
                                               </div>

                                            </div>



                                         </Col>
                                     ))
                                     :
                                     <span className='noData'>No Learning Path Assignments Found</span>

                                 }

                              </>


                          )

                      }
                   </Row>
                   {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response || state?.courses?.previous ?
                   <div className="d-flex justify-content-center">
                      <Pagination size="sm" className="custom-pagination">
                         <Pagination.First disabled={!state?.courses?.previous} className={`${state?.courses?.previous ? 'paginationStyle' : ''} `} onClick={handlePrevious}/>
                         {state.paginationItem.map(page => {
                            if(!page.ellipsis) {
                               return <li key={page.id}>
                                  <Pagination.Item
                                      onClick={()=>handleIndex(page.id)} key={page.id} active={page.id == activePage}
                                  >
                                     {page.id}
                                  </Pagination.Item>
                               </li>
                            }else {
                               return <Pagination.Ellipsis></Pagination.Ellipsis>
                            }
                         })}
                         <Pagination.Last disabled={!state?.courses?.next} onClick={handleNext} className={`${state?.courses?.next ? 'paginationStyle' :''} `}/>

                      </Pagination>

                   </div>
                       :
                       ''
                   }
                </div>

             </>
         }

      </div>
   );
};

export default LearnerLearningPathDetails;
