import React from "react";
import RadioButton from "../../radioButton/container";


function FormikControl(props){
    const {control,...rest} =props
    switch (control){
        case 'radio':
            return <RadioButton {...rest} />
        default :

    }

}
export default FormikControl;
