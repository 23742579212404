import React, {useState, useEffect, useCallback} from 'react';
import DataTable from 'react-data-table-component';
import Spinner from "react-bootstrap/Spinner";
import ExpandedComponent from './expandedcomponent';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import {Button} from "react-bootstrap";
import {
   getLearnerLearningPathComplete,
   handlePaginationLearnerLearningPathComplete, handlePreviousPaginationLearnerLearningPatCompleted
} from "../../../../../learnerLearningPath/services";
import debounce from "lodash.debounce";

const LearnerLearningPathDetails = () => {
   const history = useHistory()
   const dispatch = useDispatch();
   const response = useSelector(state => state?.learningpath_learner ?.learner_LearningPath_completed_list?.response);
   const loading = useSelector(state => state?.learningpath_learner?.learner_LearningPath_completed_list?.loading);
   const [state, setState] =useState({
      courses:{},
      count:response?.count,
      paginationItem:[]
   })
   const [activePage, setActivePage] = useState(1);
   const [search, setSearch] = useState('');
   const [status, setStatus] = useState('');



   useEffect(() => {
      dispatch( getLearnerLearningPathComplete('', ''));

   }, []);




   useEffect(() => {
      const count = response?.count
      const perPage = 12;
      const buttonsCount = Math.ceil(count / perPage);
      let ellipsisLeft = false;
      let ellipsisRight = false;
      let items = []
      for (let i = 1; i <= Number(buttonsCount); i++) {
         if (i === activePage) {
            items.push(
                {id: i, current: true, ellipsis: false}
            );
         } else {
            if (i < 10 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
               items.push(
                   {id: i, current: false, ellipsis: false}
               );
            } else if (i > 1 && i < activePage && !ellipsisLeft) {
               items.push(
                   {id: i, current: false, ellipsis: true}
               );
               ellipsisLeft = true;
            } else if (i < buttonsCount && i > activePage && !ellipsisRight) {
               items.push(
                   {id: i, current: false, ellipsis: true}
               );
               ellipsisRight = true;

            }
         }

      }
      setState({...state, courses: response, count: response?.count, paginationItem: items});

   }, [response]);

   const debouncedGetSearch = useCallback(debounce(query => {

      setActivePage(1)
      dispatch( getLearnerLearningPathComplete( query, status === '' ? '' : status?.value));

   },1000),[])


   const handleNext = () => {

      if (state.courses?.next) {
         setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
         dispatch(handlePaginationLearnerLearningPathComplete(state.courses?.next))
      } else {
         return false
      }

   }

   const handlePrevious = () => {
      if (state.courses?.previous) {
         setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
         dispatch(handlePreviousPaginationLearnerLearningPatCompleted(state.courses?.previous))
      } else {
         return false
      }

   }
   const handleIndex = (index) =>{
      if(index == 1){
         dispatch(getLearnerLearningPathComplete('','',''));
      }else{
         dispatch(getLearnerLearningPathComplete('','', index));
      }
      setActivePage(index);
   }

   const checkTextStatus = (status) => {
      if(status == 'In Progress'){
         return 'Continue'
      }else if(status == 'New'){
         return 'Start'
      }else{
         return 'Start'
      }

   }
   const columns = [
      // {
      //    name: 'No#',
      //    selector: 'learning_path_name',
      //    sortable: true,
      //    center: false,
      //    grow: 2,
      //    cell: (row,index) => <div>
      //       {index+1}
      //    </div>
      //
      // },

      {
         name: 'Learning Path Name',
         selector: 'learning_path_name',
         sortable: true,
         center: false,
         grow: 2,

      },


      {
         name: 'Completed Date',
         selector: 'completed_date',
         sortable: true,
         center: false,
         grow: 2,
         cell: row => <div>
            {row?.status == 'Completed' || row?.status == 'Failed' ?
                <div> {row?.completed_date}</div>
                :
                '-'

            }
         </div>


      },
      {
         name: 'Download Certificate',
         selector: 'certificate',
         sortable: true,
         center: false,
         grow: 2,
         cell: row => <div>

                <Button className='text-white' type="button" onClick={ ()=>  history.push(`/learning-path/certificate/${row?.id}/`)}>Download</Button>



         </div>

      },
   ];





   return (
      <div className="learner-accordian-wrap datatble-wrap ">

         {loading ?
             <div className="loader-middle-wrap">
                <Spinner animation="border" variant="danger"/>
             </div>

             :
             <>
                <DataTable
                    columns={columns}
                    data={state?.courses?.results}

                    paginationComponentOptions={{
                       noRowsPerPage: true
                    }}

                    center={true}
                    showPaginationBottom={false}
                    paginationIconFirstPage={false}
                    paginationIconLastPage={false}
                    noHeader={true}

                />
                {!loading && state?.courses && state?.courses && state?.courses.count > 12 && response &&
                <div className="d-flex justify-content-center">
                   <Pagination size="sm" className="custom-pagination">
                      <Pagination.First disabled={!state?.courses?.previous}
                                        className={`${state?.courses?.previous ? 'paginationStyle' : ''} `}
                                        onClick={handlePrevious}/>
                      {state.paginationItem.map(page => {
                         if (!page.ellipsis) {
                            return <li key={page.id}>
                               <Pagination.Item
                                   onClick={() => handleIndex(page.id)} key={page.id}
                                   active={page.id == activePage}
                               >
                                  {page.id}
                               </Pagination.Item>
                            </li>
                         } else {
                            return <Pagination.Ellipsis></Pagination.Ellipsis>
                         }
                      })}
                      <Pagination.Last disabled={!state?.courses?.next} onClick={handleNext}
                                       className={`${state?.courses?.next ? 'paginationStyle' : ''} `}/>

                   </Pagination>

                </div>
                }

             </>
         }

      </div>
   );
};
export default LearnerLearningPathDetails;
