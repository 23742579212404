import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Intro from '../container/components/intro/container';
import Questions from './components/questions/container';
import Summary from './components/summary/container';
import Scoring from "./components/scoring/container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {detailSurvey} from '~/modules/surveys/services';
import {useDispatch, useSelector} from "react-redux";
import Spinner from "react-bootstrap/Spinner";

function SurveyReports({match}) {
  const dispatch = useDispatch()
  const {surveyDetail, report_Loading} = useSelector(state => state.surveys);
  const [demoLength,setDemoLength] =useState(0)
  const [key, setKey] = useState('0');
  const [survey, setsurvey] = useState({})
  const [state, setState]=useState({
  })

  const id = match.params.id;
const getChildState = (data)=>{
    setState({...state,...data})
  }

  const handleNext = () =>{
    setKey(`${Number(key)+1}`)
  }
  const handlePrevious = () =>{
    setKey(`${key-1}`)
  }
  useEffect(() => {

      dispatch(detailSurvey(id,function (){
        
      }))

  }, [])
  useEffect(()=>{
    setsurvey(surveyDetail)
    setDemoLength(surveyDetail && surveyDetail?.demographics &&  surveyDetail?.demographics?.length ? surveyDetail?.demographics?.length : 0 )
  },[surveyDetail])


  return (
      <>
    <Container fluid={true}>
      <div className="reports-wrap">
        <div className="d-flex justify-content-between">
          {report_Loading ?
              <Spinner animation="border" variant="danger"/>
              :
              <h4 className="page-head">{survey?.name ? survey?.name : 'Not Available'}</h4>

          }

          {/* <Button type="button">Download Report</Button> */}
        </div>
        <div className="tabs-wrap reports-tab">
          <Tabs defaultActiveKey='0'
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="m-b-30">
          {/* <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          > */}
            <Tab eventKey="0" title="Intro">
              <Intro survey={survey}/>
            </Tab>
            <Tab eventKey="1" title="Summary">
              {demoLength && demoLength > 0 ?
              < Summary id={id} survey={surveyDetail}/>
                  :
                <div className="noresults">
                <div style={{padding:'24px'}}>There are no demographic questions
                </div>
                </div>
              }
            </Tab>
            <Tab eventKey="2" title="Questions">
              <Questions  id={id} />
            </Tab>
            <Tab eventKey="3" title="Scoring" >
              <Scoring id={id}/>
            </Tab>
          </Tabs>
        </div>
      </div>
      <div className="btn-wrap mt-5 double-btn d-flex justify-content-end">
        {key != 0 ?
            <button className="btn border-btn" onClick={handlePrevious}>Previous Page</button>
            :
            ''
        }
        {key != 3 ?
            <Button onClick={handleNext}>Next Page</Button>
        :
            ''
        }

      </div>
    </Container>

      </>
  );
}
export default SurveyReports;
