import React, { useEffect, useState, useRef } from "react";
import { FormLabel, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Template1 from "./templates/templates1";
import Template2 from "./templates/templates2";
import Template4 from "./templates/templates4";
import Template6 from "./templates/templates6";
import Template8 from "./templates/templates8";
import TextOnly from "./templates/templates3";
import ImageOnly from "./templates/templates10";
import img2 from "~/assets/images/moduleselection/img2.svg";
import img3 from "~/assets/images/moduleselection/img3.svg";
import img4 from "~/assets/images/moduleselection/img4.svg";
import img6 from "~/assets/images/moduleselection/img6.svg";
import img7 from "~/assets/images/moduleselection/img7.svg";
import img9 from "~/assets/images/moduleselection/img9.svg";
import img10 from "~/assets/images/moduleselection/img10.svg";
import img1 from "~/assets/images/moduleselection/img1.svg";
import img5 from "~/assets/images/moduleselection/img5.svg";
import ModuleQuizAdded from "./modulequizadded";
import ModuleQuizAdded2 from "./modulequizadded2";
import AddQuiz from "./addquiz";
import CourseDuration from "./courseduration";
import ModulePopView from "../../../../../../../shared/components/modulepopview";
import { useSelector, useDispatch } from "react-redux";
import {
    addCourseModule,
    deleteCourseModule,
    updateCourseModule,
    getManagerCourse,
} from "../../../../../services";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import { autoSave } from "../../../../../../../shared/utils/autoSave";
import { ToastContainer, toast } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from 'react-router-dom';
import _ from 'lodash';


function MyVerticallyCenteredModal(props) {
    const templates = [
        {
            name: "text_with_image_on_left",
            title: "Text with image on left side",
            src: img2,
        },
        {
            name: "text_with_image_on_right",
            title: "Text with image on right side",
            src: img4,
        },
        // {name: 'text_with_image_on_top', title: "Text with image on top", src: img6},
        // {name: 'text_with_image_at_the_bottom', title: "Text with image at the bottom", src: img7},
        {
            name: "text_with_embedded_video",
            title: "Text with embedded video on top",
            src: img3,
        },
        {
            name: "question_multiple_choice",
            title: "Question - Multiple choice",
            src: img9,
        },
        {
            name: "question_true_or_false",
            title: "Question - True or false",
            src: img10,
        },
        { name: "image_only", title: "Image Only", src: img1 },
        { name: "text_only", title: "Text Only", src: img5 },
    ];
    const templateSelection = (name) => {
        props.click(name);
        props.onHide();
    };

    return (
        <Modal
            className="custom-modal confirmation-popup module-modal dashboard-modal"
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="module-modal-header">
                    <h4>Select Content Format</h4>
                    <p>Pick out your required format.</p>
                </div>
                <div className="module-formact-box row">
                    {templates && templates.length
                        ? templates.map((el, index) => (
                            <div
                                className="col-sm-6 col-md-3 module-format-item"
                                onClick={() => templateSelection(el.name)}
                            >
                                <div className="img-box">
                                    <img src={el?.src} alt="" />
                                </div>
                                <p>{el?.title}</p>
                            </div>
                        ))
                        : ""}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn border-btn" onClick={props.onHide}>
                    Cancel
                </button>
                {/*<Button variant="primary">Continue</Button>*/}
            </Modal.Footer>
        </Modal>
    );
}


function TutorialModal(props) {
 
    return (
        <Modal className="custom-modal info-modal dashboard-modal" show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="module-modal-header">
                    <h4>A course is a set of modules or chapters positioned in a linear manner, so please create unique modules for your course in this section.</h4>
                </div>
                <ol className="info-list">
                  <li><span>Add Module enables authors to add chapters/modules to their courses.</span></li>
                  <li><span>Pick out the format you wish and start adding content to it.</span></li>
                  <li><span>Add a title for every Module and reorder positions with the arrows you may find in the border once there are two modules in your course</span></li>
                  <li><span> There will be text editors for every text field where you can customize your course content.</span></li>
                  <li><span> In the Course Duration field, you may add the expected minutes required to complete the course.</span></li>
                  <li><span> Once you complete adding modules to your course, you can click Preview Button at the end to view how your course shall be displayed to your learners</span></li>
                  <li><span> Click on Save and find you course in Drafts and click on Publish to find it in Published courses</span></li>
                 <li><span>Each time you click Add Module, all the modules gets auto-saved hence you can find the same course in Drafts anytime.</span></li>
                  <li><span>After you Publish, you reach the Assign tab, where you can enroll the created course to either individual learners or groups.</span></li>
                </ol>
                </Modal.Body>
            <div className="btn-wrap text-center">
                <Button onClick={props.onHide}> Close </Button>
            </div>
            </Modal>
        );
}


function AddModuleFormatted(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const auto = useRef();
    const save = useRef();
    const formikRef = React.useRef(null);
    let response = useSelector(
        (state) => state?.course?.get_course_manager?.response
    );
    let addModuleLoading = useSelector(
        (state) => state?.course?.newly_added_course_module?.loading
    );
    let deleteModuleLoading = useSelector(
        (state) => state?.course?.deleted_module?.loading
    );
    let updateLoading = useSelector(
        (state) => state?.course?.updated_module?.loading
    );
    const { hideLpQuizes } = useSelector((state) => state.settings);
    const publish_ref = useRef(null)

    const [state, setState] = useState({
        show: false,
        moduleId: "",
        selectedIndex: "",
        selectedTemplate: "",
        selectedTemplateArray: [],
        modulesById: {},
        popupView: false,
        modules: [],
        initialValuesState: {
            modules: [
            ],
            duration: '',
            quizzes: [],
        },
    });


    const [activeButton, setActiveButton] = useState(undefined);
    const [modalShow, setModalShow] = useState(false);
    const [tutorialModel, setTutorialmodel] = useState(false);
    
    useEffect(() => {
        if (props.urlId) {
            if (!response?.modules?.length) {
                const { initialValuesState } = state;
                initialValuesState.modules = [];
                
                if(response.duration){
                    setState({ ...state, initialValuesState: {...initialValuesState,duration:response.duration} });
                } else {
                    setState({ ...state, initialValuesState});
                }
            } else {
                for (let i = 0; i < response?.modules?.length; i++) {
                    // response.modules[i].video = response.modules[i].video ? response.modules[i].video : null
                    if (
                        response?.modules[i]?.module_tests?.length &&
                        response?.modules[i].template == "question_multiple_choice"
                    ) {
                        for (
                            let j = 0;
                            j < response?.modules[i]?.module_tests?.length;
                            j++
                        ) {
                            let findIndex = response?.modules[i]?.module_tests[
                                j
                            ]?.choices.findIndex((el) => {
                                return el.is_correct_answer;
                            });

                            response.modules[i].module_tests[j].right_answer = findIndex;
                        }
                    }
                    
                }
                if(!response?.duration){
                    response.duration = '';
                } 
                
                setState({ ...state, initialValuesState: response });
            }
        }
    }, [response]);



    const handleModel = (e, i) => {
        setState({ ...state, selectedIndex: i, show: true });
    };

    async function handleModules(
        e,
        values,
        setValues,
        errors,
        validateField,
        validateForm
    ) {

        if (values?.modules && values?.modules?.length >= 1) {
            let error = await formikRef.current.validateForm();
            if (Object.keys(error).length === 0 && error.constructor === Object) {
                setTimeout(() => {

                    if (values?.modules?.find((el) => el.template == "")) {
                        for (let i = 0; i < values?.modules?.length; i++) {
                            if (values.modules[i].template == "") {
                                toast.warn("Module Template is mandatory");
                                break;
                            }
                        }
                    } else {
                        const modules = [...values.modules];
                        const { initialValuesState } = state;

                        if (values?.modules && values?.modules?.length) {
                            for (let i = 0; i < values?.modules?.length; i++) {
                                if (
                                    values?.modules[i]?.module_tests?.length &&
                                    values?.modules[i].template == "question_multiple_choice"
                                ) {
                                    for (
                                        let j = 0;
                                        j < values?.modules[i]?.module_tests?.length;
                                        j++
                                    ) {
                                        for (
                                            let k = 0;
                                            k < values.modules[i]?.module_tests[j]?.choices?.length;
                                            k++
                                        ) {
                                            if (
                                                !values.modules[i].module_tests[j].choices[k]?.choice ||   values.modules[i].module_tests[j].choices[k].choice
                                                    .length == 0
                                            ) {
                                                values.modules[i].module_tests[j].choices =
                                                    values?.modules[i]?.module_tests[j]?.choices.filter(
                                                        (el) => el?.choice?.length
                                                    );
                                            }
                                        }
                                    }
                                }
                            }
                            if (values?.modules && values?.modules?.length) {
                                for (let i = 0; i < values?.modules?.length; i++) {
                                    if (values?.modules[i].image && values?.modules[i].image) {
                                        var fileExtension = values?.modules[i].image
                                            .split(".")
                                            .pop();
                                        if (values?.modules[i].id && fileExtension.length > 5) {
                                            values.modules[i].is_updated = true;
                                        }
                                    }
                                    values.modules[i].position = i + 1;
                                }
                            }
                        }
                        if (!hideLpQuizes && values?.quizzes?.length) {
                            values.quizzes = values?.quizzes?.map((el) => el.id);
                        }
                        values.is_published = "False";
                        dispatch(
                            updateCourseModule(
                                JSON.stringify(values),
                                props?.courseId,
                                function (res) {
                                    if (res) {
                                        toast.success('The above-added modules are auto-saved!')
                                        dispatch(
                                            getManagerCourse(props?.courseId, function (response) {
                                                if (response) {
                                                    if (response?.modules?.length) {
                                                        for (
                                                            let i = 0;
                                                            i < response?.modules?.length;
                                                            i++
                                                        ) {
                                                            if (
                                                                response?.modules[i]?.module_tests?.length &&
                                                                response?.modules[i].template ==
                                                                "question_multiple_choice"
                                                            ) {
                                                                for (
                                                                    let j = 0;
                                                                    j <
                                                                    response?.modules[i]?.module_tests?.length;
                                                                    j++
                                                                ) {
                                                                    let findIndex = response?.modules[
                                                                        i
                                                                    ]?.module_tests[j]?.choices.findIndex(
                                                                        (el) => {
                                                                            return el.is_correct_answer;
                                                                        }
                                                                    );
                                                                    response.modules[i].module_tests[
                                                                        j
                                                                    ].right_answer = findIndex;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    // response?.modules?.push({
                                                    //     name: "",
                                                    //     position: response.modules.length + 1,
                                                    //     description: "",
                                                    //     template: "",
                                                    //     contents: "",
                                                    //     image: "",
                                                    //     video: "",
                                                    //     module_tests: [],
                                                    // });
                                                    const newMod = [...response.modules];
                                                    const lastIdx = newMod?.findIndex(
                                                        (n) => n.name == "" || n.position == ""
                                                    );
                                                    setState({
                                                        ...state,
                                                        initialValuesState: response,
                                                        selectedIndex: `${lastIdx}`,
                                                        show: true,
                                                    });
                                                }
                                            })
                                        );
                                    }
                                }
                            )
                        );
                    }
                }, 10);
            } else {
                toast.error("Some fields are empty, please check!");
                if (values?.modules?.length) {
                    auto?.current?.click();
                }

            }
        } else {

            const { initialValuesState } = state;
            const lastIdx = initialValuesState?.modules?.findIndex(
                (n) => n.name == "" || n.position == ""
            );
            setState({
                ...state,
                selectedIndex: `${lastIdx}`,
                show: true,
            });
        }

        // autoSave(values,props?.courseId,props.urlId)
    }

    const deleteModule = (index, values, setValues) => {
        const modules = [...values.modules];
        const { initialValuesState } = state;
        initialValuesState?.modules?.splice(index, 1);
        modules.splice(index, 1);
        setValues({ ...values, modules });

        setState({ ...state, initialValuesState: initialValuesState });
    };
    const handlePreviewModel = () => {
        setState({ ...state, popupView: true });
    };

    const ConfirmPublishmodal = (props) => {

        return (
            <Modal className="custom-modal confirmation-popup  delete-confirmation inner-popup publish-course-popup"
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Body>
                    <div className="modal-content-wrap text-center">
                        <h3 className="modal-title">Publish Course?</h3>
                        <p>Course once published cannot be edited </p>
                        <div className="d-flex justify-content-center buttons-wrap double-btn">

                            <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                                need to recheck
                            </button>
                            <Link className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes,
                                Continue</Button></Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    function onSubmit(values) {
        if (!hideLpQuizes && values.quizzes.length) {
            values.quizzes = values.quizzes.map((el) => el.id);
        }
        if (activeButton == 'save') {
            if (values?.modules && values?.modules?.length) {
                for (let i = 0; i < values?.modules?.length; i++) {
                    if (
                        values?.modules[i]?.module_tests?.length &&
                        values?.modules[i].template == "question_multiple_choice"
                    ) {
                        for (let j = 0; j < values?.modules[i]?.module_tests?.length; j++) {
                            for (
                                let k = 0;
                                k < values.modules[i]?.module_tests[j]?.choices?.length;
                                k++
                            ) {
                                if (
                                    !values.modules[i].module_tests[j].choices[k]?.choice ||  values.modules[i].module_tests[j].choices[k].choice.length ==
                                    0
                                ) {
                                    values.modules[i].module_tests[j].choices = values?.modules[
                                        i
                                    ]?.module_tests[j]?.choices.filter(
                                        (el) => el?.choice?.length
                                    );
                                }
                            }
                        }
                    }
                }
            }

            if (values?.modules && values?.modules?.length) {
                for (let i = 0; i < values?.modules?.length; i++) {
                    if (
                        values?.modules[i].image &&
                        values?.modules[i].image &&
                        values?.modules[i].id
                    ) {
                        var fileExtension = values?.modules[i].image.split(".").pop();
                        if (values?.modules[i].id && fileExtension.length > 5) {
                            values.modules[i].is_updated = true;
                        }
                    }
                    values.modules[i].position = i + 1;
                }
            }
            values.is_published = "False";
            dispatch(
                updateCourseModule(
                    JSON.stringify(values),
                    props?.courseId,
                    function (response) {
                        toast.success('Modules have been  updated  successfully')
                        props.parentState(2, props?.urlId, props?.course, true);
                        history.push("/draft/courses");
                    }
                )
            );
          
        } else if (activeButton == 'publish') {
            if (values?.modules && values?.modules?.length) {
                for (let i = 0; i < values?.modules?.length; i++) {
                    if (
                        values?.modules[i]?.module_tests?.length &&
                        values?.modules[i].template == "question_multiple_choice"
                    ) {
                        for (let j = 0; j < values?.modules[i]?.module_tests?.length; j++) {
                            for (
                                let k = 0;
                                k < values.modules[i]?.module_tests[j]?.choices?.length;
                                k++
                            ) {
                                if (
                                    !values.modules[i].module_tests[j].choices[k]?.choice ||  values.modules[i].module_tests[j].choices[k].choice.length ==
                                    0
                                ) {
                                    values.modules[i].module_tests[j].choices = values?.modules[
                                        i
                                    ]?.module_tests[j]?.choices.filter(
                                        (el) => el?.choice?.length
                                    );
                                }
                            }
                        }
                    }
                }
            }

            if (values?.modules && values.modules.length) {
                for (let i = 0; i < values?.modules.length; i++) {
                    if (
                        values?.modules[i].image &&
                        values?.modules[i].image &&
                        values?.modules[i].id
                    ) {
                        var fileExtension = values?.modules[i].image.split(".").pop();
                        if (values?.modules[i].id && fileExtension.length > 5) {
                            values.modules[i].is_updated = true;
                        }
                    }
                    values.modules[i].position = i + 1;
                }

                values.is_published = "True";
                dispatch(
                    updateCourseModule(
                        JSON.stringify(values),
                        props?.courseId,
                        function (response) {
                            toast.success('Course has been  published  successfully')
                            props.parentState(2, props?.courseId, props?.course, true);
                        }
                    )
                );
            } else {
                toast.error("To Publish you should Add at least one Module to your course");
            }
        }
    }

    const handleModuleDelete = (index, values, setValues, module) => {
        setState({ ...state, moduleId: module?.id });
        dispatch(
            deleteCourseModule(props.courseId, module?.id, function (response) {
                if (response == "response") {
                    const modules = [...values.modules];
                    modules.splice(index, 1);
                    setValues({ ...values, modules });
                }
            })
        );
    };
    const handleLoader = () => {
        if (addModuleLoading || updateLoading) {
            return (
                <div className="loader-full-screen-course">
                    <div className="loader">
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
            );
        } else {
            return <span></span>;
        }
    };

    const publishSurvey = () => {
        publish_ref?.current?.focus();
        publish_ref?.current?.click();

    };

    return (
        <div className="coursedetails-wrap">
            <div className="help_icon_position">
                <OverlayTrigger
                    overlay={
                        <Tooltip id="InfoText">
                            Help
                        </Tooltip>
                    }
                >
                    <span className="info-icon-wrap-tab animated-icon" onClick={() => setTutorialmodel(true)}>
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </span>
                </OverlayTrigger>
            </div>
             
            <div>{handleLoader()}</div>

            <Formik
                innerRef={formikRef}
                initialValues={state.initialValuesState}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validateOnMount
            >
                {({
                    errors,
                    values,
                    setFieldValue,
                    touched,
                    setValues,
                    handleChange,
                    handleBlur,
                    validateField,
                    validateForm,
                    handleSubmit,
                    submitForm
                }) => {
                    const handleContent = (template) => {
                        const { selectedIndex, selectedTemplateArray, initialValuesState } =
                            state;
                        if (template) {
                            selectedTemplateArray.push(template);
                            const newModule = {
                                name: "",
                                description: "",
                                template,
                                contents: "",
                                image: "",
                                video: "",
                                module_tests: [],
                            };
                            // const modules = [...values.modules];
                            // if (modules[selectedIndex].hasOwnProperty(`${template}`)) {
                            //     return false;
                            // } else {

                            switch (template) {
                                case "text_with_image_on_left":

                                    newModule.template = template;

                                    break;
                                case "text_with_image_on_right":
                                    newModule.template = template;

                                    break;
                                case "text_with_image_on_top":
                                    newModule.template = template

                                    break;
                                case "text_with_image_at_the_bottom":
                                    newModule.template = template
                                    break;
                                case "text_with_embedded_video":
                                    newModule.template = template
                                    break;
                                case "question_multiple_choice":
                                    newModule.template = template
                                    newModule.module_tests = [
                                        {
                                            question: "",
                                            right_answer: "",
                                            show_response: false,
                                            choices: [
                                                { choice: "", choice_response: "" },
                                                { choice: "", choice_response: "" },
                                                { choice: "", choice_response: "" },
                                                { choice: "", choice_response: "" },
                                            ],
                                        },
                                    ];

                                    break;
                                case "question_true_or_false":
                                    newModule.template = template

                                    newModule.module_tests = [
                                        {
                                            question: "",
                                            right_answer: "",
                                            show_response: false,
                                            choices: [
                                                { choice: "", choice_response: "" },
                                                { choice: "", choice_response: "" },
                                            ],
                                        },
                                    ];

                                    break;

                                case "text_only":
                                    newModule.template = template
                                    break;
                                case "image_only":
                                    newModule.template = template

                            }
                            setState({
                                ...state,
                                initialValuesState: { ...state.initialValuesState, modules: [...state.initialValuesState.modules, newModule] }
                            });
                            setValues({ ...values, modules: [...values.modules, newModule] });


                            // }
                        }
                        // setTimeout(() => {

                        //     let positions = document.getElementsByClassName("position");
                        //     positions[selectedIndex]?.focus();
                        // }, 400);
                    };
                    // const handlePosition = async (e, index) => {
                    //     const modules = [...values.modules];
                    //     const {initialValuesState} = state;

                    //     if (
                    //         modules &&
                    //         e?.target?.value.length &&
                    //         modules.length &&
                    //         modules.find((el) => el.position == e.target.value.trim())
                    //     ) {
                    //         modules[index].position = e?.target?.value;
                    //         let error = await formikRef.current;

                    //         //   error.setFieldTouched(`modules.${index}.position`);
                    //         error?.setFieldError(
                    //             `modules.${index}.position`,
                    //             "Position already exists"
                    //         );
                    //         console.log("erRef", error);

                    //         return;
                    //     }
                    //     modules[index].position = e?.target?.value;
                    //     setValues({...values, modules});
                    //     setState({...state, initialValuesState: initialValuesState});
                    // };

                    const handleClickSubmit = async () => {
                        let error = await formikRef.current.validateForm();
                        if (Object.keys(error).length === 0 && error.constructor === Object) {
                            handleSubmit();
                        } else {
                            auto?.current?.click();
                            toast.error("Some fields are empty, please check!");
                        }
                    };

                    const goUp = (index) => {
                        if (index !== 0) {
                            let modulescopy = JSON.parse(JSON.stringify(values.modules));
                            let item = modulescopy[index];
                            modulescopy[index] = { ...modulescopy[index - 1], position: index + 1 };
                            modulescopy[index - 1] = { ...item, position: index };
                            setFieldValue('modules', modulescopy)
                        }

                    }

                    const goDown = (index) => {
                        if (index < values.modules.length - 1) {
                            let modulescopy = JSON.parse(JSON.stringify(values.modules));
                            let item = modulescopy[index];
                            modulescopy[index] = { ...modulescopy[index + 1], position: index + 1 };
                            modulescopy[index + 1] = { ...item, position: index + 2 };
                            setFieldValue('modules', modulescopy)
                        }
                    }

                    return (
                        <Form className="d-block mb-4">

                       
                            <FieldArray name="modules">
                                {() =>
                                    values.modules && values?.modules?.length
                                        ? values?.modules?.map((module, i) => {
                                            const moduleTestsError =
                                                (errors.modules?.length &&
                                                    errors?.modules?.length &&
                                                    errors.modules[i]?.module_tests?.length &&
                                                    errors?.modules[i]?.module_tests[0]) ||
                                                {};
                                            const moduleErrors =
                                                (errors.modules?.length && errors.modules[i]) || {};
                                            const moduleTouched =
                                                (touched?.modules?.length && touched?.modules[i]) ||
                                                {};

                                            return (
                                                <>

                                                    <div className="course-form-content-sec" key={i}>
                                                        <div className="whitebox shadow-box module-form-box p-relative">
                                                            {/* {module?.id ? (
                                                                ""
                                                            ) : (
                                                                <div
                                                                    onClick={() =>
                                                                        deleteModule(i, values, setValues)
                                                                    }
                                                                >
                                                                    {" "}
                                                                    <div className="form-close-btn p-relative">
                                                                        <span className="memb-close-icon-card">
                                                                            ×
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )} */}
                                                            {/*<div onClick={()=>deleteModule(i,values, setValues)}> <div className="form-close-btn p-relative"><span className="memb-close-icon-card">×</span></div></div>*/}
                                                            {values?.modules?.length > 1 && (
                                                                <div className="top-bottom-carret-wrap">
                                                                    {i !== 0 && <div onClick={() => goUp(i)} className="move-to-top move-arrow-wrap">
                                                                        <OverlayTrigger
                                                                            overlay={<Tooltip id="InfoText">Move up</Tooltip>}>
                                                                            <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                                                        </OverlayTrigger>
                                                                    </div>}
                                                                    {i !== values?.modules?.length - 1 && <div onClick={() => goDown(i)} className="move-to-bottom move-arrow-wrap">
                                                                        <OverlayTrigger
                                                                            overlay={<Tooltip id="InfoText">Move down</Tooltip>}>
                                                                            <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                        </OverlayTrigger>
                                                                    </div>}
                                                                </div>
                                                            )}

                                                            <Row>
                                                                <Col md={2}>
                                                                    <div key={i} className="form-input-wrap">
                                                                        <FormLabel>
                                                                            Position
                                                                        </FormLabel>
                                                                        <FormControl
                                                                            type="number"
                                                                            name={`modules.${i}.position`}
                                                                            placeholder="Position"
                                                                            className="position"
                                                                            readOnly
                                                                            // onChange={(e) => handlePosition(e, i)}
                                                                            value={i + 1}
                                                                        // isInvalid={
                                                                        //     (!!moduleErrors?.position &&
                                                                        //         !!moduleTouched?.position || moduleErrors?.position ==
                                                                        //         "Position already exists")

                                                                        //         ? " is-invalid"
                                                                        //         : ""
                                                                        // }
                                                                        />
                                                                        <FormControl.Feedback type="invalid">
                                                                            {moduleErrors?.position}
                                                                        </FormControl.Feedback>
                                                                    </div>
                                                                </Col>
                                                                <Col md={10}>
                                                                    <div className="form-input-wrap">
                                                                        <FormLabel>Module Title</FormLabel>
                                                                        <FormControl
                                                                            type="text"
                                                                            name={`modules.${i}.name`}
                                                                            value={values?.modules[i]?.name}
                                                                            placeholder="Module Title"
                                                                            onChange={handleChange}
                                                                            isInvalid={
                                                                                !!moduleErrors?.name &&
                                                                                    !!moduleTouched?.name
                                                                                    ? " is-invalid"
                                                                                    : ""
                                                                            }
                                                                            maxLength="500"
                                                                        />
                                                                        <FormControl.Feedback type="invalid">
                                                                            {moduleErrors?.name}
                                                                        </FormControl.Feedback>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={12}>
                                                                    {module?.template ? (
                                                                        <div className="text-editor-wrap">
                                                                            {(() => {
                                                                                switch (module.template) {
                                                                                    case "text_with_image_on_left":
                                                                                        return (
                                                                                            <Template1
                                                                                                name={`modules.${i}.image`}
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                index={i}
                                                                                            />
                                                                                        );
                                                                                    case "text_with_image_on_right":
                                                                                        return (
                                                                                            <Template2
                                                                                                name={`modules.${i}.image`}
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                index={i}
                                                                                            />
                                                                                        );
                                                                                    case "text_with_image_on_top":
                                                                                        return (
                                                                                            <Template4
                                                                                                name={`modules.${i}.image`}
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                index={i}
                                                                                            />
                                                                                        );
                                                                                    case "text_with_image_at_the_bottom":
                                                                                        return (
                                                                                            <Template8
                                                                                                name={`modules.${i}.image`}
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                index={i}
                                                                                            />
                                                                                        );
                                                                                    case "image_only":
                                                                                        return (
                                                                                            <ImageOnly
                                                                                                name={`modules.${i}.image`}
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                index={i}
                                                                                            />
                                                                                        );
                                                                                    case "text_only":
                                                                                        return (
                                                                                            <TextOnly
                                                                                                name={`modules.${i}.contents`}
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                index={i}
                                                                                            />
                                                                                        );
                                                                                    case "text_with_embedded_video":
                                                                                        return (
                                                                                            <Template6
                                                                                                name={`modules.${i}.video`}
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                handleBlur={handleBlur}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                index={i}
                                                                                            />
                                                                                        );
                                                                                    case "question_multiple_choice":
                                                                                        return (
                                                                                            <ModuleQuizAdded
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                urlId={props.urlId}
                                                                                                setValues={setValues}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                moduleTestsError={
                                                                                                    moduleTestsError
                                                                                                }
                                                                                                index={i}
                                                                                                handleBlur={handleBlur}
                                                                                            />
                                                                                        );
                                                                                    case "question_true_or_false":
                                                                                        return (
                                                                                            <ModuleQuizAdded2
                                                                                                setFieldValue={setFieldValue}
                                                                                                values={values}
                                                                                                setValues={setValues}
                                                                                                handleChange={handleChange}
                                                                                                moduleErrors={moduleErrors}
                                                                                                moduleTouched={moduleTouched}
                                                                                                moduleTestsError={
                                                                                                    moduleTestsError
                                                                                                }
                                                                                                index={i}
                                                                                                handleBlur={handleBlur}
                                                                                            />
                                                                                        );
                                                                                    default:
                                                                                        return null;
                                                                                }
                                                                            })()}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Col>
                                                                <Col
                                                                    xs={12}
                                                                    className="d-flex justify-content-end"
                                                                >
                                                                    {module?.template ? (
                                                                        ""
                                                                    ) : (
                                                                        <button
                                                                            className="btn border-btn mr-auto"
                                                                            type="button"
                                                                            onClick={(e) => handleModel(e, i)}
                                                                        >
                                                                            Add Module Content
                                                                        </button>
                                                                    )}
                                                                    {module?.id ? (
                                                                        <button
                                                                            className="btn border-btn ml-auto"
                                                                            type="button"
                                                                            onClick={(e) =>
                                                                                handleModuleDelete(
                                                                                    i,
                                                                                    values,
                                                                                    setValues,
                                                                                    module
                                                                                )
                                                                            }
                                                                        >
                                                                            {deleteModuleLoading &&
                                                                                deleteModuleLoading &&
                                                                                state.moduleId == module.id ? (
                                                                                <Spinner
                                                                                    animation="border"
                                                                                    variant="warning"
                                                                                />
                                                                            ) : (
                                                                                "Delete"
                                                                            )}
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="btn border-btn ml-auto"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                deleteModule(i, values, setValues)
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })
                                        : ""
                                }
                            </FieldArray>
                            <div className="button-wrap double-btn m-t-20">
                                <div className="d-flex align-items-center mt-4">
                                <Button
                                    variant="primary"
                                    onClick={(e) =>
                                        handleModules(
                                            e,
                                            values,
                                            setValues,
                                            errors,
                                            validateField,
                                            validateForm
                                        )
                                    }
                                >

                                   {values?.modules?.length ? 'Add Another Module' : 'Add New Module'} 
                                </Button>
                               
                                  </div>
                                {/*<Button type="submit"> Submit</Button>*/}
                            </div>
                            {!hideLpQuizes && (
                                <div className="course-content-sec border-top-box">
                                    <AddQuiz
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        handleChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        urlId={props.urlId}
                                    />
                                </div>
                            )}

                            <div className="course-content-sec border-top-box">
                                <CourseDuration
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                />
                               
                            </div>
                            <div className="add-module-footer d-flex justify-content-end">
                                <div className="button-wrap double-btn">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        data-flag="action-3"
                                        ref={auto}
                                        className="btn-m d-none"
                                    >
                                        Hidden
                                    </Button>
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="overlay-example">Save to Drafts</Tooltip>}>
                                    <Button
                                        variant="dark"

                                        className="btn-m"
                                        name='save'
                                        ref={save}
                                        data-flag="action-1"
                                        onClick={() => { setActiveButton('save'); setTimeout(() => { handleClickSubmit() }, 100) }}
                                    >
                                        Save
                                    </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="overlay-example">View a Preview</Tooltip>}>
                                    <Button
                                        variant="dark"
                                        className="btn-m"
                                        onClick={handlePreviewModel}
                                    >
                                        Preview
                                    </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="overlay-example">Publish to Published tab</Tooltip>}>
                                    <Button
                                        variant="primary"
                                        name='publish'
                                       
                                        className="btn-m"
                                        type="button" onClick={() => setModalShow(true)} 
                                    >
                                        Publish
                                    </Button>
                                    </OverlayTrigger>
                                    <Button variant="primary"
                                        onClick={() => { setActiveButton('publish'); setTimeout(() => { handleClickSubmit() }, 100) }} data-flag="action-2" ref={publish_ref} className="btn-m d-none">Publish-hidden</Button>
                                    {/*<Button variant="primary" className="btn-m">Continue</Button>*/}
                                </div>
                            </div>
                            <ConfirmPublishmodal
                              show={modalShow}
                              handleSubmit={() => { publishSurvey(); setModalShow(false)}}
                              onHide={() => setModalShow(false)}
                            
                            />
                            <ModulePopView
                                show={state.popupView}
                                values={values?.quizzes && values?.quizzes?.length ? [...values?.modules, ...values?.quizzes?.map((el) => {
                                    return {
                                        ...el,
                                        template: 'quiz'
                                    };
                                })] : values.modules}
                                courseId={props?.courseId}
                                course={props?.course}
                                name={props?.course?.name}
                                onHide={() => setState({ ...state, popupView: false })}
                            />

                            <MyVerticallyCenteredModal
                                show={state.show}
                                click={handleContent}
                                onHide={() => setState({ ...state, show: false })}
                            />
                            <TutorialModal  show={tutorialModel}
                          
                                onHide={() => setTutorialmodel(false)}
                            />
                        </Form>
                    );
                }}
            </Formik>


           
        </div>
    );
}



const validationSchema = Yup.object().shape({
    duration: Yup.number().required("Duration is required"),

    // quizzes: Yup.mixed(),
    modules: Yup.array().of(
        Yup.object().shape({
            template: Yup.string(),

            name: Yup.string().required("Title is required"),
            // position: Yup.number()
            //     .required("Position is required")
            //     .test(
            //         "Is positive?",
            //         "ERROR: The number must be greater than 0!",
            //         (value) => value > 0
            //     ),
            description: Yup.string(),
            image: Yup.mixed().when("template", {
                is: (val) =>
                    [
                        "text_with_image_on_left",
                        "text_with_image_on_right",
                        "text_with_image_on_top",
                        "text_with_image_at_the_bottom",
                        "image_only",
                    ].includes(val),

                then: Yup.mixed().required("Image is required"),
                otherwise: Yup.mixed(),
            }),
            video: Yup.string().when("template", {
                is: (val) => ["text_with_embedded_video"].includes(val),
                then: Yup.string()
                    .matches(
                        /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
                        "Enter correct url!"
                    )
                    .required("Video is required").transform((v, o) => o === '' ? null : v).nullable(),

                otherwise: Yup.string().transform((v, o) => o === '' ? null : v).nullable(),
            }),

            contents: Yup.mixed().when("template", {
                is: (val) =>
                    [
                        "text_with_image_on_left",
                        "text_with_image_on_right",
                        "text_with_image_on_top",
                        "text_with_image_at_the_bottom",
                        "text_with_embedded_video",
                        "text_only",
                    ].includes(val),
                then: Yup.string().required("Content is required"),
                otherwise: Yup.string(),
            }),
            module_tests: Yup.array().of(
                Yup.object().shape({
                    question: Yup.string().required("Question is required").max(500, 'Must be less than 500 charectors'),
                    show_response: Yup.boolean(),
                    right_answer: Yup.string().required("Question is required"),
                    choices: Yup.array()
                        .of(
                            Yup.object().shape({
                                choice: Yup.string(),
                                choice_response: Yup.string(),
                            })
                        )
                        .test({
                            name: "choices",
                            message: "Required",
                            test: (val) =>
                                val.every(({ choice }, index) => {
                                    if (index === 0 || index === 1 || index === 2) {
                                        return !!choice;
                                    }
                                    return true;
                                }),
                        }).test({
                            name: "choices",
                            message: "Options should have different values",
                            test: (val) => {
                                const array = val.map(({choice}) => { return choice; });
                                return _.uniq(array).length === array.length; 

                            }
                                
                        }),
                })
            ),
        })
    ),
});

export default AddModuleFormatted;
