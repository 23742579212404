export const steps = [

    {
      element: '.assignment-link',
      intro: 'View your assignments here',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"
    }, 
    {
      element: '.profile-link',
      intro: 'Your profile information, setting up password, certificates, and subscription details are here',
      highlightClass: "sidebarTooltip",
      position:"right",
      showBullets: false,
    },
    
    {
      element: '.course-library-menu',
      intro: 'Manage course templates, created and edit original course content, and assign users to courses',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    
    {
      element: '.teams-menu',
      intro: 'Add members of your organization and group them to easily assign learning',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    {
      element: '.survey-library-menu',
      intro: 'Create and edit original surveys and view reports',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    {
      element: '.settings-menu',
      intro: "Customize your experience by adding your logo, changing the color theme, adding your organization's contact information",
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    {
      element: '.help-menu',
      intro: 'Find the contact details of Cultural Engagement here and reach out to them for any support or query.',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
  ];

  export const stepsadmin = [

    {
      element: '.assignment-link',
      intro: 'View your assignments here',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"
    }, 
    {
      element: '.profile-link',
      intro: 'Your profile information, setting up password, certificates, and subscription details are here',
      highlightClass: "sidebarTooltip",
      position:"right",
      showBullets: false,
    },
    
    {
      element: '.course-library-menu',
      intro: 'Manage course templates, created and edit original course content, and assign users to courses',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    
    {
      element: '.teams-menu',
      intro: 'Add members of your organization and group them to easily assign learning',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    {
      element: '.survey-library-menu',
      intro: 'Create and edit original surveys and view reports',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    {
      element: '.settings-menu',
      intro: "Customize your experience by adding your logo, changing the color theme, adding your organization's contact information",
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    }
  ];

  export const steps_staffmanager = [

    {
      element: '.assignment-link',
      intro: 'View your assignments here',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"
    }, 
    {
      element: '.profile-link',
      intro: 'Your profile information, setting up password, certificates, and subscription details are here',
      highlightClass: "sidebarTooltip",
      position:"right",
      showBullets: false,
    },
    {
      element: '.course-library-menu',
      intro: 'Manage course templates, created and edit original course content, and assign users to courses',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    
    {
      element: '.teams-menu',
      intro: 'Add members of your organization and group them to easily assign learning',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    {
      element: '.survey-library-menu',
      intro: 'Create and edit original surveys and view reports',
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    {
      element: '.institution-contact-menu',
      intro: "Find your organisation's contact details here.",
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    }
  ];


  export const stepsleaner = [

    {
        element: '.assignment-link',
        intro: 'View your assignments here',
        highlightClass: "sidebarTooltip",
        showBullets: false,
        position:"right"
      }, 
      {
        element: '.profile-link',
        intro: 'Your profile information, setting up password, certificates, and subscription details are here',
        highlightClass: "sidebarTooltip",
        position:"right",
        showBullets: false,
      },
     {
      element: '.institution-contact-menu',
      intro: "Find your organisation's contact details here.",
      highlightClass: "sidebarTooltip",
      showBullets: false,
      position:"right"

    },
    
  ];
