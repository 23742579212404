import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from './ckquestion'
import {useSelector,useDispatch} from "react-redux";
import {deleteCourseChoice, getManagerCourse} from "../../../../../services";
import Spinner from "react-bootstrap/Spinner";
import {ToastContainer} from "react-toastify";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {useLocation} from "react-router-dom";
import { Switch } from 'antd';
function ModuleQuizAdded(props) {
    let location = useLocation();

    const dispatch = useDispatch();
    let loading = useSelector(state => state?.course?.get_course_manager?.loading);
    const { urlId,handleChange,values,errors,index,setFieldValue,moduleErrors,moduleTouched,handleBlur,setValues} = props
  const [showinput, setshowinput] = useState(false)
    const [hideicon, sethideicon] = useState(true)
    const [choiceid, setchoiceid] = useState('')

    useEffect(()=>{
        if(values?.modules[index]?.module_tests[0]?.choices[3]?.choice.length  > 0){
            sethideicon(false)
        }
    },[])

const handleAnswer = (e)=> {
    setFieldValue(`modules.${index}.module_tests.${0}.right_answer`, e.target.value)
    const modules = [...values.modules]
    modules[index].module_tests[0].test_type = 'multiple_choice'

        modules[index].module_tests[0].choices.forEach((el)=>{
            el.is_correct_answer='False'
        })
        modules[index].module_tests[0].right_answer=e.target.value
        modules[index].module_tests[0].choices[e.target.value].is_correct_answer = 'True';

    setValues({...values, modules})
}
const handleThird = (e) =>{
    setFieldValue(`modules.${index}.module_tests.${0}.choices.${2}.choice`,e.target.value)
}
const handleRemoveIcone = (choice) =>{
        if(choice?.id && choice?.choice?.length && choice?.id != choiceid  ) {
            setchoiceid(choice?.id)

                dispatch(deleteCourseChoice(choice?.id, function (res) {
                    if (res) {
                        setshowinput(false)
                        sethideicon(true)
                        setFieldValue(`modules.${index}.module_tests.${0}.choices.${3}.id`,'')
                    }
                }))

            if(choice?.is_correct_answer){
                setFieldValue(`modules.${index}.module_tests.${0}.right_answer`,'')

            }

        }else{
            setshowinput(false)
            sethideicon(true)
        }
    if(choice?.is_correct_answer == 'True' || choice?.is_correct_answer == true){
        setFieldValue(`modules.${index}.module_tests.${0}.right_answer`,'')

    }
    setFieldValue(`modules.${index}.module_tests.${0}.choices.${3}.choice`,'')
    setFieldValue(`modules.${index}.module_tests.${0}.choices.${3}.choice_response`,'')

}

const handleAddIcon = () =>{
    setshowinput(true)
    sethideicon(false)
}

const handleAddIconView = () => {
        if(hideicon){
           return( <span className="memb-add-icon-quiz" onClick={handleAddIcon}>+</span>)
        }else {
            return (<span></span>)
        }

}
  const   handleSwitchChange = (checked) =>{
      setFieldValue(`modules.${index}.module_tests.${0}.show_response`,checked)
  }
const SELECT_OPTIONS = [1,2,3,4];
    const SELECT_OPTIONS_THIRD = [1,2,3]
    return (
        <div className="module-quiz-add-wrap">
            <ToastContainer autoClose={2000} />
             {/*<div className="form-close-btn p-relative"><span className="memb-close-icon">×</span></div>*/}
             {
                        values?.modules[index]?.module_tests[0] && (
                <Row className="justify-content-center">

                    <Col md={12} >
                        <div className="form-input-wrap">
                            <Form.Label>Add Question</Form.Label>
                            <CkEditorQuestion moduleErrors={moduleErrors} moduleTouched={moduleTouched} name={`modules.${index}.module_tests.${0}.question`} setFieldValue={setFieldValue} values={values} handleBlur={handleBlur} index={index} />
                        </div>
                    </Col>
                  
                            <Col md={12}>
                            <Row className="">
                                <Col md={5}>
                                    <div className="form-input-wrap">
    
                                        <div  className="form-input-wrap">
                                            <Form.Label>Option1</Form.Label>
                                            <Form.Control
                                                type="text" placeholder="Option 1"
                                                value={values?.modules[index]?.module_tests[0]?.choices[0]?.choice}
                                                name={`modules.${index}.module_tests.${0}.choices.${0}.choice`}
                                                isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.choices?.length && !!moduleErrors?.module_tests[0]?.choices[0] && moduleTouched?.module_tests && moduleTouched?.module_tests?.length && moduleTouched?.module_tests[0]?.choices?.length && !!moduleTouched?.module_tests[0]?.choices[0] && !!moduleTouched?.module_tests[0]?.choices[0]}
                                                // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${0}.choice`,e.target.value)}
                                                onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${0}.choice`,e.target.value)}
                                                maxLength="500"
                                            />
    
                                        </div>
    
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <div className="form-input-wrap">
    
                                        <div  className="form-input-wrap">
                                            <Form.Label>Response</Form.Label>
                                            <Form.Control
                                                type="text" placeholder="Response"
                                                name={`modules.${index}.module_tests.${0}.choices.${0}.choice_response`}
                                                value={values?.modules[index]?.module_tests[0]?.choices[0]?.choice_response}
                                                // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${0}.choice_response`,e.target.value)}
                                                onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${0}.choice_response`,e.target.value)}
                                            />
    
                                        </div>
    
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className="form-input-wrap">
                                        <div  className="form-input-wrap">
                                            <Form.Label>Option2</Form.Label>
                                            <Form.Control
                                                type="text" placeholder="Option 2"
                                                name={`modules.${index}.module_tests.${0}.choices.${1}.choice}`}
                                                value={values?.modules[index]?.module_tests[0]?.choices[1]?.choice}
                                                isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.choices?.length && !!moduleErrors?.module_tests[0]?.choices[1] && moduleTouched?.module_tests && moduleTouched?.module_tests.length && moduleTouched?.module_tests[0]?.choices?.length && !!moduleTouched?.module_tests[0]?.choices[1] }
                                                // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${1}.choice`,e.target.value)}
                                                onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${1}.choice`,e.target.value)}
                                                maxLength="500"
                                            />
    
                                        </div>
    
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <div className="form-input-wrap">
    
                                        <div  className="form-input-wrap">
                                            <Form.Label>Response</Form.Label>
                                            <Form.Control
                                                type="text" placeholder="Response"
                                                name={`modules.${index}.module_tests.${0}.choices.${1}.choice_response`}
                                                value={values?.modules[index]?.module_tests[0]?.choices[1]?.choice_response}
                                                // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${1}.choice_response`,e.target.value)}
                                                onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${1}.choice_response`,e.target.value)}
                                               
                                            />
    
                                        </div>
    
                                    </div>
                                </Col>
    
                            </Row>
    
                            <Row>
    
                                <Col md={5}>
                                    <div className="form-input-wrap" >
                                        <Form.Label>Option3</Form.Label>
                                        <Form.Control
                                            type="text" placeholder="Option 3"
                                                      name={`modules.${index}.module_tests.${0}.choices.${2}.choice`}
                                            value={values?.modules[index]?.module_tests[0]?.choices[2]?.choice}
                                                      isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.choices?.length && !!moduleErrors?.module_tests[0]?.choices[2] &&  moduleTouched?.module_tests && moduleTouched?.module_tests.length && moduleTouched?.module_tests[0]?.choices?.length && !!moduleTouched?.module_tests[0]?.choices[2]}
                                                      maxLength="500"
                                                      // onChange={handleThird}
                                            onChange={handleThird}
                                        />
    
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <div className="form-input-wrap" style ={{position:'relative'}}>
    
                                        <div  className="form-input-wrap">
                                            <Form.Label>Response</Form.Label>
                                            <div className="d-flex repeat-icon-wrap">
                                            <Form.Control
                                                type="text" placeholder="Response"
                                                name={`modules.${index}.module_tests.${0}.choices.${2}.choice_response`}
                                                value={values.modules[index].module_tests[0].choices[2].choice_response}
                                                // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${2}.choice_response`,e.target.value)}
                                                onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${2}.choice_response`,e.target.value)}
    
                                            />
                                            {handleAddIconView()}
                                            </div>
                                        </div>
    
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {values?.modules[index]?.module_tests[0]?.choices[3]?.choice?.length || showinput  ?
                                    <>
                                    <Col md={5}>
                                        <div className="form-input-wrap"   >
                                            <Form.Label>Option4</Form.Label>
                                            <Form.Control type="text" placeholder="Option 4"
                                                          name={`modules.${index}.module_tests.${0}.choices.${3}.choice`}
                                                          value={values?.modules[index]?.module_tests[0]?.choices[3]?.choice}
                                                          maxLength="500"
                                                       //  isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.choices?.length && !!moduleErrors?.module_tests[0]?.choices[3] &&  moduleTouched?.module_tests && moduleTouched?.module_tests.length &&  oduleTouched?.module_tests[0]?.choices[3]}
                                                           isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.choices?.length && !!moduleErrors?.module_tests[0]?.choices[3] &&  moduleTouched?.module_tests && moduleTouched?.module_tests.length && moduleTouched?.module_tests[0]?.choices?.length && !!moduleTouched?.module_tests[0]?.choices[3]}
                                                //     onChange={e => setFieldValue(`modules.${index}.module_tests.${0}.choices.${3}.choice`, e.target.value)}
                                                          onChange={e => setFieldValue(`modules.${index}.module_tests.${0}.choices.${3}.choice`, e.target.value)}
                                            />
    
    
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <div className="form-input-wrap" style ={{position:'relative'}}>
    
                                            <div  className="form-input-wrap">
                                                <Form.Label>Response</Form.Label>
                                                <div className="d-flex repeat-icon-wrap">
                                                <Form.Control
                                                    type="text" placeholder="Response"
                                                    name={`modules.${index}.module_tests.${0}.choices.${3}.choice_response`}
                                                    value={values?.modules[index]?.module_tests[0]?.choices[3]?.choice_response}
                                                    // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${3}.choice_response`,e.target.value)}
                                                    onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${3}.choice_response`,e.target.value)}
    
                                                />
                                                <span className="memb-add-icon-quiz" onClick={()=>handleRemoveIcone(values?.modules[index]?.module_tests[0]?.choices[3])}>
         {loading && choiceid == values?.modules[index]?.module_tests[0]?.choices[3]?.choice?.id ?
             <Spinner animation="border" variant="warning"/>
             :
             '-'
         }
                                               </span>
                                               </div>
                                            </div>
    
                                        </div>
                                    </Col>
                                   
                                    </>
                                    :
                                    ''
                                }
    
                            </Row>
                           {moduleErrors?.module_tests?.length && moduleErrors?.module_tests[0]?.choices  && moduleTouched?.module_tests?.length && moduleTouched?.module_tests[0]?.choices ? <span className="form-error text-danger mb-3" style={{display:'block'}}>{moduleErrors?.module_tests[0]?.choices}</span> : ''} 
                            <Row>
                                <Col md={4}>
                                    {/*onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.rightAnswer`,e.target.value)}*/}
                                    <div className="form-input-wrap" >
                                        <Form.Label>Right Answer</Form.Label>
                                        {values.modules[index].id ?
    
                                        <Form.Control as="select"
                                                      name={`modules.${index}.module_tests.${0}.right_answer`} onChange={handleAnswer}
                                                      onBlur={handleBlur}
                                                      value={values?.modules[index]?.module_tests[0]?.right_answer}
                                                      isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.right_answer &&  moduleTouched?.module_tests && moduleTouched?.module_tests.length && moduleTouched?.module_tests[0]?.choices?.length &&  !!moduleTouched?.module_tests[0]?.right_answer}
                                        >
    
                                            <option value="">Choose right answer</option>
                                            {SELECT_OPTIONS && SELECT_OPTIONS?.length && values?.modules[index]?.module_tests[0]?.choices[3]?.choice?.length ? SELECT_OPTIONS.map((item,index)=>(
                                                    <option value={index}>Option {index + 1}</option>
                                                ))
                                                :
                                                SELECT_OPTIONS_THIRD?.map((item,index)=>(
                                                    <option value={index}>Option {index + 1}</option>
                                                ))
                                            }
    
                                        </Form.Control>
                                            :
                                            <Form.Control as="select"
                                                          name={`modules.${index}.module_tests.${0}.right_answer`} onChange={handleAnswer}
                                                          value={values?.modules[index]?.module_tests[0]?.right_answer}
                                                          onBlur={handleBlur}
                                                          isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.right_answer &&  moduleTouched?.module_tests && moduleTouched?.module_tests.length && !!moduleTouched?.module_tests[0]?.right_answer}
                                            >
    
                                                <option value="">Choose right answer</option>
                                                {SELECT_OPTIONS && SELECT_OPTIONS?.length && values?.modules[index]?.module_tests[0]?.choices[3]?.choice?.length ? SELECT_OPTIONS.map((item,index)=>(
                                                        <option value={index}>Option {index + 1}</option>
                                                    ))
                                                    :
                                                    SELECT_OPTIONS_THIRD?.map((item,index)=>(
                                                        <option value={index}>Option {index + 1}</option>
                                                    ))
                                                }
    
                                            </Form.Control>
    
                                        }
                                    </div>
                                </Col>
                                {location?.pathname?.includes('quizbuilder')  ?
                                <></>
                                :
                                    <Col md={4}>
                                        <div className='d-flex switch-inmodule-wrap'>
                                            <span> Should Show Response</span>
                                            <div className="switch-align-right">
                                                <BootstrapSwitchButton onChange={handleSwitchChange}  checked={values?.modules[index]?.module_tests[0]?.show_response} size="xs" />
                                            </div>
                                        </div>
    
                                    </Col>
                                }
    
    
                            </Row>
                        </Col>
                      
                   
                </Row>
                ) 
                }
        </div>
    );
}

export default ModuleQuizAdded;

