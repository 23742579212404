import React, {useEffect,useState,useRef} from "react";
import DataTable from "react-data-table-component";
import Tab from "react-bootstrap/Tab";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from 'react-redux';
import {
    getManagerLearningPathLearnersWithPage,
    prevgetManagerLearningPathLearnersWithPage,
    nextgetManagerLearningPathLearnersWithPage,
    getManagerLearningPathLearners
} from '../../../../services';
import {format} from "date-fns";
import Spinner from "react-bootstrap/Spinner";
import {Link} from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function QuizSection(props) {
    const dispatch = useDispatch();
    const [activePage, setActivePage] = useState(1);
    const nodesLearnerResponse = useSelector(state => state?.learningpath?.nodes_learner?.response);
    const nodesLearnerLoading= useSelector(state => state?.learningpath?.nodes_learner?.loading);
    const [state,setState] = useState({
        data:{},
       learners:{},
        paginationItem:[]
    })
    // useEffect(()=>{
    //     dispatch(getManagerLearningPathLearnersWithPage(props?.urlId,props?.node?.id,activePage,function (resLearners){
    //     }))
    //
    // },[])

  useEffect(()=>{
      const count = nodesLearnerResponse?.count
      const perPage =12;
      const buttonsCount = Math.ceil(count/perPage);
      let ellipsisLeft = false;
      let ellipsisRight = false;
      let items =[]
      for (let i = 1; i <= Number(buttonsCount); i++) {
          if(i === activePage) {
              items.push(
                  { id: i, current: true, ellipsis: false }
              );
          }else {
              if(i < 4 || i > buttonsCount - 1 || i === activePage - 1 || i === activePage + 1) {
                  items.push(
                      { id: i, current: false, ellipsis: false }
                  );
              }else if(i > 1 && i < activePage && !ellipsisLeft) {
                  items.push(
                      { id: i, current: false, ellipsis: true }
                  );
                  ellipsisLeft = true;
              }else if(i < buttonsCount && i > activePage && !ellipsisRight) {
                  items.push(
                      { id: i, current: false, ellipsis: true }
                  );
                  ellipsisRight = true;

              }
          }

      }

      setState({...state,data:props?.node,learners: nodesLearnerResponse,paginationItem:items})
      // setState({...state,paginationItem:items});

  },[nodesLearnerResponse])


    const handleNext = () => {

        if (state?.learners?.next) {


            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);

            dispatch(nextgetManagerLearningPathLearnersWithPage(state.learners?.next,function (res){
                setState({...state,learners: res})
            }))

        } else {
            return false;
        }

    }

    const handlePrevious = () => {

        if (state.learners?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            dispatch(prevgetManagerLearningPathLearnersWithPage(state.learners?.previous,function (res){
                setState({...state,learners: res})
            }))

        } else {
            return false

        }

    }
    const handleIndex = (index) =>{

            dispatch(getManagerLearningPathLearnersWithPage(props?.urlId,state?.data?.id,index,function (resLearners){
                setState({...state,learners: resLearners})
            }))

        setActivePage(index);
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.learner_name,
        },
        {
            name: 'Completed',
            cell: row => <div >{row.has_completed ?  <div className={`status-icon-show tick-show`}>

                    <i className="fa fa-check" aria-hidden="true"></i>





                </div>

                :
                <div className={`status-icon-show cross-show`}>


                    <OverlayTrigger
                        overlay={
                            <Tooltip id="InfoText">
                                In Progress
                            </Tooltip>
                        }
                    >
                                                        <span className="status-tooltip-text">
                                                            <i className="fa fa-exclamation"
                                                               aria-hidden="true"></i></span>
                    </OverlayTrigger>


                </div>}</div>,
        },
        {
            name: 'Marks',
            selector: row => row.mark_scored ? row.mark_scored : '-',
        },
    ];


    if(props.node && Object?.keys(props?.node)?.length ) {
        return (
            <div className="grey-bg-content-box">

                <div className="grey-bg-content-box-body">

                    <div className="box-changable-wrap2">
                        <div className="qstn-type-wrap">
                            {/*<span className="qstn-type-light">Question type:</span><span className="qstn-type-bold"> Objective</span>*/}
                        </div>
                        <div className="count-details d-flex">
                            <div className="count-box-1">
                                <span
                                    className="count-in-round primary-border-3 border-round">{props?.node?.questions_count ? props?.node?.questions_count : '0'}</span>
                                <p>Number of questions</p>
                            </div>
                            <div className="count-box-1">
                                <div className="count-box-1">
                                    <span
                                        className="count-in-round primary-border-3 border-round">{state?.learners?.results?.length ? state?.learners?.results?.length: '0'}</span>
                                    <p>Number of learners</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-line-gap">&nbsp;</div>
                    <div className="table-listing-wraper">
                        <div className="datatble-wrap checkbox-datatable">

                            {nodesLearnerLoading && nodesLearnerLoading ?
                                (
                                    <>

                                            <Spinner animation="border" variant="danger"/>


                                    </>


                                ) : (

                                     <>
                                         <h4 className="sec-sub-head  m-b-15 ml-2">Learners</h4>
                                    <DataTable
                                        columns={columns}
                                        data={state?.learners?.results}
                                        showPaginationBottom={false}
                                    />

                                     </>
                                )}

                            {!nodesLearnerLoading && state?.learners && state?.learners && state?.learners?.count > 12 &&
                            <div className="d-flex justify-content-center">
                                <Pagination size="sm" className="custom-pagination">
                                    <Pagination.First disabled={!state?.learners?.previous}
                                                      className={`${state?.learners?.previous ? 'paginationStyle' : ''} `}
                                                      onClick={handlePrevious}/>
                                    {state.paginationItem.map(page => {
                                        if (!page.ellipsis) {
                                            return <li key={page.id}>
                                                <Pagination.Item
                                                    onClick={() => handleIndex(page.id)} key={page.id}
                                                    active={page.id == activePage}
                                                >
                                                    {page.id}
                                                </Pagination.Item>
                                            </li>
                                        } else {
                                            return <Pagination.Ellipsis></Pagination.Ellipsis>
                                        }
                                    })}
                                    <Pagination.Last disabled={!state?.learners?.next} onClick={handleNext}
                                                     className={`${state?.learners?.next ? 'paginationStyle' : ''} `}/>

                                </Pagination>

                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return (
            <p>No Data</p>


        )


    }
}
export default QuizSection;
