import React, {useEffect,useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import SurveyCreation from "../container";
//import SurveyInstitution from "../institutionContainer";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";

function SurveysManagement ({match}){
    const matchUrl = match.path;
    const { user } = useSelector(state => state.auth)
    const [key, setKey] = useState();
    const id = match?.params?.id;


    const [state, setState]= useState({
        instituteArr:[],
        defaultKey:'0',
    })

    const getChildState = (data,key)=>{
    
        setState({...state,instituteArr:data,defaultKey:key})
    }


    if(user && user?.user_type == 'admin' ) {

        return (
            <div className="tab-wrapper">
                <ToastContainer autoClose={2000}/>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={state?.defaultKey}
                    onSelect={(k) => setKey(k)}
                >
                    <Tab eventKey="0" title="Institution Type">
                        {/* <SurveyInstitution  getChildState={getChildState} id={id}/> */}
                    </Tab>


                    < Tab eventKey="1" title="Survey Designer" disabled={state?.instituteArr?.length == 0}>
                        <SurveyCreation instituteArray={state?.instituteArr} id={id} />
                    </Tab>


                </Tabs>
            </div>
        );
    }else{

        return (
            <SurveyCreation id={id}/>

        )
    }

};
export default SurveysManagement
