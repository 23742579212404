import React from 'react';

function LearningPathIcon() {
return (
   <React.Fragment>
<svg xmlns="http://www.w3.org/2000/svg" width="20.426" height="24.32" viewBox="0 0 20.426 24.32">
   <g transform="translate(-40.994)">
      <path className="a" d="M61.42,15.676a2.379,2.379,0,0,0-1.9-2.327V10.783a.713.713,0,0,0-.963-.667l-1.36.51a8.307,8.307,0,0,0-3.168-2.057,4.75,4.75,0,1,0-5.644,0,8.308,8.308,0,0,0-3.168,2.057l-1.36-.51a.713.713,0,0,0-.963.667v2.565a2.375,2.375,0,0,0,0,4.655v2.755a.713.713,0,0,0,.462.667l7.6,2.85a.721.721,0,0,0,.5,0l7.6-2.85a.713.713,0,0,0,.462-.667V18A2.379,2.379,0,0,0,61.42,15.676ZM47.882,4.75a3.325,3.325,0,1,1,3.325,3.325A3.329,3.329,0,0,1,47.882,4.75ZM51.207,9.5a6.877,6.877,0,0,1,4.508,1.681l-4.508,1.69L46.7,11.182A6.877,6.877,0,0,1,51.207,9.5Zm-8.788,6.175a.951.951,0,0,1,.95-.95h.475v1.9h-.475A.951.951,0,0,1,42.419,15.676Zm1.9,2.351a1.19,1.19,0,0,0,.95-1.164V14.488a1.19,1.19,0,0,0-.95-1.164V11.811l6.175,2.316V22.58l-6.175-2.316Zm7.6,4.553V14.127l6.175-2.316v1.513a1.19,1.19,0,0,0-.95,1.164v2.375a1.19,1.19,0,0,0,.95,1.164v2.238Zm7.125-5.955H58.57v-1.9h.475a.95.95,0,0,1,0,1.9Z"/>
   </g>
</svg>
</React.Fragment>
);
}
export default LearningPathIcon;