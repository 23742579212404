import React from 'react';
import ServerErrorImg from '~/assets/images/server-error.svg';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function ServerError (){

   return (
      <div className="error-page-wrap">
      <div className="error-content-wrap text-center">
          <div className="error-img">
              <img src={ServerErrorImg} alt="" />
          </div>
          <h4>Access Denied!</h4>
          <div className="error-btn-wrap">
              <Link to="/profile"><Button>Go Back</Button></Link>
          </div>
      </div>
  </div>
   );
};
export default ServerError;
