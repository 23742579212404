
import React from 'react';

function CourseTransitionIcon() {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="332.617" height="290.493" viewBox="0 0 332.617 290.493">
                <g id="undraw_prioritise_re_r5xu" transform="translate(0 0)">
                    <path id="Path_8722" data-name="Path 8722" d="M594.681,225.051H416.1a6.827,6.827,0,0,1-6.819-6.819V166.945a6.827,6.827,0,0,1,6.819-6.819H609.8a6.827,6.827,0,0,1,6.819,6.819v36.173A21.959,21.959,0,0,1,594.681,225.051Z" transform="translate(-338.535 -160.126)" fill="#f2f2f2"/>
                    <path id="Path_8723" data-name="Path 8723" d="M573.4,193.027H491.85a1.759,1.759,0,1,1,0-3.517H573.4a1.759,1.759,0,0,1,0,3.517Z" transform="translate(-378.856 -174.786)" fill="#fff"/>
                    <path id="Path_8724" data-name="Path 8724" d="M614.682,207.382H491.85a1.759,1.759,0,1,1,0-3.517H614.682a1.759,1.759,0,1,1,0,3.517Z" transform="translate(-378.856 -181.948)" fill="#fff"/>
                    <path id="Path_8725" data-name="Path 8725" d="M614.682,223.859H491.85a1.759,1.759,0,1,1,0-3.517H614.682a1.759,1.759,0,1,1,0,3.517Z" transform="translate(-378.856 -190.169)" fill="#fff"/>
                    <path id="Path_8726" data-name="Path 8726" d="M614.682,240.337H491.85a1.759,1.759,0,1,1,0-3.517H614.682a1.759,1.759,0,1,1,0,3.517Z" transform="translate(-378.856 -198.391)" fill="#fff"/>
                    <path id="Path_8727" data-name="Path 8727" d="M614.682,256.815H491.85a1.759,1.759,0,1,1,0-3.517H614.682a1.759,1.759,0,1,1,0,3.517Z" transform="translate(-378.856 -206.612)" fill="#fff"/>
                    <path id="Path_8728" data-name="Path 8728" d="M477.814,310.067h-202.9a6.827,6.827,0,0,1-6.819-6.819v-60.49a6.827,6.827,0,0,1,6.819-6.819h202.9a6.827,6.827,0,0,1,6.819,6.819v60.49A6.827,6.827,0,0,1,477.814,310.067Z" transform="translate(-268.095 -197.951)" fill="#e6e6e6"/>
                    <path id="Path_8729" data-name="Path 8729" d="M462.681,310.051H284.1a6.827,6.827,0,0,1-6.819-6.819V251.945a6.827,6.827,0,0,1,6.819-6.819H477.8a6.827,6.827,0,0,1,6.819,6.819v36.173A21.958,21.958,0,0,1,462.681,310.051Z" transform="translate(-272.676 -202.535)" fill="#fff"/>
                    <path id="Path_8730" data-name="Path 8730" d="M441.4,278.027H359.85a1.759,1.759,0,0,1,0-3.517H441.4a1.759,1.759,0,1,1,0,3.517Z" transform="translate(-312.997 -217.195)" fill="#ce002a"/>
                    <path id="Path_8731" data-name="Path 8731" d="M482.682,292.382H359.85a1.759,1.759,0,0,1,0-3.517H482.682a1.759,1.759,0,0,1,0,3.517Z" transform="translate(-312.997 -224.357)" fill="#e6e6e6"/>
                    <path id="Path_8732" data-name="Path 8732" d="M482.682,308.86H359.85a1.759,1.759,0,0,1,0-3.517H482.682a1.759,1.759,0,0,1,0,3.517Z" transform="translate(-312.997 -232.579)" fill="#e6e6e6"/>
                    <path id="Path_8733" data-name="Path 8733" d="M482.682,325.337H359.85a1.759,1.759,0,0,1,0-3.517H482.682a1.759,1.759,0,0,1,0,3.517Z" transform="translate(-312.997 -240.8)" fill="#e6e6e6"/>
                    <path id="Path_8734" data-name="Path 8734" d="M482.682,341.815H359.85a1.759,1.759,0,0,1,0-3.517H482.682a1.759,1.759,0,0,1,0,3.517Z" transform="translate(-312.997 -249.021)" fill="#e6e6e6"/>
                    <path id="Path_8735" data-name="Path 8735" d="M396.446,455.027a14.986,14.986,0,1,1,14.986-14.986A14.986,14.986,0,0,1,396.446,455.027Z" transform="translate(-296.332 -294.183)" fill="#e6e6e6"/>
                    <path id="Path_8736" data-name="Path 8736" d="M13.3,5.913H8.87V1.478a1.478,1.478,0,0,0-2.957,0V5.913H1.478a1.478,1.478,0,1,0,0,2.957H5.913V13.3a1.478,1.478,0,1,0,2.957,0V8.87H13.3a1.478,1.478,0,0,0,0-2.957Z" transform="translate(100.114 135.405) rotate(45)" fill="#fff"/>
                    <path id="Path_8739" data-name="Path 8739" d="M549.541,455.027a14.986,14.986,0,1,1,14.986-14.986A14.986,14.986,0,0,1,549.541,455.027Z" transform="translate(-399.811 -294.183)" fill="#ce002a"/>
                    <path id="Path_8740" data-name="Path 8740" d="M563.637,444.942l-4.248-6.2a1.26,1.26,0,1,0-2.079,1.424l2.748,4.01H549.293a1.478,1.478,0,1,0,0,2.957h10.9l-2.883,4.208a1.26,1.26,0,1,0,2.079,1.424l4.249-6.2a1.663,1.663,0,0,0,0-1.621Z" transform="translate(-405.477 -299.796)" fill="#fff"/>
                    <path id="Path_8741" data-name="Path 8741" d="M451.568,542.054h-6.055l-2.881-23.359h8.938Z" transform="translate(-220.844 -258.793)" fill="#a0616a"/>
                    <path id="Path_8742" data-name="Path 8742" d="M710.444,728.026H691.821v-.236a7.249,7.249,0,0,1,7.248-7.248h0l3.4-2.581,6.347,2.581h1.626Z" transform="translate(-479.506 -438.447)" fill="#2f2e41"/>
                    <path id="Path_8743" data-name="Path 8743" d="M535.927,530.745l-5.6,2.311-11.582-20.49,8.261-3.412Z" transform="translate(-258.82 -254.034)" fill="#a0616a"/>
                    <path id="Path_8744" data-name="Path 8744" d="M808.557,716.971l-17.212,7.11-.09-.218a7.249,7.249,0,0,1,3.932-9.467h0l2.159-3.684,6.851-.038,1.5-.621Z" transform="translate(-528.841 -434.503)" fill="#2f2e41"/>
                    <path id="Path_8745" data-name="Path 8745" d="M782.067,454.722s-4.692-14.8-2.747-20.854l10.817-.657,1.238,21.834-6.183,25.245a4.306,4.306,0,1,1-7.247-1.555Z" transform="translate(-521.906 -296.377)" fill="#a0616a"/>
                    <path id="Path_8746" data-name="Path 8746" d="M776.373,394.149l-1.621-7.134a20.889,20.889,0,0,0-9.292-13.08h0a9.144,9.144,0,0,0-9.652.007h0a9.123,9.123,0,0,0-3.154,12.05c3.339,6.167,3.356,13.154,8.3,13.351l-.194,2.566,6.8,14.866,13.316-3.361Z" transform="translate(-509.309 -266.117)" fill="#ce002a"/>
                    <path id="Path_8747" data-name="Path 8747" d="M740.965,480s3.464,11.522.732,21.359-4.934,33.563-4.934,33.563l20.393,50.358-9.487,2.446-30.312-49.519-2.86,51.936-9.145.375-6.9-52.011,5.475-52.31Z" transform="translate(-482.813 -319.721)" fill="#2f2e41"/>
                    <path id="Path_8748" data-name="Path 8748" d="M746.554,434.754l.055-.078c2.958-4.141-1.491-14.093-2.162-15.536h0a3.622,3.622,0,0,0,2.479-3.868l-.289-2.459-.3-2.412,2.16-2.16.006-.054,1.243-10.877,1.807-7.224a15.861,15.861,0,0,0-4.5-15.371l-4.178-.663L737.8,369.16h-8.188l-2.807,2.682c-5.241.919-4.137.619-8.639,6.021l-5.661,16.2.51,2.31V399.5l.8,9.245a4.486,4.486,0,0,0-1.064,3.881l.171.853h0c-1.338.573-2.615,10.764-2.92,12.187l-.456,2.126c-.39.514-2.963,3.945-2.963,4.883,0,.141.1.277.327.428,1.754,1.187,12.346,2.408,16.636.691,4.569-1.827,22.733.914,22.916.942Z" transform="translate(-486.87 -264.42)" fill="#ce002a"/>
                    <path id="Path_8749" data-name="Path 8749" d="M646.595,429.178s13.162-8.229,19.513-7.854l3.33,10.313L648.6,438.273l-25.989.3a4.306,4.306,0,1,1-.3-7.406Z" transform="translate(-441.658 -290.44)" fill="#a0616a"/>
                    <path id="Path_8750" data-name="Path 8750" d="M715.112,407.968l6.505-3.347a20.889,20.889,0,0,0,10.353-12.257h0a9.144,9.144,0,0,0-2.41-9.347h0a9.123,9.123,0,0,0-12.455-.054c-5.141,4.769-11.9,6.526-10.864,11.36l-2.533.452L691,405.068l6.571,12.06Z" transform="translate(-479.098 -270.095)" fill="#ce002a"/>
                    <circle id="Ellipse_330" data-name="Ellipse 330" cx="12.24" cy="12.24" r="12.24" transform="translate(234.655 77.178)" fill="#a0616a"/>
                    <path id="Path_8751" data-name="Path 8751" d="M738.7,306.63c.877-.026.576-1.68,1.283-2.207,0,.016.007.032.011.048a1.723,1.723,0,0,0,3.292.14,2.618,2.618,0,0,1,1.662-1.744,4.294,4.294,0,0,0,2.586,3.525,10.707,10.707,0,0,0,4.493.795,19.833,19.833,0,0,1,4.575.346,5.214,5.214,0,0,1,3.568,2.653,3.063,3.063,0,0,1-1.207,3.966,2.734,2.734,0,0,1,2.361,1.9,7.559,7.559,0,0,1,.233,3.193c-.3,3.958-1.04,8.219-3.922,10.949a12.048,12.048,0,0,1-8.366,2.906c-3.025-.012-2.493-.29-5.419-1.062,3.008.337,2.188-13.1-2.783-13.647a3.719,3.719,0,0,1-.129-.364,4.139,4.139,0,0,0-2.585-2.741,3.21,3.21,0,0,0-3.508,1.062C731.644,313.349,733.631,306.781,738.7,306.63Z" transform="translate(-500.199 -231.344)" fill="#2f2e41"/>
                    <path id="Path_8752" data-name="Path 8752" d="M876.609,721.216H859.98l-.072-.13c-.213-.386-.418-.794-.61-1.214-1.713-3.667-2.437-7.861-3.076-11.561l-.481-2.789a1.722,1.722,0,0,1,2.711-1.685q3.791,2.758,7.584,5.511c.958.7,2.051,1.5,3.1,2.375.1-.491.207-.983.313-1.469a1.723,1.723,0,0,1,3.147-.544l1.946,3.126c1.419,2.283,2.671,4.532,2.416,6.958a.381.381,0,0,1-.007.088,5.486,5.486,0,0,1-.282,1.168Z" transform="translate(-561.279 -431.236)" fill="#f0f0f0"/>
                    <path id="Path_8753" data-name="Path 8753" d="M772.784,738.379l-158.015.154a.6.6,0,1,1,0-1.193l158.016-.154a.6.6,0,0,1,0,1.193Z" transform="translate(-440.764 -448.04)" fill="#cacaca"/>
                </g>
            </svg>


        </React.Fragment>
    );
}
export default CourseTransitionIcon;
