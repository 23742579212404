import React, { useEffect, useState ,useRef} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {Link, useHistory,useLocation} from 'react-router-dom';
import ProfileBasicDetails from './components/basicdetails/container';
import MyGroup from './components/mygroups/container';
import ProfilePic from './components/profilepic/container';
import Subscription from './components/subscription/container';
import SubscriptionHistory from './components/subscriptionhistory/container';
import MyCertificate from './components/learnersdashboard'
import MyCards from './components/mycards/container';
import PhoneIcon from '~/assets/images/icons/phone-call.svg';
import LetsGoImg from '~/assets/images/letsgo.svg';
import {getContact, getTransactionHistory,editManagerContact, getManagerContact,createManagerContact,deleteManagerContact,
} from '../services';
import { useDispatch, useSelector } from 'react-redux';
import {ToastContainer} from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import Form from 'react-bootstrap/Form';
import { set } from 'date-fns';
import { Popconfirm } from 'antd';
// import 'antd/dist/antd.css';
import deleteIco from '~/assets/images/icons/delete.svg';
import editIco from '~/assets/images/icons/edit.svg';
import {Helmet} from "react-helmet";



const Profile = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  //  const response = useSelector(state => state.profile.contact_manager.response);
  const { user } = useSelector(state => state.auth);
  const [show,setShow] = useState(true)
  const  profile  = useSelector(state => state?.profile?.profile_details?.response);
  const [shouldShowPlans,setshouldShowPlans] = useState(false)
  const { hideLpQuizes } = useSelector((state) => state.settings);
  const response_contact=useSelector(state => state.profile?. manager_contact_details?.response ||null );
  const [completed, setCompleted] = useState('');
  const [ongoing, setOngoing] = useState('')
  const [contactEdit, setContactEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const[noContact,setNoContact] = useState(false);
  const [coarseprogress,setCoarseProgress] = useState([]);
  const [key, setKey] = useState('BasicDetails');
  // const [visible, setVisible] = useState(false);
  // const [confirmLoading, setConfirmLoading] = useState(false);


  const history = useHistory();

  // var regex = /^[0-9!@#\$%\^\&*\)\(+=._-]+$/g
  // const basicDetailsSchema = yup.object().shape({
  //   email: yup.string().email("email Invalid").required(),
  //   alternate_email: yup.string().email("email Invalid"),
  //   number: yup.string().required('MobileNumber is required')
  //       .matches(regex, 'Phone add valid number'),
  //   alternate_number: yup.string().matches(regex, 'Phone add valid number'),

  // });

useEffect(()=>{
    if(location?.state?.key){
        setKey(location?.state?.key);
        setshouldShowPlans(true);
    }
},[location?.state]);

  useEffect(() => {

    dispatch(getContact(user?.token));
    dispatch(getManagerContact());
    if(profile.courses_count && profile.courses_count.length){
      profile.courses_count.map((item)=>{
        if(item.status === "completed"){
          setCompleted(item?.count);
        }
        else{
          setOngoing(item.count);
        }
      })
    }
  }, []);
  // useEffect(() => {
  //   if( response_contact?.results?.length ===0 ){
  //     setNoContact(true)
  //   } else (setNoContact(false))

  // }, [response_contact?.results ])

  useEffect(() => {

    if(profile.courses_count && profile.courses_count.length){
      profile.courses_count.map((item)=>{
        if(item.status === "completed"){
          setCompleted(item.count);
        }
        else{
          setOngoing(item.count);
        }
      })
    }

  }, [profile]);

  const handleStatClick = (prop) => {

    if(prop == 'complete'){
      history.push({
        pathname : '/my-assignments',
        state :{
        category:"Completed",
        }
        }
      );
      // history.push('/completedCourses')

    }else if(prop == 'ongoing'){

      history.push({
        pathname : '/my-assignments',
        state :{
        category: "Courses",

        }
        }
      );
    }

  }





  const handleGroupClick = (prop) => {

    history.push('/grouplist')

  }


  // const showPopconfirm = () => {
  //   setVisible(true);
  //   setConfirmLoading(false)
  // };

  // const handleOk = () => {
  //   console.log('Clicked ok button');
  //   setConfirmLoading(true)

  //   const id=response_contact?.results[0]?.id ||0
  //   dispatch(deleteManagerContact(id))
  //   setTimeout(() => {
  //     dispatch(getManagerContact());
  //     setVisible(false);
  //   },2000);


  // };

  // const handleCancel = () => {

  //   setVisible(false);
  // };



  // const handleSubmit=(values)=>{
  //   setIsEdit(false)
  //   setContactEdit(false)
  //   console.log(values+JSON.stringify(values))
  //   const id=response_contact?.results[0]?.id
  //   const formData=new FormData();

  //   formData.append('email',values.email)
  //   formData.append('alternate_email',values.alternate_email)
  //   formData.append('phone_number',values.number)
  //   formData.append('alternate_phone_number',values.alternate_number);

  //   if(noContact){
  //     //create
  //     dispatch(createManagerContact(formData))
  //     setTimeout(() => {
  //       dispatch(getManagerContact());
  //     },2000);
  //   }else{
  //     //edit
  //     dispatch(editManagerContact(id,formData))
  //     setTimeout(() => {
  //       dispatch(getManagerContact());
  //     },2000);
  //   }

  // }

 
  function lp_count_result() {

    let array_of_lp_count = profile?.learning_path_count?.filter((el) => el.status != 'new' && el.status != 'failed')

    let findInProgress = profile?.learning_path_count.find((el)=>el.status == "in_progress")
    let findCompleted = profile?.learning_path_count.find((el)=>el.status == "completed")
      if(!findInProgress){
        array_of_lp_count.push({count:0,status:'in_progress'})
      }
      if(!findCompleted){
        array_of_lp_count.push({count:0,status:'completed'})
      }
    return array_of_lp_count
  }

  function coarse_count_result() {
  
      let array_of_count = profile?.course_count?.filter((el) => el.status != 'new' && el.status != 'failed') || []
  
      let findInProgress = profile?.course_count?.find((el)=>el.status == "in_progress")
      let findCompleted = profile?.course_count?.find((el)=>el.status == "completed")
        if(!findInProgress){
          array_of_count.push({count:0,status:'in_progress'})
        }
        if(!findCompleted){
          array_of_count.push({count:0,status:'completed'})
        }
        setCoarseProgress(array_of_count) 
    }

    useEffect(()=> {
      coarse_count_result();
    },[profile])

  return (
      <div className="profile-wrapper">
        <Helmet>
            <title>Profile - Cultural Engagement  </title>
        </Helmet>
        {/* <ToastContainer autoClose={2000} /> */}
        <Row>
          <Col md={4}>
            <ProfilePic />
          </Col>
          {!hideLpQuizes ? (
            <Col md={8}>
            <div className="whitebox shadow-box your-status-box">
              <div className="profile-status-wrap">
                <h4 className="text-center">Your Stats</h4>
                <div className="status-show-wrapper">
                  <div className="status-round-wrap" style={{ cursor:"pointer"}}  onClick={() =>handleGroupClick()} >
                    <span className="status-round"  >{profile?.groups_count ? profile?.groups_count : 0}</span>
                    <p className="text-center">Groups</p>
                  </div>
                  {lp_count_result() && lp_count_result()?.length && lp_count_result()?.map((el)=>(

                      <>
                        <div className="status-round-wrap" style={{ cursor:"pointer"}} onClick={() =>handleStatClick(el?.status == "in_progress" ? 'ongoing' : 'complete')}>
                          <span className="status-round">{el?.count ?  el?.count: 0}</span>
                          <p className="text-center"> {el?.status == "in_progress" ? 'Ongoing Learning Paths' :'Completed Learning Paths' }</p>
                        </div>

                        {/*<div className="status-round-wrap" style={{ cursor:"pointer"}}   onClick={() =>handleStatClick('ongoing')}>*/}
                        {/*  <span className="status-round">{ongoing ? ongoing :0}</span>*/}
                        {/*  <p className="text-center">Ongoing Learning Paths</p>*/}
                        {/*</div>*/}


                      </>

                  ))


                  }


                </div>
              </div>
            </div>
          </Col>
          ) : (
            <Col md={8}>
            <div className="whitebox shadow-box your-status-box">
              <div className="profile-status-wrap">
                <h4 className="text-center">Your Stats</h4>
                <div className="status-show-wrapper">
                  <div className="status-round-wrap" style={{ cursor:"pointer"}}  onClick={() =>handleGroupClick()} >
                    <span className="status-round"  >{profile?.groups_count ? profile?.groups_count : 0}</span>
                    <p className="text-center">Groups</p>
                  </div>
                  { coarseprogress?.map((el)=>(

                      <>
                        <div className="status-round-wrap" style={{ cursor:"pointer"}} onClick={() =>handleStatClick(el?.status == "in_progress" ? 'ongoing' : 'complete')}>
                          <span className="status-round">{el?.count ?  el?.count: 0}</span>
                          <p className="text-center"> {el?.status == "in_progress" ? 'Ongoing Courses' :'Completed Courses' }</p>
                        </div>

                        {/*<div className="status-round-wrap" style={{ cursor:"pointer"}}   onClick={() =>handleStatClick('ongoing')}>*/}
                        {/*  <span className="status-round">{ongoing ? ongoing :0}</span>*/}
                        {/*  <p className="text-center">Ongoing Learning Paths</p>*/}
                        {/*</div>*/}


                      </>

                  ))


                  }


                </div>
              </div>
            </div>
          </Col>
          )}
          
        </Row>
        <div className="profile-details-save m-t-50">
          <div className="tab-wrapper">
    

            <Tabs  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)} className="m-b-30">
              <Tab eventKey="BasicDetails" title='Basic Details'>
                <ProfileBasicDetails />
              </Tab>
              <Tab eventKey="certificates" title='My Certificates'>
                <MyCertificate />
              </Tab>
              {/* <Tab eventKey="MyGroups" title="My Groups"  tabClassName={user.user_type === "manager" || user.user_type === "admin"  ? 'd-none' : ''}>
                <MyGroup />
              </Tab> */}
              <Tab eventKey="MyCards" title="My Card" tabClassName={user.user_type === "manager" || user.user_type === "admin" ? '' : 'd-none'}>
                <MyCards />
              </Tab>
              <Tab eventKey="Subscription" title="Subscription" tabClassName={user.user_type === "manager" || user.user_type === "admin" ? '' : 'd-none'}>
                <Subscription shouldShowPlans = {shouldShowPlans} />
              </Tab>
              <Tab eventKey="SubscriptionHistory" title="Transaction History" tabClassName={user.user_type === "manager" || user.user_type === "admin" ? '' : 'd-none'}>
                <SubscriptionHistory />
              </Tab>


            </Tabs>
          </div>
        </div>


        {/* <Modal
            size="md"
            show={contactEdit}
            onHide={() => setContactEdit(false)}
            onExit={() => {setIsEdit(false);setVisible(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
            className="common-modal inner-popup"
        >
          <Modal.Header closeButton>
            <div className="addmember-header">
              <h4>Contact Manager</h4>

            </div>

          </Modal.Header>
          <Modal.Body>

            <div className="whitebox shadow-box support-box support-bg-box">

              {
                noContact && !isEdit? <div><b>NO CONTACT DETAILS ADDED </b> </div> :
                    <>

                      {!isEdit  ?
                          <>
                            <div className="contact-text-row d-flex align-items-center">

                            <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.239" height="18.265" viewBox="0 0 18.239 18.265">
                                <g id="phone-call" transform="translate(-0.344 0)">
                                    <g id="Group_6537" data-name="Group 6537" transform="translate(0.344 0)">
                                        <path id="Path_7939" data-name="Path 7939" d="M14.767,37.664a1.8,1.8,0,0,0-1.3-.6,1.858,1.858,0,0,0-1.318.594l-1.218,1.214c-.1-.054-.2-.1-.3-.154-.139-.069-.27-.135-.382-.2a13.24,13.24,0,0,1-3.173-2.891,7.811,7.811,0,0,1-1.041-1.642c.316-.289.609-.59.894-.879.108-.108.216-.22.324-.328a1.769,1.769,0,0,0,0-2.668L6.2,29.056c-.12-.12-.243-.243-.359-.366-.231-.239-.474-.486-.725-.717a1.825,1.825,0,0,0-1.291-.567,1.888,1.888,0,0,0-1.311.567l-.008.008L1.2,29.3A2.82,2.82,0,0,0,.36,31.1a6.758,6.758,0,0,0,.493,2.86A16.6,16.6,0,0,0,3.8,38.875,18.141,18.141,0,0,0,9.844,43.6a9.412,9.412,0,0,0,3.392,1c.081,0,.166.008.243.008a2.9,2.9,0,0,0,2.224-.956c0-.008.012-.012.015-.019a8.763,8.763,0,0,1,.675-.7c.166-.158.335-.324.5-.5a1.923,1.923,0,0,0,.582-1.334,1.853,1.853,0,0,0-.594-1.322Zm1.38,4.059s0,0,0,0c-.15.162-.3.308-.47.47a10.136,10.136,0,0,0-.744.771,1.858,1.858,0,0,1-1.449.613c-.058,0-.12,0-.177,0a8.361,8.361,0,0,1-3.007-.9,17.124,17.124,0,0,1-5.69-4.456,15.651,15.651,0,0,1-2.776-4.622A5.5,5.5,0,0,1,1.4,31.18a1.77,1.77,0,0,1,.532-1.145l1.315-1.315a.876.876,0,0,1,.586-.274.825.825,0,0,1,.563.27l.012.012c.235.22.459.447.694.69.12.123.243.247.366.374l1.052,1.052a.736.736,0,0,1,0,1.2c-.112.112-.22.224-.332.332-.324.332-.632.64-.968.941-.008.008-.015.012-.019.019a.786.786,0,0,0-.2.875l.012.035a8.449,8.449,0,0,0,1.245,2.032l0,0a14.149,14.149,0,0,0,3.423,3.115,5.264,5.264,0,0,0,.474.258c.139.069.27.135.382.2.015.008.031.019.046.027a.836.836,0,0,0,.382.1.824.824,0,0,0,.586-.266l1.318-1.318a.872.872,0,0,1,.582-.289.785.785,0,0,1,.555.281l.008.008,2.124,2.124A.762.762,0,0,1,16.147,41.724Z" transform="translate(-0.344 -26.35)" fill="#333" />
                                        <path id="Path_7940" data-name="Path 7940" d="M245.372,86.958a4.961,4.961,0,0,1,4.04,4.04.517.517,0,0,0,.513.432.688.688,0,0,0,.089-.008.521.521,0,0,0,.428-.6,6,6,0,0,0-4.888-4.888.523.523,0,0,0-.6.424A.515.515,0,0,0,245.372,86.958Z" transform="translate(-235.514 -82.613)" fill="#333" />
                                        <path id="Path_7941" data-name="Path 7941" d="M257.429,8.057A9.878,9.878,0,0,0,249.38.008a.52.52,0,1,0-.17,1.025A8.823,8.823,0,0,1,256.4,8.227a.517.517,0,0,0,.513.432.688.688,0,0,0,.089-.008A.511.511,0,0,0,257.429,8.057Z" transform="translate(-239.198 0)" fill="#333" />
                                    </g>
                                </g>
                            </svg>
                            </span>
                              <div>
                                <p>{response_contact?.results && response_contact?.results.length && response_contact?.results[0] && response_contact?.results[0]?.phone_number ? response_contact?.results[0]?.phone_number :''}</p>
                                <p className='mt-1'>{response_contact?.results && response_contact?.results.length && response_contact?.results[0] && response_contact?.results[0]?.alternate_phone_number? response_contact?.results[0]?.alternate_phone_number :''}</p>
                              </div>
                            </div>
                            <div className="contact-text-row d-flex align-items-center ">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.067" height="13" viewBox="0 0 17.067 13">
                                <g id="email_9_" data-name="email (9)" transform="translate(0 -61)">
                                    <g id="Group_6538" data-name="Group 6538" transform="translate(0 61)">
                                        <path id="Path_7942" data-name="Path 7942" d="M15.567,61H1.5A1.5,1.5,0,0,0,0,62.5v10A1.5,1.5,0,0,0,1.5,74H15.567a1.5,1.5,0,0,0,1.5-1.5v-10A1.5,1.5,0,0,0,15.567,61Zm-.207,1L8.565,68.794,1.712,62ZM1,72.293V62.7l4.816,4.775ZM1.707,73l4.819-4.819,1.689,1.674a.5.5,0,0,0,.706,0l1.646-1.646L15.36,73Zm14.36-.707L11.274,67.5l4.793-4.793Z" transform="translate(0 -61)" fill="#333" />
                                    </g>
                                </g>
                            </svg>
                            </span>
                              <div class="d-flex flex-wrap flex-column contact-emails-wrap">
                                <a style={{textDecoration:"none",color:'inherit'}} href={`mailto:${response_contact?.results && response_contact?.results.length && response_contact?.results[0]  ? response_contact?.results[0]?.email : ""}`}>
                                  <p>{response_contact?.results && response_contact?.results.length && response_contact?.results[0] && response_contact?.results[0]?.email ? response_contact?.results[0]?.email :''}</p>
                                </a>
                                <a style={{textDecoration:"none",color:'inherit'}} href={`mailto:${response_contact?.results && response_contact?.results.length && response_contact?.results[0] ? response_contact?.results[0]?.alternate_email : ''}`}>
                                  <p>{response_contact?.results && response_contact?.results.length && response_contact?.results[0] && response_contact?.results[0]?.alternate_email ? response_contact?.results[0]?.alternate_email :''}</p>
                                </a>
                              </div>
                            </div>
                          </>   :''}

                    </>
              }




              {isEdit  ?
                  <>
                    <Formik enableReinitialize={true} initialValues={{
                      email:response_contact?.results && response_contact?.results.length && response_contact?.results[0] && response_contact?.results[0]?.email ? response_contact?.results[0]?.email :'',
                      alternate_email:response_contact?.results && response_contact?.results.length && response_contact?.results[0] && response_contact?.results[0]?.alternate_email ? response_contact?.results[0]?.alternate_email :'',
                      number: response_contact?.results && response_contact?.results.length && response_contact?.results[0] && response_contact?.results[0]?.phone_number ? response_contact?.results[0]?.phone_number :'',
                      alternate_number:response_contact?.results && response_contact?.results.length && response_contact?.results[0] && response_contact?.results[0]?.alternate_phone_number ? response_contact?.results[0]?.alternate_phone_number :''
                    }}
                            validationSchema={basicDetailsSchema}
                            onSubmit={handleSubmit}>
                      {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <div
                            >

                              <Form.Label><b>Mobile Number *</b></Form.Label>
                              <Form.Control type="text" name="number" placeholder="Your Mobile Number" className="input-item" value={values.number} onChange={handleChange} isInvalid={!!errors.number} />
                              <Form.Control.Feedback type="invalid">
                                {errors.number}

                              </Form.Control.Feedback>




                              <div className='mt-2'>
                                <Form.Label><b>Alternate Mobile Number (optional)</b></Form.Label>
                                <Form.Control type="text" name="alternate_number" placeholder="Your Mobile Number" className="input-item" value={values. alternate_number} onChange={handleChange} isInvalid={!!errors. alternate_number} />
                                <Form.Control.Feedback type="invalid">
                                  {errors. alternate_number}
                                </Form.Control.Feedback>
                              </div>


                            </div>

                            <div
                            >

                              <Form.Label><b>Email *</b></Form.Label>
                              <div className="coupon-input-wrap p-relative">
                                <Form.Control type="text" name="email" placeholder="Your email" className="input-item" value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                              </div>
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>



                              <div className='mt-2'>
                                <div className="coupon-input-wrap p-relative">
                                  <Form.Label><b>Alternate Email (optional)</b></Form.Label>
                                  <Form.Control type="text" name="alternate_email" placeholder="Your email" className="input-item" value={values. alternate_email} onChange={handleChange} isInvalid={!!errors. alternate_email} />
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  {errors. alternate_email}
                                </Form.Control.Feedback></div>



                            </div>


                            <div className="btn-wrapper double-btn d-flex justify-content-start  m-t-20 m-b-10">

                              <button type="button" className="btn border-btn"  onClick={()=>setIsEdit(false)}>Cancel</button>

                              <button type="submit"  className="btn btn-primary" >Save</button>
                            </div>

                          </Form>
                      )}
                    </Formik>

                  </>   :''}


              {
                user.user_type != "manager" ? ''
                    :
                    <>
                      {noContact && !isEdit?
                          <div className="button-wrapper d-flex justify-content-end m-t-20 m-b-10"><Button onClick={()=>setIsEdit(true)} >CREATE CONTACT</Button></div>
                          :
                          <>
                            {!isEdit &&
                                <div className=" d-flex double-btn m-t-20">

                                    <button onClick={()=>setIsEdit(true)} disabled={visible}
                                             className="btn btn-primary">Edit</button>


                                    <Popconfirm
                                        title="Are you sure to delete this contact details?"
                                        visible={visible}
                                        onConfirm={()=>handleOk()}
                                        okText="Yes"
                                        cancelText="No"
                                        placement="bottom"
                                        okButtonProps={{loading: confirmLoading}}
                                        onCancel={()=>handleCancel()}
                                    >



                                      <button  type="primary"
                                          //  onClick={managerDelete}
                                               onClick={showPopconfirm}
                                               className="btn btn-primary">Delete</button>

                                    </Popconfirm>


                                  </div>



                                }
                          </>
                      }
                    </>
              }



            </div>



          </Modal.Body>
        </Modal> */}





      </div>







  );

};

export default Profile;
