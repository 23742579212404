import React from 'react';
import ScrollArea from 'react-scrollbar';
import contentImg from '~/assets/images/content-pic-dummy.jpeg';

const ModuleEight = (props) => {
    const {value, index, length} = props;

    return (
        <div className="videomodule">

            <ScrollArea
                speed={0.8}
                className="modulemodalscroll"
                contentClassName="content"
                horizontal={false}
            >
                <div className="video-content-wrap">

                    <div className="module-content">
                                  <div className="w-100 d-flex mob-flex-wrap">
                            <div className="image-wrap-item image-left-wrap">
                                <img src={value?.image} alt="" className="content-img content-img-leftside" />
                            </div>
                            <div className="image-with-text-p image-left-p">
                            <h3 className="module-content-header">{value?.name}</h3>
                                 <p><span dangerouslySetInnerHTML={{__html: `${value?.contents ? value?.contents : ''}`}}></span></p>
                                 </div>
                        </div>
                        
                    </div>
                </div>
            </ScrollArea>

        </div>
    );

};

export default ModuleEight;
