
import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Demographics from './components/demographics';
import Responses from './components/responses';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import { listSurveyReportQuestions } from "../services"
import FreeResponses from './components/FreeResponses';
import FreeResponsesQuestion from './components/freeresponsesquestion';

function SurveyGraph({ match }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const responseQuestionList = useSelector(state => state?.surveys?.listSurveyReportQuestions?.response || {})
    const loadingList = useSelector(state => state.surveys?.listSurveyReportQuestions?.loading || false)
    const id = match.params.id
    const [survey, setSurvey] = useState({})
    const [freeResponse, setFreeResponse] = useState(false)
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(false)
    const [DemoQuestions, setDemoQestions] = useState(false)
    const [state, setState] = useState({
        defaultKey: '0',

    })
    useEffect(() => {
        setLoading(true)
        dispatch(listSurveyReportQuestions(id, function (response) {
             setSurvey(response)

            if (response?.demographic_questions?.length) {
                setDemoQestions(true)
                setState({ ...state, defaultKey: "1" })
            }


            if (response?.general_questions?.length) {
                let generalQS = response?.general_questions?.find((item) => item?.question_type != "free_response")
                let freeResponses = response?.general_questions?.find((item) => item?.question_type == "free_response")

                if (generalQS) {
                    setResponse(true)
                        if(!response?.demographic_questions?.length)
                        { setState({ ...state, defaultKey: "2" })}
                
                }

                if (freeResponses) {
                    setFreeResponse(true)
                    if(!DemoQuestions&&!response){ setState({ ...state, defaultKey: "3" })}
                }



            }
            if(response?.demographic_questions?.length){
                setState({ ...state, defaultKey: "1" }) 
            }
            else if (response?.general_questions?.find((item) => item?.question_type != "free_response"))
            { setState({ ...state, defaultKey: "2" }) }

            else if (!response?.general_questions?.find((item) => item?.question_type != "free_response") && !response?.demographic_questions?.length)
             { setState({ ...state, defaultKey: "3" }) }
             
setTimeout(() => {
    setLoading(false)
    
}, 200);


        }))
    }, [])

    // useEffect(() => {
    //     setSurvey(responseQuestionList)
    //     console.log("response of surveys", responseQuestionList)
    //     if (responseQuestionList?.general_questions?.length) {
    //         responseQuestionList?.general_questions.map((item, index) => {
    //             if (item?.question_type == "free_response") {

    //                 setFreeResponse(true)
    //             }
    //             if (item?.question_type != "free_response") {
    //                 setResponse(true)
    //             }

    //         }
    //         )
    //     }
    //     if (responseQuestionList?.demographic_questions?.length && responseQuestionList?.demographic_questions) {
    //         setState({ ...state, defaultKey: "1" })
    //     }
    //     else if (responseQuestionList?.general_questions?.length && respond) {
    //         setState({ ...state, defaultKey: "2" })

    //     } else if (responseQuestionList?.general_questions?.length && freeRespond) {
    //         setState({ ...state, defaultKey: "3" })
    //     }




    // }, [responseQuestionList])


    return (
        <div className="tab-wrapper">
            {loadingList || loading ?
                <div className="loader-full-screen-course">
                    <div className="loader">
                        <Spinner animation="border" variant="danger" />
                    </div>
                </div>
                :
                <Tabs onSelect={(k) => setState({ ...state, defaultKey: k })} activeKey={state?.defaultKey} id="uncontrolled-tab-example" className="m-b-30">
                    <p>Individual responses can be viewed in these interactive graphics. Select the desired variable with which to view. </p>

                    {responseQuestionList?.demographic_questions?.length ?
                        <Tab title="Demographics" eventKey="1" disabled={!responseQuestionList?.demographic_questions?.length}>
                            <Demographics demographicQuestions={responseQuestionList?.demographic_questions} surveyId={id} />
                        </Tab>
                        : ''}

                    {response ?
                        <Tab title="Responses" eventKey="2" disabled={!responseQuestionList?.general_questions?.length || !response}>
                            <Responses generalQuestions={responseQuestionList?.general_questions} surveyId={id} demographicQuestions={responseQuestionList?.demographic_questions} />

                        </Tab>
                        : ''}
                    {freeResponse ?
                        <Tab title="Free Responses" eventKey="3" disabled={!responseQuestionList?.general_questions?.length || !freeResponse}>
                            <FreeResponses generalQuestions={responseQuestionList?.general_questions} surveyId={id} demographicQuestions={responseQuestionList?.demographic_questions} />
                        </Tab>
                        : ''}

                </Tabs>
            }
        </div>
    );
};

export default SurveyGraph;
