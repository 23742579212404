import React, {useState, useEffect, useCallback} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from 'date-fns';
import Select, { components } from "react-select" ;
import CourseDummy from '~/assets/images/course_dummy.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import debounce from "lodash.debounce";

import {
    getManagerDraftLearningPaths, getManagerLearningPathList,
    handlePaginationDraftLearningPath,
    handlePreviousPaginationDraftLearningPath,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';

import {
    getManagersList,

} from '~/modules/surveys/services';

import {useDispatch, useSelector} from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import Pagination from "react-bootstrap/Pagination";
import {useHistory} from "react-router-dom";
import {getNotifications} from "../../../Notifications/services";
import CustomPagination from "../../../../shared/components/pagination";
import {getManagerCoursesList} from "../../../coursemanagement/services";
import {getInstitutionType} from "../../../auth/services";
const DraftLearningPaths = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [search, setSearch] = useState('');
    const response = useSelector(state => state?.learningpath?.learning_path_draft?.response);
    const loading = useSelector(state => state?.learningpath?.learning_path_draft?.loading);
    const managers = useSelector(state => state.surveys.manager_list?.response || [] || []);
    const { user } = useSelector(state => state.auth);
    const [state, setState] =useState({
        learningPaths:response,
        count:response?.count,
        paginationItem:[]
    })
    const [activePage, setActivePage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [createdby, setCreatedby] = useState('');
    const [count,setCount] = useState(0)
    const [category, setCategory] = useState([]);
    const [categorySelected, setCategorySelected] = useState('');
    useEffect(() => {
        setSearch('');
        setDateRange([null, null]);
        setCreatedby('');
        dispatch(getManagerDraftLearningPaths(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate, "False", createdby === '' ? '' : createdby?.id,activePage ? activePage : '', categorySelected ? categorySelected.value:''));
        dispatch(
            getInstitutionType(function (response) {
                if (response) {
                    let newMap = response?.results?.map((el) => {
                        return {
                            label: el?.name,
                            value: el?.id,
                        };
                    });
                    setCategory(newMap);

                }
            })
        );
        // dispatch(getNotifications())

        dispatch(getManagersList());
    }, []);
    useEffect(()=>{
        if(count > 0 && dateRange[0] == null){
            setActivePage(1);
            dispatch(getManagerDraftLearningPaths("", startDate === null ? '' : '', endDate === null ? '' : '', "False", createdby === '' ? '' : '',activePage ? activePage : '', categorySelected ? categorySelected.value:''));

        }
    },[count])
    useEffect(() => {
        setState({...state, learningPaths:response});
    }, [response]);
    useEffect(() => {

        if (startDate !== null && endDate !== null) {
            setActivePage(1);
            dispatch(getManagerDraftLearningPaths(search, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd'), "False", createdby === '' ? '' : createdby?.id,activePage ? activePage : '', categorySelected ? categorySelected?.value:''));
        }

    }, [startDate, endDate]);

    const debouncedGetSearch = useCallback(debounce((query,startDate,endDate,publish,createdby,activePage,categorySelected) => {
        setActivePage(1)
        dispatch(getManagerDraftLearningPaths(query, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), "False", createdby === '' ? '' : createdby?.id,activePage ? activePage : '', categorySelected ? categorySelected?.value:''));
    },800),[])



    const searchText = (e) => {
        setSearch(e.target.value);
        debouncedGetSearch(e.target.value,startDate,endDate,'',createdby === '' ? '' : createdby,activePage ? activePage : '',categorySelected ? categorySelected:'')

    };

    const reset = () => {
        setSearch('');
        setDateRange([null, null]);
        setCreatedby('');
        dispatch(getManagerDraftLearningPaths("", startDate === null ? '' : '', endDate === null ? '' : '', "False", createdby === '' ? '' : '',activePage ? activePage : '', categorySelected ? categorySelected?.value:''));
    };
    const onSelect = (val) => {
        setCreatedby(val);
        setActivePage(1);
        dispatch(getManagerDraftLearningPaths(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), "False", val.id,activePage ? activePage : '', categorySelected ? categorySelected?.value:''));
    };
    const handleNext = () => {
        if (state?.learningPaths?.next) {
            var regex = /page=\d+/g;
            const [number ] = state.learningPaths?.next?.match(regex)
            setActivePage(prevVal => prevVal === state.paginationItem ? prevVal : prevVal + 1);
            dispatch(handlePaginationDraftLearningPath(state.learningPaths?.next));
        } else {
            return false;
        }

    }
    const handlePrevious = () => {
        if (state.learningPaths?.previous) {
            setActivePage(prevVal => prevVal - 1 === 0 ? prevVal : prevVal - 1);
            dispatch(handlePreviousPaginationDraftLearningPath(state.learningPaths?.previous));

        } else {
            return false;
        }
    };
    const handleIndex = (index) =>{


            dispatch(getManagerDraftLearningPaths(search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), "False", createdby === '' ? '' : createdby?.id, index,categorySelected ? categorySelected?.value:''));

        setActivePage(index);
    }
    const onCategorySelect=(val)=>{
        setCategorySelected(val);
        setActivePage(1)


        dispatch(getManagerDraftLearningPaths(search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate === null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), "False", createdby === '' ? '' : createdby?.id, activePage ? activePage : '', val?.value));

    }

    const handleCardClick  =(el)=>{
        history.push(`/learningmanagementedit/${el?.id}`)
    }
    const CaretDownIcon = () => {
        return (
           <div className="filter-icon-select">
           <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
           <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
           </svg>
           </div>
        );
      };
      
      const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownIcon />
          </components.DropdownIndicator>
        );
      };
    return (
        <div className="available-course-wrap">
            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="datatable-filter-left-box d-flex align-items-center">
                    <div className="datepicker-box p-relative">
                        <DatePicker
                            placeholderText={'Created date'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setCount(count+1)
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        <span className="datepickericon">
                     <i className="fa fa-calendar"></i>
                  </span>
                    </div>

                    <div className="dt-filter-left d-flex justify-content-between align-items-center">
                        <div className="custom-react-select">
                            <Select options={managers || []}
                                    getOptionLabel ={(option)=>option.label.length>1 ?option.label :option.email_label}
                                    value={createdby} onChange={onSelect}
                                    placeholder="Author" components={{ DropdownIndicator }}/>
                        </div>
                        {/*<button type="button" className="iconbutton">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653"*/}
                        {/*         viewBox="0 0 19.652 19.653">*/}
                        {/*        <path className="a"*/}
                        {/*              d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0"*/}
                        {/*              transform="translate(-0.5 0)"/>*/}
                        {/*    </svg>*/}
                        {/*</button>*/}
                    </div>
                    {user?.is_admin_institution &&
                    <div className="dt-filter-left d-flex justify-content-between align-items-center">
                        <div className="custom-react-select">
                            <Select options={category || []}
                                    value={categorySelected} onChange={onCategorySelect} placeholder="Category" components={{ DropdownIndicator }}/>
                        </div>

                        {/*<button type="button" className="iconbutton">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653"*/}
                        {/*         viewBox="0 0 19.652 19.653">*/}
                        {/*        <path className="a"*/}
                        {/*              d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0"*/}
                        {/*              transform="translate(-0.5 0)"/>*/}
                        {/*    </svg>*/}
                        {/*</button>*/}
                    </div>
                    }
                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative">
                        <input type="text" placeholder="Search using keyword"
                               value={search} onChange={searchText}
                               className="form-control input-item"/>
                        <span className="search-close-icon" style={{cursor: "pointer"}} onClick={reset}>×</span>
                    </div>
                    <button className="iconbutton search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216"
                             viewBox="0 0 19.216 19.216">
                            <path className="a"
                                  d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="courseslisting">
                <Row>
                    {loading && loading ?
                        (
                            <>
                                <div className='loaderCourses'>
                                    <Spinner animation="border" variant="danger"/>
                                </div>

                            </>


                        ) : (
                            <>

                                {!loading && state.learningPaths && state.learningPaths && state.learningPaths?.results && state.learningPaths?.results?.length ? state.learningPaths?.results?.map((el, index) => (
                                        <Col style= {{cursor:'pointer'}} md={4} lg={3} className="m-b-30 course-box-outer">

                                            <div className="common-card coursecard" onClick={()=>handleCardClick(el)}>
                                                <div className="course-box-top">
                                                    <div className="course-listing-img">
                                                        <img src={el?.banner_image ? el?.banner_image :CourseDummy} alt=""/>
                                                    </div>
                                                    <div className="common-card-detail-wrap box-listing">
                                                        <h4>{el?.name?.substring(0, 50) + ' '  +  (el?.name?.length > 50 ?  '...' : '')}</h4>
                                                        <p>{el?.description?.substring(0, 50) + ' '  +  (el?.description?.length > 50 ?  '...' : '')}</p>
                                                    </div>
                                                    {/*<div className="coommon-card-det-number d-flex justify-content-between">*/}
                                                    {/*    <span className="common-card-oth-det-left">Modules <span*/}
                                                    {/*        className="det-numb">{el?.modules_count}</span> </span>*/}
                                                    {/*    <span className="common-card-oth-det-left">Quizzes <span*/}
                                                    {/*        className="det-numb">{el?.quizzes_count}</span> </span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                {/*<div className="common-card-footer border-top-footer">*/}
                                                {/*<div*/}
                                                {/*    className="common-card-footer-cont no-border d-flex justify-content-between align-items-center">*/}
                                                {/*    /!*<div className="grp-name">Group name</div>*!/*/}
                                                {/*    {el?.is_owned_by_admin ?*/}
                                                {/*        <div>*/}
                                                {/*            {el?.created_by == user?.user_id ?*/}
                                                {/*                <>*/}
                                                {/*                    <button type="button" onClick={e=>handleDeleteCourse(el)} className="btn border-btn">Delete</button>*/}
                                                {/*                    <button type="button" className="btn border-btn" onClick={()=>handleEdit(el?.id)}>Edit</button>*/}
                                                {/*                    /!*<button type="button" className="btn border-btn mt-2" onClick={(e)=>handleEnroll(e,el)}>Enroll Users</button>*!/*/}
                                                {/*                </>*/}
                                                {/*                :*/}
                                                {/*                ''*/}

                                                {/*            }*/}

                                                {/*        </div>*/}
                                                {/*        :*/}
                                                {/*        <>*/}
                                                {/*        <button type="button" onClick={e=>handleDeleteCourse(el)} className="btn border-btn">Delete</button>*/}
                                                {/*        <button type="button" className="btn border-btn" onClick={()=>handleEdit(el?.id)}>Edit</button>*/}
                                                {/*        </>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                                {/*</div>*/}
                                                <div className="box-overlay">
                                                    <span className="overlay-viewmore">View More<i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                                                    <div className="overlay-bg">&nbsp;</div>
                                                </div>

                                            </div>


                                        </Col>
                                    ))
                                    :
                                    <>
                                        {search?.length > 0 || createdby || categorySelected || dateRange[0] || dateRange[1] ?
                                            <span className='noData'>No data available</span>
                                            :

                                            <span className='noData text-center'>You have no draft Learning Paths</span>
                                        }
                                    </>

                                }

                            </>


                        )

                    }

                </Row>
                {!loading && state?.learningPaths?.count > 12 ?

                    <CustomPagination response = {state?.learningPaths} activePage ={activePage} handleNext = {handleNext} handlePrevious={handlePrevious} handleIndex = {handleIndex}/>
                    :
                    ''
                }
            </div>
        </div>
    );
};
export default DraftLearningPaths;
