import React, {memo, useContext, useState} from 'react';

import {Handle, removeElements} from 'react-flow-renderer';
import {FlowContext} from "../container";
import {toast, ToastContainer} from "react-toastify";
import Col from "react-bootstrap/Col";
import CourseDummy from '~/assets/images/course_dummy.png';
import {deleteElementByIdLp} from "../../../services";
import {useDispatch, useSelector} from "react-redux";
export default memo(({ data, isConnectable,...rest }) => {
    const [elements,setElements,nodeId,setNodeId,lpId] =useContext(FlowContext)
    const [nestedNode,setNestedNode] = useState(null)
    const deleteLoading = useSelector(state => state?.learningpath?.delete_lp_module?.loading);
    const toastId = React.useRef(null);
    let dispatch = useDispatch();
    const handleRemove = (e) =>{
        e.stopPropagation();
        let elmentsToRemove = elements?.filter((el)=>el.id == rest.id)
        let singleElement = elements?.find((el)=>el.id == rest.id)
        setNestedNode(singleElement.id)
        let copyElements = [...elements]
        if(singleElement.is_root){
            if(singleElement.is_created){
                dispatch(deleteElementByIdLp(lpId,rest.id,function (res){
                    if(res){
                        setElements([]);
                        setNodeId('')
                    }
                }))
            }else{
                setElements([]);
                setNodeId('')
            }
        }else {
            copyElements = copyElements.map((el) => {
                return {
                    ...el,
                    ...(!el?.source?.length && {parent: el.parent == rest.id ? null : el.parent ? el.parent : null}),
                    ...(!el?.source?.length && {criteria: el.parent == rest.id ? null : el.criteria ? el.criteria : null}),

                }
            })


            if(singleElement.is_created) {
                dispatch(deleteElementByIdLp(lpId,rest.id,function (res){
                    if(res){
                        if (nodeId == rest.id) {
                            setNodeId('')
                        }
                        setElements(copyElements)
                        setTimeout(() => {
                            setElements((els) => removeElements(elmentsToRemove, els));
                        }, 10)

                    }
                }))

            }else{
                if (nodeId == rest.id) {
                    setNodeId('')
                }
                setElements(copyElements)
                setTimeout(() => {
                    setElements((els) => removeElements(elmentsToRemove, els));
                }, 10)
            }
        }

    }
    const isValidConnectionSource = (connection) => {
        let findParent = elements.find((el)=>el.id == connection.source)
        let findChild = elements.find(((el)=>el.id == connection.target))
        let childrens = elements.filter((el)=>el.source == findParent.id)
        if( childrens.length  == 1){
            if( !toast.isActive(toastId.current)) {
                 toast.error("Only 1 course can be connected to cours!", {
                     toastId: 'success1',
                 });

            }else{
                // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
            }

            return false
        }else {
            return true
        }

    }
    const isValidConnection = (connection) => {
        let findParent = elements.find((el)=>el.id == connection.source)
        let findChild = elements.find(((el)=>el.id == connection.target))
        let childrens = elements.filter((el)=>el.source == findParent.id)
        if(findParent?.data?.selType =='quiz' && childrens.length  == 4){


            if( !toast.isActive(toastId.current)) {
             toast.error("Only 4 courses can be connected to quiz");

            }else{
                // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
            }

            return false
        }else if(findParent?.data?.selType =='course' && childrens.length  == 1){
            if( !toast.isActive(toastId.current)) {
              toast.error("Only 1 quiz can be connected to coursez", {
                  toastId: 'success1',
              });

            }else{
                // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
            }
            return false
        }  else{
            if(childrens.find((el)=>el.sourceHandle == connection.sourceHandle) ||  childrens.find((el)=>el?.data?.id == findChild?.data?.id)){

                if(childrens.find((el)=>el.sourceHandle == connection.sourceHandle)){
                    if( !toast.isActive(toastId.current)) {
                 toast.error("Only one node can be connected to one handle!", {
                     toastId: 'success1',
                 });

                    }else{
                        // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                    }
                }else{
                    if( !toast.isActive(toastId.current)) {
                      toast.error("Course can not be repeated on same node !", {
                          toastId: 'success1',
                      });

                    }else{
                        // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })  // toast.dismiss('Quiz can not be connected to Quiz', { delay: 1000 })
                    }
                }
                return  false
            }else{
                return true
            }


        }

    }
    return (
        <>
            {/*<ToastContainer  autoClose={2000}/>*/}
            {/*<div className="close-icon nodrag" onClick={(e)=>handleRemove(e)}>*/}
            {/*    x*/}
            {/*</div>*/}
            <div className="custom-node itscourse handle-custom ">
                <div >
                    <div className="" >
                        <div className="course-box-top">
                            <div className="course-listing-img">
                                <img src={data?.image ? data?.image :CourseDummy} alt="" />
                            </div>
                            <div className="common-card-detail-wrap box-listing">
                                <h4>{data?.name}</h4>
                            </div>
                            <div className="coommon-card-det-number d-flex justify-content-between">
                                <span className="common-card-oth-det-left">Modules <span className="det-numb">{data?.modules_count}</span> </span>
                            </div>
                            {deleteLoading && nestedNode && nestedNode == rest.id  ?

                                <div className="box-overlay1" >

                                    <div className="overlay-bg"><div className='delete-text-here d-flex justify-content-center align-items-center
                                    '>Deleting...</div></div>
                                </div>
                                :
                                <></>

                            }
                        </div>
                    </div>
                </div>
                {/*<div className="close-icon" onClick={(e)=>handleRemove(e)}>*/}
                {/*    x*/}
                {/*</div>*/}

                <Handle
                    type="source"
                    position="bottom"
                    id="a"
                    isConnectable={isConnectable}
                    isValidConnection={isValidConnectionSource}
                />
                {/*<Handle*/}
                {/*    type="source"*/}
                {/*    position="right"*/}
                {/*    id="b"*/}
                {/*    style={{ bottom: 10, top: 'auto', background: '#555' }}*/}
                {/*    isConnectable={isConnectable}*/}
                {/*/>*/}
            </div>

        </>
    );
});
