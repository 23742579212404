import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditor from '../ckeditor';
function Template3(props) {
    const {values,index,setFieldValue,name,moduleErrors,moduleTouched,handleBlur} = props
    return (
        <div className="templates-wrap">
            <Row>
                <Col md={12}><CkEditor moduleErrors={moduleErrors} moduleTouched={moduleTouched} name={`modules.${index}.contents`} setFieldValue={setFieldValue} values={values} index={index} handleBlur={handleBlur}/>

                </Col>
            </Row>
        </div>
    )
};
export default Template3;
