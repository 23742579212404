import React from 'react';
import NotFoundImg from '~/assets/images/page-not-found.svg';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
function PageNoFound() {
    return (
        <div className="error-page-wrap">
            <div className="error-content-wrap text-center">
                <div className="error-img">
                    <img src={NotFoundImg} alt="" />
                </div>
                <h4>Page does not exist</h4>
                <div className="error-btn-wrap">
                    {/*<Link to="#"><Button>Go Back</Button></Link>*/}
                </div>
            </div>
        </div>
    );
};
export default PageNoFound;
