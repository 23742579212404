import { createAction, handleActions } from 'redux-actions';
import { _getLoginDetails } from '~shared/httpService/api';
import ActionTypes from '~shared/constants/actionTypes';

const initialState = {
  login_details: { loading: false, response: {}, hasError: false, error: {} },
};

export const getLoginDetailsStart = createAction(ActionTypes.GET_LOGIN_DETAILS);
export const getLoginDetailsSuccess = createAction(ActionTypes.GET_LOGIN_DETAILS_SUCCESS, response => response);
export const getLoginDetailsError = createAction(ActionTypes.GET_LOGIN_DETAILS_ERROR, error => error);

export const getLoginDetails = (data) => (dispatch) => {
  dispatch(getLoginDetailsStart(data));
  return _getLoginDetails(data).then((response) => {
    dispatch(getLoginDetailsSuccess(response));
  }).catch((error) => {
    dispatch(getLoginDetailsError(error));
  });
};

const reducer = handleActions({
  
  //STARSHIP_DETAILS
  [ActionTypes.GET_LOGIN_DETAILS]: (state) => ({
    ...state, login_details: {
      ...state.login_details,
      loading: true, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_LOGIN_DETAILS_SUCCESS]: (state, action) => ({
    ...state,
    login_details: {
      ...state.login_details,
      response: action.payload, loading: false, hasError: false, error: {}
    }
  }),
  [ActionTypes.GET_LOGIN_DETAILS_ERROR]: (state, action) => ({
    ...state,
    login_details: {
      ...state.login_details,
      error: action.payload, loading: false, hasError: true, response: {}
    }
  }),

}, initialState);

export default reducer;