export function findURLParam(key, location) {
  let param,
    params_arr = [],
    queryString = (location.indexOf("?") !== -1) ? location.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        return params_arr[i];
      }
    }
  }
}


export function isEmptyObj(obj = {}) {
  return Object.keys(obj).length !== 0;
}
export function  beaconChat() {
// eslint-disable-next-line no-unused-expressions
  !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
  // Create PaymentIntent as soon as the page loads

}
