import { set } from 'date-fns';
import { invalid } from 'moment';
import React, { useState, useEffect } from 'react';
import {
    ToggleButton,
    ToggleButtonGroup,
    Button,
    ButtonGroup
} from "react-bootstrap";


function MultipleSurvey(props) {

    const [state, setState] = useState({
        stateOptions: [],

    })

    const [invalid,setInvalid]=useState(false)
const mystyle = {
  border:'1px solid #dc3545',
};



    const handleSelection = (e) => {

        var myValues = state?.stateOptions;
        let valueAtIndex = myValues[e]
        setFieldValue(quesId, valueAtIndex?.choice)

    }
    useEffect(() => {

        setState({ ...state, stateOptions: props?.options })
    }, [props?.options])



    const { name, errors, options, setFieldValue, mandatory,quesNumber, values, quesIndex, quesId, errorName,touched, ...rest } = props;


    return (
        <div className="survey-box whitebox shadow-box m-b-30">
            <h4 className="survey-qstn"><span>{quesNumber }.</span>{name ? name : ''}
            {
                mandatory ?  <span className="mandatory-star">*</span> :''
            }

           </h4>

            <div className="multiplechoice-survey-wrap"  >
                <ToggleButtonGroup onChange={e => handleSelection(e)} type="radio" name={quesId} className="custom-toggle-radio"
                    isInvalid={!!errors && !!errors[errorName] && !!errors[errorName] && !!touched && !!touched[errorName]
                        ? ' is-invalid'
                        : ''}
                >
                    {state?.stateOptions.map((option, index) => {
                        return (<ToggleButton key={index} value={index} style={!!errors && !!errors[errorName] && !!errors[errorName] && !!touched && !!touched[errorName] ? mystyle : {}}>

                            <div className="d-flex justify-content-between custom-radio-survey align-items-center">
                                <span>{option?.choice}</span>
                                <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </ToggleButton>
                        )
                    })

                    }
                </ToggleButtonGroup>
                {/* <ToggleButton value={2}> <div className="d-flex justify-content-between custom-radio-survey align-items-center">
                    <span>Every 2 Hours</span>
                    <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
                </div></ToggleButton>
                <ToggleButton value={3}> <div className="d-flex justify-content-between custom-radio-survey align-items-center">
                    <span>During Breaks</span>
                    <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
                </div></ToggleButton>
                <ToggleButton value={4}> <div className="d-flex justify-content-between custom-radio-survey align-items-center">
                    <span>I don't use</span>
                    <span className="round-radio"><i class="fa fa-check" aria-hidden="true"></i></span>
                </div></ToggleButton> */}

            </div>
        </div>

    )
}

export default MultipleSurvey
