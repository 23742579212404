import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Alert, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Redirect } from "react-router";
import FirsttimeLoginImg from "~/assets/images/firsttimelogin-img.svg";
import Logo from "~/assets/images/logo.png";
import { signIn, clearState } from "~/modules/auth/services";
import {
  signInWithWorkOs,
  verifyWorkOsProfile,
  verifyEdLinkProfile,
  
  verifySubDomain,
  signInWithEdLink,
} from "../../services";
import SignInWithMagicLink from "../components/magicLink Popup";
import { Link, useHistory, useLocation } from "react-router-dom";
import GoogleSignUpButton from "../../googleAuth/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import ModulePopView from "../../../../shared/components/modulepopview";
import { redirectUser } from "../../../../shared/utils/checkHost";

function FirstTimeLogin({}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [learner, setLearner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { user, loading, error, signUpError } = useSelector(
    (state) => state.auth
  );
  const [checkDomain, setCheckDomain] = useState(null);
  const loadingSSO = useSelector(
    (state) => state?.auth?.signin_workos?.loading
  );
  const loadingEdLink = useSelector(
    (state) => state?.auth?.signin_edlink?.loading
  );
  const response = useSelector(
    (state) => state.auth?.verify_subdomain?.response
  );
  
 
  const subdomainLogo = useSelector(
    (state) => state?.auth?.verify_subdomain?.response?.logo_url || null
  );
  const selectedLogo = useSelector(
    (state) => state.settings.selectedLogo || ""
  );

  const host = window.location.host;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  useEffect(() => {
    dispatch(
      verifySubDomain(
        host,
        function (response) {
          if (response) {
          }
        },
        function (error) {}
       
      )
    );
  }, []);

  useEffect(() => {
    setCheckDomain(response);
  }, [response]);


  useEffect(() => {
    if (subdomainLogo) {
      localStorage.setItem("MainLogo", `${subdomainLogo}`);
    }
  }, [subdomainLogo]);

  useEffect(() => {
    if (selectedLogo) {
      localStorage.setItem("MainLogo", `${selectedLogo}`);
    }
  }, [selectedLogo]);


 

  const handleSubmit = async (values) => {
    dispatch(signIn(values, history, "", ""));
    setTimeout(() => {
      dispatch(clearState("error"));
    }, 3000);
  };


  const handleLoader = () => {
    if (loading) {
      return (
        <div className="loader-full-screen-course">
         
        </div>
      );
    }
  };
  const handleSSO = () => {
    dispatch(signInWithWorkOs());
  };



  return (
    // <div className="auth-page-wrap">
    <>
      <ToastContainer autoClose={2000} />
      {handleLoader()}
      {/* <div className="auth-page-box d-flex"> */}
        {/* <div className="auth-right d-flex justify-content-center align-items-center"> */}
          {/* <div className="auth-cont-box"> */}


            {/* <div className="auth-cont-box-header text-center">
              <div className="auth-logo">
                {localStorage.getItem("MainLogo") ? (
                  <img src={localStorage.getItem("MainLogo")} alt="" />
                ) : (
                  <img src={Logo} alt="" />
                )}
              </div>
              <h2 className="auth-title">Welcome</h2>
              <p className="auth-desc">
                Please enter your e-mail and password.
              </p>
            </div> */}




            {/* <div className="auth-form-box white-box radius-box"> */}
              {learner ? (
                <h4 className="text-center mb-3 auth-info">
                  Log in as Learner
                </h4>
              ) : (
                <h4 className="text-center mb-3 auth-info">Log in </h4>
              )}

              <Formik
                validationSchema={SignInSchema}
                onSubmit={handleSubmit}
                initialValues={{
                  username: "",
                  password: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="form-input-wrap ">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="username"
                        placeholder="Your Email"
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input-wrap cus-icon" style={{position:'relative'}}>
                      <Form.Label>Password</Form.Label>
                      {/* <Form.Control type="password" placeholder="Your Password" className="input-item" /> */}
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Your Password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      
                        <span
                          style={{position:'absolute', top:30, right:10,cursor: 'pointer'}}
                         
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>}
                        </span>
                      
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      {error && error.error && error.errorMessage && (
                        <span className="text-danger">
                          {error.error.errorMessage}
                        </span>
                      )}
                    </div>
                    <p
                      className="login-forget"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/*{learner ?*/}
                      {/*    <Link onClick={()=>handleLearner('manager')}>  <span className="forgot-password-text">Sign in as Manager</span></Link>*/}
                      {/*    :*/}
                      {/*    <Link onClick={() =>handleLearner('learner')}>  <span className="forgot-password-text">Sign in as Learner</span></Link>*/}
                      {/*}*/}

                      <Link to="/forgotpassword">
                        {" "}
                        <span className="forgot-password-text">
                          Forgot Password?
                        </span>
                      </Link>
                    </p>
                    <div className="button-wrap mb-3">
                      <Button
                        type="submit"
                        className="w-100"
                        disabled={loading}
                      >
                        Log In
                      </Button>
                    </div>
                    {loading && (
                      <div className="text-center">
                        <Spinner animation="border" variant="danger" />
                      </div>
                    )}
                    
                    {/* <div className="button-wrap mb-3">
                      <Button
                        type="button"
                        onClick={handleClick}
                        className="w-100"
                      >
                        Sign Up
                      </Button>
                    </div> */}

                    {/* <div className="button-wrap mb-3">
                                   <a href="www.aptree.app/signup"> <Button  className="w-100">Sign
                                      Up</Button></a>
                                </div> */}
                    {/*{learner ?*/}
                    {/*''*/}
                    {/*:*/}

                    {/*}*/}
                  </Form>
                )}
              </Formik>

            {
            // checkDomain?.is_edlink_linked && (<div className="social-login-wrap">
            //    <button
            //       className="social-login-btn google-login"
            //       onClick={handleEdLinkSignIn}
            //     >
            //       <span></span>
            //       {loadingEdLink && loadingEdLink ? (
            //         <Spinner animation="border" variant="danger" />
            //       ) : (
            //         "Sign In With EdLink"
            //       )}
            //     </button>
            //     {/*<p className='mt-2' onClick={handlMagicModal}> <span className="forgot-password-text">Login with Magic Link</span></p>*/}
            //     {/*<button className="social-login-btn google-login" ><span><img src={GoogleIcon} alt="" /></span>Sign In using Google</button>*/}
            //   </div>)
              }

              {
              
              checkDomain?.is_workos_linked &&
              
              (
                <div className="social-login-wrap">
                  <button
                    className="social-login-btn google-login"
                    onClick={handleSSO}
                  >
                    <span></span>
                    {loadingSSO && loadingSSO ? (
                      <Spinner animation="border" variant="danger" />
                    ) : (
                      "Sign In With WorkOs"
                    )}
                  </button>
                  {/*<p className='mt-2' onClick={handlMagicModal}> <span className="forgot-password-text">Login with Magic Link</span></p>*/}
                  {/*<button className="social-login-btn google-login" ><span><img src={GoogleIcon} alt="" /></span>Sign In using Google</button>*/}
                </div>
              )}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
      {/* </div> */}
      <SignInWithMagicLink show={show} onHide={() => setShow(false)} />
    {/* </div> */}
    </>
  );
}
const SignInSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is Required field")
    .min(8, "Password is too short - should be 8 chars minimum."),
  // .matches(/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Password must include one upper case, and at least one number.'),
  username: yup.string().email('Username must be a valid email').required("Email is Required field"),
});
export default FirstTimeLogin;
