import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LearningPathContainer from "./components/workFlow/container";
import LearningPathDetails from "./components/details";
import { getNodesLpList } from "./services";
import { useDispatch, useSelector } from "react-redux";
import LpAssignment from "./components/lpassign";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  MiniMap,
  Controls,
  Background,
  removeElements,
  useZoomPanHelper,
} from "react-flow-renderer";
import { ToastContainer } from "react-toastify";
import CourseAssign from "../coursemanagement/components/coursebuilder/components/courseassign";
function LearningPath(props) {
  const urlId = props?.match?.params?.id;
  let dispatch = useDispatch();
  const [state, setState] = useState({
    defaultKey: "0",
    lpId: "",
    learningPath: {},
    moduleAdded: false,
    nodes: [],
  });

  const parentState = (key, id, item, yes) => {
    setState({
      ...state,
      defaultKey: key,
      lpId: id,
      learningPath: item,
      moduleAdded: yes,
    });
  };
  useEffect(() => {
    if (urlId) {
      dispatch(
        getNodesLpList(urlId, function (res) {
          if (res) {
            if (res?.elements?.length > 0) {
              res.elements = res?.elements?.filter((el) =>
                Object?.keys(el)?.includes("data")
              );
            }
            setState({ ...state, nodes: res });
          }
        })
      );
    }
  }, []);
  return (
    <>
      <div className="tab-wrapper">
        <ToastContainer autoClose={2000} />
        <Tabs
          activeKey={state?.defaultKey}
          onSelect={(k) => setState({ ...state, defaultKey: k })}
          id="controlled-tab-example"
          className="m-b-30"
        >
          <Tab
            eventKey="0"
            title="Details"
            disabled={
              state?.lpId || state.defaultKey == "1" || state.defaultKey == "2"
            }
          >
            <LearningPathDetails
              parentState={parentState}
              urlId={urlId ? urlId : null}
            />
          </Tab>

          <Tab
            eventKey="1"
            title="Add Content"
            eventKey="1"
            disabled={
              !state?.lpId ||
              state?.moduleAdded ||
              state.defaultKey == "0" ||
              state.defaultKey == "2"
            }
          >
            {/*<LearningPathContainer  parentState={parentState} lpId={state?.lpId} learningPath={state?.learningPath} urlId={urlId ? urlId :null} />*/}
            {/*<LearningPathContainer parentState={parentState} lpId={state?.lpId} learningPath={state?.learningPath} urlId={urlId ? urlId :null} />*/}
          </Tab>
          <Tab
            disabled={!state?.lpId}
            eventKey="2"
            title="Assign"
            disabled={state.defaultKey == "0" || state.defaultKey == "1"}
          >
            <LpAssignment
              keys={state?.defaultKey}
              urlId={urlId ? urlId : null}
              parentState={parentState}
              courseId={state?.lpId}
              course={state?.learningPath}
              urlId={urlId ? urlId : null}
            />
          </Tab>
        </Tabs>
      </div>
      {state.defaultKey == "1" && (
        <ReactFlowProvider>
          <LearningPathContainer
            parentState={parentState}
            lpId={state?.lpId}
            learningPath={state?.learningPath}
            urlId={urlId ? urlId : null}
            nodes={state.nodes}
          />
        </ReactFlowProvider>
      )}
    </>
  );
}
export default LearningPath;
