import React, {useEffect, useState} from "react";
import {Field, ErrorMessage, Form} from "formik";
import {FormCheck} from "react-bootstrap";
import ScrollArea from 'react-scrollbar';


const  RadioButton = (props) => {

    const [state, setState]=useState({
        stateOptions:[],
        rightAnswerMessage:false,
        wrongAnswer:false
    })


    useEffect(()=>{
      // let deepOptions =JSON.parse(JSON.stringify(props?.options))
        setState({...state,stateOptions:props?.options})
    },[props?.options])


    const handleSelection = (e,index,option,fieldValue) => {


        setFieldValue(name,e.target.value)
        const {stateOptions} =state
        if(stateOptions[index].is_correct_answer == 'True' || stateOptions[index].is_correct_answer == true){
            stateOptions.forEach((el)=>{
                el.rightSelection =false
            })
            stateOptions[index].rightSelection = true
            stateOptions[index].rightAnswerMessage = true

            setState({...state,stateOptions,rightAnswerMessage:true,wrongAnswer: false})
        }else if(stateOptions[index].is_correct_answer == 'False' || stateOptions[index].is_correct_answer == false){

            stateOptions[index].wrongSelection = true
            stateOptions[index].wrongAnswer = true
            stateOptions.forEach((el)=>{
                el.rightAnswerMessage =false
            })
            stateOptions.forEach((el)=>{
                el.rightSelection =false
            })
            const tempIndex = props?.options?.findIndex((el)=>{
                return el.is_correct_answer == 'True' || el.is_correct_answer == true
                })
            stateOptions[tempIndex].rightSelection = true
            setState({...state,stateOptions:stateOptions,rightAnswerMessage:false,wrongAnswer: true})
        }



    }
    const { name,errors, options,setFieldValue,values,showResponse, ...rest} = props;
    return (
        <div className="integer-radio-block">

            <Field name={name} {...rest} >
                {
                    ({ field }) => {
                        return state.stateOptions.map((option,index) => {
                            return (
                                <>
                                    <div className="radio-block-wrap-item m-b-20">
                                        <FormCheck
                                            custom
                                            {...field}

                                            id={option?.choice}
                                            label={`${option?.choice}`}
                                            type="radio"

                                            value={option?.choice}
                                            onChange={e =>handleSelection(e,index,option,field)}
                                            checked={field.value === option?.choice}
                                        />
                                        { field.value  === option?.choice && showResponse &&

                                            (

                                                <p className="radio-validation label-validation">
                                            <ScrollArea
                                            speed={1}
                                            className="validation-scroll"
                                            contentClassName="content"
                                            horizontal={false}
                                        >
                                                    <div className={`${option?.rightSelection ? 'answ-right-text' :'answer-wrong-text' }`}>
                                                    <span className="answer-icon"><i className="fa fa-check"></i> <i className="fa fa-times"></i></span>
                                                   {option?.rightSelection ? 'Correct!' : 'Wrong!'}
                                                    </div>
                                                    <div className={`${option?.rightSelection ? 'answer-sucess' :'answer-wrong' }`}>
                                                        {option?.choice_response && option?.choice_response ?  option?.choice_response : ''}

                                                    </div>
                                                    </ScrollArea>
                                                </p>

                                            )
                                        }
                                    </div>

                                </>
                            );
                        })
                    }
                }

            </Field>

            {/*<>*/}
            {/*           {state.stateOptions.find((el)=>el?.rightAnswerMessage == true)  && values[name]?.length && state?.rightAnswerMessage ?*/}
            {/*   <div className="radio-box-footer">*/}
            {/*       <p>Congrats! Right Answer </p>*/}

            {/*           </div>*/}
            {/*               :*/}
            {/*               <div>*/}
            {/*               {state.stateOptions.find((el) => el?.wrongAnswer == true) &&  values[name]?.length &&  state.wrongAnswer?*/}
            {/*                       <div className="radio-box-footer">*/}
            {/*                           <p>That was close! You will get there next time!</p>*/}
            {/*                       </div>*/}
            {/*                       :*/}
            {/*                       ''*/}
            {/*               }*/}
            {/*               </div>*/}
            {/*           }*/}




            {/*            </>*/}






        </div>


    );
};

export default RadioButton;
