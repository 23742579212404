import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TabModules from "./components/tabs";
import AccordianItem from "../learnerlisting/learnercoursedetail/accordian";
import LearnerQuiz from "./components/learnerQuiz.js";
import { getLearnerCourseById, getLearnerCourseModules } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import CourseCompleted from "~/shared/components/coursecompleted";

const LearnerModulePopView = (props) => {
  const dispatch = useDispatch();
  const learnerModules = useSelector(
    (state) => state.learnercourse?.learner_course_modules_list?.response
  );
  const response = useSelector(
    (state) => state.learnercourse?.learner_course?.response
  );
  const loading = useSelector(
    (state) => state.learnercourse?.learner_course?.loading
  );
  const [state, setState] = useState({
    course: null,
    completed: null,
  });

  const [complete, setComplete] = useState(false);

  const handleCallback = (childData) => {
    setComplete(true);
  };

  useEffect(() => {
    if (props.courseId) {
      dispatch(
        getLearnerCourseById(props.courseId, function (res) {
          setTimeout(() => {
            setState({
              ...state,
              course: res?.result,
              completed: res?.result?.completed_modules,
            });
          }, 100);
        })
      );
    }
  }, [props.count]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        onExit={() => setComplete(false)}
        className="common-modal full-screen-modal inner-popup add-module-content-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ overflowY: "auto" }}>
          <div>
            {loading ? (
              <div className="row justify-content-center mt-1">
                <Spinner animation="border" variant="danger" />
              </div>
            ) : (
              <>
                {!loading &&
                state?.course?.modules &&
                state?.course?.modules.length ? (
                  <span>
                    {complete ? (
                      <CourseCompleted
                        name={props.course?.name}
                      ></CourseCompleted>
                    ) : (
                      <TabModules
                        onHide={props.onHide}
                        course_id={state?.course?.course_id}
                        courseId={state?.course?.id}
                        parentCallback={handleCallback}
                        values={
                          state?.course?.quiz && state?.course?.quiz?.length
                            ? [
                                ...state?.course?.modules,
                                ...state?.course?.quiz?.map((el) => {
                                  return {
                                    ...el,
                                    template: "quiz",
                                  };
                                }),
                              ]
                            : state?.course?.modules
                        }
                        course={state?.course}
                        name={state?.course?.name}
                        completedCount={state?.completed}
                      />
                    )}
                  </span>
                ) : (
                  <>
                    <div className="row justify-content-center mt-5">
                      No Modules Available
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default LearnerModulePopView;
