import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalendarListing = (props) => {
    const [value, setValue] = useState(new Date());

        const handleChange =  (e) => {
           setValue(e)
            props?.getDate(`${e}`)
        }

    return (
        <div className="calendar-wrap custom-react-calendar">
            <Calendar minDate={new Date()} onChange={handleChange} value={value} />
        </div>
    );
};
export default CalendarListing;
