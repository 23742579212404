import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addQuiz,
  updateQuiz,
  getManagerQuiz,
} from "../../../../services/index";
import { getInstitutionType } from "../../../../../auth/services";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
// import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import { callback } from "chart.js/helpers";

function QuizDetails(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const response = useSelector(
    (state) => state?.quiz?.get_quiz_manager?.response
  );
  const loading = useSelector(
    (state) => state?.quiz?.newly_added_quiz?.loading
  );
  const updatedQuizLoading = useSelector(
    (state) => state?.quiz?.updated_quiz?.loading
  );
  const { user, error, signUpError } = useSelector((state) => state.auth);
  const [category, setcategory] = useState([]);
  const categoryResponse = useSelector(
    (state) => state?.auth?.institution_type?.response
  );

  const [state, setState] = useState({
    quiz: response,
    loadForm: true,
  });

  useEffect(() => {
    if (props?.urlId) {
      dispatch(getManagerQuiz(props?.urlId, function (res) {}));
    }
  }, [props?.userId]);

  useEffect(() => {
    if (props?.urlId) {
      setState({ ...state, quiz: response });
    }
  }, [response]);

  const handleSubmit = async (values) => {
    let data = new FormData();

    if (props.urlId) {
      data.append("name", values?.name);
      data.append("description", values?.description);

      dispatch(
        updateQuiz(data, props?.urlId, function (response) {
          if (response) {
            props.parentState(1, response?.id, response, false);
          }
        })
      );
    } else {
      data.append("name", values?.name);
      data.append("description", values?.description);

      dispatch(
        addQuiz(data, function (response) {
          if (response) {
            props.parentState(1, response?.id, response, false);
          }
        })
      );
    }
  };
  const historyBack = () => {
    if (location?.state?.active) {
      history.push({
        pathname: "/quizmanagement",
        state: { active: location?.state?.active },
      });
    } else {
      history.push("/quizmanagement");
    }
  };
  const handleLoader = () => {
    if (loading || updatedQuizLoading) {
      return (
        <div className="loader-full-screen-course">
          <div className="loader">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="coursedetails-wrap">
      <div>{handleLoader()}</div>
      <div className="course-form-content-sec">
        <Formik
          validationSchema={CourseDetailSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={
            props?.urlId
              ? state.quiz
              : {
                  name: "",
                  description: "",
                }
          }
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            setValues,
          }) => {
            return (
              <Form noValidate onSubmit={handleSubmit}>
           

                <div className="form-input-wrap">
                  <Form.Label>Quiz Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Quiz Title"
                    value={values?.name}
                    onChange={handleChange}
                    maxLength="120"
                  />
                  {errors?.name && (
                    <span className={"text-danger mt-1"}> {errors?.name}</span>
                  )}
                </div>

                <div className="form-input-wrap">
                  <Form.Label>Quiz Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Quiz Description"
                    style={{ height: "200px" }}
                    className="textarea-item"
                    name="description"
                    value={values?.description}
                    onChange={handleChange}
                  />
                  {errors?.description && (
                    <span className={"text-danger mt-1"}>
                      {" "}
                      {errors?.description}
                    </span>
                  )}
                </div>
                <div className="details-footer d-flex justify-content-end">
                  <div className="button-wrap double-btn">
                    <button
                      className="btn btn-m border-btn"
                      onClick={historyBack}
                    >
                      Cancel
                    </button>
                    <Button variant="primary" className="btn-m" type="submit">
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

const CourseDetailSchema = Yup.object().shape({
  name: Yup.string().required("Quiz title is required"),
  description: Yup.string().required("Description is required"),
});

export default QuizDetails;
