export default {
  GET_STARSHIP_DETAILS: "GET_STARSHIP_DETAILS",
  GET_STARSHIP_DETAILS_SUCCESS: "GET_STARSHIP_DETAILS_SUCCESS",
  GET_STARSHIP_DETAILS_ERROR: "GET_STARSHIP_DETAILS_ERROR",

  SIGNUP: "SIGNUP",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_ERROR: "SIGNUP_ERROR",

  SIGNIN: "SIGNIN",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_ERROR: "SIGNIN_ERROR",

  PASSWORDLESS_SIGNIN:'PASSWORDLESS_SIGNIN',
  PASSWORDLESS_SIGNIN_SUCCESS:'PASSWORDLESS_SIGNIN_SUCCESS',
  PASSWORDLESS_SIGNIN_ERROR:'PASSWORDLESS_SIGNIN_ERROR',

  PASSWORDLESS_WITH_OTP_START:'PASSWORDLESS_WITH_OTP_START',
  PASSWORDLESS_WITH_OTP_SUCCESS:'PASSWORDLESS_WITH_OTP_SUCCESS',
  PASSWORDLESS_WITH_OTP_ERROR:'PASSWORDLESS_WITH_OTP_ERROR',


  SIGNIN_ADLINK: "SIGNIN_ADLINK",
  SIGNIN_ADLINK_SUCCESS: "SIGNIN_ADLINK_SUCCESS",
  SIGNIN_ADLINK_ERROR: "SIGNIN_ADLINK_ERROR",

  



  SIGNIN_WORKOS: "SIGNIN_WORKOS",
  SIGNIN_WORKOS_SUCCESS: "SIGNIN_WORKOS_SUCCESS",
  SIGNIN_WORKOS_ERROR: "SIGNIN_WORKOS_ERROR",

  SIGNIN_EDLINK: "SIGNIN_EDLINK",
  SIGNIN_EDLINK_SUCCESS: "SIGNIN_EDLINK_SUCCESS",
  SIGNIN_EDLINK_ERROR: "SIGNIN_EDLINK_ERROR",

  SYNC_WORKOS: "SYNC_WORKOS",
  SYNC_WORKOS_SUCCESS: "SYNC_WORKOS_SUCCESS",
  SYNC_WORKOS_ERROR: "SYNC_WORKOS_ERROR",

  UNSYNC_WORKOS: "UNSYNC_WORKOS",
  UNSYNC_WORKOS_SUCCESS: "UNSYNC_WORKOS_SUCCESS",
  UNSYNC_WORKOS_ERROR: "UNSYNC_WORKOS_ERROR",

  SIGNIN_WORKOS_MAGICLINK: "SIGNIN_WORKOS_MAGICLINK",
  SIGNIN_WORKOS_MAGICLINK_SUCCESS: "SIGNIN_WORKOS_MAGICLINK_SUCCESS",
  SIGNIN_WORKOS_MAGICLINK_ERROR: "SIGNIN_WORKOS_MAGICLINK_ERROR",

  CREATE_ORGANIZATION_WITH_WORKOS: "CREATE_ORGANIZATION_WITH_WORKOS",
  CREATE_ORGANIZATION_WITH_SUCCESS: "CREATE_ORGANIZATION_WITH_SUCCESS",
  CREATE_ORGANIZATION_WITH_ERROR: "CREATE_ORGANIZATION_WITH_ERROR",

  LOG_OUT: "LOG_OUT",
  LOG_OUT_SUCCESS: "LOG_OUT_SUCCESS",
  LOG_OUT_ERROR: "LOG_OUT_ERROR",

  REGISTER: "REGISTER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",

  STRIPE_PAYMENT_INTENT_VERIFY: "STRIPE_PAYMENT_INTENT_VERIFY",
  STRIPE_PAYMENT_INTENT_VERIFY_SUCCESS: "STRIPE_PAYMENT_INTENT_VERIFY_SUCCESS",
  STRIPE_PAYMENT_INTENT_VERIFY_ERROR: "STRIPE_PAYMENT_INTENT_VERIFY_ERROR",

  STRIPE_PAYMENT_INTENT: "STRIPE_PAYMENT_INTENT",
  STRIPE_PAYMENT_INTENT_SUCCESS: "STRIPE_PAYMENT_INTENT_SUCCESS",
  STRIPE_PAYMENT_INTENT_ERROR: "STRIPE_PAYMENT_INTENT_ERROR",

  SIGNIN_GOOGLE: "SIGNIN_GOOGLE",
  SIGNIN_GOOGLE_SUCCESS: "SIGNIN_GOOGLE_SUCCESS",
  SIGNIN_GOOGLE_ERROR: "SIGNIN_GOOGLE_ERROR",

  GET_SUBSCRIPTION_PLANS: "GET_SUBSCRIPTION_PLANS",
  GET_SUBSCRIPTION_PLANS_SUCCESS: "GET_SUBSCRIPTION_PLANS_SUCCESS",
  GET_SUBSCRIPTION_PLANS_ERROR: "GET_SUBSCRIPTION_PLANS_ERROR",

  DISABLE_SUBSCRIPTION: "DISABLE_SUBSCRIPTION",
  DISABLE_SUBSCRIPTION_SUCCESS: "DISABLE_SUBSCRIPTION_SUCCESS",
  DISABLE_SUBSCRIPTION_ERROR: "DISABLE_SUBSCRIPTION_ERROR",

  CHECK_SUBSCRIPTION: "CHECK_SUBSCRIPTION",
  CHECK_SUBSCRIPTION_SUCCESS: "CHECK_SUBSCRIPTION_SUCCESS",
  CHECK_SUBSCRIPTION_ERROR: "CHECK_SUBSCRIPTION_ERROR",

  SUBDOMAIN_LOGIN: "SUBDOMAIN_LOGIN",
  SUBDOMAIN_LOGIN_SUCCESS: "SUBDOMAIN_LOGIN_SUCCESS",
  SUBDOMAIN_LOGIN_ERROR: "SUBDOMAIN_LOGIN_ERROR",

  FORGOT_SUBDOMAIN: "FORGOT_SUBDOMAIN",
  FORGOT_SUBDOMAIN_SUCCESS: "FORGOT_SUBDOMAIN_SUCCESS",
  FORGOT_SUBDOMAIN_ERROR: "FORGOT_SUBDOMAIN_ERROR",

  SUBDOMAIN_EXISTS: "SUBDOMAIN_EXISTS",
  SUBDOMAIN_EXISTS_SUCCESS: "SUBDOMAIN_EXISTS_SUCCESS",
  SUBDOMAIN_EXISTS_ERROR: "SUBDOMAIN_EXISTS_ERROR",

  CONSTRUCT_RESULTS_NEW: "CONSTRUCT_RESULTS_NEW",
  CONSTRUCT_RESULTS_NEW_SUCCESS: "CONSTRUCT_RESULTS_NEW_SUCCESS",
  CONSTRUCT_RESULTS_NEW_ERROR: "CONSTRUCT_RESULTS_NEW_ERROR",

  CONSTRUCT_RESULTS_BY_CHOICES: "CONSTRUCT_RESULTS_BY_CHOICES",
  CONSTRUCT_RESULTS_BY_CHOICES_SUCCESS: "CONSTRUCT_RESULTS_BY_CHOICES_SUCCESS",
  CONSTRUCT_RESULTS_BY_CHOICES_ERROR: "CONSTRUCT_RESULTS_BY_CHOICES_NEW_ERROR",

  CREATE_NEW_PASSWORD: "CREATE_NEW_PASSWORD",
  CREATE_NEW_PASSWORD_SUCCESS: "CREATE_NEW_PASSWORD_SUCCESS",
  CREATE_NEW_PASSWORD_ERROR: "CREATE_NEW_PASSWORD_ERROR",

  
  SET_PASSWORD: "SET_PASSWORD",
  SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_ERROR: "SET_PASSWORD_ERROR",

  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",

  //LOGIN
  GET_LOGIN_DETAILS: "GET_LOGIN_DETAILS",
  GET_LOGIN_DETAILS_SUCCESS: "GET_LOGIN_DETAILS_SUCCESS",
  GET_LOGIN_DETAILS_ERROR: "GET_LOGIN_DETAILS_ERROR",

  //SURVEY
  GET_SURVEY_DESCRIPTION: "GET_SURVEY_DESCRIPTION",
  GET_SURVEY_DESCRIPTION_SUCCESS: "GET_SURVEY_DESCRIPTION_SUCCESS",
  GET_SURVEY_DESCRIPTION_ERROR: "GET_SURVEY_DESCRIPTION_ERROR",

  GET_SURVEY_QUESTIONS: "GET_SURVEY_QUESTIONS",
  GET_SURVEY_QUESTIONS_SUCCESS: "GET_SURVEY_QUESTIONS_SUCCESS",
  GET_SURVEY_QUESTIONS_ERROR: "GET_SURVEY_QUESTIONS_ERROR",

  GET_SURVEY_CATEGORIES: "GET_SURVEY_CATEGORIES",
  GET_SURVEY_CATEGORIES_SUCCESS: "GET_SURVEY_CATEGORIES_SUCCESS",
  GET_SURVEY_CATEGORIES_ERROR: "GET_SURVEY_CATEGORIES_ERROR",

  GET_COURSES_CATEGORIES: "GET_COURSES_CATEGORIES",
  GET_COURSES_CATEGORIES_SUCCESS: "GET_COURSES_CATEGORIES_SUCCESS",
  GET_COURSES_CATEGORIES_ERROR: "GET_COURSES_CATEGORIES_ERROR",

  

  //CHECK SUBDOMAIN
  VERIFY_SUBDOMAIN: "VERIFY_SUBDOMAIN",
  VERIFY_SUBDOMAIN_SUCCESS: "VERIFY_SUBDOMAIN_SUCCESS",
  VERIFY_SUBDOMAIN_ERROR: "VERIFY_SUBDOMAIN_ERROR",

  GET_SURVEY_DEMOGRAPHICS: "GET_SURVEY_DEMOGRAPHICS",
  GET_SURVEY_DEMOGRAPHICS_SUCCESS: "GET_SURVEY_DEMOGRAPHICS_SUCCESS",
  GET_SURVEY_DEMOGRAPHICS_ERROR: "GET_SURVEY_DEMOGRAPHICS_ERROR",

  //Register
  GET_REGISTER: "GET_REGISTER",
  GET_REGISTER_SUCCESS: "GET_REGISTER_SUCCESS",
  GET_REGISTER_ERROR: "GET_REGISTER_ERROR",

  GET_SURVEY_RESULTS: "GET_SURVEY_RESULTS",
  GET_SURVEY_RESULTS_SUCCESS: "GET_SURVEY_RESULTS_SUCCESS",
  GET_SURVEY_RESULTS_ERROR: "GET_SURVEY_RESULTS_ERROR",

  //PROFILE

  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_ERROR: "GET_PROFILE_ERROR",

  PROFILE_UPDATE: "PROFILE_UPDATE",
  PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
  PROFILE_UPDATE_ERROR: "PROFILE_UPDATE_ERROR",

  PROFILE_IMAGE_UPDATE: "PROFILE_IMAGE_UPDATE",
  PROFILE_IMAGE_UPDATE_SUCCESS: "PROFILE_IMAGE_UPDATE_SUCCESS",
  PROFILE_IMAGE_UPDATE_ERROR: "PROFILE_IMAGE_UPDATE_ERROR",

  GET_CONTACT: "GET_CONTACT",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_ERROR: "GET_CONTACT_ERROR",

  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",

  GROUP_LIST: "GROUP_LIST",
  GROUP_LIST_SUCCESS: "GROUP_LIST_SUCCESS",
  GROUP_LIST_ERROR: "GROUP_LIST_ERROR:",

  SUBSCRIPTION_HISTORY: "SUBSCRIPTION_HISTORY",
  SUBSCRIPTION_HISTORY_SUCCESS: "SUBSCRIPTION_HISTORY_SUCCESS",
  SUBSCRIPTION_HISTORY_ERROR: "SUBSCRIPTION_HISTORY_ERROR",

  GET_CARD_DETAILS: "GET_CARD_DETAILS",
  GET_CARD_DETAILS_SUCCESS: "GET_CARD_DETAILS_SUCCESS",
  GET_CARD_DETAILS_ERROR: "GET_CARD_DETAILS_ERROR",

  DELETE_CARD: "DELETE_CARD",
  DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
  DELETE_CARD_ERROR: "DELETE_CARD_ERROR",

  UPDATE_CARD: "UPDATE_CARD",
  UPDATE_CARD_SUCCESS: "UPDATE_CARD_SUCCESS",
  UPDATE_CARD_ERROR: "UPDATE_CARD_ERROR",

  UPGRADE_PLAN: "UPGRADE_PLAN",
  UPGRADE_PLAN_SUCCESS: "UPGRADE_PLAN_SUCCESS",
  UPGRADE_PLAN_ERROR: "UPGRADE_PLAN_ERROR",

  SUBSCRIPTION_DETAILS: "SUBSCRIPTION_DETAILS",
  SUBSCRIPTION_DETAILS_SUCCESS: "SUBSCRIPTION_DETAILS_SUCCESS",
  SUBSCRIPTION_DETAILS_ERROR: "SUBSCRIPTION_DETAILS_ERROR",

  SELECT_PLAN: "SELECT_PLAN",
  SELECT_PLAN_SUCCESS: "SELECT_PLAN_SUCCESS",
  SELECT_PLAN_ERROR: "SELECT_PLAN_ERROR",

  //  SETUP DOMAINS
  SETUP_DOMAIN: "SETUP_DOMAIN",
  SETUP_DOMAIN_SUCCESS: "SETUP_DOMAIN_SUCCESS",
  SETUP_DOMAIN_ERROR: "SETUP_DOMAIN_ERROR",

  SETUP_DOMAIN_TWO: "SETUP_DOMAIN_TWO",
  SETUP_DOMAIN_SUCCESS_TWO: "SETUP_DOMAIN_SUCCESS_TWO",
  SETUP_DOMAIN_ERROR_TWO: "SETUP_DOMAIN_ERROR_TWO",

  POST_SUBSCRIPTION_PLAN: "POST_SUBSCRIPTION_PLAN",
  POST_SUBSCRIPTION_PLAN_SUCCESS: "POST_SUBSCRIPTION_PLAN_SUCCESS",
  POST_SUBSCRIPTION_PLAN_ERROR: "POST_SUBSCRIPTION_PLAN_ERROR",

  APPLY_COUPON: "APPLY_COUPON",
  APPLY_COUPON_SUCCESS: "APPLY_COUPON_SUCCESS",
  APPLY_COUPON_ERROR: "APPLY_COUPON_ERROR",

  //  CREATE SUBSCRIPTIONS
  SUBSCRIBE_SUBSCRIPTION_PLAN: "SUBSCRIBE_SUBSCRIPTION_PLAN",
  SUBSCRIBE_SUBSCRIPTION_PLAN_SUCCESS: "SUBSCRIBE_SUBSCRIPTION_PLAN_SUCCESS",
  SUBSCRIBE_SUBSCRIPTION_PLAN_ERROR: "SUBSCRIBE_SUBSCRIPTION_PLAN_ERROR",

  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_ERROR_SUCCESS: "CLEAR_ERROR_SUCCESS",
  CLEAR_ERROR_ERROR: "CLEAR_ERROR_ERROR",

  //  CREATE SURVEYS
  CREATE_SURVEY: "CREATE_SURVEY",
  CREATE_SURVEY_SUCCESS: "CREATE_SURVEY_SUCCESS",
  CREATE_SURVEY_ERROR: "CREATE_SURVEY_ERROR",

  // GET SURVEYS
  GET_SURVEYS_LISTING: "GET_SURVEYS_LISTING",
  GET_SURVEYS_LISTING_SUCCESS: "GET_SURVEYS_LISTING_SUCCESS",
  GET_SURVEYS_LISTING_ERROR: "GET_SURVEYS_LISTING_ERROR",

  // GET SURVEY
  GET_SINGLE_SURVEY_LIST: "GET_SINGLE_SURVEY_LIST",
  GET_SINGLE_SURVEY_LIST_SUCCESS: "GET_SINGLE_SURVEY_LIST_SUCCESS",
  GET_SINGLE_SURVEY_LIST_ERROR: "GET_SINGLE_SURVEY_LIST_ERROR",

  // GET DRAFT SURVEY
  GET_DRAFT_SURVEYS: "GET_DRAFT_SURVEYS",
  GET_DRAFT_SURVEYS_SUCCESS: "GET_DRAFT_SURVEYS_SUCCESS",
  GET_DRAFT_SURVEYS_ERROR: "GET_DRAFT_SURVEYS_ERROR",

  //UPDATE SURVEY

  //DELETE SURVEY
  DELETE_SURVEY: "DELETE_SURVEY",
  DELETE_SURVEY_SUCCESS: "DELETE_SURVEY_SUCCESS",
  DELETE_SURVEY_ERROR: "DELETE_SURVEY_ERROR",

  //PAGINATION NEXT
  PAGINATION_NEXT: "PAGINATION_NEXT",
  PAGINATION_NEXT_SUCCESS: "PAGINATION_NEXT_SUCCESS",
  PAGINATION_NEXT_ERROR: "PAGINATION_NEXT_ERROR",

  //PAGINATION PREVIOUS
  PAGINATION_PREVIOUS: "PAGINATION_PREVIOUS",
  PAGINATION_PREVIOUS_SUCCESS: "PAGINATION_PREVIOUS_SUCCESS",
  PAGINATION_PREVIOUS_ERROR: "PAGINATION_PREVIOUS_ERROR",

  //GROUP_PAGINATION NEXT
  GROUP_PAGINATION_NEXT: "GROUP_PAGINATION_NEXT",
  GROUP_PAGINATION_NEXT_SUCCESS: "GROUP_PAGINATION_NEXT_SUCCESS",
  GROUP_PAGINATION_NEXT_ERROR: "GROUP_PAGINATION_NEXT_ERROR",

  //GROUP PAGINATION PREVIOUS
  GROUP_PAGINATION_PREVIOUS: "GROUP_PAGINATION_PREVIOUS",
  GROUP_PAGINATION_PREVIOUS_SUCCESS: "GROUP_PAGINATION_PREVIOUS_SUCCESS",
  GROUP_PAGINATION_PREVIOUS_ERROR: "GROUP_PAGINATION_PREVIOUS_ERROR",

  //Learner PAGINATION NEXT
  LEARNER_PAGINATION_NEXT: "LEARNER_PAGINATION_NEXT",
  LEARNER_PAGINATION_NEXT_SUCCESS: "LEARNER_PAGINATION_NEXT_SUCCESS",
  LEARNER_PAGINATION_NEXT_ERROR: "LEARNER_PAGINATION_NEXT_ERROR",

  //Learner PAGINATION PREVIOUS
  LEARNER_PAGINATION_PREVIOUS: "LEARNER_PAGINATION_PREVIOUS",
  LEARNER_PAGINATION_PREVIOUS_SUCCESS: "LEARNER_PAGINATION_PREVIOUS_SUCCESS",
  LEARNER_PAGINATION_PREVIOUS_ERROR: "LEARNER_PAGINATION_PREVIOUS_ERROR",
  //DRAFT PAGINATION NEXT
  DRAFT_PAGINATION_NEXT: "DRAFT_PAGINATION_NEXT",
  DRAFT_PAGINATION_NEXT_SUCCESS: "DRAFT_PAGINATION_NEXT_SUCCESS",
  DRAFT_PAGINATION_NEXT_ERROR: "DRAFT_PAGINATION_NEXT_ERROR",
  //DRAFT PAGINATION PREVIOUS
  DRAFT_PAGINATION_PREVIOUS: "DRAFT_PAGINATION_PREVIOUS",
  DRAFT_PAGINATION_PREVIOUS_SUCCESS: "DRAFT_PAGINATION_PREVIOUS_SUCCESS",
  DRAFT_PAGINATION_PREVIOUS_ERROR: "DRAFT_PAGINATION_PREVIOUS_ERROR",

  //GET GROUPS
  GROUPS_LIST: "GROUPS_LIST",
  GROUPS_LIST_SUCCESS: "GROUPS_LIST_SUCCESS",
  GROUPS_LIST_ERROR: "GROUPS_LIST_ERROR:",

  // SURVEY ASSIGNMENT
  ASSIGN_SURVEY: "ASSIGN_SURVEY",
  ASSIGN_SURVEY_SUCCESS: "ASSIGN_SURVEY_SUCCESS",
  ASSIGN_SURVEY_ERROR: "ASSIGN_SURVEY_ERROR",

  // SURVEY ASSIGNMENT TO MULTIPLE MEMBERS
  ASSIGN_SURVEY_MEMBERS: "ASSIGN_SURVEY_MEMBERS",
  ASSIGN_SURVEY_SUCCESS_MEMBERS: "ASSIGN_SURVEY_SUCCESS_MEMBERS",
  ASSIGN_SURVEY_ERROR_MEMBERS: "ASSIGN_SURVEY_ERROR_MEMBERS",

  LIST_SURVEY_REPORT_QUESTIONS: "LIST_SURVEY_REPORT_QUESTIONS",
  LIST_SURVEY_REPORT_QUESTIONS_SUCCESS: "LIST_SURVEY_REPORT_QUESTIONS_SUCCESS",
  LIST_SURVEY_REPORT_QUESTIONS_ERROR: "LIST_SURVEY_REPORT_QUESTIONS_ERROR",

  LIST_SURVEY_REPORT_GENERAL: "LIST_SURVEY_REPORT_GENERAL",
  LIST_SURVEY_REPORT_GENERAL_SUCCESS: "LIST_SURVEY_REPORT_GENERAL_SUCCESS",
  LIST_SURVEY_REPORT_GENERAL_ERROR: "LIST_SURVEY_REPORT_GENERAL_ERROR",

  GET_DELETED_SURVEYS_LISTING: "GET_DELETED_SURVEYS_LISTING",
  GET_DELETED_SURVEYS_LISTING_SUCCESS: "GET_DELETED_SURVEYS_LISTING_SUCCESS",
  GET_DELETED_SURVEYS_LISTING_ERROR: "GET_DELETED_SURVEYS_LISTING_ERROR",

  RESTORE_SURVEY: "RESTORE_SURVEY",
  RESTORE_SURVEY_SUCCESS: "RESTORE_SURVEY_SUCCESS",
  RESTORE_SURVEY_ERROR: "RESTORE_SURVEY_ERROR",

  DELETE_SURVEY_FOREVER: "DELETE_SURVEY_FOREVER",
  DELETE_SURVEY_FOREVER_SUCCESS: "DELETE_SURVEY_FOREVER_SUCCESS",
  DELETE_SURVEY_FOREVER_ERROR: "DELETE_SURVEY_FOREVER_ERROR",

  DELETE_PAGINATION_PREVIOUS: "DELETE_PAGINATION_PREVIOUS",
  DELETE_PAGINATION_PREVIOUS_SUCCESS: "DELETE_PAGINATION_PREVIOUS_SUCCESS",
  DELETE_PAGINATION_PREVIOUS_ERROR: "DELETE_PAGINATION_PREVIOUS_ERROR",

  DELETE_PAGINATION_NEXT: "DELETE_PAGINATION_NEXT",
  DELETE_PAGINATION_NEXT_SUCCESS: "DELETE_PAGINATION_NEXT_SUCCESS",
  DELETE_PAGINATION_NEXT_ERROR: "DELETE_PAGINATION_NEXT_ERROR",

  // SURVEY DOWNLOAD REPORT
  DOWNLOAD_SURVEY_REPORT: "DOWNLOAD_SURVEY_REPORT",
  DOWNLOAD_SURVEY_REPORT_SUCCESS: "DOWNLOAD_SURVEY_REPORT_SUCCESS",
  DOWNLOAD_SURVEY_REPORT_ERROR: "DOWNLOAD_SURVEY_REPORT_ERROR",

  LIST_SURVEY_REPORT_DEMOGRAPHIC: "LIST_SURVEY_REPORT_DEMOGRAPHIC",
  LIST_SURVEY_REPORT_DEMOGRAPHIC_SUCCESS:
    "LIST_SURVEY_REPORT_DEMOGRAPHIC_SUCCESS",
  LIST_SURVEY_REPORT_DEMOGRAPHIC_ERROR: "LIST_SURVEY_REPORT_DEMOGRAPHIC_EROOR",

  // CLONE SURVEY
  CLONE_SURVEY_REPORT: "CLONE_SURVEY_REPORT",
  CLONE_SURVEY_REPORT_SUCCESS: "CLONE_SURVEY_REPORT_SUCCESS",
  CLONE_SURVEY_REPORT_ERROR: "CLONE_SURVEY_REPORT_ERROR",

  //SURVEY SUBMIT
  SURVEY_SUBMIT: "SURVEY_SUBMIT",
  SURVEY_SUBMIT_SUCCESS: "SURVEY_SUBMIT_SUCCESS",
  SURVEY_SUBMIT_ERROR: "SURVEY_SUBMIT_ERROR",

  //LEARNER SURVEY LIST

  LEARNER_SURVEY_LIST: "LEARNER_SURVEY_LIST",
  LEARNER_SURVEY_LIST_SUCCESS: "LEARNER_SURVEY_LIST_SUCCESS",
  LEARNER_SURVEY_LIST_ERROR: "LEARNER_SURVEY_LIST_ERROR",

  //LEARNER SURVEY DETAIL

  LEARNER_SURVEY_DETAIL: "LEARNER_SURVEY_DETAIL",
  LEARNER_SURVEY_DETAIL_SUCCESS: "LEARNER_SURVEY_DETAIL_SUCCESS",
  LEARNER_SURVEY_DETAIL_ERROR: "LEARNER_SURVEY_DETAIL_ERROR",

  COUPON_CODE: "COUPON_CODE",
  COUPON_CODE_SUCCESS: "COUPON_CODE_SUCCESS",
  COUPON_CODE_ERROR: "COUPON_CODE_ERROR",

  GET_MANAGERS: "GET_MANAGERS",
  GET_MANAGERS_SUCCESS: "GET_MANAGERS_SUCCESS",
  GET_MANAGERS_ERROR: "GET_MANAGERS_ERROR",

  CREATE_COURSE: "CREATE_COURSE",
  CREATE_COURSE_SUCCESS: "CREATE_COURSE_SUCCESS",
  CREATE_COURSE_ERROR: "CREATE_COURSE_ERROR",

  GET_MANAGERS_LIST: "GET_MANAGERS_LIST",
  GET_MANAGERS_LIST_SUCCESS: "GET_MANAGERS_LIST_SUCCESS",
  GET_MANAGERS_LIST_ERROR: "GET_MANAGERS_LIST_ERROR",

  GET_MEMBERS: "GET_MEMBERS",
  GET_MEMBERS_SUCCESS: "GET_MEMBERS_SUCCESS",
  GET_MEMBERS_ERROR: "GET_MEMBERS_ERROR",

  GET_NEW_MEMBERS: "GET_NEW_MEMBERS",
  GET_NEW_MEMBERS_SUCCESS: "GET_NEW_MEMBERS_SUCCESS",
  GET_NEW_MEMBERS_ERROR: "GET__NEW_MEMBERS_ERROR",

  ADD_MEMBER: "ADD_MEMBER",
  ADD_MEMBER_SUCCESS: "ADD_MEMBER_SUCCESS",
  ADD_MEMBER_ERROR: "ADD_MEMBER_ERROR",

  COUNT_MEMBER: "COUNT_MEMBER",
  COUNT_MEMBER_SUCCESS: "COUNT_MEMBER_SUCCESS",
  COUNT_MEMBER_ERROR: "COUNT_MEMBER_ERROR",

  ADD_GROUP: "ADD_GROUP",
  ADD_GROUP_SUCCESS: "ADD_GROUP_SUCCESS",
  ADD_GROUP_ERROR: "ADD_GROUP_ERROR",

  REMOVE_MANAGER: " REMOVE_MANAGER",
  REMOVE_MANAGER_SUCCESS: " REMOVE_MANAGER_SUCCESS",
  REMOVE_MANAGER_ERROR: "REMOVE_MANAGER_ERROR",

  ASSIGN_MANAGER: "ASSIGN_MANAGER",
  ASSIGN_MANAGER_SUCCESS: "ASSIGN_MANAGER_SUCCESS",
  ASSIGN_MANAGER_ERROR: "ASSIGN_MANAGER_ERROR",

  GET_ALL_GROUPS: "GET_ALL_GROUPS",
  GET_ALL_GROUPS_SUCCESS: "GET_ALL_GROUPS_SUCCESS",
  GET_ALL_GROUPS_ERROR: "GET_ALL_GROUPS_ERROR",

  GET_ALL_LEARNER_GROUPS: "GET_ALL_LEARNER_GROUPS",
  GET_ALL_LEARNER_GROUPS_SUCCESS: "GET_ALL_LEARNER_GROUPS_SUCCESS",
  GET_ALL_LEARNER_GROUPS_ERROR: "GET_ALL_LEARNER_GROUPS_ERROR",

  GET_ALL_QUIZ: "GET_ALL_QUIZ",
  GET_ALL_QUIZ_SUCCESS: "GET_ALL_QUIZ_SUCCESS",
  GET_ALL_QUIZ_ERROR: "GET_ALL_QUIZ_ERROR",

  ASSIGN_COURSE: "ASSIGN_COURSE",
  ASSIGN_COURSE_SUCCESS: "ASSIGN_COURSE_SUCCESS",
  ASSIGN_COURSE_ERROR: "ASSIGN_COURSE_ERROR",

  //  Survey Construct lISTS
  SURVEY_CONSTRUCT_LISTS: "SURVEY_CONSTRUCT_LISTS",
  SURVEY_CONSTRUCT_LISTS_SUCCESS: "SURVEY_CONSTRUCT_LISTS_SUCCESS",
  SURVEY_CONSTRUCT_LISTS_ERROR: "SURVEY_CONSTRUCT_LISTS_ERROR",

  //  Survey SCoring
  SURVEY_SCORING: "SURVEY_SCORING",
  SURVEY_SCORING_SUCCESS: "SURVEY_SCORING_SUCCESS",
  SURVEY_SCORING_ERROR: "SURVEY_SCORING_ERROR",

  //  Survey Construct Results
  SURVEY_CONSTRUCT_RESULTS: "SURVEY_CONSTRUCT_RESULTS",
  SURVEY_CONSTRUCT_RESULTS_SUCCESS: "SURVEY_CONSTRUCT_RESULTS_SUCCESS",
  SURVEY_CONSTRUCT_RESULTS_ERROR: "SURVEY_CONSTRUCT_RESULTS_ERROR",

  //  Survey Construct Results FILTER
  CONSTRUCT_RESULTS_FILTER: "CONSTRUCT_RESULTS_FILTER",
  CONSTRUCT_RESULTS_FILTER_SUCCESS: "CONSTRUCT_RESULTS_FILTER_SUCCESS",
  CONSTRUCT_RESULTS_FILTER_ERROR: "CONSTRUCT_RESULTS_FILTER_ERROR",

  // gender-demographics-results
  SURVEY_GENDER_DEMOGRAPHIC_RESULTS: "SURVEY_GENDER_DEMOGRAPHIC_RESULTS",
  SURVEY_GENDER_DEMOGRAPHIC_RESULTS_SUCCESS:
    "SURVEY_GENDER_DEMOGRAPHIC_RESULTS_SUCCESS",
  SURVEY_GENDER_DEMOGRAPHIC_RESULTS_ERROR:
    "SURVEY_GENDER_DEMOGRAPHIC_RESULTS_ERROR",

  // SURVEY role-demographics
  SURVEY_ROLE_DEMOGRAPHIC_RESULTS: "SURVEY_ROLE_DEMOGRAPHIC_RESULTS",
  SURVEY_ROLE_DEMOGRAPHIC_RESULTS_SUCCESS:
    "SURVEY_ROLE_DEMOGRAPHIC_RESULTS_SUCCESS",
  SURVEY_ROLE_DEMOGRAPHIC_RESULTS_ERROR:
    "SURVEY_ROLE_DEMOGRAPHIC_RESULTS_ERROR",

  //   Survey Racial DemoGrphics
  SURVEY_RACIAL_DEMOGRAPHIC_RESULTS: "SURVEY_RACIAL_DEMOGRAPHIC_RESULTS",
  SURVEY_RACIAL_DEMOGRAPHIC_RESULTS_SUCCESS:
    "SURVEY_RACIAL_DEMOGRAPHIC_RESULTS_SUCCESS",
  SURVEY_RACIAL_DEMOGRAPHIC_RESULTS_ERROR:
    "SURVEY_RACIAL_DEMOGRAPHIC_RESULTS_ERROR",

  //  Survey Racial DemoGrphics BY AGE

  SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS:
    "SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS",
  SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS_SUCCESS:
    "SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS_SUCCESS",
  SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS_ERROR:
    "SURVEY_RACIAL_BY_AGE_DEMOGRAPHIC_RESULTS_ERROR",

  THEME_SELECT: "THEME_SELECT",
  THEME_SELECT_SUCCESS: "THEME_SELECT_SUCCESS",
  THEME_SELECT_ERROR: "THEME_SELECT_ERROR",

  //remove members
  REMOVE_MEMBER_BULK: "REMOVE_MEMBER_BULK",
  REMOVE_MEMBER_BULK_SUCCESS: " REMOVE_MEMBER_BULK_SUCCESS",
  REMOVE_MEMBER_BULK_ERROR: "REMOVE_MEMBER_BULK_ERROR",

  REMOVE_MEMBER: "REMOVE_MEMBER",
  REMOVE_MEMBER_SUCCESS: " REMOVE_MEMBER_SUCCESS",
  REMOVE_MEMBER_ERROR: " REMOVE_MEMBER_ERROR",

  ASSIGN_MEMBER_COURSE: "ASSIGN_MEMBER_COURSE",
  ASSIGN_MEMBER_COURSE_SUCCESS: "ASSIGN_MEMBER_COURSE_SUCCESS",
  ASSIGN_MEMBER_COURSE_ERROR: "ASSIGN_MEMBER_COURSE_ERROR",

  //selected members
  SELECTED_MEMBERS: "SELECTED_MEMBERS",
  //  Courses
  // GET COURSES
  GET_COURSES_MANAGER_LIST: "GET_COURSES_MANAGER_LIST",
  GET_COURSES_MANAGER_LIST_SUCCESS: "GET_COURSES_MANAGER_LIST_SUCCESS",
  GET_COURSES_MANAGER_LIST_ERROR: "GET_COURSES_MANAGER_LIST_ERROR",

  // GET COURSE ADMIN
  GET_COURSE_ADMIN: "GET_COURSE_ADMIN",
  GET_COURSE_ADMIN_SUCCESS: "GET_COURSE_ADMIN_SUCCESS",
  GET_COURSE_ADMIN_ERROR: "GET_COURSE_ADMIN_ERROR",

  // GET SURVEY ADMIN
  GET_SURVEY_ADMIN: "GET_SURVEY_ADMIN",
  GET_SURVEY_ADMIN_SUCCESS: "GET_SURVEY_ADMIN_SUCCESS",
  GET_SURVEY_ADMIN_ERROR: "GET_SURVEY_ADMIN_ERROR",

  // GET SURVEY ADMIN SINGLE
  GET_SINGLE_SURVEY_ADMIN: "GET_SINGLE_SURVEY_ADMIN",
  GET_SINGLE_SURVEY_ADMIN_SUCCESS: "GET_SINGLE_SURVEY_ADMIN_SUCCESS",
  GET_SINGLE_SURVEY_ADMIN_ERROR: "GET_SINGLE_SURVEY_ADMIN_ERROR",

  //admin survey PAGINATION NEXT
  SURVEY_ADMIN_PAGINATION_NEXT: "SURVEY_ADMIN_PAGINATION_NEXT",
  SURVEY_ADMIN_PAGINATION_NEXT_SUCCESS: "SURVEY_ADMIN_PAGINATION_NEXT_SUCCESS",
  SURVEY_ADMIN_PAGINATION_NEXT_ERROR: "SURVEY_ADMIN_PAGINATION_NEXT_ERROR",

  //admin survey  PREVIOUS
  SURVEY_ADMIN_PAGINATION_PREVIOUS: "SURVEY_ADMIN_PAGINATION_PREVIOUS",
  SURVEY_ADMIN_PAGINATION_PREVIOUS_SUCCESS:
    "SURVEY_ADMIN_PAGINATION_PREVIOUS_SUCCESS",
  SURVEY_ADMIN_PAGINATION_PREVIOUS_ERROR:
    "SURVEY_ADMIN_PAGINATION_PREVIOUS_ERROR",

  // GET DRAFT COURSES
  GET_COURSES_MANAGER_LIST_DRAFT: "GET_COURSES_MANAGER_LIST_DRAFT",
  GET_COURSES_MANAGER_LIST_DRAFT_SUCCESS:
    "GET_COURSES_MANAGER_LIST_DRAFT_SUCCESS",
  GET_COURSES_MANAGER_LIST_DRAFT_ERROR: "GET_COURSES_MANAGER_LIST_DRAFT_ERROR",

  // GET COURSES ADMIN
  GET_COURSES_ADMIN_LIST: "GET_COURSES_ADMIN_LIST",
  GET_COURSES_ADMIN_LIST_SUCCESS: "GET_COURSES_ADMIN_LIST_SUCCESS",
  GET_COURSES_ADMIN_LIST_ERROR: "GET_COURSES_ADMIN_LIST_ERROR",

  //PAGINATION ADMIN COURSE
  ADMIN_COURSE_PAGINATION_NEXT: "ADMIN_COURSE_PAGINATION_NEXT",
  ADMIN_COURSE_PAGINATION_NEXT_SUCCESS: "ADMIN_COURSE_PAGINATION_NEXT_SUCCESS",
  ADMIN_COURSE_PAGINATION_NEXT_ERROR: "ADMIN_COURSE_PAGINATION_NEXT_ERROR",

  ADMIN_COURSE_PAGINATION_PREVIOUS: "ADMIN_COURSE_PAGINATION_PREVIOUS",
  ADMIN_COURSE_PAGINATION_PREVIOUS_SUCCESS:
    "ADMIN_COURSE_PAGINATION_PREVIOUS_SUCCESS",
  ADMIN_COURSE_PAGINATION_PREVIOUS_ERROR:
    "ADMIN_COURSE_PAGINATION_PREVIOUS_ERROR",

  //PAGINATION DELETED COURSES
  DELETED_COURSES_PAGINATION_NEXT: "DELETED_COURSES_PAGINATION_NEXT",
  DELETED_COURSES_PAGINATION_NEXT_SUCCESS:
    "DELETED_COURSES_PAGINATION_NEXT_SUCCESS",
  DELETED_COURSES_PAGINATION_NEXT_ERROR:
    "DELETED_COURSES_PAGINATION_NEXT_ERROR",

  DELETED_COURSES_PAGINATION_PREVIOUS: "DELETED_COURSES_PAGINATION_PREVIOUS",
  DELETED_COURSES_PAGINATION_PREVIOUS_SUCCESS:
    "DELETED_COURSES_PAGINATION_PREVIOUS_SUCCESS",
  DELETED_COURSES_PAGINATION_PREVIOUS_ERROR:
    "DELETED_COURSES_PAGINATION_PREVIOUS_ERROR",

  // GET Deleted Courses
  GET_DELETED_COURSES: "GET_DELETED_COURSES",
  GET_DELETED_COURSES_SUCCESS: "GET_DELETED_COURSES_SUCCESS",
  GET_DELETED_COURSES_ERROR: "GET_DELETED_COURSES_ERROR",

  // Restore Deleted Course

  RESTORE_DELETED_COURSE: "RESTORE_DELETED_COURSE",
  RESTORE_DELETED_COURSE_SUCCESS: "RESTORE_DELETED_COURSE_SUCCESS",
  RESTORE_DELETED_COURSE_ERROR: "RESTORE_DELETED_COURSE_ERROR",

  // Restore Bulk Deleted Course

  RESTORE_BULK_DELETED_COURSE: "RESTORE_BULK_DELETED_COURSE",
  RESTORE_BULK_DELETED_COURSE_SUCCESS: "RESTORE_BULK_DELETED_COURSE_SUCCESS",
  RESTORE_BULK_DELETED_COURSE_ERROR: "RESTORE_BULK_DELETED_COURSE_ERROR",

  // Permanently Delete Course

  PERMANENTLY_DELETE_COURSE: "PERMANENTLY_DELETE_COURSE",
  PERMANENTLY_DELETE_COURSE_SUCCESS: "PERMANENTLY_DELETE_COURSE_SUCCESS",
  PERMANENTLY_DELETE_COURSE_ERROR: "PERMANENTLY_DELETE_COURSE_ERROR",

  // Permanently Delete BULK Course

  PERMANENTLY_DELETE_BULK_COURSE: "PERMANENTLY_DELETE_BULK_COURSE",
  PERMANENTLY_DELETE_BULK_COURSE_SUCCESS:
    "PERMANENTLY_DELETE_BULK_COURSE_SUCCESS",
  PERMANENTLY_DELETE_BULK_COURSE_ERROR: "PERMANENTLY_DELETE_BULK_COURSE_ERROR",

  // CLONE COURSE
  CLONE_COURSE: "CLONE_COURSE",
  CLONE_COURSE_SUCCESS: "CLONE_COURSE_SUCCESS",
  CLONE_COURSE_ERROR: "CLONE_COURSE_ERROR",

  //PAGINATION AVAILABLE COURSE
  AVAILABLE_COURSE_PAGINATION_NEXT: "AVAILABLE_COURSE_PAGINATION_NEXT",
  AVAILABLE_COURSE_PAGINATION_NEXT_SUCCESS:
    "AVAILABLE_COURSE_PAGINATION_NEXT_SUCCESS",
  AVAILABLE_COURSE_PAGINATION_NEXT_ERROR:
    "AVAILABLE_COURSE_PAGINATION_NEXT_ERROR",

  AVAILABLE_COURSE_PAGINATION_PREVIOUS: "AVAILABLE_COURSE_PAGINATION_PREVIOUS",
  AVAILABLE_COURSE_PAGINATION_PREVIOUS_SUCCESS:
    "AVAILABLE_COURSE_PAGINATION_PREVIOUS_SUCCESS",
  AVAILABLE_COURSE_PAGINATION_PREVIOUS_ERROR:
    "AVAILABLE_COURSE_PAGINATION_PREVIOUS_ERROR",

  //PAGINATION DRAFT COURSE
  DRAFT_COURSE_PAGINATION_NEXT: "DRAFT_COURSE_PAGINATION_NEXT",
  DRAFT_COURSE_PAGINATION_NEXT_SUCCESS: "DRAFT_COURSE_PAGINATION_NEXT_SUCCESS",
  DRAFT_COURSE_PAGINATION_NEXT_ERROR: "DRAFT_COURSE_PAGINATION_NEXT_ERROR",

  DRAFT_COURSE_PAGINATION_PREVIOUS: "DRAFT_COURSE_PAGINATION_PREVIOUS",
  DRAFT_COURSE_PAGINATION_PREVIOUS_SUCCESS:
    "DRAFT_COURSE_PAGINATION_PREVIOUS_SUCCESS",
  DRAFT_COURSE_PAGINATION_PREVIOUS_ERROR:
    "DRAFT_COURSE_PAGINATION_PREVIOUS_ERROR",

  //remove groups
  REMOVE_GROUP_BULK: "REMOVE_GROUP_BULK",
  REMOVE_GROUP_BULK_SUCCESS: " REMOVE_GROUP_BULK_SUCCESS",
  REMOVE_GROUP_BULK_ERROR: "REMOVE_MEMBER_BULK_ERROR",

  REMOVE_GROUP: "REMOVE_GROUP",
  REMOVE_GROUP_SUCCESS: " REMOVE_GROUP_SUCCESS",
  REMOVE_GROUP_ERROR: " REMOVE_GROUP_ERROR",

  //get group detail
  GET_GROUP_DETAIL: " GET_GROUP_DETAIL",
  GET_GROUP_DETAIL_SUCCESS: " GET_GROUP_DETAIL_SUCCESS",
  GET_GROUP_DETAIL_ERROR: "GET_GROUP_DETAIL_ERROR",

  // get class detail
  GET_CLASS_DETAIL: " GET_CLASS_DETAIL",
  GET_CLASS_DETAIL_SUCCESS: " GET_CLASS_DETAIL_SUCCESS",
  GET_CLASS_DETAIL_ERROR: "GET_CLASS_DETAIL_ERROR",
  

  //get member detail
  GET_MEMBER_DETAIL: " GET_MEMBER_DETAIL",
  GET_MEMBER_DETAIL_SUCCESS: "GET_MEMBER_DETAIL_SUCCESS",
  GET_MEMBER_DETAIL_ERROR: "GET_MEMBER_DETAIL_ERROR",

  //get member courses
  GET_MEMBER_COURSES: " GET_MEMBER_COURSES",
  GET_MEMBER_COURSES_SUCCESS: "GET_MEMBER_COURSES_SUCCESS",
  GET_MEMBER_COURSES_ERROR: "GET_MEMBER_COURSES_ERROR",

  //DELETE member courses
  DELETE_MEMBER_COURSES: " DELETE_MEMBER_COURSES",
  DELETE_MEMBER_COURSES_SUCCESS: " DELETE_MEMBER_COURSES_SUCCESS",
  DELETE_MEMBER_COURSES_ERROR: " DELETE_MEMBER_COURSES_ERROR",

  //get member LP
  GET_MEMBER_LP: " GET_MEMBER_LP  ",
  GET_MEMBER_LP_SUCCESS: "GET_MEMBER_COURSES_LP  ",
  GET_MEMBER_LP_ERROR: "GET_MEMBER_COURSES_LP ",

  //DELETE member LP
  DELETE_MEMBER_LP: "DELETE_MEMBER_LP",
  DELETE_MEMBER_LP_SUCCESS: "DELETE_MEMBER_LP_SUCCESS",
  DELETE_MEMBER_LP_ERROR: "DELETE_MEMBER_LP_ERROR",

  //get member SURVEY
  GET_MEMBER_SURVEY: " GET_MEMBER_SURVEY",
  GET_MEMBER_SURVEY_SUCCESS: "GET_MEMBER_SURVEY_SUCCESS",
  GET_MEMBER_SURVEY_ERROR: "GET_MEMBER_SURVEY_ERROR",

  //DELETE member SURVEY
  DELETE_MEMBER_SURVEY: " DELETE_MEMBER_SURVEY",
  DELETE_MEMBER_SURVEY_SUCCESS: " DELETE_MEMBER_SURVEY_SUCCESS",
  DELETE_MEMBER_SURVEY_ERROR: " DELETE_MEMBER_SURVEY_ERROR",

  //get GROUP courses
  GET_GROUP_COURSES: " GET_GROUP_COURSES",
  GET_GROUP_COURSES_SUCCESS: "GET_GROUP_COURSES_SUCCESS",
  GET_GROUP_COURSES_ERROR: "GET_GROUP_COURSES_ERROR",

  //DELETE GROUP courses
  DELETE_GROUP_COURSES: " DELETE_GROUP_COURSES",
  DELETE_GROUP_COURSES_SUCCESS: " DELETE_GROUP_COURSES_SUCCESS",
  DELETE_GROUP_COURSES_ERROR: " DELETE_GROUP_COURSES_ERROR",

  //get GROUP LP
  GET_GROUP_LP: " GET_GROUP_LP  ",
  GET_GROUP_LP_SUCCESS: "GET_GROUP_LP  ",
  GET_GROUP_LP_ERROR: "GET_GROUP_LP ",

  //get All LP
  GET_LP: " GET_LP  ",
  GET_LP_SUCCESS: "GET_LP_SUCCESS  ",
  GET_LP_ERROR: "GET_GROUP_ERROR ",

  //DELETE GROUP LP
  DELETE_GROUP_LP: " DELETE_MEMBER_LP  ",
  DELETE_GROUP_LP_SUCCESS: " DELETE_MEMBER_LP_SUCCESS",
  DELETE_GROUP_LP_ERROR: " DELETE_MEMBER_LP_ERROR",

  //get GROUP SURVEY
  GET_GROUP_SURVEY: " GET_GROUP_SURVEY",
  GET_GROUP_SURVEY_SUCCESS: "GET_GROUP_SURVEY_SUCCESS",
  GET_GROUP_SURVEY_ERROR: "GET_GROUP_SURVEY_ERROR",

  //DELETE GROUP SURVEY
  DELETE_GROUP_SURVEY: " DELETE_GROUP_SURVEY",
  DELETE_GROUP_SURVEY_SUCCESS: " DELETE_GROUP_SURVEY_SUCCESS",
  DELETE_GROUP_SURVEY_ERROR: " DELETE_GROUP_SURVEY_ERROR",

  //DELETE COURSES
  DELETE_COURSE: "DELETE_COURSE",
  DELETE_COURSE_SUCCESS: "DELETE_COURSE_SUCCESS",
  DELETE_COURSE_ERROR: "DELETE_COURSE_ERROR",

  //ADD COURSE
  ADD_COURSE: "ADD_COURSE",
  ADD_COURSE_SUCCESS: "ADD_COURSE_SUCCESS",
  ADD_COURSE_ERROR: "ADD_COURSE_ERROR",

  //GET COURSE
  GET_COURSE_MANAGER: "GET_COURSE_MANAGER",
  GET_COURSE_MANAGER_SUCCESS: "GET_COURSE_MANAGER_SUCCESS",
  GET_COURSE_MANAGER_ERROR: "GET_COURSE_MANAGER_ERROR",

  //ADD COURSE MODULE
  ADD_COURSE_MODULE: "ADD_COURSE_MODULE",
  ADD_COURSE_MODULE_SUCCESS: "ADD_COURSE_MODULE_SUCCESS",
  ADD_COURSE_MODULE_ERROR: "ADD_COURSE_MODULE_ERROR",

  //Update COURSE MODULE
  UPDATE_COURSE_MODULE: "UPDATE_COURSE_MODULE",
  UPDATE_COURSE_MODULE_SUCCESS: "UPDATE_COURSE_MODULE_SUCCESS",
  UPDATE_COURSE_MODULE_ERROR: "UPDATE_COURSE_MODULE_ERROR",

  //Assignment List By Course id
  ASSIGNMENT_LIST_COURSE: "ASSIGNMENT_LIST_COURSE",
  ASSIGNMENT_LIST_COURSE_SUCCESS: "ASSIGNMENT_LIST_COURSE_SUCCESS",
  ASSIGNMENT_LIST_COURSE_ERROR: "ASSIGNMENT_LIST_COURSE_ERROR",

  //Assignment List By assignment id
  ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE: "ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE",
  ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE_SUCCESS:
    "ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE_SUCCESS",
  ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE_ERROR:
    "ASSIGNMENT_BY_ASSIGNMENT_ID_COURSE_ERROR",

  //Update COURSE ASSIGNMENT
  UPDATE_COURSE_ASSIGNMENT: "UPDATE_COURSE_ASSIGNMENT",
  UPDATE_COURSE_ASSIGNMENT_SUCCESS: "UPDATE_COURSE_ASSIGNMENT_SUCCESS",
  UPDATE_COURSE_ASSIGNMENT_ERROR: "UPDATE_COURSE_ASSIGNMENT_ERROR",

  //GET COURSE MODULE
  GET_COURSE_MODULE_MANAGER: "GET_COURSE_MODULE_MANAGER",
  GET_COURSE_MODULE_MANAGER_SUCCESS: "GET_COURSE_MODULE_MANAGER_SUCCESS",
  GET_COURSE_MODULE_MANAGER_ERROR: "GET_COURSE_MODULE_MANAGER_ERROR",

  //DELETE COURSE MODULE
  DELETE_COURSE_MODULE: "DELETE_COURSE_MODULE",
  DELETE_COURSE_MODULE_SUCCESS: "DELETE_COURSE_MODULE_SUCCESS",
  DELETE_COURSE_MODULE_ERROR: "DELETE_COURSE_MODULE_ERROR",

  //DELETE COURSE CHOICE
  DELETE_COURSE_CHOICE: "DELETE_COURSE_CHOICE",
  DELETE_COURSE_CHOICE_SUCCESS: "DELETE_COURSE_CHOICE_SUCCESS",
  DELETE_COURSE_CHOICE_ERROR: "DELETE_COURSE_CHOICE_ERROR",

  GET_LIST_COURSE_MANAGERS: "GET_LIST_COURSE_MANAGERS",
  GET_LIST_COURSE_MANAGERS_SUCCESS: "GET_LIST_COURSE_MANAGERS_SUCCESS",
  GET_LIST_COURSE_MANAGERS_ERROR: "GET_LIST_COURSE_MANAGERS_ERROR",

  UPDATE_COURSE: "UPDATE_COURSE",
  UPDATE_COURSE_SUCCESS: "UPDATE_COURSE_SUCCESS",
  UPDATE_COURSE_ERROR: "UPDATE_COURSE_ERROR",

  UPDATE_GROUPS: "UPDATE_GROUPS",
  UPDATE_GROUPS_SUCCESS: "UPDATE_GROUPS_SUCCESS",
  UPDATE_GROUPS_ERROR: "UPDATE_GROUPS_ERROR",

  //get learner courses
  

  GET_LEARNER_COURSES: "GET_LEARNER_COURSES",
  GET_LEARNER_COURSES_SUCCESS: "GET_LEARNER_COURSES_SUCCESS",
  GET_LEARNER_COURSES_ERROR: "GET_LEARNER_COURSES_ERROR",

  GET_LEARNER_COMPLETED_COURSES: "GET_LEARNER_COMPLETED_COURSES",
  GET_LEARNER_COMPLETED_COURSES_SUCCESS: "GET_LEARNER_COMPLETED_COURSES_SUCCESS",
  GET_LEARNER_COMPLETED_COURSES_ERROR: "GET_LEARNER_COMPLETED_COURSES_ERROR",

  GET_LEARNER_ONGOING_COURSES: "GET_LEARNER_ONGOING_COURSES",
  GET_LEARNER_ONGOING_COURSES_SUCCESS: "GET_LEARNER_ONGOING_COURSES_SUCCESS",
  GET_LEARNER_ONGOING_COURSES_ERROR: "GET_LEARNER_ONGOING_COURSES_ERROR",

  GET_LEARNER_FAILED_COURSES: "GET_LEARNER_FAILED_COURSES",
  GET_LEARNER_FAILED_COURSES_SUCCESS: "GET_LEARNER_FAILED_COURSES_SUCCESS",
  GET_LEARNER_FAILED_COURSES_ERROR: "GET_LEARNER_FAILED_COURSES_ERROR",

  //PAGINATION lEARNER COURSE
  LEARNER_COURSE_PAGINATION_NEXT: "LEARNER_COURSE_PAGINATION_NEXT",
  LEARNER_COURSE_PAGINATION_NEXT_SUCCESS:
    "LEARNER_COURSE_PAGINATION_NEXT_SUCCESS",
  LEARNER_COURSE_PAGINATION_NEXT_ERROR: "LEARNER_COURSE_PAGINATION_NEXT_ERROR",

  LEARNER_COURSE_PAGINATION_PREVIOUS: "LEARNER_COURSE_PAGINATION_PREVIOUS",
  LEARNER_COURSE_PAGINATION_PREVIOUS_SUCCESS:
    "LEARNER_COURSE_PAGINATION_PREVIOUS_SUCCESS",
  LEARNER_COURSE_PAGINATION_PREVIOUS_ERROR:
    "LEARNER_COURSE_PAGINATION_PREVIOUS_ERROR",

  //  GET LEARNER COURSE BY ID
  GET_LEARNER_COURSE_BY_ID: "GET_LEARNER_COURSE_BY_ID",
  GET_LEARNER_COURSE_BY_ID_SUCCESS: "GET_LEARNER_COURSE_BY_ID_SUCCESS",
  GET_LEARNER_COURSE_BY_ID_ERROR: "GET_LEARNER_COURSE_BY_ID_ERROR",

  //  GET LIST LEARNER MODULES LIST
  GET_LEARNER_COURSE_MODULES_LIST: "GET_LEARNER_COURSE_MODULES_LIST",
  GET_LEARNER_COURSE_MODULES_LIST_SUCCESS:
    "GET_LEARNER_COURSE_MODULES_LIST_SUCCESS",
  GET_LEARNER_COURSE_MODULES_LIST_ERROR:
    "GET_LEARNER_COURSE_MODULES_LIST_ERROR",

  //  GET LEARNER COURSE MODULE BY ID
  GET_LEARNER_COURSE_MODULE_BY_ID: "GET_LEARNER_COURSE_MODULE_BY_ID",
  GET_LEARNER_COURSE_MODULE_BY_ID_SUCCESS:
    "GET_LEARNER_COURSE_MODULE_BY_ID_SUCCESS",
  GET_LEARNER_COURSE_MODULE_BY_ID_ERROR:
    "GET_LEARNER_COURSE_MODULE_BY_ID_ERROR",

  //  GET LEARNER COURSE MODULE COMPLETE
  GET_LEARNER_COURSE_MODULE_COMPLETE: "GET_LEARNER_COURSE_MODULE_COMPLETE",
  GET_LEARNER_COURSE_MODULE_COMPLETE_SUCCESS:
    "GET_LEARNER_COURSE_MODULE_COMPLETE_SUCCESS",
  GET_LEARNER_COURSE_MODULE_COMPLETE_ERROR:
    "GET_LEARNER_COURSE_MODULE_COMPLETE_ERROR",

  //  PATCH LEARNER COURSE MODULE COMPLETE
  PATCH_LEARNER_COURSE_MODULE_COMPLETE: "PATCH_LEARNER_COURSE_MODULE_COMPLETE",
  PATCH_LEARNER_COURSE_MODULE_COMPLETE_SUCCESS:
    "PATCH_LEARNER_COURSE_MODULE_COMPLETE_SUCCESS",
  PATCH_LEARNER_COURSE_MODULE_COMPLETE_ERROR:
    "PATCH_LEARNER_COURSE_MODULE_COMPLETE_ERROR",

  //   LEARNER COURSE TEST ATTEMPT
  LEARNER_COURSE_TEST_ATTEMPT: "LEARNER_COURSE_TEST_ATTEMPT",
  LEARNER_COURSE_TEST_ATTEMPT_SUCCESS: "LEARNER_COURSE_TEST_ATTEMPT_SUCCESS",
  LEARNER_COURSE_TEST_ATTEMPT_ERROR: "LEARNER_COURSE_TEST_ATTEMPT_ERROR",

  //Assign multiple course to groups
  ASSIGN_COURSE_GROUPS: "ASSIGN_COURSE_GROUPS",
  ASSIGN_COURSE_SUCCESS_GROUPS: "ASSIGN_COURSE_SUCCESS_GROUPS",
  ASSIGN_COURSE_ERROR_GROUPS: "ASSIGN_COURSE_ERROR_GROUPS",

  //Assign multiple lEARNING PATH to groups
  ASSIGN_LP_GROUPS: "ASSIGN_LP_GROUPS",
  ASSIGN_LP_SUCCESS_GROUPS: "ASSIGN_LP_SUCCESS_GROUPS",
  ASSIGN_LP_ERROR_GROUPS: "ASSIGN_LP_ERROR_GROUPS",

  //Assign multiple lEARNING PATH to MEMBERS

  ASSIGN_LP: "ASSIGN_LP",
  ASSIGN_LP_SUCCESS: "ASSIGN_LP_SUCCESS",
  ASSIGN_LP_ERROR: "ASSIGN_LP_ERROR",

  //Assign multiple lEARNING PATH to groups
  ASSIGN_LP_GROUPS_MULTIPLE: "ASSIGN_LP_GROUPS_MULTIPLE",
  ASSIGN_LP_SUCCESS_GROUPS_MULTIPLE: "ASSIGN_LP_SUCCESS_GROUPS_MULTIPLE",
  ASSIGN_LP_ERROR_GROUPS_MULTIPLE: "ASSIGN_LP_ERROR_GROUPS_MULTIPLE",

  //PAGINATION GROUP course
  GROUP_COURSE_PAGINATION_NEXT: "GROUP_COURSE_PAGINATION_NEXT",
  GROUP_COURSE_PAGINATION_NEXT_SUCCESS: "GROUP_COURSE_PAGINATION_NEXT_SUCCESS",
  GROUP_COURSE_PAGINATION_NEXT_ERROR: "GROUP_COURSE_PAGINATION_NEXT_ERROR",

  GROUP_COURSE_PAGINATION_PREVIOUS: "GROUP_COURSE_PAGINATION_PREVIOUS",
  GROUP_COURSE_PAGINATION_PREVIOUS_SUCCESS:
    "GROUP_COURSE_PAGINATION_PREVIOUS_SUCCESS",
  GROUP_COURSE_PAGINATION_PREVIOUS_ERROR:
    "GROUP_COURSE_PAGINATION_PREVIOUS_ERROR",

  //PAGINATION GROUP LP
  LP_GROUP_PAGINATION_NEXT: "LP_GROUP_PAGINATION_NEXT",
  LP_GROUP_PAGINATION_NEXT_SUCCESS: "LP_GROUP_PAGINATION_NEXT_SUCCESS",
  LP_GROUP_PAGINATION_NEXT_ERROR: "LP_GROUP_PAGINATION_NEXT_ERROR",

  LP_GROUP_PAGINATION_PREVIOUS: "LP_GROUP_PAGINATION_PREVIOUS",
  LP_GROUP_PAGINATION_PREVIOUS_SUCCESS: "LP_GROUP_PAGINATION_PREVIOUS_SUCCESS",
  LP_GROUP_PAGINATION_PREVIOUS_ERROR: "LP_GROUP_PAGINATION_PREVIOUS_ERROR",

  //pagination group survey
  PAGINATION_NEXT_SURVEY: "PAGINATION_NEXT_SURVEY",
  PAGINATION_NEXT_SURVEY_SUCCESS: "PAGINATION_NEXT_SURVEY_SUCCESS",
  PAGINATION_NEXT_SURVEY_ERROR: "PAGINATION_NEXT_SURVEY_ERROR",

  PAGINATION_PREVIOUS_SURVEY: "PAGINATION_PREVIOUS_SURVEY",
  PAGINATION_PREVIOUS_SURVEY_SUCCESS: "PAGINATION_PREVIOUS_SURVEY_SUCCESS",
  PAGINATION_PREVIOUS_SURVEY_ERROR: "PAGINATION_PREVIOUS_SURVEY_ERROR",

  //Logo Upload
  LOGO_SELECT: "LOGO_SELECT",
  LOGO_SELECT_SUCCESS: "LOGO_SELECT_SUCCESS",
  LOGO_SELECT_ERROR: "LOGO_SELECT_ERROR",

  //     GET NOTIFICATIONS
  GET_NOTIFICATION: "GET_NOTIFICATION",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_ERROR: "GET_NOTIFICATION_ERROR",

  //     GET NOTIFICATIONS DETAIL
  GET_NOTIFICATION_DETAIL: "GET_NOTIFICATION_DETAIL",
  GET_NOTIFICATION_DETAIL_SUCCESS: "GET_NOTIFICATION_DETAIL_SUCCESS",
  GET_NOTIFICATION_DETAIL_ERROR: "GET_NOTIFICATION_DETAIL_ERROR",

  INSTITUTION_CATEGORY: "INSTITUTION_CATEGORY",
  INSTITUTION_CATEGORY_SUCCESS: "INSTITUTION_CATEGORY_SUCCESS",
  INSTITUTION_CATEGORY_ERROR: "INSTITUTION_CATEGORY_ERROR",

  //dasboard

  GET_DASBOARD_USERS: "GET_DASBOARD_USERS",
  GET_DASBOARD_USERS_SUCCESS: "GET_DASBOARD_USERS_SUCCESS",
  GET_DASBOARD_USERS_ERROR: "GET_DASBOARD_USERS_ERROR",

  GET_DASHBOARD_SURVEY_COUNT: "GET_DASHBOARD_SURVEY_COUNT",
  GET_DASHBOARD_SURVEY_COUNT_SUCCESS: "GET_DASHBOARD_SURVEY_COUNT_SUCCESS",
  GET_DASHBOARD_SURVEY_COUNT_ERROR: "GET_DASHBOARD_SURVEY_COUNT_ERROR",

  GET_DASBOARD_COURSES_COUNT: "GET_DASBOARD_COURSES_COUNT",
  GET_DASBOARD_COURSES_COUNT_SUCCESS: "GET_DASBOARD_COURSES_COUNT_SUCCESS",
  GET_DASBOARD_COURSES_COUNT_ERROR: "GET_DASBOARD_COURSES_COUNT_ERROR",

  GET_COURSES_GROUP: "GET_COURSES_GROUP",
  GET_COURSES_GROUP_SUCCESS: "GET_COURSES_GROUP_SUCCESS",
  GET_COURSES_GROUP_ERROR: "GET_COURSES_GROUP_ERROR",

  GET_DASBOARD_GROUP: "GET_DASBOARD_GROUP",
  GET_DASBOARD_GROUP_SUCCESS: "GET_DASBOARD_GROUP_SUCCESS",
  GET_DASBOARD_GROUP_ERROR: "GET_DASBOARD_GROUP_ERROR",

  GET_DASBOARD_COURSES_USERS_COUNT: "GET_DASBOARD_COURSES_USERS_COUNT",
  GET_DASBOARD_COURSES_USERS_COUNT_SUCCESS:
    "GET_DASBOARD_COURSES_USERS_COUNT_SUCCESS",
  GET_DASBOARD_COURSES_USERS_COUNT_ERROR:
    "GET_DASBOARD_COURSES_USERS_COUNT_ERROR",

  GET_DASHBOARD_COURSE_COMPLETION: "GET_DASHBOARD_COURSE_COMPLETION",
  GET_DASHBOARD_COURSE_COMPLETION_SUCCESS:
    "GET_DASHBOARD_COURSE_COMPLETION_SUCCESS",
  GET_DASHBOARD_COURSE_COMPLETION_ERROR:
    "GET_DASHBOARD_COURSE_COMPLETION_ERROR",

  GET_DASHBOARD_SURVEY_USER: "GET_DASHBOARD_SURVEY_USER",
  GET_DASHBOARD_SURVEY_USER_SUCCESS: "GET_DASHBOARD_SURVEY_USER_SUCCESS",
  GET_DASHBOARD_SURVEY_USER_ERROR: "GET_DASHBOARD_SURVEY_USER_ERROR",

  GET_ASSIGNMENT_SURVEY: "GET_ASSIGNMENT_SURVEY",
  GET_ASSIGNMENT_SURVEY_SUCCESS: "GET_ASSIGNMENT_SURVEY_SUCCESS",
  GET_ASSIGNMENT_SURVEY_ERROR: "GET_ASSIGNMENT_SURVEY_ERROR",

  //    QUIZ MANAGEMENT

  GET_QUIZZES_MANAGER_LIST: "GET_QUIZZES_MANAGER_LIST",
  GET_QUIZZES_MANAGER_LIST_SUCCESS: "GET_QUIZZES_MANAGER_LIST_SUCCESS",
  GET_QUIZZES_MANAGER_LIST_ERROR: "GET_QUIZZES_MANAGER_LIST_ERROR",

  //PAGINATION MANAGER COURSE
  MANAGER_QUIZ_PAGINATION_NEXT: "MANAGER_QUIZ_PAGINATION_NEXT",
  MANAGER_QUIZ_PAGINATION_NEXT_SUCCESS: "MANAGER_QUIZ_PAGINATION_NEXT_SUCCESS",
  MANAGER_QUIZ_PAGINATION_NEXT_ERROR: "MANAGER_QUIZ_PAGINATION_NEXT_ERROR",

  MANAGER_QUIZ_PAGINATION_PREVIOUS: "MANAGER_QUIZ_PAGINATION_PREVIOUS",
  MANAGER_QUIZ_PAGINATION_PREVIOUS_SUCCESS:
    "MANAGER_QUIZ_PAGINATION_PREVIOUS_SUCCESS",
  MANAGER_QUIZ_PAGINATION_PREVIOUS_ERROR:
    "MANAGER_QUIZ_PAGINATION_PREVIOUS_ERROR",

  GET_DRAFT_MANAGER_QUIZZES: "GET_DRAFT_MANAGER_QUIZZES",
  GET_DRAFT_MANAGER_QUIZZES_SUCCESS: "GET_DRAFT_MANAGER_QUIZZES_SUCCESS",
  GET_DRAFT_MANAGER_QUIZZES_ERROR: "GET_DRAFT_MANAGER_QUIZZES_ERROR",

  //DRAFT QUIZ PAGINATION NEXT
  QUIZ_MANAGER_DRAFT_PAGINATION_NEXT: "QUIZ_MANAGER_DRAFT_PAGINATION_NEXT",
  QUIZ_MANAGER_DRAFT_PAGINATION_NEXT_SUCCESS:
    "QUIZ_MANAGER_DRAFT_PAGINATION_NEXT_SUCCESS",
  QUIZ_MANAGER_DRAFT_PAGINATION_NEXT_ERROR:
    "QUIZ_MANAGER_DRAFT_PAGINATION_NEXT_ERROR",

  //DRAFT QUIZ PAGINATION PREVIOUS
  QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS:
    "QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS",
  QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS_SUCCESS:
    "QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS_SUCCESS",
  QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS_ERROR:
    "QUIZ_MANAGER_DRAFT_PAGINATION_PREVIOUS_ERROR",

  //TEAMS MEMBERS PAGINATION NEXT
  TEAMS_MEMBERS_PAGINATION_NEXT: "TEAMS_MEMBERS_PAGINATION_NEXT",
  TEAMS_MEMBERS_PAGINATION_NEXT_SUCCESS:
    "TEAMS_MEMBERS_PAGINATION_NEXT_SUCCESS",
  TEAMS_MEMBERS_PAGINATION_NEXT_ERROR: "TEAMS_MEMBERS_PAGINATION_NEXT_ERROR",

  //TEAMS MEMBERS PAGINATION PREVIOUS
  TEAMS_MEMBERS_PAGINATION_PREVIOUS: "TEAMS_MEMBERS_PAGINATION_PREVIOUS",
  TEAMS_MEMBERS_PAGINATION_PREVIOUS_SUCCESS:
    "TEAMS_MEMBERS_PAGINATION_PREVIOUS_SUCCESS",
  TEAMS_MEMBERS_PAGINATION_PREVIOUS_ERROR:
    "TEAMS_MEMBERS_PAGINATION_PREVIOUS_ERROR",

  //DELETE QUIZ
  DELETE_QUIZ: "DELETE_QUIZ",
  DELETE_QUIZ_SUCCESS: "DELETE_QUIZ_SUCCESS",
  DELETE_QUIZ_ERROR: "DELETE_QUIZ_ERROR",

  //ADD QUIZ
  ADD_QUIZ: "ADD_QUIZ",
  ADD_QUIZ_SUCCESS: "ADD_QUIZ_SUCCESS",
  ADD_QUIZ_ERROR: "ADD_QUIZ_ERROR",

  //UPDATE QUIZ
  UPDATE_QUIZ: "UPDATE_QUIZ",
  UPDATE_QUIZ_SUCCESS: "UPDATE_QUIZ_SUCCESS",
  UPDATE_QUIZ_ERROR: "UPDATE_QUIZ_ERROR",

  //ATTEMPT LEARN QUIZ
  ATTEMPT_QUIZ: "ATTEMPT_QUIZ",
  ATTEMPT_QUIZ_SUCCESS: "ATTEMPT_QUIZ_SUCCESS",
  ATTEMPT_QUIZ_ERROR: "ATTEMPT_QUIZ_ERROR",

  //QUESTIONS QUIZ
  ADD_QUESTIONS_QUIZ: "ADD_QUESTIONS_QUIZ",
  ADD_QUESTIONS_QUIZ_SUCCESS: "ADD_QUESTIONS_QUIZ_SUCCESS",
  ADD_QUESTIONS_QUIZ_ERROR: "ADD_QUESTIONS_QUIZ_ERROR",

  //QUESTIONS UPDATE QUIZ
  UPDATE_QUESTIONS_QUIZ: "UPDATE_QUESTIONS_QUIZ",
  UPDATE_QUESTIONS_QUIZ_SUCCESS: "UPDATE_QUESTIONS_QUIZ_SUCCESS",
  UPDATE_QUESTIONS_QUIZ_ERROR: "UPDATE_QUESTIONS_QUIZ_ERROR",

  // QUIZ ASSIGNMENT
  ASSIGN_QUIZ: "ASSIGN_QUIZ",
  ASSIGN_QUIZ_SUCCESS: "ASSIGN_QUIZ_SUCCESS",
  ASSIGN_QUIZ_ERROR: "ASSIGN_QUIZ_ERROR",

  //DELETE QUIZ QUESTION
  DELETE_QUIZ_QUESTION: "DELETE_QUIZ_QUESTION",
  DELETE_QUIZ_QUESTION_SUCCESS: "DELETE_QUIZ_QUESTION_SUCCESS",
  DELETE_QUIZ_QUESTION_ERROR: "DELETE_QUIZ_QUESTION_ERROR",

  //DELETE QUIZ QUESTION CHOICE
  DELETE_QUIZ_QUESTION_CHOICE: "DELETE_QUIZ_QUESTION_CHOICE",
  DELETE_QUIZ_QUESTION_CHOICE_SUCCESS: "DELETE_QUIZ_QUESTION_CHOICE_SUCCESS",
  DELETE_QUIZ_QUESTION_CHOICE_ERROR: "DELETE_QUIZ_QUESTION_CHOICE_ERROR",

  GET_MANAGER_QUIZ_DETAIL: "GET_MANAGER_QUIZ_DETAIL",
  GET_MANAGER_QUIZ_DETAIL_SUCCESS: "GET_MANAGER_QUIZ_DETAIL_SUCCESS",
  GET_MANAGER_QUIZ_DETAIL_ERROR: "GET_MANAGER_QUIZ_DETAIL_ERROR",

  // GET_LEARNING_PATH_FOR_QUIZ

  GET_MANAGER_QUIZ_LEARNING_PATHS: "GET_MANAGER_QUIZ_LEARNING_PATHS",
  GET_MANAGER_QUIZ_LEARNING_PATHS_SUCCESS:
    "GET_MANAGER_QUIZ_LEARNING_PATHS_SUCCESS",
  GET_MANAGER_QUIZ_LEARNING_PATHS_ERROR:
    "GET_MANAGER_QUIZ_LEARNING_PATHS_ERROR",

  POST_SUPPORT_CONTACT: "POST_SUPPORT_CONTACT",
  POST_SUPPORT_CONTACT_SUCCESS: "POST_SUPPORT_CONTACT_SUCCESS",
  POST_SUPPORT_CONTACT_ERROR: "POST_SUPPORT_CONTACT_ERROR",

  GET_SUPPORT_CONTACT: "GET_SUPPORT_CONTACT",
  GET_SUPPORT_CONTACT_SUCCESS: "GET_SUPPORT_CONTACT_SUCCESS",
  GET_SUPPORT_CONTACT_ERROR: "GET_SUPPORT_CONTACT_ERROR",

  GET_MANAGER_CONTACT: "GET_MANAGER_CONTACT",
  GET_MANAGER_CONTACT_SUCCESS: "GET_MANAGER_CONTACT_SUCCESS",
  GET_MANAGER_CONTACT_ERROR: "GET_MANAGER_CONTACT_ERROR",

  CREATE_MANAGER_CONTACT: "CREATE_MANAGER_CONTACT",
  CREATE_MANAGER_CONTACT_SUCCESS: "CREATE_MANAGER_CONTACT_SUCCESS",
  CREATE_MANAGER_CONTACT_ERROR: "CREATE_MANAGER_CONTACT_ERROR",

  EDIT_MANAGER_CONTACT: "EDIT_MANAGER_CONTACT",
  EDIT_MANAGER_CONTACT_SUCCESS: "EDIT_MANAGER_CONTACT_SUCCESS",
  EDIT_MANAGER_CONTACT_ERROR: "EDIT_MANAGER_CONTACT_ERROR",

  DELETE_MANAGER_CONTACT: "DELETE_MANAGER_CONTACT",
  DELETE_MANAGER_CONTACT_SUCCESS: "DELETE_MANAGER_CONTACT_SUCCESS",
  DELETE_MANAGER_CONTACT_ERROR: "DELETE_MANAGER_CONTACT_ERROR",

  RESEND_EMAIL: "RESEND_EMAIL",
  RESEND_EMAIL_SUCCESS: "RESEND_EMAIL_SUCCESS",
  RESEND_EMAIL_ERROR: "RESEND_EMAIL_ERROR",

  GET_WORK_FLOW_ELEMENTS: "GET_WORK_FLOW_ELEMENTS",
  GET_WORK_FLOW_ELEMENTS_SUCCESS: "GET_WORK_FLOW_ELEMENTS_SUCCESS",
  GET_WORK_FLOW_ELEMENTS_ERROR: "GET_WORK_FLOW_ELEMENTS_ERROR",

  ADD_WORK_FLOW_ELEMENTS: "ADD_WORK_FLOW_ELEMENTS",
  ADD_WORK_FLOW_ELEMENTS_SUCCESS: "ADD_WORK_FLOW_ELEMENTS_SUCCESS",
  ADD_WORK_FLOW_ELEMENTS_ERROR: "ADD_WORK_FLOW_ELEMENTS_ERROR",

  REMOVE_WORK_FLOW_ELEMENTS: "REMOVE_WORK_FLOW_ELEMENTS",
  REMOVE_WORK_FLOW_ELEMENTS_SUCCESS: "REMOVE_WORK_FLOW_ELEMENTS_SUCCESS",
  REMOVE_WORK_FLOW_ELEMENTS_ERROR: "REMOVE_WORK_FLOW_ELEMENTS_ERROR",

  UPDATE_WORK_FLOW_ELEMENTS: "UPDATE_WORK_FLOW_ELEMENTS",
  UPDATE_WORK_FLOW_ELEMENTS_SUCCESS: "UPDATE_WORK_FLOW_ELEMENTS_SUCCESS",
  UPDATE_WORK_FLOW_ELEMENTS_ERROR: "UPDATE_WORK_FLOW_ELEMENTS_ERROR",

  //FOR DELETED QUIZZES TAB
  GET_DELETED_QUIZZES_LISTING: "GET_DELETED_QUIZZES_LISTING",
  GET_DELETED_QUIZZES_LISTING_SUCCESS: "GET_DELETED_QUIZZES_LISTING_SUCCESS",
  GET_DELETED_QUIZZES_LISTING_ERROR: "GET_DELETED_QUIZZES_LISTING_ERROR",

  //DELETED QUIZ FOREVER
  DELETE_QUIZ_FOREVER: "DELETE_QUIZ_FOREVER",
  DELETE_QUIZ_FOREVER_SUCCESS: "DELETE_QUIZ_FOREVER_SUCCESS",
  DELETE_QUIZ_FOREVER_ERROR: "DELETE_QUIZ_FOREVER_ERROR",

  // RESTORE DELETED QUIZ
  RESTORE_DELETED_QUIZ: "RESTORE_DELETED_QUIZ",
  RESTORE_DELETED_QUIZ_SUCCESS: "RESTORE_DELETED_QUIZ_SUCCESS",
  RESTORE_DELETED_QUIZ_ERROR: "RESTORE_DELETED_QUIZ_ERROR",

  // RESTORE MULTIPLE QUIZ
  RESTORE_MULTIPLE_QUIZ: "RESTORE_MULTIPLE_QUIZ",
  RESTORE_MULTIPLE_QUIZ_SUCCESS: "RESTORE_MULTIPLE_QUIZ_SUCCESS",
  RESTORE_MULTIPLE_QUIZ_ERROR: "RESTORE_MULTIPLE_QUIZ_ERROR",

  // DELETED MULTIPLE QUIZ
  DELETE_MULTIPLE_QUIZ: "DELETE_MULTIPLE_QUIZ",
  DELETE_MULTIPLE_QUIZ_SUCCESS: "DELETE_MULTIPLE_QUIZ_SUCCESS",
  DELETE_MULTIPLE_QUIZ_ERROR: "DELETE_MULTIPLE_QUIZ_ERROR",

  //    Learning Paths

  // GET Manager Learning Paths
  GET_LEARNING_PATH_MANAGER_LIST: "GET_LEARNING_PATH_MANAGER_LIST",
  GET_LEARNING_PATH_MANAGER_LIST_SUCCESS:
    "GET_LEARNING_PATH_MANAGER_LIST_SUCCESS",
  GET_LEARNING_PATH_MANAGER_LIST_ERROR: "GET_LEARNING_PATH_MANAGER_LIST_ERROR",

  // GET DRAFT Learning Paths
  GET_LEARNING_PATH_MANAGER_LIST_DRAFT: "GET_LEARNING_PATH_MANAGER_LIST_DRAFT",
  GET_LEARNING_PATH_MANAGER_DRAFT_SUCCESS:
    "GET_LEARNING_PATH_MANAGER_DRAFT_SUCCESS",
  GET_LEARNING_PATH_MANAGER_DRAFT_ERROR:
    "GET_LEARNING_PATH_MANAGER_DRAFT_ERROR",

  // GET Deleted Learning Paths
  GET_DELETED_LEARNING_PATH: "GET_DELETED_LEARNING_PATH",
  GET_DELETED_LEARNING_PATH_SUCCESS: "GET_DELETED_LEARNING_PATH_SUCCESS",
  GET_DELETED_LEARNING_PATH_ERROR: "GET_DELETED_LEARNING_PATH_ERROR",

  // Restore Deleted Learning Path

  RESTORE_DELETED_LEARNING_PATH: "RESTORE_DELETED_LEARNING_PATH",
  RESTORE_DELETED_LEARNING_PATH_SUCCESS:
    "RESTORE_DELETED_LEARNING_PATH_SUCCESS",
  RESTORE_DELETED_LEARNING_PATH_ERROR: "RESTORE_DELETED_LEARNING_PATH_ERROR",

  // Restore Bulk Deleted Learning Path

  RESTORE_BULK_DELETED_LEARNING_PATH: "RESTORE_BULK_DELETED_LEARNING_PATH",
  RESTORE_BULK_DELETED_LEARNING_PATH_SUCCESS:
    "RESTORE_BULK_DELETED_LEARNING_PATH_SUCCESS",
  RESTORE_BULK_DELETED_LEARNING_PATH_ERROR:
    "RESTORE_BULK_DELETED_LEARNING_PATH_ERROR",

  //DELETE Learning Path
  DELETE_LEARNING_PATH: "DELETE_LEARNING_PATH",
  DELETE_LEARNING_PATH_SUCCESS: "DELETE_LEARNING_PATH_SUCCESS",
  DELETE_LEARNING_PATH_ERROR: "DELETE_LEARNING_PATH_ERROR",

  // Permanently Delete Learning Path
  PERMANENTLY_DELETE_LEARNING_PATH: "PERMANENTLY_DELETE_LEARNING_PATH",
  PERMANENTLY_DELETE_LEARNING_PATH_SUCCESS:
    "PERMANENTLY_DELETE_LEARNING_PATH_SUCCESS",
  PERMANENTLY_DELETE_LEARNING_PATH_ERROR:
    "PERMANENTLY_DELETE_LEARNING_PATH_ERROR",

  // Permanently Delete BULK LEARNING_PATH

  PERMANENTLY_DELETE_BULK_LEARNING_PATH:
    "PERMANENTLY_DELETE_BULK_LEARNING_PATH",
  PERMANENTLY_DELETE_BULK_LEARNING_PATH_SUCCESS:
    "PERMANENTLY_DELETE_BULK_LEARNING_PATH_SUCCESS",
  PERMANENTLY_DELETE_BULK_LEARNING_PATH_ERROR:
    "PERMANENTLY_DELETE_BULK_LEARNING_PATH_ERROR",

  //GET Learning Path
  GET_LEARNING_PATH_MANAGER: "GET_LEARNING_PATH_MANAGER",
  GET_LEARNING_PATH_MANAGER_SUCCESS: "GET_LEARNING_PATH_MANAGER_SUCCESS",
  GET_LEARNING_PATH_MANAGER_ERROR: "GET_LEARNING_PATH_MANAGER_ERROR",

  //PAGINATION AVAILABLE COURSE
  AVAILABLE_LEARNING_PATH_PAGINATION_NEXT:
    "AVAILABLE_LEARNING_PATH_PAGINATION_NEXT",
  AVAILABLE_LEARNING_PATH_PAGINATION_NEXT_SUCCESS:
    "AVAILABLE_LEARNING_PATH_PAGINATION_NEXT_SUCCESS",
  AVAILABLE_LEARNING_PATH_PAGINATION_NEXT_ERROR:
    "AVAILABLE_LEARNING_PATH_PAGINATION_NEXT_ERROR",

  AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS:
    "AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS",
  AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS:
    "AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS",
  AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR:
    "AVAILABLE_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR",

  //PAGINATION DRAFT Learning Paths
  DRAFT_LEARNING_PATH_PAGINATION_NEXT: "DRAFT_LEARNING_PATH_PAGINATION_NEXT",
  DRAFT_LEARNING_PATH_PAGINATION_NEXT_SUCCESS:
    "DRAFT_LEARNING_PATH_PAGINATION_NEXT_SUCCESS",
  DRAFT_LEARNING_PATH_PAGINATION_NEXT_ERROR:
    "DRAFT_LEARNING_PATH_PAGINATION_NEXT_ERROR",

  DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS:
    "DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS",
  DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS:
    "DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS",
  DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR:
    "DRAFT_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR",

  //PAGINATION DELETED learning paths
  DELETED_LEARNING_PATH_PAGINATION_NEXT:
    "DELETED_LEARNING_PATH_PAGINATION_NEXT",
  DELETED_LEARNING_PATH_PAGINATION_NEXT_SUCCESS:
    "DELETED_LEARNING_PATH_PAGINATION_NEXT_SUCCESS",
  DELETED_LEARNING_PATH_PAGINATION_NEXT_ERROR:
    "DELETED_LEARNING_PATH_PAGINATION_NEXT_ERROR",

  DELETED_LEARNING_PATH_PAGINATION_PREVIOUS:
    "DELETED_LEARNING_PATH_PAGINATION_PREVIOUS",
  DELETED_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS:
    "DELETED_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS",
  DELETED_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR:
    "DELETED_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR",

  //ADD LEARNING PATH
  ADD_LEARNING_PATH: "ADD_LEARNING_PATH",
  ADD_LEARNING_PATH_SUCCESS: "ADD_LEARNING_PATH_SUCCESS",
  ADD_LEARNING_PATH_ERROR: "ADD_LEARNING_PATH_ERROR",

  UPDATE_LEARNING_PATH: "UPDATE_LEARNING_PATH",
  UPDATE_LEARNING_PATH_SUCCESS: "UPDATE_LEARNING_PATH_SUCCESS",
  UPDATE_LEARNING_PATH_ERROR: "UPDATE_LEARNING_PATH_ERROR",

  // CLONE LEARNING PATH
  CLONE_LEARNING_PATH: "CLONE_LEARNING_PATH",
  CLONE_LEARNING_PATH_SUCCESS: "CLONE_LEARNING_PATH_SUCCESS",
  CLONE_LEARNING_PATH_ERROR: "CLONE_LEARNING_PATH_ERROR",

  // GET LPS ADMIN
  GET_LEARNING_PATH_ADMIN_LIST: "GET_LEARNING_PATH_ADMIN_LIST",
  GET_LEARNING_PATH_ADMIN_LIST_SUCCESS: "GET_LEARNING_PATH_ADMIN_LIST_SUCCESS",
  GET_LEARNING_PATH_ADMIN_LIST_ERROR: "GET_LEARNING_PATH_ADMIN_LIST_ERROR",

  //PAGINATION ADMIN LEARNING PATH
  ADMIN_LEARNING_PATH_PAGINATION_NEXT: "ADMIN_LEARNING_PATH_PAGINATION_NEXT",
  ADMIN_LEARNING_PATH_PAGINATION_NEXT_SUCCESS:
    "ADMIN_LEARNING_PATH_PAGINATION_NEXT_SUCCESS",
  ADMIN_LEARNING_PATH_PAGINATION_NEXT_ERROR:
    "ADMIN_LEARNING_PATH_PAGINATION_NEXT_ERROR",

  ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS:
    "ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS",
  ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS:
    "ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS_SUCCESS",
  ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR:
    "ADMIN_LEARNING_PATH_PAGINATION_PREVIOUS_ERROR",

  // GET LEARNING_PATH ADMIN
  GET_LEARNING_PATH_ADMIN: "GET_LEARNING_PATH_ADMIN",
  GET_LEARNING_PATH_ADMIN_SUCCESS: "GET_LEARNING_PATH_ADMIN_SUCCESS",
  GET_LEARNING_PATH_ADMIN_ERROR: "GET_LEARNING_PATH_ADMIN_ERROR",

  //get learner learning Path

  GET_LEARNER_LEARNINGPATH: "GET_LEARNER_LEARNINGPATH",
  GET_LEARNER_LEARNINGPATH_SUCCESS: "GET_LEARNER_LEARNINGPATH_SUCCESS",
  GET_LEARNER_LEARNINGPATH_ERROR: "GET_LEARNER_LEARNINGPATH_ERROR",

  //PAGINATION lEARNER COURSE
  LEARNER_LEARNINGPATH_PAGINATION_NEXT: "LEARNER_LEARNINGPATH_PAGINATION_NEXT",
  LEARNER_LEARNINGPATH_PAGINATION_NEXT_SUCCESS:
    "LEARNER_LEARNINGPATH_PAGINATION_NEXT_SUCCESS",
  LEARNER_LEARNINGPATH_PAGINATION_NEXT_ERROR:
    "LEARNER_LEARNINGPATH_PAGINATION_NEXT_ERROR",

  LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS:
    "LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS",
  LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS_SUCCESS:
    "LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS_SUCCESS",
  LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS_ERROR:
    "LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS_ERROR",

  GET_LEARNER_LEARNINGPATH_COMPLETE: "GET_LEARNER_LEARNINGPATH_COMPLETE",
  GET_LEARNER_LEARNINGPATH_COMPLETE_SUCCESS:
    "GET_LEARNER_LEARNINGPATH_COMPLETE_SUCCESS",
  GET_LEARNER_LEARNINGPATH_COMPLETE_ERROR:
    "GET_LEARNER_LEARNINGPATH_COMPLETE_ERROR",

  //PAGINATION lEARNER COURSE
  LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT:
    "LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT",
  LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT_SUCCESS:
    "LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT_SUCCESS",
  LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT_ERROR:
    "LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT_ERROR",

  LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS:
    "LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS",
  LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS_SUCCESS:
    "LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS_SUCCESS",
  LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS_ERROR:
    "LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS_ERROR",

  GET_LEARNER_LEARNINGPATH_FAILED: "GET_LEARNER_LEARNINGPATH_FAILED",
  GET_LEARNER_LEARNINGPATH_FAILED_SUCCESS:
    "GET_LEARNER_LEARNINGPATH_FAILED_SUCCESS",
  GET_LEARNER_LEARNINGPATH_FAILED_ERROR:
    "GET_LEARNER_LEARNINGPATH_FAILED_ERROR",

  //PAGINATION lEARNER COURSE
  LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT:
    "LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT",
  LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT_SUCCESS:
    "LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT_SUCCESS",
  LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT_ERROR:
    "LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT_ERROR",

  LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS:
    "LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS",
  LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS_SUCCESS:
    "LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS_SUCCESS",
  LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS_ERROR:
    "LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS_ERROR",

  LEARNER_ASSIGNED_LEARNING_Path: "LEARNER_ASSIGNED_LEARNING_Path",
  LEARNER_ASSIGNED_LEARNING_Path_SUCCESS:
    "LEARNER_ASSIGNED_LEARNING_Path_SUCCESS",
  LEARNER_ASSIGNED_LEARNING_Path_ERROR: "LEARNER_ASSIGNED_LEARNING_Path_ERROR",

  //  GET LEARNER COURSE BY ID
  // GET COURSES FOR WITHOUT PAGINATION
  GET_COURSES_LIST_WITHOUT_PAGINATION: "GET_COURSES_LIST_WITHOUT_PAGINATION",
  GET_COURSES_LIST_WITHOUT_PAGINATION_SUCCESS:
    "GET_COURSES_LIST_WITHOUT_PAGINATION_SUCCESS",
  GET_COURSES_LIST_WITHOUT_PAGINATION_ERROR:
    "GET_COURSES_LIST_WITHOUT_PAGINATION_ERROR",

  // GET QUIZZES FOR WITHOUT PAGINATION
  GET_QUIZZES_LIST_WITHOUT_PAGINATION: "GET_QUIZZES_LIST_WITHOUT_PAGINATION",
  GET_QUIZZES_LIST_WITHOUT_PAGINATION_SUCCESS:
    "GET_QUIZZES_LIST_WITHOUT_PAGINATION_SUCCESS",
  GET_QUIZZES_LIST_WITHOUT_PAGINATION_ERROR:
    "GET_QUIZZES_LIST_WITHOUT_PAGINATION_ERROR",

  // GET GROUPS FOR WITHOUT PAGINATION

  GET_GROUPS_LIST_WITHOUT_PAGINATION: "GET_GROUPS_LIST_WITHOUT_PAGINATION",
  GET_GROUPS_LIST_WITHOUT_PAGINATION_SUCCESS:
    "GET_GROUPS_LIST_WITHOUT_PAGINATION_SUCCESS",
  GET_GROUPS_LIST_WITHOUT_PAGINATION_ERROR:
    "GET_GROUPS_LIST_WITHOUT_PAGINATION_ERROR",

  // GET_CLASSES_LIST_WITHOUT_PAGINATION
  GET_CLASSES_LIST_WITHOUT_PAGINATION:'GET_CLASSES_LIST_WITHOUT_PAGINATION',
  GET_CLASSES_LIST_WITHOUT_PAGINATION_SUCCESS: 'GET_CLASSES_LIST_WITHOUT_PAGINATION_SUCCESS',
  GET_CLASSES_LIST_WITHOUT_PAGINATION_ERROR : 'GET_CLASSES_LIST_WITHOUT_PAGINATION_ERROR',

  //GET MEMBERS WITHOUT PAGINATION
  GET_MEMBERS_LIST_WITHOUT_PAGINATION: "GET_MEMBERS_LIST_WITHOUT_PAGINATION",
  GET_MEMBERS_LIST_WITHOUT_PAGINATION_SUCCESS:
    "GET_MEMBERS_LIST_WITHOUT_PAGINATION_SUCCESS",
  GET_MEMBERS_LIST_WITHOUT_PAGINATION_ERROR:
    "GET_MEMBERS_LIST_WITHOUT_PAGINATION_ERROR",

  ASSIGN_LEARNING_Path: "ASSIGN_LEARNING_Path",
  ASSIGN_LEARNING_Path_SUCCESS: "ASSIGN_LEARNING_Path_SUCCESS",
  ASSIGN_LEARNING_Path_ERROR: "ASSIGN_LEARNING_Path_ERROR",

  //Assignment List By LP id

  ASSIGNMENT_LIST_LP: "ASSIGNMENT_LIST_LP",
  ASSIGNMENT_LP_LIST_SUCCESS: "ASSIGNMENT_LP_LIST_SUCCESS",
  ASSIGNMENT_LP_LIST_ERROR: "ASSIGNMENT_LP_LIST_ERROR",

  //Assignment List By assignment id
  ASSIGNMENT_BY_ASSIGNMENT_ID_LP: "ASSIGNMENT_BY_ASSIGNMENT_ID_LP",
  ASSIGNMENT_BY_ASSIGNMENT_ID_LP_SUCCESS:
    "ASSIGNMENT_BY_ASSIGNMENT_ID_LP_SUCCESS",
  ASSIGNMENT_BY_ASSIGNMENT_ID_LP_ERROR: "ASSIGNMENT_BY_ASSIGNMENT_ID_LP_ERROR",

  //Update lp ASSIGNMENT
  UPDATE_LP_ASSIGNMENT: "UPDATE_LP_ASSIGNMENT",
  UPDATE_LP_ASSIGNMENT_SUCCESS: "UPDATE_LP_ASSIGNMENT_SUCCESS",
  UPDATE_LP_ASSIGNMENT_ERROR: "UPDATE_LP_ASSIGNMENT_ERROR",
  //Update lp ASSIGNMENT
  DELETE_LP_ASSIGNMENT: "DELETE_LP_ASSIGNMENT",
  DELETE_LP_ASSIGNMENT_SUCCESS: "DELETE_LP_ASSIGNMENT_SUCCESS",
  DELETE_LP_ASSIGNMENT_ERROR: "DELETE_LP_ASSIGNMENT_ERROR",

  //Update lp Node

  UPDATE_LP_NODE: "UPDATE_LP_NODE",
  UPDATE_LP_NODE_SUCCESS: "UPDATE_LP_NODE_SUCCESS",
  UPDATE_LP_NODE_ERROR: "UPDATE_LP_NODE_ERROR",

  UPDATE_LP_NODE_AUTO_SAVE: "UPDATE_LP_NODE_AUTO_SAVE",
  UPDATE_LP_NODE_AUTO_SAVE_SUCCESS: "UPDATE_LP_NODE_AUTO_SAVE",
  UPDATE_LP_NODE_AUTO_SAVE_ERROR: "UPDATE_LP_NODE_AUTO_SAVE_ERROR",

  LIST_LP_NODE: "LIST_LP_NODE",
  LIST_LP_NODE_SUCCESS: "LIST_LP_NODE_SUCCESS",
  LIST_LP_NODE_ERROR: "LIST_LP_NODE_ERROR",

  ELEMENT_LP_NODE: "ELEMENT_LP_NODE",
  ELEMENT_LP_NODE_SUCCESS: "ELEMENT_LP_NODE_SUCCESS",
  ELEMENT_LP_NODE_ERROR: "ELEMENT_LP_NODE_ERROR",

  DELETE_ELEMENT_LP_NODE: "DELETE_ELEMENT_LP_NODE",
  DELETE_ELEMENT_LP_NODE_SUCCESS: "DELETE_ELEMENT_LP_NODE_SUCCESS",
  DELETE_ELEMENT_LP_NODE_ERROR: "DELETE_ELEMENT_LP_NODE_ERROR",

  //GET Learning Path dETAIL
  GET_LEARNING_PATH_MANAGER_DETAIL: "GET_LEARNING_PATH_MANAGER_DETAIL",
  GET_LEARNING_PATH_MANAGER_DETAIL_SUCCESS:
    "GET_LEARNING_PATH_MANAGER_DETAIL_SUCCESS",
  GET_LEARNING_PATH_MANAGER_DETAIL_ERROR:
    "GET_LEARNING_PATH_MANAGER_DETAIL_ERROR",

  ADD_SURVEY: "ADD_SURVEY",
  ADD_SURVEY_SUCCESS: "ADD_SURVEY_SUCCESS",
  ADD_SURVEY_ERROR: "ADD_SURVEY_ERROR",

  UPDATE_SURVEY: "UPDATE_SURVEY",
  UPDATE_SURVEY_SUCCESS: "UPDATE_SURVEY_SUCCESS",
  UPDATE_SURVEY_ERROR: "UPDATE_SURVEY_ERROR",

  GET_DEMOGRAPHICQUESTIONS: "GET_DEMOGRAPHICQUESTIONS",
  GET_DEMOGRAPHICQUESTIONS_SUCCESS: "GET_DEMOGRAPHICQUESTIONS_SUCCESS",
  GET_DEMOGRAPHICQUESTIONS_ERROR: "GET_DEMOGRAPHICQUESTIONS_ERROR",

  LP_NODE_LIST_DETAIL_MANAGER: "LP_NODE_LIST_DETAIL_MANAGER",
  LP_NODE_LIST_DETAIL_MANAGER_SUCCESS: "LP_NODE_LIST_DETAIL_MANAGER_SUCCESS",
  LP_NODE_LIST_DETAIL_MANAGER_SUCCESS_ERROR:
    "LP_NODE_LIST_DETAIL_MANAGER_SUCCESS_ERROR",

  LP_NODE_LIST_MANAGER: "LP_NODE_LIST_MANAGER",
  LP_NODE_LIST_MANAGER_SUCCESS: "LP_NODE_LIST_MANAGER_SUCCESS",
  LP_NODE_LIST_MANAGER_SUCCESS_ERROR: "LP_NODE_LIST_MANAGER_SUCCESS_ERROR",

  LP_NODE_LIST_LEARNER_MANAGER: "LP_NODE_LIST_LEARNER_MANAGER",
  LP_NODE_LIST_LEARNER_MANAGER_SUCCESS: "LP_NODE_LIST_LEARNER_MANAGER_SUCCESS",
  LP_NODE_LIST_LEARNER_MANAGER_SUCCESS_ERROR:
    "LP_NODE_LIST_LEARNER_MANAGER_SUCCESS_ERROR",

  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE",
  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_SUCCESS:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_SUCCESS",
  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_SUCCESS_ERROR:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_SUCCESS_ERROR",

  //PAGINATION NEXT
  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PAGINATION_NEXT:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PAGINATION_NEXT",
  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_NEXT_SUCCESS:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_NEXT_SUCCESS",
  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_NEXT_ERROR:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_NEXT_ERROR",

  //PAGINATION PREVIOUS
  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PAGINATION_PREVIOUS:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PAGINATION_PREVIOUS",
  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PREVIOUS_SUCCESS:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PREVIOUS_SUCCESS",
  LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PREVIOUS_ERROR:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_PAGE_PREVIOUS_ERROR",

  LP_LEARNER_ASSIGNMENT_MANAGER: "LP_LEARNER_ASSIGNMENT_MANAGER",
  LP_LEARNER_ASSIGNMENT_MANAGER_SUCCESS:
    "LP_LEARNER_ASSIGNMENT_MANAGER_SUCCESS",
  LP_LEARNER_ASSIGNMENT_MANAGER_SUCCESS_ERROR:
    "LP_LEARNER_ASSIGNMENT_MANAGER_SUCCESS_ERROR",

  LP_LEARNER_ALL_MANAGER: "LP_LEARNER_ALL_MANAGER",
  LP_LEARNER_ALL_MANAGER_SUCCESS: "LP_LEARNER_ALL_MANAGER_SUCCESS",
  LP_LEARNER_ALL_MANAGER_SUCCESS_ERROR: "LP_LEARNER_ALL_MANAGER_SUCCESS_ERROR",

  LP_LEARNER_ALL_MANAGER_WITH_PAGE: "LP_LEARNER_ALL_MANAGER_WITH_PAGE",
  LP_LEARNER_ALL_MANAGER_WITH_PAGE_SUCCESS:
    "LP_LEARNER_ALL_MANAGER_WITH_PAGE_SUCCESS",
  LP_LEARNER_ALL_MANAGER_WITH_PAGE_SUCCESS_ERROR:
    "LP_LEARNER_ALL_MANAGER_WITH_PAGE_SUCCESS_ERROR",

  //PAGINATION NEXT
  LP_LEARNER_ALL_MANAGER_WITH_PAGE_PAGINATION_NEXT:
    "LP_LEARNER_ALL_MANAGER_WITH_PAGE_PAGINATION_NEXT",
  LP_LEARNER_ALL_MANAGER_WITH_PAGE_NEXT_SUCCESS:
    "LP_LEARNER_ALL_MANAGER_WITH_PAGE_NEXT_SUCCESS",
  LP_LEARNER_ALL_MANAGER_WITH_PAGE_NEXT_ERROR:
    "LP_LEARNER_ALL_MANAGER_WITH_PAGE_NEXT_ERROR",

  //PAGINATION PREVIOUS
  LP_LEARNER_ALL_MANAGER_WITH_PAGE_PAGINATION_PREVIOUS:
    "LP_LEARNER_ALL_MANAGER_WITH_PAGE_PAGINATION_PREVIOUS",
  LP_LEARNER_ALL_MANAGER_WITH_PAGE_PREVIOUS_SUCCESS:
    "LP_LEARNER_ALL_MANAGER_WITH_PAGE_PREVIOUS_SUCCESS",
  LP_LEARNER_ALL_MANAGER_WITH_PAGE_PREVIOUS_ERROR:
    "LP_LEARNER_ALL_MANAGER_WITH_PAGE_PREVIOUS_ERROR",

  LP_LEARNER_LP_EXTEND: "LP_LEARNER_LP_EXTEND",
  LP_LEARNER_LP_EXTEND_SUCCESS: "LP_LEARNER_LP_EXTEND_SUCCESS",
  LP_LEARNER_LP_EXTEND_ERROR: "LP_LEARNER_LP_EXTEND_ERROR",

  LP_LEARNER_LP_EXTEND_LEARNER: "LP_LEARNER_LP_EXTEND_LEARNER",
  LP_LEARNER_LP_EXTEND_LEARNER_SUCCESS: "LP_LEARNER_LP_EXTEND_LEARNER_SUCCESS",
  LP_LEARNER_LP_EXTEND_LEARNER_ERROR: "LP_LEARNER_LP_EXTEND_LEARNER_ERROR",

  //upsate survey
  UPDATE_QUESTIONS_SURVEY: "UPDATE_QUESTIONS_SURVEY",
  UPDATE_QUESTIONS_SURVEY_SUCCESS: "UPDATE_QUESTIONS_SURVEY_SUCCESS",
  UPDATE_QUESTIONS_SURVEY_ERROR: "UPDATE_QUESTIONS_SURVEY_ERROR",

  //survey detail
  GET_MANAGER_SURVEY_DETAIL: "GET_MANAGER_SURVEY_DETAIL",
  GET_MANAGER_SURVEY_DETAIL_SUCCESS: "GET_MANAGER_SURVEY_DETAIL_SUCCESS",
  GET_MANAGER_SURVEY_DETAIL_ERROR: "GET_MANAGER_SURVEY_DETAIL_ERROR",
  LP_NODE_LIST_LEARNER_MANAGER_WITH: "LP_NODE_LIST_LEARNER_MANAGER_WITH",
  LP_NODE_LIST_LEARNER_MANAGER_WITH_SUCCESS:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_SUCCESS",
  LP_NODE_LIST_LEARNER_MANAGER_WITH_ERROR:
    "LP_NODE_LIST_LEARNER_MANAGER_WITH_ERROR",

  LEARNER_LEARNING_PATH_DETAIL: "LEARNER_LEARNING_PATH_DETAIL",
  LEARNER_LEARNING_PATH_DETAIL_SUCCESS: "LEARNER_LEARNING_PATH_DETAIL_SUCCESS",
  LEARNER_LEARNING_PATH_DETAIL_ERROR: "LEARNER_LEARNING_PATH_DETAIL_ERROR",

  LEARNER_LEARNING_PATH_NODES: "LEARNER_LEARNING_PATH_NODES",
  LEARNER_LEARNING_PATH_NODES_SUCCESS: "LEARNER_LEARNING_PATH_NODES_SUCCESS",
  LEARNER_LEARNING_PATH_NODES_ERROR: "LEARNER_LEARNING_PATH_NODES_ERROR",

  LEARNER_LEARNING_PATH_NODES_DETAIL: "LEARNER_LEARNING_PATH_NODES_DETAIL",
  LEARNER_LEARNING_PATH_NODES_DETAIL_SUCCESS:
    "LEARNER_LEARNING_PATH_NODES_DETAIL_SUCCESS",
  LEARNER_LEARNING_PATH_NODES_DETAIL_ERROR:
    "LEARNER_LEARNING_PATH_NODES_DETAIL_ERROR",

  LEARNER_LEARNING_PATH_COURSE_SUBMIT: "LEARNER_LEARNING_PATH_COURSE_SUBMIT",
  LEARNER_LEARNING_PATH_COURSE_SUBMIT_SUCCESS:
    "LEARNER_LEARNING_PATH_COURSE_SUBMIT_SUCCESS",
  LEARNER_LEARNING_PATH_COURSE_SUBMIT_ERROR:
    "LEARNER_LEARNING_PATH_COURSE_SUBMIT_ERROR",

  LEARNER_LEARNING_PATH_COURSE_QUIZ: "LEARNER_LEARNING_PATH_COURSE_QUIZ",
  LEARNER_LEARNING_PATH_COURSE_QUIZ_SUCCESS:
    "LEARNER_LEARNING_PATH_COURSE_QUIZ_SUCCESS",
  LEARNER_LEARNING_PATH_COURSE_QUIZ_ERROR:
    "LEARNER_LEARNING_PATH_COURSE_QUIZ_ERROR",

  LEARNER_LEARNING_CERTIFICATES: "LEARNER_LEARNING_CERTIFICATES",
  LEARNER_LEARNING_CERTIFICATES_SUCCESS:
    "LEARNER_LEARNING_CERTIFICATES_SUCCESS",
  LEARNER_LEARNING_CERTIFICATES_ERROR: "LEARNER_LEARNING_CERTIFICATES_ERROR",

  LEARNER_LEARNING_CERTIFICATE_DETAIL: "LEARNER_LEARNING_CERTIFICATE_DETAIL",
  LEARNER_LEARNING_CERTIFICATE_DETAIL_SUCCESS:
    "LEARNER_LEARNING_CERTIFICATE_DETAIL_SUCCESS",
  LEARNER_LEARNING_CERTIFICATES_DETAIL_ERROR:
    "LEARNER_LEARNING_CERTIFICATES_DETAIL_ERROR",

  //get learner Assignments courses

  GET_LEARNER_ASSIGNMENTS_COURSES: "GET_LEARNER_ASSIGNMENTS_COURSES",
  GET_LEARNER_ASSIGNMENTS_COURSES_SUCCESS:
    "GET_LEARNER_ASSIGNMENTS_COURSES_SUCCESS",
  GET_LEARNER_ASSIGNMENTS_COURSES_ERROR:
    "GET_LEARNER_ASSIGNMENTS_COURSES_ERROR",

  //PAGINATION lEARNER COURSE
  LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT:
    "LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT",
  LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT_SUCCESS:
    "LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT_SUCCESS",
  LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT_ERROR:
    "LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT_ERROR",

  LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS:
    "LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS",
  LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS_SUCCESS:
    "LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS_SUCCESS",
  LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS_ERROR:
    "LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS_ERROR",

  //get learner Assignments lp

  GET_LEARNER_ASSIGNMENTS_LP: "GET_LEARNER_ASSIGNMENTS_LP",
  GET_LEARNER_ASSIGNMENTS_LP_SUCCESS: "GET_LEARNER_ASSIGNMENTS_LP_SUCCESS",
  GET_LEARNER_ASSIGNMENTS_LP_ERROR: "GET_LEARNER_ASSIGNMENTS_LP_ERROR",

  //PAGINATION lEARNER COURSE
  LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT:
    "LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT",
  LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT_SUCCESS:
    "LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT_SUCCESS",
  LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT_ERROR:
    "LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT_ERROR",

  LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS:
    "LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS",
  LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS_SUCCESS:
    "LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS_SUCCESS",
  LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS_ERROR:
    "LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS_ERROR",

  LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE: "LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE",
  LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE_SUCCESS:
    "LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE_SUCCESS",
  LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE_SUCCESS_ERROR:
    "LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE_SUCCESS_ERROR",

  // GET LPS ADMIN
  GET_LPS_ADMIN_LIST: "GET_LPS_ADMIN_LIST",
  GET_LPS_ADMIN_LIST_SUCCESS: "GET_LPS_ADMIN_LIST_SUCCESS",
  GET_LPS_ADMIN_LIST_ERROR: "GET_LPS_ADMIN_LIST_ERROR",

  // GET LP ADMIN
  GET_LP_ADMIN: "GET_LP_ADMIN",
  GET_LP_ADMIN_SUCCESS: "GET_LP_ADMIN_SUCCESS",
  GET_LP_ADMIN_ERROR: "GET_LP_ADMIN_ERROR",

  //PAGINATION ADMIN LPS
  ADMIN_LP_PAGINATION_NEXT: "ADMIN_LP_PAGINATION_NEXT",
  ADMIN_LP_PAGINATION_NEXT_SUCCESS: "ADMIN_LP_PAGINATION_NEXT_SUCCESS",
  ADMIN_LP_PAGINATION_NEXT_ERROR: "ADMIN_LP_PAGINATION_NEXT_ERROR",

  ADMIN_LP_PAGINATION_PREVIOUS: "ADMIN_LP_PAGINATION_PREVIOUS",
  ADMIN_LP_PAGINATION_PREVIOUS_SUCCESS: "ADMIN_LP_PAGINATION_PREVIOUS_SUCCESS",
  ADMIN_LP_PAGINATION_PREVIOUS_ERROR: "ADMIN_LP_PAGINATION_PREVIOUS_ERROR",

  GET_LEARNER_REPORT_LP: "GET_LEARNER_REPORT_LP",
  GET_LEARNER_REPORT_LP_SUCCESS: "GET_LEARNER_REPORT_LP_SUCCESS",
  GET_LEARNER_REPORT_LP_ERROR: "GET_LEARNER_REPORT_LP_ERROR",

  //PAGINATION lEARNER COURSE
  LEARNER_REPORT_LP_PAGINATION_NEXT: "LEARNER_REPORT_LP_PAGINATION_NEXT",
  LEARNER_REPORT_LP_PAGINATION_NEXT_SUCCESS:
    "LEARNER_REPORT_LP_PAGINATION_NEXT_SUCCESS",
  LEARNER_REPORT_LP_PAGINATION_NEXT_ERROR:
    "LEARNER_REPORT_LP_PAGINATION_NEXT_ERROR",

  LEARNER_REPORT_LP_PAGINATION_PREVIOUS:
    "LEARNER_REPORT_LP_PAGINATION_PREVIOUS",
  LEARNER_REPORT_LP_PAGINATION_PREVIOUS_SUCCESS:
    "LEARNER_REPORT_LP_PAGINATION_PREVIOUS_SUCCESS",
  LEARNER_REPORT_LP_PAGINATION_PREVIOUS_ERROR:
    "LEARNER_REPORT_LP_PAGINATION_PREVIOUS_ERROR",

  LP_LEARNER_ALL_REPORT_WITH_PAGE: "LP_LEARNER_ALL_REPORT_WITH_PAGE",
  LP_LEARNER_ALL_REPORT_WITH_PAGE_SUCCESS:
    "LP_LEARNER_ALL_REPORT_WITH_PAGE_SUCCESS",
  LP_LEARNER_ALL_REPORT_WITH_PAGE_SUCCESS_ERROR:
    "LP_LEARNER_ALL_REPORT_WITH_PAGE_SUCCESS_ERROR",

  GET_LEARNER_COURSE_CERTIFICATE: "GET_LEARNER_COURSE_CERTIFICATE",
  GET_LEARNER_COURSE_CERTIFICATE_SUCCESS:
    "GET_LEARNER_COURSE_CERTIFICATE_SUCCESS",
  GET_LEARNER_COURSE_CERTIFICATE_ERROR: "GET_LEARNER_COURSE_CERTIFICATE_ERROR",

  //PAGINATION lEARNER COURSE
  LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT:
    "LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT",
  LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT_SUCCESS:
    "LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT_SUCCESS",
  LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT_ERROR:
    "LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT_ERROR",

  LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS:
    "LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS",
  LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS_SUCCESS:
    "LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS_SUCCESS",
  LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS_ERROR:
    "LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS_ERROR",

  LEARNER_COURSE_CERTIFICATE_WITH_PAGE: "LEARNER_COURSE_CERTIFICATE_WITH_PAGE",
  LEARNER_COURSE_CERTIFICATE_WITH_PAGE_SUCCESS:
    "LEARNER_COURSE_CERTIFICATE_WITH_PAGE_SUCCESS",
  LEARNER_COURSE_CERTIFICATE_WITH_PAGE_SUCCESS_ERROR:
    "LEARNER_COURSE_CERTIFICATE_WITH_PAGE_SUCCESS_ERROR",


    //GET_EDLINK_ASSIGNMENT

GET_EDLINK_ASSIGNMENT_START:'GET_EDLINK_ASSIGNMENT_START',
GET_EDLINK_ASSIGNMENT_SUCCESS:'GET_EDLINK_ASSIGNMENT_SUCCESS',
GET_EDLINK_ASSIGNMENT_ERROR: 'GET_EDLINK_ASSIGNMENT_ERROR',

//TOUR STATUS

SET_TOUR_STATUS:'SET_TOUR_STATUS'

};


