import React from "react";
import { Bar } from "react-chartjs-2";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { listDemographicSurveyReport } from '../../../../services'
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table';


const initialOptions = {
  responsive: true,
  maintainAspectRatio: false,
  pan: {
    enabled: false,
    mode: "xy"
  },
  zoom: {
    enabled: true,
    mode: "xy"
  },
  skipNull: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "center",
      labels: {
        usePointStyle: true,
      },
    },
  },
};




function EthnicityGraph(props) {

  const responseDemo = useSelector(state => state?.surveys?.listSurveyReportDemographic?.response || {})
  const loading = useSelector(state => state.surveys?.listSurveyReportDemographic?.loading || false)
  const { type, demoQuestion, questionId, surveyId } = props;
  const [surveyReport, setSurveyReport] = useState({})
  const [dataLoading, setDataLoading] = useState(false)
  const label = 'ABCDEFGHIJKLMN'

  const sampleData = {
    legend: {
      display: false,
      position: 'right'
    },

    labels: [],
    datasets: [
      {
        label: type,
        data: [],
        barPercentage: 0.6,
        // barThickness:30,
        backgroundColor: ["#1b59ff", "#6610f2", "#084298", "#8c4efc"]
      },

    ]
  };


  const [dataChart, setDataChart] = useState(sampleData)

  const dispatch = useDispatch();

  useEffect(() => {
    setDataLoading(true)

    if (props?.questionId && props?.surveyId && props?.questionId !== undefined) {
      dispatch(listDemographicSurveyReport(surveyId, questionId, function (response) {




        let newArray = response
        response?.choices.map((entry, index) => {


          let array1 = response?.count?.filter((item) => item[Object.keys(item)[0]] === entry?.choice)


          if (!array1?.length) {

            let obj = {
              answer: entry?.choice,
              count: 0,
              percentage: 0
            }
            newArray.count?.push(obj)
          }

        })


        let array2 = newArray?.count.filter((item) => item[Object.keys(item)[0]].length)
        let obj = {
          count: array2
        }
        setSurveyReport(obj)


      }
      ))
    }
    setTimeout(() => {
      setDataLoading(false)
    }, 500);

  }, [props?.questionId])




  useEffect(() => {


    let labelArray = []
    let dataArray = []

    if (surveyReport?.count && surveyReport?.count?.length) {
      surveyReport?.count?.map((item, index) => {

        dataArray.push(item?.count)
        if (surveyReport?.count?.length > 4) {

          labelArray.push(label.charAt(index))
          // labelArray.push(item[Object.keys(item)[0]].slice(0, 8) + '..')

        }

        else if (item[Object.keys(item)[0]].length <= 24) {
          labelArray.push(item[Object.keys(item)[0]])

        }


        else {
          labelArray.push(item[Object.keys(item)[0]].slice(0, 22) + '..')
        }







      })
    }

    const newsampleData = {
      legend: {
        display: false,
        position: 'right'
      },

      labels: labelArray,
      datasets: [
        {
          label: type,

          data: dataArray,
          backgroundColor: ["#1b59ff", "#6610f2", "#084298", "#8c4efc"]
        },

      ]
    };

    setDataChart(newsampleData)

  }, [surveyReport])




  return (
    <div className="gendergraph-wrapper">

      {loading || dataLoading ?

        <div className='loader-full-screen-course'>
          <div className='loader'>
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
        : ''}

      <Row>
        <Col md={4}>

          <div className="graph-data-counthere">

            <div className="graph-data-text">
              <h4 className="graph-title">{props?.demoQuestion?.question}</h4>
              {type == "ethnicity" ? <p>The following graph represents the ethnities represented in your survey</p> : ""}
              {type == "age" ? <p>The following results of your survey based on Age</p> : ''}
              {type != "ethnicity" && type != "age" ? <p>The following results of your survey based on responses received</p> : ''}
              <h5 className="graph-info-title m-b-10">Breakdown of Responses</h5>
            </div>

            <Table striped hover className="normal-table graph-info-table">
              {surveyReport?.count?.length > 4 ?
                <tr>
                  <th width="100">Label For Choices</th>
                  <th>Choices</th>
                  <th>Count</th>
                </tr>
                : ''}

              <tbody> 

                {surveyReport?.count?.map((item, index) => {
                  return (<tr>
                    {surveyReport?.count?.length > 4 ?
                      <td >{label.charAt(index)}</td>
                      : ''}

                    <td>{item[Object.keys(item)[0]]}</td>


                    <td align="right" style={{ wordBreak: 'keep-all'}}>{item?.count}</td>
                  </tr>
                  )
                })
                }

              </tbody>
            </Table>

          </div>
        </Col>

        <Col md={8}>
          <div className="ethnicgraph-box">
            <Bar data={dataChart} options={initialOptions} />
          </div>


        </Col>



      </Row>

    </div>
  );
};

export default EthnicityGraph;
