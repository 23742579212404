
import React, { useState, useEffect, useRef } from 'react';
import { Form } from "react-bootstrap";
import { checkMemberCount } from '../../services';
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { CSVLink } from "react-csv";
import { Link, useHistory } from 'react-router-dom';

function ImportCsv(props) {
   const dispatch = useDispatch()
   const[limitError,setLimitError]=useState(false)
  const history = useHistory()
   const onButtonClick = () => {
      props.fileAdded(false)
      // inputFile.current.click();
   };

   // const inputFile = useRef(null)
   const csvdownload = useRef(null)
   const loading = useSelector(state => state.teams?.count_member?.loading || false);




   const handleLoader = () => {
      if (loading) {
         return (
            <div className='loader-full-screen-course'>
               <div className='loader'>
                  <Spinner animation="border" variant="danger" />
               </div>
            </div>
         )
      }
   }


   const csvData = [
      ["first_name", "last_name", "email", "mobile_number"],

   ];


   const handleDownload = (id) => {

      csvdownload.current.link.click();

   }



useEffect(() => {

   dispatch(checkMemberCount( function (res) {
               if (res) {
                 
   
                  
                 let error=res?.maximum_member_count <= res?.current_member_count
                 if(error)
                 {
                    setLimitError(true)
                 }
   
               }
            }))
  
}, [])




   // const handleSubmit = (e) => {
   //    const data = new FormData();
   //    const file = e.target?.files[0];
   //    if (file != null) {
   //       data.append('user_list', file);
   //       props.parentFile(file)


   //       dispatch(checkMemberCount(data, function (res) {
   //          if (res) {
   //             console.log(res)

   //             props.fileAdded(false)
   //             props.ParentState(res?.data?.Max_members, res?.data?.Current_users, res?.data?.Members_to_add)


   //          }
   //       }))
         //  setAddMember(false)
         //  setTimeout(() => {
         //    dispatch( getMembersList(user?.token,search,''))
         //      dispatch(getNotifications())
         // }, 1000)


      // }
   // }
   return (
      <div className="csv-show-wrap">
         <div>{handleLoader()}</div>

   {limitError ?
         <div className="csv-error-show">
          <div className="csv-error-icon">
             <i className="fa fa-info-circle" aria-hidden="true"></i>
          </div>
         
          <div className="csv-error-content">
             <p>You have more members than what your current plan offers. Please remove the excess members and add Or</p>
             <div className="error-link">
                <span className='d-inline text-primary' style={{cursor:'pointer'}} onClick={() => history.push({pathname:'/profile', state:{key:'Subscription'}})}>upgrade your plan</span>
             </div>
          </div>
          </div>

           :
         <div>
         <p>The CSV import template is pre-populated with columns for available user attributes required for adding a user to the team</p>
         <div className="csv-icons-wrap d-flex">
            <div className="csv-column csv-download" style={{ cursor: 'pointer' }} onClick={() => handleDownload()}>
               <CSVLink
                  data={csvData}
                  filename="data.csv"
                  className="hidden"
                  ref={csvdownload}
               />

               <span className="csv-icon-contain">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                     <g id="Layer_2" data-name="Layer 2" transform="translate(-0.262 -0.262)">
                        <g id="invisible_box" data-name="invisible box">
                           <rect id="Rectangle_3316" data-name="Rectangle 3316" width="40" height="40" transform="translate(0.262 0.262)" fill="none" />
                        </g>
                        <g id="icons_Q2" data-name="icons Q2" transform="translate(3.377 3.377)">
                           <path id="Path_8534" data-name="Path 8534" d="M36.082,43.377H5.689a1.689,1.689,0,0,1,0-3.377H36.082a1.689,1.689,0,0,1,0,3.377Z" transform="translate(-4 -9.606)" fill="#333" />
                           <path id="Path_8535" data-name="Path 8535" d="M12.507,22.152l8.443,8.358a1.6,1.6,0,0,0,2.364,0l8.443-8.358a1.773,1.773,0,0,0,.169-2.28,1.6,1.6,0,0,0-2.533-.169L23.82,25.276V5.689a1.689,1.689,0,0,0-3.377,0V25.276L14.871,19.7a1.6,1.6,0,0,0-2.533.169A1.773,1.773,0,0,0,12.507,22.152Z" transform="translate(-5.246 -4)" fill="#333" />
                        </g>
                     </g>
                  </svg>
               </span>
               <h5>Download CSV Template</h5>
               {/* <div>{handleLoader()}</div> */}
            </div>
            <div className="csv-column csv-upload" onClick={(e)=>onButtonClick()} style={{ cursor: 'pointer' }}>
               {/* <input type='file' id='file' onChange={e => handleSubmit(e)} ref={inputFile} accept='.csv' style={{ display: 'none' }} /> */}

               <span className="csv-icon-contain">

                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                     <g id="Layer_2" data-name="Layer 2" transform="translate(-0.023 -0.023)">
                        <g id="invisible_box" data-name="invisible box">
                           <rect id="Rectangle_3317" data-name="Rectangle 3317" width="40" height="40" transform="translate(0.023 0.023)" fill="none" />
                        </g>
                        <g id="icons_Q2" data-name="icons Q2" transform="translate(3.337 3.324)">
                           <path id="Path_8536" data-name="Path 8536" d="M35.7,40H5.669a1.669,1.669,0,1,0,0,3.337H35.7A1.669,1.669,0,0,0,35.7,40Z" transform="translate(-4 -9.952)" fill="#ce002a" />
                           <path id="Path_8537" data-name="Path 8537" d="M31.523,12.758,23.18,4.5a1.585,1.585,0,0,0-2.336,0L12.5,12.758a1.752,1.752,0,0,0-.167,2.253,1.585,1.585,0,0,0,2.5.167l5.506-5.506V29.027a1.669,1.669,0,1,0,3.337,0V9.671l5.506,5.506a1.585,1.585,0,0,0,2.5-.167,1.752,1.752,0,0,0-.167-2.253Z" transform="translate(-5.326 -3.985)" fill="#ce002a" />
                        </g>
                     </g>
                  </svg>
               </span>
               <h5>Upload CSV for Import</h5>
            </div>
         </div>
         </div>
          }

      </div>
   );
};
export default ImportCsv;
