import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import SubscriptionCanccelledImg from '~/assets/images/subscription-cancelled-img.svg';
import{Link} from 'react-router-dom'
import { useSelector } from 'react-redux';
function SubscriptionCancelled() {

  // const {user} = useSelector(state => state.auth);
  // useEffect(()=>{
        
  //     if (user?.is_subscribed) {
  //         history.push('/my-assignments');
  //     } 

  // },[user]);


    return (
        <div className="error-page-wrap subscription-cancelled-wrap">
        <div className="error-content-wrap text-center">
            <div className="error-img">
            <svg xmlns="http://www.w3.org/2000/svg" width="517.369" height="399.616" viewBox="0 0 517.369 399.616">
  <g id="_6951753" data-name="6951753" transform="translate(-0.8 -74.1)">
    <g id="Background_complete" transform="translate(0.8 74.1)">
      <g id="Group_7449" data-name="Group 7449">
        <g id="Group_7446" data-name="Group 7446" transform="translate(50.495)">
          <g id="Group_7443" data-name="Group 7443" transform="translate(215.743)">
            <g id="Group_7442" data-name="Group 7442">
              <path id="Path_9037" data-name="Path 9037" d="M451.182,358.653H264.2a6.116,6.116,0,0,1-6.1-6.1V80.2a6.116,6.116,0,0,1,6.1-6.1H451.182a6.116,6.116,0,0,1,6.1,6.1V352.651A6.1,6.1,0,0,1,451.182,358.653ZM264.2,74.41A5.785,5.785,0,0,0,258.41,80.2V352.651a5.785,5.785,0,0,0,5.795,5.795H451.182a5.785,5.785,0,0,0,5.795-5.795V80.2a5.785,5.785,0,0,0-5.795-5.795Z" transform="translate(-258.1 -74.1)" fill="#f0f0f0"/>
            </g>
          </g>
          <g id="Group_7445" data-name="Group 7445">
            <g id="Group_7444" data-name="Group 7444">
              <path id="Path_9038" data-name="Path 9038" d="M242.785,358.653H55.7a6.116,6.116,0,0,1-6.1-6.1V80.2a6.116,6.116,0,0,1,6.1-6.1H242.682a6.116,6.116,0,0,1,6.1,6.1V352.651A6.006,6.006,0,0,1,242.785,358.653ZM55.7,74.41A5.785,5.785,0,0,0,49.91,80.2V352.651a5.785,5.785,0,0,0,5.795,5.795H242.682a5.785,5.785,0,0,0,5.795-5.795V80.2a5.785,5.785,0,0,0-5.795-5.795Z" transform="translate(-49.6 -74.1)" fill="#f0f0f0"/>
            </g>
          </g>
        </g>
        <g id="Group_7448" data-name="Group 7448" transform="translate(0 347.775)">
          <g id="Group_7447" data-name="Group 7447">
            <rect id="Rectangle_3370" data-name="Rectangle 3370" width="517.369" height="0.31" fill="#b5b5b5"/>
          </g>
        </g>
      </g>
      <g id="Group_7451" data-name="Group 7451" transform="translate(165.661 15.831)">
        <g id="Group_7450" data-name="Group 7450" transform="translate(19.453)">
          <path id="Path_9039" data-name="Path 9039" d="M181.252,99.851h32.7A1.591,1.591,0,0,0,215.5,98.3V90.952A1.591,1.591,0,0,0,213.95,89.4h-32.7a1.591,1.591,0,0,0-1.552,1.552V98.3A1.524,1.524,0,0,0,181.252,99.851Z" transform="translate(-179.7 -89.4)" fill="#e7e7e7"/>
        </g>
        <path id="Path_9040" data-name="Path 9040" d="M162.452,112.851h32.7A1.591,1.591,0,0,0,196.7,111.3v-7.347a1.591,1.591,0,0,0-1.552-1.552h-32.7a1.591,1.591,0,0,0-1.552,1.552V111.3A1.524,1.524,0,0,0,162.452,112.851Z" transform="translate(-160.9 -88.948)" fill="#e7e7e7"/>
      </g>
      <g id="Group_7452" data-name="Group 7452" transform="translate(65.809 210.155)">
        <path id="Path_9041" data-name="Path 9041" d="M98.65,287.651h-32.7A1.591,1.591,0,0,1,64.4,286.1v-7.347a1.591,1.591,0,0,1,1.552-1.552h32.7a1.591,1.591,0,0,1,1.552,1.552V286.1A1.591,1.591,0,0,1,98.65,287.651Z" transform="translate(-64.4 -277.2)" fill="#e7e7e7"/>
        <path id="Path_9042" data-name="Path 9042" d="M117.45,300.551h-32.7A1.591,1.591,0,0,1,83.2,299v-7.347a1.591,1.591,0,0,1,1.552-1.552h32.7A1.591,1.591,0,0,1,119,291.652V299A1.591,1.591,0,0,1,117.45,300.551Z" transform="translate(-63.747 -276.752)" fill="#e7e7e7"/>
      </g>
      <g id="Group_7454" data-name="Group 7454" transform="translate(288.278 14.486)">
        <g id="Group_7453" data-name="Group 7453">
          <path id="Path_9043" data-name="Path 9043" d="M313.65,98.551h-32.7A1.591,1.591,0,0,1,279.4,97V89.652a1.591,1.591,0,0,1,1.552-1.552h32.7a1.591,1.591,0,0,1,1.552,1.552V97A1.591,1.591,0,0,1,313.65,98.551Z" transform="translate(-279.4 -88.1)" fill="#e7e7e7"/>
          <path id="Path_9044" data-name="Path 9044" d="M351.35,98.551h-32.7A1.591,1.591,0,0,1,317.1,97V89.652a1.591,1.591,0,0,1,1.552-1.552h32.7a1.591,1.591,0,0,1,1.552,1.552V97A1.524,1.524,0,0,1,351.35,98.551Z" transform="translate(-278.09 -88.1)" fill="#e7e7e7"/>
        </g>
        <path id="Path_9045" data-name="Path 9045" d="M332.55,111.451h-32.7A1.591,1.591,0,0,1,298.3,109.9v-7.347A1.591,1.591,0,0,1,299.852,101h32.7a1.591,1.591,0,0,1,1.552,1.552V109.9A1.591,1.591,0,0,1,332.55,111.451Z" transform="translate(-278.743 -87.652)" fill="#e7e7e7"/>
      </g>
      <g id="Group_7455" data-name="Group 7455" transform="translate(380.68 18.004)">
        <rect id="Rectangle_3371" data-name="Rectangle 3371" width="72.742" height="176.837" fill="#f0f0f0"/>
        <rect id="Rectangle_3372" data-name="Rectangle 3372" width="63.843" height="167.938" transform="translate(4.346 4.449)" fill="#fafafa"/>
        <path id="Path_9046" data-name="Path 9046" d="M436.847,102.7v12.727L372.9,179.271V166.647Z" transform="translate(-368.554 -91.111)" fill="#f1f1f1"/>
        <path id="Path_9047" data-name="Path 9047" d="M436.847,145.1V163L372.9,226.844v-17.9Z" transform="translate(-368.554 -89.638)" fill="#f1f1f1"/>
        <rect id="Rectangle_3373" data-name="Rectangle 3373" width="4.449" height="176.837" transform="translate(34.146)" fill="#e7e7e7"/>
        <rect id="Rectangle_3374" data-name="Rectangle 3374" width="72.742" height="4.553" transform="translate(0 86.09)" fill="#e7e7e7"/>
      </g>
      <g id="Group_7456" data-name="Group 7456" transform="translate(65.809 18.004)">
        <rect id="Rectangle_3375" data-name="Rectangle 3375" width="72.742" height="176.837" fill="#f0f0f0"/>
        <rect id="Rectangle_3376" data-name="Rectangle 3376" width="63.843" height="167.938" transform="translate(4.449 4.449)" fill="#fafafa"/>
        <path id="Path_9048" data-name="Path 9048" d="M132.543,102.7v12.727L68.7,179.271V166.647Z" transform="translate(-64.251 -91.111)" fill="#f1f1f1"/>
        <path id="Path_9049" data-name="Path 9049" d="M132.543,145.1V163L68.7,226.844v-17.9Z" transform="translate(-64.251 -89.638)" fill="#f1f1f1"/>
        <rect id="Rectangle_3377" data-name="Rectangle 3377" width="4.346" height="176.837" transform="translate(34.146)" fill="#e7e7e7"/>
        <rect id="Rectangle_3378" data-name="Rectangle 3378" width="72.742" height="4.553" transform="translate(0 86.09)" fill="#e7e7e7"/>
      </g>
      <g id="Group_7486" data-name="Group 7486" transform="translate(270.258 214.707)">
        <g id="Group_7458" data-name="Group 7458" transform="translate(0 59.705)">
          <path id="Path_9050" data-name="Path 9050" d="M457.669,342.4l-5.795,19.35-.828,2.587-3,10.14a6.644,6.644,0,0,1-6.312,4.76h-163.7a6.644,6.644,0,0,1-6.312-4.76l-3-10.14-.828-2.587L262.1,342.4a2.4,2.4,0,0,1,2.276-3.1H455.393A2.442,2.442,0,0,1,457.669,342.4Z" transform="translate(-261.985 -339.3)" fill="#e7e7e7"/>
          <path id="Path_9051" data-name="Path 9051" d="M451.576,361l-.724,2.587H268.428L267.6,361Z" transform="translate(-261.79 -338.546)" fill="#e0e0e0"/>
          <g id="Group_7457" data-name="Group 7457" transform="translate(11.087 39.837)">
            <rect id="Rectangle_3379" data-name="Rectangle 3379" width="144.863" height="3.311" transform="translate(13.141 11.693)" fill="#d5d5d5"/>
            <path id="Path_9052" data-name="Path 9052" d="M292.774,377.8,272.7,411.325h8.8L306.432,377.8Z" transform="translate(-272.7 -377.8)" fill="#d5d5d5"/>
            <path id="Path_9053" data-name="Path 9053" d="M419.659,377.8l20.074,33.526h-8.8L406,377.8Z" transform="translate(-268.07 -377.8)" fill="#d5d5d5"/>
          </g>
        </g>
        <g id="Group_7485" data-name="Group 7485" transform="translate(140.083)">
          <g id="Group_7460" data-name="Group 7460" transform="translate(17.626 17.695)">
            <g id="Group_7459" data-name="Group 7459">
              <path id="Path_9054" data-name="Path 9054" d="M415.435,321.257v-22.04a.517.517,0,1,0-1.035,0v22.04Z" transform="translate(-414.4 -298.7)" fill="#e0e0e0"/>
            </g>
          </g>
          <g id="Group_7464" data-name="Group 7464" transform="translate(3.864 40.252)">
            <g id="Group_7463" data-name="Group 7463">
              <g id="Group_7462" data-name="Group 7462">
                <g id="Group_7461" data-name="Group 7461">
                  <path id="Path_9055" data-name="Path 9055" d="M401.721,322.88l.517,1.759.1.414,4.035,14.9h18l4.035-14.9.1-.414.517-1.759.1-.414.517-1.966H401.1l.517,1.966Z" transform="translate(-401.1 -320.5)" fill="#d5d5d5"/>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_7469" data-name="Group 7469" transform="translate(0 12.885)">
            <g id="Group_7466" data-name="Group 7466">
              <g id="Group_7465" data-name="Group 7465">
                <path id="Path_9056" data-name="Path 9056" d="M413.647,306.622A1.476,1.476,0,0,0,415.2,306c.1-.207.207-.31.207-.517v-.31a1.444,1.444,0,0,0-.31-.931c-1.966-2.38-11.9-13.865-16.556-9C393.055,301.034,408.266,305.277,413.647,306.622Z" transform="translate(-397.365 -294.052)" fill="#e7e7e7"/>
              </g>
            </g>
            <g id="Group_7468" data-name="Group 7468" transform="translate(7.382 5.284)">
              <g id="Group_7467" data-name="Group 7467">
                <path id="Path_9057" data-name="Path 9057" d="M404.6,299.719l10.347,6.1c.1-.207.207-.31.207-.517l-10.244-6.1c-.1-.1-.31,0-.414.1C404.5,299.512,404.5,299.719,404.6,299.719Z" transform="translate(-404.5 -299.159)" fill="#e0e0e0"/>
              </g>
            </g>
          </g>
          <g id="Group_7474" data-name="Group 7474" transform="translate(18.144 18.613)">
            <g id="Group_7471" data-name="Group 7471">
              <g id="Group_7470" data-name="Group 7470">
                <path id="Path_9058" data-name="Path 9058" d="M414.9,310.672v.31a.79.79,0,0,0,.207.517,1.476,1.476,0,0,0,1.552.621c5.381-1.345,20.591-5.588,15-11.382-4.656-4.76-14.59,6.622-16.556,9A2.471,2.471,0,0,0,414.9,310.672Z" transform="translate(-414.9 -299.587)" fill="#e7e7e7"/>
              </g>
            </g>
            <g id="Group_7473" data-name="Group 7473" transform="translate(0 5.248)">
              <g id="Group_7472" data-name="Group 7472">
                <path id="Path_9059" data-name="Path 9059" d="M414.9,310.806a.79.79,0,0,0,.207.517l10.347-6.1c.1-.1.207-.31.1-.414s-.31-.207-.414-.1Z" transform="translate(-414.9 -304.659)" fill="#e0e0e0"/>
              </g>
            </g>
          </g>
          <g id="Group_7479" data-name="Group 7479" transform="translate(16.576)">
            <g id="Group_7476" data-name="Group 7476">
              <g id="Group_7475" data-name="Group 7475">
                <path id="Path_9060" data-name="Path 9060" d="M413.814,299.708a1.86,1.86,0,0,0,.414.931c.1.1.207.1.207.207a1.748,1.748,0,0,0,.517.207,1.39,1.39,0,0,0,1.449-.724c2.794-4.863,10.14-18.832,2.069-18.729C411.952,281.5,413.4,296.6,413.814,299.708Z" transform="translate(-413.385 -281.599)" fill="#e7e7e7"/>
              </g>
            </g>
            <g id="Group_7478" data-name="Group 7478" transform="translate(1.154 7.347)">
              <g id="Group_7477" data-name="Group 7477">
                <path id="Path_9061" data-name="Path 9061" d="M414.5,300.5a1.748,1.748,0,0,0,.517.207l2.69-11.693c0-.207-.1-.31-.207-.31-.207,0-.31.1-.31.207Z" transform="translate(-414.5 -288.7)" fill="#e0e0e0"/>
              </g>
            </g>
          </g>
          <g id="Group_7484" data-name="Group 7484" transform="translate(0 25.095)">
            <g id="Group_7481" data-name="Group 7481">
              <g id="Group_7480" data-name="Group 7480">
                <path id="Path_9062" data-name="Path 9062" d="M413.647,318.422a1.476,1.476,0,0,0,1.552-.621c.1-.207.207-.31.207-.517v-.31a1.444,1.444,0,0,0-.31-.931c-1.966-2.38-11.9-13.865-16.556-9C393.055,312.731,408.266,317.077,413.647,318.422Z" transform="translate(-397.365 -305.852)" fill="#e7e7e7"/>
              </g>
            </g>
            <g id="Group_7483" data-name="Group 7483" transform="translate(7.382 5.284)">
              <g id="Group_7482" data-name="Group 7482">
                <path id="Path_9063" data-name="Path 9063" d="M404.6,311.519l10.347,6.1c.1-.207.207-.31.207-.517L404.914,311c-.1-.1-.31,0-.414.1C404.5,311.312,404.5,311.415,404.6,311.519Z" transform="translate(-404.5 -310.959)" fill="#e0e0e0"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Button_2" transform="translate(38.257 224.654)">
      <g id="Group_7488" data-name="Group 7488" transform="translate(0 236.024)">
        <g id="Group_7487" data-name="Group 7487">
          <path id="Path_9064" data-name="Path 9064" d="M483.489,454.219c0,3.622-99.956,6.519-223.193,6.519S37,457.84,37,454.219s99.956-6.519,223.193-6.519S483.489,450.6,483.489,454.219Z" transform="translate(-37 -447.7)" fill="#f5f5f5"/>
        </g>
      </g>
      <g id="Group_7491" data-name="Group 7491" transform="translate(92.506)">
        <path id="Path_9065" data-name="Path 9065" d="M316.378,306.518H169.859A43.454,43.454,0,0,1,126.4,263.059h0A43.454,43.454,0,0,1,169.859,219.6H316.378a43.454,43.454,0,0,1,43.459,43.459h0A43.388,43.388,0,0,1,316.378,306.518Z" transform="translate(-126.4 -219.6)" fill="#f68416"/>
        <path id="Path_9067" data-name="Path 9067" d="M169.755,303.31a40.355,40.355,0,1,1,0-80.71H316.274a40.355,40.355,0,1,1,0,80.71Z" transform="translate(-126.296 -219.496)" fill="#fff" opacity="0.5"/>
        <g id="Group_7489" data-name="Group 7489" transform="translate(4.967 4.346)">
          <circle id="Ellipse_339" data-name="Ellipse 339" cx="39.113" cy="39.113" r="39.113" fill="#f68416"/>
        </g>
        <g id="Group_7490" data-name="Group 7490" transform="translate(25.662 24.834)">
          <path id="Path_9068" data-name="Path 9068" d="M186.9,273.4a4.372,4.372,0,0,1,0,6.1,4.32,4.32,0,0,1-3,1.242,4.144,4.144,0,0,1-3-1.242l-11.279-11.279L158.443,279.4a4.32,4.32,0,0,1-3,1.242,4.144,4.144,0,0,1-3-1.242,4.372,4.372,0,0,1,0-6.1l11.175-11.175-11.175-11.175a4.317,4.317,0,1,1,6.1-6.1l11.175,11.175L180.9,244.842a4.317,4.317,0,0,1,6.1,6.1l-11.175,11.175Z" transform="translate(-151.2 -243.6)" fill="#fff"/>
        </g>
      </g>
    </g>
    <g id="Button_1" transform="translate(130.763 127.699)">
      <g id="Group_7493" data-name="Group 7493">
        <path id="Path_9069" data-name="Path 9069" d="M316.378,212.818H169.859A43.454,43.454,0,0,1,126.4,169.359h0A43.454,43.454,0,0,1,169.859,125.9H316.378a43.454,43.454,0,0,1,43.459,43.459h0A43.388,43.388,0,0,1,316.378,212.818Z" transform="translate(-126.4 -125.9)" fill="#f68416"/>
        <path id="Path_9070" data-name="Path 9070" d="M169.755,209.61a40.355,40.355,0,1,1,0-80.71H316.274a40.355,40.355,0,1,1,0,80.71Z" transform="translate(-126.296 -125.796)" opacity="0"/>
        <g id="Group_7492" data-name="Group 7492" transform="translate(46.667 3.105)">
          <circle id="Ellipse_340" data-name="Ellipse 340" cx="39.113" cy="39.113" r="39.113" transform="translate(0 0)" fill="#f68416"/>
          <circle id="Ellipse_341" data-name="Ellipse 341" cx="39.113" cy="39.113" r="39.113" transform="translate(0 0)" fill="#fff" opacity="0.7"/>
          <path id="Path_9071" data-name="Path 9071" d="M203.144,182.124h0a3.613,3.613,0,0,1-2.483-1.035l-9.727-9.727a3.585,3.585,0,0,1,5.07-5.07l7.14,7.14,20.8-20.8a3.585,3.585,0,1,1,5.07,5.07l-23.385,23.385A3.613,3.613,0,0,1,203.144,182.124Z" transform="translate(-170.86 -129.352)" fill="#f68416"/>
        </g>
      </g>
    </g>
    <g id="Plant" transform="translate(107.976 335.809)">
      <g id="Group_7537" data-name="Group 7537">
        <g id="Group_7495" data-name="Group 7495" transform="translate(33.652 125.489)">
          <g id="Group_7494" data-name="Group 7494">
            <rect id="Rectangle_3380" data-name="Rectangle 3380" width="14.383" height="6.415" fill="#333"/>
          </g>
        </g>
        <g id="Group_7497" data-name="Group 7497" transform="translate(12.854 90.515)">
          <g id="Group_7496" data-name="Group 7496">
            <path id="Path_9072" data-name="Path 9072" d="M172.779,414.5l-1.966,5.381-.414,1.138-.207.621-.31.931-1.242,3.518-.1.207-.207.621-.621,1.759-.724,2.069-.207.724v.1l-.31.931-1.759,4.76-.414,1.035-3.518,9.623-.31.724-.724,1.863a4.213,4.213,0,0,1-1.242,1.759,1.667,1.667,0,0,1-.724.414,4.336,4.336,0,0,1-1.552.414H133.459c-.414,0-.724-.1-1.035-.1-.31-.1-.517-.207-.828-.31a3.878,3.878,0,0,1-1.863-2.173l-2.794-7.761-.31-.931-1.966-5.484-.207-.621-.1-.31-.31-.931-1.966-5.381-.1-.31-.1-.31-.828-2.38-.414-1.035-.31-.828-.31-.828-.207-.621-.1-.207-.931-2.587v-.1l-.207-.621L116.8,414.5h55.979Z" transform="translate(-116.8 -414.5)" fill="#333"/>
          </g>
        </g>
        <path id="Path_9073" data-name="Path 9073" d="M170.754,419.7l-.31,1.138-.31.517-.31.931H119.431L118.5,419.8v-.1Z" transform="translate(-103.887 -323.804)" fill="#fff" opacity="0.2"/>
        <path id="Path_9074" data-name="Path 9074" d="M168.5,425.6l-.1.207-.207.621-.621,1.759H121.631l-.1-.207-.828-2.38Z" transform="translate(-103.811 -323.599)" fill="#fff" opacity="0.2"/>
        <g id="Group_7536" data-name="Group 7536">
          <g id="Group_7506" data-name="Group 7506" transform="translate(15.556 56.096)">
            <g id="Group_7499" data-name="Group 7499" transform="translate(7.542 2.949)">
              <g id="Group_7498" data-name="Group 7498">
                <path id="Path_9075" data-name="Path 9075" d="M126.7,391.861s7.967-2.9,9.933,5.484c2.069,8.381,7.967,6.208,9,4.346,2.173-3.622,2.483-16.763-1.242-17.487C139.945,383.273,128.252,388.032,126.7,391.861Z" transform="translate(-126.7 -384.086)" fill="#f68416"/>
              </g>
            </g>
            <g id="Group_7501" data-name="Group 7501" transform="translate(7.542 2.949)" opacity="0.5">
              <g id="Group_7500" data-name="Group 7500">
                <path id="Path_9076" data-name="Path 9076" d="M126.7,391.861s7.967-2.9,9.933,5.484c2.069,8.381,7.967,6.208,9,4.346,2.173-3.622,2.483-16.763-1.242-17.487C139.945,383.273,128.252,388.032,126.7,391.861Z" transform="translate(-126.7 -384.086)" fill="#333"/>
              </g>
            </g>
            <g id="Group_7503" data-name="Group 7503">
              <g id="Group_7502" data-name="Group 7502">
                <path id="Path_9077" data-name="Path 9077" d="M131.713,381.613s-7.967-2.587-10.968,5.794,0,28.973,0,28.973,6.933-2.173,9.106-9.209c2.276-7.036,1.966-22.661,14.9-22.868Z" transform="translate(-119.411 -381.236)" fill="#f68416"/>
              </g>
            </g>
            <g id="Group_7505" data-name="Group 7505" transform="translate(5.162 0.846)">
              <g id="Group_7504" data-name="Group 7504">
                <path id="Path_9078" data-name="Path 9078" d="M133.2,382.1c-6.933,4.035-8.071,12.624-8.8,19.867,0,.414.517.414.621,0,.724-7.036,1.759-15.418,8.485-19.453.31-.1,0-.621-.31-.414Z" transform="translate(-124.4 -382.053)" fill="#333" opacity="0.2"/>
              </g>
            </g>
          </g>
          <g id="Group_7515" data-name="Group 7515" transform="translate(35.588 1.043)">
            <g id="Group_7508" data-name="Group 7508" transform="translate(0 2.383)">
              <g id="Group_7507" data-name="Group 7507">
                <path id="Path_9079" data-name="Path 9079" d="M158.875,338.784s-7.761-3.208-10.14,5.07-7.967,7.657-9,4.139c-1.138-4.035-1.966-16.97,1.759-17.591C146.044,329.678,157.426,334.955,158.875,338.784Z" transform="translate(-138.771 -330.335)" fill="#f68416"/>
              </g>
            </g>
            <g id="Group_7510" data-name="Group 7510" transform="translate(0 2.383)" opacity="0.5">
              <g id="Group_7509" data-name="Group 7509">
                <path id="Path_9080" data-name="Path 9080" d="M158.875,338.784s-7.761-3.208-10.14,5.07-7.967,7.657-9,4.139c-1.138-4.035-1.966-16.97,1.759-17.591C146.044,329.678,157.426,334.955,158.875,338.784Z" transform="translate(-138.771 -330.335)" fill="#333"/>
              </g>
            </g>
            <g id="Group_7512" data-name="Group 7512" transform="translate(2.72)">
              <g id="Group_7511" data-name="Group 7511">
                <path id="Path_9081" data-name="Path 9081" d="M154.541,328.309s8.071-2.276,10.761,6.208-1.138,28.973-1.138,28.973-6.829-2.483-8.8-9.623S154.334,331.1,141.4,330.482Z" transform="translate(-141.4 -328.032)" fill="#f68416"/>
              </g>
            </g>
            <g id="Group_7514" data-name="Group 7514" transform="translate(13.372 0.635)" opacity="0.2">
              <g id="Group_7513" data-name="Group 7513">
                <path id="Path_9082" data-name="Path 9082" d="M151.8,329.116c7.347,5.795,8.588,15.625,9.52,24.316a.31.31,0,0,0,.621,0c-.931-8.8-2.173-18.832-9.727-24.73-.31-.207-.724.207-.414.414Z" transform="translate(-151.694 -328.645)" fill="#333"/>
              </g>
            </g>
          </g>
          <g id="Group_7524" data-name="Group 7524" transform="translate(42.108 38.232)">
            <g id="Group_7517" data-name="Group 7517" transform="translate(0 4.36)">
              <g id="Group_7516" data-name="Group 7516">
                <path id="Path_9083" data-name="Path 9083" d="M166.934,375.961s-4.863-1.449-9.933,5.484c-4.967,6.726-6.829,7.45-10.244,4.863-3.311-2.587-1.345-17.28,2.483-18C153.689,367.373,165.382,372.132,166.934,375.961Z" transform="translate(-145.073 -368.186)" fill="#f68416"/>
              </g>
            </g>
            <g id="Group_7519" data-name="Group 7519" transform="translate(0 4.36)" opacity="0.5">
              <g id="Group_7518" data-name="Group 7518">
                <path id="Path_9084" data-name="Path 9084" d="M166.934,375.961s-4.863-1.449-9.933,5.484c-4.967,6.726-6.829,7.45-10.244,4.863-3.311-2.587-1.345-17.28,2.483-18C153.689,367.373,165.382,372.132,166.934,375.961Z" transform="translate(-145.073 -368.186)" fill="#333"/>
              </g>
            </g>
            <g id="Group_7521" data-name="Group 7521" transform="translate(3.857)">
              <g id="Group_7520" data-name="Group 7520">
                <path id="Path_9085" data-name="Path 9085" d="M166.8,364.518s11.382-3.725,15.728,8.278c4.242,12,0,41.389,0,41.389s-9.83-3.1-13.038-13.245-2.38-32.18-20.695-32.387C152.422,367.519,159.561,365.967,166.8,364.518Z" transform="translate(-148.8 -363.972)" fill="#f68416"/>
              </g>
            </g>
            <g id="Group_7523" data-name="Group 7523" transform="translate(18.525 1.326)" opacity="0.2">
              <g id="Group_7522" data-name="Group 7522">
                <path id="Path_9086" data-name="Path 9086" d="M163.1,365.819a28.542,28.542,0,0,1,15.211,25.144.31.31,0,0,0,.621,0A29.111,29.111,0,0,0,163.415,365.3c-.31-.207-.621.31-.31.517Z" transform="translate(-162.976 -365.254)" fill="#333"/>
              </g>
            </g>
          </g>
          <g id="Group_7533" data-name="Group 7533" transform="translate(0 18.779)">
            <g id="Group_7526" data-name="Group 7526" transform="translate(17.51 4.178)">
              <g id="Group_7525" data-name="Group 7525">
                <path id="Path_9087" data-name="Path 9087" d="M121.3,357.167s7.967-2.9,9.933,5.484c2.069,8.381,12.417,11.589,13.452,7.45,1.138-4.346-1.345-19.97-5.174-20.8C135.062,348.475,122.956,353.339,121.3,357.167Z" transform="translate(-121.3 -349.21)" fill="#f68416"/>
              </g>
            </g>
            <g id="Group_7528" data-name="Group 7528" transform="translate(17.51 4.178)" opacity="0.5">
              <g id="Group_7527" data-name="Group 7527">
                <path id="Path_9088" data-name="Path 9088" d="M121.3,357.167s7.967-2.9,9.933,5.484c2.069,8.381,12.417,11.589,13.452,7.45,1.138-4.346-1.345-19.97-5.174-20.8C135.062,348.475,122.956,353.339,121.3,357.167Z" transform="translate(-121.3 -349.21)" fill="#333"/>
              </g>
            </g>
            <g id="Group_7530" data-name="Group 7530">
              <g id="Group_7529" data-name="Group 7529">
                <path id="Path_9089" data-name="Path 9089" d="M121.991,345.718s-11.382-3.725-15.728,8.278c-4.242,12,0,41.389,0,41.389s9.83-3.1,13.038-13.245,2.9-32.387,21.212-32.594Z" transform="translate(-104.378 -345.172)" fill="#f68416"/>
              </g>
            </g>
            <g id="Group_7532" data-name="Group 7532" transform="translate(4.668 1.581)">
              <g id="Group_7531" data-name="Group 7531">
                <path id="Path_9090" data-name="Path 9090" d="M126.387,346.7c-4.967.207-9.209,3.311-11.9,7.347-3.415,4.863-4.553,10.761-5.588,16.452-.1.414.517.517.621.207,1.035-5.381,2.069-10.968,5.07-15.625,2.587-4.139,6.933-7.554,11.9-7.761.31,0,.31-.621-.1-.621Z" transform="translate(-108.889 -346.7)" fill="#333" opacity="0.2"/>
              </g>
            </g>
          </g>
          <g id="Group_7535" data-name="Group 7535" transform="translate(28.089)">
            <g id="Group_7534" data-name="Group 7534">
              <path id="Path_9091" data-name="Path 9091" d="M143.606,417.539h1.966c5.07-21.419,1.656-44.39-2.173-60.015a191.745,191.745,0,0,0-9.933-29.9,1.019,1.019,0,0,0-1.863.828,172.343,172.343,0,0,1,9.83,29.594C145.365,373.562,148.779,396.327,143.606,417.539Z" transform="translate(-131.523 -327.023)" fill="#f68416"/>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Character" transform="translate(247.558 112.321)">
      <g id="Group_7608" data-name="Group 7608">
        <g id="Group_7601" data-name="Group 7601" transform="translate(89.738)">
          <g id="Group_7560" data-name="Group 7560" transform="translate(1.608 150.618)">
            <g id="Group_7559" data-name="Group 7559" transform="translate(4.704 178.182)">
              <g id="Group_7540" data-name="Group 7540" transform="translate(13.038)">
                <g id="Group_7539" data-name="Group 7539">
                  <g id="Group_7538" data-name="Group 7538">
                    <path id="Path_9092" data-name="Path 9092" d="M355.565,428.8v18l-7.347.517L344.7,430.559Z" transform="translate(-344.7 -428.8)" fill="#ff8b7b"/>
                  </g>
                </g>
              </g>
              <g id="Group_7558" data-name="Group 7558" transform="translate(0 12.906)">
                <g id="Group_7542" data-name="Group 7542">
                  <g id="Group_7541" data-name="Group 7541">
                    <path id="Path_9093" data-name="Path 9093" d="M350.829,445.233s-2.276-4.346-3.622-3.932c-1.345.517-1.863,5.795-1.863,5.795s-7.347,2.483-9.313,2.587c-2.069.1-3.725,1.242-3.932,5.277h24.937s-.207-9-.207-10.554-2.276.414-2.276.414Z" transform="translate(-332.1 -441.273)" fill="#263238"/>
                  </g>
                </g>
                <g id="Group_7545" data-name="Group 7545" transform="translate(22.208 6.215)">
                  <g id="Group_7544" data-name="Group 7544">
                    <g id="Group_7543" data-name="Group 7543">
                      <path id="Path_9094" data-name="Path 9094" d="M355.878,447.611a3.716,3.716,0,0,0-1.345,5.277c.1.207-.207.414-.31.207a4.06,4.06,0,0,1,1.449-5.795c.207-.1.414.207.207.31Z" transform="translate(-353.563 -447.28)" fill="#fff"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7548" data-name="Group 7548" transform="translate(0.321 11.824)">
                  <g id="Group_7547" data-name="Group 7547">
                    <g id="Group_7546" data-name="Group 7546">
                      <path id="Path_9095" data-name="Path 9095" d="M356.716,453.114c-6.519.1-13.038.1-19.557.207h-4.553c-.207,0-.31-.414,0-.414,6.519-.1,13.038-.1,19.557-.207h4.553c.207,0,.207.414,0,.414Z" transform="translate(-332.411 -452.7)" fill="#fff"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7551" data-name="Group 7551" transform="translate(10.365 5.868)">
                  <g id="Group_7550" data-name="Group 7550">
                    <g id="Group_7549" data-name="Group 7549">
                      <path id="Path_9096" data-name="Path 9096" d="M346.031,448.451c-1.035-1.242-2.483-1.552-3.622-.621-.207.1-.414-.207-.207-.31,1.242-1.035,3-.621,4.242.828.1.1-.207.31-.414.1Z" transform="translate(-342.117 -446.944)" fill="#fff"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7554" data-name="Group 7554" transform="translate(8.296 6.593)">
                  <g id="Group_7553" data-name="Group 7553">
                    <g id="Group_7552" data-name="Group 7552">
                      <path id="Path_9097" data-name="Path 9097" d="M344.031,449.151c-1.035-1.242-2.483-1.552-3.622-.621-.207.1-.414-.207-.207-.31,1.242-1.035,3-.621,4.242.828.1.1-.207.31-.414.1Z" transform="translate(-340.117 -447.645)" fill="#fff"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7557" data-name="Group 7557" transform="translate(6.123 7.214)">
                  <g id="Group_7556" data-name="Group 7556">
                    <g id="Group_7555" data-name="Group 7555">
                      <path id="Path_9098" data-name="Path 9098" d="M341.931,449.751c-1.035-1.242-2.483-1.552-3.622-.621-.207.1-.414-.207-.207-.31,1.242-1.035,3-.621,4.242.828.1.1-.207.31-.414.1Z" transform="translate(-338.017 -448.245)" fill="#fff"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path id="Path_9099" data-name="Path 9099" d="M360.506,256.6c-.207,3.208.207,13.038-.207,18.315-.828,10.037-1.863,23.178-2.794,36.216-1.035,15.107-1.759,30.318-1.345,40.872.931,27.007.724,88.367.724,88.367H345.192S331.43,379.423,328.843,352c-2.173-22.764-1.035-81.33-.621-95.4h32.284Z" transform="translate(-327.554 -256.6)" fill="#f68416"/>
            <path id="Path_9100" data-name="Path 9100" d="M360.506,256.6c-.207,3.208.207,13.038-.207,18.315-.828,10.037-1.863,23.178-2.794,36.216-1.035,15.107-1.759,30.318-1.345,40.872.931,27.007.724,88.367.724,88.367H345.192S331.43,379.423,328.843,352c-2.173-22.764-1.035-81.33-.621-95.4h32.284Z" transform="translate(-327.554 -256.6)" fill="#fff" opacity="0.3"/>
            <path id="Path_9101" data-name="Path 9101" d="M359.384,274.626c-.828,10.037-1.863,23.178-2.794,36.216-5.795-17.073-.414-42.942-.414-42.942Z" transform="translate(-326.638 -256.207)" fill="#333" opacity="0.2"/>
            <path id="Path_9102" data-name="Path 9102" d="M357.293,434.853H343.531L342.6,430.4l15.107-.1Z" transform="translate(-327.031 -250.566)" fill="#f68416"/>
          </g>
          <g id="Group_7583" data-name="Group 7583" transform="translate(28.145 149.17)">
            <g id="Group_7582" data-name="Group 7582" transform="translate(47.039 176.319)">
              <g id="Group_7581" data-name="Group 7581">
                <g id="Group_7563" data-name="Group 7563" transform="translate(9.355)">
                  <g id="Group_7562" data-name="Group 7562">
                    <g id="Group_7561" data-name="Group 7561">
                      <path id="Path_9103" data-name="Path 9103" d="M418.151,425.6l2.69,17.694-7.036,1.759-6.1-16.142Z" transform="translate(-407.7 -425.6)" fill="#ff8b7b"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7565" data-name="Group 7565" transform="translate(0 14.006)">
                  <g id="Group_7564" data-name="Group 7564">
                    <path id="Path_9104" data-name="Path 9104" d="M415.878,442.513s-2.9-3.932-4.139-3.311-1.035,6-1.035,6-6.829,3.518-8.9,3.932c-1.966.414-3.415,1.863-3.1,5.795l24.73-3.829s-1.552-8.9-1.863-10.451-2.173.724-2.173.724Z" transform="translate(-398.659 -439.136)" fill="#263238"/>
                  </g>
                </g>
                <g id="Group_7568" data-name="Group 7568" transform="translate(21.554 18.708)">
                  <g id="Group_7567" data-name="Group 7567">
                    <g id="Group_7566" data-name="Group 7566">
                      <path id="Path_9105" data-name="Path 9105" d="M421.363,444.115a3.689,3.689,0,0,0-.517,5.381c.207.207-.1.414-.31.207a4.154,4.154,0,0,1,.517-6c.207-.1.517.207.31.414Z" transform="translate(-419.49 -443.68)" fill="#fff"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7571" data-name="Group 7571" transform="translate(0.298 24.316)">
                  <g id="Group_7570" data-name="Group 7570">
                    <g id="Group_7569" data-name="Group 7569">
                      <path id="Path_9106" data-name="Path 9106" d="M423.008,449.514c-6.415,1.035-12.934,2.173-19.35,3.208-1.449.207-3,.517-4.449.724-.207,0-.414-.31-.1-.414,6.415-1.035,12.934-2.173,19.35-3.208,1.449-.207,3-.517,4.449-.724.207,0,.31.414.1.414Z" transform="translate(-398.947 -449.1)" fill="#fff"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7574" data-name="Group 7574" transform="translate(9.327 20.264)">
                  <g id="Group_7573" data-name="Group 7573">
                    <g id="Group_7572" data-name="Group 7572">
                      <path id="Path_9107" data-name="Path 9107" d="M411.736,446.442c-1.242-1.035-2.69-1.138-3.725-.1-.207.207-.414-.1-.31-.31,1.138-1.242,2.9-1.035,4.242.1.31.207,0,.517-.207.31Z" transform="translate(-407.673 -445.184)" fill="#fff"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7577" data-name="Group 7577" transform="translate(7.361 21.195)">
                  <g id="Group_7576" data-name="Group 7576">
                    <g id="Group_7575" data-name="Group 7575">
                      <path id="Path_9108" data-name="Path 9108" d="M409.836,447.342c-1.242-1.035-2.69-1.138-3.725-.1-.207.207-.414-.1-.31-.31,1.138-1.242,2.9-1.035,4.242.1.31.207,0,.517-.207.31Z" transform="translate(-405.773 -446.084)" fill="#fff"/>
                    </g>
                  </g>
                </g>
                <g id="Group_7580" data-name="Group 7580" transform="translate(5.395 22.127)">
                  <g id="Group_7579" data-name="Group 7579">
                    <g id="Group_7578" data-name="Group 7578">
                      <path id="Path_9109" data-name="Path 9109" d="M407.936,448.242c-1.242-1.035-2.69-1.138-3.725-.1-.207.207-.414-.1-.31-.31,1.138-1.242,2.9-1.035,4.242.1.207.31,0,.517-.207.31Z" transform="translate(-403.873 -446.984)" fill="#fff"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path id="Path_9110" data-name="Path 9110" d="M378.655,354.535c7.243,26.593,31.663,84.021,31.663,84.021l11.589-1.966s-12-59.808-17.591-86.3c-4.967-23.9-10.554-78.744-12.313-95.092l-38.8,3.518c2.069,13.865,19.453,73.777,25.454,95.817Z" transform="translate(-353.2 -255.2)" fill="#f68416"/>
            <path id="Path_9111" data-name="Path 9111" d="M378.655,354.535c7.243,26.593,31.663,84.021,31.663,84.021l11.589-1.966s-12-59.808-17.591-86.3c-4.967-23.9-10.554-78.744-12.313-95.092l-38.8,3.518c2.069,13.865,19.453,73.777,25.454,95.817Z" transform="translate(-353.2 -255.2)" fill="#fff" opacity="0.3"/>
            <path id="Path_9112" data-name="Path 9112" d="M421.114,430.853l-13.555,2.276L405.8,428.99l15.107-2.69Z" transform="translate(-351.373 -249.256)" fill="#f68416"/>
          </g>
          <path id="Path_9113" data-name="Path 9113" d="M395.224,258.6a3.988,3.988,0,0,1-3.415,3.829l-61.877,2.9c-1.863.1-3.932-1.138-3.932-3,.621-21.316,5.588-94.782,7.243-99.438,2.069-5.691,13.555-7.761,18.522-8.174a200.109,200.109,0,0,1,23.7-.1c3.829.207,7.347.517,10.347.931a7.864,7.864,0,0,1,6.829,9.106,227.954,227.954,0,0,0-2.587,24.73,3.879,3.879,0,0,1-.1,1.138c-.1,2.794-.31,5.588-.31,8.278v1.656C390.05,227.973,393.672,241.528,395.224,258.6Z" transform="translate(-326 -109.535)" fill="#333"/>
          <path id="Path_9114" data-name="Path 9114" d="M388.413,188.569a3.878,3.878,0,0,1-.1,1.138c-.1,2.794-.31,5.588-.31,8.278L376.1,174.6Z" transform="translate(-324.26 -108.83)" opacity="0.2"/>
          <g id="Group_7600" data-name="Group 7600" transform="translate(7.14)">
            <g id="Group_7599" data-name="Group 7599">
              <g id="Group_7596" data-name="Group 7596">
                <g id="Group_7595" data-name="Group 7595">
                  <g id="Group_7591" data-name="Group 7591">
                    <path id="Path_9115" data-name="Path 9115" d="M355.768,114.31s-10.761-5.07-15.211-2.587c-3.518,1.966-1.138,6-1.138,6s-6.519.207-6.519,4.346a3.7,3.7,0,0,0,3.518,3.725s-2.276,4.035.207,4.967c2.587.931,5.588-4.346,5.588-4.346Z" transform="translate(-332.9 -111.038)" fill="#263238"/>
                    <path id="Path_9116" data-name="Path 9116" d="M366.142,134.3s2.794,4.035,2.276,5.174c-.828,1.656-3.1-.207-3.1-.207s2.069,2.794,0,4.139-3.415-5.484-3.415-5.484Z" transform="translate(-331.893 -110.23)" fill="#263238"/>
                    <path id="Path_9117" data-name="Path 9117" d="M350.664,114.718a16.188,16.188,0,0,1,12.934,2.069c2.483,1.656,4.553,3.932,4.139,7.14-.828,7.347-4.139,19.35-4.139,19.35l-23.592-14.383s-1.035-6.208,1.966-10.037C343.627,116.787,347.456,115.545,350.664,114.718Z" transform="translate(-332.659 -110.929)" fill="#263238"/>
                    <g id="Group_7584" data-name="Group 7584" transform="translate(19.246 27.795)">
                      <path id="Path_9118" data-name="Path 9118" d="M351.5,150.524h0l1.138,4.346a21.632,21.632,0,0,1,.1,2.587,7.17,7.17,0,0,1-.31,2.069c-.931,1.242-1.345,4.139,1.966,4.139,4.139,0,11.8-6.1,12-7.967-1.966-5.691-3.622-12.313-3.311-17.8l-4.76,5.174Z" transform="translate(-351.5 -137.9)" fill="#ff8b7b"/>
                    </g>
                    <g id="Group_7585" data-name="Group 7585" transform="translate(19.35 34.107)" opacity="0.2">
                      <path id="Path_9119" data-name="Path 9119" d="M351.6,150.312l1.138,4.242c2.587-1.242,5.277-3.829,5.9-6.622.414-1.966-.517-3.829-1.345-3.932Z" transform="translate(-351.6 -144)"/>
                    </g>
                    <g id="Group_7586" data-name="Group 7586" transform="translate(7.201 6.23)">
                      <path id="Path_9120" data-name="Path 9120" d="M341.35,142.556a20.442,20.442,0,0,0,.828,2.69c2.69,7.864,12.417,8.485,18.418,2.69h0c.517-.517,1.035-1.138,1.552-1.759,3.829-5.07,2.794-9,2.173-17.8a12.019,12.019,0,0,0-18.108-9.623C338.867,122.9,338.867,133.347,341.35,142.556Z" transform="translate(-339.86 -117.059)" fill="#ff8b7b"/>
                    </g>
                    <g id="Group_7587" data-name="Group 7587" transform="translate(13.291 29.008)">
                      <path id="Path_9139" data-name="Path 9139" d="M.67,6.4a.3.3,0,0,1-.042-.6A20.2,20.2,0,0,0,7.89,3.168a.3.3,0,1,1,.337.5A20.421,20.421,0,0,1,.712,6.4.3.3,0,0,1,.67,6.4Z" transform="translate(-2 -1)" fill="#263238"/>
                    </g>
                    <path id="Path_9122" data-name="Path 9122" d="M364.406,129.257s-2.69.1-2.794,2.38.1,2.587.1,2.587l-3.1.724s.828-8.692-1.449-8.588.207-3.829-1.242-4.346c-3.208-1.345-12.106-1.345-15.521,4.035,0,0,.828-5.277,5.07-8.071,4.139-2.9,12.624-3.518,16.038.621C365.027,122.634,364.406,129.257,364.406,129.257Z" transform="translate(-332.639 -110.878)" fill="#263238"/>
                    <g id="Group_7588" data-name="Group 7588" transform="translate(28.119 18.279)">
                      <path id="Path_9123" data-name="Path 9123" d="M368.172,133.253a8.326,8.326,0,0,1-3.311,5.484c-2.38,1.759-4.553-.207-4.76-2.9-.207-2.483.828-6.415,3.622-7.036a3.727,3.727,0,0,1,4.449,4.449" transform="translate(-360.075 -128.704)" fill="#ff8b7b"/>
                    </g>
                    <g id="Group_7589" data-name="Group 7589" transform="translate(16.95 22.401)">
                      <path id="Path_9124" data-name="Path 9124" d="M350.956,133.839c.1.724-.207,1.345-.621,1.345-.414.1-.931-.414-1.035-1.138s.207-1.345.621-1.345c.517-.1.931.414,1.035,1.138" transform="translate(-349.281 -132.687)" fill="#263238"/>
                    </g>
                    <g id="Group_7590" data-name="Group 7590" transform="translate(9.396 23.332)">
                      <path id="Path_9125" data-name="Path 9125" d="M343.656,134.739c.1.724-.207,1.345-.621,1.345-.414.1-.931-.414-1.035-1.138s.207-1.345.621-1.345c.517-.1.931.414,1.035,1.138" transform="translate(-341.981 -133.587)" fill="#263238"/>
                    </g>
                  </g>
                  <g id="Group_7592" data-name="Group 7592" transform="translate(7.72 19.747)">
                    <path id="Path_9126" data-name="Path 9126" d="M340.815,132.169c.1,0,.207-.1.31-.207a1.57,1.57,0,0,1,.931-1.035,2.013,2.013,0,0,1,1.449,0c.207.1.414,0,.414-.207.1-.207,0-.414-.207-.414a2.819,2.819,0,0,0-1.966,0,2.348,2.348,0,0,0-1.345,1.449c-.1.207,0,.414.207.414Z" transform="translate(-340.361 -130.122)" fill="#263238"/>
                  </g>
                  <g id="Group_7593" data-name="Group 7593" transform="translate(16.695 18.653)">
                    <path id="Path_9127" data-name="Path 9127" d="M352.62,130.86a.1.1,0,0,0,.1-.1c.207-.1.207-.31.1-.517a2.913,2.913,0,0,0-1.656-1.138,2.488,2.488,0,0,0-1.966.517c-.207.1-.207.31-.1.517s.31.207.517.1a1.853,1.853,0,0,1,1.345-.414,2.166,2.166,0,0,1,1.242.828C352.413,130.86,352.517,130.86,352.62,130.86Z" transform="translate(-349.034 -129.065)" fill="#263238"/>
                  </g>
                  <g id="Group_7594" data-name="Group 7594" transform="translate(11.793 23.139)">
                    <path id="Path_9128" data-name="Path 9128" d="M346.473,133.4a54.855,54.855,0,0,0-2.173,5.9c-.1.828,2.483.828,2.483.828Z" transform="translate(-344.297 -133.4)" fill="#ff5652"/>
                  </g>
                </g>
              </g>
              <g id="Group_7598" data-name="Group 7598" transform="translate(19.97 43.833)">
                <g id="Group_7597" data-name="Group 7597">
                  <path id="Path_9129" data-name="Path 9129" d="M352.2,153.4Z" transform="translate(-352.2 -153.4)"/>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Group_7603" data-name="Group 7603" transform="translate(139.248 45.923)">
          <path id="Path_9130" data-name="Path 9130" d="M390.665,159.124c4.863,6.519,9.623,13.245,14.176,19.97,2.276,3.415,4.553,6.829,6.829,10.14l3.415,5.07,1.656,2.483.828,1.242.414.517a1.428,1.428,0,0,0,.31.414l.207.414.207.31.31.621.207.517a24.376,24.376,0,0,1,1.242,3.518c.31,1.138.621,2.173.931,3.208q.931,3.1,1.552,6.208c.931,4.035,1.863,8.174,2.69,12.21,1.656,8.071,3.1,16.245,4.139,24.42a4.627,4.627,0,0,1-8.9,2.276h0c-2.9-7.657-5.691-15.314-8.485-22.971-1.449-3.829-2.794-7.657-4.139-11.382-.724-1.863-1.449-3.725-2.173-5.484a19.538,19.538,0,0,0-1.138-2.587,14.382,14.382,0,0,0-1.035-2.173,1984.872,1984.872,0,0,1-.207-.207h0v.1l.1.1c.1.1.1.207.207.207h0l-.1-.207-.31-.31-.517-.724-1.966-2.483-3.829-4.863c-2.483-3.311-5.07-6.519-7.45-9.83-4.967-6.519-9.727-13.141-14.383-19.97a9.321,9.321,0,0,1,2.483-12.934,9.53,9.53,0,0,1,12.727,2.173Z" transform="translate(-373.848 -155.419)" fill="#333"/>
          <g id="Group_7602" data-name="Group 7602" transform="translate(45.803 96.188)">
            <path id="Path_9131" data-name="Path 9131" d="M420.376,250.9s-2.794,9.313-2.173,11.279c.724,1.966,2.9-2.069,2.9-2.069Z" transform="translate(-418.113 -248.29)" fill="#ff8b7b"/>
            <path id="Path_9132" data-name="Path 9132" d="M428.586,248.608l3.829,8.692c.931,2.483-6.933,7.243-10.347,5.484-2.794-1.449-1.863-12.106-1.863-12.106A9.366,9.366,0,0,1,428.586,248.608Z" transform="translate(-418.045 -248.378)" fill="#ff8b7b"/>
            <path id="Path_9133" data-name="Path 9133" d="M432.379,257c1.035,2.794,1.345,8.485-3.208,9.623a9.718,9.718,0,0,1-3,.414s.207,2.483-1.035,2.9c-1.345.414-4.035-8.692-4.035-8.692Z" transform="translate(-418.009 -248.079)" fill="#ff8b7b"/>
          </g>
        </g>
        <path id="Path_9134" data-name="Path 9134" d="M347.583,175.752q-6.674,4.035-13.659,7.761c-1.552.828-3,1.552-4.553,2.38-1.345.724-2.69,1.345-4.035,1.966-3.829,1.863-7.657,3.725-11.693,5.381-1.966.828-4.035,1.656-6.312,2.483-.517.207-1.242.414-1.863.621l-1.138.31-.828.207-.414.1h-.207l-.517.1a3.192,3.192,0,0,1-.931.1h-.621a2.883,2.883,0,0,1-1.035-.1c-.31,0-.517-.1-.828-.1s-.517-.1-.724-.1a11.182,11.182,0,0,1-2.173-.517,69.492,69.492,0,0,1-6.622-2.38c-2.069-.828-4.035-1.656-6-2.587q-5.9-2.639-11.486-5.588c-7.554-3.829-14.9-7.864-22.143-12.313l3-6.829c8.071,2.276,16.038,4.76,24.006,7.243,3.932,1.242,7.967,2.483,11.8,3.518l5.795,1.656a36.158,36.158,0,0,0,5.174,1.138h.1a9.613,9.613,0,0,1,1.035-.517c1.552-.724,3.311-1.759,5.07-2.69,3.518-1.966,7.14-3.932,10.658-5.9,7.243-4.035,14.486-8.071,21.833-11.8Z" transform="translate(-238.909 -109.362)" fill="#333"/>
        <path id="Path_9135" data-name="Path 9135" d="M332.288,171.2l-.931,11.589a.38.38,0,0,1-.1.31c-1.552.828-3,1.552-4.553,2.38Z" transform="translate(-236.237 -108.948)" opacity="0.2"/>
        <g id="Group_7607" data-name="Group 7607" transform="translate(0 42.474)">
          <g id="Group_7606" data-name="Group 7606">
            <path id="Path_9136" data-name="Path 9136" d="M250.062,174.037c3,.517,7.036-1.138,4.863-6.519,0,0-1.138-3-4.656-9.83-1.449-2.794-7.45-3.932-7.45-3.932s-3.1-2.69-3.518-1.242,4.139,4.967,4.656,6c1.759,3.208,3.622,6.519,3.518,7.036-.1.621-2.276-4.035-4.139-4.449s1.863,4.967,2.276,7.14C245.923,170.519,248.717,173.726,250.062,174.037Z" transform="translate(-239.274 -152.086)" fill="#ff8b7b"/>
            <g id="Group_7604" data-name="Group 7604" transform="translate(3.918 1.953)">
              <path id="Path_9137" data-name="Path 9137" d="M243.308,154a14.167,14.167,0,0,1,4.863,5.588c.1.207-.207.31-.31.207a12.889,12.889,0,0,0-4.76-5.484c-.1-.207,0-.414.207-.31Z" transform="translate(-243.061 -153.973)" opacity="0.2"/>
            </g>
            <g id="Group_7605" data-name="Group 7605" transform="translate(6.476 2.987)">
              <path id="Path_9138" data-name="Path 9138" d="M248.086,158a8.08,8.08,0,0,0-2.483-2.794c-.207-.1.1-.31.207-.207a9.5,9.5,0,0,1,2.483,2.9c.207.1-.1.31-.207.1Z" transform="translate(-245.533 -154.973)" opacity="0.2"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

            </div>
            <h4>Your subscription was cancelled. Please contact your organization admin.</h4>
        </div>
    </div>

    );
};

export default SubscriptionCancelled;
