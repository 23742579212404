import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLearnerLearningPathNodes } from "../../../services";
import { useHistory } from "react-router-dom";
import LearnerModulePopView from "../learnermodulepopview";
import LearnerCoursePopView from "../learnercoursepopview";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

function NodsListing({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = match.params.id;
  const response = useSelector(
    (state) => state?.learningpath_learner?.learner_lp_nodes?.response
  );
  const loading = useSelector(
    (state) => state?.learningpath_learner?.learner_lp_nodes?.loading
  );
  const [state, setState] = useState({
    nodes: [],
    stats: {},
    popupView: false,
    nodeId: "",
    nodeType: "",
    count: 0,
    nodeName: "",
    coursePopUp: false,
  });

  useEffect(() => {
    dispatch(getLearnerLearningPathNodes(id, function (res) {}));
  }, []);

  useEffect(() => {

    setState({
      ...state,
      nodes: response?.result,
      stats: response?.assignment_details,
    });
  }, [response]);

  const handleModalHide = () => {
    dispatch(
      getLearnerLearningPathNodes(id, function (res) {
        setState({
          ...state,
          popupView: false,
          nodeId: "",
          nodeType: "",
          coursePopUp: false,
        });
      })
    );
  };
  const handleNode = (node, type) => {

    setState({
      ...state,
      nodeId: node.id,
      nodeName: node?.node_object_name,
      [type]: true,
      nodeType: node?.node_type,
    });
  };
  const checkPercentage = (course) => {
    let percentage =
      (course?.completed_modules_count / course?.total_course_modules) * 100;
    return percentage;
  };
  const handleButtonText = (el) => {
    if (el?.has_completed) {
      return "Completed";
    } else if (!el?.has_completed && el?.completed_modules_count > 0) {
      return "Continue";
    } else if (el?.available_on) {
      // For past dates
      if (
        moment(el?.available_on, "YYYY-MM-DD").isBefore(
          moment(new Date(), "YYYY-MM-DD"),
          "day"
        )
      ) {
        return "Start";
      } else if (
        moment(el?.available_on, "YYYY-MM-DD").isSame(
          moment(new Date(), "YYYY-MM-DD"),
          "day"
        )
      ) {
        return "Start";
      } else if (
        moment(el?.available_on, "YYYY-MM-DD").isAfter(
          moment(new Date(), "YYYY-MM-DD"),
          "day"
        )
      ) {
        return `${el?.available_on?.split("-")?.reverse()?.join("/")}`;
      }
    } else {
      return "Start";
    }
  };
  const checkDisabilty = (value) => {
    if (value == "Start" || value == "Continue") {
      return false;
    } else {
      return true;
    }
  };
  if (!loading) {
    return (
      <div className="node-listing-main-wrap">
        <div className="nodes-count-section">
          {/*<h3 className="tab-title m-b-30">Nodes</h3>*/}
          <div className="nodes-count-box-wrap d-flex">
            <div className="node-count-box d-flex">
              <div className="node-count-item">
                <span className="nodes-count">
                  {Math.floor(state?.stats?.completion_percentage)}%
                </span>
                <p>Learning Path Completion</p>
              </div>
              <div className="node-count-item">
                <span className="nodes-count">
                  {state?.stats?.total_nodes_completed}
                </span>
                <p>Total Nodes Completed</p>
              </div>
              <div className="node-count-item">
                <span className="nodes-count">
                  {state?.stats?.courses_count}
                </span>
                <p>Courses Unlocked</p>
              </div>
              <div className="node-count-item">
                <span className="nodes-count">
                  {state?.stats?.quizzes_count}
                </span>
                <p>Quizzes Unlocked</p>
              </div>
            </div>
            <div className="node-date-box">
              <span>Due Date:</span>
              {state?.stats?.due_date}
            </div>
          </div>
        </div>

        <div className="status-items status-structure-wrap">
          <Table striped hover className="normal-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th className="text-center">Progress</th>
                <th>Status</th>
                <th className="text-center">Done</th>
              </tr>
            </thead>
            <tbody>
              {state && state?.nodes && state?.nodes?.length
                ? state?.nodes?.map((el, index) => {
                    return (
                      <tr>
                        <td>
                          <h4 className="status-structure-title">
                            {el?.node_object_name}
                          </h4>
                        </td>
                        <td>
                          {" "}
                          <div className="course-or-quiz-slip">
                            {el.node_type == "quiz" ? "Quiz" : "Course"}
                          </div>
                        </td>
                        <td align="center">
                          {" "}
                          <div className="modules-status">
                            {el.node_type == "quiz" ? (
                              <div className="d-flex  align-items-center justify-content-center learing-detail-progress">
                                <span className="persentage-show">
                                  {el?.completion_percentage}%
                                </span>
                              </div>
                            ) : (
                              <div className="d-flex  align-items-center justify-content-center learing-detail-progress">
                                <div className="learning-progress progress">
                                  <div
                                    className="progress-bar"
                                    style={{ width: `${checkPercentage(el)}%` }}
                                  >
                                    &nbsp;
                                  </div>
                                </div>
                                <span className="common-progress-text">
                                  {el?.completed_modules_count}/
                                  {el?.total_course_modules} Modules Completed
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="status-button-show tick-show">
                            {/*disabled={checkDisabilty(handleButtonText(el))}*/}
                            <Button
                              type="button"
                              onClick={() =>
                                handleNode(
                                  el,
                                  el.node_type == "quiz"
                                    ? "popupView"
                                    : "coursePopUp"
                                )
                              }
                              disabled={checkDisabilty(handleButtonText(el))}
                            >
                              {handleButtonText(el)}
                            </Button>
                          </div>
                        </td>
                        <td align="center">
                          {el?.has_completed ? (
                            <div className={`status-icon-show tick-show`}>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="InfoText">Completed</Tooltip>
                                }
                              >
                                <span className="status-tooltip-text">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </OverlayTrigger>
                            </div>
                          ) : (
                            <div className={`status-icon-show cross-show`}>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="InfoText">In Progress</Tooltip>
                                }
                              >
                                <span className="status-tooltip-text">
                                  <i
                                    class="fa fa-exclamation"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </OverlayTrigger>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : "No Nodes are available"}
            </tbody>
          </Table>

          <div className="details-footer d-flex justify-content-end">
            <div className="button-wrap double-btn">
              <button
                className="btn btn-m border-btn"
                onClick={() => history.push(`/learningpathdetails/${id}`)}
              >
                Back
              </button>
              <Button
                variant="primary"
                className="btn-m"
                onClick={() => history.push("/my-assignments")}
              >
                Back to Assignments
              </Button>
            </div>
          </div>
        </div>

        {/*    )*/}

        {/*})*/}
        {/*    :*/}

        {/*    'No Nodes are available'*/}
        {/*}*/}

        {/*<div className="status-item d-flex  align-items-center justify-content-between">*/}
        {/*    <div className="status-structure-wrap d-flex  align-items-center justify-content-between">*/}
        {/*        <h4>Introduction</h4>*/}
        {/*        <div className="course-or-quiz-slip">Quiz</div>*/}
        {/*        <div className="modules-status">*/}
        {/*            <div className="d-flex  align-items-center justify-content-between learing-detail-progress">*/}
        {/*                <span className="persentage-show">65%</span>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className="status-icon-show cross-show">*/}
        {/*        <i class="fa fa-times" aria-hidden="true"></i>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {state.nodeId && state.nodeType == "quiz" && (
          <LearnerModulePopView
            lpId={id}
            show={state.popupView}
            name={state?.nodeName}
            nodeId={state.nodeId}
            count={state.count}
            onHide={() => handleModalHide()}
          />
        )}
        {state.nodeId && state.nodeType == "course" && (
          <LearnerCoursePopView
            lpId={id}
            show={state.coursePopUp}
            name={state?.nodeName}
            nodeId={state.nodeId}
            count={state.count}
            onHide={() => handleModalHide()}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="loader-middle-wrap">
        <Spinner animation="border" variant="danger" />
      </div>
    );
  }
}
export default NodsListing;
