import React, {useEffect,useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Button} from 'react-bootstrap';
import {Link,useLocation} from 'react-router-dom';
import AvailableLearningPaths from "../components/availablelearningpaths";
import DraftLearningPaths from "../components/drafts";
import DeletedLearningPaths from "../components/deletedlearningpaths";
import AdminLps from "../components/adminlps/container";
import {ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    restoreBulkLearningPath,
    permanentlyBulkDeleteLearningPath,
    getDeletedLearningPaths,
    getManagerDraftLearningPaths
} from '../services';
import Spinner from "react-bootstrap/Spinner";
import infoModal from "../../../shared/components/infoModal";
import InfoModal from "../../../shared/components/infoModal";
function LearningManagement ({match}){
    const dispatch = useDispatch();
    const location = useLocation();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const permanentBulkLoading = useSelector(state => state?.learningpath?.bulk_delete_learning_paths?.loading);
    const permanentBulkRestoreLoading = useSelector(state => state?.learningpath?.restore_bulk_learning_paths?.loading);
    const [search, setSearch] = useState('');
    const [showModal,setShowModal] = useState(false)
    const [state,setState] = useState({
        lpIds:[]

    })
    const getCourseIds = (data) =>{
        setState({...state,lpIds:data})
    }
    const matchUrl = match.path;
    const { user } = useSelector(state => state.auth);
    const handleBulkDelete = () =>{

        let lp_ids = state.lpIds.map((el)=>{
            return {
                id:el
            }
        })

        let {lpIds} = state
        let tempObj ={learning_path_ids:lp_ids}
        dispatch(permanentlyBulkDeleteLearningPath(tempObj,function (res){
            if(res){
                setTimeout(()=>{
                    dispatch(getDeletedLearningPaths(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate));
                    dispatch(getManagerDraftLearningPaths('', '', '', "False", '','1', ''));
                    lpIds = []
                    setState({...state, lpIds:lpIds})
                },500)
            }
        }));


    }
    const handleBulkRestore = (e) =>{
        e.stopPropagation()

        let lp_ids = state.lpIds.map((el)=>{
            return {
                id:el
            }
        })
        let {lpIds} = state
        let tempObj ={learning_path_ids:lp_ids}
        dispatch(restoreBulkLearningPath(tempObj,function (res){
            if(res){
                setTimeout(()=>{

                    dispatch(getDeletedLearningPaths(search, startDate === null ? '' : startDate, endDate === null ? '' : endDate));
                    dispatch(getManagerDraftLearningPaths('', '', '', "False", '','1', ''));
                    lpIds = []
                    setState({...state, lpIds:lpIds})
                },500)
            }
        }));

    }

    const handleModal = (e) => {
         e.stopPropagation()
        setShowModal(true)

    }
    return (
        <div className="tab-wrapper">
            <ToastContainer autoClose={2000} />
            <div className="tab-right-multiple-button d-flex double-btn">
            {state.lpIds && state.lpIds.length ?
                <>
                    <Button onClick={(e)=>handleBulkRestore(e)}>

                        {permanentBulkRestoreLoading ?
                            <Spinner animation="border" variant="warning"/>

                            :
                            <>
                                Restore All</>
                        }



                    </Button>
                    <Button onClick={(e)=>handleModal(e)}>

                        {permanentBulkLoading ?
                            <Spinner animation="border" variant="warning"/>

                            :
                            <>
                                Delete All</>
                        }

                    </Button>


                </>

                :
                ''
            }
            <Link to="/learning_path_builder" className="m-l-15"><Button>Create New Learning Paths</Button></Link>
            </div>
            <Tabs defaultActiveKey={   location?.state && location?.state?.tab ? location?.state?.tab : 'AvailableCourses'  } id="uncontrolled-tab-example" className="m-b-30">
                <Tab eventKey="AvailableCourses" title="Available Learning Paths">
                    <AvailableLearningPaths />
                </Tab>
                <Tab eventKey="Drafts" title="Drafts">
                    <DraftLearningPaths/>
                </Tab>

                {/*{user && user?.user_type == 'manager' || user?.user_type == 'staff_manager' ?*/}
                {/*    < Tab eventKey="Admin" title="Learning Paths By Admin">*/}
                {/*        /!*<AdminCourse type = {user?.user_type}/>*!/*/}
                {/*        <>sss</>*/}
                {/*    </Tab>*/}
                {/*    :*/}
                {/*    ''*/}

                {/*}*/}
                {user && user?.user_type == 'manager' || user?.user_type == 'staff_manager' ?
                    < Tab eventKey="Admin" title="Template Library">
                        {/*<AdminCourse type = {user?.user_type}/>*/}
                        <AdminLps/>
                    </Tab>
                    :
                    ''
                }


                <Tab eventKey="Deleted" title="Deleted Learning Paths">
                    <DeletedLearningPaths getCourseIds={getCourseIds}/>
                </Tab>



                {/*<Tab eventKey="Admin Draft" title="Draft Courses By Admin">*/}
                {/*    <AdminDraftCourse/>*/}
                {/*</Tab>*/}

            </Tabs>

            <InfoModal
                show={showModal}
                heading={'Delete Learning Paths Forever ?'}
                desctiption ={''}
                button_text={'Yes, Continue'}
                type={'del'}
                handleSubmit={()=> {handleBulkDelete();setShowModal(false)}}
                onHide={() => setShowModal(false)}

            />
        </div>
    );
};
export default LearningManagement;
