
import React from 'react';

function SettingsIcon() {
return (
   <React.Fragment>
<svg xmlns="http://www.w3.org/2000/svg" width="19.087" height="20" viewBox="0 0 19.087 20">
   <g transform="translate(-4 -62)">
      <path className="a" d="M8.158,20a1.445,1.445,0,0,1-1.4-1.126l-.351-1.56a7.663,7.663,0,0,1-1.628-.946l-1.519.48a1.444,1.444,0,0,1-1.687-.657L.19,13.8a1.457,1.457,0,0,1,.267-1.778l1.177-1.082A8.441,8.441,0,0,1,1.582,10a8.625,8.625,0,0,1,.052-.941L.464,7.982A1.455,1.455,0,0,1,.186,6.206l1.39-2.4a1.445,1.445,0,0,1,1.68-.652l1.522.481a7.67,7.67,0,0,1,1.628-.946l.352-1.562A1.444,1.444,0,0,1,8.158,0h2.772a1.445,1.445,0,0,1,1.4,1.126l.351,1.56a7.662,7.662,0,0,1,1.628.946l1.519-.48a1.446,1.446,0,0,1,1.687.657L18.9,6.2a1.457,1.457,0,0,1-.268,1.778L17.452,9.059a8.469,8.469,0,0,1,0,1.882l1.172,1.077.006.005a1.453,1.453,0,0,1,.272,1.771l-1.39,2.4a1.441,1.441,0,0,1-1.681.651l-1.522-.481a7.671,7.671,0,0,1-1.628.946l-.352,1.562A1.442,1.442,0,0,1,10.929,20ZM5.3,15.192A6.419,6.419,0,0,0,7.16,16.269a.625.625,0,0,1,.393.449L7.978,18.6a.187.187,0,0,0,.181.148H10.93a.186.186,0,0,0,.18-.147l.425-1.885a.625.625,0,0,1,.393-.449,6.431,6.431,0,0,0,1.857-1.078.623.623,0,0,1,.586-.114l1.834.579a.187.187,0,0,0,.221-.077l1.39-2.4a.2.2,0,0,0-.04-.241L16.37,11.642a.625.625,0,0,1-.2-.553,7.235,7.235,0,0,0,0-2.18.625.625,0,0,1,.2-.553l1.41-1.3a.2.2,0,0,0,.033-.245L16.431,4.426a.191.191,0,0,0-.227-.083l-1.832.578a.623.623,0,0,1-.586-.114,6.42,6.42,0,0,0-1.858-1.078.624.624,0,0,1-.393-.449L11.112,1.4a.191.191,0,0,0-.182-.147H8.158a.186.186,0,0,0-.18.147L7.553,3.282a.626.626,0,0,1-.393.449A6.413,6.413,0,0,0,5.3,4.808a.627.627,0,0,1-.586.114L2.883,4.343a.189.189,0,0,0-.221.077l-1.39,2.4a.2.2,0,0,0,.043.243L2.719,8.358a.625.625,0,0,1,.2.553,7.238,7.238,0,0,0,0,2.18.626.626,0,0,1-.2.552l-1.41,1.3a.2.2,0,0,0-.032.245l1.382,2.389a.192.192,0,0,0,.227.083l1.832-.578a.607.607,0,0,1,.188-.029A.625.625,0,0,1,5.3,15.192ZM5.377,10a4.167,4.167,0,1,1,4.167,4.167A4.171,4.171,0,0,1,5.377,10Zm1.25,0A2.917,2.917,0,1,0,9.544,7.083,2.92,2.92,0,0,0,6.627,10Z" transform="translate(4 62)"/>
   </g>
</svg>
</React.Fragment>
);
}
export default SettingsIcon;