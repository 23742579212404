import React from "react";
import RadioButton from "../radioButton/container";
import QuizInput from  "../input/container";
import LikertScale from '../../../../../../../surveyDisplay/components/likertscale';
import FreeResponse from '../../../../../../../surveyDisplay/components/freeresponse';
import MultipleChoice from '../../../../../../../surveyDisplay/components/multiplechoicesurvey';
import YesOrNoSurvey from '../../../../../../../surveyDisplay/components/yesornosurvey';

function FormikControl(props){
    const {control,...rest} =props
    if(control.length >1)
    switch (control){
        case "multiple_choice":
            return <RadioButton {...rest} />
        case "likert_scale":
             return <RadioButton {...rest} />
        case 'true_or_false':
            return <RadioButton {...rest} />
         case "free_response":
            return <QuizInput {...rest} />  
        case 'fill_in_the_blanks':
            return <QuizInput {...rest} />
       
        default :
            return false
    }

}

export const FormikControlStled = (props) => {

    const {control,...rest} =props;
    if(control.length >1){   
   
    switch (control){
        case "multiple_choice":
        
           return <MultipleChoice {...rest}/>
        case "likert_scale":
            
            return <LikertScale {...rest}/>
        case 'true_or_false':
            
           return <YesOrNoSurvey {...rest} />
         case "free_response":
           
           return <FreeResponse {...rest}/>

        
        default :
            return false;
   }

}
}

export default FormikControl;
