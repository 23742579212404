import { createAction, handleActions } from 'redux-actions';
import { _getNotifications,_getNotificationsDetail } from '~shared/httpService/api';
import ActionTypes from '~shared/constants/actionTypes';
import {toast} from "react-toastify";

const initialState = {
    get_notifications: { loading: false, response: {}, hasError: false, error: {} },
    detail_notifications:{ loading: false, response: {}, hasError: false, error: {} },
    tour:false
};


export const getNotificationStart = createAction(ActionTypes.GET_NOTIFICATION);
export const getNotificationSuccess = createAction(ActionTypes.GET_NOTIFICATION_SUCCESS, response => response);
export const getNotificationError = createAction(ActionTypes.GET_NOTIFICATION_ERROR, error => error);

export const getNotifications = () => (dispatch) => {
    dispatch(getNotificationStart());
    return _getNotifications().then((response) => {

        dispatch(getNotificationSuccess (response));

    }).catch((error) => {
        dispatch(getNotificationError (error));
    });
};


export const getNotificationDetailStart = createAction(ActionTypes.GET_NOTIFICATION_DETAIL);
export const getNotificationDetailSuccess = createAction(ActionTypes.GET_NOTIFICATION_DETAIL_SUCCESS, response => response);
export const getNotificationDetailError = createAction(ActionTypes.GET_NOTIFICATION_DETAIL_ERROR, error => error);

export const getNotificationsDetail = (id,callback) => (dispatch) => {
    dispatch(getNotificationDetailStart());
    return _getNotificationsDetail(id).then((response) => {
        dispatch(getNotificationDetailSuccess (response));
    if(response){
    callback(response)
       }

    }).catch((error) => {
        dispatch(getNotificationDetailError (error));
    });
};


export const setTourStart = createAction(ActionTypes.SET_TOUR_STATUS);

export const resetTour = (status) => (dispatch) => {
    dispatch(setTourStart(status));
};


const reducer = handleActions({

    [ActionTypes.GET_NOTIFICATION]: (state) => ({
        ...state,
        get_notifications: {
            ...state. get_notifications,
            loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_NOTIFICATION_SUCCESS]: (state, action) => ({
        ...state,
        get_notifications: {
            ...state.  get_notifications,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.GET_NOTIFICATION_ERROR]: (state, action) => ({
        ...state,
        get_notifications: {
            ...state.  get_notifications,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //DETAIL

    [ActionTypes.GET_NOTIFICATION_DETAIL]: (state) => ({
        ...state,
        detail_notifications: {
            ...state. detail_notifications,
            loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_NOTIFICATION_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        detail_notifications: {
            ...state.  detail_notifications,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.GET_NOTIFICATION_DETAIL_ERROR]: (state, action) => ({
        ...state,
        detail_notifications: {
            ...state.  detail_notifications,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    //Tour

    [ActionTypes.SET_TOUR_STATUS]: (state, action) => ({
        ...state,
        tour: action.payload

    }),

}, initialState);

export default reducer;
