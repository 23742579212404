import React, { useState, useEffect, useCallback } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { themeSettings, logoSettings } from "../../settings/services";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ProfileDummy from "~/assets/images/logo-name.svg";
import { HexColorPicker, HexColorInput } from "react-colorful";
import "./styles.css";
import debounce from "lodash.debounce";
import PhoneIco from "~/assets/images/icons/phone-call-black.svg";
import EmailIco from "~/assets/images/icons/email.svg";
import * as yup from "yup";
import { Formik } from "formik";
import {editManagerContact, getManagerContact,createManagerContact,deleteManagerContact,
} from '../../profile/services';
import { Popconfirm } from 'antd';
import {Helmet} from "react-helmet";


function Settings() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [picture, setPicture] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [contactEdit, setcontactEdit] = useState(false);
  const [emptyContact, setEmptyContact] = useState(true);
  const [deletPopup, setdeletPopup] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const selectedLogo = useSelector(
    (state) => state?.settings?.selectedLogo || ""
  );
  const selectedTheme = useSelector(
    (state) => state.settings?.theme?.response || ""
  );
  const loading = useSelector((state) => state.settings.loading);
  const { user } = useSelector((state) => state.auth);

  const response_contact = useSelector(state => state.profile?. manager_contact_details?.response);
  const contact_loading = useSelector(state => state.profile?. manager_contact_details?.loading);


  const [fileTypeError, setFileError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [color, setColor] = useState("#F69525");
  const [initialValues,setInitialValues] = useState({
    email: '',
    alternate_email: '',
    phone_number: '',
    alternate_phone_number: ''
  });

  useEffect(() => {
    if(response_contact?.results?.length > 0){
      setInitialValues(response_contact.results[0]);
      setEmptyContact(false);
    } else {
      setEmptyContact(true);
    }

  }, [response_contact]);

  

  useEffect(() => {
    let body = document.querySelector("body");
    let clr = body?.style?.getPropertyValue("--primary-color")
      ? body?.style?.getPropertyValue("--primary-color")
      : "#F69525";
    setColor(clr);
    dispatch(getManagerContact());
  }, []);

  const debouncedThemeChange = useCallback(
    debounce((e) => {
      let body = document.querySelector("body");

      if (body) {
        let colorTheme = e;
        dispatch(
          themeSettings(colorTheme, false, function (response) {
            if (response) {
              body.setAttribute("data-theme", `dynamic`);
              body?.style.setProperty("--primary-color", `${response?.theme}`);
            } else {
              body.setAttribute("data-theme", `dynamic`);
              body?.style.setProperty("--primary-color", "#F69525");
            }
          })
        );
      }
    }, 500),
    []
  );

  const dynamicColorSelect = (e) => {
    setColor(e);
    debouncedThemeChange(e);
  };

  // const dynamicColorSelect = (e) => {

  //     setColor(e)
  //     let body = document.querySelector('body')

  //     if (body) {
  //         let colorTheme = e
  //         dispatch(themeSettings(colorTheme, false, function (response) {

  //             if (response) {
  //                 body.setAttribute('data-theme', `dynamic`)
  //                 body?.style.setProperty(
  //                     "--primary-color",
  //                     `${response?.theme}`
  //                 );
  //             } else {
  //                 body.setAttribute('data-theme', `dynamic`)
  //                 body?.style.setProperty(
  //                     "--primary-color",
  //                     "#F69525"
  //                 );
  //             }
  //         }))
  //     }

  // useEffect(() => {
  //     if(localStorage.getItem('logo') ){
  //     let localLogo=localStorage.getItem('logo')
  //     setImgData( localLogo)
  //     console.log("user in settings"+JSON.stringify(imgData))
  //     }
  //     else  setImgData(user.logo)
  //
  // }, [])

  useEffect(() => {
    if (selectedLogo.logo) {
      if (selectedLogo.logo.includes("null")) {
        setImgData(ProfileDummy);
      } else {
        setImgData(selectedLogo.logo ? selectedLogo.logo : ProfileDummy);
      }
    } else {
      setImgData(user?.logo ? user?.logo : ProfileDummy);
    }
  }, [selectedLogo]);

  useEffect(() => {
    if (selectedLogo?.theme) {
      if (selectedLogo.logo.includes("null")) {
        setImgData(ProfileDummy);
      } else {
        setImgData(selectedLogo.logo ? selectedLogo.logo : ProfileDummy);
        localStorage.setItem("MainLogo", `${selectedLogo.logo}`);
      }
    } else {
      setImgData(user?.logo ? user?.logo : ProfileDummy);
    }
  }, [selectedLogo]);

  const fileEvent = (e) => {
    const fsize = e.target.files[0].size;
    const fileinMB = Math.round(fsize / 1024);
    if (fileinMB >= 5120) {
      setFileSizeError(true);
      setFileError(false);
    } else {
      setFileSizeError(false);
      const getFileExtension = e.target.files[0].name.split(".").pop();
      if (
        getFileExtension === "jpg" ||
        getFileExtension === "jpeg" ||
        getFileExtension === "png" ||
        getFileExtension === "gif" ||
        getFileExtension === "svg"
      ) {
        //         {
        setFileError(false);
        dispatch(
          logoSettings(
            selectedTheme?.theme ? selectedTheme?.theme : user?.theme,
            e.target.files[0]
          )
        );
      } else {
        setFileError(true);
      }
    }

    // dispatch( logoSettings(selectedTheme?.theme ? selectedTheme?.theme : user?.theme, e.target.files[0]))
  };

  const clearImage = () => {
    dispatch(logoSettings("", null));
    // setImgData( null)
  };

  // const handleThemeSelect = (prop) => {

  //     let body = document.querySelector('body')
  //     if (prop == 'red') {
  //         let redTheme = 'red_white';
  //         if (body) {
  //             dispatch(themeSettings(redTheme, false, function (response) {

  //                 if (response) {
  //                     body.setAttribute('data-theme', `${response?.theme}`)
  //                 } else {
  //                     body.setAttribute('data-theme', `yellow_white`)
  //                 }
  //             }))
  //         }
  //     } else if (prop == 'green') {
  //         let greenTheme = 'green_white';
  //         if (body) {
  //             dispatch(themeSettings(greenTheme, false, function (response) {
  //                 if (response) {
  //                     body.setAttribute('data-theme', `${response?.theme}`)
  //                 } else {
  //                     body.setAttribute('data-theme', `yellow_white`)
  //                 }
  //             }))
  //         }
  //     } else if (prop == 'blue') {
  //         let blackTheme = 'blue_white';
  //         if (body) {
  //             dispatch(themeSettings(blackTheme, false, function (response) {
  //                 if (response) {
  //                     body.setAttribute('data-theme', `${response?.theme}`)
  //                 } else {
  //                     body.setAttribute('data-theme', `yellow_white`)
  //                 }
  //             }))
  //         }
  //     } else if (prop == 'yellow') {
  //         let blackTheme = 'yellow_white';
  //         if (body) {
  //             dispatch(themeSettings(blackTheme, false, function (response) {
  //                 if (response) {
  //                     body.setAttribute('data-theme', `${response?.theme}`)
  //                 } else {
  //                     body.setAttribute('data-theme', `yellow_white`)
  //                 }
  //             }))
  //         }
  //     }
  // }
  var regex = /^[0-9!@#\$%\^\&*\)\(+=._-]+$/g
  const basicDetailsSchema = yup.object().shape({
    email: yup.string().email("Email is Invalid").required('Email is required'),
    alternate_email: yup.string().email("Email is Invalid"),
    phone_number: yup.string().required('Phone Number is required')
        .matches(regex, 'Phone number is invalid'),
    alternate_phone_number: yup.string().matches(regex, 'Phone number is invalid'),

  });

  const handleFormSubmit = (values) => {

    setcontactEdit(false);
  
    const id=response_contact?.results[0]?.id;
    const formData=new FormData();

    formData.append('email',values.email);
    formData.append('alternate_email',values.alternate_email);
    formData.append('phone_number',values.phone_number);
    formData.append('alternate_phone_number',values.alternate_phone_number);

    if(emptyContact){
      //create
      dispatch(createManagerContact(formData, () => {
        dispatch(getManagerContact());
      }));

    }else{
      //edit
      dispatch(editManagerContact(id,formData,() => {
        dispatch(getManagerContact());
      }));
    }
  };

  const onDeleteContact = () => {
    setdeletPopup(true);
    setConfirmLoading(false)
  };

  const handleOk = () => {
    setConfirmLoading(true)
    const id=response_contact?.results[0]?.id;
    if(id){
      dispatch(deleteManagerContact(id,() => {
        setdeletPopup(false);
        dispatch(getManagerContact());
      }));
    }
  };

  return (
    <div className="setting-wrap">
          <Helmet>
                <title>Settings - Cultural Engagement  </title>
            </Helmet>
      <Row>
        <div className="settings-box-wrap settings-box-1">
          <div className="settings-box-outer">
            <h5>Themes</h5>
            <p>Select platform theme from here.</p>
            <div className="whitebox shadow-box settings-box">
              {/* <ul className="d-flex justify-content-between w-100 settings-box-list">
                                <li className="yellowwhite">
                                    <button className="settings-box-item" onClick={e => handleThemeSelect('yellow')}>
                                        <div className="settings-box-item-left st-white-bg"></div>
                                        <div className="settings-box-item-right st-yellow-bg"></div>
                                    </button>
                                </li>
                                <li className="redwhite">
                                    <button className="settings-box-item" onClick={e => handleThemeSelect('red')}>
                                        <div className="settings-box-item-left st-white-bg"></div>
                                        <div className="settings-box-item-right st-red-bg"></div>
                                    </button>
                                </li>
                                <li className="greenwhite">
                                    <button className="settings-box-item" onClick={e => handleThemeSelect('green')}>

                                        <div className="settings-box-item-left st-white-bg"></div>
                                        <div className="settings-box-item-right st-green-bg"></div>
                                    </button>
                                </li>
                                <li className="bluewhite">
                                    <button className="settings-box-item" onClick={e => handleThemeSelect('blue')}>
                                        <div className="settings-box-item-left st-white-bg"></div>
                                        <div className="settings-box-item-right st-blue-bg"></div>
                                    </button>
                                </li>
                            </ul> */}
              <div className="custom-color-picker">
                <HexColorPicker
                  color={color}
                  onChange={(e) => dynamicColorSelect(e)}
                />
              </div>
              <div className="custom-color-input text-center">
                <div className="hexa-input-hash-wrap">
                  <span className="hash-wrap">#</span>
                  <HexColorInput
                    className="hexa"
                    color={color}
                    onChange={(e) => dynamicColorSelect(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-box-wrap settings-box-2">
          <div className="settings-box-outer">
            <h5>Logo</h5>
            <p>Add your institution/company logo from here.</p>
            <div className="whitebox shadow-box settings-box">
              <div className="logo-settings-wrap d-flex w-100 align-items-center">
                {/* <div className="logo-uploaded" >
                                {loading ?
                                <Spinner animation="border" variant="warning" />
                               : <div>
                                   {localStorage.getItem()
                                   <div>
                                    {
                                    imgData ? <div >
                                     < img className="logo-resize" src={imgData} alt="No Image" />
                                      <span className="memb-close-icon" onClick={clearImage}>×</span>
                                      </div>

                                   :  <div  >
                                     <img src={require("~/assets/images/logo2.svg")} />
                                     </div>
                                     }
                                   </div>
                                   </div>
                                }

                              </div> */}

                <div className="logo-uploaded">
                  {loading ? (
                    <Spinner animation="border" variant="warning" />
                  ) : (
                    <div>
                      {imgData ? (
                        <div>
                          <img
                            className="logo-resize"
                            src={imgData ? imgData : ProfileDummy}
                            alt="No Image"
                          />
                          {/*<span className="memb-close-icon" onClick={clearImage}>×</span>*/}
                        </div>
                      ) : (
                        <div>
                          <img src={ProfileDummy} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="upload-btn">
                  <div className="mt-2">
                    <Form.Control
                      type="file"
                      name="image"
                      id="image"
                      className="fileuploader-hidden"
                      onChange={(e) => fileEvent(e)}
                      accept="image/png, image/gif, image/jpeg,image/svg"
                    />
                    <label htmlFor="image" className="btn border-btn">
                      Upload Logo
                    </label>
                    {fileTypeError ? (
                      <span style={{ color: "#ce002a" }}>
                        Select png, jpeg or svg images only
                      </span>
                    ) : (
                      ""
                    )}
                    {fileSizeError ? (
                      <span style={{ color: "#ce002a" }}>
                        {" "}
                        File too big, please select a file less than 5MB
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-box-wrap settings-box-3">
          <div className="settings-box-outer">
            <h5>Contact</h5>
            <p>Contact your institution</p>
            {!contact_loading ? (
              <div className="whitebox shadow-box settings-box contact-info-box">
            <div>
               {contactEdit ? (
                  <div className="form-content-wrap">{/* hide this section with d-none and show below section */}
                   <Formik
                    initialValues={initialValues}
                    enableReinitialize={true} 
                    validationSchema={basicDetailsSchema}
                    onSubmit={handleFormSubmit}
                  >
                    { ({ errors , values, handleChange, handleSubmit}) => (
                       <Form noValidate onSubmit={handleSubmit}>
                       <div className="contact-info-sec">
                         <h4 className="settings-sub-title">Primary contact info</h4>
                         <div className="form-input-wrap">
                           <Form.Control type="text" placeholder="Your Phone Number" name="phone_number" value={values.phone_number} onChange={handleChange} isInvalid={errors.phone_number} />
                           <Form.Control.Feedback type="invalid">
                                {errors.phone_number}

                              </Form.Control.Feedback>
                         </div>
                         <div className="form-input-wrap">
                           <Form.Control type="email" placeholder="Your Email" name="email" value={values.email} onChange={handleChange} isInvalid={errors.email}/>
                           <Form.Control.Feedback type="invalid">
                                {errors.email}

                              </Form.Control.Feedback>
                         </div>
                       </div>
                       <div className="contact-info-sec">
                         <h4 className="settings-sub-title">Secondary contact info</h4>
                         <div className="form-input-wrap">
                           <Form.Control type="text" placeholder="Your Phone Number"  name="alternate_phone_number" value={values.alternate_phone_number} onChange={handleChange} isInvalid={errors.alternate_phone_number}/>
                           <Form.Control.Feedback type="invalid">
                                {errors.alternate_phone_number}

                              </Form.Control.Feedback>
                         </div>
                         <div className="form-input-wrap">
                           <Form.Control type="email"  placeholder="Your Email" name="alternate_email" value={values.alternate_email} onChange={handleChange} isInvalid={errors.alternate_email} />
                           <Form.Control.Feedback type="invalid">
                                {errors.alternate_email}

                              </Form.Control.Feedback>
                         </div>
                         <div className="btn-wrap">
                           <Button type="submit">Save</Button>
                           <Button className="mx-2" onClick={() => setcontactEdit(false)}>Cancel</Button>
                         </div>
                       </div>
                     </Form>
                    )}
                 
                  </Formik>
                </div>
                ): (
                  <>
                  {!emptyContact ? (
                       <div className="saved-content-show">{/* remove d-none class, so you can see this section */}
                       <div className="contact-info-sec">
                        <h4 className="settings-sub-title">Primary contact info</h4>
                        <div className="form-item-show d-flex align-items-center">
                          <span className="items-show-img"><img src={PhoneIco} alt="phone" /></span><span>{response_contact?.results[0]?.phone_number}</span>
                        </div>
                        <div className="form-item-show d-flex align-items-center">
                          <span className="items-show-img"><img src={EmailIco} alt="email" /></span><span>{response_contact?.results[0]?.email}</span>
                        </div>
                      </div>
                    
                      <div className="contact-info-sec">
                      {(response_contact?.results[0]?.alternate_phone_number || response_contact?.results[0]?.alternate_email) && (
                          <>  <h4 className="settings-sub-title">Secondary contact info</h4>
                          {response_contact?.results[0]?.alternate_phone_number && (
                              <div className="form-item-show d-flex align-items-center">
                              <span className="items-show-img"><img src={PhoneIco} alt="phone" /></span><span>{response_contact?.results[0]?.alternate_phone_number}</span>
                            </div>
                          )}
                        {response_contact?.results[0]?.alternate_email && (
                          <div className="form-item-show d-flex align-items-center">
                            <span className="items-show-img"><img src={EmailIco} alt="email" /></span><span>{response_contact?.results[0]?.alternate_email}</span>
                          </div>
                          )}
                          </>
                        )}
                      
                        <div className="btn-wrap">
                          <Button onClick={() => setcontactEdit(true)}>Edit</Button>
                          <Popconfirm
                            title="Are you sure to delete this contact details?"
                            visible={deletPopup}
                            onConfirm={()=>handleOk()}
                            okText="Yes"
                            cancelText="No"
                            placement="bottom"
                            okButtonProps={{loading: confirmLoading}}
                            onCancel={()=>setdeletPopup(false)}
    >
                          <Button className="mx-2" onClick={onDeleteContact}>Delete</Button>
                          </Popconfirm>
                        </div>
                      </div>
    
                    </div>
                  ):(
                    <div ><b>No Contact Details Added </b>
                      <div className="button-wrapper d-flex  m-t-20 m-b-10"><Button onClick={()=>setcontactEdit(true)} >Create Contact</Button></div>
                    </div>
                  )}
                  </>
               

                )}
              
            </div>
          
            </div>
            ) : (
              <><Spinner animation="border" />;</>
            )}
          </div>
        </div>
      </Row>
      <div className="settings-links-wrap m-t-30">
        <ul className="settings-links-list d-flex list-unstyled">
          <li>
            <a
              href="https://docs.aptreelms.com/agreements-and-privacy/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://docs.aptreelms.com/agreements-and-privacy/master-subscription-agreement"
              target="_blank"
            >
              Terms and conditions
            </a>
          </li>
        </ul>
      </div>
     
    </div>
  );
}
export default Settings;
