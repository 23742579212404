import 'chartjs-plugin-datalabels';


// Chart 9 Extreme responsive configuration
export const chart9Data = {
	data: {
		labels: ['2010-11', '2011-12', '2012-13', '2013-13', '2014-15', '2015-16'],
		series: [
			[0.9, 0.4, 1.5, 4.9, 3, 3.8, ],
			[6.4, 5.7, 7, 4.95, 3, 3.8,] ]
	},
	options: {
		seriesBarDistance: 15,
		horizontalBars: false,
		axisY: {
			offset: 20
		},
	},
	responsiveOptions: [
		[
			"screen and (max-width: 1440px)",
			{
				seriesBarDistance: 11.5,
			}
		],
		[
			"screen and (max-width: 480px)",
			{
				stackBars: true,
				seriesBarDistance: 10
			}
		]
	]
}
