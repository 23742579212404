import React  , {Fragment, useEffect, useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import  SurveyIntroduction from '../components/introduction'
import LearningPathAssign from '../components/addtolearningpath';
import AddDemoDetails from '../components/addDemographicQs/index';
import AddDetails from '../components/addGeneralQs/index'
import {ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet";



function SurveyCreate(props) {

  

  
   const [state, setState] =useState({
       defaultKey:'0',
       surveyId:'',
       survey:{},
       moduleAdded:false,
   })

   const urlId = props?.match?.params?.id 

   const[gError,setGeneralError]=useState(false)
   const[dError,setDemoError]=useState(false)

   const parentState = (key,id,item,yes) =>{
       setState({...state, defaultKey: key,surveyId:id,survey:item,moduleAdded:yes})
   }

   const handleNext = (key) => {
       setState({...state, defaultKey: key})

   }

const demoError=(childData)=>{


   if(childData){
      setDemoError(true)
    } else { setDemoError(false)}


}

const generalError=(childData)=>{

   if(childData){
      setGeneralError(true)
   } else { setGeneralError(false)}

}



   return (
      <div className="tab-wrapper">
             <Helmet>
                    <title>{urlId ? 'Survey Edit - Cultural Engagement ' : 'Survey Create - Cultural Engagement '} </title>
                </Helmet>
          <ToastContainer autoClose={2000} />
         <Tabs onSelect={(k) => setState({...state,defaultKey: k})} activeKey={state?.defaultKey} defaultActiveKey="Introduction" className="m-b-30">
            <Tab eventKey="0" disabled={state?.moduleAdded}  title="Introduction"  tabClassName='zIndex'>
            <SurveyIntroduction  parentState={parentState} urlId={urlId ? urlId :state.surveyId ? state.surveyId  : null}/>
            </Tab>
            <Tab disabled={!state?.surveyId || state?.moduleAdded 
               // || gError
                }  eventKey="1" title="Demographic Questions" tabClassName='zIndex'>
          <AddDemoDetails parentState={parentState} surveyId={state?.surveyId} demographicError={demoError} survey={state?.survey} urlId={urlId ? urlId :null} defaultKey={state?.defaultKey} next={handleNext}/>
            </Tab> 
               <Tab disabled={!state?.surveyId || state?.moduleAdded 
               // || dError
               } eventKey="2" title="Questions" tabClassName='zIndex'>
             <AddDetails parentState={parentState} surveyId={state?.surveyId} generalError={generalError}  survey={state?.survey} urlId={urlId ? urlId :null} defaultKey={state?.defaultKey} next={handleNext}/> 
            </Tab>  
           
             

         </Tabs>
         
      </div>
   );
};
export default SurveyCreate;
