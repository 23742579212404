import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { FormLabel, FormControl } from "react-bootstrap";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";

import MultipleChoice from "../addGeneralQs/components/multipleChoice";
import TrueFalse from "../addGeneralQs/components/trueFalse";
import DropDownList from "../addGeneralQs/components/dropdownlist";
import LikertScale from "../addGeneralQs/components/likeart";
import FreeResponse from "../addGeneralQs/components/freeResponse";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import { ToastContainer, toast } from "react-toastify";
import QuizModulePopView from "../managerQuizPreview";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { updateSurveyQuestion, getManagerSurveys } from "../../services";
import Select from "react-select";
import uuid from "react-uuid";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const AddDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [infoPopUp, showInfoPopup] = useState(false);
  const auto = useRef();
  // let response = useSelector(state => state?.survey_builder?.get_survey_manager?.response);
  let addQuestionLoading = useSelector(
    (state) => state?.survey_builder?.get_survey_manager?.loading
  );
  let deleteQuestionLoading = useSelector(
    (state) => state?.survey_builder?.get_survey_manager?.loading
  );
  const [modalShow, setModalShow] = React.useState(false);
  const publish_ref = useRef(null);
  const [activeButton, setActiveButton] = useState(undefined);
  const [response, setResponse] = useState({});
  const [tutorialModel, setTutorialmodel] = useState(false);

  useEffect(() => {
    if (props?.defaultKey == 2) {
      dispatch(
        getManagerSurveys(props?.surveyId, function (res) {
          setResponse(res);
        })
      );
    }
  }, [props?.defaultKey, props?.surveyId]);

  function VerticallyCenteredModal(props) {
    return (
      <Modal
        className="custom-modal confirmation-popup  delete-confirmation inner-popup"
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-content-wrap text-center">
            <h3 className="modal-title">Publish Survey?</h3>
            <p>Survey once published cannot be edited </p>
            <div className="d-flex justify-content-center buttons-wrap double-btn">
              <button
                type="button"
                className="btn border-btn white-btn"
                onClick={() => props.onHide()}
              >
                No, I need to recheck
              </button>
              <Link className="m-l-20">
                <Button onClick={props.handleSubmit} type="button">
                  Yes, Continue
                </Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const [state, setState] = useState({
    questionIndex: "",
    popupView: false,
    initialValuesState: {
      //   pass_score:'1',
      general_questions: [
        {
          question_type: "",
        },
      ],
    },
  });
  const formikRef = useRef(null);
  const handleSwitchChange = (e, i, values, setFieldValue, question) => {
    setFieldValue(`general_questions.${i}.mandatory`, e);
  };
  const handlePageBreakChange = (e, i, values, setFieldValue, question) => {
    setFieldValue(`general_questions.${i}.page_break`, e);
  };

  function TutorialModal(props) {

    return (
      <Modal className="custom-modal info-modal dashboard-modal" show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="module-modal-header my-4">

            <h4>Questions tab enables you to create any type of question based on the survey you require and thus get responses from your organisation members
            </h4>
          </div>
          <ol className="info-list">
            <li><span> Add Question enables authors to add questions to their surveys</span></li>
            <li><span> Question type helps you to choose from four options like Multiple Choice, True/False, Likert Scale and Free Response.</span></li>
            <li><span>Adding Question Title helps you to differentiate each question while reviewing in the Reports, also please remember the title should be between 8-20 characters.</span></li>
            <li><span>Enabling the Mandatory  button will make the particular question mandatory for learners to answer</span></li>
            <li><span>Enabling Page Break for a question will add the particular question into a new page.</span></li>
            <li><span>Reorder positions of questions with the arrows you may find in the border once there are two questions in your surveys</span></li>
            <li><span>Clicking on the Back  button takes you back to the Demographic Questions creation tab.</span></li>
            <li><span> Once you complete adding questions to your survey, you can click Preview Button at the end to view how your survey shall be displayed to your learners</span></li>
            <li><span> Click on Save and find your survey in Drafts and click on Publish to find it in Published surveys</span></li>
            <li><span>Each time you click Add question, all the questions get auto-saved hence you can find the same survey in Drafts anytime.</span></li>
            <li><span> Please remember, once you publish your survey you cannot edit it ever.</span></li>

          </ol>
        </Modal.Body>
        <div className="btn-wrap text-center">
          <Button onClick={props.onHide}> Close </Button>
        </div>
      </Modal>
    );
  }

  useEffect(() => {
    if (props?.urlId) {
      if (
        response?.general_questions?.length == 0 ||
        !response?.general_questions
      ) {
        const { initialValuesState } = state;
        initialValuesState.general_questions = [];
        initialValuesState?.general_questions?.push({
          question_type: "",
        });

        setState({ ...state, initialValuesState: initialValuesState });
      } else {
        setState({ ...state, initialValuesState: response });
      }
    }
  }, [response?.general_questions]);

  function onSubmit(values) {
    if (activeButton == "save") {
      if (values?.general_questions && values?.general_questions?.length) {
        for (let i = 0; i < values?.general_questions?.length; i++) {
          if (
            values?.general_questions[i]?.multiple_choices?.length &&
            values?.general_questions[i].question_type == "multiple_choice"
          ) {
            for (
              let j = 0;
              j < values?.general_questions[i]?.multiple_choices?.length;
              j++
            ) {
              if (
                values.general_questions[i].multiple_choices[j].choice.length ==
                0
              ) {
                values.general_questions[i].multiple_choices =
                  values?.general_questions[i]?.multiple_choices?.filter(
                    (el) => el?.choice?.length
                  );
              }
            }
          }
          values.general_questions[i].position = i + 1;
        }
      }
      values.is_published = "False";
      values.is_active = "true";
      if (!("survey_uuid" in values)) {
        const new_values = { ...response, ...values };

        dispatch(
          updateSurveyQuestion(
            JSON.stringify(new_values),
            props?.surveyId,
            function (response) {
              toast.success('Questions  has been  updated  successfully')
              props.parentState(2, props?.surveyId, props?.survey, true);

              history.push({
                pathname: "/surveymanagement",
                state: {
                  key: 1,
                },
              });
            }
          )
        );
      } else {
        dispatch(
          updateSurveyQuestion(
            JSON.stringify(values),
            props?.surveyId,
            function (response) {
              toast.success('Questions  has been  updated  successfully')
              props.parentState(2, props?.surveyId, props?.survey, true);

              history.push({
                pathname: "/surveymanagement",
                state: {
                  key: 1,
                },
              });
            }
          )
        );
      }
    } else if (activeButton == "back") {

      if (
        values?.demographic_questions &&
        values?.demographic_questions?.length
      ) {
        for (let i = 0; i < values?.demographic_questions?.length; i++) {
          if (
            values?.demographic_questions[i]?.multiple_choices?.length &&
            values?.demographic_questions[i].question_type == "multiple_choice"
          ) {
            for (
              let j = 0;
              j < values?.demographic_questions[i]?.multiple_choices?.length;
              j++
            ) {
              if (
                values.demographic_questions[i].multiple_choices[j].choice
                  .length == 0
              ) {
                values.demographic_questions[i].multiple_choices =
                  values?.demographic_questions[i]?.multiple_choices?.filter(
                    (el) => el?.choice?.length
                  );
              }
            }
          }
        }
      }

      values.is_published = "False";
      values.is_active = "true";
      if (!("survey_uuid" in values)) {
        const new_values = { ...response, ...values };

        dispatch(
          updateSurveyQuestion(
            JSON.stringify(new_values),
            props?.surveyId,
            function (response) {
              toast.success('Questions  has been  updated  successfully')
              setTimeout(() => {
                props.parentState(1, props?.surveyId, props?.survey, true);
              }, 300);
            }
          )
        );
      } else {
        dispatch(
          updateSurveyQuestion(
            JSON.stringify(values),
            props?.surveyId,
            function (response) {
              toast.success('Questions  has been  updated  successfully')
              setTimeout(() => {
                props.parentState(1, props?.surveyId, props?.survey, true);
              }, 300);
            }
          )
        );
      }
    } else if (activeButton == "publish") {
      if (values?.general_questions && values?.general_questions?.length) {
        for (let i = 0; i < values?.general_questions?.length; i++) {
          if (
            values?.general_questions[i]?.multiple_choices?.length &&
            values?.general_questions[i].question_type == "multiple_choice"
          ) {
            for (
              let j = 0;
              j < values?.general_questions[i]?.multiple_choices?.length;
              j++
            ) {
              if (
                values.general_questions[i].multiple_choices[j].choice.length ==
                0
              ) {
                values.general_questions[i].multiple_choices =
                  values?.general_questions[i]?.multiple_choices?.filter(
                    (el) => el?.choice?.length
                  );
              }
            }
          }
          values.general_questions[i].position = i + 1;
        }

        values.is_published = "true";
        values.is_active = "true";
        if (!("survey_uuid" in values)) {
          const new_values = { ...response, ...values };

          dispatch(
            updateSurveyQuestion(
              JSON.stringify(new_values),
              props?.surveyId,
              function (response) {
                toast.success('Survey  has been  published  successfully')
                props.parentState(2, props?.surveyId, props?.survey, true);

                history.push({
                  pathname: "/surveymanagement",
                  state: {
                    key: 0,
                  },
                });
              }
            )
          );
        } else {
          dispatch(
            updateSurveyQuestion(
              JSON.stringify(values),
              props?.surveyId,
              function (response) {
                toast.success('Survey  has been  published  successfully')
                props.parentState(2, props?.surveyId, props?.survey, true);
                showInfoPopup(true);

                showInfoPopup(false);
                history.push({
                  pathname: "/surveymanagement",
                  state: {
                    key: 0,
                  },
                });

                history.push({
                  pathname: "/surveymanagement",
                  state: {
                    key: 0,
                  },
                });
              }
            )
          );
        }
      } else {
        toast.error("Please add Question");
      }
    }
  }
  async function handleSurveys(
    e,
    values,
    setValues,
    errors,
    validateField,
    validateForm
  ) {
    if (values?.general_questions && values?.general_questions?.length >= 1) {
      let error = await formikRef.current.validateForm();

      if (error.general_questions) {
        error.general_questions = errors?.general_questions?.filter(function (
          x
        ) {
          return x !== undefined;
        });
      }
      if (
        error?.general_questions &&
        error?.general_questions?.length &&
        values?.general_questions?.find((el) => el.question_type == "")
      ) {
        for (let i = 0; i < values?.general_questions?.length; i++) {
          if (values?.general_questions[i].question_type == "") {
    
            formikRef.current.setFieldTouched(
              `general_questions.${i}.question_type`
            );

            break;
          }
        }
      } else if (
        Object.keys(error).includes("general_questions") &&
        error?.general_questions?.length &&
        error.constructor === Object
      ) {
        if (values?.general_questions?.length) {
          auto?.current?.click();
        }
        // }

  
      }

      if (Object.keys(error).length === 0 && error.constructor === Object) {
        setTimeout(() => {
   
          if (values?.general_questions && values?.general_questions?.length) {
            for (let i = 0; i < values?.general_questions?.length; i++) {
              if (
                values?.general_questions?.length &&
                values?.general_questions[i].question_type == "multiple_choice"
              ) {
                for (
                  let j = 0;
                  j < values?.general_questions[i]?.multiple_choices?.length;
                  j++
                ) {
                  if (
                    values.general_questions[i].multiple_choices[j].choice
                      .length == 0
                  ) {
                    values.general_questions[i].multiple_choices =
                      values?.general_questions[i]?.multiple_choices.filter(
                        (el) => el?.choice?.length
                      );
                  }
                }
              }
              values.general_questions[i].position = i + 1;
            }
          }
          values.is_published = "False";
          values.is_active = "true";
       

          // const surveydet = props?.survey ? props?.survey : ''
          const new_values = { ...response, ...values };

      

          dispatch(
            updateSurveyQuestion(
              JSON.stringify(new_values),
              props?.surveyId,
              function (res) {
                toast.success('Questions are auto-saved!')
                if (res) {
                  dispatch(
                    getManagerSurveys(props?.surveyId, function (res) {
                      if (res) {
                        res?.general_questions?.push({
                          question_type: "",
                        });

                        setValues({ ...values, general_questions: [] });
                        setState({ ...state, initialValuesState: res });
                      }
                    })
                  );
                }
              }
            )
          );
        }, 10);
      } else {
        toast.error("Some fields are empty, please check!");
      }
    } else {
      const general_questions = [...values.general_questions];
      const { initialValuesState } = state;

      general_questions.push({
        question_type: "",
      });
      initialValuesState?.general_questions?.push({
        question_type: "",
      });

      setValues({ ...values, general_questions });
      setState({ ...state, initialValuesState: initialValuesState });
    }
    // autoSave(values,props?.courseId,props.urlId)
  }

  const deleteQuestion = (index, values, setValues) => {
    const general_questions = [...values.general_questions];
    const { initialValuesState } = state;
    initialValuesState?.general_questions?.splice(index, 1);
    general_questions.splice(index, 1);
    setValues({ ...values, general_questions });

    setState({ ...state, initialValuesState: initialValuesState });
  };
  const handleQuestionDelete = (index, values, setValues, question) => {
    setState({ ...state, quizId: question?.id });
    const general_questions = [...values.general_questions];
    dispatch(
      deleteQuizQuestion(props.quizId, question?.id, function (response) {
        if (response == "response") {
          general_questions.splice(index, 1);
          setValues({ ...values, general_questions });
        }
      })
    );
  };

  const publishSurvey = () => {
    publish_ref?.current?.focus();
    publish_ref?.current?.click();
  };
  const handlePreviewModel = () => {
    setState({ ...state, popupView: true });
  };
  const handleLoader = () => {
    if (addQuestionLoading || updateLoading) {
      return (
        <div className="loader-full-screen-course">
          <div className="loader">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      );
    } else {
      return <span></span>;
    }
  };
  const ger = (general_questionsErrors) => {
    if (Object.keys(general_questionsErrors).length != 0) {
      props.generalError(true);
    } else {
      props.generalError(false);
    }
  };

  return (
    <div className="coursedetails-wrap">
      {addQuestionLoading ? (
        <div className="loader-full-screen-course">
          <div className="loader">
            <Spinner animation="border" variant="danger" />
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <div>{handleLoader()}</div> */}
      <Formik
        innerRef={formikRef}
        initialValues={state.initialValuesState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          values,
          setFieldValue,
          touched,
          setValues,
          handleChange,
          handleBlur,
          validateField,
          validateForm,
          handleSubmit,
        }) => {

          const handleSubmitTrigger = async () => {
            let error = await validateForm();
            if (Object.keys(error).length === 0 && error.constructor === Object) {
              handleSubmit();
            } else {
              auto?.current?.click();
              toast.error("Some fields are empty, please check!");
            }
          };

          const handleContent = (template, selectedIndex) => {
            const { initialValuesState } = state;
            if (template) {
       
              const general_questions = [...values.general_questions];
              switch (template) {
                case "multiple_choice":
                  general_questions[selectedIndex].question_type = template;
                  initialValuesState.general_questions[
                    selectedIndex
                  ].question_type = template;
                  initialValuesState.general_questions[selectedIndex].question =
                    "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].question_id = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].page_break = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].mandatory = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].multiple_choices = [
                      { choice: "" },
                      { choice: "" },
                      { choice: "" },
                    ];
                  initialValuesState.general_questions[selectedIndex].title =
                    "";
                  general_questions[selectedIndex].title = "";

                  general_questions[selectedIndex].question = "";
                  general_questions[selectedIndex].question_id = uuid();
                  general_questions[selectedIndex].page_break = false;
                  general_questions[selectedIndex].mandatory = false;
                  general_questions[selectedIndex].multiple_choices = [
                    { choice: "" },
                    { choice: "" },
                    { choice: "" },
                  ];
                  setState({
                    ...state,
                    initialValuesState: initialValuesState,
                    questionIndex: selectedIndex,
                  });
                  setValues({ ...values, general_questions });
                  break;

                case "likert_scale":
                  general_questions[selectedIndex].question_type = template;
                  initialValuesState.general_questions[
                    selectedIndex
                  ].question_type = template;
                  initialValuesState.general_questions[selectedIndex].question =
                    "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].question_id = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].page_break = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].mandatory = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].multiple_choices = [
                      { choice: "" },
                      { choice: "" },
                      { choice: "" },
                      { choice: "" },
                      { choice: "" },
                    ];
                  initialValuesState.general_questions[selectedIndex].title =
                    "";
                  general_questions[selectedIndex].title = "";
                  general_questions[selectedIndex].question = "";
                  general_questions[selectedIndex].question_id = uuid();
                  //    general_questions[selectedIndex].right_answer =''
                  general_questions[selectedIndex].page_break = false;
                  general_questions[selectedIndex].mandatory = false;
                  general_questions[selectedIndex].multiple_choices = [
                    { choice: "" },
                    { choice: "" },
                    { choice: "" },
                    { choice: "" },
                    { choice: "" },
                  ];
                  setState({
                    ...state,
                    initialValuesState: initialValuesState,
                    questionIndex: selectedIndex,
                  });
                  setValues({ ...values, general_questions });
                  break;

                case "true_or_false":
                  initialValuesState.general_questions[
                    selectedIndex
                  ].question_type = template;
                  general_questions[selectedIndex].question_type = template;
                  initialValuesState.general_questions[selectedIndex].question =
                    "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].question_id = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].page_break = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].mandatory = "";
                  initialValuesState.general_questions[selectedIndex].title =
                    "";
                  general_questions[selectedIndex].title = "";
                  general_questions[selectedIndex].multiple_choices = [
                    { choice: "" },
                    { choice: "" },
                  ];
                  general_questions[selectedIndex].question = "";
                  general_questions[selectedIndex].question_id = uuid();
                  general_questions[selectedIndex].page_break = false;
                  general_questions[selectedIndex].mandatory = false;
                  general_questions[selectedIndex].multiple_choices = [
                    { choice: "True" },
                    { choice: "False" },
                  ];
                  setState({
                    ...state,
                    initialValuesState: initialValuesState,
                    questionIndex: selectedIndex,
                  });
                  setValues({ ...values, general_questions });
                  break;
                case "free_response":
                  general_questions[selectedIndex].question_type = template;
                  initialValuesState.general_questions[
                    selectedIndex
                  ].question_type = template;
                  initialValuesState.general_questions[selectedIndex].question =
                    "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].question_id = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].page_break = "";
                  initialValuesState.general_questions[
                    selectedIndex
                  ].mandatory = "";
                  initialValuesState.general_questions[selectedIndex].title =
                    "";
                  general_questions[selectedIndex].title = "";
                  general_questions[selectedIndex].multiple_choices = [];
                  general_questions[selectedIndex].question = "";
                  general_questions[selectedIndex].question_id = uuid();
                  general_questions[selectedIndex].page_break = false;
                  general_questions[selectedIndex].mandatory = false;
                  general_questions[selectedIndex].multiple_choices = [];
                  setState({
                    ...state,
                    initialValuesState: initialValuesState,
                    questionIndex: selectedIndex,
                  });
                  setValues({ ...values, general_questions });
                  break;
              }
            }
          };

          const goUp = (index) => {
            if (index !== 0) {
              let questionscopy = JSON.parse(JSON.stringify(values.general_questions));
              let item = questionscopy[index];
              questionscopy[index] = questionscopy[index - 1];
              questionscopy[index - 1] = item;
              setFieldValue('general_questions', questionscopy);
            }

          };

          const goDown = (index) => {
            if (index < values.general_questions.length - 1) {
              let questionscopy = JSON.parse(JSON.stringify(values.general_questions));
              let item =  questionscopy[index];
              questionscopy[index] =  questionscopy[index + 1];
              questionscopy[index + 1] = item;
              setFieldValue('general_questions', questionscopy);
            }
          };

          return (
            <Form className="d-block mb-4">
              <div className="help_icon_position">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="InfoText">
                      Help
                    </Tooltip>
                  }
                >
                  <span className="info-icon-wrap-tab animated-icon" onClick={() => setTutorialmodel(true)}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </span>
                </OverlayTrigger>
              </div>
              <div className="course-content-sec"></div>
              <div className="course-content-sec ">
                <FieldArray name="general_questions">
                  {() =>
                    values?.general_questions &&
                      values?.general_questions?.length
                      ? values?.general_questions?.map((question, i) => {
                        const general_questionsErrors =
                          (errors.general_questions?.length &&
                            errors.general_questions[i]) ||
                          {};
                        const general_questionsTouched =
                          (touched?.general_questions?.length &&
                            touched?.general_questions[i]) ||
                          {};

                        return (
                          <div key={i}>
                            

                            {ger(general_questionsErrors)}

                            <div className="course-form-content-sec p-relative" >
                              {values?.general_questions?.length > 1 && (

                                <div className="top-bottom-carret-wrap">
                                  {i !== 0 && <div onClick={() => goUp(i)} className="move-to-top move-arrow-wrap ">
                                    <OverlayTrigger
                                      overlay={<Tooltip id="InfoText">Move up</Tooltip>}>
                                      <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                  </div>}
                                  {i !== values?.general_questions?.length - 1 && <div onClick={() => goDown(i)} className="move-to-bottom move-arrow-wrap">
                                    <OverlayTrigger
                                      overlay={<Tooltip id="InfoText">Move down</Tooltip>}>
                                      <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                  </div>}
                                </div>

                              )}

                              <h3 className="tab-title"> Question {i + 1}</h3>
                              <div className="whitebox shadow-box module-form-box">
                                {/* {question?.id ? (
                                    ""
                                  ) : (
                                    <div
                                      onClick={() =>
                                        deleteQuestion(i, values, setValues)
                                      }
                                    >
                                      {" "}
                                      <div className="form-close-btn p-relative">
                                        <span className="memb-close-icon-card">
                                          ×
                                        </span>
                                      </div>
                                    </div>
                                  )} */}
                                {/*<div onClick={()=>deleteModule(i,values, setValues)}> <div className="form-close-btn p-relative"><span className="memb-close-icon-card">×</span></div></div>*/}
                                <Row>
                                  <Col md={2}>
                                    <div key={i} className="form-input-wrap">
                                      <FormLabel>
                                        Position
                                      </FormLabel>
                                      <FormControl
                                        type="number"
                                        name={`modules.${i}.position`}
                                        placeholder="Position"
                                        className="position"
                                        readOnly
                                        value={i + 1} />
                                    </div>
                                    
                                  </Col>
                                  <Col md={6}>
                                    <div className="form-input-wrap">
                                      <FormLabel>Question Type</FormLabel>
                                      <FormControl
                                        as="select"
                                        name={`general_questions.${i}.question_type`}
                                        onChange={(e) =>
                                          handleContent(e.target.value, i)
                                        }
                                        value={
                                          values?.general_questions[i]
                                            ?.question_type
                                        }
                                        isInvalid={
                                          !!general_questionsErrors?.question_type &&
                                          general_questionsTouched?.question_type
                                        }
                                        onBlur={handleBlur}
                                      >
                                        <option value="" selected disabled>
                                          Choose question type
                                        </option>
                                        <option value="multiple_choice">
                                          Multiple Choice
                                        </option>
                                        <option value="true_or_false">
                                          True/False
                                        </option>
                                        <option value="likert_scale">
                                          Likert Scale
                                        </option>
                                        <option value="free_response">
                                          Free response
                                        </option>
                                      </FormControl>
                                    </div>
                                  </Col>
                                </Row>
                                {question?.question_type ? (
                                  <div className="text-editor-wrap">
                                    {(() => {
                                      switch (question?.question_type) {
                                        case "multiple_choice":
                                          return (
                                            <MultipleChoice
                                              setFieldValue={setFieldValue}
                                              values={values}
                                              handleChange={handleChange}
                                              setValues={setValues}
                                              general_questionsErrors={
                                                general_questionsErrors
                                              }
                                              general_questionsTouched={
                                                general_questionsTouched
                                              }
                                              touched={touched}
                                              handleBlur={handleBlur}
                                              index={i}
                                            />
                                          );
                                        case "true_or_false":
                                          return (
                                            <TrueFalse
                                              setFieldValue={setFieldValue}
                                              values={values}
                                              handleChange={handleChange}
                                              setValues={setValues}
                                              general_questionsErrors={
                                                general_questionsErrors
                                              }
                                              general_questionsTouched={
                                                general_questionsTouched
                                              }
                                              touched={touched}
                                              handleBlur={handleBlur}
                                              index={i}
                                            />
                                          );
                                        case "likert_scale":
                                          return (
                                            <LikertScale
                                              setFieldValue={setFieldValue}
                                              values={values}
                                              setValues={setValues}
                                              handleChange={handleChange}
                                              general_questionsErrors={
                                                general_questionsErrors
                                              }
                                              general_questionsTouched={
                                                general_questionsTouched
                                              }
                                              handleBlur={handleBlur}
                                              index={i}
                                            />
                                          );

                                        case "free_response":
                                          return (
                                            <FreeResponse
                                              setFieldValue={setFieldValue}
                                              values={values}
                                              setValues={setValues}
                                              handleChange={handleChange}
                                              general_questionsErrors={
                                                general_questionsErrors
                                              }
                                              general_questionsTouched={
                                                general_questionsTouched
                                              }
                                              handleBlur={handleBlur}
                                              index={i}
                                            />
                                          );

                                        default:
                                          return null;
                                      }
                                    })()}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <Col
                                  xs={12}
                                  className="d-flex justify-content-end"
                                >
                                  {question?.id ? (
                                    <button
                                      className="btn border-btn ml-auto"
                                      type="button"
                                      onClick={(e) =>
                                        handleQuestionDelete(
                                          i,
                                          values,
                                          setValues,
                                          question
                                        )
                                      }
                                    >
                                      {deleteQuestionLoading &&
                                        deleteQuestionLoading &&
                                        state.quizId == question.id ? (
                                        <Spinner
                                          animation="border"
                                          variant="warning"
                                        />
                                      ) : (
                                        "Delete"
                                      )}
                                    </button>
                                  ) : (
                                    <button className="btn border-btn ml-auto" type="button"
                                      onClick={e => deleteQuestion(i, values, setValues)}>
                                      Delete
                                    </button>
                                  )}
                                </Col>
                                {question?.question_type ? (
                                  <Row className="survey-page-break">
                                    <Col md={4} lg={3}>
                                      <div className="d-flex switch-inmodule-wrap">
                                        <span>Mandatory</span>
                                        <div className="switch-align-right">
                                          <BootstrapSwitchButton
                                            onChange={(e) =>
                                              handleSwitchChange(
                                                e,
                                                i,
                                                values,
                                                setFieldValue,
                                                question
                                              )
                                            }
                                            checked={
                                              values?.general_questions[i]
                                                ?.mandatory
                                            }
                                            size="xs"
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={4} lg={3}>
                                      <div className="d-flex switch-inmodule-wrap">
                                        <span>Page Break</span>
                                        <div className="switch-align-right">
                                          <BootstrapSwitchButton
                                            onChange={(e) =>
                                              handlePageBreakChange(
                                                e,
                                                i,
                                                values,
                                                setFieldValue,
                                                question
                                              )
                                            }
                                            checked={
                                              values?.general_questions[i]
                                                ?.page_break
                                            }
                                            size="xs"
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : ""
                  }
                </FieldArray>

                <Button
                  variant="primary"
                  className="mt-4"
                  onClick={(e) =>
                    handleSurveys(
                      e,
                      values,
                      setValues,
                      errors,
                      validateField,
                      validateForm
                    )
                  }
                >
                  {values?.general_questions?.length ? 'Add Another Question' : 'Add New Question'}
                </Button>
                {/*  </div>*/}
              </div>

              <div className="details-footer d-flex justify-content-end">
                <div className="button-wrap double-btn">
                  <Button
                    variant="primary"
                    type="submit"
                    data-flag="action-3"
                    ref={auto}
                    className="btn-m d-none"
                  >
                    Hidden
                  </Button>
                  <OverlayTrigger placement="top"
                    overlay={<Tooltip id="overlay-example">Move to Previous page</Tooltip>}>
                    <Button
                      variant="dark"
                      className="btn-m"
                      data-flag="action-4"
                      onClick={() => {
                        setActiveButton("back");
                        setTimeout(() => {
                          handleSubmitTrigger();
                        }, 100);
                      }}
                    >
                      Back
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top"
                    overlay={<Tooltip id="overlay-example">Save to Drafts</Tooltip>}>
                    <Button
                      variant="dark"
                      onClick={() => {
                        setActiveButton("save");
                        setTimeout(() => {
                          handleSubmitTrigger();
                        }, 100);
                      }}
                      className="btn-m"
                      data-flag="action-1"
                    >
                      Save
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top"
                    overlay={<Tooltip id="overlay-example">View a Preview</Tooltip>}>
                    <Button
                      variant="dark"
                      className="btn-m"
                      onClick={handlePreviewModel}
                    >
                      Preview
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top"
                    overlay={<Tooltip id="overlay-example">Publish to Published tab</Tooltip>}>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => setModalShow(true)}
                      className="btn-m"
                    >
                      Publish
                    </Button>
                  </OverlayTrigger>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setActiveButton("publish");
                      setTimeout(() => {
                        handleSubmitTrigger();
                      }, 100);
                    }}
                    data-flag="action-2"
                    ref={publish_ref}
                    className="btn-m d-none"
                  >
                    Publish-hidden
                  </Button>
                </div>
              </div>
              <QuizModulePopView
                show={state.popupView}
                values={{ ...values, questions: values?.general_questions }}
                onHide={() => setState({ ...state, popupView: false })}
              />
              <VerticallyCenteredModal
                show={modalShow}
                handleSubmit={() => {
                  publishSurvey();
                  setModalShow(false);
                }}
                onHide={() => setModalShow(false)}
              // onExited={ModalExit}
              />
            </Form>
          );
        }}
      </Formik>
      <Modal
        size="sm"
        show={infoPopUp}
        aria-labelledby="example-modal-sizes-title-lg"
        className="common-modal quiz-flash-popup inner-popup"
        centered
        closeButton={false}
      >
        <Modal.Body>
          <div className="addmember-header text-center">
            <p>
              <b>
                This assessments is now available to be used in your learning
                path builder , or can be attached to individual modules found in
                your library
              </b>
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <TutorialModal show={tutorialModel}

        onHide={() => setTutorialmodel(false)}
      />
    </div>
  );
};
const validationSchema = Yup.object().shape({
  general_questions: Yup.array().of(
    Yup.object().shape({
      question_type: Yup.string().required("Question type is required"),
      title: Yup.string().required("Title is required"),
      question: Yup.string().required("Question is required"),

      free_response: Yup.object().when("question_type", {
        is: (val) => ["free_response"].includes(val),
        then: Yup.object().shape({}),
        otherwise: Yup.object().shape({}).nullable(),
      }),

      multiple_choices: Yup.array()
        .of(
          Yup.object().shape({
            choice: Yup.string(),
          })
        )
        .test({
          name: "multiple_choices",
          message: "Required",
          test: (val) =>
            val?.every(({ choice }, index) => {
              if (index === 0 || index === 1) {
                return !!choice;
              }
              return true;
            }),
        }),
    })
  ),
});

export default AddDetails;
