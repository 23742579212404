import {createAction, handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {
    _getLearnerLearningPath,
    _learnerPaginationLearningPathNext,
    _learnerPaginationLearningPathPrevious,
    _getLearnerAssignedAssignments,
    _getLearnerLearningPathNodes,
    _getLearnerLearningPathNodesDetail,
    _getLearnerLearningPathDetail,
    _submitCourseLearner,
    _submitQuizLearner,
    _getLearnerLearningCertificates,
    _getLearnerLearningPathComplete,
    _learnerPaginationLearningPathCompleteNext,
    _learnerPaginationLearningPathCompletePrevious,
    _getLearnerLearningPathFail,
    _learnerPaginationLearningPathFailNext,
    _learnerPaginationLearningPathFailPrevious,
    _getLearnerLearningCertificateDetail,
    _extendLearningPathLearner,
    _getLearnerAssignmentCourses,
    _learnerPaginationAssignmentCourseNext,
    _learnerPaginationAssignmentCoursePrevious,
    _getLearnerLearningPathAssigments,
    _getLearnerLearningPathAssigmentsWithPage,
    _nextLearnerLearningPathAssigments,
    _prevLearnerLearningPathAssigments,
    _getLearnerLearningPathReportDetail,
    _getLearnerLearningPathReportDetailWithPage,
    _nextLearnerLearningPathReportDetail,
    _prevLearnerLearningPathReportDetail,
    _getLearnerCourseCertificateDetail,
    _getLearnerCourseCertificates,
    _nextLearnerCourseCertificates,_prevLearnerCourseCertificate,_getLearnerCourseCertificateWithPage

} from '~shared/httpService/api';

import { ToastContainer, toast } from 'react-toastify';
import { _prevLearnerCourseCertificates } from '../../../shared/httpService/api';


const initialState = {
    learner_LearningPath_list: { loading: false, response: {}, hasError: false, error: {} },
    learner_lp_detail: { loading: false, response: {}, hasError: false, error: {} },
    learner_lp_nodes: { loading: false, response: {}, hasError: false, error: {} },
    learner_lp_node_detail: { loading: false, response: {}, hasError: false, error: {} },
    learner_submit_course: { loading: false, response: {}, hasError: false, error: {} },
    learner_submit_quiz: { loading: false, response: {}, hasError: false, error: {} },
    learner_certificates: { loading: false, response: {}, hasError: false, error: {} },
    learner_LearningPath_completed_list: { loading: false, response: {}, hasError: false, error: {} },
    learner_LearningPath_failed_list: { loading: false, response: {}, hasError: false, error: {} },
    learner_certificate: { loading: false, response: {}, hasError: false, error: {} },
    request_extend_date:{ loading: false, response: {}, hasError: false, error: {} },
    lp_assignments :{ loading: false, response: {}, hasError: false, error: {} },
    course_assignments:{ loading: false, response: {}, hasError: false, error: {} },
    lp_dashboard:{ loading: false, response: {}, hasError: false, error: {} },
    lp_courses_cert:{ loading: false, response: {}, hasError: false, error: {} },
};


export const getLearnerLearningPathStart = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH);
export const getLearnerLearningPathSuccess = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH_SUCCESS, response => response);
export const getLearnerLearningPathError = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH_ERROR, error => error);

export const getLearnerLearningPathList = (search, status,index) => (dispatch) => {
    dispatch(getLearnerLearningPathStart());
    return _getLearnerLearningPath(search, status,index).then((response) => {
        dispatch(getLearnerLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerLearningPathError(error));

        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};


export const paginationLearnerLearningPathStart = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_NEXT);
export const paginationLearnerLearningPathSuccess = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationLearnerLearningPathError = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationLearnerLearningPath = (url) => (dispatch) => {
    dispatch(paginationLearnerLearningPathStart());
    return _learnerPaginationLearningPathNext(url).then((response) => {
        dispatch(paginationLearnerLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearnerLearningPathError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};

export const paginationPreviousLearnerLearningPathStart = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS);
export const paginationPreviousLearnerLearningPathSuccess = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearnerLearningPathError = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationLearnerLearningPath = (url) => (dispatch) => {
    dispatch(paginationPreviousLearnerLearningPathStart());
    return _learnerPaginationLearningPathPrevious(url).then((response) => {
        dispatch(paginationPreviousLearnerLearningPathSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearnerLearningPathError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }

    });
};


export const getLearnerLearningPathCompleteStart = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH_COMPLETE);
export const getLearnerLearningPathCompleteSuccess = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH_COMPLETE_SUCCESS, response => response);
export const getLearnerLearningPathCompleteError = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH_COMPLETE_ERROR, error => error);

export const getLearnerLearningPathComplete = (search, status,index) => (dispatch) => {
    dispatch(getLearnerLearningPathCompleteStart());
    return _getLearnerLearningPathComplete(search, status,index).then((response) => {
        dispatch(getLearnerLearningPathCompleteSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerLearningPathCompleteError(error));

        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};


export const paginationLearnerLearningPathCompleteStart = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT);
export const paginationLearnerLearningPathCompleteSuccess = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT_SUCCESS, response => response);
export const paginationLearnerLearningPathCompleteError = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT_ERROR, error => error);

export const handlePaginationLearnerLearningPathComplete = (url) => (dispatch) => {
    dispatch(paginationLearnerLearningPathCompleteStart());
    return _learnerPaginationLearningPathCompleteNext(url).then((response) => {
        dispatch(paginationLearnerLearningPathCompleteSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearnerLearningPathCompleteError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};

export const paginationPreviousLearnerLearningPathCOMPLETEStart = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS);
export const paginationPreviousLearnerLearningPathCOMPLETESuccess = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearnerLearningPathCOMPLETEError = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationLearnerLearningPatCompleted = (url) => (dispatch) => {
    dispatch(paginationPreviousLearnerLearningPathCOMPLETEStart());
    return _learnerPaginationLearningPathCompletePrevious(url).then((response) => {
        dispatch(paginationPreviousLearnerLearningPathCOMPLETESuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearnerLearningPathCOMPLETEError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }

    });
};


export const getLearnerLearningPathFailedStart = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH_FAILED);
export const getLearnerLearningPathFailedSuccess = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH_FAILED_SUCCESS, response => response);
export const getLearnerLearningPathFailedError = createAction(ActionTypes.GET_LEARNER_LEARNINGPATH_FAILED_ERROR, error => error);

export const getLearnerLearningPathFail = (search, status,index) => (dispatch) => {
    dispatch(getLearnerLearningPathFailedStart());
    return _getLearnerLearningPathFail(search, status,index).then((response) => {
        dispatch(getLearnerLearningPathFailedSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerLearningPathFailedError(error));

        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};


export const paginationLearnerLearningPathFailedStart = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT);
export const paginationLearnerLearningPathFailedSuccess = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT_SUCCESS, response => response);
export const paginationLearnerLearningPathFailedError = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT_ERROR, error => error);


export const handlePaginationLearnerLearningPathFailed = (url) => (dispatch) => {
    dispatch(paginationLearnerLearningPathFailedStart());
    return _learnerPaginationLearningPathFailNext(url).then((response) => {
        dispatch(paginationLearnerLearningPathFailedSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearnerLearningPathFailedError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};

export const paginationPreviousLearnerLearningPathFailedStart = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS);
export const paginationPreviousLearnerLearningPathFailedSuccess = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearnerLearningPathFailedError = createAction(ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationLearnerLearningPatFailed = (url) => (dispatch) => {
    dispatch(paginationPreviousLearnerLearningPathFailedStart());
    return _learnerPaginationLearningPathFailPrevious(url).then((response) => {
        dispatch(paginationPreviousLearnerLearningPathFailedSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearnerLearningPathFailedError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }

    });
};



export const getLearnerAssignedAssinmentPathStart = createAction(ActionTypes.LEARNER_ASSIGNED_LEARNING_Path);
export const getLearnerAssignedAssinmentSuccess = createAction(ActionTypes.LEARNER_ASSIGNED_LEARNING_Path_SUCCESS, response => response);
export const getLearnerAssignedAssinmentError = createAction(ActionTypes.LEARNER_ASSIGNED_LEARNING_Path_ERROR, error => error);

export const getLearnerAssignedAssignments = (search, status,index) => (dispatch) => {
    dispatch(getLearnerAssignedAssinmentPathStart());
    return _getLearnerAssignedAssignments(search, status,index).then((response) => {
        dispatch(getLearnerAssignedAssinmentSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerAssignedAssinmentError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};

export const getLearningPathNodesLearnerStart = createAction(ActionTypes.LEARNER_LEARNING_PATH_NODES);
export const getLearningPathNodesLearnerSuccess = createAction(ActionTypes.LEARNER_LEARNING_PATH_NODES_SUCCESS, response => response);
export const getLearningPathNodesLearnerError = createAction(ActionTypes.LEARNER_LEARNING_PATH_NODES_ERROR, error => error);

export const getLearnerLearningPathNodes = (id,callback) => (dispatch) => {
    dispatch(getLearningPathNodesLearnerStart());
    return _getLearnerLearningPathNodes(id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathNodesLearnerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathNodesLearnerError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};


export const getLearningPathNodesDetailLearnerStart = createAction(ActionTypes.LEARNER_LEARNING_PATH_NODES_DETAIL);
export const getLearningPathNodesDetailLearnerSuccess = createAction(ActionTypes.LEARNER_LEARNING_PATH_NODES_DETAIL_SUCCESS, response => response);
export const getLearningPathNodesDetailLearnerError = createAction(ActionTypes.LEARNER_LEARNING_PATH_NODES_DETAIL_ERROR, error => error);

export const getLearnerLearningPathNodesDetail = (id,elementId,callback) => (dispatch) => {
    dispatch(getLearningPathNodesDetailLearnerStart());
    return _getLearnerLearningPathNodesDetail(id,elementId).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathNodesDetailLearnerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathNodesDetailLearnerError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};

export const getLearningPathLearnerDetailStart = createAction(ActionTypes.LEARNER_LEARNING_PATH_DETAIL);
export const getLearningPathLearnerDetailSuccess = createAction(ActionTypes.LEARNER_LEARNING_PATH_DETAIL_SUCCESS, response => response);
export const getLearningPathLearnerDetailError = createAction(ActionTypes.LEARNER_LEARNING_PATH_DETAIL_ERROR, error => error);

export const getLearnerLearningPathDetail = (id,callback) => (dispatch) => {
    dispatch(getLearningPathLearnerDetailStart());
    return _getLearnerLearningPathDetail(id).then((response) => {
        dispatch(getLearningPathLearnerDetailSuccess(response));

        if(response){
          
            callback(response)
        }


    }).catch((error) => {
        dispatch(getLearningPathLearnerDetailError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};


export const submitLearningPathLearnerCourseStart = createAction(ActionTypes.LEARNER_LEARNING_PATH_COURSE_SUBMIT);
export const submitLearningPathLearnerCourseSuccess = createAction(ActionTypes.LEARNER_LEARNING_PATH_COURSE_SUBMIT_SUCCESS, response => response);
export const submitLearningPathLearnerCourseError = createAction(ActionTypes.LEARNER_LEARNING_PATH_COURSE_SUBMIT_ERROR, error => error);

export const submitCourseLearner = (id,nodeId,callback,callbackError) => (dispatch) => {
    dispatch(submitLearningPathLearnerCourseStart());
    return _submitCourseLearner(id,nodeId).then((response) => {
        dispatch(submitLearningPathLearnerCourseSuccess(response));

        if(response){
            callback(response)
        }


    }).catch((error) => {
        dispatch(submitLearningPathLearnerCourseError(error));
        callbackError(error)
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};


export const submitLearningPathLearnerQuizStart = createAction(ActionTypes.LEARNER_LEARNING_PATH_COURSE_QUIZ);
export const submitLearningPathLearnerQuizSuccess = createAction(ActionTypes.LEARNER_LEARNING_PATH_COURSE_QUIZ_SUCCESS, response => response);
export const submitLearningPathLearnerQuizError = createAction(ActionTypes.LEARNER_LEARNING_PATH_COURSE_QUIZ_ERROR, error => error);

export const submitQuizLearner = (data,nodeId,callback) => (dispatch) => {
    dispatch(submitLearningPathLearnerQuizStart());
   
    return _submitQuizLearner(data,nodeId).then((response) => {
        dispatch(submitLearningPathLearnerQuizSuccess(response));

        if(response){
            callback(response)
        }


    }).catch((error) => {
        dispatch(submitLearningPathLearnerQuizError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};
export const getLearningCertificatesStart = createAction(ActionTypes.LEARNER_LEARNING_CERTIFICATES);
export const getLearningCertificatesSuccess = createAction(ActionTypes.LEARNER_LEARNING_CERTIFICATES_SUCCESS, response => response);
export const getLearningCertificatesError = createAction(ActionTypes.LEARNER_LEARNING_CERTIFICATES_ERROR, error => error);

export const getLearnerLearningCertificates = (callback) => (dispatch) => {
    dispatch(getLearningCertificatesStart());
    return _getLearnerLearningCertificates().then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningCertificatesSuccess(response));

    }).catch((error) => {
        dispatch(getLearningCertificatesError(error));
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};

export const getLearningPathLearnerCertificateDetailStart = createAction(ActionTypes.LEARNER_LEARNING_CERTIFICATE_DETAIL);
export const getLearningPathLearnerCertificateDetailSuccess = createAction(ActionTypes.LEARNER_LEARNING_CERTIFICATE_DETAIL_SUCCESS, response => response);
export const getLearningPathLearnerCertificateDetailError = createAction(ActionTypes.LEARNER_LEARNING_CERTIFICATES_DETAIL_ERROR, error => error);

export const getLearnerLearningPathCertificateDetail = (id,callback,type,callbackerror) => (dispatch) => {
    dispatch(getLearningPathLearnerCertificateDetailStart());
    let tempObj ={course:_getLearnerCourseCertificateDetail,learning_path:_getLearnerLearningCertificateDetail}
    return  tempObj[type](id).then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningPathLearnerCertificateDetailSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathLearnerCertificateDetailError(error));
        callbackerror('error')
        if(error?.error?.errorMessage){
            toast.error(error?.error?.errorMessage)
        }else{
            toast.error('Something went wrong')
        }
    });
};

//ASSIGNMENTS
export const extendLpLearnerStart = createAction(ActionTypes.LP_LEARNER_LP_EXTEND_LEARNER);
export const extendLpLearnerSuccess = createAction(ActionTypes.LP_LEARNER_LP_EXTEND_LEARNER_SUCCESS, response => response);
export const extendLpLearnerError = createAction(ActionTypes.LP_LEARNER_LP_EXTEND_LEARNER_ERROR, error => error);

export const extendLearningPathLearner = (data, userId,history,callback) => (dispatch) => {
    dispatch(extendLpLearnerStart());
    return _extendLearningPathLearner(data, userId).then((response) => {
        if(response){
            toast.success('Learning path Date extended successfully')

        }
        dispatch(extendLpLearnerSuccess(response));

    }).catch((error) => {
        dispatch(extendLpLearnerError(error));
        if(error){
            // console.log(error)
            // console.log(error?.error.errorMessage)
            toast.error(error?.error.errorMessage)
            callback(error)
        }
    });
};

export const getLearnerAssignCoursesStart = createAction(ActionTypes.GET_LEARNER_ASSIGNMENTS_COURSES);
export const getLearnerAssignCoursesSuccess = createAction(ActionTypes.GET_LEARNER_ASSIGNMENTS_COURSES_SUCCESS, response => response);
export const getLearnerAssignCoursesError = createAction(ActionTypes.GET_LEARNER_ASSIGNMENTS_COURSES_ERROR, error => error);

export const getLearnerAssignmentCourses = (index) => (dispatch) => {
    dispatch(getLearnerAssignCoursesStart());
    return _getLearnerAssignmentCourses(index).then((response) => {
        dispatch(getLearnerAssignCoursesSuccess(response));
    }).catch((error) => {
        dispatch(getLearnerAssignCoursesError(error));
    });
};


export const paginationLearnerAssigmentCourseStart = createAction(ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT);
export const paginationLearnerAssigmentSuccess = createAction(ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationLearnerAssigmentError = createAction(ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT_ERROR, error => error);

export const handlePaginationLearnerAssignmentCourse = (url) => (dispatch) => {
    dispatch(paginationLearnerAssigmentCourseStart());
    return _learnerPaginationAssignmentCourseNext(url).then((response) => {
        dispatch(paginationLearnerAssigmentSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearnerAssigmentError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousLearnerAssignmentCourseStart = createAction(ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS);
export const paginationPreviousLearnerAssignmentCourseSuccess = createAction(ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearnerAssignmentCourseError = createAction(ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS_ERROR, error => error);

export const handlePreviousPaginationLearnerAssignmentCourse = (url) => (dispatch) => {
    dispatch(paginationPreviousLearnerAssignmentCourseStart());
    return _learnerPaginationAssignmentCoursePrevious(url).then((response) => {
        dispatch(paginationPreviousLearnerAssignmentCourseSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearnerAssignmentCourseError(error));
        toast.error('Something went wrong')

    });
};


export const getLearningAssigmentLearnerStart = createAction(ActionTypes.GET_LEARNER_ASSIGNMENTS_LP);
export const getLearningAssigmentLearnerSuccess = createAction(ActionTypes.GET_LEARNER_ASSIGNMENTS_LP_SUCCESS, response => response);
export const getLearningAssigmentLearnerError = createAction(ActionTypes.GET_LEARNER_ASSIGNMENTS_LP_ERROR, error => error);

export const getLearnerLearningPathAssigments = (callback) => (dispatch) => {
    dispatch(getLearningAssigmentLearnerStart());
    return _getLearnerLearningPathAssigments().then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningAssigmentLearnerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningAssigmentLearnerError(error));
        toast.error('Something went wrong')
    });
};

export const getLearningPathAssignmentWithPageLeranerStart = createAction(ActionTypes.LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE);
export const getLearningPathAssignmentWithPageLeranerSuccess = createAction(ActionTypes.LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE_SUCCESS, response => response);
export const getLearningPathAssignmentWithPageLeranerError = createAction(ActionTypes.LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE_SUCCESS_ERROR, error => error);

export const getLearnerLearningPathAssigmentsWithPage = (page) => (dispatch) => {
    dispatch(getLearningPathAssignmentWithPageLeranerStart());
    return _getLearnerLearningPathAssigmentsWithPage(page).then((response) => {
        dispatch(getLearningPathAssignmentWithPageLeranerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningPathAssignmentWithPageLeranerError(error));
        toast.error('Something went wrong')
    });
};

export const paginationLearningPathAssignmentWithPageLeranerStart = createAction(ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT);
export const paginationLearningPathAssignmentWithPageLeranerSuccess = createAction(ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationLearningPathAssignmentWithPageLeranerError = createAction(ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT_ERROR, error => error);

export const nextLearnerLearningPathAssigments= (url) => (dispatch) => {
    dispatch(paginationLearningPathAssignmentWithPageLeranerStart());
    return _nextLearnerLearningPathAssigments(url).then((response) => {
        dispatch(paginationLearningPathAssignmentWithPageLeranerSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearningPathAssignmentWithPageLeranerError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousLearningPathAssignmentWithPageLeranerStart = createAction(ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS);
export const paginationPreviousLearningPathAssignmentWithPageLeranerSuccess = createAction(ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearningPathAssignmentWithPageLeranerError = createAction(ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS_ERROR, error => error);

export const prevLearnerLearningPathAssigments = (url) => (dispatch) => {
    dispatch(paginationPreviousLearningPathAssignmentWithPageLeranerStart());
    return _prevLearnerLearningPathAssigments(url).then((response) => {

        dispatch(paginationPreviousLearningPathAssignmentWithPageLeranerSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearningPathAssignmentWithPageLeranerError(error));
        toast.error('Something went wrong')

    });
};


/////////////////////////////////////////

export const getLearningReportLearnerStart = createAction(ActionTypes.GET_LEARNER_REPORT_LP);
export const getLearningReportLearnerSuccess = createAction(ActionTypes.GET_LEARNER_REPORT_LP_SUCCESS, response => response);
export const getLearningReportLearnerError = createAction(ActionTypes.GET_LEARNER_REPORT_LP_ERROR, error => error);

export const getLearnerLearningPathReport = (callback) => (dispatch) => {
    dispatch(getLearningReportLearnerStart());
    return _getLearnerLearningPathReportDetail().then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningReportLearnerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningReportLearnerError(error));
        toast.error('Something went wrong')
    });
};

export const getLearningReportReportWithPageLeranerStart = createAction(ActionTypes.LP_LEARNER_ALL_REPORT_WITH_PAGE);
export const getLearningReportReportWithPageLeranerSuccess = createAction(ActionTypes.LP_LEARNER_ALL_REPORT_WITH_PAGE_SUCCESS, response => response);
export const getLearningReportReportWithPageLeranerError = createAction(ActionTypes.LP_LEARNER_ALL_REPORT_WITH_PAGE_SUCCESS_ERROR, error => error);

export const getLearnerLearningPathReportWithPage = (page) => (dispatch) => {
    dispatch(getLearningReportReportWithPageLeranerStart());
    return _getLearnerLearningPathReportDetailWithPage(page).then((response) => {
        dispatch(getLearningReportReportWithPageLeranerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningReportReportWithPageLeranerError(error));
        toast.error('Something went wrong')
    });
};

export const paginationLearningPathReportWithPageLeranerStart = createAction(ActionTypes.LEARNER_REPORT_LP_PAGINATION_NEXT);
export const paginationLearningPathReportWithPageLeranerSuccess = createAction(ActionTypes.LEARNER_REPORT_LP_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationLearningPathReportWithPageLeranerError = createAction(ActionTypes.LEARNER_REPORT_LP_PAGINATION_NEXT_ERROR, error => error);

export const nextLearnerLearningPathReport= (url) => (dispatch) => {
    dispatch(paginationLearningPathReportWithPageLeranerStart());
    return _nextLearnerLearningPathReportDetail(url).then((response) => {
        dispatch(paginationLearningPathReportWithPageLeranerSuccess(response));
    }).catch((error) => {
        dispatch(paginationLearningPathReportWithPageLeranerError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousLearningPathReportWithPageLeranerStart = createAction(ActionTypes.LEARNER_REPORT_LP_PAGINATION_PREVIOUS);
export const paginationPreviousLearningPathReportWithPageLeranerSuccess = createAction(ActionTypes.LEARNER_REPORT_LP_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousLearningPathReportWithPageLeranerError = createAction(ActionTypes.LEARNER_REPORT_LP_PAGINATION_PREVIOUS_ERROR, error => error);

export const prevLearnerLearningPathReport = (url) => (dispatch) => {
    dispatch(paginationPreviousLearningPathReportWithPageLeranerStart());
    return _prevLearnerLearningPathReportDetail(url).then((response) => {

        dispatch(paginationPreviousLearningPathReportWithPageLeranerSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousLearningPathReportWithPageLeranerError(error));
        toast.error('Something went wrong')

    });
};

///////////////////////////////////////////////////////////////////////////////////////////////

export const getLearningCourseCertificatesLearnerStart = createAction(ActionTypes.GET_LEARNER_COURSE_CERTIFICATE);
export const getLearningCourseCertificatesLearnerSuccess = createAction(ActionTypes.GET_LEARNER_COURSE_CERTIFICATE_SUCCESS, response => response);
export const getLearningCourseCertificatesLearnerError = createAction(ActionTypes.GET_LEARNER_COURSE_CERTIFICATE_ERROR, error => error);

export const getLearnerCourseCertificates = (callback) => (dispatch) => {
    dispatch(getLearningCourseCertificatesLearnerStart());
    return _getLearnerCourseCertificates().then((response) => {
        if(response){
            callback(response)
        }
        dispatch(getLearningCourseCertificatesLearnerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningCourseCertificatesLearnerError(error));
        toast.error('Something went wrong')
    });
};

export const getLearningReportCourseCertificatesWithPageLeranerStart = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_WITH_PAGE);
export const getLearningReportCourseCertificatestWithPageLeranerSuccess = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_WITH_PAGE_SUCCESS, response => response);
export const getLearningReportCourseCertificatesWithPageLeranerError = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_WITH_PAGE_SUCCESS_ERROR, error => error);

export const getLearnerCourseCertificateWithPage = (page) => (dispatch) => {
    dispatch(getLearningReportCourseCertificatesWithPageLeranerStart());
    return _getLearnerCourseCertificateWithPage(page).then((response) => {
        dispatch(getLearningReportCourseCertificatestWithPageLeranerSuccess(response));

    }).catch((error) => {
        dispatch(getLearningReportCourseCertificatesWithPageLeranerError(error));
        toast.error('Something went wrong')
    });
};

export const paginationCourseCertificateWithPageLeranerStart = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT);
export const paginationCourseCertificatetWithPageLeranerSuccess = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT_SUCCESS, response => response);
export const paginationCourseCertificateWithPageLeranerError = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT_ERROR, error => error);

export const nextLearnerCourseCertificates= (url) => (dispatch) => {
    dispatch(paginationCourseCertificateWithPageLeranerStart());
    return _nextLearnerCourseCertificates(url).then((response) => {
        dispatch(paginationCourseCertificatetWithPageLeranerSuccess(response));
    }).catch((error) => {
        dispatch(paginationCourseCertificateWithPageLeranerError(error));
        toast.error('Something went wrong')
    });
};

export const paginationPreviousCourseCertificateWithPageLeranerStart = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS);
export const paginationPreviousCourseCertificateWithPageLeranerSuccess = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS_SUCCESS, response => response);
export const paginationPreviousCourseCertificateWithPageLeranerError = createAction(ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS_ERROR, error => error);

export const previousLearnerCourseCertificate = (url) => (dispatch) => {
    dispatch(paginationPreviousCourseCertificateWithPageLeranerStart());
    return _prevLearnerCourseCertificates(url).then((response) => {
      dispatch(paginationPreviousCourseCertificateWithPageLeranerSuccess(response));
    }).catch((error) => {
        dispatch(paginationPreviousCourseCertificateWithPageLeranerError(error));
        toast.error('Something went wrong')

    });
};



/////////////////////////////////////////////////
const reducer = handleActions({
    //COURSE_DETAILS
    [ActionTypes.GET_LEARNER_LEARNINGPATH]: (state) => ({
        ...state, learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_LEARNINGPATH_SUCCESS]: (state, action) => ({
        ...state,
        learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_LEARNINGPATH_ERROR]: (state, action) => ({
        ...state,
        learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //    Learner Course Pagination Next
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_NEXT]: (state) => ({
        ...state,
        learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            loading: false, hasError: false, error: {}
        }
    }),
    //    Learner Course Pagination Previous
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        learner_LearningPath_list: {
            ...state.learner_LearningPath_list,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //
    [ActionTypes.LEARNER_ASSIGNED_LEARNING_Path]: (state) => ({
        ...state, learner_assinged_lps: {
            ...state.learner_assinged_lps,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_ASSIGNED_LEARNING_Path_SUCCESS]: (state, action) => ({
        ...state,
        learner_assinged_lps: {
            ...state.learner_assinged_lps,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_ASSIGNED_LEARNING_Path_ERROR]: (state, action) => ({
        ...state,
        learner_assinged_lps: {
            ...state.learner_assinged_lps,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),


    [ActionTypes.LEARNER_LEARNING_PATH_DETAIL]: (state) => ({
        ...state, learner_lp_detail: {
            ...state.learner_lp_detail,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        learner_lp_detail: {
            ...state.learner_lp_detail,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_DETAIL_ERROR]: (state, action) => ({
        ...state,
        learner_lp_detail: {
            ...state.learner_lp_detail,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    [ActionTypes.LEARNER_LEARNING_PATH_NODES]: (state) => ({
        ...state, learner_lp_nodes: {
            ...state.learner_lp_nodes,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_NODES_SUCCESS]: (state, action) => ({
        ...state,
        learner_lp_nodes: {
            ...state.learner_lp_nodes,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_NODES_ERROR]: (state, action) => ({
        ...state,
        learner_lp_nodes: {
            ...state.learner_lp_nodes,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    [ActionTypes.LEARNER_LEARNING_PATH_NODES_DETAIL]: (state) => ({
        ...state, learner_lp_node_detail: {
            ...state.learner_lp_node_detail,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_NODES_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        learner_lp_node_detail: {
            ...state.learner_lp_node_detail,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_NODES_DETAIL_ERROR]: (state, action) => ({
        ...state,
        learner_lp_node_detail: {
            ...state.learner_lp_node_detail,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    ///submit course
    [ActionTypes.LEARNER_LEARNING_PATH_COURSE_SUBMIT]: (state) => ({
        ...state, learner_submit_course: {
            ...state.learner_submit_course,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_COURSE_SUBMIT_SUCCESS]: (state, action) => ({
        ...state,
        learner_submit_course: {
            ...state.learner_submit_course,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_COURSE_SUBMIT_ERROR]: (state, action) => ({
        ...state,
        learner_submit_course: {
            ...state.learner_submit_course,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    ///submit quiz
    [ActionTypes.LEARNER_LEARNING_PATH_COURSE_QUIZ]: (state) => ({
        ...state, learner_submit_quiz: {
            ...state.learner_submit_quiz,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_COURSE_QUIZ_SUCCESS]: (state, action) => ({
        ...state,
        learner_submit_quiz: {
            ...state.learner_submit_quiz,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_PATH_COURSE_QUIZ_ERROR]: (state, action) => ({
        ...state,
        learner_submit_quiz: {
            ...state.learner_submit_quiz,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    [ActionTypes.LEARNER_LEARNING_CERTIFICATES]: (state) => ({
        ...state, learner_certificates: {
            ...state.learner_certificates,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_CERTIFICATES_SUCCESS]: (state, action) => ({
        ...state,
        learner_certificates: {
            ...state.learner_certificates,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_CERTIFICATES_ERROR]: (state, action) => ({
        ...state,
        learner_certificates: {
            ...state.learner_certificates,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //COURSE_DETAILS
    [ActionTypes.GET_LEARNER_LEARNINGPATH_COMPLETE]: (state) => ({
        ...state, learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_LEARNINGPATH_COMPLETE_SUCCESS]: (state, action) => ({
        ...state,
        learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_LEARNINGPATH_COMPLETE_ERROR]: (state, action) => ({
        ...state,
        learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //    Learner Course Pagination Next
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT]: (state) => ({
        ...state,
        learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_NEXT_ERROR]: (state, action) => ({
        ...state,

        learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            loading: false, hasError: false, error: {}
        }
    }),
    //    Learner Course Pagination Previous
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS]: (state) => ({
        ...state,
        learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_COMPLETE_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        learner_LearningPath_completed_list: {
            ...state.learner_LearningPath_completed_list,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),
    //COURSE_DETAILS
    [ActionTypes.GET_LEARNER_LEARNINGPATH_FAILED]: (state) => ({
        ...state, learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_LEARNINGPATH_FAILED_SUCCESS]: (state, action) => ({
        ...state,
        learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_LEARNINGPATH_FAILED_ERROR]: (state, action) => ({
        ...state,
        learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //    Learner Course Pagination Next
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT]: (state) => ({
        ...state,
        learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_NEXT_ERROR]: (state, action) => ({
        ...state,

        learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            loading: false, hasError: false, error: {}
        }
    }),
    //    Learner Course Pagination Previous
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS]: (state) => ({
        ...state,
        learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LEARNER_LEARNINGPATH_PAGINATION_FAILED_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        learner_LearningPath_failed_list: {
            ...state.learner_LearningPath_failed_list,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),
    //COURSE_DETAILS
    [ActionTypes.LEARNER_LEARNING_CERTIFICATE_DETAIL]: (state) => ({
        ...state, learner_certificate: {
            ...state.learner_certificate,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_CERTIFICATE_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        learner_certificate: {
            ...state.learner_certificate,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_LEARNING_CERTIFICATES_DETAIL_ERROR]: (state, action) => ({
        ...state,
        learner_certificate: {
            ...state.learner_certificate,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //COURSE_DETAILS
    [ActionTypes.LP_LEARNER_LP_EXTEND_LEARNER]: (state) => ({
        ...state, request_extend_date: {
            ...state.request_extend_date,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LP_LEARNER_LP_EXTEND_LEARNER_SUCCESS]: (state, action) => ({
        ...state,
        request_extend_date: {
            ...state.request_extend_date,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.LP_LEARNER_LP_EXTEND_LEARNER_ERROR]: (state, action) => ({
        ...state,
        request_extend_date: {
            ...state.request_extend_date,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),

    ///

    [ActionTypes.GET_LEARNER_ASSIGNMENTS_COURSES]: (state) => ({
        ...state, course_assignments: {
            ...state.course_assignments,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_ASSIGNMENTS_COURSES_SUCCESS]: (state, action) => ({
        ...state,
        course_assignments: {
            ...state.course_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        }
    }),
    [ActionTypes.GET_LEARNER_ASSIGNMENTS_COURSES_ERROR]: (state, action) => ({
        ...state,
        course_assignments: {
            ...state.course_assignments,
            error: action.payload, loading: false, hasError: true, response: {}
        }
    }),
    //    Learner Course Pagination Next
    [ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT]: (state) => ({
        ...state,
        course_assignments: {
            ...state.course_assignments,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,


        course_assignments: {
            ...state.course_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        course_assignments: {
            ...state.course_assignments,
            loading: false, hasError: false, error: {}
        }
    }),
    //    Learner Course Pagination Previous
    [ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        course_assignments: {
            ...state.course_assignments,
            loading: true, hasError: false, error: {}
        }

    }),
    [ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        course_assignments: {
            ...state.course_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LEARNER_ASSIGNMENTS_COURSES_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,
        course_assignments: {
            ...state.course_assignments,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),



    //Get Manger Learning path Nodes learner
    [ActionTypes.GET_LEARNER_ASSIGNMENTS_LP]: (state) => ({
        ...state,
        lp_assignments: {
            ...state.lp_assignments,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNER_ASSIGNMENTS_LP_SUCCESS]: (state, action) => ({
        ...state,
        lp_assignments: {
            ...state.lp_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNER_ASSIGNMENTS_LP_ERROR]: (state, action) => ({
        ...state,
        lp_assignments: {
            ...state.lp_assignments,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Get Manger Learning path Nodes learner
    [ActionTypes.LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE]: (state) => ({
        ...state,
        lp_assignments: {
            ...state.lp_assignments,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE_SUCCESS]: (state, action) => ({
        ...state,
        lp_assignments: {
            ...state.lp_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_ASSIGNMENT_WITH_PAGE_SUCCESS_ERROR]: (state, action) => ({
        ...state,
        lp_assignments: {
            ...state.lp_assignments,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    //Get Manger Learning path Nodes learner
    [ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT]: (state) => ({
        ...state,
        lp_assignments: {
            ...state.lp_assignments,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,

        lp_assignments: {
            ...state.lp_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        lp_assignments: {
            ...state.lp_assignments,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    [ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        lp_assignments: {
            ...state.lp_assignments,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        lp_assignments: {
            ...state.lp_assignments,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_ASSIGNMENTS_LP_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        lp_assignments: {
            ...state.lp_assignments,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    ////////////////////////////////////
    //Get Manger Learning path Nodes learner
    [ActionTypes.GET_LEARNER_REPORT_LP]: (state) => ({
        ...state,
        lp_dashboard: {
            ...state.lp_dashboard,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNER_REPORT_LP_SUCCESS]: (state, action) => ({
        ...state,
        lp_dashboard: {
            ...state.lp_dashboard,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNER_REPORT_LP_ERROR]: (state, action) => ({
        ...state,
        lp_dashboard: {
            ...state.lp_dashboard,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Get Manger Learning path Nodes learner
    [ActionTypes.LP_LEARNER_ALL_REPORT_WITH_PAGE]: (state) => ({
        ...state,
        lp_dashboard: {
            ...state.lp_dashboard,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_REPORT_WITH_PAGE_SUCCESS]: (state, action) => ({
        ...state,
        lp_dashboard: {
            ...state.lp_dashboard,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LP_LEARNER_ALL_REPORT_WITH_PAGE_SUCCESS_ERROR]: (state, action) => ({
        ...state,
        lp_dashboard: {
            ...state.lp_dashboard,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    //Get Manger Learning path Nodes learner
    [ActionTypes.LEARNER_REPORT_LP_PAGINATION_NEXT]: (state) => ({
        ...state,
        lp_dashboard: {
            ...state.lp_dashboard,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_REPORT_LP_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,

        lp_dashboard: {
            ...state.lp_dashboard,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_REPORT_LP_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        lp_dashboard: {
            ...state.lp_dashboard,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    [ActionTypes.LEARNER_REPORT_LP_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        lp_dashboard: {
            ...state.lp_dashboard,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_REPORT_LP_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        lp_dashboard: {
            ...state.lp_dashboard,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_REPORT_LP_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        lp_dashboard: {
            ...state.lp_dashboard,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

///////////////////////////////////////////////////////////////////////////////////
    [ActionTypes.GET_LEARNER_COURSE_CERTIFICATE]: (state) => ({
        ...state,
        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNER_COURSE_CERTIFICATE_SUCCESS]: (state, action) => ({
        ...state,
        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.GET_LEARNER_COURSE_CERTIFICATE_ERROR]: (state, action) => ({
        ...state,
        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: {}, loading: false, hasError: false, error: action.payload
        },
    }),

    //Get Manger Learning path Nodes learner
    [ActionTypes.LEARNER_COURSE_CERTIFICATE_WITH_PAGE]: (state) => ({
        ...state,
        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_COURSE_CERTIFICATE_WITH_PAGE_SUCCESS]: (state, action) => ({
        ...state,
        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_COURSE_CERTIFICATE_WITH_PAGE_SUCCESS_ERROR]: (state, action) => ({
        ...state,
        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


    //Get Manger Learning path Nodes learner
    [ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT]: (state) => ({
        ...state,
        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT_SUCCESS]: (state, action) => ({
        ...state,

        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_NEXT_ERROR]: (state, action) => ({
        ...state,

        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),

    [ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS]: (state) => ({
        ...state,
        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: {}, loading:true, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS_SUCCESS]: (state, action) => ({
        ...state,

        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: action.payload, loading: false, hasError: false, error: {}
        },
    }),
    [ActionTypes.LEARNER_COURSE_CERTIFICATE_PAGINATION_PREVIOUS_ERROR]: (state, action) => ({
        ...state,

        lp_courses_cert: {
            ...state.lp_courses_cert,
            response: {}, loading: false, hasError: false, error: action.payload
        },

    }),


}, initialState);

export default reducer;

