import React, { useEffect, useState } from 'react';
import ScrollArea from 'react-scrollbar';
import Form from 'react-bootstrap/Form';
import HeaderSearch from '~/assets/images/icons/magnifiying-glass.svg';
import {getAllGroup, getMemberList} from "../../../../../services";
import { useDispatch, useSelector } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import {getGroupsWithOutPagination} from "../../../../../../learningpath/services";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import {checkTrialPlan, getNewMembers, addNewGroup, getAllGroups} from '../../../../../../team/services/index';
import Select from "react-select";

const GroupAssignBox = ({groupList,assignId,urlId,singleAssignment}) => {
    const dispatch = useDispatch();
    const response = useSelector(state => state?.learningpath?.groups_without_pagination?.response);
    const loading = useSelector(state => state?.learningpath?.groups_without_pagination?.loading);
    const responseMembers = useSelector(
        (state) => state.teams?.memberslist?.response || []
      );
      const { user } = useSelector((state) => state.auth);

    // const assignmentById = useSelector(state => state.course?.get_course_assigment_by_id?.response);
    const [state, setState] = useState({
        group: [],
        tempGroup: response?.results || [],
        selectedGroup: [],
        tempoGroup:[],
        groupId: [],
    });

    const [noPart,setNoPart] = useState(false);
    const [groupShow,SetGroupShow] = useState(false);
    const [nottrialPlan, setTrialPlan] = useState(true);
    const [selectedData, setData] = useState([]);
    const [newMembers, setNewMembers] = useState([]);
    const [newEntry, setNewEntry] = useState([]);
    const [memberExist, setMemberExist] = useState(false);
    const [searchGroup, setSearchGroup] = useState('');
    
    useEffect(() => {
        dispatch(getGroupsWithOutPagination(function (response){
            if(response){
            }
        },function (error){
        }));
    }, []);

    useEffect(() => {
        if(assignId && urlId){

            let {groupId} = state;
            let newArray = response?.results?.map(item => {
                let temp = {...item, isChecked: singleAssignment?.groups?.includes(item.id)};
                return temp
            })
            let selectedArray = newArray?.filter((el) => {
                return el.isChecked
            })
            let groupsId = selectedArray?.map((el) => el.id)
            groupList(groupsId)

            setState({...state, group: newArray,tempGroup:newArray, selectedGroup: selectedArray, groupId: groupsId})
        }else{
            setState({...state,group:response?.results,tempGroup:response?.results})
        }

    }, [response,assignId]);

    useEffect(() => {
        dispatch(
          checkTrialPlan(function (response) {
            if (response) {
              if (!response.status) {
                setTrialPlan(false);
              }
            }
          })
        );

      }, []);


      useEffect(() => {
        if(responseMembers?.results?.length){
          setNewMembers(responseMembers.results.slice(0, 5));
        }
      }, [responseMembers]);

    const searchMembers = (e) => {
         dispatch(getNewMembers(user?.token, e, ""));
    };

    const AddNew = (e) => {
        setNewEntry(e);
      };

      const handleCreateGroup = (values) => {
        let result = selectedData?.map((a) => a?.id);
        dispatch(addNewGroup(user?.token, values.groupname, result));
        setTimeout(() => {
          dispatch(getGroupsWithOutPagination());
        }, 1000);
        SetGroupShow(false);
        setSearchGroup('');
      };

    const addNewMembers = () => {
        const result = selectedData.find((member) => member?.id == newEntry?.id);
        if (result) {
            setMemberExist(true);
            setTimeout(() => {
            setMemberExist(false);
            }, 3000);
        }
        if (!result && newEntry?.id) {
            let tempObj = [];
            tempObj = selectedData;
            tempObj.push(newEntry);
            setData(tempObj);
        }
        setNewEntry([]);
    };

    const handleClick = (item, index) => {
        let {group, selectedGroup, groupId,tempGroup} = state;
        let tempArray =  JSON.parse(JSON.stringify(tempGroup))
        group[index].isChecked = !group[index].isChecked;

        let tempIndex = tempArray.findIndex((el)=>{
            return el.id == item.id
        })
        tempArray[tempIndex].isChecked = !tempArray[tempIndex].isChecked;

        if (selectedGroup.find((f) => f.id == item.id)) {
            selectedGroup = selectedGroup.filter((el) => el.id != item.id);
            groupId = groupId.filter((el) => el != item.id);
        } else {
            selectedGroup.push(item);
            groupId.push(item.id);
        }

        groupList(groupId)
        setState({...state, selectedGroup: selectedGroup, groupId: groupId,group: group,tempGroup:tempArray});
    };

    const handleRemovemember = (item) => {
        const tempselcted = selectedData.filter((member) => member?.id != item?.id);
        setData(tempselcted);
      };

    const handleRemove = (item,j) =>{
        const {selectedGroup,groupId,group,tempGroup} =state
        selectedGroup.splice(j,1)
        let tempArray =  JSON.parse(JSON.stringify(tempGroup))

        let tempIndextemp = tempArray.findIndex((el)=>{
            return el.id == item.id
        })
        let tempIndex = group.findIndex((el)=>{
            return el.id == item.id
        })
        if(tempIndex > -1) {
            group[tempIndex].isChecked = false
        }
        tempArray[tempIndextemp].isChecked = false
        let groupsId = selectedGroup?.map((el)=>el.id)
        groupList(groupsId)
        setState({...state,selectedGroup: selectedGroup,group: group,groupId:groupsId,tempGroup:tempArray})
    }

    const handleSearch = (e) => {
        let val = e.target.value;
        setSearchGroup(val);
        if (val.length > 0) {
            if(assignId && urlId) {
                let results = state?.tempGroup?.filter(item =>
                    item?.name.includes(val)
                );
                setState({...state, group: results})
            }else{
                let results = state?.tempGroup?.filter(item =>
                    item?.name.includes(val)
                );
                setState({...state, group: results})
            }
        } else {
            const {tempGroup} =state
            if(assignId && urlId){

                setState({...state,group: tempGroup})
            }else {
                setState({...state,group: tempGroup})
            }

        }

    }


    return (
        <div className="course-assignbox-wrap height-auto">
            <div className="course-form-content-sec d-flex">
                <div className="add-quiz-box whitebox shadow-box">
                    <div className="add-quiz-box-header">
                        <Form>
                            <div className="form-input-wrap">
                                <Form.Control type="text" value={searchGroup} placeholder="Select group" className="input-item" onChange={handleSearch}
                               />
                                {/* <button type="button" className="icon-btn">
                                    <img src={HeaderSearch} />
                                </button> */}
                            </div>
                        </Form>
                    </div>

                    <div className="add-quiz-box-content">
                        <ScrollArea
                            speed={0.8}
                            className="listscroll"
                            contentClassName="content"
                            horizontal={false}
                        >



                                <ul>

                                    {state?.group && state?.group && state?.group?.length ? state?.group?.map((el, index) => {

                                            return (
                                                <li onClick={() => handleClick(el, index)} key={index} className={`${el.isChecked && el.isChecked  ? 'list-selected' : ''}`}>
                                                    <span >{el?.name} </span>
                                                </li>
                                            );
                                        })
                                        :

                                        <div className='row justify-content-center mt-2'>
                                            {loading ?
                                                <Spinner animation="border" variant="danger"/>
                                                :
                                                (<div className='d-flex flex-column align-items-center'>
                                                {
                                                   searchGroup ? (
                                                    <> 
                                                    <p>No groups found</p>
                                                    </>
                                                   ) : (
                                                    <>
                                                        <p>No groups have been created yet</p>
                                                        <Button

                                                            onClick={() => {
                                                                nottrialPlan ? SetGroupShow(true) : setNoPart(true);
                                                            }}
                                                            >
                                                            Create Group
                                                        </Button> 
                                                    </>
                                                   )
                                                }
                                                   
                                            </div>)
                                            }

                                        </div>

                                    }

                                </ul>



                        </ScrollArea>
                    </div>

                </div>
                <div className="course-list-selected-box">
                    <div className="course-list-selected-box-content">
                        {state?.selectedGroup && state.selectedGroup.length ? state?.selectedGroup?.map((item, j) => {
                                return (
                                    <div className="course-selected-item p-relative">
                                        <span className="course-selected-text">{item.name}</span>
                                        <span className="memb-close-icon" onClick={()=>handleRemove(item,j)}>×</span>
                                    </div>
                                );
                            })
                            :
                            ''
                        }


                    </div>
                </div>
            </div>
            <Modal
                    className="custom-modal inner-popup letsgo-pop common-modal add-module-content-modal"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={noPart}
                    onHide={() => setNoPart(false)}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                    <div className="letsgo-pop-wrap  text-center">
                        <div className="nopart-img">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="339.178"
                            height="279.188"
                            viewBox="0 0 339.178 279.188"
                        >
                            <g
                            id="undraw_selected_options_re_vtjd"
                            transform="translate(0 0)"
                            >
                            <path
                                id="Path_8630"
                                data-name="Path 8630"
                                d="M521.214,657.958l-11.836,1.817,12.364-5.844c1-11.379-1.662-23.586-1.662-23.586s-25.536,4.441-33.4,16.138-3.655,20.457,4.924,26.223,18.278,6.357,26.141-5.341A24.721,24.721,0,0,0,521.214,657.958Z"
                                transform="translate(-373.301 -398.076)"
                                fill="#f2f2f2"
                            />
                            <path
                                id="Path_8631"
                                data-name="Path 8631"
                                d="M516.693,658.354l-.388.577c-4.54,6.679-9.185,11.149-13.878,13.34-.068.033-.137.062-.2.095l-.147-.318-.137-.308c5.133-2.322,9.782-7.358,13.743-13.182.13-.187.261-.376.387-.569,1.655-2.481,3.186-5.086,4.579-7.681.11-.2.218-.4.327-.609,1.82-3.435,3.4-6.827,4.689-9.824.092-.211.182-.425.269-.635,2.169-5.082,3.485-8.934,3.771-9.79.037-.1.055-.162.06-.175l.323.105h0l.326.107c0,.013-.066.209-.189.565-.454,1.336-1.724,4.938-3.665,9.509-.088.206-.178.419-.27.63-1.047,2.433-2.274,5.115-3.664,7.861q-.522,1.035-1.052,2.028c-.106.207-.214.411-.324.611Q519,654.9,516.693,658.354Z"
                                transform="translate(-382.741 -397.555)"
                                fill="#fff"
                            />
                            <path
                                id="Path_8632"
                                data-name="Path 8632"
                                d="M541.738,642.415c-.209-.1-.42-.208-.63-.32a18.978,18.978,0,0,1-1.666-1,18.457,18.457,0,0,1-5.617-5.947l-.669.213a19.141,19.141,0,0,0,5.9,6.305,19.606,19.606,0,0,0,1.78,1.064c.208.111.419.215.629.315a18.979,18.979,0,0,0,5.48,1.682q-.026-.359-.049-.7A18.4,18.4,0,0,1,541.738,642.415Z"
                                transform="translate(-397.916 -400.411)"
                                fill="#fff"
                            />
                            <path
                                id="Path_8633"
                                data-name="Path 8633"
                                d="M531.005,655q-.307-.182-.6-.38c-.062-.037-.125-.08-.185-.12a18.555,18.555,0,0,1-8.2-15.1c-.227.092-.455.183-.681.28a19.244,19.244,0,0,0,8.5,15.389c.08.054.16.107.242.158.2.131.4.258.605.382a19.142,19.142,0,0,0,10.459,2.721c.022-.23.044-.46.063-.691A18.428,18.428,0,0,1,531.005,655Z"
                                transform="translate(-392.17 -402.48)"
                                fill="#fff"
                            />
                            <path
                                id="Path_8634"
                                data-name="Path 8634"
                                d="M521.028,665.2c-.211-.117-.419-.24-.624-.366s-.438-.274-.652-.417a18.605,18.605,0,0,1-7.336-21.06c-.284.145-.566.287-.844.436a19.287,19.287,0,0,0,7.8,21.2c.214.144.43.285.649.415s.414.253.623.374a19.23,19.23,0,0,0,13.989,1.939c.082-.251.154-.506.227-.763A18.545,18.545,0,0,1,521.028,665.2Z"
                                transform="translate(-387.076 -404.401)"
                                fill="#fff"
                            />
                            <path
                                id="Path_682"
                                data-name="Path 682"
                                d="M304.579,338.007a13.358,13.358,0,1,1,13.358-13.358h0a13.374,13.374,0,0,1-13.358,13.359Zm0-25.467a12.108,12.108,0,1,0,12.108,12.108h0a12.122,12.122,0,0,0-12.108-12.107Z"
                                transform="translate(-280.306 -242.976)"
                                fill="#b5b5b5"
                            />
                            <path
                                id="Path_8635"
                                data-name="Path 8635"
                                d="M587.557,337.993a13.892,13.892,0,1,1,9.8-4.066,13.892,13.892,0,0,1-9.8,4.066Zm0-26.716A12.836,12.836,0,1,0,600.4,324.119a12.836,12.836,0,0,0-12.847-12.843Z"
                                transform="translate(-417.599 -242.449)"
                                fill="#b5b5b5"
                            />
                            <path
                                id="Path_682-2"
                                data-name="Path 682"
                                d="M878.947,326.421a5.58,5.58,0,1,0,5.584,5.584,5.58,5.58,0,0,0-5.584-5.584Z"
                                transform="translate(-563.303 -250.331)"
                                fill="#ce002a"
                            />
                            <path
                                id="Path_680"
                                data-name="Path 680"
                                d="M839.609,178.35a2.122,2.122,0,1,0,0,4.244H882.66a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-545.859 -178.35)"
                                fill="#6c63ff"
                            />
                            <path
                                id="Path_681"
                                data-name="Path 681"
                                d="M839.609,202.682a2.122,2.122,0,0,0,0,4.244H882.66a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-545.859 -190.178)"
                                fill="#6c63ff"
                            />
                            <path
                                id="Path_681-2"
                                data-name="Path 681"
                                d="M839.609,227.271a2.122,2.122,0,0,0,0,4.244H882.66a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-545.859 -202.132)"
                                fill="#6c63ff"
                            />
                            <path
                                id="Path_8636"
                                data-name="Path 8636"
                                d="M407.813,454.656a5.167,5.167,0,0,0-1.534-7.774l3.076-18.1-8.734,3.845-1.582,16.6a5.2,5.2,0,0,0,8.774,5.431Z"
                                transform="translate(-332.482 -300.089)"
                                fill="#ffb6b6"
                            />
                            <path
                                id="Path_8637"
                                data-name="Path 8637"
                                d="M394.223,371.77,392.63,326.5v-.031l8.549-41.555a6.477,6.477,0,0,1,12.71,2.5l-6.635,35.273-.141,49.377Z"
                                transform="translate(-329.602 -227.637)"
                                fill="#ce002a"
                            />
                            <path
                                id="Path_8649"
                                data-name="Path 8649"
                                d="M394.223,371.77,392.63,326.5v-.031l8.549-41.555a6.477,6.477,0,0,1,12.71,2.5l-6.635,35.273-.141,49.377Z"
                                transform="translate(-329.602 -227.637)"
                                opacity="0.1"
                            />
                            <path
                                id="Path_8638"
                                data-name="Path 8638"
                                d="M208.046,506.769h6.3l3-24.3h-9.3Z"
                                transform="translate(-101.135 -234.54)"
                                fill="#ffb6b6"
                            />
                            <path
                                id="Path_8639"
                                data-name="Path 8639"
                                d="M474.9,704.1h12.408a7.907,7.907,0,0,1,7.907,7.906v.257H474.9Z"
                                transform="translate(-369.596 -433.932)"
                                fill="#2f2e41"
                            />
                            <path
                                id="Path_8640"
                                data-name="Path 8640"
                                d="M155.046,506.769h6.3l3-24.3h-9.3Z"
                                transform="translate(-75.371 -234.54)"
                                fill="#ffb6b6"
                            />
                            <path
                                id="Path_8641"
                                data-name="Path 8641"
                                d="M421.9,704.1h12.407a7.907,7.907,0,0,1,7.907,7.906v.257H421.9Z"
                                transform="translate(-343.831 -433.932)"
                                fill="#2f2e41"
                            />
                            <path
                                id="Path_8642"
                                data-name="Path 8642"
                                d="M449.118,378.177l2.4,5.156,7.222,67.153-7.1,89.147-12.544.412,2.167-82.365-15.529-33.391-.915,117.153H413.858L403.491,423.88s-3.575-32.709,9.4-45.7Z"
                                transform="translate(-334.663 -275.491)"
                                fill="#2f2e41"
                            />
                            <path
                                id="Path_8643"
                                data-name="Path 8643"
                                d="M415.844,327.057l-.19-.025-6.317-39.372a11.294,11.294,0,0,1,6.743-12.2l6.53-2.777,20.411-.182,8.1,4.834a18.561,18.561,0,0,1,8.9,18.337l-4.747,36.58Z"
                                transform="translate(-337.654 -224.117)"
                                fill="#ce002a"
                            />
                            <path
                                id="Path_8644"
                                data-name="Path 8644"
                                d="M495.441,462.1a5.167,5.167,0,0,1,1.43-7.794l-3.318-18.061,8.784,3.727,1.8,16.58a5.2,5.2,0,0,1-8.7,5.548Z"
                                transform="translate(-378.664 -303.719)"
                                fill="#ffb6b6"
                            />
                            <path
                                id="Path_8645"
                                data-name="Path 8645"
                                d="M478.945,333.549l-.005-.03,2.779-42.334a6.512,6.512,0,0,1,5.057-5.9h0a6.477,6.477,0,0,1,7.876,6.636l-1.743,35.849,6.621,48.931-12.808,1.475Z"
                                transform="translate(-371.56 -230.259)"
                                fill="#ce002a"
                            />
                            <circle
                                id="Ellipse_321"
                                data-name="Ellipse 321"
                                cx="13.478"
                                cy="13.478"
                                r="13.478"
                                transform="translate(84.203 18.066)"
                                fill="#ffb6b6"
                            />
                            <path
                                id="Path_8646"
                                data-name="Path 8646"
                                d="M411.662,240.414a50.7,50.7,0,0,1,4.744-3.041,6.824,6.824,0,0,0,3.26-4.4c.311-2.237-1.126-4.284-2.049-6.344A14.746,14.746,0,0,1,442.3,211.044a5.627,5.627,0,1,1,6.686,8.6c-2.208.95-4.9.445-6.973,1.659-2.166,1.267-2.985,4.11-2.6,6.59a18.972,18.972,0,0,0,3.047,6.825l-2.909-4.422c1.59,3.365,3.207,6.831,3.448,10.544s-1.207,7.784-4.461,9.59c-2.852,1.583-6.77,1.335-8.856,3.843a5.669,5.669,0,0,0-1.206,3.708c-3.143-.484-6.3-.91-9.446-1.355l-10.506-1.485a21.709,21.709,0,0,1-.673-3.43C407.407,247.6,408.512,243.095,411.662,240.414Z"
                                transform="translate(-336.958 -191.787)"
                                fill="#2f2e41"
                            />
                            <path
                                id="Path_680-2"
                                data-name="Path 680"
                                d="M556.106,178.35a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-408.041 -178.35)"
                                fill="#b5b5b5"
                            />
                            <path
                                id="Path_681-3"
                                data-name="Path 681"
                                d="M556.106,202.682a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-408.041 -190.178)"
                                fill="#b5b5b5"
                            />
                            <path
                                id="Path_681-4"
                                data-name="Path 681"
                                d="M556.106,227.271a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-408.041 -202.132)"
                                fill="#b5b5b5"
                            />
                            <path
                                id="Path_680-3"
                                data-name="Path 680"
                                d="M273.106,178.35a2.122,2.122,0,0,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-270.468 -178.35)"
                                fill="#b5b5b5"
                            />
                            <path
                                id="Path_681-5"
                                data-name="Path 681"
                                d="M273.106,202.682a2.122,2.122,0,0,0,0,4.245h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-270.468 -190.178)"
                                fill="#b5b5b5"
                            />
                            <path
                                id="Path_681-6"
                                data-name="Path 681"
                                d="M273.106,227.271a2.122,2.122,0,0,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-270.468 -202.132)"
                                fill="#b5b5b5"
                            />
                            <path
                                id="Path_680-4"
                                data-name="Path 680"
                                d="M840.106,178.35a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-546.101 -178.35)"
                                fill="#ce002a"
                            />
                            <path
                                id="Path_681-7"
                                data-name="Path 681"
                                d="M840.106,202.682a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-546.101 -190.178)"
                                fill="#ce002a"
                            />
                            <path
                                id="Path_681-8"
                                data-name="Path 681"
                                d="M840.106,227.271a2.122,2.122,0,1,0,0,4.244h43.051a2.122,2.122,0,1,0,0-4.244Z"
                                transform="translate(-546.101 -202.132)"
                                fill="#ce002a"
                            />
                            <path
                                id="Path_8647"
                                data-name="Path 8647"
                                d="M460.363,720.492H270.592a.612.612,0,1,1,0-1.224H460.363a.612.612,0,1,1,0,1.224Z"
                                transform="translate(-269.98 -441.304)"
                                fill="#cacaca"
                            />
                            <path
                                id="Path_8648"
                                data-name="Path 8648"
                                d="M870.128,338.091a14.9,14.9,0,1,1,14.9-14.9A14.9,14.9,0,0,1,870.128,338.091Zm0-28.772A13.872,13.872,0,1,0,884,323.191a13.872,13.872,0,0,0-13.872-13.872Z"
                                transform="translate(-554.484 -241.517)"
                                fill="#ce002a"
                            />
                            </g>
                        </svg>
                        </div>
                        <h3>
                        To access this feature, please subscribe to any of our plans.
                        </h3>
                        <div className="button-wrap double-btn no-part-btns">
                        <button
                            type="button"
                            onClick={() => setNoPart(false)}
                            className="btn border-btn"
                        >
                            Cancel
                        </button>
                        {/* <Button
                            variant="primary"
                            onClick={() => history.push("/profile")}
                        >
                            Choose Plan
                        </Button> */}
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>

        <Modal
        size="md"
        show={groupShow}
        // scrollable={true}
        backdrop="static"
        // onEntered={() => handleGroupEntry()}
        onHide={() => SetGroupShow(false)}
        // onExited={() => handleGroupExit()}
        aria-labelledby="example-modal-sizes-title-lg"
        className="common-modal team-member-pop inner-popup"
      >
        <Modal.Header closeButton>
          <div className="addmember-header">
            <h4>Create Group</h4>
            <p>Adding Members shall create a group</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-input-wrap">
            <Formik
              initialValues={{ groupname: "" }}
              validationSchema={Yup.object({
                groupname: Yup.string()
                  .required("Required")
              })}
              onSubmit={handleCreateGroup}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit} className="">
                  <div className="form-input-wrap ">
                    <div className="form-group">
                      <label>Group Title</label>
                      <div className="d-flex justify-content-between">
                        <input
                          className="form-control input-item w-75"
                          type="text"
                          placeholder="Enter Group Name"
                          onChange={handleChange}
                          value={values.groupname}
                          name="groupname"
                        />
                        <div className=" d-flex justify-content-end">
                          {/* <Button variant="danger" type="submit"  >Create</Button> */}
                          <div className="group-title-create-right">
                            <Button type="submit">Create</Button>
                          </div>
                        </div>
                      </div>
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        {errors.groupname}
                      </span>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="group-list-selected-box-content">
            {selectedData && selectedData?.length
              ? selectedData?.map((item, index) => (
                  <div key={index} className="course-selected-item p-relative">
                    <span className="course-selected-text">
                      {item?.get_full_name?.length > 1
                        ? item?.get_full_name
                        : item?.id}
                    </span>
                    <span
                      className="memb-close-icon"
                      onClick={() => handleRemovemember(item)}
                    >
                      ×
                    </span>
                  </div>
                ))
              : ""}
          </div>
          <div className="group-pop-footer">
            <h4>Add New Members To The Group</h4>
            <div className="form-input-wrap">
              <Form.Label>Search and Select Email Address</Form.Label>
              <div className="d-flex w-100">
                <div className="group-title-create-left">
                  <Select
                    placeholder="Enter Email Address"
                    isClearable={true}
                    onChange={AddNew}
                    onInputChange={searchMembers}
                    options={newMembers || []}
                    getOptionLabel={(option) => option?.email}
                    getOptionValue={(option) => option?.id}
                  />
                </div>
                <div className="group-title-create-right">
                  <Button
                    onClick={addNewMembers}
                    disabled={newEntry?.length <= 0 ? "disabled" : ""}
                  >
                    Add
                  </Button>
                </div>
              </div>
              {memberExist ? (
                <span style={{ color: "#ce002a" }}>
                  Member Already selected{" "}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
        </div >
    );
};
export default GroupAssignBox;
