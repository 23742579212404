import React, { useState ,useEffect} from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { PageItem } from "react-bootstrap"; 
import Select, { components } from "react-select" ;
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory,useParams, useLocation} from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import {  getGroupDetail, getGroupCourses,getGroupLP, deleteGroupCourses,deleteGroupLP,
          getGroupSurvey,deleteGroupSurvey,
          getAllGroups,removeGroup,handlePagination_Survey, handlePreviousPagination_Survey,handlePagination_Courses_Groups,
          handlePreviousPagination_Courses_Groups, handlePagination_LP_Groups, handlePreviousPagination_LP_Groups ,getMembersList,updateGroups,getLP
} from '../../services';
import Modal from 'react-bootstrap/Modal';
// import SkillAssignBox from '../../utilities/skillassign';
import {Formik} from "formik";
import {getNotifications} from "../../../Notifications/services";
import SkillAssignBox from '../../utilities/groupAssign';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Helmet} from "react-helmet";


function RemoveCourse(props) {
   return (
       <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
              {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered

       >
           <Modal.Body>
               <div className="modal-content-wrap text-center">
                   <h3 className="modal-title">Remove Course?</h3>
                   {/* <p>Are You Sure To Delete The Members? </p> */}
                   <div className="d-flex justify-content-center buttons-wrap double-btn">

                       <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                           need to recheck
                       </button>
                       <Link  className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes, Continue</Button></Link>
                   </div>
               </div>
           </Modal.Body>
       </Modal>
   );
}

function RemoveSurvey(props) {

   return (
       <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
              {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered

       >
           <Modal.Body>
               <div className="modal-content-wrap text-center">
                   <h3 className="modal-title">Remove Survey ?</h3>
                   {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
                   <div className="d-flex justify-content-center buttons-wrap double-btn">

                       <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                           need to recheck
                       </button>
                       <Link  className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes, Continue</Button></Link>
                   </div>
               </div>
           </Modal.Body>
       </Modal>
   );
}
function RemoveLearningPath(props) {

   return (
       <Modal className="custom-modal confirmation-popup delete-confirmation inner-popup"
              {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered

       >
           <Modal.Body>
               <div className="modal-content-wrap text-center">
                   <h3 className="modal-title">Remove Learning Path ?</h3>
                   {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
                   <div className="d-flex justify-content-center buttons-wrap double-btn">

                       <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                           need to recheck
                       </button>
                       <Link  className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes, Continue</Button></Link>
                   </div>
               </div>
           </Modal.Body>
       </Modal>
   );
}

function RemoveMember(props) {

   return (
       <Modal className="custom-modal confirmation-popup inner-popup delete-confirmation"
              {...props}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
       >
           <Modal.Body>
               <div className="modal-content-wrap text-center">
                   <h3 className="modal-title">Delete The Group?</h3>
                   {/* <p>Are You Sure To Remove Manger Access For these Members? </p> */}
                   <div className="d-flex justify-content-center buttons-wrap double-btn">

                       <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                           need to recheck
                       </button>
                       <Link  className="m-l-20"><Button onClick={props.handlesubmit} type="button">Yes, Continue</Button></Link>
                   </div>
               </div>
           </Modal.Body>
       </Modal>
   );
}

const GroupDetails = (props) => {

   const { user } = useSelector(state => state.auth);
   const dispatch = useDispatch();
   const history=useHistory()
   const[dataColumns,setDataColumns]=useState([] )
   const [data, setData] = useState([]);
   const[filter,setFilter]=useState('Courses');
   const {id} = useParams();
   const urlId = props?.match?.params?.id;
   const [assign, assignShow] = useState(false);
   const groupDetails = useSelector(state => state.teams. groupDetails?.response|| [] ||[]);
   const responseCourses = useSelector(state => state.teams.groupCourses?.response|| [] ||[]);
   const responseLP = useSelector(state => state.teams. groupLP?.response|| [] ||[]);
   const responseSurvey = useSelector(state => state.teams. groupSurvey?.response|| [] ||[]);
   const { hideLpQuizes } = useSelector((state) => state.settings);

   const loading = useSelector(state => state?.teams?. group_list?.loading);
   const lploading = useSelector(state => state?.teams?. groupLP?.loading);
   const surveyloading = useSelector(state => state?.teams?.groupSurvey?.loading);
   const Courseloading = useSelector(state => state?.teams?.groupCourses?.loading);
   const[rowId,setRowId]=useState('')
   const[count,setCount]=useState('')
   const[groupMembers,setMembers]=useState([])
   const[newMembers,setNewMembers]=useState([])
   const[newEntry,setNewEntry]=useState([])
   const [editGroup, editGroupShow] = useState(false);
   const [name, setName] = useState('')
   const responseMembers = useSelector(state => state.teams.members_list?.response || []);
   const[dataProgress,setDataProgress]=useState(false)
   const[memberExist,setMemberExist]=useState(false)
   const [activePage, setActivePage] = useState(1); 
    const [activeLPPage, setActiveLPPage] = useState(1);
    const [activeCoursesPage, setActiveCoursesPage] = useState(1);
    const [state, setState] = useState({
      groupCourses: responseCourses,
      groupLP: responseLP,
      courses:[],
      countCourses: responseCourses?.count,
      countLP : responseLP?.count,
      paginationGroupLPitem: [],
      paginationGroupCoursesItem: [],
      paginationItem: [],
      });

   const[courses,setCourses]=useState([]);
   let location = useLocation();

   const[group,setGroupDetails]=useState([])
   const [search, setSearch] = useState('');
   const modalExit=()=>{
      dispatch(getNotifications())
   }

   const [modalShow, setModalShow] = React.useState(false);
   const handleModel = (values) =>{
     if(values){
         setModalShow(true)
     }

  }

  const [modalSurvey, setModalSurvey] = React.useState(false);
  const handleModalSurvey = (values) =>{
    if(values){
        setModalSurvey(true)
    }

 }

useEffect(()=>{
   if(!hideLpQuizes){
      dispatch(getLP())
   }
     
},[])
 const [modalLP, setModalLP] = React.useState(false);
 const handleModelLP = (values) =>{
   if(values){
       setModalLP(true)
   }

}

const [modalDEL, setModalDEL] = React.useState(false);
const handleModelDEL = (values) =>{
  if(values){
      setModalDEL(true)
  }

}
//pagination
useEffect(() => {

   const countCourses = responseCourses?.count
   const perPageCourses = 12;
   const buttonsCountCourses = Math.ceil(countCourses / perPageCourses);
   let itemsCourses = []
   for (let number = 1; number <= Number(buttonsCountCourses); number++) {
       itemsCourses.push(
           <Pagination.Item onClick={() => handleGroupCoursesIndex(number)} key={`${number}.courses`} active={number == activeCoursesPage}>

               {number}
           </Pagination.Item>
       );
   }
   setState({ ...state, groupCourses: responseCourses, countCourses: responseCourses?.count, paginationGroupCoursesItem: itemsCourses });


}, [responseCourses]);

useEffect(() => {
   const countLP = responseLP?.count
   const perPageLp = 12;
   const buttonsCount = Math.ceil(countLP / perPageLp);
   let itemsLp = []
   for (let number = 1; number <= Number(buttonsCount); number++) {
       itemsLp.push(
           <Pagination.Item onClick={() => handleLpIndex(number)} key={`${number}.LP`} active={number == activeLPPage}>

               {number}
           </Pagination.Item>
       );
   }
   setState({ ...state, groupLP: responseLP, countLP: responseLP?.count, paginationGroupLPitem: itemsLp});
}, [responseLP]);

const handleGroupCoursesIndex = (index) => {
        
   if (index == 1) {
       dispatch(getGroupCourses(user?.token,id,search));
   }
   else {
       dispatch(getGroupCourses(user?.token, id, search, index));
       // dispatch(handlePagination_Courses(responseCourses?.next))
       // dispatch(getMemberCourses( search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, index));
   }
   setActiveCoursesPage(index);
}

const handleLpIndex = (index) => {

   if (index == 1) {
       dispatch(getGroupLP(user?.token, id, search));

   }
   else {
       dispatch(getGroupLP(user?.token, id, '', index));
       // dispatch(getMembersList( search ? search : '', startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, index));
   }
   setActiveLPPage(index);
}
const handleNext = () => {

   if(filter=='Courses')
   {
   if ( responseCourses?.next) {
   var regex = /page=\d+/g;
      const [number ] = state.groupCourses?.next?.match(regex)
      setActiveCoursesPage(prevVal => prevVal === state.paginationGroupCoursesItem ? prevVal : prevVal + 1);
     // dispatch(handlePaginationLearnerCourse(state.courses?.next))
           
       dispatch(handlePagination_Courses_Groups(responseCourses?.next))
      // dispatch(handlePagination_Courses(state.memberCourses?.next))

   } else {
       return false   }
   }

   if(filter=='Learning Path')
   {
       //if(responseLP?.next)
         if ( responseLP?.next) 
         {
           var regex = /page=\d+/g;
           const [number ] = responseLP?.next?.match(regex)
           setActiveLPPage(prevVal => prevVal === state.paginationGroupLPitem ? prevVal : prevVal + 1); 
           dispatch(handlePagination_LP_Groups( responseLP?.next))
           } 
           else {
          return false               
        }
   }

}

const handlePrevious = () => {
   if(filter=='Courses')
   {
       if ( responseCourses?.previous) {
              setActiveCoursesPage((prevVal) => (prevVal - 1 === 0 ? prevVal : prevVal - 1));
           dispatch(handlePreviousPagination_Courses_Groups(responseCourses?.previous))

         } else {
        return false          
            }
 } 

 if(filter=='Learning Path')
 {
       if ( responseLP?.previous) {
          setActiveLPPage((prevVal) => (prevVal - 1 === 0 ? prevVal : prevVal - 1));
           dispatch(handlePreviousPagination_LP_Groups( responseLP?.previous))

         } else {
        return false             
      }
 }

}

// const handleNext = () => {
//    if(filter=='Courses')
//    {
//    if ( responseCourses?.next) {
//        dispatch(handlePagination_Courses_Groups( responseCourses?.next))
//    } else {
//        return false   }

//    }
//    if(filter=='Learning Path')
//    {
//          if ( responseLP?.next) {
//              dispatch(handlePagination_LP_Groups( responseLP?.next))
//            } else {
//           return false                }
//    }
//    if(filter=='Survey')
//    {
//          if ( responseSurvey?.previous) {
//              dispatch(handlePagination_Survey( responseSurvey?.next))
//            } else {
//           return false                }
//    }
// }

// const handlePrevious = () => {
//    if(filter=='Courses')
//    {
//          if ( responseCourses?.previous) {
//              dispatch( handlePreviousPagination_Courses_Groups( responseCourses?.previous))
//            } else {
//           return false                }
//    }
//    if(filter=='Learning Path')
//    {
//          if ( responseLP?.previous) {
//              dispatch(handlePreviousPagination_LP_Groups( responseLP?.previous))
//            } else {
//           return false                }
//    }
//    if(filter=='Surveys')
//    {
//          if ( responseSurvey?.previous) {
//              dispatch(handlePreviousPagination_Survey( responseSurvey?.previous))

//            } else {
//           return false                }
//    }

// }

useEffect(() => {
   let element = document.getElementById('headerTitle')
   if(element){
   //   switch (history.location.pathname){
   //      case '/team':
           element.innerHTML = 'Teams'
   //         break;

   //     default :
   //        'TEAM'

   //   }
 }
 dispatch(getMembersList(user?.token,'',''));
 

}, []);

useEffect(() => {
   if(responseMembers?.results?.length){
      setNewMembers(responseMembers.results.slice(0, 5));
    }
}, [responseMembers]);

   useEffect(() => {

      setDataColumns( tableColumnsCourses);
      if(!hideLpQuizes){
         setTimeout(()=>{
            dispatch( getGroupLP(user?.token,id,''));
        },100);
 
      }
       
      // dispatch(getGroupSurvey(user?.token,id,''));
       dispatch(getGroupDetail(user?.token,id))
       dispatch( getGroupCourses(user?.token,id,''));
       setFilter('Courses')
      setGroupDetails(groupDetails);
       setCourses(responseCourses.results);

       setData(responseCourses.results)



      return () => {

      }
   }, [])


useEffect(() => {
 if( !Courseloading){  setData(responseCourses.results) }

   return () => {

   }
}, [])

useEffect(() => {

   setGroupDetails(groupDetails);

}, [groupDetails])

   useEffect(() => {

      setGroupDetails(groupDetails);

      setData(responseCourses.results);
      setDataColumns( tableColumnsCourses)
      setFilter('Courses')

      setCount(responseCourses.count)
   }, [responseCourses.results])

   useEffect(() => {
      if(!hideLpQuizes){

      setGroupDetails(groupDetails);

       setData(responseLP.results)
      setDataColumns( tableColumnsLearningPath)
       setFilter('Learning Path')


      setCount(responseLP.count)
      }
   }, [responseLP.results])

   // useEffect(() => {
   //
   //    setGroupDetails(groupDetails);
   //
   //     setData(responseSurvey.results);
   //    setDataColumns(tableColumnsSurveys);
   //    setFilter('Surveys')
   //
   //    setCount(responseSurvey.count)
   //
   //  }, [responseSurvey.results])


   useEffect(() => {
      if(loading||lploading||surveyloading||Courseloading){ setDataProgress(true)}

     if (!loading&& !lploading&& !surveyloading && !Courseloading){setDataProgress(false)}


    }, [loading,lploading,surveyloading,Courseloading])


const handleGroupsFilter=(e)=>{
  
    const sch='';

    if(e.value=='Survey')
          {  dispatch(getGroupSurvey(user?.token,id,sch));

            setData(responseSurvey.results);
             setDataColumns(tableColumnsSurveys);
             setFilter('Surveys')
             setCount(responseSurvey.count)

         }
     if(e.value=='Courses')
         {
            dispatch( getGroupCourses(user?.token,id,sch));
            setData(responseCourses.results);
            setDataColumns( tableColumnsCourses)
            setFilter('Courses')
           setCount(responseCourses.count)

          }

       if(e.value=='Learning Path')
         { dispatch( getGroupLP(user?.token,id,sch));
            setData(responseLP.results);
           setDataColumns( tableColumnsLearningPath)
           setFilter('Learning Path')
           setCount(responseLP.count)

        }        }


        const deleteSingleGroup=()=>{
         dispatch( removeGroup(user?.token,id))

         setTimeout(() => {
         dispatch( getAllGroups(user?.token,'',''))
      }, 1000)
         history.push('/team')
       }


        const searchInput = (e) =>
        {
      setSearch(e.target.value);

      if(filter=='Learning Path'){
         dispatch( getGroupLP(user?.token,id,e.target.value));

      }
      if(filter=='Courses'){
         dispatch( getGroupCourses(user?.token,id,e.target.value));
         setActiveCoursesPage(1)

      }
      if(filter=='Surveys'){
         dispatch(getGroupSurvey(user?.token,id,e.target.value));


      }         }


      const reset=()=>{
         setSearch('');

         if(filter=='Learning Path'){
            dispatch( getGroupLP(user?.token,id,''));

         }
         if(filter=='Courses'){
            dispatch( getGroupCourses(user?.token,id,''));
            setActiveCoursesPage(1)

         }
         if(filter=='Surveys'){
            dispatch(getGroupSurvey(user?.token,id,''));

         }

      }


        const options = [
         { value: 'Courses', label: 'Courses' },
         // { value: 'Survey', label: 'Surveys' },
         { value: 'Learning Path', label: 'Learning Path' }
      ]


      const handleCallback = (childData) =>{
       if(childData) {
          assignShow(false)

      }
     }

    //  const handlegoBack = () =>{
    //     history.push({
    //        pathname: `/groupdetails/${urlId}`,
    //       // pathname: `/team`,
    //        state: {tab:  'Groups'}
          
    //     })
    //     console.log('state', state.courses)
    //     console.log('stategroupcourses', state.groupCourses)
    //     console.log('stategrouplp', state.groupLP)
        
        
    //  }

    //  const handlegoBack = () =>{
    //     history.push({
    //        pathname: "/team",
    //        state: {tab: "Groups" }
    //     })
    //  }

const deleteCourse=()=>{
   setModalShow(false)

   const formdata = new FormData();
   formdata.append('course_assign_id',rowId);

   dispatch(deleteGroupCourses(user?.token,id,formdata))
   const pgnumber = responseCourses?.results?.length > 1 ?  activeCoursesPage : activeCoursesPage > 1 ? activeCoursesPage - 1 : '' ;
   setTimeout(() => {
   dispatch(getGroupCourses(user?.token,id,search,pgnumber))
   setActiveCoursesPage(pgnumber)
}, 1000)

}



const handleDeleteLP=()=>{
setModalLP(false)

 const formdata = new FormData() ;
 formdata.append("learningpath_id",rowId);
dispatch(deleteGroupLP(user?.token,id,formdata))
setTimeout(() => {
dispatch( getGroupLP(user?.token,id,''));
}, 1000)

}


const handleDeleteSurvey=()=>{
setModalSurvey(false)

   dispatch(deleteGroupSurvey(user?.token,id,rowId))

   setTimeout(() => {
   dispatch( getGroupSurvey(user?.token,id,''));
}, 1000)


   }

   const handleRemove = (item,index)=>
   {
      const tempselcted = groupMembers.filter(member=> member?.id!= item?.id)
       setMembers(tempselcted)

  }


   const AddNew=(e)=>{

      setNewEntry(e)

   }


// searching for members to add to a group
const searchMembers=(e)=>{

   dispatch(getMembersList(user?.token,e,''))
   setNewMembers(responseMembers.results)

}



   const updateGroup=()=>{
      const selectlist =groupMembers.map(a => a.id);
     let tempObj={
        name: name,
        learners: selectlist
     }

        dispatch(updateGroups(id,tempObj))

        setTimeout(() => {
           dispatch(getGroupDetail(user?.token,id))

        }, 1000)

        editGroupShow(false)}


   //edit group

 const handleEdit=()=>{

   setName(groupDetails?.name)
   const learners=groupDetails?.learners_detail;

   const newArray=[];

   for (var i = 0; i < learners.length; i++) {
    newArray.push({
           id:learners[i].id,
         get_full_name:learners[i].get_full_name
       });   }

   setMembers(newArray)
   }


      const handleGroupNameChange=(e)=>{
      var value=e.target.value;
   
      setName(value)
      }


   //searching and adding new members to existing group
const addNewMembers=()=>{
   const result =groupMembers .find(member=> member?.id == newEntry?.id);
   if(result )
   {
       setMemberExist(true)
       setTimeout(() => {
         setMemberExist(false)
       }, 3000);
   }


   if((!result) &&  (newEntry?.id)){
   let tempObj=[]
    tempObj=groupMembers;
   tempObj.push(newEntry)
   setMembers(tempObj );

 }


}


   const tableColumnsCourses = [
      {
         name: 'Course Name',
         sortable: true,
         center: false,
         // cell:row => <Link to={`/commingsoon`}>{row.course_name}</Link>,
         grow: 3,
          cell: row =>
              <OverlayTrigger
                  overlay={
                      <Tooltip id="InfoText">
                          {row?.course_name}
                      </Tooltip>
                  }
              >
                  <div>
                      {row?.course_name?.substring(0, 50) + ' '  +  (row?.course_name?.length > 50 ?  '...' : '')}

                  </div>
              </OverlayTrigger>
      },

      {
         name: 'Assigned By',
         selector: 'assigner_name',
         sortable: true,
         center: false,
         grow: 4,
      },
      {
         name: 'Due Date',
         selector: 'due_date',
         sortable: true,
         center: false,
         grow: 2,

      },

      {
         name: 'Action',
         selector: 'Action',
         sortable: true,
         center: false,
         grow: 2,
         cell: row => (
            <div
          className="action-icon-item fill-icon"
         //  onClick={(e) => {
         //    handleModelLP(true), setRowId(row.id);
         //  }}
         onClick={e=>{handleModel(true),setRowId(row.id)}}
        >
          <OverlayTrigger
            overlay={<Tooltip id="InfoText">Delete Course</Tooltip>}
          >
            <svg
              id="icons_Q2"
              data-name="icons Q2"
              xmlns="http://www.w3.org/2000/svg"
              width="17.49"
              height="19.331"
              viewBox="0 0 17.49 19.331"
            >
              <path
                id="Path_8323"
                data-name="Path 8323"
                d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                transform="translate(-4.999 -3)"
                fill="#fff"
              />
              <path
                id="Path_8324"
                data-name="Path 8324"
                d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                transform="translate(-7.261 -9.477)"
                fill="#fff"
              />{" "}
            </svg>
          </OverlayTrigger>
        </div>

         )
        // <button className="btn border-btn" onClick={e=>{handleModel(true),setRowId(row.id)}}>Remove</button>,
         //onClick={(e)=>deleteCourse(row.id)}

      },
   ];

   const tableColumnsLearningPath = [
       {
           name: 'Learning Path',
           selector: 'learning_path_name',
           sortable: true,
           center: false,
           grow: 3,
           cell: row =>
               <OverlayTrigger
                   overlay={
                       <Tooltip id="InfoText">
                           {row?.learning_path_name}
                       </Tooltip>
                   }
               >
                   <div>
                       {row?.learning_path_name?.substring(0, 50) + ' '  +  (row?.learning_path_name?.length > 50 ?  '...' : '')}

                   </div>
               </OverlayTrigger>
       },
       // {
       //     name: 'Status',
       //     selector: 'status',
       //     sortable: true,
       //     center: false,
       //     grow: 2
       //
       // },
       {
           name: 'Due Date',
           selector: 'due_date',
           sortable: true,
           center: false,

           grow: 2,
       },
       {
           name: 'Completed',
           selector: 'created_by',
           sortable: true,
           center: false,
           cell: row =>
               <p>{row.completed ? 'Complete' : '--'}</p>
           ,
           grow: 2,
       },
       // {
       //     name: 'Action',
       //     selector: 'Action',
       //     sortable: true,
       //     center: false,
       //     grow: 2,
       //     cell: row =>
       //         <button className="btn border-btn"  onClick={e=>{ handleModelLP (true),setRowId(row.id)}}>Remove</button>
       //     ,
       // },
   ];

   const tableColumnsSurveys= [
      {
         name: 'Survey Name',
         sortable: true,
         center: false,
         // cell:row => <Link to={`/survey/${row.id}`}>{row.survey_name}</Link>,
         cell:row => row.survey_name,
         grow: 3
      },

      {
         name: 'Assigned By',
         selector: 'assigner_name',
         sortable: true,
         center: false,
         grow: 2,
      },
      {
         name: 'Created By',
         selector: 'created_by',
         sortable: true,
         center: false,
         grow: 2,
      },
      {
         name: 'Action',
         selector: 'Action',
         sortable: true,
         center: false,
         grow: 2,
         cell: row => (
            <div
          className="action-icon-item fill-icon"
         //  onClick={(e) => {
         //    handleModelLP(true), setRowId(row.id);
         //  }}
         onClick={(e)=>{handleModalSurvey(true);setRowId(row.id)}}
        >
          <OverlayTrigger
            overlay={<Tooltip id="InfoText">Delete Course</Tooltip>}
          >
            <svg
              id="icons_Q2"
              data-name="icons Q2"
              xmlns="http://www.w3.org/2000/svg"
              width="17.49"
              height="19.331"
              viewBox="0 0 17.49 19.331"
            >
              <path
                id="Path_8323"
                data-name="Path 8323"
                d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z"
                transform="translate(-4.999 -3)"
                fill="#fff"
              />
              <path
                id="Path_8324"
                data-name="Path 8324"
                d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z"
                transform="translate(-7.261 -9.477)"
                fill="#fff"
              />{" "}
            </svg>
          </OverlayTrigger>
        </div>
         )
         
        // <button className="btn border-btn" onClick={(e)=>{handleModalSurvey(true);setRowId(row.id)}}>Remove</button>
         ,
      },
   ];

   const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
      <div className="custom-control custom-checkbox">
         <input
            type="checkbox"
            className="custom-control-input"
            ref={ref}
            {...rest}
         />
         <label className="custom-control-label" onClick={onClick} />
      </div>
   ));
   const CaretDownIcon = () => {
      return (
         <div className="filter-icon-select">
         <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
         <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
         </svg>
         </div>
      );
    };
    
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   return (
      <div className="available-course-wrap">
           <Helmet>
                <title>Group Details - Cultural Engagement  </title>
            </Helmet>
         <div className="teammember-details-header">
            <div className="d-flex justify-content-between align-items-center m-b-20">
               <h3 className="common-card-title">{group?.name}</h3>

               {/* <div className="error-btn-wrap">
              <Link to="/team"><Button>Go Back</Button></Link>
              </div> */}
               <div className="button-wrap double-btn"> 
                  {/* <button className="btn border-btn" onClick={(e)=>history.goBack()}>Go Back</button> */}
                  <button className="btn border-btn" onClick={(e)=>history.push({pathname: '/team',state:{key:'Groups'} })}>Go Back</button> 

                  {/* <button className="btn border-btn" onClick={(e)=> { history.push({
                pathname: `/groupdetails/${id}`,
                state: { tab: "Groups"},
              })} } >Go Back</button> */}
                  <button className="btn border-btn" onClick={(e)=>handleModelDEL(true)}>Delete</button>
                  <button className="btn border-btn" onClick={(e) => {editGroupShow(true);handleEdit()}}>Edit</button>
                  <RemoveMember
                       show={modalDEL}
                      handlesubmit ={deleteSingleGroup}
                       onHide={() => setModalDEL(false)}
                        />
                  <button className="btn btn-primary" onClick={() => assignShow(true)} >Enroll</button>
               </div>
            </div>
         </div>
         <div className="teammember-other-details-wrap">
            <div className="d-flex align-items-center w-100 m-b-20">

               <div className="teammember-other-details d-flex">
                  <div className="oth-det-item"><span>Members</span>{groupDetails?.learners?.length}</div>
                  {/* <div className="oth-det-item"><span>Learning Paths</span>{ responseLP ?.count}</div> */}
                  <div className="oth-det-item"><span>Courses</span>{ responseCourses?.count}</div>
                  {!hideLpQuizes && (
                     <div className="oth-det-item"><span>Learning Path</span>{responseLP?.count}</div>
                  )}
               </div>
            </div>
         </div>
         <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
            {!hideLpQuizes && (
               <div className="datatable-filter-left-box d-flex align-items-center">
               <div className="datepicker-box p-relative">

               </div>

               <div className="dt-filter-left d-flex justify-content-between align-items-center">
                  <div className="custom-react-select">
                  <Select options={options} defaultValue={options[0]} placeholder={filter} value={filter} onChange={handleGroupsFilter}  components={{ DropdownIndicator }}  />
                  </div>
               </div>
            </div>
            ) }
            
            <div className="dt-filter-right d-flex">
               <div className="dt-filter-search-wrap p-relative search-icon-left">
                  <button className="iconbutton search-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216" viewBox="0 0 19.216 19.216">
                     <path className="a" d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                  </svg>
               </button>
                  <input type="text" placeholder="Search using keyword"
                     value={search} onChange={searchInput}
                     className="form-control input-item" />
                  <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
               </div>
               <OverlayTrigger
                overlay={
                    <Tooltip id="InfoText">
                          Help
                    </Tooltip>
                }
            >
                <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </OverlayTrigger>
            </div>
         </div>
                   <RemoveCourse
                       show={modalShow}
                        handleSubmit ={deleteCourse}
                       onHide={() => setModalShow(false)}
                       onExited={()=> setRowId('') }
                        />
                   <RemoveSurvey
                       show={modalSurvey}
                        handleSubmit ={ handleDeleteSurvey}
                       onHide={() => setModalSurvey(false)}
                       onExited={()=> setRowId('') }
                        />
                    <RemoveLearningPath
                       show={modalLP}
                        handleSubmit ={handleDeleteLP}
                       onHide={() => setModalLP(false)}
                       onExited={()=> setRowId('') }
                        />


         
         <div className="datatble-wrap">
            {Courseloading ? (  <div className='text-center'>
                           <Spinner animation="border" variant="danger" />
                        </div>) : (
                           <>
                              {data?.length ? (
                                 <DataTable
                                    data={data}
                                    columns={dataColumns}
                                    sortable={true}
                                    //selectableRows
                                    //selectableRowsComponent={BootyCheckbox}
                                    // noDataComponent={<div style={{padding:'24px'}}>No Enrollments has been made yet</div>}
                                    progressPending={dataProgress}
                                 progressComponent={
                                    <div >
                                    <Spinner animation="border" variant="warning"/>
                                 </div>
                     
                                 }
                              
                                 noHeader={true}
                                 />
                              ) : (
                                 <>
                                 {search ? (
                                    <div className='text-center'>No Enrollments found</div>
                                 ) : (
                                    <div className='text-center'>No Enrollments has been made yet</div>
                                 )}
                           
                                 </>
                              )
                           } 
                      </>
                        )}
            
           
           
         </div>
          
         {
            filter == 'Courses' ? 

             <> 
             {
               
             !Courseloading && state?.groupCourses && state?.groupCourses && state?.groupCourses.count > 12 && responseCourses &&
                  <div className="d-flex justify-content-center">
                 <Pagination size="sm" className="custom-pagination" key='courses'>
                 <Pagination.First disabled={!state?.groupCourses?.previous} className={`${state?.groupCourses?.previous ? "paginationStyle" : "" } `} onClick={handlePrevious}/>
                 {state?.paginationGroupCoursesItem}
                 <Pagination.Last disabled={!state?.groupCourses?.next} onClick={handleNext} className={`${state?.groupCourses?.next ? "paginationStyle" : "" } `} />
                </Pagination>
                 </div>
              }
            </> 
            
            : ""
          
         }
         {
            filter == 'Learning Path' ?
             <>
               {
                 !lploading && state?.groupLP && state?.groupLP && responseLP.count > 12 && responseLP &&
                 <div className="d-flex justify-content-center">
                <Pagination size="sm" className="custom-pagination" key='lp'>
                <Pagination.First disabled={!state?.groupLP?.previous} className={`${state?.groupLP?.previous ? "paginationStyle" : "" } `} onClick={handlePrevious}/>
                {state?.paginationGroupLPitem}
                 <Pagination.Last disabled={!state?.groupLP?.next} onClick={handleNext} className={`${ state?.groupLP?.next ? "paginationStyle" : "" } `} />
                </Pagination>
                </div>
            }
            </>
            : ""

         }

          <Modal
   size="md"
   show={editGroup}
   onHide={() =>
   editGroupShow(false)}
   aria-labelledby="example-modal-sizes-title-lg"
   className="common-modal team-member-pop inner-popup"
   centered
   >
   <Modal.Header closeButton>
      <div className="addmember-header">
         <h4>Edit Group</h4>
         <p>You can edit the details of your group here</p>
      </div>
   </Modal.Header>
   <Modal.Body>
      <div className="form-input-wrap">
         <Form.Label>Group Title</Form.Label>
         <div className="d-flex w-100">
            <div className="group-title-create-left">
               <Form.Control type="text" placeholder="Enter group name" className="input-item" value={name} onChange={handleGroupNameChange}/>
            </div>
            <div className="group-title-create-right">
                <Button disabled = { name?.length <=0? "disabled" : ""} onClick={updateGroup}>Update Group</Button>
            </div>
         </div>
      </div>
      <div className="group-list-selected-box-content">


      {groupMembers && groupMembers.length  ? groupMembers.map((item,index) => (

                  <div className="course-selected-item p-relative">

        {item?.id ?      <span className="course-selected-text">{item?.get_full_name.length > 1 ? item?.get_full_name : item?.id }</span>:''}
           <span className="memb-close-icon" onClick={()=>handleRemove(item,index)}>×</span>

                  </div>

          ))
          :
          ''
      }


      </div>
      <div className="group-pop-footer">
         <h4>Add New Members To The Group</h4>
         <div className="form-input-wrap">
            <Form.Label>Search and Select Email Address</Form.Label>

            <div className="d-flex w-100">
               <div className="group-title-create-left">
               <Select

            placeholder="Enter Email Address"
            isClearable={true}
              onChange={AddNew}
              onInputChange={searchMembers}

             options={newMembers||[]}
             getOptionLabel ={(option)=>option.email}
             getOptionValue={(option) => option.id}

      />
               </div>

               <div className="group-title-create-right">

               <Button  onClick={addNewMembers}>Add</Button>

               </div>
            </div>
            { memberExist ? <span  style={{  color:'#ce002a' }}>Member Already Selected </span>:''}
         </div>

      </div>
   </Modal.Body>
</Modal>



<Modal
   size="md"
   show={assign}
   onHide={() =>
   assignShow(false)}
   onExit={modalExit}
   aria-labelledby="example-modal-sizes-title-lg"
   className="common-modal team-member-pop inner-popup"
   centered
   >
   <Modal.Header closeButton>
      <div className="addmember-header">
         <h4>Enroll</h4>
         <p>Make an enrollment for this group here</p>
      </div>
   </Modal.Header>
   <Modal.Body>
      <SkillAssignBox  id={id}  parentCallback = {handleCallback} filter={{activeCoursesPage,search}}/>
   </Modal.Body>
</Modal>
<Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
               <Modal.Body>
                    <ol className="info-list">
                        <li>
                            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </span>
                        </li>
                        <li>
                            <span>dummy text of the printing and typesetting industry. Lorem Ipsum hass </span>
                        </li>
                        <li>
                            <span>Lorem Ipsum is simply dummy text of the printing and user_type</span>
                        </li>
               
                    </ol>
                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
      </div>
   );
};
export default GroupDetails;
