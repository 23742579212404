import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { tableData } from './dummyTableData';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select, { components } from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";

import {
    getSurvey,
    getManagersList,
    deleteSurvey,
    handlePagination,
    handlePreviousPagination,
    cloneSurvey,
    getDeletedSurvey,
    getSurveyCategories
} from '~/modules/surveys/services';
import { format } from 'date-fns';
import { element } from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getNotifications } from "../../../../Notifications/services";
import { getInstitutionType } from "../../../../auth/services";
import CustomPagination from "../../../../../shared/components/pagination";
import { getManagerCoursesList } from "../../../../coursemanagement/services";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function MyVerticallyCenteredModal(props) {
    const [text, setText] = useState("");
    const [isCopied, setIsCopied] = useState(false);



    const onCopyText = () => {
        setIsCopied(true);
        props.onHide()
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };
    const CaretDownIcon = () => {
        return (
            <div className="filter-icon-select">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
                    <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
                </svg>
            </div>
        );
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon />
            </components.DropdownIndicator>
        );
    };

    return (

        <Modal className="custom-modal confirmation-popup inner-popup"
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Body>
                <div>
                    {isCopied && isCopied ?
                        <span className="copied-text">
                            Copied!
                        </span>
                        :
                        ''
                    }

                    <span className="search-close-icon-modal" style={{ cursor: "pointer" }}
                        onClick={() => props.onHide()}>×</span>

                    <Row>
                        <Col xs={12} sm={12}>
                            <Form.Label>Survey Link</Form.Label>
                            <div className="form-input-wrap d-flex copylink-input-wrap">

                                <Form.Control type="text"
                                    name='last_name'
                                    placeholder="Your Last Name"
                                    value={props?.surveyLink}
                                    disabled
                                    className="input-item" />
                                <CopyToClipboard text={props?.surveyLink} onCopy={onCopyText}>

                                    <Button className="copy-btn">Copy</Button>


                                </CopyToClipboard>

                            </div>


                        </Col>


                    </Row>
                    {/*<button type="button" className="btn border-btn white-btn ml-5" style={{*/}
                    {/*    position: "absolute",*/}
                    {/*    transform: "translate(60%, -100%)"*/}
                    {/*}} onClick={() => props.onHide()}>Handle Model*/}
                    {/*</button>*/}
                </div>
            </Modal.Body>
        </Modal>
    );
}


const AvailableSurveys = (props) => {

    const dispatch = useDispatch();
    const managers = useSelector(state => state.surveys.manager_list?.response || []);
    const response = useSelector(state => state.surveys.surveys_list?.response);
    const { user } = useSelector(state => state.auth);
    const restoreresponse = useSelector((state) => state.surveys.survey_restore?.response);
    const surveyCategory = useSelector(
        (state) => state?.surveys?.survey_category?.response
    );

    const history = useHistory();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [surveys, setSurveys] = useState(response);
    const [count, setCount] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [createdby, setCreatedby] = useState('');
    const [host, setHost] = useState('http://culturalengagement-dev.sayone.team');
    const [state, setState] = useState({
        modalShow: false,
        surveyLink: ''

    });
    const [activePage, setActivePage] = useState(1);
    const [dataProgress, setDataProgress] = useState(false)
    const loading = useSelector(state => state.surveys.surveys_list?.loading);
    const [modalShow, setModalShow] = React.useState(false);
    const [selectrow, setSelectRow] = useState(null);
    const [flag, setFlag] = useState(false)
    const [category, setCategory] = useState([]);
    const [categorySelected, setCategorySelected] = useState('');


    const [statusSelected, setStatusSelected] = useState('');

    const status = [{ label: 'Active', value: 'active' }, { label: 'Due', value: 'expired' }]


    useEffect(() => {
        if (loading) { setDataProgress(true) }


        if (!loading) { setDataProgress(false) }
    }, [loading])


    useEffect(() => {
        setSearch('');
        setDateRange([null, null]);
        setCreatedby('');
        // dispatch(getSurvey('', '', '', '', '', '', ''));
        dispatch(getManagersList());
        // dispatch(
        //     getInstitutionType(function (response) {
        //         if (response) {
        //             let newMap = response?.results?.map((el) => {
        //                 return {
        //                     label: el?.name,
        //                     value: el?.id,
        //                 };
        //             });
        //             setCategory(newMap);
        //         }
        //     })
        // );
        dispatch(getNotifications())
        dispatch(getSurveyCategories())
    }, []);

    useEffect(() => {
        if (user?.is_admin_institution) {
            setFlag(true)
        }
    }, [])

    useEffect(() => {
        setActivePage(1)
    }, [restoreresponse])

    useEffect(() => {
        setSurveys(response);
    }, [response]);

    const handleModal = (link, row) => {
        if (!row.is_survey_expired) {
            setState({ ...state, modalShow: true, surveyLink: link });
        } else {
            toast.error('This survey is due, cannot copy link')
        }
    };

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            dispatch(getSurvey(search, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.id, statusSelected === '' ? '' : statusSelected.value));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        dispatch(getSurvey(search === null ? '' : search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.id, statusSelected === '' ? '' : statusSelected.value));
        setActivePage(1)
    }, [search, createdby, categorySelected, statusSelected]);



    const reset = () => {
        setSearch('');
        setActivePage(1)
        setDateRange([null, null]);
        setCreatedby('');
        setCategorySelected('');
        setStatusSelected('')
        dispatch(getSurvey('', '', '', '', '', '', ''));
    };

    const [data, setData] = useState(tableData);

    const handleDeleteSurvey = (row) => {
        setDataProgress(true)
        dispatch(deleteSurvey(row, () => {
            const pgnumber = response?.results?.length > 1 ? activePage : activePage > 1 ? activePage - 1 : '';
            dispatch(getSurvey(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.id, statusSelected === '' ? '' : statusSelected.value, pgnumber));
            dispatch(getDeletedSurvey('', '', '', '', '', ''));
            setActivePage(pgnumber)
            setDataProgress(false)
        }))

    };

    function DeleteVerticallyCenteredModal(props) {

        return (
            <Modal className="custom-modal confirmation-popup  delete-confirmation inner-popup"
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Body>
                    <div className="modal-content-wrap text-center">
                        <h3 className="modal-title">Are you sure you want to delete this survey?<br /><span style={{ fontSize: '12px' }}>Please note, the related survey reports shall be deleted too!</span>
                        </h3>

                        {/* <p>Are You Sure To Delete The Members? </p> */}
                        <div className="d-flex justify-content-center buttons-wrap double-btn">

                            <button type="button" className="btn border-btn white-btn" onClick={() => props.onHide()}>No, I
                                need to recheck
                            </button>
                            <Link className="m-l-20"><Button onClick={props.handleSubmit} type="button">Yes,
                                Continue</Button></Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }



    const handleNext = () => {
        if (surveys?.next) {
            var regex = /page=\d+/g;
            setActivePage((prevState) => prevState + 1);
            dispatch(handlePagination(surveys?.next));
        } else {
            return false;
        }

    };

    const searchInput = (e) => {
        setSearch(e.target.value);
    };

    const onSelect = (val) => {
        setCreatedby(val);
    };

    const onCategorySelect = (val) => {
        setCategorySelected(val);
    }

    const onStatusSelect = (val) => {
        setStatusSelected(val);
        setActivePage(1)
    }

    const handlePrevious = () => {
        if (surveys?.previous) {
            setActivePage((prevState) => prevState - 1);
            dispatch(handlePreviousPagination(surveys?.previous));

        } else {
            return false;
        }

    };

    const tableColumns = [
        {
            name: 'Survey Name',
            cell: row => <Link to={`/surveydetails/${row?.id}`}>{row.name}</Link>,
            //selector: 'name',
            sortable: true,
            selector: 'name',
            center: false,
            grow: 5
        },

        {
            name: 'Number of Responses',
            selector: 'attended_count',
            sortable: true,
            center: false,
            grow: 3
        },
        {
            name: 'Created By',
            selector: 'creator_name',
            sortable: true,
            center: false,
            grow: 3,

        },


        {
            name: 'Due Date',

            cell: row => row?.expiry_date ? row?.expiry_date : 'Not Available',
            sortable: true,
            center: false,
            selector: 'expiry_date',
            grow: 3,

        },

        {
            name: 'Status',
            cell: row => row?.is_survey_expired ? 'Due' : 'Active',
            //selector: 'name',
            sortable: false,
            center: false,
            grow: 3
        },


        {
            name: 'Action',
            selector: 'Action',
            sortable: false,
            center: false,
            grow: 2,
            cell: (row, index) =>
                <div data-tag="allowRowEvents" className='d-flex'>
                    <div className="action-icons-cell-wrap d-flex">
                        {/*{!row?.is_survey_expired >0 &&*/}
                        <div className="action-icon-item fill-icon copy-link-icon" onClick={() => handleModal(`${row?.link}`, row)}>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="InfoText">
                                        Copy Survey Link
                                    </Tooltip>
                                }
                            >

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.432 -0.433)">
                                        <g id="invisible_box" data-name="invisible box">
                                            <rect id="Rectangle_2933" data-name="Rectangle 2933" width="20" height="20" transform="translate(0.432 0.433)" fill="none" />
                                        </g>
                                        <g id="icons_Q2" data-name="icons Q2" transform="translate(1.721 1.739)">
                                            <path id="Path_8535" data-name="Path 8535" d="M8.28,28.713a4.347,4.347,0,0,1-3.043-1.261,4.26,4.26,0,0,1,0-6.086l3.13-3.13a4.26,4.26,0,0,1,6.086,0,.869.869,0,0,1-1.217,1.217,2.608,2.608,0,0,0-3.651,0l-3.13,3.13a2.582,2.582,0,1,0,3.651,3.651l1.956-2.043a.869.869,0,0,1,1.217,1.217l-1.956,2.043A4.347,4.347,0,0,1,8.28,28.713Z" transform="translate(-3.959 -11.326)" />
                                            <path id="Path_8536" data-name="Path 8536" d="M22.717,15.737a4.347,4.347,0,0,1-3.043-1.261.869.869,0,0,1,1.217-1.217,2.608,2.608,0,0,0,3.651,0l3.13-3.13a2.582,2.582,0,1,0-3.651-3.651L22.065,8.521A.869.869,0,0,1,20.848,7.3L22.8,5.261a4.3,4.3,0,1,1,6.086,6.086l-3.13,3.13a4.347,4.347,0,0,1-3.043,1.261Z" transform="translate(-12.744 -4)" />
                                        </g>
                                    </g>
                                </svg>

                            </OverlayTrigger>
                        </div>

                        {/*{ row?.attended_count >0 &&*/}
                        <div className="action-icon-item fill-icon big-black-icon remove-mangr" onClick={() => {
                            if (row?.attended_count > 0) {

                                history.push(`/survey-graph/${row?.id}`)
                            } else {
                                toast.error("This survey hasn't received any responses.")

                            }
                        }}>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="InfoText">
                                        Reports
                                    </Tooltip>
                                }
                            >
                                <svg id="reports" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g id="Group_6642" data-name="Group 6642" transform="translate(10328.672 81.404)">
                                        <path id="Path_8215" data-name="Path 8215" d="M14.545,17.205H3.792A1.8,1.8,0,0,1,2,15.413V1.792A1.8,1.8,0,0,1,3.792,0H14.545a1.8,1.8,0,0,1,1.792,1.792V15.413A1.8,1.8,0,0,1,14.545,17.205ZM3.792.717A1.077,1.077,0,0,0,2.717,1.792V15.413a1.077,1.077,0,0,0,1.075,1.075H14.545a1.077,1.077,0,0,0,1.075-1.075V1.792A1.077,1.077,0,0,0,14.545.717Z" transform="translate(-10325.67 -78.404)" stroke="#000" stroke-width="0.7" />
                                        <path id="Path_8216" data-name="Path 8216" d="M17.426,11.717H16.285a.368.368,0,0,1,0-.717h1.141a.368.368,0,0,1,0,.717Z" transform="translate(-10329.195 -83.172)" stroke="#000" stroke-width="0.5" />
                                        <path id="Path_8217" data-name="Path 8217" d="M17.426,8.717H16.285a.368.368,0,0,1,0-.717h1.141a.368.368,0,0,1,0,.717Z" transform="translate(-10329.195 -82.322)" stroke="#000" stroke-width="0.5" />
                                        <path id="Path_8218" data-name="Path 8218" d="M17.426,14.717H16.285a.368.368,0,0,1,0-.717h1.141a.368.368,0,0,1,0,.717Z" transform="translate(-10329.195 -84.021)" stroke="#000" stroke-width="0.5" />
                                        <path id="Path_8219" data-name="Path 8219" d="M17.426,17.717H16.285a.368.368,0,0,1,0-.717h1.141a.368.368,0,0,1,0,.717Z" transform="translate(-10329.195 -84.871)" stroke="#000" stroke-width="0.5" />
                                        <path id="Path_8220" data-name="Path 8220" d="M14.678,20.717H5.358a.358.358,0,1,1,0-.717h9.319a.358.358,0,1,1,0,.717Z" transform="translate(-10326.52 -84.066)" stroke="#000" stroke-width="0.6" />
                                        <g id="Group_6639" data-name="Group 6639" transform="translate(-10321.52 -73.964)">
                                            <g id="Group_6638" data-name="Group 6638">
                                                <path id="Path_8225" data-name="Path 8225" d="M56.7,54.729a.337.337,0,0,0-.337.337v.606a3.1,3.1,0,1,0,3.418,3.418h.606a.337.337,0,0,0,.337-.337A4.028,4.028,0,0,0,56.7,54.729Zm0,6.449a2.425,2.425,0,0,1-.337-4.827v2.4a.337.337,0,0,0,.337.337h2.4A2.429,2.429,0,0,1,56.7,61.178Zm.337-2.762h0v-3a3.356,3.356,0,0,1,3,3Z" transform="translate(-53.602 -54.729)" stroke="#000" stroke-width="0.5" />
                                            </g>
                                        </g>
                                    </g>
                                    <rect id="Rectangle_3313" data-name="Rectangle 3313" width="24" height="24" fill="none" />
                                </svg>
                            </OverlayTrigger>
                        </div>
                        {/*}*/}
                        {/* <div className="action-icon-item stroke-icon" onClick={() =>history.push(`/survey-edit/${row.id}`)}>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="InfoText">
                                        Edit
                                    </Tooltip>
                                }
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.917" height="17.917" viewBox="0 0 17.917 17.917">
                                    <g id="_3325116_edit_icon" data-name="3325116_edit_icon" transform="translate(-1.1 -1.1)">
                                        <path id="Path_8321" data-name="Path 8321" d="M16.506,12.591v4.3a1.612,1.612,0,0,1-1.612,1.612H3.612A1.612,1.612,0,0,1,2,16.894V5.612A1.612,1.612,0,0,1,3.612,4h4.3" transform="translate(0 -0.388)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                                        <path id="Path_8322" data-name="Path 8322" d="M16.059,2l3.223,3.223-8.059,8.059H8V10.059Z" transform="translate(-1.165 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" /> </g>
                                </svg>
                            </OverlayTrigger>
                        </div> */}
                        <div className="action-icon-item fill-icon" onClick={e => { setModalShow(true); setSelectRow(row) }}>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="InfoText">
                                        Delete
                                    </Tooltip>
                                }
                            >
                                <svg id="icons_Q2" data-name="icons Q2" xmlns="http://www.w3.org/2000/svg" width="17.49" height="19.331" viewBox="0 0 17.49 19.331">
                                    <path id="Path_8323" data-name="Path 8323" d="M22.489,5.669a1.059,1.059,0,0,1-.276.736.782.782,0,0,1-.644.276H16.046a.921.921,0,0,1-.921-.921V4.841H12.364v.921a.921.921,0,0,1-.921.921H5.966A.967.967,0,0,1,5,5.854a1.059,1.059,0,0,1,.276-.736.782.782,0,0,1,.644-.276h4.6V3.921A.921.921,0,0,1,11.443,3h4.6a.921.921,0,0,1,.921.921v.921h4.557a.967.967,0,0,1,.967.828Z" transform="translate(-4.999 -3)" fill="#fff" />
                                    <path id="Path_8324" data-name="Path 8324" d="M20.977,15.828l-1.1,11.138H12.14l-1.1-11.138A.921.921,0,0,0,10.115,15h0a.921.921,0,0,0-.921,1.013l1.2,11.967a.921.921,0,0,0,.921.828H20.7a.921.921,0,0,0,.921-.828l1.2-11.967A.921.921,0,0,0,21.9,15h0A.921.921,0,0,0,20.977,15.828Z" transform="translate(-7.261 -9.477)" fill="#fff" /> </svg>
                            </OverlayTrigger>
                        </div>
                        <div> </div>
                    </div>

                </div>
            ,
        },
    ];
    // const handleIndex = (index) => {
    //     if (index == 1) {
    //         dispatch(getSurvey(search, startDate === null ? '' : startDate, endDate == null ? '' : endDate, createdby === null ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.value, statusSelected === '' ? '' : statusSelected.value, ''));
    //     } else {
    //         dispatch(getSurvey(null, null, null, null, null, null, index));
    //     }
    //     setActivePage(index);
    // }


    const handleIndex = (index) => {

        dispatch(getSurvey(search, startDate === null ? '' : format(new Date(startDate), 'yyyy-MM-dd'), endDate == null ? '' : format(new Date(endDate), 'yyyy-MM-dd'), createdby === '' ? '' : createdby?.id, categorySelected === '' ? null : categorySelected.id, statusSelected === '' ? '' : statusSelected.value, index))

        setActivePage(index);
    }

    const CaretDownIcon = () => {
        return (
            <div className="filter-icon-select">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
                    <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
                </svg>
            </div>
        );
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon />
            </components.DropdownIndicator>
        );
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="available-course-wrap">
            {/*<ToastContainer autoClose={2000}/>*/}
            <div className="datatable-filters-wrap d-flex justify-content-between align-items-center">
                <div className="datatable-filter-left-box d-flex align-items-center">
                    <div className="datepicker-box p-relative">
                        <DatePicker
                            placeholderText={'Created date'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        <span className="datepickericon">
                            <i className="fa fa-calendar"></i>
                        </span>
                    </div>

                    <div className="dt-filter-left d-flex justify-content-between align-items-center">
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="InfoText">
                                    {createdby ? createdby?.label :  "Author"}
                                </Tooltip>
                            }
                        >
                        <div className="custom-react-select">


                            <Select options={managers || []} getOptionLabel={(option) => option.label.length > 1 ? option.label : option.email_label}
                             getOptionValue={(option) => option.id }
                                value={createdby} onChange={onSelect} placeholder="Author" components={{ DropdownIndicator }} />
                        </div>
                        </OverlayTrigger>
                        {/* <button type="button" className="iconbutton"><img src={FilterImg} /></button> */}
                    </div>
                    {
                        //     flag ?
                        //         <div className="dt-filter-left d-flex justify-content-between align-items-center">
                        //             <div className="custom-react-select">

                        //                 <Select options={category || []}
                        //                     value={categorySelected} onChange={onCategorySelect} placeholder="Category" components={{ DropdownIndicator }} />
                        //             </div>

                        //             {/* <button type="button" className="iconbutton">
                        //      <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">
                        //         <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />
                        //      </svg>
                        //   </button> */}
                        //         </div>
                        //         : ''
                    }
                    <div className="dt-filter-left d-flex justify-content-between align-items-center">
                        <div className="custom-react-select">
                            <Select options={surveyCategory?.results || []} getOptionLabel={(option) => option.name} getOptionValue={option => option.id}

                                value={categorySelected} onChange={onCategorySelect} placeholder="Category" components={{ DropdownIndicator }} />
                        </div>
                    </div>



                    <div className="dt-filter-left d-flex justify-content-between align-items-center">
                        <div className="custom-react-select">

                            <Select options={status || []}
                                value={statusSelected} onChange={onStatusSelect} placeholder="Status" components={{ DropdownIndicator }} />
                        </div>

                        {/*<button type="button" className="iconbutton">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="19.652" height="19.653" viewBox="0 0 19.652 19.653">*/}
                        {/*        <path className="a" d="M19.384,0H1.268A.768.768,0,0,0,.5.768a7.5,7.5,0,0,0,2.514,5.6L6.376,9.355A2.735,2.735,0,0,1,7.294,11.4v7.486a.768.768,0,0,0,1.193.639L13.016,16.5a.768.768,0,0,0,.342-.639V11.4a2.736,2.736,0,0,1,.917-2.043l3.362-2.988a7.5,7.5,0,0,0,2.514-5.6A.768.768,0,0,0,19.384,0ZM16.618,5.219,13.256,8.208a4.272,4.272,0,0,0-1.433,3.19v4.056l-2.994,2V11.4A4.272,4.272,0,0,0,7.4,8.208L4.034,5.219A5.963,5.963,0,0,1,2.085,1.535H18.567a5.962,5.962,0,0,1-1.949,3.684Zm0,0" transform="translate(-0.5 0)" />*/}
                        {/*    </svg>*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className="dt-filter-right d-flex">
                    <div className="dt-filter-search-wrap p-relative search-icon-left">
                        <button className="iconbutton search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.216" height="19.216"
                                viewBox="0 0 19.216 19.216">
                                <path className="a"
                                    d="M19,17.941l-4.69-4.69a8.1,8.1,0,1,0-1.057,1.056L17.941,19A.747.747,0,1,0,19,17.941ZM1.494,8.088a6.593,6.593,0,1,1,6.594,6.593A6.6,6.6,0,0,1,1.494,8.088Z" />
                            </svg>
                        </button>
                        <input type="text" placeholder="Search using keyword"
                            value={search} onChange={searchInput}
                            className="form-control input-item" />
                        <span className="search-close-icon" style={{ cursor: "pointer" }} onClick={reset}>×</span>
                    </div>
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="InfoText">
                                Help
                            </Tooltip>
                        }
                    >
                        <span className="searchbar-info-icon animated-icon" onClick={handleShow}>
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                        </span>
                    </OverlayTrigger>
                </div>
            </div>


            {loading && loading ? (
                <>
                    <div className="loaderCourses">
                        <Spinner animation="border" variant="danger" />
                    </div>
                </>
            ) : (
                <>
                    {surveys?.results?.length > 0 ? (
                        <div className="datatble-wrap">
                            <DataTable
                                data={surveys?.results}
                                columns={tableColumns}
                                center={true}
                                showPaginationBottom={false}
                                noHeader={true}
                                progressPending={dataProgress}
                            // progressComponent={
                            //     <div >
                            //         <Spinner animation="border" variant="warning" />
                            //     </div>
                            // }
                            />
                        </div>

                    ) : (


                        <>
                            {search?.length > 0 || createdby || categorySelected || dateRange[0] || dateRange[1] || statusSelected ? (
                                <span className='noData'>No matching results found</span>
                            ) : (

                                <span className='noData text-center max'>You have no active Surveys, to get started click on the <Link onClick={() => props.setKeyFromTab(2)}>template library</Link>  and clone a template of your choice, or create a new <Link to="/surveybuilder">Survey</Link>  from scratch.</span>
                            )}

                        </>

                    )}
                    {!dataProgress ?
                        <>

                            {surveys?.count > 12 ?
                                <CustomPagination response={surveys} activePage={activePage} handleNext={handleNext} handlePrevious={handlePrevious} handleIndex={handleIndex} />
                                :
                                ""

                            }
                        </> : ''
                    }
                </>
            )}


            <MyVerticallyCenteredModal
                show={state.modalShow}
                surveyLink={state.surveyLink}
                onHide={() => setState({ ...state, modalShow: false })}
            />
            <DeleteVerticallyCenteredModal
                show={modalShow}
                handleSubmit={() => { handleDeleteSurvey(selectrow); setModalShow(false) }}
                onHide={() => setModalShow(false)}
            // onExited={ModalExit}
            />
            <Modal className="custom-modal info-modal dashboard-modal" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="module-modal-header my-4"><h4>Surveys are developed and published by you and others in your organization here.</h4></div>
                    <ol className="info-list">
                        <li><span>The “Create New Survey” button enables managers to create a survey.</span></li>
                        <li><span>After a survey is published, it will appear under the “Published Surveys” tab.</span></li>
                        <li><span>Surveys can be: 1&#41;	 previewed, 2&#41;	 deleted, or 3&#41;	 cloned. </span></li>
                        <li><span>Published surveys cannot be edited. To duplicate a survey, clone it and the survey will appear under the “Drafts” tab. </span></li>
                        <li><span>Send out the survey by selecting the “Copy Survey Link” under the “Action” column, then paste and send the link to respondents via email, interoffice communication, etc. </span></li>
                        <li><span>Note that the identities of the survey respondents will not be saved, survey managers will only see their selected demographic information.</span></li>
                        <li><span>Select the “Reports” icon in the “Action” column to view real time survey results &#40;note: refresh the window for most up-to-date results&#41;	.</span></li>
                        <li><span>Search for a survey using the: 1&#41;	 Created Date, 2&#41;	 Author, 3&#41;	 Category, 4&#41;	status filters, or 4&#41;	 by keyword. </span></li>
                    </ol>



                </Modal.Body>
                <div className="btn-wrap text-center">
                    <Button onClick={handleClose}> Close </Button>
                </div>
            </Modal>
        </div>
    );
};
export default AvailableSurveys;
