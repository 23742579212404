import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import CkEditorQuestion from './ckquestion'
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {useLocation} from "react-router-dom";
function ModuleQuizAdded2(props) {
    let location = useLocation();
    const {handleChange,values,errors,index,setFieldValue,moduleErrors,moduleTouched,handleBlur,setValues} = props
const handleAnswer = (e) =>{
    setFieldValue(`modules.${index}.module_tests.${0}.right_answer`,e.target.value)

    const modules =[...values.modules]
    modules[index].module_tests[0].test_type='true_or_false'
    if(e.target.value == 'True'){
        modules[index].module_tests[0].right_answer='True'
        modules[index].module_tests[0].choices[0]={id:modules[index].module_tests[0].choices[0].id ?modules[index].module_tests[0].choices[0].id :'' ,choice: 'True',is_correct_answer:'True',choice_response :modules[index].module_tests[0].choices[0].choice_response ? modules[index].module_tests[0].choices[0].choice_response :''}
        modules[index].module_tests[0].choices[1]={id:modules[index].module_tests[0].choices[1].id ?modules[index].module_tests[0].choices[1].id :'',choice: 'False',is_correct_answer:'False',choice_response :modules[index].module_tests[0].choices[1].choice_response ? modules[index].module_tests[0].choices[1].choice_response :''}

    }else if(e.target.value == 'False'){
        modules[index].module_tests[0].right_answer='False'

        modules[index].module_tests[0].choices[0]={id:modules[index].module_tests[0].choices[0].id ?modules[index].module_tests[0].choices[0].id :'' ,choice: 'True',is_correct_answer:'False',choice_response :modules[index].module_tests[0].choices[0].choice_response ? modules[index].module_tests[0].choices[0].choice_response :''}
        modules[index].module_tests[0].choices[1]={id:modules[index].module_tests[0].choices[1].id ?modules[index].module_tests[0].choices[1].id :'',choice: 'False',is_correct_answer:'True',choice_response :modules[index].module_tests[0].choices[1].choice_response ? modules[index].module_tests[0].choices[1].choice_response :''}
    }
    setValues({...values, modules})
}
    const   handleSwitchChange = (checked) =>{
        setFieldValue(`modules.${index}.module_tests.${0}.show_response`,checked)
    }
    return (
        <div className="module-quiz-add-wrap">

                {  values?.modules[index]?.module_tests[0] && (
                <Row>

                    <Col md={12}>
                        <div className="form-input-wrap">
                            <Form.Label>Add Question</Form.Label>
                            <CkEditorQuestion moduleErrors={moduleErrors} moduleTouched={moduleTouched} name={`modules.${index}.module_tests.${0}.question`} setFieldValue={setFieldValue} values={values} handleBlur={handleBlur} index={index}/>
                        </div>
                    </Col>
                    
                         <Col xs={12}>
                         <Form.Control
                             type="hidden" placeholder="Option 1"
                             value={values?.modules[index]?.module_tests[0]?.choices[0]?.choice}
                             name={`modules.${index}.module_tests.${0}.choices.${0}.choice`}
                             // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${0}.choice`,e.target.value)}
                             onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${0}.choice`,e.target.value)}
                         />
 
                         <Form.Control
                             type="hidden" placeholder="Option 2"
                             name={`modules.${index}.module_tests.${0}.choices.${1}.choice}`}
                             value={values?.modules[index]?.module_tests[0]?.choices[1]?.choice}
                             // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${1}.choice`,e.target.value)}
                             onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${1}.choice`,e.target.value)}
                         />
                         <Row>
                             <Col md={2}>
                             <div className=" mt-4">
                                 <div className=" d-flex align-items-center h-100"><b>If True -</b> </div>
                             </div>
                             </Col>
                             <Col md={10} >
                                 <div className="form-input-wrap">
 
                                     <div  className="form-input-wrap">
                                         <Form.Label>Response</Form.Label>
                                         <Form.Control
                                             type="text" placeholder="Response"
                                             name={`modules.${index}.module_tests.${0}.choices.${0}.choice_response`}
                                             value={values?.modules[index]?.module_tests[0]?.choices[0]?.choice_response}
                                             // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${0}.choice_response`,e.target.value)}
                                             onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${0}.choice_response`,e.target.value)}
                                         />
 
                                     </div>
 
                                 </div>
                             </Col>
                             <Col md={2} >
                                 <div className=" mt-4">
                                     <div className=" d-flex align-items-center h-100"><b>If False -</b></div>
                                 </div>
                             </Col>
                             <Col md={10} >
                                 <div className="form-input-wrap">
 
                                     <div  className="form-input-wrap">
                                         <Form.Label>Response</Form.Label>
                                         <Form.Control
                                             type="text" placeholder="Response"
                                             name={`modules.${index}.module_tests.${0}.choices.${1}.choice_response`}
                                             value={values?.modules[index]?.module_tests[0]?.choices[1]?.choice_response}
                                             // onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${1}.choice_response`,e.target.value)}
                                             onChange={e=>setFieldValue(`modules.${index}.module_tests.${0}.choices.${1}.choice_response`,e.target.value)}
                                         />
 
                                     </div>
 
                                 </div>
                             </Col>
                         </Row>
 
 
                         <Row>
                             <Col md={6}>
                                 <div className="form-input-wrap">
                                     <Form.Label>Right Answer</Form.Label>
                                     <Form.Control as="select"  onChange={handleAnswer}
                                                   name={`modules.${index}.module_tests.${0}.rightAnswer`}
                                                   value={values?.modules[index]?.module_tests[0]?.right_answer}
                                                   onBlur={handleBlur}
                                                   isInvalid={!!moduleErrors?.module_tests && !!moduleErrors?.module_tests?.length && !!moduleErrors?.module_tests[0]?.right_answer &&  moduleTouched?.module_tests && moduleTouched?.module_tests.length && !!moduleTouched?.module_tests[0]?.right_answer}
                                     >
                                         <option value=''>Choose right answer</option>
                                         <option value='True'>True</option>
                                         <option value='False'>False</option>
                                     </Form.Control>
                                 </div>
                             </Col>
                             {location?.pathname?.includes('quizbuilder')  ?
                                 <></>
                                 :
                                 <Col md={4}>
                                     <div className='d-flex switch-inmodule-wrap'>
                                         <span> Should Show Response</span>
                                         <div className="switch-align-right">
                                             <BootstrapSwitchButton onChange={handleSwitchChange}  checked={values?.modules[index]?.module_tests[0]?.show_response} size="xs" />
                                         </div>
                                     </div>
 
                                 </Col>
                             }
                         </Row>
                     </Col>
                  
                   
                </Row>
                )}
        </div>
    );
}

export default ModuleQuizAdded2;

