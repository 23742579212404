import {toast} from "react-toastify";
import {store} from '../../redux/store'
import  {resetStore} from "../../modules/auth/services";

export  const checkHost  =  (host)  => {

    if(host.split(".")[1] == "enga")
    {
        let newHos = 'https://www.enga.app/signUp'
        window.location.assign(newHos);

    }

    else  if( host.split(".")[1] == "culturalengagement")
    {
        let newHos = 'https://www.culturalengagement.sayone.team/signUp'
        window.location.assign(newHos);

    }

    else   if( host.includes("localhost"))
    {

        let array=window.location.host.split(".")


        if(array[0]?.includes("localhost"))
        {
            let newHos = ` http://${ array[0]}/signUp`
            window.location.assign(newHos);


        }else  if(array[1]?.includes("localhost"))
        {
            let newHos = ` http://${ array[1]}/signUp`
            window.location.assign(newHos);
        }

    }

    else{
        let newHos = 'https://cultural-frontend-demo.dev.sayone.team/signUp'
        window.location.assign(newHos);
    }

}


export  const redirectUser  =  (user,newHistory,userloading)  => {


    if(!userloading){

        if(user?.is_admin_institution == false && user?.user_type == 'manager'){
            if(user?.is_domain_created == false && user?.is_profile_completed == false){
                newHistory.push('/complete-profile');
            } else if(user?.is_domain_created == false && user?.is_profile_completed == true){
                newHistory.push('/subdomainsignup');
            } else if(user?.is_domain_created == true && user?.is_subscribed == false){
                newHistory.push('/subscriptiononsignup');
            } else {
                newHistory.push('/my-assignments');
            }
        } else if(user?.user_type == 'admin'){
            newHistory.push('/my-assignments');
        } else if(user?.is_admin_institution == false && (user?.user_type === 'learner' || user?.user_type === 'staff_manager')){
            if(user?.is_subscribed){
                newHistory.push('/my-assignments');
            }else{
                let action = 'USER_LOGOUT'
                store.dispatch(resetStore(action))
                newHistory.push('/SubscriptionCancelled');
            }

        } else {
            if(user?.is_admin_institution == true){
                newHistory.push('/my-assignments');
            }
        }
    }
};


export  const simpleRedirect = (user,newHistory,logoutFunc) => {
    if(user){
        if(user?.is_admin_institution == false && user?.user_type == 'manager'){
            if(user?.is_domain_created == false && user?.is_profile_completed == false){
                newHistory.push('/complete-profile');
            } else if(user?.is_domain_created == false && user?.is_profile_completed == true){
                newHistory.push('/subdomainsignup');
            } else if(user?.is_domain_created == true && user?.is_subscribed == false){
                newHistory.push('/subscriptiononsignup');
            } else {
                newHistory.push('/my-assignments');
            }
        } else if(user?.user_type == 'admin'){
            newHistory.push('/my-assignments');
        } else if(user?.is_admin_institution == false && (user?.user_type === 'learner' || user?.user_type === 'staff_manager')){
            if(user?.is_subscribed){
                newHistory.push('/my-assignments');
            }else{
                let action = 'USER_LOGOUT'
                store.dispatch(resetStore(action))
                newHistory.push('/SubscriptionCancelled');
            }

        } else if(user?.is_admin_institution == true){
            newHistory.push('/my-assignments');
        } else {
            logoutFunc()
            newHistory.push('/login');
        }
    }

};
